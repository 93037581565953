import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-login-page-carousel',
  templateUrl: './artemis-login-page-carousel.component.html',
  styleUrls: ['./artemis-login-page-carousel.component.scss']
})
export class ArtemisLoginPageCarouselComponent implements OnInit {

  imageArray: string[];
  carouselPanels: CarouselPanel[];
  active: boolean = true;
  
  constructor() {
    this.imageArray = [];
    this.carouselPanels = [];
  }

  ngOnInit(): void {
    this.addImageToArray("01.jpg");
    this.addImageToArray("02.jpg");
    this.addImageToArray("03.jpg");
    this.carouselPanels.push(new CarouselPanel("01.jpg","TITLE 1","SUBTITLE 1","BTN 1","#",""));
    this.carouselPanels.push(new CarouselPanel("02.jpg","TITLE 2","SUBTITLE 2","BTN 2","#",""));
    this.carouselPanels.push(new CarouselPanel("03.jpg","TITLE 3","SUBTITLE 3","BTN 3","#",""));
  }

  addImageToArray (imageName: string) {
    this.imageArray.push("/assets/"+imageName);
  }

  isActive () {
    let previousValue = this.active;
    this.active = false;
    return true;
  }

}

/**
 * Temporary Class for containing carousel items
 * Planned to be replaced with json calls
 */
class CarouselPanel{
  img_src: string;
  title: string;
  subtitle: string;
  btn_text: string;
  btn_url: string;
  icon_src: string;

  constructor(img_src: string, title: string, subtitle: string, btn_text: string, btn_url: string, icon_src: string){
    this.img_src = "/assets/"+img_src;
    this.title = title;
    this.subtitle = subtitle;
    this.btn_text = btn_text;
    this.btn_url = btn_url;
    this.icon_src = icon_src;
  }
}
