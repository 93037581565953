import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { AnnouncementCrudComponent } from '../announcement-crud/announcement-crud.component'

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {
  modalRef?: MDBModalRef;
  loader: boolean = true;
  announcements: any =[]

  constructor(private router: Router,
    public modalService: MDBModalService,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.loadItems()
  }

  loadItems(){
   this.afs.collection(`Announcements`, filter => filter.orderBy('date', 'desc')).snapshotChanges().subscribe(
      (data: any) => {
        this.announcements = []
        this.loader = false;
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.announcements.includes(item) === false){
              this.announcements.push(item);
            }     
          });
      });
  }

  actionModal(method: string, collection: string, item: any){
    this.modalRef = this.modalService.show(AnnouncementCrudComponent,
      { backdrop: 'static',
        data:{
        collection: collection,
        method: method,
        id: item.id
      }
      });
  }

}
