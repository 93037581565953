<app-navbar [currentPage]="'Management'"></app-navbar>

<div class="content">
    <div class="container-fluid">
        <div class="btn-group m-3">
            <button class="btn btn btn-outline-primary float-start" (click)="togglePreview()" id="bt ">Toggle Phase 1 add / preview form</button>
        </div>

        <div class="card" *ngIf="isPhase1">
            <div class="card-head">
                <h3>Add Phase 1 Existing Regulations</h3>
            </div>
            <div class="card-body form-container">
                <form [formGroup]="phase1form">

                    <div class="form-group">
                        <label class="form-input-header fw-bold" for="short_title">Title *</label>
                        <input type="text" class="form-control" formControlName="short_title" />
                    </div>
                    <div class="form-group  fw-bold">
                        <label class="form-input-header" for="subject_full_title">Subject *</label>
                        <input type="text" class="form-control" formControlName="subject_full_title" />
                    </div>
                    <div class="form-group  fw-bold">
                        <label class="form-input-header" for="doc_date">Issue Date *</label>
                        <input type="date" class="form-control" formControlName="doc_date" />
                    </div>
                    <div class="form-group  fw-bold">
                        <label class="form-input-header" for="file_file_name">Document File *</label>
                        <input type="file" class="form-control" (change)="saveFileInformation($event)" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="folder_name_id">Folder Number ID</label>
                        <input type="text" class="form-control" formControlName="folder_name_id" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="issuance_no">Issuance Number</label>
                        <input type="text" class="form-control" formControlName="issuance_no" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="issuance_type">Agency Section</label>
                        <input type="text" class="form-control" formControlName="issuance_type" />
                    </div>
                    <div class="form-group" *ngIf="phase1form.invalid">
                        <p class="fst-italic">* Required fields - must be filled in order to Submit the form</p>

                    </div>

                    <button class="btn btn btn-primary m-3 float-end" (click)="saveAndUpload()" id="bt " [disabled]="phase1form.invalid || loading">
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span> Submit</button>
                </form>
            </div>
        </div>

    </div>
</div>