import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-pbris-account-management',
  templateUrl: './pbris-account-management.component.html',
  styleUrls: ['./pbris-account-management.component.scss']
})
export class PbrisAccountManagementComponent implements OnInit {
  @Input() data_catch: any;

  constructor(public auth: AngularFireAuth,  private afs: AngularFirestore) { }

  ngOnInit(): void {
  }

}
