import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-artemis-announcements-page',
  templateUrl: './artemis-announcements-page.component.html',
  styleUrls: ['./artemis-announcements-page.component.scss']
})
export class ArtemisAnnouncementsPageComponent implements OnInit {

  style_theme: any = 'theme-default'; //current theme

  list_of_announcements: any;
  list_of_tags: any;
  list_of_categories: any;

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.list_of_announcements = [];
    this.getFirestoreJSON();
    //this.getFilteredFirestoreJSON(['general_announcement']);

    this.list_of_categories = [];
    this.list_of_categories.push("General Announcement");
    this.list_of_categories.push("Maintenance");
    this.list_of_categories.push("Advisory");


    this.setTheme();
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }


  async getFirestoreJSON(){
    this.afs.collection(`Announcements`, filter => filter.where('tag', 'in', ['common', 'artemis']).orderBy('date', 'desc')).snapshotChanges().subscribe(
      (data: any) => {
        this.list_of_announcements = []
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.list_of_announcements.includes(item) === false){
              this.list_of_announcements.push(item);
            }     
          });
          
      });
  }


  async getFilteredFirestoreJSON(categories?: any){
    let baseRef: any = this.afs.firestore.collection(`Announcements`);
    baseRef = baseRef.where('tag', 'in', ['common', 'artemis']);

    if(categories) {
      baseRef = baseRef.where('category', 'in', categories);
    }

    baseRef = baseRef.orderBy('date', 'desc');

    baseRef.snapshotChanges().subscribe(
      (data: any) => {
        this.list_of_announcements = []
          data.forEach((info: any) => {
            
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.list_of_announcements.includes(item) === false){
              this.list_of_announcements.push(item);
            }     
          });
          
      });
  }

}
