<ng-container *ngIf="show_form">
    <form [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
          </button>
                <h4 class="modal-title w-100" id="myModalLabel">{{ title }}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-label" for="section">Section</label>
                    <input type="text" class="form-control" id="section" aria-describedby="section" formControlName="section" ng-required='true'>
                </div>
                <div class="form-group">
                    <label class="form-label" for="sector_name">Name of Sector</label>
                    <input type="text" class="form-control" id="sector_name" aria-describedby="sector_name" formControlName="sector_name" ng-required='true'>
                </div>
                <div class="form-group">
                    <label class="form-label" for="sector_def">Sector Definition</label>
                    <textarea class="form-control" id="sector_def" aria-describedby="Sector Definition" formControlName="sector_def"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="reset" class="btn btn-light">Reset</button>
                    <button *ngIf="method == 'create'" (click)="create()" class="btn btn-dark m-1" [hidden]="!form.valid">Create</button>
                    <button *ngIf="method == 'update'" (click)="update()" class="btn btn-dark m-1" [hidden]="!form.valid">Update</button>
                </div>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="del_confirm">
    <div class="modal-content">
        <div class="modal-header">

            <h4 class="modal-title w-100" id="myModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body">
            <p>Are you sure you want to delete it? Once deleted, it cannot be undone.</p>
        </div>
        <div class="modal-footer">
            <div class="btn-group">
                <button (click)="modalRef.hide()" class="btn btn-dark m-1">Cancel</button>
                <button (click)="delete()" class="btn btn-danger m-1">Delete</button>
            </div>
        </div>
    </div>
</ng-container>