import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-pbris-account-ticket-list',
  templateUrl: './pbris-account-ticket-list.component.html',
  styleUrls: ['./pbris-account-ticket-list.component.scss']
})
export class PbrisAccountTicketListComponent implements OnInit {
  tickets: any = [];
  loader: boolean = false;

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.loadTickets()
  }

  async loadTickets() {
    this.afs.collection('Tickets', filter => filter.orderBy('nature')).snapshotChanges().subscribe((data: any) => 
    {
      this.tickets = []
      data.forEach((results: any) => {
        let item: any = results.payload.doc.data();
        item.id = results.payload.doc.id;

        this.tickets.push(item);
        })
      
      });
    
  } 
}
