import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RegulationViewProposedArtaRemarks } from '../../regulation-view-proposed-arta-remarks-modal/regulation-view-proposed-arta-remarks-modal.component';


@Component({
  selector: 'app-pis-assessment-table',
  templateUrl: './pis-assessment-table.component.html',
  styleUrls: ['./pis-assessment-table.component.scss']
})
export class PisAssessmentTableComponent implements OnInit {
  @Input() assessmentSection: any;
  @Input() assessmentSectionTitle: any;
  @Input() actionButtonAllowed: boolean;


  displayedColumns: string[] = ['assessment', 'remarks'];
  assessment_choices: any = {
    "PIA": [
      "Sufficient",
      "Needs Additional Information",
      "Insufficient",
    ],
    "SIG": [
      "Major",
      "Minor",
      "Exempted",
      "Emergency",
    ],
    // Multiple
    "LENS": [
      "Competition",
      "Environment",
      "Gender and Social Inclusion",
      "Disaster Risk",
    ],
  }
  pia_choices: string[] = ['assessment', 'remarks'];

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  checkRemarks(control:UntypedFormGroup){
    const dialogRef = this.dialog.open(RegulationViewProposedArtaRemarks,{
        disableClose: true,
        height: '80%',
        width: '80%',
        data: {
          section: this.assessmentSectionTitle,
          previous_remarks: control.value.previous_remarks || [],
          remarks: control.value.remarks,
          action: this.actionButtonAllowed
        },
      }
    );

    dialogRef.afterClosed().subscribe({
      next:(result) => {
        if(this.actionButtonAllowed) control.patchValue({remarks:result.remarks});
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }
}
