
<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="!isLoaded">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="isLoaded">
    <!-- MAIN DETAILS START -->
    <div class="row w-100">
        <div class="col-lg-9 agency-details-left">
            <div class="row justify-content-between" *ngFor="let princple of pinciple_list">
                  <div class="profileHeader w-100">
                        <h3>{{princple.name}}</h3>
                  </div>
                  <div>
                    <p>{{agency_details.agencyInfo[princple.value]}}</p>
                  </div>
            </div>
        </div>
        
        <div class="col-lg-3 align-items-center">
            <div class="row">
                <div class="agency-logo-right w-100">
                    <img class='card' styles="position:relative" width="100%" [src]="agency_details.agencyDetails.logoURL">
                </div>
            </div>
            
            <div class="row">
                <div class="agency-details-right relative hover-parent width-layout-reverse">
                    <h4>Contact:</h4>
                    <ng-container *ngIf="agency_details.contact_details && agency_details.contact_details.length > 0">
                        <div class="row" *ngFor="let contact of agency_details.contact_details ">
                            <div class="col-lg-2 p-2 centering">
                               <stron><i class="fas {{contact.icon}}"></i></stron>
                            </div>
                            <div class="col-lg-10 p-2">
                                <span>{{agency_details.contact_details[contact.value]}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>    
    <!-- MAIN DETAILS END -->
    <!-- SUB DETAILS START -->
    <!-- <div class="row my-4">
                                Tabs navs
        <div style="padding:1rem;">
            <ul class="nav nav-tabs mb-3" id="ex1" role="tablist" style="border-bottom: 2px solid #eceef1;">
                <li class="nav-item" role="presentation">
                <a
                    class="nav-link active"
                    id="ex1-tab-1"
                    data-mdb-toggle="tab"
                    href="#ex1-tabs-1"
                    role="tab"
                    aria-controls="ex1-tabs-1"
                    aria-selected="true"
                    >View Document</a
                >
                </li>                
            
            </ul>
        </div>
                                Tabs navs
                                
                                Tabs content
        <div class="tab-content" id="ex1-content">
            <div class="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tabs-1">
                <ng-container>

                </ng-container>
                <div class="row my-3">
                    <a class="btn btn-lg btn-primary float-end">
                        Download Document
                    </a>
                </div>        
                <div class="row my-3 border border-dark p-3 px-0">
                    <h1>COMING SOON</h1>
                </div>
            </div>            
        </div>
                                Tabs content
    </div> -->
    <!-- SUB DETAILS END -->
</div>