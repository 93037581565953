import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GovernmentAccountSubtype, WorklistStatusPBRISRegulation } from 'src/app/entities/worklist';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { PBRISEmailer } from 'src/app/service/pbris-emailer.service';

@Component({
  selector: 'app-pbris-upload-existing-complete-regulations',
  templateUrl: './pbris-upload-existing-complete-regulations.component.html',
  styleUrls: ['./pbris-upload-existing-complete-regulations.component.scss']
})
export class PbrisUploadExistingCompleteRegulationsComponent implements OnInit {
  loading: boolean = false;
  isPhase1: boolean = true;
  // phase 1 - sample form
  phase1form = new UntypedFormGroup({ //date
    doc_date: new UntypedFormControl('', Validators.required),
    file_file_name: new UntypedFormControl('', Validators.required),
    firebase_folder: new UntypedFormControl('regulations20210505', Validators.required),
    folder_name_id: new UntypedFormControl(''),
    issuance_no: new UntypedFormControl(''),
    issuance_type: new UntypedFormControl(''),
    subject_full_title: new UntypedFormControl('', Validators.required),
    title: new UntypedFormControl('', Validators.required),
  });
  phase_01_filepath: any;

  //user info
  currenUser: any;


  constructor(private afs: AngularFirestore, private store: AngularFireStorage, private router: Router, private route: ActivatedRoute, public auth: AngularFireAuth,private nE: NotificationsEmailer, private pEmail: PBRISEmailer) { }

  ngOnInit(): void {
    this.auth.user.subscribe(data => {
      this.afs.collection('Users').doc(data?.uid).snapshotChanges()
        .subscribe({
          next: (res) => {
            let user: any = res.payload.data()
            this.currenUser = user
            console.log('user: ', user)
          }
        })
    });
  }

  togglePreview() {
    if (this.isPhase1) {
      this.isPhase1 = false;
    }
    else {
      this.isPhase1 = true;
    }

  }


  saveFileInformation(event: any) {
    this.phase_01_filepath = event.target.files[0];
    if (this.phase_01_filepath) {
      this.phase1form.get("file_file_name")?.setValue(this.phase_01_filepath.name);
    }
  }

  async saveAndUpload() {
    this.loading = true;
    let documentID: any;
    //
    let uploadProgress!: Observable<number | undefined>;

    if (this.phase_01_filepath) {
      const destinationPath = `${this.phase1form.value.firebase_folder}/${this.phase_01_filepath.name}`; //alert(Date.now().toString());
      const destinationRef = this.store.ref(destinationPath);
      const task = this.store.upload(destinationPath, this.phase_01_filepath);

      uploadProgress = task.percentageChanges();
    }

    //email
    const userAgency = this.currenUser.government.agency;
    const agencyVerifiers: any = await this.pEmail.getEmails(userAgency, GovernmentAccountSubtype.AGENCY_VER);
    const email = [this.currenUser.credentials.email];
    var data: any = {};
    data.regulationTitle = this.phase1form.controls.subject_full_title;

    console.log(agencyVerifiers);
    this.pEmail.sendEmailNotification(agencyVerifiers, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_VERIFIER_RECIEVES_REGULATION_FOR_VERIFICATION, data);
    this.pEmail.sendEmailNotification(email, EmailAppCode.PBRIS, EmailTemplateCode.AGENCY_ENCODER_FORWARD_REGULATION_TO_VERIFIER, data);


    this.afs.collection(`PBRIS 1 Regulations`).add(this.phase1form.value).then((docRef: any) => {
      alert("create successful");
      documentID = docRef.id;

      this.router.navigate(['/pbris/regulations/view/' + documentID]);
      this.loading = false
    }).catch(error => {
      this.loading = false
      alert(error.message)
    });

  }




}
