import { data } from 'jquery';
import { LoginPbrisComponent } from './../../pbris/login-pbris/login-pbris.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, QueryList, Renderer2, ViewChildren ,ViewChild} from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { finalize, take, tap, first } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import {
  CharterStatusCitizensCharter,
  CitizensCharterCommentSectionFlag,
  CitizensCharterCommentStatus,
  EffectivityCitizensCharter,
  GovernmentAccountSubtype,
  RemarkLevelCitizensCharter,
  RemarkLevelRoleMappingCitizensCharter,
  RemarkResolvedStatusCitizensCharter,
  ServiceAssessmentCitizensCharter,
  WorklistActionFlagCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ArtemisServiceInformationPageConfirmSubmitModalComponent } from './artemis-service-information-page-confirm-submit-modal/artemis-service-information-page-confirm-submit-modal.component';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// const jsreport = require('@jsreport/jsreport-browser-client-dist'); // works but console a type error, Cannot read properties of undefined (reading 'then')
// const jsreport = require('@jsreport/nodejs-client');
// import { environment } from 'src/environments/environment'; // get cloud run url
import { Timestamp } from 'firebase/firestore';
// import { off } from 'process';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { DocumentToJSONToTextFileEmailer } from 'src/app/service/documentToJSONToTextFile.service';
import { RemarkService } from 'src/app/service/remark.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { v4 as uuidv4 } from 'uuid';
import { A } from '@angular/cdk/keycodes';
import { add, intlFormat } from 'date-fns';
import { Console } from 'console';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { ArtemisCommentCreateComponent } from '../artemis-comment-page/artemis-comment-create/artemis-comment-create.component';
import { ArtemisServiceRemarkComponent } from './artemis-service-remark/artemis-service-remark.component';
import { MatSidenav } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { EmailerService } from 'src/app/service/emailer.service';
import { generateArtemisReportService } from 'src/app/service/generateArtemisReport.service';
enum EntityBuilderIndex {
  REMARK_SERVICE = 1,
  REMARK_EODB_TAG = 2,
  REMARK_CLIENT_STEP = 3,
  REMARK_AGENCY_ACTION = 4,
  REMARK_REPLY = 5,
}

@Component({
  selector: 'app-artemis-service-information-page',
  templateUrl: './artemis-service-information-page.component.html',
  styleUrls: ['./artemis-service-information-page.component.scss'],
})
export class ArtemisServiceInformationPageComponent implements OnInit {
  /**
   * levels of access
  */
  @ViewChild('srvcInfo') srvcInfo: MatSidenav;
  @ViewChild('rmrksCont') rmrksCont: MatSidenav;
  @ViewChildren(ArtemisServiceRemarkComponent) children : QueryList<ArtemisServiceRemarkComponent>;


  

  isTextAreaEmpty(textAreaId: string): boolean {
    const textAreaElement = document.getElementById(textAreaId) as HTMLTextAreaElement;

    if (textAreaElement) {
      // Trim the value to remove leading/trailing spaces before checking if it's empty.
      return textAreaElement.value.trim().length === 0;
    }

    // If the element with the given ID doesn't exist, consider it as empty.
    return true;
  }

  isOwner: boolean = false;
  isAdmin: boolean = false;
  isGuest: boolean = false;
  ismaxScreen: boolean = true;
  isValidService: boolean = true;
  // Assume true then render false
  // Currently the only users so far that can view Worklist items they can not act upon are:
  // Agency Encoder, all ARTA-level users
  actionButtonAllowed: boolean = true;
  allAgencies:any = []
  loader: boolean = false;

  // Certain user types are now only allowed to act on worklists of a certain status
  // This is because these user types can now access worklists that were previously filtered out because they had no authority
  private worklistAllowedActionButtonFilters = new Map<string, string[]>([
    [
      GovernmentAccountSubtype.AGENCY_ENC,
      [
        WorklistStatusCitizensCharter.DRAFT,
        WorklistStatusCitizensCharter.FOR_REVISION,
        WorklistStatusCitizensCharter.FOR_MODIFICATION,
      ],
    ],
    [
      GovernmentAccountSubtype.AGENCY_VER,
      [
        WorklistStatusCitizensCharter.FOR_VERIFICATION,
        WorklistStatusCitizensCharter.RESUBMITTED,
        WorklistStatusCitizensCharter.VERIFIED,
      ]
    ],
    [
      GovernmentAccountSubtype.AGENCY_DIR,
      [
        WorklistStatusCitizensCharter.FOR_APPROVAL,
        WorklistStatusCitizensCharter.RESUBMITTED,
        WorklistStatusCitizensCharter.APPROVED,
        WorklistStatusCitizensCharter.DISAPPROVED,
      ]
    ],
    [
      GovernmentAccountSubtype.ARTA_CMEO_PO,
      [
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW,
        // For overturning of assessment
        // Shows remark input
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED
      ],
    ],
    [
      GovernmentAccountSubtype.ARTA_CMEO_DC,
      [
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC,
        // Currently unnecessary, and it shows remark input
        // WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED
      ],
    ],
    [
      GovernmentAccountSubtype.ARTA_CMEO_DIR,
      [
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR,
        // Currently unnecessary, and it shows remark input
        // WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED
      ],
    ],
  ]);

  style_theme: any = 'theme-default'; //current theme

  userType: any = sessionStorage.getItem('user_type');
  currUser:any = sessionStorage.getItem('account_subtype');

  /** */
  isSidebarVisible: boolean = true;

  /** page elements */
  pageinfo: any;

  enum_service_status = WorklistStatusCitizensCharter;
  enum_action_flag = WorklistActionFlagCitizensCharter;
  enum_comment_section_flag = CitizensCharterCommentSectionFlag;
  enum_effectivity = EffectivityCitizensCharter;
  enum_assessment = ServiceAssessmentCitizensCharter;
  enum_remark_resolved_status = RemarkResolvedStatusCitizensCharter;
  enum_account_subtype = GovernmentAccountSubtype;
  service_status: any;
  worklist_effectivity: any;

  service_id: any;

  has_unresolved_comments: boolean = false;
  // If returned for revision is not yet connected to a charter, it can be deleted
  has_charter: boolean = false;

  private worklist_id: string = '';

  currentAgency: any;
  currentService: any;
  agencyName: any = [];
  services: any;
  agencyId: string = "";


  /** arrays */

  // Previously a flag for open/close remarks based on a button trigger, it will be repurposed for when a user can input remarks
  open_remarks = false;

  remarks_observable!: Observable<any[]>;
  private remark_id!: string;

  private ServiceCollection = 'Services Phase 02';

  private compliantFlag: number = -1;

  requirementTabs :Array<any> = [];
  // requirementTabs = [{title:'Standard',current:true}]

  private assessmentValues = new Map<number, string>([
    [0, ServiceAssessmentCitizensCharter.COMPLIANT],
    [1, ServiceAssessmentCitizensCharter.NON_COMPLIANT],
  ]);

  remarks_form = new UntypedFormGroup({
    service_remark_obj: this.buildNewEntry(EntityBuilderIndex.REMARK_SERVICE),
    service_eodb_tags_remark_obj: this.buildNewEntry(EntityBuilderIndex.REMARK_EODB_TAG),
    service_steps: new UntypedFormArray([]),
  });

  remarks_thread_array = {
    service_remark: new Array(),
    service_eodb_tags_remark: new Array(),
    // Consider the use case where remarks on client steps/agency actions are orphaned due to deleting steps and actions
    // This might require proper UUIDs on client steps/agency actions
    service_steps: new Map(),
    agency_actions: new Map(),
  };

  remark_status = CitizensCharterCommentStatus
  userId :any 
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    public dialog: MatDialog,
    public auth: AngularFireAuth,
    private http: HttpClient,
    public worklistService: WorklistService,
    private snackBarService: SnackbarNotifService,
    private location: Location,
    private remarkService: RemarkService,
    private breakpointObserver: BreakpointObserver,
    public _service: GovServiceService,
    private emailer: EmailerService,
    private gARService: generateArtemisReportService,
    ) {
    this.loader = true;
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
          // hide stuff  
          this.ismaxScreen = false;      
      } else {
          // show stuff
          this.ismaxScreen = true;      

        }
    });

    this._service.showRemarksEvent.subscribe((showRemarks:any)=> {
          this.showRemarks = showRemarks;
    })

    this._service.someoneistyping_remark.subscribe((value:boolean)=>{
        this.someoneistyping =value
    })

    // this.loadPageInformation(this.route.snapshot.params.id);
  }

  someoneistyping:boolean =false

async getUser() {
  const test = await this.auth.currentUser;

 if(test?.uid){
  this.userId = test.uid
 }
 }

  showRemarks: boolean   =false 

  private loadServices(service_id:any) {
  this.afs.collection('Services Phase 02')
    .doc(service_id).snapshotChanges()
      // .pipe(first())
      .subscribe(info=>{
        if(info.payload.exists){
          let service:any = info.payload.data()
          service.id = info.payload.id
          this.agencyId = service.fromAgencyId
          if(this.agencyId == sessionStorage.getItem("agency_id")){
            this.isOwner=true
          }
          this._service.maxHours = service.serviceDetails?.hour_per_day || 8
          this.loadRemarks();

          const formattedService:any =  this._service.preProcessServiceData(service)

          this.loadCharterInformation(formattedService)

          
        }
      })
    
    

  }

  remarks_thread:any = []

  service_has_remarks: boolean =false;

  get getResolved(): Array<any>{

    if(this.remarks_thread.length <= 0) return []
    if(this.currUser === GovernmentAccountSubtype.AGENCY_ENC) return this.remarks_thread.filter((filter:any)=> filter.status == RemarkResolvedStatusCitizensCharter.RESOLVED)
    else return this.remarks_thread.filter((filter:any)=> filter.status == RemarkResolvedStatusCitizensCharter.RESOLVED && (filter.user.user_id == this.userId || filter.level.indexOf("ARTA") !== -1))
  }

  get getUnresolve(): Array<any>{
    if(this.remarks_thread.length <= 0) return []
    if(this.currUser === GovernmentAccountSubtype.AGENCY_ENC)  return this.remarks_thread.filter((filter:any)=> filter.status ==  RemarkResolvedStatusCitizensCharter.UNRESOLVED )
    else return this.remarks_thread.filter((filter:any)=> filter.status ==  RemarkResolvedStatusCitizensCharter.UNRESOLVED && (filter.user.user_id == this.userId || filter.level.indexOf("ARTA") !== -1) )
  }

 get allowedToRemarks(){

  const user_type = sessionStorage.getItem('user_type') || ''

  if(user_type){
    if(user_type === 'arta' || (user_type === 'agency' && this.isOwner)) return true
    else return false
  }else{
    return false
  }
 }

  loadRemarks(){
    this.afs.collection("Service Remarks",
    filter => 
      filter
          .where('service_id','==',this.service_id)
    )
    .snapshotChanges()
    .pipe(tap( async (data:any)=>{

      this.service_has_remarks =false;
      this.remarks_thread = []

      const test = await this.auth.currentUser;

      data.forEach((info:any)=>{


          const remark = info.payload.doc.data();
          remark._id = info.payload.doc.id;
          this.remarks_thread.push(remark)
      
          if(remark?.status === RemarkResolvedStatusCitizensCharter.UNRESOLVED && remark.user.user_id === test?.uid)
          { this.service_has_remarks =true; }
         

      })

    })).subscribe()

    if (
      this.service_status !== WorklistStatusCitizensCharter.DRAFT &&
      this.service_status !== WorklistStatusCitizensCharter.FOR_REVISION &&
      this.service_status !== WorklistStatusCitizensCharter.POSTED &&
      (sessionStorage.getItem('account_subtype') || '') !== GovernmentAccountSubtype.AGENCY_ENC &&
      this.actionButtonAllowed
    ) {
      // open_remarks will act as the flag for then service_remark_obj and service_eodb_tags_remark_obj FormGroups
      this.open_remarks = true;
    }
  }

 public_comments:any = []
  ngOnInit(): void {
  
    // console.log('session',sessionStorage.getItem('artemisInboxActiveMode'))
    this.service_id = this.route.snapshot.params.id;
    //this.loadPageInformation(this.service_id);

    this.getUser();
    this.loadServices(this.service_id)

    this.setTheme();
    

    this.incrementVisitCount(this.service_id);
    this.afs
      .collection(`Comment Artemis`, (filter) =>
        filter
          .where('type.type_id', '==', this.service_id)
      )
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          this.public_comments = []

          data.forEach((info:any)=>{
              let comment:any = info.payload.doc.data();
              comment.id = info.payload.doc.id;
              if(comment.status == CitizensCharterCommentStatus.UNRESOLVED) this.has_unresolved_comments =true;
              this.public_comments.push(comment)
          })

        })
      )
      .subscribe();

  
    this.isGuest =
      !sessionStorage.getItem('user_type') ||
      sessionStorage.getItem('user_type') == 'default';
    this.actionButtonAllowed = !this.isGuest;
    if (sessionStorage.getItem('arta_admin') == 'true') {
      this.isAdmin = true;
    }

    //console.log("Childrenb",this.children)

  //console.log(this.remarks_thread_array)
  }

  loadCharterInformation(service:any){

    if (!this.isGuest) {
      this.route.queryParams.subscribe((params: any) => {
        this.worklist_id = params.worklistId;
        // get worklist status
        if (this.worklist_id != null) {
          // For data loaded from the CC document (e.g. Reason For Disapproval)
          const loadCharter = this.afs
            .collection(`citizens-charter-phase-02`)
            .doc(service.citizens_charter).snapshotChanges();
          const loadWorklist = this.afs
            .collection(`Worklist-Citizens Charter`)
            .doc(this.worklist_id).snapshotChanges();

          combineLatest([loadCharter, loadWorklist])
            .pipe(
              tap(([charterData, worklistData] : any) => {
                let charter_info_json = charterData.payload.data();
                // null handling because there will be services with no CC yet (e.g. drafts, not yet submitted to agency head)
                if(charter_info_json) {
                  this.has_charter = true;
                  //console.log('stat',charter_info_json.status);
                  if(charter_info_json.status == CharterStatusCitizensCharter.FOR_REVISION){
                    service.reasonForDisapproval = 
                    `${charter_info_json.remark_disapproval_agency_head ? `Agency Head: ${charter_info_json.remark_disapproval_agency_head}\n\n` : ''}` +
                    `${charter_info_json.remark_disapproval_arta_dir ? `ARTA CMEO DIR: ${charter_info_json.remark_disapproval_arta_dir}` : ''}`
                  }
                  else{
                    service.reasonForDisapproval = 
                    `${charter_info_json.remark_disapproval_arta_dir ? `ARTA CMEO DIR: ${charter_info_json.remark_disapproval_arta_dir}` : ''}`
                  }
                }
                else {
                  this.has_charter = false;
                }

                let worklist_info_json = worklistData.payload.data();
                
                this.service_status = worklist_info_json.status;
                this.worklist_effectivity = worklist_info_json.effectivity;
                service.worklistId = worklistData.payload.id
                service.status = worklist_info_json.status;
                service.date = worklist_info_json.dateGenerated.toDate();
                service.assessment = worklist_info_json.assessment;
                service.citizensCharter = worklist_info_json.citizensCharter;
                service.reasonForDelisting = worklist_info_json.reasonForDelisting;
                service.verifierCheckReportURL = worklist_info_json.reportURL
                if(worklist_info_json?.docOwner){
                  service.docOwner = worklist_info_json.docOwner
                }
                if(worklist_info_json?.verifier){
                  service.verifier = worklist_info_json.verifier
                }
                for(let [k, v] of this.assessmentValues.entries()) {
                  if(v === worklist_info_json.assessment) {
                    this.compliantFlag = k;
                    break;
                  }
                }
                this.loader = false;
                this.services = service;
                //console.log("service",this.services)

                const worklistFilterArr =
                  this.worklistAllowedActionButtonFilters.get(
                    sessionStorage.getItem('account_subtype') || ''
                  ) || [];
                // Let actionButtonAllowed be true if user doesn't have any "allowed" action buttons
                // Currently: Agency Verifier, Agency Head
                this.actionButtonAllowed =
                  worklistFilterArr.length == 0 ||
                  worklistFilterArr.indexOf(this.service_status) > -1;
                if(this.services.validityMap.clientSteps){
                  if(this.services.validityMap.requirements){
                      if(this.services.validityMap.serviceForm){
                        if(this.services.validityMap.overall){
                          this.isValidService = true;
                            if(this.services.validityMap.eodbTags){
                              this.isValidService = true;
                            }else{
                              this.isValidService = false;
                            }
                        }else{
                          this.isValidService = false;
                        }
                      }else{
                        this.isValidService = false;
                      }
                  }else{
                    this.isValidService = false;
                  }

                }else {
                  this.isValidService = false;
                }



                this.loadRemarksInformation(this.worklist_id);

              

                 //console.log('remarks',this.loadRemarksInformation(this.worklist_id))
              }),
              take(1)
            )
            .subscribe();

        } else {
          this.loader = false;
          this.services = service;
          this.service_status = service.posted_status
         // console.log("service",this.services)
        }
      });
    }else{
      this.loader = false;
      this.service_status = service.posted_status
      this.services = service;
    }
  }

  get service_steps_val() {
    return this.remarks_form.get('service_steps') as UntypedFormArray;
  }

  has_unresolve_remark(){
    for(var remark in this.remarks_observable){

    }
  }


  get has_remarks() {
    
    for(let [k,v] of Object.entries(this.remarks_form.controls)) {
      if(k === 'service_steps') {
        // Handling for service steps: check if the step has a remark, otherwise check if the agency actions under the step have remarks
        for(let step of v.value) {
          if(step.step_remark !== '') return true;
          else {
            for(let action of step.agency_actions) {
              if(action.action_remark !== '') return true;
            }
          }
        }
      }
      else if(k === 'service_remark_obj') {
        if (v.value?.service_remark !== '') return true;
      }
      else if(k === 'service_eodb_tags_remark_obj') {
        if (v.value?.service_eodb_tags_remark !== '') return true;
      }
    }

    return false;
  }


  get has_remarks_thread_service() {
    return this.remarks_thread_array.service_remark.length > 0;
  }

  get has_remarks_thread_eodb() {
    return this.remarks_thread_array.service_eodb_tags_remark.length > 0;
  }

  get has_remarks_thread_service_steps() {
    return this.remarks_thread_array.service_steps.size > 0;
  }

  get has_remarks_thread_agency_actions() {
    return this.remarks_thread_array.agency_actions.size > 0;
  }

  get has_unresolved_remarks() {
    // If you can resolve a remark, but you leave it unresolved, then that is truly an unresolved remark (e.g. handling of verifying worklists with unresolved *Agency Head* remarks)
    // Assumes that there shouldn't be any unresolved Verifier remarks once the worklist reaches the Agency Head
    for(let x of this.remarks_thread_array.service_remark) {
      
      if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED && x.allow_resolve) {
        return true;
      }
    }

    for(let x of this.remarks_thread_array.service_eodb_tags_remark) {
      if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED && x.allow_resolve) {
        return true;
      }
    }

    // Synchronous for loop instead of forEach
    for(let v of this.remarks_thread_array.service_steps.values()) {
      for(let x of v) {
        if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED && x.allow_resolve) {
          return true;
        }
      }
    };

    for(let v of this.remarks_thread_array.service_steps.values()) {
      for(let x of v) {
        if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED && x.allow_resolve) {
          return true;
        }
      }
    };

    return false;
  }





  /*get status_unresolved_remarks() {
    // check unresolved remarks regardless of who had given the remark)
    for(let x of this.remarks_thread_array.service_remark) {
      
      if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
        return true;
      }
    }

    for(let x of this.remarks_thread_array.service_eodb_tags_remark) {
      if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
        return true;
      }
    }

    // Synchronous for loop instead of forEach
    for(let v of this.remarks_thread_array.service_steps.values()) {
      for(let x of v) {
        if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
          return true;
        }
      }
    };

    for(let v of this.remarks_thread_array.service_steps.values()) {
      for(let x of v) {
        if(x.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
          return true;
        }
      }
    };

    return false;
  }
*/

 /*get remarks_current_status() {
    // First, call the `has_remarks` function and store the result in a variable called `has_remarks_result`.
    let has_remarks_result = this.has_remarks;
    console.log('has remark?', this.has_remarks);
    // Then, call the `has_unresolved_remarks` function and store the result in a variable called `has_unresolved_remarks_result`.
    let has_unresolved_remarks_result = this.status_unresolved_remarks;
    console.log('has unresolved?',this.has_unresolved_remarks);
    // Finally, return the logical AND of `has_remarks_result` and `has_unresolved_remarks_result`.
    return has_remarks_result || has_unresolved_remarks_result;
  }*/


  getClientStepAt(i: number): UntypedFormGroup {
    
    return this.service_steps_val.at(i) as UntypedFormGroup;
    
  }

  getAgencyActionAt(i: number, j: number): UntypedFormGroup {
    if(!this.getClientStepAt(i)) return new UntypedFormGroup({});    
    let agencyActionsArr = this.getClientStepAt(i).get(
      'agency_actions'
    ) as UntypedFormArray;
    return agencyActionsArr.at(j) as UntypedFormGroup;
  }

  async setTheme() {
    switch (sessionStorage.getItem('user_type')) {
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  editService() {
    // Now service ID because CC will not be encoded until the Verifier level
    this.router.navigate(
      ['/artemis/charter/serviceeditor/' + this.service_id],
      { queryParams: { worklistId: this.worklist_id } }
    );
  }

  checkReportFile(){
    // console.log(this.services)
    // this.gARService.checkReport('verifierCheckServiceReport',(this.services.worklistId && this.services.fromAgencyId),this.services.worklistId,this.services.fromAgencyId,this.services.verifierCheckReportURL)
    // .then((returnData: any) =>{
    //   if(returnData?.openFile)
    //     window.open(this.services.verifierCheckReportURL)
    //   else if(returnData?.generateReport)
    //     this.generateReportFileWData()
    // })
    this.generateReportFileWData()
  }

  // generateReportFileWOData(){
  //   this.gARService.generateReportWOData('verifierCheckServiceReport',(this.services.worklistId && this.services.fromAgencyId),this.services.worklistId,this.services.fromAgencyId,this.service_id)
  //   .then((url)=>{
      // if(url){
      //   window.open(url)
      //   this.services.verifierCheckReportURL = url
      // }
  //   })
  // }

  generateReportFileWData(){
    this.gARService.generateReportWData('verifierCheckServiceReport',(this.services.worklistId && this.services.fromAgencyId),this.services.worklistId,this.services.fromAgencyId,this.services)
    .then((url: any)=>{
      if(url){
        window.open(url)
        this.services.verifierCheckReportURL = url
      }
    })
  }

  toggleSidebar() {
    if (this.isSidebarVisible) {
      this.isSidebarVisible = false;
    } else {
      this.isSidebarVisible = true;
    }
  }

  preprocessSubSituationalReq(subrequirements:any){
    let temp_sub_req = []

    for (var requirement of subrequirements){

      temp_sub_req.push({
        title: requirement.title,
        requirement: this.preProcessStandardReq(requirement.requirement)
      })

    }
    return temp_sub_req;
  }
  situational_requirements: any = []

  loadPageInformation(service_ids: any) {
    // console.log("loading service", service_ids)
    this.afs
      .collection('Services Phase 02')
      .doc(service_ids)
      .snapshotChanges()
      .pipe(first())
      .subscribe((info) => {
        if (info.payload.exists) {
          let service: any = info.payload.data();
          // console.log('service: ',service)
          // this.toTextFile.createTextFile(info.payload.id,service)
          service.id = info.payload.id;
          this.agencyId =service.fromAgencyId;

          service.standardRequirements = this.preProcessStandardReq(service.standardRequirements);
         // if(service.standardRequirements) service.standardRequirements = this.preProcessStandardReq(service.standardRequirements)
          //if(service.situationalRequirementsArray) service.situationalRequirementsArray = this.preProcessSituationalReq(service.situationalRequirementsArray);
          if(service.clientSteps) service.processedClientStep = this.processClientStep(service.clientSteps);

 // format situational and subsituational array
  const temp_sit = service.situationalRequirementsArray

  temp_sit.forEach((situation:any)=>{

    this.situational_requirements.push({
      title: situation.title,
      requirement: this.preProcessStandardReq(situation.requirement? situation.requirement : []),
      subRequirement: this.preprocessSubSituationalReq(situation.subRequirement? situation.subRequirement : []  )

    })
  })
          

          

          // console.log('service', service);
          if (!this.isGuest) {
            this.route.queryParams.subscribe((params: any) => {
              this.worklist_id = params.worklistId;
              // get worklist status
              if (this.worklist_id != null) {
                // For data loaded from the CC document (e.g. Reason For Disapproval)
                const loadCharter = this.afs
                  .collection(`citizens-charter-phase-02`)
                  .doc(service.citizens_charter).snapshotChanges();
                const loadWorklist = this.afs
                  .collection(`Worklist-Citizens Charter`)
                  .doc(this.worklist_id).snapshotChanges();

                combineLatest([loadCharter, loadWorklist])
                  .pipe(
                    tap(([charterData, worklistData] : any) => {
                      let charter_info_json = charterData.payload.data();
                      // null handling because there will be services with no CC yet (e.g. drafts, not yet submitted to agency head)
                      if(charter_info_json) {
                        this.has_charter = true;
                        //console.log('stat',charter_info_json.status);
                        if(charter_info_json.status == CharterStatusCitizensCharter.FOR_REVISION){
                          service.reasonForDisapproval = 
                          `${charter_info_json.remark_disapproval_agency_head ? `Agency Head: ${charter_info_json.remark_disapproval_agency_head}\n\n` : ''}` +
                          `${charter_info_json.remark_disapproval_arta_dir ? `ARTA CMEO DIR: ${charter_info_json.remark_disapproval_arta_dir}` : ''}`
                        }
                        else{
                          service.reasonForDisapproval = 
                          `${charter_info_json.remark_disapproval_arta_dir ? `ARTA CMEO DIR: ${charter_info_json.remark_disapproval_arta_dir}` : ''}`
                        }
                      }
                      else {
                        this.has_charter = false;
                      }

                      let worklist_info_json = worklistData.payload.data();
                      
                      this.service_status = worklist_info_json.status;
                      this.worklist_effectivity = worklist_info_json.effectivity;
                      service.worklistId = worklistData.payload.id
                      service.status = worklist_info_json.status;
                      service.date = worklist_info_json.dateGenerated.toDate();
                      service.assessment = worklist_info_json.assessment;
                      service.citizensCharter = worklist_info_json.citizensCharter;
                      service.reasonForDelisting = worklist_info_json.reasonForDelisting;
                      service.verifierCheckReportURL = worklist_info_json.reportURL
                      for(let [k, v] of this.assessmentValues.entries()) {
                        if(v === worklist_info_json.assessment) {
                          this.compliantFlag = k;
                          break;
                        }
                      }
                      this.loader = false;
                      this.services = service;
                    
                      //console.log("service",this.services)

                      const worklistFilterArr =
                        this.worklistAllowedActionButtonFilters.get(
                          sessionStorage.getItem('account_subtype') || ''
                        ) || [];
                      // Let actionButtonAllowed be true if user doesn't have any "allowed" action buttons
                      // Currently: Agency Verifier, Agency Head
                      this.actionButtonAllowed =
                        worklistFilterArr.length == 0 ||
                        worklistFilterArr.indexOf(this.service_status) > -1;
                      if(this.services.validityMap.clientSteps){
                        if(this.services.validityMap.requirements){
                            if(this.services.validityMap.serviceForm){
                              if(this.services.validityMap.overall){
                                this.isValidService = true;
                                  if(this.services.validityMap.eodbTags){
                                    this.isValidService = true;
                                  }else{
                                    this.isValidService = false;
                                  }
                              }else{
                                this.isValidService = false;
                              }
                            }else{
                              this.isValidService = false;
                            }
                        }else{
                          this.isValidService = false;
                        }

                      }else {
                        this.isValidService = false;
                      }



                      this.loadRemarksInformation(this.worklist_id);

                       //console.log('remarks',this.loadRemarksInformation(this.worklist_id))
                    }),
                    take(1)
                  )
                  .subscribe();

              } else {
                this.loader = false;
                this.services = service;
                this.service_status = service.posted_status
               // console.log("service",this.services)
              }
            });
          } else {
            this.loader = false;
            this.service_status = service.posted_status
            this.services = service;
            //console.log("service",this.services)
          
          }
          // console.log("service",this.services)
        }
      });
    // console.log("all services",this.services)
  }

  
  async loadAgency(agencyName: any) {
    //console.log('loading agency', agencyName);
    let tempArr: any = [];

    tempArr.push(await this.getAgency(agencyName, 'agencyDetails.agencyName'));
    tempArr.push(await this.getAgency(agencyName, 'name'));
   
    // console.log('agency tempArr', tempArr);
    Promise.all(tempArr).then((result: any[]) => {
      let nameArr: any = [];
      if (result && result.length > 0) {
        result.forEach((data: any[]) => {
          if (data && data.length > 0) {
            data.forEach((info: any) => {
              if (this.currentAgency == null) {
                this.currentAgency = info;
              } 
              nameArr.push(info);
            });
          }
        });
       // console.log('agencies ', nameArr);
      }
    });
  }
//services.serviceDetails



  getAgency(agencyName: any, find: any) {
    // console.log('checking ', find);
    return this.afs
      .collection('Agency')
      .ref.where(find, '==', agencyName)
      .get()
      .then((info) => {
        if (info.size > 0) {
          let nameArr: any[] = [];
          info.forEach((data) => {
            let agency: any = data.data();
            agency.id = data.id;
            nameArr.push(agency);
          });
          return nameArr;
        } else {
          return null;
        }
      });

    
      
  }


  private async loadRemarksInformation(worklist_id: string) {
    
    // Load all remarks, regardless of status, still sort by remark_date
    const test = await this.auth.currentUser;
    const user_position = sessionStorage.getItem('account_subtype') || '';
   
    /**
     * this.remarks_observable = this.afs
      .collection(`Remarks Artemis New`, (filter) =>
        filter
          .where('remark_worklist_id', '==', worklist_id)
          .where('remark_level', 'in', [RemarkLevelRoleMappingCitizensCharter[user_position], RemarkLevelCitizensCharter.LEVEL_RETURN])
          .orderBy('remark_date','asc')
      )
      .snapshotChanges()
     */


    this.remarks_observable = this.afs.collection(`Service Remarks`, (filter) => 
        filter
          .where('service_id','==',this.service_id)
          .where('level','==',[RemarkLevelRoleMappingCitizensCharter[user_position], RemarkLevelCitizensCharter.LEVEL_RETURN])
          .orderBy('date','desc')
        ).snapshotChanges()


    //this.loadRemarksThread();
   // this.setupClientStepsAgencyActionsRemarkInputs();
  }


/*
  preProcessStandardReq(standardRequirements:any){
    if(standardRequirements && standardRequirements.length > 0){
      standardRequirements.map((docItem:any)=>{
        switch(docItem.requirementType){
          case 'Documentary':{
            if(docItem.documentFormat && docItem.documentFormat.length > 0){
              docItem.documentString = ''
              docItem.documentFormat.map((formatItem:any,index:any)=>{
                // console.log('formatItem: ',formatItem)
                switch(formatItem.type){
                  case 'document':{
                    if(index > 0) docItem.documentString += ' '
                    switch(formatItem.value){
                      case 'Original Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Photo Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Electronic Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                    }
                    break;
                  }
                  case 'operator':{
                    switch(formatItem.value){
                      case 'Or':{
                        docItem.documentString += '<br/>'+formatItem.value+'<br/>'
                        break
                      }
                      default:{
                        if(index > 0) docItem.documentString += ' '
                        docItem.documentString += formatItem.value
                      }
                    }
                    break;
                  }
                  default:{
                    if(index > 0) docItem.documentString += ' '
                    docItem.documentString += formatItem.value
                  }
                }
              })
            }
            break;
          }
        }
      })
    }
    return standardRequirements
  }
*/
  
  private preProcessStandardReq(standardRequirements: any) {
    if(standardRequirements && standardRequirements.length > 0){
      standardRequirements.map((docItem:any)=>{
        switch(docItem.requirementType){
          case 'Documentary':{
            if(docItem.documentFormat && docItem.documentFormat.length > 0){
              docItem.documentString = ''
              docItem.documentFormat.map((formatItem:any,index:any)=>{
                // console.log('formatItem: ',formatItem)
                switch(formatItem.type){
                  case 'document':{
                    if(index > 0) docItem.documentString += ' '
                    switch(formatItem.value){
                      case 'Original Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Photo Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Electronic Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                    }
                    break;
                  }
                  case 'operator':{
                    switch(formatItem.value){
                      case 'Or':{
                        docItem.documentString += '<br/>'+formatItem.value+'<br/>'
                        break
                      }
                      default:{
                        if(index > 0) docItem.documentString += ' '
                        docItem.documentString += formatItem.value
                      }
                    }
                    break;
                  }
                  default:{
                    if(index > 0) docItem.documentString += ' '
                    docItem.documentString += formatItem.value
                  }
                }
              })
            }
            break;
          }
        }
        // if(docItem.requirementType == 'Documentary'){
        //   if(docItem.documentFormat && docItem.documentFormat.length > 0){
        //     docItem.documentString = ''
        //     docItem.documentFormat.map((formatItem:any,index:any)=>{
        //       // console.log('formatItem: ',formatItem)
        //       if(index > 0) docItem.documentString += ' '
        //       if(formatItem.type == 'document'){
        //         if(formatItem.value == 'Original Copy')
        //           docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                 
        //         else if(formatItem.value == 'Photo Copy')
        //           docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                
        //         else if(formatItem.value == 'Electronic Copy')
        //           docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                
        //       }else{
        //         docItem.documentString += formatItem.value
        //       }
              
        //     })
        //   }
        // }
      })
    }
    return standardRequirements
  }
/*
  private preProcessSituationalReq(situationalRequirements: any){
    if(situationalRequirements && situationalRequirements.length > 0){
      situationalRequirements.map((situationalRequirementsItem:any)=>{
        // console.log('situationalRequirementsItem: ', situationalRequirementsItem)
        if(situationalRequirementsItem && situationalRequirementsItem.situationalRequirements.length > 0){
          situationalRequirementsItem.situationalRequirements = this.preProcessStandardReq(situationalRequirementsItem.situationalRequirements);
        }
      })
    }

    return situationalRequirements
  }*/


  private preProcessSituationalReq(situationalRequirements: any){
    if(situationalRequirements && situationalRequirements.length > 0){
      situationalRequirements.map((situationalRequirementsItem:any)=>{
        // console.log('situationalRequirementsItem: ', situationalRequirementsItem)
        if(situationalRequirementsItem && situationalRequirementsItem.situationalRequirements.length > 0){
          situationalRequirementsItem.situationalRequirements = this.preProcessStandardReq(situationalRequirementsItem.situationalRequirements);
        }
        this.requirementTabs.push({
          title: situationalRequirementsItem.title,
          currentTab: this.requirementTabs.length > 0 ? false : true,
          data: situationalRequirementsItem.situationalRequirements
        })
      })
    }

    return situationalRequirements
  }

  processClientStep(clientSteps:any[]){
    let processedClientSteps:any = []
    if(clientSteps && clientSteps.length > 0){
      clientSteps.map((clientStep,index)=>{
        if(clientStep.agency_actions && clientStep.agency_actions.length > 0){
          clientStep.agency_actions.map((agencyAction:any,index2:number)=>{
            let clientStepRow = {
              csid:index2 == 0 ? 'cs' + (index+1): '',
              aaid:'aa'+(index+1)+'.'+(index2+1),
              clientStepName:index2 == 0 ? (index+1)+'. '+clientStep.info.title: '',
              agencyActions:(index+1)+'.'+(index2+1)+'. '+agencyAction.title,
              fees: index2 == 0 ? this.getClientStepFees(clientStep.info) : '',
              location: clientStep.info.location,
              processingTime:'',
              personResponsible:agencyAction.persons_responsible,
              isSituationalStep: clientStep.info.is_situational_step,
              situation: clientStep.info.is_situational_step ? clientStep.info.situation : '',
              // borderBottom: (index2 < (clientStep.agency_actions.length - 1)) ? false : true,
              // borderTop: (index2 > 0 ) ? false : true,
              rowSpan: (clientStep.agency_actions.length > 1) ? (index2 == 0) ? clientStep.agency_actions.length : 0 : 1 //(index == 0) ? (clientStep.agency_actions.length > 1) ? clientStep.agency_actions.length : 0 : 1
            }

            // check if AA L > 1
              // T: check index 0
                // T: AA L
                // F: 0
              // F: 1

            let processingTime = '';
            if(agencyAction.processing_time_days){
              const days = Number(agencyAction.processing_time_days);
              if(days && !isNaN(days) && days > 0) processingTime += agencyAction.processing_time_days+' day/s'
            }

            if(agencyAction.processing_time_hours){
              const hours = Number(agencyAction.processing_time_hours);
              if(hours && !isNaN(hours) && hours > 0) processingTime += (processingTime?', ':'')+agencyAction.processing_time_hours+' hour/s'
            }

            if(agencyAction.processing_time_minutes){
              const mins = Number(agencyAction.processing_time_minutes);
              if(mins && !isNaN(mins) && mins > 0) processingTime += (processingTime?', ':'')+agencyAction.processing_time_minutes+' minute/s'
            }
            clientStepRow.processingTime = processingTime
            processedClientSteps.push(clientStepRow)
          })
        }else{
          processedClientSteps.push({
            csid:'',
            aaid:'',
            clientStepName: clientStep.info.title,
            agencyActions:'',
            fees: this.getClientStepFees(clientStep.info),
            processingTime:'',
            personResponsible:'',
            borderBottom: true,
          })
        }
      })
      return processedClientSteps
      // for(let clientStep of clientSteps){
      //   let clientStep = {
      //     clientStepName:clientStep,
      //     agencyActions:'',
      //     fees:'',
      //     processingTime:'',
      //     personResponsible:''
      //   }

      // }
    }

    return processedClientSteps
  }

  getClientStepFees(clientStepData:any){
    let fees = ''
    if(clientStepData.standard_fees == true || clientStepData.list_of_possible_fees_or_formulas == true){
      if(clientStepData.standard_fees == true){
        fees += clientStepData.fees+' PHP'//return clientStepData.fees+' php'
        if(clientStepData.fees_arr && clientStepData.fees_arr.length > 0){
          fees += (fees ? '<br><br>' : '')+'Breakdown:';
          clientStepData.fees_arr.map((fee_data:any)=>{
            fees += (fees ? '<br>' : '')+fee_data.fee_type+':'
            if(fee_data.amount) fees += (fees ? ' ' : '')+fee_data.amount+' PHP'
          })
        }
      } //fees += clientStepData.fees+' php'//return clientStepData.fees+' php'
      else if(clientStepData.list_of_possible_fees_or_formulas == true && clientStepData.list_of_fees_arr && clientStepData.list_of_fees_arr.length > 0) {
        clientStepData.list_of_fees_arr.map((list_of_fees_data:any)=>{
          fees+=(fees ? '<br><br>' : '')+list_of_fees_data.fee_type+':'
          if(list_of_fees_data.list_fee_desc) fees+=(fees ? '<br>' : '')+list_of_fees_data.list_fee_desc
        })
      }//fees += (fees ? '<br>' : '')+clientStepData.list_of_fees_val//return clientStepData.list_of_fees_val

      return fees
    }
    // else if(clientStepData.is_situational_step) return clientStepData.fees+' php'
    return 'None'
  }


  async incrementVisitCount(id: any) {
    const pageRef = this.afs.collection(`Services Phase 02`).doc(id);

    let incVisit = 1;

    pageRef
      .update({
        visit_count: incVisit,
      })
      .then(() => {});
  }

  triggerDeleteWarning() {
    this.openWarningPrompt(
      {
        deleteWarning: true,
      }
    );
  }

  triggerFinalWarning() {
    this.openWarningPrompt(
      {
        finalWarning: true,
      }
    );
  }

  openWarningPrompt(data: any) {
    const dialogRef = this.dialog.open(
      ArtemisServiceInformationPageConfirmSubmitModalComponent,
      {
        disableClose: true,
        height: '480px',
        width: '640px',
        data: data,
      }
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.submitFlag && result.actionFlag) {
        this.submit(result.actionFlag);
      } else {
        // console.log('cancelled!');
      }
    });
  }

  // Ongoing certification retrieval for deleting
  private loadOngoingCertificationFunc() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('citizens_charter', '==', '');

    return this.afs
      .collection(`services-certification-phase-02`, inboxFilter)
      .snapshotChanges();
  }
  
  private async hardDeleteService() {
    //console.log("test delete");

    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    const checkService = this.afs.firestore
      .collection(`Services Phase 02`)
      .doc(this.service_id);
    
    const checkWorklist = this.afs.firestore
      .collection(`Worklist-Citizens Charter`)
      .doc(this.worklist_id);

    let checkServiceCC = (await checkService.get()).data()?.citizens_charter;
    
    if(!this.has_charter && !checkServiceCC) {
      // Remove service and worklist IDs from certification
      // Test for non-certified DRAFT services because the documents will be hard deleted anyway
      this.loadOngoingCertificationFunc()
        .pipe(
          tap((certificationData) => {
            if(certificationData.length > 0) {
              let certData: any = certificationData[0].payload.doc.data();
              
              let updatedServices = certData?.service_id;
              const indexService = updatedServices.indexOf(this.service_id);
              if (indexService > -1) {
                updatedServices.splice(indexService, 1);
              }
              
              let updatedWorklists = certData?.worklist_id;
              const indexWorklist = updatedWorklists.indexOf(this.worklist_id);
              if (indexWorklist > -1) {
                updatedWorklists.splice(indexWorklist, 1);
              }

              let services_certification_ref = this.afs.firestore
                .collection(`services-certification-phase-02`)
                .doc(certificationData[0].payload.doc.id);
              batch.update(services_certification_ref, {
                service_id: updatedServices,
                worklist_id: updatedWorklists
              });
            }
          }),
          take(1),
          finalize(() => {
            // Main batch delete here
            batch.delete(checkService);
            batch.delete(checkWorklist);
      
            batch
              .commit()
              .then(() => {
                // Snackbar notif here
                this.snackBarService.openPlainNotif("Service deleted!","Close");

                this.returnRedirect();
              })
              .catch((err) => {
                //console.log(err);
              });  
          })
        )
        .subscribe();
    }
    else {
      alert("You cannot delete a service attached to a citizens charter.")
    }
  }

  async submitAgencyVerifier(actionFlag: number) {
    if(this.has_unresolved_remarks) {
      this.openWarningPrompt(
        {
          verifiedWithRemarksWarning: true,
        }
      );
    }
    else {
      this.submit(actionFlag);
    }  
  }

  async submitAgencyHead(actionFlag: number) {
    //this.resolveAllRemarks();
    this.submit(actionFlag);
  }


  async submitCMEOPO(actionFlag: number, compliantFlag: number) {
    this.compliantFlag = compliantFlag;
    this.submit(actionFlag);
  }

  async submitCMEODC(actionFlag: number, overturn: boolean) {
    if(this.compliantFlag > -1 && overturn) {
      if(this.compliantFlag == 1) {
        // If assessment is to overturned from Non-Compliant to Compliant, resolve all remarks
        //this.resolveAllRemarks();
        this.automaticResolveRemarks(" unresolve ")
      }

      for(let k of this.assessmentValues.keys()) {
        // Get the first mismatch
        if(k !== this.compliantFlag) {
          this.compliantFlag = k;
          break;
        }
      }
    }
    this.submit(actionFlag);
  }

 

  async submit(actionFlag: number) {
    // Also save the remarks (if valid)
    //this.saveRemarks(false);   
    this.snackBarService.callLoadingmodal();
    let nextStatus = '';

    switch (actionFlag) {
      case WorklistActionFlagCitizensCharter.HARD_DELETE_SERVICE:
        this.hardDeleteService();
        return;
      case WorklistActionFlagCitizensCharter.SUBMIT_DRAFT:
        nextStatus = WorklistStatusCitizensCharter.FOR_VERIFICATION;
        break;
      case WorklistActionFlagCitizensCharter.RESUBMIT:
        nextStatus = WorklistStatusCitizensCharter.RESUBMITTED;
        break;
      case WorklistActionFlagCitizensCharter.TAG_AS_VERIFIED:
        nextStatus = WorklistStatusCitizensCharter.VERIFIED;
        break;
      case WorklistActionFlagCitizensCharter.APPROVE:
        nextStatus = WorklistStatusCitizensCharter.APPROVED;
        break;
      case WorklistActionFlagCitizensCharter.DISAPPROVE:
        nextStatus = WorklistStatusCitizensCharter.DISAPPROVED;
        break;
      case WorklistActionFlagCitizensCharter.RETURN_FROM_AGENCY_QA:
        nextStatus = WorklistStatusCitizensCharter.FOR_REVISION;
        break;
      case WorklistActionFlagCitizensCharter.CMEO_PO_APPROVE:
        nextStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED;
        break;
      // Return back to PO-level ARTA review
      case WorklistActionFlagCitizensCharter.CMEO_DC_DISAPPROVE:
      // When DIR disapproves, return the service back to PO for review
      case WorklistActionFlagCitizensCharter.CMEO_DIR_DISAPPROVE:
        nextStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW;
        break;
      case WorklistActionFlagCitizensCharter.CMEO_DC_APPROVE:
        nextStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED;
        break;
      case WorklistActionFlagCitizensCharter.CMEO_DIR_APPROVE:
        nextStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED;
        break;
      case WorklistActionFlagCitizensCharter.POST_CITIZENS_CHARTER:
        // TODO: Update Service Status to POSTED for List of Service retrieval
        nextStatus = WorklistStatusCitizensCharter.POSTED;
        break;
    }

    if (nextStatus !== '') {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      if (nextStatus == WorklistStatusCitizensCharter.POSTED) {
        const updateService = this.afs.firestore
          .collection(`Services Phase 02`)
          .doc(this.service_id);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        batch.update(updateService, { is_posted: true, date_posted: today });
      }

      let updateObj: Record<string,any> = await this.worklistService.updateWorklistStatusObj(nextStatus);

      // Add assessment to worklist document when sending from CMEO PO to CMEO DC
      if (
        (
          nextStatus == WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED ||
          nextStatus == WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED
        ) &&
        this.assessmentValues.has(this.compliantFlag)
      ) {
        updateObj.assessment = this.assessmentValues.get(this.compliantFlag);
        updateObj.reviewer = updateObj?.latestUser
        if(nextStatus == WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED) {
          // Update charter to ONGOING REVIEW
          let citizens_charter_ref = this.afs.firestore
            .collection(`citizens-charter-phase-02`)
            .doc(this.services.citizensCharter);
          let updateCharterObj = {
            status: CharterStatusCitizensCharter.ONGOING_ARTA_REVIEW,
          };

          batch.update(citizens_charter_ref, updateCharterObj);
        }
      }

      // If Agency Verifier verifies the worklist with unresolved remarks, and the warning was confirmed, resolve all remarks && this.has_unresolved_remarks
      if(nextStatus == WorklistStatusCitizensCharter.VERIFIED || nextStatus == WorklistStatusCitizensCharter.FOR_REVISION) {
        updateObj.verifier = updateObj?.latestUser

        if(nextStatus == WorklistStatusCitizensCharter.FOR_REVISION){
          if(this.services?.docOwner){

            this.emailer.firebaseEmailer([this.services?.docOwner.email,updateObj?.latestUser.email],'Service Returned with Remarks',this.services.serviceDetails.service_name + ' has been returned to encoder for modification.' + '<br> <a href="https://citizenscharter.gov.ph/artemis/view/detail/'+ this.service_id +'?worklistId='+ this.worklist_id+'">Click here</a> to view the service.')
         
          }
        }
        //this.resolveAllRemarks();
      }

      if(nextStatus == WorklistStatusCitizensCharter.RESUBMITTED || nextStatus == WorklistStatusCitizensCharter.FOR_VERIFICATION){
        updateObj.docOwner = updateObj?.latestUser

        if(nextStatus == WorklistStatusCitizensCharter.RESUBMITTED){
            if(this.services?.verifier){

              this.emailer.firebaseEmailer([this.services?.verifier.email,updateObj?.latestUser.email],'Resubmitted Service ',this.services.serviceDetails.service_name + ' has been resubmitted to verifier for reviewing.' + '<br> <a href="https://citizenscharter.gov.ph/artemis/view/detail/'+ this.service_id +'?worklistId='+ this.worklist_id+'">Click here</a> to view the service.'  )
            }
        }
        
      }

      const updateWorklist = this.afs.firestore
        .collection(`Worklist-Citizens Charter`)
        .doc(this.worklist_id);
      batch.update(updateWorklist, updateObj);

      await batch
        .commit()
        .then(() => {
          Swal.close();
          this.returnRedirect();
        })
        .catch((err) => {
          this.snackBarService.displayNotif('error',err)
        });
    }
  }

 

  // Requires services.clientSteps to be loaded
  setupClientStepsAgencyActionsRemarkInputs() {
    this.services?.clientSteps.forEach(async (x: any) => {
      let clientStepRemark = await this.buildNewEntry(EntityBuilderIndex.REMARK_CLIENT_STEP, x);

      x.agency_actions.forEach(async (y: any) => {
        let agencyActionRemark = await this.buildNewEntry(EntityBuilderIndex.REMARK_AGENCY_ACTION, y);
      
        let agencyActionsArr = clientStepRemark.get(
          'agency_actions'
        ) as UntypedFormArray;
        
        agencyActionsArr.push(agencyActionRemark);
      });
      this.service_steps_val.push(clientStepRemark);
    });
  }

openCommentModal(){
  this.dialog.open(ArtemisCommentCreateComponent,{
    height: '70vh'

  }).afterClosed().subscribe()
}



 CommentPrams():any {

  return {
    type_flag: this.enum_comment_section_flag.OVERALL_CC,
    type_title: this.services.serviceDetails.service_name,
    type_id: this.services.id,
    type_agencyId: this.agencyId
    }

}

  // ID of the previously scrolled-to paragraph
prevID: string = "";


// Scroll function
/**Moved to GovService
 * scrollTo(serviceId:any){
    
  if (serviceId) {
    // Reset the color of the previously scrolled-to card
    if (this.prevID) {
      const prevDoc = document.getElementById(this.prevID);
      if (prevDoc) {
        prevDoc.style.backgroundColor = 'transparent'; // Reset color to its default
      }
    }

    // Set the color and scroll to the new card
    const doc = document.getElementById(serviceId);
    if (doc) {
      doc.style.backgroundColor = '#C2CFD8';
      doc.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      // Update the previous ID to the current one
      this.prevID = serviceId;
    }
  }
}
 */


 with_remarks:any = 0;

  loadRemarksThread() {
    if (this.remarks_observable) {     
      this.remarks_observable
      .pipe(
        tap(async (data: any) => {
          const test = await this.auth.currentUser;

          if(data.length == 0) {
            // Still need to reinitialize what?
          }
          data.forEach((remarkObj: any) => {
            const remark_data = remarkObj?.payload?.doc?.data();
            // Is remark_id still going to be used?
           // this.remark_id = remarkObj?.payload?.doc?.id;
       
                       
            if (remark_data) {
              // Remark thread for Service
              
              const service_remark_obj = remark_data.service_remark_obj;
              const service_eodb_tags_remark_obj = remark_data.service_eodb_tags_remark_obj;
              
              if(service_remark_obj?.service_remark) {
                let addServiceRemark = this.remarkService.createRemarksArrayObj(remark_data, service_remark_obj, test?.uid || '', remarkObj?.payload?.doc?.id);
                if(addServiceRemark.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                  //console.log('Service remark:', addServiceRemark.serviceMsg);
                 // console.log('service-Status:',addServiceRemark.resolved_status);
                 
                  this.with_remarks = this.with_remarks + 1;
                }
               
                this.remarks_thread_array.service_remark.push(addServiceRemark);
              }

              // Remark thread for EODB Tags
              if(service_eodb_tags_remark_obj?.service_eodb_tags_remark) {
                let addEodbTagsRemark = this.remarkService.createRemarksArrayObj(remark_data, service_eodb_tags_remark_obj, test?.uid || '', remarkObj?.payload?.doc?.id);
                if(addEodbTagsRemark.resolved_status == RemarkResolvedStatusCitizensCharter.UNRESOLVED){
                  //console.log('EODB remark',addEodbTagsRemark.eodbMsg);
                  //console.log('eodb-Status:',addEodbTagsRemark.resolved_status);
                  this.with_remarks = this.with_remarks + 1;
                } 

                this.remarks_thread_array.service_eodb_tags_remark.push(addEodbTagsRemark);
              }

              // Remarks for client steps and 
              for (let [k,v] of Object.entries<any>(remark_data.service_steps_remarks || new Map())) {
                // Set up the remark thread of the client step
                let stepId = k;
                let addStepRemarkArr = this.remarks_thread_array.service_steps.get(stepId) || new Array();

                if(v.step_remark) {                
                  let addStepRemark = this.remarkService.createRemarksArrayObj(remark_data, v, test?.uid || '', remarkObj?.payload?.doc?.id, stepId);
                  
                  if(addStepRemark.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                    this.with_remarks= this.with_remarks + 1;
                    //console.log('step-remark: ', addStepRemark.msg);
                    //console.log('step-status',addStepRemark.resolved_status);
                   // console.log('step_ID',addStepRemark.client_step_id);
                  }
                   
                  addStepRemarkArr.push(addStepRemark);
                }

                this.remarks_thread_array.service_steps.set(stepId,addStepRemarkArr);
              
              }

              for (let [k,v] of Object.entries<any>(remark_data.service_agency_actions_remarks || new Map())) {
                // Set up the remark thread of the agency action
                let actionId = k;
                let addActionRemarkArr = this.remarks_thread_array.agency_actions.get(actionId) || new Array();
                if(v.action_remark) {                  
                  let addActionRemark = this.remarkService.createRemarksArrayObj(remark_data, v, test?.uid || '', remarkObj?.payload?.doc?.id, actionId);
                  if(addActionRemark.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED) {
                    //console.log('action-remark: ', addActionRemark.actionMsg);
                   // console.log('action-status',addActionRemark.resolved_status);
                    //console.log('action_ID',addActionRemark.agency_action_id);
                    
                    this.with_remarks = this.with_remarks + 1;
                  }
                    
                    addActionRemarkArr.push(addActionRemark);
                }

                this.remarks_thread_array.agency_actions.set(actionId,addActionRemarkArr);
              }
            }
         //console.log('countStats',this.with_remarks);

          });
        }),
        take(1)
      )
      .subscribe();
    }
    
    // Open remarks flag goes here
    // Encoders are no longer allowed to make remarks
    if (
      this.service_status !== WorklistStatusCitizensCharter.DRAFT &&
      this.service_status !== WorklistStatusCitizensCharter.FOR_REVISION &&
      this.service_status !== WorklistStatusCitizensCharter.POSTED &&
      (sessionStorage.getItem('account_subtype') || '') !== GovernmentAccountSubtype.AGENCY_ENC &&
      this.actionButtonAllowed
    ) {
      // open_remarks will act as the flag for then service_remark_obj and service_eodb_tags_remark_obj FormGroups
      this.open_remarks = true;
    }else if(this.service_status == WorklistStatusCitizensCharter.FOR_APPROVAL ){
      this.open_remarks =false;
    }
  }

  


  genIndex(i:string,x:string){
return i + x;
  }

  async saveRemarks(saveRemarksOnly: boolean) {
    // Do not allow saving of empty remarks
    if(!this.open_remarks || !this.has_remarks) return;


  
    const test = await this.auth.currentUser;
    const user_position = sessionStorage.getItem('account_subtype') || '';

    const remark_user = {
      email: test?.email,
      name: test?.displayName,
      user_id: test?.uid,
      position: user_position
    };

    const batch = this.afs.firestore.batch(); // batch uploader, firestore
  
    // if this.remark_id is undefined, assume insert new document, auto-generate a new ID
    let remark_ref = this.afs.firestore
      .collection(`Remarks Artemis New`)
      .doc(this.remark_id);

    let service_steps_map: Record<string, any> = {};
    let agency_actions_map: Record<string, any> = {};
      
    for (let item of this.service_steps_val.controls) {
      if(item.value.step_remark) {
        //console.log('Item Value',item)
        let saveSerivceStepRemark: Record<string, any> = {
          step_title: item.value.step_title,
          step_remark: item.value.step_remark,
          resolved_status: item.value.resolved_status,
          reply: item.value.reply,

        };
  
        service_steps_map[`${saveSerivceStepRemark.step_title}`] = saveSerivceStepRemark;
      }      
      
      for (let action of item.value.agency_actions) {
        if(action.action_remark) {
          
          let saveAgencyActionRemark: Record<string, any> = {
            action_title: action.action_title,
            action_remark: action.action_remark,
            resolved_status: action.resolved_status,
            reply: action.reply,
          
          }
  
          agency_actions_map[`${saveAgencyActionRemark.action_title}`] = saveAgencyActionRemark;
        }
      }
    }

    //console.log('Client Step Map',service_steps_map)
    //console.log('Agency ACtion Map',agency_actions_map)

    let remarkJSON: Record<string, any> = {            
      remark_user: remark_user,
      remark_date: Timestamp.now().toDate(),
      remark_worklist_id: this.worklist_id,
      // Apparently, this is the status before submission
      remark_worklist_status: this.service_status,
      remark_level: RemarkLevelRoleMappingCitizensCharter[user_position],
      // pipe '' in case of remark with no CC yet
      remark_citizens_charter: this.services.citizensCharter || '',
      remark_service_name: this.services.serviceDetails.service_name
    };

    // To avoid clutter of empty unresolved remarks, only save the remark objs that contain input
    // Assume that at least one of these conditions are fulfilled; otherwise remarks are empty and method will return at the start
    if(this.remarks_form.get('service_remark_obj')?.value.service_remark) {
      remarkJSON.service_remark_obj = this.remarks_form.get('service_remark_obj')?.value;
    }

    if (this.remarks_form.get('service_eodb_tags_remark_obj')?.value.service_eodb_tags_remark) {
      remarkJSON.service_eodb_tags_remark_obj =
        this.remarks_form.get('service_eodb_tags_remark_obj')?.value;
    }

    if (Object.keys(service_steps_map).length > 0) {
      remarkJSON.service_steps_remarks = service_steps_map;
    }

    if (Object.keys(agency_actions_map).length > 0) {
      remarkJSON.service_agency_actions_remarks = agency_actions_map;
    }

    batch.set(remark_ref, remarkJSON);

    await batch.commit().then(() => {
      if(saveRemarksOnly) {
        let alert_message = '';
        alert_message = 'Remarks Saved!';
        alert(alert_message);
      }
    });
  }
  auto_resolve_PO_DC_remarks: boolean =false
  resolveAllRemarks() {

    for(let x of this.remarks_thread_array.service_remark) {
      this.remarkService.resolveRemark(x, 1);
    }

    for(let x of this.remarks_thread_array.service_eodb_tags_remark) {
      this.remarkService.resolveRemark(x, 2);
    }

    this.remarks_thread_array.service_steps.forEach((v: any, k: string) => {
      for(let x of v) {
        this.remarkService.resolveRemark(x, 3, k);
      }
    });

    this.remarks_thread_array.agency_actions.forEach((v: any, k: string) => {
      for(let x of v) {
        this.remarkService.resolveRemark(x, 4, k);
      }
    });
  }

  automaticResolveRemarks(message:any = "your"){
    Swal.fire({
      title: "Do you want to resolve all "+ message +" remarks?",
      showCancelButton: true,
      confirmButtonText: "Resolve Remarks",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        this.snackBarService.callLoadingmodal("Resolving remarks...");
         //auto resolve all remarks 
          this._service.hideRemarks(this.service_id,true)
          
      } 
    });
   
  }
  
  async resolveRemark(remark_obj: any, update_flag: number, step_action_id?: string) {
    this.remarkService.resolveRemark(remark_obj, update_flag, step_action_id).then(() => {    
      this.with_remarks = this.with_remarks - 1;  
      let alert_message = '';
      alert_message = 'Remarks resolved!';
      
     // console.log('deleted',this.with_remarks)
      this.snackBarService.openPlainNotif(alert_message,"Close");  
    });
  }

  async submitReply(remark_obj: any, update_flag: number, step_action_id?: string) {
    // Close reply/resolve options after
    remark_obj.allow_reply = false;
    remark_obj.open_reply = false;

    if(remark_obj.resolve_remark_id) {
      const test = await this.auth.currentUser;
      const user_position = sessionStorage.getItem('account_subtype') || '';
  
      const reply_user = {
        email: test?.email,
        name: test?.displayName,
        user_id: test?.uid,
        position: user_position
      };
  
      let updateObj: Record<string, any> = {};

      let setReplyObj = this.buildNewEntry(EntityBuilderIndex.REMARK_REPLY).value;
      setReplyObj.reply_remark = remark_obj.reply_remark;
      setReplyObj.reply_user = reply_user;
      setReplyObj.reply_date = Timestamp.now().toDate();

      switch(update_flag) {
        case 1:
          // Service remarks
          updateObj = {
            service_remark_obj : {
              reply : setReplyObj
            }
          };
          break;
        case 2:
          // Service EODB tags remarks
          updateObj = {
            service_eodb_tags_remark_obj : {
              reply : setReplyObj
            }
          };
          break;
        case 3:
          // Service steps
          if(step_action_id) {
            updateObj = {
              service_steps_remarks : {
                [`${step_action_id}`] : {
                  reply : setReplyObj
                }
              }
            };
          }
          break;
        case 4:
          // Agency action
          if(step_action_id) {
            updateObj = {
              service_agency_actions_remarks : {
                [`${step_action_id}`] : {
                  reply : setReplyObj
                }
              }
            };
          }
          break;
        default:
          // Should not happen
          return;
      }

      const batch = this.afs.firestore.batch(); // batch uploader, firestore
    
      let remark_ref = this.afs.firestore
        .collection(`Remarks Artemis New`)
        .doc(remark_obj.resolve_remark_id);
    
      // https://stackoverflow.com/a/47801933
      // https://stackoverflow.com/a/53115582
      batch.set(remark_ref, updateObj, { merge: true });
    
      await batch.commit().then(() => {
        let alert_message = '';
          alert_message = 'Reply sent!';
          this.snackBarService.openPlainNotif(alert_message,"Close");
          remark_obj.reply_msg = remark_obj.reply_remark;
          remark_obj.reply_from = reply_user?.name;
          remark_obj.reply_from_pos = reply_user?.position;
      });
    }
  }

  returnRedirect() {
    this.activateArtaInboxAction()
    this.router.navigate(['/artemis/charter']);
  }

  back(){
    this.location.back()
  }

  // requirements tab
  changeTab(i:number){
    this.resetRequirementsTabs()
    this.requirementTabs[i].currentTab = true
  }

  resetRequirementsTabs(){
    this.requirementTabs.map((val)=>{
      val.currentTab = false
      return val
    })
  }
  // !

  // report generation
  async generatePDF(serviceId: string) {
    //retrieve service details from firebase
    if (this.currentAgency) {
      this.afs
        .collection(this.ServiceCollection)
        .doc(serviceId)
        .snapshotChanges()
        .pipe(first())
        .subscribe((serviceRetrievedFromFirebase) => {
          //check if service exist
          if (
            serviceRetrievedFromFirebase.payload.exists &&
            this.currentAgency
          ) {
            let serviceDoc: any = serviceRetrievedFromFirebase.payload.data();
            serviceDoc.id = serviceRetrievedFromFirebase.payload.id;

            //check if link is in firebase
            if (
              serviceDoc &&
              serviceDoc.reports &&
              (serviceDoc.reports.pdfReportLink != null ||
                serviceDoc.reports.pdfReportLink != undefined)
            ) {
              //check link validity/expiry
              const signedURLValid: boolean = this.extractDateFromUrl(
                serviceDoc.reports.pdfReportLink
              );
              // console.log({signedURLValid})
              if (signedURLValid) {
                this.checkDocumentExist(serviceDoc, true);
              } else {
                // console.log("expired : check document exist")
                this.checkDocumentExist(serviceDoc, false);
              }
            } else {
              // console.log("no link : check document exist")
              this.checkDocumentExist(serviceDoc, false);
            }
          } else {
            alert('Error: No Service ID or Service is not under any Agency');
          }
        });
    } else alert('Error: Service is not under any Agency');
  }

  generateWord() {
    // console.log("generateWord inprogress");
    alert('Generating word document still under progress');
  }

  printFile() {
    // console.log("printFile inprogress");
    alert('Printing file still under progress');
    // this.jsReportPDFAPICall()
    // .then((res: any) =>{
    //   console.log("jsreport result: Success")
    //   let printer = res.openInWindow({title:"Artemis Service Report",filename: 'Artemis-Service-Report.pdf'})
    //   printer.print()
    // })
    // .catch((err: any)=>{
    //   console.error("jsreport error: ",err)
    //   //401 means unauthorized access, need jsreport browser client authorization (admin:password) username:userpassword
    // })
  }
  //

  //api request and updates for pdf generations
  private extractDateFromUrl(link: any) {
    const signedUrl = link;
    const urlHeaders: any = signedUrl.match(
      /X-Goog-Date=([^&]+)&X-Goog-Expires=([^&]+)/
    );
    const googleDate: string = urlHeaders[1];
    let gDate = new Date(
      googleDate.substr(0, 4) +
        '-' +
        googleDate.substr(4, 2) +
        '-' +
        googleDate.substr(6, 2) +
        googleDate.substr(8, 1) +
        googleDate.substr(9, 2) +
        ':' +
        googleDate.substr(11, 2) +
        ':' +
        googleDate.substr(13, 2) +
        googleDate.substr(15, 1)
    );
    gDate.setSeconds(gDate.getSeconds() + parseInt(urlHeaders[2]));
    const currentDate = new Date();
    if (gDate > currentDate) return true;
    else return false;
  }

  private async checkDocumentExist(serviceDoc: any, urlValid: boolean) {
    // console.log('checkDocumentExist');
    const apiUrl =
      'https://us-central1-arta-respond.cloudfunctions.net/checkDocExist'; //cloudfunc /to check document existence /no checking
    let serviceData = {
      generationFor: 'serviceReport',
      service_id: this.service_id,
      agency: { agencyCode: this.currentAgency.id },
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.http.post<any>(apiUrl, serviceData, httpOptions).subscribe(
      (apiResponse: any) => {
        // console.log({ apiResponse });
        //{ message: string, exist: boolean  }
        if (apiResponse.exist && urlValid) {
          window.open(serviceDoc.reports.pdfReportLink);
        } else if (apiResponse.exist && !urlValid) {
          this.signUrl(serviceDoc);
        } else if (!apiResponse.exist) {
          this.postToJSReportAPI(serviceDoc);
        } else {
          alert('Checking Report failed');
        }
      },
      (error) => {
        alert('Checking Report failed');
      }
    );
  }

  private async postToJSReportAPI(serviceDoc: any) {
    // console.log('newpostToJSReportAPI');
    const apiUrl =
      'https://us-central1-arta-respond.cloudfunctions.net/generateArtemisReport'; //cloudfunc endpoint / to generate /no checking
    let serviceData = {
      generationFor: 'serviceReport',
      agency: { agencyCode: this.currentAgency.id },
      service_id: this.service_id,
      service: this.currentService,
      // service_background: this.service_background,
      // service_requirements: this.service_requirements,
      // service_client_steps_info: this.service_client_steps_info,
    };
    // console.log('sent data');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.http.post<any>(apiUrl, serviceData, httpOptions).subscribe(
      (apiResponse: any) => {
        // console.log({ apiResponse });
        if (apiResponse.generate) {
          this.signUrl(serviceDoc);
        } else {
          alert('Generating PDF Failed, Try Again');
        }
      },
      (error) => {
        alert('Generating PDF Failed, Try Again');
      }
    );
  }

  private async signUrl(serviceDoc: any) {
    // console.log('signUrl');
    const apiUrl =
      'https://us-central1-arta-respond.cloudfunctions.net/signReportURL'; //cloudfunc endpoint / to sign url
    let serviceData = {
      service_id: this.service_id,
      generationFor: 'serviceReport',
      agency: { agencyCode: this.currentAgency.id },
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    this.http.post<any>(apiUrl, serviceData, httpOptions).subscribe(
      (apiResponse: any) => {
        // console.log({ apiResponse });
        //return a url
        if (apiResponse.result == 'success') {
          const serviceLink = apiResponse.link;
          this.updateFirebaseReportLink(serviceDoc, serviceLink);
          window.open(serviceLink);
        } else {
          alert('Failed to Generate URL, Try Again');
        }
      },
      (error) => {
        alert('Failed to Generate URL, Try Again');
      }
    );
  }

  updateFirebaseReportLink(serviceDoc: any, link: any) {
    // console.log('updateFirebaseReportLink');
    if (serviceDoc.reports == null || serviceDoc.reports == undefined) {
      serviceDoc.reports = {};
      serviceDoc.reports.pdfReportLink = link;
    } else {
      serviceDoc.reports.pdfReportLink = link;
    }

    this.afs.collection(this.ServiceCollection).doc(serviceDoc.id).update({
      reports: serviceDoc.reports,
    });
  }
  //

  activateArtaInboxAction(){
    let artemisInboxActiveMode:any = sessionStorage.getItem('artemisInboxActiveMode')
    if(artemisInboxActiveMode != null && artemisInboxActiveMode != undefined && artemisInboxActiveMode != '' && artemisInboxActiveMode == 'Arta Check Service Inbox'){
      sessionStorage.setItem('artemisInboxActivated','Arta Check Service Inbox')
    }
  }

  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {

    switch (entry_id) {
      case EntityBuilderIndex.REMARK_SERVICE:
        let serviceRemark = new UntypedFormGroup({
          service_remark: new UntypedFormControl(''),
          resolved_status: new UntypedFormControl(RemarkResolvedStatusCitizensCharter.UNRESOLVED),
          reply: this.buildNewEntry(EntityBuilderIndex.REMARK_REPLY),
        });
        
        return serviceRemark;            
      case EntityBuilderIndex.REMARK_EODB_TAG:
        let eodbRemark = new UntypedFormGroup({
          service_eodb_tags_remark: new UntypedFormControl(''),
          resolved_status: new UntypedFormControl(RemarkResolvedStatusCitizensCharter.UNRESOLVED),
          reply: this.buildNewEntry(EntityBuilderIndex.REMARK_REPLY),
        });
        
        return eodbRemark;
      case EntityBuilderIndex.REMARK_CLIENT_STEP:
        //console.log('client_step',existing_obj)
        let clientStepRemark = new UntypedFormGroup({
          step_title: new UntypedFormControl(existing_obj?.info?.step_Id),
          step_remark: new UntypedFormControl(''),
          resolved_status: new UntypedFormControl(RemarkResolvedStatusCitizensCharter.UNRESOLVED),
          reply: this.buildNewEntry(EntityBuilderIndex.REMARK_REPLY),
          agency_actions: new UntypedFormArray([]),
        });

        return clientStepRemark;
      case EntityBuilderIndex.REMARK_AGENCY_ACTION:
       // console.log('agency_step',existing_obj)
        let agencyActionRemark = new UntypedFormGroup({
          action_title: new UntypedFormControl(existing_obj?.action_Id),
          action_remark: new UntypedFormControl(''),
          resolved_status: new UntypedFormControl(RemarkResolvedStatusCitizensCharter.UNRESOLVED),
          reply: this.buildNewEntry(EntityBuilderIndex.REMARK_REPLY),
        });

        return agencyActionRemark;
      case EntityBuilderIndex.REMARK_REPLY:
        return new UntypedFormGroup({
          reply_remark: new UntypedFormControl(''),
          reply_user: new UntypedFormGroup({
            email: new UntypedFormControl(''),
            name: new UntypedFormControl(''),
            user_id: new UntypedFormControl(''),
            position: new UntypedFormControl('')
          })
        });
      default:
        return new UntypedFormGroup({});
    }
  }

  shareService(){

    Swal.fire({
      icon: "info",
      html: `
        Share  <b>`+ this.services.serviceDetails.service_name +`</b>, 
        to 
      `,
      showCloseButton: true,
      showDenyButton: false,
      focusConfirm: false,
      confirmButtonText: `
        <i class="fab fa-facebook"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=`+ `https://citizenscharter.gov.ph` + this.router.url +`&amp;src=sdkpreparse" ></i> Facebook
      `,
      denyButtonText: `
        <i href="https://twitter.com/intent/tweet?text=I love this service`+ `https://citizenscharter.gov.ph` + this.router.url+`&hashtags=hashtag1,hashtag2" target="_blank" class="fab fa-twitter"></i> Twitter
      `,
    }).then((result)=>{
        if(result.isConfirmed){

        }else if(result.isDenied){

        }
    })


  }

}
