<!-- <div class="d-flex justify-content-center align-self-center">
  <div class="spinner-grow" role="status" *ngIf="loader">
      <span class="visually-hidden">Loading...</span>
  </div>
</div> -->
<div class="card">
<!-- <div class="card" *ngIf="!loader" > -->
    <div class="header d-flex" style="border-bottom: 1px solid rgba(0,0,0,.125); justify-content: space-between; ">
      <h5 class="card-header" style="border-bottom: none;">Notifications </h5>
        
        <!-- <form> -->
        <form [formGroup]="searchform" (ngSubmit)="search()">
            <div class="d-flex">
                <div class="row input-group my-3">
                    <input
                    type="text"
                    class="form-control mx-2"
                    placeholder="Search .."
                    formControlName="keyword"
                    
                    />
                </div>
                <div class="row input-group my-3" style="margin: 0rem 1rem;">
                    <select name="eventCategory" 
                    formControlName="eventCategory"
                    class="form-select text-center service-finder-input"
                    [(ngModel)]="selectedOption"
                    (change)="eventSelected()"
                    >   
                        <option *ngFor="let o of options" > {{o.name}}</option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive" style="max-height: 500px;">
        <table class="table">
          <thead class="text-muted">
            <tr>
              <!-- <th class="table-header fw-bold">Name</th> -->
            <th class="table-header fw-bold">Event</th>
              <th class="table-header fw-bold">Agency</th>
              <th class="table-header fw-bold">Level</th>
              <th class="table-header fw-bold">User</th>              
              <th class="table-header fw-bold">Message</th>
            </tr>
          </thead>
          <tbody class="card-text">
            <tr *ngFor="let row of notifList; let i = index">
              <th scope="row" >{{ row.event}}</th>    
              <td >{{ row.agency_name }}</td>   
              <td >{{ row.level }}</td>  
              <td >{{ row.display_name }}</td>  
              <td >{{ row.message }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>
  
  
  
  