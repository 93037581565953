import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  GovernmentAccountSubtype,
  WorklistActionFlagPBRISRegulation,
  WorklistAgencyActionGroupedStatusPBRISRegulation,
  WorklistAgencyActionMappedStatusPBRISRegulation,
  WorklistArtaActionMappedStatusPBRISRegulation,
  WorklistStatusPBRISRegulation
} from 'src/app/entities/worklist';
import { startWith, debounceTime, flatMap, map, take, tap, first } from 'rxjs/operators';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { RegulationViewExistingComponent } from '../regulation-view-existing.component';

@Component({
  selector: 'app-dropdown-comment',
  templateUrl: './dropdown-comment.component.html',
  styleUrls: ['./dropdown-comment.component.scss']
})
export class DropdownCommentComponent implements OnInit {

  // comment_details = new FormControl('', Validators.required);
  comment_regtext = new UntypedFormControl('', Validators.required);
  // comment_self_assessment = new FormControl('', Validators.required);
  @Input() worklist_id: any;
  @Input() reg_docId: any;

  constructor(private afs: AngularFirestore,
    public worklistService: WorklistService,
    private route: ActivatedRoute,
    private router: Router,
    private regulationViewExisting: RegulationViewExistingComponent
  ) {
  }

  ngOnInit(): void {
  }



  async submitCommentsAndReturn() {
    console.log(this.worklist_id);
    console.log(this.reg_docId);

    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let worklist_ref: any;

    if (this.worklist_id) {
      worklist_ref = this.afs.firestore.collection(`Worklist-Existing Regulations`).doc(this.reg_docId);

      let nextStatus = WorklistStatusPBRISRegulation.FOR_REVISION;
      let updateObj: Record<string, any> = await this.worklistService.updateWorklistStatusObj(nextStatus);
      updateObj.commentDetails = this.comment_regtext.value;
      updateObj.commentRegText = this.comment_regtext.value;

      batch.update(
        worklist_ref,
        updateObj
      );
    }
    console.log('load this: ' + this.worklist_id);
    this.regulationViewExisting.loadWorkListInformation(this.worklist_id);
    this.regulationViewExisting.onCommentsView();

    await batch.commit().then(() => {
      alert("Comments updated.");
      // this.getDetailsForEmailer(worklist_ref)
      this.forReturnToRegulatoryManagement(this.worklist_id, this.reg_docId)
      this.router.navigate(['/pbris/regulatory-management']);
      // this.loading = false
    }).catch((error: { message: any; }) => {
      // this.loading = false
      alert(error.message)
    });
  }

  forReturnToRegulatoryManagement(worklistId: any, regulatiionId: any) {
    sessionStorage.setItem('regulatoryActive', 'phase 2 existing')
    sessionStorage.setItem('worklistId', worklistId)
    sessionStorage.setItem('regulationId', regulatiionId)
  }
}
