import { Component, OnInit,Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-artemis-delist-service-reason-modal',
  templateUrl: './artemis-delist-service-reason-modal.component.html',
  styleUrls: ['./artemis-delist-service-reason-modal.component.scss']
})
export class ArtemisDelistServiceReasonModalComponent implements OnInit {

  reason_for_delisting = new UntypedFormControl("", Validators.required);
  serviceName : any = '';
  constructor(
    
    public dialogRef: MatDialogRef<ArtemisDelistServiceReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.serviceName = this.data.serviceName
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    const returnServiceEntity = {
      submitFlag: true,
      submitValue: this.reason_for_delisting.value
    };

    this.dialogRef.close(returnServiceEntity);
  }

}
