<app-artemis-navigation-header [currentPage]="''"></app-artemis-navigation-header>
<div class="d-flex justify-content-center align-self-center">
  <div class="spinner-grow"  *ngIf="loader">
    <span class="visually-hidden">Loading...</span>
  </div>
  </div>

  <mat-toolbar *ngIf="!loader" class="navbar-color" style="height: 64px; background-color: #fff; border: 1px solid #E8EAED;">
    
    <div class="main-controls mx-2" >
      <button mat-icon-button mat-icon-button (click)="back()" matTooltip="Return" matTooltipPosition="below"> 
        <i class="fas fa-chevron-circle-left fa-lg"></i>
      </button>
      <button mat-icon-button matTooltip="View Service Information" matTooltipPosition="below" (click)="srvcInfo.toggle()"> 
        <i class="fas fa-bars fa-lg"></i>
      </button>
      <button mat-icon-button (click)="editService()" *ngIf="(service_status != enum_service_status.FOR_VERIFICATION ||
      service_status != enum_service_status.RESUBMITTED) && currUser == enum_account_subtype.AGENCY_VER"
          matTooltip="Edit Service" matTooltipPosition="below"> 
          <i class="fas fa-edit fa-lg"></i>
      </button>
      <button mat-icon-button (click)="checkReportFile()" *ngIf="currUser == enum_account_subtype.AGENCY_VER && (service_status == enum_service_status.FOR_VERIFICATION || service_status == enum_service_status.VERIFIED || service_status == enum_service_status.REAPPROVED || service_status == enum_service_status.FOR_REVISION)"
          matTooltip="Generate Service File" matTooltipPosition="below"> 
          <i class="fas fa-file-export"></i>
      </button>
      <button mat-icon-button (click)="triggerDeleteWarning()" *ngIf="
        (service_status == enum_service_status.DRAFT || service_status == enum_service_status.FOR_REVISION) && !has_charter && services?.docOwner? services.docOwner.id === userId  ?true : false : false" 
        matTooltip="Remove from Worklist" matTooltipPosition="below"> 
        <i class="fas fa-trash fa-lg"></i>
      </button>
    </div>

    <div class="guest-controls mx-2" *ngIf="service_status == enum_service_status.POSTED">
      <div class="doc-controls mx-3">
        <button routerLink="/artemis/comment" [queryParams]="{
          type_flag: enum_comment_section_flag.OVERALL_CC,
          type_title: services.serviceDetails.service_name,
          type_id: services.id,
          type_agencyId: agencyId
          }"
         mat-icon-button matTooltip="Submit a Comment" matTooltipPosition="below" *ngIf="!isGuest"
          > <i class="fas fa-comments fa-lg"></i>
        </button>
        <button mat-icon-button mat-icon-button matTooltip="Generate PDF" matTooltipPosition="below" (click)="generatePDF(service_id)">
          <i class="fas fa-file-pdf fa-lg"></i>
        </button>
        <button (click)="shareService()" mat-icon-button matTooltip="Share Service" matTooltipPosition="below">
          <i class="fas fa-share-alt fa-lg"></i>
        </button>
        <button mat-icon-button matTooltip="Generate Document File" matTooltipPosition="below" (click)="generateWord()">
          <i class="fas fa-file-word fa-lg"></i>
        </button>
        <button mat-icon-button matTooltip="Print Service" matTooltipPosition="below" (click)="printFile()">
          <i class="fas fa-print fa-lg"></i>
        </button>
        <button mat-icon-button matTooltip="Report" matTooltipPosition="below">
          <i class="fas fa-exclamation-triangle fa-lg"></i>
        </button>
      </div>
    </div>

    <span class="example-spacer text-center">{{services.serviceDetails.service_name}}</span>
    
    <div *ngIf="services?.status" class="stats">
      <span class="mx-3"><label>Status:</label></span>
      <span class="badge {{
        service_status == enum_service_status.POSTED ? '' : style_theme
      }} text-center fw-bold p-2">{{ services.status | uppercase }}</span>
    </div>
    <!-- <div *ngIf="!actionButtonAllowed && service_status === enum_service_status.POSTED || service_status === enum_service_status.FOR_MODIFICATION " class="mx-3">
      <button mat-icon-button matTooltip="View Agency Information" matTooltipPosition="below" (click)="rmrksCont.toggle()" >
        <i class="fas fa-info-circle"></i>
      </button>
    </div> -->
    <div  class="mx-3" mat-icon-button matTooltip="View other service details" matTooltipPosition="below" (click)="rmrksCont.toggle()">
      <button type="button" mat-icon-button class=" position-relative">
        <i class="fas fas fa-info-circle fa-lg"></i> 
        <span *ngIf="getUnresolve.length > 0" class="position-absolute top-0 start-70 translate-middle badge rounded-pill bg-danger">
          {{getUnresolve.length}}
        </span>
      </button>

    </div>
    
  </mat-toolbar>
  <mat-sidenav-container class="pt-1" hasBackdrop="false" *ngIf="!loader" >
    <!--left-navigation - Scrollspy -->
    <mat-sidenav #srvcInfo class="p-3" class="right-sidenav" opened mode="side" fixedInViewport fixedTopGap="248">
      <app-artemis-service-scrollspy [services]="services"></app-artemis-service-scrollspy>
    </mat-sidenav>
     <!--Remarks Navigation-->
    <mat-sidenav #rmrksCont  mode="over" position="end" fixedInViewport fixedTopGap="248" class="left-sidenav">
      <div class="p-3">
        <ng-container *ngIf="agencyId">
          <div class="p-2" *ngIf="!isGuest && !isOwner">
           <app-artemis-agency-mini-profile [agency_id]="agencyId"></app-artemis-agency-mini-profile>
          </div>
        </ng-container>
        <mat-accordion *ngIf="(service_status == enum_service_status.POSTED ? false : true || !isGuest) && ( allowedToRemarks)">
          <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Unresolved Remarks
              </mat-panel-title>
              <mat-panel-description>
                
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container mx-3 " style="border-top: 1px solid #f5f2f2;  width: 90%;">
              <button mat-raised-button color="warn" *ngIf="getUnresolve.length > 0 && actionButtonAllowed && currUser !== enum_account_subtype.AGENCY_ENC" (click)="automaticResolveRemarks()">Resolve all my remarks</button>
              <ng-container class="mt-1">
                <div (click)="_service.scrollTo(remark._id)" 
                *ngFor="let remark of getUnresolve" class="row rem-holder">

                <span><i class="fas fa-exclamation-circle text-danger"></i><b style="font-weight: 500;">{{remark.user.name}}</b>   </span>
                 <p class="ms-3" style="word-wrap: break-word;">{{remark.message}}</p> 
                </div>
              </ng-container>

            </div>
           
          </mat-expansion-panel>
          <mat-expansion-panel >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Remark History
              </mat-panel-title>
              <mat-panel-description>
                
              </mat-panel-description>
            </mat-expansion-panel-header>
    
            <ul class="list-group list-group-light list-group-small full-width">
              
              <li (click)="_service.scrollTo(remark._id)" *ngFor="let remark of getResolved" class="list-item text-muted"><i class="fas fa-check"></i> {{remark.message}}</li>
              
            </ul>
           
          </mat-expansion-panel>
    
        </mat-accordion>
    
        <div *ngIf="isOwner" class="row container mt-4">
          <h6>Public Comments</h6>
          <div *ngFor="let comment of public_comments;let i = index ;let c = count">
    
            <app-artemis-comments [isAdmin]="isOwner" [comment]="comment"></app-artemis-comments>
            <hr *ngIf="(i < (c-1))">
          </div>
        </div>


        <div *ngIf="isOwner" class="row container mt-4">
          <h6>Service history</h6>
          <app-artemis-service-history-list [service_id]="service_id"></app-artemis-service-history-list>
        </div>


      </div>
      
    </mat-sidenav>
<!--Remarks main-content-->
    <div class="px-3" style="margin: auto" >
      <mat-card>
        
        <mat-card-content>

          <app-citizens-charter-service-view [service_status]="service_status" [openRemarks]="actionButtonAllowed && allowedToRemarks" [service_effectivity]="worklist_effectivity" [service]= services [service_id] = service_id  [reviewMode]="(service_status == enum_service_status.POSTED ? false : true)" [hasCounter]="false"></app-citizens-charter-service-view>

          <div class="row p-2 mt-2 " style="border: 2px dotted black; ">
            <span class="text-center">End of Service</span>
          </div>

        </mat-card-content>

        <mat-card-actions >
          <div >
            <div class="form-group mt-5" *ngIf="
                        worklist_effectivity === enum_effectivity.DELIST ||
                        worklist_effectivity === enum_effectivity.MODIFY_DELIST
                      ">
                      <label class="form-input-header" for="reason_for_delisting"><b>Reason For Delisting</b></label>
                      <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never">
                        <textarea matInput readonly placeholder="Input" type="text"
                          [(ngModel)]="services.reasonForDelisting"></textarea>
                      </mat-form-field>
                  </div>
                  <div class="form-group mt-5" *ngIf="services.reasonForDisapproval">
                      <label class="form-input-header" for="reason_for_disapproval"><b>Reason For Disapproval</b></label>
                      <mat-form-field style="width: 100%" hideRequiredMarker appearance="outline" floatLabel="never">
                        <textarea matInput readonly placeholder="Input" type="text"
                          [(ngModel)]="services.reasonForDisapproval"></textarea>
                      </mat-form-field>
                  </div>
                  <div *ngIf="actionButtonAllowed">
                    <!-- <div *ngIf="true"> -->
                    <div *ngIf="
                        service_status === enum_service_status.FOR_VERIFICATION ||
                        service_status === enum_service_status.RESUBMITTED ||
                        service_status === enum_service_status.VERIFIED
                      " class="row">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button [disabled]="!service_has_remarks" (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)" mat-raised-button
                          class="btn btn-danger m-1 btn-min_width">
                          Return For Revision
                        </button>
                      </div>
                    </div>
                    <div *ngIf="
                        service_status === enum_service_status.FOR_ARTA_REVIEW_DC
                      " class="row">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button (click)="submit(enum_action_flag.CMEO_DC_DISAPPROVE)" mat-raised-button
                        [disabled]="!service_has_remarks"
                          class="btn btn-danger m-1 btn-min_width">
                          Return to PO
                        </button>
                      </div>
                    </div>
                    <div *ngIf="
                        service_status === enum_service_status.FOR_ARTA_REVIEW_DIR
                      " class="row">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button
                        [disabled]="!service_has_remarks"
                        (click)="submit(enum_action_flag.CMEO_DIR_DISAPPROVE)" mat-raised-button
                          class="btn btn-danger m-1 btn-min_width">
                          Disapprove
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        (service_status === enum_service_status.DRAFT ||
                          service_status === enum_service_status.FOR_REVISION ||
                          service_status ===
                            enum_service_status.FOR_MODIFICATION) &&
                        isValidService
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <!-- Resubmit -->
                        <button (click)="
                            submit(
                              service_status === enum_service_status.DRAFT
                                ? enum_action_flag.SUBMIT_DRAFT
                                : enum_action_flag.RESUBMIT
                            )
                          " class="btn btn-primary m-1 btn-min_width" mat-raised-button color="primary">
                          {{
                          service_status === enum_service_status.DRAFT
                          ? "Submit"
                          : "Re-Submit"
                          }}
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        service_status === enum_service_status.FOR_VERIFICATION ||
                        service_status === enum_service_status.RESUBMITTED
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button [disabled]="service_has_remarks  || someoneistyping" mat-raised-button color="primary" (click)="
                            submitAgencyVerifier(enum_action_flag.TAG_AS_VERIFIED)
                          " class="btn btn-primary m-1 btn-min_width">
                          Tag as Verified
                        </button>
                      </div>
                    </div>
                    <!-- If worklist is verified, it can be reverted back to FOR_VERIFICATION -->
                    <div class="row" *ngIf="service_status === enum_service_status.VERIFIED">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button (click)="submit(enum_action_flag.SUBMIT_DRAFT)" mat-raised-button color="warn"
                          class="btn m-1 btn-min_width">
                          Untag Verified
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        service_status === enum_service_status.FOR_APPROVAL ||
                        service_status === enum_service_status.DISAPPROVED
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button [disabled]="service_has_remarks  || someoneistyping " (click)="submitAgencyHead(enum_action_flag.APPROVE)"
                          mat-raised-button color="primary" class="btn btn-primary m-1 btn-min_width">
                          Approve
                        </button>
                      </div>
                    </div>
                    <div *ngIf="
                        service_status === enum_service_status.FOR_APPROVAL ||
                        service_status === enum_service_status.APPROVED
                      " class="row">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button [disabled]="!service_has_remarks || someoneistyping" (click)="submit(enum_action_flag.DISAPPROVE)" mat-raised-button
                          class="btn btn-danger m-1 btn-min_width">
                          Disapprove
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="service_status === enum_service_status.FOR_ARTA_REVIEW">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <!-- 0 = Compliant -->
                        <!--if blank ung new remarkinput or may unresolve has_remarks || with_remarks > 0-->
                        <button [disabled]="service_has_remarks || someoneistyping " (click)="
                            submitCMEOPO(enum_action_flag.CMEO_PO_APPROVE, 0)
                          " mat-raised-button color="primary" class="btn btn-primary m-1 btn-min_width">
                          For Approval - Next Level (COMPLIANT)
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="service_status === enum_service_status.FOR_ARTA_REVIEW">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <!-- 1 = Non-Compliant -->
                        <!--if not blank ung new remarkinput or wala unresolve !has_remarks && with_remarks == 0-->
                        <button [disabled]="!service_has_remarks  " (click)="
                            submitCMEOPO(enum_action_flag.CMEO_PO_APPROVE, 1)
                          " mat-raised-button class="btn btn-danger m-1 btn-min_width">
                          For Approval - Next Level (NON-COMPLIANT)
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        service_status ===
                        enum_service_status.FOR_ARTA_REVIEW_PO_APPROVED
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <!-- POs can now overturn their own assessment like with the DC -->
                        <!-- TODO: PO cannot overturn a non-compliant assessment unless all remarks are resolved -->
                        <button [disabled]="
                            services.assessment == enum_assessment.NON_COMPLIANT
                          " (click)="
                            submitCMEODC(enum_action_flag.CMEO_PO_APPROVE, true)
                          " mat-raised-button class="btn btn-danger m-1 btn-min_width">
                          Overturn Compliance Assessment (
                          <span>{{ services.assessment }}</span> )
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        service_status === enum_service_status.FOR_ARTA_REVIEW_DC
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button 
                            [disabled]="(services.assessment == enum_assessment.COMPLIANT ? service_has_remarks  || someoneistyping ? false : true : false  ) "
                            (click)="
                            submitCMEODC(enum_action_flag.CMEO_DC_APPROVE, true)
                          " mat-raised-button class="btn btn-danger m-1 btn-min_width">
                          Overturn Compliance Assessment (
                          <span>{{
                            services.assessment == "Compliant"
                            ? "NON-COMPLIANT"
                            : "COMPLIANT"
                            }}</span>
                          )
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        service_status === enum_service_status.FOR_ARTA_REVIEW_DC
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button [disabled]="
                            (service_has_remarks  || someoneistyping )&&
                            services.assessment == enum_assessment.COMPLIANT
                          " (click)="
                            submitCMEODC(enum_action_flag.CMEO_DC_APPROVE, false)
                          " mat-raised-button color="primary" class="btn btn-primary m-1 btn-min_width">
                          Keep Compliance Assessment (
                          <span>{{ services.assessment }}</span> )
                        </button>
                      </div>
                    </div>
                    <div class="row" *ngIf="
                        service_status === enum_service_status.FOR_ARTA_REVIEW_DIR
                      ">
                      <div class="col-6"></div>
                      <div class="col-6 align-right">
                        <button [disabled]="service_has_remarks  || someoneistyping" (click)="submit(enum_action_flag.CMEO_DIR_APPROVE)"
                          mat-raised-button color="primary" class="btn btn-primary m-1 btn-min_width">
                          Approve
                        </button>
                      </div>
                    </div>
                  </div>  
        </div>
        </mat-card-actions>
    </mat-card>
  </div>
  </mat-sidenav-container>


  


  

