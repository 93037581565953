import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-upload-charter',
  templateUrl: './upload-charter.component.html',
  styleUrls: ['./upload-charter.component.scss']
})
export class UploadCharterComponent implements OnInit {
  loading: boolean = false;
  isPhase1: boolean = true;
  // phase 1 - sample form
  phase1form = new UntypedFormGroup({ //date
    organization_grouping_01: new UntypedFormControl('', Validators.required),
    organization_level_00: new UntypedFormControl('', Validators.required),
    organization_level_01: new UntypedFormControl('', Validators.required),
    organization_level_02: new UntypedFormControl(''),
    organization_level_03: new UntypedFormControl(''),
    psgc_location: new UntypedFormControl(''),
    uacs_location_code: new UntypedFormControl(''),
    uacs_org_description: new UntypedFormControl(''),
    uacs_organization_code: new UntypedFormControl(''),
    filename: new UntypedFormControl('', Validators.required),
    date: new UntypedFormControl('', Validators.required),
    consolidated: new UntypedFormControl(''),
    agency_initials: new UntypedFormControl(''),
   });

   phase_01_filepath: any = '';

  constructor(private afs: AngularFirestore, private store: AngularFireStorage, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  togglePreview () {
    if(this.isPhase1){
      this.isPhase1 = false;
    }
    else{
      this.isPhase1 = true;
    }
     
  }


  saveFileInformation(event: any) {
    this.phase_01_filepath = event.target.files[0];

    if(this.phase_01_filepath){
      this.phase1form.get("filename")?.setValue(this.phase_01_filepath.name);
    }
  }

  async saveAndUpload () {
    this.loading = true;
    let documentID: any;
    //
    let uploadProgress!: Observable<number | undefined>;

    if(this.phase_01_filepath){
      const destinationPath = `citizen_charter20210511/${this.phase_01_filepath.name}`; //alert(Date.now().toString());
      const destinationRef = this.store.ref(destinationPath);
      const task = this.store.upload(destinationPath, this.phase_01_filepath);

      uploadProgress = task.percentageChanges();
    }

    

    this.afs.collection(`citizens-charter-phase-01`).add(this.phase1form.value).then((docRef:any) => {
      alert("create successful");
      documentID = docRef.id;
      
      // this.router.navigate(['/artemis/citizen-charter/view/'+ documentID]);
      
      this.router.navigate(['/artemis/citizen-charter/view'],{
        queryParams:{
          cc_id:documentID
        }
      });
      this.loading = false
    }).catch((error:any) =>{
      this.loading = false
      alert(error.message)
    });
  }
}
