<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Reports'"></app-artemis-navigation-header>

<div class="content">
    <div class="p-4">
        <div class="row">
            <div class="container">
                <!--Nav components-->
                <div class="row">
                    <div class="col-4">
                        <nav>
                            <ul class="nav flex-column">
                                <li class="nav-item" style="border: 1px black solid; text-align: center; padding: 5px;">ARTEMIS Summary Reports</li>
                                
                                
                               
                                <li (click)="openConsultationReport()" class="nav-item nav-item-click fw-bold" [ngClass]="{
                                'bg-dark text-white': consultation_report}" style="border: 1px black solid;padding: 10px; ">
                                    <a>Existing Citizen's Charter</a>
                                </li>
                                <li [ngClass]="{
                                'bg-dark text-white': existing_report}"  (click)="openExistingReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Multi Stage</a>
                                </li>
                                <li  [ngClass]="{
                                'bg-dark text-white': proposed_report}" (click)="openProposedReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>By Agency</a>
                                </li>                           
                                <li  [ngClass]="{
                                'bg-dark text-white': agency_compliance_report}" (click)="openComplianceReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>Agency Compliance</a>
                                </li>
                                <li  [ngClass]="{
                                'bg-dark text-white': tickets_report}" (click)="openTicketReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>ARTEMIS Tickets</a>
                                </li>     
                                <li  [ngClass]="{
                                'bg-dark text-white': use_mgt_report}" (click)="openUserManagementReport()" class="nav-item nav-item-click fw-bold" style="border: 1px black solid;padding: 10px; ">
                                    <a>User Management</a>
                                </li>
                                
                                
                                
                            </ul>


                        </nav>
                    </div>
                    <div class="col-8 ">
                        <div class="container " style="border: 2px solid black; padding: 30px; ">
                            <!--Main Content Here -->
                            <app-existing-citizens-charter-report *ngIf="consultation_report"></app-existing-citizens-charter-report>
                            <app-multi-stage *ngIf="existing_report"></app-multi-stage>
                            <app-artemis-by-agency-report *ngIf="proposed_report"></app-artemis-by-agency-report>
                            <app-artemis-agency-compliance-report *ngIf="agency_compliance_report"></app-artemis-agency-compliance-report>
                            <app-artemis-ticket-report *ngIf="tickets_report"></app-artemis-ticket-report>
                            <app-artemis-user-management *ngIf="use_mgt_report"></app-artemis-user-management>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
