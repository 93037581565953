import { first, take } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import * as admin from 'firebase-admin';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';

import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';


@Component({
  selector: 'app-crud-users',
  templateUrl: './crud-users.component.html',
  styleUrls: ['./crud-users.component.scss']
})
export class CrudUsersComponent implements OnInit  {
  
  displayedColumns: string[] = ['selector', 'Timestamp','Email', 'Agency', 'User Type', 'User Subtype Type', 'Actions'];
  dataSource: MatTableDataSource<any>;

  cred: any;
  users:any[] = [];
  toggleAll: boolean = false;
  triggerAll: boolean = false;
  entries: any = [];
  loader: boolean = true;
  enum_govt_account_subtype = GovernmentAccountSubtype;
  selectedAgency: any;
  private agencies:any[] = [];

  public TypeOfAccount = Array<string>();
  public TypeOfAgency = Array<any>();
  public TypeOfAgencyRole = Array<string>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public auth: AngularFireAuth, private afs: AngularFirestore, private router: Router) { }

  ngOnInit(): void {
    this.loadUsers()
    this.TypeOfAccount = Array<string>();
    this.TypeOfAgency = Array<any>();
    this.loadAgency()
  }

  filterType(type: string){
    this.TypeOfAccount = [];
    if(type === "Personal" ){
      this.TypeOfAccount.push("Private Individual");
    }
    else if(type === "Company"){
      this.TypeOfAccount.push("Organization");
    }
    else if(type === "Government"){
      this.TypeOfAccount.push(GovernmentAccountSubtype.AGENCY_ENC);
      this.TypeOfAccount.push(GovernmentAccountSubtype.AGENCY_VER);
      this.TypeOfAccount.push(GovernmentAccountSubtype.AGENCY_DIR);
      
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_CMEO_PO);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_CMEO_DC);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_CMEO_DIR);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_BRO_PO);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_BRO_DC);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_BRO_DIR);

      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_ADMIN);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_DDG);
      this.TypeOfAccount.push(GovernmentAccountSubtype.ARTA_DG);
    }

    return this.TypeOfAccount;
  }

  filterAgency(type: string){
    this.TypeOfAgency = []

    if(type === "Government"){
      this.agencies.forEach(agency=>{
        this.TypeOfAgency.push(agency)
      })
    }
    else{
      this.TypeOfAgency.push({name:"Public/Non Government", id:"non-agency"})
    }

    return this.TypeOfAgency;
  }

  agencySelector(agencyId: any, entryId: any){
    if (agencyId === entryId) return true;
    else if (agencyId === 'non-agency') return true;

    return false
  }

  agencySelectDisabler(account_subtype:any, ARTA_ADMIN:any, account_type:any){
    if(account_subtype === ARTA_ADMIN) return true;
    else if(account_type === "Personal" || account_type === "Company")return true
    
    return false
  }

  async updateSubType(entry:any, option: any){
    let pbris:any;
    let artemis: any;

    if(option === GovernmentAccountSubtype.ARTA_ADMIN){
       pbris = true
       artemis = true
    }

    else{
      pbris = entry.credentials.access_pbris
      artemis = entry.credentials.access_artemis
    }

    await this.afs.doc(`Users/${entry.id}`).update({
      credentials:{
        access_artemis: artemis,
        access_pbris: pbris,
        account_type: entry.credentials.account_type,
        account_subtype: option,
        email: entry.credentials.email,
        loggedIn: entry.credentials.loggedIn,
        mobile_number: entry.credentials.mobile_number,
        sinceMember: entry.credentials.sinceMember,
        uid: entry.credentials.uid,
        
      }
     }).then(() => {
       alert("User subtype updated!")
     }).catch(error =>{
       alert(error.message)
     });
  }

  async updateAgency(tempEntry:any, agencyId: any) {
    //console.log("tempEntry: ",tempEntry)
    //console.log("agencyId: ", agencyId)
    
    // const ctr = this.agencies.map(res=>res.id).indexOf(agencyId)
    // let agency = ctr>=0 ? this.agencies[ctr] : false
    // if(agency){
    //   console.log("selected agency ",agency)
      
    //   tempEntry.government.agency = agencyId

    //   //updates government w/ the new agency id
    //   this.afs.doc(`Users/${tempEntry.id}`).update({
    //     government: tempEntry.government
    //   }).then(()=>{
    //     alert("User agency updated!")
    //   }).catch(error =>{
    //     alert(error.message)
    //   });

    // }else{
    //   if(agencyId === "non-agency")
    //     console.error("User/Company is using a non govenrment account and thus unable to choose an agency")
    //   else
    //     console.error("Error: No such Agency w/ id ",agencyId)
    // }
  }

  triggerToggleAll(event: any){
    this.entries = []
    if(event.target.checked){
      this.toggleAll = true
      this.users.forEach((users:any )=>{
        this.entries.push(users)
      })
    }
    else{
      this.toggleAll = false
      this.entries = []
    }
  }

  checkCheckBoxvalue(event:any, entry:any){
    if(event.target.checked){
      this.entries.push(entry)
      if(this.entries.length < 4){
        this.triggerAll = false;
      }
      else{
        this.triggerAll = true;
      }
    }
    else{
      this.entries.pop(entry)
      this.toggleAll = false
    }
  }

  checkCheckBoxSelected(entry:any){
    if(this.entries.indexOf(entry) == -1)
      return false;
    else
      return true;
  }

  loadUsers(){
    this.auth.user.subscribe(data=>{
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred:any) =>{
        this.cred = cred.data()
        //if(this.cred.credentials.account_subtype === "ARTA - IT/Admin"){
        this.afs.collection(`Users`, filter => filter.orderBy('credentials.sinceMember', "asc")).snapshotChanges()
        .subscribe(
          (data: any) => {
            this.loader = false;
            this.users = []
            data.forEach((info: any) => {  
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              item.sinceMember = item.credentials.sinceMember;
              item.email = item.credentials.email;
              item.account_type = item.credentials.account_type;
    
              if (this.users.includes(item) === false){
                this.users.push(item);
              }     
            });
              
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      })
    })   
  }

  private loadAgency(){
    this.afs.collection('Agency', filter => filter.orderBy('name', "asc")).snapshotChanges().pipe(take(1)).subscribe((agencyRetrievedFromFirebase: any) => {
      this.loader = false;
      this.agencies = []
        agencyRetrievedFromFirebase.forEach((agencyRetrieved: any) => {  
          let agency: any = agencyRetrieved.payload.doc.data();
          agency.id = agencyRetrieved.payload.doc.id;

          if (this.agencies.includes(agency) === false){
            // console.log("loadAgency ",agency.name)
            this.agencies.push(agency);
          }     
        });
    })
  }

  setARTAccessibility(entry:any, status: boolean){
    let pbris = entry.credentials.access_artemis
    let artemis = status

    this.afs.doc(`Users/${entry.id}`).update({
     credentials:{
       access_artemis: status,
       access_pbris: entry.credentials.access_pbris,
       account_type: entry.credentials.account_type,
       account_subtype: entry.credentials.account_subtype,
       email: entry.credentials.email,
       loggedIn: entry.credentials.loggedIn,
       mobile_number: entry.credentials.mobile_number,
       sinceMember: entry.credentials.sinceMember,
       uid: entry.credentials.uid,
       
     }
    }).then(() => {
    }).catch(error =>{
      alert(error.message)
    });
  }

  setPBRAccessibility(entry:any, status: boolean){
    let pbris = status
    let artemis = entry.credentials.access_artemis
    this.afs.doc(`Users/${entry.id}`).update({
      credentials:{
        access_pbris: pbris,
        access_artemis: artemis,
        account_type: entry.credentials.account_type,
        account_subtype: entry.credentials.account_subtype,
        email: entry.credentials.email,
        loggedIn: entry.credentials.loggedIn,
        mobile_number: entry.credentials.mobile_number,
        sinceMember: entry.credentials.sinceMember,
        uid: entry.credentials.uid,
        
      }
    }).then(() => {
    }).catch(error =>{
      alert(error.message)
    });
  }

  setAllAccessibility(entry:any, status: boolean){
    this.afs.doc(`Users/${entry.id}`).update({
      credentials:{
        access_pbris: status,
        access_artemis: status,
        account_type: entry.credentials.account_type,
        account_subtype: entry.credentials.account_subtype,
        email: entry.credentials.email,
        loggedIn: entry.credentials.loggedIn,
        mobile_number: entry.credentials.mobile_number,
        sinceMember: entry.credentials.sinceMember,
        uid: entry.credentials.uid,
        
      }
    }).then(() => {
      this.loadUsers()
    }).catch(error =>{
      alert(error.message)
    });
  }

  multPBRIS(status: boolean){
    this.entries.forEach((entry:any) =>{
      this.setPBRAccessibility(entry, status)
    })
    this.entries = [];
  }

  multARTEMIS(status: boolean){
    this.entries.forEach((entry:any) =>{
      this.setARTAccessibility(entry, status)
    })
    this.entries = [];
  }

  multAll(status: boolean){
    this.entries.forEach((entry:any) =>{
      this.setAllAccessibility(entry, status)
    })
    this.entries = [];
  }

  multDelete(){
    this.entries.forEach((entry:any) => {
      this.afs.doc(`Users/${entry.id}`).delete().then((data:any) => {
        alert("Remove successful")
      })
    })
    this.entries = []
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
