import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { error } from 'console';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';

@Component({
  selector: 'app-artemis-navigation-header',
  templateUrl: './artemis-navigation-header.component.html',
  styleUrls: ['./artemis-navigation-header.component.scss']
})
export class ArtemisNavigationHeaderComponent implements OnInit {

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  style_theme: any = 'theme-default'; //current theme

  isLoggedIn: boolean = false;
  isAdmin: boolean = false;
  isArta: boolean = false;
  isAgency: boolean = false;
  hasPage: boolean = false;
  agencyAdmin: boolean = false;
  toAgencyPage: string = "";
  toAgencyMemberPage: string = "";
  canViewCC: boolean = false;
  toAgencyCCPage: string = ""
  toAgencyCCPageQP: any = {}
  accountSettingsLink: any;
  isReportAccessible: boolean = false;
  isCharterAccessible: boolean = false;
  loader: boolean = true;

  cred: any;

  @Input() currentPage: any;
  userType: any = sessionStorage.getItem("user_type");
  agencyName: any = sessionStorage.getItem("agency_name");
  userSubtype: any = sessionStorage.getItem("account_subtype");
  showInbox: boolean = true;

  /**
   * FormGroup Variables
   */
  searchform: UntypedFormGroup = new UntypedFormGroup({
    searchfield: new UntypedFormControl('', Validators.required),
    searchsuggestions: new UntypedFormControl('', Validators.required),
  });
  searchSuggestions: any;

  /* If page is active */
  public activeHome: any;
  public activeReports: any;
  public activeAboutUs: any;
  public activeContactUs: any;
  public activeHelp: any;
  public activeCharter: any;
  public activeProfile: any;
  public activeSearch: any;
  public activeSurvey: any;
  public activeService: any;


  public dateToday: number = Date.now();

  constructor(public auth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
  }

  ngOnInit(): void {

    /**check if session variables are still there */
    this.checkSessionVariables();

    this.searchSuggestions = [];

    /* Reset All to false */
    this.activeHome = '';
    this.activeReports = '';
    this.activeAboutUs = '';
    this.activeContactUs = '';
    this.activeHelp = '';
    this.activeCharter = '';
    this.activeSearch = '';
    this.activeProfile = '';
    this.activeSurvey = '';
    this.activeService = '';

    switch (this.currentPage) {
      case 'Home':
        this.activeHome = 'active';
        break;
      case 'Reports':
        this.activeReports = 'active';
        break;
      case 'AboutUs':
        this.activeAboutUs = 'active';
        break;
      case 'ContactUs':
        this.activeContactUs = 'active';
        break;
      case 'Help':
        this.activeHelp = 'active';
        break;
      case 'Charter':
        this.activeCharter = 'active';
        break;
      case 'SearchPage':
        this.activeSearch = 'active';
        break;
      case 'Profile':
        this.activeProfile = 'active';
        break;
      case 'Survey':
        this.activeSurvey = 'active';
        break;
      case 'Service':
        this.activeService = 'active';
        break;
    }
  }





  /**
   * due to sessionStorage reseting for every new tab or when you reopen your browser
   * as long as firebase auth is logged in, it will reload your session variables
   * it is placed here "navigation header" since it is visible on all pages that use themes
  */
  async checkSessionVariables() {
    /**
     * checks if user is logged in
     */
    if (await this.auth.user) {
      if (sessionStorage.getItem("user_type")) {
        this.auth.onAuthStateChanged((user: any) => {
          if (user) {
            if (user.uid) {
              this.accountSettingsLink = "/account/" + user.uid
            }
          }
        }).catch(() => {
          //console.log("Error getting user data")
        })
        this.setTheme();
        this.loader = false;

      }
      else {
        /**
         * for reloading user types {default, agency, arta}
         */
        this.auth.onAuthStateChanged(user => {
          if (user?.uid) {
            // set logged in status
            this.isLoggedIn = false;
            // get account type from firestore
            this.afs.doc(`Users/${user.uid}`).snapshotChanges().subscribe(
              (data: any) => {
                let item = data.payload.data()
                this.accountSettingsLink = "/account/" + user.uid
                if (item.credentials.account_subtype.includes("ARTA")) {
                  sessionStorage.setItem("user_type", "arta");
                  this.isCharterAccessible = true;
                  this.isReportAccessible = true;
                  this.isArta = true;


                  switch(item.credentials.account_subtype){
                    case GovernmentAccountSubtype.ARTA_ADMIN:{
                      sessionStorage.setItem("arta_admin", "true");
                      break;
                    }
                    case GovernmentAccountSubtype.AGENCY_ADMIN:{
                      sessionStorage.setItem("agency_admin", "true");
                      break;
                    }
                  }
                  // if (item.credentials.account_subtype.toLowerCase().includes("admin")) {
                  //   sessionStorage.setItem("arta_admin", "true");
                  //   this.loader = false;
                  // }

                  this.loader = false;
                }
                else if (item.credentials.account_type.includes("Government") || item.credentials.account_type.includes("Company")) {
                  sessionStorage.setItem("user_type", "agency");
                  this.isCharterAccessible = true;
                  this.isReportAccessible = true;

                  if (item.credentials.account_type.includes("Government")) {
                    this.isAgency = true
                    this.hasPage = true;

                    switch(item.credentials.account_subtype){
                      case GovernmentAccountSubtype.AGENCY_ADMIN:{
                        sessionStorage.setItem("agency_admin", "true");
                        break;
                      }
                    }

                    this.toAgencyPage = "/artemis/view/agency/" + item.government.agency;
                    if (item.credentials.account_subtype.trim().toLowerCase().includes('government') && item.credentials.account_subtype.trim().toLowerCase().includes('it/admin')) {
                      this.agencyAdmin = true;
                      this.toAgencyMemberPage = "/agency/view/" + item.government.agency + "/members";
                    }

                    if (item.credentials.account_type.trim().toLowerCase().includes('government')
                      && (item.credentials.account_subtype.trim().toLowerCase().includes('encoder')
                        || item.credentials.account_subtype.trim().toLowerCase().includes('verifier')
                        || item.credentials.account_subtype.trim().toLowerCase().includes('agency head'))) {

                      this.toAgencyCCPage = "/artemis/citizen-charter/view/report"
                      this.toAgencyCCPageQP.agency = item.government.agency
                      // +item.government.agency+

                      this.canViewCC = true
                    }

                    this.loader = false;
                  }
                  this.loader = false;
                }
                else {
                  sessionStorage.setItem("user_type", "personal");
                  this.loader = false;
                }
                this.loader = false;
                this.setTheme();
                window.location.reload();
              });
          }
          else {
            sessionStorage.setItem("user_type", "default");
            this.showInbox = false;
            this.loader = false;
          }
        }).catch(() => {
          //console.log("Error reading user data")
        });
      }
      //
    } else {
      this.showInbox = false;
    }
  }

  async setTheme() {
    switch (sessionStorage.getItem("user_type")) {
      case 'arta':
        this.style_theme = 'theme-arta';
        this.isLoggedIn = true;
        this.isCharterAccessible = true;
        this.isReportAccessible = true;
        if (sessionStorage.getItem("arta_admin") == "true") {
          this.isArta = true;
          this.isAdmin = true;
          this.loader = false;
        }
        this.loader = false;
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        this.isAgency = true;
        this.isLoggedIn = true;
        this.isCharterAccessible = true;
        if (await this.auth.user) {
          this.auth.onAuthStateChanged((user: any) => {
            if (user) {
              if (user.uid) {
                this.afs.collection('Users').doc(user.uid).snapshotChanges()
                  .subscribe(data => {
                    const item: any = data.payload.data();
                    this.hasPage = true;
                    this.toAgencyPage = "/artemis/view/agency/" + item.government.agency
                    if (item.credentials.account_subtype.trim().toLowerCase().includes('government') && item.credentials.account_subtype.trim().toLowerCase().includes('it/admin')) {
                      this.agencyAdmin = true
                      this.toAgencyMemberPage = "/agency/view/" + item.government.agency + "/members";
                    }

                    if (item.credentials.account_type.trim().toLowerCase().includes('government')
                      && (item.credentials.account_subtype.trim().toLowerCase().includes('encoder')
                        || item.credentials.account_subtype.trim().toLowerCase().includes('verifier')
                        || item.credentials.account_subtype.trim().toLowerCase().includes('agency head'))) {

                      this.toAgencyCCPage = "/artemis/citizen-charter/view/report"
                      this.toAgencyCCPageQP.agency = item.government.agency
                      this.canViewCC = true
                    }
                    this.loader = false;
                  })
              }else{ this.style_theme = 'theme-default';   this.loader = false;}
            }else{  this.style_theme = 'theme-default';   this.loader = false; }
          },error=>{ 
            this.style_theme = 'theme-default';
            this.loader = false;
          })
        }

        break;
      case 'personal':
        this.style_theme = 'theme-default';
        this.isLoggedIn = true;
        this.loader = false;
        break;
      default:
        this.style_theme = 'theme-default';
        this.loader = false;
    }
  }

  getSuggestions() {
    this.searchSuggestions = [];
    if (this.searchform.value.searchfield == '') {

    }
    else {
      this.afs.collection("Services").snapshotChanges().subscribe(
        (data: any) => {
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();

            if (this.searchSuggestions.includes(item.name_and_details.service_name) == false) {
              if (item.name_and_details.service_name.toLowerCase().includes(this.searchform.value.searchfield.toLowerCase())) {
                this.searchSuggestions.push(item.name_and_details.service_name);
              }
            }
          });
        })
    }
  }

  selectItem(item: any) {
    this.searchSuggestions = [];
    this.searchform.get("searchfield")?.setValue(item);
  }

  search() {

    let JSONarray = {
      keyword: this.searchform.value.searchfield,
    };

    // Temporarily search citizens charter until service finder is for implementation RWL 20210614
    /* if(this.searchform.value.searchfield=="")
      this.router.navigate(['/artemis/advanced-search']);  // define your component where you want to go
    else
      this.router.navigate(['/artemis/results'], {queryParams: JSONarray} );  // define your component where you want to go */

    this.router.navigate([`/artemis/citizen-charter`], { queryParams: JSONarray });  // define your component where you want to go
  }

  logout() {

    this.auth.signOut().then(() => {
      sessionStorage.clear();
      this.router.navigate(['/']);
    });
  }

  goToCCReport() {
    //console.log(this.toAgencyCCPage + '?', this.toAgencyCCPageQP)
    this.router.navigate([this.toAgencyCCPage], {
      queryParams: this.toAgencyCCPageQP
    })
  }
}