import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-productivity-user-management',
  templateUrl: './productivity-user-management.component.html',
  styleUrls: ['./productivity-user-management.component.scss']
})
export class ProductivityUserManagementComponent implements OnInit {

  users_count: any = [];

  //COUNT PER USER TYPE
  guests_count: any = [];
  individual_count: any = [];
  organization_count: any = [];
  government_count: any = [];

  dateInfo: any;

  constructor(private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.loadUsersCounts();
  }
  
  receivedDatePicker(p:any){
    this.dateInfo = p;
  }
  
  async loadUsersCounts() {
    this.users_count = [];

    this.afs.collection("Users").snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              
              this.users_count.push(item);

              // if(item['credentials']['account_type'] == "Personal"){
              //   this.individual_count = []
              //   this.individual_count.push(item)
              // } 
              if(item.credentials.acount_type && 
                item.credentials.acount_type.toLowerCase() == 'personal'){
                  this.individual_count = []
                  this.individual_count.push(item)
                }
              // else if(item['credentials']['account_type'] == "Guest"){
              //   this.guests_count = []
              //   this.guests_count.push(item)
              // }  
              else if(item['credentials']['account_type'] == "Organization"){
                this.organization_count = []
                this.organization_count.push(item)
              }        
              else if(item['credentials']['account_type'] == "Government"){
                this.organization_count = []
                this.organization_count.push(item)
              } 
              
              console.log(" Individual: " + item.credentials.acount_type);        
            });
          }
        )
      )
      .subscribe()
  }  
}
