<!--NAVBAR-->
<app-artemis-navigation-header
  [currentPage]="'Home'"
></app-artemis-navigation-header>

<div class="container-fluid">
  <div class="content">
    <!-- Carousel-->
    <div class="row">
      <app-artemis-home-page-header-board></app-artemis-home-page-header-board>
    </div>
    <!-- Consultations and Regulations Pane -->
    <!-- <div class="row mb-5"> -->
      <!-- Service Finder -->
      <!-- <div class="col-3">
        <app-artemis-home-page-service-finder></app-artemis-home-page-service-finder>
      </div> -->

      <!-- Recent Changes / Trending -->
      <!-- <div class="col">
        <app-artemis-home-page-recent-trending></app-artemis-home-page-recent-trending>
      </div>
    </div> -->

<!--Search Area-->

<!--Charters-->

       
<div class="row">
  <div class="col-4">
    <div class="row">
      <app-artemis-home-page-service-finder (searchFilter)="forwardSearchFilters($event)"></app-artemis-home-page-service-finder> 
 
    </div>
    <div class="row">
      <div>
        <div class="row">
          <h4 class='text-primary list-header p-4'><span class="p-2"><i class="fas fa-link"></i></span>  CHARTERS</h4>
          <!--cards-->
          <div class="list-container">
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'National Government Agencies'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/nga.png" class="img-fluid" />
                </div>
               
              </a>
            </div>  
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Local Government Agencies'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/lgu.png" class="img-fluid" />
                </div>
               
              </a>
            </div>    
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Government-Owned & Controlled Corporations'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/gocc.png" class="img-fluid" />
                </div>
              
              </a>
            </div>    
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Hospitals'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/hosp.png" class="img-fluid" />
                </div>
                
              </a>
            </div>
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'State Universities and Colleges'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/suc.png" class="img-fluid" />
                </div>
               
              </a>
            </div>  
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Local Water Districts'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/wd.png" class="img-fluid" />
                </div>
              
              </a>
            </div>    
            <div class="card text-center ">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'Constitutional Offices'}" class="card-link">
                <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="../../assets/artemis_landing_icons_acronym/cc.png" class="img-fluid" />
                </div>
               
              </a>
            </div>    
        </div>    
          <!--ul class="list-group my-2">
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'executive'}" class="list-group-item align-self-center">
                  <img src="../../assets/artemis_landing/nga.png" class="img-fluid m-0 align-self-center">
              </a>
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'lgu'}" class="list-group-item align-self-center">
                  <img src="../../assets/artemis_landing/lgu.png" class="img-fluid m-0 align-self-center">
              </a>
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'gocc'}" class="list-group-item align-self-center">
                  <img src="../../assets/artemis_landing/gocc.png" class="img-fluid m-0 align-self-center">
              </a>
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'hospitals'}" class="list-group-item align-self-center">
                  <img src="../../assets/artemis_landing/hospital.png" class="img-fluid m-0 align-self-center">
              </a>
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'suc'}" class="list-group-item align-self-center">
                  <img src="../../assets/artemis_landing/suc.png" class="img-fluid m-0 align-self-center">
              </a>
              <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'water district'}" class="list-group-item align-self-center">
                <img src="../../assets/artemis_landing/wd.png" class="img-fluid m-0 align-self-center">
            </a>
            <a routerLink="/artemis/citizen-charter" [queryParams]="{keyword: 'constitutional offices'}" class="list-group-item align-self-center">
              <img src="../../assets/artemis_landing/cc.png" class="img-fluid m-0 align-self-center">
          </a>
          </ul-->        
      </div>
      </div>
    </div>

  
    
  </div>

  <div class="col">
    <app-artemis-home-page-recent-trending *ngIf="!serviceFinderResultView"></app-artemis-home-page-recent-trending>
    <!----
     <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
      <mat-button-toggle checked >NGA</mat-button-toggle>
      <mat-button-toggle >LGU</mat-button-toggle>
      <mat-button-toggle >GOCC</mat-button-toggle>
      <mat-button-toggle   >HOSP</mat-button-toggle>
      <mat-button-toggle   >SUC</mat-button-toggle>
      <mat-button-toggle   >WD</mat-button-toggle>
      <mat-button-toggle   >CONCOM</mat-button-toggle>
    </mat-button-toggle-group>-->
    <app-artemis-home-page-services-results (closeForm)="closeSearchResults($event)" *ngIf="serviceFinderResultView" [receivedfromserviceFinder]="receivedfromserviceFinder"></app-artemis-home-page-services-results>
  </div>
</div>





<!--End of Search Area-->



    <div class="row"> 
      <div class="container social-icon">
        <!--h3 class="text-primary list-header">>>>QUICK REPORTS----</h3-->
        <div class="d-flex flex-column"  >
          <!--<table>
            <tr>
                <th></th>
                <th *ngFor="let column of displayedColumns" class="p-2">
                    {{column.name}}
                </th>
                comment here<td>
                    {{item.designation}}
                </td>
                <td>
                    {{item.email}}
                </td> -->
            <!--/tr>
            
            <tr>
              <td class="p-2">
                HOSPITALS
              </td>
              <td class="p-2">
                {{draft_list.length}}
              </td>
              
              <td class="p-2">
                {{for_verification_list.length}}
              </td>
              
              <td class="p-2">
                {{verified_list.length}}
              </td>
              
              <td class="p-2">
                {{for_approval_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_list.length}}
              </td>
              
              <td class="p-2">
                {{for_revision_list.length}}
              </td>
              
              <td class="p-2">
                {{for_notification_list.length}}
              </td>
              
              <td class="p-2">
                {{disapproved__list.length}}
              </td>
              
              <td class="p-2">
                {{resubmitted__list.length}}
              </td>
              
              <td class="p-2">
                {{for_arta_review_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_arta_po_list.length}}
              </td>
              
              <td class="p-2">
                {{for_arta_review_dc_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_arta_dc_list.length}}
              </td>
              
              <td class="p-2">
                {{for_arta_review_director_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_director_list.length}}
              </td>
              
              <td class="p-2">
                {{return_to_agency_list.length}}
              </td>
              
              <td class="p-2">
                {{posted_list.length}}
              </td>
            </tr>
            
            <tr>
              <td class="p-2">
                NGA
              </td>
              <td class="p-2">
                {{draft_list.length}}
              </td>
              
              <td class="p-2">
                {{for_verification_list.length}}
              </td>
              
              <td class="p-2">
                {{verified_list.length}}
              </td>
              
              <td class="p-2">
                {{for_approval_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_list.length}}
              </td>
              
              <td class="p-2">
                {{for_revision_list.length}}
              </td>
              
              <td class="p-2">
                {{for_notification_list.length}}
              </td>
              
              <td class="p-2">
                {{disapproved__list.length}}
              </td>
              
              <td class="p-2">
                {{resubmitted__list.length}}
              </td>
              
              <td class="p-2">
                {{for_arta_review_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_arta_po_list.length}}
              </td>
              
              <td class="p-2">
                {{for_arta_review_dc_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_arta_dc_list.length}}
              </td>
              
              <td class="p-2">
                {{for_arta_review_director_list.length}}
              </td>
              
              <td class="p-2">
                {{approved_director_list.length}}
              </td>
              
              <td class="p-2">
                {{return_to_agency_list.length}}
              </td>
              
              <td class="p-2">
                {{posted_list.length}}
              </td>
            </tr>
        </table>
      -->
          <!-- <div class="example-container mat-elevation-z8">
            <div class="p-2">
              <mat-form-field appearance="outline" class="pt-3 w-100">
                <mat-label style="font-size: 1rem;"><i class="fas fa-search"></i> Search...</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
              </mat-form-field>
            </div>

            <mat-table #table [dataSource]="dataSource" multiTemplateDataRows matSort #sort="matSort">
               
              <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
              </ng-container>

              <ng-container matColumnDef="DRAFT" >
                <th mat-header-cell *matHeaderCellDef> DRAFT</th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
              </ng-container>
               
              <ng-container matColumnDef="FOR VERIFICATION" >
                <th mat-header-cell *matHeaderCellDef> FOR VERIFICATION</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="VERIFIED" >
                <th mat-header-cell *matHeaderCellDef> VERIFIED</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="FOR APPROVAL" >
                <th mat-header-cell *matHeaderCellDef> FOR APPROVAL</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="APPROVED" >
                <th mat-header-cell *matHeaderCellDef> APPROVED</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="FOR REVISION" >
                <th mat-header-cell *matHeaderCellDef> FOR REVISION</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="FOR MODIFICATION" >
                <th mat-header-cell *matHeaderCellDef> FOR MODIFICATION</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="DISAPPROVED" >
                <th mat-header-cell *matHeaderCellDef> DISAPPROVED</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="RESUBMITTED" >
                <th mat-header-cell *matHeaderCellDef> RESUBMITTED</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="FOR ARTA REVIEW" >
                <th mat-header-cell *matHeaderCellDef> FOR ARTA REVIEW</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="APPROVED - ARTA CMEO PO" >
                <th mat-header-cell *matHeaderCellDef> APPROVED - ARTA CMEO PO</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="APPROVED - ARTA CMEO DC" >
                <th mat-header-cell *matHeaderCellDef> APPROVED - ARTA CMEO DC</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="FOR ARTA REVIEW - DIRECTOR" >
                <th mat-header-cell *matHeaderCellDef> FOR ARTA REVIEW - DIRECTOR</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="APPROVED - ARTA CMEO DIRECTOR" >
                <th mat-header-cell *matHeaderCellDef> APPROVED - ARTA CMEO DIRECTOR</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="RETURN SERVICE TO AGENCY" >
                <th mat-header-cell *matHeaderCellDef> RETURN SERVICE TO AGENCY</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>
               
              <ng-container matColumnDef="POSTED" >
                <th mat-header-cell *matHeaderCellDef> POSTED</th>
                <td mat-cell *matCellDef="let element"> test </td>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>
               
            </mat-table>
            <mat-paginator class="paginator" #MatPaginator pageSize="5"></mat-paginator>
          </div> -->
        </div>
      </div>
    </div>    

   
  </div>
</div>

<!-- Pre Footer -->
<app-artemis-home-page-announcements></app-artemis-home-page-announcements>
<app-artemis-home-page-footer-banner></app-artemis-home-page-footer-banner>
