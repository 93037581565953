<app-navbar></app-navbar>

<div class="content">
    <div class="container-fluid">

        <div class="card shadow p-3">
            <div card-header>
                <h4>Update Regulation</h4>
            </div>
            <div class="card-body form-container">
                <form [formGroup]="phase1form">

                    <div class="form-group">
                        <label class="form-input-header fw-bold" for="short_title">Title *</label>
                        <input type="text" class="form-control" formControlName="short_title" />
                    </div>
                    <div class="form-group  fw-bold">
                        <label class="form-input-header" for="subject_full_title">Subject *</label>
                        <input type="text" class="form-control" formControlName="subject_full_title" />
                    </div>
                    <div class="form-group  fw-bold">
                        <label class="form-input-header" for="doc_date">Issue Date *</label>
                        <input type="text" placeholder="mm/dd/yyyy" class="form-control" formControlName="doc_date" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="folder_name_id">Folder Number ID</label>
                        <input type="text" class="form-control" formControlName="folder_name_id" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="issuance_no">Issuance Number</label>
                        <input type="text" class="form-control" formControlName="issuance_no" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="issuance_type">Agency Section</label>
                        <input type="text" class="form-control" formControlName="issuance_type" />
                    </div>
                    <div class="form-group">
                        <label class="form-input-header" for="sector">Sector</label>
                        <input type="text" class="form-control" formControlName="sector" />
                    </div>
                    <div class="form-group" *ngIf="phase1form.invalid">
                        <p class="fst-italic">* Required fields - must be filled in order to Submit the form</p>

                    </div>
                    <a href="/pbris/regulations/view/{{id}}" class="btn btn-lg btn-link text-dark m-3 fw-bold">Cancel Update</a>

                    <button class="btn btn-lg btn-primary m-3 float-end" (click)="saveAndUpload()" id="bt " [disabled]="phase1form.invalid || loading">
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span> Update</button>
                </form>
            </div>
        </div>

    </div>
</div>