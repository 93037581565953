<app-navbar></app-navbar>
<div class="content">
    <div class="jumbotron bg-primary p-4">
        <!-- PBRIS title with search Bar-->
        <div class="container">
            <div class="row">
                <div class="col-2 text-start">
                    <a href="/pbris/references" class="btn btn-lg btn-light align-self-center">Return</a>
                </div>
                <div class="col-6">
                    <h1 class="text-white">Sectors</h1>
                    <h3 class="text-white">Section A</h3>
                    <h6 class="text-white">Agriculture, forestry and fishing</h6>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid bg-white">
        <div class="row m-3">
            <h4>Definition</h4>
            <p>This section includes the exploitation of vegetal and animal natural resources, comprising the activities of growing crops, raising and breeding of animals, harvesting of timber and other plants, animals or animal products from a farm or their
                natural habitats.</p>
        </div>
    </div>
</div>