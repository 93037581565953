<div class="card">
    <h5 class="card-header">Proposed Regulations View</h5>
    <div class="card-body p-0">
      <div class="table-responsive" style="max-height: 500px;">
        <table class="table">
          <thead class="text-muted">
            <tr>
            <th class="table-header fw-bold">Regulation Short Title</th>
              <th class="table-header fw-bold">Agency</th>
              <th class="table-header fw-bold">Officer</th>
              <!-- <th class="table-header fw-bold">Status</th> -->
              <th class="table-header fw-bold">Date Generated</th>
              <th class="table-header fw-bold">Action</th>
            </tr>
          </thead>
          <tbody class="card-text">
            <tr *ngFor="let list of workList; let i = index">
              <th scope="row">{{ list.notif_data.subject }}</th>
              <td>{{ list.notif_data.user_details?.fromAgency }}</td>
              <td>{{ list.notif_data.user_details?.fromOfficer }}</td>
              <!-- <td>{{ list.worklist_data.status }}</td> -->
              <td>{{ list.notif_data.user_details?.dateGenerated.toDate() | date: "MM/dd/yyyy" }}</td>
              <td>
                <button type="button" class="btn btn-info" (click)="sampleModal.show(); openModal(list.id)" >View</button>
                <!-- <button type="button" class="btn btn-info" (click)="sampleModal.show();" >View</button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  
    <!-- Modal -->
    <div style="overflow-y: auto" data-keyboard="false" data-backdrop="static" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Review Proposed Regulation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide(); closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body max-contain">
            <dl class="row">
              <dt class="col-sm-3">Regulation Title</dt>
              <dd class="col-sm-9">{{title}} </dd>
  
              <dt class="col-sm-3 text-truncate">Regulation Subject</dt>
              <dd class="col-sm-9"> {{subject}} </dd>
            
              <dt class="col-sm-3"> Regulation Instrument</dt>
              <dd class="col-sm-9">{{reg_instrument}}</dd>              
              
              <dt class="col-sm-3 text-truncate" >Proposal Date  </dt>
              <dd class="col-sm-9">{{doc_date}}</dd>
            
              <dt class="col-sm-3 text-truncate">Issuing Agency</dt>
                <dd class="col-sm-9">
                    <li *ngFor="let agencyList of reg_agency">
                        {{agencyList}} 
                    </li>
                </dd>
                       
              <dt class="col-sm-3 text-truncate" *ngIf="reg_problem">Regulation Problem</dt>
              <dd class="col-sm-9">{{reg_problem}}</dd>
              
              <dt class="col-sm-3 text-truncate" *ngIf="reg_classification">Classification </dt>
              <dd class="col-sm-9">{{reg_classification}}</dd>
            </dl>

            <dl class="row" *ngIf="reg_classification == 'Business' || reg_classification == 'business' ">
              <dt class="col-sm-3 text-truncate" *ngIf="reg_sector">Sector</dt>
              <dd class="col-sm-9">{{reg_sector}}</dd>
              
              <dt class="col-sm-3 text-truncate" *ngIf="reg_business">Stage of Business</dt>
              <dd class="col-sm-9">{{reg_business}}</dd>
            </dl>

            <dl class="row" *ngIf="reg_classification == 'Non-Business' || reg_classification == 'non-business' ">
              <dt class="col-sm-3 text-truncate" *ngIf="reg_stageoflife">Stage of Life</dt>
              <dd class="col-sm-9">{{reg_stageoflife}}</dd>
              
              <dt class="col-sm-3 text-truncate" *ngIf="reg_business">Life Event </dt>
              <dd class="col-sm-9">{{reg_lifeevent}}</dd>
            </dl>
            
            <dl class="row">
              <dt class="col-sm-3 text-truncate" >Division </dt>
              <dd class="col-sm-9">{{reg_division}}</dd>
              
              <dt class="col-sm-3 text-truncate">Jurisdiction  </dt>
              <dd class="col-sm-9">{{reg_juris}}</dd>
              
              <dt class="col-sm-3 text-truncate">Case Use </dt>
              <dd class="col-sm-9">{{reg_case}}</dd>
              
              <dt class="col-sm-3 text-truncate" >Other Remarks </dt>
              <dd class="col-sm-9">{{reg_remarks}}</dd>
            </dl>

            <dl class="row bottom-border"></dl>

            <dl class="row">
              <dt class="col-sm-3 text-extend" >Target Timelines for the Conduct of PIA and RIA </dt>
            </dl>

            <dl class="row padder-lr">
              <table class="table">
                <thead class="text-muted">
                  <tr>
                  <th class="table-header fw-bold">Activity Title</th>
                    <th class="table-header fw-bold">Start Date</th>
                    <th class="table-header fw-bold">End Data</th>
                  </tr>
                </thead>
                <tbody class="card-text">
                  <tr *ngFor="let activityTitleList of pia_ria_timeline">
                    <th scope="row">{{ activityTitleList.activity_title}}</th>
                    <td>{{ activityTitleList.start_date }}</td>
                    <td>{{ activityTitleList.end_date }}</td>                   
                  </tr>
                </tbody>
              </table>
            </dl>              
          </div> 
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="sampleModal.hide(); closeModal()">Close</button>
            <!-- <button type="button" class="btn btn-primary" (click)="submit(id);  sampleModal.hide()">Submit</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  