import { Component, OnInit } from '@angular/core';
import { formatDate } from "@angular/common";
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-artemis-help-page',
  templateUrl: './artemis-help-page.component.html',
  styleUrls: ['./artemis-help-page.component.scss']
})
export class ArtemisHelpPageComponent implements OnInit {

  constructor(private router: Router, private afs: AngularFirestore) { }

  ngOnInit(): void {
  }


}
