import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import 'mdb-ui-kit';
import { filter, map } from 'rxjs/operators';

import * as firebase from 'firebase/compat/app';
import { environment } from '../environments/environment';
import 'firebase/messaging';
import { SwUpdate, SwPush } from '@angular/service-worker';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular';

  constructor(private titleService: Title, private router: Router, private activatedRoute: ActivatedRoute, updates: SwUpdate, push: SwPush) {
  
        this.router.events.subscribe(event => {
          if(event instanceof NavigationEnd){
              gtag('config', 'G-Y4M60V2MYP', 
                    {
                      'page_path': event.urlAfterRedirects
                    }
                  );
          }
        }
    )

    // firebase notifications credentials
    updates.available.subscribe(_ => updates.activateUpdate().then(() => {
      //console.log('reload for update');
      document.location.reload();
    }));
    push.messages.subscribe();// removed inside subscribe msg => console.log('push message', msg)
    push.notificationClicks.subscribe(); // removed from subscribed click => console.log('notification click', click)
    
    if (!firebase.default.apps.length) {
      firebase.default.initializeApp(environment.firebaseConfig);
      // TODO: check how to fix RWL 20240828
      // navigator.serviceWorker.getRegistration().then((swr: any) => firebase.default.messaging().useServiceWorker(swr));
    }
  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {


          const child = this.activatedRoute.firstChild;
          if (child && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  back_to_top() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}


