<h4>List of Tickets</h4>

<table class="table table-hover table-responsive w-100">
    <thead>
        <tr>
            <th>Nature</th>
            <th>Regulation</th>
            <th>Description</th>
            <th>Tags</th>
            <th>File</th>
        </tr>
    </thead>
    <div class="spinner-grow text-center" role="status" *ngIf="loader">
        <span class="visually-hidden">Loading...</span>
    </div>
    <tbody *ngIf="!loader">
        <tr *ngFor="let entry of tickets">
            <th>{{ entry.nature }}</th>
            <th>{{ entry.regulation }}</th>
            <td>{{ entry.ticket_text }}</td>
            <td>
                <table class = "table table-info table-sm" *ngIf="entry.tags_list.length > 0; else no_tags">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of entry.tags_list ">
                            <td>{{ item.tag_name }}</td>
                            <td>{{ item.tag_type }}</td>
                        </tr>
                    </tbody>
                </table>

                <ng-template #no_tags>
                    No tags included
                </ng-template>
                
                
            </td>
            <td>
                <a class = "btn btn-primary" href="{{ entry.ticket_file }}" *ngIf="entry.ticket_file">View File</a>
                
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="tickets.length < 1 && !loader">
        <tr>
            <td colspan="4">No tickets has been submitted</td>
        </tr>
    </tbody>
</table>