<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'AboutUs'"></app-artemis-navigation-header>


<div class="content">
    <div class="container">

        <!-- ARTEMIS -->
        <div class="card shadow my-3">
            <div class="card-header text-primary theme-default">
                <h5>THE ANTI-RED TAPE ELECTRONIC MANAGEMENT INFORMATION SYSTEM</h5>
                <h2 class="fw-bold">ABOUT US</h2>
            </div>
            <div class="card-body p-3 bg-gray rights-container">
                <strong>ANTI-RED TAPE ELECTRONIC MANAGEMENT INFORMATION SYSTEM</strong>
                <p class="m-3">
                    The Anti-Red Tape Electronic Management Information System (ARTEMIS) is managed by the Anti-Red Tape Authority, which is mandated to implement and oversee a national policy on anti-red tape and ease of doing business.
                </p>

                <p class="m-3">
                    The <strong>ARTEMIS</strong> is a web-based platform that will facilitate
                    <strong>the submission of Citizen’s Charters</strong> yielding <strong>a real-time on-demand database and mapping of all government services.</strong> By providing information on services and process flows, the ARTEMIS provides an
                    efficient monitoring mechanism for agencies and ARTA-CMEO and a useful information and transparency tool to our citizens.
                </p>

                <p class="m-3">
                    The <strong>ARTEMIS</strong> shall also serve as a tool to facilitate the submission and evaluation of Citizen’s Charter submissions of government agencies and provide a real-time database of Citizen’s Charter of all government agencies
                    to the public.
                </p>

            </div>
        </div>




    </div>
</div>