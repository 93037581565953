import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {format, isThursday } from 'date-fns';
import { DataTableDirective } from 'angular-datatables';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Timestamp } from 'firebase/firestore';
import { ArtemisCocPreviewPageArtaComponent } from '../artemis-charter-inbox-page-arta/artemis-coc-preview-page-arta/artemis-coc-preview-page-arta.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { allMustBe, atLeastOne } from 'src/app/validators';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-download-charter-page',
  templateUrl: './artemis-download-charter-page.component.html',
  styleUrls: ['./artemis-download-charter-page.component.scss']
})
export class ArtemisDownloadCharterPageComponent implements OnInit {

   //number formatting
   ordinals: string[] = ['th', 'st', 'nd', 'rd'];


   @ViewChild(DataTableDirective)
   dtElement: DataTableDirective;
   dtOptions: any = {};
   dtTrigger1: Subject<any> = new Subject<any>();
 
   ccCollections: any = []

  charterSearchForm: UntypedFormGroup = new UntypedFormGroup({
    agencyCategory: new UntypedFormControl(''),
    motherAgency: new UntypedFormControl(''),
    agency: new UntypedFormControl(''),

    rangeDateSubmitted: new UntypedFormGroup({
      start: new UntypedFormControl(<Date | null>(null)),
      end: new UntypedFormControl(<Date | null>(null)),
    }, { validators: [allMustBe(Validators.required)] }),
 
    rangeDatePosted: new UntypedFormGroup({
     start: new UntypedFormControl(<Date | null>(null)),
     end: new UntypedFormControl(<Date | null>(null)),
    }, { validators: [allMustBe(Validators.required)] }),
  }, { validators: [atLeastOne(Validators.required)] });

  loading:boolean = true

  orig_results: any = [];
  filtered_results: any = [];
  results: any = [];
  resultscount: any;

  valueSuggestions: any = {};

  constructor(private router: Router,private afs: AngularFirestore, public dialog: MatDialog ,private store: AngularFireStorage,private http: HttpClient) { }

  ngOnInit(): void {
    this.getSuggestions();
    //this.searchFromDatabase();

    this.dtOptions = {
      processing: true,
      lengthMenu: [20, 50, 100],
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 20,
      searching: true,
      ordering: true,
    }

  }

  ngAfterViewInit(): void {
    //this.dtTrigger.next(this.dtOptions);
    this.dtTrigger1.next(this.dtOptions);

  }

  rerender(): void {


    this.dtElement.dtInstance.then((dtInstance) => {
      dtInstance.clear().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger1.next(this.dtOptions);

    })

  }

  async getSuggestions() {
    let collectionArray: any = [];

    collectionArray.push({ collname: 'agency', collRef: this.afs.collection('Agency', ref => ref.where('agencyDetails.agencyName', '!=', '').orderBy('agencyDetails.agencyName')) });
    collectionArray.push({ collname: 'agency_category', collRef: this.afs.collection('Agency Category', ref => ref.orderBy('name')) });
    collectionArray.push({ collname: 'mother_agency', collRef: this.afs.collection('Mother Agency', ref => ref.orderBy('motheragency')) });

    /* loop */
    for (let collJSON of collectionArray) {
      let suggestArray: any = [];
      collJSON.collRef.snapshotChanges().pipe(first()).subscribe((data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id
          suggestArray.push(item);
        });
      });
      this.valueSuggestions[collJSON.collname] = suggestArray;

      if(collJSON.collname === 'agency') {
        this.valueSuggestions['agency_filtered'] = suggestArray;
      }
    }
  }

  ViewCCDocument(data:any){

    if(data.id){
      //console.log(data.id);
      this.router.navigate(["/artemis/citizen-charter/view/report/"],{
        queryParams: {
          cc_id: data.id,
          edition: data.edition
        }
      })
    }
 /**
       * 
       *
    if(data.reportURL ==''){
      return;
    }else{
      


      this.dialog.open(ArtemisCocPreviewPageArtaComponent, {
        maxWidth: "80vw",
        width: "80vw",
        data : {
          file : data.reportURL,
          file_type: "Citizen's Charter " + data.edition + " Edition"
        }
        
      })
       
    }*/

  }

  event: MatDatepickerInputEvent<Date>

 /***
  *  filterResults() {
    //
    this.filtered_results = [];

    for (let item of this.orig_results) {
      let if_blank = this.charterSearchForm.value.keyword == "";
      let by_org_group_01 = item["organization_grouping_01"].toLowerCase().includes(this.charterSearchForm.value.keyword.toLowerCase());
      let by_org_level_00 = item["organization_level_00"].toLowerCase().includes(this.charterSearchForm.value.keyword.toLowerCase());
      let by_org_level_01 = item["organization_level_01"].toLowerCase().includes(this.charterSearchForm.value.keyword.toLowerCase());
      let by_org_level_02 = item["organization_level_02"].toLowerCase().includes(this.charterSearchForm.value.keyword.toLowerCase());
      let by_org_level_03 = item["organization_level_03"].toLowerCase().includes(this.charterSearchForm.value.keyword.toLowerCase());
      let by_date = '';

      // filter by each
      if(if_blank || by_org_group_01 || by_org_level_00 || by_org_level_01 || by_org_level_02 || by_org_level_03){
        this.filtered_results.push(item); // push into array
        this.resultscount = this.filtered_results.length;
      }

    }
  }
  */

  filterAgency() {
    //console.log('check')
    // Clear Filter of bot
    if(!this.charterSearchForm.value.agencyCategory && !this.charterSearchForm.value.motherAgency) {
      this.valueSuggestions.agency_filtered = this.valueSuggestions.agency;
    }
    else {
      this.valueSuggestions.agency_filtered = 
        this.valueSuggestions.agency.filter(
          (data: any) => 
            (this.charterSearchForm.value.agencyCategory ? data.agencyDetails?.agencyCategory?.id === this.charterSearchForm.value.agencyCategory : true)
            &&
            (this.charterSearchForm.value.motherAgency ? data.agencyDetails?.motherAgency?.id === this.charterSearchForm.value.motherAgency : true)
            
        );
    }
  }

  sendSearchFilters() {
    let batches: any[] = [
      this.retrieveCCFunc(
        'dateSubmitted',
        this.charterSearchForm.controls.rangeDateSubmitted
      ),
      this.retrieveCCFunc(
        'date_posted',
        this.charterSearchForm.controls.rangeDatePosted
      )
    ];

    combineLatest(batches)
    .subscribe((results:any)=>{
      this.ccCollections = []
      let ccCollectionsTemp:any[] = [];
      results.forEach((content: any) => {
        let temp:any[] = [];
        content.forEach((info:any)=>{
          let item: any  = info.payload.doc.data();
          item.id = info.payload.doc.id;
          temp.push(item);
        });

        ccCollectionsTemp.push(temp);
      });

      this.ccCollections = [...new Set(...ccCollectionsTemp)];
      this.rerender();
    })
  }

  private retrieveCCFunc(dateField: string, dateControl: AbstractControl) {
    if(!dateControl.valid) {
      return of([]);
    }

    return this.afs.collection('citizens-charter-phase-02', ref => {
      let query : Query = ref;

      query = query
        .where('status','==','POSTED')
        .orderBy(dateField,'asc');
        
      let d1: Date = new Date(dateControl.value.start);
      let d2: Date = new Date(dateControl.value.end);

      d1.setHours(0)
      d2.setHours(24);
      const d1fromatted = Timestamp.fromDate(d1);
      const d2fromatted = Timestamp.fromDate(d2);

      query = query
        .where(dateField, '<=', d2fromatted)
        .where(dateField, '>=', d1fromatted)

      if(this.charterSearchForm.controls.agencyCategory.value) {
        query = query
          .where('agencyCategory.id', '==', this.charterSearchForm.controls.agencyCategory.value)
      }

      if(this.charterSearchForm.controls.motherAgency.value) {
        query = query
          .where('motherAgency.id', '==', this.charterSearchForm.controls.motherAgency.value)
      }

      if(this.charterSearchForm.controls.agency.value) {
        query = query
          .where('fromAgencyId', '==', this.charterSearchForm.controls.agency.value)
      }

      return query;
    })
    .snapshotChanges();
  }

  //format certificate of compliance edition and version
  transform(n: number, year: string, keepNumber: boolean = true) {
    if(n){
      let v = n % 100;
    return year  + " "+  (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]) + " Edition";

    }else{
      return year + " Edition"
    }
      }



  /***
   * 
   * async searchFromDatabase() {
    //
    this.results = [];
    this.orig_results = [];
    this.resultscount = 0;

    this.afs.collection("citizens-charter-phase-01").snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
      (data: any) => {
          data.forEach((info: any) => {
            this.loading =false;
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            // item.formatted_date = format(item.date.toDate(), 'MM/dd/yyyy');
            
            // push item if only it is not included yet
            if (this.orig_results.includes(item) == false){
              /*
              let path = 'citizen_charter20210511/' + data.payload.get('filename')
              const fileRef = this.store.ref(path)
              
              fileRef.getDownloadURL().toPromise().then(data => {
                item.filedata = data
              })
             
            }
            this.orig_results.push(item); // push into array
            // count number of entries

          });
          this.filterResults();
          this.resultscount = this.orig_results.length;

          console.log('this.orig_results: ',this.orig_results.date)
      });
  }
   */

}
