import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CreateSectorComponent } from 'src/app/create-sector/create-sector.component'
import { UntypedFormControl, UntypedFormGroup, Validators, NgForm, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-crud-lov',
  templateUrl: './crud-lov.component.html',
  styleUrls: ['./crud-lov.component.scss']
})
export class CrudLovComponent implements OnInit {

  public tabPages: Array<tabPage>;
  public currentTab: string;
  
  /* Forms */
  form = new UntypedFormGroup({
    section: new UntypedFormControl('', Validators.required),
    sector_name: new UntypedFormControl('', Validators.required),
    sector_def: new UntypedFormControl('')
  });

  constructor(private router: Router,public modalService: MDBModalService,private afs: AngularFirestore) {
    /* Initialize the global variables */
    this.tabPages = [];
    this.currentTab = '';

  }

  ngOnInit(): void {
    this.tabPages.push(new tabPage("Agency","subtitle","ARTEMIS","agency"));
    this.tabPages.push(new tabPage("Agency Category","subtitle","ARTEMIS","agency_category"));
    this.tabPages.push(new tabPage("Classification","subtitle","ARTEMIS","classification"));
    this.tabPages.push(new tabPage("List of Services","subtitle","ARTEMIS","list_of_services"));
    this.tabPages.push(new tabPage("Service Category","subtitle","ARTEMIS","service_category"));
    this.tabPages.push(new tabPage("Stage of Life","subtitle","ARTEMIS","stage_of_life"));
    this.currentTab = this.tabPages[0].title;
  }

  switchTab(tabName: string) {
    this.currentTab = tabName;
  }
  
}

/* For each tab */
class tabPage {

  /* variables */
  title: string = "";
  subtitle: string = "";
  subcollection: string = "";
  collectionID: string = "";
  full_path: string = "";

  /* constructor */
  constructor(title: string, subtitle: string, collectionID: string, subcollection:string) {
    this.title = title;
    this.subtitle = subtitle;
    this.subcollection = subcollection;
    this.collectionID = collectionID;
    this.full_path = "References/" + collectionID + "/" + subcollection;
  }

}
