<form class="container center">
    <input 
    [(ngModel)]="pickedDate"
    (ngModelChange) ="monthPicked($event)"
    type ="text"
    placeholder="Pick Month"
    autocomplete="off"
    class="form-control center"
    name="date"    
    #dp="bsDatepicker"
    bsDatepicker
    [bsConfig]="{ dateInputFormat: 'MMMM (YYYY)' }"
    minMode="month"
    >
<!-- 
    Result: 
    <p>{{dateToNum}}</p> -->

  </form>
  <!--   [(ngModel)]="pickedDate"
    type ="date"
    placeholder="Pick Month"
    autocomplete="off"
    class="form-control center"
    name="date"    
    #dp="bsDatepicker"
    bsDatepicker
    [bsConfig]="{ dateInputFormat: 'MMMM (YYYY)' }"
    minMode="month" -->