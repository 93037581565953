import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { filter, tap } from 'rxjs/operators';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import Swal from 'sweetalert2';
import { OfficeNode } from '../artemis-office-model-view/artemis-office-model-view.component';

export enum officeOPtion {
  PARENT_OFFICE = 'Parent Office',
  CHILD_OFFICE = 'Child Office',
}

enum OfficeEntityIndex {
  PARENT = 0,
  CHILD = 1,
}
@Component({
  selector: 'app-artemis-office-control',
  templateUrl: './artemis-office-control.component.html',
  styleUrls: ['./artemis-office-control.component.scss'],
})
export class ArtemisOfficeControlComponent implements OnInit {
  officeGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('', Validators.required),
  });

  office_hierarchy = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('', Validators.required),
    children: new UntypedFormArray([]),
  });

  officetypelist: any = [
    'Central Office',
    'Regional Office',
    'Provincial Office',
    'Field Office',
    'Satellite Office',
    'Branch',
    'Subsidiaries',
    'Office',
    'Campus (Main or Satellite)',
    'Division',
    'Unit',
    'Others',
  ];

  office_option = officeOPtion;

  updating: boolean = false;
  office: any;
  agency_id: any;
  loading: boolean = false;

  office_index: number;

  constructor(
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<ArtemisOfficeControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notif: SnackbarNotifService
  ) {
    if(this.data){
      if(data.node){
        this.office =data.node
        this.officeGroup.controls['name'].patchValue(this.office.name)
        this.officeGroup.controls['type'].patchValue(this.office.type)
        if(this.office.others){
          this.addOtherControl()
          this.officeGroup.controls['others'].patchValue(this.office.others)
        }
      }
    }
  }

  option: officeOPtion;

  ngOnInit(): void {}

  save() {
    if (this.officeGroup.valid) {
      const value: any = {
        submitFlag: true,
        office: {
          name: this.officeGroup.value.name,
          type: this.officeGroup.value.type,
          others: this.officeGroup.value.others
            ? this.officeGroup.value.others
            : '',
          children: [],
        },
      };

      this.dialogRef.close(value);
    }
  }

  valueChanged(event: any) {
    if (event.value === 'Others') {
      this.addOtherControl();
    } else {
      this.removeControl();
    }
  }

  addOtherControl() {
    this.officeGroup.addControl(
      'others',
      new UntypedFormControl('', Validators.required)
    );
  }

  removeControl() {
    this.officeGroup.removeControl('others');
  }

  addParent() {}

  buildnewEntry(existingObj?: any): UntypedFormGroup {
    let office_form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      children: new UntypedFormArray([]),
    });

    return office_form;
  }
}
