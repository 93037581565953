<h2 mat-dialog-title class="mat-typography" style="font-weight: bold;">{{action}} {{title}}</h2>
<div mat-dialog-content style="min-width: 800px;max-width: 800px;">
    <form [formGroup]="commonRefForm" class="form-group">
        <div class="row margin-1em">
            <div class="col-2"><label class="form-input-header fw-bold">Section</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.section ? currentRef.section : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="section">
                    <ng-container *ngFor="let item of checkRefForm.section">
                        <mat-error style="color:red" *ngIf="hasError('section',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                </ng-container>
            </div>
        </div>
        <div class="row margin-1em">
            <div class="col-2"><label class="form-input-header fw-bold">Name</label></div>
            <div class="col-1 form-input-header fw-bold">:</div>
            <div class="col">
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-align: justify;">{{currentRef ? (currentRef.name ? currentRef.name : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <input type="text" class="form-control" formControlName="name">
                    <ng-container *ngFor="let item of checkRefForm.name">
                        <mat-error style="color:red" *ngIf="hasError('name',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                </ng-container>
            </div>
        </div>
        <div class="row margin-1em">
            <div class="col">
                <label class="form-input-header fw-bold">Definition :</label>
                <ng-container *ngIf="toView || toDelete">
                    <p style="text-indent: 2em; text-align: justify;">{{currentRef ? (currentRef.definition ? currentRef.definition : "N/A") : "N/A"}}</p>
                </ng-container>
                <ng-container *ngIf="toCreate || toEdit">
                    <textarea type="text" class="form-control" formControlName="definition"></textarea>
                    <ng-container *ngFor="let item of checkRefForm.definition">
                        <mat-error style="color:red" *ngIf="hasError('definition',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
    <button type="button" class="btn btn-dark" (click)="goBack()" >
        <span *ngIf="!toView">Cancel</span>
        <span *ngIf="toView">Back</span>
    </button>
    <button type="button" *ngIf="toDelete" class="btn btn-danger" (click)="deleting()">
        Delete
    </button>
    <button type="button" *ngIf="toEdit" class="btn btn-warning" (click)="updating()">
        Update
    </button>
    <button type="button" *ngIf="toCreate" class="btn btn-primary" (click)="creating()" cdkFocusInitial>
        Add
    </button>
</div>