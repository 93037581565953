import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pis-assessment-phase2-view',
  templateUrl: './pis-assessment-phase2-view.component.html',
  styleUrls: ['./pis-assessment-phase2-view.component.scss'],
})
export class PisAssessmentPhase2ViewComponent implements OnInit {
  // Always true
  isPhase2: boolean = true;

  @Input() phase2Worklist: any;

  agency_list: any = [];
  legalBases_list: any = [];
  internationalAgreement_list: any = [];

  constructor(private afs: AngularFirestore) {}

  loadedData:any = {};

  ngOnInit(): void {
    this.loadPhase2View();
  }

  private loadPhase2View() {
    console.log("load phase 2 view for assessment: ",this.phase2Worklist.sub_tags);
    if (this.phase2Worklist) {
      let reg_info = this.phase2Worklist;

      if (reg_info.tags && reg_info.reg_instrument)
        this.loadData('Instrument', reg_info.reg_instrument, 'instrument_name');
      if (reg_info.tags && reg_info.tags.reg_sector)
        this.loadData('Sector', reg_info.tags.reg_sector, 'sector_name');
      if (reg_info.tags && reg_info.tags.reg_business)
        this.loadData(
          'Stage of Business',
          reg_info.tags.reg_business,
          'business_name'
        );
      if (reg_info.tags && reg_info.tags.reg_division)
        this.loadData('Division', reg_info.tags.reg_division, 'division_name');
      if (reg_info.tags && reg_info.tags.reg_juris)
        this.loadData('Jurisdiction', reg_info.tags.reg_juris, 'juris_name');
      if (reg_info.tags && reg_info.tags.reg_case)
        this.loadData('Case Use', reg_info.tags.reg_case, 'case_name');
      if (reg_info.reg_agency) this.loadAgencies(reg_info.reg_agency);
      if (reg_info.tags && reg_info.tags.reg_legal)
        // Legal Bases is text-based, the collection is only a list of suggestions
        this.legalBases_list = [];
      for (let x of reg_info.tags.reg_legal) {
        this.legalBases_list.push(x);
      }
      if (
        reg_info.tags &&
        reg_info.tags.reg_intl &&
        reg_info.tags.reg_intl.length > 0
      ) {
        this.internationalAgreement_list = [];
        for (let x of reg_info.tags.reg_intl) {
          this.internationalAgreement_list.push(x);
        }
      }

      if(reg_info.has_sub_tags && reg_info.sub_tags && reg_info.sub_tags.length > 0){
        
        reg_info.sub_tags.map(async (sub_tag:any)=>{
          sub_tag.reg_tag_classification = sub_tag.reg_classification
          if(sub_tag.reg_classification == 'Business'){
            if(sub_tag.reg_sector) {
              const sector:any = await this.dataLoader('Sector','Sector',sub_tag.reg_sector)
              if(sector) sub_tag.sector_name = sector.name
              else console.error('no sector retrieved')
            }
            if(sub_tag.reg_business){
              const sob:any = await this.dataLoader('StageOfBusiness','Stage of Business',sub_tag.reg_business)
              if(sob) sub_tag.sob_name = sob.name
              else console.error('no sob retrieved')
            }

            if(sub_tag.reg_division) {
              const division:any = await this.dataLoader('Division','Division',sub_tag.reg_division)
              if(division) sub_tag.div_name = division.name
              else console.error('no division retrieved')
            }

            if(sub_tag.reg_case) {
              const caseUse:any = await this.dataLoader('CaseUse','Case Use',sub_tag.reg_case)
              if(caseUse) sub_tag.case_name = caseUse.name
              else console.error('no caseUse retrieved')
            }
          }

          else if (sub_tag.reg_classification == 'Non-Business'){
            if (sub_tag.reg_stageoflife) {
              const sol:any = await this.dataLoader('StageOfLife','Stage of Life',sub_tag.reg_stageoflife)
              if(sol) sub_tag.sol_name = sol.stage
              else console.error('no sol retrieved')
            }
            if (sub_tag.reg_lifeevent) {
              const le:any = await this.dataLoader('LifeEvent','Life Event',sub_tag.reg_lifeevent)
              if(le) sub_tag.le_name = le.event
              else console.error('no life event retrieved')
            }
          }
        })
      }
    }
  }

  loadData(collection: any, id: any, storeval: any) {
    this.afs
      .collection(collection)
      .doc(id)
      .snapshotChanges()
      .pipe(first())
      .subscribe((data) => {
        let item: any = data.payload.data();
        this.phase2Worklist[storeval] = item.name;
      });
  }

  // Because reg_agency can be single input or multiple input, handle as if it were a strong or an object (array)
  private loadAgencies(agency_list: any) {
    if (typeof agency_list === 'string') {
      this.afs
        .collection('Agency')
        .doc(agency_list)
        .snapshotChanges()
        .subscribe((data) => {
          this.agency_list = [];
          let agency_info: any = data.payload.data();
          this.agency_list.push(agency_info.name);
        });
    } else if (typeof agency_list === 'object') {
      if (agency_list.length > 0) {
        for (let x of agency_list) {
          if (x !== '') {
            this.afs
              .collection('Agency')
              .doc(x)
              .snapshotChanges()
              .subscribe((data) => {
                this.agency_list = [];
                let agency_info: any = data.payload.data();
                this.agency_list.push(agency_info.name);
              });
          }
        }
      }
    } else {
      this.agency_list = [];
    }
  }
  
  async dataLoader(code:string, collection:string, id:any):Promise<any>{
    // code is used for accessing loadedData object
    // check if code, collection, and id are valid
    if(code && collection && id){

      // check if loadedData.Object has a value
      if(this.loadedData[code]){

        // check if there loadedData.Object array contains an element
        if(this.loadedData[code].length > 0){

          // look for id wihtin the array and return the element
          const ctr = this.loadedData[code].indexOf(id)
          if(ctr >= 0){
            return this.loadedData[code][ctr]
          }
          
          // if no element with that id get it from firebase
          else return await this.dataLoader2(code, collection, id)
        }

        // if loadedData.Object array doesnt contain an element get value from firebase
        else return await this.dataLoader2(code, collection, id)
      } 
      
      // if loadedData.Object has no value, initialized an array there
      else {
        this.loadedData[code] = []
        return await this.dataLoader(code,collection,id)
      }
    } else return false
  }

  async dataLoader2(code:string, collection:string, id:any): Promise<any>{

    // get data from firebase
    const item = await this.loadData2(collection,id)
    
    // if data exist add it to loaded.Object array
    if(item){
      if(this.loadedData[code].map((val:any)=>val.id).indexOf(id) == -1)this.loadedData[code].push(item)
      return item
    }
    
    // if data doesnt exist return false
    else return false
  }

  loadData2(collection:any, value:any){
    // get data from firebase if no data was retrieved return false
    return new Promise((res,rej)=>{
      this.afs.collection(collection).doc(value).snapshotChanges()
      .subscribe({
        next:(data)=>{  
          if(data.payload.exists){
            let item:any = data.payload.data()
            item.id = data.payload.id

            if(item.ref) delete item['ref']
            if(item._createdBy) delete item['_createdBy']
            if(item._updatedBy) delete item['_updatedBy']
            return res(item)
          } else return res(false)
        },
        error:(err)=>{
          console.error('loadData Error: ',err)
          return res(false)
        }
      })
    })
  }
}
