<h2 mat-dialog-title class="mat-typography">Add Template</h2>
<div class="form-group" [formGroup]="createTemplateForm" mat-dialog-content style="min-width: 440.25px; min-height: max-content;">
    <div class="row">
        <div class="col-md-4">
            Config Code
        </div>
        <div class="col-md-1">
            :
        </div>
        <div class="col-md-7">
            <input class="form-control" type="number" formControlName="configCode" min="1">
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            Recipient
        </div>
        <div class="col-md-1">
            :
        </div>
        <div class="col-md-7">
            <input class="form-control" type="text" formControlName="recipient">
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            Subject
        </div>
        <div class="col-md-1">
            :
        </div>
        <div class="col-md-7">
            <input class="form-control" type="text" formControlName="subject">
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            Template Name
        </div>
        <div class="col-md-1">
            :
        </div>
        <div class="col-md-7">
            <input class="form-control" type="text" formControlName="templateName">
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            Trigger
        </div>
        <div class="col-md-1">
            :
        </div>
        <div class="col-md-7">
            <input class="form-control" type="text" formControlName="trigger">
        </div>
    </div>

    <div class="d-flex justify-content-between">
        <span>Variables</span>
        <button class="btn btn-primary" type="button" (click)="addNewVariable()">Add</button> 
    </div>
    <div >
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Key</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="variables">
                    <ng-container  *ngFor="let item of variablesFormGroup; let i = index">
                        <tr [formGroup]="item">
                            <th><input type="text" class="form-control" formControlName="key"></th>
                            <th>
                                <input type="text" class="form-control" formControlName="title">

                                <!-- for update -->
                                <ng-container *ngIf="item.value.type">
                                    <br>
                                    <input type="text" placeholder="Link"  class="form-control" formControlName="others">
                                </ng-container>
                            </th>
                            <th>
                                <select formControlName="type" class="form-control" >
                                    <option selected readonly value="" readonly>Select a variable type</option>
                                    <option *ngFor="let typeOption of typeOptions">{{typeOption}}</option>
                                </select>
                            </th>
                            <th>
                                <button class="btn btn-danger" type="button" (click)="removeVariable(i)">-</button>
                            </th>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
    <button type="button" class="btn btn-dark" (click)="goBack()" >
        Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="submitCreate()" cdkFocusInitial>
        Create
    </button>
</div>