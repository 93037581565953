<!-- <div class="row mb-2">
   <div *ngIf="isAgencyMember || isAdmin" class="col-2 float-right">
    <button mat-flat-button color="primary"  (click)="addOffice()">Add Office</button>
   </div>
</div>
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1"
    class="table table-striped table-responsive">
    <thead>
        <tr>
            <th class="table-header">Office Type</th>
            <th class="table-header">Name</th>
            <th *ngIf="isAdmin || isAgencyMember" class="table-header">Status</th>
            <th *ngIf="isAgencyMember || isAdmin" class="table-header">Action</th>
        </tr>
    </thead>
    <tbody>

        <tr *ngFor="let office of officeCollection;let i = index">
            
            <td>
                {{ office.type }}
            </td>
            <td>
                {{office.name}}
            </td>
            <td *ngIf="isAdmin || isAgencyMember">
                {{office.Status}}
            </td>

            <td *ngIf="isAgencyMember || isAdmin">
                <div [disabled]="loading" class="col align-right">
                    <button (click)="UpdateOffice(office)" mat-flat-button color="primary" class="mx-1">
                        Edit
                    </button>
                    <button mat-stroked-button (click)="removeOffice(office,i)" class="btn btn-warning ">
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>  Remove
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</table>
 -->
<!-- 
 <button  mat-raised-button color="primary" *ngIf="leadEncoder" (click)="addNode(true)">Add Lower Level Operating Unit Office</button>

 <h5 class="mt-5">
    Lower Level Operating Units </h5>

    <p *ngIf="!dataSource.length"> None </p>

    <ul class="tree" *ngFor="let node of dataSource;let i = index">
    
        <li>
            <ng-container >
                <app-artemis-office-model-view
                [node]="node.office"
                [parent_node_array]="dataSource"
                [index]="i"
                [office_id]="node.id"
                [referenceArray]="dataSource"
                [parent]="true"
                [leadEncoder]="leadEncoder"
                ></app-artemis-office-model-view>
            </ng-container>
        </li>
    
    </ul>

 -->

 <app-artemis-office-hierarchy-control [isLowerLevel]="true" [agency_id]="agency_id" [isAdmin]="isAdmin" [leadEncoder]="isAgencyMember"></app-artemis-office-hierarchy-control>
