import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { DataTableDirective } from 'angular-datatables';
import { Subject, combineLatest, of } from 'rxjs';
import { ArtemisCocPreviewPageArtaComponent } from '../artemis-charter-inbox-page-arta/artemis-coc-preview-page-arta/artemis-coc-preview-page-arta.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { firestore } from 'firebase-admin';
import { Timestamp } from 'firebase/firestore';
import { allMustBe, atLeastOne } from 'src/app/validators';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-coc-masterlist',
  templateUrl: './artemis-coc-masterlist.component.html',
  styleUrls: ['./artemis-coc-masterlist.component.scss']
})
export class ArtemisCocMasterlistComponent implements OnInit {

  //number formatting
  ordinals: string[] = ['th', 'st', 'nd', 'rd'];


  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger1: Subject<any> = new Subject<any>();

  cocCollections: any = []
  cocSubmissions: any = []

  charterSearchForm: UntypedFormGroup = new UntypedFormGroup({
    agencyCategory: new UntypedFormControl(''),
    motherAgency: new UntypedFormControl(''),
    agency: new UntypedFormControl(''),

    rangeDateSubmitted: new UntypedFormGroup({
      start: new UntypedFormControl(<Date | null>(null)),
      end: new UntypedFormControl(<Date | null>(null)),
    }, { validators: [allMustBe(Validators.required)] }),

  }, { validators: [atLeastOne(Validators.required)] });

  valueSuggestions: any = {};

  constructor(private afs: AngularFirestore, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getSuggestions();

    this.dtOptions = {
      processing: true,
      lengthMenu: [20, 50, 100],
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 20,
      searching: true,
      ordering: true,
    }

    //this.loadCertificateofcompliance();
  }

  event: MatDatepickerInputEvent<Date>

  ngAfterViewInit(): void {
    //this.dtTrigger.next(this.dtOptions);
    this.dtTrigger1.next(this.dtOptions);

  }

  rerender(): void {


    this.dtElement.dtInstance.then((dtInstance) => {
      dtInstance.clear().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger1.next(this.dtOptions);

    })

  }

  async getSuggestions() {
    let collectionArray: any = [];

    collectionArray.push({ collname: 'agency', collRef: this.afs.collection('Agency', ref => ref.where('agencyDetails.agencyName', '!=', '').orderBy('agencyDetails.agencyName')) });
    collectionArray.push({ collname: 'agency_category', collRef: this.afs.collection('Agency Category', ref => ref.orderBy('name')) });
    collectionArray.push({ collname: 'mother_agency', collRef: this.afs.collection('Mother Agency', ref => ref.orderBy('motheragency')) });

    /* loop */
    for (let collJSON of collectionArray) {
      let suggestArray: any = [];
      collJSON.collRef.snapshotChanges().pipe(first()).subscribe((data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id
          suggestArray.push(item);
        });
      });
      this.valueSuggestions[collJSON.collname] = suggestArray;

      if(collJSON.collname === 'agency') {
        this.valueSuggestions['agency_filtered'] = suggestArray;
      }
    }
  }

  filterAgency() {
    //console.log('check')
    // Clear Filter of bot
    if(!this.charterSearchForm.value.agencyCategory && !this.charterSearchForm.value.motherAgency) {
      this.valueSuggestions.agency_filtered = this.valueSuggestions.agency;
    }
    else {
      this.valueSuggestions.agency_filtered = 
        this.valueSuggestions.agency.filter(
          (data: any) => 
            (this.charterSearchForm.value.agencyCategory ? data.agencyDetails?.agencyCategory?.id === this.charterSearchForm.value.agencyCategory : true)
            &&
            (this.charterSearchForm.value.motherAgency ? data.agencyDetails?.motherAgency?.id === this.charterSearchForm.value.motherAgency : true)
            
        );
    }
  }

  sendSearchFilters() {

    this.retrieveCOCFunc(
      'dateSubmitted',
      this.charterSearchForm.controls.rangeDateSubmitted
    )
    .pipe(first())
    .subscribe((results:any)=>{
      this.cocCollections = []
      results.forEach((info: any) => {
        let item: any  = info.payload.doc.data();
        item.id = info.payload.doc.id;
        this.cocCollections.push(item);
      });

      this.rerender();
    })
  }

  private retrieveCOCFunc(dateField: string, dateControl: AbstractControl) {
    if(!dateControl.valid) {
      return of([]);
    }

    return this.afs.collection('Certificate of Compliance Submissions', ref => {
      let query : Query = ref;

      query = query
        .where('status','==','Submitted')
        .orderBy(dateField,'asc');0
      
      let d1: Date = new Date(dateControl.value.start);
      let d2: Date = new Date(dateControl.value.end);

      d1.setHours(0)
      d2.setHours(24);
      const d1fromatted = Timestamp.fromDate(d1);
      const d2fromatted = Timestamp.fromDate(d2);

      query = query
        .where(dateField, '<=', d2fromatted)
        .where(dateField, '>=', d1fromatted)

      if(this.charterSearchForm.controls.agencyCategory.value) {
        query = query
          .where('agencyCategory.id', '==', this.charterSearchForm.controls.agencyCategory.value)
      }

      if(this.charterSearchForm.controls.motherAgency.value) {
        query = query
          .where('motherAgency.id', '==', this.charterSearchForm.controls.motherAgency.value)
      }

      if(this.charterSearchForm.controls.agency.value) {
        query = query
          .where('fromAgencyId', '==', this.charterSearchForm.controls.agency.value)
      }

      return query;
    })
    .snapshotChanges();
  }

  //format certificate of compliance edition and version
  transform(n: number, keepNumber: boolean = true) {
    let v = n % 100;
    return (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]);
  }


  ViewCCDocument(data: any) {

    if (data.cocurl == '') {
      return;
    } else {
      this.dialog.open(ArtemisCocPreviewPageArtaComponent, {

        data: {
          file: data.cocurl,
          file_type: "Certificate of Compliance"
        }

      })

    }

  }




}
