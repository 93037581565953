import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-pbris-user-feedback-create',
  templateUrl: './pbris-user-feedback-create.component.html',
  styleUrls: ['./pbris-user-feedback-create.component.scss']
})
export class PbrisUserFeedbackCreateComponent implements OnInit {
  loading: boolean = false;
  featureAccessList: any;
  overall_rate: any;
  visit_site: any;
  refer_site: any;
  others: boolean = false;
  commentView: number = 0;
  todayDate : Date = new Date();

  // phase 1 - sample form
  phase1form = new UntypedFormGroup({ //date
      application: new UntypedFormControl('feedback-pbris', Validators.required),
      feature: new UntypedFormControl('', Validators.required),
      overall: new UntypedFormControl('', Validators.required),
      elaborate: new UntypedFormControl(''),
      improve: new UntypedFormControl(''),
      experience: new UntypedFormControl(''),
      visit: new UntypedFormControl('', Validators.required),
      refer: new UntypedFormControl('', Validators.required),
      discover: new UntypedFormControl('', Validators.required),
      enhance: new UntypedFormControl('', Validators.required),
      others: new UntypedFormControl(''),
      otherDiscover: new UntypedFormControl(''),
      created_at: new UntypedFormControl(formatDate(this.todayDate, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800'))
    });

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.getFeatureAccessed().subscribe(data => {
      this.featureAccessList = data.map(e => {
        return {
          id: e.payload.doc.id,
          access: e.payload.doc.data()
        };
      })
    })
  }

  getFeatureAccessed() {
    return this.afs.collection('Feature Accessed').snapshotChanges();
  }

  starOnChange(data: any) {
    if (data.target.name == 'overall') {
      this.overall_rate = data.target.value ? data.target.value : ''
      if (data.target.value == 'Excellent') {
        this.commentView = 1
      } else if (data.target.value == 'Very satisfy' || data.target.value == 'Satisfy') {
        this.commentView = 2
      } else if (data.target.value == 'Unsatisfactory' || data.target.value == 'Poor') {
        this.commentView = 3
      } else {
        this.commentView = 0
      }
    } else if (data.target.name == 'visit') {
      this.visit_site = data.target.value ? data.target.value : ''
    } else if (data.target.name == 'refer') {
      this.refer_site = data.target.value ? data.target.value : ''
    }
  }

  discoverOnChange(data: any) {
    if (data.target.value == 'Others') {
      this.others = true
    } else {
      this.others = false
    }
  }

  resetForm() {
    this.others = false;
    this.commentView = 0;
  }

  async onSubmit() {
    this.loading = true;
    let documentID: any;

    await Swal.fire({
      title: 'Thank you for your feedback!',
      input: 'checkbox',
      inputValue: 0,
      allowOutsideClick: false,
      showCancelButton: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
      html: 
      '<p class="text-left" style="text-align: left; font-size: medium;"><b>DISCLAIMER:</b> ' +
        'Adhering to the provisions of the Data Privacy Act of 2012, please note that your feedback will only be visible to the PBRIS Administrator.' +
        '<br><br>The system administrators reserve the right to disable or ban your account for any inappropriate, malicious, and abusive use of feedback functionalities.'+
      '</p>',
      inputPlaceholder:
        'I agree with the terms and conditions',
      confirmButtonText:
        'Proceed',
      inputValidator: (result) => {
        return !result ? 'You need to agree with T&C' : ''
      }
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Processing',
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            
            if(this.phase1form.valid){
              this.phase1form.addControl('terms and conditions',new UntypedFormControl(1, Validators.required));
              this.afs.collection(`Feedback PBRIS`).add(this.phase1form.value).then((docRef:any) => {
                this.loading = false;
                Swal.close();
                Swal.fire({
                  title:'Successfully processed',
                  icon: 'info'
                });
                documentID = docRef.id;
                this.phase1form.reset();
                this.resetForm();
              }).catch((error:any) =>{
                this.loading = false;
                Swal.close();
                Swal.fire({
                  title:'System error',
                  titleText: 'Please refresh the page',
                  icon: 'error'
                });
              });
            } else {
              this.loading = false;
              Swal.close();
              Swal.fire({
                title:'Warning',
                titleText: 'Please fill out the required fields',
                icon: 'warning'
              });
            }  
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.loading = false;
      }
    })  
  }
}
