<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="''"></app-artemis-navigation-header>

<div class="container-fluid">

    <!-- Title -->
    <div class="row p-3">
        <div class="col-9">
            <div class="row">
                <div class="col-6">
                    <h2 class="{{style_theme}}-text report-title fw-bold">{{pageinfo.name}}</h2>
                </div>
            </div>
            <p class="border border-dark"></p>
            <div class="row">
                <div class="col">
                    {{pageinfo.servicecategory}} of the 
                    <strong class="text-danger">{{pageinfo.agency}}</strong>
                    issued on 
                    <strong class="text-danger">{{pageinfo.date | date:'MM/dd/yyyy'}}</strong>
                </div>
            </div>
        </div>
        <div class="col-2">
            <img src="/assets/arta-logo.png" class="image-fluid" alt="Anti-Red Tape Authority Logout" style="height: 150px;">
        </div>
    </div>

    <div class="row my-4">
        <!-- Sidebar -->
        <div class="col-{{isSidebarVisible?'3':'1'}}">
            <div class="row h-100">
                <!-- Sidebar -->
                <div *ngIf="isSidebarVisible" class="{{style_theme}} h-100 col p-4">
                    <h4 class="report-sidepanel-title fw-bold">{{pageinfo.name}}</h4>
                    <div class="my-3">
                        <p class="text-white m-0">
                            Agency:<br>. {{pageinfo.agency}}
                        </p>
                    </div>
                    <h4 class="text-white fw-bold text-decoration-underline">
                        _
                    </h4>
                    <div class="my-3">
                        Agency Contact Details
                        <br>
                        <strong>Email</strong>
                        <br>
                        <strong>Number</strong>
                    </div>
                </div>
                <!-- Sidebar Icons-->
                <div class="col{{isSidebarVisible?'-2':''}}">
                    <div class="py-4 bg-warning text-dark text-center sidebar-icon-btn {{isSidebarVisible?'sidebar-icon-btn-shift':''}}">
                        <div class="row">
                            <i (click)="toggleSidebar()" class="fas fa-bars fa-lg"></i>
                        </div>
                        <div class="row my-5">
                            <i class="fas fa-comments my-2 fa-2x"></i>
                            <i class="fas fa-file-invoice my-2 fa-2x"></i>
                        </div>
                        <div class="row my-5">
                            <i class="fas fa-share-square my-2 fa-2x"></i>
                            <i class="far fa-file-alt my-2 fa-2x"></i>
                            <i class="fas fa-print my-2 fa-2x"></i>
                        </div>
                        <div class="row my-5">
                            <i class="fas fa-exclamation-triangle my-2 fa-2x"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    </div>

</div>