import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { ARTEMIS, PBRIS } from 'src/app/entities/constant';
import { AuthGuardTypeFlag } from '../entities/authFlags';
import { GovernmentAccountSubtype } from '../entities/worklist';

@Injectable({
  providedIn: 'root'
})
export class PrivatepageguardGuard implements CanActivate ,CanActivateChild {

  constructor(
    public router: Router,
    public auth: AngularFireAuth,
    public afs: AngularFirestore
  ){}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    //console.log(route.url)
    
    return new Promise((res,rej)=>{
      this.auth.onAuthStateChanged((user)=>{
        if(user){
          //console.log('Logged in')
        }else{
          sessionStorage.clear();
          return res(this.redirectoLogin())
        }
      })
        this.auth.authState.subscribe({
            next: (result)=>{
                if(result){
                  if(result.uid){
                      this.afs.collection('Users').doc(result.uid)
                      .snapshotChanges()
                      .subscribe({
                        next: async (userResult)=>{

                            if(userResult.payload.exists){
                              const user:any = userResult.payload.data();

                              if(Array.isArray(route.data.restrictions)){
                                route.data.restrictions.forEach((restriction: number) => {
                                  switch(restriction) {
                                    case AuthGuardTypeFlag.ARTEMIS_ONLY:
                                      if(!user.credentials.access_artemis) return res(this.restrict());
                                      break;
                                    case AuthGuardTypeFlag.PBRIS_ONLY:
                                      if(!user.credentials.access_pbris) return res(this.restrict());
                                      break;
                                    case AuthGuardTypeFlag.ADMIN_ONLY:
                                      if(!user.credentials.account_subtype.toLowerCase().includes("admin")) return res(this.restrict());
                                      break;
                                  }
                                });
                              }
                              
                              let account_type = user.credentials.account_type.toLowerCase();
                              sessionStorage.setItem("account_subtype", user.credentials.account_subtype);
                              sessionStorage.setItem('account_role',user.credentials.role)

                              switch (account_type) {
                                case "government":
                                  if (user.credentials.account_subtype && user.credentials.account_subtype.toLowerCase().includes("arta")) {
                                    sessionStorage.setItem("user_type", "arta");
            
                                    switch(user.credentials.account_subtype){
                                      case GovernmentAccountSubtype.ARTA_ADMIN:{
                                        sessionStorage.setItem("arta_admin", "true");
                                        break;
                                      }
                                      case GovernmentAccountSubtype.AGENCY_ADMIN:{
                                        sessionStorage.setItem("agency_admin", "true");
                                        break;
                                      }
                                    }
                                    // if (user.credentials.account_subtype.toLowerCase().includes("admin")) {
                                    //   sessionStorage.setItem("arta_admin", "true");
                                    // }
                                  }
                                  else {
                                    sessionStorage.setItem("user_type", "agency");
                                    const agency_data = await firstValueFrom(this.afs.doc(`Agency/${user.government?.agency}`).get());
                                    let agency_data_data: any = agency_data.data();
            
                                    sessionStorage.setItem("agency_name", agency_data_data?.name);
                                    sessionStorage.setItem("agency_id", user.government?.agency);
                                    switch(user.credentials.account_subtype){
                                      case GovernmentAccountSubtype.AGENCY_ADMIN:{
                                        sessionStorage.setItem("agency_admin", "true");
                                        break;
                                      }
                                    }
                                  }
                                  break;
                                case "company":
                                  sessionStorage.setItem("user_type", "agency");
                                  break;
                                case "personal":
                                  sessionStorage.setItem("user_type", "personal");
                                  break;
                              }

                              
                              if(
                                sessionStorage.getItem('agency_id') === 'undefined'
                                ||
                                sessionStorage.getItem('account_subtype') === 'undefined'
                                ){
                                return res(this.restrict())  
                              }
                              if(route.url[0].path === 'pbris') {
                                sessionStorage.setItem("currentSystem",PBRIS)
                              }

                              if(route.url[0].path === 'artemis') {
                                sessionStorage.setItem("currentSystem",ARTEMIS);
                              }
                              
                              return res(true);
                            }else return res(this.redirectoLogin());
                        },error: (error)=>{
                          return res(this.redirectoLogin())
                        }
                      });

                  }else return res(this.redirectoLogin());
                }else return res(this.redirectoLogin());
            }, error: (err)=> res(this.redirectoLogin())
        })




    })
  }

  redirectoLogin(){
    sessionStorage.clear();
    this.router.navigate(['/artemis/login']);
    return false
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  restrict(){
    //console.warn('restrict')
    //console.log('Private Guard')
    this.router.navigate(['/restricted'])
    return false
  }

  
}
