<div *ngIf="isCharterListView">
  <app-artemis-charter-inbox-page-arta-charter-list
    (toInboxComponent)="changeInboxViewPort($event)"
  ></app-artemis-charter-inbox-page-arta-charter-list>
</div>

<div *ngIf="isServiceListView">
  <app-artemis-charter-inbox-page-arta-service-list
    [service_ids]="service_ids"
    [citizens_charter]="citizens_charter"
    [agency_id]="agency_id"
    [agency_name]="agency_name"
    (toInboxComponent)="changeInboxViewPort($event)"
  ></app-artemis-charter-inbox-page-arta-service-list>
</div>
