<mat-card>
  <mat-card-header class="mb-2">
    <mat-card-title class="text-primary"><strong>For Action</strong></mat-card-title>
    <!--<mat-card-subtitle>Start</mat-card-subtitle>-->
  </mat-card-header>
  <mat-card-content>
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1"
      class="table table-hover  table-bordered table-striped  table-responsive">
      <thead>
        <tr>
          <th class="table-header">Date Generated</th>
          <th class="table-header">Officer</th>
          <th class="table-header">Service Name</th>
          <th class="table-header">Status</th>
          <th class="table-header">Effectivity</th>
          <th class="table-header">Action Due</th>
          <th class="table-header">Action</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let worklist of results1">
          <td>
            <h6>
              {{
              worklist.dateGenerated.toDate() | date: "MMMM dd, yyyy hh:mm:ss"
              }}
            </h6>
          </td>
          <td>
            <h6>{{ worklist.fromOfficer }}</h6>
          </td>
          <td>
            <h6>
              <u>{{ worklist.documentTitle }}</u>
            </h6>
          </td>

          <!--
            <td>
            <h6>{{ worklist.status }}</h6>
          </td>
          -->
          <td>
            <div class="align-items-center">
              <div [ngSwitch]="worklist.status">
                <div *ngSwitchCase="'APPROVED'">

                  <h6><span class="badge  bg-primary ">{{worklist.status}}</span></h6>

                </div>

                <div *ngSwitchCase="'FOR APPROVAL'">

                  <h6><span class="badge  bg-danger ">{{worklist.status}}</span></h6>

                </div>

                <div *ngSwitchDefault>

                  <h6>
                    <span class="badge  bg-warning ">{{worklist.status}}</span>
                  </h6>
                </div>

              </div>
            </div>

          </td>
          <td>

            <div class="align-items-center">
              <div [ngSwitch]="worklist.effectivity">
                <div *ngSwitchCase="'NEW'">

                  <h6>
                    <span class="badge bg-success" [innerHtml]='worklist.effectivity'></span>
                  </h6>
                </div>

                <div *ngSwitchCase="'MODIFY - NEW'">

                  <h6>
                    <span class="badge bg-info" [innerHtml]='worklist.effectivity'></span>
                  </h6>
                </div>

                <div *ngSwitchCase="'UPDATE'">

                  <h6>
                    <span class="badge bg-info" [innerHtml]='worklist.effectivity'></span>
                  </h6>
                </div>

                <div *ngSwitchDefault>

                  <h6>
                    <span class="badge bg-danger" [innerHtml]='worklist.effectivity'></span>
                  </h6>
                </div>


              </div>

            </div>
            <!---
           <h6>{{ worklist.effectivity }}</h6>-->
          </td>
          <td>
            <h6>
              {{ worklist.dueDate.toDate() | date: "MMMM dd, yyyy hh:mm:ss" }}
            </h6>
          </td>
          <td>
            <div class="col align-right">
              <button mat-flat-button color="primary" class="m-1" [disabled]="
                  worklist.status !== enum_service_status.FOR_APPROVAL
                " (click)="approveWorklist(worklist.id)">
                Approve
              </button>
              <button [routerLink]="['/artemis/view/detail', worklist.documentId]"
                [queryParams]="{ worklistId: worklist.id }" mat-flat-button class="btn btn-warning m-1">
                Review
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-flat-button class="btn btn-warning m-2" [disabled]="!hasPendingWorklist" (click)="approveAll()">
      Approve All
    </button>
  </mat-card-actions>
</mat-card>
<br>
<mat-card>
  <mat-card-header class="mb-2">
    <mat-card-title class="text-primary"><strong>Existing Services</strong></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table class="table table-responsive table-striped table-sm" mat-table [dataSource]="dataSource" matSort
      (matSortChange)="sortChange($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="datemodified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Last Modified </th>
        <td mat-cell *matCellDef="let row"> {{ row.date_posted.toDate() | date: "yyyy-MMM-dd" }} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="servicename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Service Name </th>
        <td mat-cell *matCellDef="let row" style="cursor:pointer;">{{row.serviceDetails.service_name}}</td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row">
          <div class="align-items-center">
            <div [ngSwitch]="row.posted_status">

              <div *ngSwitchCase="'POSTED'">

                <span class="badge  bg-primary ">{{row.posted_status}}</span>

              </div>
              <div *ngSwitchCase="'FOR DELISTING'">

                <span class="badge  bg-danger ">{{row.posted_status}}</span>

              </div>

              <div *ngSwitchDefault>

                <span class="badge  bg-info ">{{row.posted_status}}</span>
              </div>

            </div>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50,100]" aria-label="Select page of users"></mat-paginator>
    <div [disabled]="!allWorklistsApproved">
      <p class="note note-info mt-2">
        <strong>Upload Certificate of Compliance:</strong>Before you can submit Citizen's Charter to ARTA you need to
        generate and upload a signed copy of Certificate of Compliance using the button below. This certification is
        being issued to attest to the compliance of the agency with the foregoing statements that can be validated by
        the Authority.

      </p>

      <button [disabled]="generatingcoc || (artaongoingReview && ccisEmpty)" class="mt-1" mat-flat-button color="primary" (click)="createCOC()">Create Certificate of
        Compliance</button>
      <div  class="form-group mt-2  fw-bold">
        <label class="form-input-header" for="cocurl">Upload Signed Certificate of Compliance *
          {{uploadProgress}}</label>
        <input [disabled]="!latestCOCdraft || generatingcoc" type="file" class="form-control" (change)="saveFileInformation($event)" />
         </div>
         <mat-hint *ngIf="!latestCOCdraft">Please generate Certificate of Compliance.</mat-hint>
         <mat-progress-bar class="mt-2" *ngIf="loading || generatingcoc"  mode="indeterminate"></mat-progress-bar>
    
    </div>

  </mat-card-content>

  <!--hasPendingWorklist ||-->
  <mat-card-actions align="end">
    <button mat-flat-button class="btn btn-warning m-2" [disabled]="( ccisEmpty || generatingcoc || loading)"
      (click)="openReasonForDisapproval()">
      Disapprove CC
    </button>
    <button mat-flat-button class="btn btn-warning m-2" [disabled]="hasPendingWorklist || !submiCCenable || loading "
      (click)="submitCCToARTA()">
      Submit CC to ARTA
    </button>
  </mat-card-actions>
</mat-card>

<!--
  <div class="container-fluid border border-2">
  <div class="row">
    <div class="inbox-title">
      <h3>For Action</h3>
    </div>
    <div >
     
    </div>
  </div>
  <div class="row">
    <div class="col align-right">
      <button
        mat-flat-button
        class="btn btn-warning m-2"
        [disabled]="!hasPendingWorklist"
        (click)="approveAll()"
      >
        Approve All
      </button>
    </div>
  </div>

</div>

<div classs="container-fluid border border-2">
  <div class="row">
    <div class="inbox-title">
      <h3>Existing Services (X of X)</h3>
    </div>
    
  </div>
  <div class="row">
    <div class="col align-right">
     
    </div>
  </div>
</div>

-->