<app-artemis-navigation-header [currentPage]="'CharterMain'"></app-artemis-navigation-header>

<div class="content">

    <div class="container-fluid">
        <!-- Title -->
        <div class="row p-3">
            <div class="col-6">
                <h6 class="fw-bold" *ngIf="charter.displayDate">{{ charter.displayDate }}</h6>
                <!-- <h2 class="text-success fw-bold" *ngIf="charter.filename">{{charter.organization_level_01}}</h2> -->
                <h2 class="text-success fw-bold" *ngIf="charter.agency_name">{{charter.agency_name}}</h2>
                <!-- <h6 *ngIf="charter.organization_grouping_01"> by {{ charter.organization_grouping_01 }} </h6> -->
                <h6 *ngIf="charter.agency_category"> by {{ charter.agency_category }} </h6>
            </div>

            <div *ngIf="isAdmin" class="m-2">
                <button (click)="deletecharter()" class="row btn btn-danger">Delete (No Warning Prompt)</button>
            </div>
            <div *ngIf="isAdmin" class="m-2">
                <a class="row btn btn-secondary" routerLink="/artemis/citizen-charter/update/{{id}}">Update Charter</a>
            </div>
            <div *ngIf="(isAdmin || isEncoder || isArta || isAgencyHead || isVerifier) && charter.fromPhase == 'phase2'" class="m-2">
                <a class="row btn btn-success" (click)="goToCCReport()" >Check Charter Report</a>
                <!-- routerLink="/artemis/citizen-charter/view/report" [queryParams]="charterQP" -->
            </div>

        </div>

        <p class="border border-dark"></p>
        <div class="row">

            <div class="col" *ngIf="charter.organization_level_00">
                Department and Agency:
                <strong class="fw-bold">
                {{ charter.organization_level_00 }} - {{ charter.organization_level_01 }}
            </strong> 
            <!-- <strong class="fw-bold">{{ charter.agency_initials }}</strong> -->
            <strong *ngIf="charter.agency_abv" class="fw-bold">{{ charter.agency_abv }}</strong>
            </div>
            <!-- <div class="col" *ngIf="charter.organization_level_02">
                Operating Unit (OU):
                <strong class="fw-bold">{{ charter.organization_level_02 }} - {{ charter.organization_level_03 }}</strong>
            </div> -->

        </div>


        <div class="row p-2 my-4" *ngIf="fileLoading || file; else noData">

            <!-- Content -->
            <div class="col m-3">
                <div class="row mx-3">
                    <a target="_blank" [hidden]="!file" href="{{file}}" class="btn btn-lg btn-primary float-end">Download Charter</a>
                </div>

                <div class="row my-3 border border-dark p-3 px-0">
                    <div class="d-flex justify-content-center align-self-center">
                        <div class="spinner-grow text-info" role="status" [hidden]="file">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <pdf-viewer *ngIf="file" [src]="file"></pdf-viewer>
                    <!-- <object *ngIf="file && charter.fromPhase == 'phase2'" [data]="file + '#toolbar=0&navpanes=0' | safeUrl" type="application/pdf"></object> -->
                </div>
            </div>
        </div>
        <ng-template #noData>
            <div class="row p-2 my-4 d-flex justify-content-center">
                No Data
            </div>
        </ng-template>
    </div>

</div>