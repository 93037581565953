import { Component, OnInit } from '@angular/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-artemis-comment-page',
  templateUrl: './artemis-comment-page.component.html',
  styleUrls: ['./artemis-comment-page.component.scss']
})
export class ArtemisCommentPageComponent implements OnInit {
  recaptchaV2SiteKey: string = environment.recaptchaSiteKeys.v2;
  // Modal
  comment: any;
  user: any;
  url: any;
  document: any;
  created_at: any;

  loading: boolean = false;

  // File
  temp_file: any = [];
  // Date
  todayDate: Date = new Date();
  // phase 1 - sample form
  phase1form = new UntypedFormGroup({
    comment: new UntypedFormControl(''),
    user: new UntypedFormControl(''),
    document: new UntypedFormControl(''),
    url: new UntypedFormControl(''),
    captcha: new UntypedFormControl('', Validators.required),
  });
  // Path storage
  path: any = '';
  
  data: any = [];

  // User id
  user_id: any;

  constructor(
    public auth: AngularFireAuth, 
    private afs: AngularFirestore, 
    private store: AngularFireStorage,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.auth.user.subscribe( data => {
      this.user_id = data?.uid;
    });
  }

  public resolved(captchaResponse: string): void {
    this.phase1form.value.captcha = captchaResponse;
    //console.log(`Resolved captcha with response: ${this.phase1form.value.captcha}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    //console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  async onSubmit() {
    this.loading = true;
    Swal.fire({
      title: 'Processing',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        if (this.temp_file.length > 0) {
          for (let x = 0; x < this.temp_file.length; x++) {
            this.path = `comment_artemis/${this.todayDate.getFullYear()}${this.todayDate.getMonth()}${this.todayDate.getDate()}/${this.user_id}/files/`;
            let destinationPath = `comment_artemis/${this.todayDate.getFullYear()}${this.todayDate.getMonth()}${this.todayDate.getDate()}/${this.user_id}/files/${this.temp_file[x].name}`;
            let destinationRef = this.store.ref(destinationPath);
            let task = this.store.upload(destinationPath, this.temp_file);
            console.log(task.percentageChanges());
          }
        }
        this.phase1form.value.document = this.path;
        this.data.push({
          uuid: this.afs.createId(),
          comments: this.phase1form.value,
          type: this.route.snapshot.params.service,
          created_at: formatDate(this.todayDate, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
        });
        //console.log(this.data)
        this.afs.collection(`Comment Artemis`).add(this.data[0]).then((docRef:any) => {
          this.loading = false;
          Swal.close();
          Swal.fire({
            title:'Successfully processed',
            icon: 'info'
          });
          this.resetForm();
        }).catch((error:any) =>{
          this.loading = false;
          Swal.close();
          Swal.fire({
            title:'System error',
            titleText: 'Please refresh the page',
            icon: 'error'
          });
        });
      }
    })
  }

  resetForm() {
    this.phase1form.reset();
    this.data = [];
    this.temp_file = [];
  }

  documentOnChange(data: any) {
    if (data.target.files.length > 0) {
      let temp = data.target.files;
      for (let x = 0; x < temp.length; x++) {
        if (Object.keys(this.temp_file).length == 0) {
          this.temp_file.push(temp[x]);
        } else {
          let found = this.temp_file.find((elements: any) => elements.name === temp[x].name);
          if (!found) {
            this.temp_file.push(temp[x]);
          }
        }
      }
      this.phase1form.value.document = this.temp_file;
    }
  }

  deleteFile(data: any) {
    this.temp_file = this.temp_file.filter((element: any) => element.name !== data);
  }
}
