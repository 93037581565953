<div class="inboxStart">

  <!--Page Title -->
  <div class="row" style="background-color:whitesmoke;">
    <nav aria-label="breadcrumb" class="col-6 py-2">
      <ol class="breadcrumb my-1 ms-4">
          <!--<li class="breadcrumb-item active fw-bold" style="color:#595959;" aria-current="page" *ngIf="currentFormPage == 'preface'">CREATE A PROPOSED REGULATIONS</li>-->
          <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:13px" aria-current="page">MY WORKLIST<i class="fas fa-chevron-right fa-sm mx-2"></i>EXISTING</li>
      </ol>
    </nav> 
    <div class="col-6">
      <button class="my-2 me-4 btn btn-dark btn-sm btn-custom float-end" (click)="showOnInboxViewport()">Back To Inbox</button>
    </div>
  </div>

  <div class="padded">
    <!-- title -->
    <div class="row">
      <div class="col">

        <div class="row">
          <div class="col-md-3 col-sm-12" style="background-color:#F0F0F0;">
            <div class="text-center my-2">
              <div class="col-12 rounded-3 py-4" style="background-color:#343A40;"> <!-- {{ style_theme }} -->
                <h6 class="fw-bold m-2" style="font-size:14px;color:#FFFFFF;" >{{ reg_status }}</h6> 
                <!-- <span style="font-size:14px;color:#FFFFFF;" *ngIf="rulestage">{{rulestage.stage}} {{rulestage.rulemakingStage}}</span> -->
              </div>
            </div>
          </div>

          <div class="col-md-9 col-sm-12 p-3" style="border-bottom: 7px solid #F2F2F2;">
            <!-- <h2 class="{{style_theme}}-text report-title fw-bold">[LONG TITLE OF REGULATION HERE]</h2> -->
            <h6 class="report-title fw-bold my-3">
              {{ reg_long_name }}
            </h6>
          </div>
        </div>

      </div>
    </div>

    <!-- Sidenav -->
    <div class="row mb-5">
      <div class="col-lg-3 col-sm-12" style="background-color: #F0F0F0;">

        <!-- Nav-pills Buttons -->
        <div class="card col sidebar-custom mt-2 mb-3" *ngIf="isSidebarVisible">
          <div class="align-left">
            <nav aria-label="breadcrumb" class="breadcrumb-custom">
              <ol class="breadcrumb p-2 mb-0">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">MENU</li>
              </ol>
            </nav>
          </div>
          
          <!-- Side Menu -->
          <ul class="nav nav-pills nav flex-column py-3" id="pills-tab" role="tablist">
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" [ngClass]="phase2View? 'active':''" (click)="onPhase2View()" data-toggle="pill"  role="tab" aria-controls="pills-phase2" aria-selected="false">Regulation Details</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" [ngClass]="textRegView? 'active':''" (click)="onTextRegView()" data-toggle="pill"  role="tab" aria-controls="pills-textReg" aria-selected="false">Text of the Regulation</a>
            </li>
            <li class="nav-item" style="cursor: pointer;" *ngIf="current_regulation && current_regulation.proposed_regulation_02_id">
              <a class="nav-link" [ngClass]="piaView? 'active':''" (click)="onPiaView()" data-toggle="pill"  role="tab" aria-controls="pills-pia" aria-selected="false">PIA</a>
            </li>
            <li class="nav-item" style="cursor:pointer;border-top:1px solid rgba(0,0,0,0.1);padding-top:10px;margin-top:10px;">
              <a class="nav-link" [ngClass]="commentsView? 'active':''" (click)="onCommentsView()" data-toggle="pill"  role="tab" aria-controls="pills-comments" aria-selected="false">
                <i class="fas fa-comments me-2"></i>View Comments
              </a>
            </li>
          </ul>
        </div>

        <!-- Action Buttons -->
        <div class="card col-12 sidebar-custom my-4" *ngIf="actionButtonAllowed">
          <div class="align-left">
            <nav aria-label="breadcrumb" class="breadcrumb-custom">
              <ol class="breadcrumb p-2 mb-0">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">ACTION</li>
              </ol>
            </nav>
          </div>

          <div class="row p-3 text-center">

            <!-- Encoder Action Buttons -->
            <div class="col-12" *ngIf="(actionButtonAllowed) && (reg_status == enum_regulation_status.DRAFT || reg_status == enum_regulation_status.FOR_REVISION
              || reg_status == enum_regulation_status.FOR_MODIFICATION)">
                
              <!-- Edit-->
              <div class="dropdown">
                <button id="btnActionEdit" type="button" class="btn btn-dark btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown">
                    <i class="fas fa-edit me-2"></i>EDIT
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionEdit">
                  <div class="btn-group dropdown-item" role="group">
                    <button type="button" class="btn btn-dark btn-sm rounded-end rounded-9" (click)="editRegulation()" matTooltipPosition="above" matTooltip="Yes">
                      <i class="fas fa-check"></i>
                    </button>
                    <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </ul>
              </div>
            </div>

            <!-- Encoder Action Buttons -->
            <!-- Submit Draft -->
            <div class="col-12" *ngIf="reg_status === enum_regulation_status.DRAFT">
              <div class="dropdown my-2">
                <button id="btnActionSubmit" type="button" [disabled]="!true" class="btn btn-primary btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown"
                  matTooltipPosition="above" matTooltip="Submit For Verification">
                    <i class="fas fa-share-square me-2"></i>SUBMIT
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionSubmit">
                  <div class="btn-group dropdown-item" role="group">
                    <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_DRAFT)" 
                      matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                    </button>
                    <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </ul>
              </div>
            </div>

            <!-- Encoder Action Buttons -->
            <!-- resubmit for approval agency/arta -->
            <!-- Resubmissions must now go through verifier first -->
            <div class="col-12" *ngIf="reg_status === enum_regulation_status.FOR_REVISION || reg_status === enum_regulation_status.FOR_MODIFICATION">
              <div class="dropdown my-2">
                <button id="btnActionReSubmit" type="button" class="btn btn-primary btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown" 
                  matTooltipPosition="above" matTooltip="Re-Submit For Verification">
                    <i class="fas fa-share-square me-2"></i>RE-SUBMIT
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionReSubmit">
                  <div class="btn-group dropdown-item" role="group">
                    <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_DRAFT)" 
                      matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                    </button>
                    <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </ul>
              </div>
            </div>

            <!-- Verifier Action Buttons -->
            <div class="col-12" *ngIf="commentButtonsEnabled">
              <div class="dropdown my-2">
                <button id="btnActionAddComment" type="button" class="btn btn-dark btn-custom dropdown-toggle w-100"
                  aria-label="Comment on regulation details" data-mdb-toggle="dropdown" matTooltipPosition="above"
                  matTooltip="Add Comment">
                  <i class="fas fa-edit me-2"></i>Add Comment
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionAddComment">
                  <app-dropdown-comment [worklist_id]=docuFromInboxList [reg_docId]=docuFromInboxList></app-dropdown-comment>
                </ul>
              </div>
            </div>

            <div class="col-12" *ngIf="reg_status === enum_regulation_status.FOR_VERIFICATION || reg_status === enum_regulation_status.RESUBMITTED">
              <!-- for revision -->
              <!-- <div *ngIf="reg_status !== enum_regulation_status.DRAFT && reg_status !== enum_regulation_status.FOR_REVISION" class="row"> -->
              <div class="dropdown">
                <button id="btnActionReturnForRevision" type="button" class="btn btn-primary btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown"
                  matTooltipPosition="above" matTooltip="Return For Revision">
                    <i class="fas fa-share-square fa-flip-horizontal me-2"></i>RETURN
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionReturnForRevision">
                  <div class="btn-group dropdown-item" role="group">
                    <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)" matTooltipPosition="above" matTooltip="Yes">
                      <i class="fas fa-check"></i>
                    </button>
                    <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </ul>
              </div>

              <!-- for approval -->
              <div class="dropdown my-2">
                <button id="btnActionSubmitForApproval" type="button" [disabled]="!true" class="btn btn-warning btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown" 
                  matTooltipPosition="above" matTooltip="Submit For Approval">
                    <i class="fas fa-share-square me-2"></i>SUBMIT
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionSubmitForApproval">
                  <div class="btn-group dropdown-item" role="group">
                    <button type="button" class="btn btn-warning btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_TO_AGENCY_APPR)" matTooltipPosition="above" matTooltip="Yes">
                      <i class="fas fa-check"></i>
                    </button>
                    <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </ul>
              </div>
            </div>

            <!-- HOA Action Buttons -->
              <!-- for revision -->
              <!-- <div *ngIf="reg_status !== enum_regulation_status.DRAFT && reg_status !== enum_regulation_status.FOR_REVISION" class="row"> -->
              <div class="col-12" *ngIf="reg_status === enum_regulation_status.FOR_APPROVAL || reg_status === enum_regulation_status.RESUBMITTED">
                <div class="dropdown">
                  <button id="btnActionReturnForRevision" type="button" class="btn btn-primary btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Return For Revision">
                      <i class="fas fa-share-square fa-flip-horizontal me-2"></i>RETURN
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionReturnForRevision">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div>

                <!-- approve / submit to arta -->
                <!-- <div class="dropdown my-2">
                  <button id="btnActionApprove" type="button" class="btn btn-warning btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Approve For ARTA Review">
                      <i class="fas fa-thumbs-up me-2"></i>APPROVE
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionApprove">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-warning btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_TO_ARTA)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div> -->

                <!-- posting -->
                <!-- *ngIf="reg_status === enum_regulation_status.FOR_POSTING" -->
                <div class="dropdown my-2">
                  <button id="btnActionPostRegulation" type="button" class="btn btn-danger btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Post Regulation Now">POST REGULATION
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionPostRegulation">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-danger btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.POST_REGULATION)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div>
              </div>

              <!-- posting -->
              <div class="dropdown my-2" *ngIf="reg_status === enum_regulation_status.FOR_POSTING">
                <button id="btnActionPostRegulationNow" class="btn btn-danger btn-custom dropdown-toggle w-100" data-mdb-toggle="dropdown"
                  matTooltipPosition="above" matTooltip="Post Regulation Now">POST REGULATION
                </button>
                <ul class="dropdown-menu w-100" aria-labelledby="btnActionPostRegulationNow">
                  <div class="btn-group dropdown-item" role="group">
                    <button type="button" class="btn btn-danger btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.POST_REGULATION)" matTooltipPosition="above" matTooltip="Yes">
                      <i class="fas fa-check"></i>
                    </button>
                    <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </ul>
              </div>



            <div class="p-0">
              <!-- buttons -->
              <div *ngIf="actionButtonAllowed">
                
            
                <!-- <div
                  class="row"
                  *ngIf="
                    regulationType === 'PROPOSED' &&
                    (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR ||
                      reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG ||
                      reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG)
                  "
                >
                  <div class="col-6"></div>
                  <div class="col-6 align-right">
                    <button
                      (click)="submit(enum_action_flag.MARK_FOR_RIA)"
                      class="btn btn-primary m-1 btn-min_width"
                    >
                      For RIA
                    </button>
                  </div>
                </div> -->
            
                
            

            
                
            
                

                <!-- re-approve modifications -->
                <div class="row"
                  *ngIf="reg_status === enum_regulation_status.FOR_REAPPROVAL">
                  <div class="col-6"></div>
                  <div class="col-6">
                    <button class="btn btn-primary m-1"
                      (click)="submit(enum_action_flag.SUBMIT_TO_ARTA)">
                          Re-Approve
                    </button>
                  </div>
                </div>

                <!-- return to agency -->
                <div class="row"
                  *ngIf="(reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR)
                  || (reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR)
                  || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG)
                  || (reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG)
                  || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG)
                  || (reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG)">
                  <div class="col-6"></div>
                  <div class="col-6">
                    <button class="btn btn-primary m-1"
                      (click)="submit(enum_action_flag.RETURN_FROM_ARTA)">
                      Return to Agency for Modifications
                    </button>
                  </div>
                </div>
                
                <!-- for approval arta/bro dc/dir --> 
                <!-- <div class="row"
                  *ngIf=" (reg_status === enum_regulation_status.FOR_ARTA_REVIEW)
                  || (reg_status === enum_regulation_status.FOR_REAPPROVAL)
                  || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DC)">
                  <div class="col-6"></div>
                  <div class="col-6">
                    <button class="btn btn-primary m-1"
                      (click)="submit(((reg_status === enum_regulation_status.FOR_ARTA_REVIEW)
                        || (reg_status === enum_regulation_status.FOR_REAPPROVAL))
                          ? enum_action_flag.SUBMIT_TO_ARTA_BRO_DC
                          : enum_action_flag.SUBMIT_TO_ARTA_BRO_DIR)">
                      For Approval (Next Level)
                    </button>
                  </div>
                </div> -->

                
                <div class="row"
                  *ngIf=" (reg_status === enum_regulation_status.FOR_ARTA_REVIEW)
                  || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DC)">
                  <div class="col-6"></div>
                  <div class="col-6">
                    <button class="btn btn-primary m-1"
                      (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW)
                          ? enum_action_flag.SUBMIT_TO_ARTA_BRO_DC
                          : enum_action_flag.SUBMIT_TO_ARTA_BRO_DIR)">
                      For Approval (Next Level)
                    </button>
                  </div>
                </div>

                <!-- decs for approval arta/bro dc/dir -->
                <div class="row"
                  *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW
                    || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC">
                  <div class="col-6"></div>
                  <div class="col-6">
                    <button class="btn btn-primary m-1"
                      (click)="submit((reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW)
                        ? enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DC
                        : enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DIR)">
                      Decision For Approval (Next Level)
                    </button>
                  </div>
                </div>
            
                <!-- for post regulatio for arta dir -->
                <div class="row"
                  *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR 
                    || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR">
                  <div class="col-6">
                    <button class="btn btn-primary m-1 btn-min_width"
                      (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR)
                        ? enum_action_flag.SUBMIT_TO_ARTA_DDG
                        : enum_action_flag.DRIS_SUBMIT_TO_ARTA_DDG)">
                      Escalate to DDG
                    </button>
                  </div>
                  <div class="col-6 align-right">
                    <button class="btn btn-primary m-1 btn-min_width"
                    (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
                      For Posting
                    </button>
                  </div>
                </div>
            
                <!-- for post regulation for arta ddg -->
                <div class="row"
                  *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG
                  || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG">
                  <div class="col-6">
                    <button class="btn btn-primary m-1 btn-min_width"
                      (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG)
                            ? enum_action_flag.SUBMIT_TO_ARTA_DG
                            : enum_action_flag.DRIS_SUBMIT_TO_ARTA_DG)">
                      Escalate to DG
                    </button>
                  </div>
                  <div class="col-6 align-right">
                    <button class="btn btn-primary m-1 btn-min_width"
                      (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
                      For Posting
                    </button>
                  </div>
                </div>
            
                <!-- for post regulation  -->
                <div class="row"
                  *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG
                  || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG">
                  <div class="col-6"></div>
                  <div class="col-6 align-right">
                    <button class="btn btn-primary m-1 btn-min_width"
                      (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
                      For Posting
                    </button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <!-- Document Details -->
        <div class="card col sidebar-custom mb-3">
          <div class="align-left">
            <nav aria-label="breadcrumb" class="breadcrumb-custom">
              <ol class="breadcrumb p-2 mb-0">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">DOCUMENT DETAILS</li>
              </ol>
            </nav>
          </div>

          <div class="table-responsive">
            <table class="table table-borderless">
                <thead class="info-subheader">
                    <tr></tr>
                </thead>

                <tbody class="info-subheader">
                  <tr>
                      <td><span class="fw-bold">Created by:</span><br />{{ reg_fromOfficer }}</td>
                  </tr>
                  <tr *ngIf="reg_dateGenerated">
                    <td><span class="fw-bold">Date/Time created:</span><br />{{ reg_dateGenerated.toDate() | date: "MMMM dd, yyyy hh:mm:ss a" }}</td>
                  </tr>
                  <tr *ngIf="reg_dueDate">
                    <td><span class="fw-bold">Due Date:</span><br />{{ reg_dueDate.toDate() | date: "MMMM dd, yyyy hh:mm:ss a" }}</td>
                  </tr>
                </tbody>

            </table>
          </div>
          
        </div>
      </div>

      <!-- content -->
      <div class="col-lg-9 col-sm-12">
        <div class="card padded">

          <!-- regulation details -->
          <div *ngIf="phase2View && current_regulation">
            <app-worklist-regulations-phase2-view *ngIf="current_regulation.id" [id]="current_regulation.id" [docuFromInboxList]="docuFromInboxList" [paramFromInboxList]="paramFromInboxList" [commentButtonsEnabled]="commentButtonsEnabled" collection="regulations-existing-phase-02"></app-worklist-regulations-phase2-view>
          </div>
          <div *ngIf="phase2View && current_regulation == null">
            <div class="row" style="padding:0.5em;">
              <label class="form-input-header fw-bold" for="feedback_remark">
                <h5><b>No Data</b></h5></label>
            </div>
          </div>

          <!-- Text Regulation -->
          <div *ngIf="textRegView">
            <div class="mt-3">
              <div class="row">

                <div class="table-responsive" *ngFor="let section of reg_text; let secidx = index">
                  <table class="table table-bordered">
                    <thead class="info-subheader">
                      <tr></tr>
                    </thead>

                    <tbody class="info-subheader">
                      <tr>
                        <th>
                          <h6>{{section.info.section_title}}
                            <!-- <button *ngIf="commentButtonsEnabled" mat-icon-button aria-label="Comment on this section" (click)="goToComments('regtext',secidx)">
                              <mat-icon>edit</mat-icon>
                            </button> -->
                            <!-- <button mat-button (click)="openDialog()">Add Comment</button> -->
                          </h6>
                        </th>
                      </tr>

                      <tr *ngFor="let type of section.info.section_type; let i = index">
                        <td><ng-container *ngIf="i > 0">, </ng-container>{{type}}</td>
                      </tr>

                      <tr>
                        <td>
                          <div [innerHtml]="section.info.section_text"></div>
                            <ng-container *ngFor="let subsection of section.subsections; let subsecidx = index">
                              <div class="row" style="padding-left:2em;">
                                <label class="form-input-header fw-bold" for="feedback_remark">
                                <h6 class="section-header-link fw-bold mb-3">{{subsection.subsection_title}}
                                    <!-- <button *ngIf="commentButtonsEnabled" mat-icon-button aria-label="Comment on this subsection" (click)="goToComments('regtext',secidx,subsecidx)">
                                    <mat-icon>edit</mat-icon>
                                    </button> -->
                                </h6>
                                </label>
                                <div class="row" style="padding-left:2em;">
                                    <div [innerHtml]="subsection.subsection_text"></div>
                                    <div class="d-flex justify-content-center" *ngIf="subsection.fileUrl">
                                        <img style="width:200px;" [src]="subsection.fileUrl">
                                    </div>
                                </div>
                              </div>
                            </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-responsive" *ngIf="reg_text_annex_file && reg_text_annex_file.has_annex_files">
                  <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                      <tr></tr>
                    </thead>

                    <tbody class="info-subheader">
                      <tr>
                        <th><h6>Annexes to Regulation</h6></th>
                      </tr>

                      <tr>
                        <td>
                          <div>
                            <ng-container *ngIf="(reg_text_annex_file.annex_file || reg_text_annex_file.annex_file_download_link); else noAnnexFile">
                              <div class="d-flex justify-content-center"  *ngIf="reg_text_annex_file.annex_file_type == 'zip'; else regTextNotZipFile">
                                <button class="btn btn-primary" type="button" (click)="downloadFile(reg_text_annex_file.annex_file_download_link,reg_text_annex_file.annex_file_name,'reg_text_annex')">
                                  Annexes to Regulation - Download: {{reg_text_annex_file.reg_text_annex_file_name}}
                                </button>
                              </div>

                              <ng-template #regTextNotZipFile>
                                <div class="row p-2 w-100 ms-0 file-container" >
                                  <div class="col-1">
                                    <button *ngIf="reg_text_annex_file.annex_file" (click)="pageShift('back','reg_text_annex')" type="button" class=" h-100 left-button btn-dark">
                                      <i class="fas fa-chevron-left"></i>
                                    </button>
                                  </div>

                                  <div class=" col-10">
                                    <div *ngIf="reg_text_annex_file.annex_file" >
                                      <div class="d-flex justify-content-center"><p>{{reg_text_annex_file.annex_file_page}} / {{reg_text_annex_file.annex_file_total_page ? reg_text_annex_file.annex_file_total_page : "1"}}</p></div>
                                        <pdf-viewer *ngIf="reg_text_annex_file.annex_file"
                                        style="min-height: 1070px; max-height: 1200px;"
                                        (after-load-complete)="afterLoadComplete($event,'reg_text_annex')"
                                        [show-all]="false"
                                        [page]="reg_text_annex_file.annex_file_page"
                                        [stick-to-page]="true"
                                        [src]="reg_text_annex_file.annex_file"></pdf-viewer>
                                      <div class="d-flex justify-content-center"><p>{{reg_text_annex_file.annex_file_page}} / {{reg_text_annex_file.annex_file_total_page ? reg_text_annex_file.annex_file_total_page : "1"}}</p></div>
                                    </div>
                                  </div>

                                  <div class="col-1">
                                    <button *ngIf="reg_text_annex_file.annex_file" (click)="pageShift('next','reg_text_annex')" type="button" class=" h-100 right-button btn-dark">
                                      <i class="fas fa-chevron-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </ng-template>
                            </ng-container>

                            <ng-template #noAnnexFile>
                              <div class="d-flex justify-content-center" style="padding:1em;" >
                                <div class="row" style="padding:0.5em;">
                                  <label class="form-input-header fw-bold">
                                    <p>No Annexes to Regulation File</p>
                                  </label>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              
              </div>
            </div>
            <!-- <app-worklist-regulations-text-regulation-view [reg_text]="reg_text"></app-worklist-regulations-text-regulation-view> -->
          </div> 

          <!-- pia -->
          <div *ngIf="piaView" >
            <app-worklist-regulations-pia-view *ngIf="current_regulation.proposed_regulation_02_id" [id]="current_regulation.proposed_regulation_02_id" [current_regulation]=""></app-worklist-regulations-pia-view>
            <div class="d-flex justify-content-center" style="padding:1em;" *ngIf="current_regulation.proposed_regulation_02_id == null || current_regulation.proposed_regulation_02_id == undefined">
                <div class="row" style="padding:0.5em;">
                    <label class="form-input-header fw-bold" for="feedback_remark"
                        ><h3><b>No PIA Data</b></h3></label>
                </div>
            </div>
          </div>

          <!-- Comments -->
          <div *ngIf="commentsView">

            <table class="table table-striped table-bordered">
              <thead class="info-subheader">
                <tr></tr>
              </thead>

              <tbody class="info-subheader">
                <tr>
                  <th>
                    <h6>Comments on Regulation Details</h6>
                  </th>
                </tr>
                <tr>
                  <td>{{ reg_comment_details }}</td>
                </tr>
              </tbody>

              <tbody class="info-subheader">
                <tr>
                  <th>
                    <h6>Comments on the Text of the Regulation</h6>
                  </th>
                </tr>
                <tr>
                  <td>{{ reg_comment_regtext }}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>

    </div>

    <!-- Footer Bottons -->
    <div class="row g-3 my-5" *ngIf="actionButtonAllowed">
      <div class="d-flex flex-row justify-content-evenly">
        <div class="card-footer text-center col-md-3 mt-2">
          <button (click)="showOnInboxViewport()" class="m-2 btn btn-dark rounded-0 float-start">Back to Inbox</button>
        </div>

        <div class="card-footer text-center col-md-9 mt-2">

          <!-- Encoder Action Buttons -->
          <div class="float-end">
            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
              <div class="btn-group" role="group">

                <!-- Edit -->
                <div *ngIf="(actionButtonAllowed) && (reg_status == enum_regulation_status.DRAFT || reg_status == enum_regulation_status.FOR_REVISION
                  || reg_status == enum_regulation_status.FOR_MODIFICATION)">
                  <div class="dropdown my-2">
                    <button id="btnActionEdit" type="button" class="btn btn-dark rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown">
                        <i class="fas fa-edit me-2"></i>EDIT
                    </button>
                    <ul class="dropdown-menu w-100" aria-labelledby="btnActionEdit">
                      <div class="btn-group dropdown-item" role="group">
                        <button type="button" class="btn btn-dark btn-sm rounded-end rounded-9" (click)="editRegulation()" matTooltipPosition="above" matTooltip="Yes">
                          <i class="fas fa-check"></i>
                        </button>
                        <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </ul>
                  </div>
                </div>

                <!-- Submit Draft -->
                <div *ngIf="reg_status === enum_regulation_status.DRAFT">
                  <div class="dropdown my-2">
                    <button id="btnActionSubmit" type="button" [disabled]="!true" class="btn btn-primary rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown"
                      matTooltipPosition="above" matTooltip="Submit For Verification">
                        <i class="fas fa-share-square me-2"></i>SUBMIT
                    </button>
                    <ul class="dropdown-menu w-100" aria-labelledby="btnActionSubmit">
                      <div class="btn-group dropdown-item" role="group">
                        <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_DRAFT)" 
                          matTooltipPosition="above" matTooltip="Yes">
                            <i class="fas fa-check"></i>
                        </button>
                        <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </ul>
                  </div>
                </div>

                <!-- resubmit for approval agency/arta -->
                <!-- Resubmissions must now go through verifier first -->
                <div *ngIf="reg_status === enum_regulation_status.FOR_REVISION || reg_status === enum_regulation_status.FOR_MODIFICATION">
                  <div class="dropdown my-2">
                    <button id="btnActionReSubmit" type="button" class="btn btn-primary rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown" 
                      matTooltipPosition="above" matTooltip="Re-Submit For Verification">
                        <i class="fas fa-share-square me-2"></i>RE-SUBMIT
                    </button>
                    <ul class="dropdown-menu w-100" aria-labelledby="btnActionReSubmit">
                      <div class="btn-group dropdown-item" role="group">
                        <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_DRAFT)" 
                          matTooltipPosition="above" matTooltip="Yes">
                            <i class="fas fa-check"></i>
                        </button>
                        <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Verifier Action Buttons -->
          <div class="float-end" *ngIf="reg_status === enum_regulation_status.FOR_VERIFICATION || reg_status === enum_regulation_status.RESUBMITTED">
            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
              <div class="btn-group" role="group">

                <div class="dropdown my-2">
                  <button id="btnActionReturnForRevision" type="button" class="btn btn-primary rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Return For Revision">
                      <i class="fas fa-share-square fa-flip-horizontal me-2"></i>RETURN FOR REVISION
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionReturnForRevision">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div>
  
                <!-- for approval -->
                <div class="dropdown my-2">
                  <button id="btnActionSubmitForApproval" type="button" [disabled]="!true" class="btn btn-warning rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown" 
                    matTooltipPosition="above" matTooltip="Submit For Approval">
                      <i class="fas fa-share-square me-2"></i>SUBMIT FOR APPROVAL
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionSubmitForApproval">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-warning btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_TO_AGENCY_APPR)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div>

              </div>
            </div>
          </div>

          <!-- HOA Action Buttons -->
          <div class="float-end" *ngIf="reg_status === enum_regulation_status.FOR_APPROVAL || reg_status === enum_regulation_status.RESUBMITTED">
            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
              <div class="btn-group" role="group">

                <div class="dropdown my-2">
                  <button id="btnActionReturnForRevision" type="button" class="btn btn-primary rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Return For Revision">
                      <i class="fas fa-share-square fa-flip-horizontal me-2"></i>RETURN FOR REVISION
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionReturnForRevision">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-primary btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.RETURN_FROM_AGENCY_QA)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div>

                <!-- approve / submit to arta -->
                <!-- <div class="dropdown my-2">
                  <button id="btnActionApprove" type="button" class="btn btn-warning rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Approve For ARTA Review">
                      <i class="fas fa-thumbs-up me-2"></i>APPROVE
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionApprove">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-warning btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.SUBMIT_TO_ARTA)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div> -->

                <!-- posting -->
                <!-- *ngIf="reg_status === enum_regulation_status.FOR_POSTING" -->
                <div class="dropdown my-2">
                  <button id="btnActionPostRegulation" type="button" class="btn btn-danger rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown"
                    matTooltipPosition="above" matTooltip="Post Regulation Now">POST REGULATION
                  </button>
                  <ul class="dropdown-menu w-100" aria-labelledby="btnActionPostRegulation">
                    <div class="btn-group dropdown-item" role="group">
                      <button type="button" class="btn btn-danger btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.POST_REGULATION)" matTooltipPosition="above" matTooltip="Yes">
                        <i class="fas fa-check"></i>
                      </button>
                      <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ul>
                </div>

              </div>
            </div>
          </div>

          <!-- HOA Action Buttons part 2 -->
          <div class="col-4 float-end">
            <div class="dropdown my-2" *ngIf="reg_status === enum_regulation_status.FOR_POSTING">
              <button id="btnActionPostRegulationNow" type="button" class="btn btn-danger rounded-0 dropdown-toggle w-100" data-mdb-toggle="dropdown" 
                matTooltipPosition="above" matTooltip="Post Regulation Now">POST REGULATION
              </button>
              <ul class="dropdown-menu w-100" aria-labelledby="btnActionPostRegulationNow">
                <div class="btn-group dropdown-item" role="group">
                  <button type="button" class="btn btn-danger btn-sm rounded-end rounded-9" (click)="submit(enum_action_flag.POST_REGULATION)" matTooltipPosition="above" matTooltip="Yes">
                    <i class="fas fa-check"></i>
                  </button>
                  <button type="button" class="btn btn-dark btn-sm rounded-start rounded-9" matTooltipPosition="above" matTooltip="Cancel">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </ul>
            </div>
          </div>
      
















            <!-- ria -->
            <!-- <div class="row"
              *ngIf="(reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR 
                || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG 
                || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG)">
              <button (click)="submit(enum_action_flag.MARK_FOR_RIA)"
                class="btn btn-primary m-1 w-100">
                For RIA
              </button>
            </div> -->
      
          
      
          
      
          

          <!-- return to agency -->
          <div class="row"
            *ngIf="(reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR)
            || (reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR)
            || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG)
            || (reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG)
            || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG)
            || (reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG)">
            <div class="col-6"></div>
            <div class="col-6">
              <button class="btn btn-primary m-1"
                (click)="submit(enum_action_flag.RETURN_FROM_ARTA)">
                Return to Agency for Modifications
              </button>
            </div>
          </div>
          
          <!-- for approval arta/bro dc/dir -->
          <div class="row"
            *ngIf=" (reg_status === enum_regulation_status.FOR_ARTA_REVIEW)
            || (reg_status === enum_regulation_status.FOR_REAPPROVAL)
            || (reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DC)">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit(((reg_status === enum_regulation_status.FOR_ARTA_REVIEW)
                || (reg_status === enum_regulation_status.FOR_REAPPROVAL))
                  ? enum_action_flag.SUBMIT_TO_ARTA_BRO_DC
                  : enum_action_flag.SUBMIT_TO_ARTA_BRO_DIR)">
              For Approval (Next Level)
            </button>
          </div>
      
          <!-- decs for approval arta/bro dc/dir -->
          <div class="row"
            *ngIf="reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW
              || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit((reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW)
                ? enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DC
                : enum_action_flag.DRIS_SUBMIT_TO_ARTA_BRO_DIR)">
              Decision For Approval (Next Level)
            </button>
          </div>
      
          <!-- for post regulatio for arta dir -->
          <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR 
              || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR)
                ? enum_action_flag.SUBMIT_TO_ARTA_DDG
                : enum_action_flag.DRIS_SUBMIT_TO_ARTA_DDG)">
              Escalate to DDG
            </button>
          </div>
      
          <!-- <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR 
              || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR">
            <button class="btn btn-primary m-1 w-100"
            (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
              For Posting
            </button>
          </div> -->
      
          <!-- for post regulation for arta ddg -->
          <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG
            || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit((reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG)
                    ? enum_action_flag.SUBMIT_TO_ARTA_DG
                    : enum_action_flag.DRIS_SUBMIT_TO_ARTA_DG)">
              Escalate to DG
            </button>
          </div>
      
          <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DIR 
            || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR
            || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DDG
            || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG
            || reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG
            || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
              For Posting
            </button>
          </div>
      
          <!-- for post regulation  -->
          <!-- <div class="row"
            *ngIf="reg_status === enum_regulation_status.FOR_ARTA_REVIEW_DG
            || reg_status === enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG">
            <button class="btn btn-primary m-1 w-100"
              (click)="submit(enum_action_flag.SUBMIT_FOR_POSTING)">
              For Posting
            </button>
          </div> -->
        </div>
        
      </div>
    </div>

    <ng-template #showRestricted>
      <h1>This is a Restricted Page</h1>
    </ng-template>
    
  </div>
