import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfRenderService {

  constructor(private http: HttpClient) { }

  // fileUrl expects a complete url from the results data; do not use an interceptor for this service
  getPdfFile(fileUrl: string) {
    const headers = new HttpHeaders({
      Accept: "application/pdf",
    });

    return this.http.get<Blob>(fileUrl, {
      headers: headers,
      responseType: "blob" as "json",
    });
  }

  getAnyFile(fileUrl: string) {
    return this.http.get<Blob>(fileUrl, {
      responseType: "blob" as "json",
    });
  }

  getFileUrl(path: string) {
    const url = environment.apiBaseURL + "/getFile/" + encodeURIComponent(path);
    return this.http.get(url, { responseType: "text" });
  }

  getPrivateFileUrl(path: string) {
    const url = environment.apiBaseURL + "/getStorageFile/private/" + encodeURIComponent(path);
    return this.http.get(url, { responseType: "text" });
  }
}
