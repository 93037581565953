import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { info } from 'console';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-service-history-list',
  templateUrl: './artemis-service-history-list.component.html',
  styleUrls: ['./artemis-service-history-list.component.scss']
})
export class ArtemisServiceHistoryListComponent implements OnInit {

  @Input() service_id :any

  history_list:any[]=[]

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {

    
    this.loadserviceHistory();

  }


  loadserviceHistory(){
    this.afs.collection('Services Phase 02').doc(this.service_id)
    .collection("History").snapshotChanges()
    .pipe(tap(data=>{
      this.history_list = []
      data.forEach((info:any)=>{
          let item:any = info.payload.doc.data();
          item.id = info.payload.doc.id;
          this.history_list.push(item)
      })

      console.log("History",this.history_list)

    })).subscribe()
  }

}
