<div class="jumbotron bg-cover text-white p-4" style="background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url(./assets/Assets/pbris_header/arta-header.jpg)">
    <div class="row">
        <!-- Featured Video -->
        <div class="col">
            <h2 class="text-warning list-header">Featured Videos<label class="border-top border-warning w-50">-</label></h2>
            <iframe class="m-3 ms-0 border rounded" width="90%" height="315px" src="https://www.youtube-nocookie.com/embed/96Lu37Amu_8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <!-- Announcements -->
        <div class="col">
            <div class="row pb-4">
                <h2 routerLink="/artemis/announcements" class="text-warning list-header">Announcements<label class="border-top border-warning w-50">-</label></h2>
                <ul class="list-group text-white overflow-auto">
                    <li *ngFor="let announcement of list_of_announcements" class="list-group-flush border-bottom border-white bg-dark text-white p-3">
                        <h5 class="list-header announcement-title" (click)="callModal(announcement.title, announcement.description)">{{announcement.title}}</h5>
                        <small>Posted on {{announcement.date.toDate() | date:"MMMM dd, yyyy" }}</small>
                    </li>
                </ul>
            </div>

            <div class="row mt-3">
                <!-- Stay Connected -->
                <h2 class="text-warning list-header pb-2">Stay Connected<label class="border-top border-warning w-50">-</label></h2>
                <div class="d-flex justify-content-center">
                  <a
                    href="https://www.facebook.com/ARTAPh/"
                    class="btn btn-lg btn-dark btn-lg btn-floating mx-2"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f fa-2x"></i>
                  </a>
                  <a
                    href="https://twitter.com/ARTAgovph"
                    class="btn btn-lg btn-dark btn-lg btn-floating mx-2"
                    target="_blank"
                  >
                    <i class="fab fa-twitter fa-2x"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/artagovph/"
                    class="btn btn-lg btn-dark btn-lg btn-floating mx-2"
                    target="_blank"
                  >
                    <i class="fab fa-instagram fa-2x"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UChQr6Tl3lqcKfMd4ANNN75w?view_as=subscriber"
                    class="btn btn-lg btn-dark btn-lg btn-floating mx-1"
                    target="_blank"
                  >
                    <i class="fab fa-youtube fa-2x"></i>
                  </a>
                </div>
              </div>
        </div>
    </div>
</div>