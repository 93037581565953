import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app--reference-artemis-modal',
  templateUrl: './reference-artemis-modal.component.html',
  styleUrls: ['./reference-artemis-modal.component.scss']
})
export class ReferenceArtemisModalComponent implements OnInit {

  action:any;

  toEdit:boolean = false;
  toCreate:boolean= false;
  toView:boolean = false;
  toDelete:boolean = false;

  currentRef:any;

  method!: string;
  collection!: string;
  id?:any;
  show_form: boolean = false;
  del_confirm: boolean = false;

  viewDetails = {
    id: "",
    section: "",
    name: "",
    definition: "",
  }
  
  artemisRefForm = this.fb.group({
    // section: new FormControl('', Validators.required),
    // name: new FormControl('', Validators.required),
    // definition: new FormControl('', Validators.required)
  });
  
  title: string = "";

  resetValue = {
    id: "",
    section: "",
    name: "",
    definition: "",
  }

  checkRefForm = {
    motheragency:[{
      type:"required",message:"Name is required"
    }],
    section:[
      { type:"required", message:"Section is requried"}
    ],
    abbreviation:[
      { type:"required", message:"Abbreviation is requried"}
    ],
    name:[
      { type:"required", message:"Name is requried"}
    ],
    stage:[
      { type:"required", message:"Stage is requried"}
    ],
  };

  constructor(
    private afs: AngularFirestore,
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReferenceArtemisModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
  }

  ngOnInit(): void {
    this.data.success = false;
    if(this.data.action === 'view'){
      this.action = "View"
      this.toView = true
    }
    else if(this.data.action === 'create'){
      this.action = "Add"
      this.toCreate = true
    }
    else if(this.data.action === 'edit'){
      this.action = "Edit"
      this.toEdit = true
    }
    else if(this.data.action === 'delete'){
      this.action = "Delete"
      this.toDelete = true
    }

    if(this.data.currentRef == 'Agency Category'){
      this.artemisRefForm.addControl('abbreviation', new UntypedFormControl('', Validators.required))
      this.artemisRefForm.addControl('name', new UntypedFormControl('', Validators.required))

    }else if(this.data.currentRef == 'Mother Agency'){
      this.artemisRefForm.addControl('motheragency',new UntypedFormControl('',Validators.required))
    }
    else if(this.data.currentRef == 'Classification' || this.data.currentRef == 'Service Category'){
      this.artemisRefForm.addControl('section', new UntypedFormControl('', Validators.required))
      this.artemisRefForm.addControl('name', new UntypedFormControl('', Validators.required))
    }
    else if(this.data.currentRef == 'Stage of Life'){
      this.artemisRefForm.addControl('stage', new UntypedFormControl('', Validators.required))

    }

    this.title = this.data.currentRef
    if(this.data.action === 'edit' || this.data.action === 'delete' || this.data.action === 'view'){
      this.loadData(this.data.currentRef,this.data.refId)
      // if(this.data.currentRef == 'Stage of Life'){

      // }
    }
  }

  loadData(collection:any, id:any){
    this.afs.collection(collection).doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      if(info.payload.exists){
        let data:any = info.payload.data();
        data.id = id
        this.currentRef = data
        this.patchData(collection, data)
      }
    })
  }

  patchData(ref:any, data:any){
    if(ref == 'Agency Category'){
      this.artemisRefForm.patchValue({
        abbreviation: data.abbreviation ? data.abbreviation : "",
        name: data.name ? data.name : "",
      })
    }
    else if(ref == 'Mother Agency' || ref == 'Mother Agency'){
        this.artemisRefForm.patchValue({
            motheragency: data.motheragency ? data.motheragency : ""
        })
    }
    else if(ref == 'Classification' || ref == 'Service Category'){
      this.artemisRefForm.patchValue({
        section: data.section ? data.section : "",
        name: data.name ? data.name : "",
      })
    }
    else if(ref == 'Stage of Life'){
      this.artemisRefForm.patchValue({
        stage: data.stage ? data.stage : "",
      })
    }
    else if(ref == 'Life Event'){

    }
  }

  goBack(){
    this.dialogRef.close(this.data)
  }

  creating(){
    this.validateFields()
    if(this.artemisRefForm.valid){
      const artemisData = this.artemisRefForm.value
      this.afs.collection(this.data.currentRef).add(artemisData)
      .then(result=>{
        // this.dialogRef.close(this.data)
        this.data.refId = result.id
        this.data.success = true;
        this.dialogRef.close(this.data)
      })
      .catch(err=>{
        alert('Unable to create '+this.currentRef)
        this.dialogRef.close(this.data)
      })
    }
  }

  updating(){
    this.validateFields()
    if(this.artemisRefForm.valid){
      const artemisData = this.artemisRefForm.value
      this.afs.collection(this.data.currentRef).doc(this.data.refId).update(artemisData)
      .then(()=>{
        this.data.success = true;
        this.dialogRef.close(this.data)
      })
      .catch(err=>{
        alert('Unable to edit '+this.currentRef)
        this.dialogRef.close(this.data)
      })
    }
  }

  deleting(){
    this.afs.collection(this.data.currentRef).doc(this.data.refId).delete()
    .then(()=>{
      this.data.success = true;
      this.dialogRef.close(this.data)
    })
    .catch(err=>{
      alert('Unable to delete '+this.currentRef)
      this.dialogRef.close(this.data)
    })
  }



  isTouched(controlName:any){
    return (this.artemisRefForm.get(controlName) as UntypedFormControl).touched;
  }

  hasError(controlName:any, validType:any){
    return ((this.artemisRefForm.get(controlName) as UntypedFormControl).hasError(validType) 
    && ((this.artemisRefForm.get(controlName) as UntypedFormControl).dirty 
    || (this.artemisRefForm.get(controlName) as UntypedFormControl).touched));
  }

  validateFields(): void{
    if(!this.artemisRefForm.valid){
      this.artemisRefForm.markAsTouched();
      for(const control in this.artemisRefForm.controls){
        if(this.artemisRefForm.controls.hasOwnProperty(control)){
          this.artemisRefForm.controls[control].markAllAsTouched();
          this.artemisRefForm.controls[control].markAsDirty();
        }
      }
    }
  }
}
