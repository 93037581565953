<div class="card">
  <h5 class="card-header">Comments</h5>
  <div class="card-body p-0">
    <div class="table-responsive" style="max-height: 500px;">
      <table class="table">
        <thead class="text-muted">
          <tr>
            <th class="table-header fw-bold">Name</th>
            <th class="table-header fw-bold">Service</th>
            <th class="table-header fw-bold">User Type</th>
            <th class="table-header fw-bold">Date Generated</th>
            <th class="table-header fw-bold">Action</th>
          </tr>
        </thead>
        <tbody class="card-text">
          <tr *ngFor="let list of comment_list; let i = index">
            <th scope="row">{{ list.data.user.name ? list.data.user.name : "N/A"}}</th>
            <td>{{ list.data.type ? list.data.type : "N/A" }}</td>
            <td>{{ list.data.comments.user ? list.data.comments.user : "N/A" }}</td>
            <td>{{ list.data.created_at }}</td>
            <td>
              <button type="button" class="btn btn-info" (click)="sampleModal.show(); openModal(list)" >View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Modal -->
  <div style="overflow-y: auto" data-keyboard="false" data-backdrop="static" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review Comment</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide(); closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <dl class="row">
            <dt class="col-sm-3">Name</dt>
            <dd class="col-sm-9">{{ user.name ? user.name : 'N/A'}}</dd>

            <dt class="col-sm-3 text-truncate">Type</dt>
            <dd class="col-sm-9">{{ type ? type : 'N/A'}}</dd>
          
            <dt class="col-sm-3">URL</dt>
            <dd class="col-sm-9">{{ url ? url : 'N/A'}}</dd>
          
            <dt class="col-sm-3 text-truncate">Comment</dt>
            <dd class="col-sm-9">{{ comment ? comment : 'N/A'}}</dd>
          
            <dt class="col-sm-3 text-truncate">Files</dt>
            <dd class="col-sm-9">
              <div *ngIf="fetching" class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="row col-12" *ngIf="!fetching && files.length > 0">
                <div class="col-2 m-2" *ngFor="let files of files; let i = index">
                  <a [href]="files.url" class="" target="_blank">
                    <i class="fa fa-file" style="font-size: 50px;" aria-hidden="true"></i>
                    <p class="text-wrap">{{ files.name }}</p>
                    <!-- <img [src]="files.url | async" /> -->
                  </a>
                </div>
              </div>
              <div *ngIf="files.length === 0 && !fetching">N/A</div>
            </dd>
            <dt class="col-sm-3 text-truncate">Reply</dt>
            <dd class="col-sm-9">{{ reply ? reply : 'N/A'}}</dd>
          </dl>
          <div>
            <form [formGroup]="form" autocomplete="off">
              <label clas for="reply"><strong><small>Response</small></strong> </label>
              <textarea class="form-control" id="reply" formControlName="reply" placeholder="..." rows="3"></textarea>
            </form>
          </div>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="sampleModal.hide(); closeModal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="submit(id);  sampleModal.hide()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>




