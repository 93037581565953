import { Component, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ReferenceService } from 'src/app/service/reference.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmResetPasswordComponent } from 'src/app/modal-manager/confirm-reset-password/confirm-reset-password.component';
import { ReuseLoginComponent } from 'src/app/modal-manager/reuse-login/reuse-login.component';
import { matchingGroupedPassword, matchingPassword, needsStrongerPassword, phoneNumberValidator } from 'src/app/validators';
import { formatISO, parseISO } from 'date-fns';
import { FileUploadService } from 'src/app/file-upload.service';
import { HttpEventType } from '@angular/common/http';
import { AnyMxRecord } from 'dns';

@Component({
  selector: 'app-artemis-registration',
  templateUrl: './artemis-registration.component.html',
  styleUrls: ['./artemis-registration.component.scss']
})
export class ArtemisRegistrationComponent implements OnInit {
  loading: boolean = false;
  public show_terms_and_conditions: boolean = true;
  public show_data_privacy: boolean = false;
  public showFormErrors: boolean = false;
  modalRef?: MDBModalRef;
  
  @Output() close_reg = new EventEmitter();

  public show_first_registration: boolean = false;
  public show_second_registration: boolean = false;
  public show_personal_registration: boolean = false;
  public show_company_registration: boolean = false;
  public show_government_registration: boolean = false;
  public show_final_registration: boolean = false;
  public show_recaptcha_confirmation: boolean = false;
  public show_government_id_input: boolean = false;
  public show_agency_id_input: boolean = false;
  public show_authorization_letter_input: boolean = false;
  public show_back_button: boolean = false;
  public show_next_button: boolean = true;
  public show_submit_button: boolean = false;
  public show_final_send_button: boolean = false;

  public firstTextFieldTitle: any = 'Last Name';
  public secondTextFieldTitle: any = 'First Name';
  public thirdTextFieldTitle: any = 'Area of Residence';
  public firstDropdownTitle: any = 'Government Issued ID';
  public typeOfAccountToggle: any = 'Personal';
  public buttonName: any = 'Next';
  uploading: boolean = true;
  needsRecaptcha: boolean = true;
  recaptchaV2SiteKey: string = environment.recaptchaSiteKeys.v2;
  private singleExecutionSubscription: Subscription | undefined;

  // upload files
  gov_id_path: any;
  company_id_path: any;
  auth_letter_path: any;

  gov_uploadProgress!: Observable<number | undefined>;
  com_uploadProgress!: Observable<number | undefined>;
  auth_uploadProgress!: Observable<number | undefined>;

  government_id: string = "";
  company_id: string = "";
  authorization_letter: string = "";
  expiresOnDateISO: string = "";

  display_government_id: string = "";
  display_company_id: string = "";
  display_authorization_letter: string = "";

  public GovernmentIssuedIDType = Array<string>();
  public TypeOfAccount = Array<string>();
  /**
   * Firestore data
   */
  public terms_and_conditions_JSON: any;
  public privacy_policy_JSON: any;
  public gov_agency: any = [];
  public mother_agency: any =[];
  public sectors: any = [];

  checkPersonalRegistrationForm = {
    last_name:[
      {type:'required',message:'Last name is required'}
    ],
    first_name:[
      {type:'required',message:'First name is required'}
    ],
    sex:[
      {type:'required',message:'Sex is required'}
    ],
    area_of_residence:[
      {type:'required',message:'Area of residence is required'}
    ],
    idtype:[
      {type:'required',message:'ID type is required'}
    ],
    idnumber:[
      {type:'required',message:'ID number is required'}
    ],
  }

  checkRegistrationForm = {
    email:[
      {type:'email',message:'Invalid email address'},
      {type:'required',message:'Email address is required'},
    ],
    passwordGroup:[
      {type:'matchingPassword',message:'Passwords mismatch'},
    ],
    password:[
      {type:'required',message:'Passwords is required'},
      {type:'pattern',message:'Password is not valid'},
    ],
    passwordStrength:[
      {type:'needsStrongerPassword',message:'Password too weak'},
    ],
    confirmpassword:[
      {type:'matchingPassword',message:'Passwords mismatch'},
      {type:'required',message:'Confirm password is required'}
    ],
    mobile_number:[
      {type:'required',message:'Mobile Number is required'},
      {type:'invalidPhoneNumber',message:'Invalid Mobile Number'},
    ],
    account_type:[
      {type:'required',message:'Type of Account is required'},
    ],
  }

  cehckCompanyRegistrationForm = {
    name_of_organization:[
      {type:'required',message:'Organization name is required'}
    ],
    organization_address:[
      {type:'required',message:'Organization address is required'}
    ],
    official_designation:[
      {type:'required',message:'Official designation is required'}
    ],
    sector:[
      {type:'required',message:'Sector is required'}
    ],
  }

  checkGovernmentRegistrationForm = {
    agency:[
      {type:'required',message:'Mother Agency is required'}
    ],
    subagency:[
      {type:'required',message:'Specific Agency is required'}
    ],
    official_designation:[
      {type:'required',message:'Official designation is required'}
    ],
  }

  checkFinalRegistrationForm = {
    government_id:[
      {type:'required',message:'Government ID is required'}
    ],
    company_id:[
      {type:'required',message:'Company ID is required'}
    ],
    authorization_letter:[
      {type:'required',message:'Authorization letter is required'}
    ],
  }

  checkUploadsForm = {
    gov_id:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    company_id:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    auth_letter:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
  }

  /**
   * Forms
   */
  personalregistrationform = new UntypedFormGroup({
    last_name: new UntypedFormControl('', Validators.required),
    first_name: new UntypedFormControl('', Validators.required),
    sex: new UntypedFormControl('', Validators.required),
    area_of_residence: new UntypedFormControl('', Validators.required),
    idtype: new UntypedFormControl('', Validators.required),
    idnumber: new UntypedFormControl('', Validators.required)
  });
  registrationform = new UntypedFormGroup({
    email: new UntypedFormControl('',
      {
        validators: [Validators.required, Validators.email],
        updateOn: "blur"
      }
    ),
    passwordGroup: new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      passwordStrength: new UntypedFormControl(0, [needsStrongerPassword()]),
      confirmpassword: new UntypedFormControl('', [Validators.required]),
    },[matchingGroupedPassword()]),
    // password: new FormControl('',
    //   {
    //     validators: [Validators.required],
    //     // updateOn: "blur"
    //   }
    // ),
    // passwordStrength: new FormControl(0,
    //   {
    //     validators: [needsStrongerPassword()],
    //   }
    // ),
    // confirmpassword: new FormControl('',
    //   {
    //     validators: [Validators.required, matchingPassword()],
    //     // updateOn: "blur"
    //   }
    // ),
    mobile_number: new UntypedFormControl('',
      {
        validators: [Validators.required, phoneNumberValidator()],
      }
    ),
    account_type: new UntypedFormControl('',
      {
        validators: [Validators.required],
        updateOn: "blur"
      }
    )
  });

  companyregistrationform = new UntypedFormGroup({
    name_of_organization: new UntypedFormControl('', Validators.required),
    organization_address: new UntypedFormControl('', Validators.required),
    official_designation: new UntypedFormControl('', Validators.required),
    sector: new UntypedFormControl('', Validators.required)
  });
  governmentregistrationform = new UntypedFormGroup({
    agency: new UntypedFormControl('', Validators.required),
    subagency: new UntypedFormControl('', Validators.required),
    official_designation: new UntypedFormControl('', Validators.required)
  });
  finalregistrationform = new UntypedFormGroup({
    government_id: new UntypedFormControl('', Validators.required),
    company_id: new UntypedFormControl('', Validators.required),
    authorization_letter: new UntypedFormControl('', Validators.required)
  });

  oneMBinByte = 1000000
  uploadsForm = new UntypedFormGroup({
    // government: new FormGroup({
    //   count: new FormControl(0)
    // })
  });
  
  acceptedFileType:any=[
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
  ]

  constructor(private myElement: ElementRef, private router: Router,
    private afs: AngularFirestore, public auth: AngularFireAuth,
    private recaptchaV3Service: ReCaptchaV3Service,
    private referenceService: ReferenceService, public modalService: MDBModalService,
    public fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.getRegistrationContracts();
    //this.getAgencies();
    this.getMotherAgencies();
    this.getSectors();
    this.getGovernmentIssuedIdTypes();
    this.show_back_button=true;
    this.show_next_button = false;

    this.TypeOfAccount = Array<string>();
    this.TypeOfAccount.push('Personal');
    // Return Company user registration in Phase 2
    // this.TypeOfAccount.push('Company');
    //this.TypeOfAccount.push('Government');
  }

  public ngOnDestroy(): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  numberInputOnly(event:any){
    let charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  /**
   * Firestore Data Retrieval Functions
   */
  getRegistrationContracts() {
    this.getTermsConditions();
    this.getPrivacyPolicy();
  }

  getTermsConditions() {
    this.terms_and_conditions_JSON = [];
    this.afs.collection("registration contracts").doc("ARTEMIS").collection("terms_and_conditions").snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.terms_and_conditions_JSON.includes(item) == false) {
            this.terms_and_conditions_JSON.push(item);
          }
        });
      });
  }

  getPrivacyPolicy() {
    this.privacy_policy_JSON = [];
    this.afs.collection("registration contracts").doc("ARTEMIS").collection("privacy_policy").snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.privacy_policy_JSON.includes(item) == false) {
            this.privacy_policy_JSON.push(item);
          }
        });
      });
  }

  getMotherAgencies(){
    this.afs.collection("Mother Agency",filter=> filter.orderBy('motheragency','asc')).snapshotChanges().subscribe((data)=>{
      data.forEach((info: any)=>{
         let item: any = info.payload.doc.data();
         item.id = info.payload.doc.id;
         if(this.mother_agency.includes(item) === false){
          this.mother_agency.push(item)
         }
      })
    })
  }

  getAgencies(id: any) {
    //let JSONholder: any = [];
    this.governmentregistrationform.get("subagency")?.reset(); 
    this.gov_agency =[];
    let agencyFilter: QueryFn ;

    agencyFilter = (filter) =>
        filter
          .where('agencyDetails.motherAgency.id', '==', id.target.value)
          
    this.afs.collection("Agency",agencyFilter).snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.gov_agency.includes(item) === false) {
            this.gov_agency.push(item);
          }
        });
      });
    //this.terms_and_conditions = JSONholder[0]["full text"];
  }
  
  getSectors() {
    let JSONholder: any = [];
    this.afs.collection("Sector").snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.sectors.includes(item) === false) {
            this.sectors.push(item);
          }
        });
      });
    //this.terms_and_conditions = JSONholder[0]["full text"];
  }

  getGovernmentIssuedIdTypes() {
    this.afs.collection("References")
      .doc("common").collection("government_issued_id_types", filter => filter.orderBy("order"))
      .snapshotChanges().subscribe(
        (data: any) => {
          data.forEach((info: any) => {

            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (this.GovernmentIssuedIDType.includes(item.value) === false) {
              this.GovernmentIssuedIDType.push(item.value);
            }
          });
        });
  }
  /**
   * END: Firestore Data Retrieval Functions
   */

  loginAsGuest() {
    this.router.navigate(['/artemis']);
  }

  checkbox(event: any) {
    if (this.show_terms_and_conditions) {
      this.show_next_button = false;
      if (event.target.checked) {
        this.show_next_button = true;
      }
      else {
        this.show_next_button = false;
      }
    }

    else if (this.show_data_privacy) {
      this.show_next_button = false;
      if (event.target.checked) {
        this.show_next_button = true;
      }
      else {
        this.show_next_button = false;
      }
    }
  }

  toggleNext() {
    // GO TO NEXT PAGE.

    // terms & conditions to first registration
    if (this.show_terms_and_conditions) {
      this.show_terms_and_conditions = false;
      this.show_data_privacy = true;

      this.show_back_button = true;
      this.show_next_button = false;
    }

    else if (this.show_data_privacy) {
      this.show_data_privacy = false;
      this.show_first_registration = true;

      this.show_back_button = true;
    }

    // first registration to personal/company/government registration
    else if (this.show_first_registration) {
      this.validateFields('registrationform')
      if (!this.registrationform.valid) {
        this.showFormErrors = true;
      }
      else {
        this.showFormErrors = false;
        this.afs.collection(`Users`, filter => filter.where('credentials.email', '==', this.registrationform.value.email).limit(1))
          .snapshotChanges()
          .pipe(
            tap((data) => {
              var emailExists = false;
              var accessToArtemis = false;
              var accessToPbris = false;

              if (data.length) {
                emailExists = true;
                data.forEach((entry: any) => {
                  var item = entry.payload.doc.data();
                  var userId = entry.payload.doc.id;

                  accessToArtemis = item.credentials.access_artemis;
                  accessToPbris = item.credentials.access_pbris;
                  var retrievedUserAccountType = item.credentials.account_type

                  if (accessToArtemis) {
                    this.modalRef = this.modalService.show(ConfirmResetPasswordComponent,
                      {
                        backdrop: 'static',
                        data: {
                          header_content: {
                            logo: "/assets/arta-logo.png"
                          },
                          body_content: {
                            message: "You have already registered for this account. Would you like to reset this account's password?"
                          },
                          buttons: {
                            confirm: "Yes, please reset my password.",
                            cancel: "No thanks."
                          },
                          email: this.registrationform.value.email,
                          login_route: "/artemis",
                          notif_message_code: "user_registration_reset_password_artemis",
                          redirect_url: environment.verificationRedirectUrls.artemis
                        }
                      });
                  }
                  else if (accessToPbris) {
                    // If user has no access to ARTEMIS but has access to the other system
                    switch (retrievedUserAccountType) {
                      case "Personal":
                        this.modalRef = this.modalService.show(ReuseLoginComponent,
                          {
                            backdrop: 'static',
                            data: {
                              header_content: {
                                logo: "/assets/arta-logo.png"
                              },
                              body_content: {
                                message: "You already have an existing login for PBRIS. Would you like to use the same login for ARTEMIS?"
                              },
                              buttons: {
                                confirm: "Yes, I will use the same login.",
                                cancel: "No, I would use a new login."
                              },
                              notif_message_code: "user_registration_auto_approve_artemis",
                              userId: userId,
                              login_route: "/artemis",
                              updateAccessObj: {
                                "credentials.access_artemis": true
                              }
                            }
                          });
                        break;
                      case "Company":
                      case "Government":
                        this.referenceService.getNotification("user_registration_notify_admin").then((data) => {
                          data.subscribe((entry) => {
                            alert(entry);
                            this.router.navigate(['/artemis']);
                          })
                        });
                        break;
                      default:
                        alert("should not happen!")
                    }
                  }
                  else {
                    switch (retrievedUserAccountType) {
                      case "Company":
                      case "Government":
                        this.referenceService.getNotification("user_registration_disallow").then((data) => {
                          data.subscribe((entry) => {
                            alert(entry);
                          })
                        });
                        break;
                      default:
                        alert("should not happen! individual users must have access to at least one site!")
                    }
                  }
                });
              }
              else {
                //If no email, move on to next page
                switch (this.registrationform.value.account_type) {
                  case "Personal":
                    this.show_first_registration = false;
                    this.show_personal_registration = true;
                    this.show_back_button = true;
                    break;
                  case "Company":
                    this.show_first_registration = false;
                    this.show_company_registration = true;
                    this.show_back_button = true;
                    break;
                  case "Government":
                    this.show_first_registration = false;
                    this.show_government_registration = true;
                    this.show_back_button = true;
                    break;
                  default:
                  //this.show_final_registration = true;
                }
              }
            }
            ),
            take(1)
          )
          .subscribe();
      }
    }

    // personal/company/government registration to final registration
    else if (this.show_personal_registration || this.show_company_registration || this.show_government_registration) {
      if(this.show_personal_registration) this.validateFields('personalregistrationform')
      else if(this.show_company_registration) this.validateFields('companyregistrationform')
      else if(this.show_government_registration) this.validateFields('governmentregistrationform')
      this.validateFields('uploadsForm')
      if (
        ((this.show_personal_registration && !this.personalregistrationform.valid) ||
        (this.show_company_registration && !this.companyregistrationform.valid) ||
        (this.show_government_registration && !this.governmentregistrationform.valid))
        && this.uploadsForm.valid
      ) {
        this.showFormErrors = true;
      }
      else {
        this.showFormErrors = false;

        this.show_personal_registration = false;
        this.show_company_registration = false;
        this.show_government_registration = false;
        this.removeAllControlUploadForm()
        switch (this.registrationform.value.account_type) {
          case "Personal":
            this.show_government_id_input = true;
            this.addFormGroupUploadsForm('gov_id')
            //this.show_agency_id_input = true;
            //this.show_authorization_letter_input = true;
            break;
          case "Company":
            //this.show_government_id_input = true;
            this.show_agency_id_input = true;
            this.show_authorization_letter_input = true;
            this.addFormGroupUploadsForm('company_id')
            this.addFormGroupUploadsForm('auth_letter')
            break;
          case "Government":
            this.show_government_id_input = true;
            //this.show_agency_id_input = true;
            this.show_authorization_letter_input = true;
            this.addFormGroupUploadsForm('gov_id')
            this.addFormGroupUploadsForm('auth_letter')
            break;
          default:
          //this.show_final_registration = true;
        }

        this.show_final_registration = true;
        this.show_back_button = true;
        this.show_next_button = false;
        this.show_submit_button = true;
      }
    }

    // after final registration is accomplished, do one more final recaptcha validation before user submission
    else if (this.show_final_registration) {
      this.show_final_registration = false;
      this.show_recaptcha_confirmation = true;
      this.show_next_button = false;
      this.show_submit_button = false;
      this.show_final_send_button = true;
    }
  }

  addFormGroupUploadsForm(formGroup:string){
    this.uploadsForm.addControl(formGroup,
      new UntypedFormGroup({
        count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
        type: new UntypedFormControl('',this.checkUploadType()),
        size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
      })
    )
  }

  removeAllControlUploadForm(){
    Object.keys(this.uploadsForm).map((controlName)=>{
      this.uploadsForm.removeControl(controlName)
    })
  }

  toggleBack(test?:any) {
    this.show_submit_button = false;
    this.show_next_button = true;
    // GO BACK A PAGE.
    //|| this.show_company_registration || this.show_government_registration
    if (this.show_terms_and_conditions) {
      this.show_terms_and_conditions = true;
      this.show_back_button = true;
      this.show_next_button = false;
      
      this.close_reg.emit(test)
    }

    else if (this.show_data_privacy || this.show_first_registration) {
      this.show_terms_and_conditions = true;
      this.show_data_privacy = false;
      this.show_first_registration = false;

      this.show_back_button = true;
      this.show_next_button = false;
    }

    // personal/company/government registration to first registration
    else if (this.show_personal_registration || this.show_company_registration || this.show_government_registration) {
      this.show_personal_registration = false;
      this.show_company_registration = false;
      this.show_government_registration = false;

      this.show_first_registration = true;
      this.show_back_button = true;
    }

    // final registration to personal/company/government registration
    else if (this.show_final_registration) {
      this.show_final_registration = false;
      this.show_government_id_input = false;
      this.show_agency_id_input = false;
      this.show_authorization_letter_input = false;

      switch (this.registrationform.value.account_type) {
        case "Personal":
          this.show_personal_registration = true;
          break;
        case "Company":
          this.show_company_registration = true;
          break;
        case "Government":
          this.show_government_registration = true;
          break;
        default:
          this.show_first_registration = true;
      }

      this.show_back_button = true;
    }

    else if (this.show_recaptcha_confirmation) {
      this.show_final_registration = true;
      this.show_next_button = false;
      this.show_submit_button = true;
      this.show_final_send_button = false;
      this.show_recaptcha_confirmation = false;
      this.needsRecaptcha = true;
    }
  }

  // upload file handlers
  buttonUpload(event: any, type: any){
    this.upload(event.target.files,type)
  }

  dropzone(files: any, type: any){
    this.upload(files,type)
  }

  upload(files: any, type: any) {
    if(type == 'gov_id') this.gov_uploadProgress = of(undefined);
    if(type == 'company_id') this.com_uploadProgress = of(undefined);
    if(type == 'auth_letter') this.auth_uploadProgress = of(undefined);
    //console.log('files: ',files);
    (this.uploadsForm.controls[type] as UntypedFormGroup).markAllAsTouched();
    (this.uploadsForm.controls[type] as UntypedFormGroup).markAsDirty();
    var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;
    if(files.length > 0){
      if(files.length > 1){
        if(type == 'gov_id') this.display_government_id = files[0].name+', ...';
        if(type == 'company_id') this.display_company_id = files[0].name+', ...';
        if(type == 'auth_letter') this.display_authorization_letter = files[0].name+', ...';
        (this.uploadsForm.controls[type] as UntypedFormGroup).patchValue({
          count:files.length,
          type:'',
          size:0
        })
      }
      else{
        const file:File = files[0];

        (this.uploadsForm.controls[type] as UntypedFormGroup).patchValue({
          count:1,
          type:file.type,
          size:file.size
        });
      
        let collectionName: any;
        // let id_path: any;
        // id_path = file;
    
        switch (type) {
          case 'gov_id':
            collectionName = "government-id";
            this.display_government_id = file.name;
            break;
          case 'company_id':
            collectionName = "company-id";
            this.display_company_id = file.name;
            break;
          case 'auth_letter':
            collectionName = "auth-letter";
            this.display_authorization_letter = file.name;
            break;
          default:
            return;
        }
        
        if(this.uploadsForm.controls[type].valid){
          //console.log('Uploading')
          if(type == 'gov_id') this.gov_uploadProgress = of(0);
          else if(type == 'company_id') this.com_uploadProgress = of(0);
          else if(type == 'auth_letter') this.auth_uploadProgress = of(0);

          this.showFormErrors = false;

          if(file){
            this.uploading = true
            const filePath = `${collectionName}/artemis/${formatISO(Date.now())}`;
            this.fileUploadService.uploadFile(file, filePath)
            .subscribe({
              next:(resp)=>{
                if(resp.type === HttpEventType.Response){
                  this.expiresOnDateISO = resp.body.expiresOn;
                  //console.log('Upload complete');
                  switch (type) {
                    case 'gov_id': this.government_id = resp.body.downloadUrl;
                      break;
                    case 'company_id': this.company_id = resp.body.downloadUrl;
                      break;
                    case 'auth_letter': this.authorization_letter = resp.body.downloadUrl;
                      break;
                  }
                  this.uploading = false;
                }
                if (resp.type === HttpEventType.UploadProgress) {
                  const total = resp.total || 1
                  const percentDone = Math.round(100 * resp.loaded / total);
                  //console.log('percentDone: ', percentDone)
                  switch (type) {
                    case 'gov_id': this.gov_uploadProgress = of(percentDone);
                      break;
                    case 'company_id': this.com_uploadProgress = of(percentDone);
                      break;
                    case 'auth_letter': this.auth_uploadProgress = of(percentDone);
                      break;
                  }
                }
              },
              error:(err)=>{
                console.error('Error: ',err)
              }
            })
          }
        }
        else{
          console.warn('Cant upload')
        }
      }
    }else{
      if(type == 'gov_id') this.display_government_id = '';
      if(type == 'company_id') this.display_company_id = '';
      if(type == 'auth_letter') this.display_authorization_letter = '';
      (document.getElementById(type) as HTMLInputElement).value = "";
      (this.uploadsForm.controls[type] as UntypedFormGroup).patchValue({
        count:0,
        type:'',
        size:0
      })
    } 
  }
  // upload file handlers

  // upload(event: any, type: any) {
  //   var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;
  //   if(!allowedExtensions.exec(event.target.files[0].name)) {
  //     this.finalregistrationform.reset();
  //     this.government_id = "";
  //     this.company_id = "";
  //     this.authorization_letter = "";
  //     this.display_government_id = "";
  //     this.display_company_id = "";
  //     this.display_authorization_letter = "";
  //     this.uploading = true;
  //     this.showFormErrors = true;
  //     return;
  //   }
  //   this.showFormErrors = false;

  //   let collectionName: any;
  //   let id_path: any;
  //   id_path = event.target.files[0];

  //   switch (type) {
  //     case 'gov_id':
  //       collectionName = "government-id";
  //       this.display_government_id = id_path.name;
  //       break;
  //     case 'company_id':
  //       collectionName = "company-id";
  //       this.display_company_id = id_path.name;
  //       break;
  //     case 'auth_letter':
  //       collectionName = "auth-letter";
  //       this.display_authorization_letter = id_path.name;
  //       break;
  //     default:
  //       return;
  //   }

  //   if (id_path) {
  //     this.uploading = true
  //     // new path to make it somewhat less difficult to navigate
  //     const filePath = `${collectionName}/artemis/${formatISO(Date.now())}`;

  //     //new method that uses an API endpoint to access file storage through google cloud
  //     //old method was due to firebase DNS issues
  //     this.fileUploadService.uploadFile(id_path, filePath)
  //       .subscribe((resp) => {
  //         if (resp.type === HttpEventType.Response) {
  //           console.log('Upload complete');
  //           this.expiresOnDateISO = resp.body.expiresOn;
  //           switch (type) {
  //             case 'gov_id':
  //               this.government_id = resp.body.downloadUrl;
  //               break;
  //             case 'company_id':
  //               this.company_id = resp.body.downloadUrl;
  //               break;
  //             case 'auth_letter':
  //               this.authorization_letter = resp.body.downloadUrl;
  //               break;
  //           }
  //           this.uploading = false;
  //         }
  //         if (resp.type === HttpEventType.UploadProgress) {
  //           const total = resp.total || 1
  //           const percentDone = Math.round(100 * resp.loaded / total);
  //           switch (type) {
  //             case 'gov_id':
  //               this.gov_uploadProgress = of(percentDone);
  //               break;
  //             case 'company_id':
  //               this.com_uploadProgress = of(percentDone);
  //               break;
  //             case 'auth_letter':
  //               this.auth_uploadProgress = of(percentDone);
  //               break;
  //           }
  //         }
  //       });
  //   }
  // }

  submitForm() {
    this.singleExecutionSubscription = this.recaptchaV3Service.execute('registration')
      .subscribe((token) => {
        this.secureCreateUser(token);
      },
        (error) => {
          alert(error);
        });
  }

  secureCreateUser(token: string) {
    this.validateFields('all')
    this.loading = true;
    // Hide recaptcha input when user creation has been submitted
    this.show_recaptcha_confirmation = false;

    // this.auth.createUserWithEmailAndPassword(this.registrationform.value.email,
    //   this.registrationform.value.password)
    if(this.registrationform.valid){
      //console.log('email: ',this.registrationform.value.email)
      //console.log('pasword: ',this.registrationform.value.passwordGroup.password)
      this.auth.createUserWithEmailAndPassword(this.registrationform.value.email,
        this.registrationform.value.passwordGroup.password).then(cred => {
          let id = cred.user?.uid
  
          switch (this.registrationform.value.account_type) {
            case "Personal":
              cred.user?.updateProfile({
                displayName: this.personalregistrationform.value.first_name +
                  " " + this.personalregistrationform.value.last_name
              })
              cred.user?.sendEmailVerification({
                url: environment.verificationRedirectUrls.artemis
              });
              break;
  
            case "Company":
              cred.user?.updateProfile({
                displayName: this.companyregistrationform.value.name_of_organization
              })
              break;
  
            case "Government":
              this.afs.doc(`Agency/${this.governmentregistrationform.value.agency}`).get().subscribe(
                agency => {
                  cred.user?.updateProfile({

                    displayName: this.personalregistrationform.value.first_name +
                    " " + this.personalregistrationform.value.last_name
                    //** displayName: agency.get("name") + " (" + this.governmentregistrationform.value.subagency + ")"// */
                  })
                }
              )
  
              break;
  
            default:
          }
  
          this.afs.doc(`Users/${id}`).set({
            government: this.governmentregistrationform.value,
            personal: this.personalregistrationform.value,
            company: this.companyregistrationform.value,
            file: {
              government_id: this.government_id,
              company_id: this.company_id,
              authorization_letter: this.authorization_letter,
              downloadLinkExpiresOn: parseISO(this.expiresOnDateISO) ? parseISO(this.expiresOnDateISO) : undefined
            },
  
            credentials: {
              uid: id,
              admin: false,
              access_artemis: true,
              access_pbris: false,
              loggedIn: new Date(),
              sinceMember: cred.user?.metadata.creationTime,
              email: this.registrationform.value.email,
              mobile_number: this.registrationform.value.mobile_number,
              account_type: this.registrationform.value.account_type
            },
  
            registrationRecaptchaV3Token: token
          }).then((data: any) => {
            this.toNotifications( this.governmentregistrationform.value.agency, id )   
            // Force sign out because approver access is required
            this.auth.signOut();
  
            this.loading = false;
  
            switch (this.registrationform.value.account_type) {
              case "Personal":
                this.referenceService.getNotification("user_registration_success_email_verification").then((data) => {
                  data.pipe(
                    tap((entry) => {
                      alert(entry);
                      this.router.navigate(['/artemis']);
                    }),
                    take(1)
                  ).subscribe()
                });
                break;
              case "Company":
              case "Government":
                this.referenceService.getNotification("user_registration_success_admin").then((data) => {
                  data.pipe(
                    tap((entry) => {
                      alert(entry);
                      this.router.navigate(['/artemis']);
                    }),
                    take(1)
                  ).subscribe()
                });
                break;
            }
           
          })
          .catch((err)=>{
            console.error('2 Error: ',err)
          });
  
  
        }).catch((message: any) => {
          console.error('1 Error: ',message)
          alert(message)
          this.loading = false
        })
    } else console.warn('registration invalid')
  }

  onStrengthChanged(e: number) {
    // this.registrationform.patchValue({
    //   passwordStrength: e
    // });
    (this.registrationform.controls['passwordGroup'] as UntypedFormGroup).patchValue({
      passwordStrength: e
    });
  }

  resolved(e: any) {
    this.needsRecaptcha = false;
  }

  toNotifications(agency:any, uid:any){
    let toNotifCollectionJSON = {
      uid:uid,
      agency_id:agency,
      event: 'New User',
      message: 'This is a notification Message',
      level: 'Level test',
      notif_type: 'Account-Creation',
      notif_in:'pbris'
    }
    this.afs.collection('Notifications').add(toNotifCollectionJSON)
  }

  // validations
  hasError(formGroupName:string, controlName:string, errorType:string, forSize?:boolean){
    switch(formGroupName){
      case 'registrationform': return this.registrationform.controls[controlName].hasError(errorType) && (this.registrationform.controls[controlName].dirty || this.registrationform.controls[controlName].touched)
      case 'passwordGroup':{
        const formGroup = (this.registrationform.controls[formGroupName] as UntypedFormGroup)
        return formGroup.controls[controlName].hasError(errorType) && (formGroup.controls[controlName].dirty || formGroup.controls[controlName].touched)
      }
      case 'personalregistrationform': return this.personalregistrationform.controls[controlName].hasError(errorType) && (this.personalregistrationform.controls[controlName].dirty || this.personalregistrationform.controls[controlName].touched)
      case 'uploadsForm':{
        const formGroup = (this.uploadsForm.controls[controlName] as UntypedFormGroup)
        if(forSize)
          return (formGroup.get('size')?.hasError(errorType) && (formGroup.get('size')?.dirty || formGroup.get('size')?.touched))
        else
          return (formGroup.get('type')?.hasError(errorType) && (formGroup.get('type')?.dirty || formGroup.get('type')?.touched)) 
              || (formGroup.get('count')?.hasError(errorType) && (formGroup.get('count')?.dirty || formGroup.get('count')?.touched));
      }
      case 'governmentregistrationform': return this.governmentregistrationform.controls[controlName].hasError(errorType) && (this.governmentregistrationform.controls[controlName].dirty || this.governmentregistrationform.controls[controlName].touched)
      case 'companyregistrationform': return this.companyregistrationform.controls[controlName].hasError(errorType) && (this.companyregistrationform.controls[controlName].dirty || this.companyregistrationform.controls[controlName].touched)
      default: return true
    }
  }
  
  isInvalid(formGroup:string, controlName:string){
    switch(formGroup){
      case 'registrationform': return this.registrationform.controls[controlName].invalid && (this.registrationform.controls[controlName].dirty || this.registrationform.controls[controlName].touched)
      case 'passwordGroup':  return (this.registrationform.controls['passwordGroup'] as UntypedFormGroup).controls[controlName].invalid && ((this.registrationform.controls['passwordGroup'] as UntypedFormGroup).controls[controlName].dirty || (this.registrationform.controls['passwordGroup'] as UntypedFormGroup).controls[controlName].touched)
      case 'personalregistrationform': return this.personalregistrationform.controls[controlName].invalid && (this.personalregistrationform.controls[controlName].dirty || this.personalregistrationform.controls[controlName].touched)
      case 'governmentregistrationform': return this.governmentregistrationform.controls[controlName].invalid && (this.governmentregistrationform.controls[controlName].dirty || this.governmentregistrationform.controls[controlName].touched)
      case 'companyregistrationform': return this.companyregistrationform.controls[controlName].invalid && (this.companyregistrationform.controls[controlName].dirty || this.companyregistrationform.controls[controlName].touched)
      default: return true
    }
  }

  validateFields(formGroup:string){
    switch(formGroup){
      case 'personalregistrationform':{
        if(!this.personalregistrationform.valid){
          this.personalregistrationform.markAllAsTouched()
          this.personalregistrationform.markAsDirty()
          for (const control in this.personalregistrationform.controls) {
            if (this.personalregistrationform.controls.hasOwnProperty(control)) {
              this.personalregistrationform.controls[control].markAllAsTouched();
              this.personalregistrationform.controls[control].markAsDirty();
            }
          }
        }
      }
      break;
      case 'registrationform':{
        if(!this.registrationform.valid){
          this.registrationform.markAllAsTouched()
          this.registrationform.markAsDirty()
          for (const control in this.registrationform.controls) {
            if (this.registrationform.controls.hasOwnProperty(control)) {
              this.registrationform.controls[control].markAllAsTouched();
              this.registrationform.controls[control].markAsDirty();
            }
          }
        }
      }
      break;
      case 'companyregistrationform':{
        if(!this.companyregistrationform.valid){
          this.companyregistrationform.markAllAsTouched()
          this.companyregistrationform.markAsDirty()
          for (const control in this.companyregistrationform.controls) {
            if (this.companyregistrationform.controls.hasOwnProperty(control)) {
              this.companyregistrationform.controls[control].markAllAsTouched();
              this.companyregistrationform.controls[control].markAsDirty();
            }
          }
        }
      }
      break;
      case 'governmentregistrationform':{
        if(!this.governmentregistrationform.valid){
          this.governmentregistrationform.markAllAsTouched()
          this.governmentregistrationform.markAsDirty()
          for (const control in this.governmentregistrationform.controls) {
            if (this.governmentregistrationform.controls.hasOwnProperty(control)) {
              this.governmentregistrationform.controls[control].markAllAsTouched();
              this.governmentregistrationform.controls[control].markAsDirty();
            }
          }
        }
      }
      break;
      case 'finalregistrationform':{
        if(!this.finalregistrationform.valid){
          this.finalregistrationform.markAllAsTouched()
          this.finalregistrationform.markAsDirty()
          for (const control in this.finalregistrationform.controls) {
            if (this.finalregistrationform.controls.hasOwnProperty(control)) {
              this.finalregistrationform.controls[control].markAllAsTouched();
              this.finalregistrationform.controls[control].markAsDirty();
            }
          }
        }
      }
      break;
      case 'uploadsForm':{
        if(!this.uploadsForm.valid){
          this.uploadsForm.markAllAsTouched()
          this.uploadsForm.markAsDirty()
          for (const control in this.uploadsForm.controls) {
            if (this.uploadsForm.controls.hasOwnProperty(control)) {
              this.uploadsForm.controls[control].markAllAsTouched();
              this.uploadsForm.controls[control].markAsDirty();
            }
          }
        }
      }
      break;
      case 'all':{ 
        this.validateFields('personalregistrationform')
        this.validateFields('registrationform')
        this.validateFields('companyregistrationform')
        this.validateFields('governmentregistrationform')
        this.validateFields('finalregistrationform')
      }
      break;
    }
  }
  // !validations

  // validators
  checkUploadType():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.uploadsForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          let errors = {
            type: false,
          }
          if(!this.acceptedFileType.includes(val)){
            tempbool = true
            errors.type = true
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  // !validators

  //onagency value changed
  onValueChange(value:string){
    //let value: any = this.gov_agency.filter((x:any) => x.id === event.target.value)
    this.governmentregistrationform.get('subagency')?.setValue(value);
   
  }
}
