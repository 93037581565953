<ng-container *ngIf="service" >
    <div id="{{service.id}}">
      <label id="s_name"><h2><span *ngIf="counter">{{counter}}.</span> {{service.serviceDetails.service_name}}</h2></label>
    </div>
    <div>
      <p id="s_info" [innerHtml]="service.serviceDetails.service_description"></p>
    </div>
    <table class="service-details">
      <col style="width:25%"/>
      <col style="width:25%"/>
      <col style="width:16%"/>
      <col style="width:17%"/>
      <col style="width:17%"/>
      <tbody>
          <tr id="s_ood" class="header-details">
              <th>Office or Division:</th>
              <td colspan="4">
              {{service.serviceDetails.process_owner?.name ? service.serviceDetails.process_owner.name  : service.serviceDetails.process_owner }},
              <label class="my-tooltip" [matTooltip]="office?.name ? getJSONValue(office.offices) : office" (click)="requirementservice.scrollto(office?.name ? office.name : office)" *ngFor="let office of filterOfficeDivision(service.serviceDetails.process_owner,service.serviceDetails.office_division) ;let oindex = index;let c  = count">
                  {{office?.name ? office.name : office}}{{ (oindex < (c-1)) ? ' , ' : ''}}</label>
              <span *ngIf="service.serviceDetails?.inter_agency_offices">, <span *ngFor="let inter_office of service.serviceDetails.inter_agency_offices;let iindex = index; let z = count ">
                  {{inter_office}}{{ (iindex < (z-1))? ' , ' : '' }}
                  </span>
              </span>
              </td>
                
          </tr>
          <tr id="s_cat" class="header-details">
              <th>Category:</th>
              <td colspan="4">{{service.serviceDetails.service_category}}
  
                  
              
              </td>
             
          </tr>
          <tr id="s_class" class="header-details">
              <th>Classification:</th>
              <td colspan="4">
                  {{service.serviceDetails.classification}}
  
                  
              </td>
              
          </tr>
          <tr id="s_tot" class="header-details">
              <th>Type of Transaction:</th>
              <td colspan="4">{{service.serviceDetails.transactionTypeString}}
  
                
              </td>
              
          </tr>
          <tr id="s_wma" class="header-details">
              <th>Who may avail:</th>
              <td colspan="4">
                  <div [innerHtml]="service.serviceDetails.who_may_avail"></div>
  
                
              </td>
            
          </tr>
          <tr id="s_oh" class="header-details">
              <th>Operating Hours:</th>
              <td colspan="4">{{service.serviceDetails.operatingTime}}
              </td>
  
          </tr>
          <tr id="s_stt" class="header-details" *ngIf="service.serviceDetails.has_statute == true">
              <th>Statute:</th>
              <td colspan="4">
                  <div [innerHtml]="service.serviceDetails.statute"></div>
  
                 
              </td>
          </tr>
          <tr *ngIf="reviewMode && service_effectivity != effectivity.DELIST" >
              <td colspan="5">
                  <div class="container px-5">
                  <h4 *ngIf="openRemarks ">Service Information Remarks</h4>
                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="'Description'" [type]= "'description'"></app-artemis-service-remarks-thread>
  
                  </div>
              </td>
          </tr>
          <tr>
              <th colspan="2" class="text-center">CHECKLIST OF REQUIREMENTS</th>
              <th colspan="3" class="text-center">WHERE TO SECURE</th>
          </tr>
  
          <ng-container *ngIf="service.standardRequirements && service.standardRequirements.length > 0">
              <tr>
                  <td colspan="5">
                      <div>
                          <label style="font-weight: bold; padding-left: 25px;">For Standard Requirement</label>
                      </div>
                      <div class="content-center">
                          <table class="requirement-table">
                              <col style="width:50%"/>
                              <col style="width:50%"/>
                              <tbody>
                                  <ng-container *ngFor="let standardReq of service.standardRequirements; let si = index; let c = count">
                                      <tr *ngIf="!standardReq?.chooseany" id="stdreq_{{si}}" [ngClass]="(si < (c-1)) ? 'middle-row-bot-border' : ''">
                                          <td>
                                              {{si+1}}.{{standardReq.requirement}} 
                                                <ng-container [ngSwitch]="standardReq.requirementType">
                                                  <div *ngSwitchCase="'Sample'">({{ standardReq.requirementType }}: {{standardReq.number}} {{standardReq.unit}}) </div>
                                                  <div *ngSwitchCase="'Documentary'" [innerHtml]="standardReq.documentString"></div>
                                                  <div *ngSwitchCase="'Id'" [innerHtml]="standardReq.listOfIds"></div>
                                              </ng-container>
                                          </td>
                                          <td>
                                              <div *ngIf="!(standardReq.requirementType == 'Id')">
                                                  <p *ngIf="!standardReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ standardReq.agency }} - {{ standardReq.bureau_division }}</p>
                                                  <p *ngIf="standardReq?.applicant">Applicant / Client</p>
                                              </div>
                                              <div *ngIf="standardReq.remarks_or_reminders != ''">
                                                  <p  class="req-details-header">Remarks:</p>
                                                  <p [innerHTML]="standardReq.remarks_or_reminders"></p>
                                              </div>
  
                                            
                                          </td>
                                      </tr>
                                      <tr *ngIf="standardReq?.chooseany">
                                          <td colspan="5">
                                              <div >
                                                  <label id="stdreq_{{si}}" >{{si + 1}}.{{standardReq.title}}</label><br>
                                                  <label *ngIf="standardReq.chooseany"><small>Any of the following:</small></label>
                                              </div>
                                              <ng-container *ngIf="standardReq.requirement && standardReq.requirement.length > 0;">
                                                  <div class="content-center">
                                                      <table class="requirement-table">
                                                          <col style="width:50%"/>
                                                          <col style="width:50%"/>
                                                          <tbody>
                                                              <ng-container *ngFor="let situationalReq of standardReq.requirement; let x = index; let z = count">
                                                                  <tr id="standard_sub{{si}}{{x}}" [ngClass]="(x < (z-1)) ? 'middle-row-bot-border' : ''">
                                                                      <td>
                                                                          <div class="" >{{ requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}
                                                                            <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                              <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                              <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                              <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                          </ng-container>
                                                                          </div>
  
                                                                          
                                                                      </td>
                                                                      <td>
                                                                          <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                              <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                      <p *ngIf="situationalReq?.applicant">Applicant / Client</p>
                                                                       </div>
                                                                          <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                              <p class="req-details-header">Remarks:</p>
                                                                              <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                          </div>
                                                                      </td>
                                                                  </tr>
                                                              </ng-container>
  
                                                              
                                                              
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </ng-container>
                          
                                          </td>
                                      </tr>
                                      <ng-container *ngIf="standardReq?.subRequirement && standardReq?.subRequirement.length > 0 ;noSituationalRequirementsData">
                                        <!-- <p class="text-muted"><i>Sub Situational Requirement/s</i></p>-->
                                         <ng-container *ngFor="let situationalBody of standardReq?.subRequirement; let subindex = index">
                                         
                                             <tr>
                                                 <td colspan="5">
                                                     <div class="ms-5">
                                                         <label id="subsitreq_title{{si}}{{subindex}}" style="font-weight: bold;">{{situationalBody.title}}</label><br>
                                                         <label *ngIf="situationalBody.chooseany"><small>Any of the following:</small></label>
                                                     </div>
                                                     <ng-container *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;">
                                                         <div class="content-center">
                                                             <table class="requirement-table">
                                                                 <col style="width:50%"/>
                                                                 <col style="width:50%"/>
                                                                 <tbody>
                                                                     <ng-container *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                                                                         <tr *ngIf="!situationalReq.chooseany" id="subsitreq_{{si}}{{subindex}}{{i}}" [ngClass]="(i < (c-1)) ? 'middle-row-bot-border' : ''">
                                                                             <td>
                                                                                 <div class="ms-5" > {{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}
                                                                                   <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                     <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                     <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                     <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                 </ng-container>
                                                                                 </div>

                                                                                 
                                                                             </td>
                                                                             <td>
                                                                                 <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                   <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                   <p *ngIf="situationalReq?.applicant">Applicant / Client</p>   </div>
                                                                                 <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                     <p class="req-details-header">Remarks:</p>
                                                                                     <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                 </div>
                                                                             </td>
                                                                         </tr>
                                                                         <tr *ngIf="situationalReq?.chooseany">
                                                                           <td colspan="5">
                                                                               <div >
                                                                                   <label id="stdreq_{{i}}" >{{i + 1}}.{{situationalReq.title}}</label><br>
                                                                                   <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                                               </div>
                                                                               <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                                                   <div class="content-center">
                                                                                       <table class="requirement-table">
                                                                                           <col style="width:50%"/>
                                                                                           <col style="width:50%"/>
                                                                                           <tbody>
                                                                                               <ng-container *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                                                   <tr id="standard_sub{{i}}{{x}}" [ngClass]="(x < (z-1)) ? 'middle-row-bot-border' : ''">
                                                                                                       <td>
                                                                                                           <div class="" >{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}
                                                                                                             <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                                               <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                                               <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                                               <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                                           </ng-container>
                                                                                                           </div>
                                   
                                                                                                           
                                                                                                       </td>
                                                                                                       <td>
                                                                                                           <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                                               <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                                       <p *ngIf="situationalReq?.applicant">Applicant / Client</p>
                                                                                                        </div>
                                                                                                           <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                                               <p class="req-details-header">Remarks:</p>
                                                                                                               <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                                           </div>
                                                                                                       </td>
                                                                                                   </tr>
                                                                                               </ng-container>
                                   
                                                                                               
                                                                                               
                                                                                           </tbody>
                                                                                       </table>
                                                                                   </div>
                                                                               </ng-container>
                                                           
                                                                           </td>
                                                                       </tr>
                                                                     </ng-container>

                                                                     
                                                                     
                                                                 </tbody>
                                                             </table>
                                                         </div>
                                                     </ng-container>
                                                     <ng-template #noSituationalRequirementsData>
                                                         <div class="content-center">
                                                             No Requirements Data
                                                         </div>
                                                     </ng-template>
                                                 </td>
                                             </tr>
                                         </ng-container>
                                       </ng-container>
                                  </ng-container>
                              </tbody>
                          </table>
                      </div>
                  </td>
              </tr>
          </ng-container>
          <ng-container *ngIf="service?.situationalRequirementsArray && service?.situationalRequirementsArray.length > 0 ;noSituationalRequirementsData">
              <ng-container *ngFor="let situationalBody of service?.situationalRequirementsArray;let sitIndex = index">
                  <tr >
                      <td colspan="5">
                          <div>
                              <label id="sitreq_title{{sitIndex}}" style="font-weight: bold; padding-left: 25px;">{{situationalBody.title}}</label><br>
                              <label *ngIf="situationalBody.chooseany"><small>Any of the following:</small></label>
                          </div>
                          <ng-container *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;">
                              <div class="content-center">
                                  <table class="requirement-table situational">
                                      <col style="width:50%"/>
                                      <col style="width:50%"/>
                                      <tbody>
                                          <ng-container *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                                              <tr *ngIf="!situationalReq.chooseany" id="sitreq_{{sitIndex}}{{i}}" [ngClass]="(i < (c-1)) ? 'middle-row-bot-border' : ''">
                                                  <td>
                                                      {{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}
                                                        <ng-container [ngSwitch]="situationalReq.requirementType">
                                                          <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                          <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                          <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                      </ng-container>
                                                  </td>
                                                  <td>
                                                      <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                          <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                          <p *ngIf="situationalReq?.applicant">Applicant / Client</p>   </div>
                                                      <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                          <p class="req-details-header">Remarks:</p>
                                                          <p [innerHTML]="situationalReq.remarks_or_reminders"></p>
                                                      </div>
  
                                                      
                                                  </td>
                                                 
                                              </tr>
                                              <tr *ngIf="situationalReq?.chooseany">
                                                  <td colspan="5">
                                                      <div >
                                                          <label id="stdreq_{{i}}" >{{i + 1}}.{{situationalReq.title}}</label><br>
                                                          <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                      </div>
                                                      <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                          <div class="content-center">
                                                              <table class="requirement-table">
                                                                  <col style="width:50%"/>
                                                                  <col style="width:50%"/>
                                                                  <tbody>
                                                                      <ng-container *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                          <tr  *ngIf=!situationalReq.chooseany id="standard_sub{{i}}{{x}}" [ngClass]="(x < (z-1)) ? 'middle-row-bot-border' : ''">
                                                                              <td>
                                                                                  <div class="" >{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}
                                                                                    <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                      <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                      <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                      <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                  </ng-container>
                                                                                  </div>
          
                                                                                  
                                                                              </td>
                                                                              <td>
                                                                                  <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                      <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                              <p *ngIf="situationalReq?.applicant">Applicant / Client</p>
                                                                               </div>
                                                                                  <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                      <p class="req-details-header">Remarks:</p>
                                                                                      <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                  </div>
                                                                              </td>
                                                                          </tr>
                                                                          <tr *ngIf="situationalReq?.chooseany">
                                                                              <td colspan="5">
                                                                                  <div >
                                                                                      <label id="stdreq_{{x}}" >{{ requirementservice.printToLetter(x + 1) }}.{{situationalReq.title}}</label><br>
                                                                                      <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                                                  </div>
                                                                                  <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                                                      <div class="content-center">
                                                                                          <table class="requirement-table">
                                                                                              <col style="width:50%"/>
                                                                                              <col style="width:50%"/>
                                                                                              <tbody>
                                                                                                  <ng-container *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                                                      <tr id="standard_sub{{i}}{{x}}" [ngClass]="(x < (z-1)) ? 'middle-row-bot-border' : ''">
                                                                                                          <td>
                                                                                                              <div class="" >{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}
                                                                                                                <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                                                  <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                                                  <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                                                  <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                                              </ng-container>
                                                                                                              </div>
                                      
                                                                                                              
                                                                                                          </td>
                                                                                                          <td>
                                                                                                              <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                                                  <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                                          <p *ngIf="situationalReq?.applicant">Applicant / Client</p>
                                                                                                           </div>
                                                                                                              <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                                                  <p class="req-details-header">Remarks:</p>
                                                                                                                  <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                                              </div>
                                                                                                          </td>
                                                                                                      </tr>
                                                                                                  </ng-container>
                                      
                                                                                                  
                                                                                                  
                                                                                              </tbody>
                                                                                          </table>
                                                                                      </div>
                                                                                  </ng-container>
                                                              
                                                                              </td>
                                                                          </tr>
                                                                      </ng-container>
          
                                                                      
                                                                      
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                      </ng-container>
                                  
                                                  </td>
                                              </tr>
                                              <ng-container *ngIf="situationalReq?.subRequirement && situationalReq?.subRequirement.length > 0 ;noSituationalRequirementsData">
                                               <!-- <p class="text-muted"><i>Sub Situational Requirement/s</i></p>-->
                                                <ng-container *ngFor="let situationalBody of situationalReq?.subRequirement; let subindex = index">
                                                
                                                    <tr>
                                                        <td colspan="5">
                                                            <div class="ms-5">
                                                                <label id="subsitreq_title{{i}}{{subindex}}" style="font-weight: bold;">{{situationalBody.title}}</label><br>
                                                                <label *ngIf="situationalBody.chooseany"><small>Any of the following:</small></label>
                                                            </div>
                                                            <ng-container *ngIf="situationalBody.requirement && situationalBody.requirement.length > 0;">
                                                                <div class="content-center">
                                                                    <table class="requirement-table">
                                                                        <col style="width:50%"/>
                                                                        <col style="width:50%"/>
                                                                        <tbody>
                                                                            <ng-container *ngFor="let situationalReq of situationalBody.requirement; let i = index; let c = count">
                                                                                <tr *ngIf="!situationalReq.chooseany" id="subsitreq_{{sitIndex}}{{subindex}}{{i}}" [ngClass]="(i < (c-1)) ? 'middle-row-bot-border' : ''">
                                                                                    <td>
                                                                                        <div class="ms-5" > {{ situationalBody.chooseany? requirementservice.printToLetter(i + 1)  : i+1}}.{{situationalReq.requirement}}
                                                                                          <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                            <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                            <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                            <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                        </ng-container>
                                                                                        </div>
    
                                                                                        
                                                                                    </td>
                                                                                    <td>
                                                                                        <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                          <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                          <p *ngIf="situationalReq?.applicant">Applicant / Client</p>   </div>
                                                                                        <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                            <p class="req-details-header">Remarks:</p>
                                                                                            <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr *ngIf="situationalReq?.chooseany">
                                                                                  <td colspan="5">
                                                                                      <div >
                                                                                          <label id="stdreq_{{i}}" >{{i + 1}}.{{situationalReq.title}}</label><br>
                                                                                          <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                                                      </div>
                                                                                      <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                                                          <div class="content-center">
                                                                                              <table class="requirement-table">
                                                                                                  <col style="width:50%"/>
                                                                                                  <col style="width:50%"/>
                                                                                                  <tbody>
                                                                                                      <ng-container *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                                                          <tr id="standard_sub{{i}}{{x}}" [ngClass]="(x < (z-1)) ? 'middle-row-bot-border' : ''">
                                                                                                              <td>
                                                                                                                  <div class="" >{{  requirementservice.printToLetter(x + 1) }}.{{situationalReq.requirement}}
                                                                                                                    <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                                                      <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                                                      <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                                                      <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                                                  </ng-container>
                                                                                                                  </div>
                                          
                                                                                                                  
                                                                                                              </td>
                                                                                                              <td>
                                                                                                                  <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                                                      <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                                              <p *ngIf="situationalReq?.applicant">Applicant / Client</p>
                                                                                                               </div>
                                                                                                                  <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                                                      <p class="req-details-header">Remarks:</p>
                                                                                                                      <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                                                  </div>
                                                                                                              </td>
                                                                                                          </tr>
                                                                                                      </ng-container>
                                          
                                                                                                      
                                                                                                      
                                                                                                  </tbody>
                                                                                              </table>
                                                                                          </div>
                                                                                      </ng-container>
                                                                  
                                                                                  </td>
                                                                              </tr>
                                                                            </ng-container>
    
                                                                            
                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #noSituationalRequirementsData>
                                                                <div class="content-center">
                                                                    No Requirements Data
                                                                </div>
                                                            </ng-template>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                              </ng-container>
                                          </ng-container>
                                      </tbody>
                                  </table>
                              </div>
                          </ng-container>
  
                          <ng-container *ngIf="situationalBody?.subRequirement && situationalBody?.subRequirement.length > 0 ;">
                              <ng-container *ngFor="let situation of situationalBody?.subRequirement; let i = index;">
                                  <div class="content-center">
                                      <table class="requirement-table">
                                          <tr>
                                              <td >
                                                  <div class="">
                                                      <label style="font-weight: bold; ">{{requirementservice.printToLetter(i + 1)}}.{{situation.title}}</label><br>
                                                      <label *ngIf="situation.chooseany"><small>Any of the following:</small></label>
                                                  </div>
                                                  <ng-container *ngIf="situation.requirement && situation.requirement.length > 0;">
                                                      <div class="content-center">
                                                          <table class="requirement-table situational">
                                                              <col style="width:50%"/>
                                                              <col style="width:50%"/>
                                                              <tbody>
                                                                  <ng-container *ngFor="let situationalReq of situation.requirement; let i = index; let c = count">
                                                                      <tr *ngIf="!situationalReq.chooseany" [ngClass]="(i < (c-1)) ? 'middle-row-bot-border' : ''">
                                                                          <td>
                                                                              <div>{{ situation.chooseany? requirementservice.printToLetter(i + 1)  : i+1 }}.{{situationalReq.requirement}}
                                                                                <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                  <div  *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                  <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                  <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                              </ng-container>
                                                                              </div>
          
                                                                              
                                                                          </td>
                                                                          <td>
                                                                              <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                  <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                  <p *ngIf="situationalReq?.applicant">Applicant / Client</p>   </div>
                                                                              <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                  <p class="req-details-header">Remarks:</p>
                                                                                  <p [innerHTML]="situationalReq.remarks_or_reminders"></p>
                                                                              </div>
                                                                          </td>
                                                                      </tr>
                                                                      <tr *ngIf="situationalReq?.chooseany">
                                                                          <td colspan="5">
                                                                              <div >
                                                                                  <label id="stdreq_{{i}}" >{{i + 1}}.{{situationalReq.title}}</label><br>
                                                                                  <label *ngIf="situationalReq.chooseany"><small>Any of the following:</small></label>
                                                                              </div>
                                                                              <ng-container *ngIf="situationalReq.requirement && situationalReq.requirement.length > 0;">
                                                                                  <div class="content-center">
                                                                                      <table class="requirement-table">
                                                                                          <col style="width:50%"/>
                                                                                          <col style="width:50%"/>
                                                                                          <tbody>
                                                                                              <ng-container *ngFor="let situationalReq of situationalReq.requirement; let x = index; let z = count">
                                                                                                  <tr id="standard_sub{{i}}{{x}}" [ngClass]="(x < (z-1)) ? 'middle-row-bot-border' : ''">
                                                                                                      <td>
                                                                                                          <div class="" >{{ situationalReq.chooseany? requirementservice.printToLetter(x + 1) :  x+1}}.{{situationalReq.requirement}}
                                                                                                            <ng-container [ngSwitch]="situationalReq.requirementType">
                                                                                                              <div *ngSwitchCase="'Sample'">({{ situationalReq.requirementType }}: {{situationalReq.number}} {{situationalReq.unit}}) </div>
                                                                                                              <div *ngSwitchCase="'Documentary'" [innerHtml]="situationalReq.documentString"></div>
                                                                                                              <div *ngSwitchCase="'Id'" [innerHtml]="situationalReq.listOfIds"></div>
                                                                                                          </ng-container>
                                                                                                          </div>
                                  
                                                                                                          
                                                                                                      </td>
                                                                                                      <td>
                                                                                                          <div *ngIf="!(situationalReq.requirementType == 'Id')">
                                                                                                              <p *ngIf="!situationalReq?.applicant"><span class="req-details-header">Agency - Division: </span>{{ situationalReq.agency }} - {{ situationalReq.bureau_division }}</p>
                                                                                                      <p *ngIf="situationalReq?.applicant">Applicant / Client</p>
                                                                                                       </div>
                                                                                                          <div *ngIf="situationalReq.remarks_or_reminders  != ''">
                                                                                                              <p class="req-details-header">Remarks:</p>
                                                                                                              <p [innerHTML]="situationalReq.remarks_or_reminders "></p>
                                                                                                          </div>
                                                                                                      </td>
                                                                                                  </tr>
                                                                                              </ng-container>
                                  
                                                                                              
                                                                                              
                                                                                          </tbody>
                                                                                      </table>
                                                                                  </div>
                                                                              </ng-container>
                                                          
                                                                          </td>
                                                                      </tr>
                                                                      
                                                                  </ng-container>
          
                                                                  
                                                                  
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </ng-container>
                                                 
                                              </td>
                                          </tr>
                                      </table>
                                  </div>
                                  
                              </ng-container>
                            </ng-container>
  
                          
                          <ng-template #noSituationalRequirementsData>
                              <div class="content-center">
                                  No Requirements Data
                              </div>
                          </ng-template>
                      </td>
                  </tr>
                  
                  
              </ng-container>
          </ng-container>
          <ng-container *ngIf="service.no_requirements">
              <tr>
                  <td colspan="5">
                      <div class="row text-center">
                          No Requirement/s Needed
                      </div>
      
                  </td>
              </tr>
          </ng-container>
          
          <tr *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
              <td colspan="5">
                  <div class="container px-5">
                      <h4 *ngIf = "openRemarks">Requirements Remarks</h4>
                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="'Requirements'" [type]= "'requirement'" ></app-artemis-service-remarks-thread>
  
                  </div>
              </td>
          </tr>
  
          <tr>
              <td class="row-header text-center">CLIENT STEPS</td>
              <td class="row-header text-center">AGENCY ACTIONS</td>
              <td class="row-header text-center">FEES TO BE PAID</td>
              <td class="row-header text-center">PROCESSING TIME</td>
              <td class="row-header text-center">
                  <div>PERSON RESPONSIBLE</div>
                  <div>(Designation; Office)</div>
              </td>
          </tr>
          <ng-container *ngIf="service.processedClientStep && service.processedClientStep.length > 0">
              <ng-container *ngFor="let clientStep of service.processedClientStep ; let clientStep_Index = index">
  
                  <ng-container *ngIf="clientStep.situational">
  
                         <tr  *ngIf="clientStep?.step_title "  class="client-step">
                              <td colspan="5 w-100">
          
                               <span class="fw-bold">{{ clientStep.situational_step_title}}</span>
          
                              </td>
          
                          </tr>
                      <tr *ngFor="let situationalStep of clientStep.clientStep ; let sitClientStep_Index = index" class="client_step">
                          <ng-container *ngIf="(situationalStep.rowSpan > 0)">
                              <td [id]="situationalStep.step_Id" class="step" [attr.rowspan]="situationalStep.rowSpan">
                                  <div class="fw-bold mb-1">
                                      {{situationalStep.situation}}
                                  </div>
                                  <div>
                                      {{situationalStep.clientStepName}}
                                  </div>
                                  
                                  <label class="fw-bold" for="location mt-1">Location:</label>
                                  <p  id="location" class="mt-1 step_loc" [innerHTML]="situationalStep.location"></p>
                                 <!-- <p>Total Fees: {{ clientStep.fees }}</p>-->
                                 <div *ngIf="situationalStep.notes">
                                 <label class="fw-bold" for="nodes">Notes/Instruction: </label>
                                  <p class="step_notes" [innerHTML]="situationalStep.notes" >
                                   
                                  </p></div>
                                  <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                   
                                      <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="situationalStep.step_Id" [type]="client_Step" ></app-artemis-service-remarks-thread>
      
                              
                                  </div>
      
                                  
                              </td>
                          </ng-container>
                          <td [id]="situationalStep.action_Id" class="step">
                              <!--removed situational step title in agency action
                                  <div *ngIf="clientStep.isSituationalStep == true" class="situational-header">* If {{clientStep.situation}}</div>-->
                              <div>{{situationalStep.agencyActions}}</div>
                              <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="situationalStep.action_Id" [type]="agency_action" ></app-artemis-service-remarks-thread>
      
      
                              </div>
                          </td>
                          <ng-container *ngIf="(situationalStep.rowSpan > 0)" >
                              <td [attr.rowspan]="situationalStep.rowSpan">
                                  <p [innerHTML]="situationalStep.fees"></p>
                                  
                               <!--  <div *ngIf="(situationalStep.formulaFeesArr.length > 0 || situationalStep.possibleFeesArr.length > 0) " >
                                  <div *ngIf="situationalStep?.fees != 'None'" >
                                      <ul style="list-style-type: none;">
                                          
                                          <li *ngFor="let fee of computeTotalFees([ ],situational_clientStep(clientStep_Index,sitClientStep_Index))">
                                             + {{fee.currency}} {{fee.amount  }} 
                                          </li>
                                          <li *ngIf="(clientStep.formulaFeesArr.length > 0 || clientStep.possibleFeesArr.length > 0)">
                                              Plus
                                          </li>
                                      </ul> 
                                    
                                    </div>
                                  <div *ngIf="situationalStep.formulaFeesArr.length > 0">
                                    <div>
                                      <ul>
                                          <li style="cursor: pointer; font-style: italic;" (click)="openFees(sfee)" *ngFor="let sfee of situationalStep.formulaFeesArr">
                                              {{sfee.fee_type}}
                                          </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div *ngIf="situationalStep.possibleFeesArr.length > 0">
                                    <div>
                                      <ul>
                                          <li style="cursor: pointer; font-style: italic;" (click)="openFees(sfee)" *ngFor="let sfee of situationalStep.possibleFeesArr">
                                              {{sfee.fee_type}}
                                          </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
  
                                <ul style="list-style-type: none;">
                                  <ng-container *ngIf="computeTotalFees([],situational_clientStep(clientStep_Index,sitClientStep_Index)).length ; else noFee">
                                      <li *ngFor="let fee of computeTotalFees([],situational_clientStep(clientStep_Index,sitClientStep_Index))">
                                          + {{fee.currency}} {{fee.amount  }} 
                                       </li>
                                  </ng-container>
                                </ul>  -->
  
                               
        
                                <!-- <div *ngIf="(situationalStep.formulaFeesArr.length <= 0 && situationalStep.possibleFeesArr.length <= 0) " [innerHtml]="situationalStep.fees"></div>
                            -->   </td>
                          </ng-container>
                          <td ><span class="text-center" style="vertical-align: middle;" >{{situationalStep.processingTime}}</span>
                              <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="situationalStep.action_Id" [type]="'ProcessingTime'" ></app-artemis-service-remarks-thread>
      
      
                              </div>
                          </td>
                          <td>
                              <ul>
                                  <ng-container *ngFor="let personResponsible of situationalStep.personResponsible">
                                      <li>
                                          {{personResponsible.designation}}; {{personResponsible.division_group?.name ? personResponsible.division_group.name : personResponsible.division_group }}
                                      </li>
                                  </ng-container>
                              </ul>
  
                              <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="situationalStep.action_Id" [type]="'PersonResponsible'" ></app-artemis-service-remarks-thread>
      
      
                              </div>
                          </td>
                      </tr>
                  </ng-container>
  
                  <ng-container *ngIf="!clientStep.situational">
  
                      <tr  class="client_step">
                          <ng-container *ngIf="(clientStep.rowSpan > 0)">
                              <td [id]="clientStep.step_Id" class="step" [attr.rowspan]="clientStep.rowSpan">
                                  <div *ngIf="clientStep.isSituationalStep == true" class="situational-header">* If {{clientStep.situation}}</div>
                                  {{clientStep.clientStepName}}
                                  <br>
                                  <label class="fw-bold" for="location mt-1">Location:</label>
                                  <p  id="location" class="mt-1 step_loc" [innerHTML]="clientStep.location"></p>
                                 <div *ngIf="clientStep.notes">
                                 <label class="fw-bold" for="nodes">Notes/Instruction: </label>
                                  <p class="step_notes" [innerHTML]="clientStep.notes" >
                                   
                                  </p></div>
                                  <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
                                      <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="clientStep.step_Id" [type]="client_Step" ></app-artemis-service-remarks-thread>
                                  </div>
                              </td>
                          </ng-container>
                          <td [id]="clientStep.action_Id" class="step">
                              <!--removed situational step title in agency action
                                  <div *ngIf="clientStep.isSituationalStep == true" class="situational-header">* If {{clientStep.situation}}</div>-->
                              <div>{{clientStep.agencyActions}}</div>
                              <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="clientStep.action_Id" [type]="agency_action" ></app-artemis-service-remarks-thread>
      
      
                              </div>
                          </td>
                          <ng-container *ngIf="(clientStep.rowSpan > 0)">
                              <td [attr.rowspan]="clientStep.rowSpan">
                                  <p [innerHTML]="clientStep.fees"></p>
                                  
                              <!--   <div *ngIf="(clientStep.formulaFeesArr.length > 0 || clientStep.possibleFeesArr.length > 0) " >
                                    <div *ngIf="clientStep?.fees != 'None'" >
                                      <ul style="list-style-type: none;">
                                          <li *ngFor="let fee of computeTotalFees([service.clientSteps[clientStep_Index]])">
                                             + {{fee.currency}} {{fee.amount  }} 
                                          </li>
                                          <li *ngIf="(clientStep.formulaFeesArr.length > 0 || clientStep.possibleFeesArr.length > 0)">
                                              Plus
                                          </li>
                                      </ul> 
        
                                    </div>
                                  <div *ngIf="clientStep.formulaFeesArr.length > 0">
                                      <div>
                                        <ul>
                                            <li style="cursor: pointer; font-style: italic;" (click)="openFees(sfee)" *ngFor="let sfee of clientStep.formulaFeesArr">
                                                {{sfee.fee_type}}
                                            </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div *ngIf="clientStep.possibleFeesArr.length > 0">
                                      <div >
                                        <ul>
                                            <li style="cursor: pointer; font-style: italic;" (click)="openFees(sfee)" *ngFor="let sfee of clientStep.possibleFeesArr">
                                                {{sfee.fee_type}}
                                            </li>
                                        </ul>
                                      </div>
                                    </div>
                                </div>
        
                                <div class="text-center" *ngIf="(clientStep.formulaFeesArr.length <= 0 && clientStep.possibleFeesArr.length <= 0) ">
                                  <ul style="list-style-type: none;">
                                      <li *ngFor="let fee of computeTotalFees([service.clientSteps[clientStep_Index]])">
                                         + {{fee.currency}} {{fee.amount  }} 
                                      </li>
                                  </ul> 
  
                                  <ng-container *ngIf="!computeTotalFees([service.clientSteps[clientStep_Index]]).length">
                                      None
                                  </ng-container>
    
                                </div> -->
                              </td>
                          </ng-container>
                          <td [ngClass]="reviewMode? '':'text-center'"><span>{{clientStep.processingTime}}</span>
                              <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="clientStep.action_Id" [type]="'ProcessingTime'" ></app-artemis-service-remarks-thread>
      
      
                              </div>
                          </td>
                          <td>
                              <ul>
                                  <ng-container *ngFor="let personResponsible of clientStep.personResponsible">
                                      <li>
                                          {{personResponsible.designation}}; {{personResponsible.division_group?.name ? personResponsible.division_group.name : personResponsible.division_group }}
                                      </li>
                                  </ng-container>
                              </ul>
  
                              <div class="container" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      
                                  <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="clientStep.action_Id" [type]="'PersonResponsible'" ></app-artemis-service-remarks-thread>
      
      
                              </div>
                          </td>
                      </tr>
  
                  </ng-container>
  
                  
              </ng-container>
          </ng-container>
          <tr *ngIf="service.serviceDetails?.general_remarks">
            <td colspan="5">
                <label class="text-bold" for="generalremarks">General Remarks</label>
              <div id="generalremarks" [innerHTML]=" service.serviceDetails?.general_remarks"></div>
            </td>
          </tr>
          <tr *ngIf="situational_steps.length > 0">
              <th  colspan="5" class="text-center header-details">Total Processing Time:</th>
          </tr>
          <tr *ngIf="situational_steps.length <= 0" >
              <th  colspan="3" class="text-center header-details">Total Processing Time:</th>
              <td  id="tpt" colspan="2" class="text-center">{{service.serviceDetails.total_processing_time}}</td>
          </tr>
          <tr *ngFor="let sit_step of situational_steps">
              <td colspan="3" class="text-center row-header">* If {{sit_step.info.situation}} ({{sit_step.info.title}})</td>
              <td id="tpt" colspan="2" class="text-center">{{computeProcessingTime(service.clientSteps, sit_step)}}</td>
          </tr>
          <tr *ngIf="situational_steps.length === 0">
              <th colspan="3" class="text-center header-details">Total Processing Fee:</th>
              <td id="tpf" colspan="2" class="text-center">
                  <div >
                      <ul style="list-style-type: none;">
                          <li *ngFor="let fee of computeTotalFees(service.clientSteps)">
                             + {{fee.currency}} {{fee.amount  }} 
                          </li>
                      </ul>
                      <ul *ngIf="service?.countStepwithPossible > 0 || service?.countStepwithFormula > 0">
                          <div (click)="openFees(clientstep)"  *ngFor="let clientstep of filterPossibleFees(service.clientSteps);let i= index ;let c= count ">
                             + {{clientstep.fee_type}}
                            </div>
  
                            <div (click)="openFees(clientstep)" *ngFor="let clientstep of filterFormulaFees(service.clientSteps) ;let i= index ;let c= count">
                             + {{clientstep.fee_type}} 
                          
                          </div>
                      </ul>
                     
  
                      <ng-container  *ngIf="!computeTotalFees(service.clientSteps).length && (!service?.countStepwithFormula && !service?.countStepwithPossible)">
                          None
                      </ng-container>
                  </div>
              </td>
          </tr>
          <ng-container *ngIf="situational_steps.length > 0">
              <tr>
                  <th colspan="5" class="text-center header-details">Total Processing Fee:</th>
              </tr>
              <tr  *ngFor="let sit_step of situational_steps">
                  <td colspan="3" class="text-center row-header">*If {{sit_step.info.situation}} ({{sit_step.info.title}})</td>
                  <td id="tpt" colspan="2" class="text-center">
  
                      <ul style="list-style-type: none;">
                          <li *ngFor="let fee of computeTotalFees(service.clientSteps,sit_step)">
                             + {{fee.currency}} {{fee.amount  }} 
                          </li>
                          
                      </ul>
                      <ng-container *ngIf="!computeTotalFees(service.clientSteps,sit_step).length && (!sit_step.info.list_of_formula_fees && !sit_step.list_of_possible_fees )">
                          None
                      </ng-container>
      
                      <div *ngIf="sit_step.info.list_of_formula_fees">
                          <div>
                            <ul>
                                <li style="cursor: pointer; font-style: italic;" (click)="openFees(sfee)"  *ngFor="let sfee of sit_step.info.list_of_formula_fees_arr">
                                    {{sfee.fee_type}}
                                </li>
                            </ul>
                          </div>
                        </div>
                        <div *ngIf="sit_step.list_of_possible_fees">
                          <div>
                            <ul>
                                <li style="cursor: pointer; font-style: italic;" (click)="openFees(sfee)"  *ngFor="let sfee of sit_step.info.list_of_possible_fees_arr">
                                    {{sfee.fee_type}}
                                </li>
                            </ul>
                          </div>
                        </div>
                  
                  
                  
                  </td>
              </tr>
          </ng-container>
          <tr *ngIf="service?.countStepwithPossible > 0">
           <!--- <th colspan="1" class="text-center header-details">List of Possible Fees: </th>-->
            <td id="lpf" colspan="5">
              <label for="">Possible Fees</label>
              <ul class="mb-1">
                <div *ngFor="let clientstep of filterPossibleFees(service.clientSteps) ">
                  <strong>{{clientstep.fee_type}}</strong> : {{ clientstep.amount }}
                </div>
  
                <div *ngFor="let clientstep of filterPossibleFees(situational_steps) ">
                  <label for="">{{clientstep?.situation}}</label>
                  <strong>{{clientstep.fee_type}}</strong> : {{ clientstep.amount }}
                </div>
              </ul>
            </td>
        </tr>
        <tr *ngIf="service?.countStepwithFormula > 0">
         <!-- <th colspan="1" class="text-center header-details">List of Formulated Fees: </th>-->
          <td id="lff" colspan="5">
              <label for="">Formula / Schedule of Fees</label>
            <ul>
              <div *ngFor="let clientstep of filterFormulaFees(service.clientSteps) ">
                
                  <p><strong>{{clientstep.fee_type}} </strong></p>
                    <div [innerHTML]="clientstep.amount "></div>
              
              </div>
              <div *ngFor="let clientstep of filterFormulaFees(situational_steps) ">
                  <label for="">{{client_Step?.situation}}</label>
                  <p><strong>{{clientstep.fee_type}} </strong></p>
                    <div [innerHTML]="clientstep.amount "></div>
              
              </div>
            </ul>
            
          </td>
      </tr>
      </tbody>
    </table>
  
    <div class="row container px-5" *ngIf="reviewMode ">
      <h4 class="mt-2">General Remarks</h4>
      <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="'General_Remarks'" [type]= "'general_remarks'" ></app-artemis-service-remarks-thread>
    </div>
  
    <div *ngIf="group_office.length" class="row container px-5" >
      <h4 class="mt-2">Office Legend</h4>
      <ul [id]="officegroup.name" *ngFor="let officegroup of group_office">
          <label for="">{{officegroup.name}}</label>
          <li *ngFor="let office of officegroup.offices">
              {{office.name}}
          </li>
      </ul>
      </div>
    <div class="row container px-5" *ngIf="reviewMode && service_effectivity != effectivity.DELIST ">
      <h4 class="mt-2">Service EODB Tags Remarks</h4>
      <app-artemis-service-remarks-thread [openRemarks]="openRemarks" [remarks]=remarks_thread [service_id]=service_id [component_id]="'EODB_Tags'" [type]= "'eodb_tags'" ></app-artemis-service-remarks-thread>
  
    </div>
  </ng-container>
  <ng-template #noFee>
      None
  </ng-template>