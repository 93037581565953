<div class="container">
  <div class="py-4">
    <div class="form-group mb-3">
      <div class="text-primary fw-bold fs-1">
        My Subscriptions
      </div>
      <hr class="my-0"/>
      <div>
        <strong>
          Manage your Active Subscriptions here
        </strong>
      </div>
    </div>
    <div class="form-group my-3">

      <div *ngFor="let list of pinList; let i = index" class="accordion my-2" id="accordion{{i}}">
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading{{i}}">
            <button
              class="accordion-button collapsed py-2"
              type="button"
              data-mdb-toggle="collapse"
              attr.data-mdb-target="#collapse{{i}}"
              aria-expanded="false"
              aria-controls="collapse{{i}}"
            >
              <div class="row justify-content-center">
                <div class="row col-lg-12">
                  <div class="col-lg-2">
                    <img
                      *ngIf="list[i]?.type === 'agency' "
                      src="/assets/Assets/Icons/Existing%20Regulation.png"
                      class="img-fluid"
                      alt="Existing Regulation"
                    />
                    <img
                      *ngIf="list[i]?.type === 'sector' "
                      src="/assets/Assets/Icons/Proposed%20Regulation.png"
                      class="img-fluid"
                      alt="Proposed Regulation"
                    />
                  </div>
                  <div class="col-lg-10 row align-items-center">
                    <div class="">
                      <h4 class=""><b>{{ list[i]?.name ? list[i]?.name : 'N/A'}}{{ list[i]?.abbreviation ? ' ('+list[i]?.abbreviation+')' : ''}}</b></h4>
                      <hr class="my-0"/>
                      <p class="row text-muted">
                        <b><small>
                          <div class="float-start d-inline">Subscription-Status: <b [class]="list[i]?.status ? ' text-success' : ' text-danger'">{{ list[i]?.status ? 'Actived' : 'Deactived' }}</b></div>
                          <div class="float-end">Subscription-Started: {{ list[i]?.created_at }}</div>
                        </small>
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </h2>
          <div
            id="collapse{{i}}"
            class="accordion-collapse collapse"
            attr.aria-labelledby="heading{{i}}"
            attr.data-mdb-parent="#accordion{{i}}"
          >
            <div class="accordion-body">
              <div>
                <strong>Manage Subscription</strong>
              </div>
              <hr class="mt-0"/>
              <div class="row col-lg-12">
                <div class="col-lg-3 row align-items-center">
                  <div class="col-12 align-self-center text-center">
                    Notify when:
                  </div>
                </div>
                <div class="col-lg-9">
                  <!-- <div class="form-check" *ngFor="let list_subs of list[i]?.subscription; let a = index">
                    <input class="form-check-input" type="checkbox" value="1" [name]="notify" (click)="onNotify($event)" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      {{ list_subs. }}
                    </label>
                  </div> -->
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.consult_scheduled" name="consult_scheduled" (click)="onNotify(list, $event)" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      a new Consultation in Scheduled
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.consult_commence_7" name="consult_commence_7" (click)="onNotify(list, $event)" id="flexCheckChecked">
                    <label class="form-check-label" for="flexCheckChecked">
                      a Consultation will commence in 7 days
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.consult_7" name="consult_7" (click)="onNotify(list, $event)" id="flexCheckChecked1">
                    <label class="form-check-label" for="flexCheckChecked1">
                      a Consultation will close in 7 days
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.consult_closed" name="consult_closed" (click)="onNotify(list, $event)" id="flexCheckChecked2">
                    <label class="form-check-label" for="flexCheckChecked2">
                      a Consultation has closed
                    </label>
                  </div>
                  <br/>
                  <br/>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.regulation_final" name="regulation_final" (click)="onNotify(list, $event)" id="flexCheckChecked3">
                    <label class="form-check-label" for="flexCheckChecked3">
                      a proposed regulation enters the final rule stage
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.regulation_published" name="regulation_published" (click)="onNotify(list, $event)" id="flexCheckChecked4">
                    <label class="form-check-label" for="flexCheckChecked4">
                      a proposed regulation has been published
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="list[i]?.subscription.regulation_effect" name="regulation_effect" (click)="onNotify(list, $event)" id="flexCheckChecked5">
                    <label class="form-check-label" for="flexCheckChecked5">
                      a proposed regulation has taken effect
                    </label>
                  </div>
                </div>
              </div>
              <hr class="mt-2" />
              <div class="row col-lg-12">
                <div class="col-lg-3 row align-items-center">
                  <div class="col-12 align-self-center text-center">
                    Subscription Status:
                  </div>
                </div>
                <div class="row col-lg-9">
                  <div class="col">
                    <button *ngIf="list[i]?.pause === false" class="btn btn-warning" (click)="onPause(list[i])">
                      <b>
                        Pause subscription
                      </b>
                    </button>
                    <button *ngIf="list[i]?.pause === true" class="btn btn-success" (click)="onPause(list[i])">
                      <b>
                        Unpause subscription
                      </b>
                    </button>
                  </div>
                  <div class="col">
                    <button *ngIf="list[i]?.status === true" class="btn btn-danger" (click)="onSubscribe(list[i])">
                      <b>
                        Unsubscribe
                      </b>
                    </button> 
                    <button *ngIf="list[i]?.status === false" class="btn btn-success" (click)="onSubscribe(list[i])">
                      <b>
                        Subscribe
                      </b>
                    </button> 
                  </div>
                </div>
              </div>
              <hr class="mt-3" />
              <div class="text-center">
                <button (click)="permitToNotify()">
                  Notifiy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
