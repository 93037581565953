import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.scss']
})
export class ReportsMainComponent implements OnInit {
  consultation_report: boolean = false;
  existing_report: boolean = false;
  proposed_report: boolean = false;
  tickets_report: boolean = false;
  use_mgt_report: boolean = false;

  // global settings
  userType: any = sessionStorage.getItem("user_type");

  constructor(private router: Router, private afs: AngularFirestore, public auth: AngularFireAuth) { }

  ngOnInit(): void {
  }

  openConsultationReport(){
    this.consultation_report = true;
    this.existing_report = false;
    this.proposed_report = false;
    this.tickets_report = false;
    this.use_mgt_report = false;
  }

  openExistingReport(){
    this.consultation_report = false;
    this.existing_report = true;
    this.proposed_report = false;
    this.tickets_report = false;
    this.use_mgt_report = false;
  }

  

  openProposedReport(){
    this.consultation_report = false;
    this.existing_report = false;
    this.proposed_report = true;
    this.tickets_report = false;
    this.use_mgt_report = false;
  }

  openTicketReport(){
    this.consultation_report = false;
    this.existing_report = false;
    this.proposed_report = false;
    this.tickets_report = true;
    this.use_mgt_report = false;
  }

  openUserManagementReport(){
    this.consultation_report = false;
    this.existing_report = false;
    this.proposed_report = false;
    this.tickets_report = false;
    this.use_mgt_report = true;
  }

}
