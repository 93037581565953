<!-- Body -->
<div class="content">
    <app-wel-navbar></app-wel-navbar>
    <!-- Carousel -->
    <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-mdb-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="/assets/Assets/Banner/AmbisyonNatin2040.png" class="d-block w-100" alt="..." />
                <div class="carousel-caption">
                    <h1 class="text-uppercase">Article Title One</h1>
                    <h4 class="text-uppercase">First article</h4>
                    <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                </div>
            </div>
            <div class="carousel-item">
                <img src="/assets/Assets/Banner/Banner2.png" class="d-block w-100" alt="..." />
                <div class="carousel-caption">
                    <h1 class="text-uppercase">Article Title Two</h1>
                    <h4 class="text-uppercase">Second article</h4>
                    <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                </div>
            </div>
            <div class="carousel-item">
                <img src="/assets/Assets/Banner/Banner3.png" class="d-block w-100" alt="..." />
                <div class="carousel-caption">
                    <h1 class="text-uppercase">Article Title Three</h1>
                    <h4 class="text-uppercase">Third article</h4>
                    <a href="#read" class="btn btn-lg btn-rounded btn-danger">Read now</a>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-mdb-target="#carouselExampleCaptions" data-mdb-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>

    <!-- Card -->

    <div class="jumbotron-fluid card-group p-2 bg-light">
        <div class="card bg-light shadow-lg text-center p-5">
            <div class="card-body align-self-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                <h5 class="card-title m-2">What is <strong>ARTA</strong>?</h5>
                <p><small class="card-text">
                    Learn more about the new Anti-Red Tape Authority.
                </small></p>
                <a href="#read" class="text-warning 0text-underline"><u>Read more</u></a>
            </div>
        </div>
        <div class="card shadow-lg bg-primary text-white text-center p-5">
            <div class="card-body">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                  </svg>
                <h5 class="card-title m-2">
                    What is the <strong>Ease of Doing Business and
                        Efficient Government Service Delivery Act of 2018
                    </strong>?
                </h5>
                <p><small class="card-text">
                   Get to know the law that is amiming to streamline the current systems
                   and procedure of government services.
                </small></p>
            </div>
        </div>
        <div class="card shadow-lg bg-light text-center p-5">
            <div class="card-body align-self-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-stack" viewBox="0 0 16 16">
                    <path d="M14.12 10.163l1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z"/>
                    <path d="M14.12 6.576l1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z"/>
                  </svg>
                <h5 class="card-title m-2">
                    What are the <strong>violations and penalties
                        under R.A. 11032
                    </strong>?
                </h5>
                <p><small class="card-text">
                  Assert your rights. Learn about the violations to the law and the penalties
                  specified for those who will commit these.
                </small></p>
                <a href="#read" class="text-warning 0text-underline"><u>Read more</u></a>
            </div>
        </div>
    </div>
</div>