import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  link_toggle_status = "Show"
  show_links = false

  constructor() { }

  ngOnInit(): void {
  }

  toggle_link(){
    if(this.show_links){
      this.show_links = false;
      this.link_toggle_status = "Show"
    }
    else{
      this.show_links = true;
      this.link_toggle_status = "Hide"
    }
    
  }

}
