import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-agency-compliance-report',
  templateUrl: './artemis-agency-compliance-report.component.html',
  styleUrls: ['./artemis-agency-compliance-report.component.scss']
})
export class ArtemisAgencyComplianceReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
