<div class="content col-md-12">
    <div class="container-fluid">    

        <div class="row">
            <div class="card padded">
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">Consultations</h6>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">

                        <div class="col main-panel">
                            <ng-container *ngIf="consultationsData && consultationsData.length > 0; noConsultationData">
                                <table mat-table class="table table-hover table-responsive" id="matTable"  [dataSource]="consultationTabledataSource">
                                    <!-- multiTemplateDataRows -->
                                    <ng-container matColumnDef="rowId">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <div style="padding:0.6">
                                                <div class="row align-items-center p-2">
                                                    <div class="col-md-12">
                                                        <ul  class="list-unstyled">
                                                            <li>
                                                                <h6>{{element.purpose}}</h6>
                                                            </li>
                                                            <li>
                                                                Sector/Stage of Life: <b>{{ crisLifeStatus }}</b>
                                                            </li>
                                                            <li>
                                                                Duration of Consultation: <b>{{element.formated_from_date}} to {{element.formated_to_date}} </b>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
    
                                    <!-- <tr mat-header-row *matHeaderRowDef="consultationTableDisplayCol;"></tr> -->
                                    <tr mat-row matRipple *matRowDef="let row; columns: consultationTableDisplayCol;" ></tr> 
                                    <!-- [ngClass]="expandedCommentCreate == row || expandedCommentView == row? 'row-active' :''" -->
                                    <!-- <tr mat-row *matRowDef="let row; " class="example-detail-row"></tr> -->
                                    <!-- columns: ['expandedDetail'] -->
                                </table>
                            </ng-container>
                            <ng-template #noConsultationData>
                                <div>
                                    <label class="form-input-header" for="subject">No Consultation Data</label>
                                </div>
                            </ng-template>
                        </div>
    
                        <div *ngIf="consultations_file">
                            <div class="d-flex justify-content-center"  *ngIf="consultations_file.consultations_file_type == 'zip'; else regTextNotZipFile">
                                <button class="btn btn-primary" type="button" (click)="downloadFile(consultations_file.consultations_file_download_link,consultations_file.consultations_file_name,'consultations')">
                                    Annexes to Regulation - Download: {{consultations_file.consultations_file_name}}
                                </button>
                            </div>
                            <ng-template #regTextNotZipFile>
                                <div class="row p-2 w-100 ms-0 file-container" >
                                    <div class="col-1">
                                        <button *ngIf="consultations_file.consultations_file" (click)="pageShift('back','consultations')" type="button" class=" h-100 left-button btn-dark">
                                            <i class="fas fa-chevron-left"></i>
                                        </button>
                                    </div>
                                    <div class=" col-10">
                                        <div *ngIf="consultations_file.consultations_file" >
                                            <div class="d-flex justify-content-center"><p>{{consultations_file.consultations_file_page}} / {{consultations_file.consultations_file_total_page ? consultations_file.consultations_file_total_page : "1"}}</p></div>
                                                <pdf-viewer *ngIf="consultations_file.consultations_file"
                                                style="min-height: 1070px; max-height: 1200px;"
                                                (after-load-complete)="afterLoadComplete($event,'consultations')"
                                                [show-all]="false"
                                                [page]="consultations_file.consultations_file_page"
                                                [stick-to-page]="true"
                                                [src]="consultations_file.consultations_file"></pdf-viewer>
                                            <div class="d-flex justify-content-center"><p>{{consultations_file.consultations_file_page}} / {{consultations_file.consultations_file_total_page ? consultations_file.consultations_file_total_page : "1"}}</p></div>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <button *ngIf="consultations_file.consultations_file" (click)="pageShift('next','consultations')" type="button" class=" h-100 right-button btn-dark">
                                            <i class="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>