import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { take, tap } from 'rxjs/operators';
import { PBRIS } from 'src/app/entities/constant';
import { ModalManagerComponent } from 'src/app/modal-manager/modal-manager.component'
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { environment } from 'src/environments/environment';
import { ConfirmSendVerificationComponent } from '../../modal-manager/confirm-send-verification/confirm-send-verification.component';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-login-pbris',
  templateUrl: './login-pbris.component.html',
  styleUrls: ['./login-pbris.component.scss']
})
export class LoginPbrisComponent implements OnInit {
  modalRef?: MDBModalRef;
  loading: boolean = false;
  public call_registration: boolean = false;

  // loginFailedCtr:number = 0
  failedLoginArr:any[] = []

  form = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', Validators.required),

  });

  defaultLoginRoute = '/pbris';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    public modalService: MDBModalService,
    private nE: NotificationsEmailer,
    private snackBarService: SnackbarNotifService
    ) { }

  ngOnInit(): void {
    sessionStorage.setItem("currentSystem",PBRIS)
    // this.checkingLoginFailed()
    this.route.queryParams.subscribe(params => {
      if (params.initial) {
        this.callModal();
      }

      if(params.newRoute){
        this.defaultLoginRoute = params.newRoute
      }
    })
  }

  toggleRegistration() {
    this.call_registration = true;
  }

  callModal() {
    this.modalRef = this.modalService.show(ModalManagerComponent,
      {
        backdrop: 'static',
        data: {
          header_content: {
            logo: "/assets/Assets/ARTA-OP-BLK.png"
          },
          body_content: {
            message: "You can experience PBRIS without logging in. Click here to proceed with a guest account."
          },
          buttons: {
            confirm: "Continue As Guest",
            //registration: "Sign Up for an Account",
            cancel: "No thanks, I have an account"
          },
          login_route: "/pbris",
          register_route: "/registration-pbris",
        }
      });
  }

  callMissingUserModal() {
    this.modalRef = this.modalService.show(ModalManagerComponent,
      {
        backdrop: 'static',
        data: {
          header_content: {
            logo: "/assets/Assets/ARTA-OP-BLK.png"
          },
          body_content: {
            message: "This user does not exist. Would you like to sign up for a PBRIS account?"
          },
          buttons: {
            confirm: "No (Continue As Guest)",
            registration: "Yes (Sign Up for an Account)"
          },
          login_route: "/pbris",
          register_route: "/registration-pbris",
        }
      });
  }

  checkingLoginFailedEmailGood(email:any){
    console.log('sending mail')
    this.toSendNotifEmail([email],10)
  }

  onSubmit() {
    this.loading = true
    //console.log(this.form.value)
    this.auth.signInWithEmailAndPassword(this.form.value.username,
      this.form.value.password).then(cred => {

        if (cred.user?.uid) {
          // get account type from firestore
          this.afs.doc(`Users/${cred.user.uid}`).snapshotChanges()
            .pipe(
              tap(
                async (data: any) => {
                  let item = data.payload.data()
                  let account_type = item.credentials.account_type.toLowerCase();
                  // Set account_subtype in sessionStorage for other features, assumes that it matches GovernmentAccountSubtype
                  sessionStorage.setItem("account_subtype", item.credentials.account_subtype);
                  sessionStorage.setItem("currentSystem",PBRIS)
                  switch (account_type) {
                    case "government":
                      if (item.credentials.account_subtype && item.credentials.account_subtype.toLowerCase().includes("arta")) {
                        sessionStorage.setItem("user_type", "arta");

                        switch(item.credentials.account_subtype){
                          case GovernmentAccountSubtype.ARTA_ADMIN:{
                            sessionStorage.setItem("arta_admin", "true");
                            break;
                          }
                          case GovernmentAccountSubtype.AGENCY_ADMIN:{
                            sessionStorage.setItem("agency_admin", "true");
                            break;
                          }
                        }
                        // if (item.credentials.account_subtype.toLowerCase().includes("admin")) {
                        //   sessionStorage.setItem("arta_admin", "true");
                        // }
                      }
                      else {
                        sessionStorage.setItem("user_type", "agency");
                        const agency_data = await firstValueFrom(this.afs.doc(`Agency/${item.government?.agency}`).get());
                        let agency_data_data: any = agency_data.data();

                        sessionStorage.setItem("agency_name", agency_data_data?.name);
                        sessionStorage.setItem("agency_id", item.government?.agency);
                        switch(item.credentials.account_subtype){
                          case GovernmentAccountSubtype.AGENCY_ADMIN:{
                            sessionStorage.setItem("agency_admin", "true");
                            break;
                          }
                        }
                      }

                      this.redirectLogin();
                      break;
                    case "company":
                      sessionStorage.setItem("user_type", "agency");

                      this.redirectLogin();
                      break;
                    case "personal":
                      sessionStorage.setItem("user_type", "personal");

                      if (!cred.user?.emailVerified) {
                        this.loading = false;
                        this.modalRef = this.modalService.show(ConfirmSendVerificationComponent,
                          {
                            backdrop: 'static',
                            data: {
                              header_content: {
                                logo: "/assets/arta-logo.png"
                              },
                              body_content: {
                                message: `This user's email is not verified. 
                              If you have not received a verfication message, 
                              or your previous message has expired, you can request for a new message.`
                              },
                              buttons: {
                                confirm: "Yes, please send me a new message.",
                                cancel: "No thanks."
                              },
                              user_obj: cred?.user,
                              redirect_url: environment.verificationRedirectUrls.pbris
                            }
                          });
                      }
                      else {
                        this.redirectLogin();
                      }
                      break;
                    default:
                  }
                }
              ),
              take(1)
            )
            .subscribe();
        }
      }).catch(error => {
        this.loading = false;
        switch (error.code) {
          case 'auth/user-not-found':
          case 'auth/invalid-email':
            this.callMissingUserModal();
            break;
          // case 'auth/too-many-requests':
          //   // this.checkingLoginFailedEmailGood(this.form.value.username)
          //   alert(error);
          //   break;
          default:
            alert(error);
            break;
        }
      })

  }

  loginAsGuest() {
    this.router.navigate(['/pbris']);
  }

  redirectLogin() {
    this.router.navigate([this.defaultLoginRoute]).then(() => {
      this.snackBarService.openPlainNotif("Login successful!","Close");
      this.loading = false;
    });
  }

  toSendNotifEmail(currentUsers:any[], templateCode:number){
    console.log('sending email: ',currentUsers, templateCode)
    if(templateCode > 0){
      let data = {
        confirmLink: 'https://arta-respond.web.app/pbris/help',
        reportLink: 'https://arta-respond.web.app/pbris/help'
      }
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('LoginPbrisComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('LoginPbrisComponent emailer error: ',err)
      //     }
      // })
    } else console.error('LoginPbrisComponent emailer error: no email template for account tyype')
  }
}
