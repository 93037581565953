<!-- <div class="theme-warning">
    <h2>Summary of PIS Assessment</h2>
  </div> -->
<div class="card-header border-bottom border-4 border-dark">
  <h3>Summary of PIS Assessment</h3>
</div>
<div class="row">
  <div class="col ms-5 m-5">
    <div class="row">
      <table
        mat-table
        [dataSource]="pis_assessment_summary['pis_assessment']"
        class="mat-elevation-z8 demo-table"
      >
        <ng-container matColumnDef="section">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Section
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.url; else noURL">
              <a [href]="element.url" target="_blank">
                {{ element.section }}
              </a>
            </ng-container>
            <ng-template #noURL>
              {{ element.section }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="assessment">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Assessment
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assessment }}
          </td>
        </ng-container>

        <ng-container matColumnDef="previous_remarks">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Remarks
          </th>
          <td style="width: 20%;" mat-cell *matCellDef="let element">
            <ng-container>
              <button class="w-100 btn btn-outline-primary" (click)="checkRemarks(element)">
                ({{element.previous_remarks ? element.previous_remarks.length : '0'}})
                <i class="far fa-comments"></i> Remarks
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizePis"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsFinalizePis"
        ></tr>
      </table>
    </div>
  </div>
</div>
  
  <!-- Tag Assessment -->
<!-- <div class="theme-warning">
  <h2>Summary of Tag Assessment</h2>
</div> -->

<div class="card-header border-bottom border-4 border-dark">
  <h3>Summary of Tag Assessment</h3>
</div>
<div class="row">
  <div class="col ms-5 m-5">
    <div class="row">
      <table
        mat-table
        [dataSource]="pis_assessment_summary['tag_assessment']"
        class="mat-elevation-z8 demo-table"
      >
        <ng-container matColumnDef="section">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Section
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.section }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assessment">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Assessment
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assessment }}
          </td>
        </ng-container>

        <ng-container matColumnDef="previous_remarks">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Remarks
          </th>
          <td style="width: 20%;" mat-cell *matCellDef="let element">
            <ng-container>
              <button class="w-100 btn btn-outline-primary" (click)="checkRemarks(element)">
                ({{element.previous_remarks ? element.previous_remarks.length : '0'}})
                <i class="far fa-comments"></i> Remarks
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizePis"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsFinalizePis"
        ></tr>
      </table>
    </div>
  </div>
</div>
  
  <!-- Proportionality Assessment -->
  
<!-- <div class="theme-warning">
  <h2>Summary of Proportionality Assessment</h2>
</div> -->

<div class="card-header border-bottom border-4 border-dark">
  <h3>Summary of Proportionality Assessment</h3>
</div>
<div class="row">
  <div class="col ms-5 m-5">
    <div class="row">
      <table
        mat-table
        [dataSource]="pis_assessment_summary['proportionality_assessment']"
        class="mat-elevation-z8 demo-table"
      >
        <ng-container matColumnDef="section">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Section
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.section }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assessment">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Assessment
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.assessment }}
          </td>
        </ng-container>

        <ng-container matColumnDef="previous_remarks">
          <th class="theme-default" mat-header-cell *matHeaderCellDef>
            Remarks
          </th>
          <td style="width: 20%;" mat-cell *matCellDef="let element">
            <ng-container>
              <button class="w-100 btn btn-outline-primary" (click)="checkRemarks(element)">
                ({{element.previous_remarks ? element.previous_remarks.length : '0'}})
                <i class="far fa-comments"></i> Remarks
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsFinalizePis"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsFinalizePis"
        ></tr>
      </table>
    </div>
  </div>
</div>
  
  <!-- File Uploads -->
  
  <!-- Buttons -->
  