<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - STOCK OF PROPOSED REGULATION</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                    <app-date-picker 
                    (datePickerEvent) = "receivedDatePicker($event)"
                    ></app-date-picker>{{dateInfo}}</th>    
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">TOTAL COUNT:</td>
                <td class = "h3"> {{report_count.length}} </td>
            </tr>
            <tr>
                <td class = "table-warning">COMPLETED (for verification):</td>
                <td class = "h3">{{draft_result.length}}</td>
            </tr>
            <tr>
                <td class = "table-warning">PENDING (draft):</td>
                <td class = "h3">{{verify_result.length}}</td>
            </tr>
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">PROCESSED WITHIN TARGETED SCHEDULE</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>WITHIN TARGET MONTH</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ON TIME %</td>
                                <td></td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>

          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">PER STAGE</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>PRE-RULE</td>
                                <td class = "h3">{{pre_rule_count.length}}</td>
                            </tr>
                            <tr>
                                <td>PRE-RULE ASSESSMENT</td>
                                <td class = "h3">{{pre_rule_assessment_count.length}}</td>
                            </tr>
                            <tr>
                                <td>PROPOSED RULE</td>
                                <td class = "h3">{{proposed_rule_count.length}}</td>
                            </tr>
                            <tr>
                                <td>PROPOSED RULE ASSESSMENT</td>
                                <td class = "h3">{{verify_result.length}}</td>
                            </tr>
                            <tr>
                                <td>INTERIM RULE</td>
                                <td class = "h3">{{verify_result.length}}</td>
                            </tr>
                            <tr>
                                <td>FINAL RULE</td>
                                <td class = "h3">{{verify_result.length}}</td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>

          <!-- BY SIGNIFICANCE-->
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">BY SIGNIFICANCE</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>MAJOR</td>
                                <td class = "h3">{{major_count.length}}</td>
                            </tr>
                            <tr>
                                <td>EXEMPTED</td>
                                <td class = "h3">{{exempted_count.length}}</td>
                            </tr>
                            <tr>
                                <td>EMERGENCY</td>
                                <td class = "h3">{{emergency_count.length}}</td>
                            </tr>
                            <tr>
                                <td>MINOR</td>
                                <td class = "h3">{{minor_count.length}}</td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>

          <!-- BY ARTA ASSESSMENT (RIS)-->
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">BY ARTA ASSESSMENT (RIS)</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>BEST PRACTICE</td>
                                <td class = "h3">{{verify_result.length}}</td>
                            </tr>
                            <tr>
                                <td>SATISFACTORY</td>
                                <td class = "h3">{{verify_result.length}}</td>
                            </tr>
                            <tr>
                                <td>RETURNED</td>
                                <td class = "h3">{{verify_result.length}}</td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>

        </tbody>
        
    </table>

    <table class = "table table-bordered">
        <thead class = "table">
            <tr class =" table-dark">
                <th colspan = "4">COUNT PER EODB TAGGING:</th>
            </tr>
            <tr class = "table-warning text-center">
                <th colspan = "2">
                    BUSINESS:
                </th>
                <th colspan = "2">
                    NON-BUSINESS:
                </th>
            </tr>
           
        </thead>
        <tbody>
            <tr>
                <th>SECTOR</th>
                <td>(display count per sector)</td>
                <th>STAGE OF LIFE</th>
                <td>(count per stage of life)</td>
            </tr>
            <tr>
                <th>STAGE OF BUSINESS</th>
                <td>(display count per stage)</td>
                <th>CASE USE</th>
                <td>(count per transaction)</td>
            </tr>
        
        </tbody>
        
    </table>
</div>