import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, Query, QueryFn } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { firstValueFrom, Subject } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import { ModalManagerComponent } from 'src/app/modal-manager/modal-manager.component'
import { environment } from 'src/environments/environment';
import { compareDesc, format } from 'date-fns';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Timestamp } from 'firebase/firestore';
import { RegulationsNotifViewComponent } from './regulations/notif-view-list/regulations-notif-view.component';
import { isArray } from 'ngx-bootstrap/chronos';
import { CollectionName } from '../entities/constant';
import { GovernmentAccountSubtype } from '../entities/worklist';

export enum ConsultationCode {
  ALL = 'allTemp',
  ONGOING = 'ongoingTemp',
  TRENDING = 'trendingTemp',
  UPCOMING = 'upcomingTemp',
  DUE = 'dueTemp',
  COMPLETED = 'completedTemp',
  DELAYED = 'delayedTemp',
}

@Component({
  selector: 'app-pbris',
  templateUrl: './pbris.component.html',
  styleUrls: ['./pbris.component.scss']
})
export class PbrisComponent implements OnInit {
  modalRef?: MDBModalRef;
  cred: any
  announcements: any = [];
  consultations: any = [];

  referenceMap:Map<string, any> = new Map();
  consultationsMap: Map<string, any> = new Map();
  
  consultations_ongoing: any = [];
  consultations_trending: any = [];
  consultations_upcoming: any = [];
  consultations_due : any = [];
  consultations_completed: any = [];
  consultations_delayed: any = [];

  displayed_consultations:any = [];

  filter_counter:any;
  chosenFilter:any=''
  
  dateFilter:any;
  sDateTS:any;
  eDateTS:any;

  maxAnnouncements: number = 2;
  maxRegulations: number = 8;
  links: any = [];
  loader: boolean = true;

  style_theme: any = 'theme-default';
  userType: any = sessionStorage.getItem("user_type");

  isLoggedIn: boolean = false;

  artemisSite: string = environment.redirectUrls.artemisHome;

  regulations: any = [];
  regulationsOrderedByUpload: any = [];
  regulationsOrderedByEffectivity: any = [];

  uploadRView:boolean = false;
  effectiveRView:boolean = false;
  oldRView:boolean = true;

  paneOptions:any=[
    {title: 'Regulations Pane', value:'Regulations',},
    {title: 'Consultations Pane', value:'Consultation'},
  ]

  chosen_pane:any;

  
  defaultLogo = '/assets/GOP_Logo.png';
  // defaultLogo = '/assets/ph-seal_small.png'

  filterList: any = [
    {name:'All',value:'All', icon:'fas fa-list'},
    {name:'Ongoing',value:'Ongoing', icon:'fas fa-sync'},
    {name:'Trending',value:'Trending', icon:'fas fa-fire'},
    {name:'Upcoming',value:'Upcoming', icon:'far fa-comment-dots'},
    {name:'Closing',value:'Due in 7 Days', icon:'fas fa-hourglass-half'},
    {name:'Completed',value:'Completed', icon:'fas fa-check'},
    {name:'Delayed',value:'Delayed', icon:'fas fa-fast-backward'},
  ]

  searchform: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('', Validators.required),    
    eventCategory: new UntypedFormControl('', Validators.required),
  });

  filtersForm = this.formBuilder.group({
    search: new UntypedFormControl(''),
    range: this.formBuilder.group({
      sDate: new UntypedFormControl(null), // {value:null, disabled: true}
      eDate: new UntypedFormControl(null),
    })
  });

  constructor(public auth: AngularFireAuth,
    private afs: AngularFirestore,
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    public modalService: MDBModalService
  ) { }

  ngOnInit(): void {
    this.loader = true;
    this.loadConsultations()
    this.loadAnnouncement()
    this.checkSessionVariables()
    this.loadLinks()

    this.chosen_pane = 'Regulations Pane'


    // this.loadRegulations();
    // this.loadRegulations2();
    this.regulationLoader();
  }

  loadAnnouncement() {
    this.afs.collection(`Announcements`, filter => filter.where('tag', 'in', ['common', 'pbris']).orderBy('date', 'desc').limit(this.maxAnnouncements)).snapshotChanges().subscribe(
      (data: any) => {
        this.announcements = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.announcements.includes(item) === false) {
            this.announcements.push(item);
            this.loader = false;
          }
        });

      });
  }

  loadConsultations(){
    this.afs.collection(CollectionName.PUBLIC_CONSULTATIONS, filter=>this.filterFunc(filter)).snapshotChanges()    
    .pipe(first())
    .subscribe({
      next:(data: any) => {
          
        // let allTemp:any[] = []
        // let ongoingTemp:any[] = []
        // let trendingTemp:any[] = []
        // let upcomingTemp:any[] = []
        // let dueTemp:any[] = []
        // let completedTemp:any[] = []
        // let delayedTemp:any[] = [] 
        let today = new Date();

        this.loader = false;

        // get parent regulation then check if posted

        Promise.all(
          data.map(async (info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            const proposeddRegulation = await this.loadData1(CollectionName.REGULATION_PROPOSED_PHASE_2,item.regulation_id)
            if(proposeddRegulation){
              if(!proposeddRegulation.is_posted) return this.loadConsultations2(item,today)
              else return true
            } else return this.loadConsultations2(item,today)
  
          })
        )
        .then(()=>{
          this.consultations = this.consultationsMap.has(ConsultationCode.ALL) ? Array.from((this.consultationsMap.get(ConsultationCode.ALL) as Map<string, any>).values()) : [];
          this.consultations_ongoing = this.consultationsMap.has(ConsultationCode.ONGOING) ? Array.from((this.consultationsMap.get(ConsultationCode.ONGOING) as Map<string, any>).values()) : [];     
          this.consultations_completed = this.consultationsMap.has(ConsultationCode.COMPLETED) ? Array.from((this.consultationsMap.get(ConsultationCode.COMPLETED) as Map<string, any>).values()) : [];      
          this.consultations_delayed = this.consultationsMap.has(ConsultationCode.DELAYED) ? Array.from((this.consultationsMap.get(ConsultationCode.DELAYED) as Map<string, any>).values()) : [];  
          this.consultations_due = this.consultationsMap.has(ConsultationCode.DUE) ? Array.from((this.consultationsMap.get(ConsultationCode.DUE) as Map<string, any>).values()) : [];
          this.consultations_upcoming = this.consultationsMap.has(ConsultationCode.UPCOMING) ? Array.from((this.consultationsMap.get(ConsultationCode.UPCOMING) as Map<string, any>).values()) : [];
          this.consultations_trending = this.consultationsMap.has(ConsultationCode.TRENDING) ? Array.from((this.consultationsMap.get(ConsultationCode.TRENDING) as Map<string, any>).values()) : [];
        })

        // console.log('consultations', this.consultations)
        // console.log('consultations_ongoing', this.consultations_ongoing)
        // console.log('consultations_trending', this.consultations_ongoing)
        // console.log('consultations_completed', this.consultations_completed)
        // console.log('consultations_delayed', this.consultations_delayed)
        // console.log('consultations_due', this.consultations_due)
        // console.log('consultations_upcoming', this.consultations_upcoming)
      }
    });
  }

  async loadConsultations2(item:any,today:Date){
    const crisData = await this.loadData1(CollectionName.REGULATION_PROPOSED_CRIS,item.cris_id)
    if(crisData){
      let regProposedCris = crisData
      item.regulation_title = regProposedCris.title
      
      item.short_title = regProposedCris.subject
      // consultation.regulation_subj = regProposedCris.subject
      item.reg_agency = regProposedCris.reg_agency;
      item.consultation_id = item.id;
      item.to_date_Date = item.to_date.toDate();
      item.from_date_Date = item.from_date.toDate();
      item.formated_to_date = format(item.to_date.toDate(), 'MM/dd/yyyy');
      item.formated_from_date = format(item.from_date.toDate(), 'MM/dd/yyyy');

      // console.log('reg_agency: ', typeof(item.reg_agency))

      if(item.regulation_type){
        item.regulation_type = item.regulation_type
      }else{
        item.regulation_type = 'Proposed'
      }

      if(typeof(item.reg_agency) == 'string'){
        const agency = await this.loadData1(CollectionName.AGENCY,item.reg_agency)
        if(agency && agency.agencyDetails && agency.agencyDetails.logoURL) item.agencyLogo = agency.agencyDetails.logoURL
        else item.agencyLogo = this.defaultLogo
      }
      // else if(Array.isArray(item.reg_agency)){

      // }
      else item.agencyLogo = this.defaultLogo

      if(regProposedCris.reg_sector){
        const regSector = await this.loadData1(CollectionName.SECTOR,regProposedCris.reg_sector)
        if(regSector) item.life_status = regSector.name
      }

      if(regProposedCris.reg_stageoflife){
        const regStage = await this.loadData1(CollectionName.STAGE_OF_LIFE,regProposedCris.reg_stageoflife)
        if(regStage) item.life_status = regStage.name
      } 

      if(item.completed == false) this.addToConsultationMap(ConsultationCode.ONGOING,item) 
      else if(item.completed == true) this.addToConsultationMap(ConsultationCode.COMPLETED,item)   
      
      if(item.completed == false && this.dateChecker('Upcoming',item.from_date_Date,today,7) == true) this.addToConsultationMap(ConsultationCode.UPCOMING,item)  
      
      if(item.completed == false && this.dateChecker('Delayed',item.to_date_Date,today) == true) this.addToConsultationMap(ConsultationCode.DELAYED,item)    
      
      if(item.completed == false && this.dateChecker('Closing',item.to_date_Date,today,7) == true) this.addToConsultationMap(ConsultationCode.COMPLETED,item)    
      
      console.log(item.id+': ',item.to_date_Date)

      this.addToConsultationMap(ConsultationCode.ALL,item)
      return true
    } else return true
  }

  addToConsultationMap(consultationCode:string,item:any){
    if(!this.consultationsMap.has(consultationCode)){
      let currentMap: Map<string,any> = new Map()
      currentMap.set(item.id,item)
      this.consultationsMap.set(consultationCode,currentMap)
    }else{
      let currentMap = this.consultationsMap.get(consultationCode)
      if(!currentMap.has(item.id)){
        currentMap.set(item.id,item)
        this.consultationsMap.set(consultationCode,currentMap)
      }
    }
  }

  filterFunc(filter:Query){
    let currentDate = new Date()
    console.log('current date: ',currentDate)
    filter = filter.orderBy('to_date','desc').where('to_date','>=',currentDate);
    // filter.limit(10)
    return filter
  }
  
  search(event: Event) {
    let searched:any = []

    const filterValue = (event.target as HTMLInputElement).value
    // console.log(this.displayed_consultations)

    this.displayed_consultations.forEach((element:any) => {
      if(element.regulation_title.includes(filterValue.trim().toLowerCase())){
        searched.push(element)
      }
    });
    this.displayed_consultations = searched
  }

  choosePane(title:string){
    this.chosen_pane = title

    if(title=='Consultations Pane'){
      this.displayed_consultations=this.consultations

      if(this.chosenFilter == ''){
        this.chosenFilter = 'All'
      }
    }else{      
      this.chosenFilter='';
    }
  }

  setFilter(filter:any){
    
    this.chosenFilter=filter;
    this.chosen_pane='Consultations Pane';
    switch(filter){
      case'Ongoing':{                
        return this.displayed_consultations=this.consultations_ongoing
      }
      case'Trending':{        
        return this.displayed_consultations=this.consultations_trending
      }
      case'Upcoming':{
        return this.displayed_consultations=this.consultations_upcoming
      }
      case'Due in 7 Days':{        
        return this.displayed_consultations=this.consultations_due
      }
      case'Completed':{        
        return this.displayed_consultations=this.consultations_completed
      }
      case'Delayed':{        
        return this.displayed_consultations=this.consultations_delayed
      }
      case'All':{        
        return this.displayed_consultations=this.consultations
      }
      default:{  
        return null
      }
    }
  }

  filterCounter(counter:any){
    switch(counter){
      case'Ongoing':{
        return this.filter_counter=this.consultations_ongoing.length
      }
      case'Trending':{
        return this.filter_counter=this.consultations_trending.length
      }
      case'Upcoming':{
        return this.filter_counter=this.consultations_upcoming.length
      }
      case'Due in 7 Days':{
        return this.filter_counter=this.consultations_due.length
      }
      case'Completed':{
        return this.filter_counter=this.consultations_completed.length
      }
      case'Delayed':{
        return this.filter_counter=this.consultations_delayed.length
      }
      case'All':{
        return this.filter_counter=this.consultations.length
      }
      default:{
        return null
      }
    }
  }
  
  toPage(consultation:any){
    this.router.navigate(['pbris/consultation/view/'+consultation.consultation_id])
    // console.log('consultation',consultation.consultation_id)
  }

  dateChecker(choice:any,day1:any,day2:any,days?:any){    
    switch(choice){
      case 'Upcoming':
        day1 = new Date(day1);   
        return new Date(day2) < day1.setDate(day1.getDate() + (days||0))
      case 'Closing':
        day1 = new Date(day1);   
        return new Date(day2) <= day1.setDate(day1.getDate() + (days||0))
      case 'Delayed':
          return new Date(day2) > day1.setDate(day1.getDate())
      default:
        return null
    }
  }

  refreshDatePicker(){
    this.filtersForm.patchValue({
      search:'',
    });
    this.filtersForm.controls.range.patchValue({
      sDate:null,
      eDate:null,
    });
    this.sDateTS = null;
    this.eDateTS = null;
    this.displayed_consultations = this.consultations
    // }
    this.changeFilter(this.dateFilter)
  }

  applyDateRange(){
    this.sDateTS = this.filtersForm.value.range.sDate ? Timestamp.fromDate(this.filtersForm.value.range.sDate) : null;
    let beforeFormatEDate = this.filtersForm.value.range.eDate ?  new Date(this.filtersForm.value.range.eDate) : null;
    let formatedEdate:any;
    if(beforeFormatEDate){
      formatedEdate = new Date(beforeFormatEDate.setHours(+beforeFormatEDate.getHours() + +23))
      formatedEdate = new Date(formatedEdate.setMinutes(+formatedEdate.getMinutes() + +59))
      formatedEdate = new Date(formatedEdate.setSeconds(+formatedEdate.getSeconds() + +59))
    }
    this.eDateTS = formatedEdate ? Timestamp.fromDate(formatedEdate) : null;
    if(this.sDateTS != null && this.eDateTS != null){
      // this.consultationTabledataSource = new MatTableDataSource<Consultation>(this.consultations)
      
      this.displayed_consultations =this.consultations.filter((data:any)=>(this.sDateTS <= data.to_date && this.eDateTS >= data.from_date) ? true : false)
     
      // if (this.consultationTabledataSource.paginator) {
      //   this.consultationTabledataSource.paginator.firstPage();
      // }
      this.changeFilter(this.dateFilter)
    }else{
      this.refreshDatePicker()
    }
  }

  changeFilter(value:any){
    this.displayed_consultations = this.settingFilters(value)
  }

  settingFilters(searchColumns:any){
    if(searchColumns == 'defualt'){
      this.dateFilter = 'default'
      return this.defaultSearch
    }
    else if(searchColumns == 'type'){
      this.dateFilter = 'type'
      return this.typeSearch
    }
    else if(searchColumns == 'purpose'){
      this.dateFilter = 'purpose'
      return this.purposeSearch
    }
    else if(searchColumns == 'date'){
      this.dateFilter = 'date'
        return this.dateSearch
    }
    // else if(searchColumns == 'startdate'){
    //   this.currentFilter = 'startdate'
    //   return this.startDateSearch
    // }
    // else if(searchColumns == 'enddate'){
    //   this.currentFilter = 'enddate'
    //   return this.endDateSearch 
    // }
    
    this.dateFilter = 'default'
    return this.defaultSearch
  }
  
  typeSearch = (data:any, filter:string):boolean=>{
    return (data.type.toLowerCase().includes(filter.toLowerCase())) 
    && this.dateRange(data)
  }

  purposeSearch = (data:any, filter:string):boolean=>{
    return (data.purpose.toLowerCase().includes(filter.toLowerCase()))
    && this.dateRange(data)
  }

  defaultSearch = (data:any, filter:string):boolean=>{
    return (data.regulation_title.toLowerCase().includes(filter.toLowerCase()))
    && this.dateRange(data)
  }  


  dateSearch = (data:any, filter:string):boolean=>{
    return (data.formated_to_date.toLowerCase().includes(filter.toLowerCase())
    || data.formated_from_date.toLowerCase().includes(filter.toLowerCase())
    || data.to_date_Date.toString().toLowerCase().includes(filter.toLowerCase())
    || data.from_date_Date.toString().toLowerCase().includes(filter.toLowerCase()))
    && this.dateRange(data)
  }

  dateRange(data:any){
    if(this.filtersForm.value.range.sDate != null && this.filtersForm.value.range.eDate != null){
      if(this.sDateTS <= data.to_date && this.eDateTS >= data.from_date) return true
    }
    // else if(this.filtersForm.value.range.sDate && this.filtersForm.value.range.eDate == null){
    //   if(this.filtersForm.value.range.sDate == data.sDate)
    // }
    else if(this.filtersForm.value.range.sDate == null && this.filtersForm.value.range.eDate == null){
      return true
    }
    return false
  }


  loadLinks() {
    this.afs.collection(`Essential Links`).snapshotChanges().subscribe(
      (data: any) => {
        this.links = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.links.includes(item) === false) {
            this.links.push(item);
            this.loader = false;
          }

        });
      });

  }

  callModal(title: string, content: string) {
    this.modalRef = this.modalService.show(ModalManagerComponent,
      {
        backdrop: 'static',
        data: {
          header_content: {
            title: title
          },
          body_content: {
            message: content
          },
          buttons: {
            cancel: "Close"
          },
        }
      });
  }

  async loadUsers() {
    await this.auth.user.subscribe(data => {
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred: any) => {
        this.cred = cred.data()
        if (!this.cred.credentials.access_pbris) {
          this.router.navigate(['/restricted']);
        }
        else {
          alert("Welcome to PBRIS!")
        }
      })
    })
  }

  async checkSessionVariables() {
    /**
     * checks if user is logged in
     */
    if (await this.auth.user) {

      if (sessionStorage.getItem("user_type")) {
        this.setTheme();
      }
      else {
        /**
         * for reloading user types {default, agency, arta}
         */
        this.auth.onAuthStateChanged(user => {
          if (user?.uid) {
            // alert(user?.uid);
            // set logged in status
            this.isLoggedIn = true;
            // get account type from firestore
            this.afs.doc(`Users/${user.uid}`).snapshotChanges().subscribe(
              (data: any) => {
                let item = data.payload.data()

                if (item.credentials.account_subtype.includes("ARTA")) {
                  sessionStorage.setItem("user_type", "arta");

                  switch(item.credentials.account_subtype){
                    case GovernmentAccountSubtype.ARTA_ADMIN:{
                      sessionStorage.setItem("arta_admin", "true");
                      break;
                    }
                    case GovernmentAccountSubtype.AGENCY_ADMIN:{
                      sessionStorage.setItem("agency_admin", "true");
                      break;
                    }
                  }
                  // if (item.credentials.account_subtype.toLowerCase().includes("admin")) {
                  //   sessionStorage.setItem("arta_admin", "true");
                  // }
                }
                else if (item.credentials.account_type.includes("Government") || item.credentials.account_type.includes("Company")) {
                  sessionStorage.setItem("user_type", "agency");
                  switch(item.credentials.account_subtype){
                    case GovernmentAccountSubtype.AGENCY_ADMIN:{
                      sessionStorage.setItem("agency_admin", "true");
                      break;
                    }
                  }
                }
                else {
                  sessionStorage.setItem("user_type", "personal");
                }
                this.setTheme();
                window.location.reload();
              });
          }
          else {
            sessionStorage.setItem("user_type", "default");
          }
        });
      }
      //
    }
  }

  async setTheme() {
    switch (sessionStorage.getItem("user_type")) {
      case 'arta':
        this.style_theme = 'theme-arta';
        this.isLoggedIn = true;
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        this.isLoggedIn = true;
        break;
      case 'personal':
        this.style_theme = 'theme-default';
        this.isLoggedIn = true;
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  async loadRegulations() {
    this.afs.collection('PBRIS 1 Regulations',
      filter => filter.where('regulation_type', '==', 'Proposed')
        .orderBy('short_title')
        .limit(this.maxRegulations))
      .snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            this.regulations = [];
            data.forEach((results: any) => {
              let item: any = results.payload.doc.data();
              item.id = results.payload.doc.id;
              item.displayDate = (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date )
              item.regulations_from = 'regulations';
              this.regulations.push(item);
            });

            if (this.regulations.length < this.maxRegulations) {
              this.afs.collection('PBRIS 1 Regulations',
                filter => filter.where('regulation_type', '==', 'Existing')
                  .orderBy('short_title')
                  .limit(this.maxRegulations))
                .snapshotChanges()
                .pipe(
                  tap(
                    (data: any) => {
                      const existingToInsert = this.maxRegulations - this.regulations.length;
                      for (var i = 0; i < existingToInsert; i++) {
                        let item: any = data[i].payload.doc.data();
                        item.id = data[i].payload.doc.id;
                        item.displayDate = (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date )
                        item.regulations_from = 'regulations';
                        this.regulations.push(item);
                      }
                    }
                  ),
                  take(1)
                )
                .subscribe();
            }
          }
        ),
        take(1)
      )
      .subscribe();
  }

  regulationLoader(){
    this.loadRegulations1();
    this.loadExistingPhase2ByDate('date_posted');
    this.loadExistingPhase2ByDate('date_effective');
  }

  async loadRegulations1(){
    this.afs.collection('regulations-existing-phase-02',
    filter => filter.where('is_posted', '==', true)
      .orderBy('date_posted', 'desc')
      .limit(this.maxRegulations))
      .snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result)=>{
          result.map(async (data)=>{
            let item: any = data.payload.doc.data();
            item.id = data.payload.doc.id;
            item.displayDate = (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date )
            item.regulations_from = 'existing';
            item.regulation_type = 'Existing';
            item.sector_data = await this.loadSector(item.reg_sector);
            item.agency_data = await this.loadAgency(item.reg_agency);
            if(item.agency_data && item.agency_data != ''){
              let agency:any = item.agency_data
              
              if(agency.agencyDetails){
                if(agency.agencyDetails.logoURL){
                  item.agencyLogo = agency.agencyDetails.logoURL
                }else item.agencyLogo = this.defaultLogo
              }else item.agencyLogo = this.defaultLogo

              if(agency.agencyDetails){
                if(agency.agencyDetails.agencyName){
                  item.agency_name = agency.agencyDetails.agencyName
                }else item.agency_name = agency.name
              }else item.agency_name = agency.name
            }

            this.regulations.push(item);
          })
        },
        error:(err)=>{

        }
      })
  }

  async loadRegulations2() {
    this.afs.collection('regulations-existing-phase-02',
      filter => filter.where('is_posted', '==', true)
        .orderBy('date_posted', 'desc')
        .limit(this.maxRegulations))
      .snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            const existingToInsert = this.maxRegulations - this.regulations.length;
            for (var i = 0; i < existingToInsert; i++) {
              if(i == data.length) {
                break;
              }

              let item: any = data[i].payload.doc.data();
              item.id = data[i].payload.doc.id;
              // item.displayDate = (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date)
              item.short_title = item.subject;
              item.displayDate = format(new Date(item.doc_date), 'MM/dd/yyyy')
              item.regulation_type = 'Existing';
              item.regulations_from = 'existing';
              this.regulations.push(item);
            }
          }
        ),
        take(1)
      )
      .subscribe();
  }

  async loadExistingPhase2ByDate(dateOrder:any){
    let existingFilter: QueryFn;
    console.log('formated date: ',format( new Date(), 'yyyy-MM-dd'))
    if(dateOrder === 'date_effective') {
      // Do not allow any future dates for Recently Effective
      // Firestore's less than filter handles null values
      // Because date_effective is saved from HTML date input, format with the yyyy-MM-dd date string
      existingFilter =  
        filter => filter.orderBy(dateOrder, 'desc')
          .where('date_effective', '<=', format( new Date(), 'yyyy-MM-dd'))
          .where('is_posted', '==', true)
          .limit(this.maxRegulations);
    }
    else {
      existingFilter = 
        filter => filter.orderBy(dateOrder, 'desc')
          .where('date_posted','<=',new Date())
          .where('is_posted', '==', true)
          .limit(this.maxRegulations);
    }
    
    this.afs.collection('regulations-existing-phase-02',existingFilter)
      .snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result)=>{
          result.map(async (data)=>{
            let regulation:any = data.payload.doc.data()
            regulation.id = data.payload.doc.id
            regulation.short_title = regulation.subject;
            regulation.displayDate = format(new Date(regulation.doc_date), 'MM/dd/yyyy');
            // regulation.datePosted = format(regulation.date_posted.toDate(), 'MM/dd/yyyy');
            // console.log(new Date(regulation.doc_date),regulation.date_posted.toDate())
            // console.log(regulation.date_posted)

            if (dateOrder == 'date_effective' && regulation.date_effective != null && regulation.date_effective != undefined && regulation.date_effective != '' ){
              regulation.effectiveDate = format(new Date(regulation.date_effective), 'MM/dd/yyyy')
            }
            // if(dateOrder == 'date_effective' && regulation.date_effective != null && regulation.date_effective != undefined && regulation.date_effective != ''  && new Date() < regulation.date_effective ){
            //   regulation.effectiveDate = format(new Date(regulation.date_effective), 'MM/dd/yyyy')
            // }
            regulation.regulation_type = 'Existing';
            regulation.regulations_from = 'existing';
            regulation.sector_data = await this.loadSector(regulation.reg_sector);
            regulation.agency_data = await this.loadAgency(regulation.reg_agency);
            if(regulation.agency_data && regulation.agency_data != ''){
              let agency:any = regulation.agency_data
              
              if(agency.agencyDetails){
                if(agency.agencyDetails.logoURL){
                  regulation.agencyLogo = agency.agencyDetails.logoURL
                }else regulation.agencyLogo = this.defaultLogo
              }else regulation.agencyLogo = this.defaultLogo

              if(agency.agencyDetails){
                if(agency.agencyDetails.agencyName){
                  regulation.agency_name = agency.agencyDetails.agencyName
                }else regulation.agency_name = agency.name
              }else regulation.agency_name = agency.name

            }else {regulation.agencyLogo = this.defaultLogo; regulation.agency_name = ''}
            if(dateOrder == 'date_posted'){
              if(regulation.date_effective != null && regulation.date_effective != undefined && regulation.date_effective != ''){
                if(new Date(regulation.date_effective) <= new Date()){
                  regulation.effectiveDate = format(new Date(regulation.date_effective), 'MM/dd/yyyy')
                  if(!this.regulationsOrderedByUpload.includes(regulation) && regulation.date_posted != null && regulation.date_posted != undefined && regulation.date_posted != ''){
                    this.regulationsOrderedByUpload.push(regulation)

                    this.regulationsOrderedByUpload.sort((a:any,b:any)=>
                      compareDesc(new Date(a.displayDate), new Date(b.displayDate))
                    )
                  }
                }
              }else{
                if(!this.regulationsOrderedByUpload.includes(regulation) && regulation.date_posted != null && regulation.date_posted != undefined && regulation.date_posted != ''){
                  this.regulationsOrderedByUpload.push(regulation)
                  this.regulationsOrderedByUpload.sort((a:any,b:any)=>
                    compareDesc(new Date(a.displayDate), new Date(b.displayDate))
                  )
                }
              }
            }
            else if (dateOrder == 'date_effective'){
              if(!this.regulationsOrderedByEffectivity.includes(regulation) && regulation.date_effective != null && regulation.date_effective != undefined && regulation.date_effective != ''){
                this.regulationsOrderedByEffectivity.push(regulation)
                this.regulationsOrderedByEffectivity.sort((a:any,b:any)=>
                  compareDesc(new Date(a.date_effective), new Date(b.date_effective))
                )
              }
            }
          })

          // if(dateOrder == 'date_posted'){
          //   this.regulationsOrderedByUpload.sort((a:any,b:any)=>
          //     a.date_posted > b.date_posted ? 1 : a.date_posted < b.date_posted ? -1 : 0
          //   )
          //   console.log(this.regulationsOrderedByUpload)
          // }
          // else if (dateOrder == 'date_effective'){
          //   this.regulationsOrderedByEffectivity.sort((a:any,b:any)=>
          //     a.effectiveDate > b.effectiveDate ? 1 : a.effectiveDate < b.effectiveDate ? -1 : 0
          //   )
          //   console.log(this.regulationsOrderedByEffectivity)
          // }
        },
        error:(err)=>{
          console.error("Error: ",err)
        }
      })
  }

  loadSector(sectorId:any){
    return new Promise((res,rej)=>{
      if(sectorId){
        return this.afs.collection('Sector').doc(sectorId).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let sector: any = result.payload.data();
            sector.id = result.payload.id
            return res(sector)
          },
          error:(err)=>{
            return res('');
          }
        })
      }else return res('');
    })
  }

  loadAgency(agencyIds:any){
    return new Promise((res,rej)=>{
      if(agencyIds.length > 0 && agencyIds != null && agencyIds != undefined && agencyIds != ''){
        let agencyId:any;
        if(isArray(agencyIds)) agencyId = agencyIds[0]
        else agencyId = agencyIds
        this.afs.collection('Agency').doc(agencyId).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let agency:any = result.payload.data()
            agency.id = result.payload.id
            return res(agency)
            // if(agency.agencyDetails){
            //   if(agency.agencyDetails.logoURL){
            //     return res(agency.agencyDetails.logoURL)
            //   }else return res(this.defaultLogo)
            // }else return res(this.defaultLogo)
          },
          error:(err)=>{
            return res('');
          }
        })
      }else return res('');
    })
  }

  viewRegulation(entryId: string, from: string) {
    this.router.navigate([`/pbris/regulations/view/${entryId}`])
    this.router.navigate(['/pbris/regulations/view/',from,entryId])
  }

  resetFlag(){
    this.uploadRView= false;
    this.effectiveRView= false;
    this.oldRView= false;
  }

  onUploadRView(){ 
    this.resetFlag();   
    this.uploadRView= true;
  }

  onEffectieRView(){ 
    this.resetFlag();   
    this.effectiveRView= true;
  }

  onOldRView(){ 
    this.resetFlag();   
    this.oldRView= true;
  }

  async loadData1(collection:string,id:string){
    if(this.referenceMap.has(collection) && this.referenceMap.get(collection).has(id)) return this.referenceMap.get(collection).get(id)
    else return this.loadCollectionDataById(collection,id)
  }

  async loadCollectionDataById(collection:string, id:string){
    return firstValueFrom(this.afs.collection(collection).doc(id).get())
    .then((res)=>{
      if(res.exists){
        let item:any = res.data()
        item.id = res.id

        if(item._createdBy) delete item._createdBy
        if(item._updatedBy) delete item._updatedBy
        if(item.ref) delete item.ref
        
        if(!this.referenceMap.has(collection)){
          let currentMap: Map<string,any> = new Map()
          currentMap.set(item.id,item)
          this.referenceMap.set(collection,currentMap)
        }else{
          let currentMap = this.referenceMap.get(collection)
          if(!currentMap.has(item.id)){
            currentMap.set(item.id,item)
            this.referenceMap.set(collection,currentMap)
          }
        }
        return item
      } else return false
    })
    .catch((err)=>{
      console.error('Error: ',err)
      return false
    })
  }
}

