import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { allMustBe } from 'src/app/validators';
@Component({
  selector: 'app-agency-information',
  templateUrl: './agency-information.component.html',
  styleUrls: ['./agency-information.component.scss']
})
export class AgencyInformationComponent implements OnInit {
  loading: boolean = false;
  loader: boolean = true;
  agency: any = "";
  update_flag: boolean = false;

  public agencyPageForm: UntypedFormGroup;

  form =  new UntypedFormGroup({
    abbreviation: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    contact: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', Validators.required),
    address: new UntypedFormControl('', Validators.required),
    mandate: new UntypedFormControl('', Validators.required),
    mission: new UntypedFormControl('', Validators.required),
    vision: new UntypedFormControl('', Validators.required),
    service_pledge: new UntypedFormControl('', Validators.required)
  });

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    public fomrbuilder: UntypedFormBuilder,
    ) {
      this.agencyPageForm = fomrbuilder.group({
        //agencyDetails
        agencyAbbreviation: new UntypedFormControl(''),
        agencyName: new UntypedFormControl(''),
        websiteLink: new UntypedFormControl(''),

        //contactDetails
        phoneNumber: new UntypedFormControl(''),
        telephoneNumber: new UntypedFormControl(''),
        email: new UntypedFormControl(''),

        //addressDetails
        unit: new UntypedFormControl(''),
        street: new UntypedFormControl(''),
        lot: new UntypedFormControl(''),
        subdivision: new UntypedFormControl(''),
        barangay: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        region: new UntypedFormControl(''),
        zipcode: new UntypedFormControl(''),

        //agencyInfo
        mandate: new UntypedFormControl(''),
        mission: new UntypedFormControl(''),
        vision: new UntypedFormControl(''),
        pledges: new UntypedFormControl(''),

        //cartDetails
        headOfAgencyForm: new UntypedFormArray([
          new UntypedFormGroup({
            name: new UntypedFormControl(''),
            designation: new UntypedFormControl(''),
            email: new UntypedFormControl(''),
          }),
        ]),
        chairPersonForm: new UntypedFormArray([
          new UntypedFormGroup({
            name: new UntypedFormControl(''),
            designation: new UntypedFormControl(''),
            email: new UntypedFormControl(''),
          }),
        ]),
        viceChairPersonForm: new UntypedFormArray([
          new UntypedFormGroup({
            name: new UntypedFormControl(''),
            designation: new UntypedFormControl(''),
            email: new UntypedFormControl(''),
          }),
        ]),
        membersForm: new UntypedFormArray([
          new UntypedFormGroup({
            name: new UntypedFormControl(''),
            designation: new UntypedFormControl(''),
            email: new UntypedFormControl(''),
          }),
        ]),

        //feedbackDetails
        howToSendFeedback: new UntypedFormControl(''),
        howFeedbackProcess: new UntypedFormControl(''),
        howTofileComplaint: new UntypedFormControl(''),
        howComplaintsProcess: new UntypedFormControl(''),
      })
    }

  ngOnInit(): void {
    this.loader = true;
    this.form.disable();
    let id = this.route.snapshot.params.id
    this.loadAgency(id);
  }

  update(){
    this.update_flag = true;
    this.form.enable();
  }

  createForm(value:any){
    if(!value){
      value={}
    }

    this.agencyPageForm = this.fomrbuilder.group({
      //agencyDetails
      agencyAbbreviation:new UntypedFormControl(value.agencyDetails.agencyAbbreviation,[Validators.required]),
      agencyName: new UntypedFormControl(value.agencyDetails.agencyName,[Validators.required]),
      websiteLink: new UntypedFormControl(value.agencyDetails.websiteLink,[Validators.required]),

      //contactDetails
      phoneNumber: new UntypedFormControl(value.contactDetails.phoneNumber,[Validators.required]),
      telephoneNumber: new UntypedFormControl(value.contactDetails.telephoneNumber,[Validators.required]),
      email: new UntypedFormControl(value.contactDetails.email,[Validators.required,Validators.email]),

      //addressDetails
      unit: new UntypedFormControl(value.addressDetails.unit),
      street: new UntypedFormControl(value.addressDetails.street,[Validators.required]),
      lot: new UntypedFormControl(value.addressDetails.lot,[Validators.required]),
      subdivision: new UntypedFormControl(value.addressDetails.subdivision,[Validators.required]),
      barangay: new UntypedFormControl(value.addressDetails.barangay,[Validators.required]),
      city: new UntypedFormControl(value.addressDetails.city,[Validators.required]),
      region: new UntypedFormControl(value.addressDetails.region,[Validators.required]),
      zipcode: new UntypedFormControl(value.addressDetails.zipcode,[Validators.required]),

      //agencyInfo
      mandate: new UntypedFormControl(value.agencyInfo.mandate,[Validators.required]),
      mission: new UntypedFormControl(value.agencyInfo.mission,[Validators.required]),
      vision: new UntypedFormControl(value.agencyInfo.vision,[Validators.required]),
      pledges: new UntypedFormControl(value.agencyInfo.pledges,[Validators.required]),

      //cartDetails
      headOfAgencyForm: new UntypedFormArray([],{ validators: [allMustBe(Validators.required)] }),
      chairPersonForm: new UntypedFormArray([],{ validators: [allMustBe(Validators.required)] }),
      viceChairPersonForm: new UntypedFormArray([],{ validators: [allMustBe(Validators.required)] }),
      membersForm: new UntypedFormArray([],{ validators: [allMustBe(Validators.required)] }),

      //feedbackMeasures
      howToSendFeedback: new UntypedFormControl(value.feedbackMeasures.howToSendFeedback,[Validators.required]),
      howFeedbackProcess: new UntypedFormControl(value.feedbackMeasures.howFeedbackProcess,[Validators.required]),
      howTofileComplaint: new UntypedFormControl(value.feedbackMeasures.howTofileComplaint,[Validators.required]),
      howComplaintsProcess: new UntypedFormControl(value.feedbackMeasures.howComplaintsProcess,[Validators.required]),
    })
    value.cartDetails.headOfAgencyForm.map((row:any)=>{
      this.addNewRow('headOfAgencyForm',row);
    })
    value.cartDetails.chairPersonForm.map((row:any)=>{
      this.addNewRow('chairPersonForm',row);
    })
    value.cartDetails.viceChairPersonForm.map((row:any)=>{
      this.addNewRow('viceChairPersonForm',row);
    })
    value.cartDetails.membersForm.map((row:any)=>{
      this.addNewRow('membersForm',row);
    })
    //console.log("agencyFormPage",this.agencyPageForm)
    this.loader = false;
  }

  //not working properly
  addNewRow(listName: string,rowValue: any){
    const acceptedFilter = ["headOfAgencyForm","chairPersonForm","viceChairPersonForm","membersForm"]
    //make this into acceptedFilter = this.cartForm.controls as an array of string
    const exist = acceptedFilter.includes(listName)
    if(exist){
      let cartFormTemp = this.agencyPageForm.get(listName) as UntypedFormArray;
      cartFormTemp.controls.push(new UntypedFormGroup({
        name: new UntypedFormControl(rowValue.name, Validators.required),
        designation: new UntypedFormControl(rowValue.designation, Validators.required),
        email: new UntypedFormControl(rowValue.email, [Validators.required, Validators.email]),
      }));
      this.agencyPageForm.setControl(listName,cartFormTemp)
    }
  }

  loadAgency(id:any){
    // this.createForm();
    this.afs.collection('Agency').doc(id).snapshotChanges()
    .subscribe(agencyRetrievedFromFirebase=>{
      if(agencyRetrievedFromFirebase){
        const agency: any = agencyRetrievedFromFirebase.payload.data()
        agency.id = agencyRetrievedFromFirebase.payload.id
        this.createForm(agency)
      }
      else{
        this.createForm(false)
      }
    })
  }

  // loadAgency(id: any){
  //   this.afs.doc(`Agency/${id}`).get().subscribe(
  //     (data: any) => {
  //       this.loader = false;
        
  //       this.agency = ""

  //       this.form.patchValue({
  //         abbreviation: data.get('abbreviation'),
  //         name: data.get('name'),
  //         email: data.get('email'),
  //         contact: data.get('contact'),
  //         address: data.get('address'),
  //         mandate: data.get('mandate'),
  //         mission: data.get('mission'),
  //         vision: data.get('vision'),
  //         service_pledge: data.get('service_pledge')
  //       });

  //     });
  //   }

  save(){
    this.loading = true;
    let abbreviation = this.form.value.abbreviation;
    let name = this.form.value.name;
    let email = this.form.value.email;
    let contact = this.form.value.contact;
    let address = this.form.value.address;
    let mandate = this.form.value.mandate;
    let mission = this.form.value.mission;
    let vision = this.form.value.vision;
    let service_pledge = this.form.value.service_pledge;

    this.afs.doc(`Agency/${this.route.snapshot.params.id}`).update({
      contact, address, mandate, mission, vision, service_pledge
    }).then(() => {
      alert('This agency has been updated')
      this.form.disable();
      this.update_flag = false;
      this.loading = false;
    }).catch(error =>{
      alert(error.message)
      this.loading = false;
    });
  }

  delete() {
    this.afs.collection('Agency').doc(this.route.snapshot.params.id).delete().then(()=>{
      this.router.navigate(['/agency/']).then(() =>{
        alert('An agency has been deleted');
      });
    });
  }

}
