<mat-card>
    <table style="width:100%">
        <tbody>
            <tr>
               <!-- <td style="width: 10%"> image
                    <div class="d-flex justify-content-center p-0 m-0">
                        <mat-card class="img-container">
                            <img [src]="agencyLogoForm.value.logoURL" alt="">
                        </mat-card>     
                    </div>
                </td>-->
                <td (click)="goToService(s_data.id)" title="Go to {{s_data.serviceDetails.service_name}} service" style="cursor: pointer;"><!--details-->
                    <div class=" m-3">
                        <!--service name-->
                        <h5 class="fw-bold mb-0">{{s_data.serviceDetails.service_name}}
                        </h5>
                        <!--service stats-->
                        <div class="stats py-2">
                            <div [ngSwitch]="s_data.posted_status">
                                <div *ngSwitchCase="'POSTED'">
                                    <span class="badge badge-success d-inline">
                                        Status: <strong class="px-2"> {{s_data.posted_status}} </strong> 
                                    </span>
                                </div>
                                <div *ngSwitchCase="'DELISTED'">
                                    <span class="badge badge-danger d-inline">
                                        Status: <strong class="px-2"> {{s_data.posted_status}} </strong> 
                                    </span>
                                </div>
                                <div *ngSwitchDefault>
                                    <span class="badge badge-primary d-inline">{{s_data.posted_status}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="my-3">
                            <p class="mb-0">Name of Agency: {{agency_name}}</p><!--agency name-->
                            <p class="mb-0">Citizen's Charter as of: {{ s_data.date_posted ? (s_data.date_posted.toDate() | date: "yyyy-MM-dd hh:mm") : ''}}</p><!--CC date posted-->
                            <p class="mb-0">Category: {{s_data.serviceDetails.service_category}}<!--category-->    
                        </div>
                        <div>
                            <strong class="fw-bold"> Description</strong>
                            <p [innerHtml]="s_data.serviceDetails.service_description"></p>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
  
</mat-card>


