<app-navbar [currentPage]="'Consultation-view'"></app-navbar>


<div class="d-flex justify-content-center align-self-center loader" *ngIf="loading">
  <div class="spinner-grow" role="status" >
      <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div class="content" *ngIf="!loading">

  <div class="container-fluid">
      <!-- Title -->
      <div class="row align-items-end p-3">
        <div class="col-2 d-flex justify-content-center">
          <i class="fas fa-comments" style="font-size: 5rem; color: #B22222;"></i>
        </div>
          <div class="col-8">
              <h5 class="fw-bold">Public Consultation on</h5>
              <h5 class="fw-bold">{{consultations.regulation_title}}</h5>
              <!-- <h1 class="fw-bold">Test</h1> -->
          </div>
          <div class="col-2 text-end align-self-center">

              <img title="Existing" src="/assets/Assets/Icons/Proposed%20Regulation.png" height="100" width="100" alt="Existing Regulation" />

          </div>
      </div>
      <p class="border border-dark"></p>
      <div class="row p-2 my-1">
          <!-- START OF SIDEBAR -->
          <div class="col-sm-6 col-xl-3 {{style_theme}}">
              <div class="row">
                  <div class="col p-4">
                      <!-- <h4 class="text-white fw-bold">{{consultations.regulation_title}}</h4> -->
                      <div class="my-3">
                          <p class="text-white m-0">
                              <strong> Memorandum Circular</strong></p>
                          <p class="text-white m-0"> proposed on
                              <strong>{{consultations.doc_date}}</strong></p>
                          <p class="text-white m-0"> by the
                              <strong><u>{{consultations.agency_name}} </u></strong></p>
                      </div>

                      <div class="my-3">
                        <p class="text-white m-0"> Rulemaking Stage:
                            <strong class="text-uppercase">N/A</strong></p>
                        <p class="text-white m-0"> Significance:
                            <strong class="text-uppercase">{{consultations.significance}}</strong></p>                            
                      </div>


                      <!-- <div *ngIf="isAdmin" class="row m-4">
                          <button (click)="deleteRegulation(collection)" class="row btn btn-danger">Delete (No Warning Prompt)</button>
                      </div> -->
                      <!-- <div *ngIf="isAdmin" class="row m-4">
                          <a class="row btn btn-secondary" routerLink="/pbris/regulations/update/{{id}}">Update Regulation</a>
                      </div> -->

                      <h4 class="text-white fw-bold">
                          Document Details
                      </h4>
                      <div class="my-3">
                      <p class="text-white m-0"> Classification:
                          <strong class="text-uppercase">{{consultations.reg_classification}}</strong></p>
                      <p class="text-white m-0"> Sector:
                          <strong class="text-uppercase">{{consultations.sector_name}}</strong></p>
                      <p class="text-white m-0"> Case Use:
                          <strong class="text-uppercase">{{consultations.case_name}}</strong></p>
                      <p class="text-white m-0"> Jurisdiction:
                          <strong class="text-uppercase">{{consultations.jury_name}}</strong></p>                                
                      </div>

                      <h4 class="text-white fw-bold">
                          Relevant Dates
                      </h4>
                      <div class="my-3">
                          <p class="text-white m-0"> Date Published (ONAR):
                              <strong class="text-uppercase">{{consultations.date_published_onar}}</strong>
                          </p>
                          <p class="text-white m-0"> Date Published (Gazette):
                              <strong class="text-uppercase">{{consultations.date_published_gazette}}</strong>
                          </p>
                          <p class="text-white m-0"> Date of Effectivity:
                                  <strong class="text-uppercase">{{consultations.date_effective}}</strong>
                              </p>
                          <p class="text-white m-0"> Date of Upload PBRIS:
                                  <strong class="text-uppercase">{{consultations.doc_date}}</strong>
                              </p>
                          <p class="text-white m-0"> Date Repealed:
                              <strong class="text-uppercase">{{consultations.date_repeal}}</strong>
                          </p>
                          <p class="text-white m-0"> Date Amended:
                                  <strong class="text-uppercase">{{consultations.ammended}}</strong>
                              </p>
                          </div>

                          <h4 class="text-white fw-bold">
                              Related Regulations
                          </h4>
                          <div class="my-3">
                              <p class="text-white m-0"> Laws:
                                  <u class="text-uppercase">TBA</u></p>
                              <p class="text-white m-0"> National Regulations:
                                  <u class="text-uppercase">TBA</u></p>
                              <p class="text-white m-0"> Local Government Ordinances:
                                  <u class="text-uppercase">TBA</u></p>
                              <!-- <div class="d-flex flex-column">
                                  <p class="text-white m-0"> International Agreements:</p>
                                  <strong class=" text-white text-uppercase ms-2" *ngFor="let row of regulation.reg_intl">{{row}}</strong>
                              </div> -->

                              <div class="d-flex flex-column">
                                  <p class="text-white m-0"> International Agreements:
                                    
                                     <u class=" text-white text-uppercase ms-2" >TBA</u>
                                  </p>
                                  <!-- <strong class=" text-white text-uppercase ms-2" *ngFor="let row of regulation.reg_intl">{{row}}</strong> -->
                              </div>
                          </div>                            

                  </div>
                  <!-- Sidebar -->
                  <div class="col-sm-2 col-md-1 py-3 px-auto bg-warning">
                      <div class="row">
                          <i class="fas fa-bars"></i>
                      </div>

                      <div class="row my-5">
                          <i class="fas fa-file" ></i>
                          <i class="fas fa-comments my-2" style="cursor: pointer;" ></i>
                          <span  style="color: #0d6efd;">
                              <i class="fas fa-thumbtack" style="cursor: pointer;"></i>
                          </span>
                          <span style="color: #4f4f4f;">
                              <i class="fas fa-thumbtack" style="cursor: pointer;" ></i>
                          </span>
                          <a href=""> <i class="fas fa-file-invoice my-2"></i></a>
                      </div>

                      <div class="row my-5">
                          <i class="fas fa-share-square my-2"></i>
                          <i class="far fa-file-alt my-2"></i>
                          <i class="fas fa-print my-2"></i>
                      </div>

                      <div class="row my-5">
                          <i class="fas fa-exclamation-triangle my-2"></i>
                      </div>

                  </div>
              </div>

          </div>

          <!-- END OF SIDEBAR -->

          <!-- Content -->
          <div class="col mx-3">
            
            <div class="row mb-3">
              <p class="col-md-8 py-1">Memorandum Circular proposed on MM/DD/YYYY by the Anti-Red Tape Authority </p>
              <div class="col-md-4 py-1 float-end">
                <button type="button" class="btn btn-outline-danger"
                (click)="toRegulation(consultations.regulation_id)">
                  view Regulation
                </button>
              </div>
            </div>

            <div class="main mt-2">
              <div class="header row" style="border-bottom: 1px solid black;">
                <div class="col-12">
                  <h5 style="font-weight: 600;">Consultation Information</h5>
                </div>
              </div>
              
              <div class="body">
                <div class="info mt-2 row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <p>Consultation Period:</p>
                      </div>
                      
                      <div class="col-8">
                        <p style="margin-bottom: 0;"><strong> {{consultations.formated_from_date}} to {{consultations.formated_to_date}}</strong></p>
                        <p>(Starts/Due in {{getDueDays(consultations.due_to_date)}} Days)</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>Issuing Agency</p>
                      </div>
                      
                      <div class="col-8">
                        <p><strong>{{consultations.agency_name}}</strong></p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <p>Sector:</p>
                      </div>
                      
                      <div class="col-8">
                        <p class="mb-0"> {{consultations.sector_name}}</p>
                        <p>(Starts/Due i XX Days)</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <p>Consultation Type:</p>
                      </div>
                      
                      <div class="col-8">
                        <p>{{consultations.type}}</p>
                      </div>
                    </div>                    
                  </div>

                </div>

                <div class="row mt-4">
                  <div class="col-7">
                    <h5 style="border-bottom: 1px solid; width: 33vw;font-weight: 600;">Purpose of the Consultation</h5>
                    <p style="width: 39vw;text-align: justify;">{{consultations.purpose}}</p>
                  </div>

                  <div class="col-5">
                    <div class="row">
                      <h5 style="border-bottom: 1px solid;font-weight: 600;text-align: justify;">Affected Stakeholders</h5>
                      <div class="px-2">
                        <ul>
                          <li style="text-align:justify;">
                            {{consultations.stakeholder}}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <h5 style="border-bottom: 1px solid;font-weight: 600;">Consultation Materials</h5>
                      <div class="px-2">
                        <ul>
                          <li>
                            Text of Regulation
                          </li>
                          <li>
                            Consultation RIS
                          </li>
                          <li>
                            Other Documents
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>                   
                    
                    

                  </div>

              </div>

            </div>

          </div>
      </div>
  </div>

</div>


<!-- <div class="card">
  <div class="card-body p-0">
      <table class="table table-responsive table-hover">
        <thead class="text-muted">
          <tr> 
            <th class="table-header fw-bold">Name</th>
            <th class="table-header fw-bold">Service</th>
            <th class="table-header fw-bold">User Type</th> 
            <th class="table-header fw-bold">Date Generated</th>
            <th class="table-header fw-bold">Action</th>
          </tr>
        </thead>
        <tbody class="card-text">
          <tr *ngFor="let list of commentList; let i = index">
            <th scope="row">{{ list.user.name ? list.user.name : "N/A"}}</th>
            <td>{{ list.type ? list.type.type_title + ' (' + list.type.type_flag + ')' : "N/A" }}</td>
            <td>{{ list.comments.user ? list.comments.user : "N/A" }}</td>
            <td>{{ list.created_at }}</td>
            <td>
              <button type="button" class="btn btn-info" (click)="sampleModal.show(); openModal(list)" >View</button>
            </td>
          </tr>
        </tbody> 
      </table>
    </div>
  <div style="overflow-y: auto; z-index:1000;" data-keyboard="false" data-backdrop="static" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review Comment</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide(); closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <dl class="row"> 
            <dt class="col-sm-3">Name</dt>
            <dd class="col-sm-9">{{ user.name ? user.name : 'N/A'}}</dd>

            <dt class="col-sm-3 text-truncate">Type</dt>
            <dd class="col-sm-9">{{ type ? type.type_title + ' (' + type.type_flag + ')'  : 'N/A'}}</dd>
          
            <dt class="col-sm-3">URL</dt>
            <dd class="col-sm-9">{{ url ? url : 'N/A'}}</dd>
          
            <dt class="col-sm-3 text-truncate">Comment</dt>
            <dd class="col-sm-9">{{ comment ? comment : 'N/A'}}</dd>
          
            <dt class="col-sm-3 text-truncate">Files</dt>
            <dd class="col-sm-9">
              <div *ngIf="fetching" class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="row col-12" *ngIf="!fetching && files.length > 0"> 
                <div class="col-2 m-2" *ngFor="let files of files; let i = index">
                  <a [href]="files.url" class="" target="_blank">
                    <i class="fa fa-file" style="font-size: 50px;" aria-hidden="true"></i>
                    <p class="text-wrap">{{ files.name }}</p>
                  </a>
                </div>
              </div>
              <div *ngIf="files.length === 0 && !fetching">N/A</div>
            </dd>
          </dl>
          <div>
            <form [formGroup]="form" autocomplete="off"> 
              <label clas for="reply"><strong><small>Response</small></strong> </label>
              <textarea class="form-control" id="reply" formControlName="reply" placeholder="..." rows="3"></textarea>
            </form>
          </div>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="sampleModal.hide(); closeModal()">Close</button>
          <button type="button" class="btn btn-primary" (click)="submit(id);  sampleModal.hide()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div> -->
      
      
      
      