import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, QueryFn, Query } from '@angular/fire/compat/firestore';
import { Output, EventEmitter } from '@angular/core';
import { first, tap,  } from 'rxjs/operators';
import {
  GovernmentAccountSubtype,
  WorklistStatusWeightPBRISRegulation,
  WorklistStatusPBRISRegulation,
  WorklistArtaViewGroupedStatusPBRISRegulation,
  WorklistArtaArtaMappedStatusPBRISRegulation,
  WorklistAgencyViewMappedStatusPBRISRegulation,
  WorklistAgencyViewGroupedStatusPBRISRegulation,
} from 'src/app/entities/worklist';
import { combineLatest } from 'rxjs';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { DatePipe } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-inbox-list',
  templateUrl: './inbox-list.component.html',
  styleUrls: ['./inbox-list.component.scss'],
})
export class InboxListComponent implements OnInit {

  //worklist_columns = ['dateGenerated','fromAgency','fromOfficer','dateSubmitted','title','status','due-date','action']
  worklist_columns = ['dateGenerated','fromAgency','title','due-date','status','action']
  worklistDataSource: MatTableDataSource<any>;
  
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  
  @Input() activateChild: boolean = false;
  @Input() activateObject: any = {};
  
  userType: any = sessionStorage.getItem('user_type');
  results: any = []; // main countainer for the inbox results list
  results_2: any = [];
  countDraft = 0;
  countVerification = 0;
  countRevision = 0;
  countAgencyReview = 0;
  countConsolidation = 0;
  countApproval = 0;
  countARTAReview = 0;
  countModification = 0;
  countPosting = 0;

  // TODO: Include Proposed Regulation
  // private worklistFilters = new Map<string, string[]>([
  //   [
  //     GovernmentAccountSubtype.AGENCY_ENC,
  //     [
  //       WorklistStatusPBRISRegulation.DRAFT,
  //       WorklistStatusPBRISRegulation.FOR_REVISION,
  //       WorklistStatusPBRISRegulation.FOR_APPROVAL,
  //       // Include FOR VERIFICATION, but do not let Encoders do anything
  //       WorklistStatusPBRISRegulation.FOR_VERIFICATION,
  //       WorklistStatusPBRISRegulation.FOR_RIA,
  //       WorklistStatusPBRISRegulation.DRAFT_DRIS,
  //       WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.AGENCY_VER,
  //     [
  //       WorklistStatusPBRISRegulation.DRAFT,
  //       WorklistStatusPBRISRegulation.FOR_APPROVAL,
  //       WorklistStatusPBRISRegulation.FOR_REVISION,
  //       WorklistStatusPBRISRegulation.FOR_VERIFICATION,
  //       WorklistStatusPBRISRegulation.FOR_RIA,
  //       WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.AGENCY_DIR,
  //     [
  //       WorklistStatusPBRISRegulation.DRAFT,
  //       WorklistStatusPBRISRegulation.FOR_REVISION,
  //       WorklistStatusPBRISRegulation.FOR_MODIFICATION,
  //       WorklistStatusPBRISRegulation.FOR_VERIFICATION,
  //       WorklistStatusPBRISRegulation.FOR_APPROVAL,
  //       WorklistStatusPBRISRegulation.RESUBMITTED,
  //       WorklistStatusPBRISRegulation.FOR_RIA,
  //       WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION,
  //       WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL,
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.ARTA_BRO_PO,
  //     [
  //       WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW,
  //       WorklistStatusPBRISRegulation.FOR_REAPPROVAL,
  //       WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.ARTA_BRO_DC,
  //     [
  //       WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC,
  //       WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.ARTA_BRO_DIR,
  //     [
  //         WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR,
  //         WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR,
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.ARTA_DDG,
  //     [
  //         WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG,
  //         WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG,
  //     ],
  //   ],
  //   [
  //     GovernmentAccountSubtype.ARTA_DG,
  //     [
  //         WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG,
  //         WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG,
  //     ],
  //   ]
  // ]);

  private agencyWorklistFilter = new Map<string, string[]>([
    WorklistAgencyViewMappedStatusPBRISRegulation.AGENCY_ENC,
    WorklistAgencyViewMappedStatusPBRISRegulation.AGENCY_VER,
    WorklistAgencyViewMappedStatusPBRISRegulation.AGENCY_DIR,
  ])

  // private artaWorklistFilters = new Map<string, string[]>([
  //   WorklistArtaArtaMappedStatusPBRISRegulation.ARTA,
  // ]);

  docu_id: any;
  reg_status: any;
  enum_regulation_status = WorklistStatusPBRISRegulation;

  @Output() toInboxComponent = new EventEmitter<object>();
  // Worklist-Existing Regulations or Worklist-Proposed Regulations
  @Input() regulationInboxWorklistCollectionName: string = '';

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('')
  })

  constructor(private afs: AngularFirestore, 
    public auth: AngularFireAuth,) {
  }

  ngOnInit(): void {
    if(this.activateChild == true && (this.activateObject.selection == "phase 2 existing" || this.activateObject.selection == "phase 2 proposed")){
      this.showOnInboxViewport(this.activateObject.regulationId, this.activateObject.worklistId)
    }
    else
      this.loadCounts2();
    // this.loadCounts2();
  }

  showOnInboxViewport(param: any, docuID: any) {
    // console.log('clicked',param,docuID)
    this.toInboxComponent.emit({
      param: param,
      docuID: docuID,
    });
    this.resetActiveValues()   
  }

  resetActiveValues(){
    this.activateChild = false
    this.activateObject = {}
  }

  async loadCounts() {
    let inboxFilter: QueryFn;
    
    if(sessionStorage.getItem("user_type") === "arta") {
      // const worklistFilterArr = this.artaWorklistFilters.get(
      //   sessionStorage.getItem('account_subtype') || ''
      // );
      // Do not include fromAgency if user_type is arta
      inboxFilter = (filter) =>
      filter
        .where('status', 'in', WorklistArtaViewGroupedStatusPBRISRegulation.ARTA)
        .orderBy('dueDate', 'asc')
    }
    else {
      const worklistFilterArr = this.agencyWorklistFilter.get(
        sessionStorage.getItem('account_subtype') || ''
      );
      inboxFilter = (filter) =>
      filter
        .where('fromAgency','==',sessionStorage.getItem("agency_name"))
        .where('status', 'in', worklistFilterArr)
        .orderBy('dueDate', 'asc')
    }
    
    //do not commit, comment out
    // inboxFilter = filter => filter.orderBy('dueDate', "asc")
    //do not commit, comment out

    this.afs
      .collection(
        this.regulationInboxWorklistCollectionName,
        inboxFilter
      )
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          // Need to reinitialize array(s) every time snapshotChanges fires off
          this.results = [];

          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            this.results.push(item);

            // TODO: Include Proposed Regulation
            switch (item.status) {
              case WorklistStatusPBRISRegulation.DRAFT:
                this.countDraft++;
                break;
              case WorklistStatusPBRISRegulation.FOR_VERIFICATION:
                this.countVerification++;
                break;
              case WorklistStatusPBRISRegulation.FOR_APPROVAL:
              case WorklistStatusPBRISRegulation.FOR_REAPPROVAL:
                this.countApproval++;
                break;
              case WorklistStatusPBRISRegulation.FOR_RIA:
              case WorklistStatusPBRISRegulation. DRIS_FOR_REVISION:
              case WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION:
              case WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL:
              case WorklistStatusPBRISRegulation.SUBMITTED_CRIS:
              case WorklistStatusPBRISRegulation.DRAFT_CRIS:
              case WorklistStatusPBRISRegulation.DRAFT_DRIS:
                this.countAgencyReview++;
                break;
              case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW:
              case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC:
              case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR:
              case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG:
              case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG:
              case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW:
              case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC:
              case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR:
              case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG:
              case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG:
              case WorklistStatusPBRISRegulation.DRIS_FOR_ASSESMENT:
                this.countARTAReview++;
                break;
              case WorklistStatusPBRISRegulation.FOR_REVISION:
              case WorklistStatusPBRISRegulation.RESUBMITTED:
              case WorklistStatusPBRISRegulation.FOR_MODIFICATION:
                this.countConsolidation++;
                break;
              case WorklistStatusPBRISRegulation.FOR_POSTING:
                this.countPosting++;
                break;
            }
            /**
             * load un-posted services for editing & approving
             */
          });
        })
      )
      .subscribe();
  }

  loadCounts2(){
    let batches:any = []
    if(sessionStorage.getItem("user_type") === "arta") {
      WorklistArtaViewGroupedStatusPBRISRegulation.ARTA.forEach(res=>{
        batches.push(this.dataGetter('arta',res))
      })
      this.loadData(combineLatest(batches))
    }
    // else if(sessionStorage.getItem('account_subtype') == 'Government - Agency Head'){
    //   WorklistAgencyViewGroupedStatusPBRISRegulation.AGENCY_DIR.forEach(res=>{
    //     batches.push(this.dataGetter('arta',res))
    //   })
    //   this.loadData(combineLatest(batches))
    // }
    else {
      const worklistFilterArr = this.agencyWorklistFilter.get(
        sessionStorage.getItem('account_subtype') || ''
      );
      if(worklistFilterArr && worklistFilterArr.length >= 10){
        worklistFilterArr?.forEach(res=>{
          batches.push(this.dataGetter('agency',res))
        })
        this.loadData(combineLatest(batches))
      }
      else {
        this.loadData(this.dataGetter('agency',worklistFilterArr))
      }
    }
  }

  loadData(data:any){
    this.results = [];
    this.results_2 = [];

    data.pipe(
      first(),
      tap(
      (results)=>{
        // console.log("load data 1")
        this.loadData2(results)
        this.results.sort((a:any,b:any)=>(a.dueDate > b.dueDate) ? -1 : ((b.dueDate > a.dueDate) ? 1 : 0))
        // console.log(this.results)
        this.worklistDataSource = new MatTableDataSource<any>(this.results)
        this.resetSandP();
      }
    )).subscribe();
  }

  loadData2(results:any){
    if(Array.isArray(results)){
      results.forEach((element:any)=>{
        this.loadData2(element)
      });


    }else{
      let item: any = results.payload.doc.data();
      item.id = results.payload.doc.id;
      item.status_weight = WorklistStatusWeightPBRISRegulation[item.status]
      this.results.push(item);
      // TODO: Include Proposed Regulation
      switch (item.status) {
        case WorklistStatusPBRISRegulation.DRAFT:
          this.countDraft++;
          break;
        case WorklistStatusPBRISRegulation.FOR_VERIFICATION:
          this.countVerification++;
          break;
        case WorklistStatusPBRISRegulation.FOR_APPROVAL:
        case WorklistStatusPBRISRegulation.FOR_REAPPROVAL:
          this.countApproval++;
          break;
        case WorklistStatusPBRISRegulation.FOR_RIA:
        case WorklistStatusPBRISRegulation. DRIS_FOR_REVISION:
        case WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION:
        case WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL:
        case WorklistStatusPBRISRegulation.SUBMITTED_CRIS:
        case WorklistStatusPBRISRegulation.DRAFT_CRIS:
        case WorklistStatusPBRISRegulation.DRAFT_DRIS:
          this.countAgencyReview++;
          break;
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG:
        case WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG:
        case WorklistStatusPBRISRegulation.DRIS_FOR_ASSESMENT:
          this.countARTAReview++;
          break;
        case WorklistStatusPBRISRegulation.RESUBMITTED:
        case WorklistStatusPBRISRegulation.FOR_REVISION:
        case WorklistStatusPBRISRegulation.FOR_MODIFICATION:
          this.countConsolidation++;
          break;
        case WorklistStatusPBRISRegulation.FOR_POSTING:
          this.countPosting++;
          break;
      }
    }
  }

  dataGetter(user:any,value:any){
    return this.afs.collection(this.regulationInboxWorklistCollectionName, filter => this.filterFunc(filter,user,value)).snapshotChanges()
  }

  filterFunc(filter:any,user:any,value:any){
    if(Array.isArray(value)){
      if(user == 'arta'){
        filter = filter
          .where('status', 'in', value)
          // .orderBy('dueDate', 'asc')
      }else{
        filter = filter
          .where('fromAgency','==',sessionStorage.getItem("agency_name"))
          .where('status', 'in', value)
          .orderBy('dueDate', 'asc')
      }
    }else{
      if(user == 'arta'){
        filter = filter
          .where('status', '==', value)
          // .orderBy('dueDate', 'asc')
      }else{
        filter = filter
          .where('fromAgency','==',sessionStorage.getItem("agency_name"))
          .where('status', '==', value)
          .orderBy('dueDate', 'asc')
      }
    }
    return filter
  }


  sortChange(sort: Sort){
    const data = this.results.slice()
    if(!sort.active || sort.direction === ''){
        this.results_2 = data
        return;
    }
    this.results_2 = data.sort((a:any,b:any)=>{
        const isAsc = sort.direction === 'asc'
        switch (sort.active){
            case 'dateGenerated':
              return this.compare(a.dateGenerated, b.dateGenerated, isAsc)
            //case 'dateSubmitted':
              //return this.compare(a.dateSubmitted, b.dateSubmitted, isAsc)
            case 'due-date':
              return this.compare(a.dueDate, b.dueDate, isAsc)
            case 'status':
              return this.compare(a.status_weight, b.status_weight, isAsc)
            case 'title':
              return this.compare(a.documentTitle.trim().toLowerCase(), b.documentTitle.trim().toLowerCase(), isAsc)
            //case 'fromOfficer':
              //return this.compare(a.fromOfficer.trim().toLowerCase(), b.fromOfficer.trim().toLowerCase(), isAsc)
            case 'fromAgency':
              return this.compare(a.fromAgency.trim().toLowerCase(), b.fromAgency.trim().toLowerCase(), isAsc)
            case 'action':
              return this.compare(a.documentTitle.trim().toLowerCase(), b.documentTitle.trim().toLowerCase(), isAsc)
            default:
              return 0
        }
    })
    this.worklistDataSource = new MatTableDataSource<any>(this.results_2)
    this.resetSandP()
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  search(event:Event){
    let searchValue = (event.target as HTMLInputElement).value
    this.worklistDataSource.filter = searchValue.trim().toLowerCase()
  }

  resetSandP(){
    this.worklistDataSource.paginator = this.paginator
    this.worklistDataSource.sort = this.sort
    this.settingFilters()
  }

  settingFilters(){
    this.worklistDataSource.filterPredicate = (option:any, searchValue:string): boolean =>{
      let dateGenerated = option.dateGenerated ? option.dateGenerated.toDate() : ''
      let transformedG = new DatePipe('en-US').transform(dateGenerated,'MMMM d, y h:mm:ss')
      let stringGeneratedDate = transformedG?.toString()

      let dateSubmitted = option.dateSubmitted ? option.dateSubmitted.toDate() : ''
      let transformedS = new DatePipe('en-US').transform(dateSubmitted,'MMMM d, y h:mm:ss')
      let stringSubmittedDate = transformedS?.toString()

      let duesDate = option.dueDate ? option.dueDate.toDate() : ''
      let transformedD = new DatePipe('en-US').transform(duesDate,'MMMM d, y h:mm:ss')
      let stringDueDate = transformedD?.toString()
      if(searchValue){
        return (option.fromOfficer)?.trim().toLowerCase().includes(searchValue)
        || (option.fromAgency)?.trim().toLowerCase().includes(searchValue)
        || (option.documentTitle)?.trim().toLowerCase().includes(searchValue)
        || (option.status)?.trim().toLowerCase().includes(searchValue)
        || (transformedG)?.trim().toLowerCase().includes(searchValue)
        || (transformedS)?.trim().toLowerCase().includes(searchValue)
        || (transformedD)?.trim().toLowerCase().includes(searchValue)
        || (stringGeneratedDate)?.trim().toLowerCase().includes(searchValue)
        || (stringSubmittedDate)?.trim().toLowerCase().includes(searchValue)
        || (stringDueDate)?.trim().toLowerCase().includes(searchValue)
      }else return false
    }
  }

  refreshList(){
    this.results = []
    this.results_2 = []
    this.resetCounter();
    this.searchForm.patchValue({keyword:''})
    this.worklistDataSource = new MatTableDataSource<any>(this.results)
    this.resetSandP()
    this.loadCounts2()
  }

  resetCounter(){
    this.countDraft = 0
    this.countVerification = 0
    this.countApproval = 0
    this.countAgencyReview = 0
    this.countARTAReview = 0
    this.countConsolidation = 0
    this.countPosting = 0
  }

  // goToRegulation(){
  //   this.toInboxComponent.emit("hi");
  // }
}
