import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-home-page-footer-banner',
  templateUrl: './artemis-home-page-footer-banner.component.html',
  styleUrls: ['./artemis-home-page-footer-banner.component.scss']
})
export class ArtemisHomePageFooterBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
