import { Component, HostListener, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { combineLatest, Observable, of } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import {
  atLeastOne,
  regulationRequiredByClassification,
} from 'src/app/validators';
import { DecimalPipe } from '@angular/common';
import { format } from 'date-fns';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first } from 'rxjs/operators';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { WorklistService } from 'src/app/service/worklist-service.service';

enum EntityBuilderIndex {
  PIA_RIA_TIMELINE_ACTIVITY = 5,
}

@Component({
  selector: 'app-pbris-regulatory-notif-form',
  templateUrl: './pbris-regulatory-notif-form.component.html',
  styleUrls: ['./pbris-regulatory-notif-form.component.scss']
})
export class PbrisRegulatoryNotifFormComponent implements OnInit {
  loading: boolean = false;
  isPhase1: boolean = true;

  agencies: any[] = [];
  sectors: any[] = [];
  sobs: any[] = [];
  divisions: any[] = [];
  divisions_select: any[] = [];
  jurisdictions: any[] = [];
  cases: any[] = [];
  cases_select: any[] = [];
  instruments: any[] = [];
  sols: any[] = [];
  lifeevents: any[] = [];
  lifeevents_select: any[] = [];
  loader: boolean = true;

  multipleIssuingAgency: boolean = false;

  notification_id:String;

  checkRegulatoryNotificationForm = {
    part_of_reg_plan:[
      { type: "required", message: "Regulatory Planning is required" },
    ],
    reg_action_classification:[
      { type: "atLeastOne", message: "At least one regulatory action is required" },
    ],
    title:[
      { type: "required", message: "Regulation Title is required" },
    ],
    subject:[
      { type: "required", message: "Regulation Short Title is required" },
    ],
    reg_agency:[
      { type: "required", message: "Issuing agency/ies is required" },
    ],
    reg_instrument:[
      { type: "required", message: "Regulation Instrument is required" },
    ],
    has_consultation_comments:[
      { type: "required", message: "Subjected to stakeholder consultation and comments is required" },
    ],
    reg_notif_form:{
      reg_classification:[
        { type: "required", message: "Classification is required" },
      ],
      reg_sector:[
        { type: "required", message: "Sector is required" },
      ],
      reg_division:[
        { type: "required", message: "Division is required" },
      ],
      reg_business:[
        { type: "required", message: "Division is required" },
      ],
      reg_case:[
        { type: "required", message: "Case Use is required" },
      ],
      reg_stageoflife:[
        { type: "required", message: "Stage of life is required" },
      ],
      reg_lifeevent:[
        { type: "required", message: "Life event is required" },
      ],
      reg_problem:[
        { type: "required", message: "Problem being addressed is required" },
      ],
      reg_juris:[
        { type: "required", message: "Jurisdiction is required" },
      ],
      doc_date:[
        { type: "required", message: "Proposal date invalid" },
      ],
      reg_notif_form:[
        { type: "regulationRequiredByClassification", message: "Classification is required" },
      ]
    },
    concudted_consultations:{
      activity_title:[
        { type: "required", message: "Activity title is required" },
      ],
      start_date:[
        { type: "required", message: "Start date invalid" },
      ],
      end_date:[
        { type: "required", message: "End date invalid" },
      ],
    },
  }

  regnotifangularform = new UntypedFormGroup({
    // true or false, but needs to be answered so initialize as null
    // Initialize as false first until plan collection is accomplished
    part_of_reg_plan: new UntypedFormControl(false, Validators.required),
    // Not to be mistaken with reg_classification
    reg_action_classification: new UntypedFormGroup(
      {
        reg_action_new: new UntypedFormControl(false),
        reg_action_modification: new UntypedFormControl(false),
        reg_action_repeal: new UntypedFormControl(false),
      },
      { validators: [atLeastOne(Validators.requiredTrue)] }
    ),

    //date

    // Regulation Details
    subject: new UntypedFormControl('', Validators.required),
    title: new UntypedFormControl('', Validators.required),
    reg_agency: new UntypedFormControl((sessionStorage.getItem("agency_id") || ''), Validators.required),
    reg_instrument: new UntypedFormControl('', Validators.required),
    has_consultation_comments: new UntypedFormControl(null, Validators.required),

    // Removed for proposed regulations but might be reinstated
    // file_file_name: new FormControl('', Validators.required),
    // firebase_folder: new FormControl('regulationsProposedPhase02'),

    reg_notif_form: new UntypedFormGroup(
      {
        reg_problem: new UntypedFormControl('', Validators.required),
        reg_classification: new UntypedFormControl('Business'),

        // Business only
        reg_sector: new UntypedFormControl('', [Validators.required]),
        reg_business: new UntypedFormControl('', [Validators.required]),
        reg_division: new UntypedFormControl(''),
        reg_case: new UntypedFormControl('', [Validators.required]),
        // Non-Business only
        reg_stageoflife: new UntypedFormControl(''),
        reg_lifeevent: new UntypedFormControl(''),

        reg_juris: new UntypedFormControl('', Validators.required),
        pia_ria_timeline: new UntypedFormArray([]),
        reg_remarks: new UntypedFormControl(''),
        doc_date: new UntypedFormControl(
          format(new Date(), 'yyyy-MM-dd'),
          Validators.required
        ),
      },
      {
        validators: [regulationRequiredByClassification()],
      }
    ),
  });

  active_pia_ria_timeline_form: any;
  active_pia_ria_timeline_index: number = -1;

  get pia_ria_timeline_val() {
    return this.regnotifangularform
      .get('reg_notif_form')
      ?.get('pia_ria_timeline') as UntypedFormArray;
  }

  get reg_action_classification_val() {
    return this.regnotifangularform.get(
      'reg_action_classification'
    ) as UntypedFormGroup;
  }

  // form
  currentFormPage: string = '';
  previewImg: any = null;

  private currentRegulation:any;
  
  proposedRegulationFile = [
    {title:'Regulatory Notification Form (page 1 of 2)', code:'rnf1', path:'regulationdetails'},
    {title:'Regulatory Notification Form (page 2 of 2)', code:'rnf2', path:'regulatorynotificationform'},
  ]

  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute,
    public decimalPipe: DecimalPipe,
    public auth: AngularFireAuth,
    public worklistService: WorklistService,
    private nE: NotificationsEmailer,
    private fB: UntypedFormBuilder,
  ) {

    this.loadFormDependentReferences();

    this.pia_ria_timeline_val.push(
      this.buildNewEntry(EntityBuilderIndex.PIA_RIA_TIMELINE_ACTIVITY)
    );

    // First page when encoding
    this.currentFormPage = 'preface';

    // Disable reg_agency when encoding because default reg_instrument value does not contain "joint"
    this.regnotifangularform.controls.reg_agency.disable();
  }

  ngOnInit(): void {
    this.loadFormIndependentReferences();

    // Force scroll to top so user can read the preface for PIS
    // Consider creating a service or routing listener for this
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // reg_business
    // reg_division
    // reg_case
    // reg_stageoflife
    // reg_lifeevent
    // reg_juris
    // reg_classification
    this.valueChangeChecker1('reg_classification');

    this.valueChangeChecker1('reg_sector');
    this.valueChangeChecker1('reg_business');
    this.valueChangeChecker1('reg_division');
    this.valueChangeChecker1('reg_case');

    this.valueChangeChecker1('reg_stageoflife');
    this.valueChangeChecker1('reg_lifeevent');
    
    this.valueChangeChecker1('reg_juris');
  }

  valueChangeChecker1(control2:any){
    this.valueChangeChecker2('reg_notif_form',control2)
  }

  valueChangeChecker2(control1:any,control2:any){
    ((this.regnotifangularform.controls[control1] as UntypedFormGroup).controls[control2] as UntypedFormControl).valueChanges
    .subscribe({
      next:(value)=>{
        let tempArr:any[] = [];
        if(control2 == 'reg_sector') tempArr = this.sectors
        if(control2 == 'reg_division') tempArr = this.divisions_select
        if(control2 == 'reg_business') tempArr = this.sobs
        if(control2 == 'reg_case') tempArr = this.cases_select

        if(control2 == 'reg_stageoflife') tempArr = this.sols
        if(control2 == 'reg_lifeevent') tempArr = this.lifeevents_select

        if(control2 == 'reg_juris') tempArr = this.jurisdictions

        
        let control3:any = 'reg_notif_form';

        if(tempArr.length > 0 && control2 != 'reg_classification'){
          const dataCtr = tempArr.map((data)=>data.id).indexOf(value)
          if(dataCtr >= 0){
            if(((this.regnotifangularform.controls[control3] as UntypedFormGroup).controls[control2] as UntypedFormControl).value != value)
              ((this.regnotifangularform.controls[control3] as UntypedFormGroup).patchValue({[control2]:value}));
          }
        }else if(control2 == 'reg_classification'){
          switch (value) {
            case 'Business':
              // Empty Non-Business fields
              if(((this.regnotifangularform.controls[control3] as UntypedFormGroup).controls['reg_classification'] as UntypedFormControl).value != 'Business')
                this.regnotifangularform.controls[control3].patchValue({reg_classification:'Business'})
                this.regnotifangularform.controls[control1].patchValue({
                  reg_sector: '',
                  reg_business: '',
                  reg_division: '',
                  reg_case: '',
                  reg_stageoflife: '',
                  reg_lifeevent: '',
                });
                this.businessValidators('reg_notif_form')
              break;
            case 'Non-Business':
              // Empty Business fields
              if(((this.regnotifangularform.controls[control3] as UntypedFormGroup).controls['reg_classification'] as UntypedFormControl).value != 'Non-Business')
                this.regnotifangularform.controls[control3].patchValue({reg_classification:'Non-Business'})
                this.regnotifangularform.controls[control1].patchValue({
                  reg_sector: '',
                  reg_business: '',
                  reg_division: '',
                  reg_case: '',
                  reg_stageoflife: '',
                  reg_lifeevent: '',
                });
                this.nonBusinessValidators('reg_notif_form')
              break;
          }
        }
      }
    });
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    // function must return false i guess if user proceeds to pia?
    return true
  }

  /**
   *  ________________________
   * |                        |
   * |        Reminder        |
   * |________________________|
   *
   * Please move all firestore references
   * to 'References'=>'common/PBRIS/ARTEMIS'=>...
   *
   */

   loadFormIndependentReferences() {
    this.loadAgencies();
    this.loadSectors();
    this.loadSobs();
    this.loadJuris();
    this.loadSols();
  }

  loadFormDependentReferences() {
    this.loadInstruments();
    this.loadDivisions();
    this.loadCases();
    this.loadLifeEvents();
  }

  loadAgencies() {
    this.afs
      .collection(`Agency`)
      .snapshotChanges()
      .subscribe((data: any) => {
        this.loader = false;
        this.agencies = [];
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.agencies.includes(item) === false) {
            this.agencies.push(item);
          }
        });
      });
  }

  loadSectors() {
    this.afs
      .collection(`Sector`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.sectors = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.sectors.includes(item) === false) {
            this.sectors.push(item);
          }
        });
      });
  }

  loadSobs() {
    this.afs
      .collection(`Stage of Business`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.sobs = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.sobs.includes(item) === false) {
            this.sobs.push(item);
          }
        });
      });
  }

  loadSols() {
    this.afs
      .collection(`Stage of Life`)
      .snapshotChanges()
      .subscribe((data: any) => {
        this.sols = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.sols.includes(item) === false) {
            this.sols.push(item);
          }
        });
      });
  }

  loadLifeEvents() {
    this.afs
      .collection(`Life Event`)
      .snapshotChanges()
      .subscribe((data: any) => {
        this.lifeevents = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.lifeevents.includes(item) === false) {
            this.lifeevents.push(item);
          }
        });
      });
  }

  loadDivisions() {
    this.afs
      .collection(`Division`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.divisions = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.divisions.includes(item) === false) {
            this.divisions.push(item);
          }
        });
      });
  }

  loadJuris() {
    this.afs
      .collection(`Jurisdiction`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.jurisdictions = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.jurisdictions.includes(item) === false) {
            this.jurisdictions.push(item);
          }
        });
      });
  }

  loadCases() {
    this.afs
      .collection(`Case Use`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.cases = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.cases.includes(item) === false) {
            this.cases.push(item);
          }
        });
      });
  }

  loadInstruments() {
    this.afs
      .collection(`Instrument`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe((data: any) => {
        this.instruments = [];
        this.loader = false;
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.instruments.includes(item) === false) {
            this.instruments.push(item);
          }
        });

        // Set multipleIssuingAgency during loading
        // Now a "form-dependent reference data call, so put it inside the subscribe"
        this.updateIssuingAgency(this.regnotifangularform.value.reg_instrument);
      });
  }

  instrumentOnChange(event: any) {
    this.updateIssuingAgency(event.target.value);
  }

  private updateIssuingAgency(instrumentVal: string) {
    // For copying to other pages
    const useForm = this.regnotifangularform;

    // Handle blank reg_instrument
    if(!instrumentVal) {
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      this.baseSelectOnChange1('reg_agency')
      return;
    }

    let instrument = this.instruments.find((option) => option.id === instrumentVal).name.toLowerCase();
    this.multipleIssuingAgency = instrument.includes('joint');
    // Force reg_agency values after switching reg_instrument value
    this.baseSelectOnChange1('reg_instrument')
    if(this.multipleIssuingAgency) {
      // joint: reg_agency is an array
      useForm.controls.reg_agency.enable();
      useForm.patchValue({
        reg_agency: [sessionStorage.getItem("agency_id") || '']
      });
      this.baseSelectToUntouchOnChange1('reg_agency')
    }
    else {
      // non-joint: reg_agency is a string
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      this.baseSelectOnChange1('reg_agency')
    }
  }

  sectorOnChange(event: any) {
    let section = this.sectors.find((option) => option.id === event.target.value).section.toLowerCase();
    this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
    this.selectOnChange1('reg_sector');
    this.selectToUntouchOnChange1('reg_division');
  }

  sobOnChange(event: any) {
    let section = this.sobs.find((option) => option.id === event.target.value).section.toLowerCase();
    this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
    this.selectOnChange1('reg_business');
    this.selectToUntouchOnChange1('reg_case');
  }

  solOnChange(event: any) {
    let stageOfLife = this.sols.find((option) => option.id === event.target.value).stage.toLowerCase();
    this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
    this.selectOnChange1('reg_stageoflife');
    this.selectToUntouchOnChange1('reg_lifeevent');
  }

  selectOnChange1(formControl: string){
    this.selectOnChange2('reg_notif_form',formControl);
  }

  selectOnChange2(controlGroup: string, formControl: string){
    if(this.regnotifangularform && (this.regnotifangularform.controls[controlGroup] as UntypedFormGroup) && ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl)){
      ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).markAsTouched();
      ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).updateValueAndValidity();
    }
  }

  selectToUntouchOnChange1(formControl: string){
    this.selectToUntouchOnChange2('reg_notif_form',formControl);
  }

  selectToUntouchOnChange2(controlGroup: string, formControl: string){
    if(this.regnotifangularform && (this.regnotifangularform.controls[controlGroup] as UntypedFormGroup) && ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl)){
      ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).markAsUntouched();
      ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls[formControl] as UntypedFormControl).updateValueAndValidity();
    }
  }

  baseSelectOnChange1(formControl: string){
    (this.regnotifangularform.controls[formControl] as UntypedFormControl).markAsTouched();
    (this.regnotifangularform.controls[formControl] as UntypedFormControl).updateValueAndValidity();
  }

  baseSelectToUntouchOnChange1(formControl: string){
    (this.regnotifangularform.controls[formControl] as UntypedFormControl).markAsUntouched();
    (this.regnotifangularform.controls[formControl] as UntypedFormControl).updateValueAndValidity();
  }

  togglePreview() {
    if (this.isPhase1) {
      this.isPhase1 = false;
    } else {
      this.isPhase1 = true;
    }
  }

  async submitNotificationForm() {
    this.saveAndUpload();
  }

  async saveAndUpload() {
    let saveAndUploadPass = true

    this.validateFields()
    if(this.regnotifangularform.invalid) saveAndUploadPass = false
    if(this.loading) saveAndUploadPass = false

    if(saveAndUploadPass){
      const batch = this.afs.firestore.batch(); // batch uploader, firestore
  
      this.loading = true;
      const user = await this.auth.currentUser;
      let fromAgency = sessionStorage.getItem('agency_name');
      let fromOfficer = user?.displayName;
  
      let notif_form_ref: any;

      notif_form_ref = this.afs.firestore
        .collection(`regulation-proposed-notifications`)
        .doc();

      const toRegulationNotif = this.regnotifangularform.value;

      // Gotcha: disabled form controls will not be part of form.value, similar to disabled input tags in a form tag
      // You need to get values from a disabled form control through controls['controlname'].value
      toRegulationNotif.reg_agency = this.regnotifangularform.controls.reg_agency.value;
      
      // const user = await this.auth.currentUser;
      // let fromAgency = sessionStorage.getItem('agency_name');
      // let fromOfficer = user?.displayName;
  
      let proposedRegulationNotifJSON ={
        user_details:{
          dateGenerated: Timestamp.now().toDate(),
          fromAgency: fromAgency,
          fromOfficer: fromOfficer,
        },
        
        part_of_reg_plan: toRegulationNotif.part_of_reg_plan,
        title: toRegulationNotif.title,
        subject: toRegulationNotif.subject,
        reg_instrument: toRegulationNotif.reg_instrument,
        reg_agency: toRegulationNotif.reg_agency,
        reg_action_classification: {
            reg_action_new: toRegulationNotif.reg_action_classification.reg_action_new,
            reg_action_modification: toRegulationNotif.reg_action_classification.reg_action_modification,
            reg_action_repeal: toRegulationNotif.reg_action_classification.reg_action_repeal,
        },
        has_consultation_comments: toRegulationNotif.has_consultation_comments,
        reg_notif_form: {
            reg_problem: toRegulationNotif.reg_notif_form.reg_problem,
            reg_classification: toRegulationNotif.reg_notif_form.reg_classification,
            reg_sector: toRegulationNotif.reg_notif_form.reg_sector,
            reg_business: toRegulationNotif.reg_notif_form.reg_business,
            reg_stageoflife: toRegulationNotif.reg_notif_form.reg_stageoflife,
            reg_lifeevent: toRegulationNotif.reg_notif_form.reg_lifeevent,
            reg_division: toRegulationNotif.reg_notif_form.reg_division,
            reg_juris: toRegulationNotif.reg_notif_form.reg_juris,
            reg_case: toRegulationNotif.reg_notif_form.reg_case,
            pia_ria_timeline: toRegulationNotif.reg_notif_form.pia_ria_timeline,
            reg_remarks: toRegulationNotif.reg_notif_form.reg_remarks,
            doc_date: toRegulationNotif.reg_notif_form.doc_date,
        },
      }
      // console.log("at model: ", proposedRegulationNotif)
      const new_notif = await this.afs.collection('regulation-proposed-notifications').add(proposedRegulationNotifJSON);
      this.notification_id = new_notif.id;
    
      console.log("at model: ", this.notification_id)

      batch.set(notif_form_ref, proposedRegulationNotifJSON);
  
      await batch
        .commit()
        .then(() => {
          alert('create successful');
          this.forReturnToRegulatoryManagement() //change to reg notif, maybe consider autoviewing
          this.router.navigate(['/pbris/regulatory-management']);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          alert(error.message);
        });
      // !uncomment -------------------------------
    }
    // else{
    //   alert('Unable to Submit, Please finishing filling up the missing details!')
    // }
  }

  async gotoForm(selectedForm: any) {
    /**
     * step 1 : regulationdetails
     * step 2 : pia
     * step 3 : tagging
     * step 4 : regulationtext
     * step 5 : selfassessment
     */

     this.currentFormPage = selectedForm;
  }

  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.PIA_RIA_TIMELINE_ACTIVITY:
        return new UntypedFormGroup({
          activity_title: new UntypedFormControl(existing_obj?.activity_title || 'New Entry',[Validators.required]),
          start_date: new UntypedFormControl(existing_obj?.start_date || format(new Date(), 'yyyy-MM-dd'),[Validators.required]),
          end_date: new UntypedFormControl(existing_obj?.end_date || format(new Date(), 'yyyy-MM-dd'),[Validators.required]),
        });
      default:
        return new UntypedFormGroup({});
    }
  }

  addPIARIATimeline() {
    this.pia_ria_timeline_val.push(
      this.buildNewEntry(EntityBuilderIndex.PIA_RIA_TIMELINE_ACTIVITY)
    );
  }

  removePIARIATimeline(index: any) {
    this.pia_ria_timeline_val.removeAt(index);
    if (index === this.active_pia_ria_timeline_index) {
      this.active_pia_ria_timeline_form = null;
      this.active_pia_ria_timeline_index = -1;
    } else {
      // Handle index shifts, object equivalency seems to work for FormGroups
      for (var x in this.pia_ria_timeline_val.controls) {
        if (
          this.pia_ria_timeline_val.controls[x] ==
          this.active_pia_ria_timeline_form
        ) {
          // Unary plus to convert var x in (which is a string) to TS number
          this.active_pia_ria_timeline_index = +x;
          break;
        }
      }
    }
  }

  loadPIARIATimelineInformation(entry: any, index: any) {
    this.active_pia_ria_timeline_form = entry;
    this.active_pia_ria_timeline_index = index;
  }
  
  toCancel(){
    this.uploadsReturnToRegulatoryManagement()
    this.router.navigate(['/pbris/regulatory-management'])
  }
  
  uploadsReturnToRegulatoryManagement(){
    sessionStorage.setItem('regulatoryActive','regulatoryStock')
  }
  
  forReturnToRegulatoryManagement(){
    sessionStorage.setItem('regulatoryActive','regulation notification')
  }

  // error handlers
  isTouched(form:UntypedFormGroup ,controlName:string, controlGroup?:string,){
    if(controlGroup){
      return ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched;
    }
    else{
      return (form.get(controlName) as UntypedFormControl).touched;
    }
  }

  hasValue(form:UntypedFormGroup ,controlName:string, controlGroup?:string,){
    if(controlGroup){
      return (((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).value != ''
        && ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).value != undefined
        && ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).value != null);
    }
    else{
      return ((form.get(controlName) as UntypedFormControl).value != ''
        && (form.get(controlName) as UntypedFormControl).value != undefined
        && (form.get(controlName) as UntypedFormControl).value != null);
    }
  }

  validateFields(){
    if(!this.regnotifangularform.valid){
      
      for (const control in this.regnotifangularform.controls) {
        if (this.regnotifangularform.controls.hasOwnProperty(control)) {
            this.regnotifangularform.controls[control].markAllAsTouched();
            this.regnotifangularform.controls[control].markAsDirty();
        }
      }
    }
  } 
 
  hasErrors(form:UntypedFormGroup, controlName:string, validType:string, controlGroup?:string, ){
    if(controlGroup){
      return (((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).hasError(validType) 
      && (((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).dirty 
      || ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched))
    }
    else{
      return ((form.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((form.get(controlName) as UntypedFormControl).dirty 
      || (form.get(controlName) as UntypedFormControl).touched))
    }
  }

  arrayIsTouched(controlName:any){
    return controlName.touched;
  }

  arrayHasErrors(controlName:any, validType:string){
    return controlName.hasError(validType) 
    && (controlName.dirty 
    || controlName.touched)
  }
  // !error handlers

  async getDetailsForEmailer(worklist_id:any){
    const [worklist, user]: any  = await Promise.all([this.getWorklist(worklist_id),this.auth.currentUser])
    // console.log('worklist',worklist)
    if(worklist){
      const agency:any = await this.getAgency(worklist.fromAgency)
      // console.log('agency',agency)
      if(agency){
        // const user = await this.auth.currentUser;
        // console.log('user',user)
        if(user && user.email){
          let userData = {
            regulationTitle: this.currentRegulation.title,
            agencyTitle: agency.agencyDetails ? agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name : agency.name,
          }
          // this.toSendNotifEmail([user.email],84,userData)
        }
      }
    }
  }

  getAgency(agencyCode:any){
    return new Promise((res, rej)=>{
      let batches = []
      let batchCode:any = []
      batches.push(this.afs.collection('Agency').doc(agencyCode).snapshotChanges())
      batchCode.push('id')

      batches.push(this.afs.collection('Agency', filter=> filter.where('agencyDetails.agencyName','==',agencyCode)).snapshotChanges())
      batchCode.push('name')

      batches.push(this.afs.collection('Agency', filter=> filter.where('name','==',agencyCode)).snapshotChanges())
      batchCode.push('name')

      combineLatest(batches)
      .pipe(first())
      .subscribe({
        next:(result)=>{
          let ctr = 0
          let agency:any;
          result.every((content:any,ctr:number)=>{
            if(batchCode[ctr] == 'id'){
              if(content.payload.exists){
                agency = content.payload.data()
                agency.id = content.payload.id
                return false
              }
            }
            else if(batchCode[ctr] == 'name'){
              content.every((item:any)=>{
                if(item.payload.doc.exists){
                  agency = item.payload.doc.data()
                  agency.id = item.payload.doc.id
                  return false
                }
                return true
              })
              if(agency) return false
            }
            return true
          })
          if(agency) return res(agency)
          return res(false)
        },
        error:(err)=>{
          console.error('getAgency error: ',err)
          return res(false)
        }
      })
    })
  }

  getWorklist(worklist_id:any){
    return new Promise((res,rej)=>{
      this.afs.collection(`Worklist-Proposed Regulations`).doc(worklist_id).snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result:any)=>{
          let worklist:any = result.payload.data()
          worklist.id = result.payload.id
          return res(worklist)
        },
        error:(err:any)=>{
          console.error('worklist error: ',err)
          return res(false)
        }
      })
    })
  }
  
  toSendNotifEmail(currentUsers:any[], templateCode:number,data:any){
    // console.log('sending email: ',currentUsers, templateCode)
    // if(templateCode > 0){
    //   this.nE.sendEmailNotiftoUser(currentUsers,templateCode,data)
    //   .subscribe({
    //       next:(apiResponse)=>{
    //           console.log('RegulationViewExistingComponent emailer: ',apiResponse)
    //       },
    //       error:(err)=>{
    //           console.error('RegulationViewExistingComponent emailer error: ',err)
    //       }
    //   })
    // } else console.error('RegulationViewExistingComponent emailer error: no email template for account type')
  }

  classificationChange(event:any){
    if(event){
      if(event.value == 'Business'){
        this.businessValidators('reg_notif_form')
      }else if(event.value == 'Non-Business'){
        this.nonBusinessValidators('reg_notif_form')
      }
    }
  }

  businessValidators(controlGroup:string){
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).setValidators([Validators.required]);
    // ((this.proposedregulationform.controls[controlGroup] as FormGroup).controls.reg_division as FormControl).setValidators([Validators.required]);
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).setValidators([Validators.required]);
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).setValidators([Validators.required]);
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).clearValidators();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).clearValidators();

    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  nonBusinessValidators(controlGroup:string){
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).setValidators([Validators.required]);
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).setValidators([Validators.required]);
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).clearValidators();
    // ((this.proposedregulationform.controls[controlGroup] as FormGroup).controls.reg_division as FormControl).clearValidators();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).clearValidators();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).clearValidators();

    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_division as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_business as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_case as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    ((this.regnotifangularform.controls[controlGroup] as UntypedFormGroup).controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  proposedRegulationChecker(code:any){
    if(code == 'rnf1'){
      return ( 
        (this.regnotifangularform.controls.part_of_reg_plan as UntypedFormControl).valid
        && (this.regnotifangularform.controls.title as UntypedFormControl).valid
        && (this.regnotifangularform.controls.subject as UntypedFormControl).valid
        && ((this.regnotifangularform.controls.reg_instrument as UntypedFormControl).valid
          && ((this.multipleIssuingAgency && (this.regnotifangularform.controls.reg_agency as UntypedFormControl).valid)
              || (!this.multipleIssuingAgency)
          )
        )
        && (this.regnotifangularform.controls.reg_action_classification as UntypedFormControl).valid
        && (this.regnotifangularform.controls.has_consultation_comments as UntypedFormControl).valid
      )
    }
    else if(code == 'rnf2'){
      return ( 
        (this.regnotifangularform.controls.reg_notif_form as UntypedFormGroup).valid
      )
    }
    return false
  }
}
