<ng-container *ngIf="show_form">
    <form [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
          </button>
                <h4 class="modal-title w-100" id="myModalLabel">{{ title }}</h4>
            </div>
            <div class="modal-body">

                <div class="form-group">
                    <label class="form-label" for="title">Title</label>
                    <input type="text" class="form-control" id="title" aria-describedby="title" formControlName="title" ng-required='true'>
                </div>
                <div class="form-group">
                    <label class="form-label" for="tag">Tag</label>
                    <select class="form-control" id="tag" aria-describedby="tag" formControlName="tag" ng-required='true'>
                        <option value = "common">Common</option>
                        <option value = "pbris">PBRIS</option>
                        <option value = "artemis">ARTEMIS</option>
                        
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-label" for="category">Category</label>
                    <select class="form-control" id="category" aria-describedby="category" formControlName="category" ng-required='true'>
                        <option value = "general_announcement">General Announcement</option>
                        <option value = "maintenance">Maintenance</option>
                        <option value = "advisory">Advisory</option>
                        
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-label" for="description">Description</label>
                    <textarea class="form-control" id="description" formControlName="description"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="reset" class="btn btn-light">Reset</button>
                    <button *ngIf="method == 'create'" (click)="create()" class="btn btn-dark m-1" [hidden]="!form.valid">Create</button>
                    <button *ngIf="method == 'update'" (click)="update()" class="btn btn-dark m-1" [hidden]="!form.valid">Update</button>
                </div>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="del_confirm">
    <div class="modal-content">
        <div class="modal-header">

            <h4 class="modal-title w-100" id="myModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body">
            <p>Are you sure you want to delete it? Once deleted, it cannot be undone.</p>
        </div>
        <div class="modal-footer">
            <div class="btn-group">
                <button (click)="modalRef.hide()" class="btn btn-dark m-1">Cancel</button>
                <button (click)="delete()" class="btn btn-danger m-1">Delete</button>
            </div>
        </div>
    </div>
</ng-container>