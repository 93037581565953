import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import {Observable, of} from 'rxjs';
import { map, startWith, first, debounceTime, flatMap } from 'rxjs/operators';
import {Query, QueryFn } from '@angular/fire/compat/firestore';
import { Subject  } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-artemis-search-results-page',
  templateUrl: './artemis-search-results-page.component.html',
  styleUrls: ['./artemis-search-results-page.component.scss']
})
export class ArtemisSearchResultsPageComponent implements  OnInit, OnDestroy {

  // @ViewChild(DataTableDirective, {static: false})
  
  loading:boolean = true;

  isAdmin: boolean = false;

  userType: any = sessionStorage.getItem("user_type");

  formInitialParams = {
    keyword: "",
    year: "",
    agency: "",
    sector: "",
    service: "",
    classification: "",
    agencycategory: "",
    servicecategory: "",
  };
  form: UntypedFormGroup = new UntypedFormGroup({
    agencycategory: new UntypedFormControl(''),
    // agency: new FormControl(''),
    agency: new UntypedFormControl('', Validators.required),
    sector: new UntypedFormControl(''),
    classification: new UntypedFormControl(''),
    servicecategory: new UntypedFormControl(''),
    service: new UntypedFormControl(''),
    year: new UntypedFormControl(''),
    keyword: new UntypedFormControl(''),
  });

  checkSearchForm={
    agency:[
      {type: 'required', message: "Agency Name is required"}
    ]
  }

  /**
   * Firebase variables
   */
  private servicesRef: any;
  results: any = []; // main countainer for the search results list

  fieldLOVagencyCategory: any = []; // List of Agency Categories
  agencyCategoryList:any = [];
  filteredAgencyCategory: Observable<any[]>;

  fieldLOVagency: any = []; // List of Agencies
  filteredAgency: Observable<any[]>;
  agencyList : any = []

  fieldLOVsector: any = []; // List of Sectors
  filteredSector: Observable<any[]>;
  sectorList : any = []

  fieldLOVclassification: any = []; // List of Classifications
  filteredClassification: Observable<any[]>;
  classificationList:any = []

  fieldLOVserviceCategory: any = []; // List of Service Categories
  filteredServiceCategory: Observable<any[]>;

  fieldLOVservice: any = []; // List of Services
  // filteredService: Observable<any[]>;
  filteredService: any = [];

  fieldLOVyear: any = []; // List of Years
  allYears:any = []
  
  status: string = "";

  /**
   * Other variables
   */
  resultscount: any;
  tempdata: any;

  /**
   * query output variables
   */
  searchQueryJSON: any;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstanceTriggered:boolean = false

  totalRegulationsNum : number = 0;
  currentRegulationNum : number = 0;
  currentRegulationInPercent: number = 0;
  servicesLoading : boolean = true

  /**
   * 
   * @param router 
   * @param afs 
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    // console.log('1')
    this.results = [];
    this.resultscount = 0;
    this.settingDataTable()
    // this.getSuggestions();
    if(this.userType == 'arta'){
      if(sessionStorage.getItem('arta_admin') == "true")
        this.isAdmin = true;
    }

    //this.searchQueryJSON = "fish in my mouth"
    //this.loadQuery(this.route.snapshot.params.status)

    // this.getAgencyList()
    // this.getClassificationList()
    // this.getSectorList()
    // this.getServiceList()
    // this.getYearList()
    this.loadAllData()
    this.handleSearch()
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  settingDataTable(){
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    // this.dtTrigger.next(this.dtOptions);
  }

  handleSearch(){
    let JSONfields = {
      keyword: '',
      year: '',
      agency: '',
      sector: '',
      service: '',
      servicecategory: '',
      classification: '',
      agencycategory: '',
    };

    this.route.queryParams.subscribe(params => {
      this.resetForm()
      if(params.keyword != '' && params.keyword != null) {
        JSONfields.keyword = params.keyword;
        // this.form.get("keyword")?.setValue(params.keyword);
        this.form.patchValue({keyword:params.keyword})
      }else JSONfields.keyword = ''; 
      if(params.agencycategory != '' && params.agencycategory != null) {
        JSONfields.agencycategory = params.agencycategory;
        // this.form.get("agencycategory")?.setValue(params.agencycategory);
        this.form.patchValue({agencycategory:params.agencycategory})
      }else JSONfields.agencycategory = ''; 
      if(params.servicecategory != '' && params.servicecategory != null) {
        JSONfields.servicecategory = params.servicecategory;
        // this.form.get("servicecategory")?.setValue(params.servicecategory);
        this.form.patchValue({servicecategory:params.servicecategory})
      }else JSONfields.servicecategory = ''; 
      if(params.agency != '' && params.agency != null) {
        JSONfields.agency = params.agency;
        // this.form.get("agency")?.setValue(params.agency);
        this.form.patchValue({agency:params.agency})
      }else JSONfields.agency = ''; 
      if(params.sector != '' && params.sector != null) {
        JSONfields.sector = params.sector;
        // this.form.get("sector")?.setValue(params.sector);
        this.form.patchValue({sector:params.sector})
      }else JSONfields.sector = ''; 
      if(params.classification != '' && params.classification != null) {
        JSONfields.classification = params.classification;
        // this.form.get("classification")?.setValue(params.classification);
        this.form.patchValue({classification:params.classification})
      }else JSONfields.classification = ''; 
      if(params.year != '' && params.year != null) {
        JSONfields.year = params.year;
        // this.form.get("year")?.setValue(params.year);
        this.form.patchValue({year:params.year})
      }else JSONfields.year = ''; 
      // console.log({JSONfields})
      this.searchQueryJSON = params
      this.searching(JSONfields)
    })
  }

  rerender(): void {
    if(this.dtInstanceTriggered == true){
      this.dtElement.dtInstance.then((dtInstance) => {
        dtInstance.clear().draw();
        dtInstance.destroy();
        this.dtTrigger.next(this.dtOptions);
      });
    }else if(this.dtInstanceTriggered == false){
      this.dtTrigger.next(this.dtOptions);
      this.dtInstanceTriggered = true
    }
  }

  loadStatus(getStatus: string) {
    if (getStatus) {
      this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
    }
  }

  // valueSuggestions: any = {};
  // async getSuggestions(){
  //   /* main */
  //   let referencesRef = this.afs.collection("References");
  //   /* sub */
  //   //let commonRef = referencesRef.doc('common');
  //   let artemisRef = referencesRef.doc('ARTEMIS');
  //   //let pbrisRef = referencesRef.doc('PBRIS');
  //   /* sub sub */
  //   let collectionArray: any = [];
  //   /* common */
  //   /* common reference data has not yet been moved to the latest collection
  //   collectionArray.push({ collname: 'location_code', collRef: commonRef.collection('location_code') });
  //   collectionArray.push({ collname: 'notif_messages', collRef: commonRef.collection('notif_messages') });
  //   collectionArray.push({ collname: 'organization_code', collRef: commonRef.collection('organization_code') });
  //   collectionArray.push({ collname: 'psic_code', collRef: commonRef.collection('psic_code') });
  //   collectionArray.push({ collname: 'sector', collRef: commonRef.collection('sector') });
  //   */
  //   collectionArray.push({ collname: 'location_code', collRef: this.afs.collection('Location Code') });
  //   collectionArray.push({ collname: 'organization_code', collRef: this.afs.collection('Organization Code') });
  //   collectionArray.push({ collname: 'psic_code', collRef: this.afs.collection('PSIC Code') });
  //   collectionArray.push({ collname: 'sector', collRef: this.afs.collection('Sector') });
  //   /* artemis */
  //   collectionArray.push({ collname: 'agency', collRef: artemisRef.collection('agency') });
  //   collectionArray.push({ collname: 'agency_category', collRef: artemisRef.collection('agency_category') });
  //   collectionArray.push({ collname: 'classification', collRef: artemisRef.collection('classification') });
  //   collectionArray.push({ collname: 'list_of_services', collRef: artemisRef.collection('list_of_services') });
  //   collectionArray.push({ collname: 'service_category', collRef: artemisRef.collection('service_category') });
  //   collectionArray.push({ collname: 'stage_of_life', collRef: artemisRef.collection('stage_of_life') });
  //   /* pbris */
  //   /* pbris reference data has not yet been moved to the latest collection
  //   collectionArray.push({ collname: 'agency_location', collRef: pbrisRef.collection('agency_location') });
  //   collectionArray.push({ collname: 'case_use', collRef: pbrisRef.collection('case_use') });
  //   collectionArray.push({ collname: 'division', collRef: pbrisRef.collection('division') });
  //   collectionArray.push({ collname: 'document', collRef: pbrisRef.collection('document') });
  //   collectionArray.push({ collname: 'instrument', collRef: pbrisRef.collection('instrument') });
  //   collectionArray.push({ collname: 'jurisdiction', collRef: pbrisRef.collection('jurisdiction') });
  //   collectionArray.push({ collname: 'stage_of_business', collRef: pbrisRef.collection('stage_of_business') });
  //   */
  //   collectionArray.push({ collname: 'agency_location', collRef: this.afs.collection('Agency Location') });
  //   collectionArray.push({ collname: 'case_use', collRef: this.afs.collection('Case Use') });
  //   collectionArray.push({ collname: 'division', collRef: this.afs.collection('Division') });
  //   collectionArray.push({ collname: 'document', collRef: this.afs.collection('Document') });
  //   collectionArray.push({ collname: 'instrument', collRef: this.afs.collection('Instrument') });
  //   collectionArray.push({ collname: 'jurisdiction', collRef: this.afs.collection('Jurisdiction') });
  //   collectionArray.push({ collname: 'stage_of_business', collRef: this.afs.collection('Stage of Business') });
  //   /* loop */
  //   for(let collJSON of collectionArray) {
  //     let suggestArray: any = [];
  //     collJSON.collRef.snapshotChanges().subscribe((data: any) => {
  //       data.forEach((info: any) => {
  //         let item: any = info.payload.doc.data();
  //         suggestArray.push(item);
  //       });
  //     });
  //     this.valueSuggestions[collJSON.collname] = suggestArray;
  //   }
  // }
  // searchSuggestions: any = [];
  // getKeywordSuggestions(){
  //   // keyword auto suggestion
  //   if(this.form.value.keyword == '') {
  //     this.searchSuggestions = [];
  //   }
  //   else{
  //     this.afs.collection("Services").snapshotChanges().subscribe(
  //       (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();

  //           if(this.searchSuggestions.includes(item.name_and_details.service_name) == false){
  //             if(item.name_and_details.service_name.toLowerCase().includes(this.form.value.keyword.toLowerCase())){
  //               this.searchSuggestions.push(item.name_and_details.service_name);
  //             }
  //           }
  //         });
  //       })
  //   }
  // }
  // selectItem(item: any){
  //   this.searchSuggestions = [];
  //   this.form.get("keyword")?.setValue(item);
  // }

  
  
  // loadQuery(getQuery: string){
  //   this.searchQueryJSON = getQuery//getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
  // }

  /**
   * [LOV Functions] ========================= [LOV Functions] ========================= [LOV Functions] =========================
   */
  // getAgencyList(){
  //   this.getFirestoreJSON("agency", "ARTEMIS",this.fieldLOVagency)
  // }
  // getServiceList(){
  //   this.getFirestoreJSON("list_of_services", "ARTEMIS",this.fieldLOVservice)
  // }
  // getSectorList(){
  //   this.getFirestoreJSON("sector", "common",this.fieldLOVsector)
  // }
  // getYearList(){
  //   //
  // }
  // getClassificationList(){
  //   this.getFirestoreJSON("classification", "ARTEMIS",this.fieldLOVclassification)
  // }

  // async getFirestoreJSON(collectionName: string, id: string ,arrayItem: any[]){
  //   this.afs.collection("References").doc(id).collection(collectionName).snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
  //     (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
            
  //           arrayItem.push(item); // push into array
  //         });
  //     });
  // }

  async loadAllData(){
    this.loading = true;
    const agencyCatFlag = await this.loadData('agencycategory','Agency Category')
    const agencyFlag = await this.loadData('agency','Agency')
    const sectorFlag = await this.loadData('sector','Sector')
    const classificationFlag = await this.loadData('classification','Classification')
    const serviceCatFlag = await this.loadData('servicecategory','Service Category')
    const serviceFlag = await this.loadData('service','Services Phase 02')
    if(agencyCatFlag && agencyFlag && sectorFlag && classificationFlag && serviceCatFlag && serviceFlag){
      //("loading finished")
      this.loading = false;
    }
  }

  //page loader
  async loadData(load:any,collection:any){
    const arrayData = await this.afs.collection(collection,
      filter => this.filterFunc(filter,load))
      .snapshotChanges()
      .pipe(first())

    if(load == 'agencycategory'){
      this.fieldLOVagencyCategory = arrayData
      // let agencyCategoryList = await this.dataToList(arrayData)

      this.filteredAgencyCategory = this.mapper(load)
      // if(agencyCategoryList){
      //   this.agencyCategoryList = agencyCategoryList
      //   console.log('agency category list',this.agencyCategoryList)
      //   return true
      // }
      // else 
      return true
    }
    else if(load == 'agency'){
      this.fieldLOVagency = arrayData
      this.filteredAgency = this.mapper(load)
      return true
    }
    else if(load == 'sector'){
      this.fieldLOVsector = arrayData
      this.filteredSector = this.mapper(load)
      return true
    }
    else if(load == 'classification'){
      this.fieldLOVclassification = arrayData
      this.filteredClassification = this.mapper(load)
      return true
    }
    else if(load == 'servicecategory'){
      this.fieldLOVserviceCategory = arrayData
      this.filteredServiceCategory = this.mapper(load)
      return true
    }
    else if(load == 'service'){
      this.fieldLOVservice = arrayData
      // this.filteredService = this.mapper('year')
      return this._filter('','year')
      // return true
    }
    else{
      return true
    }
    
  }

  async dataToList(arrayData: Observable<DocumentChangeAction<any>[]>){
    return new Promise((res,rej)=>{
      arrayData.subscribe({
        next:(item)=>{
          let tempArr:any = []
          item.forEach((data:any)=>{
            let value:any = data.payload.doc.data()
            value.id = data.payload.doc.id
            if(!tempArr.map((element:any)=>element.id).includes(value.id)) tempArr.push(value)
          })
          return res(tempArr)
        },
        error:(err)=>{
          console.error('Error: ',err)
          return res([])
        }
      })
    })
  }

  private mapper(load:any){
    return (this.form.controls[load] as UntypedFormControl).valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      flatMap((value) => this._filter(value,load))
    );
  }

  private _filter(value: string,load:any):Observable<any[]> {
    const filteredVal = value.toLowerCase()
    if(load == 'agencycategory'){
      return this.fieldLOVagencyCategory.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && ( option.agencyDetails ? option.agencyDetails.agencyName.toLowerCase().includes(filteredVal) : option.name.toLowerCase().includes(filteredVal)))
          );
        })
      );
    }

    else if(load == 'agency'){
      return this.fieldLOVagency.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          this.agencyList = modifiedCode
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && ( option.agencyDetails ? option.agencyDetails.agencyName.toLowerCase().includes(filteredVal) : option.name.toLowerCase().includes(filteredVal)))
          );
        })
      );
    }

    else if(load == 'sector'){
      return this.fieldLOVsector.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal))
          );
        })
      );
    }

    else if(load == 'classification'){
      return this.fieldLOVclassification.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal))
          );
        })
      );
    }

    else if(load == 'servicecategory'){
      return this.fieldLOVserviceCategory.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal))
          );
        })
      );
    }

    else if(load == 'service'|| load == 'keyword'|| load == 'year'){
      return this.fieldLOVservice
      .subscribe((info:any)=>{
        let tempArr:any = []
        info.forEach((data:any)=>{
          let service: any = data.payload.doc.data()
          service.id = data.payload.doc.id
          tempArr.push(service)
        })
        this.filteredService = tempArr
        this.allYears = this.getAllYear(tempArr)
        return true
      })
      // .pipe(
      //   map((code:any) => {
      //     let modifiedCode: any = code.map((element:any) => {
      //       let item:any = element.payload.doc.data()
      //       item.id = element.payload.doc.id
      //       return item
      //     });
      //     // return modifiedCode
      //     return modifiedCode.filter(
      //       (option:any) =>  (filteredVal.trim() != '' && option.serviceDetails.service_name.toLowerCase().includes(filteredVal))
      //     )
      //   }) 
      // );
    }
      
    return of([])
  }

  filterFunc(filter:any,load:any){
    if(load == 'agencycategory')
      return filter.orderBy('abbreviation')
    if(load == 'agency')
      return filter.orderBy(("agencyDetails" ? "agencyDetails.agencyName" : "name"))
    if(load == 'sector')
      return filter.orderBy('name')
    if(load == 'classification')
      return filter.orderBy('name')
    if(load == 'servicecategory')
      return filter.orderBy('name')
    if(load == 'service'){
      const userType = sessionStorage.getItem("user_type")
      if(userType == 'arta' || userType == 'agency') 
        return filter.orderBy('serviceDetails.service_name');
      else
        return filter.orderBy('date_posted').where('is_posted','==',true);
    }
    return filter
  }

  yearSelected(yearOption:any){
    if(yearOption == this.searchQueryJSON.year)
      return true
    return false
  }

  getAllYear(services:any){
    let tempArr: any = []
    // let hasYear = services.filter((result:any)=>(result.date_posted)?true:false)
    // console.log({hasYear})
    services.forEach((result:any)=>{
      if(result.date_posted){
        let servicePostedDate = result.date_posted.toDate()
        // console.log("date",servicePostedDate)
        let serviceYear = servicePostedDate.getFullYear()
        // console.log("year",serviceYear)
        if(!tempArr.includes(serviceYear))
          tempArr.push(serviceYear)
      }
    })
    
    return tempArr
  }

  yearChanged(yearValue:any){
    this.form.patchValue({
      year:yearValue
    })
  }


  /**
   * [Search Functions] ========================= [Search Functions] ========================= [Search Functions] =========================
   */
  // searchForm() {
  //   this.getKeywordSuggestions();
  //   let JSONfields = {
  //     service_name: this.form.value.keyword,
  //     //year: this.form.value.year,
  //     agency: this.form.value.agency,
  //     sector: this.form.value.sector,
  //     //service: this.form.value.service,
  //     classification: this.form.value.classification,
  //   };
  //   this.search(JSONfields);
  // }
  resetForm() {
    // this.form.reset(this.formInitialParams);
    // this.form.reset()
    this.form.patchValue({
      agencycategory:'',
      agency:'',
      sector:'',
      classification:'',
      servicecategory:'',
      service:'',
      year:'',
      keyword:'',
    })
    // this.searchForm();
  }
  // /**apply filtering */
  // async search(inputJSON: any) {
  //   this.results = [];
  //   let status : any;

  //   this.afs.collection("Services").snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
  //     (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
            
  //           if(item.citizens_charter){
  //             //get charter status
  //             const charterRef = this.afs.collection("citizens-charter-phase-01").doc(item.citizens_charter);
  //             charterRef.snapshotChanges().subscribe((data: any) => {
  //               let charter_info_json = data.payload.data();
  //               status = charter_info_json.status;
  //               if(status == "posted" || this.isAdmin) {
  //                 // push item if only it is not included yet
  //                 if (item.name_and_details){
  //                   let name_and_details = item.name_and_details;
  //                   let eodb_tags = item.eodb_tags;
      
  //                   let byName = this.isAcceptedItem("service_name" , inputJSON , name_and_details)
                    
  //                   //let byYear = this.isAcceptedItem("year" , inputJSON , item)
  //                   let byAgency = this.isAcceptedItem("agency" , inputJSON , name_and_details)
  //                   let bySector = this.isAcceptedItem("sector" , inputJSON , eodb_tags)
  //                   //let byService = this.isAcceptedItem("service" , inputJSON , item)
  //                   let byClassification = this.isAcceptedItem("classification" , inputJSON , name_and_details)
  //                   let allBool = byName && byAgency && bySector && byClassification;
      
  //                   // filter by each
  //                   if(allBool){
  //                     let new_item = item.name_and_details;
  //                     new_item.sector = item.eodb_tags.sector;
  //                     new_item.id = item.id;
    
  //                     this.results.push(new_item); // push into array
  //                     // count number of entries
  //                     this.resultscount = this.results.length;
  //                   }
  //                 }
  //               }
  //             });
  //           }

  //         });
  //     });
  // }
  // async searchServices(inputJSON: any) {
  //   this.results = [];

  //   this.afs.collection("Services").snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
  //     (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
            
  //           // push item if only it is not included yet
  //           if (this.results.includes(item) === false){
  //             let byName = this.isAcceptedItem("service_name" , inputJSON , item)
  //             //let byYear = this.isAcceptedItem("year" , inputJSON , item)
  //             let byAgency = this.isAcceptedItem("agency" , inputJSON , item)
  //             let bySector = this.isAcceptedItem("sector" , inputJSON , item)
  //             //let byService = this.isAcceptedItem("service" , inputJSON , item)
  //             let byClassification = this.isAcceptedItem("classification" , inputJSON , item)
  //             let allBool = byName && byAgency && bySector && byClassification;

  //             // filter by each
  //             if(allBool){
  //               this.results.push(item); // push into array
  //             }
  //           }
  //           // count number of entries
  //           this.resultscount = this.results.length;

  //         });
  //     });
  // }
  // async searchAgency(inputJSON: any) {
  //   this.results = [];

  //   this.afs.collection("citizens-charter-phase-01").snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
  //     (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
            
  //           // push item if only it is not included yet
  //           if (this.results.includes(item) === false){
  //             let byName = this.isAcceptedItem("name" , inputJSON , item)
  //             let byYear = this.isAcceptedItem("year" , inputJSON , item)
  //             let byAgency = this.isAcceptedItem("agency" , inputJSON , item)
  //             let bySector = this.isAcceptedItem("sector" , inputJSON , item)
  //             let byService = this.isAcceptedItem("service" , inputJSON , item)
  //             let byClassification = this.isAcceptedItem("classification" , inputJSON , item)
  //             let allBool = byName && byYear && byAgency && bySector && byService && byClassification;

  //             // filter by each
  //             if(allBool){
  //               this.results.push(item); // push into array
  //             }
  //           }
  //           // count number of entries
  //           this.resultscount = this.results.length;

  //         });
  //     });
  // }

  // isAcceptedItem (keyName: string, inputJSON: any, item: any) {
  //   // if field is left empty
  //   if(inputJSON[keyName] == ''){
  //     return true;
  //   }
  //   // if field is from keyword, convert all to lower-case then compare
  //   if (keyName == "service_name"){
  //     if(item[keyName].toLowerCase().includes(inputJSON[keyName].toLowerCase())){
  //       return true;
  //     }
  //   }
  //   // if field has data that is not from keyword
  //   else if ( item[keyName] == inputJSON[keyName] ) {
  //     return true
  //   }
    
  //   return false;
  // }

  // anArray(arr:any,keyword:any){
  //   if(this.hasTrue == false){
  //     return arr.map((values:any)=>{
  //       if(Array.isArray(values)){
  //         return this.anArray(values,keyword)
  //       }else{
  //         // let value: any = Object.values(values)
  //         if(typeof values === 'object' && values !== null){
            
  //           let anObject: any = Object.values(values)
            
  //           return this.anArray(anObject,keyword)
  //         }
  //         else{
  //           if(values && (values.toString().trim().toLowerCase().includes(keyword) || values.toString().trim().toLowerCase() == keyword)){
  //             this.hasTrue = true;
  //             return true
  //           }
  //           else return false
  //         }
  //       }
  //     })
  //   }else{
  //     return true
  //   }
    
  // }

  // hasTrue: boolean = false;
  // keywordFunc(arr:any,keyword:any){
  //   keyword = keyword.toString().trim().toLowerCase()
  //   let newArr:any = []  
  //   newArr = arr.filter((result:any)=>{
  //     this.hasTrue = false;
  //     //remove some values
  //     if(result.citizens_charter)
  //       result.citizens_charter = ""
  //     if(result.reports)
  //       result.reports = ""
  //     // console.log("base service",result)
  //     let values: any = Object.values(result)
  //     // console.log("base service",values)
      
  //     if(Array.isArray(values)){
  //       this.anArray(values,keyword)
  //       return this.hasTrue
  //     }
  //     else {
  //       if(values && (values.toString().trim().toLowerCase().includes(keyword) || values.toString().trim().toLowerCase() == keyword))
  //         return true
  //       else return false
  //     }
  //   })
  //   // console.log("filtered by keyword",newArr)
  //   // newArr.map((element:any) => {
  //   //   if(!tempArr.includes(element))
  //   //     tempArr.push(element)
  //   // });
  //   return newArr  
  // }
  anArray(arr:any,keyword:any){
    let value = false;
    for(let ctr = 0; ctr < arr.length && value == false;ctr++){
      // console.log('arr['+ctr+']',arr[ctr])
      // console.log('is array: '+Array.isArray(arr[ctr]))
      if(Array.isArray(arr[ctr])){
        let bool:any =  this.anArray(arr[ctr],keyword)
        if(bool == true){
          // console.log('returning keyword found', true)
          value = true
          return value
        }
      }else{
        // let value: any = Object.values(values)
        // console.log('is object: ',typeof arr[ctr] === 'object')
        if(typeof arr[ctr] === 'object' && arr[ctr] !== null){
          let anObject: any = Object.values(arr[ctr])
          let bool:any =  this.anArray(anObject,keyword)
          if(bool == true){
            // console.log('returning keyword found', true)
            value = true
            return value
          }
        }
        else{
          // console.log('check keyword')
          if(arr[ctr] != null && arr[ctr] != undefined
            && (arr[ctr].toString().trim().toLowerCase().includes(keyword)
            || arr[ctr].toString().trim().toLowerCase() == keyword)){
            // console.log('keyword found', true)
            // console.log("value: ",values)
            value = true;
            return true
          }
        }
      }
    }
    return value
  }


  keywordFunc(item:any,keyword:any){
    keyword = keyword.trim().toLowerCase()
    const itemCleaned:any = this.cleanItem(item)
    if(typeof item === 'object') return this.anArray(Object.values(itemCleaned),keyword)
    else return false
  }

  cleanItem(item:any){
    let itemToClean:any = JSON.parse(JSON.stringify(item))
    if(itemToClean.citizens_charter) itemToClean.citizens_charter = ""
    if(itemToClean.reports) itemToClean.reports = ""
    if(itemToClean.id) itemToClean.id = ""
    return itemToClean
  }

  submitFunc(filter:any,searchData:any){
    let query: Query = filter
    let ctr:number = 0;

    // agency cat based on agency
    if(searchData.agencycategory != '' && searchData.agencycategory != null ){
      ctr++;
      let agencyNames:any = [] 
      this.agencyList.map((info:any)=>{
        let agencyCat = info.agencyDetails ? info.agencyDetails.agencyCategory ? info.agencyDetails.agencyCategory.name : "" : ""
        if( ((agencyCat.trim().toLowerCase()) == (searchData.agencycategory.trim().toLowerCase()) ? true : false))
          agencyNames.push( info.agencyDetails ? info.agencyDetails.agencyName : info.name)
      })
      if(agencyNames.length > 0)
        query = query.where('serviceDetails.agency','in',agencyNames)
      else
        query = query.where('error','==','error')
    }

    // agency
    if(searchData.agency != '' && searchData.agency != null ){
      console.log('agency query: ',searchData.agency)
      ctr++;
      query = query.where('serviceDetails.agency','==',searchData.agency)
    }


    //sector
    //impossible search for firebase, nesed arrays/objects within arraysx`
    //unless use arraycontain and use whole ibject as value for search
    //need to be handled ater pulling documents
    // worst case only search by sector
    // if(searchData.sector != '' && searchData.sector != null ){
    //   console.log("sector in")
    //   query = query.where('eodbTags','array-contains',{
    //     sector: searchData.sector,
    //     // case_use: "test",
    //     // classification:["Business"],
    //     // division: "Buen Dia, El Mundo",
    //     // jurisdiction: "National",
    //     // stage_of_business: ""
    //   })
    // }

    //classification
    if(searchData.classification != '' && searchData.classification != null && (searchData.classification == 'Simple' || searchData.classification == 'Simple ' || searchData.classification == 'Complex' || searchData.classification == 'Complex ')){
      ctr++;
      query = query.where('serviceDetails.classification','==',searchData.classification)
    }

    //service cat
    if(searchData.servicecategory != '' && searchData.servicecategory != null ){
      ctr++;
      query = query.where('serviceDetails.service_category','==',searchData.servicecategory)
    }

    //year to be based on is_posted date, but cannot find date
    if(searchData.year != '' && searchData.year != null ){
      ctr++;
      // let dateTS = Timestamp.fromDate(searchData.year)
      let startDateTS = Timestamp.fromDate(new Date(searchData.year+'-01-01T01:00:00Z'));
      let endDateTS = Timestamp.fromDate(new Date(searchData.year+'-12-31T23:59:59Z'));
      query = query = query.orderBy('date_posted').where('date_posted','>=',startDateTS).where('date_posted','<=',endDateTS);
      const userType = sessionStorage.getItem("user_type")
      if(userType !== 'arta' && userType !== 'agency'){
        query = query.where('is_posted','==',true);
      }
    }else{
      // user type
      const userType = sessionStorage.getItem("user_type")
      if(userType == 'arta' || userType == 'agency') 
        query = query.orderBy('serviceDetails.service_name');
      else
        query = query.orderBy('date_posted').where('posted_status','==','POSTED');
    }

    //keyword

    //browser side search flag
    if((searchData.classification != '' && searchData.classification != null && searchData.classification == 'Technical')
      || (searchData.sector != '' && searchData.sector != null )
      || (searchData.keyword != '' && searchData.keyword != null )){
      ctr++
    }

    if(ctr > 0)
      return query
    else
      return query.where('error','==','error')
  }

  searching(searchData:any){
    this.servicesLoading = true
    this.afs.collection('Services Phase 02', filter=>this.submitFunc(filter,searchData)).snapshotChanges()
    .pipe(first())
    .subscribe(
      async (result)=>{
        // console.log({result})
        console.log('result L: ',result.length)
        let tempArr:any=[]
        // result.forEach(data=>{
        this.totalRegulationsNum = 0;
        this.currentRegulationNum = 0;
        this.currentRegulationInPercent = 0;
        this.totalRegulationsNum = result.length

        Promise.all(
          result.map((data)=>{
            let service:any = data.payload.doc.data()
            service.id = data.payload.doc.id
            service.displayName = service.serviceDetails.service_name
            let search_ctr = 0
            if(searchData.classification != '' && searchData.classification != null && searchData.classification.toString().trim().toLowerCase() == 'technical'){
              search_ctr++
              if(service.serviceDetails.classification.trim().toLowerCase().includes(searchData.classification.toString().trim().toLowerCase())){
                // if(!tempArr.includes(service)) tempArr.push(service)
                this.computeLoadingPercentage()
                return service
              }else{
                this.computeLoadingPercentage()
              }
            }
  
            if(searchData.sector != '' && searchData.sector != null ){
              search_ctr++
              if(service.eodbTags.map((element:any)=>element.sector.trim().toLowerCase()).includes(searchData.sector.trim().toLowerCase())){
                // if(!tempArr.includes(service)) tempArr.push(service)
                this.computeLoadingPercentage()
                return service
              }
              else{
                this.computeLoadingPercentage()
              }
            }
  
            
          if(searchData.keyword != '' && searchData.keyword != null ){
            search_ctr++
            let bool: boolean = this.keywordFunc(service,searchData.keyword)
            if(bool == true){
              // if(!tempArr.includes(service)){
              //   tempArr.push(service)
              // }
              this.computeLoadingPercentage()
              return service
            }else{
              this.computeLoadingPercentage()
            }
          }
  
            if(search_ctr == 0){
              // tempArr.push(service)
              this.computeLoadingPercentage()
              return service
            }
          })
        )
        .then((results)=>{
          this.resultscount = results.length
          console.log('result L2: ',results.length)
          this.results = results
          this.rerender()
          this.servicesLoading = false
        })

        // for await (let data of result){
        //   let service:any = data.payload.doc.data()
        //   service.id = data.payload.doc.id
        //   service.displayName = service.serviceDetails.service_name
        //   let search_ctr = 0
        //   if(searchData.classification != '' && searchData.classification != null && searchData.classification.toString().trim().toLowerCase() == 'technical'){
        //     search_ctr++
        //     if(service.serviceDetails.classification.trim().toLowerCase().includes(searchData.classification.toString().trim().toLowerCase())){
        //       if(!tempArr.includes(service)) tempArr.push(service)
        //     }
        //     this.computeLoadingPercentage()
        //   }

        //   if(searchData.sector != '' && searchData.sector != null ){
        //     search_ctr++
        //     if(service.eodbTags.map((element:any)=>element.sector.trim().toLowerCase()).includes(searchData.sector.trim().toLowerCase())){
        //       if(!tempArr.includes(service)) tempArr.push(service)
        //     }
        //     this.computeLoadingPercentage()
        //   }

          
        // if(searchData.keyword != '' && searchData.keyword != null ){
        //   search_ctr++
        //   let bool: boolean = this.keywordFunc(service,searchData.keyword)
        //   if(bool == true){
        //     if(!tempArr.includes(service)){
        //       tempArr.push(service)
        //     }
        //     this.computeLoadingPercentage()
        //   }else{
        //     this.computeLoadingPercentage()
        //   }
        // }

        //   if(search_ctr == 0){
        //     tempArr.push(service)
        //     this.computeLoadingPercentage()
        //   }
        // }
        //)
        // console.log("before processing client side filters", tempArr)
        
        //classification technical was done client side since theres no string check/string LIKE with firebase
        //there are classification that has a value of Highly Technical
        // if(searchData.classification != '' && searchData.classification != null && searchData.classification.trim().toLowerCase() == 'technical'){
        //   // console.log("classification technical in")
        //   tempArr = tempArr.filter((data:any)=>(data.serviceDetails.classification.trim().toLowerCase().includes(searchData.classification.trim().toLowerCase())?true:false))
        // }

        //cannot be handled withing firebase query (nested array)
        // if(searchData.sector != '' && searchData.sector != null ){
        //   tempArr = tempArr.filter((data:any)=>(data.eodbTags.map((element:any)=>element.sector).includes(searchData.sector)?true:false))
        // }

        // handle keyword here
        // if(searchData.keyword != '' && searchData.keyword != null ){
        //   // console.log("keyword in")
        //   // tempArr = tempArr.filter((data:any)=>(data.eodbTags.map((element:any)=>element.sector).includes(searchData.sector)?true:false))
        //   tempArr = this.keywordFunc(tempArr,searchData.keyword)
        // }

        // console.log("services : ",tempArr)
        // this.resultscount = tempArr.length
        // this.results = tempArr
        // // console.log(this.results)
        // // this.dtTrigger.next(this.dtOptions);
        // this.rerender() //thros error
        // // this.dtTrigger.unsubscribe();
        // this.servicesLoading = false
      },
      (error)=>{
        console.error("error searching : ",error)
        this.servicesLoading = false
      }
    )
  }

  computeLoadingPercentage(){
    this.currentRegulationNum++
    this.currentRegulationInPercent = parseInt(((this.currentRegulationNum / this.totalRegulationsNum)*100).toFixed(2))
  }

  formSubmit() {
    // validate
    // console.log(this.form.value)
    this.validatedFields()
    if(this.form.valid){
      const searchData = this.form.value
      // this.searching(searchData)
      let JSONarray = {
        keyword: searchData.keyword,
        year: searchData.year,
        classification: searchData.classification,
        sector: searchData.sector,
        agency: searchData.agency,
        agencycategory: searchData.agencycategory,
        servicecategory: searchData.servicecategory,
      };
      this.router.navigate(['/artemis/results'], {queryParams: JSONarray} );
    }else {
      console.error("form ivalid")
    }
  }

  viewService(entryId: string){
    //TODO: Temporarily reusing the view details for worklisting
    //TODO: Create a new component for viewing separate from 
    this.router.navigate([`artemis/view/detail/${entryId}`])
  }

 

  getImage(service:any){
    if(service.serviceDetails){
      if(service.serviceDetails.agency_id){
        return "/assets/GOP_Logo.png"
      }
      else if(service.serviceDetails.agency){
        const ctr:number = this.agencyList.map((res:any)=>{
          if(res.agencyDetails){
            if(res.agencyDetails.agencyName)
              return res.agencyDetails.agencyName;
            else if(res.name) return res.name
          }
          else if(res.name) return res.name
          return ""
        }).indexOf(service.serviceDetails.agency)
        if(ctr>=0){
          if(this.agencyList[ctr].agencyDetails.logoURL)
            return this.agencyList[ctr].agencyDetails.logoURL;
        }
      }
    }
    return "/assets/GOP_Logo.png"
  }
  
  hasError(controlName:any, validType:any){
    return ((this.form.get(controlName) as UntypedFormControl).hasError(validType) 
    && ((this.form.get(controlName) as UntypedFormControl).dirty 
    || (this.form.get(controlName) as UntypedFormControl).touched)); 
  }

  validatedFields(){
    if(!this.form.valid){
      this.form.markAllAsTouched();
      for(const control in this.form.controls){
        if (this.form.controls.hasOwnProperty(control)) {
          this.form.controls[control].markAllAsTouched();
          this.form.controls[control].markAsDirty();
        }
      }
    }
  }

  toArtemisAdvancedSearch(){
    this.router.navigate(['artemis/advanced-search'])
  }

}