import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User, sendEmailVerification } from 'firebase/auth';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ReferenceService } from 'src/app/service/reference.service';

@Component({
  selector: 'app-confirm-send-verification',
  templateUrl: './confirm-send-verification.component.html',
  styleUrls: ['./confirm-send-verification.component.scss']
})
export class ConfirmSendVerificationComponent implements OnInit {

  method!: string;
  collection!: string;
  id?: any;

  header_content?: any;
  body_content?: any;
  buttons?: any;

  user_obj?: User;
  redirect_url?: string;
  notif_message_code!: string;

  constructor(public modalRef: MDBModalRef, private auth: AngularFireAuth, private router: Router,
    private referenceService: ReferenceService) { }

  ngOnInit(): void {
  }

  confirm() {
    this.modalRef.hide();
    if(this.user_obj && this.redirect_url){
      sendEmailVerification(this.user_obj, {
        url: this.redirect_url
      });
    }
    /* this.referenceService.getNotification(this.notif_message_code).then((data) => {
      data.subscribe((entry) => {
        alert(entry);
      });
    }); */
    alert("New email sent.");
  }

  cancel() {
    //
    this.modalRef.hide();
  }

}
