import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Console } from 'console';
import { map, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { el } from 'date-fns/locale';


@Component({
  selector: 'app-artemis-home-page-service-finder',
  templateUrl: './artemis-home-page-service-finder.component.html',
  styleUrls: ['./artemis-home-page-service-finder.component.scss']
})
export class ArtemisHomePageServiceFinderComponent implements OnInit {

  style_theme: any = 'theme-default'; //current theme
  agency_categories: any = []
  agencies: any = []

  @Output() searchFilter = new EventEmitter<any>();

  agency1 = new UntypedFormControl('');

  searchFilters: any = {
    category: "",
    agency: "",
    sector: "",
    classification: "",
    service: "",
    year: "",
    keyword: "",
    logo_url: "",
  }

  agencyList: any = []

  serviceList: any = []

  userType: any = sessionStorage.getItem("user_type");
  filteredOptions: Observable<any[]>;

  searchform: UntypedFormGroup = new UntypedFormGroup({
    agencycategory: new UntypedFormControl('', Validators.required),
    agency: new UntypedFormControl('', Validators.required),
    classification: new UntypedFormControl(''),
    service: new UntypedFormControl(''),
    year: new UntypedFormControl('')
  });

  constructor(private router: Router, private route: ActivatedRoute, private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.getSuggestions();
    this.setTheme();
    
    this.filteredOptions = this.agency1.valueChanges.pipe(
      startWith(''), map(value => {

        return this._filter(value);

      })
    )
    this.agencyList = [];
  }

  _filter(value: string) {
    const keyword = value.toLowerCase()
    return this.agencyList.filter((agencyList: any) => agencyList.agencyDetails.agency_name.toLowerCase().includes(keyword))

  }

  sendSearchFilters() {

    if(this.searchform.valid){
      this.searchFilters = {
        category: this.searchform.value.agencycategory ? this.searchform.value.agencycategory : [this.agencyList],
        agency: this.searchform.value.agency ? this.searchform.value.agency : "",
        classification: this.searchform.value.classification ? this.searchform.value.classification : "",
        service: this.searchform.value.service ? this.searchform.value.service : "",
        year: this.searchform.value.year ? this.searchform.value.year : "",
        logo_url: this.getAgencyLogoUrl(this.selectedAgency)
      }
  
      this.searchFilter.emit(this.searchFilters);
    }else{
      this.searchform.controls.agencycategory.markAllAsTouched();
    }

   
  }

  resetFields(){
    this.searchform.reset();

  }



  async setTheme() {
    switch (sessionStorage.getItem("user_type")) {
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }
  /***
   * 
   *  agencymasterList:any = []
 
   agencySuggestion(){
     return this.afs.collection('Agency',ref => ref.where('agencyDetails.agencyName','!=','')).snapshotChanges()
     .pipe().subscribe((data:any)=>{
         this.agencymasterList =[]
         data.forEach((info:any)=>{
             let item:any = info.payload.doc.data();
             item.id = info.payload.doc.id;
             this.agencymasterList.push(item)
         })
 
     })
 
   }
   */

  valueSuggestions: any = {};
  async getSuggestions() {
    /* main */
    let referencesRef = this.afs.collection("References");
    /* sub */
    //let commonRef = referencesRef.doc('common');
    let artemisRef = referencesRef.doc('ARTEMIS');
    //let pbrisRef = referencesRef.doc('PBRIS');
    /* sub sub */
    let collectionArray: any = [];
    /* common */
    /* common reference data has not yet been moved to the latest collection
    collectionArray.push({ collname: 'location_code', collRef: commonRef.collection('location_code') });
    collectionArray.push({ collname: 'notif_messages', collRef: commonRef.collection('notif_messages') });
    collectionArray.push({ collname: 'organization_code', collRef: commonRef.collection('organization_code') });
    collectionArray.push({ collname: 'psic_code', collRef: commonRef.collection('psic_code') });
    collectionArray.push({ collname: 'sector', collRef: commonRef.collection('sector') });
    */
    collectionArray.push({ collname: 'location_code', collRef: this.afs.collection('Location Code') });
    collectionArray.push({ collname: 'organization_code', collRef: this.afs.collection('Organization Code') });
    collectionArray.push({ collname: 'psic_code', collRef: this.afs.collection('PSIC Code') });
    collectionArray.push({ collname: 'sector', collRef: this.afs.collection('Sector') });
    /* artemis */
    collectionArray.push({ collname: 'agency', collRef: this.afs.collection('Agency', ref => ref.where('agencyDetails.agencyName', '!=', '')) });
    collectionArray.push({ collname: 'agency_category', collRef: this.afs.collection('Agency Category') });
    collectionArray.push({ collname: 'classification', collRef: artemisRef.collection('classification') });
    /** collectionArray.push({ collname: 'list_of_services', collRef: this.afs.collection('Services Phase 02',ref => ref.where('posted_status','==','POSTED')) });
    */
    collectionArray.push({ collname: 'service_category', collRef: artemisRef.collection('service_category') });
    collectionArray.push({ collname: 'stage_of_life', collRef: artemisRef.collection('stage_of_life') });
    /* pbris */
    /* pbris reference data has not yet been moved to the latest collection
    collectionArray.push({ collname: 'agency_location', collRef: pbrisRef.collection('agency_location') });
    collectionArray.push({ collname: 'case_use', collRef: pbrisRef.collection('case_use') });
    collectionArray.push({ collname: 'division', collRef: pbrisRef.collection('division') });
    collectionArray.push({ collname: 'document', collRef: pbrisRef.collection('document') });
    collectionArray.push({ collname: 'instrument', collRef: pbrisRef.collection('instrument') });
    collectionArray.push({ collname: 'jurisdiction', collRef: pbrisRef.collection('jurisdiction') });
    collectionArray.push({ collname: 'stage_of_business', collRef: pbrisRef.collection('stage_of_business') });
    */
    collectionArray.push({ collname: 'agency_location', collRef: this.afs.collection('Agency Location') });
    collectionArray.push({ collname: 'case_use', collRef: this.afs.collection('Case Use') });
    collectionArray.push({ collname: 'division', collRef: this.afs.collection('Division') });
    collectionArray.push({ collname: 'document', collRef: this.afs.collection('Document') });
    collectionArray.push({ collname: 'instrument', collRef: this.afs.collection('Instrument') });
    collectionArray.push({ collname: 'jurisdiction', collRef: this.afs.collection('Jurisdiction') });
    collectionArray.push({ collname: 'stage_of_business', collRef: this.afs.collection('Stage of Business') });

    /* loop */
    for (let collJSON of collectionArray) {
      let suggestArray: any = [];
      collJSON.collRef.snapshotChanges().subscribe((data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id
          suggestArray.push(item);
        });
      });
      this.valueSuggestions[collJSON.collname] = suggestArray;
    }
  }
  searchSuggestions: any = [];
  getKeywordSuggestions() {
    // keyword auto suggestion
    if (this.searchform.value.keyword == '') {
      this.searchSuggestions = [];
    }
    else {
      this.afs.collection("Services").snapshotChanges().subscribe(
        (data: any) => {
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();

            if (this.searchSuggestions.includes(item.name_and_details.service_name) == false) {
              if (item.name_and_details.service_name.toLowerCase().includes(this.searchform.value.keyword.toLowerCase())) {
                this.searchSuggestions.push(item.name_and_details.service_name);
              }
            }
          });
        })
    }
  }
  selectItem(item: any) {
    this.searchSuggestions = [];
    this.searchform.get("keyword")?.setValue(item);
  }

  selectedAgency: any;
  selectedClassification: any = "";
  selectedYear:any = ""
  datePostedList:any = []
  tempArray:any = []

  getAgencyLogoUrl(id:any){
    let tempArr:any = this.agencyList;
    let agency =  tempArr.filter((agency:any)=> agency.id == id)
    if(agency[0]?.agencyDetails.logoURL && agency.length > 0){
        return agency[0].agencyDetails.logoURL
    }else{
      return "/assets/GOP_Logo.png"
    }

  }

  onAgencyChange($event: any) {
    
    this.selectedAgency = $event.target.value;
    //this.onClassificationChange(this.selectedClassification, this.selectedAgency)
    this.tempArray = []
    this.afs.collection('Services Phase 02', ref => ref.where('posted_status', '==', 'POSTED')
      .where('fromAgencyId', '==', this.selectedAgency )).snapshotChanges()
      .pipe(map(actions =>
        actions.map((data: any) => {
          let item: any = data.payload.doc.data();
          item.id = data.payload.doc.id;
          if(item.date_posted){
            if(!this.datePostedList.includes(item.date_posted.toDate().getFullYear())){
              this.datePostedList.push(item.date_posted.toDate().getFullYear())
            }
            
          }
          item.year = item.date_posted.toDate().getFullYear();
          this.tempArray.push(item);
          //console.log(item.year)
         
        }))).subscribe()
      this.filterAgencyServices();
  }


  filterAgencyServices(){

    let temp:any = []
    temp  = this.tempArray;
    this.serviceList = [];
    const classification = this.searchform.value.classification;
    const year = this.searchform.value.year;
    //console.log("Classification", classification);
    //console.log("year", year);


    if(classification || year){
      if(year) temp = temp.filter((s:any)=>s.year == year);
      if(classification) temp =temp.filter((s:any)=> s.serviceDetails.classification == classification);
      this.serviceList =temp;
     
    }else{
      this.serviceList =temp;

    }

  

  }



  onCategoryChange($event: any) {
    this.searchform.controls.agency.setValue("")
    const keyword = $event.target.value.toLowerCase();
    this.agencyList.length =0

    for (var data of this.valueSuggestions.agency){
      if (data.agencyDetails) {
        if (data.agencyDetails.agencyCategory) {
          if (data.agencyDetails.agencyCategory.name) {
            if (data.agencyDetails.agencyCategory.name.toLowerCase() === keyword) {
              if(!this.agencyList.includes(data)){
                this.agencyList.push(data);
              }
             
            }

          }
        }
      }
    }
    

  }
  formSubmit() {
    //
    let JSONarray = {
      keyword: this.searchform.value.keyword,
      year: this.searchform.value.year,
      service: this.searchform.value.service,
      classification: this.searchform.value.classification,
      sector: this.searchform.value.sector,
      agency: this.searchform.value.agency,
      agencycategory: this.searchform.value.agencycategory,
    };
    this.router.navigate(['/artemis/results'], { queryParams: JSONarray });
  }

}
