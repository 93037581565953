import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { identity, Subject } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import {
  CharterStatusCitizensCharter,
  EffectivityCitizensCharter,
  GovernmentAccountSubtype,
  PostedServiceStatusCitizensCharter,
  RemarkLevelCitizensCharter,
  RemarkLevelRoleMappingCitizensCharter,
  RemarkResolvedStatusCitizensCharter,
  ServiceAssessmentCitizensCharter,
  WorklistArtaMappedStatusCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';
import { ArtemisServiceInformationPageConfirmSubmitModalComponent } from 'src/app/artemis/artemis-service-information-page/artemis-service-information-page-confirm-submit-modal/artemis-service-information-page-confirm-submit-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ArtemisDisapproveCharterReasonModalComponent } from '../../artemis-disapprove-charter-reason-modal/artemis-disapprove-charter-reason-modal.component';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { Router } from '@angular/router';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { DataTableDirective } from 'angular-datatables';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ArtemisCocModalComponent } from '../../artemis-coc-modal/artemis-coc-modal.component';
import { ArtemisCocPreviewPageArtaComponent } from '../artemis-coc-preview-page-arta/artemis-coc-preview-page-arta.component';
import { GovServiceService } from 'src/app/service/gov-service.service';
import Swal from 'sweetalert2';
import { EmailerService } from 'src/app/service/emailer.service';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';

@Component({
  selector: 'app-artemis-charter-inbox-page-arta-service-list',
  templateUrl: './artemis-charter-inbox-page-arta-service-list.component.html',
  styleUrls: ['./artemis-charter-inbox-page-arta-service-list.component.scss'],
})
export class ArtemisCharterInboxPageArtaServiceListComponent implements OnInit {
  results1: any = []; // main countainer for the inbox results list
  results2: any = []; // main countainer for the inbox results list

  @Input() service_ids: string[] = [];
  @Input() citizens_charter: string = '';
  @Input() agency_id: string = '';
  @Input() agency_name: string = '';
  @Output() toInboxComponent = new EventEmitter<object>();

  //angular table paginator
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();


  //mat table paginator
  displayedColumns: string[] = ['datemodified', 'servicename', 'status'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  account_type: string = '';
  enum_account_subtype = GovernmentAccountSubtype;
  enum_service_status = WorklistStatusCitizensCharter;
  hasPendingWorklist: boolean = false;
  hasNonCompliantService: boolean = false;
  allWorklistsApproved: boolean = false;
  submittedFlag: boolean = false;
  latestcertificateofcompliance: any;
  certificateofcompliancelist: any = []

  toAgencyPage:any ;

  private remark_ids_disapprove: string[] = [];

  private worklistFilters = new Map<string, string[]>([
    WorklistArtaMappedStatusCitizensCharter.ARTA_CMEO_PO,
    WorklistArtaMappedStatusCitizensCharter.ARTA_CMEO_DC,
    WorklistArtaMappedStatusCitizensCharter.ARTA_CMEO_DIR,
  ]);

  constructor(
    private afs: AngularFirestore,
    public dialog: MatDialog,
    public worklistService: WorklistService,
    public router: Router,
    private snackBarService: SnackbarNotifService,
    private remarkService: GovServiceService,
    private emailer : EmailerService,
    private nE : NotificationsEmailer
  ) {
    this.account_type = sessionStorage.getItem('account_subtype') || '';
  }

  ngOnInit(): void {
    // console.log('citizens_charter: ',this.citizens_charter)
    if (this.citizens_charter) {
      this.toAgencyPage = "/artemis/view/agency/" 
      this.loadCounts();
      this.loadAgencyServicesList();

      this.loadCertificateofComplianceList()
      //this.loadlatestCertificateofCompliance()
      this.dtOptions = {
        processing: true,
        lengthMenu: [15, 30, 50, 100],
        destroy: true,
        pagingType: 'full_numbers',
        pageLength: 15,
        searching: true,
        order:[3,'desc'],

      }

    }
  }
  ngAfterViewInit() {
    this.dtTrigger.next(this.dtOptions);
    this.gotoTop();
   
  }


  viewAgencyProfile(){
    window.open(this.toAgencyPage + this.agency_id,"_black")
  }
  //open certificate of compliance attached to the citzens charter
  viewCertificateofCompliance(){

   this.dialog.open(ArtemisCocPreviewPageArtaComponent , {
      data: {
         charter_id: this.citizens_charter,
         file_type: "Certificate of Compliance"
      }
    })
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    //this.dtTrigger2.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next(this.dtOptions);
    })

  }
  //set mat table datasource paginator
  resetSortPage() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort

  }

  //set mat table column sorting

  sortChange(sort: Sort) {
    const data = this.results2.slice()
    // console.log('sort.active',sort.active)
    // console.log('sort.direction',sort.direction)
    if (!sort.active || sort.direction === '') {
      this.results2 = data
      return;
    }
    this.results2 = data.sort((a: any, b: any) => {
      // console.log('results',this.results)
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {

        case 'datemodified':
          return this.compare(a.date_posted, b.date_posted, isAsc)
        case 'servicename':
          return this.compare(a.serviceDetails.service_name.trim().toLowerCase(), b.serviceDetails.service_name.trim().toLowerCase(), isAsc)
        case 'status':
          return this.compare(a.posted_status.trim().toLowerCase(), b.posted_status.trim().toLowerCase(), isAsc)

        default:
          return null
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.results2)
    this.resetSortPage()

  }
  //sort data 
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // loads data from database (firestore)
  // TODO: Do the new loading procedure
  async loadCounts() {
    this.results1 = [];

    const worklistFilterArr =
      this.worklistFilters.get(
        sessionStorage.getItem('account_subtype') || ''
      ) || [];

    // Because status filter is needed, CC id is now saved in Worklist-Citizens Charter to free up the in clause
    // Thus, snapshotChanges handling is returning in place of combineLatest
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('citizensCharter', '==', this.citizens_charter)
        .where('status', 'in', worklistFilterArr);

    this.afs
      .collection('Worklist-Citizens Charter', inboxFilter)
      .snapshotChanges()
      .pipe(
        this.submittedFlag
          ? identity
          : tap((data: any) => {
            // Reset everytime snapshotChanges triggers
            this.hasPendingWorklist = false;
            this.hasNonCompliantService = false;
            var tempAllWorklistsApproved = true;

            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;
              if (
                !this.hasPendingWorklist &&
                (
                  // For PO: also include FOR_ARTA_REVIEW_DC?
                  (this.account_type === GovernmentAccountSubtype.ARTA_CMEO_PO &&
                    [
                      WorklistStatusCitizensCharter.FOR_ARTA_REVIEW,
                      WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC
                    ].indexOf(item.status) > -1
                  ) ||

                  (this.account_type === GovernmentAccountSubtype.ARTA_CMEO_DIR &&
                    item.status === WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR
                  ) ||
                  // && item.status ===
                  // WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR) 


                  // For DC: also include FOR_ARTA_REVIEW_DIR?
                  (this.account_type === GovernmentAccountSubtype.ARTA_CMEO_DC &&
                    [
                      WorklistStatusCitizensCharter.FOR_ARTA_REVIEW,
                      WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC,
                      WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR
                    ].indexOf(item.status) > -1
                  )
                )
              ) {
                this.hasPendingWorklist = true;
              }

              if (
                !this.hasNonCompliantService &&
                item.assessment ===
                ServiceAssessmentCitizensCharter.NON_COMPLIANT
              ) {
                this.hasNonCompliantService = true;
              }

              if (
                tempAllWorklistsApproved &&
                !(
                  this.account_type ===
                  GovernmentAccountSubtype.ARTA_CMEO_DIR &&
                  item.status ==
                  WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED
                )
              ) {
                tempAllWorklistsApproved = false;
              }

              /* 
                Whenever worklist status is updated, snapshotChanges() fires off again,
                so ensure the snapshots do not get recklessly added to the list
              */
              if (info.type === 'modified') {
                // For modified snapshots, find and replace the entry
                for (var x in this.results1) {
                  const worklist = this.results1[x];
                  if (item.id === worklist.id) {
                    this.results1.splice(x, 1, item);
                  }
                }
              } else {
                // For added snapshots, check if worklist already exists in the list
                let addFlag = true;
                for (var worklist of this.results1) {
                  if (item.id === worklist.id) {
                    addFlag = false;
                    break;
                  }
                }

                if (addFlag) {
                  this.results1.push(item);
                }
              }
            });

            this.allWorklistsApproved = tempAllWorklistsApproved;
            this.rerender();
          })
      )
      .subscribe();
  }

  showOnInboxViewport() {
    this.toInboxComponent.emit({
      service_ids: [],
      citizens_charter: '',
      agency_id: '',
      agency_name:'',
    });
  }

  checkAllowSubmission() {

  }

  async submitWorklistsForApproval() {

    this.snackBarService.callLoadingmodal();
    const batch = this.afs.firestore.batch(); // batch uploader, firestore
    let checkWorklistStatus = '';
    let nextWorklistStatus = '';
    let nextCharterStatus = '';

    // set next statuses based on this.account_type
    if (this.account_type === GovernmentAccountSubtype.ARTA_CMEO_PO) {
      checkWorklistStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_PO_APPROVED;
      nextWorklistStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC;
      nextCharterStatus = CharterStatusCitizensCharter.FOR_DC_APPROVAL;
    } else if (this.account_type === GovernmentAccountSubtype.ARTA_CMEO_DC) {
      checkWorklistStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DC_APPROVED;
      nextWorklistStatus = WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR;
      nextCharterStatus = CharterStatusCitizensCharter.FOR_DIR_APPROVAL;
    } else {
      return;
    }

    // Update worklist/s
    let updateWorklistObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(nextWorklistStatus);

    for (var worklist of this.results1) {
      if (worklist.status === checkWorklistStatus) {
        let worklist_ref = this.afs.firestore
          .collection(`Worklist-Citizens Charter`)
          .doc(worklist.id);

        batch.update(worklist_ref, updateWorklistObj);
      }
    }

    // Update charter
    let citizens_charter_ref = this.afs.firestore
      .collection(`citizens-charter-phase-02`)
      .doc(this.citizens_charter);
    let updateCharterObj = {
      status: nextCharterStatus,
    };

    batch.update(citizens_charter_ref, updateCharterObj);

    this.hasPendingWorklist = false;

    await batch.commit().then(() => {
      Swal.close();
      this.snackBarService.displayNotif('success','Charter submitted!')
    });
  }

  async approveWorklist(id: string) {

    this.snackBarService.callLoadingmodal();
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let updateObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED
      );

    let worklist_ref = this.afs.firestore
      .collection(`Worklist-Citizens Charter`)
      .doc(id);

    batch.update(worklist_ref, updateObj);

    await batch.commit().then(() => { 
      Swal.close();
    });
  }

  async approveAll() {

    this.snackBarService.callLoadingmodal();

    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    let updateObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED
      );

    for (var worklist of this.results1) {
      if (
        worklist.status === WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR
      ) {
        let worklist_ref = this.afs.firestore
          .collection(`Worklist-Citizens Charter`)
          .doc(worklist.id);

        batch.update(worklist_ref, updateObj);
      }
    }

    await batch.commit().then(() => {
      Swal.close();
     });
  }

  triggerFinalWarning() {
    const dialogRef = this.dialog.open(
      ArtemisServiceInformationPageConfirmSubmitModalComponent,
      {
        disableClose: true,
        height: '480px',
        width: '640px',
        data: {
          finalWarning: true,
        },
      }
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.submitFlag && result.actionFlag) {
        this.postCC();
      } else {
        // console.log('cancelled!');
      }
    });
  }

  private async postCC() {

    this.snackBarService.callLoadingmodal();
    this.submittedFlag = true;

    const batch = this.afs.firestore.batch(); // batch uploader, firestore
  
    //get citizens charter version
    let cc_version : any = ""
     this.afs
      .collection(`citizens-charter-phase-02`)
      .doc(this.citizens_charter).ref.get().then( (doc:any)=>{
          if(doc.exists){
            let charter_data:any = doc.data();
            cc_version = "Citizen's Charter " + this.remarkService.transform(charter_data.edition,charter_data.date_posted.toDate().getFullYear()) 
          }
      });

 

    // service_ids could be changed
    let setServiceID: any = this.service_ids;
    let service_with_remarks: any = []
    // For date_posted
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    // Update worklist/s
    let updateWorklistObj: Record<string, any> =
      await this.worklistService.updateWorklistStatusObj(
        WorklistStatusCitizensCharter.POSTED
      );

    for (var worklist of this.results1) {
      if (
        worklist.status ===
        WorklistStatusCitizensCharter.FOR_ARTA_REVIEW_DIR_APPROVED
      ) {

        service_with_remarks.push(worklist.documentId)
        let worklist_ref = this.afs.firestore
          .collection(`Worklist-Citizens Charter`)
          .doc(worklist.id);

        batch.update(worklist_ref, updateWorklistObj);

        // Also post the service according to the worklist's effectivity type
        let updateService: any;

        switch (worklist.effectivity) {
          case EffectivityCitizensCharter.NEW:
          case EffectivityCitizensCharter.MODIFY_NEW:
            // NEW: Set service to posted
            updateService = this.afs.firestore
              .collection(`Services Phase 02`)
              .doc(worklist.documentId);
            batch.update(updateService, {
              is_posted: true,
              date_posted: today,
              cc_edition:cc_version,
              posted_status: PostedServiceStatusCitizensCharter.POSTED,
            });
            break;
          case EffectivityCitizensCharter.UPDATE:
          case EffectivityCitizensCharter.MODIFY_UPDATE:
            // Post new service
            updateService = this.afs.firestore
              .collection(`Services Phase 02`)
              .doc(worklist.documentId);
            batch.update(updateService, {
              is_posted: true,
              date_posted: today,
              cc_edition:cc_version,
              posted_status: PostedServiceStatusCitizensCharter.POSTED,
            });

            // "Delist" previous service
            const updatePreviousService = this.afs.firestore
              .collection(`Services Phase 02`)
              .doc(worklist.previousDocumentId);
            batch.update(updatePreviousService, {
              is_posted: false,
              date_posted: today,
              posted_status: PostedServiceStatusCitizensCharter.DELISTED,
            });

            // Remove previous updated service from CC
            for (var x in setServiceID) {
              if (setServiceID[x] === worklist.previousDocumentId) {
                setServiceID.splice(x, 1);
              }
            }
            break;
          case EffectivityCitizensCharter.DELIST:
          case EffectivityCitizensCharter.MODIFY_DELIST:
            // DELIST: Set POSTED_STATUS to DELISTED and set is_posted to false
            // Consider migrating from is_posted boolean to posted_status
            updateService = this.afs.firestore
              .collection(`Services Phase 02`)
              .doc(worklist.documentId);
            batch.update(updateService, {
              is_posted: false,
              date_posted: today,
              posted_status: PostedServiceStatusCitizensCharter.DELISTED,
            });

            // Remove delisted service from CC
            for (var x in setServiceID) {
              if (setServiceID[x] === worklist.documentId) {
                setServiceID.splice(x, 1);
              }
            }
            break;
        }
      }
    }

    this.hasPendingWorklist = false;
    this.remarkService.hideAllResolvedRemarks(service_with_remarks)
    // Update charter
    let citizens_charter_ref = this.afs.firestore
      .collection(`citizens-charter-phase-02`)
      .doc(this.citizens_charter);
    
    // CC uses a status so retrieving posted CCs is "status == 'POSTED' " rather than is_posted == true
    let updateCharterObj = {
      status: CharterStatusCitizensCharter.POSTED,
      date_posted: today,
      service_id: setServiceID,
    };

    batch.update(citizens_charter_ref, updateCharterObj);

    await batch.commit().then(() => {
      this.sendNotiftoAgencyForPostedCC(cc_version)
      Swal.close();
      this.snackBarService.displayNotif('success','Charter successfully posted!')
      this.showOnInboxViewport();
    });
  }

  async openReasonForDisapproval() {
    // TODO: Load 
    const user_position = sessionStorage.getItem('account_subtype') || '';

    const dialogRef = this.dialog.open(
      ArtemisDisapproveCharterReasonModalComponent,
      {
        disableClose: true,
        height: '480px',
        width: '640px',
        data: {
          defaultReason: "",
        },
      }
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.submitFlag && result.submitValue) {
        this.disapproveCC(result.submitValue);
      } else {
        // console.log('cancelled!');
      }
    });

    /**
     * this.afs
      .collection(`Remarks Artemis New`, (filter) =>
        filter
          .where('remark_citizens_charter', '==', this.citizens_charter)
          .where('remark_level', 'in', [RemarkLevelRoleMappingCitizensCharter[user_position]])
          .orderBy('remark_date', 'asc')
      )
      .snapshotChanges()
      .pipe(
        tap(
          async (data: any) => {
            let defaultReason = '';

            data.forEach((remarkObj: any) => {
              const remark_data = remarkObj?.payload?.doc?.data();
              const remark_id = remarkObj?.payload?.doc?.id;

              const isPODCRemark = remark_data.remark_user?.position == GovernmentAccountSubtype.ARTA_CMEO_PO || remark_data.remark_user?.position == GovernmentAccountSubtype.ARTA_CMEO_DC;

              const service_remark_obj = remark_data.service_remark_obj;
              let serviceRemarkAppend = '';

              const service_eodb_tags_remark_obj = remark_data.service_eodb_tags_remark_obj;
              let eodbTagsAppend = '';

              const service_steps_remarks = remark_data.service_steps_remarks || new Map();
              let serviceStepsAppend = '';

              const service_agency_actions_remarks = remark_data.service_agency_actions_remarks || new Map();
              let agencyActionsAppend = '';

              if (service_remark_obj?.service_remark && service_remark_obj.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED && isPODCRemark) {
                serviceRemarkAppend = `Service: ${service_remark_obj.service_remark}`;
                this.remark_ids_disapprove = [...new Set([remark_id, ...this.remark_ids_disapprove])];
              }

              if (service_eodb_tags_remark_obj?.service_eodb_tags_remark && service_eodb_tags_remark_obj.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED && isPODCRemark) {
                eodbTagsAppend = `EODB Tags: ${service_eodb_tags_remark_obj.service_eodb_tags_remark}`;
                this.remark_ids_disapprove = [...new Set([remark_id, ...this.remark_ids_disapprove])];
              }

              let stepRemarksArr = [];
              for (let [k, v] of Object.entries<any>(service_steps_remarks)) {
                if (v.step_remark && v.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED && isPODCRemark) {
                  stepRemarksArr.push(`${v.step_title}: ${v.step_remark}`);
                  this.remark_ids_disapprove = [...new Set([remark_id, ...this.remark_ids_disapprove])];
                }
              }
              serviceStepsAppend = `${stepRemarksArr.join(';')}`;

              let agencyActionArr = [];
              for (let [k, v] of Object.entries<any>(service_agency_actions_remarks)) {
                if (v.action_remark && v.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED && isPODCRemark) {
                  agencyActionArr.push(`${v.action_title}: ${v.action_remark}`);
                  this.remark_ids_disapprove = [...new Set([remark_id, ...this.remark_ids_disapprove])];
                }
              }
              agencyActionsAppend = `${agencyActionArr.join(';')}`;

              if (serviceRemarkAppend || eodbTagsAppend || serviceStepsAppend || agencyActionsAppend) {
                let appendValue =
                  `${remark_data.remark_service_name}
                ${serviceRemarkAppend}
                ${eodbTagsAppend}
                ${serviceStepsAppend}
                ${agencyActionsAppend}`;

                defaultReason += appendValue;
              }
            });

            const dialogRef = this.dialog.open(
              ArtemisDisapproveCharterReasonModalComponent,
              {
                disableClose: true,
                height: '480px',
                width: '640px',
                data: {
                  defaultReason: defaultReason,
                },
              }
            );
            dialogRef.afterClosed().subscribe(async (result) => {
              if (result && result.submitFlag && result.submitValue) {
                this.disapproveCC(result.submitValue);
              } else {
                // console.log('cancelled!');
              }
            });
          }
        ),
        take(1)
      )
      .subscribe();
     */
  }

  private async disapproveCC(reason_for_disapproval: string) {

    
    this.submittedFlag = true;

    const batch = this.afs.firestore.batch(); // batch uploader, firestore
    let service_ids: any = []
    // Update worklist/s
    for (var worklist of this.results1) {
      let worklist_ref = this.afs.firestore
        .collection(`Worklist-Citizens Charter`)
        .doc(worklist.id);

      service_ids.push(worklist.documentId)

      let modifyEffectivityVal = '';
      switch (worklist.effectivity) {
        case EffectivityCitizensCharter.UPDATE:
          modifyEffectivityVal = EffectivityCitizensCharter.MODIFY_UPDATE;
          break;
        case EffectivityCitizensCharter.DELIST:
          modifyEffectivityVal = EffectivityCitizensCharter.MODIFY_DELIST;
          break;
        case EffectivityCitizensCharter.NEW:
        default:
          modifyEffectivityVal = EffectivityCitizensCharter.MODIFY_NEW;
          break;
      }

      // Set worklist to FOR_MODIFICATION (if service was non-compliant) or APPROVED (if service was compliant)
      let updateWorklistObj: Record<string, any> =
        await this.worklistService.updateWorklistStatusObj(
          worklist.assessment ===
            ServiceAssessmentCitizensCharter.NON_COMPLIANT
            ? WorklistStatusCitizensCharter.FOR_MODIFICATION
            : WorklistStatusCitizensCharter.APPROVED
        );
      updateWorklistObj.effectivity = modifyEffectivityVal;

      batch.update(worklist_ref, updateWorklistObj);
    }
    this.remarkService.hideAllResolvedRemarks(service_ids)
    // ARTA-level remarks need to be viewed by the Agency once returned, so change the remark level to "Returned"
    let updateRemarkObj = {
      remark_level: RemarkLevelCitizensCharter.LEVEL_RETURN
    };

    this.remark_ids_disapprove.forEach((remark_id: string) => {
      let remark_ref = this.afs.firestore
      .collection(`Remarks Artemis New`)
      .doc(remark_id);

      batch.update(remark_ref, updateRemarkObj);
    });

    this.hasPendingWorklist = false;

    // Update charter
    let citizens_charter_ref = this.afs.firestore
      .collection(`citizens-charter-phase-02`)
      .doc(this.citizens_charter);
    let updateCharterObj = {
      status: CharterStatusCitizensCharter.FOR_REVISION,
      remark_disapproval_arta_dir: reason_for_disapproval,
      returnedByArtaForRevision: true,
    };

    batch.update(citizens_charter_ref, updateCharterObj);

    await batch.commit().then(() => {
      this.sendNotifForDisapprovedCC(reason_for_disapproval)
      this.snackBarService.displayNotif('success','Returned charter to agency!')
      this.showOnInboxViewport();
    });
  }

  //load latest certificate of compliance based on citizens charted submitted
 /***
  * 
  *  loadlatestCertificateofCompliance() {
    console.log('CCid',this.citizens_charter)
    this.afs.collection('Certificate of Compliance Submissions',
      filter => filter
        .where('citizensCharter_Id', '==', this.citizens_charter))
      .snapshotChanges()
      .pipe(first())
      .subscribe((data: any) => {

        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id
          item.cocsubmission = true
          this.latestcertificateofcompliance = item
          console.log('COC data', item)
        })

        this.createCOC()


      })

  }
  */

  ///load all certificate of cimpliance
  //submissions
  loadCertificateofComplianceList() {

    this.afs.collection('Certificate of Compliance Submissions',
      filter => filter
        .where('citizensCharterId', '==', this.citizens_charter)
        .where('fromAgencyId', '==', this.agency_id))
      .snapshotChanges()
      .pipe(tap((data: any) => {

        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id
          this.certificateofcompliancelist.push(item)

        })
        //console.log('Certification List',this.certificateofcompliancelist)
      })).subscribe()


  }

  createCOC() {
    
    const data: any = this.latestcertificateofcompliance
    let agencyData: any = {
      fromAgencyName: data.fromAgencyName,
      year: data.citizenscharterVersion.year,
      edition: data.citizenscharterVersion.edition,
      id: this.agency_id,
      personResponsible: {
        name: data.personResponsible.name,
        position: data.personResponsible.position

      },
      citizenscharterPosting: {
        billboard: data.citizenscharterPosting.billboard,
        handbook: data.citizenscharterPosting.handbook,
        online: data.citizenscharterPosting.online
      },
      offices: data.offices
    };
    let dialogref = this.dialog.open(ArtemisCocModalComponent, {
      height: '80vh',
      data: {
        agency: agencyData,
      }

    });

  }

  sendNotifForDisapprovedCC(reason: any){

     //get head of agency email
     const userRef =   this.emailer.getUsers(EmailTemplateCode.ARTA_DIR_RETURN_CC_TO_ENCODER,this.agency_id )
    
     let emaillist : any[] = []

     const Variables = {
       Agency : this.agency_name,
       ReasonForDisapproval :  reason
     }
 
     userRef.pipe(take(1))
     .subscribe({
       next : (responses)=>{
 
         responses.map((response)=>{
            let item:any =  response.payload.doc.data()
            emaillist.push(item.credentials.email) 
 
         })

         this.nE.emailServiceHandler(emaillist,
          EmailAppCode.ARTEMIS,
          EmailTemplateCode.ARTA_DIR_RETURN_CC_TO_ENCODER,Variables )
 
       }
 
       
     })

  }
  

  sendNotiftoAgencyForPostedCC(citizensCharter: any){


    //get head of agency email
    const userRef =   this.emailer.getUsers(EmailTemplateCode.ARTA_DIR_POST_CC,this.agency_id )
    

    let emaillist : any[] = []

    userRef.pipe(take(1))
    .subscribe({
      next : (responses)=>{

        responses.map((response)=>{
           let item:any =  response.payload.doc.data()
           emaillist.push(item.credentials.email)

        })

        this.sendNotif(emaillist,citizensCharter)

      }

      
    })

  }

  sendNotif(emaillist: any[],citizenscharter: any){

    const docRef = this.emailer.pulloutAgencyEmails(this.agency_id)
    
    docRef.pipe(take(1))
    .subscribe({
    next : (responses)=>{

        let item :any = responses.payload.data();
        
        const emails:any[] = item.contactDetails.emailArray

        for (var email of emails){
          emaillist.push(email.email)
        }
       const agencyName = item.agencyDetails.agencyName
      
        this.emailer.sendPostNotifToAgency(emaillist, agencyName ,citizenscharter )
        
    }
  })
  }

  async loadAgencyServicesList() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('is_posted', '==', true)
        .where('fromAgencyId', '==', this.agency_id);

    this.afs
      .collection('Services Phase 02', inboxFilter)
      .snapshotChanges()
      .pipe(
        tap((data: any) => {

          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            this.results2.push(item)
          });
          this.dataSource = new MatTableDataSource(this.results2);
          this.resetSortPage();
          let sortData: Sort = {
            active: 'status',
            direction: 'asc'
          }
          this.sortChange(sortData)

        }),
        take(1)
      )
      .subscribe(() => {

      });
  }

  goToServiceDetail(id: any, documentId: any) {
    // add to session
    // console.log(JSON.stringify(this.service_ids))
    sessionStorage.setItem(
      'artemisInboxActiveMode',
      'Arta Check Service Inbox'
    );
    sessionStorage.setItem('artemisInboxActivated', 'Arta Check Service Inbox');
    sessionStorage.setItem('agencyId', this.agency_id);
    sessionStorage.setItem('ccId', this.citizens_charter);
    sessionStorage.setItem('serviceId', JSON.stringify(this.service_ids));

    // sent to intended
    this.router.navigate(['/artemis/view/detail', documentId], {
      queryParams: { worklistId: id },
    });
  }
}
