import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ReferenceService } from '../../service/reference.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmResetPasswordComponent } from '../../modal-manager/confirm-reset-password/confirm-reset-password.component';
import { ReuseLoginComponent } from '../../modal-manager/reuse-login/reuse-login.component';
import { matchingPassword, needsStrongerPassword, phoneNumberValidator } from '../../validators';
import { formatISO, parseISO } from 'date-fns';
import { FileUploadService } from '../../file-upload.service';
import { HttpEventType } from '@angular/common/http';
import { emailVerified } from '@angular/fire/compat/auth-guard';
import { AgencyAddComponent } from 'src/app/agency-control/agency-add/agency-add.component';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { PBRISEmailer } from 'src/app/service/pbris-emailer.service';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';

@Component({
  selector: 'app-registration-pbris',
  templateUrl: './registration-pbris.component.html',
  styleUrls: ['./registration-pbris.component.scss']
})
export class RegistrationPbrisComponent implements OnInit {
  public show_terms_and_conditions: boolean = true;
  public show_data_privacy: boolean = false;
  loading: boolean = false;
  public showFormErrors: boolean = false;
  modalRef?: MDBModalRef;

  public show_first_registration: boolean = false;
  public show_second_registration: boolean = false;
  public show_personal_registration: boolean = false;
  public show_company_registration: boolean = false;
  public show_government_registration: boolean = false;
  public show_final_registration: boolean = false;
  public show_recaptcha_confirmation: boolean = false;
  public show_government_id_input: boolean = false;
  public show_agency_id_input: boolean = false;
  public show_authorization_letter_input: boolean = false;
  public show_back_button: boolean = false;
  public show_next_button: boolean = true;
  public show_submit_button: boolean = false;
  public show_final_send_button: boolean = false;

  public firstTextFieldTitle: any = 'Last Name';
  public secondTextFieldTitle: any = 'First Name';
  public thirdTextFieldTitle: any = 'Area of Residence';
  public firstDropdownTitle: any = 'Government Issued ID';
  public typeOfAccountToggle: any = 'Personal';
  public buttonName: any = 'Next';
  uploading: boolean = true;
  needsRecaptcha: boolean = true;
  recaptchaV2SiteKey: string = environment.recaptchaSiteKeys.v2;
  private singleExecutionSubscription: Subscription | undefined;

  // upload files
  gov_id_path: any = "";
  company_id_path: any = "";
  auth_letter_path: any = "";

  gov_uploadProgress!: Observable<number | undefined>;
  com_uploadProgress!: Observable<number | undefined>;
  auth_uploadProgress!: Observable<number | undefined>;

  government_id: any = "";
  company_id: any = "";
  authorization_letter: any = "";
  expiresOnDateISO: string = "";

  display_government_id: string = "";
  display_company_id: string = "";
  display_authorization_letter: string = "";

  public GovernmentIssuedIDType = Array<string>();
  public TypeOfAccount = Array<string>();
  /**
   * Firestore data
   */
  public terms_and_conditions_JSON: any;
  public privacy_policy_JSON: any;
  public gov_agency: any = [];
  public sectors: any = [];

  /**
   * Forms
   */
  personalregistrationform = new UntypedFormGroup({
    last_name: new UntypedFormControl('', Validators.required),
    first_name: new UntypedFormControl('', Validators.required),
    sex: new UntypedFormControl('', Validators.required),
    area_of_residence: new UntypedFormControl('', Validators.required),
    idtype: new UntypedFormControl('', Validators.required),
    idnumber: new UntypedFormControl('', Validators.required)
  });
  registrationform = new UntypedFormGroup({
    email: new UntypedFormControl('',
      {
        validators: [Validators.required, Validators.email],
        updateOn: "blur"
      }
    ),
    password: new UntypedFormControl('',
      {
        validators: [Validators.required],
        updateOn: "blur"
      }

    ),
    passwordStrength: new UntypedFormControl(0,
      {
        validators: [needsStrongerPassword()],
      }
    ),
    confirmpassword: new UntypedFormControl('',
      {
        validators: [Validators.required, matchingPassword()],
        updateOn: "blur"
      }
    ),
    mobile_number: new UntypedFormControl('',
      {
        validators: [Validators.required, phoneNumberValidator()],
        updateOn: "blur"
      }
    ),
    account_type: new UntypedFormControl('',
      {
        validators: [Validators.required],
        updateOn: "blur"
      }
    )
  });
  companyregistrationform = new UntypedFormGroup({
    name_of_organization: new UntypedFormControl('', Validators.required),
    organization_address: new UntypedFormControl('', Validators.required),
    official_designation: new UntypedFormControl('', Validators.required),
    sector: new UntypedFormControl('', Validators.required)
  });
  governmentregistrationform = new UntypedFormGroup({
    agency: new UntypedFormControl('', Validators.required),
    subagency: new UntypedFormControl('', Validators.required),
    official_designation: new UntypedFormControl('', Validators.required)
  });
  finalregistrationform = new UntypedFormGroup({
    government_id: new UntypedFormControl('', Validators.required),
    company_id: new UntypedFormControl('', Validators.required),
    authorization_letter: new UntypedFormControl('', Validators.required)
  });

  constructor(private myElement: ElementRef, private router: Router,
    private afs: AngularFirestore, public auth: AngularFireAuth,
    private recaptchaV3Service: ReCaptchaV3Service,
    private referenceService: ReferenceService, public modalService: MDBModalService,
    public fileUploadService: FileUploadService,
    private nE: NotificationsEmailer,
    private pEmail: PBRISEmailer,
  ) { }

  ngOnInit(): void {
    this.getRegistrationContracts();
    this.getAgencies();
    this.getSectors();
    this.getGovernmentIssuedIdTypes();
    this.show_next_button = false;

    this.TypeOfAccount = Array<string>();
    this.TypeOfAccount.push('Personal');
    // Return Company user registration in Phase 2
    this.TypeOfAccount.push('Company');
    this.TypeOfAccount.push('Government');
  }

  public ngOnDestroy(): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

  /**
   * Firestore Data Retrieval Functions
   */
  getRegistrationContracts() {
    this.getTermsConditions();
    this.getPrivacyPolicy();
  }

  getTermsConditions() {
    this.terms_and_conditions_JSON = [];
    this.afs.collection("registration contracts").doc("PBRIS").collection("terms_and_conditions").snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.terms_and_conditions_JSON.includes(item) == false) {
            this.terms_and_conditions_JSON.push(item);
          }
        });
      });
  }

  getPrivacyPolicy() {
    this.privacy_policy_JSON = [];
    this.afs.collection("registration contracts").doc("PBRIS").collection("privacy_policy").snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.privacy_policy_JSON.includes(item) == false) {
            this.privacy_policy_JSON.push(item);
          }
        });
      });
  }

  getAgencies() {
    let JSONholder: any = [];
    this.afs.collection("Agency", filter => filter.orderBy('name', 'asc')).snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {

              let item: any = info.payload.doc.data();
              item.id = info.payload.doc.id;

              if (this.gov_agency.includes(item) === false) {
                this.gov_agency.push(item);
              }
            });
          }
        ),
        take(1)
      )
      .subscribe();
    //this.terms_and_conditions = JSONholder[0]["full text"];
  }
  getSectors() {
    let JSONholder: any = [];
    this.afs.collection("Sector").snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.sectors.includes(item) === false) {
            this.sectors.push(item);
          }
        });
      });
    //this.terms_and_conditions = JSONholder[0]["full text"];
  }

  getGovernmentIssuedIdTypes() {
    this.afs.collection("References")
      .doc("common").collection("government_issued_id_types", filter => filter.orderBy("order"))
      .snapshotChanges().subscribe(
        (data: any) => {
          data.forEach((info: any) => {

            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (this.GovernmentIssuedIDType.includes(item.value) === false) {
              this.GovernmentIssuedIDType.push(item.value);
            }
          });
        });
  }
  /**
   * END: Firestore Data Retrieval Functions
   */

  loginAsGuest() {
    this.router.navigate(['/pbris']);
  }

  checkbox(event: any) {
    if (this.show_terms_and_conditions) {
      this.show_next_button = false;
      if (event.target.checked) {
        this.show_next_button = true;
      }
      else {
        this.show_next_button = false;
      }
    }

    else if (this.show_data_privacy) {
      this.show_next_button = false;
      if (event.target.checked) {
        this.show_next_button = true;
      }
      else {
        this.show_next_button = false;
      }
    }
  }

  toggleNext() {
    // GO TO NEXT PAGE.

    // terms & conditions to first registration
    if (this.show_terms_and_conditions) {
      this.show_terms_and_conditions = false;
      this.show_data_privacy = true;

      this.show_back_button = true;
      this.show_next_button = false;
    }

    else if (this.show_data_privacy) {
      this.show_data_privacy = false;
      this.show_first_registration = true;

      this.show_back_button = true;
    }



    // first registration to personal/company/government registration
    else if (this.show_first_registration) {
      if (!this.registrationform.valid) {
        this.showFormErrors = true;
      }
      else {
        this.showFormErrors = false;
        this.afs.collection(`Users`, filter => filter.where('credentials.email', '==', this.registrationform.value.email).limit(1))
          .snapshotChanges()
          .pipe(
            tap((data) => {
              var emailExists = false;
              var accessToArtemis = false;
              var accessToPbris = false;

              if (data.length) {
                emailExists = true;
                data.forEach((entry: any) => {
                  var item = entry.payload.doc.data();
                  var userId = entry.payload.doc.id;

                  accessToArtemis = item.credentials.access_artemis;
                  accessToPbris = item.credentials.access_pbris;
                  var retrievedUserAccountType = item.credentials.account_type

                  if (accessToPbris) {
                    this.modalRef = this.modalService.show(ConfirmResetPasswordComponent,
                      {
                        backdrop: 'static',
                        data: {
                          header_content: {
                            logo: "/assets/arta-logo.png"
                          },
                          body_content: {
                            message: "You have already registered for this account. Would you like to reset this account's password?"
                          },
                          buttons: {
                            confirm: "Yes, please reset my password.",
                            cancel: "No thanks."
                          },
                          email: this.registrationform.value.email,
                          login_route: "/pbris",
                          notif_message_code: "user_registration_reset_password_pbris",
                          redirect_url: environment.verificationRedirectUrls.pbris
                        }
                      });
                  }
                  else if (accessToArtemis) {
                    // If user has no access to PBRIS but has access to the other system
                    switch (retrievedUserAccountType) {
                      case "Personal":
                        this.modalRef = this.modalService.show(ReuseLoginComponent,
                          {
                            backdrop: 'static',
                            data: {
                              header_content: {
                                logo: "/assets/arta-logo.png"
                              },
                              body_content: {
                                message: "You already have an existing login for ARTEMIS. Would you like to use the same login for PBRIS?"
                              },
                              buttons: {
                                confirm: "Yes, I will use the same login.",
                                cancel: "No, I would use a new login."
                              },
                              notif_message_code: "user_registration_auto_approve_pbris",
                              userId: userId,
                              login_route: "/pbris",
                              updateAccessObj: {
                                "credentials.access_pbris": true
                              }
                            }
                          });
                        break;
                      case "Company":
                      case "Government":
                        this.referenceService.getNotification("user_registration_notify_admin").then((data) => {
                          data.subscribe((entry) => {
                            alert(entry);
                            this.router.navigate(['/pbris']);
                          })
                        });
                        break;
                      default:
                        alert("should not happen!")
                    }
                  }
                  else {
                    switch (retrievedUserAccountType) {
                      case "Company":
                      case "Government":
                        this.referenceService.getNotification("user_registration_disallow").then((data) => {
                          data.pipe(
                            tap((entry) => {
                              alert(entry);
                            }),
                            take(1)
                          ).subscribe()
                        });
                        break;
                      default:
                        alert("should not happen! individual users must have access to at least one site!")
                    }
                  }
                });
              }
              else {
                //If no email, move on to next page
                switch (this.registrationform.value.account_type) {
                  case "Personal":
                    this.show_first_registration = false;
                    this.show_personal_registration = true;
                    this.show_back_button = true;
                    break;
                  case "Company":
                    this.show_first_registration = false;
                    this.show_company_registration = true;
                    this.show_back_button = true;
                    break;
                  case "Government":
                    this.show_first_registration = false;
                    this.show_government_registration = true;
                    this.show_back_button = true;
                    break;
                  default:
                  //this.show_final_registration = true;
                }
              }
            }),
            take(1)
          ).subscribe();
      }
    }

    // personal/company/government registration to final registration
    else if (this.show_personal_registration || this.show_company_registration || this.show_government_registration) {
      if (
        (this.show_personal_registration && !this.personalregistrationform.valid) ||
        (this.show_company_registration && !this.companyregistrationform.valid) ||
        (this.show_government_registration && !this.governmentregistrationform.valid)
      ) {
        this.showFormErrors = true;
      }
      else {
        this.showFormErrors = false;

        this.show_personal_registration = false;
        this.show_company_registration = false;
        this.show_government_registration = false;

        switch (this.registrationform.value.account_type) {
          case "Personal":
            this.show_government_id_input = true;
            //this.show_agency_id_input = true;
            //this.show_authorization_letter_input = true;
            break;
          case "Company":
            //this.show_government_id_input = true;
            this.show_agency_id_input = true;
            this.show_authorization_letter_input = true;
            break;
          case "Government":
            this.show_government_id_input = true;
            //this.show_agency_id_input = true;
            this.show_authorization_letter_input = true;
            break;
          default:
          //this.show_final_registration = true;
        }

        this.show_final_registration = true;
        this.show_back_button = true;
        this.show_next_button = false;
        this.show_submit_button = true;
      }
    }

    // after final registration is accomplished, do one more final recaptcha validation before user submission
    else if (this.show_final_registration) {
      this.show_final_registration = false;
      this.show_recaptcha_confirmation = true;
      this.show_next_button = false;
      this.show_submit_button = false;
      this.show_final_send_button = true;
    }
  }

  toggleBack() {
    this.show_submit_button = false;
    this.show_next_button = true;
    // GO BACK A PAGE.
    //|| this.show_company_registration || this.show_government_registration
    if (this.show_terms_and_conditions) {
      this.show_terms_and_conditions = true;
      this.show_back_button = false;
    }

    else if (this.show_data_privacy || this.show_first_registration) {
      this.show_terms_and_conditions = true;
      this.show_data_privacy = false;
      this.show_first_registration = false;

      this.show_back_button = false;
      this.show_next_button = false;
    }

    else if (this.show_personal_registration || this.show_company_registration || this.show_government_registration) {
      this.show_personal_registration = false;
      this.show_company_registration = false;
      this.show_government_registration = false;


      this.show_first_registration = true;

      this.show_back_button = true;
    }

    else if (this.show_final_registration) {
      this.show_final_registration = false;
      this.show_government_id_input = false;
      this.show_agency_id_input = false;
      this.show_authorization_letter_input = false;

      switch (this.registrationform.value.account_type) {
        case "Personal":
          this.show_personal_registration = true;
          break;
        case "Company":
          this.show_company_registration = true;
          break;
        case "Government":
          this.show_government_registration = true;
          break;
        default:
          this.show_first_registration = true;
      }

      this.show_back_button = true;
    }

    else if (this.show_recaptcha_confirmation) {
      this.show_final_registration = true;
      this.show_next_button = false;
      this.show_submit_button = true;
      this.show_final_send_button = false;
      this.show_recaptcha_confirmation = false;
      this.needsRecaptcha = true;
    }
  }

  upload(event: any, type: any) {
    var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;
    if (!allowedExtensions.exec(event.target.files[0].name)) {
      this.finalregistrationform.reset();
      this.government_id = "";
      this.company_id = "";
      this.authorization_letter = "";
      this.display_government_id = "";
      this.display_company_id = "";
      this.display_authorization_letter = "";
      this.uploading = true;
      this.showFormErrors = true;
      return;
    }
    this.showFormErrors = false;

    if (type === "gov_id") {
      this.gov_id_path = event.target.files[0]
      this.display_government_id = this.gov_id_path.name;

      if (this.gov_id_path !== null) {
        this.uploading = true
        // new path to make it somewhat less difficult to navigate
        const filePath = `government-id/pbris/${formatISO(Date.now())}`;

        //new method that uses an API endpoint to access file storage through google cloud
        //old method was due to firebase DNS issues
        this.fileUploadService.uploadFile(this.gov_id_path, filePath)
          .subscribe((resp) => {
            if (resp.type === HttpEventType.Response) {
              console.log('Upload complete');
              this.expiresOnDateISO = resp.body.expiresOn;
              this.government_id = resp.body.downloadUrl;
              this.uploading = false;
            }
            if (resp.type === HttpEventType.UploadProgress) {
              const total = resp.total || 1
              const percentDone = Math.round(100 * resp.loaded / total);
              this.gov_uploadProgress = of(percentDone);
            }
          });
      }
    }

    if (type === "company_id") {
      this.company_id_path = event.target.files[0]
      this.display_company_id = this.company_id_path.name;

      if (this.company_id_path !== null) {
        this.uploading = true
        // new path to make it somewhat less difficult to navigate
        const filePath = `company-id/pbris/${formatISO(Date.now())}`;

        //new method that uses an API endpoint to access file storage through google cloud
        //old method was due to firebase DNS issues
        this.fileUploadService.uploadFile(this.company_id_path, filePath)
          .subscribe((resp) => {
            if (resp.type === HttpEventType.Response) {
              console.log('Upload complete');
              this.expiresOnDateISO = resp.body.expiresOn;
              this.company_id = resp.body.downloadUrl;
              this.uploading = false;
            }
            if (resp.type === HttpEventType.UploadProgress) {
              const total = resp.total || 1
              const percentDone = Math.round(100 * resp.loaded / total);
              this.com_uploadProgress = of(percentDone);
            }
          });
      }
    }

    if (type === "auth_letter") {
      this.auth_letter_path = event.target.files[0];
      this.display_authorization_letter = this.auth_letter_path.name;

      if (this.auth_letter_path !== null) {
        this.uploading = true
        // new path to make it somewhat less difficult to navigate
        const filePath = `auth-letter/pbris/${formatISO(Date.now())}`;

        //new method that uses an API endpoint to access file storage through google cloud
        //old method was due to firebase DNS issues
        this.fileUploadService.uploadFile(this.auth_letter_path, filePath)
          .subscribe((resp) => {
            if (resp.type === HttpEventType.Response) {
              console.log('Upload complete');
              this.expiresOnDateISO = resp.body.expiresOn;
              this.authorization_letter = resp.body.downloadUrl;
              this.uploading = false;
            }
            if (resp.type === HttpEventType.UploadProgress) {
              const total = resp.total || 1
              const percentDone = Math.round(100 * resp.loaded / total);
              this.auth_uploadProgress = of(percentDone);
            }
          });
      }

    }
  }

  submitForm() {
    this.singleExecutionSubscription = this.recaptchaV3Service.execute('registration')
      .subscribe((token) => {
        this.secureCreateUser(token);
      },
        (error) => {
          alert(error);
        });
  }

  secureCreateUser(token: string) {
    this.loading = true;
    // Hide recaptcha input when user creation has been submitted
    this.show_recaptcha_confirmation = false;

    this.auth.createUserWithEmailAndPassword(this.registrationform.value.email,
      this.registrationform.value.password).then(async cred => {
        let id = cred.user?.uid

        switch (this.registrationform.value.account_type) {
          case "Personal":
            cred.user?.updateProfile({
              displayName: this.personalregistrationform.value.first_name +
                " " + this.personalregistrationform.value.last_name
            });
            cred.user?.sendEmailVerification({
              url: environment.verificationRedirectUrls.pbris
            });
            break;

          case "Company":
            cred.user?.updateProfile({
              displayName: this.companyregistrationform.value.name_of_organization
            })
            break;

          case "Government":
            this.afs.doc(`Agency/${this.governmentregistrationform.value.agency}`).get().subscribe(
              agency => {
                cred.user?.updateProfile({
                  displayName: agency.get("name") + " ("
                    + this.governmentregistrationform.value.subagency + ")"
                })
              }
            )

            break;

          default:
        }
        const adminEmails: any = await this.pEmail.getAdminEmails(); //for the admin email
        await this.afs.doc(`Users/${id}`).set({
          government: this.governmentregistrationform.value,
          personal: this.personalregistrationform.value,
          company: this.companyregistrationform.value,
          file: {
            government_id: this.government_id,
            company_id: this.company_id,
            authorization_letter: this.authorization_letter,
            downloadLinkExpiresOn: parseISO(this.expiresOnDateISO) ? parseISO(this.expiresOnDateISO) : undefined
          },

          credentials: {
            uid: id,
            admin: false,
            access_artemis: false,
            access_pbris: true,
            loggedIn: new Date(),
            sinceMember: cred.user?.metadata.creationTime,
            email: this.registrationform.value.email,
            mobile_number: this.registrationform.value.mobile_number,
            account_type: this.registrationform.value.account_type
          },

          registrationRecaptchaV3Token: token

        }).then(data => {

          this.toNotifications(this.governmentregistrationform.value.agency, id)
          this.toSendNotifEmail(id)
          // email
          if (this.registrationform.value.account_type == "Government") {
            this.nE.emailServiceHandler(adminEmails, EmailAppCode.ARTA, EmailTemplateCode.GOV_NEWLY_REGISTERED, data, this.nE.footerPBRIS) //it admin
          }
          this.nE.emailServiceHandler([this.registrationform.value.email], EmailAppCode.ARTA, EmailTemplateCode.NEW_USER_REGISTRATION, data, this.nE.footerPBRIS) //self
          // Force sign out because approver access is required
          this.auth.signOut();
          if (this.gov_uploadProgress || this.com_uploadProgress || this.auth_uploadProgress) {
            this.loading = false
            switch (this.registrationform.value.account_type) {
              case "Personal":
                this.referenceService.getNotification("user_registration_success_email_verification").then((data) => {
                  data.pipe(
                    tap((entry) => {
                      alert(entry);
                      this.router.navigate(['/pbris']);
                    }),
                    take(1)
                  ).subscribe()
                });
                break;
              case "Company":
              case "Government":
                this.referenceService.getNotification("user_registration_success_admin").then((data) => {
                  data.pipe(
                    tap((entry) => {
                      alert(entry);
                      this.router.navigate(['/pbris']);
                    }),
                    take(1)
                  ).subscribe()
                });
                break;
            }

          }

        });
      }).catch(message => {
        this.loading = false
        alert(message)
      })
  }

  onStrengthChanged(e: number) {
    this.registrationform.patchValue({
      passwordStrength: e
    });
  }

  resolved(e: any) {
    this.needsRecaptcha = false;
  }

  toNotifications(agency: any, uid: any) {
    let toNotifCollectionJSON = {
      uid: uid,
      agency_id: agency,
      event: 'New User',
      message: 'This is a notification Message',
      level: 'Level test',
      notif_type: 'Account-Creation',
      notif_in: 'pbris'
    }
    this.afs.collection('Notifications').add(toNotifCollectionJSON)
  }

  toSendNotifEmail(uid: any) {
    console.log('sending email')
    let templateCode: number = 0;
    let data: any = {}
    if (this.registrationform.value.account_type == 'Personal' || this.registrationform.value.account_type == 'Company') {
      templateCode = EmailTemplateCode.NEW_USER_REGISTRATION;
      data.link = 'https://arta-respond.web.app/account/' + uid;
    }
    else if (this.registrationform.value.account_type == 'Government') {
      templateCode = EmailTemplateCode.GOV_NEWLY_REGISTERED;
      data.canAccess = ['A', 'B', 'C'];
      data.workListLink = 'https://arta-respond.web.app/pbris/regulatory-management';
      data.accountSettingsLink = 'https://arta-respond.web.app/account/' + uid;
      data.helpLink = 'https://arta-respond.web.app/pbris/help';
    }
    // if(templateCode > 0){
    // this.nE.sendEmailNotiftoUser([this.registrationform.value.email],1,templateCode,data)
    // .subscribe({
    //     next:(apiResponse)=>{
    //         console.log('RegistrationPbrisComponent emailer: ',apiResponse)
    //     },
    //     error:(err)=>{
    //         console.error('RegistrationPbrisComponent emailer error: ',err)
    //     }
    // })
    // } else console.error('RegistrationPbrisComponent emailer error: no email template for account type')
  }

}


