import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router, } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { verify } from 'crypto';
import { emailVerified } from '@angular/fire/compat/auth-guard';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ARTEMIS, PBRIS } from '../entities/constant';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    public router: Router,
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
  ){ }

  currentSystem = sessionStorage.getItem('currentSystem')
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
    
      return new Promise((res,rej)=>{
        this.auth.authState.subscribe({
          next:(result)=>{
            // return res(true)
            if(result){

              if(result.uid){
                this.afs.collection('Users').doc(result.uid).snapshotChanges()
                .subscribe({
                  next: async (userResult)=>{
                    if(userResult.payload.exists){
                      const user:any = userResult.payload.data()
                      if(user && user.credentials){
                        if(user.credentials.account_type) {
                          if(user.credentials.account_type == 'Personal' && result.emailVerified == false) return res(this.restrict())
                          else{
                            return res(true)
                          }
                        } else return res(this.restrict())
                      } else return res(this.restrict())
                    } else return res(this.restrict())
                  },
                  error:(err)=> {return this.restrict();}
                })
              }else return res(this.restrict())
            }else{
              sessionStorage.clear()
              return res(true)
            }
          },error:(err)=> {return res(this.restrict())}
        })
      })

    // return this.auth.authState.map((result)=>{
		// 	console.log('result: ',result)
		// 	if(result){
		// 			if(result.emailVerified == true){
		// 					console.log('allowing')
		// 					return true
		// 			}
		// 			else{
		// 					console.warn('restricting')
		// 					this.router.navigate(['/restricted'])
		// 					return false
		// 			}
		// 	} else{
		// 			console.log('allowing')
		// 			return true
		// 	}
		// })
		
    //   return new Promise<boolean | UrlTree>((res,rej)=>{
    //     this.auth.authState.subscribe({
    //       next:(result)=>{
    //         // if not verified return to login
    //         if(result){
    //             // console.log('result: ',result)
    //             if(result.emailVerified == true){
    //                 console.log('allowing')
    //                 return res(true)
    //             }
    //             else{
    //                 console.warn('restricting')
    //                 this.router.navigate(['/restricted'])
    //                 return rej(false)
    //             }
    //         } else{
    //             console.log('allowing')
    //             return res(true)
    //         }
    //         // this.router.navigate(['/restricted'])
    //         // console.log('result: ',result)
    //         // if(result){
    //         //   const currentSystem = sessionStorage.getItem('currentSystem')
    //         //   if(currentSystem == PBRIS) this.router.navigate(['/pbris'])
    //         //   else if(currentSystem == ARTEMIS) this.router.navigate(['/artemis/charter'])
    //         //   return res(false)
    //         // }else return res(true)
    //       },
    //       error:(err)=>{
    //         console.warn('restricting')
    //         this.router.navigate(['/restricted'])
    //         return res(false)
    //       }
    //     })
    //   })
    //   return true
    //   .then(async(user:any)=>{
    //   })
    //   .catch((err)=>{
    //     return true
    //   })
  }
  
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  restrict(){
    console.warn('restrict')
    this.router.navigate(['/restricted'])
    return false
  }
  
}
