import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
    selector: 'app-regulation-details',
    templateUrl: './regulation-details.component.html',
    styleUrls: ['./regulation-details.component.scss']
})
export class RegulationDetailsViewComponent implements OnInit {

  @Input() regulation:any;

  @Output() EditRegulation = new EventEmitter<object>();

  checkEditReg = {
      // date_effective:[
      //     {type:'required', message:'Effectivity Date is required'},
      // ],
      date_published_gazette:[
          // {type:'required', message:'Effectivity Date is required'},
      ],
      date_published_onar :[
          // {type:'required', message:'Effectivity Date is required'},
      ],
  }

  editRegForm = new UntypedFormGroup({
    date_published_gazette: new UntypedFormControl(''),
    date_published_onar: new UntypedFormControl(''),
  })

  constructor(
      private afs: AngularFirestore,
  ) { }

    
  ngOnInit(): void {
    if(this.regulation){
      console.log(this.regulation)
      if(this.regulation.date_published_gazette)
        this.editRegForm.patchValue({date_published_gazette: moment(this.regulation.date_published_gazette,"YYYY-MM-DD")})
      
      if(this.regulation.date_published_onar)
        this.editRegForm.patchValue({date_published_onar:moment(this.regulation.date_published_onar,"YYYY-MM-DD")})
    }
  }

  submit(){
      this.validateForm()
      // console.log('prePostForm',this.prePostForm.value)
      if(this.editRegForm.valid){
          let formValue:any = this.editRegForm.value
          try{
            console.log('submitting')
              this.EditRegulation.emit({
                  date_published_gazette: formValue.date_published_gazette ? formValue.date_published_gazette.format('YYYY-MM-DD') : '',
                  date_published_onar: formValue.date_published_onar ? formValue.date_published_onar.format('YYYY-MM-DD') : '',
              })
          }catch(error){
              console.error('Error: ',error)
          }
      }else{
          console.warn('Form invalid')
      }
  }

  refreshDate(control:any){
      // if(control == 'effective'){
      //     this.editRegForm.patchValue({date_effective:moment(new Date())})
      // }else
       if(control == 'gazette'){
          this.editRegForm.patchValue({date_published_gazette:''})
      }else if(control == 'onar'){
          this.editRegForm.patchValue({date_published_onar:''})
      }
  }

  hasError(controlName:any, validType:any){
      return ((this.editRegForm.get(controlName) as UntypedFormControl).hasError(validType)
          && ((this.editRegForm.get(controlName) as UntypedFormControl).dirty
          || (this.editRegForm.get(controlName) as UntypedFormControl).touched)
      )
  }

  validateForm(){
      if(!this.editRegForm.valid){
          this.editRegForm.markAllAsTouched();
          for(const control in this.editRegForm.controls){
              if(this.editRegForm.controls.hasOwnProperty(control)){
                  this.editRegForm.controls[control].markAllAsTouched();
                  this.editRegForm.controls[control].markAsDirty();
              }
          }
      }
  }

}