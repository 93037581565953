import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-artemis-home-page-recent-trending',
  templateUrl: './artemis-home-page-recent-trending.component.html',
  styleUrls: ['./artemis-home-page-recent-trending.component.scss']
})
export class ArtemisHomePageRecentTrendingComponent implements OnInit {

  isAdmin: boolean = false;

  style_theme: any = 'theme-default'; //current theme

  userType: any = sessionStorage.getItem("user_type");
  
  recentTab: boolean;
  trendingTab: boolean;
  activeRecent: string;
  activeTrending: string;

  /**
   * List
   */
  service_status: any;
  recentList: any = []
  trendingList: any  = []

  /**
   * Functions
   */


  constructor(private afs: AngularFirestore) {
    this.recentTab = false;
    this.trendingTab = false;
    this.activeRecent = '';
    this.activeTrending = '';
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('arta_admin') == "true"){
      this.isAdmin = true;
    }
    this.loadList(this.recentList, "date_posted"); // recent list
    this.loadList(this.trendingList, "visit_count"); // trending list
    //this.loadTrendingList();
    this.openRecentTab();
    this.setTheme();
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  openRecentTab(){
    this.recentTab = true;
    this.trendingTab = false;
    this.activeRecent = 'active';
    this.activeTrending = '';
  }
  openTrendingTab(){
    this.recentTab = false;
    this.trendingTab = true;
    this.activeRecent = '';
    this.activeTrending = 'active';
  }


  async loadList (arrayToBeSorted: any[], sortcolumn: string) {
    //arrayToBeSorted = [];
    const pageRef = this.afs.collection("Services Phase 02", ref => ref.orderBy(sortcolumn,'desc').limit(10) );
    
    // Firestore get data as JSON object
    pageRef.snapshotChanges().subscribe(
      (data: any) => {
        data.forEach((info: any) => {
          
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if(item.posted_status == 'POSTED'){
              arrayToBeSorted.push(item);
          }

          /**
           * console.log('CC',item)
          if(item.citizens_charter){
            let service_parent_charter_id = item.citizens_charter;
            //get charter status
            const charterRef = this.afs.collection("citizens-charter-phase-02").doc(service_parent_charter_id);
            charterRef.snapshotChanges().subscribe((data: any) => {
              let charter_info_json = data.payload.data();
              if ( charter_info_json && charter_info_json.status == 'posted' /** posted status is for all users  
              || sessionStorage.getItem("arta_admin") == "true" /** admin can access all services
              /** if user is owner of the service (not yet implemented) 
          ){
            this.service_status = charter_info_json.status; 
            arrayToBeSorted.push(item);
          } 
        });

      }
           */
 
        });
      });
  }

}
