import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { RequirementType, RequriementsService } from 'src/app/service/requriements.service';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-subsituational-requirement-control',
  templateUrl: './subsituational-requirement-control.component.html',
  styleUrls: ['./subsituational-requirement-control.component.scss']
})
export class SubsituationalRequirementControlComponent implements OnInit {

  @Input() situation : any
  @Input() situationIndex: number
  @Input() referenceArray:any[]
  @Input() req_type :RequirementType
  @Input() indexName: any
  @Input() fromchooseany: boolean=false
  @Input() fromReq: boolean
  enum_requirement_type = RequirementType
  title = new UntypedFormControl('',Validators.required)


  hideButtons:boolean =false

  constructor(
    public requirementservice: RequriementsService,
    private snackbar : SnackbarNotifService
  ) {
   
   }

  ngOnInit(): void {

  }

  pasteSituation(){
    this.requirementservice.pasteRequirement(this.referenceArray,'situation_copy',this.referenceArray.length + 1)
  }
  pasteRequirement(){

    this.requirementservice.pasteRequirement(this.situation.requirement,'requirement_copy',this.situation.requirement.length + 1)

  }

  copyRequirement(){
    if(this.requirementservice.checkClipboard('requirement_copy')) this.cancelcopyRequirement();
    else this.requirementservice.copyRequirement(this.situation,'requirement_copy')
  }

  cancelcopyRequirement(){
    this.requirementservice.cancelcopyRequirement('requirement_copy')
  }

  copySituation(){

    if(this.requirementservice.checkClipboard('situation_copy')) this.requirementservice.cancelcopyRequirement('situation_copy')

    else this.requirementservice.copyRequirement(this.situation,'situation_copy')

  }

  removeRequirement(){

    this.snackbar.confirmDialog("Remove Requirement").then(result => {
      if (result){
        this.requirementservice.removeRequirement(this.referenceArray,this.situationIndex)
      
      }
   })

  }

  updateSituationalRequirement(){
    //instead of update add requirement either standard or standard with any of the ff option
    this.requirementservice.newStandardRequirement(
      this.situation.requirement,RequirementType.STANDARD_NO_TITLE
    )
    //this.requirementservice.updateSituationalRequirement(this.situation,this.situationIndex,this.referenceArray,this.req_type)
  }

}
