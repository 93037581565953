import { Component, OnInit } from '@angular/core';
import { formatDate } from "@angular/common";
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-inbox-page',
  templateUrl: './artemis-inbox-page.component.html',
  styleUrls: ['./artemis-inbox-page.component.scss']
})
export class ArtemisInboxPageComponent implements OnInit {
  
  userType: any = sessionStorage.getItem("user_type");
  agencyName: any = sessionStorage.getItem("agency_name");
  userSubtype: any = sessionStorage.getItem("account_subtype");

  activateChild:boolean = false;
  activateObject:any;
  activateTrigger:boolean = false;
  toAgencyCCPage: string = ""
  toAgencyCCPageQP: any = {}
  toAgencyPage: string = "";  
  userFullname: string = "";
  
  user:any = {};
  userLoading:boolean = true

  add_sample_service_form: UntypedFormGroup = new UntypedFormGroup({
    agencycategory: new UntypedFormControl('', Validators.required),
    agency: new UntypedFormControl('', Validators.required),
    sector: new UntypedFormControl('', Validators.required),
    classification: new UntypedFormControl('', Validators.required),
    servicecategory: new UntypedFormControl('', Validators.required),
    service: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
  });

  sampleServiceAdderUI : boolean = false;

  /**pages to open */
  
  collapse = false;
  inboxExpander = true;
  notificationsView : boolean = false;
  isAgency : boolean = false;
  isAdmin : boolean = false;
  inboxView : boolean = false;
  inboxMode : string = '';
  charterListView : boolean = false;
  feedbackIndex : boolean = false;
  comment : boolean = false;
  canViewCC:boolean = false;
  serviceManagementView : boolean = false;
  AgencyControlView : boolean = false;
  accountManagementView : boolean =false;
  systemsettingsView : boolean =false;
  accountverificationView: boolean =false;
  accountprofileView: boolean =false;
  profile:boolean =false;
  cocmasterlistview:boolean=false;
  agency_id = sessionStorage.getItem('agency_id')

  account_management_link :any = ""
  isAgencyAdmin :boolean =false


  constructor(private router: Router, private afs: AngularFirestore, public auth: AngularFireAuth) {
    switch(sessionStorage.getItem('account_subtype') || '') {
      case GovernmentAccountSubtype.AGENCY_ADMIN:
        this.isAgencyAdmin = true
        break;
      case GovernmentAccountSubtype.ARTA_ADMIN:
        this.isAdmin = true;
        this.isAgency =false;
        this.canViewCC = true;
        break;
      case GovernmentAccountSubtype.AGENCY_ENC:
      case GovernmentAccountSubtype.AGENCY_VER:
        this.inboxMode = 'agency';
        this.isAdmin = false;
        this.isAgency = true;
        this.canViewCC = true;
        break;
      case GovernmentAccountSubtype.AGENCY_DIR:
        this.inboxMode = 'agency-head';
        this.isAgency = true;
        this.isAdmin = false;
        this.canViewCC = true;
     
        break;

      case GovernmentAccountSubtype.ARTA_CMEO_PO:
      case GovernmentAccountSubtype.ARTA_CMEO_DC:
      case GovernmentAccountSubtype.ARTA_CMEO_DIR:
        this.inboxMode = 'arta';
        this.isAdmin = false;
        this.canViewCC = true;
       
        break;


    }
    // console.log('subtype',this.userSubtype)

    auth.user
    .pipe(first())
    .subscribe({
      next:(res)=>{
        // const id = res?.uid
        // console.log('res',res)
        this.user.auth = {}
        this.user.auth.displayName = res?.displayName
        this.user.auth.uid = res?.uid
        this.user.auth.email = res?.email
        // console.log('auth',this.user)
        if(this.user.auth.uid) this.getUserData(this.user.auth.uid)
        else this.userLoading = false
      },
      error:(err)=>{
        console.warn('User not authenticated',err)
        this.userLoading = false
      }
    })
  }

  ngOnInit(): void {

   this.account_management_link = "/agency/view/" + this.agency_id + "/members"
    const artemisInboxActiveMode = sessionStorage.getItem('artemisInboxActiveMode')
    if(artemisInboxActiveMode != null && artemisInboxActiveMode != undefined && artemisInboxActiveMode != ''){
      let removeList:any = []
      const artemisInboxActivated = sessionStorage.getItem('artemisInboxActivated')
      if(artemisInboxActivated != null && artemisInboxActivated != undefined && artemisInboxActivated != ''){
        removeList.push('artemisInboxActivated')
        if(artemisInboxActiveMode == artemisInboxActivated && artemisInboxActiveMode == 'Arta Check Service Inbox'){
          
          let serviceIds = sessionStorage.getItem('serviceId')
          this.activateChild = true
          this.activateObject = {
            agencyId: sessionStorage.getItem('agencyId'),
            ccId: sessionStorage.getItem('ccId'),
            serviceId: JSON.parse(serviceIds? serviceIds : "[]")
          }
          removeList.push('agencyId')   
          removeList.push('ccId')
          removeList.push('serviceId')
          this.openInboxView();
        }
      }
      else{
        if(artemisInboxActiveMode == 'Arta Check Service Inbox'){
          this.openInboxView();
          removeList.push('agencyId')   
          removeList.push('ccId')
          removeList.push('serviceId')
        }
      }
      this.resetActiveSelection(removeList)
    }else{
      this.openInboxView();
    }
  }

  async UpdateDisplayName(name: any){
    const profile ={
      displayName : name
    }

    return (await this.auth.currentUser)?.updateProfile(profile);
  }

  getUserData(id:any){
    this.afs.collection('Users').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe({
      next:(res)=>{
        if(res.payload.exists){
          let data:any = res.payload.data()
          this.user.data = data
          //console.log(data.personal.first_name)
          const firstname = data.personal.first_name
          const lastname = data.personal.last_name
          this.userFullname = "Hi, " + firstname + ", " + lastname; 
          //this.userFullname = this.user.data.personal.last_name? this.user.data.personal.last_name : 'Last name' + ", " + this.user.data.personal.first_name ? this.user.data.personal.first_name : 'First name' ;
          this.toAgencyPage = "/artemis/view/agency/" + this.user.data.government.agency;
          this.UpdateDisplayName(firstname + " " + lastname);
          this.user.auth.displayName = firstname + " " + lastname;
          this.toAgencyCCPage = "/artemis/citizen-charter/view/report"
          this.toAgencyCCPageQP.agency = this.user.data.government.agency
          if(this.user.data.government && this.user.data.government.agency) this.getAgencyDataCollector(this.user.data.government.agency)
          else this.userLoading = false
          // this.userLoading = false
          // console.log('data',this.user)
        }
        else
          this.userLoading = false
      },
      error:(err)=>{
        console.warn('User no data: ',err)
        this.userLoading = false
      }
    })
  }

  goToCCReport(){
    //console.log(this.toAgencyCCPage+'?',this.toAgencyCCPageQP)
    this.router.navigate([this.toAgencyCCPage],{
      queryParams:this.toAgencyCCPageQP
    })
  }

  
  async getAgencyDataCollector(value:any){
    const agencyData1: any = await this.getAgencyData('id',value)
    if(agencyData1){
      // console.log('agencyData1 good',agencyData1)
      this.user.agency = agencyData1
      this.userLoading = false
    }else{
      // console.warn('agencyData1 bad')
      const agencyData2: any = await this.getAgencyData('name',value,'agencyDetails.agencyName')
      if(agencyData2.length > 0){
        // console.log('agencyData2 good',agencyData2[0])
        this.user.agency = agencyData2
        this.userLoading = false
      }
      else{
        console.warn('agencyData2 bad')
        const agencyData3: any = await this.getAgencyData('name',value,'name')
        if(agencyData3.length > 0){
          // console.log('agencyData3 good',agencyData3[0])
          this.user.agency = agencyData3
          this.userLoading = false
        }else{
          // console.warn('agencyData3 bad')
          this.userLoading = false
        }
      }
    }
  }

  
  getAgencyData(code:any,value:any,lookFor?:any){
    return new Promise((res,rej)=>{
      if(code == 'id'){
        this.afs.collection('Agency').doc(value).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let user:any = result.payload.data()
            user.id = result.payload.id
            return res(user)
          },
          error:(err)=>{
            return res(false)
          }
        })
      }
      else if(code == 'name'){
        this.afs.collection('Agency',filter=>filter.where(lookFor,'==',value)).snapshotChanges()
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let tempArr:any = []
            result.map((item)=>{
              let agency:any = item.payload.doc.data()
              agency.id = item.payload.doc.id
              if(!tempArr.map((info:any)=>info.id).includes(agency.id)) tempArr.push(agency)
              return res(tempArr)
            })
          },
          error:(err)=>{
            console.error('getAgencyData : '+code+' | '+lookFor,err)
            return res(false)
          }
        })
      }
      else return res(false)
    })
  }

  
  getAcronym(str:any){
    let matches = str.match(/\b(\w)/g);
    let acronym = matches.join('');
    return acronym;
  }

  /**
   * Open Views
   */

  toggleSideNav(){
    this.collapse = !this.collapse
  }

  toggleExpander(){
    this.inboxExpander = !this.inboxExpander
  }

  openNotificationsView(){
    this.resetViews()
    this.notificationsView = true;
    // this.inboxView = false;
    // this.charterListView = false;
    // this.feedbackIndex = false;
    // this.comment = false;
    // this.serviceManagementView = false;
  }

  openInboxView() {
    this.resetViews()
    // this.notificationsView = false;
    this.inboxView = true;
    // this.charterListView = false;
    // this.feedbackIndex = false;
    // this.comment = false;
    // this.serviceManagementView = false;
  }

  openaccountprofileView(){
    this.resetViews();
    this.accountprofileView = true;
  }

  openCharterListView() {
    this.resetViews()
    // this.notificationsView = false;
    // this.inboxView = false;
    this.charterListView = true;
    // this.feedbackIndex = false;
    // this.comment = false;
    // this.serviceManagementView = false;
  }

  openFeedbackInbox() {
    this.resetViews()
    // this.notificationsView = false;
    // this.inboxView = false;
    // this.charterListView = false;
    this.feedbackIndex = true;
    // this.comment = false;
    // this.serviceManagementView = false;
  }
  
  openCommentView() {
    this.resetViews()
    // this.notificationsView = false;
    // this.inboxView = false;
    // this.charterListView = false;
    // this.feedbackIndex = false;
    this.comment = true;
    // this.serviceManagementView = false;
  }

  openServiceManagementView() {
    this.resetViews()
    // this.notificationsView = false;
    // this.inboxView = false;
    // this.charterListView = false;
    // this.feedbackIndex = false;
    // this.comment = false;
    this.serviceManagementView = true;
  }
  openAgencyControlView(){
    this.resetViews();
    this.AgencyControlView = true;
  }

  openaccountmanagementView(){
    this.resetViews();
    this.accountManagementView = true;  
  }

  opensystemsettingsView(){
    this.resetViews();
    this.systemsettingsView = true;
  }

  toggleSampleServiceAdder(){
    if(this.sampleServiceAdderUI)
      this.sampleServiceAdderUI=false;
    else
      this.sampleServiceAdderUI = true;
  }
  openagencypageView(){

    this.resetViews();
    this.profile =true;
    //window.open(this.toAgencyPage,"_blank");
    
  }

  goToReports() {
    this.router.navigate(['/artemis/summary-report']);
  }

  formSubmit() {

    this.afs.collection("Services").add({
      agencycategory : this.add_sample_service_form.value.agencycategory,
      agency : this.add_sample_service_form.value.agency,
      sector : this.add_sample_service_form.value.sector,
      classification : this.add_sample_service_form.value.classification,
      servicecategory : this.add_sample_service_form.value.servicecategory,
      service : this.add_sample_service_form.value.service,
      name : this.add_sample_service_form.value.name,
      date: formatDate(new Date().toDateString(), 'yyyy-MM-dd', 'en-PH')
    }).then(() => {
      alert("create successful");
    }).catch(error =>{
      alert(error.message)
    })
    //
    //this.router.navigate(['/artemis/results']);
  }

  openaccountverificationView(){
    this.resetViews()
    this.accountverificationView = true;
  }

  opencocmasterlistView(){
    this.resetViews();
    this.cocmasterlistview =true;
  }
 
  
  resetViews(){
    this.notificationsView = false;
    this.accountprofileView =false;
    this.inboxView = false;
    this.charterListView = false;
    this.feedbackIndex = false;
    this.comment = false;
    this.serviceManagementView = false;
    this.AgencyControlView = false;
    this.accountManagementView = false;
    this.systemsettingsView = false;
    this.accountverificationView = false;
    this.profile =false;
    this.cocmasterlistview =false;
    if(!this.activateTrigger) this.activateTrigger = true
    else this.resetActivatedValues()
  }

  resetActivatedValues(){
    this.activateChild = false
    this.activateObject = {}
  }

  resetActiveSelection(list:any[]){
    list.forEach((item:any)=>{
      sessionStorage.removeItem(item)
    })
  }

}
