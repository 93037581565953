<div class="content col-md-12">
    <div class="container-fluid">    

        <div class="row">
            <div class="card padded">
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">POSTING DETAILS</h6>
                            
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">

                        <h5 style="background-color: red" *ngIf="regulationsData?.risData?.risAssessmentForReconsideration">
                            Warning! Please reconsider posting this regulation!
                        </h5>
                        <section style="padding:2em">
                            <div>
                                <div style="margin-bottom:0.5em" class="row">
                                    <div class="col-3">
                        
                                    </div>
                                    <div class="col d-flex justify-content-center">
                                        ARTA Remarks
                                    </div>
                                </div>
                                <hr>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Classification
                                    </div>
                                    <div class="col">
                                        {{regulationsData.reg_tag_classification}}
                                    </div>
                                </div>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Sector
                                    </div>
                                    <div class="col">
                                        {{regulationsData.reg_tag_sector}}
                                    </div>
                                </div>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Division
                                    </div>
                                    <div class="col">
                                        {{regulationsData.reg_tag_division}}
                                    </div>
                                </div>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Stage of Business
                                    </div>
                                    <div class="col">
                                        {{regulationsData.reg_tag_stage_of_business}}
                                    </div>
                                </div>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Case Use
                                    </div>
                                    <div class="col">
                                        {{regulationsData.reg_tag_case_use}}
                                    </div>
                                </div>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Regulatory Agenda
                                    </div>
                                    <div class="col">
                        
                                    </div>
                                </div>
                                <div style="margin-bottom:2em" class="row">
                                    <div class="col-3">
                                        Regulatory Plan
                                    </div>
                                    <div class="col">
                        
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div [formGroup]="prePostForm">
                                <!-- class="d-flex flex-row justify-content-evenly" -->
                                <div class="row">
                                    <div class="col-6 d-flex flex-column align-items-center">
                                        <div>
                                            <div>
                                                <label>Date Signed (ONAR)</label>
                                                <a  style="margin-left:1em; cursor: pointer;" (click)="refreshDate('onar')"><i class="fas fa-sync"></i></a>
                                            </div>
                                            <!-- appearance="fill" -->
                                            <mat-form-field class="" >
                                                <!-- <mat-label>Date Signed (ONAR)</mat-label> -->
                                                <input matInput readonly [matDatepicker]="date_published_onar" formControlName="date_published_onar" placeholder="YYYY-MM-DD">
                                                <mat-datepicker-toggle matSuffix [for]="date_published_onar"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #date_published_onar [disabled]="!actionButtonAllowed"></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                        
                                    <div class="col-6 d-flex flex-column align-items-center">
                                        <div>
                                            <div>
                                                <label>Date Published (Gazette/Newspaper)</label>
                                                <a  style="margin-left:1em; cursor: pointer;" (click)="refreshDate('gazette')"><i class="fas fa-sync"></i></a>
                                            </div>
                                            <mat-form-field class="">
                                                <!-- <mat-label>Date Published (Gazette/Newspaper)</mat-label> -->
                                                <input matInput readonly [matDatepicker]="date_published_gazette" formControlName="date_published_gazette" placeholder="YYYY-MM-DD">
                                                <mat-datepicker-toggle matSuffix [for]="date_published_gazette"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #date_published_gazette [disabled]="!actionButtonAllowed"></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    
                                </div>
                                <!-- class="d-flex flex-row justify-content-center" -->
                                <div class="row">
                                    <div class="col-6 d-flex flex-column align-items-center">
                                        <div>
                                            <div>
                                                <label><span style="color:red;margin-right:1em">*</span>Effective Date</label>
                                                <a  style="margin-left:1em; cursor: pointer;" (click)="refreshDate('effective')"><i class="fas fa-sync"></i></a>
                                            </div>
                                            <mat-form-field class="">
                                                <!-- <mat-label>Effective Date</mat-label> -->
                                                <input matInput readonly [matDatepicker]="date_effective" formControlName="date_effective" placeholder="YYYY-MM-DD">
                                                <mat-datepicker-toggle matSuffix [for]="date_effective"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #date_effective [disabled]="!actionButtonAllowed"></mat-datepicker>
                                                <mat-error style="color:red">
                                                    <ng-Container *ngFor="let item of checkPrePost.date_effective">
                                                        <div *ngIf="hasError('date_effective',item.type)">* {{item.message}}</div>
                                                    </ng-Container>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="row">
                                    <div class="col-6 d-flex flex-column align-items-center">
                                        <div>
                                            <div>
                                                <label><span style="color:red;margin-right:1em">*</span>Issue Date</label>
                                                <a  style="margin-left:1em; cursor: pointer;" (click)="refreshDate('issue')"><i class="fas fa-sync"></i></a>
                                            </div>
                                            <mat-form-field class="">
                                                <!-- <mat-label>Effective Date</mat-label> -->
                                                <input matInput readonly [matDatepicker]="doc_date" formControlName="doc_date" placeholder="YYYY-MM-DD">
                                                <mat-datepicker-toggle matSuffix [for]="doc_date"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #doc_date [disabled]="!actionButtonAllowed"></mat-datepicker>
                                                <mat-error style="color:red">
                                                    <ng-Container *ngFor="let item of checkPrePost.doc_date">
                                                        <div *ngIf="hasError('doc_date',item.type)">* {{item.message}}</div>
                                                    </ng-Container>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                        
                                <div *ngIf="actionButtonAllowed" class="d-flex flex-row-reverse">
                                    <button class="btn btn-primary mx-2" type="button" (click)="submit()">Post Regulation</button>
                                    <button *ngIf="objectDataDRIS" class="btn btn-primary mx-2" type="button" (click)="returnDRIS()">Return DRIS For Revision</button>
                                    <button *ngIf="!objectDataDRIS" class="btn btn-primary mx-2" type="button" (click)="forRIA()">
                                        <i class="fas fa-tasks me-2"></i>For RIA
                                    </button>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>