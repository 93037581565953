import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-new-citizens-charter',
  templateUrl: './artemis-new-citizens-charter.component.html',
  styleUrls: ['./artemis-new-citizens-charter.component.scss']
})
export class ArtemisNewCitizensCharterComponent implements OnInit {

  isAgency: boolean = false;
  isAdmin: boolean = false;

  pageTag: any;
  currentStatus: any;
  // phase 1 - sample charter form
  phase1CharterForm = new UntypedFormGroup({ //date
     organization_grouping_01: new UntypedFormControl('', Validators.required),
     organization_level_00: new UntypedFormControl('', Validators.required),
     organization_level_01: new UntypedFormControl('', Validators.required),
     organization_level_02: new UntypedFormControl('', Validators.required),
     organization_level_03: new UntypedFormControl('', Validators.required),
     psgc_location: new UntypedFormControl('', Validators.required),
     uacs_location_code: new UntypedFormControl('', Validators.required),
     uacs_org_description: new UntypedFormControl('', Validators.required),
     uacs_organization_code: new UntypedFormControl('', Validators.required),
     filename: new UntypedFormControl('', Validators.required),
     date: new UntypedFormControl('', Validators.required),
   });
   phase_01_filepath: any;

  
  // phase 1 - sample service form
  /**
   * 
  pageinfo : any;
  requirements: any;
  clientstep: any;
   */
  phase1ServicePageStep: any;
  phase1ServicePageInfoForm = new UntypedFormGroup({ //date
    agency: new UntypedFormControl('', Validators.required),
    agencycategory: new UntypedFormControl('', Validators.required),
    classification: new UntypedFormControl('', Validators.required),
    date: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    sector: new UntypedFormControl('', Validators.required),
    service: new UntypedFormControl('', Validators.required),
    servicebackground: new UntypedFormControl('', Validators.required),
    servicecategory: new UntypedFormControl('', Validators.required),
    visit_count: new UntypedFormControl('0', Validators.required),
  });
  phase1ServiceRequirementsForm = [new UntypedFormGroup({ //date
    description: new UntypedFormControl('', Validators.required),
    material: new UntypedFormControl('', Validators.required),
    wheretosecure: new UntypedFormControl('', Validators.required),
  })];
  phase1ServiceStepsForm = [
    {
      staticform: new UntypedFormGroup({
        step: new UntypedFormControl('', Validators.required),
        description: new UntypedFormControl('', Validators.required),
        fees: new UntypedFormControl('', Validators.required),
        }),
      agencyaction: [
        new UntypedFormGroup({
          description: new UntypedFormControl('', Validators.required),
          subdescription: new UntypedFormControl('', Validators.required),
          subname: new UntypedFormControl('', Validators.required),
          processingtime: new UntypedFormControl('', Validators.required),
          })
      ]
    }
  ];
  phase1ServiceProcessFlowForm = [{
    next_section_code: [],
    form: new UntypedFormGroup({
      section_code: new UntypedFormControl('', Validators.required),
      }),
    blocks: [{
      next_block_id: [],
      form: new UntypedFormGroup({
        description: new UntypedFormControl('', Validators.required),
        fee: new UntypedFormControl('', Validators.required),
        duration: new UntypedFormControl('', Validators.required),
        })
    }]
  }];

  // step 1 form
  agencyprofile = new UntypedFormGroup({
     mandate: new UntypedFormControl('', Validators.required),
     mission: new UntypedFormControl('', Validators.required),
     vision: new UntypedFormControl('', Validators.required),
     servicepledge: new UntypedFormControl('', Validators.required),
     websitelink: new UntypedFormControl('', Validators.required),
   });
  // step 2 form
  committee = new UntypedFormGroup({
      headofagency: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        designation: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
      }),
      chairperson: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        designation: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
      }),
      vicechairperson: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        designation: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
      }),
      members: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        designation: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
      }),
    });
  // step 3 form
  servicenameanddetails = new UntypedFormGroup({
       servicename: new UntypedFormControl('', Validators.required),
       servicecategory: new UntypedFormControl('', Validators.required),
       servicedescription: new UntypedFormControl('', Validators.required),
       classification: new UntypedFormControl('', Validators.required),
       typeoftransaction: new UntypedFormControl('', Validators.required),
       officedivision: new UntypedFormControl('', Validators.required),
       whomayavail: new UntypedFormControl('', Validators.required),
       operatinghours: new UntypedFormControl('', Validators.required),
       statute: new UntypedFormControl('', Validators.required),
     });
  // standard requirement in step 3 form
  standardrequirement = new UntypedFormGroup({
      requirement: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      numberofcopies: new UntypedFormControl('', Validators.required),
      wheretoget: new UntypedFormControl('', Validators.required),
      bureaudivision: new UntypedFormControl('', Validators.required),
    });
  // step 4 form
  servicesteps = new UntypedFormGroup({
      sequencenumber: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required),
      location: new UntypedFormControl('', Validators.required),
      notes: new UntypedFormControl('', Validators.required),
      fees: new UntypedFormControl('', Validators.required),
      agencyaction: new UntypedFormGroup({
        designation: new UntypedFormControl('', Validators.required),
        divisiongroup: new UntypedFormControl('', Validators.required),
        processingtime: new UntypedFormControl('', Validators.required),
      }),
    });
  // step 7 form
  eodbtags = new UntypedFormGroup({
      classification: new UntypedFormControl('', Validators.required),
      sector: new UntypedFormControl('', Validators.required),
      division: new UntypedFormControl('', Validators.required),
      stageofbusiness: new UntypedFormControl('', Validators.required),
      caseuse: new UntypedFormControl('', Validators.required),
      jurisdiction: new UntypedFormControl('', Validators.required),
    });
  // step 8 form
  feedback = new UntypedFormGroup({
    howtosendfeedback: new UntypedFormControl('', Validators.required),
    howfeedbackisprocessed: new UntypedFormControl('', Validators.required),
    howtofileacomplaint: new UntypedFormControl('', Validators.required),
    howcomplaintsareprocessed: new UntypedFormControl('', Validators.required),
  });

  // form
  currentFormPage: string = "";

  constructor(private afs: AngularFirestore, private store: AngularFireStorage, private router: Router, private route: ActivatedRoute) {
    //this.route.snapshot.params.id
  }

  ngOnInit(): void {
    /**
     * step 1 : agencyprofile
     * step 2 : committee
     * step 3 : servicenameanddetails
     *        : standardrequirement
     * step 4 : servicesteps
     * step 5 : 
     * step 6 : 
     * step 7 : eodbtags
     * step 8 : feedback
     */
    this.currentFormPage = "agencyprofile"

    /**
     * phase 1 add charter
     * phase 1 add service
     * preview
     */
    this.pageTag = "phase 1 add service";
    this.phase1ServicePageStep = 'general';

    if(sessionStorage.getItem("user_type")) {
      if(sessionStorage.getItem("user_type") == 'agency'){
        this.isAgency = true;
      }
    }
    if(sessionStorage.getItem("arta_admin")) {
      if(sessionStorage.getItem("arta_admin") == 'true'){
        this.isAdmin = true;
      }
    }
    if(this.route.snapshot.params.id) {
      this.loadCitizensCharterInformation();
    }
  }

  loadCitizensCharterInformation() {
    const pageRef = this.afs.collection("Services").doc(this.route.snapshot.params.id);
    this.phase1ServiceRequirementsForm = [];
    this.phase1ServiceStepsForm = [];
    this.phase1ServiceProcessFlowForm = [];

    pageRef.snapshotChanges().subscribe( (data: any) => {
      let pageinfo = data.payload.data();

      this.currentStatus = pageinfo.status;
      //
      this.phase1ServicePageInfoForm = new UntypedFormGroup({ //date
        agency: new UntypedFormControl(pageinfo.agency, Validators.required),
        agencycategory: new UntypedFormControl(pageinfo.agencycategory, Validators.required),
        classification: new UntypedFormControl(pageinfo.classification, Validators.required),
        date: new UntypedFormControl(pageinfo.date, Validators.required),
        name: new UntypedFormControl(pageinfo.name, Validators.required),
        sector: new UntypedFormControl(pageinfo.sector, Validators.required),
        service: new UntypedFormControl(pageinfo.service, Validators.required),
        servicebackground: new UntypedFormControl(pageinfo.servicebackground, Validators.required),
        servicecategory: new UntypedFormControl(pageinfo.servicecategory, Validators.required),
        visit_count: new UntypedFormControl(pageinfo.visit_count, Validators.required),
      });

      // requirements array
      for(let requirement of pageinfo.requirements) {
        this.phase1ServiceRequirementsForm.push( new UntypedFormGroup({
          description: new UntypedFormControl(requirement.description, Validators.required),
          material: new UntypedFormControl(requirement.material, Validators.required),
          wheretosecure: new UntypedFormControl(requirement.wheretosecure, Validators.required),
        }));
      }
      // clientsteps array
      for(let clientstep of pageinfo.clientsteps) {
        let agencyaction = [];

        for(let agencyactionitem of clientstep.agencyaction) {
          agencyaction.push( new UntypedFormGroup({
            description: new UntypedFormControl(agencyactionitem.description, Validators.required),
            subdescription: new UntypedFormControl(agencyactionitem.subdescription, Validators.required),
            subname: new UntypedFormControl(agencyactionitem.subname, Validators.required),
            processingtime: new UntypedFormControl(agencyactionitem.processingtime, Validators.required),
          }));
        }

        this.phase1ServiceStepsForm.push({
          staticform: new UntypedFormGroup({
            step: new UntypedFormControl(clientstep.step, Validators.required),
            description: new UntypedFormControl(clientstep.description, Validators.required),
            fees: new UntypedFormControl(clientstep.fees, Validators.required),
            }),
          agencyaction: agencyaction
        });
      }

      this.phase1ServiceProcessFlowForm = [{
        next_section_code: [],
        form: new UntypedFormGroup({
          section_code: new UntypedFormControl('', Validators.required),
          }),
        blocks: [{
          next_block_id: [],
          form: new UntypedFormGroup({
            description: new UntypedFormControl('', Validators.required),
            fee: new UntypedFormControl('', Validators.required),
            duration: new UntypedFormControl('', Validators.required),
            })
        }]
      }];
    });

  }

  changePage(pageTag: string){
    this.pageTag = pageTag;
  }

  saveFileInformation(event: any) {
    this.phase_01_filepath = event.target.files[0];
  }

  nextForm(){
    /**
     * step 1 : agencyprofile
     * step 2 : committee
     * step 3 : servicenameanddetails
     *        : standardrequirement
     * step 4 : servicesteps
     * step 5 : 
     * step 6 : 
     * step 7 : eodbtags
     * step 8 : feedback
     */
    switch(this.currentFormPage) {
      case 'agencyprofile':
        this.currentFormPage = "committee";
        break;
      case 'committee':
        this.currentFormPage = "servicenameanddetails";
        break;
      case 'servicenameanddetails':
        this.currentFormPage = "servicesteps";
        break;
      case 'servicesteps':
        this.currentFormPage = "eodbtags";
        break;
      case 'eodbtags':
        this.currentFormPage = "feedback";
        break;
      case 'feedback':
        //this.currentFormPage = "";
        break;
    }
  }
  cancelForm(){
    //
  }

  saveAndUploadCharter () {
    //
    let uploadProgress!: Observable<number | undefined>;

    if(this.phase_01_filepath){
      const destinationPath = `citizen_charter20210511/${this.phase_01_filepath.name}`; //alert(Date.now().toString());
      const destinationRef = this.store.ref(destinationPath);
      const task = this.store.upload(destinationPath, this.phase_01_filepath);

      uploadProgress = task.percentageChanges()
    }

    this.phase1CharterForm.get("filename")?.setValue(this.phase_01_filepath.name);
    this.phase1CharterForm.get("date")?.setValue(Date.now().toString());

    this.afs.collection(`citizens-charter-phase-01`).add(this.phase1CharterForm.value).then(() => {
      alert("create successful");
    }).catch(error =>{
      alert(error.message)
    });
  }





  /**
   * Sample Service Functions
   */
  changeServicePage(pageTag: string) {
    this.phase1ServicePageStep = pageTag;
  }

  /**
   * step 1: general information
   * step 2: requirements
   * step 3: client steps
   * step 4: process flow
   */
  addServiceRequirementsForm() {
    this.phase1ServiceRequirementsForm.push(
      new UntypedFormGroup({ //date
        description: new UntypedFormControl('', Validators.required),
        material: new UntypedFormControl('', Validators.required),
        wheretosecure: new UntypedFormControl('', Validators.required),
      }));
  }
  addServiceStepsForm(){
    this.phase1ServiceStepsForm.push(
      {
        staticform: new UntypedFormGroup({
          step: new UntypedFormControl('', Validators.required),
          description: new UntypedFormControl('', Validators.required),
          fees: new UntypedFormControl('', Validators.required),
          }),
        agencyaction: [
          new UntypedFormGroup({
            description: new UntypedFormControl('', Validators.required),
            subdescription: new UntypedFormControl('', Validators.required),
            subname: new UntypedFormControl('', Validators.required),
            processingtime: new UntypedFormControl('', Validators.required),
          })
        ]
      });
  }
  addServiceAgencyActionForm(formArray: any){
    formArray.push(
      new UntypedFormGroup({
        description: new UntypedFormControl('', Validators.required),
        subdescription: new UntypedFormControl('', Validators.required),
        subname: new UntypedFormControl('', Validators.required),
        processingtime: new UntypedFormControl('', Validators.required),
      }));
  }
  

  /**
   * [STATUSES]
   * 1 - draft
   * 2 - for verification
   * 3 - for revision
   * 4 - for agency review
   * 5 - for comments consolidation
   * 6 - consolidation complete
   * 7 - for approval
   * 8 - for arta review
   * 9 - for modification
   * 10 - posted
   */
  uploadService (status?: string) {
    let finalJSON: any;
    let dateToday = new Date();
    let visit_count = 0;

    let requirementsArray = [];
    for(let row of this.phase1ServiceRequirementsForm) {
      requirementsArray.push(row.value);
    }
    let clientstepsArray = [];
    for(let row of this.phase1ServiceStepsForm) {
      let json = row.staticform.value;
      let agencyaction = []
      for(let subrow of row.agencyaction) {
        agencyaction.push(subrow.value);
      }
      json.agencyaction = agencyaction;
      clientstepsArray.push(json);
    }

    if(this.route.snapshot.params.id) {
      visit_count = this.phase1ServicePageInfoForm.value.visit_count;
    }
    else {
      this.currentStatus = 'draft';
    }

    finalJSON = {
      date_generated: dateToday,
      officer: '',
      status: this.currentStatus,
      action_due: '',
      agency: this.phase1ServicePageInfoForm.value.agency,
      agencycategory: this.phase1ServicePageInfoForm.value.agencycategory,
      classification: this.phase1ServicePageInfoForm.value.classification,
      date: this.phase1ServicePageInfoForm.value.date,
      name: this.phase1ServicePageInfoForm.value.name,
      sector: this.phase1ServicePageInfoForm.value.sector,
      service: this.phase1ServicePageInfoForm.value.service,
      servicebackground: this.phase1ServicePageInfoForm.value.servicebackground,
      servicecategory: this.phase1ServicePageInfoForm.value.servicecategory,
      visit_count: visit_count,
      requirements: requirementsArray,
      clientsteps: clientstepsArray,
    }

    /**
     * Update if draft
     * Add if not yet
     */
    if(this.route.snapshot.params.id) {
      this.afs.collection(`Services`).doc(this.route.snapshot.params.id).update(finalJSON).then(() => {
        alert("Draft Updated");
        this.returnRedirect();
      }).catch(error =>{
        alert(error.message)
      });
    }
    else {
      this.afs.collection(`Services`).add(finalJSON).then(() => {
        alert("New Draft Saved");
        this.returnRedirect();
      }).catch(error =>{
        alert(error.message)
      });
    }
    //this.returnRedirect();
  }



  /**
   * CANCEL
   */
  returnRedirect() {
    if(this.route.snapshot.params.id) {
      // return to service page, if draft
      this.router.navigate(['/artemis/view/detail/'+this.route.snapshot.params.id]);
    }
    else {
      // return to inbox page, if not draft
      this.router.navigate(['/artemis/charter']);
    }
  }
}
