import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router, ActivatedRoute } from '@angular/router';
import { error } from 'console';
import { format } from 'date-fns';
import { Observable, Subject, of, combineLatest, firstValueFrom } from 'rxjs';
import { tap, take, startWith, debounceTime, flatMap, map, } from 'rxjs/operators';
import { FileUploadInput } from 'src/app/entities/consultation';
import { RegulationTextNode, RegulationTextNodeType } from 'src/app/entities/regulation-text-section';
import { WorklistStatusPBRISRegulation } from 'src/app/entities/worklist';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { hasSubTagValidator, regulationRequiredByClassification } from 'src/app/validators';
import { v4 as uuidv4 } from 'uuid';

enum EntityBuilderIndex {
  TAG = 1,
  TAG_BUSINESS = 2,
  TAG_NON_BUSINESS = 3,
}

/* 
  DRIS - Decision RIS
  Different from CRIS - Consultation RIS
  A DRIS is created from the results of a CRIS
*/
@Component({
  selector: 'app-dris-create',
  templateUrl: './dris-create.component.html',
  styleUrls: ['./dris-create.component.scss']
})
export class DrisCreateComponent implements OnInit {

  currentRegulation:any;

  loading: boolean = false;
  currentFormPage: string = '';

  agencies: any[] = [];
  sectors: any[] = [];
  org_codes: any[] = [];
  location_codes: any[] = [];
  psic_codes: any[] = [];
  sobs: any[] = [];
  divisions: any[] = [];
  divisions_select: any[] = [];
  jurisdictions: any[] = [];
  cases: any[] = [];
  cases_select: any[] = [];
  locations: any[] = [];
  documents: any[] = [];
  instruments: any[] = [];
  sols: any[] = [];
  lifeevents: any[] = [];
  lifeevents_select: any[] = [];
  loader: boolean = true;

  legalbases: Observable<any[]>;
  legalbasesobs: Observable<any[]>[] = [];
  intlagreements: Observable<any[]>;
  intlagreementsobs: Observable<any[]>[] = [];
  regulations: Observable<any[]>;
  reg_repeal_obs: Observable<any[]>[] = [];

  multipleIssuingAgency: boolean = false;

  edit_dris_id: any;
  edit_regulation_id: any;
  edit_worklist_id: any;
  edit_cris_id: any;
  initialInput:boolean = true;

  // With so many file upload inputs, it's better to make a map object
  public uploadInputs: Map<string, FileUploadInput> = new Map([
    [
      'regulatoryImpactStatement',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'policyObjective',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'policyProblem',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'policyOptions',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'policyOptionsAssessment',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'impactAssessment',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'consultationDocumentation',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'implementationEnforcement',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'monitoringEvaluation',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
    [
      'regulationTextAnnex',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile:false,
        url: null
      },
    ],
  ]);

  previewImg: any = null;

  get drisSections(): any[] {
    return [
      // Documents
      {
        code:'regulatoryImpactStatement',
        section: 'Regulatory Impact Statement',
        link: this.getURL('regulatoryImpactStatement'),
        status: this.uploadRequirement('regulatoryImpactStatement'),
      },
      {
        code:'policyObjective',
        section: 'Policy Objective',
        link: this.getURL('policyObjective'),
        status: this.uploadRequirement('policyObjective'),
      },
      {
        code:'policyProblem',
        section: 'Policy Problem and Need for Government Action',
        link: this.getURL('policyProblem'),
        status: this.uploadRequirement('policyProblem'),
      },
      {
        code:'policyOptions',
        section: 'Policy Options',
        link: this.getURL('policyOptions'),
        status: this.uploadRequirement('policyOptions'),
      },
      {
        code:'policyOptionsAssessment',
        section: 'Assessment of Policy Options',
        link: this.getURL('policyOptionsAssessment'),
        status: this.uploadRequirement('policyOptionsAssessment'),
      },
      {
        code:'impactAssessment',
        section: 'Impact Assessment (Tools & Lenses)',
        link: this.getURL('impactAssessment'),
        status: this.uploadRequirement('impactAssessment'),
      },
      {
        code:'consultationDocumentation',
        section: 'Consultation',
        link: this.getURL('consultationDocumentation'),
        status: this.uploadRequirement('consultationDocumentation'),
      },
      {
        code:'implementationEnforcement',
        section: 'Implementation and Enforcement',
        link: this.getURL('implementationEnforcement'),
        status: this.uploadRequirement('implementationEnforcement'),
      },
      {
        code:'monitoringEvaluation',
        section: 'Monitoring and Evalutation',
        link: this.getURL('monitoringEvaluation'),
        status: this.uploadRequirement('monitoringEvaluation'),
      },
      // Others
      {
        section: 'Tagging',
        status: this.drisform.invalid ? 'Required' : 'Complete',
        form: 'details'
      },
      {
        section: 'Sub Tags',
        status: (this.drisform.value.has_sub_tags == true && this.drisform.controls.sub_tags.invalid) ? 'Invialid' : (this.drisform.value.has_sub_tags == true) ? 'Complete' : 'Optional',
        form: this.drisform.value.has_sub_tags == true ? 'subtagging' : 'details'
      },
      {
        section: 'Related Issuances',
        status: this.drisform.value.reg_legal.length < 1 ? 'None' : 'Added',
      },
      {
        section: 'Text of Proposed Regulation',
        status: this._regulationTextFormIsInvalid ? 'Required' : 'Complete',
        form: 'regulationtext'
      },
    ];
  }

  private uploadRequirement(key: string): string {
    let requirementStatus = 'Required';
    if(key == 'consultationDocumentation') requirementStatus = 'Optional';
    if (
      // Null check display_file_name over file_path so that you don't need to load all the files from storage during editing
      this.uploadInputs.get(key)?.display_file_name &&
      !this.uploadInputs.get(key)?.show_errors
    ) {
      requirementStatus = 'Added';
    }
    else if(this.uploadInputs.get(key)?.show_errors) requirementStatus = 'Invalid';
    return requirementStatus;
  }

  private getURL(key:string) :any {
    return this.uploadInputs.get(key)?.url
  }

  displayedColumnsFinalizeDris: string[] = ['section', 'status'];

  // Tree selector for Regulation Text START
  treeControl = new NestedTreeControl<RegulationTextNode>(
    (node) => node.children
  );
  dataSource = new MatTreeNestedDataSource<RegulationTextNode>();
  hasChild = (_: number, node: RegulationTextNode) =>
    !!node.children && node.children.length > 0;
  childlessSection = (_: number, node: RegulationTextNode) => !!node.children && node.children.length == 0 && node.nodeType == RegulationTextNodeType.SECTION;
  childlessSubsection = (_: number, node: RegulationTextNode) => node.nodeType == RegulationTextNodeType.SUBSECTION;
  regulation_sections_form: Map<string, any>;
  activeRegulationSection: any = null;
  activeRegulationSectionUuid: string = '';
  activeRegulationSubsection: any = null;
  activeRegulationSubsectionUuid: string = '';
  parentOfActiveRegulationSubsectionUuid: string = '';
  private _regulationTextFormIsInvalid: boolean = true;
  // Tree selector END
  
  drisMode:any;
  originalUploadFolders:any = {}

  checkDrisForm = {
    subject:[
      { type: "required", message: "Regulation short title is required" },
    ],
    title:[
      { type: "required", message: "Regulation title is required" },
    ],
    reg_instrument:[
      { type: "required", message: "Regulation Intrument is required" },
    ],
    reg_agency:[
      { type: "required", message: "Issuing agency/ies is required" },
    ],
    reg_number:[
      { type: "required", message: "PBRIS number is required" },
    ],
    reg_classification:[
      { type: "required", message: "Classification is required" },
    ],
    reg_sector:[
      { type: "required", message: "Sector is required" },
    ],
    reg_business:[
      { type: "required", message: "Stage of business is required" },
    ],
    reg_division:[
      { type: "required", message: "Division is required" },
    ],
    reg_case:[
      { type: "required", message: "Case use is required" },
    ],
    reg_stageoflife:[
      { type: "required", message: "Stage of life is required" },
    ],
    reg_lifeevent:[
      { type: "required", message: "Life event is required" },
    ],
    reg_juris:[
      { type: "required", message: "Jurisdiction is required" },
    ],
    reg_legal:[
      { type: "required", message: "Legal bases required" },
    ],
    reg_legal_item:[
      { type: "required", message: "Legal bases required" },
    ],
    activeRegulationSection:{
      section_title:[
        { type: "required", message: "Section Title is required" },
      ],
      section_type:[
        { type: "required", message: "Section Type is required" },
      ],
      section_text:[
        { type: "required", message: "Section Text is required" },
      ],
      subsection_title:[
        { type: "required", message: "Subsection Title is required" },
      ],
      subsection_text:[
        { type: "required", message: "Subsection Text is required" },
      ],
    },
  }

  drisform = new UntypedFormGroup(
    {
      firebase_folder: new UntypedFormControl('regulationsProposedDris'),

      subject: new UntypedFormControl('', Validators.required),
      title: new UntypedFormControl('', Validators.required),
      reg_instrument: new UntypedFormControl('', Validators.required),
      reg_agency: new UntypedFormControl('', Validators.required),
      // Not in regulatory notification form
      reg_number: new UntypedFormControl('', Validators.required),

      // Get these from the tags field, not the reg_notif_form field
      reg_classification: new UntypedFormControl('Business'),
      // Business only
      reg_sector: new UntypedFormControl(''),
      reg_business: new UntypedFormControl(''),
      reg_division: new UntypedFormControl(''),
      reg_case: new UntypedFormControl(''),
      // Non-Business only
      reg_stageoflife: new UntypedFormControl(''),
      reg_lifeevent: new UntypedFormControl(''),

      reg_juris: new UntypedFormControl('', Validators.required),
      reg_legal: new UntypedFormArray([], Validators.required),
      reg_intl: new UntypedFormArray([]),

      has_sub_tags:new UntypedFormControl(false, Validators.required),
      sub_tags: new UntypedFormArray([]),

      // regulation repealed has a value if regulation_repeal is true
      regulation_repeal: new UntypedFormControl(false, Validators.required),
      reg_repeal: new UntypedFormArray([]),

      has_annex_files: new UntypedFormControl(false, Validators.required),
      regulation_text_annex_file_name: new UntypedFormControl(''),
      regulation_text_annex_file_folder: new UntypedFormControl(''),
    },
    {
      validators: [regulationRequiredByClassification(),hasSubTagValidator()],
    }
  );

  oneMBinByte = 1000000

  uploadsForm = this._fB.group({
    regulatoryImpactStatement: new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    policyObjective: new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    policyProblem:  new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    policyOptions:  new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    policyOptionsAssessment:  new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    impactAssessment:  new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    consultationDocumentation: new UntypedFormGroup({
      count: new UntypedFormControl(0,Validators.max(1)),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    implementationEnforcement: new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    monitoringEvaluation: new UntypedFormGroup({
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
    regulationTextAnnex: new UntypedFormGroup({
      count: new UntypedFormControl(0,Validators.max(1)),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
    }),
  })

  checkUploadsForm = {
    regulatoryImpactStatement:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    policyObjective:[
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'min', message: 'No Uploaded file'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    policyProblem:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    policyOptions:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    policyOptionsAssessment:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    impactAssessment:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    consultationDocumentation:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    implementationEnforcement:[
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'min', message: 'No Uploaded file'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    monitoringEvaluation:[
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'min', message: 'No Uploaded file'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
    regulationTextAnnex:[
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'min', message: 'No Uploaded file'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
  };
  
  
  allowedExtensions = /(\.doc|\.docx|\.ppt|\.pptx|\.xls|\.xlsx|\.pdf)$/i;
  acceptedFileType:any=[
    'application/pdf','application/vnd.ms-excel','application/msword','text/csv"',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]
  
  dataLoaded:any = {
    agency:false,
    sector:false,
    division:false,
    sob:false,
    case:false,
    sol:false,
    le:false,
    juris:false,
    instrument:false,
  }

  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    public worklistService: WorklistService,
    private _fB: UntypedFormBuilder, 
  ) {
    this.loadFormIndependentReferences();
    this.valueChangeSetter()

    this.regulation_sections_form = new Map();

    if(this.route.snapshot.params.regulation_id && this.route.snapshot.params.worklist_id && this.route.snapshot.params.cris_id) {
      this.edit_regulation_id = this.route.snapshot.params.regulation_id;
      this.edit_worklist_id = this.route.snapshot.params.worklist_id;
      this.edit_cris_id = this.route.snapshot.params.cris_id;
      this.loadCrisInformation();
    }
    
    if(this.route.snapshot.params.dris_id) {
      this.edit_dris_id = this.route.snapshot.params.dris_id;
      this.edit_regulation_id = this.route.snapshot.queryParams.a;
      this.loadDrisInformation();
    }
  }

  ngOnInit(): void {

    // this.drisform.get('reg_classification')?.valueChanges.subscribe((val) => {
    //   switch (val) {
    //     case 'Business':
    //       // Empty Non-Business fields
    //       this.drisform.patchValue({
    //         reg_stageoflife: '',
    //         reg_lifeevent: '',
    //       });
    //       break;
    //     case 'Non-Business':
    //       // Empty Business fields
    //       this.drisform.patchValue({
    //         reg_sector: '',
    //         reg_business: '',
    //         reg_division: '',
    //         reg_case: '',
    //       });
    //       break;
    //   }
    // });

    this.valueChangeChecker1('reg_classification');

    this.valueChangeChecker1('reg_sector');
    this.valueChangeChecker1('reg_business');
    this.valueChangeChecker1('reg_division');
    this.valueChangeChecker1('reg_case');

    this.valueChangeChecker1('reg_stageoflife');
    this.valueChangeChecker1('reg_lifeevent');
    
    this.valueChangeChecker1('reg_juris');

    // Force scroll to top so user can read the preface for PIS
    // Consider creating a service or routing listener for this
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // Actual first page
    this.currentFormPage = 'uploadstatements';
  }

  checkPageLoader(){
    let loaded;
    for(let check of Object.keys(this.dataLoaded)){
      if(loaded == undefined || loaded == null) loaded = this.dataLoaded[check]
      else loaded = loaded && this.dataLoaded[check]
    }
    return loaded
  }

  valueChangeChecker1(controlName:any,){
    (this.drisform.controls[controlName] as UntypedFormControl).valueChanges
    .subscribe({
      next:(value)=>{
        let tempArr:any[] = [];
        if(controlName == 'reg_sector') tempArr = this.sectors
        if(controlName == 'reg_division') tempArr = this.divisions_select
        if(controlName == 'reg_business') tempArr = this.sobs
        if(controlName == 'reg_case') tempArr = this.cases_select

        if(controlName == 'reg_stageoflife') tempArr = this.sols
        if(controlName == 'reg_lifeevent') tempArr = this.lifeevents_select

        if(controlName == 'reg_juris') tempArr = this.jurisdictions

        if(tempArr.length > 0 && controlName != 'reg_classification'){
          
        }else if(controlName == 'reg_classification'){
          switch (value) {
            case 'Business':
              // Empty Non-Business fields
                this.drisform.patchValue({
                  reg_sector: '',
                  reg_business: '',
                  reg_division: '',
                  reg_case: '',
                  reg_stageoflife: '',
                  reg_lifeevent: '',
                });
                this.businessValidators()
              break;
            case 'Non-Business':
              // Empty Business fields
                this.drisform.patchValue({
                  reg_sector: '',
                  reg_business: '',
                  reg_division: '',
                  reg_case: '',
                  reg_stageoflife: '',
                  reg_lifeevent: '',
                });
                this.nonBusinessValidators()
              break;
          }
        }
      }
    });
  }

  valueChangeSetter(){
    this.drisform.controls.has_annex_files.valueChanges.subscribe({
      next:(value)=>{
        if(value == true) (this.uploadsForm.controls['regulationTextAnnex'] as UntypedFormGroup).controls['count'].setValidators([Validators.min(1),Validators.max(1)])
        else{
          (this.uploadsForm.controls['regulationTextAnnex'] as UntypedFormGroup).controls['count'].setValidators(Validators.max(1))
          this.refreshUpload('regulationTextAnnex')
        }
      }
    })
  }
  
  async loadDrisInformation() {
    this.drisMode = 'editing'
    // TODO: Load DRIS information
    let dris_ref = this.afs.collection('regulations-proposed-dris').doc(this.edit_dris_id)
    dris_ref.snapshotChanges()
    .subscribe({
      next:async (data)=>{
        let pageinfo:any = data.payload.data();
        console.log('loadDrisInformation: ',pageinfo)
        this.currentRegulation = data.payload.data();
        this.currentRegulation.id = data.payload.id;
        
        this.drisform.patchValue({
          subject: pageinfo.subject,
          title: pageinfo.title,
          reg_instrument: pageinfo.reg_instrument,
          reg_agency: pageinfo.reg_agency,
          reg_number: pageinfo.reg_number,

          // Originally retrieved from tags property of proposed regulation but saved to the main cris form
          reg_classification: pageinfo.reg_classification,

          // Business only
          // reg_sector: pageinfo.reg_sector,
          // reg_business: pageinfo.reg_business,
          // reg_division: pageinfo.reg_division,
          // reg_case: pageinfo.reg_case,

          // Non-Business only
          // reg_stageoflife: pageinfo.reg_stageoflife,
          // reg_lifeevent: pageinfo.reg_lifeevent,

          reg_juris: pageinfo.reg_juris,

          has_sub_tags: pageinfo.has_sub_tags,

          // regulation repealed has a value if regulation_repeal is true
          regulation_repeal: pageinfo.regulation_repeal,

          has_annex_files: pageinfo.has_annex_files,

          regulation_text_annex_file_folder: pageinfo.regulation_text_annex_file_folder,
          regulation_text_annex_file_name: pageinfo.regulation_text_annex_file_name,
        });

        switch(pageinfo.reg_classification){
          case 'Business':{
            this.drisform.patchValue({
              reg_sector: pageinfo.reg_sector,
              reg_business: pageinfo.reg_business,
              reg_division: pageinfo.reg_division,
              reg_case: pageinfo.reg_case,
            })
            this.businessValidators();
            break;
          }
          case 'Non-Business':{
            this.drisform.patchValue({
              reg_stageoflife: pageinfo.reg_stageoflife,
              reg_lifeevent: pageinfo.reg_lifeevent,
            })
            this.nonBusinessValidators();
            break;
          }
        }

        if(pageinfo.has_sub_tags && pageinfo.sub_tags){
          pageinfo.sub_tags.map((sub_tag:any)=>{
            this.addSubtag('sub_tags',sub_tag)
          })
        }

        if (pageinfo.reg_legal) {
          pageinfo.reg_legal.forEach((legal: any) => {
            this.reg_legal_val.push(new UntypedFormControl(legal,[Validators.required]));
          });
        }

        if (pageinfo.reg_intl) {
          pageinfo.reg_intl.forEach((intl: any) => {
            this.reg_intl_val.push(new UntypedFormControl(intl));
          });
        }

        if (pageinfo.reg_repeal) {
          pageinfo.reg_repeal.forEach((reg_repeal: any) => {
            this.reg_repeal_val.push(new UntypedFormControl(reg_repeal));
          });
        }

        this.loadFormDependentReferences();

        this.fillUpRegulationText(pageinfo.regulation_text);
        
        // Load uploaded files for the check
        const uploadNames = [
          'regulatoryImpactStatement',
          'policyObjective',
          'policyProblem',
          'policyOptions',
          'policyOptionsAssessment',
          'impactAssessment',
          'consultationDocumentation',
          'implementationEnforcement',
          'monitoringEvaluation',
        ];

        for (var name of uploadNames) {
          let uploadInput = this.uploadInputs.get(name);

          if(uploadInput && pageinfo.upload_folders && pageinfo.upload_folders[name] && pageinfo.upload_folders.hasOwnProperty(name)) {
            // // Only set display_file_name so that you don't need to load all the files from storage during editing
            // uploadInput.display_file_name = pageinfo.upload_folders[name].split('/')[4];
            // uploadInput.hasFile = true;

            // const drisSectionItemCtr = this.drisSections.map(val=>val.code).indexOf(name)
            // console.log('drisSectionItemCtr: ',drisSectionItemCtr)
            // if(drisSectionItemCtr >= 0){
            //   const fileData:any = await this.refToURL(pageinfo.upload_folders[name])
            //   if(fileData){
            //     this.drisSections[drisSectionItemCtr].link = fileData.url
            //     console.log('link: ',fileData.url)
            //     // fileData has type
            //   }
            // }
            this.originalUploadFolders[name] = {}

            let tokenized = pageinfo.upload_folders[name].split('/')
            if(tokenized.length > 0){
              uploadInput.display_file_name = tokenized[tokenized.length-1];
              uploadInput.file_path = tokenized.slice(0,-1).join("/")
              uploadInput.hasFile = true;
              this.originalUploadFolders[name].display_file_name = uploadInput.display_file_name
              this.originalUploadFolders[name].file_path = uploadInput.file_path
              this.originalUploadFolders[name].hasFile = true;
              // (this.uploadsForm.controls[name] as FormControl).setValue(1);
              (this.uploadsForm.controls[name] as UntypedFormGroup).patchValue({
                count:1,
                type:'',
                size:0,
              })
            }

            const fileData:any = await this.refToURL(pageinfo.upload_folders[name])
            if(fileData){
              // fileData has type
              uploadInput.url = fileData.url
              this.originalUploadFolders[name].url = fileData.url
            }
          }
        }

        
        const textRegulation = [
          'regulationTextAnnex'
        ]

        for (var name of textRegulation) {
          let uploadInput = this.uploadInputs.get(name);

          if(uploadInput &&  pageinfo.regulation_text_annex_file_folder && pageinfo.regulation_text_annex_file_name){
            uploadInput.display_file_name = pageinfo.regulation_text_annex_file_name
            uploadInput.file_path = pageinfo.regulation_text_annex_file_folder
            uploadInput.hasFile = true;
          }
        }

        this.drisform.markAllAsTouched()
        this.drisform.markAsDirty()
      },
      error:(err)=>{
        console.error('Error: Loading DRIS info ',err)
      }
    })
  }

  async loadCrisInformation() {
    this.drisMode = 'create'
    let regulation_ref = this.afs
      .collection(`regulations-proposed-cris`)
      .doc(this.edit_cris_id);

    regulation_ref
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          let pageinfo = data.payload.data();

          this.drisform.patchValue({
            subject: pageinfo.subject,
            title: pageinfo.title,
            reg_instrument: pageinfo.reg_instrument,
            reg_agency: pageinfo.reg_agency,
            reg_number: pageinfo.reg_number,
            reg_classification: pageinfo.reg_classification,

            // Business only
            // reg_sector: pageinfo.reg_sector,
            // reg_business: pageinfo.reg_business,
            // reg_division: pageinfo.reg_division,
            // reg_case: pageinfo.reg_case,

            // Non-Business only
            // reg_stageoflife: pageinfo.reg_stageoflife,
            // reg_lifeevent: pageinfo.reg_lifeevent,

            reg_juris: pageinfo.reg_juris,

            has_sub_tags: pageinfo.has_sub_tags,

            // regulation repealed has a value if regulation_repeal is true
            regulation_repeal: pageinfo.regulation_repeal,

            has_annex_files: pageinfo.has_annex_files,

            regulation_text_annex_file_folder: pageinfo.regulation_text_annex_file_folder,
            regulation_text_annex_file_name: pageinfo.regulation_text_annex_file_name,

            consultation_file_folder: pageinfo.consultation_file_folder,
            consultation_file_name: pageinfo.consultation_file_name,
          });

          switch(pageinfo.reg_classification){
            case 'Business':{
              this.drisform.patchValue({
                reg_sector: pageinfo.reg_sector,
                reg_business: pageinfo.reg_business,
                reg_division: pageinfo.reg_division,
                reg_case: pageinfo.reg_case,
              })
              this.businessValidators();
              break;
            }
            case 'Non-Business':{
              this.drisform.patchValue({
                reg_stageoflife: pageinfo.reg_stageoflife,
                reg_lifeevent: pageinfo.reg_lifeevent,
              })
              this.nonBusinessValidators();
              break;
            }
          }

          if(pageinfo.has_sub_tags && pageinfo.sub_tags){
            pageinfo.sub_tags.map((sub_tag:any)=>{
              this.addSubtag('sub_tags',sub_tag)
            })
          }

          if (pageinfo.reg_legal) {
            pageinfo.reg_legal.forEach((legal: any) => {
              this.reg_legal_val.push(new UntypedFormControl(legal,[Validators.required]));
            });
          }

          if (pageinfo.reg_intl) {
            pageinfo.reg_intl.forEach((intl: any) => {
              this.reg_intl_val.push(new UntypedFormControl(intl));
            });
          }

          if (pageinfo.reg_repeal) {
            pageinfo.reg_repeal.forEach((reg_repeal: any) => {
              this.reg_repeal_val.push(new UntypedFormControl(reg_repeal));
            });
          }

          this.loadFormDependentReferences();
          
          // At the DRIS level, regulation_text should already exist from the loaded CRIS
          this.fillUpRegulationText(pageinfo.regulation_text);
          
          this.drisform.markAllAsTouched()
          this.drisform.markAsDirty()
        }),
        take(1)
      )
      .subscribe();
  }

  businessValidators(){
    console.log('businessValidators');
    (this.drisform.controls.reg_sector as UntypedFormControl).setValidators([Validators.required]);
    // ((this.proposedregulationform.controls[controlGroup] as FormGroup).controls.reg_division as FormControl).setValidators([Validators.required]);
    (this.drisform.controls.reg_business as UntypedFormControl).setValidators([Validators.required]);
    (this.drisform.controls.reg_case as UntypedFormControl).setValidators([Validators.required]);
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).clearValidators();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).clearValidators();

    (this.drisform.controls.reg_sector as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_division as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_business as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_case as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).markAsPristine();

    (this.drisform.controls.reg_sector as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_division as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_business as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_case as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    (this.drisform.controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_division as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_business as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_case as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  nonBusinessValidators(){
    console.log('nonBusinessValidators');
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).setValidators([Validators.required]);
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).setValidators([Validators.required]);

    (this.drisform.controls.reg_sector as UntypedFormControl).clearValidators();
    // (this.crisform.controls.reg_division as FormControl).clearValidators();
    (this.drisform.controls.reg_business as UntypedFormControl).clearValidators();
    (this.drisform.controls.reg_case as UntypedFormControl).clearValidators();

    (this.drisform.controls.reg_sector as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_division as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_business as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_case as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).markAsPristine();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).markAsPristine();

    (this.drisform.controls.reg_sector as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_division as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_business as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_case as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    (this.drisform.controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_division as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_business as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_case as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    (this.drisform.controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  /**
   *  ________________________
   * |                        |
   * |        Reminder        |
   * |________________________|
   *
   * Please move all firestore references
   * to 'References'=>'common/PBRIS/ARTEMIS'=>...
   *
   */

   loadFormIndependentReferences() {
    this.loadAgencies();
    this.loadSectors();
    this.loadSobs();
    this.loadJuris();
    this.loadSols();
    this.loadLegalBases();
    this.loadInternationalAgreements();
    this.loadRegulations();
  }

  loadFormDependentReferences() {
    this.loadInstruments();
    this.loadDivisions();
    this.loadCases();
    this.loadLifeEvents();
  }

  loadAgencies() {
    this.afs
      .collection(`Agency`)
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.agency = false
          this.loader = false;
          this.agencies = [];
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.agencies.includes(item) === false) {
              this.agencies.push(item);
            }
          });
          this.dataLoaded.agency = true
        },
        error:(err)=> this.dataLoaded.agency = true
      });
  }

  loadSectors() {
    this.afs
      .collection(`Sector`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
          next:(data: any) => {
          this.dataLoaded.sector = false
          this.sectors = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.sectors.includes(item) === false) {
              this.sectors.push(item);
            }
          });

          this.dataLoaded.sector = true
        },
        error:(err)=> this.dataLoaded.sector = true
      });
  }

  loadSobs() {
    this.afs
      .collection(`Stage of Business`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.sob = false
          this.sobs = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.sobs.includes(item) === false) {
              this.sobs.push(item);
            }
          });
          this.dataLoaded.sob = true
        },
        error:(err)=> this.dataLoaded.sob = true
      });
  }

  loadSols() {
    this.afs
      .collection(`Stage of Life`)
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.sol = false
          this.sols = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.sols.includes(item) === false) {
              this.sols.push(item);
            }
          });
          this.dataLoaded.sol = true
        },
        error:(err)=>this.dataLoaded.sol = true
      });
  }

  loadLifeEvents() {
    this.afs
      .collection(`Life Event`)
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.le = false
          this.lifeevents = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
              
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.lifeevents.includes(item) === false) {
              this.lifeevents.push(item);
            }
          });

          // Now form-dependent on reg_stageoflife
          if(this.drisform.value.reg_stageoflife) {
            let stageOfLife = this.sols.find((option) => option.id === this.drisform.value.reg_stageoflife).stage.toLowerCase();
            this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
          }
          this.dataLoaded.le = true
        },
        error:(err)=>this.dataLoaded.le = true
      });
  }

  loadDivisions() {
    this.afs
      .collection(`Division`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.division = false
          this.divisions = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
              
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.divisions.includes(item) === false) {
              this.divisions.push(item);
            }
          });

          // Now form-dependent on reg_sector
          if(this.drisform.value.reg_sector) {
            let section = this.sectors.find((option) => option.id === this.drisform.value.reg_sector).section.toLowerCase();
            this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
          }
          this.dataLoaded.division = true        
        },
        error:(err)=>this.dataLoaded.division = true
      });
  }

  loadJuris() {
    this.afs
      .collection(`Jurisdiction`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.juris = false
          this.jurisdictions = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
                
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.jurisdictions.includes(item) === false) {
              this.jurisdictions.push(item);
            }
          });
          this.dataLoaded.juris = true
        },
        error:(err)=>this.dataLoaded.juris = true
      });
  }

  loadCases() {
    this.afs
      .collection(`Case Use`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.dataLoaded.case = false
          this.cases = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
                  
            if(item.ref) delete item.ref
            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy

            if (this.cases.includes(item) === false) {
              this.cases.push(item);
            }
          });

          // Now form-dependent on reg_business
          if(this.drisform.value.reg_business) {
            let section = this.sobs.find((option) => option.id === this.drisform.value.reg_business).section.toLowerCase();
            this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
          }
          this.dataLoaded.case = true    
        },
        error:(err)=>this.dataLoaded.case = true
      });
  }

  loadInstruments() {
    this.afs
      .collection(`Instrument`, (filter) => filter.orderBy('section'))
      .snapshotChanges()
      .subscribe({
        next:(data: any) => {
          this.instruments = [];
          this.loader = false;
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (this.instruments.includes(item) === false) {
              this.instruments.push(item);
            }
          });

          // Set multipleIssuingAgency during loading
          // Now a "form-dependent reference data call, so put it inside the subscribe"
          // this.updateIssuingAgency(this.drisform.value.reg_instrument);
          if(this.initialInput == false) this.updateIssuingAgency(this.drisform.value.reg_instrument);
          else{
            this.initialInput = false
            this.updateInitialIssuingAgency(this.drisform.value.reg_instrument);
          }
          this.dataLoaded.instrument = true
        },
        error:(err)=>this.dataLoaded.instrument = true
      });
  }

  loadLegalBases() {
    this.legalbases = this.afs.collection(`Legal Bases`, filter => filter.orderBy('name')).valueChanges();
    // CRIS and DRIS should not have a default legal base because it must exists from a previous counterpart (proposed regulation or CRIS)
  }

  loadInternationalAgreements() {
    this.intlagreements = this.afs.collection(`International Agreements`, filter => filter.orderBy('name')).valueChanges();
  }

  loadRegulations() {
    let batches = [];

    batches.push(
      this.afs.collection('PBRIS 1 Regulations', filter => filter.orderBy('short_title')).snapshotChanges()
    );

    batches.push(
      this.afs.collection('regulations-existing-phase-02', filter => filter.where('is_posted', '==', true)).snapshotChanges()
    );

    combineLatest(batches)
      .pipe(
        tap(
          (results) => {
            let index = 0;
            let reg_results: any[] = [];
            
            results.forEach(content => {
              content.forEach(async (results: any) => {
                let item: any = results.payload.doc.data();
                item.id = results.payload.doc.id;
                
                let resultObj = this.buildNewRegEntry(index == 0 ? item.short_title : item.subject);
                reg_results.push(resultObj);
              });
              index++;
            });

            reg_results.sort((a:any,b:any)=>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            );
            this.regulations = of(reg_results);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  private _filter(value: string, autocompletecode: string): Observable<any[]> {
    const filterValue = value.toLowerCase();

    switch (autocompletecode) {
      case "legal":
        return this.legalbases.pipe(
          map(legalbases => {
            return legalbases.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      case "intl":
        return this.intlagreements.pipe(
          map(intlagreements => {
            return intlagreements.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      case "reg":
        return this.regulations.pipe(
          map(regulations => {
            return regulations.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      default:
        return of([]);
    }
  }

  get reg_legal_val() {
    return this.drisform.get('reg_legal') as UntypedFormArray;
  }

  get reg_intl_val() {
    return this.drisform.get('reg_intl') as UntypedFormArray;
  }

  get reg_repeal_val() {
    return this.drisform.get('reg_repeal') as UntypedFormArray;
  }

  addLegalBase() {
    let newControl = new UntypedFormControl('',[Validators.required]);
    this.legalbasesobs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(value, "legal"))
      )
    );
    this.reg_legal_val.push(newControl);
  }

  removeLegalBase(index: number) {
    this.reg_legal_val.removeAt(index);
    this.legalbasesobs.splice(index,1);
  }

  addInternationalAgreement() {
    let newControl = new UntypedFormControl('');
    this.intlagreementsobs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(value, "intl"))
      )
    )
    this.reg_intl_val.push(newControl);
  }

  removeInternationalAgreement(index: number) {
    this.reg_intl_val.removeAt(index);
    this.intlagreementsobs.splice(index,1);
  }

  addRegulationRepealed() {
    let newControl = new UntypedFormControl('');
    this.reg_repeal_obs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(typeof value === 'string' ? value : value.name, "reg"))
      )
    )
    this.reg_repeal_val.push(newControl);
  }

  removeRegulationRepealed(index: number) {
    this.reg_repeal_val.removeAt(index);
    this.reg_repeal_obs.splice(index,1);
  }

  displayReg(reg: any): string {
    return reg && reg.name ? reg.name : '';
  }

  private buildNewRegEntry(name: string): any {
    return {
      name: name
    }
  }

  instrumentOnChange(event: any) {
    this.updateIssuingAgency(event.target.value);
  }

  private updateInitialIssuingAgency(instrumentVal:string){
    const useForm = this.drisform;
    // Handle blank reg_instrument
    if(!instrumentVal && (Array.isArray(useForm.value.reg_agency) ? (useForm.value.reg_agency && useForm.value.reg_agency.length == 0) : (!useForm.value.reg_agency))) {
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      return;
    }

    let instrument = this.instruments.find((option) => option.id === instrumentVal).name.toLowerCase();
    this.multipleIssuingAgency = instrument.includes('joint');
    console.log('reg_agency: ',useForm.value.reg_agency)
    if(this.multipleIssuingAgency) {
      useForm.controls.reg_agency.enable();
    }
    else {
      if(!useForm.value.reg_agency){
        useForm.patchValue({
          reg_agency: sessionStorage.getItem("agency_id") || ''
        });
      }
      useForm.controls.reg_agency.disable();
    }
  }

  private updateIssuingAgency(instrumentVal: string) {
    // For copying to other pages
    const useForm = this.drisform;

    // Handle blank reg_instrument
    if(!instrumentVal) {
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      return;
    }

    let instrument = this.instruments.find((option) => option.id === instrumentVal).name.toLowerCase();
    this.multipleIssuingAgency = instrument.includes('joint');
    // Force reg_agency values after switching reg_instrument value
    if(this.multipleIssuingAgency) {
      // joint: reg_agency is an array
      useForm.controls.reg_agency.enable();
      useForm.patchValue({
        reg_agency: [sessionStorage.getItem("agency_id") || '']
      });
    }
    else {
      // non-joint: reg_agency is a string
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
    }
  }

  sectorOnChange(event: any) {
    let section = this.sectors.find((option) => option.id === event.target.value).section.toLowerCase();
    this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
  }

  sobOnChange(event: any) {
    let section = this.sobs.find((option) => option.id === event.target.value).section.toLowerCase();
    this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
  }

  solOnChange(event: any) {
    let stageOfLife = this.sols.find((option) => option.id === event.target.value).stage.toLowerCase();
    this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
  }

  gotoForm(selectedForm: any) {
    /**
     * step 1 : uploadstatements
     * step 2 : details
     * step 3 : regulationtext
     * step 4 : finalize
     */
    this.currentFormPage = selectedForm;
  }
  
  // file download handler
  refToURL(filePath:string){
    return new Promise((res,rej)=>{
      this.store.ref(filePath).getDownloadURL()
      .subscribe({
        next:(data1)=>{
          this.store.ref(filePath).getMetadata()
          .subscribe({
            next:(data2)=>{
              let item:any = {
                url: data1,
                metaData: data2,
              }
              return res(item)
            },
            error:(err)=>{
              // console.error('refToURL Error: ',err)
              let item:any = {
                url: data1,
                metaData: null,
              }
              return res(item)
            }
          })
        },
        error:(err)=>{
          // console.error('refToURL Error: ',err)
          return res(false)
        }
      })
    })
  }
  
  downloadFile(downloadLink:any){
    window.open(downloadLink, '_blank')
  }
  // !file download handler
  
  // file upload to browser handler

  /*
  // saveFileInformation(fieldName: string, event: any) {
  //   let uploadInput = this.uploadInputs.get(fieldName);
  //   if (uploadInput) {
  //     var allowedExtensions =
  //       /(\.doc|\.docx|\.ppt|\.pptx|\.xls|\.xlsx|\.pdf)$/i;
  //     if (!allowedExtensions.exec(event.target.files[0].name)) {
  //       uploadInput.display_file_name = '';
  //       uploadInput.show_errors = true;
  //       return;
  //     }
  //     uploadInput.file_path = event.target.files[0];
  //     uploadInput.display_file_name = uploadInput.file_path.name;
  //     uploadInput.newFile = true;
  //     uploadInput.show_errors = false;

  //     // Regulation text annex is set during file upload, legacy from upload-existing-regulations and pbris-upload-proposed-regulations
  //     if (fieldName === 'regulationTextAnnex') {
  //       this.drisform
  //         .get('regulation_text_annex_file_name')
  //         ?.setValue(uploadInput.file_path.name);
  //     }
  //   }
  // }
  //*/

  saveFileInformation(fieldName: string, files: any) {
    let uploadInput = this.uploadInputs.get(fieldName);
    (this.uploadsForm.controls[fieldName] as UntypedFormGroup).markAllAsTouched();
    (this.uploadsForm.controls[fieldName] as UntypedFormGroup).markAsDirty();
    if (uploadInput) {
      if(files.length > 0){
        console.log('file: ',files[0])
        if(files.length > 1){
          uploadInput.display_file_name = files[0].name+', ...';
          if(uploadInput.newFile = true){
            (this.uploadsForm.controls[fieldName] as UntypedFormGroup).patchValue({
              count:files.length,
              type:'',
              size:0
            })
          }
        }else{
          const file:File = files[0]
          // var allowedExtensions = /(\.doc|\.docx|\.ppt|\.pptx|\.xls|\.xlsx|\.pdf)$/i;
          // if (!allowedExtensions.exec(files[0].name)) {
          // if(!file || (file && !this.acceptedFileType.includes(file.type))){
          //   console.log('file not allowed')
          //   uploadInput.display_file_name = '';
          //   // uploadInput.show_errors = true;
          //   (document.getElementById(fieldName) as HTMLInputElement).value = "";
          //   if(uploadInput.newFile = true){
          //     (this.uploadsForm.controls[fieldName] as FormGroup).patchValue({
          //       count:0,
          //       type:files[0].type
          //     })
          //     // (this.uploadsForm.controls[fieldName] as FormControl).setValue(0);
          //   }else{
          //     (this.uploadsForm.controls[fieldName] as FormGroup).patchValue({
          //       type:files[0].type
          //     })
          //   }
          //   return;
          // }
  
          uploadInput.file_path = file;
          uploadInput.display_file_name = file.name;
          uploadInput.newFile = true;
          uploadInput.url = window.URL.createObjectURL(file);
          uploadInput.show_errors = false; 
    
          // Regulation text annex is set during file upload, legacy from upload-existing-regulations and pbris-upload-proposed-regulations
          if (fieldName === 'regulationTextAnnex') {
            this.drisform.get('regulation_text_annex_file_name')?.setValue(uploadInput.file_path.name);
          }
          (this.uploadsForm.controls[fieldName] as UntypedFormGroup).patchValue({
            count:1,
            type:file.type,
            size:file.size
          })
        }
      }else{
        uploadInput.display_file_name = '';
        (document.getElementById(fieldName) as HTMLInputElement).value = "";
        if(uploadInput.newFile = true){
          (this.uploadsForm.controls[fieldName] as UntypedFormGroup).patchValue({
            count:files.length,
            type:'',
            size:0
          })
        }
      }
    }else{
      (document.getElementById(fieldName) as HTMLInputElement).value = "";
      (this.uploadsForm.controls[fieldName] as UntypedFormGroup).patchValue({
        count:0,
        type:'',
        size:0
      })
    }
  }

  onMultipleChange(controlCode: string, event: any): void {
    this.saveFileInformation(controlCode,event.target.files);
  }

  refreshUpload(controlCode:string){
    if(this.drisMode == 'create') this.refreshUploadCreate(controlCode)
    else if(this.drisMode == 'editing') this.refreshUploadEditing(controlCode)
  }

  refreshUploadCreate(controlCode:string){
    if(controlCode == 'others1'){
      this.refreshUploadCreate('policyObjective')
      this.refreshUploadCreate('policyProblem')
      this.refreshUploadCreate('policyOptions')
      this.refreshUploadCreate('policyOptionsAssessment')
      this.refreshUploadCreate('impactAssessment')
      this.refreshUploadCreate('consultationDocumentation')
      this.refreshUploadCreate('implementationEnforcement')
      this.refreshUploadCreate('monitoringEvaluation')
    }
    else{
      (this.uploadsForm.controls[controlCode] as UntypedFormGroup).markAllAsTouched();
      (this.uploadsForm.controls[controlCode] as UntypedFormGroup).markAsDirty();
      let uploadInput = this.uploadInputs.get(controlCode)
      if(uploadInput){
        uploadInput.display_file_name = ''
        uploadInput.file_path = null;
        uploadInput.newFile = false;
        uploadInput.url = null;
        uploadInput.show_errors = false; 
      }
      (this.uploadsForm.controls[controlCode] as UntypedFormGroup).patchValue({
        count:0,
        type:'',
        size:0
      })
    }
  }

  refreshUploadEditing(controlCode:string){
    if(controlCode == 'others1'){
      this.refreshUploadEditing('policyObjective')
      this.refreshUploadEditing('policyProblem')
      this.refreshUploadEditing('policyOptions')
      this.refreshUploadEditing('policyOptionsAssessment')
      this.refreshUploadEditing('impactAssessment')
      this.refreshUploadEditing('consultationDocumentation')
      this.refreshUploadEditing('implementationEnforcement')
      this.refreshUploadEditing('monitoringEvaluation')
    }
    else{
      (this.uploadsForm.controls[controlCode] as UntypedFormGroup).markAllAsTouched();
      (this.uploadsForm.controls[controlCode] as UntypedFormGroup).markAsDirty();
      let uploadInput = this.uploadInputs.get(controlCode)
      if(uploadInput){
        if(this.originalUploadFolders[controlCode] && this.originalUploadFolders.hasOwnProperty(controlCode)){
          uploadInput.display_file_name = this.originalUploadFolders[controlCode].display_file_name
          uploadInput.file_path = this.originalUploadFolders[controlCode].file_path
          uploadInput.newFile = this.originalUploadFolders[controlCode].newFile;
          uploadInput.url = this.originalUploadFolders[controlCode].url;
          uploadInput.show_errors = false;
          (this.uploadsForm.controls[controlCode] as UntypedFormGroup).patchValue({
            count:1,
            type:'',
            size:0
          })
        }
        else this.refreshUploadCreate(controlCode)
      }
    }
  }
  // !file upload to browser handler

  saveImageInformation(event: any) {
    if (this.activeRegulationSubsection) {
      var allowedExtensions = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
      if (!allowedExtensions.exec(event.target.files[0].name)) {
        this.activeRegulationSubsection.patchValue({
          subsection_image_file: null,
        });
      } else {
        this.activeRegulationSubsection.patchValue({
          subsection_image_file: event.target.files[0],
        });
        this.showSubsectionImagePreview();
      }
    }
  }

  updateTreeSectionName(event: any) {
    if (this.activeRegulationSection && this.activeRegulationSectionUuid) {
      this.dataSource.data.forEach((e) => {
        if (e && e.uuid === this.activeRegulationSectionUuid) {
          const parentRegulationSection: RegulationTextNode = e;
          parentRegulationSection.name = event.target.value;
          return;
        }
      });
    }
  }

  updateTreeSubsectionName(event: any) {
    if (
      this.activeRegulationSubsection &&
      this.activeRegulationSubsectionUuid
    ) {
      this.dataSource.data.forEach((e) => {
        if (e && e.uuid === this.parentOfActiveRegulationSubsectionUuid) {
          const parentRegulationSection: RegulationTextNode = e;
          parentRegulationSection.children?.forEach((f) => {
            if (f && f.uuid === this.activeRegulationSubsectionUuid) {
              const subsection: RegulationTextNode = f;
              subsection.name = event.target.value;
              return;
            }
          });
          return;
        }
      });
    }
  }

  showSubsectionImagePreview() {
    if (
      this.activeRegulationSubsection &&
      this.activeRegulationSubsection.value.subsection_image_file
    ) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(
        this.activeRegulationSubsection.value.subsection_image_file
      );
      fileReader.onload = () => {
        this.previewImg = fileReader.result as string;
      };
    } else {
      this.previewImg = null;
    }
  }

  async saveAsDraft() {
    this.saveAndUpload(WorklistStatusPBRISRegulation.DRAFT_DRIS);
  }

  async submitRegulation() {
    this.validateFields()
    if(this.uploadsForm.valid && this.drisform.valid){
      this.saveAndUpload(WorklistStatusPBRISRegulation.DRIS_FOR_VERIFICATION);
    }
    else console.warn('Invalid Form')
  }

  async saveAndUpload(status: string) {
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    this.loading = true;
    let uploadProgress!: Observable<number | undefined>;
    const dateUploadedStr = format(new Date(), 'yyyy-MM-dd');

    let dris_ref: any;
    let worklist_ref: any;

    let drisJSON = this.drisform.value;

    if (this.edit_dris_id) {
      dris_ref = this.afs.firestore
        .collection(`regulations-proposed-dris`)
        .doc(this.edit_dris_id);
    } else {
      dris_ref = this.afs.firestore
        .collection(`regulations-proposed-dris`)
        .doc();
    }

    // Worklist required anyway to even create a DRIS
    if (this.edit_worklist_id) {
      worklist_ref = this.afs.firestore
        .collection(`Worklist-Proposed Regulations`)
        .doc(this.edit_worklist_id);
      
      drisJSON.worklist_id = this.edit_worklist_id;
    }else{
      drisJSON.worklist_id = this.currentRegulation.worklist_id
    }
    
    if(this.edit_regulation_id){
      drisJSON.regulation_id = this.edit_regulation_id;
    }
    
    if(this.edit_cris_id){
      drisJSON.cris_id = this.edit_cris_id;
    }

    if(drisJSON.has_sub_tags == false){
      drisJSON.sub_tags = []
    }

    if(!drisJSON.reg_agency){
      console.log('crisJSON reg_instrument: ',drisJSON['reg_instrument'])
      let instrument = this.instruments.find((option) => option.id === drisJSON.reg_instrument).name.toLowerCase();
      console.log('instrument: ',instrument)
      const agency = sessionStorage.getItem("agency_id") || ''
      const multipleIssuingAgency = instrument.includes('joint');
      if(!multipleIssuingAgency){
        drisJSON.reg_agency = agency
        console.log('agency: ',agency)
      }
    }

    // Uploads START
    const uploadNames = [
      'regulatoryImpactStatement',
      'policyObjective',
      'policyProblem',
      'policyOptions',
      'policyOptionsAssessment',
      'impactAssessment',
      'consultationDocumentation',
      'implementationEnforcement',
      'monitoringEvaluation',
    ];
    let uploadObjJson: any = {};
    for (var name of uploadNames) {
      const uploadInput = this.uploadInputs.get(name);
      // if (uploadInput?.file_path) {
      //   let destinationPath = '';
      //   // Subdirectory format for uniqueness. no doc_date
      //   uploadObjJson[name] = `regulationsProposedDRIS/${dateUploadedStr}/${this.edit_regulation_id}/${name}/${uploadInput.file_path.name}`;
      //   // destinationPath = `${uploadObjJson[name]}/${uploadInput.file_path.name}`;
      //   const destinationRef = this.store.ref(uploadObjJson[name]);
      //   const task = this.store.upload(uploadObjJson[name], uploadInput.file_path);

      //   // uploadProgress = task.percentageChanges();
      // }
      // else if(this.edit_dris_id && uploadInput?.hasFile){
      //   uploadObjJson[name] = `regulationsProposedCRIS/${dateUploadedStr}/${this.edit_regulation_id}/${name}/${uploadInput.display_file_name}`;
      // }

      if(uploadInput?.newFile){
        uploadObjJson[name] = `regulationsProposedDRIS/${dateUploadedStr}/${this.edit_regulation_id}/${name}/${uploadInput.file_path.name}`;
        const task = this.store.upload(uploadObjJson[name], uploadInput.file_path);
      }else{
        if(uploadInput?.file_path) uploadObjJson[name] = `${uploadInput?.file_path}/${uploadInput?.display_file_name}`
        else uploadObjJson[name] = null
      }
    }
    drisJSON.upload_folders = uploadObjJson;
    // Uploads END

    // Regulation Text START
    // Must be an array
    var regulationText: Array<any> = [];

    /* 
      Because order matters for both regulation sections and subsections,
      regulation text must be stored in Firestore as an ordered array.
      However, the UUIDs generated during encoding are still used for the subsection image paths.
    */
    this.regulation_sections_form.forEach((v: any) => {
      // Do not return the FormGroup objects associated with regulation section and regulation subsections
      // Only return the actual FormGroup value
      let returnRegulationSection = {
        info: v.info.value,
        // Must be an array
        subsections: [] as Array<any>,
      };

      // Must be an array
      let returnRegulationSubsections: Array<any> = [];

      // Keep generated UUIDs as a unique path folder name for every subsection image uploaded
      v.subsections.forEach((v: UntypedFormGroup, k: string) => {
        let regulationSubsection = v.value;

        // Upload subsection images
        if (regulationSubsection.subsection_image_file) {
          const temp_subsection_image_file =
            regulationSubsection.subsection_image_file;
          // Subdirectory format for uniqueness. no doc_date
          const new_subsection_image_file_path = `regulationsProposedDRIS/${dateUploadedStr}/${this.edit_regulation_id}/subsections/${k}`;

          let subsectionImgDestinationPath = `${new_subsection_image_file_path}/${temp_subsection_image_file.name}`;
          // const destinationRef = this.store.ref(subsectionImgDestinationPath);
          const task = this.store.upload(subsectionImgDestinationPath,temp_subsection_image_file);

          // Replace regulationSubsection.subsection_image_file values with string name and folder so it can be saved in Firestore
          regulationSubsection.subsection_image_folder =
            new_subsection_image_file_path;
          regulationSubsection.subsection_image_file =
            temp_subsection_image_file.name;
          // TODO: Handle collective upload percentage
          // uploadProgress = task.percentageChanges();
        }

        returnRegulationSubsections.push(regulationSubsection);
      });

      returnRegulationSection.subsections = returnRegulationSubsections;
      regulationText.push(returnRegulationSection);
    });

    drisJSON.regulation_text = regulationText;

    const regulation_text_annex_filepath = this.uploadInputs.get('regulationTextAnnex');
    // // let regulation_text_annex_filepath = this.uploadInputs.get('regulationTextAnnex')?.file_path;
    // // Upload regulation text annex files
    // if (regulation_text_annex_filepath) {
    //   // Subdirectory format for uniqueness. no doc_date
    //   this.drisform.patchValue({
    //     regulation_text_annex_file_folder: `regulationsProposedDRIS/${dateUploadedStr}/${this.edit_regulation_id}/regulationTextAnnex`,
    //   });
    //   let regTextAnnexDestinationPath = `${this.drisform.value.regulation_text_annex_file_folder}/${regulation_text_annex_filepath.name}`;
    //   const destinationRef = this.store.ref(regTextAnnexDestinationPath);
    //   const task = this.store.upload(
    //     regTextAnnexDestinationPath,
    //     regulation_text_annex_filepath
    //   );
    // } else {
    //   // Handling in case the user uploaded an annex file but changed their mind and unticked has_annex_file
    //   this.drisform.patchValue({
    //     regulation_text_annex_file_name: '',
    //   });
    // }
    console.log('has_annex_files: ',this.drisform.value.has_annex_files)
    if(this.drisform.value.has_annex_files == true){
      console.log('regulation_text_annex_filepath newFile: ',regulation_text_annex_filepath?.newFile)
      if(regulation_text_annex_filepath?.newFile){
        this.drisform.patchValue({
          regulation_text_annex_file_folder: `regulationsProposedDRIS/${dateUploadedStr}/${this.edit_regulation_id}/regulationTextAnnex`
        })
        const regTextAnnexDestinationPath = `${this.drisform.value.regulation_text_annex_file_folder}/${this.drisform.value.regulation_text_annex_file_name}`;
        drisJSON.regulation_text_annex_file_folder = this.drisform.value.regulation_text_annex_file_folder
        console.log('regulation_text_annex_file_folder: ',drisJSON.regulation_text_annex_file_folder)
        const task = this.store.upload(regTextAnnexDestinationPath, regulation_text_annex_filepath.file_path);
      }
    }else{
      //check if theres an uploaded file and delete
      drisJSON.regulation_text_annex_file_folder = ''
      drisJSON.regulation_text_annex_file_name = ''
    }
    // Regulation Text END

    // Create DRIS
    // batch.set(dris_ref, drisJSON);
    if(this.edit_dris_id) {
      batch.update(dris_ref, drisJSON);
    }
    else {
      batch.set(dris_ref, drisJSON);
    }

    // TODO: Update of data once the data flows are clarified and resolved
    // Update worklist based on DRIS status
    // Set drisID so that the DRIS can be accessed from Worklist data
    // if(this.edit_worklist_id){
    if(drisJSON.worklist_id){
      let updateWorklistObj: Record<string,any> = await this.worklistService.updateWorklistStatusObj(status);
      updateWorklistObj.drisID = dris_ref.id ;
      batch.update(worklist_ref, updateWorklistObj);
    }

    await batch.commit().then(() => {
        alert('create successful');
        // email users
        // this.getUsersSubscribedtoRegulation();
        this.router.navigate(['/pbris/regulatory-management']);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        alert(error.message);
      });
  }

  fillUpRegulationText(regulationText: any) {
    // Load client steps
    if (regulationText) {
      let newData: RegulationTextNode[] = [];

      for (let regulationSection of regulationText) {
        // Not reused
        const initialRegulationSectionUuid = uuidv4();

        // Client step agency action
        let newRegulationSectionDataSourceChildren: RegulationTextNode[] = [];
        let newRegulationSectionDataSource: RegulationTextNode = {
          name: regulationSection.info.section_title,
          uuid: initialRegulationSectionUuid,
          children: newRegulationSectionDataSourceChildren,
          nodeType: RegulationTextNodeType.SECTION
        };

        let newSectionInfo = new UntypedFormGroup({
          section_title: new UntypedFormControl(
            regulationSection.info.section_title,
            Validators.required
          ),
          section_type: new UntypedFormControl(
            regulationSection.info.section_type,
            Validators.required
          ),
          section_text: new UntypedFormControl(
            regulationSection.info.section_text,
            Validators.required
          ),
        });

        let newRegulationSection = {
          info: newSectionInfo,
          subsections: new Map(),
        };

        for (let regulationSubsection of regulationSection.subsections) {
          let initialRegulationSubsectionUuid = '';
          if (
            regulationSubsection.subsection_image_folder &&
            regulationSubsection.subsection_image_folder.split('/') &&
            regulationSubsection.subsection_image_folder.split('/').length == 5
          ) {
            // Reuse UUID previously saved
            // Format is "regulationsProposedPhase02/<doc date>/<regulation id>/subsections/<subsection uuid>", so get the fifth one
            initialRegulationSubsectionUuid =
              regulationSubsection.subsection_image_folder.split('/')[4];
          } else {
            initialRegulationSubsectionUuid = uuidv4();
          }

          // Agency action data source
          const newRegulationSubsectionDataSource: RegulationTextNode = {
            name: regulationSubsection.subsection_title,
            uuid: initialRegulationSubsectionUuid,
            parentUuid: initialRegulationSectionUuid,
            nodeType: RegulationTextNodeType.SUBSECTION
          };
          // Push each agency action to children array of parent client step first
          newRegulationSectionDataSource.children?.push(
            newRegulationSubsectionDataSource
          );
          // Agency action form

          let newSubsection = new UntypedFormGroup({
            subsection_title: new UntypedFormControl(
              regulationSubsection.subsection_title,
              Validators.required
            ),
            subsection_text: new UntypedFormControl(
              regulationSubsection.subsection_text,
              Validators.required
            ),
            // TODO: load actual file object
            subsection_image_file: new UntypedFormControl(null),
          });

          // Load images
          if (
            regulationSubsection.subsection_image_folder &&
            regulationSubsection.subsection_image_file
          ) {
            firstValueFrom(this.store
              .ref(
                `${regulationSubsection.subsection_image_folder}/${regulationSubsection.subsection_image_file}`
              )
              .getDownloadURL())
              .then((url) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                  var blob = xhr.response;
                  newSubsection.patchValue({
                    subsection_image_file: new File(
                      [blob],
                      regulationSubsection.subsection_image_file,
                      { type: blob.type }
                    ),
                  });
                  newRegulationSection.subsections.set(
                    initialRegulationSubsectionUuid,
                    newSubsection
                  );
                };
                xhr.open('GET', url);
                xhr.send();
              });
          } else {
            newRegulationSection.subsections.set(
              initialRegulationSubsectionUuid,
              newSubsection
            );
          }
        }

        // Push to form
        this.regulation_sections_form.set(
          initialRegulationSectionUuid,
          newRegulationSection
        );

        // Finally, push client step to newData which will be the final dataSource.data
        newData.push(newRegulationSectionDataSource);
      }

      // Initial Client Steps selection tree (loaded)
      this.dataSource.data = newData;
      this.checkRegulationTextFormIsInvalid();
    }
    else {
      // TODO: Create a way to alert admins that someone is creating a DRIS without any regulation text
    }
  }

  getRegulationSectionInfo(node: RegulationTextNode) {
    const uuid = node.uuid;

    this.activeRegulationSubsection = null;
    this.activeRegulationSubsectionUuid = '';
    this.activeRegulationSection = this.regulation_sections_form.get(uuid);
    this.activeRegulationSectionUuid = uuid;

    // Automatically expand node upon adding new subsection
    this.treeControl.expand(node);
  }

  getRegulationSubsectionInfo(regulationSectionUuid: string, uuid: string) {
    this.activeRegulationSection = null;
    this.activeRegulationSectionUuid = '';
    this.parentOfActiveRegulationSubsectionUuid = regulationSectionUuid;
    const parentRegulationSection = this.regulation_sections_form.get(
      regulationSectionUuid
    );
    if (parentRegulationSection) {
      this.activeRegulationSubsection =
        parentRegulationSection.subsections.get(uuid);
      this.activeRegulationSubsectionUuid = uuid;
      this.showSubsectionImagePreview();
    }
  }

  addNewRegulationSection() {
    const newRegulationSectionUuid = uuidv4();

    let newData = this.dataSource.data;
    let newNode = {
      name: 'New Section',
      uuid: newRegulationSectionUuid,
      children: [],
      nodeType: RegulationTextNodeType.SECTION
    };
    newData.push(newNode);

    this.refreshNodeTree();

    let newSectionInfo = new UntypedFormGroup({
      section_title: new UntypedFormControl('New Section', Validators.required),
      section_type: new UntypedFormControl('', Validators.required),
      section_text: new UntypedFormControl('', Validators.required),
    });

    let newRegulationSection = {
      info: newSectionInfo,
      subsections: new Map(),
    };

    this.regulation_sections_form.set(
      newRegulationSectionUuid,
      newRegulationSection
    );

    this.getRegulationSectionInfo(newNode);
  }

  addNewRegulationSubsection(node: RegulationTextNode) {
    const newRegulationSubsectionUuid = uuidv4();
    const regulationSectionUuid = node.uuid;

    this.dataSource.data.forEach((e) => {
      if (e && e.uuid === regulationSectionUuid) {
        const parentRegulationSection: RegulationTextNode = e;

        let newSubsectionNode = {
          name: 'New Subsection',
          uuid: newRegulationSubsectionUuid,
          parentUuid: regulationSectionUuid,
          nodeType: RegulationTextNodeType.SUBSECTION
        };
        parentRegulationSection.children?.push(newSubsectionNode);

        this.refreshNodeTree();

        const parentRegulationSectionForm = this.regulation_sections_form.get(regulationSectionUuid);
        parentRegulationSectionForm.subsections.set(newRegulationSubsectionUuid, new UntypedFormGroup({
          subsection_title: new UntypedFormControl('New Subsection', Validators.required),
          subsection_text: new UntypedFormControl('', Validators.required),
          subsection_image_file: new UntypedFormControl(null),
        }));

        // Select the parent section to force it to expand, it will be deselected by getRegulationSubsectionInfo
        this.getRegulationSectionInfo(parentRegulationSection);
        this.getRegulationSubsectionInfo(regulationSectionUuid, newRegulationSubsectionUuid);

        return;
      }
    });

    // Automatically expand node upon adding new subsection
    this.treeControl.expand(node);
  }

  removeRegulationSection(uuid: string) {
    this.activeRegulationSection = null;
    this.activeRegulationSectionUuid = '';
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === uuid) {
        this.dataSource.data.splice(i, 1);
        this.refreshNodeTree();
      }
    }
    this.regulation_sections_form.delete(uuid);
  }

  removeRegulationSubsection(RegulationSectionUuid: string, uuid: string) {
    this.activeRegulationSubsection = null;
    this.activeRegulationSubsectionUuid = '';
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === RegulationSectionUuid) {
        var RegulationSubsections = this.dataSource.data[i].children || [];

        for (var j = 0; j < RegulationSubsections.length; j++) {
          if (RegulationSubsections[j].uuid === uuid) {
            RegulationSubsections.splice(j, 1);
          }
        }

        if (this.regulation_sections_form.get(RegulationSectionUuid)) {
          this.regulation_sections_form
            .get(RegulationSectionUuid)
            .subsections.delete(uuid);
        }
        this.refreshNodeTree();
      }
    }
  }

  private refreshNodeTree() {
    let newData = this.dataSource.data;
    // Workaround for updating nested tree nodes, do not remove
    this.dataSource.data = [];
    this.dataSource.data = newData;
    this.checkRegulationTextFormIsInvalid();
  }

  checkRegulationTextFormIsInvalid() {
    for (let [k, v] of this.regulation_sections_form) {
      if (!v.info.valid) {
        this._regulationTextFormIsInvalid = true;
        return;
      }

      for (let [k, v2] of v.subsections) {
        if (!v2.valid) {
          this._regulationTextFormIsInvalid = true;
          return;
        }
      }
    }
    this._regulationTextFormIsInvalid = false;
  }

  get regulationTextFormIsInvalid(): boolean {
    return this._regulationTextFormIsInvalid;
  }

  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup{
    switch(entry_id){
      case EntityBuilderIndex.TAG:{
        if(existing_obj && existing_obj.reg_classification){
          switch(existing_obj.reg_classification){
            case 'Business': return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS,existing_obj)
            case 'Non-Business': return this.buildNewEntry(EntityBuilderIndex.TAG_NON_BUSINESS,existing_obj)
            default: return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS,existing_obj)
          }
        }else return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS)
      }
      case EntityBuilderIndex.TAG_BUSINESS:{
        let group = new UntypedFormGroup({
          reg_classification: new UntypedFormControl(existing_obj?.reg_classification ? existing_obj.reg_classification : 'Business',[Validators.required]),
          reg_sector: new UntypedFormControl(existing_obj?.reg_sector ? existing_obj.reg_sector : '', [Validators.required]),
          reg_business: new UntypedFormControl(existing_obj?.reg_business ? existing_obj.reg_business : '', [Validators.required]),
          reg_division: new UntypedFormControl(''),
          reg_case: new UntypedFormControl('', [Validators.required]),
        })

        this.subTagsClassificationChange(group)
        
        this.subTagControlClear(group,'reg_sector','reg_division')
        this.subTagControlClear(group,'reg_business','reg_case')

        if(existing_obj){
          this.subTagMarked(group.controls.reg_classification)
          this.subTagMarked(group.controls.reg_sector)
          this.subTagMarked(group.controls.reg_business)
          this.subTagMarked(group.controls.reg_division)
          this.subTagMarked(group.controls.reg_case)
        }

        group.patchValue({
          reg_division: existing_obj?.reg_division ? existing_obj.reg_division : '',
          reg_case: existing_obj?.reg_case ? existing_obj.reg_case : '',
        })
        
        return group
      }
      case EntityBuilderIndex.TAG_NON_BUSINESS:{
        // console.log('existing_obj reg_classification: ',existing_obj.reg_classification)
        // console.log('existing_obj reg_stageoflife: ',existing_obj.reg_stageoflife)
        // console.log('existing_obj reg_lifeevent: ',existing_obj.reg_lifeevent)
        let group =  new UntypedFormGroup({
          reg_classification: new UntypedFormControl(existing_obj?.reg_classification ? existing_obj.reg_classification : 'Non-Business',[Validators.required]),
          reg_stageoflife: new UntypedFormControl(existing_obj?.reg_stageoflife ? existing_obj.reg_stageoflife : '',[Validators.required]),
          reg_lifeevent: new UntypedFormControl('',[Validators.required]),
        })

        this.subTagsClassificationChange(group)

        this.subTagControlClear(group,'reg_stageoflife','reg_lifeevent')

        if(existing_obj){
          this.subTagMarked(group.controls.reg_classification)
          this.subTagMarked(group.controls.reg_stageoflife)
          this.subTagMarked(group.controls.reg_lifeevent)
        }

        group.patchValue({
          reg_lifeevent: existing_obj?.reg_lifeevent ? existing_obj.reg_lifeevent : ''
        })

        return group
      }
      default:
        return new UntypedFormGroup({});
    }
  }
  
  // sub tags handler
  getFormsArray(listName:string):UntypedFormGroup[]{
    switch(listName){
      case 'sub_tags': return ((this.drisform.controls.sub_tags as UntypedFormArray).controls as UntypedFormGroup[])
      default: return []
    }
  }

  addSubtag(listName:string, item?:any){
    switch(listName){
      case 'sub_tags': (this.drisform.controls.sub_tags as UntypedFormArray).push(this.buildNewEntry(EntityBuilderIndex.TAG, item))
    }
  }

  removeSubTag(listName:string, index:number){
    switch(listName){
      case 'sub_tags': 
        // need to unsubscribe to each control valuechanges before removing whole group
        // reg_classification
        // reg_stageoflife
        // reg_lifeevent
        // reg_sector
        // reg_division
        // reg_business
        // reg_case
        // ((((this.proposedregulationform.controls.sub_tags as FormArray).controls as FormGroup[])[index] as FormGroup).controls.reg_classification as FormControl).valueChanges ;
        (this.drisform.controls.sub_tags as UntypedFormArray).removeAt(index);

    }
  }

  subTagsClassificationChange(group: UntypedFormGroup){
    // need to unsubscribe later when remove to prevent memory leak
    // const valueChanges = 
    group.controls['reg_classification'].valueChanges.subscribe({
      next:(val)=>{
        switch(val){
          case 'Business':{
            // unsubscribe valuechange before removing
            // reg_stageoflife
            // reg_lifeevent
            if(group.contains('reg_stageoflife')) group.removeControl('reg_stageoflife')
            if(group.contains('reg_lifeevent')) group.removeControl('reg_lifeevent')
  
            if(!group.contains('reg_sector')) group.addControl('reg_sector', new UntypedFormControl('',[Validators.required]))
            if(!group.contains('reg_division')) group.addControl('reg_division', new UntypedFormControl(''))
            if(!group.contains('reg_business')) group.addControl('reg_business', new UntypedFormControl('',[Validators.required]))
            if(!group.contains('reg_case')) group.addControl('reg_case', new UntypedFormControl('',[Validators.required]))

            this.subTagControlClear(group,'reg_sector','reg_division')
            this.subTagControlClear(group,'reg_business','reg_case')
            break; 
          }
          case 'Non-Business':{
            // unsubscribe valuechange before removing
            // reg_sector
            // reg_division
            // reg_business
            // reg_case
            if(group.contains('reg_sector')) group.removeControl('reg_sector')
            if(group.contains('reg_division')) group.removeControl('reg_division')
            if(group.contains('reg_business')) group.removeControl('reg_business')
            if(group.contains('reg_case')) group.removeControl('reg_case')
  
            if(!group.contains('reg_stageoflife')) group.addControl('reg_stageoflife', new UntypedFormControl('',[Validators.required]))
            if(!group.contains('reg_lifeevent')) group.addControl('reg_lifeevent', new UntypedFormControl('',[Validators.required]))

            this.subTagControlClear(group,'reg_stageoflife','reg_lifeevent')

            break; 
          }
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })

    // this.subscriptions.push(valueChanges)
    // console.log('subcriptions: ',this.subscriptions)
    // console.log('valueChanges: ',valueChanges)
  }
  
  subTagControlClear(group:UntypedFormGroup,mainControl:string,dependentControl:string){
    // need to unsubscribe later when remove to prevent memory leak
    // const valueChanges = 
    group.controls[mainControl].valueChanges.subscribe({
      next:()=>{
        group.patchValue({[dependentControl]:''})
        console.log('value changer changed -> '+dependentControl)
      },
      error:(err:any)=>{
        console.error('Error: ',err)
      }
    })

    // this.subscriptions.push(valueChanges)
    // console.log('subcriptions: ',this.subscriptions)
    // console.log('valueChanges: ',valueChanges)
  }

  subTagMarked(control:any){
    control.markAllAsTouched()
    control.markAsTouched()
    control.updateValueAndValidity()
  }

  filterSelection(controlName:string, value:any):Array<any>{
    if(value != null && value != undefined && value != ''){
      switch(controlName){
        case 'reg_division':{
          let section = this.sectors.find((option) => option.id === value).section.toLowerCase();
          return this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
        }
        case 'reg_case':{
          let section = this.sobs.find((option) => option.id === value).section.toLowerCase();
          return this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
        }
        case 'reg_lifeevent':{
          let stageOfLife = this.sols.find((option) => option.id === value).stage.toLowerCase();
          return this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
        }
        default:return []
      }
    } return []
  }

  subTagOptionSelected(optionValue:any, itemValue:any){
    return optionValue == itemValue ? true : false
  }
  // !sub tags handler

  // validators
  checkUploadType():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(this.uploadsForm != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          let errors = {
            type: false,
          }
          if(!this.acceptedFileType.includes(val)){
            tempbool = true
            errors.type = true
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  // !validators

  // validations
  arrayIsTouched(controlName:any){
    return controlName.touched;
  }

  arrayHasErrors(controlName:any, validType:string){
    return controlName.hasError(validType) 
    && (controlName.dirty 
    || controlName.touched)
  }

  validateNode(node: any){
    let errors:any = []
    if(node){
      if(node.nodeType == 'SECTION'){
        let item:any = this.regulation_sections_form.get(node.uuid);
        if(item && item.info){
          let nodeParent : UntypedFormGroup = item.info
          if(!nodeParent.valid) errors.push('Section')
          if(item.subsections){
            if(node.children && node.children.length > 0){
              node.children.map((nodeChildren:RegulationTextNode)=>{
                if(nodeChildren && nodeChildren.uuid){
                  let nodeChild : UntypedFormGroup = item.subsections.get(nodeChildren.uuid)
                  if(nodeChild && !nodeChild.valid){
                    if(!errors.includes('Subsection/s')) errors.push('Subsection/s')
                  }
                }
              })
            }
          }
        }
      }
      else if(node.nodeType == 'SUBSECTION'){
        let item:any = this.regulation_sections_form.get(node.parentUuid);
        if(item && item.subsections){
          let nodeChild : UntypedFormGroup = item.subsections.get(node.uuid);
          if(nodeChild && !nodeChild.valid){
            if(!errors.includes('Subsection')) errors.push('Subsection')
          }
        }
      }
    }else{
      errors.push('Internal Error')
    }
    return errors
  }

  isTouched(form:UntypedFormGroup ,controlName:string, controlGroup?:string,){
    if(controlGroup){
      return ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched;
    }
    else{
      return (form.get(controlName) as UntypedFormControl).touched;
    }
  }

  validateFields(){
    if(!this.uploadsForm.valid){
      this.uploadsForm.markAsTouched()
      for (const control in this.uploadsForm.controls) {
        if (this.uploadsForm.controls.hasOwnProperty(control)) {
          this.uploadsForm.controls[control].markAllAsTouched();
          this.uploadsForm.controls[control].markAsDirty();
        }
      }
    }
  }

  hasError(formGroupName:any, controlName: string, errorType: string, forSize?:boolean, formGroup?:any){
    switch(formGroupName){
      case 'uploadsForm':{
        // return this.uploadsForm.get(controlName)?.hasError(errorType) && (this.uploadsForm.get(controlName)?.dirty || this.uploadsForm.get(controlName)?.touched)
        let uploadInput = this.uploadInputs.get(controlName)
        const formGroup = (this.uploadsForm.controls[controlName] as UntypedFormGroup)
        // console.log(controlName+' formGroup: ',formGroup)
        if(uploadInput){
          uploadInput.show_errors = formGroup.invalid
        }
        if(forSize)
          return (formGroup.get('size')?.hasError(errorType) && (formGroup.get('size')?.dirty || formGroup.get('size')?.touched))
        
        else
          return (formGroup.get('type')?.hasError(errorType) && (formGroup.get('type')?.dirty || formGroup.get('type')?.touched)) 
              || (formGroup.get('count')?.hasError(errorType) && (formGroup.get('count')?.dirty || formGroup.get('count')?.touched));
      }
      case 'drisform':{
        if(this.drisform.contains(controlName)){
          let drisControl = this.drisform.get(controlName) as UntypedFormControl
          return (drisControl.hasError(errorType) 
            && (drisControl.dirty 
            || drisControl.touched))
        } else return null
      }
      case 'sub_tags':{
        if(formGroup.contains(controlName)){
          let formGroupControl = formGroup.get(controlName) as UntypedFormControl
          return (formGroupControl.hasError(errorType) 
            && (formGroupControl.dirty 
            || formGroupControl.touched))
        } else return null
      }
      case 'activeRegulationSection':
      case 'activeRegulationSubsection':  
      {
        if(formGroup.contains(controlName)){
          return ((formGroup.get(controlName) as UntypedFormControl).hasError(errorType) 
          && ((formGroup.get(controlName) as UntypedFormControl).dirty 
          || (formGroup.get(controlName) as UntypedFormControl).touched))
        }else return false;
      }
      default:
        return null
    }
  }
  // !validations

  toCancel(){
    this.router.navigate(['/pbris/regulatory-management'])
  }
}
