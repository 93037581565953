import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-pbris-account-sector',
  templateUrl: './pbris-account-sector.component.html',
  styleUrls: ['./pbris-account-sector.component.scss']
})
export class PbrisAccountSectorComponent implements OnInit {
  @Input() data_catch: any;
  sectorList: any = [];
  
  modal: any = [];
  onModal: boolean = false

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.loadSector()
  }

  async loadSector() {
    this.afs.collection('Sector')
    .get()
    .subscribe(data => {
      this.sectorList = []
      data.docs.map(res => {
        let temp: any = res?.data()
        temp.id = res?.id
        this.afs.collection('PBRIS Subscription', ref => ref.where('id','==', res?.id).where('user_id','==', this.data_catch.credentials?.uid)).get().subscribe(result => {
          if (result.docs.length) {
            result.docs.map(doc => {
              temp.status = doc?.data()
              temp.status.reg_id = doc?.id
            })
          }else{
            temp.status = false
          }
        }) 
        this.sectorList.push(temp)  
      })
    })
  }

  openModal(data: any) {
    this.onModal = true
    this.modal = data
  }

  closeModal() {
    this.onModal = false
    this.modal = []
  }

  onSubscribe(data: any) {
    if (data.status == false) {
      let info: any = [{
        user_id: this.data_catch.credentials.uid,
        id: data.id,
        status: true,
        pause: false,
        type: 'sector',
        subscription: {
          "consult_scheduled" : false,
          "consult_commence_7" : false,
          "consult_7" : false,
          "consult_closed" : false,
          "regulation_final" : false,
          "regulation_published" : false,
          "regulation_effect" : false,
        },
        created_at: new Date(),
        updated_at: new Date(),
      }];

      this.afs.collection(`PBRIS Subscription`)
        .add(info[0])
        .then((docRef:any) => {
          this.sectorList.find((curr:any, idx:any, arr:any) => {
            if (curr.id == data.id) {
              curr.status = info[0]
              curr.status.reg_id = docRef.id
              return arr
            } 
          })
      }).catch((error:any) =>{
        console.log("Error",error)
      });
    } else {
      let stat: any;
      let detail: any;
      if (data.status.status) 
      {
        stat = false
        detail = "Successfully unpinned"
      }
      else
      {
        stat = true
        detail = "Successfully pinned"
      } 

      this.afs.collection(`PBRIS Subscription`)
        .doc(data.status.reg_id)
        .update({status: stat, updated_at: new Date()})
        .then((docRef:any) => {
          this.sectorList.map((reg: any) => {
          if(reg.id === data.id) {
            reg.status.status = stat;
          }
          return reg
          })
      }).catch((error:any) =>{
        console.log("Error",error)
      });
    }
  }
}
