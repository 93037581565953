import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { tap } from 'rxjs/operators';
import { ArtemisCocPreviewPageArtaComponent } from 'src/app/artemis/artemis-inbox-page/artemis-charter-inbox-page-arta/artemis-coc-preview-page-arta/artemis-coc-preview-page-arta.component';

@Component({
  selector: 'app-coc-submissions',
  templateUrl: './coc-submissions.component.html',
  styleUrls: ['./coc-submissions.component.scss']
})
export class CocSubmissionsComponent implements OnInit {

  //number formatting
  ordinals: string[] = ['th', 'st', 'nd', 'rd'];


  @Input('agency_id') agency_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;
  results:any = []
  //mat table paginator
  displayedColumns: string[] = ['createdAt', 'responsible', 'version', 'status'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  

  constructor(
    public dialog: MatDialog,
    private store: AngularFireStorage,private afs: AngularFirestore,private http: HttpClient,) { }
  
  ngOnInit(): void {
    this.loadCCbyAgency();
  }


  loadCCbyAgency(){

    this.afs.collection('Certificate of Compliance Submissions',
    filter=>filter.where("fromAgencyId","==",this.agency_id)
    .orderBy("createdAt",'desc'))
    .snapshotChanges()
    .pipe(tap((data:any)=>{
      this.results= []
      data.forEach((info:any)=>{
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;

        if(item.cocurl){
          this.results.push(item)
        }
       
      })
      this.dataSource = new MatTableDataSource(this.results)
      this.resetSortPage()
    }))
    .subscribe()
  }

  resetSortPage() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
//format certificate of compliance edition and version
transform(n: number, keepNumber: boolean = true) {
  let v = n % 100;
  return (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]);
}

  sortChange(sort: Sort) {
    const data = this.results.slice()
    // console.log('sort.active',sort.active)
    // console.log('sort.direction',sort.direction)
    if (!sort.active || sort.direction === '') {
      this.results = data
      return;
    }
    this.results = data.sort((a: any, b: any) => {
      // console.log('results',this.results)
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'responsible':
          return this.compare(a.personResponsible.name,b.personResponsible.name,isAsc)
        case 'createdAt':
          return this.compare(a.date_posted, b.date_posted, isAsc)
        case 'version':
          return this.compare(a.reportURL.trim().toLowerCase(), b.reportURL.trim().toLowerCase(), isAsc)

        default:
          return null
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.results)
    this.resetSortPage()

  }
  //sort data 
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ViewCCDocument(data:any){

    if(data.cocurl == ''){
      return;
    }
    if(this.agency_id){

       this.dialog.open(ArtemisCocPreviewPageArtaComponent, {

        data:{
          file: data.cocurl,
          file_type: "Certificate of Compliance"
        }
        
       })
        
    }
  }

 
}
