import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-artemis',
  templateUrl: './navigation-artemis.component.html',
  styleUrls: ['./navigation-artemis.component.scss']
})
export class NavigationArtemisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
