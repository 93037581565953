import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PublicRiaAssessmentViewArtaRemarksModalComponent } from './ris-assessment-view-arta-remarks-modal/ris-assessment-view-arta-remarks-modal.component';


@Component({
    selector: 'app-ria-assessment-view',
    templateUrl: './ria-assessment-view.component.html',
    styleUrls: ['./ria-assessment-view.component.scss']
})
export class PublicRiaAssessmentViewComponent implements OnInit,OnChanges {
    
  @Input() drisData: any;

  displayedColumns: string[] = ['section', 'rating', 'remarks'];
  displayedColumnsRisDocs: string[] = ['docname', 'remarks']; //, 'action

  ris_scoring_test = this.fB.array([
      new UntypedFormGroup({
        control: new UntypedFormControl('policyProblemAndNeedForGovernmentAction'),
        section: new UntypedFormControl('Policy Problem and Need for Government Action'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('policyObjective'),
        section: new UntypedFormControl('Policy Objective'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('policyOptions'),
        section: new UntypedFormControl('Policy Options'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('assessmentOfPolicyOptions'),
        section: new UntypedFormControl('Assessment of Policy Options'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('consultation'),
        section: new UntypedFormControl('Consultation'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('recommendedOption'),
        section: new UntypedFormControl('Recommended Option'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('implementationAndEnforcement'),
        section: new UntypedFormControl('Implementation and Enforcement'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('monitoringAndEvaluation'),
        section: new UntypedFormControl('Monitoring and Evaluation'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0, Validators.min(1)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType1Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('overallRISRating'),
        section: new UntypedFormControl('OVERALL RIS RATING'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(0,Validators.min(8)),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        oneCheck:new UntypedFormControl(0),
        zeroCheck:new UntypedFormControl(0),
        rating_text: new UntypedFormControl(''),
        computedRatingText: new UntypedFormControl(''),
        overall_score_flag: new UntypedFormControl(true),
        // control: '',
        optionsType: new UntypedFormControl('assesmentOverallRatingOptions'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('tagging'),
        section: new UntypedFormControl('Tagging'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl(''),//,[Validators.required]
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl(''),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('relatedIssuances'),
        section: new UntypedFormControl('Related Issuances'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl('',[Validators.required]),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType2Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('textOfProposedRegulation'),
        section: new UntypedFormControl('Text of Proposed Regulation'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl('',[Validators.required]),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType3Options'),
      }),
      new UntypedFormGroup({
        control: new UntypedFormControl('pbrisConsultation'),
        section: new UntypedFormControl('PBRIS Consultation'),
        url: new UntypedFormControl(''),
        score: new UntypedFormGroup({
          rating: new UntypedFormControl('',[Validators.required]),
          previous_remarks: this.fB.array([]),
          remarks: new UntypedFormControl(''),
        }),
        overall_score_flag: new UntypedFormControl(false),
        // control: '',
        optionsType: new UntypedFormControl('assesmentType3Options'),
      }),
  ])

  ris_documents_test = this.fB.array([
  ])

  assesmentType1Options:any = [
    {title:'GOOD QUALITY',value:5,color:'green'},
    {title:'SUFFICIENT QUALITY',value:3,color:'yellow'},
    {title:'POOR QUALITY',value:1,color:'red'},
  ]

  assesmentOverallRatingOptions:any = [
    {
      title:'BETTER PRACTICE',
      range:{
        from:40,to:40
      },
      color: 'blue',
    },
    {
      title:'GOOD PRACTICE',
      range:{
        from:30,to:39
      },
      color: 'green',
    },
    {
      title:'SATISFACTORY PRACTICE',
      range:{
        from:24,to:29
      },
      color: 'yellow',
    },
    {
      title:'INSUFFICIENT PRACTICE',
      range:{
        from:8,to:23
      },
      color: 'red',
    },
  ]

  assesmentType2Options:any = [
    {title:'CONSISTENT',color:'black'},
    {title:'NEEDS REVISION',color:'red'},
  ]

  assesmentType3Options:any = [
    {title:'CONSISTENT',color:'black'},
    {title:'INCONSISTENT',color:'red'},
  ]

  constructor(private fB:UntypedFormBuilder,
      private store: AngularFireStorage,
      public dialog: MatDialog,){
      
  }

  ngOnInit(): void {

    this.setRatingValueChange()
    if(this.drisData && this.drisData.risData){
      if(this.drisData.risData) this.handleRisData(this.drisData.risData, this.drisData.upload_folders)
      else this.initializeRisData(this.drisData.upload_folders)
    }
  }

  ngOnChanges(): void {
  }

  setRatingValueChange(){
    (this.ris_scoring_test.controls as UntypedFormGroup[]).map((control:UntypedFormGroup)=>{
      if(control.value.optionsType == 'assesmentType1Options'){
        (control.controls.score as UntypedFormGroup).controls.rating.valueChanges.subscribe({
          next:(response1)=>{
            let overallVal:number = 0;
            let overallIndex;
            let zeroCount: number = 0;
            let oneCount: number = 0;
            (this.ris_scoring_test.controls as UntypedFormGroup[]).map((control1:UntypedFormGroup, index:number)=>{
              if(control1.value.optionsType == 'assesmentType1Options'){
                let isImplement = (control1.value.control == 'implementAndEnhancement' || control1.value.control == 'implementAndEnforcement')
                if(control1.value.control == control.value.control && !isImplement){
                  if(response1 == 0) +zeroCount++
                  if(response1 == 1) +oneCount++
                  overallVal += response1 ? +response1 : +0
                }
                else if(isImplement){
                  if(response1 == 0) +zeroCount++
                  if(response1 == 1) +oneCount++
                  overallVal += response1 ? +response1 : +0
                }
                else{
                  if((control1.controls.score as UntypedFormGroup).value.rating == 0) +zeroCount++
                  if((control1.controls.score as UntypedFormGroup).value.rating == 1) +oneCount++
                  overallVal += (control1.controls.score as UntypedFormGroup).value.rating ? +(control1.controls.score as UntypedFormGroup).value.rating : +0
                }
              }
              if(control1.value.control == 'overallRISRating'){
                overallIndex = index
              }
            });
            let ratingValue:string = '';
            let computedRatingText: string = ''
            if(zeroCount > 0){
              ratingValue = "Rating Incomplete"
            }
            else if(zeroCount == 0 && oneCount > 0){
              let assesmentOptionsValue = this.getAssesmentOptionsValue('assesmentOverallRatingOptions',8)
              if(assesmentOptionsValue.length > 0){
                computedRatingText = assesmentOptionsValue[0].title
                ratingValue += assesmentOptionsValue[0].title+" "
              }
              ratingValue += "("+overallVal+")"
            }else{
              let assesmentOptionsValue = this.getAssesmentOptionsValue('assesmentOverallRatingOptions',overallVal)
              if(assesmentOptionsValue.length > 0) {
                computedRatingText = assesmentOptionsValue[0].title
                ratingValue += assesmentOptionsValue[0].title+" "
              }
              ratingValue += "("+overallVal+")"
            }
            
            if(overallIndex && overallIndex >= 0) {
              (((this.ris_scoring_test.controls as UntypedFormGroup[])[overallIndex] as UntypedFormGroup).patchValue({
                computedRatingText: computedRatingText,
                rating_text: ratingValue
              }));
              (((this.ris_scoring_test.controls as UntypedFormGroup[])[overallIndex] as UntypedFormGroup).controls.score as UntypedFormGroup).patchValue({
                rating:overallVal
              });
            }
            // else (((this.ris_scoring_test.controls as FormGroup[])[overallIndex] as FormGroup).controls.score as FormGroup).patchValue({rating:0});
          }
        })
      }
    });
  }

  getAssesmentOptionsValue(code:any, count:number){
    if(code == 'assesmentOverallRatingOptions'){
      return this.assesmentOverallRatingOptions.filter((element:any)=>element.range.from <= count && count <= element.range.to)
    }
    else return ''
  }

  handleRisData(risData:any, upload_folders:any){
    // console.log('ris_scoring_test',this.ris_scoring_test.value);
    (this.ris_scoring_test.controls as UntypedFormGroup[]).map((control:UntypedFormGroup)=>{
      let controlName = control.value.control
      // console.log({controlName})
      let controlCtr:number = -1
      let controlCtr2:number = -1
      if(controlName == 'implementationAndEnchancement' || controlName == 'implementationAndEnforcement'){
        controlCtr = risData.risScoring.map((element:any)=>element.control).indexOf('implementationAndEnchancement')
        controlCtr2 = risData.risScoring.map((element:any)=>element.control).indexOf('implementationAndEnforcement')
        // console.log({controlCtr2})
        if(controlCtr2 >= 0) controlCtr = controlCtr2
      }else{
        controlCtr = risData.risScoring.map((element:any)=>element.control).indexOf(controlName)
      }

      
      if(upload_folders){
        if(control.value.control == 'policyProblemAndNeedForGovernmentAction'){
          if(upload_folders.policyProblem){
            this.store.ref(upload_folders.policyProblem).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'policyObjective'){
          if(upload_folders.policyObjective){
            this.store.ref(upload_folders.policyObjective).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'policyOptions'){
          if(upload_folders.policyOptions){
            this.store.ref(upload_folders.policyOptions).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'assessmentOfPolicyOptions'){
          if(upload_folders.policyOptionsAssessment){
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'consultation'){
          if(upload_folders.consultationDocumentation){
            this.store.ref(upload_folders.consultationDocumentation).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'recommendedOption'){
          if(upload_folders.policyOptionsAssessment){
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'implementationAndEnforcement' || control.value.control == 'implementationAndEnhancement'){
          if(upload_folders.implementationEnforcement){
            this.store.ref(upload_folders.implementationEnforcement).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'monitoringAndEvaluation'){
          if(upload_folders.monitoringEvaluation){
            this.store.ref(upload_folders.monitoringEvaluation).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        // else if(control.value.control == 'overallRISRating'){

  
        // }
        // else if(control.value.control == 'tagging'){
        //   control.patchValue({url:''})
  
        // }
        // else if(control.value.control == 'relatedIssuances'){
        //   control.patchValue({url:''})
  
        // }
        // else if(control.value.control == 'textOfProposedRegulation'){
        //   control.patchValue({url:''})
  
        // }
        // else if(control.value.control == 'pbrisConsultation'){
        //   control.patchValue({url:''})
  
        // }
      }

      // console.log(controlCtr+'control',control.value)
      if(controlCtr >= 0){
        let risDetail = risData.risScoring[controlCtr];
        
        if(risDetail.score.previousRemarks && risDetail.score.previousRemarks.length > 0){
          risDetail.score.previousRemarks.forEach((element2:any)=>{
            ((control.controls.score as UntypedFormGroup).controls.previous_remarks as UntypedFormArray).push(new UntypedFormGroup({
              currentAccountSubtype: new UntypedFormControl(element2.currentAccountSubtype),
              date: new UntypedFormControl(element2.date),
              remark: new UntypedFormControl(element2.remark),
              userID: new UntypedFormControl(element2.userID),
              userName: new UntypedFormControl(element2.userName),
            }));
          })
        }
        (control.controls.score as UntypedFormGroup).patchValue({
          rating: risDetail.score.rating ? risDetail.score.rating : 0,
        });
      }
      // console.log('------------')
    });
    
    let batches:any = [];

    if(risData.risAssessmentFile) batches.push(this.store.ref(risData.risAssessmentFile).getDownloadURL())
    if(risData.coverLetterFile) batches.push(this.store.ref(risData.coverLetterFile).getDownloadURL())
    
    if(batches.length > 0){
      forkJoin(batches)
      .subscribe({
        next:(results: any)=>{
  
          
          for (var x in results) {
            let previous_remarks =  this.fB.array([]);
            let doc_name = '';
            if (x == '0') {
              doc_name = 'RIS Assessment Document';
              risData.risAssessmentFilePreviousRemarks.forEach((element3:any)=>{
                previous_remarks.push(new UntypedFormGroup({
                  currentAccountSubtype: new UntypedFormControl(element3.currentAccountSubtype),
                  date: new UntypedFormControl(element3.date),
                  remark: new UntypedFormControl(element3.remark),
                  userID: new UntypedFormControl(element3.userID),
                  userName: new UntypedFormControl(element3.userName),
                }))
              })
            }
  
            if (x == '1') { 
              doc_name = 'Cover Letter';
              risData.coverLetterFilePreviousRemarks.forEach((element3:any)=>{
                previous_remarks.push(new UntypedFormGroup({
                  currentAccountSubtype: new UntypedFormControl(element3.currentAccountSubtype),
                  date: new UntypedFormControl(element3.date),
                  remark: new UntypedFormControl(element3.remark),
                  userID: new UntypedFormControl(element3.userID),
                  userName: new UntypedFormControl(element3.userName),
                }))
              })
            }
  
            this.ris_documents_test.push(new UntypedFormGroup({
              doc_name: new UntypedFormControl(doc_name),
              url: new UntypedFormControl(results[x]),
              remarksDetails: new UntypedFormGroup({
                previous_remarks: previous_remarks,
                remarks: new UntypedFormControl('')
              }),
              new_file: new UntypedFormControl('')
            }))
          }
  
        },
        error:(err)=>{
          console.error('Error: ',err)
        }
      });
    }
  }

  initializeRisData(upload_folders:any){
    if(upload_folders){
      (this.ris_scoring_test.controls as UntypedFormGroup[]).map(async (control:UntypedFormGroup) => {
        if(control.value.control == 'policyProblemAndNeedForGovernmentAction'){
          if(upload_folders.policyProblem){
            this.store.ref(upload_folders.policyProblem).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'policyObjective'){
          if(upload_folders.policyObjective){
            this.store.ref(upload_folders.policyObjective).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'policyOptions'){
          if(upload_folders.policyOptions){
            this.store.ref(upload_folders.policyOptions).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'assessmentOfPolicyOptions'){
          if(upload_folders.policyOptionsAssessment){
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'consultation'){
          if(upload_folders.consultationDocumentation){
            this.store.ref(upload_folders.consultationDocumentation).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'recommendedOption'){
          if(upload_folders.policyOptionsAssessment){
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'implementationAndEnforcement'){
          if(upload_folders.implementationEnforcement){
            this.store.ref(upload_folders.implementationEnforcement).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
        else if(control.value.control == 'monitoringAndEvaluation'){
          if(upload_folders.monitoringEvaluation){
            this.store.ref(upload_folders.monitoringEvaluation).getDownloadURL()
            .subscribe({
              next:(response)=>{
                control.patchValue({url:response})
              },
              error:(err)=>{
                console.error('Error no document found!: ',control.value.control)
              }
            })
          }
        }
      })
    }
  }
  

  getOption(code:string){
    if(code == 'assesmentType1Options') return this.assesmentType1Options
    else if(code == 'assesmentOverallRatingOptions') return this.assesmentOverallRatingOptions
    else if(code == 'assesmentType2Options') return this.assesmentType2Options
    else if(code == 'assesmentType3Options') return this.assesmentType3Options
    else return []
  }

  getColor(text:string){
    const assesmentOverallRatingArr = this.assesmentOverallRatingOptions.filter((element:any)=>element.title === text)
    if(assesmentOverallRatingArr && assesmentOverallRatingArr.length > 0) return assesmentOverallRatingArr[0].color
    else return ''
  }

  getColor2(control:any){
    let tempArr:any = this.getOption(control.optionsType)
    if(control.optionsType == 'assesmentType1Options'){
      const tempArrNew:any = tempArr.filter((element:any)=>element.value == control.score.rating)
      if(tempArrNew.length > 0){
          return tempArrNew[0].color
      }
    }
    else if(control.optionsType == 'assesmentOverallRatingOptions'){
      return this.getColor(control.computedRatingText)
    }
    else if(control.optionsType == 'assesmentType2Options' || control.optionsType == 'assesmentType3Options'){
      const tempArrNew:any = tempArr.filter((element:any)=>element.title == control.score.rating)
      if(tempArrNew.length > 0){
          return tempArrNew[0].color
      }
    }
    return ''
  }

  getSelectionItem(control:any){
      let tempArr:any = this.getOption(control.optionsType)
      if(control.optionsType == 'assesmentType1Options'){
          const tempArrNew:any = tempArr.filter((element:any)=>element.value == control.score.rating)
          if(tempArrNew.length > 0){
              return tempArrNew[0].title
          }
      }
      else if(control.optionsType == 'assesmentOverallRatingOptions'){
          return control.rating_text
      }
      else if(control.optionsType == 'assesmentType2Options' || control.optionsType == 'assesmentType3Options'){
          const tempArrNew:any = tempArr.filter((element:any)=>element.title == control.score.rating)
          if(tempArrNew.length > 0){
              return tempArrNew[0].title
          }
      }
      else return ''
      return 'No Rating. . .'
  }
  
  checkRemarks(control:UntypedFormGroup){
      this.dialog.open(PublicRiaAssessmentViewArtaRemarksModalComponent,{
              disableClose: true,
              height: '80%',
              width: '80%',
              data: {
              control: control.value,
              action: false
              },
          }
      );
  }

  checkDocumentRemarks(documentDetial:any){
    let documentVal:any = documentDetial.value
    this.dialog.open(PublicRiaAssessmentViewArtaRemarksModalComponent,{
        disableClose: true,
        height: '80%',
        width: '80%',
        data: {
          control: {
            section: documentVal.doc_name,
            score:{
              previous_remarks: documentVal.remarksDetails.previous_remarks,
              remarks: documentVal.remarksDetails.remarks,
            }
          },
          action: false
        },
      }
    );
  }
}