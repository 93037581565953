<ng-container *ngIf="fileURL; else noFile">
  <div class="row my-3">
    <a target="_blank"  href="{{fileURL}}" class="btn btn-lg btn-primary float-end">Download Document</a>
  </div>        
  <div class="row my-3 border border-dark p-3 px-0">
      <pdf-viewer [src]="fileURL"></pdf-viewer>
  </div>
</ng-container>
<ng-template #noFile>  
  <div class="row my-3 border border-dark p-3 px-0">
      <h1>No Data</h1>
  </div>
</ng-template>