import { startWith, debounceTime, flatMap, map, first, filter } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {Query, QueryFn } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-artemis-advanced-search-page',
  templateUrl: './artemis-advanced-search-page.component.html',
  styleUrls: ['./artemis-advanced-search-page.component.scss']
})
export class ArtemisAdvancedSearchPageComponent implements OnInit {

  loader:boolean = false;

  isArta:any = false;

  style_theme: any = 'theme-default'; //current theme
  
  userType: any = sessionStorage.getItem("user_type");

  searchform: UntypedFormGroup = new UntypedFormGroup({
    agencycategory: new UntypedFormControl(''),
    // agency: new FormControl(''),
    agency: new UntypedFormControl('', Validators.required),
    sector: new UntypedFormControl(''),
    classification: new UntypedFormControl(''),
    servicecategory: new UntypedFormControl(''),
    service: new UntypedFormControl(''),
    year: new UntypedFormControl(''),
    keyword: new UntypedFormControl(''),
  });

  checkSearchForm={
    agency:[
      {type: 'required', message: "Agency Name is required"}
    ]
  }



  fieldLOVagencyCategory: any = []; // List of Agency Categories
  filteredAgencyCategory: Observable<any[]>;

  fieldLOVagency: any = []; // List of Agencies
  filteredAgency: Observable<any[]>;
  agencyList : any = []

  fieldLOVsector: any = []; // List of Sectors
  filteredSector: Observable<any[]>;
  sectorList : any = []

  fieldLOVclassification: any = []; // List of Classifications
  filteredClassification: Observable<any[]>;
  classificationList:any = []

  fieldLOVserviceCategory: any = []; // List of Service Categories
  filteredServiceCategory: Observable<any[]>;

  fieldLOVservice: any = []; // List of Services
  // filteredService: Observable<any[]>;
  filteredService: any = [];

  fieldLOVyear: any = []; // List of Years
  allYears:any = []

  constructor(
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    // this.getSuggestions();

    // this.getAgencyCategoryList()
    // this.getAgencyList()
    // this.getSectorList()
    // this.getClassificationList()
    // this.getServiceCategoryList()
    // this.getServiceList()
    // this.getYearList()
    this.loadAllData()

    this.setTheme();
  }

  async loadAllData(){
    this.loader = true;
    const agencyCatFlag = await this.loadData('agencycategory','Agency Category')
    const agencyFlag = await this.loadData('agency','Agency')
    const sectorFlag = await this.loadData('sector','Sector')
    const classificationFlag = await this.loadData('classification','Classification')
    const serviceCatFlag = await this.loadData('servicecategory','Service Category')
    const serviceFlag = await this.loadData('service','Services Phase 02')
    if(agencyCatFlag && agencyFlag && sectorFlag && classificationFlag && serviceCatFlag && serviceFlag){
      //console.log("loading finished")
      this.loader = false;
    }
  }

  //page loader
  async loadData(load:any,collection:any){
    const arrayData = await this.afs.collection(collection,
      filter => this.filterFunc(filter,load))
      .snapshotChanges()
      .pipe(first())

    if(load == 'agencycategory'){
      this.fieldLOVagencyCategory = arrayData
      this.filteredAgencyCategory = this.mapper(load)
      return true
    }
    else if(load == 'agency'){
      this.fieldLOVagency = arrayData
      this.filteredAgency = this.mapper(load)
      return true
    }
    else if(load == 'sector'){
      this.fieldLOVsector = arrayData
      this.filteredSector = this.mapper(load)
      return true
    }
    else if(load == 'classification'){
      this.fieldLOVclassification = arrayData
      this.filteredClassification = this.mapper(load)
      return true
    }
    else if(load == 'servicecategory'){
      this.fieldLOVserviceCategory = arrayData
      this.filteredServiceCategory = this.mapper(load)
      return true
    }
    else if(load == 'service'){
      this.fieldLOVservice = arrayData
      // this.filteredService = this.mapper('year')
      return this._filter('','year')
      return true
    }
    else{
      return true
    }
    
  }

  private mapper(load:any){
    return (this.searchform.controls[load] as UntypedFormControl).valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      flatMap((value) => this._filter(value,load))
    );
  }

  private _filter(value: string,load:any):Observable<any[]> {
    const filteredVal = value.toLowerCase()
    if(load == 'agencycategory'){
      return this.fieldLOVagencyCategory.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && ( option.agencyDetails ? option.agencyDetails.agencyName.toLowerCase().includes(filteredVal) : option.name.toLowerCase().includes(filteredVal)))
          );
        })
      );
    }

    else if(load == 'agency'){
      return this.fieldLOVagency.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          this.agencyList = modifiedCode
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && ( option.agencyDetails ? option.agencyDetails.agencyName.toLowerCase().includes(filteredVal) : option.name.toLowerCase().includes(filteredVal)))
          );
        })
      );
    }

    else if(load == 'sector'){
      return this.fieldLOVsector.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal))
          );
        })
      );
    }

    else if(load == 'classification'){
      return this.fieldLOVclassification.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal))
          );
        })
      );
    }

    else if(load == 'servicecategory'){
      return this.fieldLOVserviceCategory.pipe(
        map((code:any) => {
          let modifiedCode: any = code.map((element:any) => {
            let item:any = element.payload.doc.data()
            item.id = element.payload.doc.id
            return item
          });
          return modifiedCode.filter(
            (option:any) =>  (filteredVal.trim() != '' && option.name.toLowerCase().includes(filteredVal))
          );
        })
      );
    }

    else if(load == 'service'|| load == 'year'){
      return this.fieldLOVservice
      .subscribe((info:any)=>{
        let tempArr:any = []
        info.forEach((data:any)=>{
          let service: any = data.payload.doc.data()
          service.id = data.payload.doc.id
          tempArr.push(service)
        })
        this.filteredService = tempArr
        this.allYears = this.getAllYear(this.filteredService)
        return true
      })
      // .pipe(
      //   map((code:any) => {
      //     let modifiedCode: any = code.map((element:any) => {
      //       let item:any = element.payload.doc.data()
      //       item.id = element.payload.doc.id
      //       return item
      //     });
      //     // return modifiedCode
      //     return modifiedCode.filter(
      //       (option:any) =>  (filteredVal.trim() != '' && option.serviceDetails.service_name.toLowerCase().includes(filteredVal))
      //     )
      //   }) 
      // );
    }
      
    return of([])
  }

  filterFunc(filter:any,load:any){
    if(load == 'agencycategory')
      return filter.orderBy('abbreviation')
    else if(load == 'agency')
      return filter.orderBy(("agencyDetails" ? "agencyDetails.agencyName" : "name"))
    else if(load == 'sector')
      return filter.orderBy('name')
    else if(load == 'classification')
      return filter.orderBy('name')
    else if(load == 'servicecategory')
      return filter.orderBy('name')
    else if(load == 'service'){
      const userType = sessionStorage.getItem("user_type")
      if(userType == 'arta' || userType == 'agency') 
        return filter.orderBy('serviceDetails.service_name');
      else
        return filter.orderBy('date_posted').where('is_posted','==',true);
    }
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        this.isArta = true
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  getAllYear(services:any){
    let tempArr: any = []
    // let hasYear = services.filter((result:any)=>(result.date_posted)?true:false)
    // console.log({hasYear})
    services.forEach((result:any)=>{
      if(result.date_posted){
        let servicePostedDate = result.date_posted.toDate()
        // console.log("date",servicePostedDate)
        let serviceYear = servicePostedDate.getFullYear()
        // console.log("year",serviceYear)
        if(!tempArr.includes(serviceYear))
          tempArr.push(serviceYear)
      }
    })
    
    return tempArr
  }

  yearChanged(yearValue:any){
    this.searchform.patchValue({
      year:yearValue
    })
  }


  // valueSuggestions: any = {};
  // async getSuggestions(){
  //   /* main */
  //   let referencesRef = this.afs.collection("References");
  //   /* sub */
  //   //let commonRef = referencesRef.doc('common');
  //   let artemisRef = referencesRef.doc('ARTEMIS');
  //   //let pbrisRef = referencesRef.doc('PBRIS');
  //   /* sub sub */
  //   let collectionArray: any = [];
  //   /* common */
  //   /* common reference data has not yet been moved to the latest collection
  //   collectionArray.push({ collname: 'location_code', collRef: commonRef.collection('location_code') });
  //   collectionArray.push({ collname: 'notif_messages', collRef: commonRef.collection('notif_messages') });
  //   collectionArray.push({ collname: 'organization_code', collRef: commonRef.collection('organization_code') });
  //   collectionArray.push({ collname: 'psic_code', collRef: commonRef.collection('psic_code') });
  //   collectionArray.push({ collname: 'sector', collRef: commonRef.collection('sector') });
  //   */
  //   collectionArray.push({ collname: 'location_code', collRef: this.afs.collection('Location Code') });
  //   collectionArray.push({ collname: 'organization_code', collRef: this.afs.collection('Organization Code') });
  //   collectionArray.push({ collname: 'psic_code', collRef: this.afs.collection('PSIC Code') });
  //   collectionArray.push({ collname: 'sector', collRef: this.afs.collection('Sector') });
  //   /* artemis */
  //   collectionArray.push({ collname: 'agency', collRef: artemisRef.collection('agency') });
  //   collectionArray.push({ collname: 'agency_category', collRef: artemisRef.collection('agency_category') });
  //   collectionArray.push({ collname: 'classification', collRef: artemisRef.collection('classification') });
  //   collectionArray.push({ collname: 'list_of_services', collRef: artemisRef.collection('list_of_services') });
  //   collectionArray.push({ collname: 'service_category', collRef: artemisRef.collection('service_category') });
  //   collectionArray.push({ collname: 'stage_of_life', collRef: artemisRef.collection('stage_of_life') });
  //   /* pbris */
  //   /* pbris reference data has not yet been moved to the latest collection
  //   collectionArray.push({ collname: 'agency_location', collRef: pbrisRef.collection('agency_location') });
  //   collectionArray.push({ collname: 'case_use', collRef: pbrisRef.collection('case_use') });
  //   collectionArray.push({ collname: 'division', collRef: pbrisRef.collection('division') });
  //   collectionArray.push({ collname: 'document', collRef: pbrisRef.collection('document') });
  //   collectionArray.push({ collname: 'instrument', collRef: pbrisRef.collection('instrument') });
  //   collectionArray.push({ collname: 'jurisdiction', collRef: pbrisRef.collection('jurisdiction') });
  //   collectionArray.push({ collname: 'stage_of_business', collRef: pbrisRef.collection('stage_of_business') });
  //   */
  //   collectionArray.push({ collname: 'agency_location', collRef: this.afs.collection('Agency Location') });
  //   collectionArray.push({ collname: 'case_use', collRef: this.afs.collection('Case Use') });
  //   collectionArray.push({ collname: 'division', collRef: this.afs.collection('Division') });
  //   collectionArray.push({ collname: 'document', collRef: this.afs.collection('Document') });
  //   collectionArray.push({ collname: 'instrument', collRef: this.afs.collection('Instrument') });
  //   collectionArray.push({ collname: 'jurisdiction', collRef: this.afs.collection('Jurisdiction') });
  //   collectionArray.push({ collname: 'stage_of_business', collRef: this.afs.collection('Stage of Business') });
  //   /* loop */
  //   for(let collJSON of collectionArray) {
  //     let suggestArray: any = [];
  //     collJSON.collRef.snapshotChanges().subscribe((data: any) => {
  //       data.forEach((info: any) => {
  //         let item: any = info.payload.doc.data();
  //         suggestArray.push(item);
  //       });
  //     });
  //     this.valueSuggestions[collJSON.collname] = suggestArray;
  //   }
  // }


  // searchSuggestions: any = [];
  // getKeywordSuggestions(){
  //   // keyword auto suggestion
  //   if(this.searchform.value.keyword == '') {
  //     this.searchSuggestions = [];
  //   }
  //   else{
  //     this.afs.collection("Services").snapshotChanges().subscribe(
  //       (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();

  //           if(this.searchSuggestions.includes(item.name_and_details.service_name) == false){
  //             if(item.name_and_details.service_name.toLowerCase().includes(this.searchform.value.keyword.toLowerCase())){
  //               this.searchSuggestions.push(item.name_and_details.service_name);
  //             }
  //           }
  //         });
  //       })
  //   }
  // }


  // selectItem(item: any){
  //   this.searchSuggestions = [];
  //   this.searchform.get("keyword")?.setValue(item);
  // }

  /**
   * [LOV Functions] ========================= [LOV Functions] ========================= [LOV Functions] =========================
   */
  // getAgencyCategoryList(){
  //   this.getFirestoreJSON("agency_category", "ARTEMIS",this.fieldLOVagencyCategory)
  // }
  // getAgencyList(){
  //   this.getFirestoreJSON("agency", "ARTEMIS",this.fieldLOVagency)
  // }
  // getSectorList(){
  //   this.getFirestoreJSON("sector", "common",this.fieldLOVsector)
  // }
  // getClassificationList(){
  //   this.getFirestoreJSON("classification", "ARTEMIS",this.fieldLOVclassification)
  // }
  // getServiceCategoryList(){
  //   this.getFirestoreJSON("service_category", "ARTEMIS",this.fieldLOVserviceCategory)
  // }
  // getServiceList(){
  //   this.getFirestoreJSON("list_of_services", "ARTEMIS",this.fieldLOVservice)
  // }
  // getYearList(){
  //   //
  // }
  // async getFirestoreJSON(collectionName: string, id: string ,arrayItem: any[]){
  //   this.afs.collection("References").doc(id).collection(collectionName).snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
  //     (data: any) => {
  //         data.forEach((info: any) => {
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
            
  //           arrayItem.push(item); // push into array
  //         });
  //     });
  // }

  formReset() {
    //not yet working correctly
    // this.searchform.reset()
    this.searchform.patchValue({
      agencycategory:'',
      agency:'',
      sector:'',
      classification:'',
      servicecategory:'',
      service:'',
      year:'',
      keyword:'',
    })
    this.searchform.markAsPristine();
    this.searchform.markAsUntouched();
  }

  formSubmit() {
    // validate
    //console.log(this.searchform.value)
    this.validatedFields()
    if(this.searchform.valid){

      let JSONarray = {
        keyword: this.searchform.value.keyword,
        year: this.searchform.value.year,
        classification: this.searchform.value.classification,
        sector: this.searchform.value.sector,
        agency: this.searchform.value.agency,
        agencycategory: this.searchform.value.agencycategory,
        servicecategory:this.searchform.value.servicecategory,
      };
      this.router.navigate(['/artemis/results'], {queryParams: JSONarray} );
    }else {
      console.error("form ivalid")
    }
  }
  
  hasError(controlName:any, validType:any){
    return ((this.searchform.get(controlName) as UntypedFormControl).hasError(validType) 
    && ((this.searchform.get(controlName) as UntypedFormControl).dirty 
    || (this.searchform.get(controlName) as UntypedFormControl).touched)); 
  }

  validatedFields(){
    if(!this.searchform.valid){
      this.searchform.markAllAsTouched();
      for(const control in this.searchform.controls){
        if (this.searchform.controls.hasOwnProperty(control)) {
          this.searchform.controls[control].markAllAsTouched();
          this.searchform.controls[control].markAsDirty();
        }
      }
    }
  }

}
