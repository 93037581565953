
    <div *ngIf="isPhase2">
      <div class="row">
        <div class="card">

          <!-- Regulatory notification Form -->
          <div class="card-header info-header mt-5">
            <div class="row">
              <div class="ms-3">
                  <h6 class="fw-bold">UPLOADED REGULATIONS DETAILS</h6>
              </div>
            </div>
          </div>

          <div class="card-body form-container">
            <div class="row">
              <form class="col">

                <!-- Regulation Details-->
                <div class="row table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                      <tr></tr>
                    </thead>

                    <tbody class="info-subheader">
                      <tr>
                        <th colspan="2">Regulation Title</th>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <textarea class="form-control" [value]="phase2Worklist.title" [readOnly]="isPhase2"></textarea>
                        </td>
                      </tr>

                      <tr>
                        <th colspan="2">Regulation Short Title</th>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <textarea class="form-control" [value]="phase2Worklist.subject" [readOnly]="isPhase2" ></textarea>
                        </td>
                      </tr>
                      
                      <tr>
                        <th class="col-md-3">Regulation Number:</th>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.tags.reg_number ? phase2Worklist.tags.reg_number : '' " [readOnly]="isPhase2"/></td>
                      </tr>
                      <tr>
                        <th class="col-md-3">Regulation Instrument:</th>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.instrument_name" [readOnly]="isPhase2"/></td>
                      </tr>
                      <tr>
                        <th class="col-md-3">Issuing Agency:</th>
                        <td>
                          <ul>
                            <li *ngFor="let row of agency_list">
                              {{ row }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                        
                  </table>
                </div>

              </form>
            </div>
          </div>

          <!-- Review of Tags -->
          <div class="card-header info-header">
            <div class="row">
              <div class="ms-3">
                  <h6 class="fw-bold">Review of Tags</h6>
              </div>
            </div>
          </div>

          <div class="card-body form-container">
            <div class="row">
              <form class="col">

                <!-- Main Tag-->
                <div class="row table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                      <tr></tr>
                    </thead>

                    <tbody class="info-subheader">
                      <tr><th colspan="2">Main Tag</th></tr>
                      <tr>
                        <td class="col-md-3">Classification:</td>
                        <td>
                          <mat-radio-group class="example-radio-group">
                            <mat-radio-button 
                              [checked]="phase2Worklist.reg_classification == 'Business' || (phase2Worklist.tags && phase2Worklist.tags.reg_classification == 'Business')"
                              value="Business"
                              class="example-radio-button"
                              [disabled]="isPhase2">Business
                            </mat-radio-button>
            
                            <mat-radio-button
                              [checked]="phase2Worklist.reg_classification == 'Non-Business' || (phase2Worklist.tags && phase2Worklist.tags.reg_classification == 'Non-Business')"
                              value="Non-Business"
                              class="example-radio-button"
                              [disabled]="isPhase2">Non-Business
                            </mat-radio-button>
                          </mat-radio-group>
                        </td>
                      </tr>
                    </tbody>

                    <tbody class="info-subheader" *ngIf="phase2Worklist.reg_classification == 'Business' || (phase2Worklist.tags && phase2Worklist.tags.reg_classification == 'Business')">
                      <tr>
                        <td>Sector:</td>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.sector_name" [readOnly]="isPhase2" /></td>
                      </tr>
                      <tr>
                        <td>Stage of Business:</td>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.business_name" [readOnly]="isPhase2" /></td>
                      </tr>
                    </tbody>

                    <tbody class="info-subheader" *ngIf="phase2Worklist.reg_classification == 'Non-Business' || (phase2Worklist.tags && phase2Worklist.tags.reg_classification == 'Non-Business')">
                      <tr>
                        <td>Stage of Life:</td>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.reg_stageoflife" [readOnly]="isPhase2" /></td>
                      </tr>
                      <tr>
                        <td>Life Event:</td>
                        <td><input type="text" class="form-control read-only" [value]="phase2Worklist.reg_lifeevent" readonly /></td>
                      </tr>
                    </tbody>

                    <tbody class="info-subheader">
                      <tr>
                        <td>Division:</td>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.division_name" /></td>
                      </tr>
                      <tr>
                        <td>Jurisdiction:</td>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.juris_name" [readOnly]="isPhase2" /></td>
                      </tr>
                      <tr>
                        <td>Case Use:</td>
                        <td><input type="text" class="form-control" [value]="phase2Worklist.case_name" [readOnly]="isPhase2" /></td>
                      </tr>
                    </tbody>

                  </table>
                </div>

                <!-- Sub Tags-->
                <div class="row table-responsive" *ngIf="phase2Worklist.has_sub_tags">
                  <table class="table table-striped table-bordered" *ngFor="let subTag of phase2Worklist.sub_tags; let i = index; let c = count">
                    <thead class="info-subheader">
                      <tr></tr>
                    </thead>
                              
                    <tbody class="info-subheader">
                      <tr><th colspan="2">Sub Tag/s</th></tr>
                      <tr>
                        <td class="col-md-3">Classification:</td>
                        <td>
                          <mat-radio-group class="example-radio-group d-flex flex-row  justify-content-evenly">
                            <mat-radio-button [checked]="subTag.reg_tag_classification == 'Business'"
                            value="Business"
                            class="example-radio-button"
                            disabled readonly>Business</mat-radio-button>

                            <mat-radio-button [checked]="subTag.reg_tag_classification == 'Non-Business'"
                            value="Non-Business"
                            class="example-radio-button"
                            disabled readonly>Non-Business</mat-radio-button>
                          </mat-radio-group>
                        </td>
                      </tr>
                    </tbody>

                    <tbody class="info-subheader" *ngIf="subTag.reg_tag_classification ==  'Business'">
                      <tr>
                        <td>Sector:</td>
                        <td><input type="text" class="form-control" [value]="subTag.sector_name ? subTag.sector_name : '--'" disabled readonly/></td>
                      </tr>
                      <tr>
                        <td>Stage of business:</td>
                        <td><input type="text" class="form-control" [value]="subTag.sob_name ? subTag.sob_name : '--'" disabled readonly/></td>
                      </tr>
                      <tr>
                        <td>Division:</td>
                        <td><input type="text" class="form-control" [value]="subTag.div_name ? subTag.div_name : '--'" disabled readonly/></td>
                      </tr>
                      <tr>
                        <td>Case Use:</td>
                        <td><input type="text" class="form-control" [value]="subTag.case_name ? subTag.case_name : '--'" disabled readonly/></td>
                      </tr>
                    </tbody>

                    <tbody class="info-subheader" *ngIf="subTag.reg_tag_classification == 'Non-Business'">
                      <tr>
                        <td>Stage of Life:</td>
                        <td><input type="text" class="form-control" [value]="subTag.sol_name ? subTag.sol_name : '--'" disabled readonly/></td>
                      </tr>
                      <tr>
                        <td>Life Event:</td>
                        <td><input type="text" class="form-control read-only" [value]="subTag.le_name ? subTag.le_name : '--'" disabled readonly/></td>
                      </tr>
                    </tbody>

                  </table>
                </div>

                <!-- Legal Bases & Intl Agreements -->
                <div class="row table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr></tr>
                    </thead>

                    <tbody class="info-subheader">
                      <tr><th>Legal Bases</th></tr>
                      <tr>
                        <td>
                          <ul>
                            <li *ngFor="let row of legalBases_list">
                              {{ row }}
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <tr><th>International Agreements</th></tr>
                      <tr>
                        <td>
                          <ul>
                            <li *ngFor="let row of internationalAgreement_list">
                              {{ row }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  
                  </table>
                </div>

              </form>
            </div>
          </div>


        </div>
      </div>
    </div>
