import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EmailAppCode, EmailTemplateCode } from '../entities/emailTemplates';
import { NotificationsEmailer } from '../service/notifications-emailer.service';
import { matchingPassword, needsStrongerPassword } from '../validators';

@Component({
  selector: 'app-custom-auth-handle',
  templateUrl: './custom-auth-handle.component.html',
  styleUrls: ['./custom-auth-handle.component.scss']
})
export class CustomAuthHandleComponent implements OnInit {

  mode: string = "";
  actionCode: string = "";
  emailAddress: string = "";
  continueUrl: string = "";
  public showFormErrors: boolean = false;
  successFlag = false;
  /* 
    0 = VERIFYING
    1 = VERIFIED
    2 = ERROR
  */
  verifyStatusFlag: number = 0;
  /* 
    -1 = Empty initial loading screen
    0 = PASSWORD FORM
    1 = PASSWORD RESET SUCCESS
    2 = ERROR
  */
  resetPasswordStatusFlag: number = -1;

  resetpasswordform = new UntypedFormGroup({
    password: new UntypedFormControl('',
      {
        validators: [Validators.required],
        updateOn: "blur"
      }
    ),
    passwordStrength: new UntypedFormControl(0,
      {
        validators: [needsStrongerPassword()],
      }
    ),
    confirmpassword: new UntypedFormControl('',
      {
        validators: [Validators.required, matchingPassword()],
        updateOn: "blur"
      }
    ),
  });
  constructor(private route: ActivatedRoute,
    public auth: AngularFireAuth,
    private nE: NotificationsEmailer,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.mode = params.mode;
      this.actionCode = params.oobCode;
      this.continueUrl = params.continueUrl;

      switch (this.mode) {
        case "resetPassword":
          this.auth.verifyPasswordResetCode(this.actionCode)
            .then((returnEmail) => {
              this.resetPasswordStatusFlag = 0;
              this.emailAddress = returnEmail;
            })
            .catch((err) => {
              this.resetPasswordStatusFlag = 2;
              this.mode = '';
            })
          break;
        case "verifyEmail":
          this.verifyEmail();
          break;
        default:
          break;
      }
    });
  }

  resetPassword() {
    if (!this.resetpasswordform.valid) {
      this.showFormErrors = true;
    }
    else {
      this.showFormErrors = false;
      this.auth.verifyPasswordResetCode(this.actionCode)
        .then((userEmail) => {
          this.auth.confirmPasswordReset(this.actionCode, this.resetpasswordform.value.password)
          .then(() => {
            // 12 {link:'https://arta-respond.web.app'}
            let variables = {
              currentDate: new Date()
            }
            this.toSendNotifEmail([userEmail],EmailTemplateCode.USER_PASSWORD_CHANGE_SUCCESS,variables)
            this.resetPasswordStatusFlag = 1;
          })
          .catch((err) => {
            this.resetPasswordStatusFlag = 2;
          });
        })
        .catch((err) => {
          this.resetPasswordStatusFlag = 2;
        })
    }
  }

  verifyEmail() {
    console.log('verifyEmail', this.auth)
    this.auth.applyActionCode(this.actionCode)
      .then(async(a) => {
        console.log('after verifiying',a)
        // no way of getting email after verification
        let variables = {
          system: null
        }

        const authState = this.auth.authState.subscribe({
          next:(result)=>{
            const email = result?.email
            // no way gettting user email or id
            this.nE.emailServiceHandler([email], EmailAppCode.ARTA, EmailTemplateCode.NEW_USER_REGISTRATION, variables)
            .then(()=>this.verifyStatusFlag = 1)
            .catch((err)=>{
              console.error('emailServiceHandler',err)
              this.verifyStatusFlag = 1
            })
            authState.unsubscribe()
          },
          error:(err)=>{
            console.error('authState',err)
            this.verifyStatusFlag = 1
            authState.unsubscribe()
          }
        })
      })
      .catch((err) => {
        console.error('applyActionCode',err)
        this.verifyStatusFlag = 2;
      })
  }

  proceedToSite() {
    window.location.replace(this.continueUrl);
  }

  onStrengthChanged(e: number) {
    this.resetpasswordform.patchValue({
      passwordStrength: e
    });
  }

  toSendNotifEmail(currentUsers:any[], templateCode:number,variables:any){
    if(templateCode > 0){
      
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('CustomAuthHandleComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('CustomAuthHandleComponent emailer error: ',err)
      //     }
      // })

      return this.nE.emailServiceHandler(currentUsers, 1, templateCode, variables)
    } else {
      console.error('CustomAuthHandleComponent emailer error: no email template for account type')
      return true
    }
  }
}
