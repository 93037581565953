import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface CartData {
  doc_id: any;
  id: any;
  action: any;
  collection: any;
  name?: string;
  designation?: string;
  email?: string;
  success: boolean;
 
}

@Component({
  selector: 'app-artemis-view-organization-staffs-dialog',
  templateUrl: './artemis-view-organization-staffs-dialog.component.html',
  styleUrls: ['./artemis-view-organization-staffs-dialog.component.scss']
})
export class ArtemisViewOrganizationStaffsDialogComponent implements OnInit {
  loading: boolean = false; //for button spinner
  title:string;
  collection:string = '';
  action:any;
  actionForm = this.formBuilder.group({
    name:new UntypedFormControl('',Validators.required),
    designation:new UntypedFormControl('',Validators.required),
    email:new UntypedFormControl('',[Validators.required,Validators.email]),
  });
  toEdit:boolean = false;
  toCreate:boolean= false;
  toView:boolean = false;
  toDelete:boolean = false;

  checkActionForm = {
    name:[
      {type:"required", message:"Name is required"}
    ],
    designation:[
      {type:"required", message:"Designation is required"}
    ],
    email:[
      {type:"required", message:"Email is required"},
      {type:"email", message:"Email is in incorrect format"}
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ArtemisViewOrganizationStaffsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CartData,
    public formBuilder: UntypedFormBuilder,
    private afs: AngularFirestore,
  ){ }

  ngOnInit(): void {
    //console.log('data',this.data)
    this.data.success = false;
    if(this.data.action === 'edit'){
      this.action = "Edit"
      this.actionForm.patchValue({
        name: this.data.name,
        designation: this.data.designation,
        email: this.data.email,
        id: this.data.id
      })
      this.actionForm.markAsUntouched()
      this.actionForm.markAsPristine()
      this.toEdit = true
    }
    else if(this.data.action === 'create'){
      this.action = "Add"
      this.toCreate = true
    }
    else if(this.data.action === 'view'){
      this.action = "Viewing"
      this.toView = true
    }
    else if(this.data.action === 'delete'){
      this.action = "Confirm deletion of"
      this.toDelete = true
    }

    if(this.data.collection === 'hOA'){
      this.title = "Agency Head"
      this.collection = "Head of Agency"
    }
    else if(this.data.collection === 'cP'){
      this.title = "Chairperson"
      this.collection = "Chairperson"
    }
    else if(this.data.collection === 'vCP'){
      this.title = "Vice-Chairperson"
      this.collection = "Vice Chairperson"
    }
    else if(this.data.collection === 'memb'){
      this.title = "Member"
      this.collection = "Members"
    }
  }

  goBack(){
    this.dialogRef.close(this.data)
  }

  submitUpdate(){
    this.loading = true
    //console.log("updating", this.actionForm.value)
    this.validateField();
    if(this.actionForm.valid){
      const actionData: CartData = this.actionForm.value;
      this.afs.collection('Agency').doc(this.data.doc_id)
      .collection(this.collection).doc(this.data.id)
      .update({
        name: actionData.name,
        designation: actionData.designation,
        email: actionData.email,
      })
      .then(()=>{
        let returnData: CartData =  this.data
        returnData.name = actionData.name
        returnData.designation = actionData.designation
        returnData.email = actionData.email
        returnData.success = true;
        this.loading = false;
        this.dialogRef.close(returnData);
        alert("Update Successful")
      })
      .catch(err=>{
        this.loading = false;
        console.error('update error',err)
        alert("Update Failed")
      })
    }
    else{
      this.loading = false;
      //console.log("Form invalid")
      alert("Fill up the required fields correctly")
    }
  }

  submitCreate(){
    this.loading = true
    //console.log("submitting")
    this.validateField();
    //console.log(this.actionForm.value)
    if(this.actionForm.valid){
      let actionData: CartData = this.actionForm.value;
      this.afs.collection('Agency').doc(this.data.doc_id)
      .collection(this.collection)
      .add({
        name: actionData.name,
        designation: actionData.designation,
        email: actionData.email,
      })
      .then(res=>{
        actionData.id = res.id;
        let returnData: CartData =  this.data
        returnData.name = actionData.name
        returnData.designation = actionData.designation
        returnData.email = actionData.email
        returnData.success = true;
        returnData.id = actionData.id
        this.loading = false;
        this.dialogRef.close(returnData);
        alert("Adding Successful")
      })
      .catch(err=>{
        this.loading = false;
        alert("Adding Failed")
      })
    }
    else{
      this.loading = false;
      //console.log("Form invalid")
      alert("Fill up the required fields correctly")
    }
  }

  submitDelete(){
    this.loading = true
    //console.log("deleting")
    // let actionData = this.actionForm.value; 
    this.afs.collection('Agency').doc(this.data.doc_id)
    .collection(this.collection).doc(this.data.id).delete()
    .then(()=>{
      let returnData: CartData =  this.data
      returnData.success = true;
      this.loading = false;
      this.dialogRef.close(returnData);
    })
    .catch(err=>{
      this.loading = false;
      alert("Deleting Failed")
    })
  }

  hasErrors(controlName:any, validType:any){
    return ((this.actionForm.get(controlName) as UntypedFormControl).hasError(validType) 
      && ((this.actionForm.get(controlName) as UntypedFormControl).dirty 
      || (this.actionForm.get(controlName) as UntypedFormControl).touched));
  }

  validateField(){
    if(!this.actionForm.valid){
      this.actionForm.markAllAsTouched();
      for(const control in this.actionForm.controls){
        if (this.actionForm.controls.hasOwnProperty(control)) {
          this.actionForm.controls[control].markAllAsTouched();
          this.actionForm.controls[control].markAsDirty();
        }
      }
    }
  }
}