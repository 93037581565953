
<mat-card>
  <mat-card-header>
    <mat-card-title><Strong><i class="fas fa-comments"></i> <span class="text-primary"> Comments</span></Strong></mat-card-title>

  </mat-card-header>
  <mat-card-content>


    
    
    <div class="col buttonGroup float-start">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle (click)="filterBy('')" checked >All Services</mat-button-toggle>
        <mat-button-toggle  (click)="filterBy('0')" >Resolved</mat-button-toggle>
        <mat-button-toggle  (click)="filterBy('1')"  >Unresolved</mat-button-toggle>

      </mat-button-toggle-group>
    </div>

     <!--added search function to filter comments table-->
    
     <div class="col-4 mb-3 float-end">
      <div class="input-group rounded">
        <input type="search" class="form-control " placeholder="Enter keywords here..." aria-label="Search" aria-describedby="search-addon" (keyup)="applyFilter($event)" #input />
        <span class="input-group-text border-0" id="search-addon">
          <i class="fas fa-search"></i>
        </span>
      </div>
    </div>

    <div>
      <table class="table table-responsive table-striped  table-hover"  mat-table [dataSource]="dataSource" matSort  (matSortChange)="sortChange($event)" >
    
           <!--Column Auto number-->
        <ng-container matColumnDef="No">
          <th mat-header-cell class="col-header" *matHeaderCellDef > No.</th>
          <td mat-cell *matCellDef="let row;">
             {{ row.no }}</td>
         </ng-container>
    
        <!--Column Commentor Name-->
        <ng-container matColumnDef="Name">
          <th mat-header-cell class="col-header"  *matHeaderCellDef > Name </th>
          <td mat-cell *matCellDef="let row"> {{ row.user.name}} </td>
        </ng-container>
    
         <!--Column Service Name Name-->
         <ng-container matColumnDef="Service">
          <th mat-header-cell  *matHeaderCellDef mat-sort-header> Service </th>
          <td mat-cell *matCellDef="let row"> {{ row.type.type_title}} </td>
        </ng-container>
    
         <!--Column User type, icon displayed-->
         <ng-container matColumnDef="UserType">
          <th mat-header-cell  *matHeaderCellDef > User Type </th>
          <td mat-cell *matCellDef="let row">
            <div style="color:darkslategrey;"><h4>
                    
              <div [ngSwitch]="row.comments.user" >
                <div *ngSwitchCase="'Individual'">
                  <i matTooltip="Individual" class="fas fa-user"></i>
                </div>
    
                <div *ngSwitchCase="'Organization'">
                  <i matTooltip="Organization" class="fas fa-users"></i>
                </div>
                
                <div *ngSwitchDefault>
                  <i matTooltip="Anonymous" class="fas fa-user-secret"></i>
                </div>
    
              </div>
            </h4>
            </div>
          </td>
        </ng-container>
    
         <!--Column Date Generated-->
         <ng-container matColumnDef="DateGenerated">
          <th mat-header-cell  *matHeaderCellDef mat-sort-header> Date Generated </th>
          <td mat-cell *matCellDef="let row"> {{row.created_at}} </td>
        </ng-container>
    
         <!--Column comment status with color badges-->
         <ng-container matColumnDef="Status">
          <th mat-header-cell  *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row"> 
            <div class="text-center">
              <div [ngSwitch]="row.status" >
                <div *ngSwitchCase="'RESOLVED'">
                  <span class="badge bg-primary rounded-pill d-inline">{{row.status}}</span>
                  
                </div>
    
                <div *ngSwitchDefault>
                  <span class="badge bg-danger rounded-pill d-inline">{{row.status}}</span>
                  
                </div>
    
              </div>
            </div> </td>
        </ng-container>
    
         <!--Column action buttons eye icon if status is resolved and reply icon if not resolved-->
         <ng-container matColumnDef="Actions">
          <th mat-header-cell  *matHeaderCellDef > Actions </th>
          <td mat-cell *matCellDef="let row"> 
            <div class="float-end">
    
              <div *ngIf="account_type != 'agency'">
    
                <button mat-flat-button 
                          class="m-1 btn btn-warning"
                          matTooltip="View Comment"
                          (click)="sampleModal.show(); openModal(row)">View
                  </button>
    
              </div>
              
              <div *ngIf="account_type == 'agency'">
                <div [ngSwitch]="row.status" >
    
                  <div  *ngSwitchCase="'RESOLVED'">
      
                    <button mat-flat-button
                          class="m-1 btn btn-warning"
                          matTooltip="View Comment"
                          (click)="sampleModal.show(); openModal(row)">View
                  </button>
                  </div>
                  
                  <div *ngSwitchDefault>
                    
                    <button mat-flat-button color="primary" 
                    class="m-1"
                          matTooltip="Reply to Comment"
                          (click)="sampleModal.show(); openModal(row)">Reply
                  </button> </div>
      
                </div>
              </div>
            </div>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
    
   
     </div>
    
  </mat-card-content>
  <mat-card-actions align="end">
    <mat-paginator #paginator [pageSizeOptions]="[15,30,50, 100]" aria-label="Select page of users"></mat-paginator>
        
  </mat-card-actions>
</mat-card>
<div style="overflow-y: auto" data-keyboard="false" data-backdrop="static" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><Strong><i class="fas fa-comment-dots"></i></Strong> Review Comment</h5>
        <p class="modal-title float-end mx-4"><strong>Status:</strong> {{status}} </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide(); closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <dl class="row">
          <dt class="col-sm-2">Name:</dt>
          <dd class="col-sm-10">{{ user.name ? user.name : 'N/A'}}</dd>

          <dt class="col-sm-2 text-truncate">Type:</dt>
          <dd class="col-sm-10 text-break">{{ type ? type.type_title : 'N/A'}}</dd>
        
          <dt class="col-sm-2">URL:</dt>
          <dd class="col-sm-10 text-break">{{ url ? url : 'N/A'}}</dd>
        
          <dt class="col-sm-2 text-truncate">Comment:</dt>
          <dd class="col-sm-10 text-break">{{ comment ? comment : 'N/A'}}</dd>
        
          <dt class="col-sm-2 text-truncate">Files:</dt>
          <dd class="col-sm-10">
            <div *ngIf="fetching" class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="row col-12" *ngIf="!fetching && files.length > 0">
              <div class="col-2 m-2" *ngFor="let files of files; let i = index">
                <a [href]="files.url" class="" target="_blank">
                  <i class="fa fa-file" style="font-size: 50px;" aria-hidden="true"></i>
                  <p class="text-wrap">{{ files.name }}</p>
                  <!-- <img [src]="files.url | async" /> -->
                </a>
              </div>
            </div>
            <div *ngIf="files.length === 0 && !fetching">N/A</div>
          </dd>

          <dt *ngIf="status === enum_comment_status.RESOLVED" class="col-sm-2 text-truncate">Reply</dt>
          <dd *ngIf="status === enum_comment_status.RESOLVED" class="col-sm-10 text-break">{{ reply ? reply : 'N/A'}}</dd>
        </dl>
        <div *ngIf="status === enum_comment_status.UNRESOLVED && account_type == 'agency'">
          <label class="mb-2"><strong><small>Response:</small></strong> </label>
         
            <form [formGroup]="form" autocomplete="off">

              <mat-form-field appearance="outline">
                <mat-label>Input</mat-label>
                <textarea  id="reply" formControlName="reply" matInput placeholder="Enter response..."></textarea>
              </mat-form-field>

              <!--<label clas for="reply"><strong><small>Response</small></strong> </label>
              <textarea class="form-control" id="reply" formControlName="reply" placeholder="..." rows="3"></textarea>-->
            </form>
        </div>
      </div> 
      <div class="modal-footer">
        <button mat-stroked-button  type="button" class="btn btn-danger mx-1" data-dismiss="modal" (click)="sampleModal.hide(); closeModal()"><i class="far fa-times-circle"></i> Close</button>
        <button mat-flat-button *ngIf="status == enum_comment_status.UNRESOLVED && account_type == 'agency'" type="button" color="primary" (click)="submit(id);  sampleModal.hide()">Send Response <i class="far fa-paper-plane"></i></button>
      </div>
    </div>
  </div>
</div>

<!--
<div class="container-fluid">
  <h3 class="mb-3"><Strong><i class="fas fa-comments"></i> Comments</Strong></h3>
new mat table for comments, 
add table pagination-->

<!--added refresh button to reload comments without reloading the page

<div class="card ">
  <div class="card-body">
 
     <div class="col buttonGroup float-start">
      <div class="btn-group shadow-0" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-secondary" (click)="loadCommentList('')" >All Comments</button>
        <button *ngIf="isAgency" type="button" class="btn btn-secondary" (click)="loadCommentList('RESOLVED')" >RESOLVED</button>
        <button *ngIf="isAgency" type="button" class="btn btn-danger" (click)="loadCommentList('UNRESOLVED')" >UNRESOLVED</button>
    
      </div>
      
    </div>

added search function to filter commenta table
    
     <div class="col-4 mb-3 float-end">
      <div class="input-group rounded">
        <input type="search" class="form-control " placeholder="Enter keywords here..." aria-label="Search" aria-describedby="search-addon" (keyup)="applyFilter($event)" #input />
        <span class="input-group-text border-0" id="search-addon">
          <i class="fas fa-search"></i>
        </span>
      </div>
    </div>

    
   
  </div>
</div>

Modal -->
  



 <!--- Old comment table
  
   <div class="py-1">
    
    <div class="" style="max-height: 800px;">
      <table class="table  table-responsive table-striped table-bordered">
        <thead class="bg bg-light">
          <tr>
            
            <th class="table-header fw-bold">Name</th>
            <th class="table-header fw-bold">Service</th>
            <th class="table-header fw-bold">User Type</th>
            <th class="table-header fw-bold">Date Generated</th>
            <th class="table-header fw-bold">Status</th>
            <th class="table-header fw-bold">Action</th>
          </tr>
        </thead>
        <tbody >
          <tr></tr>
          <tr *ngFor="let list of commentList; let i = index">
            <th scope="row">{{ list.data.user.name ? list.data.user.name : "N/A"}}</th>
            <td>{{ list.data.type ? list.data.type.type_title : "N/A" }}</td>
            <td>
              <div class="text-center"><h4>
                
                <div [ngSwitch]="list.data.comments.user" >
                  <div *ngSwitchCase="'Individual'">
                    <i class="fas fa-user"></i>
                  </div>

                  <div *ngSwitchCase="'Organization'">
                    <i class="fas fa-users"></i>
                  </div>
                  
                  <div *ngSwitchDefault>
                    <i class="fas fa-user-secret"></i>
                  </div>
  
                </div>
              </h4>
              </div>
              
              
             
            <td>{{ list.data.created_at }}</td>
            <td>
             
            <div class="align-items-center">
              <div [ngSwitch]="list.data.status" >
                <div *ngSwitchCase="'RESOLVED'">
                  <span class="badge bg-info rounded-pill d-inline">{{list.data.status}}</span>
                  
                </div>
                
                <div *ngSwitchDefault>
                  <span class="badge bg-danger rounded-pill d-inline">{{list.data.status}}</span>
                  
                </div>

              </div>
            </div>
              
             </td>
            <td>

                
            <div class="align-items-center">
              <div [ngSwitch]="list.data.status" >
                <div *ngSwitchCase="'RESOLVED'">

                  <button
                  (click)="sampleModal.show(); openModal(list)"
                  mat-stroked-button
                  class="btn btn-secondary "
                  
                ><i class="far fa-eye"></i>
                </button>
                </div>
                
                <div *ngSwitchDefault>
                  
                  <button mat-flat-button (click)="sampleModal.show(); openModal(list)" color="primary"><i class="fas fa-reply"></i></button>
                </div>

              </div>
            </div>
              

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


</div> -->




