export const PBRIS = 'PBRIS'
export const ARTEMIS = 'ARTEMIS'

export enum CollectionName {
  SECTOR = 'Sector',
  DIVISION = 'Division',
  STAGE_OF_BUSINESS = 'Stage of Business',
  CASE_USE = 'Case Use',
  STAGE_OF_LIFE = 'Stage of Life',
  LIFE_EVENT = 'Life Event',
  JURISDICTION = 'Jurisdiction',
  AGENCY = 'Agency',
  INSTRUMENT = 'Instrument',
  DOCUMENT = 'Document',
  REGULATION_EXISTING_PHASE_1 = 'PBRIS 1 Regulations',
  WORKLIST_PROPOSED_REGULATIION = 'Worklist-Proposed Regulations',
  REGULATION_PROPOSED_PHASE_2 = 'regulations-proposed-phase-02',
  REGULATION_PROPOSED_CRIS = 'regulations-proposed-cris',
  REGULATION_EXISTING_PHASE_2 = 'regulations-existing-phase-02',
  PUBLIC_CONSULTATIONS = 'Public Consultations',
}

export enum FireAuthError {
  EmailAlreadyInUser = 'auth/email-already-in-use',
}