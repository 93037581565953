<div class="container-fluid p-0">
    <div class="row justify-content-center">
      <div class="col-sm-3 col-lg-6 d-none d-lg-block" >
        <app-artemis-login-page-carousel></app-artemis-login-page-carousel>
      </div>
  
      <div class="col-sm-9 col-lg-6">
        <div class="container form-container ">
            <ng-container *ngIf="call_registration === false">
                <div class="row g-0">
                    <div class="col-md" ><!--banner new-->
                        <app-artemis-login-banner></app-artemis-login-banner>
                    </div>
                </div>
                <!--FORM-->
                <div class="row mt-3">
                    <div class="col-md">
                        <form [formGroup]="form">
                            <div class="row form-group flex align-items-center justify-content-center">
                                <mat-form-field appearance="outline">
                                    <mat-label>User ID</mat-label>
                                    <input type="email" matInput placeholder="Enter your email..."
                                        formControlName="username">
                                    <mat-icon matSuffix>person</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="row form-group flex align-items-center justify-content-center">
                                <mat-form-field appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input name="password" [type]="hide ? 'password' : 'text'" matInput
                                        placeholder="Enter your password..." formControlName="password">
                                    <a mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </a>
                                </mat-form-field>
                            </div>
                            <div class="row login">
                                <div class="col d-flex justify-content-start">
                                    <p> <a routerLink="/forgot-password"
                                            [queryParams]="{requestFrom: 'artemis'}">Forgot Password?</a></p>
                                </div>
                                <div class="col m-1 d-flex justify-content-end">
                                    <button (click)="onSubmit()" class="btn btn-primary " [disabled]="loading">
                                        <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                                        Log in <i class="fas fa-sign-in-alt"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-3 registration">
                                <div class="container signup rounded-3 pb-2">
                                    <div class="row my-2">
                                        <strong class="text-center text-muted">Don't have an account?</strong>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-md text-center">
                                            <button mat-stroked-button color="primary" class=" col m-1"
                                                style="width: 110px;" (click)="toggleRegistration()"
                                                id="bt ">Sign-Up <i class="fas fa-user-circle"></i></button>
                                        </div>
                                        <div class="col-md text-center">
                                            <button mat-flat-button color="warn" class=" col m-1"
                                                (click)="loginAsGuest()">Continue as Guest <i
                                                    class="fas fa-sign-in-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-container>

            <app-artemis-registration *ngIf="call_registration"
                (close_reg)="toggleRegistration()"></app-artemis-registration>

        </div>
      </div>
  
    </div>
  </div>



