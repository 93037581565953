
<div class="container-fluid">
  
  <div class="row" style="margin-bottom: 1em">
    <div class="col">
      <button class="btn btn-dark w-25" (click)="showOnInboxViewport()">
        Back to Charters
      </button>
    </div>

  
  </div>
  <div class="row my-3 " >
   <div class="col">
    <strong style="font-size: 20px;">AGENCY: {{agency_name}}</strong>
   </div>
   
   <div class="col ">
    <div class="float-end">
      <button mat-button  (click)="viewAgencyProfile()">
        Profile
      </button> | 
      <button mat-button  (click)="viewCertificateofCompliance()">
        View Certificate of Compliance
      </button>
    </div>
  </div>
  </div>
</div>

<!--update container shadow to matcard-->
<mat-card>
  <mat-card-header class="mb-2">
    
    <mat-card-title class="text-primary" ><strong>Service With Changes</strong></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- add table pagination using datatable directive-->
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="table  table-bordered table-striped table-hover table-responsive">
    <thead>
      <tr>
        <th class="table-header">Date Generated</th>
        <th class="table-header">Officer</th>
        <th class="table-header">Service Name</th>
        <th class="table-header">Status</th>
        <th class="table-header">Effectivity</th>
        <th class="table-header">Assessment</th>
        <th class="table-header">Action Due</th>
        <th class="table-header">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let worklist of results1">
        <td>
          <h6>
            {{
            worklist.dateGenerated.toDate() | date: "MMMM dd, yyyy hh:mm:ss"
            }}
          </h6>
        </td>
        <td>
          <h6>{{ worklist.fromOfficer }}</h6>
        </td>
        <td>
          <h6>
            <u>{{ worklist.documentTitle }}</u>
          </h6>
        </td>
        <td>
          <div class="align-items-center">
            <div [ngSwitch]="worklist.status">

              <div *ngSwitchCase="'FOR ARTA REVIEW'">
                <h6>
                  <span class="badge  bg-info ">{{worklist.status}}</span>
                </h6>
              </div>

              <div *ngSwitchCase="'FOR ARTA REVIEW - DC'">
                <h6>
                  <span class="badge  bg-danger ">{{worklist.status}}</span>
                </h6>
              </div>



              <div *ngSwitchCase="'FOR ARTA REVIEW - DIRECTOR'">
                <h6>
                  <span class="badge  bg-danger ">{{worklist.status}}</span>
                </h6>
              </div>

              <div *ngSwitchDefault>

                <h6>
                  <span class="badge  bg-primary ">{{worklist.status}}</span>
                </h6>
              </div>

            </div>
          </div>
        </td>
        <td>
          <div class="align-items-center">
            <div [ngSwitch]="worklist.effectivity">
              <div *ngSwitchCase="'DELIST'">
                <h6>
                  <span class="badge  bg-warning ">{{worklist.effectivity}}</span>
                </h6>
              </div>
              <div *ngSwitchCase="'NEW'">
                <h6>
                  <span class="badge  bg-success">{{worklist.effectivity}}</span>
                </h6>
              </div>
              <div *ngSwitchDefault>

                <h6>
                  <span class="badge  bg-info ">{{worklist.effectivity}}</span>
                </h6>
              </div>

            </div>
          </div>
        </td>
        <td>
          <div class="align-items-center">
            <div [ngSwitch]="worklist.assessment">
              <div *ngSwitchCase="'Compliant'">

                <h6><span class="badge  bg-success ">{{worklist.assessment}}</span></h6>

              </div>

              <div *ngSwitchDefault>

                <h6>
                  <span class="badge  bg-warning ">{{worklist.assessment}}</span>
                </h6>
              </div>

            </div>
          </div>

        </td>
        <td>
          <h6>
            {{ worklist.dueDate.toDate() | date: "MMMM dd, yyyy hh:mm:ss" }}
          </h6>
        </td>
        <td>
          <div *ngIf="account_type == enum_account_subtype.ARTA_CMEO_DIR">
            <button mat-flat-button class="m-1" color="primary" [disabled]="
                worklist.status !== enum_service_status.FOR_ARTA_REVIEW_DIR
              " (click)="approveWorklist(worklist.id)">
              Approve
            </button>
          </div>
          <div >
            <button (click)="goToServiceDetail(worklist.id,worklist.documentId)" mat-flat-button
              class="btn btn-warning m-1">
              Review
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  </mat-card-content>
  <mat-card-actions align="end">
    <div 
    *ngIf="account_type == enum_account_subtype.ARTA_CMEO_PO || account_type == enum_account_subtype.ARTA_CMEO_DC">
    <div >
      <button mat-flat-button class="btn btn-warning m-2" [disabled]="hasPendingWorklist"
        (click)="submitWorklistsForApproval()">
        Submit to Next Level
      </button>
    </div>
  </div>
  <div  *ngIf="account_type == enum_account_subtype.ARTA_CMEO_DIR">
    <div >
      <button mat-flat-button  class="btn btn-warning m-2" [disabled]="!hasPendingWorklist" (click)="approveAll()">
        Approve All
      </button>
    </div>
  </div>
  </mat-card-actions>
</mat-card>
<br>

<!--Agency Existing Services Table with pagination-->
<mat-card>
  <mat-card-header class="mb-2">
    <mat-card-title class="text-primary"><strong>Agency Existing Services</strong></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    
    <!--We used material table for this table bec of pagination conflict with 
     if both table use same Datatable dtOptions and dtTrigger -->
    <table class="table table-responsive table-striped table-sm" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" >
      
      <!-- ID Column -->
      <ng-container matColumnDef="datemodified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Last Modified </th>
        <td mat-cell *matCellDef="let row"> {{ row.date_posted.toDate() | date: "yyyy-MMM-dd" }} </td>
      </ng-container>
  
      <!-- Progress Column -->
      <ng-container matColumnDef="servicename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Service Name </th>
        <td mat-cell *matCellDef="let row" style="cursor:pointer;" >{{row.serviceDetails.service_name}}</td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row">
          <div class="align-items-center">
            <div [ngSwitch]="row.posted_status">
             
              <div *ngSwitchCase="'POSTED'">

                <span class="badge  bg-primary ">{{row.posted_status}}</span>

              </div>
              <div *ngSwitchCase="'FOR DELISTING'">

                <span class="badge  bg-danger ">{{row.posted_status}}</span>

              </div>

              <div *ngSwitchDefault>

                <span class="badge  bg-info ">{{row.posted_status}}</span>
              </div>
              
            </div>
          </div>
           </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50,100]" aria-label="Select page of users"></mat-paginator>

    
  </mat-card-content>
  <mat-card-actions align="end">
    <div  *ngIf="account_type == enum_account_subtype.ARTA_CMEO_DIR">
      <div>
        <button mat-flat-button class="btn btn-warning m-2"
          [disabled]="!allWorklistsApproved || !hasNonCompliantService" (click)="openReasonForDisapproval()">
          Return CC to Encoder
        </button>
        <button mat-flat-button class="btn btn-warning m-2"
          [disabled]="!allWorklistsApproved || hasNonCompliantService" (click)="triggerFinalWarning()">
          Post CC
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>

<!---
<div class="container-fluid shadow">
  <div class="row" style="margin-bottom: 2em">
    <button class="btn btn-dark w-25" (click)="showOnInboxViewport()">
      Back to Charters
    </button>
  </div>

  <div class="row">
    <div class="inbox-title">
      <h3>Services With Changes</h3>
    </div>
    <div class="overflow-hidden" >
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table  table-bordered table-striped table-hover table-responsive">
        <thead>
          <tr>
            <th class="table-header">Date Generated</th>
            <th class="table-header">Officer</th>
            <th class="table-header">Service Name</th>
            <th class="table-header">Status</th>
            <th class="table-header">Effectivity</th>
            <th class="table-header">Assessment</th>
            <th class="table-header">Action Due</th>
            <th class="table-header">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let worklist of results1">
            <td>
              <h6>
                {{
                worklist.dateGenerated.toDate() | date: "MMMM dd, yyyy hh:mm:ss"
                }}
              </h6>
            </td>
            <td>
              <h6>{{ worklist.fromOfficer }}</h6>
            </td>
            <td>
              <h6>
                <u>{{ worklist.documentTitle }}</u>
              </h6>
            </td>
            <td>
              <div class="align-items-center">
                <div [ngSwitch]="worklist.status">

                  <div *ngSwitchCase="'FOR ARTA REVIEW'">
                    <h6>
                      <span class="badge  bg-info ">{{worklist.status}}</span>
                    </h6>
                  </div>

                  <div *ngSwitchCase="'FOR ARTA REVIEW - DC'">
                    <h6>
                      <span class="badge  bg-danger ">{{worklist.status}}</span>
                    </h6>
                  </div>



                  <div *ngSwitchCase="'FOR ARTA REVIEW - DIRECTOR'">
                    <h6>
                      <span class="badge  bg-danger ">{{worklist.status}}</span>
                    </h6>
                  </div>

                  <div *ngSwitchDefault>

                    <h6>
                      <span class="badge  bg-primary ">{{worklist.status}}</span>
                    </h6>
                  </div>

                </div>
              </div>
            </td>
            <td>
              <div class="align-items-center">
                <div [ngSwitch]="worklist.effectivity">
                  <div *ngSwitchCase="'DELIST'">
                    <h6>
                      <span class="badge  bg-warning ">{{worklist.effectivity}}</span>
                    </h6>
                  </div>
                  <div *ngSwitchCase="'NEW'">
                    <h6>
                      <span class="badge  bg-success">{{worklist.effectivity}}</span>
                    </h6>
                  </div>
                  <div *ngSwitchDefault>

                    <h6>
                      <span class="badge  bg-info ">{{worklist.effectivity}}</span>
                    </h6>
                  </div>

                </div>
              </div>
            </td>
            <td>
              <div class="align-items-center">
                <div [ngSwitch]="worklist.assessment">
                  <div *ngSwitchCase="'Compliant'">

                    <h6><span class="badge  bg-success ">{{worklist.assessment}}</span></h6>

                  </div>

                  <div *ngSwitchDefault>

                    <h6>
                      <span class="badge  bg-warning ">{{worklist.assessment}}</span>
                    </h6>
                  </div>

                </div>
              </div>

            </td>
            <td>
              <h6>
                {{ worklist.dueDate.toDate() | date: "MMMM dd, yyyy hh:mm:ss" }}
              </h6>
            </td>
            <td>
              <div class="row" *ngIf="account_type == enum_account_subtype.ARTA_CMEO_DIR">
                <button mat-stroked-button class="btn btn-warning m-1" [disabled]="
                    worklist.status !== enum_service_status.FOR_ARTA_REVIEW_DIR
                  " (click)="approveWorklist(worklist.id)">
                  Approve
                </button>
              </div>
              <div class="row">
                <button (click)="goToServiceDetail(worklist.id,worklist.documentId)" mat-stroked-button type="button"
                  class="btn btn-warning m-1">
                  Review
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row"
    *ngIf="account_type == enum_account_subtype.ARTA_CMEO_PO || account_type == enum_account_subtype.ARTA_CMEO_DC">
    <div class="col align-right">
      <button mat-stroked-button class="btn btn-warning m-2" [disabled]="hasPendingWorklist"
        (click)="submitWorklistsForApproval()">
        Submit to Next Level
      </button>
    </div>
  </div>
  <div class="row" *ngIf="account_type == enum_account_subtype.ARTA_CMEO_DIR">
    <div class="col align-right">
      <button mat-stroked-button class="btn btn-warning m-2" [disabled]="!hasPendingWorklist" (click)="approveAll()">
        Approve All
      </button>
    </div>
  </div>

  <div class="row">
    <div class="inbox-title">
      <h3>Existing Services</h3>
    </div>
    <table class="table table-bordered table-striped table-responsive table-sm">
      <thead>
        <tr>
          <th class="table-header">Date Last Modified</th>
          <th class="table-header">Service Name</th>
          <th class="table-header">Status</th>
          <th class="table-header">CSF*</th> 
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let service of results2">
          <td>
            <h6>{{ service.date_posted.toDate() | date: "yyyy-MMM-dd" }}</h6>
          </td>
          <td>
            <h6>{{ service.serviceDetails.service_name }}</h6>
          </td>
          <td>
            <div class="align-items-center">
              <div [ngSwitch]="service.posted_status">
               
                <div *ngSwitchCase="'POSTED'">

                  <span class="badge  bg-primary ">{{service.posted_status}}</span>

                </div>
                <div *ngSwitchCase="'FOR DELISTING'">

                  <span class="badge  bg-danger ">{{service.posted_status}}</span>

                </div>

                <div *ngSwitchDefault>

                  <span class="badge  bg-info ">{{service.posted_status}}</span>
                </div>
                
              </div>
            </div>

          </td>
           <td>
            <h6>test</h6>
          </td> 
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row" *ngIf="account_type == enum_account_subtype.ARTA_CMEO_DIR">
    <div class="col align-right">
      <button mat-stroked-button class="btn btn-warning m-2"
        [disabled]="!allWorklistsApproved || !hasNonCompliantService" (click)="openReasonForDisapproval()">
        Return CC to Encoder
      </button>
      <button mat-stroked-button class="btn btn-warning m-2"
        [disabled]="!allWorklistsApproved || hasNonCompliantService" (click)="triggerFinalWarning()">
        Post CC
      </button>
    </div>
  </div>
</div> -->