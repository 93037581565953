import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { RegulationViewProposedArtaRemarks } from '../../regulatory-management/inbox/regulation-view/regulation-view-proposed/regulation-view-proposed-arta-remarks-modal/regulation-view-proposed-arta-remarks-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-pia-assessment-view',
    templateUrl: './pia-assessment-view.component.html',
    styleUrls: ['./pia-assessment-view.component.scss']
})
export class PublicPiaAssessmentViewComponent implements OnInit {
    // TODO:
    @Input() pis_assessment: any;
    @Input() flagged_sections: UntypedFormArray;
    // @Output to trigger submission action button
  
    pis_assessment_summary: any;
  
    displayedColumnsFinalizePis: string[] = ['section', 'assessment', 'previous_remarks'];
  
    constructor(
      public dialog: MatDialog,
    ) {}
  
    ngOnInit(): void {
      console.log('flagged_sections: ',this.flagged_sections)
      console.log('pis_assessment: ',this.pis_assessment)
      this.pis_assessment_summary = {
        pis_assessment: [
          {
            section: 'Summary of Proposed Regulation',
            assessment: this.pis_assessment['pia_summary'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_summary'][0].controls['previous_remarks'].value,
            url:'',
          },
          {
            section: 'Identification of the policy problem',
            assessment: this.pis_assessment['pia_section_1'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_section_1'][0].controls['previous_remarks'].value,
            url:this.pis_assessment['pia_section_1'][0].controls['url'].value,
          },
          {
            section: 'Objectives of government action',
            assessment: this.pis_assessment['pia_section_2'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_section_2'][0].controls['previous_remarks'].value,
            url:'',
          },
          {
            section: 'Consideration of alternative options',
            assessment: this.pis_assessment['pia_section_3'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_section_3'][0].controls['previous_remarks'].value,
            url:'',
          },
          {
            section: 'Impact analysis of policy options',
            assessment: this.pis_assessment['pia_section_4'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_section_4'][0].controls['previous_remarks'].value,
            url:this.pis_assessment['pia_section_4'][0].controls['url'].value,
          },
          {
            section: 'Consultation',
            assessment: this.pis_assessment['pia_section_5'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_section_5'][0].controls['previous_remarks'].value,
            url:'',
          },
          {
            section: 'Conclusion and recommended option',
            assessment: this.pis_assessment['pia_section_6'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['pia_section_6'][0].controls['previous_remarks'].value,
            url:this.pis_assessment['pia_section_6'][0].controls['url'].value,
          },
        ],
        tag_assessment: [
          {
            section: 'Tagging',
            assessment: this.pis_assessment['reg_details'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['reg_details'][0].controls['previous_remarks'].value,
            url:'',
          },
          {
            section: 'Text of Regulation',
            assessment: this.flagged_sections.length > 0 ? 'With Issues' : 'No Issues',
            remarks: this.flagged_sections.value.map((e: any) => {
              return e.remarks;
            }).join(","),
            // https://stackoverflow.com/a/53394597
            previous_remarks: this.flagged_sections.value.map((e: any) => {
              return e.previous_remarks;
            }).reduce((x: any, y: any) => x.concat(y), []),
            url:'',
          },
        ],
        proportionality_assessment: [
          {
            section: 'Significance',
            assessment: this.pis_assessment['reg_significance'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['reg_significance'][0].controls['previous_remarks'].value,
            url:'',
          },
          {
            section: 'Lenses Needed',
            assessment: this.pis_assessment['reg_lenses'][0].controls['assessment'].value,
            previous_remarks: this.pis_assessment['reg_lenses'][0].controls['previous_remarks'].value,
            url:'',
          },
        ],
      };
    }

    checkRemarks(control:any){
      const dialogRef = this.dialog.open(RegulationViewProposedArtaRemarks,{
          disableClose: true,
          height: '80%',
          width: '80%',
          data: {
            section: control.section,
            previous_remarks: control.previous_remarks || [],
            remarks: control.remarks,
            action: false
          },
        }
      );
  
      dialogRef.afterClosed().subscribe({
        next:(result) => {
        },
        error:(err)=>{
          console.error('Error: ',err)
        }
      })
    }
}