import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ArtemisOfficeControlComponent } from '../artemis-office-control/artemis-office-control.component';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import Swal from 'sweetalert2';

export interface OfficeNode {
  name: string;
  type?: string;
  others?: string
  children: OfficeNode[];
}


@Component({
  selector: 'app-artemis-office-model-view',
  templateUrl: './artemis-office-model-view.component.html',
  styleUrls: ['./artemis-office-model-view.component.scss']
})
export class ArtemisOfficeModelViewComponent implements OnInit {

  @Input() node: OfficeNode;
  @Input() index : number;
  @Input() office_id : any;
  @Input() referenceArray : Array<any>;
  @Input() parent: boolean =false
  @Input() leadEncoder: boolean =false
  @Input('isAdmin') isAdmin :boolean =false

  expanded:boolean=true
  viewElement:boolean = false
  
  openedClass: string = 'glyphicon-minus-sign';
  closedClass: string = 'glyphicon-plus-sign';

  officeId:any 

  constructor(
    private dialog:MatDialog,
    private gservice: GovServiceService,
    private afs: AngularFirestore,
    private notif: SnackbarNotifService
  ) {

    
   }

   
 

  ngOnInit(): void {
    
    this.officeId = this.office_id
   
  }

  addNodethisChild(){

    this.dialog.open(ArtemisOfficeControlComponent, {
      data: {
        referenceArray : this.node.children
      }
    }).afterClosed().subscribe((value:any)=>{
      if(value.submitFlag){
        this.node.children.push(value.office)
        this.gservice.officeUpdate.next(this.officeId)
      }
    });
  }

  EditNode(node:any){
    this.dialog.open(ArtemisOfficeControlComponent, {
      data: {
        node: node
      }
    }).afterClosed().subscribe((value:any)=>{
      if(value.submitFlag){

        if(this.parent){

          this.afs.collection('Agency Office Hierarchy').doc(this.officeId)
          .update({

            office: {
            
              name : value.office.name,
              others: value.office?.others || "",
              type: value.office.type,
              children: node.children
  
            
            }
            
          }).then(()=>{
            this.notif.displayNotif("success","Office Updated")
          })


        }else{
          let tempoffice: any  =value.office
          tempoffice.children = node.children
          this.referenceArray[this.index] = tempoffice
          this.gservice.officeUpdate.next(this.officeId)
        }

      }
    })
  }

  removeNode(node:any){


    Swal.fire({
      title: "Remove ?" + node.name,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, remove",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if(this.parent){
          this.afs.collection("Agency Office Hierarchy").doc(this.officeId).delete()
        }else{
        
          this.referenceArray.splice(this.index,1)
          this.gservice.officeUpdate.next(this.officeId)
         
        }
    
      } 
    });

   
    
  }

}
