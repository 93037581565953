<!-- <app-wel-navbar></app-wel-navbar> -->
<app-artemis-navigation-header *ngIf="currentSystem == artemis"></app-artemis-navigation-header>
<app-navbar *ngIf="currentSystem == pbris"></app-navbar>

<div class="content">
    <div class="container-fluid p-3">
        <div class="card">
            <div class="card-head p-3">
                <h3>List of Agencies</h3>
                <!-- <a routerLink="/agency/add" class="btn btn-primary">Add Agency</a> -->
                <button class="btn btn-primary" (click)="openAddAgency()">Add Agency</button>
            </div>
            <div class="card-body form-container">


                <div class="d-flex justify-content-center align-self-center">
                    <div class="spinner-grow" role="status" *ngIf="loader">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <table class="table table-hover table-responsive w-100" *ngIf="!loader">
                    <thead>
                        <tr>
                            <th>Agency Abbreviation</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Contact</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let entry of agencies">
                            <td><a routerLink="/artemis/view/agency/{{entry.id}}"><div class="clickableTh">{{entry.abbreviation}}</div></a></td>
                            <td>{{ entry.name}}</td>
                            <th>{{ entry.address | slice: 0:50 }}</th>
                            <th>{{ entry.contact }}</th>
                            <th>
                                <button mat-icon-button
                                [matMenuTriggerFor]="agencyControl"
                                #menuTrigger 
                                [matMenuTriggerData]="{entry: entry}">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </th>
                        </tr>
                    </tbody>
                    <tbody *ngIf="agencies.length < 1 && !loader">
                        <tr>
                            <td colspan="4">No agencies</td>
                        </tr>
                    </tbody>

                </table>

            </div>
        </div>
    </div>
</div>

<mat-menu #agencyControl="matMenu">
    <ng-template matMenuContent let-entry="entry">
        <button mat-menu-item (click)="goToAgencyMembers(entry.id)">Member List</button>
    </ng-template>
</mat-menu>