import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-ticket-report',
  templateUrl: './artemis-ticket-report.component.html',
  styleUrls: ['./artemis-ticket-report.component.scss']
})
export class ArtemisTicketReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
