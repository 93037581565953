import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PdfRenderService } from 'src/app/pdf-render.service';

@Component({
selector: 'app-public-uploaded-documents-view',
templateUrl: './public-uploaded-documents-view.component.html',
styleUrls: ['./public-uploaded-documents-view.component.scss']
})
// change to uploads
export class PublicUploadedDocumentsViewComponent implements OnInit, OnChanges {

  @Input() uploadDetailsData:any;

  isLoaded:boolean = false;

  chosenUploads:any;

  objectUploads: Array<any> = []
  version:any;

  constructor(
      private store: AngularFireStorage,
      // private pdf_render: PdfRenderService,
  ) { }

  ngOnInit(): void {
    if(this.uploadDetailsData){
      // this.checkUploads(this.uploadDetailsData)
      this.showUploadsData();
    }
    this.isLoaded = true
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.uploadDetailsData.currentValue){
        this.uploadDetailsData = changes.uploadDetailsData.currentValue
        this.showUploadsData();
      }
      this.isLoaded = true
  }

  showUploadsData(){
    if(this.uploadDetailsData.dris) this.chooseVersion(this.uploadDetailsData.dris.controlCode)
    else if(this.uploadDetailsData.cris) this.chooseVersion(this.uploadDetailsData.cris.controlCode)
  }

  chooseVersion(controlCode:any){
    if(controlCode === 'dris'){
      this.version = 'DRIS'
      this.chosenUploads = this.uploadDetailsData.dris.data;
    }
    else if(controlCode === 'cris'){
      this.version = 'CRIS'
      this.chosenUploads = this.uploadDetailsData.cris.data;
    }else{
      this.version = null
      this.chosenUploads = null;
    }
  }

  // checkUploads(upload_folders:any){
  //   // for(let key of Object.keys(upload_folders)){
  //   Object.keys(upload_folders).map(async (key)=>{
  //     let value = upload_folders[key]
  //     // let value2 = value.split("/")
  //     // const fileData:any = await this.refToURL(value+"/"+value2[value2.length-1]) //remove others that value
  //     const fileData:any = await this.refToURL(value) //correct
  //     let name:any = ""
  //     if(key == 'policyObjective') name="Policy Objective"
  //     else if(key == 'policyOptions') name="Policy Options"
  //     else if(key == 'policyProblem') name="Policy Problem"
  //     else if(key == 'implementationEnforcement') name="Implementation Enforcement"
  //     else if(key == 'policyOptionsAssessment') name="Policy Options Assessment"
  //     else if(key == 'monitoringEvaluation') name="Monitoring Evaluation"
  //     else if(key == 'regulatoryImpactStatement') name="Regulatory Impact Statement"
  //     else if(key == 'impactAssessment') name="Impact Assessment"
  //     else if(key == 'consultationDocumentation') name="Consultation Documentation"
  //     else if(key == 'consultation') name="Consultation"
  //     let item:any = {
  //       key: key,
  //       name: name,
  //       value: value,
  //       fileBaseTotalPage: 1,
  //       fileBasePage: 1,
  //       downloadUrl: false,
  //       contentType: false,
  //     }
  //     if(fileData.url){
  //       item.downloadUrl = fileData.url
  //       item.contentType = fileData.metaData.contentType

  //       this.objectUploads.push(item)
  //     }else{
  //       this.objectUploads.push(item)
  //     }
  //   })
  //   // }
  // }

  // refToURL(filePath:string){
  //   return new Promise((res,rej)=>{
  //     this.store.ref(filePath).getDownloadURL()
  //     .subscribe({
  //       next:(data1)=>{
  //         this.store.ref(filePath).getMetadata()
  //         .subscribe({
  //           next:(data2)=>{
  //             let item:any = {
  //               url: data1,
  //               metaData: data2,
  //             }
  //             return res(item)
  //           },
  //           error:(err)=>{
  //             // console.error('refToURL Error: ',err)
  //             return res(false)
  //           }
  //         })
  //       },
  //       error:(err)=>{
  //         // console.error('refToURL Error: ',err)
  //         return res(false)
  //       }
  //     })
  //   })
  // }

  // // file functions
  checkAuthLetterFileType(type:any,fileType:any){
    if(fileType === type) return true
    return false
  }

  pageShift(action:any,ctr:any){
    if(action == "next"){
        if(this.chosenUploads[ctr].fileBasePage < this.chosenUploads[ctr].fileBaseTotalPage) 
          this.chosenUploads[ctr].fileBasePage++;
    }
    if(action == "back"){
        if(this.chosenUploads[ctr].fileBasePage > 1)
          this.chosenUploads[ctr].fileBasePage--;
    }
    
  }

  afterLoadComplete(pdf: PDFDocumentProxy, ctr:any){
    this.chosenUploads[ctr].fileBaseTotalPage = pdf.numPages;
  }
  
  downloadFile(downloadLink:any){
    window.open(downloadLink, '_blank')
  }
  // // !file functions
}
