import { PdfRenderService } from 'src/app/pdf-render.service';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { first } from 'rxjs/operators';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { RegulationViewProposedArtaRemarks } from '../regulation-view-proposed-arta-remarks-modal/regulation-view-proposed-arta-remarks-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'app-pis-assessment-view',
  templateUrl: './pis-assessment-view.component.html',
  styleUrls: ['./pis-assessment-view.component.scss'],
})
export class PisAssessmentViewComponent implements OnInit {
  @Input() id: any;
  @Input() pis_assessment: any;
  @Input() flagged_sections: UntypedFormArray;
  @Input() flaggedSectionIndexes: number[];
  @Input() actionButtonAllowed: boolean;

  currentProposedRegulation: any;
  reg_has_draft_text: boolean = false;
  reg_text: any;

  displayedColumnsFlaggedSections: string[] = ['flaggedSectionName', 'remarks'];
  flagged_sections_datasource: MatTableDataSource<AbstractControl>;

  pis_assessment_section_title: any = {
    'pia_summary': 'Summary of the regulatory proposal',
    'pia_section_1': 'Section 1 - Identification of the policy problem',
    'pia_section_2': 'Section 2 - Objectives of government action',
    'pia_section_3': 'Section 3 - Consideration of alternative options',
    'pia_section_4': 'Section 4 - Impact analysis of policy options',
    'pia_section_5': 'Section 5 - Consultation',
    'pia_section_6': 'Section 6 - Conclusion and recommended option',
    'reg_significance': 'PROPORTIONALITY',
    'reg_lenses': 'Lenses Required',
    'reg_details': 'Uploaded Regulations Details',
  }

  constructor(
    private afs: AngularFirestore,
    private pdf_render: PdfRenderService,
    public dialog: MatDialog,

  ) {}

  ngOnInit(): void {
    this.loadProposedRegulation(this.id);

    this.flagged_sections_datasource = new MatTableDataSource(this.flagged_sections.controls);
  }

  loadProposedRegulation(id: any) {
    this.afs
      .collection('regulations-proposed-phase-02')
      .doc(id)
      .snapshotChanges()
      .pipe(first())
      .subscribe((info) => {
        let item: any = info.payload.data();
        item.id = info.payload.id;

        this.currentProposedRegulation = item;

        if (item.has_draft_text) {
          this.reg_has_draft_text = item.has_draft_text;
        }

        if (item.regulation_text) {
          this.reg_text = item.regulation_text;
        }

        if (
          item.pia_section_1.policy_problem_file_folder &&
          item.pia_section_1.policy_problem_file_name
        ) {
          const path = `${item.pia_section_1.policy_problem_file_folder}/${item.pia_section_1.policy_problem_file_name}`;

          firstValueFrom(this.pdf_render
            .getFileUrl(path))
            .then((data: any) => {
              item.pia_section_1.policy_problem_file = data;
              item.pia_section_1.policy_problem_file_page = 1;
            });
        }

        for (let pia4Ctr in item.pia_section_4) {
          // item.pia_section_4[pia4Ctr]
          // console.log(item.pia_section_4[pia4Ctr])
          if (
            item.pia_section_4[pia4Ctr].compliance_file_name &&
            item.pia_section_4[pia4Ctr].compliance_file_folder
          ) {
            const path =
              item.pia_section_4[pia4Ctr].compliance_file_folder +
              '/' +
              item.pia_section_4[pia4Ctr].compliance_file_name;

            firstValueFrom(this.pdf_render
              .getFileUrl(path))
              .then((data: any) => {
                item.pia_section_4[pia4Ctr].compliance_file = data;
                item.pia_section_4[pia4Ctr].compliance_file_page = 1;
              });
          }
        }
      });
  }

  pageShift(action: any, section: any, index?: any) {
    if (section == 'pia1') {
      if (action == 'next') {
        if (
          this.currentProposedRegulation.pia_section_1
            .policy_problem_file_page <
          this.currentProposedRegulation.pia_section_1
            .policy_problem_file_total_page
        )
          this.currentProposedRegulation.pia_section_1
            .policy_problem_file_page++;
      }
      if (action == 'back') {
        if (
          this.currentProposedRegulation.pia_section_1
            .policy_problem_file_page > 1
        )
          this.currentProposedRegulation.pia_section_1
            .policy_problem_file_page--;
      }
    } else if (section == 'pia4') {
      if (action == 'next') {
        if (
          this.currentProposedRegulation.pia_section_4[index]
            .compliance_file_page <
          this.currentProposedRegulation.pia_section_4[index]
            .compliance_file_total_page
        )
          this.currentProposedRegulation.pia_section_4[index]
            .compliance_file_page++;
      }
      if (action == 'back') {
        if (
          this.currentProposedRegulation.pia_section_4[index]
            .compliance_file_page > 1
        )
          this.currentProposedRegulation.pia_section_4[index]
            .compliance_file_page--;
      }
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy, section: any, index?: any) {
    if (section === 'pia1') {
      this.currentProposedRegulation.pia_section_1.policy_problem_file_total_page =
        pdf.numPages;
    } else if (section === 'pia4') {
      this.currentProposedRegulation.pia_section_4[
        index
      ].compliance_file_total_page = pdf.numPages;
    }
  }

  flagSection(index: number, section_name: string) {
    this.flaggedSectionIndexes.push(index);

    this.flagged_sections.push(
      new UntypedFormGroup({
        flaggedSectionIndex: new UntypedFormControl(index),
        flaggedSectionName: new UntypedFormControl(section_name),
        remarks: new UntypedFormControl('')
      })
    );

    this.flagged_sections_datasource = new MatTableDataSource(this.flagged_sections.controls);
  }
  
  unflagSection(index: number) {
    let toUnflagIndex = this.flaggedSectionIndexes.indexOf(index);
    this.flaggedSectionIndexes.splice(toUnflagIndex, 1);
    this.flagged_sections.removeAt(toUnflagIndex);
    this.flagged_sections_datasource = new MatTableDataSource(this.flagged_sections.controls);
  }

  checkRemarks(control:UntypedFormGroup){
    const dialogRef = this.dialog.open(RegulationViewProposedArtaRemarks,{
        disableClose: true,
        height: '80%',
        width: '80%',
        data: {
          section: control.value.flaggedSectionName,
          previous_remarks: control.value.previous_remarks || [],
          remarks: control.value.remarks,
          action: this.actionButtonAllowed
        },
      }
    );

    dialogRef.afterClosed().subscribe({
      next:(result) => {
        if(this.actionButtonAllowed) control.patchValue({remarks:result.remarks});
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }
}
