import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-artemis-edit-remark-reply',
  templateUrl: './artemis-edit-remark-reply.component.html',
  styleUrls: ['./artemis-edit-remark-reply.component.scss']
})
export class ArtemisEditRemarkReplyComponent implements OnInit {

  form = new UntypedFormGroup({
    message: new UntypedFormControl('',Validators.required)
  })
  temp_obj:any 
  constructor(
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<ArtemisEditRemarkReplyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form.controls['message'].patchValue(data.obj.message);
    this.temp_obj = data.obj;
   }

  ngOnInit(): void {
  }

  editMessage(){
    if(this.form.valid){

      //prepare message to update
      const message:any = {
        message : this.form.value.message.trim(),
        date: new Date()
      }

      //update either remark or reply
      if(this.temp_obj.type == "Remark"){
        this.afs.collection("Service Remarks").doc(this.temp_obj.remark_id)
        .update(message).then(()=>{
          this.dialogRef.close()
        })
      }else{
        this.afs.collection("Service Remarks").doc(this.temp_obj.remark_id)
        .collection("Remark Reply").doc(this.temp_obj.reply_id)
        .update(message).then(()=>{
          this.dialogRef.close()
        })
      }

    }
  }

}
