import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface templateData {
    id: string;
    configCode: number;
    content: string;
    enabled: boolean;
    recipient: string;
    subject: string;
    templateName: string;
    trigger: string;
    variables: Array<variablesData>,
}

export interface variablesData{
    key: string,
    title: string,
    type: string
}


@Component({
    selector: 'app-email-configuration-template',
    templateUrl: './email-configuration-template.component.html',
    styleUrls: ['./email-configuration-template.component.scss']
})
export class EmailConfigurationTemplateDialogComponent implements OnInit {

    typeOptions=[
        'string','number','array','date','link'
    ]

    createTemplateForm = new UntypedFormGroup({
        configCode: new UntypedFormControl(999,[Validators.required,Validators.min(1)]),
        content: new UntypedFormControl(''),
        enabled: new UntypedFormControl(true),
        recipient: new UntypedFormControl(''),
        subject: new UntypedFormControl(''),
        templateName:new UntypedFormControl(''),
        trigger: new UntypedFormControl(''),
        variables: new UntypedFormArray([])
    })

    constructor(
        public dialogRef: MatDialogRef<EmailConfigurationTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: templateData,
        public formBuilder: UntypedFormBuilder,
        private afs: AngularFirestore,
    ){ }

    ngOnInit(): void {

    }

    get variables(){
        return (this.createTemplateForm.controls.variables as UntypedFormArray)
    }

    get variablesFormGroup(){
        return (this.variables.controls as UntypedFormGroup[])
    }

    addNewVariable(){
        this.variables.push(
            new UntypedFormGroup({
                key: new UntypedFormControl(''),
                title: new UntypedFormControl(''),
                others: new UntypedFormControl(''), // for update
                type: new UntypedFormControl(''),
            })
        )
    }

    removeVariable(index:number){
        this.variables.removeAt(index)
    }

    submitCreate(){
        // console.log(this.createTemplateForm.value)
        if(this.createTemplateForm.valid){
            this.afs.collection('Email Configuration').add(this.createTemplateForm.value)
            .then((result)=>{
                console.log('Success created template')
                this.goBack()
            })
            .catch((err)=>{
                console.error('Error: ',err)
            })
        }
    }

    goBack(){
      this.dialogRef.close(this.data)
    }
}