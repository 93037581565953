import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-regulations-search',
  templateUrl: './regulations-search.component.html',
  styleUrls: ['./regulations-search.component.scss']
})
export class RegulationsSearchComponent implements OnInit {
  year: number[] = [];
  getCurrentYear: number = new Date().getFullYear();
  sectors:any[] = [];
  sobs:any[] = [];
  lifeGrouped: any[] = [];
  life:any[] = [];
  sol:any[]=[];
  divisions:any[] = [];
  cases:any[] = [];
  jurisdictions:any[] = [];
  locations:any[] = [];
  documents:any[] = [];
  instruments:any[] = [];
  agencies:any[] = [];
  loader: boolean = true;
  searchQueryJSON: any;

  //Published Date Forms
  
  existing_pub_exactly_form: boolean = false
  existing_pub_inyear_form: boolean = false
  existing_pub_range_form: boolean = false

  proposed_pub_exactly_form: boolean = false
  proposed_pub_inyear_form: boolean = false
  proposed_pub_range_form: boolean = false

  existing_eff_exactly_form: boolean = false
  existing_eff_inyear_form: boolean = false
  existing_eff_range_form: boolean = false

  proposed_eff_exactly_form: boolean = false
  proposed_eff_inyear_form: boolean = false
  proposed_eff_range_form: boolean = false

  existing_amend_exactly_form: boolean = false
  existing_amend_inyear_form: boolean = false
  existing_amend_range_form: boolean = false

  proposed_amend_exactly_form: boolean = false
  proposed_amend_inyear_form: boolean = false
  proposed_amend_range_form: boolean = false

  // searchform: FormGroup = new FormGroup({
  //   keyword: new FormControl('', Validators.required),
  //   effectivity_status: new FormControl('', Validators.required),
  //   publication: new FormControl('', Validators.required),
  //   effectivity: new FormControl('', Validators.required),
  //   amend: new FormControl('', Validators.required),
  //  });

  //current theme
  style_theme: any = 'theme-default'; 

   searchform: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl(''),
    //radio
    classification: new UntypedFormControl(''),

    stageoflife: new UntypedFormControl(''),
    lifeevent: new UntypedFormControl(''),

    //selects
    sector: new UntypedFormControl(''),
    division: new UntypedFormControl(''),
    stageofbusiness: new UntypedFormControl(''),
    caseuse: new UntypedFormControl(''),
    jurisdiction: new UntypedFormControl(''),
    agencylocation: new UntypedFormControl(''),

    //radio and dates
    effectivity_status: new UntypedFormControl(''),

    publication: new UntypedFormControl(''), //mode
    publicationexact: new UntypedFormControl(''),
    publicationyear: new UntypedFormControl(''),
    publicationstartdate: new UntypedFormControl(''),
    publicationenddate: new UntypedFormControl(''),

    effectivity: new UntypedFormControl(''), //mode
    effectivityexact: new UntypedFormControl(''),
    effectivityyear: new UntypedFormControl(''),
    effectivitystartdate: new UntypedFormControl(''),
    effectivityenddate: new UntypedFormControl(''),
    
    amend: new UntypedFormControl(''), //mode
    amendexact: new UntypedFormControl(''),
    amendyear: new UntypedFormControl(''),
    amendstartdate: new UntypedFormControl(''),
    amendenddate: new UntypedFormControl(''),

    regulationid: new UntypedFormControl(''),
    agencyorlocation: new UntypedFormControl(''),
    document: new UntypedFormControl(''),
    instrument: new UntypedFormControl(''),
    agency: new UntypedFormControl(''),
    agencyaddress: new UntypedFormControl(''),
    significance: new UntypedFormControl(''),
   });

  constructor(private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router) {
      route.queryParams.subscribe(params => {
        this.searchQueryJSON = params;
      })
    }

  ngOnInit(): void {
    this.loadData('sect','Sector')
    this.loadData('sob','Stage of Business')
    this.loadData('sol','Stage of Life')
    this.loadData('life','Life Event')
    this.loadData('div','Division')
    this.loadData('juris','Jurisdiction')
    this.loadData('case','Case Use')
    this.loadData('loc','Agency Location')
    this.loadData('doc','Document')
    this.loadData('instru','Instrument')
    this.loadData('agency','Agency')
    // this.loadSectors()
    // this.loadSobs()
    // this.loadDivisions()
    // this.loadCases()
    // this.loadJuris()
    // this.loadLocations()
    // this.loadDocuments()
    // this.loadInstruments()

    this.setTheme();

    for(let i = this.getCurrentYear; i >= 1970; i--){
      this.year.push(i);
    }

    let JSONfields = {
      title: ""
    }

    this.route.queryParams.subscribe(params => {
      
      this.searchform.patchValue({
        keyword: params.keyword
      })
      
    })
  }

  async setTheme() {
    switch (sessionStorage.getItem("user_type")) {
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  detectPublication(status:any,event:any){
    const pub_query = event.target.value

    if(pub_query === "Exactly on"){
      if(status == 'existing'){
        this.existing_pub_exactly_form = true
        this.existing_pub_inyear_form = false
        this.existing_pub_range_form = false
      }
      else if(status == 'proposed'){
        this.proposed_pub_exactly_form = true
        this.proposed_pub_inyear_form = false
        this.proposed_pub_range_form = false
      }
    }

    if(pub_query === "In Year"){
      if(status == 'existing'){
        this.existing_pub_exactly_form = false
        this.existing_pub_inyear_form = true
        this.existing_pub_range_form = false
      }
      else if(status == 'proposed'){
        this.proposed_pub_exactly_form = false
        this.proposed_pub_inyear_form = true
        this.proposed_pub_range_form = false
      }
    }

    if(pub_query === "Range"){
      if(status == 'existing'){
        this.existing_pub_exactly_form = false
        this.existing_pub_inyear_form = false
        this.existing_pub_range_form = true
      }
      else if(status == 'proposed'){
        this.proposed_pub_exactly_form = false
        this.proposed_pub_inyear_form = false
        this.proposed_pub_range_form = true
      }
    }

  }

  detectEffectivity(status:any,event:any){
    const eff_query = event.target.value

    if(eff_query === "Exactly on"){
      if(status == 'existing'){
        this.existing_eff_exactly_form = true
        this.existing_eff_inyear_form = false
        this.existing_eff_range_form = false
      }
      else if(status == 'proposed'){
        this.proposed_eff_exactly_form = true
        this.proposed_eff_inyear_form = false
        this.proposed_eff_range_form = false
      }
    }

    if(eff_query === "In Year"){
      if(status == 'existing'){
        this.existing_eff_exactly_form = false
        this.existing_eff_inyear_form = true
        this.existing_eff_range_form = false
      }
      else if(status == 'proposed'){
        this.proposed_eff_exactly_form = false
        this.proposed_eff_inyear_form = true
        this.proposed_eff_range_form = false
      }
    }

    if(eff_query === "Range"){
      if(status == 'existing'){
        this.existing_eff_exactly_form = false
        this.existing_eff_inyear_form = false
        this.existing_eff_range_form = true
      }
      else if(status == 'proposed'){
        this.proposed_eff_exactly_form = false
        this.proposed_eff_inyear_form = false
        this.proposed_eff_range_form = true
      }
    }

  }

  detectAmend(status:any,event:any){
    const amend_query = event.target.value

    if(amend_query === "Exactly on"){
      if(status == 'existing'){
        this.existing_amend_exactly_form = true
        this.existing_amend_inyear_form = false
        this.existing_amend_range_form = false
      }
      else if(status == 'proposed'){
        this.proposed_amend_exactly_form = true
        this.proposed_amend_inyear_form = false
        this.proposed_amend_range_form = false
      }
    }

    if(amend_query === "In Year"){
      if(status == 'existing'){
        this.existing_amend_exactly_form = false
        this.existing_amend_inyear_form = true
        this.existing_amend_range_form = false
      }
      else if(status == 'proposed'){
        this.proposed_amend_exactly_form = false
        this.proposed_amend_inyear_form = true
        this.proposed_amend_range_form = false
      }
    }

    if(amend_query === "Range"){
      if(status == 'existing'){
        this.existing_amend_exactly_form = false
        this.existing_amend_inyear_form = false
        this.existing_amend_range_form = true
      }
      else if(status == 'proposed'){
        this.proposed_amend_exactly_form = false
        this.proposed_amend_inyear_form = false
        this.proposed_amend_range_form = true
      }
    }

  }

  selectChange(loadCode:any,value:any){
    if(loadCode == 'class'){
      this.lifeGrouped = []
      if(this.searchform.value.classification == 'Business'){
        this.searchform.patchValue({
          stageoflife:'',
          lifeevent:'',
        })
      }
      else if(this.searchform.value.classification == 'Non-business'){
        this.searchform.patchValue({
          sector:'',
          division:'',
        })
      }
      else{
        this.searchform.patchValue({
          sector:'',
          division:'',
          stageoflife:'',
          lifeevent:'',
        })
      }
    }
    else if(loadCode == 'sect'){
      this.searchform.patchValue({
        sector:value
      })
    }
    else if(loadCode == 'div'){
      this.searchform.patchValue({
        division:value
      })
    }
    else if(loadCode == 'sol'){
      this.solToLifeevent(value)
      this.searchform.patchValue({
        stageoflife:value
      })
    }
    else if(loadCode == 'life'){
      this.searchform.patchValue({
        lifeevent:value
      })
    }

    else if(loadCode == 'sob'){
      this.searchform.patchValue({
        stageofbusiness:value
      })
    }
    else if(loadCode == 'case'){
      this.searchform.patchValue({
        caseuse:value
      })
    }
    else if(loadCode == 'juris'){
      this.searchform.patchValue({
        jurisdiction:value
      })
    }
    else if(loadCode == 'loc'){
      this.searchform.patchValue({
        agencylocation:value
      })
    }
    else if(loadCode == 'publicationdate'){
      this.searchform.patchValue({
        publicationexact:'',
        publicationyear:'',
        publicationstartdate:'',
        publicationenddate:'',
      })
    }
    else if(loadCode == 'effectivitydate'){
      this.searchform.patchValue({
        effectivityexact:'',
        effectivityyear:'',
        effectivitystartdate:'',
        effectivityenddate:'',
      })
    }
    else if(loadCode == 'amenddate'){
      this.searchform.patchValue({
        amendexact:'',
        amendyear:'',
        amendstartdate:'',
        amendenddate:'',
      })
    }
    else if(loadCode == 'pubyear'){
      this.searchform.patchValue({
        publicationyear:value
      })
    }
    else if(loadCode == 'effyear'){
      this.searchform.patchValue({
        effectivityyear:value
      })
    }
    else if(loadCode == 'amdyear'){
      this.searchform.patchValue({
        amendyear:value
      })
    }
    else if(loadCode == 'doc'){
      this.searchform.patchValue({
        document:value
      })
    }
    else if(loadCode == 'instru'){
      this.searchform.patchValue({
        instrument:value
      })
    }
    else if(loadCode == 'agency'){
      console.log(value)
      this.searchform.patchValue({
        agency:value
      })
    }
  }

  solToLifeevent(value:any){
    // const codes:any[] = this.life.filter(result=>(result.code == value))
    // if(codes.length > 0 && value == codes[0].code)
    //   return codes[0].event
    // else
    //   return ""

    this.lifeGrouped = []
    this.life.map((result:any)=>{
      if(result.stage == value) {
        if(!this.lifeGrouped.includes(result.event))
          this.lifeGrouped.push(result.event)
      }
    })
  }

  resetData(){
    this.searchform.patchValue({
      keyword: '',
      //radio
      classification:'',
      stageoflife:'',
      lifeevent:'',
      //selects
      sector:'',
      division:'',
      stageofbusiness:'',
      caseuse:'',
      jurisdiction:'',
      agencylocation:'',
  
      //radio and dates
      effectivity_status:'',
  
      publication:'', //mode
      publicationexact:'',
      publicationyear:'',
      publicationstartdate:'',
      publicationenddate:'',
  
      effectivity:'', //mode
      effectivityexact:'',
      effectivityyear:'',
      effectivitystartdate:'',
      effectivityenddate:'',
      
      amend:'', //mode
      amendexact:'',
      amendyear:'',
      amendstartdate:'',
      amendenddate:'',
  
      regulationid:'',
      agencyorlocation:'',
      document:'',
      instrument:'',
      agency:'',
      agencyaddress:'',
      significance:'',
     });

    this.existing_pub_exactly_form = false
    this.existing_pub_inyear_form = false
    this.existing_pub_range_form = false
    this.proposed_pub_exactly_form = false
    this.proposed_pub_inyear_form = false
    this.proposed_pub_range_form = false
    this.existing_eff_exactly_form = false
    this.existing_eff_inyear_form = false
    this.existing_eff_range_form = false
    this.proposed_eff_exactly_form = false
    this.proposed_eff_inyear_form = false
    this.proposed_eff_range_form = false
    this.existing_amend_exactly_form = false
    this.existing_amend_inyear_form = false
    this.existing_amend_range_form = false
    this.proposed_amend_exactly_form = false
    this.proposed_amend_inyear_form = false
    this.proposed_amend_range_form = false
  }

  // loadSectors(){
  //   this.afs.collection(`Sector`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.sectors = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.sectors.includes(item) === false){
  //             this.sectors.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadSobs(){
  //   this.afs.collection(`Stage of Business`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.sobs = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.sobs.includes(item) === false){
  //             this.sobs.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadDivisions(){
  //   this.afs.collection(`Division`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.divisions = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.divisions.includes(item) === false){
  //             this.divisions.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadJuris(){
  //   this.afs.collection(`Jurisdiction`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.jurisdictions = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.jurisdictions.includes(item) === false){
  //             this.jurisdictions.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadCases(){
  //   this.afs.collection(`Case Use`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.cases = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.cases.includes(item) === false){
  //             this.cases.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadLocations(){
  //   this.afs.collection(`Agency Location`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.locations = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.locations.includes(item) === false){
  //             this.locations.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadDocuments(){
  //   this.afs.collection(`Document`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.documents = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.documents.includes(item) === false){
  //             this.documents.push(item);
  //           }     
  //         });
  //     });
  // }

  // loadInstruments(){
  //   this.afs.collection(`Instrument`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
  //     (data: any) => {
  //       this.instruments = []
  //       this.loader = false;
  //         data.forEach((info: any) => {
            
  //           let item: any = info.payload.doc.data();
  //           item.id = info.payload.doc.id;
  
  //           if (this.instruments.includes(item) === false){
  //             this.instruments.push(item);
  //           }     
  //         });
  //     });
  // }

  loadData(loadCode:any,collection:any){
    this.afs.collection(collection, filter=>this.filterFunc(loadCode,filter)).snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      let tempArr:any = []
      info.forEach(data=>{
        let item:any = data.payload.doc.data()
        item.id = data.payload.doc.id
        if(!tempArr.includes(item))
          tempArr.push(item)
      })
      
      if(loadCode == 'sect'){
        this.sectors = tempArr
      }
      else if(loadCode == 'sob'){
        this.sobs = tempArr
      }
      else if(loadCode == 'sol'){
        this.sol = tempArr
      }
      else if(loadCode == 'life'){
        this.life = tempArr
        // this.getUniqueSoLEvents(tempArr)
      }
      else if(loadCode == 'div'){
        this.divisions = tempArr
      }
      else if(loadCode == 'juris'){
        this.jurisdictions = tempArr
      }
      else if(loadCode == 'case'){
        this.cases = tempArr
      }
      else if(loadCode == 'loc'){
        this.locations = tempArr
      }
      else if(loadCode == 'doc'){
        this.documents = tempArr
      }
      else if(loadCode == 'instru'){
        this.instruments = tempArr
      }
      else if(loadCode == 'agency'){
        this.agencies = tempArr
      }
      
      this.loader = false;
    })
  }

  // getUniqueSoLEvents(lifeevents:any){
  //   lifeevents.map((data:any)=>{
  //     if (!this.lifeUnique.includes(data.stage)) this.lifeUnique.push(data.stage); // slice(-1)[0] means last item in array without removing it (like .pop())
  //   });
  //   console.log("lifeUnique",this.lifeUnique)
  // }

  filterFunc(loadCode:any, filter:any){
    if(loadCode == 'sect' || loadCode == 'div' || loadCode == 'loc' || loadCode == 'case')
      return filter.orderBy('name');
    else if(loadCode == 'life' ||  loadCode == 'sol') return filter
    else if(loadCode == 'agency') return filter.orderBy('name')
      // return filter.orderBy('event')
    return filter.orderBy('section')
  }

  search(status: any){
    // let JSONarray = {
    //   keyword: this.searchform.value.keyword,
    // };
    let JSONarray:any = this.searchform.value
    JSONarray.status = status
    console.log(JSONarray)
    
    this.router.navigate([`/pbris/regulations/${status}`], {queryParams: JSONarray});  // define your component where you want to go
  }
}
