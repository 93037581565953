import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GovernmentAccountSubtype } from 'src/app/entities/worklist';

@Component({
  selector: 'app-pbris-quick-search',
  templateUrl: './pbris-quick-search.component.html',
  styleUrls: ['./pbris-quick-search.component.scss']
})
export class PbrisQuickSearchComponent implements OnInit {
  style_theme: any = 'theme-default';
  userType: any = sessionStorage.getItem("user_type");

  isLoggedIn: boolean = false;
  searchform: UntypedFormGroup = new UntypedFormGroup({
    searchfield: new UntypedFormControl('', Validators.required),
  });

  constructor(public auth: AngularFireAuth, private afs: AngularFirestore, private router: Router) { }

  ngOnInit(): void {
    this.checkSessionVariables();
    this.setTheme();
  }

  search(){
    //
    let JSONarray = {
      keyword: this.searchform.value.searchfield,
    };
    
    this.router.navigate(['/pbris/regulations/'], {queryParams: JSONarray});  // define your component where you want to go
    }

    async checkSessionVariables(){
      /**
       * checks if user is logged in
       */
      if(await this.auth.user){
        
        if(sessionStorage.getItem("user_type")) {
          this.setTheme();
        }
        else {
          /**
           * for reloading user types {default, agency, arta}
           */
          this.auth.onAuthStateChanged(user => {
            if(user?.uid){
              // alert(user?.uid);
              // set logged in status
              this.isLoggedIn = true;
              // get account type from firestore
              this.afs.doc(`Users/${user.uid}`).snapshotChanges().subscribe(
                (data: any) => {
                    let item = data.payload.data()
          
                    if(item.credentials.account_subtype.includes("ARTA")) {
                      sessionStorage.setItem("user_type","arta");
  
                      switch(item.credentials.account_subtype){
                        case GovernmentAccountSubtype.ARTA_ADMIN:{
                          sessionStorage.setItem("arta_admin", "true");
                          break;
                        }
                        case GovernmentAccountSubtype.AGENCY_ADMIN:{
                          sessionStorage.setItem("agency_admin", "true");
                          break;
                        }
                      }
                      // if(item.credentials.account_subtype.toLowerCase().includes("admin")) {
                      //   sessionStorage.setItem("arta_admin", "true");
                      // }
                    }
                    else if(item.credentials.account_type.includes("Government") || item.credentials.account_type.includes("Company")) {
                      sessionStorage.setItem("user_type","agency");
                      switch(item.credentials.account_subtype){
                        case GovernmentAccountSubtype.AGENCY_ADMIN:{
                          sessionStorage.setItem("agency_admin", "true");
                          break;
                        }
                      }
                    }
                    else {
                      sessionStorage.setItem("user_type","personal");
                    }
                    this.setTheme();
                    window.location.reload();
                });
            }
            else {
              sessionStorage.setItem("user_type","default");
            }
          });
        }
        //
      }
    }
  
    async setTheme() {
      switch(sessionStorage.getItem("user_type")){
        case 'arta':
          this.style_theme = 'theme-arta';
          this.isLoggedIn = true;
          break;
        case 'agency':
          this.style_theme = 'theme-agency';
          this.isLoggedIn = true;
          break;
        case 'personal':
          this.style_theme = 'theme-default';
          this.isLoggedIn = true;
          break;
        default:
          this.style_theme = 'theme-default';
        }
      }
     

}
