<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Survey'"></app-artemis-navigation-header>

<div class="container">
  <div class="card shadow my-3">
      <div class="card-header text-primary theme-default">
        <h2 class="fw-bold">How was your experience?</h2>
        <h5>Send your feedback</h5>
      </div>
      <div class="card-body">
        <div class="wrapper text-center text-muted p-4">
            <p style="margin: auto ; width: 80%;" class="note note-primary">Thank you for using the ARTEMIS! We are constantly working to improve our services. Please help us by sharing your overall experience in using the site. Your feedback will be anonymous and be processed in accordance with the Data Privacy Act of 2012. </p>
        </div>
        <div class="container" style="width: 80%;">
          <div class="col-md-12 gy-4 mt-2">
            <form [formGroup]="phase1form" autocomplete="off">
              <div class="row"><!--first row dropdown-->
                <input type="hidden" formControlName="application" name="application">
                <div class="col-md-6 p-4 gx-2">
                  <label class="row mb-2 font-weight-bold form-label" for="feature_accessed">Feature Accessed *</label>
                  <mat-form-field appearance="outline">
                    <mat-label>Option</mat-label>
                    <mat-select  class="" aria-label="Default select example" formControlName="feature" >
                      <mat-option *ngFor="let featureObj of featureAccessList" [value]="featureObj.feature">
                        {{ featureObj.feature }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6 p-4 gx-2 ">
                    <label class="font-weight-bold form-label" for="overall_field">Please rate your overall experience *</label>
                    <fieldset class="rating pb-2" for="overall" id="overall_field">
                      <input type="radio" id="star5" formControlName="overall" name="overall" value="Excellent" (change)="starOnChange($event)"/><label for="star5" title="Excellent" style="font-size: 300%">  5 stars</label>
                      <input type="radio" id="star4" formControlName="overall" name="overall" value="Very satisfy" (change)="starOnChange($event)"/><label for="star4" title="Very satisfy" style="font-size: 300%">  4 stars</label>
                      <input type="radio" id="star3" formControlName="overall" name="overall" value="Satisfy" (change)="starOnChange($event)"/><label for="star3" title="Satisfy" style="font-size: 300%">  3 stars</label>
                      <input type="radio" id="star2" formControlName="overall" name="overall" value="Very good" (change)="starOnChange($event)"/><label for="star2" title="Very good" style="font-size: 300%">  2 stars</label>
                      <input type="radio" id="star1" formControlName="overall" name="overall" value="Good" (change)="starOnChange($event)"/><label for="star1" title="Good" style="font-size: 300%">  1 star</label>
                    </fieldset>
                    <div class="row gy-0 mb-2 font-italic text-muted d-flex justify-content-center ">
                      {{ overall_rate }}
                    </div>
                </div>
              </div>
            
              <div class="row pb-4 border-1"><!--2ndrow hidden-->
                  <div class="container" style="width: 90%;">
                    <div class="" *ngIf="commentView == 1"><!--one star-->
                      <label for="elaborateFormControlTextarea1" class="form-label mb-2 font-weight-bold">
                        Please elaborate on your experience
                      </label>
                      <mat-form-field appearance="outline">
                        <mat-label>Comments</mat-label>
                        <textarea matInput class="" cols="50"  formControlName="elaborate" id="elaborateFormControlTextarea1" rows="2" ></textarea>
                      </mat-form-field>
                    </div>
                    <div class="" *ngIf="commentView == 2"><!--two star-->
                      <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
                        My experience was satisfactory but could be further improved by:
                      </label>
                      <mat-form-field appearance="outline">
                        <mat-label>Comments</mat-label>
                        <textarea matInput class="" cols="50" formControlName="improve" id="improveFormControlTextarea1" rows="2" ></textarea>
                      </mat-form-field>
                    </div>
                    <div class=""*ngIf="commentView == 3"><!--three star-->
                      <label class="form-check-label font-weight-bold text-muted" for="list">
                        My experience was unsatisfactory as the ARTEMIS
                      </label>
                      <div class="row col-md-12" id="list">
                        <div class="col-12 col-md-6 mt-2">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test2" value="Did not have the information I need">
                            <label class="form-check-label font-weight-bold text-muted mx-2" for="test2">
                              Did not have the information I need
                            </label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 mt-2">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test3" value="Navigation was too confusing">
                            <label class="form-check-label font-weight-bold text-muted mx-2" for="test3">
                              Navigation was too confusing
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row col-md-12">
                        <div class="col-12 col-md-6 mt-2">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test4" value="Had too much information">
                            <label class="form-check-label font-weight-bold text-muted mx-2" for="test4">
                              Had too much information
                            </label>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 mt-2">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test5" value="Service/s is/are out of date">
                            <label class="form-check-label font-weight-bold text-muted mx-2" for="test5">
                              Service/s is/are out of date
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row col-md-12 ">
                        <div class="col-12 col-md-8 mt-2">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="experience" name="experience" id="test6" value="Other">
                            <label class="form-check-label font-weight-bold text-muted mx-2" for="test6">
                              Other
                            </label>
                              <mat-form-field appearance="outline">
                                <mat-label>Comments</mat-label>
                                <textarea matInput class="" cols="50" formControlName="others" id="elaborateFormControlTextarea1" rows="2" ></textarea>
                              </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row pb-4"><!--3rdrow comment-->
                <label for="improveFormControlTextarea1" class="form-label mb-2 font-weight-bold">
                  My experience could be further improved by: *
                </label>
                <mat-form-field appearance="outline">
                  <mat-label>Comments</mat-label>
                  <textarea matInput class="" cols="50" formControlName="enhance" id="improveFormControlTextarea1" rows="2" placeholder="Comments"></textarea>
                </mat-form-field>
              </div>
              <div class="row pt-4"><!--Row 4 Star rating with dropdown-->
                <div class="col-12">
                  <div class="row g-0">
                    <div class="col-md-8 g-0">
                      <label for="improveFormControlTextarea1" class="form-label font-weight-bold mt-2">
                        How likely are you to visit the site again? *
                      </label>  
                    </div>
                    <div class="col-md p-0 g-0 ">
                      <fieldset class="rating d-inline-block pe-2 " style="float: none;">
                        <input type="radio" id="star55" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Excellent" /><label for="star55" title="Excellent">  % stars</label>
                        <input type="radio" id="star44" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Very satisfy" /><label for="star44" title="Very satisfy">  4 stars</label>
                        <input type="radio" id="star33" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Satisfy" /><label for="star33" title="Satisfy">  3 stars</label>
                        <input type="radio" id="star22" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Very good" /><label for="star22" title="Very good">  2 stars</label>
                        <input type="radio" id="star11" name="visit" formControlName="visit" (change)="starOnChange($event)" value="Good" /><label for="star11" title="Good">  1 star</label>
                      </fieldset>
                      <div class="mb-2 font-italic text-muted d-inline  d-flex justify-content-center ">
                        {{ visit_site }}
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 pt-4">
                    <div class="col-md-8 g-0">
                      <label for="improveFormControlTextarea1" class="form-label font-weight-bold mt-2">
                        How likely are you going to refer the ARTEMIS to a friend? *
                      </label>
                    </div> 
                    <div class="col-md p-0 g-0 ">   
                      <fieldset class="rating d-inline-block pe-2" style="float: none;">
                        <input type="radio" id="star555" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Excellent" /><label for="star555" title="Excellent">  5 stars</label>
                        <input type="radio" id="star444" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Very satisfy" /><label for="star444" title="Very satisfy">  4 stars</label>
                        <input type="radio" id="star222" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Satisfy" /><label for="star222" title="Satisfy">  2 stars</label>
                        <input type="radio" id="star333" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Very good" /><label for="star333" title="Very good">  3 stars</label>
                        <input type="radio" id="star111" name="refer" formControlName="refer" (change)="starOnChange($event)" value="Good" /><label for="star111" title="Good">  1 star</label>
                      </fieldset>
                      <div class="mb-2 font-italic text-muted d-inline d-flex justify-content-center">
                      {{ refer_site }}
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 pt-4">
                    <div class="col-md-8 g-0">
                      <label for="discoverFormControlTextarea1" class="form-label font-weight-bold mt-2">
                        How did you discover ARTEMIS?    *
                      </label>
                    </div>
                    <div class="col-md p-0 g-0 ">
                      <div class="" id="discoverFormControlTextarea1">
                        <mat-form-field appearance="outline">
                          <mat-label>Option</mat-label>
                          <mat-select formControlName="discover" name="discover"  (selectionChange)="discoverOnChange($event)">
                            <mat-option value="Friends">Friends</mat-option>
                            <mat-option value="Internet">Internet</mat-option>
                            <mat-option value="Newspaper">Newspaper</mat-option>
                            <mat-option value="Others">Others</mat-option>
                          </mat-select>
                        </mat-form-field>
                       <!-- <mat-form-field appearance="outline" >
                          <select matNativeControl class="form-select" aria-label="Default select example" formControlName="discover" name="discover" (change)="discoverOnChange($event)">
                            <option value="Friends">Friends</option>
                            <option value="Internet">Internet</option>
                            <option value="Newspaper">Newspaper</option>
                            <option value="Others">Others</option>
                          </select>
                        </mat-form-field>-->
                      </div>
                      <div class="mt-1" *ngIf="others">
                        <mat-form-field appearance="outline">
                          <mat-label>Comments</mat-label>
                          <textarea matInput class="" formControlName="otherDiscover" id="elaborateFormControlTextarea1" rows="2" placeholder="Others"></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="my-5 text-center">
                  <button type="button" mat-flat-button color="primary" class=" col-12 col-md-4 p-3" (click)="onSubmit()" style="letter-spacing: 2px;" [disabled]="loading">
                    <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                    <strong *ngIf="loading == false">Submit</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>
</div>


