<div class = "btn-group float-end m-3">
    <button class = "btn btn-outline-dark" (click) = "activatePhase(1)" [disabled] = "phase1">Phase 1</button>
    <button class = "btn btn-outline-dark" (click) = "activatePhase(2)" [disabled] = "phase2">Phase 2</button>
</div>

<div class="mx-auto">

    
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - EXISTING CITIZEN'S STATISTICS ({{phase}})</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                   

                </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">TOTAL COUNT:</td>
                <td>{{cc_count.length}}</td>
            </tr>
            <tr>
                <td class = "table-warning">COMPLIANCE RATE:</td>
                <td></td>
            </tr>
            <tr>
                <td class = "table-warning">PENDING (Encoded but not yet submitted):</td>
                <td></td>
            </tr>
          <tr>
          </table>
          
                
                   <!-- Count per Status -->

                   <table class = "table table-bordered mb-0">
                    
                    <thead class = "table table-light">
                        <tr>
                            <th colspan = "2">COUNT PER STATUS</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr>
                            <td>DRAFT:</td>
                            <td>{{draft_count.length}}</td>
                        </tr>
                        <tr>
                            <td>FOR VALIDATION:</td>
                            <td>{{validation_count.length}}</td>
                        </tr>
                        <tr>
                            <td>FOR ARTA REVIEW:</td>
                            <td>{{arta_review_count.length}}</td>
                        </tr>
                        <tr>
                            <td>FOR MODIFICATION:</td>
                            <td>{{modification_count.length}}</td>
                        </tr>
                        
                    </tbody>
                    
               </table>

               <!-- Count of Multi-stage Processing Applications -->

               <table class = "table table-bordered mb-0">
                    
                <thead class = "table table-light">
                    <tr>
                        <th colspan = "2">COUNT OF MULTI-STAGE PROCESSING APPLICATIONS</th>
                    </tr>
                    
                </thead>
                <tbody>
                    <tr>
                        <td>PENDING MULTI-STAGE APPLICATIONS</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>WITH APPROVED MULTI-STAGE APPLICATIONS</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>NO MULTI-STAGE APPLICATIONS</td>
                        <td></td>
                    </tr>
                    
                </tbody>
                
           </table>

           <!-- COUNT BY AGENCY CATEGORY -->

           <table class = "table table-bordered mb-0">
                    
            <thead class = "table table-light">
                <tr>
                    <th colspan = "3">COUNT BY AGENCY CATEGORY</th>
                </tr>
                
            </thead>
            <tbody>
                <tr>
                    <td  class = "w-50">National Government Agency</td>
                    <td class = "w-25">{{nat_government_count.length}}</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td  class = "w-50">Government-Owned or -Controlled Corporation</td>
                    <td class = "w-25">{{gocc_count.length}}</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td>Local Government Units:</td>
                    <td class = "w-25">{{local_gov_count.length}}</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td class = "text-end">Province:</td>
                    <td class = "w-25">#</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td class = "text-end">City:</td>
                    <td class = "w-25">#</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td class = "text-end">Municipality:</td>
                    <td class = "w-25">#</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td class = "text-end">Barangay:</td>
                    <td class = "w-25">#</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td>State University and College:</td>
                    <td class = "w-25">{{stateuni_college_count.length}}</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td>Water District:</td>
                    <td class = "w-25">{{water_district_count.length}}</td>
                    <td class = "w-25">%</td>
                </tr>
                <tr>
                    <td>Hospital:</td>
                    <td class = "w-25">{{hospitals_count.length}}</td>
                    <td class = "w-25">%</td>
                </tr>
                
            </tbody>
            
       </table>

       <!-- COUNT BY AGENCY CATEGORY -->

       <table class = "table table-bordered mb-0">
                    
        <thead class = "table table-light">
            <tr>
                <th colspan = "2">COUNT PER DATE OF SUBMISSION</th>
            </tr>
            
        </thead>
        <tbody>
            <tr>
                <td>On-Time</td>
                <td class = "w-25">#</td>
                <td class = "w-25">%</td>
            </tr>
            <tr>
                <td>Late</td>
                <td class = "w-25">#</td>
                <td class = "w-25">%</td>
            </tr>
        </tbody>
        
   </table>

               
               
             
</div>