<div [ngSwitch]="mode">
  <div class="container" *ngSwitchCase="'resetPassword'">
    <div [ngSwitch]="resetPasswordStatusFlag">
      <!-- Password reset form -->
      <div class="row" *ngSwitchCase="0">
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Input password for: {{ emailAddress }}</h5>
              <form [formGroup]="resetpasswordform">
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    formControlName="password"
                    ng-required="true"
                    #password
                  />
                  <div
                    class="form-helper text-danger"
                    *ngIf="
                      showFormErrors &&
                      resetpasswordform.controls.password.hasError('required')
                    "
                  >
                    Password is required
                  </div>
                  <div
                    class="form-helper text-danger"
                    *ngIf="
                      showFormErrors &&
                      resetpasswordform.controls.passwordStrength.hasError(
                        'needsStrongerPassword'
                      )
                    "
                  >
                    Please make a stronger password
                  </div>

                  <mat-password-strength
                    #passwordComponent
                    (onStrengthChanged)="onStrengthChanged($event)"
                    [password]="password.value"
                  >
                  </mat-password-strength>

                  <div class="my-3">
                    <mat-password-strength-info
                      [passwordComponent]="passwordComponent"
                    >
                    </mat-password-strength-info>
                  </div>

                  <div class="form-group">
                    <label for="confirmpassword">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Re-enter Password"
                      formControlName="confirmpassword"
                      ng-required="true"
                    />
                    <div
                      class="form-helper text-danger"
                      *ngIf="
                        showFormErrors &&
                        (resetpasswordform.controls.confirmpassword.hasError(
                          'required'
                        ) ||
                        resetpasswordform.controls.confirmpassword.hasError(
                            'matchingPassword'
                          ))
                      "
                    >
                      Password does not match
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button
                      *ngIf="!successFlag"
                      (click)="resetPassword()"
                      class="btn btn-primary col m-1"
                    >
                      Save
                    </button>
                    <button
                      *ngIf="successFlag"
                      (click)="proceedToSite()"
                      class="btn btn-primary col m-1"
                    >
                      Proceed to site
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Success -->
      <div class="row" *ngSwitchCase="1">
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Password changed.</h5>
              <p class="card-text">
                You can now sign in with your new password.
              </p>
              <div class="d-flex justify-content-end">
                <button
                  *ngIf="continueUrl !== ''"
                  type="button"
                  class="btn btn-primary"
                  (click)="proceedToSite()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div class="row" *ngSwitchCase="2">
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Password failed to reset.</h5>
              <p class="card-text">
                Please return to your application's 'Forgot Password' page and
                try again.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Empty loading -->
      <div class="row" *ngSwitchDefault>
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <div class="card-text row">
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngSwitchCase="'verifyEmail'">
    <div [ngSwitch]="verifyStatusFlag">
      <!-- Success -->
      <div class="row" *ngSwitchCase="1">
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Your email has been verified.</h5>
              <p class="card-text">
                You can now sign in with your new account.
              </p>
              <div class="d-flex justify-content-end">
                <button
                  *ngIf="continueUrl !== ''"
                  type="button"
                  class="btn btn-primary"
                  (click)="proceedToSite()"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Error -->
      <div class="row" *ngSwitchCase="2">
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Email verification failed.</h5>
              <p class="card-text">
                Please request for email verification through your application's
                login page and try again.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Processing -->
      <div class="row" *ngSwitchDefault>
        <div class="d-flex justify-content-center">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Verifying email. Please wait...</h5>
              <div class="card-text row">
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"
                      >Verifying email. Please wait...</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngSwitchDefault>
    <div class="row">
      <div class="d-flex justify-content-center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Invalid access.</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
