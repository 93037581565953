import { Injectable } from '@angular/core';
import { RemarkResolvedStatusCitizensCharter } from '../entities/worklist';
import { Timestamp } from 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SnackbarNotifService } from './snackbar-notif.service';

@Injectable({
  providedIn: 'root'
})
export class RemarkService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  createRemarksArrayObj(remark_data: any, remark_subobj_data: any, active_user_id: string, resolve_remark_id: string, remark_id:string ='') {
    // Principle of "you can only resolve your own remark"
    const allow_user_resolve_bool = active_user_id === remark_data.remark_user.user_id;
//console.log('data_map',remark_data)
    return {
      from: remark_data.remark_user?.name,
      from_pos: remark_data.remark_user?.position,
      date: remark_data.remark_date,
      // Consider stuff like the details of the remark writer
      msg: remark_subobj_data.step_remark,
      actionMsg: remark_subobj_data.action_remark,
      serviceMsg: remark_subobj_data.service_remark,
      eodbMsg: remark_subobj_data.service_eodb_tags_remark,
      reply_from: remark_subobj_data.reply?.reply_user?.name,
      reply_from_pos: remark_subobj_data.reply?.reply_user?.position,
      reply_msg: remark_subobj_data.reply?.reply_remark,
      reply_date: remark_subobj_data.reply?.reply_date,
      resolved_status: remark_subobj_data.resolved_status,
      allow_resolve:
      remark_subobj_data.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED
        && allow_user_resolve_bool,
      allow_reply:
      remark_subobj_data.resolved_status === RemarkResolvedStatusCitizensCharter.UNRESOLVED
        && remark_subobj_data?.reply?.reply_remark === '',
      resolve_remark_id: resolve_remark_id,
      id: remark_id,
    }
  }

  async resolveRemark(remark_obj: any, update_flag: number, step_action_id?: string) {
    // Close reply/resolve options after
    remark_obj.allow_resolve = false;
    remark_obj.allow_reply = false;
    remark_obj.open_reply = false;

    if(remark_obj.resolve_remark_id) {
      let updateObj: Record<string, any> = {};
      let updateStatus = RemarkResolvedStatusCitizensCharter.RESOLVED;
      let updateResolvedDate = Timestamp.now().toDate();

      switch(update_flag) {
        case 1:
          // Service remarks          
          updateObj = {
            service_remark_obj : {
              resolved_status : updateStatus,
              resolved_date : updateResolvedDate
            }
          };
          break;
        case 2:
          // Service EODB tags remarks
          updateObj = {
            service_eodb_tags_remark_obj : {
              resolved_status : updateStatus,
              resolved_date : updateResolvedDate
            }
          };
          break;
        case 3:
          // Service steps
          if(step_action_id) {
            updateObj = {
              service_steps_remarks : {
                [`${step_action_id}`] : {
                  resolved_status : updateStatus,
                  resolved_date : updateResolvedDate
                }
              }
            };
          }
          break;
        case 4:
          // Agency action
          if(step_action_id) {
            updateObj = {
              service_agency_actions_remarks : {
                [`${step_action_id}`] : {
                  resolved_status : updateStatus,
                  resolved_date : updateResolvedDate
                }
              }
            };
          }
          break;
        default:
          // Should not happen
          return;
      }

      const batch = this.afs.firestore.batch(); // batch uploader, firestore
  
      let remark_ref = this.afs.firestore
        .collection(`Remarks Artemis New`)
        .doc(remark_obj.resolve_remark_id);
  
      // https://stackoverflow.com/a/47801933
      // https://stackoverflow.com/a/53115582
      batch.set(remark_ref, updateObj, { merge: true });
  
      await batch.commit().then();
    }
  }
}
