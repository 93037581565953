<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - ARTEMIS USERS</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                   

                </tr>
                
        </thead>
        <tbody>
            <tr>
                <td class = "table table-warning">TOTAL COUNT OF USERS</td>
                <td>{{users_count.length}}</td>
            </tr>
            <tr>
                <td class = "text-end">Guest</td>
                <td>{{guests_count.length}}</td>
            </tr>
            <tr>
                <td class = "text-end">Individual</td>
                <td>{{individual_count.length}}</td>
            </tr>
            <tr>
                <td class = "text-end">Organization</td>
                <td>{{organization_count.length}}</td>
            </tr>
            <tr>
                <td class = "text-end">Government</td>
                <td>{{government_count.length}}</td>
            </tr>
           
        </tbody>
        
          </table>

          <!-- CC SUBMISSION UPDATE-->
                <div class = "container m-3">
                    <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-warning">
                            <tr>
                                <th colspan = "2" class = "table-light">RELEVANT DATA</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>TOTAL COUNT OF REGISTERED USERS: </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT OF PASSWORD RESET REQUESTS: </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT OF UNLOCKING REQUESTS:</td>
                                <td></td>
                            </tr>
                            
                        </tbody>
                        
                   </table>

                
               
                </div>
                

               
               
             
</div>