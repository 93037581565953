import { ReferenceCommonModalComponent } from './reference-control/modal/common-modal/reference-common-modal.component';
import { ReferenceSubControlComponent } from './reference-control/reference-sub-control/reference-sub-control.component';
import { ReferenceControlComponent } from './reference-control/reference-control.component';
import { CharterReportsComponent } from './artemis/artemis-charter-crud/charter-reports/charter-reports.component';
import { AgencyMembersControlComponent } from './agency-control/agency-members-control/agency-members-control.component';
import { RegulationsCrisViewComponent } from './pbris/regulations/cris-view-list/regulations-cris-view.component';
import { RegulationsDrisViewComponent } from './pbris/regulations/dris-view-list/regulations-dris-view.component';
import { RegulationsTextRegulationViewComponent } from './pbris/regulations/textreg-view-list/regulations-textreg-view.component';
import { RegulationsPhase2ViewComponent } from './pbris/regulations/phase2-view-list/regulations-phase2-view.component';
import { RegulationsRiaViewComponent } from './pbris/regulations/ria-view-list/regulations-ria-view.component';
import { RegulationsNotifViewComponent } from './pbris/regulations/notif-view-list/regulations-notif-view.component';
import { PbrisAccountDashboardComponent } from './pbris/pbris-account-page/pbris-account-dashboard/pbris-account-dashboard.component';
import { PbrisAccountUserComponent } from './pbris/pbris-account/pbris-account-user/pbris-account-user.component';
import { PbrisAccountSettingComponent } from './pbris/pbris-account/pbris-account-settings/pbris-account-setting.component';
import { PbrisAccountComponent } from './pbris/pbris-account/pbris-account.component';
import { ArtemisViewOrganizationStaffsDialogComponent } from './artemis/artemis-view/artemis-view-organization/artemis-view-organization-staffs/artemis-view-organization-staffs-dialog/artemis-view-organization-staffs-dialog.component';
import { PbrisConsultationsViewComponent } from './pbris/pbris-consultation/pbris-consultation-view/pbris-consultation-view.component';
import { PbrisConsultationsComponent } from './pbris/pbris-consultation/pbris-consultation.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule, Title } from '@angular/platform-browser';
import { InjectionToken, NgModule, NgZone, NO_ERRORS_SCHEMA, PLATFORM_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { setTheme } from 'ngx-bootstrap/utils';

setTheme('bs4'); // or 'bs4'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import 'mdb-ui-kit';
import '@popperjs/core';
import { WelcomeComponent } from './welcome/welcome.component';
import { WelNavbarComponent } from './welcome/wel-navbar/wel-navbar.component';
import { ErrorComponent } from './error/error.component';
import { ArtemisComponent } from './artemis/artemis.component';
import { PbrisComponent } from './pbris/pbris.component';
import { LoginPbrisComponent } from './pbris/login-pbris/login-pbris.component';
import { RegistrationPbrisComponent } from './pbris/registration-pbris/registration-pbris.component';
import { PbrisFormBannerComponent } from './pbris/pbris-form-banner/pbris-form-banner.component';
import { NavigationArtemisComponent } from './navigation-artemis/navigation-artemis.component';
import { ArtemisNavigationHeaderComponent } from './artemis/artemis-navigation-header/artemis-navigation-header.component';
import { ArtemisHomePageComponent } from './artemis/artemis-home-page/artemis-home-page.component';
import { ArtemisAdvancedSearchPageComponent } from './artemis/artemis-advanced-search-page/artemis-advanced-search-page.component';
import { ArtemisSearchResultsPageComponent } from './artemis/artemis-search-results-page/artemis-search-results-page.component';
import { ArtemisServiceInformationPageComponent } from './artemis/artemis-service-information-page/artemis-service-information-page.component';
import { ArtemisServiceProcessFlowPageComponent } from './artemis/artemis-service-process-flow-page/artemis-service-process-flow-page.component';
import { ArtemisInboxPageComponent } from './artemis/artemis-inbox-page/artemis-inbox-page.component';
import { ArtemisAboutUsPageComponent } from './artemis/artemis-about-us-page/artemis-about-us-page.component';
import { ArtemisHelpPageComponent } from './artemis/artemis-help-page/artemis-help-page.component';
import { ArtemisReportsPageComponent } from './artemis/artemis-reports-page/artemis-reports-page.component';
import { ArtemisContactUsPageComponent } from './artemis/artemis-contact-us-page/artemis-contact-us-page.component';
import { ArtemisHomePageServiceFinderComponent } from './artemis/artemis-home-page/artemis-home-page-service-finder/artemis-home-page-service-finder.component';
import { ArtemisHomePageRecentTrendingComponent } from './artemis/artemis-home-page/artemis-home-page-recent-trending/artemis-home-page-recent-trending.component';
import { ArtemisHomePageAnnouncementsComponent } from './artemis/artemis-home-page/artemis-home-page-announcements/artemis-home-page-announcements.component';
import { ArtemisHomePageFooterBannerComponent } from './artemis/artemis-home-page/artemis-home-page-footer-banner/artemis-home-page-footer-banner.component';
import { PbrisReferencesComponent } from './pbris/pbris-references/pbris-references.component';
import { PbrisSectorComponent } from './pbris/pbris-sector/pbris-sector.component';
import { CreateSectorComponent } from './create-sector/create-sector.component';
import { ModalModule } from 'ng-uikit-pro-standard';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { PbrisAdvancedSearchComponent } from './pbris/pbris-advanced-search/pbris-advanced-search.component';
import { RegulationsComponent } from './pbris/regulations/regulations.component';
import { ArtemisLoginPageComponent } from './artemis/artemis-login-page/artemis-login-page.component';
import { ArtemisRegistrationComponent } from './artemis/artemis-login-page/artemis-registration/artemis-registration.component';
import { ArtemisLoginPageCarouselComponent } from './artemis/artemis-login-page/artemis-login-page-carousel/artemis-login-page-carousel.component';
import { ArtemisLoginBannerComponent } from './artemis/artemis-login-page/artemis-login-banner/artemis-login-banner.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { RestrictedAccessComponent } from './restricted-access/restricted-access.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UploadedFilesComponent } from './uploaded-files/uploaded-files.component';
import { AdminComponent } from './admin/admin.component';
import { RegistrationAgreementsComponent } from './admin/registration-agreements/registration-agreements.component';
import { PbrisQuickSearchComponent } from './pbris/pbris-quick-search/pbris-quick-search.component';
import { RegulationsResultComponent } from './pbris/regulations-result/regulations-result.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ArtemisViewComponent } from './artemis/artemis-view/artemis-view.component';
import { ArtemisViewOrganizationComponent } from './artemis/artemis-view/artemis-view-organization/artemis-view-organization.component';
import { ArtemisNewCharterComponent } from './artemis/artemis-inbox-page/artemis-new-charter/artemis-new-charter.component';
import { ArtemisDownloadCharterPageComponent } from './artemis/artemis-inbox-page/artemis-download-charter-page/artemis-download-charter-page.component';
import { ArtemisCharterInboxPageComponent } from './artemis/artemis-inbox-page/artemis-charter-inbox-page/artemis-charter-inbox-page.component';
import { ArtemisCharterPreviewPageComponent } from './artemis/artemis-inbox-page/artemis-charter-preview-page/artemis-charter-preview-page.component';
import { CrudTableComponent } from './crud-table/crud-table.component';
import { ArtemisHomePageHeaderBoardComponent } from './artemis/artemis-home-page/artemis-home-page-header-board/artemis-home-page-header-board.component';
import { CrudLovComponent } from './admin/crud-lov/crud-lov.component';
import { CrudUsersComponent } from './admin/crud-users/crud-users.component';
import { ModalManagerComponent } from './modal-manager/modal-manager.component';
import { PbrisUploadExistingRegulationsComponent } from './pbris/pbris-upload-existing-regulations/pbris-upload-existing-regulations.component';
import { PbrisUploadExistingCompleteRegulationsComponent } from './pbris/pbris-upload-existing-complete-regulations/pbris-upload-existing-complete-regulations.component';
import { PbrisUploadProposedRegulationsComponent } from './pbris/pbris-upload-proposed-regulations/pbris-upload-proposed-regulations.component';
import { EssentialLinksComponent } from './dashboard/essential-links/essential-links.component';
import { AnnouncementsComponent } from './dashboard/announcements/announcements.component';
import { AnnouncementCrudComponent } from './dashboard/announcement-crud/announcement-crud.component';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ArtemisNewCitizensCharterComponent } from './artemis/artemis-new-citizens-charter/artemis-new-citizens-charter.component';
import { ConfirmResetPasswordComponent } from './modal-manager/confirm-reset-password/confirm-reset-password.component';
import { ReuseLoginComponent } from './modal-manager/reuse-login/reuse-login.component';
import { ArtemisAnnouncementsPageComponent } from './artemis/artemis-announcements-page/artemis-announcements-page.component';
import { PbrisCarouselComponent } from './pbris/pbris-carousel/pbris-carousel.component';
import { ConfirmSendVerificationComponent } from './modal-manager/confirm-send-verification/confirm-send-verification.component';
import { ArtemisCharterCrudComponent } from './artemis/artemis-charter-crud/artemis-charter-crud.component';
import { ViewCharterComponent } from './artemis/artemis-charter-crud/view-charter/view-charter.component';
import { AgencyControlComponent } from './agency-control/agency-control.component';
import { UploadCharterComponent } from './artemis/artemis-charter-crud/upload-charter/upload-charter.component';
import { HttpClientModule } from '@angular/common/http';
import { CitizensRightsComponent } from './artemis/artemis-charter-crud/citizens-rights/citizens-rights.component';
import { AgencyInformationComponent } from './agency-control/agency-information/agency-information.component';
import { AgencyAddComponent } from './agency-control/agency-add/agency-add.component';
import { TextareaAutoresizeDirective } from './textarea-autoresize.directive';
import { HelpPageComponent } from './help-page/help-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CustomAuthHandleComponent } from './custom-auth-handle/custom-auth-handle.component';
import { PbrisRegulatoryPolicyComponent } from './pbris/pbris-regulatory-policy/pbris-regulatory-policy.component';
import { PbrisContactUsComponent } from './pbris/pbris-contact-us/pbris-contact-us.component';
import { PbrisHelpComponent } from './pbris/pbris-help/pbris-help.component';
import { UpdateRegulationsComponent } from './pbris/regulations/update-regulations/update-regulations.component';
import { UpdateCharterComponent } from './artemis/artemis-charter-crud/update-charter/update-charter.component';
import { RegulatoryManagementComponent } from './pbris/regulatory-management/regulatory-management.component';
import { RegulatoryStockComponent } from './pbris/regulatory-management/regulatory-stock/regulatory-stock.component';
import { UploadExistingRegulationsComponent } from './pbris/upload-existing-regulations/upload-existing-regulations.component';
import { InboxComponent } from './pbris/regulatory-management/inbox/inbox.component';
import { VerificationRegulationComponent } from './pbris/regulatory-management/inbox/verification/verification-regulation/verification-regulation.component';
import { DataTablesModule } from 'angular-datatables';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelect as MatSelect, MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

import { ArtemisNewCharterClientServiceModalComponent } from './artemis/artemis-inbox-page/artemis-new-charter/artemis-new-charter-client-service-modal/artemis-new-charter-client-service-modal.component';
import { QuickLinksComponent } from './pbris/quick-links/quick-links.component';
import { QuickLinksArtemisComponent } from './artemis/quick-links-artemis/quick-links-artemis.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TicketingFilingComponent } from './pbris/regulatory-management/ticketing-filing/ticketing-filing.component';
import { ArtemisUserFeedbackComponent } from './artemis/artemis-user-feedback/artemis-user-feedback.component';
import { PbrisUserFeedbackComponent } from './pbris/pbris-user-feedback/pbris-user-feedback.component';
import { TicketListComponent } from './pbris/regulatory-management/ticketing-filing/ticket-list/ticket-list.component';
import { ArtemisInboxUserFeedbackComponent } from './artemis/artemis-inbox-user-feedback/artemis-inbox-user-feedback.component';
import { ArtemisCommentPageComponent } from './artemis/artemis-comment-page/artemis-comment-page.component';
import { ArtemisCommentCreateComponent } from './artemis/artemis-comment-page/artemis-comment-create/artemis-comment-create.component';
import { ArtemisCommentViewComponent } from './artemis/artemis-comment-page/artemis-comment-view/artemis-comment-view.component';
import { InboxListComponent } from './pbris/regulatory-management/inbox/inbox-list/inbox-list.component';
import { PbrisUserFeedbackCrudComponent } from './pbris/pbris-user-feedback-crud/pbris-user-feedback-crud.component';
import { PbrisUserFeedbackCreateComponent } from './pbris/pbris-user-feedback-crud/pbris-user-feedback-create/pbris-user-feedback-create.component';
import { PbrisUserFeedbackViewComponent } from './pbris/pbris-user-feedback-crud/pbris-user-feedback-view/pbris-user-feedback-view.component';
import { PbrisCommentPageComponent } from './pbris/pbris-comment-page/pbris-comment-page.component';
import { PbrisCommentCreateComponent } from './pbris/pbris-comment-page/pbris-comment-create/pbris-comment-create.component';
import { PbrisCommentViewComponent } from './pbris/pbris-comment-page/pbris-comment-view/pbris-comment-view.component';
import { RegulatoryPlanningComponent } from './pbris/regulatory-management/regulatory-planning/regulatory-planning.component';
import { PbrisAccountPageComponent } from './pbris/pbris-account-page/pbris-account-page.component';
import { PbrisAccountSubscriptionComponent } from './pbris/pbris-account-page/pbris-account-subscription/pbris-account-subscription.component';
import { PbrisAccountCommentComponent } from './pbris/pbris-account-page/pbris-account-comment/pbris-account-comment.component';
import { PbrisAccountRegulationComponent } from './pbris/pbris-account-page/pbris-account-regulation/pbris-account-regulation.component';
import { PbrisAccountManagementComponent } from './pbris/pbris-account-page/pbris-account-management/pbris-account-management.component';
import { PbrisAccountTicketComponent } from './pbris/pbris-account-page/pbris-account-ticket/pbris-account-ticket.component';
import { PbrisAccountTicketListComponent } from './pbris/pbris-account-page/pbris-account-ticket/pbris-account-ticket-list/pbris-account-ticket-list.component';
import { PbrisAccountAgencyComponent } from './pbris/pbris-account-page/pbris-account-agency/pbris-account-agency.component';
import { PbrisAccountSectorComponent } from './pbris/pbris-account-page/pbris-account-sector/pbris-account-sector.component';
import { ReportsMainComponent } from './pbris/reports-main/reports-main.component';
import { ProductivityConsultationComponent } from './pbris/reports-main/productivity-consultation/productivity-consultation.component';
import { ProductivityExistingRegulationsComponent } from './pbris/reports-main/productivity-existing-regulations/productivity-existing-regulations.component';
import { ProductivityProposedRegulationsComponent } from './pbris/reports-main/productivity-proposed-regulations/productivity-proposed-regulations.component';
import { ProductivityTicketsComponent } from './pbris/reports-main/productivity-tickets/productivity-tickets.component';
import { ProductivityUserManagementComponent } from './pbris/reports-main/productivity-user-management/productivity-user-management.component';
import { DatePickerComponent } from './pbris/reports-main/date-picker/date-picker.component';
import { ArtemisServiceInformationPageConfirmSubmitModalComponent } from './artemis/artemis-service-information-page/artemis-service-information-page-confirm-submit-modal/artemis-service-information-page-confirm-submit-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ArtemisServiceCrudComponent } from './artemis/artemis-service-crud/artemis-service-crud.component';
import { RegulationViewExistingComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-existing/regulation-view-existing.component';
import { RegulationViewProposedComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/regulation-view-proposed.component';
import { ReportSummaryComponent } from './artemis/report-summary/report-summary.component';
import { ExistingCitizensCharterReportComponent } from './artemis/report-summary/existing-citizens-charter-report/existing-citizens-charter-report.component';
import { MultiStageComponent } from './artemis/report-summary/multi-stage/multi-stage.component';
import { ArtemisByAgencyReportComponent } from './artemis/report-summary/artemis-by-agency-report/artemis-by-agency-report.component';
import { ArtemisTicketReportComponent } from './artemis/report-summary/artemis-ticket-report/artemis-ticket-report.component';
import { ArtemisUserManagementComponent } from './artemis/report-summary/artemis-user-management/artemis-user-management.component';
import { RegulationProposedNotificationsComponent } from './pbris/regulatory-management/regulation-proposed-notifications/regulation-proposed-notifications.component';
import { CrisCreateComponent } from './pbris/cris-create/cris-create.component';
import { NotificationsComponent } from './pbris/regulatory-management/notifications/notifications.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ArtemisNotificationsComponent } from './artemis/artemis-inbox-page/artemis-notifications/artemis-notifications.component';
import { ArtemisAgencyCrudComponent } from './artemis/artemis-agency-crud/artemis-agency-crud.component';
import { ArtemisViewOrganizationDetailsComponent } from './artemis/artemis-view/artemis-view-organization/artemis-view-organization-details/artemis-view-organization-details.component';
import { ArtemisViewOrganizationStaffsComponent } from './artemis/artemis-view/artemis-view-organization/artemis-view-organization-staffs/artemis-view-organization-staffs.component';
import { PhoneMaskDirective } from './mask/phone-mask.directive';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { DrisCreateComponent } from './pbris/dris-create/dris-create.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { RegulationsPiaViewComponent } from './pbris/regulations/pia-view-list/regulations-pia-view.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { WorklistRegulationsPiaViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-existing/pia-view-list/worklist-regulations-pia-view.component';
import { WorklistRegulationsPhase2ViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-existing/phase2-view-list/worklist-regulations-phase2-view.component';
import { RegulationViewConfirmationModalComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-confirmation-modal/regulation-view-confirmation-modal.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PbrisQuickAdvSearchComponent } from './pbris/pbris-quick-adv-search/pbris-quick-adv-search.component';
import { ArtemisReferenceControlComponent } from './reference-control/artemis-reference-control/artemis-reference-control.component';
import { ReferenceArtemisModalComponent } from './reference-control/modal/artemis-modal/reference-artemis-modal.component';
import { ArtemisServiceManagementPageComponent } from './artemis/artemis-inbox-page/artemis-service-management-page/artemis-service-management-page.component';
import { ArtemisCharterInboxPageAgencyHeadComponent } from './artemis/artemis-inbox-page/artemis-charter-inbox-page-agency-head/artemis-charter-inbox-page-agency-head.component';
import { ArtemisCharterInboxPageArtaComponent } from './artemis/artemis-inbox-page/artemis-charter-inbox-page-arta/artemis-charter-inbox-page-arta.component';
import { ArtemisCharterInboxPageArtaCharterListComponent } from './artemis/artemis-inbox-page/artemis-charter-inbox-page-arta/artemis-charter-inbox-page-arta-charter-list/artemis-charter-inbox-page-arta-charter-list.component';
import { ArtemisCharterInboxPageArtaServiceListComponent } from './artemis/artemis-inbox-page/artemis-charter-inbox-page-arta/artemis-charter-inbox-page-arta-service-list/artemis-charter-inbox-page-arta-service-list.component';
import { ArtemisDisapproveCharterReasonModalComponent } from './artemis/artemis-inbox-page/artemis-disapprove-charter-reason-modal/artemis-disapprove-charter-reason-modal.component';
import { ArtemisDelistServiceReasonModalComponent } from './artemis/artemis-inbox-page/artemis-service-management-page/artemis-delist-service-reason-modal/artemis-delist-service-reason-modal.component';
import { JoinStringSeparatorPipe } from './pipes/join-string-separator.pipe';
import { TelephoneMaskDirective } from './mask/telephone-mask.directive';
import { TrunklineMaskDirective } from './mask/trunkline-mask.directive';
import { LinebreaksPipe } from './pipes/linebreaks.pipe';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ArtemisViewOrganizationHelpDetailsComponent } from './artemis/artemis-view/artemis-view-organization/artemis-view-organization-help-details/artemis-view-organization-help-details.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ArtemisAgencyComplianceReportComponent } from './artemis/report-summary/artemis-agency-compliance-report/artemis-agency-compliance-report.component';
import { ViewCharterPublicComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public.component';
import { ViewCharterPublicDetailsComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public-details/view-charter-public-details.component';
import { ViewCharterPublicStaffsComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public-staffs/view-charter-public-staffs.component';
import { ViewCharterPublicServicesComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public-services/view-charter-public-services.component';

import { RegulationViewProposedFinalRegComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/regulation-view-proposed-final-reg/regulation-view-proposed-final-reg.component';
import { RegulationViewProposedPrePostingComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/regulation-view-proposed-pre-posting/regulation-view-proposed-pre-posting.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RegulationDetailsViewComponent } from './pbris/regulations/regulation-details/regulation-details.component';
import { ViewCharterPublicHelpDetailsComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public-help-details/view-charter-public-help-details.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { RegulationViewProposedArtaRemarks } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/regulation-view-proposed-arta-remarks-modal/regulation-view-proposed-arta-remarks-modal.component';
import { PisAssessmentViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/pis-assessment-view/pis-assessment-view.component';
import { PisAssessmentTableComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/pis-assessment-view/pis-assessment-table/pis-assessment-table.component';
import { PisAssessmentPhase2ViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/pis-assessment-view/pis-assessment-phase2-view/pis-assessment-phase2-view.component';
import { PisAssessmentSummaryViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/pis-assessment-summary-view/pis-assessment-summary-view.component';
import { PublicPiaAssessmentViewComponent } from './pbris/regulations/pia-assessment-view/pia-assessment-view.component';
import { PublicRiaAssessmentViewComponent } from './pbris/regulations/ria-assessment-view/ria-assessment-view.component';
import { PublicRiaAssessmentViewArtaRemarksModalComponent } from './pbris/regulations/ria-assessment-view/ris-assessment-view-arta-remarks-modal/ris-assessment-view-arta-remarks-modal.component';
// import { ArtemisAgencyComplianceReportComponent } from './artemis/report-summary/artemis-agency-compliance-report/artemis-agency-compliance-report.component';
import { QuillModule } from 'ngx-quill'
// import { QuillConfigModule } from 'ngx-quill/config';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { EmailConfigurationTemplateDialogComponent } from './email-configuration/email-configuration-template/email-configuration-template.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { PbrisRegulatoryNotifFormComponent } from './pbris/pbris-regulatory-notif-form/pbris-regulatory-notif-form.component';
import { ProposedTextRegulationView } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/proposed-text-regulation-view/proposed-text-regulation-view.component';
import { UploadedDocumentsViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/uploaded-documents-view/uploaded-documents-view.component';
import { ProposedCrisConsultationsViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/proposed-cris-consultations-view/proposed-cris-consultations-view.component';
import { ProposedRegulationsDetailsViewComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/proposed-regulations-details-view/proposed-regulations-details-view.component';
import { ProposedSummaryView } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-proposed/proposed-summary-view/proposed-summary-view.component';
import { PDFFileViewComponent } from './pbris/regulations/pdf-file-view/pdf-file-view.component';
import { PublicConsultationsViewComponent } from './pbris/regulations/public-consultations-view-list/public-consultations-view.component';
import { PublicUploadedDocumentsViewComponent } from './pbris/regulations/public-uploaded-documents-view/public-uploaded-documents-view.component';
import { ArtemisViewAgencyServicesComponent } from './artemis/artemis-view/artemis-view-organization/agency-services/agency-services.component';
import { FileUploaderDirective } from './mask/file-uploader.directive';
import { ConsultationCommentModal } from './pbris/pbris-comment-page/pbris-comment-create/consultation-comment-modal/consultation-comment-modal.component';
import { ViewCharterPublicCComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public-cc/view-charter-public-cc.component';
import { ArtemisViewAgencyRegulationComponent } from './artemis/artemis-view/artemis-view-organization/agency-regulations/agency-regulations.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ArtemisCocModalComponent } from './artemis/artemis-inbox-page/artemis-coc-modal/artemis-coc-modal.component';
import { AgencyOfficesComponent } from './artemis/artemis-view/artemis-view-organization/agency-offices/agency-offices.component';
import { AgencyCitizensCharterComponent } from './artemis/artemis-view/artemis-view-organization/agency-citizens-charter/agency-citizens-charter.component';
import { AgencyOfficeModalComponent } from './artemis/artemis-view/artemis-view-organization/agency-office-modal/agency-office-modal.component';
import { ArtemisCustomDialogComponent } from './artemis/artemis-custom-dialog/artemis-custom-dialog.component';
import { CocSubmissionsComponent } from './artemis/artemis-view/artemis-view-organization/coc-submissions/coc-submissions.component';
import { ArtemisCocPreviewPageArtaComponent } from './artemis/artemis-inbox-page/artemis-charter-inbox-page-arta/artemis-coc-preview-page-arta/artemis-coc-preview-page-arta.component';
import { ArtemisHomePageServicesResultsComponent } from './artemis/artemis-home-page/artemis-home-page-services-results/artemis-home-page-services-results.component';
import { AgencyOfficeDivisionComponent } from './artemis/artemis-view/artemis-view-organization/agency-office-division/agency-office-division.component';
import { AgencyOfficeDivisionModalComponent } from './artemis/artemis-view/artemis-view-organization/agency-office-division-modal/agency-office-division-modal.component';
import { ArtemisCocMasterlistComponent } from './artemis/artemis-inbox-page/artemis-coc-masterlist/artemis-coc-masterlist.component';
import { NewServiceModalComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/new-service-modal.component';
import { NewRequirementsModalComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/new-requirements-modal/new-requirements-modal.component';
import { NewClientStepsModalComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/new-client-steps-modal/new-client-steps-modal.component';
import { ArtemisLoadingAnimationComponent } from './artemis/artemis-loading-animation/artemis-loading-animation.component';
import { UpdateExistingRegulationsModalComponent } from './pbris/upload-existing-regulations/update-existing-regulations-modal/update-existing-regulations-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { ExistingCommentModalComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-existing/existing-comment-modal/existing-comment-modal.component';
import { CitizensCharterServiceViewComponent } from './artemis/artemis-citizens-charter-view/citizens-charter-service-view/citizens-charter-service-view.component';
import { ArtemisCitizensCharterViewComponent } from './artemis/artemis-citizens-charter-view/artemis-citizens-charter-view.component';
import { CitizensCharterServiceListViewComponent } from './artemis/artemis-citizens-charter-view/citizens-charter-service-list-view/citizens-charter-service-list-view.component';
import { RequirementControlComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/requirement-control/requirement-control.component';
import { SituationalRequirementControlComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/situational-requirement-control/situational-requirement-control.component';
import { SubsituationalRequirementControlComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/subsituational-requirement-control/subsituational-requirement-control.component';
import { ArtemisServiceRemarkComponent } from './artemis/artemis-service-information-page/artemis-service-remark/artemis-service-remark.component';
import { ArtemisServiceRemarksThreadComponent } from './artemis/artemis-service-information-page/artemis-service-remarks-thread/artemis-service-remarks-thread.component';
import { ArtemisCommentsComponent } from './artemis/artemis-service-information-page/artemis-comments/artemis-comments.component';
import { ArtemisServiceScrollspyComponent } from './artemis/artemis-service-information-page/artemis-service-scrollspy/artemis-service-scrollspy.component';
import { ArtemisServiceViewComponent } from './artemis/artemis-view/artemis-view-organization/agency-services/artemis-service-view/artemis-service-view.component';
import { AgencyRegisterMemberModalComponent } from './agency-control/agency-register-member-modal/agency-register-member-modal.component';
import { AgencyRegisterMemberComponent } from './agency-control/agency-register-member/agency-register-member.component';
import { ArtemisAgencyMiniProfileComponent } from './artemis/artemis-service-information-page/artemis-agency-mini-profile/artemis-agency-mini-profile.component';
import { RegulationsSearchComponent } from './pbris/regulations-result/regulations-search/regulations-search.component';
import { ArtemisEditRemarkReplyComponent } from './artemis/artemis-service-information-page/artemis-edit-remark-reply/artemis-edit-remark-reply.component';
import { ArtemisIdentificationListMaintenanceComponent } from './artemis/artemis-identification-list-maintenance/artemis-identification-list-maintenance.component';
import { ArtemisOfficeControlComponent } from './artemis/artemis-view/artemis-office-control/artemis-office-control.component';
import { ArtemisOfficeHierarchyControlComponent } from './artemis/artemis-view/artemis-office-hierarchy-control/artemis-office-hierarchy-control.component';
import { ArtemisOfficeModelViewComponent } from './artemis/artemis-view/artemis-office-model-view/artemis-office-model-view.component';
import { CitizensCharterProcessownerListComponent } from './artemis/artemis-citizens-charter-view/citizens-charter-processowner-list/citizens-charter-processowner-list.component';
import { ArtemisViewFeesComponent } from './artemis/artemis-citizens-charter-view/artemis-view-fees/artemis-view-fees.component';
import { PbrisFaqComponent } from './pbris/pbris-faq/pbris-faq.component';
import { PbrisFaqSearchComponent } from './pbris/pbris-faq/pbris-faq-search/pbris-faq-search.component';
import { ArtemisRequirementBuilderComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/artemis-requirement-builder/artemis-requirement-builder.component';
import { ArtemisRequirementFormComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/artemis-requirement-form/artemis-requirement-form.component';
import { SearchPipe } from './pipes/search.pipe';
import { ArtemisOfficeGroupingComponent } from './artemis/artemis-view/artemis-office-grouping/artemis-office-grouping.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ArtemisRequirementNodeComponent } from './artemis/artemis-citizens-charter-view/artemis-requirement-node/artemis-requirement-node.component';
import { ArtemisChooseanyRequirementNodeComponent } from './artemis/artemis-citizens-charter-view/artemis-chooseany-requirement-node/artemis-chooseany-requirement-node.component';
import { ArtemisRequirementRowComponent } from './artemis/artemis-citizens-charter-view/artemis-requirement-row/artemis-requirement-row.component';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { DropdownCommentComponent } from './pbris/regulatory-management/inbox/regulation-view/regulation-view-existing/dropdown-comment/dropdown-comment.component';
import { ArtemisServiceHistoryListComponent } from './artemis/artemis-inbox-page/artemis-service-history-list/artemis-service-history-list.component';
import { ArtemisServiceHistoryViewComponent } from './artemis/artemis-inbox-page/artemis-service-history-view/artemis-service-history-view.component';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';

export const FIREBASE_REF = {
  DEFAULT_INSTANCE_DB_AUTH:'[DEFAULT]',
  TEMP_INSTANCE_DB_AUTH:'TEMP-DB'
}

export const FIREBASE_DB_1 = new InjectionToken('firebase project injector');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DashboardComponent,
    FooterComponent,
    WelcomeComponent,
    WelNavbarComponent,
    ErrorComponent,
    ArtemisComponent,
    PbrisComponent,
    LoginPbrisComponent,
    RegistrationPbrisComponent,
    PbrisFormBannerComponent,
    NavigationArtemisComponent,
    ArtemisNavigationHeaderComponent,
    ArtemisHomePageComponent,
    ArtemisAdvancedSearchPageComponent,
    ArtemisSearchResultsPageComponent,
    ArtemisServiceInformationPageComponent,
    ArtemisServiceProcessFlowPageComponent,
    ArtemisInboxPageComponent,
    ArtemisAboutUsPageComponent,
    ArtemisHelpPageComponent,
    ArtemisReportsPageComponent,
    ArtemisContactUsPageComponent,
    ArtemisHomePageServiceFinderComponent,
    ArtemisHomePageRecentTrendingComponent,
    ArtemisHomePageAnnouncementsComponent,
    ArtemisHomePageFooterBannerComponent,
    ArtemisRegistrationComponent,
    PbrisReferencesComponent,
    PbrisSectorComponent,
    CreateSectorComponent,
    PbrisAdvancedSearchComponent,
    RegulationsComponent,
    ArtemisLoginPageComponent,
    ArtemisLoginPageCarouselComponent,
    PbrisCarouselComponent,
    ArtemisLoginBannerComponent,
    UserManagementComponent,
    RestrictedAccessComponent,
    ForgotPasswordComponent,
    UploadedFilesComponent,
    AdminComponent,
    RegistrationAgreementsComponent,
    PbrisQuickSearchComponent,
    RegulationsResultComponent,
    ArtemisViewComponent,
    ArtemisViewOrganizationComponent,
    ArtemisNewCharterComponent,
    ArtemisDownloadCharterPageComponent,
    ArtemisCharterInboxPageComponent,
    ArtemisCharterPreviewPageComponent,
    CrudTableComponent,
    ArtemisHomePageHeaderBoardComponent,
    CrudLovComponent,
    CrudUsersComponent,
    ModalManagerComponent,
    PbrisUploadExistingRegulationsComponent,
    EssentialLinksComponent,
    AnnouncementsComponent,
    AnnouncementCrudComponent,
    ArtemisNewCitizensCharterComponent,
    ConfirmResetPasswordComponent,
    ReuseLoginComponent,
    ArtemisAnnouncementsPageComponent,
    ConfirmSendVerificationComponent,
    ArtemisCharterCrudComponent,
    ViewCharterComponent,
    AgencyControlComponent,
    UploadCharterComponent,
    CitizensRightsComponent,
    AgencyInformationComponent,
    AgencyAddComponent,
    TextareaAutoresizeDirective,
    HelpPageComponent,
    AboutUsComponent,
    CustomAuthHandleComponent,
    PbrisRegulatoryPolicyComponent,
    PbrisContactUsComponent,
    PbrisHelpComponent,
    UpdateRegulationsComponent,
    UpdateCharterComponent,
    RegulatoryManagementComponent,
    RegulatoryStockComponent,
    UploadExistingRegulationsComponent,
    PbrisUploadProposedRegulationsComponent,
    PbrisUploadExistingCompleteRegulationsComponent,
    InboxComponent,
    VerificationRegulationComponent,
    ArtemisNewCharterClientServiceModalComponent,
    QuickLinksComponent,
    QuickLinksArtemisComponent,
    TicketingFilingComponent,
    ArtemisUserFeedbackComponent,
    PbrisUserFeedbackComponent,
    TicketListComponent,
    ArtemisInboxUserFeedbackComponent,
    ArtemisCommentPageComponent,
    ArtemisCommentCreateComponent,
    ArtemisCommentViewComponent,
    PbrisUserFeedbackCrudComponent,
    PbrisUserFeedbackCreateComponent,
    PbrisUserFeedbackViewComponent,
    PbrisCommentPageComponent,
    PbrisCommentCreateComponent,
    PbrisCommentViewComponent,
    InboxListComponent,
    RegulatoryPlanningComponent,
    PbrisAccountPageComponent,
    PbrisAccountDashboardComponent,
    PbrisAccountSubscriptionComponent,
    PbrisAccountCommentComponent,
    PbrisAccountRegulationComponent,
    PbrisAccountManagementComponent,
    PbrisAccountTicketComponent,
    PbrisAccountAgencyComponent,
    PbrisAccountSectorComponent,
    ReportsMainComponent,
    ProductivityConsultationComponent,
    ProductivityExistingRegulationsComponent,
    ProductivityProposedRegulationsComponent,
    ProductivityTicketsComponent,
    ProductivityUserManagementComponent,
    DatePickerComponent,
    ArtemisServiceInformationPageConfirmSubmitModalComponent,
    PbrisAccountTicketListComponent,
    ArtemisServiceCrudComponent,
    RegulationViewExistingComponent,
    RegulationViewProposedComponent,
    ReportSummaryComponent,
    ExistingCitizensCharterReportComponent,
    MultiStageComponent,
    ArtemisByAgencyReportComponent,
    ArtemisTicketReportComponent,
    ArtemisUserManagementComponent,
    RegulationProposedNotificationsComponent,
    CrisCreateComponent,
    NotificationsComponent,
    ArtemisNotificationsComponent,
    ArtemisAgencyCrudComponent,
    ArtemisViewOrganizationDetailsComponent,
    ArtemisViewOrganizationHelpDetailsComponent,
    ArtemisViewOrganizationStaffsComponent,
    PhoneMaskDirective,
    TelephoneMaskDirective,
    TrunklineMaskDirective,
    RegulationsPiaViewComponent,
    RegulationsNotifViewComponent,
    RegulationsRiaViewComponent,
    RegulationsPhase2ViewComponent,
    RegulationsTextRegulationViewComponent,
    RegulationsCrisViewComponent,
    RegulationsDrisViewComponent,
    RegulationDetailsViewComponent,
    PbrisConsultationsComponent,
    PbrisConsultationsViewComponent,
    ArtemisViewOrganizationStaffsDialogComponent,
    PbrisAccountComponent,
    PbrisAccountSettingComponent,
    PbrisAccountUserComponent,
    DrisCreateComponent,
    AgencyMembersControlComponent,
    AgencyRegisterMemberModalComponent,
    AgencyRegisterMemberComponent,
    CharterReportsComponent,
    ReferenceControlComponent,
    ReferenceSubControlComponent,
    ArtemisReferenceControlComponent,
    ReferenceCommonModalComponent,
    ReferenceArtemisModalComponent,
    WorklistRegulationsPiaViewComponent,
    WorklistRegulationsPhase2ViewComponent,
    RegulationViewConfirmationModalComponent,
    RegulationViewProposedArtaRemarks,
    PbrisQuickAdvSearchComponent,
    ArtemisServiceManagementPageComponent,
    ArtemisCharterInboxPageAgencyHeadComponent,
    ArtemisCharterInboxPageArtaComponent,
    ArtemisCharterInboxPageArtaCharterListComponent,
    ArtemisCharterInboxPageArtaServiceListComponent,
    ArtemisDisapproveCharterReasonModalComponent,
    ArtemisDelistServiceReasonModalComponent,
    JoinStringSeparatorPipe,
    LinebreaksPipe,
    ArtemisAgencyComplianceReportComponent,
    ViewCharterPublicComponent,
    ViewCharterPublicDetailsComponent,
    ViewCharterPublicStaffsComponent,
    ViewCharterPublicServicesComponent,
    ViewCharterPublicCComponent,
    // ArtemisAgencyComplianceReportComponent,
    RegulationViewProposedFinalRegComponent,
    RegulationViewProposedPrePostingComponent,
    ViewCharterPublicHelpDetailsComponent,
    EmailConfigurationComponent,
    PisAssessmentViewComponent,
    PisAssessmentTableComponent,
    PisAssessmentPhase2ViewComponent,
    PisAssessmentSummaryViewComponent,
    PublicPiaAssessmentViewComponent,
    PublicRiaAssessmentViewComponent,
    PublicRiaAssessmentViewArtaRemarksModalComponent,
    EmailConfigurationTemplateDialogComponent,
    PbrisRegulatoryNotifFormComponent,
    ProposedTextRegulationView,
    ProposedRegulationsDetailsViewComponent,
    UploadedDocumentsViewComponent,
    ProposedCrisConsultationsViewComponent,
    ProposedSummaryView,
    PDFFileViewComponent,
    PublicConsultationsViewComponent,
    PublicUploadedDocumentsViewComponent,
    ArtemisViewAgencyServicesComponent,
    ArtemisViewAgencyRegulationComponent,
    FileUploaderDirective,
    ConsultationCommentModal,
    ArtemisCocModalComponent,
    AgencyOfficesComponent,
    AgencyCitizensCharterComponent,
    AgencyOfficeModalComponent,
    ArtemisCustomDialogComponent,
    CocSubmissionsComponent,
    ArtemisCocPreviewPageArtaComponent,
    ArtemisHomePageServicesResultsComponent,
    AgencyOfficeDivisionComponent,
    AgencyOfficeDivisionModalComponent,
    ArtemisCocMasterlistComponent,
    NewServiceModalComponent,
    NewRequirementsModalComponent,
    NewClientStepsModalComponent,
    ArtemisLoadingAnimationComponent,
    UpdateExistingRegulationsModalComponent,
    ExistingCommentModalComponent,
    ArtemisCitizensCharterViewComponent,
    CitizensCharterServiceListViewComponent,
    CitizensCharterServiceViewComponent,
    RequirementControlComponent,
    SituationalRequirementControlComponent,
    SubsituationalRequirementControlComponent,
    ArtemisServiceRemarkComponent,
    ArtemisServiceRemarksThreadComponent,
    ArtemisCommentsComponent,
    ArtemisServiceScrollspyComponent,
    ArtemisAgencyMiniProfileComponent,
    ArtemisServiceViewComponent,
    RegulationsSearchComponent,
	ArtemisEditRemarkReplyComponent,
    ArtemisIdentificationListMaintenanceComponent,
    ArtemisOfficeControlComponent,
    ArtemisOfficeHierarchyControlComponent,
    ArtemisOfficeModelViewComponent,
    CitizensCharterProcessownerListComponent,
    ArtemisViewFeesComponent,
    PbrisFaqComponent,
    PbrisFaqSearchComponent,
    ArtemisRequirementBuilderComponent,
    ArtemisRequirementFormComponent,
    SearchPipe,
    ArtemisOfficeGroupingComponent,
    ArtemisRequirementNodeComponent,
    ArtemisChooseanyRequirementNodeComponent,
    ArtemisRequirementRowComponent,
    ArtemisServiceHistoryListComponent,
    ArtemisServiceHistoryViewComponent,
    DropdownCommentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig, FIREBASE_REF.TEMP_INSTANCE_DB_AUTH)),
    provideAuth(() => getAuth(getApp(FIREBASE_REF.TEMP_INSTANCE_DB_AUTH))),
    // AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,    
    AngularFireAnalyticsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatExpansionModule,
    FormsModule,
    PdfViewerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    ModalModule.forRoot(),
    NoopAnimationsModule,
    MatPasswordStrengthModule.forRoot(),
    HttpClientModule,
    DataTablesModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatTabsModule,
    MatTreeModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTableModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatListModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    EditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSortModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatMomentDateModule,
    DragDropModule,
    CurrencyMaskModule,
    MatSnackBarModule,
    MatCardModule,
    NgxPaginationModule,
    NgxOrgChartModule,
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // SweetAlert2Module.forRoot()
    QuillModule.forRoot(),
    // QuillConfigModule.forRoot({
    //   modules: {
    //     syntax: true,
    //     toolbar: ['bold']
    //   }
    // })
    MatChipsModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    MatDatepickerModule,MatNativeDateModule,SearchPipe,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKeys.v3,
    },
    CurrencyPipe,
    DecimalPipe,
    Title,
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
