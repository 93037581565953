import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { PdfRenderService } from 'src/app/pdf-render.service';
import { DocumentToJSONToTextFileEmailer } from 'src/app/service/documentToJSONToTextFile.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-view-charter',
  templateUrl: './view-charter.component.html',
  styleUrls: ['./view-charter.component.scss']
})
export class ViewCharterComponent implements OnInit {
  // id = this.route.snapshot.params.id
  id:any;
  isAdmin: boolean = false;

  charter: any = "";
  file: any;
  fileLoading:boolean = false;

  currentUser:any;
  currentAgency:any;

  isAgencyHead:boolean = false;
  isArta:boolean = false;
  isEncoder:boolean = false;
  isVerifier:boolean = false;

  charterQP:any = {}

  constructor(private route: ActivatedRoute,
    private afs: AngularFirestore,
    private pdf_render: PdfRenderService,
    public auth: AngularFireAuth,
    private router: Router,
    private toTextFile: DocumentToJSONToTextFileEmailer,
    ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next:(result)=>{
        if(result.cc_id){
          this.id = result.cc_id
          if (sessionStorage.getItem('arta_admin') == 'true') {
            this.isAdmin = true;
          }
      
          this.charterQP.cc_id = result.cc_id
          this.loadInfo(result.cc_id)
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }

  //page loader
  loadInfo(id: any) {
    let charter_id = id;
    this.charter = "";
    let tempArr :any = []
    
    // this.afs.doc(`citizens-charter-phase-01/${id}`).get()
    // .subscribe(
    //   (data) => {
    //     // if(data)
    //     if(data.exists){
    //       this.charter = data.data();
    //       this.charter.displayDate = (this.charter.date instanceof Timestamp ? format(this.charter.date.toDate(), 'MM/dd/yyyy') : this.charter.date )
    //       this.charter.fromPhase = "phase1"

    //       let path = 'citizen_charter20210511/' + data.get('filename');

    //       this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
    //         this.file = data; 
    //       });
    //     }
    //   });

    // this.afs.doc(`citizens-charter-phase-01/${id}`).get()
    this.afs.collection('citizens-charter-phase-01').doc(id).snapshotChanges()
    .subscribe({
      next:(data) => {
        // if(data)
        if(data.payload.exists){
          let charter:any = data.payload.data()
          this.charter.displayDate = (this.charter.date instanceof Timestamp ? format(this.charter.date.toDate(), 'MM/dd/yyyy') : this.charter.date )
          this.charter.fromPhase = "phase1"

          let path = 'citizen_charter20210511/' + charter.get('filename');

          firstValueFrom(this.pdf_render.getFileUrl(path)).then((data: any) => {
            this.file = data; 
          });
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    });

    this.afs.collection('citizens-charter-phase-02').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe({
      next:(info)=>{
        if(info.payload.exists){
          this.fileLoading = true
          let charter:any = info.payload.data()
          //console.log('charter: ',charter)
          // this.toTextFile.createTextFile(info.payload.id,charter)

          charter.id = info.payload.id
          charter.agency_name = (charter.organization_level_01 || charter.fromAgencyName) ? (charter.organization_level_01 || charter.fromAgencyName) : null
          charter.agency_abv = (charter.agency_initials || charter.keywords && charter.keywords.abbreviation) ? (charter.agency_initials || charter.keywords && charter.keywords.abbreviation) : null
          charter.agency_category = charter.organization_grouping_01 ? charter.organization_grouping_01 : (charter.agencyCategory && charter.agencyCategory.name) ? charter.agencyCategory.name : null
          charter.displayDate = (charter.date instanceof Timestamp ? format(charter.date.toDate(), 'MM/dd/yyyy') : charter.date )
          charter.fromPhase = "phase2"
          this.charter = charter
          if( charter.reportURL){
            this.file = charter.reportURL
          }

          // if(charter.service_id) this.loadServices(charter.service_id)
          if(charter.fromAgencyId) this.loadAgencyByID(charter.fromAgencyId)
          this.fileLoading = false
        } 
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })
  }
  //

  //get user info
  loadServices(service_ids:any){
    service_ids.map((id:any)=>{
      this.afs.collection('Services Phase 02', filter => filter.where('is_posted', '==', true)).doc(id).snapshotChanges()
      .pipe(first())
      .subscribe(info=>{
        if(info.payload.exists){
          let service:any = info.payload.data()
          service.id = info.payload.id

          if (this.currentAgency == null){
            this.loadAgency(service.serviceDetails.agency)
          }
        }
      })
    })
  }

  async loadAgency(agencyName:any){
    let tempArr:any =[]
    
    tempArr.push(await this.getAgency(agencyName,'name'))
    tempArr.push(await this.getAgency(agencyName,'agencyDetails.agencyName'))

    Promise.all(tempArr)
    .then((result:any[])=>{
      let nameArr:any=[]
      if(result && result.length > 0){
        result.forEach((data:any[])=>{
          if(data && data.length > 0){
            data.forEach((info:any)=>{
              if(this.currentAgency == null){
                this.currentAgency = info
                this.checkUser(info);
              }
              nameArr.push(info)
            })
          }
        })
      }
    })
  }

  getAgency(agencyName:any,find:any){
    return this.afs.collection('Agency').ref
    .where(find,'==',agencyName)
    .get()
    .then(info=>{
      if(info.size > 0){
        let nameArr:any[]=[]
        info.forEach(data=>{
          let agency:any = data.data()
          agency.id = data.id
          nameArr.push(agency)
        })
        return nameArr
      }else{
        return null
      }
    })
  }

  loadAgencyByID(agencyId:any){
    if(agencyId)
      this.afs.collection('Agency').doc(agencyId).snapshotChanges()
      .subscribe({
        next:(result)=>{
          if(result.payload.exists){
            const agency:any = result.payload.data()
            agency.id = result.payload.id
            this.currentAgency = agency
            this.checkUser(agency);
          }
        },
        error:(err)=>{
          console.error('Error: ',err)
        }
      })
  }

  async checkUser(agency:any){
    if(await this.auth.user){
      this.auth.onAuthStateChanged((user:any)=>{
        if(user.uid){
          this.afs.collection('Users').doc(user.uid).snapshotChanges()
          .pipe(first())
          .subscribe(data=>{
            if(data.payload.exists){
              let user:any = data.payload.data()
              user.id = data.payload.id
              if(user.credentials){

                if(agency != null
                  && user.credentials.account_type.trim().toLowerCase().includes("government")  
                  && user.credentials.account_subtype.trim().toLowerCase().includes('agency head') 
                  && user.government.agency == agency.id){
                  this.isAgencyHead = true
                }

                else if(user.credentials.account_subtype.trim().toLowerCase().includes('arta')){
                  this.isArta = true
                }

                else if(agency != null
                  && user.credentials.account_type.trim().toLowerCase().includes("government") 
                  && user.credentials.account_subtype.trim().toLowerCase().includes('verifier') 
                  && user.government.agency == agency.id){

                    this.isVerifier = true
                }

                else if(agency != null
                  && user.credentials.account_type.trim().toLowerCase().includes("government") 
                  && user.credentials.account_subtype.trim().toLowerCase().includes('encoder') 
                  && user.government.agency == agency.id){

                    this.isEncoder = true
                }
              }
              this.currentUser = user.id
            }
          })
        }
      })
      
    }
  }
  //

  triggerViewCount(count: number) {

    this.afs.doc(`citizens-charter-phase-01/${this.id}`).update({
      view_count: count,
    }).then((report: any) => {
      //console.log(report.view_count)
    }).catch(error => {
      alert(error.message);
    });
  }

  deletecharter() {
    this.afs.collection('citizens-charter-phase-01').doc(this.route.snapshot.params.id).delete().then(() => {

      this.router.navigate(['/artemis/citizen-charter/']).then(() => {
        alert('Charter Successfully Deleted');
      });

    });
  }

  goToCCReport(){
    //console.log('/artemis/citizen-charter/view/report'+'?',this.charterQP)
    this.router.navigate(['/artemis/citizen-charter/view/report'],{
      queryParams:this.charterQP
    })
  }
}
