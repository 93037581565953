

        <!-- Consultations-->
        <div class="{{style_theme}} service-card">
            <div class="card">
                <div class="card-header w-100">
                    <h2 class='text-primary card-header-text d-inline-block'>Services Finder</h2>
                    <button routerLink="/artemis/advanced-search"
                        class="btn btn-link float-end d-inline-block service-card-expand-toggle"><i
                            class="fas fa-expand-alt"></i></button>
                </div>
                <p class="card-header-divider"></p>
                <div class="card-body w-100">
                    <form [formGroup]="searchform">
                        <div class="form-group mb-1">
                            <label>Agency Category</label>
                            <!--<input type="text" formControlName="agencycategory" class="form-control d-block">-->
                            <select (change)="onCategoryChange($event)" name="agencycategory" formControlName="agencycategory" class="form-control d-block">
                                <option value="" disabled selected>- Select Category -</option>
                                <option *ngFor="let row of valueSuggestions.agency_category" value="{{row.name}}">
                                    {{row.name}}</option>
                            </select>
                        </div>
                       
                        <div class="form-group mb-1">
                            <label>Agency</label>
                            <select (change)="onAgencyChange($event)" name="agency" formControlName="agency" class="form-control d-block">
                                <option value="" disabled selected>- Select Agency -</option>
                                <option *ngFor="let row of agencyList" value="{{row.id}}">{{row.agencyDetails.agencyName}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group mb-1">
                            <label>Classification</label>
                            <!--<input type="text" formControlName="classification" class="form-control d-block">-->
                            <select (change)="filterAgencyServices()" name="classification" formControlName="classification" class="form-control d-block">
                                <option value="" selected="selected">All</option>
                                <option *ngFor="let row of valueSuggestions.classification" value="{{row.name}}">
                                    {{row.name}}</option>
                            </select>
                        </div>
                        <div class="form-group mb-1">
                            <label>Year</label>
                            <select (change)="filterAgencyServices()" name="Year" formControlName="year" class="form-control d-block">
                                <option value="" selected ="selected">All</option>
                                <option *ngFor="let row of datePostedList" value="{{row}}">
                                    {{row}}</option>
                            </select>
                        </div>
                        <div class="form-group mb-1">

                            <label>List of Services</label>
                            <!--<input type="text" formControlName="agency" class="form-control d-block">-->
                            <select name="service" formControlName="service" class="form-control d-block">
                                <option value=""  selected ="selected">All Services</option>
                                <option *ngFor="let row of serviceList" value="{{row.id}}">{{row.serviceDetails.service_name}}
                                </option>
                            </select>
                            <!--
                                <label>List of Services</label>
                            <input type="text" formControlName="service" class="form-control d-block">
                            -->
                        </div>

                        <div class="mt-4">
                            <!--<button (click)="formSubmit()" type="button" class="btn btn-light btn-block w-50 mx-auto">
                                Search
                            </button>-->
                            <button  (click)="sendSearchFilters()" class="btn btn-warning btn-block">
                                APPLY FILTER
                            </button>
                            <button mat-button  (click)="resetFields()" class="btn btn-dark  btn-block">
                                Reset Fields
                            </button>
                           
                        </div>
                    </form>
                </div>
            </div>
        </div>
   
