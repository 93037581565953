import { FormatWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { allMustBe } from '../validators';

export enum EntityBuilderIndex {
  SITUATIONAL_REQUIREMENT = 1,
  REQUIREMENT = 3,
  CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS = 7,
  REQUIREMENT_TYPE_DOCUMENT = 8,
  REQUIREMENT_TYPE_DOCUMENT_ORIGINAL = 9,
  REQUIREMENT_TYPE_DOCUMENT_PHOTO = 10,
  REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC = 11,
  REQUIREMENT_TYPE_OPERATOR = 12,
  REQUIREMENT_TYPE_OPERATOR_AND = 13,
  REQUIREMENT_TYPE_OPERATOR_OR = 14,
  REQUIREMENT_TYPE_PARENTHESIS = 15,
  REQUIREMENT_TYPE_PARENTHESIS_OPEN = 16,
  REQUIREMENT_TYPE_PARENTHESIS_CLOSE = 17,
  REQUIREMENT_TYPE_DOCUMENT_CERTIFIED_TRUE_COPY = 18,
  REQUIREMENT_CHOOSE_ANY = 20
}

@Injectable({
  providedIn: 'root'
})
export class RequirementBuilderService {

  constructor() { }

buildRequirement(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.REQUIREMENT_CHOOSE_ANY:

        let chooseAnyRequirement: UntypedFormGroup = new UntypedFormGroup({
          title : new UntypedFormControl(existing_obj?.title || '',Validators.required),
          requirement: new UntypedFormArray([
            this.buildRequirement(EntityBuilderIndex.REQUIREMENT),
            this.buildRequirement(EntityBuilderIndex.REQUIREMENT)
          ],allMustBe(Validators.required)),
        })
/* 
        if(existing_obj){

          existing_obj.requirement.forEach((requirement:any)=>{
              const req = chooseAnyRequirement.get('requirement') as FormArray
              req.push()
          }) 
        }

 */

        return chooseAnyRequirement
        
      case EntityBuilderIndex.SITUATIONAL_REQUIREMENT:
        let newSituationalRequirement = new UntypedFormGroup({
          requirement: new UntypedFormControl(existing_obj?.requirement || '',
            Validators.required),
          requirementType: new UntypedFormControl(
            existing_obj?.requirementType 
          ,Validators.required),
          copyFormat: new UntypedFormArray([
            this.buildRequirement(
              EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
              existing_obj
            )
          ]),
          listOfIds: new UntypedFormControl({ value: '', disabled: true }),
          copyFormatDisabled: new UntypedFormControl(true),
          number: new UntypedFormControl('', Validators.required),
          unit: new UntypedFormControl('', Validators.required),
          agency: new UntypedFormControl('', Validators.required), //,[Validators.required]
          bureau_division: new UntypedFormControl('', Validators.required), //,[Validators.required]
          remarks_or_reminders: new UntypedFormControl(''),
          applicant: new UntypedFormControl(existing_obj?.applicant || false),
        });
        return newSituationalRequirement;
      case EntityBuilderIndex.REQUIREMENT:
        let newStandardRequirement = new UntypedFormGroup({
          requirement: new UntypedFormControl(existing_obj?.requirement || '', Validators.required),
          requirementType: new UntypedFormControl(existing_obj?.requirementType ,Validators.required),
          copyFormat: new UntypedFormArray([
            this.buildRequirement(
              EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
              existing_obj
            )
          ]),
          listOfIds: new UntypedFormControl({ value: '', disabled: true }),
          copyFormatDisabled: new UntypedFormControl(true),
          number: new UntypedFormControl('', Validators.required),
          unit: new UntypedFormControl('', Validators.required),
          agency: new UntypedFormControl('', Validators.required), //,[Validators.required]
          bureau_division: new UntypedFormControl('', Validators.required), //,[Validators.required]
          remarks_or_reminders: new UntypedFormControl(''),
          applicant: new UntypedFormControl(existing_obj?.applicant || false),
        });
        return newStandardRequirement;
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT:{
          return new UntypedFormGroup({
            val: new UntypedFormControl({value:existing_obj.val || existing_obj.value || 'Original copy',disabled:false}),
            disabled:new UntypedFormControl(false),
            type:new UntypedFormControl('document'),
            copyCount: new UntypedFormControl({value: existing_obj.copyCount || 1,disabled:false}),
          })
        }

      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Original Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Photo Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Electronic Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_CERTIFIED_TRUE_COPY: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Certified True Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: existing_obj.val || existing_obj.value || 'And',
            disabled: false,
          }),
          disabled: new UntypedFormControl(false),
          type: new UntypedFormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'And',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_OR: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Or',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: existing_obj.val || existing_obj.value || '(',
            disabled: false,
          }),
          disabled: new UntypedFormControl(false),
          type: new UntypedFormControl('parenthesis'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_OPEN: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: '(',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('parenthesis'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_CLOSE: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: ')',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('parenthesis'),
        });
      }
      default:
        return new UntypedFormGroup({});
    }
  }
}
