import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { first, tap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { count } from 'console';
import { off } from 'process';


@Component({
  selector: 'app-artemis-coc-modal',
  templateUrl: './artemis-coc-modal.component.html',
  styleUrls: ['./artemis-coc-modal.component.scss'],

})
export class ArtemisCocModalComponent implements OnInit {

  //number formatting
  ordinals: string[] = ['th', 'st', 'nd', 'rd'];

  //variable declarations
  loader: any = false
  agency_logo: any;
  defaultImgSrc: any;
  personResponsible: any = [];
  offices: any = []
  agency: any;
  selectedPerson: any = { id: "", name: "", position: "" }

  //not required

  ccbBillboard = new UntypedFormControl('')
  ccbWebsite = new UntypedFormControl('')


  constructor(private afs: AngularFirestore, public dialogRef: MatDialogRef<ArtemisCocModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.agency) {
      //console.log('Agency Data', data.agency)
      this.agency = this.data.agency
      this.agency.cocYear = data.agency.createdAt.getFullYear();
    }
  }

  ngOnInit(): void {

    this.loadAgencymembers();
    this.loadLogo();
    this.loadOffices();
    this.selectedPerson.name = this.agency.personResponsible.name
    this.selectedPerson.position = this.agency.personResponsible.position
    this.ccbBillboard.setValue(this.agency.citizenscharterPosting.billboard)
    this.ccbWebsite.setValue(this.agency.citizenscharterPosting.online)

  }


  //format certificate of compliance edition and version
  transform(n: number, keepNumber: boolean = true) {
    let v = n % 100;
    return (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]);
  }


  loadLogo() {
    this.afs.collection('Agency').doc(this.agency.fromAgencyId).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data) {
            let item: any = data.payload.data();
            item.id = data.payload.id;
            if (item.agencyDetails != null) {
              this.agency_logo = item.agencyDetails.logoURL;
            }
          }
        }
      })
  }


  typecounter: any = [];

  formatOfficeName(office:any){

    switch(office){
      case 'Branch':
        return 'Branches';
      case 'Others':
        return 'Others'
      default:

      return office + "s"
    }
  }


  loadOffices() {

    this.afs
    .collection('Agency Office Hierarchy', 
    filter => filter.where('fromAgencyId','==',this.agency.fromAgencyId))
    .snapshotChanges()
    .pipe(tap((data:any)=>{
      this.offices=[]
      data.forEach((info:any)=>{

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
          var name = item.office.type == 'Others' ? item.office.others : item.office.type;


          if (this.offices.length <= 0) {
            const types: any = {
              name: name,
              number: 1
            }

            if(item?.isLowerLevelUnit){
              this.offices.push(types);
           }

            
          }else{

            const ifExistsIndex = this.offices.findIndex((office:any)=> office.name ==name);

            if(ifExistsIndex !== -1){
              this.offices[ifExistsIndex].number += 1;
            }else{
              const types: any = {
                name: name,
                number: 1
              }
              if(item?.isLowerLevelUnit){
                this.offices.push(types);
             }
            }
          }


      })
      

    })).subscribe()

   /*  this.afs.collection('Agency Offices',
      ref => ref
      .where("Status",'==',"Active")
      .where('fromAgencyId', '==', this.agency.fromAgencyId))
      .snapshotChanges()
      .pipe()
      .subscribe((data: any) => {

        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          var name = item.type;
          //console.log("type name", name);

          if (this.offices.length <= 0) {
            const types: any = {
              name: name,
              number: 1
            }
            this.offices.push(types);
          }else{

            const ifExistsIndex = this.offices.findIndex((office:any)=> office.name ==name);

            if(ifExistsIndex !== -1){
              this.offices[ifExistsIndex].number += 1;
            }else{
              const types: any = {
                name: name,
                number: 1
              }
              this.offices.push(types);
            }
          }

        });

        for(var office of this.offices){
          office.name = this.formatOfficeName(office.name)
        }
  }) */
}

loadAgencymembers() {
  this.afs.collection('Agency').doc(this.agency.fromAgencyId).collection('Head of Agency',
    filter => filter.orderBy("name")).snapshotChanges()
    .pipe(tap((data: any) => {

      data.forEach((info: any) => {
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        // console.log('Head of Agency',item)
        this.personResponsible.push({
          id: item.id, name: item.name, position: item.designation
        })

      })

    }))
    .subscribe();

  this.afs.collection('Agency').doc(this.agency.fromAgencyId).collection('Chairperson',
    filter => filter.orderBy("name")).snapshotChanges()
    .pipe(tap((data: any) => {

      data.forEach((info: any) => {
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        // console.log('Head of Agency',item)
        this.personResponsible.push({
          id: item.id, name: item.name, position: item.designation
        })

      })

    }))
    .subscribe();

  //console.log(this.personResponsible)
}


submit() {
  let flag: boolean = false;
  if (this.selectedPerson.name) {

    flag = true
  } else {
    alert("Plese select person responsible for this Certificate of Compliance")
    return
  }
  this.agency.personResponsible.name = this.selectedPerson.name
  this.agency.personResponsible.position = this.selectedPerson.position

  this.agency.citizenscharterPosting.billboard = this.ccbBillboard.value ? this.ccbBillboard.value : false
  this.agency.citizenscharterPosting.handbook = true,
    this.agency.citizenscharterPosting.online = this.ccbWebsite.value ? this.ccbWebsite.value : false

  this.agency.logoURL = this.agency_logo
  this.agency.offices = this.offices ? this.offices : []
  const returnServiceEntity = {
    submitFlag: flag,
    cocData: this.agency,
  };
  this.dialogRef.close(returnServiceEntity);
}







}
