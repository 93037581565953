<h2 mat-dialog-title>ARTA MC NO. 2021-10 ANNEX A (New COC Template)</h2>
<mat-dialog-content>


    <div class="ps-5">

        <div class="row mb-1 ">
            <div class="col"></div>
            <!--agency Logo-->
            <div class="col-2 float-end">
                <img class='' width="100" height="100"  *ngIf="agency_logo"
                            [src]="agency_logo">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-4">
                
            </div>
            <div class="col">
                <!--agency name-->
                <h4 class="text-center">{{agency.fromAgencyName? agency.fromAgencyName : "(AGENCY/LGU LETTERHEAD)" }}
                </h4>
                <strong>
                    <h3 class="text-center">CERTIFICATE OF COMPLIANCE
                        <br><span>YEAR:</span><span><u>{{agency.cocYear ? agency.cocYear : "2023"}}</u></span>
                    </h3>
                </strong>

            </div>
            <div class="col-4">

            </div>
        </div>




        <p><i>Pursuant to Republic Act No. 11032: An Act Promoting Ease of Doing Business and
                Efficient Delivery of Government Services, amending for the purpose Republic Act No. 9485, otherwise
                known as the Anti-Red Tape
                Act of 2007, and for Other Purposes</i></p>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Select Person Responsible</mat-label>
                <mat-select [(value)]="selectedPerson">
                    <mat-option>Person Responsible</mat-option>
                    <ng-container *ngFor="let item of personResponsible">
                        <mat-option [value]="item">{{item.name}}</mat-option>
                    </ng-container>


                </mat-select>
                <mat-hint>Required</mat-hint>
            </mat-form-field>
        </div>

        <p>I, <span><strong><u>{{selectedPerson.name ? selectedPerson.name : "Person Responsible"}}</u></strong></span>,
            Filipino, of legal
            age, <span><strong><u>{{selectedPerson.position? selectedPerson.position : "(position of the Head of
                        Agency/Local Chief
                        Executive/Head of the Committee on Anti-Red Tape Authority/duly authorized representative)
                        "}}</u></strong></span> of the
            <strong><u>{{agency.fromAgencyName ? agency.fromAgencyName : "the (name of agency/LGU)"}}</u></strong>, the
            person
            responsible and accountable in ensuring compliance with Section 6 of the R.A. 11032 or the <span><i>Ease
                    of Doing Business and Efficient Government Service Delivery Act of 2018, hereby declare and certify
                    the
                    following facts:</i></span>
        </p>
        <div class="container">
            <ol type="1">
                <li>
                    <p><span><strong><u>{{agency.fromAgencyName ? agency.fromAgencyName : "(Name of
                                    agency/LGU)"}}</u></strong></span> {{offices.length > 0 ? 'including its': '' }} <span
                            *ngFor="let office of offices">
                            <span><strong><u>{{office.number}} {{office.name}}</u>, </strong></span>
                        </span> has established its most current and
                        updated Citizen’s Charter
                        pursuant to Section 6 of R.A. 11032, its Implementing Rules and Regulations, and the relevant
                        ARTA
                        Issuances.
                    </p>
                    <div class="container">
                        <p>Citizen’ Charter Handbook Edition: <span><u>{{agency.citizenscharterVersion.year}} {{agency.citizenscharterVersion.edition}}<sup>{{transform(agency.citizenscharterVersion.edition,false)}}</sup> Edition</u></span>
                        </p>
                    </div>  
                </li>

                <li>
                    <p>The following required forms of posting of the Citizen’s Charter are present:</p>
                    <!-- Default checkbox -->
                    <div class="form-check">
                        <input class="form-check-input" [formControl]="ccbBillboard" type="checkbox" value=""
                            id="ccbBillboard" />
                        <label class="form-check-label" for="ccbBillboard">Citizen’s Charter Information billboard
                            <br><span><i>(In the
                                    form of
                                    interactive information kiosks, electronic billboards, posters, tarpaulins standees,
                                    others)</i></span></label>
                    </div>

                    <!-- Checked checkbox -->
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="cbCCHandbook" checked disabled />
                        <label class="form-check-label" for="cbCCHandbook">Citizen’s Charter Handbook
                            <br><span><i>(Aligned
                                    with
                                    Reference B of
                                    ARTA Memorandum Circular No. 2019-002) auto check</i></span></label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" [formControl]="ccbWebsite" type="checkbox" value=""
                            id="ccbWebsite" />
                        <label class="form-check-label" for="ccbWebsite">Official website/Online Posting</label>
                    </div>
                    <br>


                </li>
               

                <li>
                    <p>The Citizen’s Charter Information Billboard enumerates the following information:</p>
                    <ol type="a">
                        <li>External services;</li>
                        <li>Checklist of requirements for each type of application or request;</li>
                        <li>Name of the person responsible for each step;</li>
                        <li>Maximum processing time;</li>
                        <li>Fee/s to be paid, if necessary; and</li>
                        <li>Procedure for filing complaints and feedback.</li>
                    </ol>
                    <br>
                </li>

                <li>
                    <p>
                        The Citizen’s Charter Handbook enumerates the following information:
                    </p>
                    <ol type="a">
                        <li>Mandate, vision, mission, and service pledge of the agency;</li>
                        <li>Government services offered (External and Internal Services);
                            <ol type="I">
                                <li>Comprehensive and uniform checklist of requirements for each type of application or
                                    request;</li>
                                <li>Classification of service;</li>
                                <li>Type of transaction;</li>
                                <li>Who may avail; </li>
                                <li>Client steps and agency actions to obtain a particular service;</li>
                                <li>Person responsible for each step;</li>
                                <li>Processing time per step and total;</li>
                                <li>Fee/s to be paid per step and total, if necessary.</li>
                            </ol>
                        </li>
                        <li>Procedure for filing complaints and feedback;</li>
                        <li>Contact Information of ARTA, Presidential Complaints Center (PCC), and CSC Contact Center ng
                            Bayan in the complaints mechanism; and</li>
                        <li>List of Offices</li>
                    </ol>
                    <br>
                </li>
                <li>
                    <p>
                        The Citizen’s Charter Information Billboard is posted at the main entrance of the office or at the most conspicuous place of all the said service offices.
                    </p>
                </li>
                <li>
                    <p>
                        The printed Citizen’s Charter Handbook is placed at the windows/counters of each frontline offices to complement the information on the services indicated in the Information Billboard. 
                    </p>
                </li>
                <li>
                    <p>
                        The Citizen’s Charter Handbook version is uploaded on the website or any online platform available of the agency/LGU through a tab or link specifically for the Citizen’s Charter, located at the most visible space or area of the official website or the online platform available.
                    </p>
                </li>
                <li>
                    <p>
                        The Citizen’s Charter is written either in English, Filipino, and/or in the local dialect and published as an information material.
                    </p>
                </li>
                <li>
                    <p>
                        There is an established Client Satisfaction Measurement per service.
                    </p>
                </li>
            </ol>

        </div>
        <br>
        <p> 
            This certification is being issued to attest to the compliance of the agency with the foregoing statements that can be validated by the Authority.</p>

            <br>
            <br>

        <div class="col-3 mt-5 float-end">
            <div class="">
                <p class="text-center"> <strong> {{selectedPerson.name? selectedPerson.name : "Full Name"}} </strong>
                    <br>
                    {{selectedPerson.position? selectedPerson.position: "Position"}} <br>
                    {{agency.fromAgencyName ? agency.fromAgencyName : "Name of Agency"}}
                </p>

            </div>
        </div>


    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">

   <div *ngIf="!agency_logo">
    <p>Loading...</p>
   </div>
<div *ngIf="agency_logo">
    <button mat-stroked-button class="btn btn-warning" mat-dialog-close>Cancel</button>
    <button mat-flat-button [disabled]="selectedPerson.name == ''" color="primary" (click)="submit()"><strong>Generate Certificate of Compliance</strong></button>

</div>
</mat-dialog-actions>