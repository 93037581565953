import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { Timestamp } from 'firebase/firestore';

@Component({
    selector: 'app-ris-assessment-view-arta-remarks-modal',
    templateUrl: './ris-assessment-view-arta-remarks-modal.component.html',
    styleUrls: ['./ris-assessment-view-arta-remarks-modal.component.scss'],
})
export class PublicRiaAssessmentViewArtaRemarksModalComponent implements OnInit {
    remarksForm = new UntypedFormGroup({
        remarks: new UntypedFormControl('')
    })

    constructor(
        public dialogRef: MatDialogRef<PublicRiaAssessmentViewArtaRemarksModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        if(this.data.control.score.remarks){
            this.remarksForm.patchValue({
                remarks: this.data.control.score.remarks
            })
        }
    }

    closeModal(){
        this.dialogRef.close()
    }

    checkData(value:any){
        try{
            if(value){
                if(value instanceof Timestamp){
                    return value.toDate()
                }
                else{
                    if(value.seconds && value.nanoseconds){
                        const timestamp = new Timestamp(value.seconds, value.nanoseconds)
                        return timestamp.toDate()
                    }
                }
            }
            return ''
        }catch(err){
            console.error('value error: ',err)
            return ''
        }
    }
}