import { Component, OnInit } from '@angular/core';
import { ARTEMIS, PBRIS } from '../entities/constant';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  isAdmin: boolean = false;

  PBRISorARTEMIS: any;
  pageTitle: any;

  pbris = PBRIS
  artemis = ARTEMIS
  currentSystem:any = sessionStorage.getItem('currentSystem');

  constructor() { }

  ngOnInit(): void {
    if(sessionStorage.getItem("arta_admin")){
      if(sessionStorage.getItem("arta_admin") == "true") {
        this.isAdmin = true;
      }
    }
    this.pageTitle =''
  }

  setPage (pageName: string, PBRISorARTEMIS?: string) {
    this.pageTitle = pageName;
    this.PBRISorARTEMIS = PBRISorARTEMIS;
  }

}
