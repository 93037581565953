import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
  UntypedFormBuilder,
  Form,
  ValidatorFn,
  ValidationErrors,
  Validator,
  AbstractControl,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { map, valHooks } from 'jquery';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { tap, startWith } from 'rxjs/operators';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { v4 as uuidv4 } from 'uuid';
enum EntityBuilderIndex {
  AGENCY_ACTION = 2,
  AGENCY_ACTION_PERSON_RESPONSIBLE = 5,
  CLIENT_STEP_STANDARD_FEE = 6,
  CLIENT_STEP_LIST_OF_POSSIBLE_FEES = 7,
  CLIENT_STEP_LIST_OF_FORMULA_FEES = 8,
}

@Component({
  selector: 'app-new-client-steps-modal',
  templateUrl: './new-client-steps-modal.component.html',
  styleUrls: ['./new-client-steps-modal.component.scss'],
})
export class NewClientStepsModalComponent implements OnInit {
  
  checkClientStepForm = {
    agencyaction : [
      {type : 'required', message : "Requried"},
      {type: "duplicate",message : "Duplicate Person Responsible"}
    ]
  }
  
  panelOpenState = true;
  client_step_index: number = 1;
  iswithStandardFees: boolean = false;
  iswithPossibleFee: boolean = false;
  iswithoutFees: boolean = false;
  iswithFormulaFee: boolean = false;
  client_step_form: UntypedFormGroup;
  maxHours : number = 7

  agency_action_array: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NewClientStepsModalComponent>,
    private decimalPipe: DecimalPipe,
    private currPipe: CurrencyPipe,
    private store: AngularFireStorage,
    private snackBarService: SnackbarNotifService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.maxHours = this.data?.maxHours - 1 

    if (this.data?.clientStep) {
      this.client_step_index = this.data.rowNumber
      this.fillupClientStepForm(this.data.clientStep,this.data?.situational)
    } else {
      this.client_step_index = this.data.rowNumber
      this.setupClientStepForm(this.data?.situational)
    }

   
  
    this.allOffices = this.data.allOffices;
  }

  ngOnInit(): void {}


  setupClientStepForm(isSituational: boolean = false){
    this.is_situational_step = isSituational;
    this.client_step_form = this.fb.group({
      info: new UntypedFormGroup({
        title: new UntypedFormControl('', Validators.required),
        step_Id: new UntypedFormControl(uuidv4()),
        location: new UntypedFormControl('', Validators.required),
        notes: new UntypedFormControl(''),
        client_step_payment_option: new UntypedFormControl('', Validators.required),
        is_situational_step: new UntypedFormControl(isSituational, Validators.required),
        standard_fees: new UntypedFormControl(false, Validators.required),
        standard_fees_arr: new UntypedFormArray([]),
        list_of_possible_fees_arr: new UntypedFormArray([]),
        list_of_possible_fees: new UntypedFormControl(false, Validators.required),
        list_of_formula_fees_arr: new UntypedFormArray([]),
        list_of_formula_fees: new UntypedFormControl(false, Validators.required),
        fees: new UntypedFormControl('0'),
      }),
      agency_actions: new UntypedFormArray([
        this.buildNewEntry(EntityBuilderIndex.AGENCY_ACTION),
      ],[this.hasDuplicate("title")]),
    });

    if(isSituational){
      this.infoGroup.addControl('situation', new UntypedFormControl('',Validators.required))
    }
  }

  fillupClientStepForm(data: any,isSituational:boolean =false){
    const clientStep =data;
    //console.log(data)
    this.iswithoutFees =clientStep.info?.client_step_payment_option

    this.client_step_form = this.fb.group({
      info: new UntypedFormGroup({
        title: new UntypedFormControl(
          clientStep.info?.title || '',
          Validators.required
        ),
        step_Id: new UntypedFormControl(clientStep.info?.step_Id || uuidv4()) ,
        location: new UntypedFormControl(
          clientStep.info?.location || '',
          Validators.required
        ),
        notes: new UntypedFormControl(
          clientStep.info?.notes || ''
        ),
        client_step_payment_option: new UntypedFormControl(
          clientStep.info?.client_step_payment_option,
          Validators.required
        ),
        is_situational_step: new UntypedFormControl(
          clientStep.info?.is_situational_step || false,
          Validators.required
        ),
        standard_fees: new UntypedFormControl(clientStep.info?.standard_fees || false, Validators.required),
        list_of_possible_fees: new UntypedFormControl(clientStep.info?.list_of_possible_fees || false, Validators.required),
        list_of_formula_fees: new UntypedFormControl(clientStep.info?.list_of_formula_fees || false, Validators.required),
        standard_fees_arr: new UntypedFormArray([]),
        list_of_possible_fees_arr: new UntypedFormArray([]),
        list_of_formula_fees_arr: new UntypedFormArray([]),
        fees: new UntypedFormControl('0'),
      }),
      agency_actions: new UntypedFormArray([]),
    });
    if( clientStep.info?.is_situational_step){
      this.is_situational_step =true
      this.infoGroup.addControl('situation', new UntypedFormControl(clientStep.info?.situation || '',Validators.required))
      //this.infoGroup.addControl('situation', new FormControl(clientStep.info?.situation || '', Validators.required))
    }
    const withFee = !clientStep.info.client_step_payment_option;

    if (withFee) {
      this.iswithoutFees = false;
      if (clientStep.info.standard_fees) {
        this.infoGroup.controls['standard_fees']?.patchValue(true)

        this.iswithStandardFees = true
        this.standardFees.clear();

   
        clientStep.info.standard_fees_arr.forEach((fees: any) => {
          this.standardFees.push(
            this.buildNewEntry(
              EntityBuilderIndex.CLIENT_STEP_STANDARD_FEE,
              fees
            )
          );
        });
      }

      if (clientStep.info.list_of_possible_fees) {
        this.infoGroup.controls['list_of_possible_fees']?.patchValue(true)
        this.iswithPossibleFee = true
        this.PossibleFees.clear();

        clientStep.info.list_of_possible_fees_arr.forEach((fees: any) => {
          this.PossibleFees.push(
            this.buildNewEntry(
              EntityBuilderIndex.CLIENT_STEP_LIST_OF_POSSIBLE_FEES,
              fees
            )
          );
        });
      }

      if (clientStep.info.list_of_formula_fees) {
        this.infoGroup.controls['list_of_formula_fees']?.patchValue(true)
        this.iswithFormulaFee=true
        this.FormulaFees.clear();

        clientStep.info.list_of_formula_fees_arr.forEach((fees: any) => {
          this.FormulaFees.push(
            this.buildNewEntry(
              EntityBuilderIndex.CLIENT_STEP_LIST_OF_FORMULA_FEES,
              fees
            )
          );
        });
      }
    } else {
      this.disabledFeesCheckboxes()
      this.infoGroup.controls['client_step_payment_option']?.patchValue(true)
    }

    clientStep.agency_actions.forEach((agencyAction: any,index:number) => {
      let action = this.addAgencyAction(agencyAction);
      let persons = action.controls['persons_responsible'] as UntypedFormArray;
      persons.removeAt(0);

      agencyAction.persons_responsible.forEach((person: any) => {
        persons.push(
          this.buildNewEntry(
            EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE,
            person
          )
        );
      });
    });
  }

  disabledFeesCheckboxes(){
    this.infoGroup.controls['standard_fees'].disable();
      this.infoGroup.controls['list_of_possible_fees'].disable();
      this.infoGroup.controls['list_of_formula_fees'].disable();
  }

  checkboxStandardFeeChange(event: Event) {
    const value = (event.target as HTMLInputElement).checked
    this.iswithStandardFees=value
    this.checkFeesBox()
    if (value) {
      this.addStandardFee();
    } else {
      this.standardFees.clear();
    }
  }

  checkFeesBox(){
    if(this.infoGroup.get('standard_fees')?.value == false 
    && this.infoGroup.get('list_of_possible_fees')?.value == false 
    &&  this.infoGroup.get('list_of_formula_fees')?.value == false){
      this.iswithoutFees=true
      this.disabledFeesCheckboxes();
    }else{
      this.iswithoutFees =false
      this.enableFeesControl()
     
    }
  }

  checkboxPossibleFeeChange(event: Event) {
    const value = (event.target as HTMLInputElement).checked
    this.checkFeesBox()
    this.iswithPossibleFee=value
    if (value) {
      this.adPossibleFee();
    } else {
      this.PossibleFees.clear();
    }
  }

  checkboxFormulaFeeChange(event: Event) {
    const value = (event.target as HTMLInputElement).checked
    this.checkFeesBox()
    this.iswithFormulaFee=value
    if (value) {
      this.addFormulaFee();
    } else {
      this.FormulaFees.clear();
    }
  }

  checkboxChange(event: Event) {
    const value  = (event.target as HTMLInputElement).checked
    this.iswithoutFees =value
    if (!value) {
      this.enableFeesControl();
    }
  }

  invalidProcessingTime: boolean =false
 
  getAgencyActionProcessingTime(index: number, withTotal: boolean = false) {
    const days = this.agencyAction()
      .at(index)
      .get('processing_time_days')?.value ? this.agencyAction()
      .at(index)
      .get('processing_time_days')?.value : 0
    const hours = this.agencyAction()
      .at(index)
      .get('processing_time_hours')?.value ? this.agencyAction()
      .at(index)
      .get('processing_time_hours')?.value : 0;
    const minutes = this.agencyAction()
      .at(index)
      .get('processing_time_minutes')?.value ? this.agencyAction()
      .at(index)
      .get('processing_time_minutes')?.value  : 0
    if (withTotal) {
      return (
        (days > 0 ? days * 8 * 60 : 0) +
        (hours > 0 ? hours * 60 : 0) +
        (minutes > 0 ? minutes : 0)
      );
    } else {
      if((days + minutes + hours) <= 0 ) this.invalidProcessingTime =true
      else this.invalidProcessingTime =false
      return (
        (days ? days + ' day/s, ' : '') +
        (hours ? hours + ' hour/s, ' : '') +
        (minutes ? minutes + ' minute/s' : '')
      );
    }
  }

  getActionTitle(index: number) {
    return this.agencyAction().at(index).get('title')?.value;
  }

  cancelRemoveFees() {
    this.iswithoutFees = false;
    this.enableFeesControl();
  }

  enableFeesControl() {
    this.infoGroup.get('standard_fees')?.enable();
    this.infoGroup.get('list_of_possible_fees')?.enable();
    this.infoGroup.get('list_of_formula_fees')?.enable();
  }

  removeAllFees() {
    this.iswithStandardFees = false;
    this.iswithPossibleFee = false;
    this.iswithFormulaFee = false;
    this.iswithoutFees = true;
    this.PossibleFees.clear();
    this.standardFees.clear();
    this.FormulaFees.clear();
    this.infoGroup.controls['standard_fees']?.patchValue(false)
    this.infoGroup.controls['list_of_possible_fees']?.patchValue(false)
    this.infoGroup.controls['list_of_formula_fees']?.patchValue(false)
    this.infoGroup.get('standard_fees')?.disable();
    this.infoGroup.get('list_of_possible_fees')?.disable();
    this.infoGroup.get('list_of_formula_fees')?.disable();
  }

  get infoGroup(): UntypedFormGroup {
    return this.client_step_form.controls['info'] as UntypedFormGroup;
  }

  get FormulaFees(): UntypedFormArray {
    return this.infoGroup.get('list_of_formula_fees_arr') as UntypedFormArray;
  }

  addFormulaFee() {
    const Ffees = this.buildNewEntry(
      EntityBuilderIndex.CLIENT_STEP_LIST_OF_FORMULA_FEES
    );
    this.FormulaFees.push(Ffees);
  }

  removeFormulaFee(index: number) {

    this.snackBarService.confirmDialog("Remove Formula/Schedule of Fees").then(result => {
      if (result){
        this.FormulaFees.removeAt(index);
      }
   })
  }

  get PossibleFees(): UntypedFormArray {
    return this.infoGroup.get('list_of_possible_fees_arr') as UntypedFormArray;
  }

  adPossibleFee() {
    const pFee = this.buildNewEntry(
      EntityBuilderIndex.CLIENT_STEP_LIST_OF_POSSIBLE_FEES
    );
    this.PossibleFees.push(pFee);
  }

  removePossibleFee(index: number) {

    this.snackBarService.confirmDialog("Remove Possible Fee").then(result => {
      if (result){
        this.PossibleFees.removeAt(index);
      }
   })
    
  }

  get standardFees(): UntypedFormArray {
    return this.infoGroup.get('standard_fees_arr') as UntypedFormArray;
  }

  addStandardFee() {
    const stfee = this.buildNewEntry(
      EntityBuilderIndex.CLIENT_STEP_STANDARD_FEE
    );
    this.standardFees.push(stfee);
  }
  removeStandardFee(index: number) {

    this.snackBarService.confirmDialog("Remove Fee").then(result => {
      if (result){
        this.standardFees.removeAt(index);
      }
   })


    
  }

  persons_responsible(index: number): UntypedFormArray {
    return this.agencyAction()
      .at(index)
      .get('persons_responsible') as UntypedFormArray;
  }

  addPersonResponsible(index: any) {
    const responsible = this.buildNewEntry(
      EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE
    );
    this.persons_responsible(index).push(responsible);
  }

  removePersonResponsible(index: number, pindex: number) {

    this.snackBarService.confirmDialog("Remove Person Responsible").then(result => {
      if (result){
        this.persons_responsible(index).removeAt(pindex);
      }
   })
   
  }

  //Agency Action Controls
  agencyAction(): UntypedFormArray {
    return this.client_step_form.get('agency_actions') as UntypedFormArray;
  }

  addAgencyAction(existingData: any = null,insert:boolean =false,index : number = 0) {
    const action = this.buildNewEntry(
      EntityBuilderIndex.AGENCY_ACTION,
      existingData
    );

    if(insert){
      //insert empty agency action
      this.agencyAction().controls.splice(index,0 ,action)

    }else{

      this.agencyAction().push(action);

    }
    
    return action;
  }

  removeAgencyAction(actionIndex: number) {

    this.snackBarService.confirmDialog("Remove Agency Action").then(result => {
      if (result){
        this.agencyAction().removeAt(actionIndex);
      }
   })
    
  }

  isAgencyActionComplete(index:number){

    const agencyAction :any = this.agencyAction().at(index)
    if(agencyAction.get('processing_time_days')?.value == 0 && agencyAction.get('processing_time_hours')?.value == 0 && agencyAction.get('processing_time_minutes')?.value == 0 )return false
    else return this.agencyAction().at(index).valid
  }
  
  formatProcessingTime(timeVal: string, timeInputType: string) {
    // Allow leading zeroes in processing time
    timeVal = timeVal? timeVal : '0'
    const retVal = this.decimalPipe.transform(
      timeVal.replace(/\D/g, '').replace(/^0+(?=\d+)/, ''),
      '1.0-2'
    );

    const timeValNum = retVal ? +retVal : null;
    switch (timeInputType) {
      case 'days':
        return {
          processing_time_days: retVal || '0',
        }
      case 'hours':
        if (timeValNum && timeValNum > this.maxHours) {
          return {
            processing_time_hours: this.maxHours,
          };
        }
        else {
          return {
            processing_time_hours: retVal || '0',
          };
        }
      case 'minutes':
        if (timeValNum && timeValNum > 59) {
          return {
            processing_time_minutes: '59',
          };
        }
        else {
          return {
            processing_time_minutes: retVal || '0',
          };
        }
      default:
        return {};
    }
  }

  exceedsMaxDays: boolean = false;
  is_situational_step:boolean=false

  private maxDaysMap: any = {
    "Simple": 3,
    "Complex": 7,
    "Highly Technical": null,
    // By law, Highly Technical services should not exceed 20 days, but ARTA has requested not to impose a hard limit yet
    "By Law Highly Technical": 20,
  }

 getAgencyActionTotalProcessingTime(agencyAction: UntypedFormGroup){
  const days = agencyAction.get('processing_time_days')?.value ? agencyAction.get('processing_time_days')?.value : 0;
    const hours = agencyAction.get('processing_time_hours')?.value ? agencyAction.get('processing_time_hours')?.value: 0;
    const minutes = agencyAction.get('processing_time_minutes')?.value ? agencyAction.get('processing_time_minutes')?.value : 0;
    agencyAction.get('total_processing_time')?.patchValue((days ? days + ' day/s, ' : '') +
    (hours ? hours + ' hour/s, ' : '') +
    (minutes ? minutes + ' minute/s' : ''))
    
 }

 

 //To Do: Custom Validators for inputs
  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.AGENCY_ACTION:
        let newAgencyAction = new UntypedFormGroup({
          action_Id: new UntypedFormControl(existing_obj?.action_Id || uuidv4()) ,
          title: new UntypedFormControl(
            existing_obj?.title || '',
            Validators.required
          ),
          persons_responsible: new UntypedFormArray([
            this.buildNewEntry(
              EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE
            ),
          ], [this.hasDuplicate("designation",true)]),
          processing_time_days: new UntypedFormControl(
            existing_obj?.processing_time_days || '0' ,
            [Validators.required,Validators.min(0)]
          ),
          processing_time_hours: new UntypedFormControl(
            existing_obj?.processing_time_hours || '0' ,
           [ Validators.required,Validators.max(this.maxHours),Validators.min(0)]
          ),
          processing_time_minutes: new UntypedFormControl(
            existing_obj?.processing_time_minutes || '0' ,
            [ Validators.required,Validators.max(59),Validators.min(0)]
          ),
          total_processing_time: new UntypedFormControl(existing_obj?.total_processing_time || '', Validators.required),
        });

         // Integer pipe
         newAgencyAction.get('processing_time_days')?.valueChanges.subscribe((value: any) => {
          newAgencyAction.patchValue(
            this.formatProcessingTime(value ? value : '0', 'days'),
            { emitEvent: false }
          );

         this.getAgencyActionTotalProcessingTime(newAgencyAction)
        });

        newAgencyAction.get('processing_time_hours')?.valueChanges.subscribe((value: any) => {
          newAgencyAction.patchValue(
            this.formatProcessingTime(value ? value : '0', 'hours'),
            { emitEvent: false }
          );

          this.getAgencyActionTotalProcessingTime(newAgencyAction)
        });

        newAgencyAction.get('processing_time_minutes')?.valueChanges.subscribe((value: any) => {
          newAgencyAction.patchValue(
            this.formatProcessingTime(value ? value : '0', 'minutes'),
            { emitEvent: false }
          );

          this.getAgencyActionTotalProcessingTime(newAgencyAction)

        });

        this.getAgencyActionTotalProcessingTime(newAgencyAction)

        return newAgencyAction;

      case EntityBuilderIndex.AGENCY_ACTION_PERSON_RESPONSIBLE:
        let newPersonResponsible = new UntypedFormGroup({
          designation: new UntypedFormControl(
            existing_obj?.designation || '',
            Validators.required
          ),
          division_group: new UntypedFormControl(
            existing_obj?.division_group || '',
            Validators.required
          ),
        });
        return newPersonResponsible;
      case EntityBuilderIndex.CLIENT_STEP_STANDARD_FEE:
        let newStandardFee = new UntypedFormGroup({
          fee_type: new UntypedFormControl(
            existing_obj?.fee_type || '',
            Validators.required
          ),
          currency: new UntypedFormControl(existing_obj?.currency
             || 'PHP',Validators.required),
          amount: new UntypedFormControl(
            existing_obj?.amount || '',
            Validators.required
          ),
        });

        newStandardFee.get('amount')?.valueChanges.subscribe(()=>{
          this.calculateStandardFees()
        })

        return newStandardFee;
      case EntityBuilderIndex.CLIENT_STEP_LIST_OF_POSSIBLE_FEES:
        let newPossibleFee = new UntypedFormGroup({
          fee_type: new UntypedFormControl(
            existing_obj?.fee_type || '',
            Validators.required
          ),
          currency: new UntypedFormControl(existing_obj?.currency
             || 'PHP',Validators.required),
          amount: new UntypedFormControl(
            existing_obj?.amount || '',
            Validators.required
          ),
        });

        return newPossibleFee;

        case EntityBuilderIndex.CLIENT_STEP_LIST_OF_FORMULA_FEES:
          let newFormulFees = new UntypedFormGroup({
            fee_type: new UntypedFormControl(
              existing_obj?.fee_type || '',
              Validators.required
            ),
            amount: new UntypedFormControl(
              existing_obj?.amount || '',
              Validators.required
            ),
          });
  
          return newFormulFees;
      default:
        return new UntypedFormGroup({});
    }
  }

  saveClientStep() {

    if(this.client_step_form.valid){

      if(this.is_situational_step){
        if(this.infoGroup.get('situation')?.value == ''){
          this.infoGroup.get('situation')?.markAllAsTouched();
          this.infoGroup.get('situation')?.markAsDirty()
          this.snackBarService.displayNotif('Error','Please input situation.')
          return
        }
      }

      const action  = this.agencyAction()

      for(let i = 0 ; i < action.length; i ++){
        if(!this.isAgencyActionComplete(i)){
          const data = this.agencyAction().at(i)
          this.snackBarService.displayNotif('error','Invalid processing time on client step ' + data.get('title')?.value )
          return
        }
      }
      if(this.iswithoutFees == false || this.infoGroup.value.client_step_payment_option == false){
        if(this.infoGroup.value.standard_fees == false && this.infoGroup.value.list_of_possible_fees == false && this.infoGroup.value.list_of_formula_fees ==false ){
          this.snackBarService.displayNotif('Error','Please input fees.')

          //do not close the client step form if no payment is unchecked but no any fees ancoded.
          return;
        }
      }

      if(this.infoGroup.value.standard_fees){
        if(this.infoGroup.value.standard_fees_arr.length <= 0){
          this.snackBarService.displayNotif('Error','Standard fees was checked. Please add standard fees.')

          //do not close the client step form if standard fees is checked but no standard fees encoded
          return;
        }
      }
      if(this.infoGroup.value.list_of_possible_fees){
        if(this.infoGroup.value.list_of_possible_fees_arr.length <= 0){
          this.snackBarService.displayNotif('Error','Standard fees was checked. Please add standard fees.')

          //do not close the client step form if list of possible fees is checked but no possible fees encoded
          return;
        }
      }

      if(this.infoGroup.value.list_of_formula_fees){
        if(this.infoGroup.value.list_of_formula_fees_arr.length <= 0){
          this.snackBarService.displayNotif('Error','Standard fees was checked. Please add standard fees.')

          //do not close the client step form if list of formula fees is checked but no formula fees encoded
          return;
        }
      }

      this.snackBarService.openPlainNotif('Client Step Saved!','close')

    }else{

      this.snackBarService.displayNotif('Error','Client Step not Save. Incomplete or Invalid inputs found.')
      this.client_step_form.markAllAsTouched()
      this.client_step_form.markAsDirty()
      //do not save client step if not complete
      return;
    }
    

    let returnServiceEntity = {
      submitFlag: true,
      data: this.client_step_form.value,
    };
    this.dialogRef.close(returnServiceEntity);
    
  }

  situationalStepChange(){
      
  if(this.is_situational_step== false)this.infoGroup.removeControl('situation')

  else this.infoGroup.addControl('situation', new UntypedFormControl('',Validators.required))
  
  }

  cancel() {
    let returnServiceEntity = {
      submitFlag: false,
    };
    this.dialogRef.close(returnServiceEntity);
  }

  calculateStandardFees() {
    let total = 0;
    var temp:any[] =[]

    for (let control of this.standardFees.controls){
      let fee:any ={
        type: control.get('fee_type')?.value,
        amount: Number(control.get('amount')?.value),
        currency: control.get('currency')?.value
      }
      temp.push(fee)
    }


    const output = temp

    const sum = output.reduce((acc,cur)=>{
      const found = acc.find((val:any) => val.currency === cur.currency)
      if(found) found.amount += Number(cur.amount)
      else acc.push({...cur,amount: Number(cur.amount)})

      return acc
    },[])


    /**
     * for (let control of this.standardFees.controls) {
      total += control.get('amount')?.value;
    }
     */
    this.infoGroup.controls['fees']?.patchValue(sum);
    return sum;
  }

  duplicates = []

  hasDuplicate(key_control :any,withdivision: boolean = false) : ValidatorFn {

    let div_group = "division_group"
    return ( control : AbstractControl ) : ValidationErrors | null => {

      if (control instanceof UntypedFormArray ) {
          
          let tempArr: any = [];
          let errors = {
              hasDuplicatePersonResponsible : false
          }

          let tempbool :boolean =false;

          control.controls.forEach((item,index)=>{

            let unprocessedErrors = ((control.controls[index] as UntypedFormGroup).controls[key_control] as UntypedFormControl).errors;
            let processedErrors = this.removeErrors(unprocessedErrors,['duplicate']);

            ((control.controls[index] as UntypedFormGroup).controls[key_control] as UntypedFormControl).setErrors(processedErrors);
            if(withdivision) ((control.controls[index] as UntypedFormGroup).controls[div_group] as UntypedFormControl).setErrors(processedErrors);
            
            let designation : string = item.get(key_control)?.value.toLowerCase().replace(/\s/g,"")

           /*  if(withdivision){
              let division : string = item.get(div_group)?.value.toLowerCase().replace(/\s/g,"");
              designation=designation + division
            } */

           /*  if(designation && designation != '' && designation != undefined && designation != null){
              const ctr =tempArr.map((item:any)=> item.designation.indexOf(designation));
            
              if(ctr >= 0){
                tempArr[ctr].value = tempArr[ctr].value + 1;
                tempArr[ctr].indexes.push(index);
                tempbool =true;
                errors.hasDuplicatePersonResponsible =true;
              }else{
                tempArr.push({designation : designation,value : 1,indexes: [index]})
              }
            } */


          })

          tempArr.forEach((item:any)=>{
            if(item.indexes.length > 1){
              item.indexes.forEach((item2:any)=>{
                ((control.controls[item2] as UntypedFormGroup).controls[key_control] as UntypedFormControl).setErrors({duplicate: true});
                if(withdivision) ((control.controls[item2] as UntypedFormGroup).controls[div_group] as UntypedFormControl).setErrors({duplicate: true});
              })
            }
          })

       
          return tempbool ? errors : null

      }

      return null


    }
    

  }

  removeErrors(errors:any,toRemove:any[]){
    if(!errors || Object.keys(errors).length == 0) return null
    if(toRemove.length > 0){
      toRemove.forEach((item)=>{
        delete errors[item]
      })
    }
    if(Object.keys(errors).length == 0) return null
    return errors
  }

  hasError(control: any, validType:string){
    return ((control.controls['designation'].hasError(validType))
      && (control.dirty
      || control.touched))
  }


  allOffices:any= []

 

  
}
