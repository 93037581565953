import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfRenderService } from '../../../../../pdf-render.service';
import { Input, Output, EventEmitter } from '@angular/core';
import {
  addDays,
  format,
  isSameDay,
  isSameMonth,
  isThisISOWeek,
} from 'date-fns';
import { take, tap, first, map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import {
  GovernmentAccountSubtype,
  WorklistActionFlagPBRISRegulation,
  WorklistAgencyActionMappedStatusPBRISRegulation,
  WorklistArtaActionMappedStatusPBRISRegulation,
  WorklistStatusPBRISRegulation,
} from 'src/app/entities/worklist';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { forkJoin, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from 'firebase/firestore';
import { RegulationViewConfirmationModalComponent } from '../regulation-view-confirmation-modal/regulation-view-confirmation-modal.component';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { PBRISEmailer } from 'src/app/service/pbris-emailer.service';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegulationViewProposedArtaRemarks } from './regulation-view-proposed-arta-remarks-modal/regulation-view-proposed-arta-remarks-modal.component';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-regulation-view-proposed',
  templateUrl: './regulation-view-proposed.component.html',
  styleUrls: ['./regulation-view-proposed.component.scss'],
})
export class RegulationViewProposedComponent implements OnInit {
  /**
   * levels of access
   */
  user: any;

  // id = this.route.snapshot.params.id
  isAdmin: boolean = false;
  isOwner: boolean = false;
  isEncoder: boolean = false;

  // status: string = "non-specific";
  // regulation: any = "";
  file: any;

  style_theme: any = 'theme-default'; //current theme

  userType: any = sessionStorage.getItem('user_type');

  /*Output and Input for Inbox View */
  @Output() toInboxComponent = new EventEmitter<boolean>();
  @Input() paramFromInboxList = '';
  @Input() docuFromInboxList = '';
  @Input() constExistingRegulation = '';

  // regulations-existing-phase-02 or regulations-proposed-phase-02
  @Input() regulationInboxCollectionName: string = '';
  // Worklist-Existing Regulations or Worklist-Proposed Regulations
  @Input() regulationInboxWorklistCollectionName: string = '';

  current_regulation: any;
  current_instrument: any;
  /** */
  isSidebarVisible: boolean = true;

  /** page elements */
  reg_long_name: any;
  reg_short_name: any;
  reg_date: any;

  reg_section_name: any;
  reg_subject: any;

  //document Preview Details
  reg_documentTitle: any;
  reg_dateGenerated: any;
  reg_dueDate: any;
  reg_fromAgency: any;
  reg_fromOfficer: any;
  // TODO: Add ARTA users to the worklist
  reg_fromQaARTA: any;
  reg_fromApproverARTA: any;
  reg_status: any;
  reg_text: any;
  reg_text_annex_file: any;
  reg_display_title: string = '';
  reg_display_text: string = '';
  selected_section: any;
  selected_subsection: any;

  // Policy Details:

  // agency details
  reg_agency: any = [];
  reg_agency_name: any;

  //others
  reg_parent_instrument_id: any;
  reg_instrument_name: any;

  reg_has_draft_text: boolean = false;
  reg_pref_pol: any;
  reg_pref_pol_impact: any;
  reg_pref_pol_cost_comp: any;

  reg_lens_comp: any;
  reg_lens_disaster: any;
  reg_lens_environment: any;
  reg_lens_inclusive: any;
  reg_significance: any;

  reg_comment_details: any = '';
  reg_comment_regtext: any = '';
  reg_comment_pia: any = '';
  reg_comment_self_assessment: any = '';

  reg_summary: any;
  reg_problem: any;
  reg_objectives: any;
  reg_lenses: any;

  reg_tag_classification: any;
  reg_tag_sector: any;
  reg_tag_stage_of_business: any;
  reg_tag_stage_of_life: any;
  reg_tag_life_event: any;
  reg_tag_division: any;
  reg_tag_jurisdiction: any;
  reg_tag_case_use: any;
  reg_other_remarks: any;
  reg_docId: any;

  reg_completed_consultations: any[] = [];
  total_consultations_count = 0;
  paramCrisID = '';

  hasCris: boolean = false
  crisId: any = ''
  crisData: any;
  consultationsData: any;
  hasDris: boolean = false
  prePostLoaded: boolean = false
  drisId: any = ''
  page_info: any;

  pis_assessment: any;
  flagged_sections: UntypedFormArray;
  flaggedSectionIndexes: number[] = [];

  // get firebase_folder of regulation info
  private firebase_folder: string = '';

  enum_regulation_status = WorklistStatusPBRISRegulation;
  enum_action_flag = WorklistActionFlagPBRISRegulation;
  // RIS Scoring
  ris_allow_action: boolean = false;
  ris_allow_agency: boolean = false;
  ris_allow_view: boolean = false;
  ris_final_assessment: boolean = false;
  show_for_reconsideration: boolean = false;

  displayedColumns: string[] = ['section', 'rating', 'remarks'];
  displayedColumnsRisDocs: string[] = ['docname', 'action', 'remarks'];

  //user info
  currenUser: any;

  ris_scoring_test = this.fB.array([
    new UntypedFormGroup({
      control: new UntypedFormControl('policyProblemAndNeedForGovernmentAction'),
      section: new UntypedFormControl('Policy Problem and Need for Government Action'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('policyObjective'),
      section: new UntypedFormControl('Policy Objective'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('policyOptions'),
      section: new UntypedFormControl('Policy Options'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('assessmentOfPolicyOptions'),
      section: new UntypedFormControl('Assessment of Policy Options'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('consultation'),
      section: new UntypedFormControl('Consultation'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('recommendedOption'),
      section: new UntypedFormControl('Recommended Option'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('implementationAndEnforcement'),
      section: new UntypedFormControl('Implementation and Enforcement'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('monitoringAndEvaluation'),
      section: new UntypedFormControl('Monitoring and Evaluation'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(1)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType1Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('overallRISRating'),
      section: new UntypedFormControl('OVERALL RIS RATING'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(0, Validators.min(8)),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      oneCheck: new UntypedFormControl(0),
      zeroCheck: new UntypedFormControl(0),
      rating_text: new UntypedFormControl('Finish Choosing Rating . . .'),
      computedRatingText: new UntypedFormControl(''),
      overall_score_flag: new UntypedFormControl(true),
      // control: '',
      optionsType: new UntypedFormControl('assesmentOverallRatingOptions'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('tagging'),
      section: new UntypedFormControl('Tagging'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl(''),//,[Validators.required]
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl(''),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('relatedIssuances'),
      section: new UntypedFormControl('Related Issuances'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl('', [Validators.required]),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType2Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('textOfProposedRegulation'),
      section: new UntypedFormControl('Text of Proposed Regulation'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl('', [Validators.required]),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType3Options'),
    }),
    new UntypedFormGroup({
      control: new UntypedFormControl('pbrisConsultation'),
      section: new UntypedFormControl('PBRIS Consultation'),
      url: new UntypedFormControl(''),
      score: new UntypedFormGroup({
        rating: new UntypedFormControl('', [Validators.required]),
        previous_remarks: this.fB.array([]),
        remarks: new UntypedFormControl(''),
      }),
      overall_score_flag: new UntypedFormControl(false),
      // control: '',
      optionsType: new UntypedFormControl('assesmentType3Options'),
    }),

  ])


  oneMBinByte = 1000000
  ris_documents_test = this.fB.array([])

  og_ris_documents: any = {
    risAssessment: {
      count: 0
    },
    coverLetter: {
      count: 0
    }
  }

  acceptedFileType: any = [
    'application/pdf', 'application/vnd.ms-excel', 'application/msword', 'text/csv"',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]
  ris_documents_new = new UntypedFormGroup({
    // risAssessment: new FormGroup({
    //   count: new FormControl(0,[Validators.min(1),Validators.max(1)]),
    //   type: new FormControl('',this.checkUploadType()),
    //   size: new FormControl(0,Validators.max(this.oneMBinByte*10)),
    // }),
    // coverLetter: new FormGroup({
    //   count: new FormControl(0,[Validators.min(1),Validators.max(1)]),
    //   type: new FormControl('',this.checkUploadType()),
    //   size: new FormControl(0,Validators.max(this.oneMBinByte*10)),
    // })
  })

  checkRisDocumentsTest = {
    all: [
      { type: 'min', message: 'No Uploaded file' },
      { type: 'max', message: 'Invalid multiple file upload' },
      { type: 'type', message: 'Incorrect uploaded file type' },
      { type: 'maxSize', message: 'File size exceeded allowed limit' },
    ],
  }

  basicRemarkGroup = new UntypedFormGroup({
    value: new UntypedFormControl(''),
    remark: new UntypedFormControl('')
  })

  assesmentType1Options: any = [
    { title: 'GOOD QUALITY', value: 5, color: 'green' },
    { title: 'SUFFICIENT QUALITY', value: 3, color: 'yellow' },
    { title: 'POOR QUALITY', value: 1, color: 'red' },
  ]

  assesmentOverallRatingOptions: any = [
    {
      title: 'BETTER PRACTICE',
      range: {
        from: 40, to: 40
      },
      color: 'blue',
    },
    {
      title: 'GOOD PRACTICE',
      range: {
        from: 30, to: 39
      },
      color: 'green',
    },
    {
      title: 'SATISFACTORY PRACTICE',
      range: {
        from: 24, to: 29
      },
      color: 'yellow',
    },
    {
      title: 'INSUFFICIENT PRACTICE',
      range: {
        from: 8, to: 23
      },
      color: 'red',
    },
  ]

  assesmentType2Options: any = [
    { title: 'CONSISTENT', color: 'black' },
    { title: 'NEEDS REVISION', color: 'red' },
  ]

  assesmentType3Options: any = [
    { title: 'CONSISTENT', color: 'black' },
    { title: 'INCONSISTENT', color: 'red' },
  ]

  risAssessmentForReconsideration: boolean = false;

  public showRisAssessmentDocumentFileUploadErrors: boolean = false;
  public showCoverLetterFileUploadErrors: boolean = false;
  display_ris_assessment_document_file_name: string = '';
  display_cover_letter_file_name: string = '';
  ris_assessment_document_filepath: any;
  cover_letter_filepath: any;
  ris_assessment_document_link: string = '';
  cover_letter_link: string = '';
  hasFileUploaded: boolean = false;

  actionButtonAllowed: boolean = false;
  actionMade: any;
  actionFlag: any;

  private worklistAllowedActionButtonFilters = new Map<string, string[]>([
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_ENC,
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_VER,
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_DIR,
    WorklistAgencyActionMappedStatusPBRISRegulation.AGENCY_ADMIN,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_ADMIN,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_CMEO_PO,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_CMEO_DC,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_CMEO_DIR,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_BRO_PO,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_BRO_DC,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_BRO_DIR,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_DDG,
    WorklistArtaActionMappedStatusPBRISRegulation.ARTA_DG,

  ]);

  actionFilter: any = []

  commentButtonsEnabled: boolean = false;

  showViews: any = {
    viewComments: false,
    phase2View: false,
    summaryView: false,
    textRegView: false,
    piaView: false,
    crisView: false,
    crisTextRegView: false,
    crisCommentsView: false,
    pisAssessmentView: false,
    pisAssessmentSummaryView: false,
    risAssessmentView: false,
    finalRegView: false,
    prePostingView: false,
    drisUploadsView: false,
    drisTextRegView: false,
    crisUploadsView: false,
    drisPhase2View: false,
    drisSummaryView: false,
    crisConsultations: false,
    crisSummaryView: false,
  }

  rulestage: any;

  reg_text_annex_pagging: any = {
    annex_file_total_page: 1,
    annex_file_page: 1
  };

  baseCode: string;
  drisUploads: any;
  crisUploads: any;

  regulationsData: any = {
    worklist: {},
    INITIAL: {},
    CRIS: {},
    DRIS: {},
  }

  loadedData: any = {

  }

  // check if RIS assesement
  // load if there are any RIS assessment
  // PO - create/can edit if currently han
  // DC
  // DR
  // DG
  // DDG
  // Agency head can see after returned
  // 

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private pdf_render: PdfRenderService,
    private router: Router,
    public dialog: MatDialog,
    public auth: AngularFireAuth,
    private store: AngularFireStorage,
    private nE: NotificationsEmailer,
    private pEmail: PBRISEmailer,
    private fB: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.setTheme();
    this.loadUser();
    this.isEncoder =
      sessionStorage.getItem('account_subtype') ===
      GovernmentAccountSubtype.AGENCY_ENC;
    if (sessionStorage.getItem('arta_admin') == 'true') {
      this.isAdmin = true;
    }

    // this.setRatingValueChange()

    // this.loadStatus(this.status)
    // this.loadInfo(this.paramFromInboxList);
    this.loadWorkListInformation(this.docuFromInboxList);

    this.auth.user.subscribe(data => {
      this.afs.collection('Users').doc(data?.uid).snapshotChanges()
        .subscribe({
          next: (res) => {
            let user: any = res.payload.data()
            this.currenUser = user
            console.log('user: ', user)
          }
        })
    });
  }

  async setTheme() {
    switch (sessionStorage.getItem('user_type')) {
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  async loadUser() {
    this.user = await this.auth.currentUser;
  }

  async loadWorkListInformation(id: any) {
    // console.log('loadWorkListInformation')
    const regRef = this.afs.collection(this.regulationInboxWorklistCollectionName).doc(id);

    regRef.snapshotChanges()
      .pipe(
        tap((data: any) => {
          let worklist_info_json = data.payload.data();
          // console.log({worklist_info_json})

          if (worklist_info_json.documentTitle) {
            this.reg_documentTitle = worklist_info_json.documentTitle;
          }

          if (worklist_info_json.documentId) {
            this.reg_docId = worklist_info_json.documentId;
            // console.log('INITIAL loadProposedRegulation:',worklist_info_json.documentId)
            this.loadInfo(this.regulationInboxCollectionName, worklist_info_json.documentId, 'INITIAL');
          }

          if (worklist_info_json.status) {
            this.reg_status = worklist_info_json.status;

            this.setActionFilter(this.reg_status);
            this.getRuleMakingStage(this.reg_status);
            this.ris_allow_action = (
              worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG
              // || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ASSESMENT
            )

            this.ris_allow_agency = (worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DC
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DIR
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DDG
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ARTA_REVIEW_DG
              || worklist_info_json.status == this.enum_regulation_status.DRIS_FOR_ASSESMENT
              || worklist_info_json.status == this.enum_regulation_status.FOR_POSTING
              || worklist_info_json.status == this.enum_regulation_status.POSTED
              || worklist_info_json.status == this.enum_regulation_status.WITHDRAWN
            )

            if (this.ris_allow_action) this.ris_allow_view = true

            console.log('ris_allow_action: ', this.ris_allow_action)
            console.log('ris_allow_agency: ', this.ris_allow_agency)
            console.log('ris_allow_view: ', this.ris_allow_view)

            this.ris_final_assessment = worklist_info_json.status === this.enum_regulation_status.DRIS_FOR_ASSESMENT;

            console.log('INITIAL loadInfo:', this.paramFromInboxList)
            // this.loadInfo(this.regulationInboxCollectionName,this.paramFromInboxList,'INITIAL');
            if (worklist_info_json.drisID) {
              // console.log('loading dris')
              this.hasDris = true
              this.drisId = worklist_info_json.drisID
              this.setRatingValueChange()
              this.loadInfo('regulations-proposed-dris', worklist_info_json.drisID, 'DRIS')
            }
            else {
              // TODO RWL 20230327 It would be better if all the this.loadInfo calls were in a combineLatest
              this.prePostLoaded = true;
            }
            // else {
            //   this.loadInfo(this.regulationInboxCollectionName,this.paramFromInboxList,'INITIAL');
            // }

            // Required setActionFilter() to be called for this.actionButtonAllowed
            // Set other default views for certain situations (For Posting, PIS Assessment)
            if (this.actionButtonAllowed && worklist_info_json.status == this.enum_regulation_status.FOR_POSTING) {
              this.onView('prePostingView')
            }
            else if (this.actionButtonAllowed && worklist_info_json.status == this.enum_regulation_status.FOR_ARTA_REVIEW) {
              this.onView('pisAssessmentView')
            }
            else if (
              this.actionButtonAllowed &&
              [
                WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC.valueOf(),
                WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR.valueOf(),
                WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG.valueOf(),
                WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG.valueOf(),
              ].indexOf(worklist_info_json.status) > -1) {
              this.onView('pisAssessmentSummaryView')
            }
            else if (this.actionButtonAllowed && this.ris_allow_action) {
              this.onView('risAssessmentView')
            }
            else {
              this.onView('phase2View')
            }
          }

          if (worklist_info_json.fromAgency) {
            this.reg_fromAgency = worklist_info_json.fromAgency;
          }

          if (worklist_info_json.fromOfficer) {
            this.reg_fromOfficer = worklist_info_json.fromOfficer;
          }

          if (worklist_info_json.dateGenerated) {
            this.reg_dateGenerated = worklist_info_json.dateGenerated;
          }

          if (worklist_info_json.dueDate) {
            this.reg_dueDate = worklist_info_json.dueDate;
          }

          if (worklist_info_json.crisID) {
            this.hasCris = true
            this.crisId = worklist_info_json.crisID
            // this.loadCRISInformation(worklist_info_json.crisID);
            this.loadInfo('regulations-proposed-cris', worklist_info_json.crisID, 'CRIS')
            // this.loadConsultations(worklist_info_json.crisID);
          }

          if (worklist_info_json.commentDetails) {
            this.reg_comment_details = worklist_info_json.commentDetails;
          }

          if (worklist_info_json.commentRegText) {
            this.reg_comment_regtext = worklist_info_json.commentRegText;
          }

          if (worklist_info_json.commentPIA) {
            this.reg_comment_pia = worklist_info_json.commentPIA;
          }

          if (worklist_info_json.commentSelfAssessment) {
            this.reg_comment_self_assessment = worklist_info_json.commentSelfAssessment;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  setActionFilter(status: any) {
    console.log('account_subtype: ', sessionStorage.getItem('account_subtype'))
    this.actionFilter = this.worklistAllowedActionButtonFilters.get(
      sessionStorage.getItem('account_subtype') || ''
    );
    console.log('actionFilter: ', this.actionFilter)
    this.actionButtonAllowed = (this.actionFilter?.includes(status))
    console.log('actionButtonAllowed: ', this.actionButtonAllowed)
    var lowest_level_users = [
      GovernmentAccountSubtype.AGENCY_ENC.valueOf()
    ];
    this.commentButtonsEnabled = this.actionButtonAllowed && (lowest_level_users.indexOf(sessionStorage.getItem('account_subtype') || '') < 0);
  }

  getRuleMakingStage(status: any) {
    this.afs.collection('Regulations_Classification').ref
      .where('status', '==', status).get()
      .then(info => {
        if (info.size > 0) {
          let tempArr: any = []
          info.forEach(data => {
            let item: any = data.data()
            item.id = data.id
            tempArr.push(item)
          })
          this.rulestage = tempArr[0]
        }
      })
      .catch(error => {
        console.warn(error)
      })
  }

  setRatingValueChange() {
    (this.ris_scoring_test.controls as UntypedFormGroup[]).map((control: UntypedFormGroup) => {
      if (control.value.optionsType == 'assesmentType1Options') {
        (control.controls.score as UntypedFormGroup).controls.rating.valueChanges.subscribe({
          next: (response1) => {
            let overallVal: number = 0;
            let overallIndex;
            let zeroCount: number = 0;
            let oneCount: number = 0;
            (this.ris_scoring_test.controls as UntypedFormGroup[]).map((control1: UntypedFormGroup, index: number) => {
              if (control1.value.optionsType == 'assesmentType1Options') {
                let isImplement = (control1.value.control == 'implementAndEnhancement' || control1.value.control == 'implementAndEnforcement')
                if (control1.value.control == control.value.control && !isImplement) {
                  if (response1 == 0) +zeroCount++
                  if (response1 == 1) +oneCount++
                  overallVal += response1 ? +response1 : +0
                }
                else if (isImplement) {
                  if (response1 == 0) +zeroCount++
                  if (response1 == 1) +oneCount++
                  overallVal += response1 ? +response1 : +0
                }
                else {
                  if ((control1.controls.score as UntypedFormGroup).value.rating == 0) +zeroCount++
                  if ((control1.controls.score as UntypedFormGroup).value.rating == 1) +oneCount++
                  overallVal += (control1.controls.score as UntypedFormGroup).value.rating ? +(control1.controls.score as UntypedFormGroup).value.rating : +0
                }
              }
              if (control1.value.control == 'overallRISRating') {
                overallIndex = index
              }
            });
            let ratingValue: string = '';
            let computedRatingText: string = ''
            if (zeroCount > 0) {
              ratingValue = "Finish Choosing Rating . . ."
            }
            else if (zeroCount == 0 && oneCount > 0) {
              let assesmentOptionsValue = this.getAssesmentOptionsValue('assesmentOverallRatingOptions', 8)
              if (assesmentOptionsValue.length > 0) {
                computedRatingText = assesmentOptionsValue[0].title
                ratingValue += assesmentOptionsValue[0].title + " "
              }
              ratingValue += "(" + overallVal + ")"

              // Assumed to be always for "INSUFFICIENT PRACTICE", so enable the For Reconsideration flag
              if (this.ris_final_assessment) {
                this.show_for_reconsideration = true;
              }
            } else {
              let assesmentOptionsValue = this.getAssesmentOptionsValue('assesmentOverallRatingOptions', overallVal)
              if (assesmentOptionsValue.length > 0) {
                computedRatingText = assesmentOptionsValue[0].title
                ratingValue += assesmentOptionsValue[0].title + " "
              }
              ratingValue += "(" + overallVal + ")"
            }

            if (overallIndex && overallIndex >= 0) {
              (((this.ris_scoring_test.controls as UntypedFormGroup[])[overallIndex] as UntypedFormGroup).patchValue({
                computedRatingText: computedRatingText,
                rating_text: ratingValue
              }));
              (((this.ris_scoring_test.controls as UntypedFormGroup[])[overallIndex] as UntypedFormGroup).controls.score as UntypedFormGroup).patchValue({
                rating: overallVal
              });
            }
            // else (((this.ris_scoring_test.controls as FormGroup[])[overallIndex] as FormGroup).controls.score as FormGroup).patchValue({rating:0});
          }
        })
      }
    });
  }

  // Someday it will be useful
  async loadCRISInformation(id: any) {
    const crisRef = this.afs.collection(`regulations-proposed-cris`).doc(id);
    crisRef
      .snapshotChanges()
      .pipe(
        tap((data) => {
          // let crisData2:any = {}
          if (data.payload.exists) {
            let cris_info_json: any = data.payload.data();
            cris_info_json.id = data.payload.id
            // console.log('CRIS: ',cris_info_json)
            // this.crisData = data.payload.data();
            // this.crisData.id = data.payload.id

            // crisData2 = cris_info_json

            this.regulationsData['CRIS'].crisData2 = cris_info_json
            // crisData2.type = 'CRIS'
            // this.crisData2 = cris_info_json
            // this.crisData2.type = 'CRIS'

            this.crisUploads = cris_info_json.upload_folders

            this.regulationsData['CRIS'].uploads = cris_info_json.upload_folders

            // this.loadConsultations(id);

            let regTextData = {
              id: id,
              regulation_text: cris_info_json.regulation_text,
              regulation_text_annex_file: {
                regulation_text_annex_file_folder: cris_info_json.regulation_text_annex_file_folder,
                regulation_text_annex_file_name: cris_info_json.regulation_text_annex_file_name,
              }
            }

            this.crisData = regTextData

            this.regulationsData['CRIS'].regTextData = regTextData
            // console.log('crisData: ',this.crisData)
            // console.log('CRIS data: ',this.regulationsData['CRIS'])
          }
          // else {
          //   // this.regulationsData['CRIS'] = {
          //   //   data:{}
          //   // }
          //   this.loadConsultations(id);
          // }
        }),
        take(1)
      )
      .subscribe();
  }

  async loadConsultations(id: any) {
    // console.log('loadConsultations')
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const crisRef = this.afs.collection(`Public Consultations`, (filter) =>
      filter.where('cris_id', '==', id)
    );
    crisRef
      .snapshotChanges()
      // .pipe(
      //   tap((data: any) => {
      //     this.total_consultations_count = data.length;
      //     // To ensure timing, set paramCrisID at the same time as total_consultations_count
      //     this.paramCrisID = id;

      //     let reg_completed_consultations:Array<any> = []

      //     // console.log('loadConsultations L: ',data.length)
      //     data.forEach((x: any) => {
      //       const data = x.payload.doc.data();
      //       // console.log('loadConsultations: ',data)
      //       // if (
      //       //   data.to_date instanceof Timestamp &&
      //       //   today > data.to_date.toDate()
      //       // ) {
      //       //   this.reg_completed_consultations.push(x.payload.doc.data());
      //       // }
      //       reg_completed_consultations.push(data);
      //     });
      //     let consultationsData = reg_completed_consultations
      //     this.consultationsData = consultationsData
      //     this.regulationsData['CRIS'].data.consultationsData = consultationsData
      //     // console.log('crisData: ',crisData)
      //   }),
      //   take(1)
      // )
      // .subscribe();
      .subscribe({
        next: (data) => {
          this.total_consultations_count = data.length;
          // To ensure timing, set paramCrisID at the same time as total_consultations_count
          this.paramCrisID = id;

          let reg_completed_consultations: Array<any> = []

          data.map((x: any) => {
            const data: any = x.payload.doc.data();
            reg_completed_consultations.push(data);
          });
          let consultationsData = reg_completed_consultations
          this.consultationsData = consultationsData
          this.regulationsData['CRIS'].data.consultationsData = consultationsData
        },
        error: (err) => {
          console.error('Error: ', err)
        }
      })
  }

  async loadInfo(collection: string, id: any, code: string) {
    // this.baseCode = code
    // console.log('baseCode: ',code)
    // this.afs.doc(`regulations-phase-02/${id}`).get().subscribe(
    const pageRef = this.afs.collection(collection).doc(id);
    // const agencyRef = this.afs.collection(`Agency`);
    /**
     * Fields
     */

    pageRef
      .snapshotChanges()
      .pipe(
        tap(async (data: any) => {
          // this.regulation = data.data();
          let page_info_json = data.payload.data();
          page_info_json.id = data.payload.id
          if (!page_info_json.has_sub_tags) {
            page_info_json.has_sub_tags = false
            page_info_json.sub_tags = []
          }

          this.page_info = data.payload.data();
          this.regulationsData[code].id = id
          // console.log('page_info_json',page_info_json)


          if (code == 'INITIAL') {
            this.current_regulation = page_info_json;

            this.pis_assessment = {
              pia_summary: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_summary?.assessment || '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_summary?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              pia_section_1: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_section_1?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_section_1?.previous_remarks),
                  url: new UntypedFormControl(''),

                }),
              ],
              pia_section_2: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_section_2?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_section_2?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              pia_section_3: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_section_3?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_section_3?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              pia_section_4: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_section_4?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_section_4?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              pia_section_5: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_section_5?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_section_5?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              pia_section_6: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.pia_section_6?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.pia_section_6?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              reg_significance: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('SIG'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.reg_significance?.assessment ||
                        '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.reg_significance?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              reg_lenses: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('LENS'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.reg_lenses?.assessment || 'None',
                      disabled: !this.actionButtonAllowed,
                    }
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.reg_lenses?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
              reg_details: [
                new UntypedFormGroup({
                  type: new UntypedFormControl('PIA'),
                  assessment: new UntypedFormControl(
                    {
                      value:
                        page_info_json.pis_assessment?.reg_details?.assessment || '',
                      disabled: !this.actionButtonAllowed,
                    },
                    Validators.required
                  ),
                  remarks: new UntypedFormControl({
                    value: '',
                    disabled: !this.actionButtonAllowed,
                  }),
                  previous_remarks: this.buildPreviousRemarks(page_info_json.pis_assessment?.reg_details?.previous_remarks),
                  url: new UntypedFormControl(''),
                }),
              ],
            };

            console.log('pis_assessment: ', this.pis_assessment)

            this.flagged_sections = new UntypedFormArray([]);
            this.flaggedSectionIndexes = [];


            if (page_info_json.pis_assessment?.flagged_sections) {
              page_info_json.pis_assessment.flagged_sections.forEach(
                (flaggedSection: any) => {
                  this.flagged_sections.push(
                    new UntypedFormGroup({
                      flaggedSectionIndex: new UntypedFormControl(
                        flaggedSection.flaggedSectionIndex
                      ),
                      flaggedSectionName: new UntypedFormControl(
                        flaggedSection.flaggedSectionName
                      ),
                      remarks: new UntypedFormControl({
                        value: '',
                        disabled: !this.actionButtonAllowed,
                      }),
                      previous_remarks: this.buildPreviousRemarks(flaggedSection?.previous_remarks),
                    })
                  );
                }
              );

              this.flaggedSectionIndexes =
                page_info_json.pis_assessment.flagged_sections.map(
                  (flaggedSection: any) => {
                    return flaggedSection.flaggedSectionIndex;
                  }
                );
            }
          }

          if (code == 'DRIS') {
            this.prePostLoaded = true;

            if (page_info_json.risData) {
              // this.ris_allow_view = true
              this.handleRisData(page_info_json.risData, page_info_json.upload_folders)
            }
            else {
              this.initializeRisData(page_info_json.upload_folders)
            }
          }

          this.regulationsData[code].data = data.payload.data();
          // console.log(code+': ',page_info_json)

          if (code == 'CRIS') this.loadConsultations(id)

          if (page_info_json.title) {
            // this.reg_long_name = page_info_json.title;

            this.regulationsData[code].data.reg_long_name = page_info_json.title
            this.regulationsData[code].data.title = page_info_json.title
          }

          if (page_info_json.doc_date) {
            // this.reg_date = page_info_json.doc_date;

            this.regulationsData[code].data.reg_date = page_info_json.doc_date
          }

          if (page_info_json.section_name) {
            // this.reg_section_name = page_info_json.section_name;

            this.regulationsData[code].data.reg_section_name = page_info_json.section_name
          }

          if (page_info_json.subject) {
            // this.reg_subject = page_info_json.subject;

            this.regulationsData[code].data.reg_subject = page_info_json.subject
          }

          if (page_info_json.reg_instrument) {
            // this.reg_parent_instrument_id = page_info_json.reg_instrument;

            this.regulationsData[code].data.reg_parent_instrument_id = page_info_json.reg_instrument

            const instrument: any = await this.dataLoader('Instrument', 'Instrument', page_info_json.reg_instrument)
            if (instrument) {
              this.current_instrument = instrument
              // this.regulationsData[code].data.instrument = instrument
              this.regulationsData[code].data.reg_instrument_name = instrument.name
            }
            else console.error('no instrument retrieved')

            // const instrumentRef = this.afs.collection(`Instrument`).doc(page_info_json.reg_instrument);
            // instrumentRef.snapshotChanges().subscribe((data: any) => {
            //   let instrumentRef_info_json = data.payload.data();
            //   let instrument:any = data.payload.data();
            //   instrument.id = data.payload.id;
            //   this.current_instrument = instrument
            //   // this.reg_instrument_name = instrumentRef_info_json.name;

            //   this.regulationsData[code].data.reg_instrument_name = instrumentRef_info_json.name
            // });
          }

          if (page_info_json.reg_agency) {
            if (Array.isArray(page_info_json.reg_agency)) {
              const agency_names: any = await this.multiDataLoader('Agency', 'Agency', page_info_json.reg_agency)
              if (agency_names && agency_names.length > 0) this.regulationsData[code].data.agency_item = agency_names
              else {
                console.warn('Error: no agency name')
                this.regulationsData[code].data.agency_item = []
              }
            }
            else {
              let temp_agency_list: any = []
              temp_agency_list.push(page_info_json.reg_agency)
              const agency_names: any = await this.multiDataLoader('Agency', 'Agency', temp_agency_list)
              if (agency_names && agency_names.length > 0) this.regulationsData[code].data.agency_item = agency_names
              else {
                console.warn('Error: no agency name')
                this.regulationsData[code].data.agency_item = []
              }
            }
          }

          // if (page_info_json.reg_agency) {
          //   const agency = await this.handleAgency(page_info_json.reg_agency)
          //   if(agency && agency.length > 0){ 
          //     this.regulationsData[code].data.reg_agency = agency
          //     this.regulationsData[code].data.agency_item = agency.map((agencyData:any)=>this.agencyNaming1(agencyData))
          //   }
          //   else {
          //     this.regulationsData[code].data.reg_agency = []
          //     console.error('no agency/ies retrieved')
          //   }
          //   // Because reg_agency can be single input or multiple input, handle as if it were a strong or an object (array)
          //   // this.reg_agency = [];
          //   // if(typeof page_info_json.reg_agency === 'string') {
          //   //   const agencyRef = this.afs.collection(`Agency`).doc(page_info_json.reg_agency);
          //   //   agencyRef.snapshotChanges().subscribe((data: any) => {
          //   //     let agencyRef_info_json = data.payload.data();
          //   //     agencyRef_info_json.reg_agency_name = agencyRef_info_json.name;
          //   //     // this.reg_agency.push(agencyRef_info_json);

          //   //     this.regulationsData[code].data.reg_agency.push(agencyRef_info_json);
          //   //   });
          //   // }
          //   // if(typeof page_info_json.reg_agency === 'object') {
          //   //   for (let agency of page_info_json.reg_agency) {
          //   //     const agencyRef = this.afs.collection(`Agency`).doc(agency);
          //   //     agencyRef.snapshotChanges().subscribe((data: any) => {
          //   //       let agencyRef_info_json = data.payload.data();
          //   //       agencyRef_info_json.reg_agency_name = agencyRef_info_json.name;
          //   //       // this.reg_agency.push(agencyRef_info_json);

          //   //       this.regulationsData[code].data.reg_agency.push(agencyRef_info_json);
          //   //     });
          //   //   }
          //   //   // console.log(' Agency Here: ' + this.reg_agency);
          //   // }
          // }

          if (page_info_json.has_draft_text) {
            // this.reg_has_draft_text = page_info_json.has_draft_text;

            this.regulationsData[code].data.reg_has_draft_text = page_info_json.has_draft_text
          }

          let regTextData = {
            id: id,
            regulation_text: page_info_json.regulation_text,
            regulation_text_annex_file: {
              regulation_text_annex_file_folder: page_info_json.regulation_text_annex_file_folder,
              regulation_text_annex_file_name: page_info_json.regulation_text_annex_file_name,
            }
          }

          this.regulationsData[code].data.regTextData = regTextData

          if (page_info_json.regulation_text) {
            // this.reg_text = page_info_json.regulation_text;

            this.regulationsData[code].data.reg_text = page_info_json.regulation_text

            if (code == 'INITIAL' && page_info_json.has_draft_text) {
              if (page_info_json.regulation_text[0]) {
                this.selected_section = page_info_json.regulation_text[0];
                if (
                  this.selected_section.subsections &&
                  this.selected_section.subsections[0]
                ) {
                  this.selected_subsection = this.selected_section.subsections[0];
                }
              }
            }
          }

          if (page_info_json.has_annex_files) {
            let reg_text_annex_file: any = {
              has_annex_files: page_info_json.has_annex_files
            }

            if (page_info_json.regulation_text_annex_file_folder && page_info_json.regulation_text_annex_file_name) {

              reg_text_annex_file.regulation_text_annex_file_folder = page_info_json.regulation_text_annex_file_folder
              reg_text_annex_file.regulation_text_annex_file_name = page_info_json.regulation_text_annex_file_name

              // this.reg_text_annex_file = reg_text_annex_file

              this.regulationsData[code].data.reg_text_annex_file = reg_text_annex_file



              // let tokenized = page_info_json.regulation_text_annex_file_name.split(".")
              // if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
              //   this.reg_text_annex_file.annex_file_type = 'zip';
              //   console.log('annex_file: ',)
              //   this.store.ref(`${page_info_json.regulation_text_annex_file_name}/${page_info_json.regulation_text_annex_file_name}`).getDownloadURL()
              //   .subscribe((url)=>{
              //     console.log('')
              //     this.reg_text_annex_file.annex_file_download_link = url

              //     this.regulationsData[code].reg_text_annex_file .annex_file_download_link = url
              //   })
              // } else{
              //   const path = `${page_info_json.regulation_text_annex_file_folder}/${page_info_json.regulation_text_annex_file_name}`;

              //   this.pdf_render.getFileUrl(path).toPromise().then((data: any) => {
              //     this.reg_text_annex_file.annex_file = data;
              //     this.reg_text_annex_file.annex_file_page = 1; 
              //   });

              //   // this.regulationsData[code].reg_text_annex_file .annex_file_download_link = url
              // }
            }
            else {
              this.regulationsData[code].data.reg_text_annex_file = reg_text_annex_file
            }
          }

          if (page_info_json.firebase_folder) {
            // this.firebase_folder = page_info_json.firebase_folder;

            this.regulationsData[code].data.firebase_folder = page_info_json.firebase_folder
          }

          if (page_info_json.page_info_json) {
            // this.reg_summary = page_info_json.pia_summary;

            this.regulationsData[code].data.reg_summary = page_info_json.pia_summary
          }

          // Regulatory notification form problem or PIA Section 1 problem?
          if (page_info_json.pia_section_1) {
            // Show RNF version if PIA is empty
            // this.reg_problem = page_info_json.pia_section_1.policy_problem_desc;
            this.regulationsData[code].data.reg_problem = page_info_json.pia_section_1.policy_problem_desc

            if (code == 'INITIAL') {
              const pia1Url = await this.getDownloadURL(page_info_json.pia_section_1.policy_problem_file_folder, page_info_json.pia_section_1.policy_problem_file_name)
              if (pia1Url) (this.pis_assessment.pia_section_1[0] as UntypedFormGroup).patchValue({ url: pia1Url });
              else console.error('no pia1 url retrieved')

              // if(page_info_json.pia_section_1.policy_problem_file_folder && page_info_json.pia_section_1.policy_problem_file_name){
              //   let path = page_info_json.pia_section_1.policy_problem_file_folder+"/"+page_info_json.pia_section_1.policy_problem_file_name
              //   this.store.ref(path).getDownloadURL()
              //   .subscribe({
              //     next:(response)=>{
              //       // console.log('pia_section_1 doc loaded');
              //       (this.pis_assessment.pia_section_1[0] as FormGroup).patchValue({url:response});
              //     },
              //     error:(err)=>{
              //       console.error('Error no document found!: pia_section_1')
              //     }
              //   })
              // }
            }
          }

          if (page_info_json.pia_section_2) {
            // this.reg_objectives = page_info_json.pia_section_2;

            this.regulationsData[code].data.reg_objectives = page_info_json.pia_section_2
          }

          if (page_info_json.pia_section_4_file) {
            if (code == 'INITIAL') {
              if (page_info_json.pia_section_4_file.compliance_file_folder && page_info_json.pia_section_4_file.compliance_file_name) {
                const pia4Url = await this.getDownloadURL(page_info_json.pia_section_4_file.compliance_file_folder, page_info_json.pia_section_4_file.compliance_file_name)
                if (pia4Url) (this.pis_assessment.pia_section_4[0] as UntypedFormGroup).patchValue({ url: pia4Url });
                else console.error('no pia4 url retrieved')
              }
            }
          }

          if (page_info_json.pia_section_6) {
            // this.reg_pref_pol = page_info_json.pia_section_6.recommended_policy_option;
            // this.reg_pref_pol_impact = page_info_json.pia_section_6.impact_summary_desc;
            // this.reg_pref_pol_cost_comp = page_info_json.pia_section_6.compliance_desc;

            this.regulationsData[code].data.reg_pref_pol = page_info_json.pia_section_6.recommended_policy_option
            this.regulationsData[code].data.reg_pref_pol_impact = page_info_json.pia_section_6.impact_summary_desc
            this.regulationsData[code].data.reg_pref_pol_cost_comp = page_info_json.pia_section_6.compliance_desc

            if (code == 'INITIAL') {
              const pia6Url = await this.getDownloadURL(page_info_json.pia_section_6.consultation_and_recommendation_file_folder, page_info_json.pia_section_6.consultation_and_recommendation_file_name)
              if (pia6Url) (this.pis_assessment.pia_section_6[0] as UntypedFormGroup).patchValue({ url: pia6Url });
              else console.error('no pia6 url retrieved')
              // if(page_info_json.pia_section_6.consultation_and_recommendation_file_folder && page_info_json.pia_section_6.consultation_and_recommendation_file_name){
              //   let path = page_info_json.pia_section_6.consultation_and_recommendation_file_folder+"/"+page_info_json.pia_section_6.consultation_and_recommendation_file_name
              //   this.store.ref(path).getDownloadURL()
              //   .subscribe({
              //     next:(response)=>{
              //       // console.log('pia_section_6 doc loaded');
              //       (this.pis_assessment.pia_section_6[0] as FormGroup).patchValue({url:response});
              //     },
              //     error:(err)=>{
              //       console.error('Error no document found!: pia_section_6')
              //     }
              //   })
              // }
            }
          }

          if (page_info_json.reg_significance) {
            let reg_significance = {
              significance: page_info_json.reg_significance,
              comments: page_info_json.reg_significance_comments,
            };
            // this.reg_significance = reg_significance

            this.regulationsData[code].data.reg_significance = reg_significance
          }

          if (page_info_json.reg_lenses) {
            // this.reg_lens_comp = page_info_json.reg_lenses.lense_competition? 'Yes': 'No';
            // this.reg_lens_disaster = page_info_json.reg_lenses.lense_disaster? 'Yes': 'No';
            // this.reg_lens_environment = page_info_json.reg_lenses.lense_environmental? 'Yes': 'No';
            // this.reg_lens_inclusive = page_info_json.reg_lenses.lense_inclusive? 'Yes': 'No';

            this.regulationsData[code].data.reg_lens_comp = page_info_json.reg_lenses.lense_competition ? 'Yes' : 'No';
            this.regulationsData[code].data.reg_lens_disaster = page_info_json.reg_lenses.lense_disaster ? 'Yes' : 'No';
            this.regulationsData[code].data.reg_lens_environment = page_info_json.reg_lenses.lense_environmental ? 'Yes' : 'No';
            this.regulationsData[code].data.reg_lens_inclusive = page_info_json.reg_lenses.lense_inclusive ? 'Yes' : 'No';
          }

          if (page_info_json.reg_classification) {
            this.regulationsData[code].data.reg_tag_classification = page_info_json.reg_classification
            if (page_info_json.reg_classification == 'Business') {
              if (page_info_json.reg_sector) {
                const sector: any = await this.dataLoader('Sector', 'Sector', page_info_json.reg_sector)
                if (sector) this.regulationsData[code].data.reg_tag_sector = sector.name
                else console.error('no sector retrieved')
              }

              if (page_info_json.reg_business) {
                const sob: any = await this.dataLoader('StageOfBusiness', 'Stage of Business', page_info_json.reg_business)
                if (sob) this.regulationsData[code].data.reg_tag_stage_of_business = sob.name
                else console.error('no sob retrieved')
              }

              if (page_info_json.reg_division) {
                const division: any = await this.dataLoader('Division', 'Division', page_info_json.reg_division)
                if (division) this.regulationsData[code].data.reg_tag_division = division.name
                else console.error('no division retrieved')
              }

              if (page_info_json.reg_case) {
                const caseUse: any = await this.dataLoader('CaseUse', 'Case Use', page_info_json.reg_case)
                if (caseUse) this.regulationsData[code].data.reg_tag_case_use = caseUse.name
                else console.error('no caseUse retrieved')
              }
            }

            else if (page_info_json.reg_classification == 'Non-Business') {
              console.log('reg_stageoflife: ', page_info_json.reg_stageoflife)
              if (page_info_json.reg_stageoflife) {
                const sol: any = await this.dataLoader('StageOfLife', 'Stage of Life', page_info_json.reg_stageoflife)
                if (sol) this.regulationsData[code].data.reg_tag_stage_of_life = sol.stage
                else console.error('no sol retrieved')
              }
              console.log('reg_lifeevent: ', page_info_json.reg_lifeevent)
              if (page_info_json.reg_lifeevent) {
                const le: any = await this.dataLoader('LifeEvent', 'Life Event', page_info_json.reg_lifeevent)
                if (le) this.regulationsData[code].data.reg_tag_life_event = le.event
                else console.error('no life event retrieved')
              }
            }
          }

          if (page_info_json.reg_juris) {
            const jurisdiction: any = await this.dataLoader('Jurisdiction', 'Jurisdiction', page_info_json.reg_juris)
            if (jurisdiction) this.regulationsData[code].data.reg_tag_jurisdiction = jurisdiction.name
            else console.error('no jurisdiction retrieved')
          }

          if (this.regulationsData[code].data.sub_tags) {
            this.regulationsData[code].data.sub_tags.map(async (sub_tag: any) => {
              sub_tag.reg_tag_classification = sub_tag.reg_classification
              if (sub_tag.reg_classification == 'Business') {
                if (sub_tag.reg_sector) {
                  const sector: any = await this.dataLoader('Sector', 'Sector', sub_tag.reg_sector)
                  if (sector) sub_tag.reg_tag_sector = sector.name
                  else console.error('no sector retrieved')
                }
                if (sub_tag.reg_business) {
                  const sob: any = await this.dataLoader('StageOfBusiness', 'Stage of Business', sub_tag.reg_business)
                  if (sob) sub_tag.reg_tag_stage_of_business = sob.name
                  else console.error('no sob retrieved')
                }

                if (sub_tag.reg_division) {
                  const division: any = await this.dataLoader('Division', 'Division', sub_tag.reg_division)
                  if (division) sub_tag.reg_tag_division = division.name
                  else console.error('no division retrieved')
                }

                if (sub_tag.reg_case) {
                  const caseUse: any = await this.dataLoader('CaseUse', 'Case Use', sub_tag.reg_case)
                  if (caseUse) sub_tag.reg_tag_case_use = caseUse.name
                  else console.error('no caseUse retrieved')
                }
              }

              else if (sub_tag.reg_classification == 'Non-Business') {
                if (sub_tag.reg_stageoflife) {
                  const sol: any = await this.dataLoader('StageOfLife', 'Stage of Life', sub_tag.reg_stageoflife)
                  if (sol) sub_tag.reg_tag_stage_of_life = sol.stage
                  else console.error('no sol retrieved')
                }
                if (sub_tag.reg_lifeevent) {
                  const le: any = await this.dataLoader('LifeEvent', 'Life Event', sub_tag.reg_lifeevent)
                  if (le) sub_tag.reg_tag_life_event = le.event
                  else console.error('no life event retrieved')
                }
              }
            })
          }

          if (page_info_json.reg_legal) {
            this.regulationsData[code].data.reg_tag_legal = page_info_json.reg_legal
          }

          if (page_info_json.reg_intl) {
            this.regulationsData[code].data.reg_tag_intl = page_info_json.reg_intl
          }


          if (page_info_json.tags) {
            // this.reg_tag_classification = page_info_json.tags.reg_classification;

            this.regulationsData[code].data.reg_tag_classification = page_info_json.tags.reg_classification;

            if (page_info_json.tags.reg_classification == 'Business') {
              if (page_info_json.tags.reg_sector) {
                const sector: any = await this.dataLoader('Sector', 'Sector', page_info_json.tags.reg_sector)
                if (sector) this.regulationsData[code].data.reg_tag_sector = sector.name
                else console.error('no sector retrieved')

                // this.afs.collection(`Sector`).doc(page_info_json.tags.reg_sector).valueChanges()
                //   .pipe(
                //     tap((data: any) => {
                //       // this.reg_tag_sector = data.name;

                //       this.regulationsData[code].data.reg_tag_sector = data.name;
                //     }),
                //     take(1)
                //   )
                //   .subscribe();

              }

              if (page_info_json.tags.reg_business) {
                const sob: any = await this.dataLoader('StageOfBusiness', 'Stage of Business', page_info_json.tags.reg_business)
                if (sob) this.regulationsData[code].data.reg_tag_stage_of_business = sob.name
                else console.error('no sob retrieved')
                // this.afs.collection(`Stage of Business`).doc(page_info_json.tags.reg_business).valueChanges()
                //   .pipe(
                //     tap((data: any) => {
                //       // this.reg_tag_stage_of_business = data.name;

                //       this.regulationsData[code].data.reg_tag_stage_of_business = data.name;
                //     }),
                //     take(1)
                //   )
                //   .subscribe();
              }
            } else if (page_info_json.tags.reg_classification == 'Non-Business') {
              if (page_info_json.tags.reg_stageoflife) {
                const sol: any = await this.dataLoader('StageOfLife', 'Stage of Life', page_info_json.tags.reg_stageoflife)
                if (sol) this.regulationsData[code].data.reg_tag_stage_of_life = sol.stage
                else console.error('no sol retrieved')
                // this.afs.collection(`Stage of Life`).doc(page_info_json.tags.reg_stageoflife).valueChanges()
                //   .pipe(
                //     tap((data: any) => {
                //       // this.reg_tag_stage_of_life = data.name;

                //       this.regulationsData[code].data.reg_tag_stage_of_life = data.name;
                //     }),
                //     take(1)
                //   )
                //   .subscribe();
              }

              if (page_info_json.tags.reg_lifeevent) {
                const le: any = await this.dataLoader('LifeEvent', 'Life Event', page_info_json.tags.reg_lifeevent)
                if (le) this.regulationsData[code].data.reg_tag_life_event = le.event
                else console.error('no life event retrieved')
                // this.afs.collection(`Life Event`).doc(page_info_json.tags.reg_lifeevent).valueChanges()
                //   .pipe(
                //     tap((data: any) => {
                //       // this.reg_tag_life_event = data.name;

                //       this.regulationsData[code].data.reg_tag_life_event = data.name;
                //     }),
                //     take(1)
                //   )
                //   .subscribe();
              }
            }

            if (page_info_json.tags.reg_division) {
              const division: any = await this.dataLoader('Division', 'Division', page_info_json.tags.reg_division)
              if (division) this.regulationsData[code].data.reg_tag_division = division.name
              else console.error('no division retrieved')
              // this.afs.collection(`Division`).doc(page_info_json.tags.reg_division).valueChanges()
              //   .pipe(
              //     tap((data: any) => {
              //       // this.reg_tag_division = data.name;

              //       this.regulationsData[code].data.reg_tag_division = data.name;
              //     }),
              //     take(1)
              //   )
              //   .subscribe();
            }

            if (page_info_json.tags.reg_juris) {
              const jurisdiction: any = await this.dataLoader('Jurisdiction', 'Jurisdiction', page_info_json.tags.reg_juris)
              if (jurisdiction) this.regulationsData[code].data.reg_tag_jurisdiction = jurisdiction.name
              else console.error('no jurisdiction retrieved')
              // this.afs.collection(`Jurisdiction`).doc(page_info_json.tags.reg_juris).valueChanges()
              //   .pipe(
              //     tap((data: any) => {
              //       // this.reg_tag_jurisdiction = data.name;

              //       this.regulationsData[code].data.reg_tag_jurisdiction = data.name;
              //     }),
              //     take(1)
              //   )
              //   .subscribe();
            }

            if (page_info_json.tags.reg_case) {
              const caseUse: any = await this.dataLoader('CaseUse', 'Case Use', page_info_json.tags.reg_case)
              if (caseUse) this.regulationsData[code].data.reg_tag_case_use = caseUse.name
              else console.error('no caseUse retrieved')
              // this.afs.collection(`Case Use`).doc(page_info_json.tags.reg_case).valueChanges()
              //   .pipe(
              //     tap((data: any) => {
              //       // this.reg_tag_case_use = data.name;

              //       this.regulationsData[code].data.reg_tag_case_use = data.name;
              //     }),
              //     take(1)
              //   )
              //   .subscribe();
            }

            if (page_info_json.tags.reg_legal) {
              this.regulationsData[code].data.reg_tag_legal = page_info_json.tags.reg_legal
            }

            if (page_info_json.tags.reg_intl) {
              this.regulationsData[code].data.reg_tag_intl = page_info_json.tags.reg_intl
            }
          }

          if (page_info_json.reg_notif_form) {
            if (page_info_json.reg_notif_form.reg_remarks) {
              // this.reg_other_remarks = page_info_json.reg_notif_form.reg_remarks;

              this.regulationsData[code].data.reg_other_remarks = page_info_json.reg_notif_form.reg_remarks;
            }
          }

          if (page_info_json.upload_folders) {
            // console.log(code+' upload_folders',page_info_json.upload_folders)
            // if(code == 'DRIS'){ this.drisUploads = page_info_json.upload_folders}

            this.regulationsData[code].uploads = page_info_json.upload_folders;
          }

          // console.log(code+' data: ',this.regulationsData[code])
          // console.log('loaded data', this.loadedData)

          // if(page_info_json.reg_agency){
          //   this.reg_agency_id = page_info_json.reg_agency;
          //   const agencyRef =this.afs.collection(`Agency`).doc(this.reg_agency_id);
          //   agencyRef.snapshotChanges().subscribe((data: any) => {
          //     let agency_info_json = data.payload.data();
          //     this.reg_agency_name = agency_info_json.name;
          //   });
          // }

          // if(code == 'INITIAL'){
          //   console.log('current_regulation sub tags1',this.regulationsData[code].data.sub_tags)
          //   let current_regulation = await this.regulationsData[code].data
          //   console.log('current_regulation sub tags2: ',current_regulation.sub_tags)
          //   current_regulation.id = id
          //   this.current_regulation = current_regulation
          // };


        }),
        take(1)
      )
      .subscribe();
  }

  // async handleAgency(reg_agency:any):Promise<any>{
  //   if(typeof reg_agency == 'string'){
  //     const agency = await this.dataLoader('Agency','Agency',reg_agency)
  //     if(agency){
  //       return [agency]
  //     }
  //     else return false
  //   }

  //   else if(Array.isArray(reg_agency)){
  //     const multiAgencyData:any = await this.multiLoadData('Agency','Agency',reg_agency)
  //     if(multiAgencyData){
  //       return multiAgencyData
  //     }
  //   }

  //   else return false
  // }



  // agencyNaming1(agency:any){
  //   if(agency.agencyDetails){
  //     if(agency.agencyDetails.agencyName){
  //       return agency.agencyDetails.agencyName
  //     }else return this.agencyNaming2(agency)
  //   } else return this.agencyNaming2(agency)
  // }

  // agencyNaming2(agency:any){
  //   if(agency.name){
  //     return agency.name
  //   }else return "Cannot get Agency"
  // }

  // multiLoadData(code:string, collection:string, idArr:any){
  //   let tempArr:any = []
  //   return Promise.all(
  //     idArr.map(async (id:any)=>{
  //       const item = await this.dataLoader(code,collection,id)
  //       if(item){
  //         return [item]
  //       }
  //       else return false
  //       // const item:any = await this.loadData(collection,id)

  //       // if(item){
  //       //   if(this.loadedData[code].map((val:any)=>val.id).indexOf(id) == -1)this.loadedData[code].push(item)
  //       //   return item
  //       // }
  //       // // if(item){
  //       // //   if(!tempArr.map((item:any)=>item.id).includes(item.id)) tempArr.push(item)
  //       // // }
  //       // return true
  //     })
  //   ).then(()=>tempArr)
  // }

  async multiDataLoader(code: string, collection: string, idArr: any): Promise<any> {
    // console.log('multiDataLoader ids: ',idArr)
    if (code && collection && idArr && idArr.length > 0) {
      if (this.loadedData[code]) {
        return this.multiDataLoader2(code, collection, idArr)
      }

      else {
        this.loadedData[code] = []
        return this.multiDataLoader(code, collection, idArr)
      }
    } else return false
  }

  async multiDataLoader2(code: string, collection: string, idArr: Array<string>) {
    return Promise.all(
      idArr.map(async (id: string) => this.asyncMapLoader(code, collection, idArr, id))
    )
      .then((data) => {
        if (data && data.length > 0) return data.map((agency: any) => agency.agencyDetails && agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name ? agency.name : '-- Not found')
        else return ['-- Not found']
      })
      .catch((err) => {
        console.error('Error: ', err)
        return false
      })
  }

  asyncMapLoader(code: string, collection: string, idArr: Array<any>, id: string) {
    const sureList = this.loadedData[code].filter((agency: any) => idArr.includes(agency.id))
    if (sureList.length > 0) return sureList[0]
    else return this.dataLoader2(code, collection, id)
  }

  async dataLoader(code: string, collection: string, id: any): Promise<any> {
    // code is used for accessing loadedData object
    // check if code, collection, and id are valid
    if (code && collection && id) {

      // check if loadedData.Object has a value
      if (this.loadedData[code]) {

        // check if there loadedData.Object array contains an element
        if (this.loadedData[code].length > 0) {

          // look for id wihtin the array and return the element
          const ctr = this.loadedData[code].map((val: any) => val.id).indexOf(id)
          if (ctr >= 0) {
            return this.loadedData[code][ctr]
          }

          // if no element with that id get it from firebase
          else return await this.dataLoader2(code, collection, id)
        }

        // if loadedData.Object array doesnt contain an element get value from firebase
        else return await this.dataLoader2(code, collection, id)
      }

      // if loadedData.Object has no value, initialized an array there
      else {
        this.loadedData[code] = []
        return await this.dataLoader(code, collection, id)
      }
    } else return false
  }

  async dataLoader2(code: string, collection: string, id: any): Promise<any> {

    // get data from firebase
    const item = await this.loadData(collection, id)

    // if data exist add it to loaded.Object array
    if (item) {
      if (this.loadedData[code].map((val: any) => val.id).indexOf(id) == -1) this.loadedData[code].push(item)
      return item
    }

    // if data doesnt exist return false
    else return false
  }

  loadData(collection: any, value: any) {
    // get data from firebase if no data was retrieved return false
    return new Promise((res, rej) => {
      this.afs.collection(collection).doc(value).snapshotChanges()
        .subscribe({
          next: (data) => {
            if (data.payload.exists) {
              let item: any = data.payload.data()
              item.id = data.payload.id

              if (item.ref) delete item['ref']
              if (item._createdBy) delete item['_createdBy']
              if (item._updatedBy) delete item['_updatedBy']
              return res(item)
            } else return res(false)
          },
          error: (err) => {
            console.error('loadData Error: ', err)
            return res(false)
          }
        })
    })
  }

  async getDownloadURL(folder: string, fileName: string, path?: string) {
    if (path) {
      return await this.getDownloadURL2(path)
    } else {
      if (folder && fileName) {
        const path = folder + '/' + fileName
        return await this.getDownloadURL2(path)
      }
      else return false
    }
  }

  getDownloadURL2(path: string) {
    return new Promise((res, rej) => {
      this.store.ref(path).getDownloadURL()
        .subscribe({
          next: (result) => res(result),
          error: (err) => res(false)
        })
    })
  }

  // loadStatus(getStatus: string) {
  //   this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
  // }

  getOption(code: string) {
    if (code == 'assesmentType1Options') return this.assesmentType1Options
    else if (code == 'assesmentOverallRatingOptions') return this.assesmentOverallRatingOptions
    else if (code == 'assesmentType2Options') return this.assesmentType2Options
    else if (code == 'assesmentType3Options') return this.assesmentType3Options
    else return []
  }

  getSelectionItem(control: any) {
    let tempArr: any = this.getOption(control.optionsType)
    if (control.optionsType == 'assesmentType1Options') {
      const tempArrNew: any = tempArr.filter((element: any) => element.value == control.score.rating)
      if (tempArrNew.length > 0) {
        return tempArrNew[0].title
      }
    }
    else if (control.optionsType == 'assesmentOverallRatingOptions') {
      return control.rating_text
    }
    else if (control.optionsType == 'assesmentType2Options' || control.optionsType == 'assesmentType3Options') {
      const tempArrNew: any = tempArr.filter((element: any) => element.title == control.score.rating)
      if (tempArrNew.length > 0) {
        return tempArrNew[0].title
      }
    }
    else return ''
    return 'No Rating. . .'
  }

  getAssesmentOptionsValue(code: any, count: number) {
    if (code == 'assesmentOverallRatingOptions') {
      return this.assesmentOverallRatingOptions.filter((element: any) => element.range.from <= count && count <= element.range.to)
    }
    else return ''
  }

  getOptionDefault(code: string, optionValue: any, controlValue: any) {
    if (code == 'assesmentType1Options') {
      if (optionValue == controlValue) return true
    }
    else if (code == 'assesmentType2Options') {
      if (optionValue == controlValue) return true
    }
    else if (code == 'assesmentType3Options') {
      if (optionValue == controlValue) return true
    }
    return false
  }

  getElement(id: string) {
    return (document.getElementById(id) as HTMLSelectElement).value
  }

  getColor(text: string) {
    const assesmentOverallRatingArr = this.assesmentOverallRatingOptions.filter((element: any) => element.title === text)
    if (assesmentOverallRatingArr && assesmentOverallRatingArr.length > 0) return assesmentOverallRatingArr[0].color
    else return ''
  }

  getColor2(control: any) {
    let tempArr: any = this.getOption(control.optionsType)
    if (control.optionsType == 'assesmentType1Options') {
      const tempArrNew: any = tempArr.filter((element: any) => element.value == control.score.rating)
      if (tempArrNew.length > 0) {
        return tempArrNew[0].color
      }
    }
    else if (control.optionsType == 'assesmentOverallRatingOptions') {
      return this.getColor(control.computedRatingText)
    }
    else if (control.optionsType == 'assesmentType2Options' || control.optionsType == 'assesmentType3Options') {
      const tempArrNew: any = tempArr.filter((element: any) => element.title == control.score.rating)
      if (tempArrNew.length > 0) {
        return tempArrNew[0].color
      }
    }
    return ''
  }

  universalSelectNumber(index: number, value: string) {
    ((((this.ris_scoring_test.controls as UntypedFormGroup[])[index] as UntypedFormGroup)).controls.score as UntypedFormGroup).patchValue({ rating: value })
  }

  showOnInboxViewport() {
    this.toInboxComponent.emit();
  }

  editRegulation() {
    this.router.navigate(
      ['/pbris/upload/proposed/' + this.paramFromInboxList],
      { queryParams: { worklistId: this.docuFromInboxList } }
    );
  }

  goToComments(codeBase: string, type: string, sectionId?: number, subsectionId?: number) {
    let queryParams: any = { type: type };
    switch (codeBase) {
      case 'INITIAL':
        console.log('INITIAL comment text reg')
        queryParams.worklistId = this.docuFromInboxList;

        if (sectionId != null) {
          queryParams.sectionId = sectionId;
        }

        if (subsectionId != null) {
          queryParams.subsectionId = subsectionId;
        }

        console.log('queryParams: ', queryParams)

        this.router.navigate(
          ['/pbris/upload/proposed/comment/' + this.paramFromInboxList],
          { queryParams: queryParams }
        );
        break;
      case 'CRIS':
        console.log('CRIS comment text reg')
        // this.reg_docId
        // this.crisId
        break;
      case 'DRIS':
        console.log('DRIS comment text reg')
        // this.reg_docId
        // this.crisId
        // this.drisId
        break;
      default:
        console.error('Error: unkown status')
        break;
    }
    // let queryParams: any = { worklistId: this.docuFromInboxList, type: type };

    // if(sectionId != null) {
    //   queryParams.sectionId = sectionId;
    // }

    // if(subsectionId != null) {
    //   queryParams.subsectionId = subsectionId;
    // }

    // this.router.navigate(
    //   ['/pbris/upload/proposed/comment/' + this.paramFromInboxList],
    //   { queryParams: queryParams }
    // );
  }

  draftCris() {
    this.router.navigate([
      `/pbris/proposed/cris/${this.paramFromInboxList}/${this.docuFromInboxList}`,
    ]);
  }

  editCris() {
    if (this.paramCrisID) {
      this.router.navigate([`/pbris/proposed/cris/${this.paramCrisID}`],
        { queryParams: { a: this.paramFromInboxList } });

    }
  }

  draftDris() {
    // CRIS ID required to draft a DRIS, which only exists at the RIA phase
    if (this.paramCrisID) {
      this.router.navigate([
        `/pbris/proposed/dris/${this.paramFromInboxList}/${this.docuFromInboxList}/${this.paramCrisID}`,
      ]);
    }
  }

  editDris() {
    if (this.hasDris) {
      this.router.navigate(['/pbris/proposed/dris/' + this.drisId],
        { queryParams: { a: this.paramFromInboxList } })
    }
  }

  withdrawRegulation() {
    // Probably need confirmation to withdraw

    let updateObj = {
      status: WorklistStatusPBRISRegulation.WITHDRAWN,
    };

    this.afs
      .collection(this.regulationInboxWorklistCollectionName)
      .doc(this.docuFromInboxList)
      .update(updateObj)
      .then(() => {
        this.showOnInboxViewport();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get pisAssessmentIsValid() {
    if (!this.pis_assessment) {
      return false;
    }

    // Pseudo-allMustBe required, but without the FormArray stuff
    return Object.values(this.pis_assessment)
      .every((assessmentArr: any) => {
        return assessmentArr[0].valid;
      });
  }

  loadInstrument(id: any) {
    this.afs.collection('Instrument').doc(id).snapshotChanges()
      .subscribe(info => {
        if (info.payload.exists) {
          let instrument: any = info.payload.data()
          instrument.id = info.payload.id
          this.current_instrument = instrument
        }
      })
  }

  loadAgencyDetails() { }

  initializeRisData(upload_folders: any) {
    (this.ris_scoring_test.controls as UntypedFormGroup[]).map(async (control: UntypedFormGroup) => {
      if (upload_folders) {
        if (control.value.control == 'policyProblemAndNeedForGovernmentAction') {
          if (upload_folders.policyProblem) {
            this.store.ref(upload_folders.policyProblem).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'policyObjective') {
          if (upload_folders.policyObjective) {
            this.store.ref(upload_folders.policyObjective).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'policyOptions') {
          if (upload_folders.policyOptions) {
            this.store.ref(upload_folders.policyOptions).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'assessmentOfPolicyOptions') {
          if (upload_folders.policyOptionsAssessment) {
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'consultation') {
          if (upload_folders.consultationDocumentation) {
            this.store.ref(upload_folders.consultationDocumentation).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'recommendedOption') {
          if (upload_folders.policyOptionsAssessment) {
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'implementationAndEnforcement') {
          if (upload_folders.implementationEnforcement) {
            this.store.ref(upload_folders.implementationEnforcement).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'monitoringAndEvaluation') {
          if (upload_folders.monitoringEvaluation) {
            this.store.ref(upload_folders.monitoringEvaluation).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        // else if(control.value.control == 'overallRISRating'){


        // }
        // else if(control.value.control == 'tagging'){
        //   control.patchValue({url:''})

        // }
        // else if(control.value.control == 'relatedIssuances'){
        //   control.patchValue({url:''})

        // }
        // else if(control.value.control == 'textOfProposedRegulation'){
        //   control.patchValue({url:''})

        // }
        // else if(control.value.control == 'pbrisConsultation'){
        //   control.patchValue({url:''})

        // }
      }
    })
  }

  //figuring out to connect back to Worklist-Existing Regulations
  handleRisData(risData: any, upload_folders: any) {
    // console.log('risData',risData);
    (this.ris_scoring_test.controls as UntypedFormGroup[]).map(async (control: UntypedFormGroup) => {
      let controlName = control.value.control
      let controlCtr: number = -1
      let controlCtr2: number = -1
      if (controlName == 'implementationAndEnchancement' || controlName == 'implementationAndEnforcement') {
        controlCtr = risData.risScoring.map((element: any) => element.control).indexOf('implementationAndEnchancement')
        controlCtr2 = risData.risScoring.map((element: any) => element.control).indexOf('implementationAndEnforcement')
        if (controlCtr2 >= 0) controlCtr = controlCtr2
      } else {
        controlCtr = risData.risScoring.map((element: any) => element.control).indexOf(controlName)
      }
      // const controlCtr1 = risData.risScoring.map((element:any)=>element.control).indexOf(controlNameAlt)

      if (upload_folders) {
        if (control.value.control == 'policyProblemAndNeedForGovernmentAction') {
          if (upload_folders.policyProblem) {
            this.store.ref(upload_folders.policyProblem).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'policyObjective') {
          if (upload_folders.policyObjective) {
            this.store.ref(upload_folders.policyObjective).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'policyOptions') {
          if (upload_folders.policyOptions) {
            this.store.ref(upload_folders.policyOptions).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'assessmentOfPolicyOptions') {
          if (upload_folders.policyOptionsAssessment) {
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'consultation') {
          if (upload_folders.consultationDocumentation) {
            this.store.ref(upload_folders.consultationDocumentation).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'recommendedOption') {
          if (upload_folders.policyOptionsAssessment) {
            this.store.ref(upload_folders.policyOptionsAssessment).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'implementationAndEnforcement') {
          if (upload_folders.implementationEnforcement) {
            this.store.ref(upload_folders.implementationEnforcement).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        else if (control.value.control == 'monitoringAndEvaluation') {
          if (upload_folders.monitoringEvaluation) {
            this.store.ref(upload_folders.monitoringEvaluation).getDownloadURL()
              .subscribe({
                next: (response) => {
                  control.patchValue({ url: response })
                },
                error: (err) => {
                  console.error('Error no document found!: ', control.value.control)
                }
              })
          }
        }
        // else if(control.value.control == 'overallRISRating'){


        // }
        // else if(control.value.control == 'tagging'){
        //   control.patchValue({url:''})

        // }
        // else if(control.value.control == 'relatedIssuances'){
        //   control.patchValue({url:''})

        // }
        // else if(control.value.control == 'textOfProposedRegulation'){
        //   control.patchValue({url:''})

        // }
        // else if(control.value.control == 'pbrisConsultation'){
        //   control.patchValue({url:''})

        // }
      }

      if (controlCtr >= 0) {
        let risDetail = risData.risScoring[controlCtr];
        if (risDetail.score.previousRemarks && risDetail.score.previousRemarks.length > 0) {
          (control.controls.score as UntypedFormGroup).setControl('previous_remarks', this.buildPreviousRemarks(risDetail.score.previousRemarks));
        }
        (control.controls.score as UntypedFormGroup).patchValue({
          rating: risDetail.score.rating ? risDetail.score.rating : 0,
        });
      }
    });
    // let url:any;
    // if(upload_folders){

    // }

    let batches: any = [];

    if (risData.risAssessmentFile) {
      batches.push(this.store.ref(risData.risAssessmentFile).getDownloadURL())
    }
    if (risData.coverLetterFile) {
      batches.push(this.store.ref(risData.coverLetterFile).getDownloadURL())
    }

    forkJoin(batches)
      .subscribe({
        next: (results: any) => {

          for (var x in results) {

            let previous_remarks = this.fB.array([]);

            let doc_name = '';
            let doc_path = '';
            let doc_code = '';
            let doc_count = 0;
            if (results[x]) doc_count = 1

            if (x == '0') {
              doc_name = 'RIS Assessment Document';
              doc_path = risData.risAssessmentFile;
              doc_code = 'risAssessment';

              previous_remarks = this.buildPreviousRemarks(risData.risAssessmentFilePreviousRemarks);
            }

            if (x == '1') {
              doc_name = 'Cover Letter';
              doc_path = risData.coverLetterFile;
              doc_code = 'coverLetter';

              previous_remarks = this.buildPreviousRemarks(risData.coverLetterFilePreviousRemarks);
            }

            this.og_ris_documents[doc_code].count = doc_count;
            this.ris_documents_test.push(new UntypedFormGroup({
              doc_name: new UntypedFormControl(doc_name),
              doc_code: new UntypedFormControl(doc_code),
              url: new UntypedFormControl(results[x]),
              doc_path: new UntypedFormControl(doc_path),
              fileDetails: new UntypedFormGroup({
                count: new UntypedFormControl(doc_count, [Validators.min(1), Validators.max(1)]),
                type: new UntypedFormControl('', this.checkUploadType()),
                size: new UntypedFormControl(0, Validators.max(this.oneMBinByte * 10)),
              }),
              remarksDetails: new UntypedFormGroup({
                previous_remarks: previous_remarks,
                remarks: new UntypedFormControl('')
              }),
              new_file: new UntypedFormControl('')
            }));
          }

          // console.log('ris_documents_test',this.ris_documents_test.value)
        },
        error: (err) => {
          console.error('Error: ', err)
        }
      });
  }

  buildPreviousRemarks(previousRemarksArr: any[]): UntypedFormArray {
    let previousRemarksFormArray = this.fB.array([]);

    // Handle undefined here
    previousRemarksArr = previousRemarksArr || [];

    previousRemarksArr.forEach((element2: any) => {
      previousRemarksFormArray.push(new UntypedFormGroup({
        currentAccountSubtype: new UntypedFormControl(element2.currentAccountSubtype),
        date: new UntypedFormControl(element2.date),
        remark: new UntypedFormControl(element2.remark),
        userID: new UntypedFormControl(element2.userID),
        userName: new UntypedFormControl(element2.userName),
      }));
    })

    return previousRemarksFormArray;
  }



  onChangeRegText(e: any, sectionFlag: number) {
    // 0 = section
    // 1 = subsection
    switch (sectionFlag) {
      case 0:
        this.selected_section = e?.selectedOptions?.selected[0].value;
        this.selected_subsection = this.selected_section.subsections[0];
        break;
      case 1:
        this.selected_subsection = e?.selectedOptions?.selected[0]?.value;
        break;
    }
  }

  get allConsultationsCompleted(): boolean {
    // total_consultations_count must be greater than 0 during CRIS
    return environment.consultationTimegateDisabled || (
      this.total_consultations_count > 0 &&
      this.total_consultations_count == this.reg_completed_consultations.length
    );
  }

  // documents handler
  buttonUpload(event: any, controlCode: string) {
    this.saveFileInformation(event.target.files, controlCode)
  }

  dropzone(files: File[], controlCode: string) {
    this.saveFileInformation(files, controlCode)
  }

  saveFileInformation(files: any, controlCode: any) {
    console.log('files: ', files)
    switch (controlCode) {
      case 'RIS Assessment Document': this.saveFileInformation2(files, controlCode)
        break;
      case 'Cover Letter': this.saveFileInformation2(files, controlCode)
        break;
      default: { console.warn('Invalid upload') }
        break;
    }
  }

  saveFileInformation2(files: any, controlCode: any,) {
    let controlName = ''
    let file: any;
    if (files.length > 0) file = files[0]
    if (this.ris_documents_test.length > 0) {
      (this.ris_documents_test.controls as UntypedFormGroup[]).some((element: UntypedFormGroup) => {
        if (element.value.doc_name == controlCode) {
          this.hasFileUploaded = true
          let fileDetails = (element.controls['fileDetails'] as UntypedFormGroup)
          fileDetails.markAllAsTouched();
          fileDetails.markAsDirty();
          if (files.length > 0) {
            if (files.length == 1) {
              element.patchValue({
                new_file: file.name,
                // count: 1,
                // type: file.type,
                // size: file.size,
                fileDetails: {
                  count: 1,
                  type: file.type,
                  size: file.size,
                }
              });
              let valid = element.valid

              if (controlCode == 'RIS Assessment Document') {
                this.showRisAssessmentDocumentFileUploadErrors = false;
                this.display_ris_assessment_document_file_name = file.name;
                if (valid == true) this.ris_assessment_document_filepath = file;
              }
              else if (controlCode == 'Cover Letter') {
                this.showCoverLetterFileUploadErrors = false;
                this.display_cover_letter_file_name = file.name;
                if (valid == true) this.cover_letter_filepath = file;
              }
            } else {
              element.patchValue({
                new_file: file.name + ', ...',
                // count: files.length,
                // type: '',
                // size: 0,
                fileDetails: {
                  count: files.length,
                  type: '',
                  size: 0,
                }
              });
            }
          } else {
            element.patchValue({
              new_file: file.name,
              // count: 0,
              // type: '',
              // size: 0,
              fileDetails: {
                count: 0,
                type: '',
                size: 0,
              }
            });
          }
          return true
        }
        return false
      });
    } else {
      if (controlCode == 'RIS Assessment Document') controlName = 'risAssessment'
      else if (controlCode == 'Cover Letter') controlName = 'coverLetter'
      else return;

      this.hasFileUploaded = true

      this.saveFileInformationSetControl(controlName, file, files.length)

      if (controlName == 'risAssessment') {
        this.display_ris_assessment_document_file_name = this.ris_documents_new.value[controlName].name;
        this.showRisAssessmentDocumentFileUploadErrors = this.ris_documents_new.controls[controlName].invalid;
      }
      else if (controlName == 'coverLetter') {
        this.display_cover_letter_file_name = this.ris_documents_new.value[controlName].name;
        this.showCoverLetterFileUploadErrors = this.ris_documents_new.controls[controlName].invalid;
      }

      if (this.ris_documents_new.controls[controlName].valid) {
        if (controlName == 'risAssessment') this.ris_assessment_document_filepath = file;
        else if (controlName == 'coverLetter') this.cover_letter_filepath = file;
      } else {
        if (controlName == 'risAssessment') this.ris_assessment_document_filepath = null;
        else if (controlName == 'coverLetter') this.cover_letter_filepath = null;
        (document.getElementById(controlName) as HTMLInputElement).value = "";
      }
    }
  }

  saveFileInformationSetControl(controlName: string, file: File, fileL: number) {
    if (!this.ris_documents_new.contains(controlName)) {
      this.ris_documents_new.addControl(controlName, new UntypedFormGroup({
        name: new UntypedFormControl(''),
        count: new UntypedFormControl(0, [Validators.min(1), Validators.max(1)]),
        type: new UntypedFormControl('', this.checkUploadType()),
        size: new UntypedFormControl(0, Validators.max(this.oneMBinByte * 10)),
      }))
      this.ris_documents_new.controls[controlName].markAllAsTouched();
      this.ris_documents_new.controls[controlName].markAsDirty();
    }
    if (fileL > 0) {
      if (fileL == 1) {
        this.ris_documents_new.controls[controlName].patchValue({
          name: file.name,
          count: 1,
          type: file.type,
          size: file.size,
        });
      }
      else {
        this.ris_documents_new.controls[controlName].patchValue({
          name: file.name + ', ...',
          count: fileL,
          type: '',
          size: 0,
        });
      }
    } else {
      this.ris_documents_new.controls[controlName].patchValue({
        name: '',
        count: fileL,
        type: '',
        size: 0,
      });
    }
  }

  /*
  // saveFileInformation(files: any, controlCode:any,) {
  //   if(files.length > 0){
  //     if(files.length == 1){
  //       const file = files[0];

  //       switch(controlCode){
  //         case 'RIS Assessment Document':{
  //           let valid:any = false;
  //           if (this.ris_documents_test.length > 0){
  //             (this.ris_documents_test.controls as FormGroup[]).map((element:FormGroup)=>{
  //               if(element.value.doc_name == controlCode){
  //                 element.patchValue({
  //                   new_file: file.name,
  //                   count: 1,
  //                   type: file.type,
  //                   size: file.size,
  //                 });
  //                 valid = element.valid
  //               }
  //             });
  //           }

  //           this.showRisAssessmentDocumentFileUploadErrors = false;
  //           this.display_ris_assessment_document_file_name = file.name;

  //           if(valid == true){
  //             this.hasFileUploaded = true
  //             this.ris_assessment_document_filepath = file;
  //           }
  //         }
  //         break;

  //         case 'Cover Letter':{
  //           let valid:any = false;

  //           if (this.ris_documents_test.length > 0) {
  //             (this.ris_documents_test.controls as FormGroup[]).map((element:FormGroup)=>{
  //               if(element.value.doc_name == controlCode){
  //                 element.patchValue({
  //                   new_file: file.name,
  //                   count: 1,
  //                   type: file.type,
  //                   size: file.size,
  //                 });
  //                 valid = element.valid
  //               }
  //             });
  //           }

  //           this.showCoverLetterFileUploadErrors = false;
  //           this.display_cover_letter_file_name = file.name;

  //           if(valid == true){
  //             this.hasFileUploaded = true
  //             this.cover_letter_filepath = file;
  //           }
  //         }
  //         break;

  //         default:{

  //         }
  //         break;
  //       }
  //     }else{

  //     }
  //   }else{

  //   }
  // }
  // */

  /*
  // saveFileInformation(title:any, event: any) {
  //   switch (title) {
  //     // risAssessmentDocument
  //     case 'RIS Assessment Document':
  //       var allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
  //       if (!allowedExtensions.exec(event.target.files[0].name)) {
  //         this.display_ris_assessment_document_file_name = '';
  //         this.showRisAssessmentDocumentFileUploadErrors = true;
  //         return;
  //       }
  //       this.hasFileUploaded = true
  //       this.showRisAssessmentDocumentFileUploadErrors = false;
  //       this.ris_assessment_document_filepath = event.target.files[0];
  //       this.display_ris_assessment_document_file_name =
  //         this.ris_assessment_document_filepath.name;

  //       if (this.ris_documents_test.length > 0) {
  //         (this.ris_documents_test.controls as FormGroup[]).map((element:FormGroup)=>{
  //           if(element.value.doc_name == title){
  //             element.patchValue({
  //               new_file: this.ris_assessment_document_filepath.name
  //             });
  //           }
  //         });
  //       }
  //       break;

  //     // coverLetter
  //     case 'Cover Letter':
  //       var allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
  //       if (!allowedExtensions.exec(event.target.files[0].name)) {
  //         this.display_cover_letter_file_name = '';
  //         this.showCoverLetterFileUploadErrors = true;
  //         return;
  //       }
  //       this.hasFileUploaded = true
  //       this.showCoverLetterFileUploadErrors = false;
  //       this.cover_letter_filepath = event.target.files[0];
  //       this.display_cover_letter_file_name = this.cover_letter_filepath.name;

  //       if (this.ris_documents_test.length > 0) {
  //         (this.ris_documents_test.controls as FormGroup[]).map((element:FormGroup)=>{
  //           if(element.value.doc_name == title){
  //             element.patchValue({
  //               new_file: this.cover_letter_filepath.name
  //             });
  //           }
  //         });
  //       }
  //       break;
  //   }
  // }
  // */

  downloadFile(downloadLink: any, fileName: string, section: string) {
    window.open(downloadLink, '_blank')
  }

  resetNewUpload(controlCode: string) {
    let hasNewUploadedFile: boolean = false;
    (this.ris_documents_test.controls as UntypedFormGroup[]).map((element: UntypedFormGroup) => {

      if (element.value.doc_code == controlCode) {
        if (controlCode == 'risAssessment') {
          this.showRisAssessmentDocumentFileUploadErrors = false;
          this.display_ris_assessment_document_file_name = '';
          this.ris_assessment_document_filepath = null;
        }
        else if (controlCode == 'coverLetter') {
          this.showCoverLetterFileUploadErrors = false;
          this.display_cover_letter_file_name = '';
          this.cover_letter_filepath = null;
        }
        element.patchValue({
          new_file: '',
          fileDetails: {
            count: this.og_ris_documents[controlCode].count,
            type: '',
            size: 0,
          }
        });
      }

      if (controlCode == 'risAssessment' && this.ris_assessment_document_filepath) {
        console.log('has risAssessment')
        hasNewUploadedFile = true;
      }
      else if (controlCode == 'coverLetter' && this.cover_letter_filepath) {
        console.log('has coverLetter')
        hasNewUploadedFile = true;
      }
    })

    this.hasFileUploaded = hasNewUploadedFile
  }
  // !documents handler

  // submissions
  submit(actionFlag: number, postingParam?: any) {
    const batch = this.afs.firestore.batch(); // batch uploader, firestore
    let risAssessmentPass: boolean = true
    // if(this.hasCris && !this.hasDris){ //
    let risData: any;
    if (this.ris_allow_action) {

      let ris_save_array: any = (this.ris_scoring_test.controls as UntypedFormGroup[]).map((item1: UntypedFormGroup) => {
        if (!(item1.controls.score as UntypedFormGroup).valid) risAssessmentPass = false

        let previous_remarks = JSON.parse(JSON.stringify(item1.value.score.previous_remarks))
        if (item1.value.score.remarks) {
          let currentRemark = {
            userID: this.user.uid,
            userName: this.user.displayName,
            currentAccountSubtype: sessionStorage.getItem('account_subtype'),
            date: new Date(),
            remark: item1.value.score.remarks
          }
          previous_remarks.push(currentRemark)
        }

        return {
          control: item1.value.control,
          section: item1.value.section,
          score: {
            rating: item1.value.score.rating,
            previousRemarks: previous_remarks,
          },
        }
      })


      // console.log('documentts',this.ris_documents_test.value)
      let uploadProgressCoverLetter!: Observable<number | undefined>;
      let uploadProgressRISAssessment!: Observable<number | undefined>;
      let destinationPathCoverLetter: string = '';
      let destinationPathRisAssessment: string = '';
      let has_assessment_document: boolean = false;
      let has_cover_letter: boolean = false;

      let formGroup_risAssessment: any = null;
      let formGroup_coverLetter: any = null;

      (this.ris_documents_test.controls as UntypedFormGroup[]).map((formGroup) => {
        if (formGroup.value.doc_code == 'risAssessment') formGroup_risAssessment = (formGroup as UntypedFormGroup)
        else if (formGroup.value.doc_code == 'coverLetter') formGroup_coverLetter = (formGroup as UntypedFormGroup)
      })

      if (this.showRisAssessmentDocumentFileUploadErrors
        || ((Object.keys(this.ris_documents_new.value).length > 0) && this.ris_documents_new.contains('risAssessment') && this.ris_documents_new.controls['risAssessment'].invalid)
        || (
          (Object.keys(this.ris_documents_new.value).length == 0) && (
            formGroup_risAssessment == null
            || (formGroup_risAssessment != null && formGroup_risAssessment instanceof UntypedFormGroup && formGroup_risAssessment.invalid)
          )
        )
      ) risAssessmentPass = false;

      if (this.showCoverLetterFileUploadErrors
        || ((Object.keys(this.ris_documents_new.value).length > 0) && this.ris_documents_new.contains('coverLetter') && this.ris_documents_new.controls['coverLetter'].invalid)
        || (
          (Object.keys(this.ris_documents_new.value).length == 0) && (
            formGroup_coverLetter == null
            || (formGroup_coverLetter != null && formGroup_coverLetter instanceof UntypedFormGroup && formGroup_coverLetter.invalid)
          )
        )
      ) risAssessmentPass = false;

      if (this.hasFileUploaded) {
        if (this.ris_assessment_document_filepath) has_assessment_document = true;
        if (this.cover_letter_filepath) has_cover_letter = true;
      }
      let coverLetterFilePreviousRemarksArr: any = []
      let risAssessmentFilePreviousRemarksArr: any = []

      if (this.ris_documents_test.length > 0) {
        this.ris_documents_test.controls.map((element) => {
          // console.log('element',element.value)
          let previous_remarks = JSON.parse(JSON.stringify(element.value.remarksDetails.previous_remarks))
          if (element.value.remarksDetails.remarks) {
            let currentRemark = {
              userID: this.user.uid,
              userName: this.user.displayName,
              currentAccountSubtype: sessionStorage.getItem('account_subtype'),
              date: new Date(),
              remark: element.value.remarksDetails.remarks
            }
            previous_remarks.push(currentRemark)
          }

          if (element.value.doc_name == 'RIS Assessment Document') {
            // console.log('Assessment',previous_remarks)
            risAssessmentFilePreviousRemarksArr = previous_remarks
            if (element.value.url) {
              destinationPathRisAssessment = element.value.doc_path
              has_assessment_document = true
            }
          }
          else if (element.value.doc_name == 'Cover Letter') {
            // console.log('Cover',previous_remarks)
            coverLetterFilePreviousRemarksArr = previous_remarks
            if (element.value.url) {
              destinationPathCoverLetter = element.value.doc_path
              has_cover_letter = true
            }
          }
        })
      }

      if (!has_assessment_document || !has_cover_letter) risAssessmentPass = false

      if (risAssessmentPass) {
        let documentType = ""
        let documentId = ""
        const dateUploadedStr = format(new Date(), 'yyyy-MM-dd');
        if (this.hasDris) {
          documentType = 'regulations-proposed-dris'
          documentId = this.drisId
        } else {
          documentType = this.regulationInboxCollectionName
          documentId = this.paramFromInboxList
        }

        if (this.cover_letter_filepath) {
          // destinationPathCoverLetter = `${this.firebase_folder}/Arta/${documentType}/${documentId}/Cover Leter/${this.cover_letter_filepath.name}`;
          destinationPathCoverLetter = `${this.firebase_folder}/${documentId}/${dateUploadedStr}/${documentType}/Cover Letter/${this.cover_letter_filepath.name}`;
          // uncomment
          // const destinationRef = this.store.ref(destinationPathCoverLetter);
          const task = this.store.upload(
            destinationPathCoverLetter,
            this.cover_letter_filepath
          );

          // uploadProgressCoverLetter = task.percentageChanges();
        }

        if (this.ris_assessment_document_filepath) {
          // destinationPathRisAssessment = `${this.firebase_folder}/Arta/${documentType}/${documentId}/Assessment Document/${this.ris_assessment_document_filepath.name}`;
          destinationPathRisAssessment = `${this.firebase_folder}/${documentId}/${dateUploadedStr}/${documentType}/Assessment Document/${this.ris_assessment_document_filepath.name}`;
          // uncomment
          // const destinationRef = this.store.ref(destinationPathRisAssessment);
          const task = this.store.upload(
            destinationPathRisAssessment,
            this.ris_assessment_document_filepath
          );

          // uploadProgressRISAssessment = task.percentageChanges();
        }
      }

      risData = {
        risScoring: ris_save_array,
        coverLetterFile: destinationPathCoverLetter,
        coverLetterFilePreviousRemarks: coverLetterFilePreviousRemarksArr,
        risAssessmentFile: destinationPathRisAssessment,
        risAssessmentFilePreviousRemarks: risAssessmentFilePreviousRemarksArr,
      }

      // console.log('risAssessmentPass',risAssessmentPass)
      // console.log('risData',risData)

      if (this.hasDris) {
        const updateDris = this.afs.firestore.collection('regulations-proposed-dris').doc(this.drisId)
        batch.update(updateDris, { risData: risData })
      } else {
        const updateRegulation = this.afs.firestore.collection(this.regulationInboxCollectionName).doc(this.paramFromInboxList)
        batch.update(updateRegulation, { risData: risData })
      }
    }

    else if (this.ris_final_assessment) {
      if (this.hasDris) {
        const updateDris = this.afs.firestore.collection('regulations-proposed-dris').doc(this.drisId)
        // Use the property accessor style to update the already existing risData map without completely overwriting it
        batch.update(updateDris, 'risData.risAssessmentForReconsideration', this.risAssessmentForReconsideration)
      }
    }

    if (risAssessmentPass) {
      // if(false){
      if (actionFlag == this.enum_action_flag.POST_REGULATION) {
        var flags = {
          // The submit modal for regulations can still be one component because the RIA workflow is the only significant difference between Existing and Proposed
          regulationType: 'PROPOSED',
          forReconsideration: this.regulationsData['DRIS']?.data?.risData?.risAssessmentForReconsideration
        };
        const dialogRef = this.dialog.open(
          RegulationViewConfirmationModalComponent,
          {
            disableClose: true,
            height: '480px',
            width: '640px',
            data: {
              flags: flags,
              currentStatus: this.reg_status,
            },
          }
        );

        dialogRef.afterClosed().subscribe(
          async (result) => {
            if (result && result.submitFlag) {
              this.submissionConfirmed(batch, result.submitFlag, result.actionFlag, postingParam)
            }
            else console.log('cancelled!');
          },
          (error) => {
            console.log('cancelled!');
          }
        )
      }
      else {
        this.submissionConfirmed(batch, true, actionFlag, postingParam)
      }
    }
    else {
      alert('Regulation Assessment Incomplete: missing/invalid rating or document uploads')
    }
  }

  // firebase.firestore.WriteBatch argument type is causing problems
  async submissionConfirmed(batch: any, submitFlag: any, actionFlag: any, postingParam?: any) {
    let nextStatus = '';
    this.actionFlag = actionFlag
    switch (actionFlag) {
      case WorklistActionFlagPBRISRegulation.SUBMIT_DRAFT:
        nextStatus = WorklistStatusPBRISRegulation.FOR_VERIFICATION;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.FOR_APPROVAL;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW;
        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.RESUBMITTED;
        break;
      case WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_REAPPROVAL;
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_QA:
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.FOR_REVISION;
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_MODIFICATION;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DC:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DIR:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DDG:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DG:
        nextStatus = WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG;
        break;
      case WorklistActionFlagPBRISRegulation.MARK_FOR_RIA:
        nextStatus = WorklistStatusPBRISRegulation.FOR_RIA;
        break;
      // DRIS actions
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_TO_AGENCY_APPR:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_APPROVAL;
        break;
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_TO_ARTA:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW;
        break;
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_TO_ARTA_BRO_DC:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DC;
        break;
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_TO_ARTA_BRO_DIR:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DIR;
        break;
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_TO_ARTA_DDG:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DDG;
        break;
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_TO_ARTA_DG:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_ARTA_REVIEW_DG;
        break;
      case WorklistActionFlagPBRISRegulation.DRIS_SUBMIT_FOR_ASSESSMENT:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_ASSESMENT;
        break;
      case WorklistActionFlagPBRISRegulation.SUBMIT_FOR_POSTING:
        nextStatus = WorklistStatusPBRISRegulation.FOR_POSTING;
        break;
      case WorklistActionFlagPBRISRegulation.RETURN_DRIS:
        nextStatus = WorklistStatusPBRISRegulation.DRIS_FOR_REVISION;
        break;
      case WorklistActionFlagPBRISRegulation.POST_REGULATION:
        // TODO: Update Service Status to POSTED for List of Service retrieval
        nextStatus = WorklistStatusPBRISRegulation.POSTED;
        break;
    }
    this.actionMade = nextStatus

    if (nextStatus !== '') {
      // const batch = this.afs.firestore.batch(); // batch uploader, firestore
      if (nextStatus == WorklistStatusPBRISRegulation.POSTED) {
        if (postingParam && Object.keys(postingParam).length > 0) {
          if (this.hasDris) {
            this.afs.collection('regulations-proposed-dris').doc(this.drisId).snapshotChanges()
              .subscribe({
                next: (res) => {
                  if (res.payload.exists) {
                    let data: any = res.payload.data()
                    let agencyData: any = (Array.isArray(data.reg_agency))
                      ? (data.reg_agency.length > 0) ? data.reg_agency : []
                      : data.reg_agency ? data.reg_agency : ''

                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    let mappedToExistingModel: any = {
                      proposed_id: this.docuFromInboxList,
                      dris_id: this.drisId,
                      date_effective: postingParam.date_effective, //
                      date_posted: today,  // optional
                      date_published_gazette: postingParam.date_published_gazette ? postingParam.date_published_gazette : '', // optional
                      date_published_onar: postingParam.date_published_onar ? postingParam.date_published_onar : '', // optional
                      doc_date: postingParam.doc_date, // mandatory
                      effectivity_type: 'In-Effect',
                      file_file_name: '',//Object.keys(data.upload_folders).length > 0 ? data.upload_folders : "", // optional
                      has_annex_files: data.has_annex_files ? data.has_annex_files : false,
                      firebase_folder: 'regulationsExistingPhase02',
                      is_posted: true,
                      reg_agency: agencyData,
                      reg_business: data.reg_business ? data.reg_business : '',
                      reg_case: data.reg_case ? data.reg_case : '',
                      reg_classification: data.reg_classification ? data.reg_classification : '',
                      reg_division: data.reg_division ? data.reg_division : '',
                      reg_instrument: data.reg_instrument ? data.reg_instrument : '',
                      reg_intl: data.reg_intl ? data.reg_intl : [],
                      reg_juris: data.reg_juris ? data.reg_juris : '',
                      reg_legal: data.reg_legal ? data.reg_legal : [],
                      reg_lifeevent: data.reg_lifeevent ? data.reg_lifeevent : '',
                      reg_number: data.reg_number ? data.reg_number : '',
                      reg_repeal: data.reg_repeal ? data.reg_repeal : [],
                      reg_sector: data.reg_sector ? data.reg_sector : '',
                      reg_significance: data.reg_significance ? data.reg_significance : this.reg_significance ? this.reg_significance.significance ? this.reg_significance.significance : '' : '',
                      reg_stageoflife: data.reg_stageoflife ? data.reg_stageoflife : '',
                      regulation_repeal: data.regulation_repeal ? data.regulation_repeal : false, //
                      regulation_text: data.regulation_text ? data.regulation_text : [],
                      regulation_text_annex_file_folder: data.regulation_text_annex_file_folder ? data.regulation_text_annex_file_folder : '',
                      regulation_text_annex_file_name: data.regulation_text_annex_file_name ? data.regulation_text_annex_file_name : '',
                      subject: data.subject ? data.subject : '',
                      title: data.title ? data.title : '',
                    }
                    // console.log('to Existing Regulation',mappedToExistingModel)

                    // uncomment
                    const updateRegulation = this.afs.firestore
                      .collection(this.regulationInboxCollectionName)
                      .doc(this.paramFromInboxList);
                    // let today = new Date();
                    // today.setHours(0, 0, 0, 0);
                    alert('the regulations has been successfully posted')
                    batch.update(updateRegulation, {
                      is_posted: true,
                      date_posted: today,
                    });
                    const insertToExistingRegulation = this.afs.firestore.collection(this.constExistingRegulation).doc()
                    batch.set(insertToExistingRegulation, mappedToExistingModel)

                    this.submissionConfirmed2(nextStatus, batch, submitFlag, actionFlag, postingParam)
                  }
                },
                error: (err) => {
                  console.error('Error loading dris', err)
                }
              })
          } else {
            let data: any = this.page_info
            let agencyData: any = (Array.isArray(data.reg_agency))
              ? (data.reg_agency.length > 0) ? data.reg_agency : []
              : data.reg_agency ? data.reg_agency : ''

            let today = new Date();
            today.setHours(0, 0, 0, 0);
            let mappedToExistingModel: any = {
              proposed_id: this.docuFromInboxList,
              dris_id: null,
              date_effective: postingParam.date_effective, //
              date_posted: today,  // optional
              date_published_gazette: postingParam.date_published_gazette ? postingParam.date_published_gazette : '', // optional
              date_published_onar: postingParam.date_published_onar ? postingParam.date_published_onar : '', // optional
              doc_date: postingParam.doc_date, // mandatory
              effectivity_type: 'In-Effect',
              file_file_name: '', // optional
              has_annex_files: data.has_annex_files ? data.has_annex_files : false,
              firebase_folder: 'regulationsExistingPhase02',
              is_posted: true,
              reg_agency: agencyData,
              reg_business: data.tags ? data.tags.reg_business ? data.tags.reg_business : '' : '',
              reg_case: data.tags ? data.tags.reg_case ? data.tags.reg_case : '' : '',
              reg_classification: data.tags ? data.tags.reg_classification ? data.tags.reg_classification : '' : '',
              reg_division: data.tags ? data.tags.reg_division ? data.tags.reg_division : '' : '',
              reg_instrument: data.reg_instrument ? data.reg_instrument : '',
              reg_intl: data.tags ? data.tags.reg_intl ? data.tags.reg_intl : [] : [],
              reg_juris: data.tags ? data.tags.reg_juris ? data.tags.reg_juris : '' : '',
              reg_legal: data.tags ? data.tags.reg_legal ? data.tags.reg_legal : [] : [],
              reg_lifeevent: data.tags ? data.tags.reg_lifeevent ? data.tags.reg_lifeevent : '' : '',
              reg_number: '',
              reg_repeal: data.tags ? data.tags.reg_repeal ? data.tags.reg_repeal : [] : [],
              reg_sector: data.tags ? data.tags.reg_sector ? data.tags.reg_sector : '' : '',
              reg_significance: data.reg_significance ? data.reg_significance : this.reg_significance ? this.reg_significance.significance ? this.reg_significance.significance : '' : '',
              reg_stageoflife: data.tags ? data.tags.reg_stageoflife ? data.tags.reg_stageoflife : '' : '',
              regulation_repeal: data.tags ? data.tags.regulation_repeal ? data.tags.regulation_repeal : false : false, //
              regulation_text: data.regulation_text ? data.regulation_text : [],
              regulation_text_annex_file_folder: data.regulation_text_annex_file_folder ? data.regulation_text_annex_file_folder : '',
              regulation_text_annex_file_name: data.regulation_text_annex_file_name ? data.regulation_text_annex_file_name : '',
              subject: data.subject ? data.subject : '',
              title: data.title ? data.title : '',
            }

            // console.log('to Existing Regulation',mappedToExistingModel)

            // uncomment
            const updateRegulation = this.afs.firestore
              .collection(this.regulationInboxCollectionName)
              .doc(this.paramFromInboxList);
            // let today = new Date();
            // today.setHours(0, 0, 0, 0);
            alert('the regulations has been successfully posted')
            batch.update(updateRegulation, {
              is_posted: true,
              date_posted: today,
            });
            const insertToExistingRegulation = this.afs.firestore.collection(this.constExistingRegulation).doc()
            batch.set(insertToExistingRegulation, mappedToExistingModel)

            this.submissionConfirmed2(nextStatus, batch, submitFlag, actionFlag, postingParam)
          }
        } else {
          this.submissionConfirmed2(nextStatus, batch, submitFlag, actionFlag, postingParam)
        }
      }
      else if (
        [
          WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC.valueOf(),
          WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR.valueOf(),
          WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG.valueOf(),
          WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG.valueOf(),
          WorklistStatusPBRISRegulation.FOR_POSTING.valueOf(),
          WorklistStatusPBRISRegulation.FOR_RIA.valueOf()
        ].indexOf(nextStatus) > -1
      ) {
        // Post-PIS assessment statuses, update PIS assessment

        // pis_assessment should be an one-to-one, although input is displayed as a length-1 table
        let updatePisAssessmentObj = {
          pia_summary: this.pis_assessment['pia_summary'][0].value,
          pia_section_1: this.pis_assessment['pia_section_1'][0].value,
          pia_section_2: this.pis_assessment['pia_section_2'][0].value,
          pia_section_3: this.pis_assessment['pia_section_3'][0].value,
          pia_section_4: this.pis_assessment['pia_section_4'][0].value,
          pia_section_5: this.pis_assessment['pia_section_5'][0].value,
          pia_section_6: this.pis_assessment['pia_section_6'][0].value,
          reg_significance: this.pis_assessment['reg_significance'][0].value,
          reg_lenses: this.pis_assessment['reg_lenses'][0].value,
          reg_details: this.pis_assessment['reg_details'][0].value,
          flagged_sections: this.flagged_sections.value
        };

        // For insertion of latest remark to previous remarks (similar to RIA assessment)
        Object.entries(updatePisAssessmentObj).forEach(([w, remarkObj]: any) => {
          console.log(w);
          console.log(remarkObj);
          if (w === 'flagged_sections') {
            console.log('check');
          }

          // Handling for flagged_sections
          if (Array.isArray(remarkObj)) {
            remarkObj.forEach((y: any) => {
              let remarkObj2 = y;
              if (Array.isArray(remarkObj2.previous_remarks) && remarkObj2.remarks) {
                let currentRemark = {
                  userID: this.user.uid,
                  userName: this.user.displayName,
                  currentAccountSubtype: sessionStorage.getItem('account_subtype'),
                  date: new Date(),
                  remark: remarkObj2.remarks
                }
                remarkObj2.previous_remarks.push(currentRemark);

                // Remove the remarks before saving as this is meant to be input only
                delete remarkObj2.remarks;
              }
            });
          }

          // Regular case
          if (Array.isArray(remarkObj.previous_remarks) && remarkObj.remarks) {
            let currentRemark = {
              userID: this.user.uid,
              userName: this.user.displayName,
              currentAccountSubtype: sessionStorage.getItem('account_subtype'),
              date: new Date(),
              remark: remarkObj.remarks
            }
            remarkObj.previous_remarks.push(currentRemark);

            // Remove the remarks before saving as this is meant to be input only
            delete remarkObj.remarks;
          }
        });

        // uncomment
        const updateRegulation = this.afs.firestore
          .collection(this.regulationInboxCollectionName)
          .doc(this.paramFromInboxList);

        batch.update(updateRegulation, {
          pis_assessment: updatePisAssessmentObj
        });

        this.submissionConfirmed2(nextStatus, batch, submitFlag, actionFlag, postingParam);
      }
      else {
        this.submissionConfirmed2(nextStatus, batch, submitFlag, actionFlag, postingParam)
      }
    }
    //email notifications
    const userAgency = this.currenUser.government.agency;
    const userEmail = [this.currenUser.credentials.email];
    var data: any = {};
    data.regulationTitle = this.reg_documentTitle;
    this.pEmail.emailNotificationbyAction(userAgency, userEmail, data, actionFlag);
  }

  async submissionConfirmed2(nextStatus: any, batch: any, submitFlag: any, actionFlag: any, postingParam?: any) {

    let updateObj: any = {
      status: nextStatus,
    };

    if (nextStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW) {
      updateObj.dateSubmitted = Timestamp.now().toDate()
    }

    // uncomment
    const updateWorklist = this.afs.firestore
      .collection(this.regulationInboxWorklistCollectionName)
      .doc(this.docuFromInboxList);
    batch.update(updateWorklist, updateObj);

    // console.log('batch commit')

    // uncomment
    await batch
      .commit()
      .then(() => {
        console.log('batch commited')
        this.showOnInboxViewport();
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  toPostRegulation(param: any) {
    if (param && Object.keys(param).length > 0) {
      this.submit(this.enum_action_flag.POST_REGULATION, param)
    } else {
      console.warn('param empty')
    }
  }
  // !submissions

  // view handler
  resetFlag() {
    for (let code of Object.keys(this.showViews)) this.showViews[code] = false
  }

  onView(code: string) {
    this.resetFlag()
    this.showViews[code] = true
  }
  // !view handler

  // email handler
  async getDetailsForEmailer() {
    // console.log('agency id',this.reg_fromAgency)
    const agency: any = await this.getAgency(this.reg_fromAgency)
    // console.log('agency',agency)
    // console.log('title:',this.reg_display_title)
    if (agency) {
      const previousStatus = this.actionFlag
      const afterStatus = this.actionMade
      // console.log('previousStatus',previousStatus)
      // console.log('actionMade',afterStatus)
      // encoder -> verifier
      if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_DRAFT && afterStatus == WorklistStatusPBRISRegulation.FOR_VERIFICATION) {
        // console.log('encoder: 112, verifier:115')
        let encoderData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Encoder', 112, encoderData)
        let verifierData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 115, verifierData)
      }

      //returned by validator for revision
      else if ((afterStatus == WorklistStatusPBRISRegulation.FOR_REVISION
        && (previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_QA || previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR)
        || (previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA && afterStatus == WorklistStatusPBRISRegulation.FOR_MODIFICATION))
      ) {
        // console.log('encoder: 113')
        let encoderData = {}
        await this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Encoder', 113, encoderData)

        // returned from arta/agency head to agency for revision
        if (previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_AGENCY_APPR || previousStatus == WorklistActionFlagPBRISRegulation.RETURN_FROM_ARTA) {
          // console.log('verifier: 118')
          let verifierData = {}
          this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 118, verifierData)

          if (afterStatus == WorklistStatusPBRISRegulation.FOR_MODIFICATION) {
            let userData = {
              regulationTitle: this.reg_display_title,
              agencyTitle: agency.agencyDetails ? agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name : agency.name,
            }
            // console.log('arta: 84')
            this.getCurrentUser(84, userData)
          }
        }
      }

      // verifier to agency head approval
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_AGENCY_APPR && afterStatus == WorklistStatusPBRISRegulation.FOR_APPROVAL) {
        // console.log('head: 116')
        let headData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Agency Head', 116, headData)
      }

      // submit to arta
      else if (
        (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW)
        || (previousStatus == WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_AGENCY_APPR && afterStatus == WorklistStatusPBRISRegulation.RESUBMITTED)
        || (previousStatus == WorklistActionFlagPBRISRegulation.RE_SUBMIT_TO_ARTA && afterStatus == WorklistStatusPBRISRegulation.FOR_REAPPROVAL)
      ) {
        // console.log('encoder: 114, verifier: 117, po: 81')
        let encoderData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Encoder', 114, encoderData)
        let verifierData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 117, verifierData)
        let artaData = {}
        this.getUsersUnderArta('ARTA - BRO - Program Officer', 81, artaData)
      }

      // cleared by arta and up for posting
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_FOR_POSTING && afterStatus == WorklistStatusPBRISRegulation.FOR_POSTING) {
        // console.log('verifier: 119')
        let verifierData = {}
        this.getUsersUnderAgency([this.reg_agency, agency.id], 'Government - Verifier', 119, verifierData)
      }

      //email users subscribed when published
      else if (afterStatus == WorklistStatusPBRISRegulation.POSTED) {
        // console.log('users: 7')
        // this.getUsersSubscribed(regulationId)
      }

      // bro po to bro dc
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DC && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DC) {
        // console.log('dc: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - BRO - Division Chief', 81, artaData)
      }

      // bro dc to bro dir
      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_BRO_DIR && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DIR) {
        // console.log('dir: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - BRO - Director', 81, artaData)
      }

      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DDG && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DDG) {
        // console.log('ddg: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - DDG', 81, artaData)
      }

      else if (previousStatus == WorklistActionFlagPBRISRegulation.SUBMIT_TO_ARTA_DG && afterStatus == WorklistStatusPBRISRegulation.FOR_ARTA_REVIEW_DG) {
        // console.log('dg: 81')
        let artaData = {}
        this.getUsersUnderArta('ARTA - DG', 81, artaData)
      }
    } else console.warn('cannot fine agency')
  }

  getCurrentUser(templateCode: any, data: any) {
    this.auth.user.subscribe({
      next: (info) => {
        let uid = info?.uid
        if (uid) {
          this.afs.collection('Users').doc(uid).snapshotChanges()
            .pipe(first())
            .subscribe({
              next: (res) => {
                let user: any = res.payload.data()
                // console.log('user: ',user)
                // if(user.settings && user.settings.admin.worklist.review == true){
                if (user.credentials.email) this.toSendNotifEmail([user.credentials.email], templateCode, data)
                // console.log('email',user.credentials.email)
                // } else console.warn('user not subscribed to worklist')
              }
            })
        }
      }
    })
  }

  getUsersUnderArta(accountSubtype: any, templateCode: any, data: any) {
    // console.log('getUsersUnderArta',accountSubtype,templateCode,data)
    this.afs.collection('Users', filter => filter.where('credentials.account_subtype', '==', accountSubtype)).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: (res) => {
          if (res.length > 0) {
            let tempUserEmailArr: any[] = []
            res.map(item => {
              let user: any = item.payload.doc.data()
              user.id = item.payload.doc.id
              // check user subscription
              // console.log('user: ',user)
              if (user.settings != undefined && user.settings != null && user.settings != '') {
                if ((templateCode == 81 && user.settings.admin && user.settings.admin.worklist && user.settings.admin.worklist.create == true)
                  || (templateCode != 81 && user.settings.admin && user.settings.admin.worklist && user.settings.admin.worklist.review == true)) {
                  if (!tempUserEmailArr.includes(user.credentials.email)) tempUserEmailArr.push(user.credentials.email)
                } else console.warn('user ' + user.id + ' not subscribed to worklist')
              } else console.warn('user ' + user.id + ' not subscribed to worklist')
            })
            // console.log('tempUserEmailArr',tempUserEmailArr)
            if (tempUserEmailArr.length > 0) this.toSendNotifEmail(tempUserEmailArr, templateCode, data)
          } else console.warn('no arta users with account subtype')
        }
      })
  }

  getUsersUnderAgency(agencyIds: any[], accountSubtype: any, templateCode: any, data: any) {
    this.afs.collection('Users',
      filter => filter.where('government.agency', 'in', agencyIds)
        .where('credentials.account_subtype', '==', accountSubtype)
    ).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: async (res) => {
          if (res.length > 0) {
            let tempUserEmailArr: any[] = []
            res.map(item => {
              let user: any = item.payload.doc.data()
              user.id = item.payload.doc.id
              // console.log('user: ',user)
              // console.log('settings',user.setting)
              if (!tempUserEmailArr.includes(user.credentials.email)) tempUserEmailArr.push(user.credentials.email)
            })
            // console.log('tempUserEmailArr',tempUserEmailArr)
            if (tempUserEmailArr.length > 0) this.toSendNotifEmail(tempUserEmailArr, templateCode, data)
          }
        }
      })
  }

  getAgency(agencyName: any) {
    return new Promise((res, rej) => {
      let batches = []
      batches.push(this.afs.collection('Agency', filter => filter.where('agencyDetails.agencyName', '==', agencyName)).snapshotChanges())
      batches.push(this.afs.collection('Agency', filter => filter.where('name', '==', agencyName)).snapshotChanges())

      combineLatest(batches)
        .pipe(first())
        .subscribe({
          next: (result) => {
            result.forEach((content: any) => {
              content.forEach((item: any) => {
                let agency: any = item.payload.doc.data()
                agency.id = item.payload.doc.id
                return res(agency)
              })
            })
            return res(false)
          },
          error: (err) => {
            console.error('getAgency error: ', err)
            return res(false)
          }
        })
    })
  }

  getUsersSubscribed(regulationId: any, data: any) {
    this.afs.collection('PBRIS Pinned Regulation', filter => filter.where('', '==', '')).snapshotChanges()
      .pipe(first())
      .subscribe({
        next: (result) => {
          // let tempArr:any = []
          // result.map((result2)=>{
          //   let item:any = result2.payload.doc.data()
          //   item.id = result2.payload.doc.id
          //   if(item.settings && user.settings.admin.worklist.review)
          //   // if(!tempArr.map((result3:any)=>result3.id).includes(item.id)) tempArr.push(item)
          // })
        }
      })
  }

  toSendNotifEmail(currentUsers: any[], templateCode: number, data: any) {
    // console.log('sending email: ',currentUsers, templateCode)
    if (templateCode > 0) {
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('RegulationViewExistingComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('RegulationViewExistingComponent emailer error: ',err)
      //     }
      // })
    } else console.error('RegulationViewExistingComponent emailer error: no email template for account type')
  }
  // !email handler

  // ris remarks handler
  checkRemarks(control: UntypedFormGroup) {
    const dialogRef = this.dialog.open(RegulationViewProposedArtaRemarks, {
      disableClose: true,
      height: '80%',
      width: '80%',
      data: {
        section: control.value.section,
        previous_remarks: control.value.score.previous_remarks,
        remarks: control.value.score.remarks,
        action: this.ris_allow_action && this.actionButtonAllowed
      },
    }
    );


    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (this.ris_allow_action && this.actionButtonAllowed) (control.controls.score as UntypedFormGroup).patchValue({ remarks: result.remarks });
      },
      error: (err) => {
        console.error('Error: ', err)
      }
    })
  }

  checkDocumentRemarks(documentDetial: any) {
    let documentVal: any = documentDetial.value
    const dialogRef = this.dialog.open(RegulationViewProposedArtaRemarks, {
      disableClose: true,
      height: '80%',
      width: '80%',
      data: {
        section: documentVal.doc_name,
        previous_remarks: documentVal.remarksDetails.previous_remarks,
        remarks: documentVal.remarksDetails.remarks,
        action: this.ris_allow_action && this.actionButtonAllowed
      },
    }
    );


    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (this.ris_allow_action && this.actionButtonAllowed) (documentDetial.controls.remarksDetails as UntypedFormGroup).patchValue({ remarks: result.remarks });
        }
      },
      error: (err) => {
        console.error('Error: ', err)
      }
    })
  }
  // !ris remarks handler

  // pdf handler
  pageShift(action: any, section: any, index?: any) {
    if (section == 'reg_text_annex') {
      if (action == "next") {
        if (this.reg_text_annex_pagging.annex_file_page < this.reg_text_annex_pagging.annex_file_total_page)
          this.reg_text_annex_pagging.annex_file_page++;
      }
      if (action == "back") {
        if (this.reg_text_annex_pagging.annex_file_page > 1)
          this.reg_text_annex_pagging.annex_file_page--;
      }
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy, section: any, index?: any) {
    if (section === 'reg_text_annex') {
      this.reg_text_annex_pagging.annex_file_total_page = pdf.numPages;
    }
  }
  // !pdf  handler

  // validators
  checkUploadType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control != undefined) {
        const val = control.value ? control.value : null // read '' as any in indexOf
        if (val) {
          let tempbool: boolean = false
          let errors = {
            type: false,
          }
          if (!this.acceptedFileType.includes(val)) {
            tempbool = true
            errors.type = true
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  // !validators

  // validations
  hasError(formGroup: any, controlName: string, errorType: string, forSize?: boolean) {
    switch (formGroup) {
      case 'ris_documents_test': {
        let invalid = false
        if (this.ris_documents_test.length > 0) {
          (this.ris_documents_test.controls as UntypedFormGroup[]).some((element: UntypedFormGroup) => {
            let fileDetails = (element.controls['fileDetails'] as UntypedFormGroup);
            if (element.value.doc_code == controlName) {
              if (forSize == true) invalid = fileDetails.controls['size'].hasError(errorType) && (fileDetails.controls['size'].dirty || fileDetails.controls['size'].touched)
              else
                invalid = (fileDetails.controls['type'].hasError(errorType) && (fileDetails.controls['type'].dirty || fileDetails.controls['type'].touched))
                  || (fileDetails.controls['count'].hasError(errorType) && (fileDetails.controls['count'].dirty || fileDetails.controls['count'].touched))
              return true
            }
            return false
          });
        }
        return invalid
      }
      case 'ris_documents_new': {
        if (this.ris_documents_new.contains(controlName)) {
          const formGroup = (this.ris_documents_new.controls[controlName] as UntypedFormGroup)
          if (forSize == true) return formGroup.controls['size'].hasError(errorType) && (formGroup.controls['size'].dirty || formGroup.controls['size'].touched)
          else
            return (formGroup.controls['type'].hasError(errorType) && (formGroup.controls['type'].dirty || formGroup.controls['type'].touched))
              || (formGroup.controls['count'].hasError(errorType) && (formGroup.controls['count'].dirty || formGroup.controls['count'].touched))
        } else return false
      }
      default: return true
    }
  }
  // !validations
}
