<div class="card shadow">
    <div class="card-header">
        <div class="row">
            <div class="col">
                <h4>Regulatory Stock</h4>
            </div>
            <div class="col text-start">
                <p>
                    Active management of the stock of regulations is part of a sound regulatory system. Managing the stock effectively means retaining 'good regulation', and identifying regulations that are no logner fit for their purpose
                </p>
            </div>
        </div>
    </div>
    <div class="card-body bg-dark p-0">
        <div class="row m-1">
            <a routerLink="/pbris/upload/existing" class="col btn btn-lg btn-outline-light">
                Upload Existing Regulation (Phase 1)
            </a>            
            <a routerLink="/pbris/upload-beta/existing" class="col btn btn-lg btn-outline-light text-light">
                Upload Existing Regulation (Phase 2)</a>
            <a routerLink="/pbris/upload/notification" class="col btn btn-lg btn-outline-light">
                Create Regulatory Notification Form
            </a>
            <a routerLink="/pbris/upload/proposed" class="col btn btn-lg btn-outline-light">
                Upload Proposed Regulation
            </a>            
            <a href="/pbris/regulations/existing" class="col btn btn-lg btn-outline-light text-light">
                Manage Existing Regulation
            </a>
        </div>

</div>