<!--NAVBAR-->
<app-artemis-navigation-header [currentPage]="'Reports'"></app-artemis-navigation-header>

<div class="container-fluid p-3">

    <div class="card shadow p-3">
        <div class="card-head">
            <h3>Create New Report</h3>
        </div>
        <div class="card-body">
            <form [formGroup]="form">

                <div class="form-group">
                    <label for="password">Title</label>
                    <input type="text" class="form-control" formControlName="title" />
                </div>
                <div class="form-group">
                    <label for="password">Description</label>
                    <input type="text" class="form-control" formControlName="description" />
                </div>
                <div class="form-group">
                    <button (click)="addReport()" class="btn btn-block btn-primary"><!-- [disabled]="loading"  -->
                        Add Report
                    </button>
                </div>

            </form>
        </div>
    </div>
    <table class="table table-light table-hover shadow table-responsive">
        <thead>
            <tr>
                <th>
                    <h3>Existing Reports</h3>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let report of reports_list">
                <td>
                    <div>
                        Title: {{report.title}}
                    </div>
                    <div>
                        Description: {{report.description}}
                    </div>
                    <div>
                        Date: {{report.date}}
                    </div>
                    <div>
                        Status: {{report.status}}
                    </div>
                    <div>
                        Compliant: {{report.compliant}}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

</div>