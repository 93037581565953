import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import 'firebase/messaging';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-pbris-account-subscription',
  templateUrl: './pbris-account-subscription.component.html',
  styleUrls: ['./pbris-account-subscription.component.scss']
})
export class PbrisAccountSubscriptionComponent implements OnInit {
  @Input() data_catch: any;
  pinList: any;
  notify: any;
  loading: boolean = false;
  displayToken: any;

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.getAgency();
  }

  async getAgency() {
    this.loading = true;
    let temp: any = []
    this.afs.collection('PBRIS Subscription', res => res.where('user_id','==',this.data_catch.credentials.uid)).get().subscribe(data => {
      this.pinList = data.docs.map(res => {
        let a: any = res?.data()
        if(a.type == 'sector'){
          this.afs.collection('Sector').doc(a.id).get().subscribe(data => {
            let b: any = data.data()
            b.id = data.id
            b.status = a.status
            b.type = a.type
            b.pause = a.pause ? a.pause : false
            b.reg_id = res?.id
            b.subscription = a.subscription
            b.created_at = (a.created_at instanceof Timestamp ? format(a.created_at.toDate(), 'MMMM dd, yyyy') : a.created_at )
            temp.push(b)
          })
        }
        if(a.type == 'agency'){
          this.afs.collection('Agency').doc(a.id).get().subscribe(data => {
            let b: any = data.data()
            b.id = data.id
            b.status = a.status
            b.type = a.type
            b.pause = a.pause ? a.pause : false
            b.reg_id = res?.id
            b.subscription = a.subscription
            b.created_at = (a.created_at instanceof Timestamp ? format(a.created_at.toDate(), 'MMMM dd, yyyy') : a.created_at )
            temp.push(b)
          })
        }
        return temp
      })
    });
  }

  onSubscribe(data: any) {
    console.log(data)
    console.log(data.status)
    let stat: any;
    let detail: any;
    if (data.status) 
    {
      stat = false
      detail = "Successfully unpinned"
    }
    else
    {
      stat = true
      detail = "Successfully pinned"
    } 

    this.afs.collection(`PBRIS Subscription`)
      .doc(data.reg_id)
      .update({status: stat, updated_at: new Date()})
      .then((docRef:any) => {
        this.pinList[0].map((reg: any) => {
          if(reg.reg_id === data.reg_id) {
            reg.status = stat;
          }
          return reg
        })
    }).catch((error:any) =>{
      console.log("Error",error)
    });
  }

  onPause(data: any) {
    console.log(data)
    let a: any = data;

    let stat: any;
    let detail: any;
    if (data.pause) 
    {
      stat = false
      detail = "Successfully unpaused"
    }
    else
    {
      stat = true
      detail = "Successfully paused"
    } 

    this.afs.collection(`PBRIS Subscription`)
      .doc(data.reg_id)
      .update({pause: stat})
      .then((docRef:any) => {
        this.pinList[0].map((reg: any) => {
          if(reg.reg_id === data.reg_id) {
            reg.pause = stat;
          }
          return reg
        })
    }).catch((error:any) =>{
      console.log("Error",error)
    });
  }

  onNotify(data:any, list: any) { 
    console.log(list.target.value+"="+list.target.name);
    console.log(list.target)

    this.afs.collection(`PBRIS Subscription`)
      .doc(data.reg_id)
      .update({subscription : [list.target.value = list.target.value]})
      .then((docRef:any) => {
        this.pinList[0].map((reg: any) => {
          // if(reg.reg_id === data.reg_id) {
          //   reg.pause = stat;
          // }
          // return reg
        })
    }).catch((error:any) =>{
      console.log("Error",error)
    });
  }

  permitToNotify() {
    // TODO: check how to fix RWL 20240828
    // console.log(firebase)
    // const messaging = firebase.messaging();
    /* messaging.requestPermission()
      .then(() => messaging.getToken({ vapidKey: environment.vapidKey }).then(token => {
        this.displayToken = token
        if (token) {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('ngsw-worker.js').then(function (registration) {
              console.log("Service Worker Registered");
              const notificationTitle = 'PBRIS Notification';
              const notificationOptions = {
                body: 'Test Notification.',
                icon: './assets/arta-favicon.png',
                sound: 'default'
              };
              setTimeout(() => {
                  registration.showNotification(notificationTitle, notificationOptions);
                  registration.update();
              }, 100);
            })
            .catch(function (err) {
                console.log("Service Worker Failed to Register", err);
            });
          } else {
            console.warn('Service workers aren\'t supported in this browser.');
          }
        } else {
          console.log("Empty token");
        }
      }))
      .catch((err: any) => {
        console.log('Unable to get permission to notify.', err);
      }); */
  }
}
