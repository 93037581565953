import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArtemisCustomDialogComponent } from 'src/app/artemis/artemis-custom-dialog/artemis-custom-dialog.component';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { AgencyOfficeDivisionModalComponent } from '../agency-office-division-modal/agency-office-division-modal.component';

@Component({
  selector: 'app-agency-office-division',
  templateUrl: './agency-office-division.component.html',
  styleUrls: ['./agency-office-division.component.scss']
})
export class AgencyOfficeDivisionComponent implements OnInit {

  /**
   * @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger1: Subject<any> = new Subject<any>();

   */
  offices: any;
  loading:any = false;

  officeCollection: any = []
  @Input('agency_id') agency_id:any;
  @Input('isAdmin') isAdmin:any;
  @Input('isAgencyMember') isAgencyMember:any;

  constructor(private afs: AngularFirestore,
    private snackBarService: SnackbarNotifService, public dialog: MatDialog) {


  }

  ngOnInit(): void {

    /* //console.log('Agency ID:',this.agency_id)
    if (this.agency_id == null){
      this.agency_id =sessionStorage.getItem('agency_id');
    } */
    //this.getAgencyOffices()
    /**
     * this.dtOptions = {
      processing: true,
      lengthMenu: [10, 20, 50],
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: true,
      ordering:false,
    }
     */
    
  }
  ngAfterViewInit(): void {
    //this.dtTrigger.next(this.dtOptions);
    //this.dtTrigger1.next(this.dtOptions);
   
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

  rerender(): void {
  
    //this.dtElement.dtInstance.then((dtInstance) => {
      //dtInstance.clear().draw();
      // Destroy the table first
     // dtInstance.destroy();
      // Call the dtTrigger to rerender again
      //this.dtTrigger1.next(this.dtOptions);

    //})

  }
  

   getAgencyOffices() {
    let queryFilter: QueryFn;
    let activestatus: any = ["Active"]

    if(this.isAdmin){
      activestatus.push("Deleted")
    }

    queryFilter = (filter)=> 
    filter.where('Status','in',activestatus)
    .where('fromAgencyId','==',this.agency_id)
    .orderBy('level','desc');

    this.afs.collection('Agency-Offices-Division', queryFilter)
    .snapshotChanges()
    .pipe(
      tap((data:any)=>{
        //clear tables before adding snapshots
        this.officeCollection = []
      data.forEach((info:any)=>{
        let item: any = info.payload.doc.data();
        item.id = info.payload.doc.id;
        this.officeCollection.push(item)
      })
      this.rerender();
    })).subscribe()
    


  }


  addOffice() {

    const dialogRef = this.dialog.open(AgencyOfficeDivisionModalComponent,{
      data:{
        action:'Add',
        agencyID: this.agency_id
      }
    });
  }

  UpdateOffice(office:any) {
    //console.log('Update',office.id)
    const dialogRef = this.dialog.open(AgencyOfficeDivisionModalComponent,{
      data:{ 
        id: office.id,
        name: office.name,
        level: office.level,
        agencyId: this.agency_id
      }
    });


  }


  removeOffice(data: any,index:any) {
    
    const dialogRef = this.dialog.open(ArtemisCustomDialogComponent,{
      data:{
        title: "Delete office",
        message: "Would you like to delete " + data.name + "?",
        acceptText:"Yes, Proceed",
        dialogType:1
 

      }
    })

    dialogRef.afterClosed().subscribe(result =>{
      if(result.flag){
        data.Status = "Deleted"
        this.afs.collection('Agency-Offices-Division').doc(data.id).update(data)
        
        .then(()=>{
          this.snackBarService.openPlainNotif("Successfully Deleted","Close");
        })
        .catch(er=>{
          alert(er)
        });

      }
    })
    //console.log("Document id",data.id)
   
  }

}
