import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-artemis-citizens-charter-view',
  templateUrl: './artemis-citizens-charter-view.component.html',
  styleUrls: ['./artemis-citizens-charter-view.component.scss']
})
export class ArtemisCitizensCharterViewComponent implements OnInit {
  @Input() loader:boolean;
  @Input() cc_id:any;
  @Input() agency_id:any;
  @Input() link:any;
  @Output() changeDetect : EventEmitter<any> = new EventEmitter()
  @Output() loaderChange : EventEmitter<boolean> = new EventEmitter()
  @Output() ccIdChange : EventEmitter<any> = new EventEmitter()
  @Output() currentCCChange : EventEmitter<any> = new EventEmitter()
  @Output() currentAgencyChange : EventEmitter<any> = new EventEmitter()
  @Output() servicesChange : EventEmitter<any> = new EventEmitter()
  @Output() cartChange : EventEmitter<any> = new EventEmitter()

  // loader:boolean = false;

  currentCC:any;
  currentCCReportURL:any;
  agencies:any[]= []
  agencyName:any;
  currentAgency:any;
  headOfAgency:any[]= [];
  chairPerson:any[]= [];
  viceChairPerson:any[]= [];
  member:any[]= [];
  services:any[] = []
  countStepwithFormula:number = 0;
  countStepwithPossible:number = 0;


  ordinals: string[] = ['th', 'st', 'nd', 'rd'];

  constructor(
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
  ) { }

 get InternalServices(){
    return this.services.filter(filter=> filter.serviceDetails.service_category == "Internal Service")
  }

  get ExternalServices(){
    return this.services.filter(filter=> filter.serviceDetails.service_category == "External Service")
  }

  //get unique process owners under each service category
  get ExternalServiceProcessOwners() {
    // Filter out external services
    const externalServices = this.services.filter(service => service.serviceDetails.service_category === "External Service");

    // Extract unique process owners
    const processOwners = externalServices.map(service => service.serviceDetails.process_owner);
    const uniqueProcessOwners = Array.from(new Set(processOwners));

    uniqueProcessOwners.sort((a, b) => a.localeCompare(b));

    return uniqueProcessOwners;
  }

  get InternalServiceProcessOwners() {
    // Filter out external services
    const externalServices = this.services.filter(service => service.serviceDetails.service_category === "Internal Service");

    // Extract unique process owners
    const processOwners = externalServices.map(service => service.serviceDetails.process_owner);
    const uniqueProcessOwners = Array.from(new Set(processOwners));

    uniqueProcessOwners.sort((a, b) => a.localeCompare(b));

    return uniqueProcessOwners;
  }
  
  ngOnInit(): void {
    //console.log('CharterReportsComponent 2')
    this.loader = true;
    // this.loaderChangeEmitter()
    this.changeEmitter('loader',this.loader)
    if(!this.currentCC){
      if(this.cc_id && !this.agency_id){
        this.loadCC(this.cc_id)
      }
      else if(this.agency_id && !this.cc_id){
        this.loadCCbyAgency(this.agency_id)
      }
    }
  }

  private loadCC(cc_id:any){
    // console.log('loadCC: '+cc_id)
    let sub1 = this.afs.collection('citizens-charter-phase-01').doc(cc_id)
   .snapshotChanges()
   .pipe(first())
   .subscribe(info=>{
     if(info.payload.exists){
       let charter: any = info.payload.data()
       charter.id = info.payload.id
       this.currentCCReportURL = charter.reportURL
       this.currentCC = charter
      //  this.currentCCChangeEmitter()
       this.changeEmitter('currentCC',charter)
     }
     sub1.unsubscribe()
   })
   
   let sub2 = this.afs.collection('citizens-charter-phase-02').doc(cc_id)
   .snapshotChanges()
   .pipe(first())
   .subscribe(info=>{
     if(info.payload.exists){
       let charter: any = info.payload.data()
       charter.id = info.payload.id
       this.currentCC = charter
       this.currentCCReportURL = charter.reportURL
       if(charter.fromAgencyId) this.loadAgencyById(charter.fromAgencyId)

       this.loadedCC(charter)
     }
     sub2.unsubscribe()
   })
  }

  private loadAgencyById(agencyId:any){
    // console.log('loadAgencyById: '+agencyId)
    let sub1 = this.afs.collection('Agency').doc(agencyId).snapshotChanges()
    .subscribe({
      next:(result)=>{
        if(result){
          if(result.payload.exists){
            let agency:any = result.payload.data()
            agency.id = result.payload.id
            this.loadedAgency(agency)
          }
        }
        sub1.unsubscribe()
      },
      error:(err)=>{
        // console.error('Error: ',err)
        sub1.unsubscribe()
      }
    })
  }

  private loadedAgency(agency:any){
    // console.log('loadedAgency: '+agency)
    this.currentAgency = agency
    // this.currentAgencyChangeEmitter()
    this.changeEmitter('currentAgency',agency)
  }

  private loadCCbyAgency(agency_id:any){
    // console.log('loadCCbyAgency: '+agency_id)
    if(agency_id) this.loadAgencyById(agency_id)

    let sub1 = this.afs.collection('citizens-charter-phase-02',filter=>filter.where("fromAgencyId","==",agency_id).orderBy("date_posted",'desc').limit(1))
    .snapshotChanges()
    .subscribe({
      next:(resultArr)=>{
        resultArr.map((info)=>{
          if(info.payload.doc.exists){
            let charter:any = info.payload.doc.data()
            charter.id = info.payload.doc.id
            this.cc_id = charter.id
            // this.ccIdChangeEmitter()
            this.changeEmitter('ccId',charter.id)
            this.loadedCC(charter)
          }
        })
        sub1.unsubscribe()
      },
      error:(err)=>{
        // console.error('Error: ',err)
        sub1.unsubscribe()
      }
    })
  }

  private loadedCC(charter:any){
    // console.log('loadedCC: ',charter)
    if(charter.fromAgencyName) this.agencyName = charter.fromAgencyName

    if(charter.service_id) this.loadServices(charter.service_id)

    if(charter.committee){
      if(charter.committee.head_of_agency) this.headOfAgency = charter.committee.head_of_agency
      if(charter.committee.chairperson) this.chairPerson = charter.committee.chairperson
      if(charter.committee.vice_chairperson) this.viceChairPerson = charter.committee.vice_chairperson
      if(charter.committee.members) this.member = charter.committee.members
    }
    
    
    const cart = {
      headOfAgency: this.headOfAgency,
      chairPerson: this.chairPerson,
      viceChairPerson: this.viceChairPerson,
      member: this.member
    }
    this.changeEmitter('cart',cart)
    this.currentCC = charter
    this.changeEmitter('currentCC',charter)
    this.loader =false
    this.changeEmitter('loader',this.loader)
    //console.log('charter: ',charter)
  }

  private loadServices(service_ids:any){
    // console.log('loadServices: ',service_ids)
    this.countStepwithFormula = 0;
    this.countStepwithPossible = 0;
    service_ids.map((id:any)=>{
      let sub1 = this.afs.collection('Services Phase 02', filter => filter.where('is_posted', '==', true)).doc(id).snapshotChanges()
      // .pipe(first())
      .subscribe(info=>{
        if(info.payload.exists){
          let service:any = info.payload.data()
          service.id = info.payload.id
          if(service.standardRequirements) service.standardRequirements = this.preProcessStandardReq(service.standardRequirements)

          if(service?.situationalRequirementsArray){
            const temp_sit = service.situationalRequirementsArray
            service.situationalRequirementsArray = []
            temp_sit.forEach((situation:any)=>{
        
              service.situationalRequirementsArray.push({
                title: situation.title,
                requirement: this.preProcessStandardReq(situation.requirement),
                subRequirement: this.preprocessSubSituationalReq(situation.subRequirement? situation.subRequirement: [] )
        
              })
            })
          }
          if(service.serviceDetails.type_of_transaction){
            let transactionTypeString = ''
            if(service.serviceDetails.type_of_transaction.type_g2b == true) transactionTypeString += 'G2B (Government to Business)'
            if(service.serviceDetails.type_of_transaction.type_g2c == true) transactionTypeString += (transactionTypeString ? ', ' : '')+'G2C (Government to Citizen)'
            if(service.serviceDetails.type_of_transaction.type_g2g == true) transactionTypeString += (transactionTypeString ? ', ' : '')+'G2G (Government to Government)'
            service.serviceDetails.transactionTypeString = transactionTypeString
          }
          
          if(service.serviceDetails.operating_hours){
            let tokenizedByDash = service.serviceDetails.operating_hours.split('-')
            //if(id == '39LkWkyiZUfrvC0Lv1PE') //console.log('tokenizedByDash: ',tokenizedByDash.length)
            if(tokenizedByDash.length > 0){
              if(tokenizedByDash.length == 2){
                let tokenizedByColon1 = tokenizedByDash[0].split(':')
                //if(id == '39LkWkyiZUfrvC0Lv1PE') console.log('tokenizedByColon1: ',tokenizedByColon1.length)
                let tokenizedByColon2 = tokenizedByDash[tokenizedByDash.length-1].split(':')
                //if(id == '39LkWkyiZUfrvC0Lv1PE') console.log('tokenizedByColon2: ',tokenizedByColon2.length)
                if(tokenizedByColon1.length == 1 && tokenizedByColon2.length == 1) service.serviceDetails.operatingTime = (tokenizedByColon1[0].trim())+':00 AM- '+(tokenizedByColon2[0].trim())+':00 PM'
                else service.serviceDetails.operatingTime = service.serviceDetails.operating_hours
              }else service.serviceDetails.operatingTime = service.serviceDetails.operating_hours
            } else service.serviceDetails.operatingTime = service.serviceDetails.operating_hours
          }
          
          if(service.clientSteps) service.processedClientStep = this.processClientStep(service.clientSteps)
          service.countStepwithFormula = this.countStepwithFormula;
          service.countStepwithPossible = this.countStepwithPossible;
          // if (this.agencyName == null){
          //   this.agencyName = service.serviceDetails.agency
          //   if(this.agencyName)
          //     this.loadAgency(this.agencyName)
          // }

          if(service.is_posted == true){
            const serviceCtr = this.services.map((serviceData)=>serviceData.id).indexOf(service.id)
            if(serviceCtr < 0){
              this.services.push(service)
              this.changeEmitter('services',this.services)
            }
          }
        }
        sub1.unsubscribe()
      })
    })
  }

  transform(n: number, year: string, keepNumber: boolean = true) {
    let v = n % 100;
    return year  + " "+  (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]) + " Edition";
  }

  private preProcessStandardReq(standardRequirements: any) {
    if(standardRequirements && standardRequirements.length > 0){
      standardRequirements.map((docItem:any)=>{
        switch(docItem.requirementType){
          case 'Documentary':{
            if(docItem.documentFormat && docItem.documentFormat.length > 0){
              docItem.documentString = ''
              docItem.documentFormat.map((formatItem:any,index:any)=>{
                switch(formatItem.type){
                  case 'document':{
                    if(index > 0) docItem.documentString += ' '
                    switch(formatItem.value){
                      case 'Original Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Photo Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Electronic Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                    }
                    break;
                  }
                  case 'operator':{
                    switch(formatItem.value){
                      case 'or':{
                        docItem.documentString +=formatItem.value
                        break
                      }
                      default:{
                        if(index > 0) docItem.documentString += ' '
                        docItem.documentString += formatItem.value
                      }
                    }
                    break;
                  }
                  default:{
                    if(index > 0) docItem.documentString += ' '
                    docItem.documentString += formatItem.value
                  }
                }
              })
            }
            break;
          }
        }
     
      })
    }
    return standardRequirements
  }

  private preprocessSubSituationalReq(subrequirement: any){

    let tempsubreq =[]

    for (var req of subrequirement){

      tempsubreq.push({
        title: req.title,
        requirement: this.preProcessStandardReq(req.requirement)
      })

    }

    return tempsubreq;

  }

  private processClientStep(clientSteps:any[]){
    this.countStepwithFormula =0;
    this.countStepwithPossible = 0;
    let processedClientSteps:any = []
    if(clientSteps && clientSteps.length > 0){
      clientSteps.map((clientStep,index)=>{
        if (clientStep.info?.list_of_formula_fees_arr.length > 0) {
          this.countStepwithFormula++;
        }
        if(clientStep.info?.list_of_possible_fees_arr.length > 0){
          this.countStepwithPossible++;
        }
        if(clientStep.agency_actions && clientStep.agency_actions.length > 0){
          
          clientStep.agency_actions.map((agencyAction:any,index2:number)=>{
            let clientStepRow = {
              clientStepName:index2 == 0 ? (index+1)+'. '+clientStep.info.title: '',
              agencyActions:(index+1)+'.'+(index2+1)+'. '+agencyAction.title,
              fees: index2 == 0 ? this.getClientStepFees(clientStep.info) : '',
              location: clientStep.info.location,
              processingTime:'',
              possibleFeesArr: clientStep.info.list_of_possible_fees_arr,
              formulaFeesArr: clientStep.info.list_of_formula_fees_arr,
              notes: clientStep.info.notes,
              personResponsible:agencyAction.persons_responsible,
              isSituationalStep: clientStep.info.is_situational_step,
              situation: clientStep.info.is_situational_step ? clientStep.info.situation : '',
              
              // borderBottom: (index2 < (clientStep.agency_actions.length - 1)) ? false : true,
              // borderTop: (index2 > 0 ) ? false : true,
              rowSpan: (clientStep.agency_actions.length > 1) ? (index2 == 0) ? clientStep.agency_actions.length : 0 : 1 //(index == 0) ? (clientStep.agency_actions.length > 1) ? clientStep.agency_actions.length : 0 : 1
            }

            // check if AA L > 1
              // T: check index 0
                // T: AA L
                // F: 0
              // F: 1

            let processingTime = '';
            if(agencyAction.processing_time_days){
              const days = Number(agencyAction.processing_time_days);
              if(days && !isNaN(days) && days > 0) processingTime += agencyAction.processing_time_days+' day/s'
            }

            if(agencyAction.processing_time_hours){
              const hours = Number(agencyAction.processing_time_hours);
              if(hours && !isNaN(hours) && hours > 0) processingTime += (processingTime?', ':'')+agencyAction.processing_time_hours+' hour/s'
            }

            if(agencyAction.processing_time_minutes){
              const mins = Number(agencyAction.processing_time_minutes);
              if(mins && !isNaN(mins) && mins > 0) processingTime += (processingTime?', ':'')+agencyAction.processing_time_minutes+' minute/s'
            }
            clientStepRow.processingTime = processingTime
            processedClientSteps.push(clientStepRow)
          })
        }else{
          processedClientSteps.push({
            clientStepName: clientStep.info.title,
            agencyActions:'',
            fees: this.getClientStepFees(clientStep.info),
            processingTime:'',
            personResponsible:'',
            borderBottom: true,
          })
        }
      })
      return processedClientSteps
      // for(let clientStep of clientSteps){
      //   let clientStep = {
      //     clientStepName:clientStep,
      //     agencyActions:'',
      //     fees:'',
      //     processingTime:'',
      //     personResponsible:''
      //   }

      // }
    }

    return processedClientSteps
  }

  private getClientStepFees(clientStepData:any){
    let fees = ''
    if(clientStepData.standard_fees == true || clientStepData.list_of_possible_fees_or_formulas == true){
      if(clientStepData.standard_fees == true){
        fees += clientStepData.fees+' PHP'//return clientStepData.fees+' php'
        if(clientStepData.fees_arr && clientStepData.fees_arr.length > 0){
          fees += (fees ? '<br><br>' : '')+'Breakdown:';
          clientStepData.fees_arr.map((fee_data:any)=>{
            fees += (fees ? '<br>' : '')+fee_data.fee_type+':'
            if(fee_data.amount) fees += (fees ? ' ' : '')+fee_data.amount+' PHP'
          })
        }
      } //fees += clientStepData.fees+' php'//return clientStepData.fees+' php'
      else if(clientStepData.list_of_possible_fees_or_formulas == true && clientStepData.list_of_fees_arr && clientStepData.list_of_fees_arr.length > 0) {
        clientStepData.list_of_fees_arr.map((list_of_fees_data:any)=>{
          fees+=(fees ? '<br><br>' : '')+list_of_fees_data.fee_type+':'
          if(list_of_fees_data.list_fee_desc) fees+=(fees ? '<br>' : '')+list_of_fees_data.list_fee_desc
        })
      }//fees += (fees ? '<br>' : '')+clientStepData.list_of_fees_val//return clientStepData.list_of_fees_val

      return fees
    }
    // else if(clientStepData.is_situational_step) return clientStepData.fees+' php'
    return 'None'
  }

  scrollTo(serviceId:any){
    if(serviceId){
      let element = document.getElementById(serviceId)
      if(element) element.scrollIntoView({ behavior:'auto', block: "center"});
    }
  }

  changeEmitter(code:string,value:any){
    switch(code){
      case 'cart':{
        const cart = {
          headOfAgency: this.headOfAgency,
          chairPerson: this.chairPerson,
          viceChairPerson: this.viceChairPerson,
          member: this.member
        }
        this.changeDetect.emit({code:code,value:cart});
        break;
      }
      default:this.changeDetect.emit({code:code,value:value});break;
    }
  }

  updateLinkTrigger(value:any){
    // if(!this.currentCC.reportURL)
    // console.log('changing cc reportURL: ',value)
    this.currentCC.reportURL = value
  }
}