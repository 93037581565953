import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-agency-office-modal',
  templateUrl: './agency-office-modal.component.html',
  styleUrls: ['./agency-office-modal.component.scss']
})
export class AgencyOfficeModalComponent implements OnInit {

  officetypelist:any = ["Regional Office","Provincial Office","Field Office","Satellite Office","Branch","Subsidiaries","Office","Campus (Main or Satellite)","Others"]

  agency_id:any = ""
  officeData:any;
  office_id:any;
  isupdate:boolean =false;
  loading:any =false;

  officeGroup = this.fb.group({
      type: new UntypedFormControl('',Validators.required),
      name: new UntypedFormControl('',Validators.required)
  })

  constructor(private afs: AngularFirestore, public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AgencyOfficeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    if(this.data.action == 'Add'){
      this.agency_id = this.data.agencyID
    }else{
      this.isupdate =true;
      this.loadData(this.data.id)
      this.officeGroup.controls['type'].setValue(this.data.type)
      this.officeGroup.controls['name'].setValue(this.data.name)
      this.office_id = this.data.id;
    }

  }


   save(){
    this.loading =true
    if(this.officeGroup.valid){
      let data: any = this.officeGroup.value
      data.Status = "Active"
      if (this.isupdate === true){
        this.updating(data);
      }else{
  
        data.fromAgencyId = this.agency_id;
        //console.log('Office Data:',data)
        let office =  this.afs.collection("Agency Offices")
        office.add(data)
        
        if (office){
          this.dialogRef.close();
        }
  
      } 
  }else{
    this.officeGroup.markAllAsTouched()
    this.loading =false
  }
  

}
  

  loadData(id:any){
    this.loading =true;
    this.afs.collection('Agency Offices').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(info=>{
      if(info.payload.exists){
        let data:any = info.payload.data();
        data.id = id
        this.officeData = data
        this.patchData(data)
      }
    })
  }

  updating(data:any){

      this.afs.collection('Agency Offices')
      .doc(this.data.id).update(data)
      .then(()=>{
        this.dialogRef.close();
      })
      .catch(err=>{
        this.loading = false;
        alert('Unable to edit ')
       
      })

  }

  patchData( data:any){
    this.officeGroup.patchValue({
      type: data.type ? data.type : "",
      name: data.name ? data.name : "",
    })
    this.loading =false
  }


}
