import { Component, Input, OnInit, SkipSelf } from '@angular/core';
import { inject } from '@angular/core/testing';
import { ControlContainer, UntypedFormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { EntityBuilderIndex, RequirementBuilderService } from 'src/app/service/requirement-builder.service';

@Component({
  selector: 'app-artemis-requirement-form',
  templateUrl: './artemis-requirement-form.component.html',
  styleUrls: ['./artemis-requirement-form.component.scss'],
  viewProviders : [{
    provide: ControlContainer,

    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]]
  }
]
})
export class ArtemisRequirementFormComponent implements OnInit {

  @Input() groupName: any = ''


  get parentFormGroup(){
    return this.parentF.control as UntypedFormArray
  }

  constructor(
    private reqBuilder: RequirementBuilderService,
    private parentF: ControlContainer
  ) { }

  ngOnInit(): void {
    //console.log(this.parentF.value)

  }

}
