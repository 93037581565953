import { Component, OnChanges,Input, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { take, tap } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RegulationTextNode } from 'src/app/entities/regulation-text-section';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-regulations-phase2-view',
  templateUrl: './regulations-phase2-view.component.html',
  styleUrls: ['./regulations-phase2-view.component.scss']
})
export class RegulationsPhase2ViewComponent implements OnInit, OnChanges {
  @Input() regulation:any;

  isLoaded:boolean = false;

  isPhase2: boolean = true;
  isOnBeta: boolean = true;

  edit_regulation_id=this.route.snapshot.params.id
  edit_worklist_id: any;

  agency_list:any=[]
  legalBases_list:any;
  internationalAgreement_list:any;

  version:any;
  chosenRegulation:any;
  
  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AngularFireAuth
    ) {
    // Initial Regulation Section form
   
  }

  ngOnInit(): void {
    // console.log('INIT regulation details: ',this.regulation)
    this.showRegulationData()
    this.isLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.regulation.currentValue){
        this.regulation = changes.regulation.currentValue
        // console.log('CHANGE regulation details: ',this.regulation)
        switch(this.version){
          case 'Existing': this.chooseVersion('existing')
          break;
          case 'DRIS': this.chooseVersion('dris')
          break;
          case 'CRIS': this.chooseVersion('cris')
          break;
          case 'Proposed': this.chooseVersion('proposed')
          break;
          default: this.showRegulationData()
          break;
        }
        this.isLoaded = true;
      }
  }
  
  showRegulationData(){
    console.log('showRegulationData: ',this.regulation)
    if(this.regulation.existing) this.chooseVersion('existing')
    else if(this.regulation.dris) this.chooseVersion('dris')
    else if(this.regulation.cris) this.chooseVersion('cris')
    else if(this.regulation.proposed) this.chooseVersion('proposed')
  }

  // ngOnChanges(): void {
  //   if(this.regulation){
  //     this.isLoaded = true;

  //     this.loadTabInfo(this.regulation)
  //     // console.log('regulation: ', this.regulation)
  //   }

  //   // this.loadPhase2View()
  // }

  chooseVersion(controlCode:any){
    if(controlCode === 'existing'){
      this.version = 'Existing'
      this.chosenRegulation = this.regulation.existing.data;
    }
    else if(controlCode === 'dris'){
      this.version = 'DRIS'
      this.chosenRegulation = this.regulation.dris.data;
    }
    else if(controlCode === 'cris'){
      this.version = 'CRIS'
      this.chosenRegulation = this.regulation.cris.data;
    }
    else if(controlCode === 'proposed'){
      this.version = 'Proposed'
      this.chosenRegulation = this.regulation.proposed.data;
    }else{
      this.version = null
      this.chosenRegulation = null;
    }
    console.log('chosenRegulation: ',this.chosenRegulation)
  }

  // loadTabInfo(regulation:any){
  //   if(regulation.reg_agency){
  //     for(let x of regulation.reg_agency){
  //       this.agency_list.push(x.name)
  //     }
  //   }
  //   if(regulation.reg_legal){
  //     this.legalBases_list=regulation.reg_legal
  //   } 
  //   if(regulation.reg_intl){
  //     this.internationalAgreement_list=regulation.reg_intl
  //   }
  // }

  
}
