import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { GovServiceService } from 'src/app/service/gov-service.service';

@Component({
  selector: 'app-artemis-service-history-view',
  templateUrl: './artemis-service-history-view.component.html',
  styleUrls: ['./artemis-service-history-view.component.scss']
})
export class ArtemisServiceHistoryViewComponent implements OnInit {

  service_id :any = this.route.snapshot.params['id']
  services:any

  constructor(private afs: AngularFirestore,
    
    public _service: GovServiceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadServices()
  }


  private loadServices() {

    this.route.queryParams.subscribe((params:any)=>{
      this.afs.collection('Services Phase 02')
      .doc(params.service_id).collection("History").doc(this.service_id).snapshotChanges()
        // .pipe(first())
        .subscribe(info=>{
          if(info.payload.exists){
            let service:any = info.payload.data()
            service.id = info.payload.id
            
       
            const formattedService:any =  this._service.preProcessServiceData(service)
  
            this.services = formattedService
            
          }
        })
      
      

    })
   
  
    }

}
