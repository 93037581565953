import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { tap, take } from 'rxjs/operators';
import {
  WorklistActionFlagCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';

interface CitizenCharterPhaseNode {
  name: string;
  remarks: string;
  children?: CitizenCharterPhaseNode[];
  // TODO: Comments?
}

@Component({
  selector: 'app-artemis-service-information-page-confirm-submit-modal',
  templateUrl:
    './artemis-service-information-page-confirm-submit-modal.component.html',
  styleUrls: [
    './artemis-service-information-page-confirm-submit-modal.component.scss',
  ],
})
export class ArtemisServiceInformationPageConfirmSubmitModalComponent
  implements OnInit
{
  enum_service_status = WorklistStatusCitizensCharter;
  enum_action_flag = WorklistActionFlagCitizensCharter;
  finalWarning: boolean = false;
  deleteWarning: boolean = false;
  verifiedWithRemarksWarning: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ArtemisServiceInformationPageConfirmSubmitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.finalWarning) {
      this.finalWarning = this.data.finalWarning;
    }

    if (this.data.deleteWarning) {
      this.deleteWarning = this.data.deleteWarning;
    }
    
    if (this.data.verifiedWithRemarksWarning) {
      this.verifiedWithRemarksWarning = this.data.verifiedWithRemarksWarning;
    }
  }

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }

  submit(actionFlag: number) {
    // final warning if CC is about to get posted, submit otherwise
    if (
      actionFlag == WorklistActionFlagCitizensCharter.POST_CITIZENS_CHARTER &&
      !this.finalWarning
    ) {
      this.finalWarning = true;
    } else {
      const returnServiceEntity = {
        submitFlag: true,
        actionFlag: actionFlag,
      };

      this.dialogRef.close(returnServiceEntity);
    }
  }
}
