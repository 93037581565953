<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - ARTEMIS TICKETS</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                   

                </tr>
                
        </thead>
        <tbody>
            <tr>
                <td class = "table table-warning">TOTAL COUNT RECIEVED</td>
                <td></td>
            </tr>
            <tr>
                <td class = "table table-warning">COUNT PROCESSED</td>
                <td class = "table table-warning">PROCESSED WITHIN SLA</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
            </tr>
        </tbody>
        
          </table>

          <!-- CC SUBMISSION UPDATE-->
                <div class = "container m-3">
                  

                   <table class = "table table-bordered mb-0">
                    
                    <thead class = "table table-warning">
                        <tr>
                            <th class = "table-light" >NATURE</th>
                            <th class = "table-light" >COUNT</th>
                            <th class = "table-light" >AVERAGE PROCESSING TIME</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr>
                            <td class = "table-warning">SYSTEM ISSUE</td>
                            <td></td>
                            <td></td>
                            
                        </tr>
                        <tr>
                            <td class = "table-warning">TECHNICAL ISSUE</td>
                            <td></td>
                            <td></td>
                            
                        </tr>
                    
                        
                    </tbody>
                    
               </table>

               <table class = "table table-bordered mb-0">
                    
                <thead>
                    <tr>
                        <th colspan = "2" class = "table-warning" >COUNT OF PENDING</th>
                        <td></td>
                        
                    </tr>
                    <tr>
                        <th class = "table-light">NATURE</th>
                        <th class = "table-light">COUNT</th>
                        <th class = "table-light">TOTAL PENDING TIME</th>
                        
                        
                    </tr>
                    
                    
                </thead>
                <tbody>
                    <tr>
                        <td class = "table-warning">SYSTEM ISSUE</td>
                        <td></td>
                        <td></td>
                        
                    </tr>
                    <tr>
                        <td class = "table-warning">TECHNICAL ISSUE</td>
                        <td></td>
                        <td></td>
                        
                    </tr>
                
                    
                </tbody>
                
           </table>

               
                </div>
                

               
               
             
</div>