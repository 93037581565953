
<h2 mat-dialog-title ><span *ngIf="file.path == ''">Loading...</span> <span *ngIf="file.path != ''">{{file.file_type}}</span></h2>
<mat-dialog-content  class="mat-typography" style="background-color: whitesmoke !important;">
    <pdf-viewer *ngIf="file" [src]="file.path"></pdf-viewer>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button  [disabeld]="file.path == ''" (click)="openfileinnewwindow(file)">Download</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>


