<div class="container">
  <div class="py-4">
    <div class="form-group mb-3">
      <div class="text-primary fw-bold fs-1">
        Account Management
      </div>
      <hr class="my-0"/>
      <div>
        Manage your account here
      </div>
    </div>
    <div class="border my-3 p-2 rounded">
      <div class="w-full">
        <h6><b>Personal Information</b></h6>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">Name</label>
          <div class="col-sm-9">
            <!-- <input type="text" readonly class="form-control-plaintext" id="staticEmail" [value]="data_catch.personal?.first_name"> -->
            <label>{{ data_catch.personal?.first_name }} {{ data_catch.personal?.last_name }}</label>
          </div>
        </div>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">Company</label>
          <div class="col-sm-9 d-flex">
            <input type="text" readonly class="form-control-plaintext" id="staticEmail" [value]="data_catch.company.name_of_organization ? data_catch.company.name_of_organization : 'N/A'">
            <b class="float-end">Edit</b>
          </div>
        </div>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">Area of Residence</label>
          <div class="col-sm-9 d-flex">
            <input type="text" readonly class="form-control-plaintext" id="staticEmail" [value]="data_catch.personal.area_of_residence ? data_catch.personal.area_of_residence : 'N/A'">
            <b class="float-end">Edit</b>
          </div>
        </div>
      </div>
    </div>
    <div class="border my-3 p-2 rounded">
      <div class="w-full">
        <h6><b>Security Management</b></h6>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">User ID</label>
          <div class="col-sm-9">
            <!-- <input type="text" readonly class="form-control-plaintext" id="staticEmail" [value]="user_info.user_id ? user_info.user_id : 'N/A'"> -->
            <label>{{ data_catch.credentials.uid }}</label>
          </div>
        </div>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">Email Address</label>
          <div class="col-sm-9 d-flex">
            <input type="text" readonly class="form-control-plaintext" id="staticEmail" [value]="data_catch.credentials.email ? data_catch.credentials.email : 'N/A'">
            <b class="float-end">Edit</b>
          </div>
        </div>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">Two Factor Authentication</label>
          <div class="col-sm-9 d-flex">
            <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="N/A">
            <b class="float-end">Edit</b>
          </div>
        </div>
        <div class="mb-3 row border-bottom mx-2">
          <label for="staticEmail" class="col-sm-3 col-form-label">Password</label>
          <div class="col-sm-9 d-flex">
            <input type="text" readonly class="form-control-plaintext" id="staticEmail" value="N/A">
            <b class="float-end">Edit</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>