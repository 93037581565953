import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { tap, take, filter, first } from 'rxjs/operators';
import {
  CharterStatusCitizensCharter,
  EffectivityCitizensCharter,
  GovernmentAccountSubtype,
  PostedServiceStatusCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';
import { ArtemisDelistServiceReasonModalComponent } from './artemis-delist-service-reason-modal/artemis-delist-service-reason-modal.component';
import { Timestamp } from 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { S } from '@angular/cdk/keycodes';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { DataTableDirective } from 'angular-datatables';
import { Subject, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { GovServiceService } from 'src/app/service/gov-service.service';




@Component({
  selector: 'app-artemis-service-management-page',
  templateUrl: './artemis-service-management-page.component.html',
  styleUrls: ['./artemis-service-management-page.component.scss'],
})
export class ArtemisServiceManagementPageComponent implements OnInit {

  results: any = []; // main countainer for the inbox results list
  allResults: any = []; // main countainer for the inbox results list
  account_type: string = '';
  enum_account_subtype = GovernmentAccountSubtype;
  enum_posted_status = PostedServiceStatusCitizensCharter;
  seqNumber = 0;
  classificationkey:any = ""

  classificationFilters:any=["Simple","Complex","Highly Technical"]
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['No', 'Date', 'Classification', 'Service Name', 'Status']

  has_actions: boolean = false;
  // displayedStatus = [...this.displayedColumns,'Actions']

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  defaultValue: string;

  constructor(
    private snackbarService: SnackbarNotifService,
    private afs: AngularFirestore,
    public dialog: MatDialog,
    public auth: AngularFireAuth,
    private router: Router,
    private govService: GovServiceService) {
    this.account_type = sessionStorage.getItem('account_subtype') || '';
   

  }

  ngOnInit(): void {
   
   const certification =  this.loadOngoingCertificationFunc()
   const activeCharter= this.loadOngoingCharterFunc()
    if (this.account_type == this.enum_account_subtype.AGENCY_ENC) {

      combineLatest([certification,activeCharter])
      .pipe(
        tap(([cert,charter]) => {

          if(cert.length <= 0 && charter.length <= 0 ){
            this.displayedColumns.push('Actions')
          } 

        }),
        take(1)
      ).subscribe()
     
      /**
       * if(certification == false || activeCharter == false){
        this.displayedColumns.push('Actions')
      }
       */
      
    }
    this.loadAgencyServicesList();
   
  
  }
  filterBy(keyword:any){
    this.classificationkey = keyword
    this.dataSource = new MatTableDataSource(this.results.filter((filter:any) => filter.serviceDetails.classification.includes(keyword)));
    this.resetSortPage()
  }


 
  loadAgencyServicesList() {

    this.seqNumber = 0;
    this.results.length = 0;
    let inboxFilter: QueryFn;
    
    // display delisted services as well

  

    /***
     * 
     *   if(keyword != ''){
     * inboxFilter = (filter) =>
                 filter
    //                 // .where('is_posted', '==', true)
                     .where('posted_status', '!=', null)
                     .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
                     .where('serviceDetails.classification','==',keyword)
                    .orderBy('posted_status','desc');

     }else{
      inboxFilter = (filter) =>
                filter
                   // .where('is_posted', '==', true)
                    .where('posted_status', '!=', null)
                      .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
                     .orderBy('posted_status','desc');
   
     }
     * 
     * 
     */

     inboxFilter = (filter) =>
     filter
       // .where('is_posted', '==', true)
       .where('posted_status', '!=', null)
       .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
       .orderBy('posted_status', 'desc');

    this.afs
      .collection('Services Phase 02', inboxFilter)
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          // console.log('data',data.length)
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            this.seqNumber += 1
            item.no = this.seqNumber;
            // console.log('item',item)
            /* 
              Whenever worklist status is updated, snapshotChanges() fires off again,
              so ensure the snapshots do not get recklessly added to the list
            */
            if (info.type === 'modified') {
              // For modified snapshots, find and replace the entry
              for (var x in this.results) {
                const worklist = this.results[x];
                if (item.id === worklist.id) {
                  this.results.splice(x, 1, item);
                }
              }
            } else {
              // For added snapshots, check if worklist already exists in the list
              let addFlag = true;
              for (var worklist of this.results) {
                if (item.id === worklist.id) {
                  addFlag = false;
                  break;
                }
              }

              if (addFlag) {

                this.results.push(item);
              }
            }
          });
          this.filterBy(this.classificationkey)
        })
      )
      .subscribe();
  }



  async openReasonForDelisting(service: any) {
    const dialogRef = this.dialog.open(
      ArtemisDelistServiceReasonModalComponent,
      {
        disableClose: true,
        height: '500px',
        width: '660px',
        data: {
          finalWarning: true,
          serviceName: service.serviceDetails.service_name
        },
      }
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.submitFlag && result.submitValue) {
        this.submitDelist(service, result.submitValue);
      } else {
        // console.log('cancelled!');
      }
    });
  }

  private async submitDelist(service: any, reason_for_delisting: string) {
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    const user = await this.auth.currentUser;
    // Agency ID instead of Agency Name due to Agency Maintenance
    let fromAgency = sessionStorage.getItem('agency_id');
    let fromOfficer = user?.displayName;

    let worklist_ref = this.afs.firestore
      .collection(`Worklist-Citizens Charter`)
      .doc();

    let worklistJSON = {
      dateGenerated: Timestamp.now().toDate(),
      fromAgency: fromAgency,
      fromOfficer: fromOfficer,
      status: WorklistStatusCitizensCharter.FOR_VERIFICATION,
      // get the old schedule of fees file upload full path, if available
      documentFile: service.firebase_folder
        ? `${service.firebase_folder}/${service.schedule_of_fees_file_name}`
        : '',
      documentId: service.id,
      category: service.serviceDetails.classification,
      process_owner: service.serviceDetails.process_owner,
      documentTitle: service.serviceDetails.service_name,
      dueDate: Timestamp.now().toDate(),
      effectivity: EffectivityCitizensCharter.DELIST,
      reasonForDelisting: reason_for_delisting,
      serviceCompleteness: true


    };

    batch.set(worklist_ref, worklistJSON);

    const service_ref = this.afs.firestore
      .collection(`Services Phase 02`)
      .doc(service.id);

    batch.update(service_ref, {
      posted_status: PostedServiceStatusCitizensCharter.FOR_DELISTING,
    });

    await batch.commit().then(()=>{
       this.snackbarService.displayNotif('Success','Service submitted for delisting!')
    }).catch(err=>{
      this.snackbarService.displayNotif('Error',err)
  
    })
  }

  sortChange(sort: Sort) {
    // console.log('sort', sort)
    const data = this.results.slice()
    if (!sort.active || sort.direction === '') {
      this.allResults = data
      return;
    }
    this.allResults = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {
        case 'Classification':
          return this.compare(a.serviceDetails.classification, b.serviceDetails.classification, isAsc)
        case 'Date':
          return this.compare(a.date_posted.trim().toLowerCase(), b.date_posted.trim().toLowerCase(), isAsc)
        case 'Service Name':
          return this.compare(a.serviceDetails.service_name.trim().toLowerCase(), b.serviceDetails.service_name.trim().toLowerCase(), isAsc)
        case 'Status':
          return this.compare(a.posted_status.trim().toLowerCase(), b.posted_status.trim().toLowerCase(), isAsc)
        default:
          return 0
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.allResults)
    this.resetSortPage()
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  resetSortPage() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.settingFilter()
    //this.settingSorter()
  }



  settingFilter() {
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      return data.serviceDetails.service_name.toLowerCase().includes(filter.toLowerCase())|| data.posted_status.toLowerCase().includes(filter.toLowerCase())
            //|| data.serviceDetails.classification.toLowerCase().includes(filter.toLocaleLowerCase())

    }
  }


  settingSorter() {
    this.dataSource.sortingDataAccessor = (data: any, property: any) => {
      if (data[property] == null || data[property] == "") {
        if (this.sort.direction == "desc") return '\u0000' + '\u0000' + '\u0000';
        return '\uFFFF' + '\uFFFF' + '\uFFFF';
      }
      return data[property]
    }
  }
    loadOngoingCertificationFunc()  {
    let result:boolean =false
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('citizens_charter', '==', '');

    return  this.afs
      .collection(`services-certification-phase-02`, inboxFilter)
      .snapshotChanges();
  }


    loadOngoingCharterFunc()  {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('status', '!=', CharterStatusCitizensCharter.POSTED);

    return this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .snapshotChanges()
  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();


  }

  gotoserviceEditor(service_id:any){

    this.afs.collection('Worklist-Citizens Charter', 
    filter => filter.where('documentId','==',service_id))
    .snapshotChanges().pipe(
      tap((data:any)=>{
        data.forEach((info:any)=>{
          let item:any = info.payload.doc.data()
          item.id = info.payload.doc.id
    
          this.router.navigate(['artemis/charter/serviceeditor',service_id],{queryParams : { worklistId: item.id ,isEditMode : true, draftonly: true}})
          return
        })
        
      },take(1))
    )
    .subscribe()

   
  }
}
