<div class="content col-md-12">
    <div class="container-fluid">  

        <div class="row">
            <div class="card padded">
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">AGENCY SELF-ASSESSMENT</h6>
                        </div>
                    </div>
                </div>

                <!-- Content -->
                <div class="card-body">
                    <div class="col">
                        <div class="row">
                            <div [innerHtml]="objectData.reg_summary"></div>
                        </div>

                        <!-- Policy & Objectives -->
                        <!-- <div class="row table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead class="info-subheader">
                                    <tr>
                                    </tr>
                                </thead>

                                <tbody class="info-subheader">
                                    <tr>
                                        //<tr *ngFor="let row of service_requirements">
                                        <th><h6>Policy Problem</h6></th>
                                    </tr>
                                    <tr>
                                        <td [innerHtml]="objectData.reg_problem"></td>
                                    </tr>
                                    
                                    <tr>
                                        <th><h6>Objectives of Government Action</h6></th>
                                    </tr>
                                    <tr>
                                        <td [innerHtml]="objectData.reg_objectives"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> --> 

                        <!-- Agency Review -->
                        <div class="row table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead class="info-subheader">
                                    <tr>
                                    </tr>
                                </thead>

                                <tbody class="info-subheader">
                                    <tr>
                                        <th rowspan="2" class="col-md-3"><h6>Agency</h6></th>
                                        <td class="col-md-4">Created By:</td>
                                        <td class="col-md-5">- {{ objectData.reg_fromOfficer }}</td>
                                    </tr>
                                    <tr>
                                        <td>Agency Approved By:
                                            <!-- <p class="fw-bold text-center">Created By</p>
                                            <p class="text-center">{{ objectData.reg_fromOfficer }}</p> -->
                                            <!-- <p class="fw-bold text-center">QA By</p>
                                            <p class ="text-center"> {{reg_agency_name}} </p>  -->
                                            <!-- <p class="fw-bold text-center">Agency Approved By</p>
                                            <p class="text-center">{{ objectData.reg_fromAgency }}</p> -->
                                        </td>
                                        <td>- {{ objectData.reg_fromAgency }}</td>
                                    </tr>
                                </tbody>
                            
                            </table>
                        </div>

                        <!-- Existing Regulation Review Field -->
                        <div class="row table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead class="info-subheader">
                                    <tr>
                                    </tr>
                                </thead>

                                <tbody class="info-subheader">
                                    <tr>
                                        <th rowspan="12" class="col-md-3"><h6>Existing Regulation Review Field</h6></th>
                                        <td class="col-md-4">ARTA QA by:</td>
                                        <td class="col-md-5">- {{ objectData.reg_fromQaARTA }}</td>
                                    </tr>

                                    <tr>
                                        <td>ARTA Approved By:</td>
                                        <td>- {{ objectData.reg_fromApproverARTA }}</td>
                                    </tr>

                                    <tr>
                                        <td class="fw-bold">SIGNIFICANCE:</td>
                                        <td>
                                            {{
                                                objectData.reg_significance ? objectData.reg_significance.significance : ""
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Narrative / Justification:</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" [innerHTML]="
                                        objectData.reg_significance ? objectData.reg_significance.comments : ''
                                        "></td>
                                    </tr>

                                    <tr>
                                        <td colspan="2" class="fw-bold">LENSES</td>
                                    </tr>
                                    <tr>
                                        <td class="col-md-4">GAD Lens:</td>
                                        <td>{{ objectData.reg_lens_inclusive }}</td>
                                    </tr>
                                    <tr>
                                        <td>NDRRMC Lens:</td>
                                        <td>{{ objectData.reg_lens_disaster }}</td>
                                    </tr>
                                    <tr>
                                        <td>Environmental Lens:</td>
                                        <td>{{ objectData.reg_lens_environment }}</td>
                                    </tr>
                                    <tr>
                                        <td>Competition Lens:</td>
                                        <td>{{ objectData.reg_lens_comp }}</td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Narrative / Justification:</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" [innerHTML]="
                                        objectData.reg_lenses ? objectData.reg_lenses_comments : ''
                                        "></td>
                                    </tr>
                                    
                                </tbody>

                            </table>
                        </div>

                            <!-- Hide until Regulatory Plan is implemented -->
                            <!-- <div class="row">
                                        <table class="table">
                                            <thead class="info-header">
                                            <tr>
                                                <th>
                                                <h5 class="info-subheader text-center">
                                                    Alignment to Regulatory Agenda
                                                </h5>
                                                </th>
                                                <th>
                                                <h5 class="info-subheader text-center">
                                                    Inclusion Regulatory Plan
                                                </h5>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>
                                                <li>[INPUT LIST HERE]</li>
                                                </td>
                                                <td>
                                                <li>[INPUT LIST HERE]</li>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                        
                        <!-- Other Agencies to Consult -->
                        <div class="row table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead class="info-subheader">
                                    <tr>
                                    </tr>
                                </thead>

                                <tbody class="info-subheader">
                                    <tr>
                                        <th rowspan="7" class="col-md-3"><h6>Other Agencies to Consult</h6></th>
                                        <!-- Get from Tagging section -->
                                        <td>Classification:</td>
                                        <td>{{ objectData.reg_tag_classification }}</td>
                                    </tr>

                                    <tr *ngIf="objectData.reg_tag_classification == 'Business'">
                                        <td>Sector:</td>
                                        <td>{{ objectData.reg_tag_sector }}</td>
                                    </tr>
                                    <tr>
                                        <td>Stage of Business:</td>
                                        <td>{{ objectData.reg_tag_stage_of_business }}</td>
                                    </tr>

                                    <tr *ngIf="objectData.reg_tag_classification == 'Non-Business'">
                                        <td>Stage of Life:</td>
                                        <td>{{ objectData.reg_tag_stage_of_life }}</td>
                                    </tr>
                                    <tr>
                                        <td>Life Event:</td>
                                        <td>{{ objectData.reg_tag_life_event }}</td>
                                    </tr>
                                    <tr>
                                        <td>Division:</td>
                                        <td>{{ objectData.reg_tag_division }}</td>
                                    </tr>

                                    <tr>
                                        <td>Jurisdiction:</td>
                                        <td>{{ objectData.reg_tag_jurisdiction }}</td>
                                    </tr>

                                    <tr>    
                                        <td>Use Case:</td>
                                        <td>{{ objectData.reg_tag_case_use }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="row table-responsive">
                                <table class="table table-striped">
                                    <thead class="info-subheader">
                                        <tr>
                                        </tr>
                                    </thead>
    
                                    <tbody class="info-subheader">
                                        <tr>
                                            <th class="col-md-3"><h6>Other Remarks</h6></th>
                                            <td class="col-md-9">- {{ objectData.reg_other_remarks }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        
                    </div>
                </div>
        
            </div>
        </div>

    </div>
</div>