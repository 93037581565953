<div class="row">
  <table
    mat-table
    [dataSource]="assessmentSection"
    class="mat-elevation-z4 demo-table"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="assessment">
      <th class="theme-default" mat-header-cell *matHeaderCellDef>
        Assessment
      </th>
      <td
        style="width: 50%;"
        mat-cell
        *matCellDef="let element; let $i = index"
        [formGroup]="element"
      >
        <mat-select
          formControlName="assessment"
          [multiple]="element.controls.type.value === 'LENS'"
          [placeholder]="
            element.controls.type.value === 'LENS' ? 'None' : 'Select one'
          "
        >
          <mat-option
            *ngFor="
              let choice of assessment_choices[element.controls.type.value]
            "
            [value]="choice"
          >
            {{ choice }}
          </mat-option>
        </mat-select>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="remarks">
      <th class="theme-default" mat-header-cell *matHeaderCellDef>
        ARTA Remarks
      </th>
      <td style="width: 50%;" mat-cell *matCellDef="let element" [formGroup]="element">
        <ng-container *ngIf="actionButtonAllowed; else noRemarksActionAllowed">
          <button class="w-100 btn" (click)="checkRemarks(element)"
            [ngClass]="(element.value.remarks != '' && element.value.remarks != null && element.value.remarks != undefined) ? 'btn-outline-danger' : 'btn-outline-primary'">
            ({{element.value && element.value.previous_remarks ? element.value.previous_remarks.length : '0'}})
            <i class="far fa-comments"></i> 
            <span *ngIf="(element.value.remarks != '' && element.value.remarks != null && element.value.remarks != undefined)">
              New
            </span>
            Remarks
            <span *ngIf="(element.value.remarks != '' && element.value.remarks != null && element.value.remarks != undefined)">
              Added
            </span>
          </button>
        </ng-container>
        <ng-template #noRemarksActionAllowed>
          <button class="w-100 btn btn-outline-primary" (click)="checkRemarks(element)">
            ({{element.value && element.value.previous_remarks ? element.value.previous_remarks.length : '0'}})
            <i class="far fa-comments"></i> Remarks
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
