import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { debounceTime, first, flatMap, map, startWith, take, tap } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RegulationTextNode, RegulationTextNodeType } from 'src/app/entities/regulation-text-section';
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { GovernmentAccountSubtype, WorklistStatusPBRISRegulation } from 'src/app/entities/worklist';
import { hasSubTagValidator, regulationRequiredByClassification } from 'src/app/validators';
import { format } from 'date-fns';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes.guard';
import { Location } from '@angular/common';
import { WorklistService } from 'src/app/service/worklist-service.service';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { PdfRenderService } from 'src/app/pdf-render.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FileUploadInput } from 'src/app/entities/consultation';
import {UpdateExistingRegulationsModalComponent} from 'src/app/pbris/upload-existing-regulations/update-existing-regulations-modal/update-existing-regulations-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { ToastrService } from 'ngx-toastr';

enum EntityBuilderIndex {
  TAG = 1,
  TAG_BUSINESS = 2,
  TAG_NON_BUSINESS = 3,
}

@Component({
  selector: 'app-existing-comment-modal',
  templateUrl: './existing-comment-modal.component.html',
  styleUrls: ['./existing-comment-modal.component.scss'],
})
export class ExistingCommentModalComponent implements OnInit, ComponentCanDeactivate { 
  loading: boolean = false;
  isPhase1: boolean = true;
  isLinear = true;
  TextRegFormGroup: UntypedFormGroup;

  agencies: any[] = [];
  sectors: any[] = [];
  org_codes: any[] = [];
  location_codes: any[] = [];
  psic_codes: any[] = [];
  sobs: any[] = [];
  divisions: any[] = [];
  divisions_select: any[] = [];
  jurisdictions: any[] = [];
  cases: any[] = [];
  cases_select: any[] = [];
  locations: any[] = [];
  documents: any[] = [];
  instruments: any[] = [];
  sols: any[] = [];
  lifeevents: any[] = [];
  lifeevents_select: any[] = [];
  
  legalbases: Observable<any[]>;
  legalbasesobs: Observable<any[]>[] = [];
  intlagreements: Observable<any[]>;
  intlagreementsobs: Observable<any[]>[] = [];
  regulations: Observable<any[]>;
  reg_lapse_obs: Observable<any[]>[] = [];
  reg_repeal_obs: Observable<any[]>[] = [];

  loader: boolean = true;
  multipleIssuingAgency: boolean = false;

  // phase 1 - sample form
  phase1form = new UntypedFormGroup({ //date
    doc_date: new UntypedFormControl(format(new Date(), 'yyyy-MM-dd'), Validators.required),
    file_file_name: new UntypedFormControl('', Validators.required),
    firebase_folder: new UntypedFormControl('regulationsExistingPhase02'),
    subject: new UntypedFormControl('', Validators.required),
    title: new UntypedFormControl('', Validators.required),

    reg_classification: new UntypedFormControl('Business'),
    reg_instrument: new UntypedFormControl('', Validators.required),
    reg_agency: new UntypedFormControl((sessionStorage.getItem("agency_id") || ''), Validators.required),
    reg_number: new UntypedFormControl(''),
    reg_significance: new UntypedFormControl('Major',[Validators.required]),

    // Business only
    reg_sector: new UntypedFormControl(''),
    reg_business: new UntypedFormControl(''),
    reg_division: new UntypedFormControl(''),
    reg_case: new UntypedFormControl(''),
    // Non-Business only
    reg_stageoflife: new UntypedFormControl(''),
    reg_lifeevent: new UntypedFormControl(''),

    reg_juris: new UntypedFormControl('', Validators.required),
    reg_legal: new UntypedFormArray([], Validators.required),
    reg_intl: new UntypedFormArray([]),

    has_sub_tags:new UntypedFormControl(false, Validators.required),
    sub_tags: new UntypedFormArray([]),

    // date_repeal and repealed_regulations have values if regulation_lapse is true
    regulation_lapse: new UntypedFormControl(false, Validators.required),
    date_repeal: new UntypedFormControl(''),
    reg_lapse: new UntypedFormArray([]),
    // regulation repealed has a value if regulation_repeal is true
    regulation_repeal: new UntypedFormControl(false, Validators.required),
    reg_repeal: new UntypedFormArray([]),

    date_published_onar: new UntypedFormControl(''),
    date_published_gazette: new UntypedFormControl(''),
    date_effective: new UntypedFormControl(''),
    effectivity_type: new UntypedFormControl('', Validators.required),

    has_annex_files: new UntypedFormControl(false, Validators.required),
    regulation_text_annex_file_name: new UntypedFormControl(''),
    regulation_text_annex_file_folder: new UntypedFormControl(''),
  },
	{
		validators: [regulationRequiredByClassification(),hasSubTagValidator()]
	});

	checkPhaseForm = {
		doc_date:[
      { type: "required", message: "Date is required" },
    ],
		file_file_name:[
      { type: "required", message: "file name ? is required" },
    ],
		subject:[
      { type: "required", message: "Regulation short title is required" },
    ],
		title:[
      { type: "required", message: "Regulation title is required" },
    ],
		reg_significance:[
      { type: "required", message: "Regulation significance is required" },
    ],
		reg_classification:[
      { type: "required", message: "Regulatory classification is required" },
    ],
		reg_sector:[
      { type: "required", message: "Sector is required" },
    ],
		reg_business:[
      { type: "required", message: "Stage of business is required" },
    ],
		reg_case:[
      { type: "required", message: "Case use is required" },
    ],
		reg_stageoflife:[
      { type: "required", message: "Stage of life is required" },
    ],
		reg_lifeevent:[
      { type: "required", message: "Life event is required" },
    ],
		reg_instrument:[
      { type: "required", message: "Regulatory instrument is required" },
    ],
		reg_agency:[
      { type: "required", message: "Issuing agency is required" },
    ],
		reg_juris:[
      { type: "required", message: "Jurisdiction is required" },
    ],
		reg_legal:[
      { type: "required", message: "Legal bases is required" },
    ],
		reg_legal_item:[
      { type: "required", message: "Legal bases is required" },
    ],
		effectivity_type:[
      { type: "required", message: "Effectivity type is required" },
    ],
    activeRegulationSection:{
      section_title:[
        { type: "required", message: "Section Title is required" },
      ],
      section_type:[
        { type: "required", message: "Section Type is required" },
      ],
      section_text:[
        { type: "required", message: "Section Text is required" },
      ],
      subsection_title:[
        { type: "required", message: "Subsection Title is required" },
      ],
      subsection_text:[
        { type: "required", message: "Subsection Text is required" },
      ],
    },
	}

  oneMBinByte = 1000000

  uploadsForm = this._fB.group({
    documentFile: new UntypedFormGroup({
      name: new UntypedFormControl(''),
      count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
      type: new UntypedFormControl('',this.checkUploadType()),
      size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
      newFile: new UntypedFormControl(false)
    })
  })

	
  // With so many file upload inputs, it's better to make a map object
  public uploadInputs: Map<string, FileUploadInput> = new Map([
    [
      'regulationTextAnnex',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile: false,
        url:null,
      },
    ],
    [
      'documentFile',
      {
        file_path: null,
        display_file_name: '',
        show_errors: false,
        newFile: false,
        url:null,
      },
    ],
  ]);

  acceptedFileType:any=[
    'application/pdf','application/vnd.ms-excel','application/msword','text/csv"',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]
	
  og_uploads:any = {
    documentFile:{
      count:0,
      name:'',
      newFile:false,
      size:0,
      type:'',
    },
    regulationTextAnnex:{
      count:0,
      name:'',
      newFile:false,
      size:0,
      type:'',
    },
  }

  checkUploadedDocs = {
    all:[
      {type: 'min', message: 'No Uploaded file'},
      {type: 'max', message: 'Invalid multiple file upload'},
      {type: 'type', message: 'Incorrect uploaded file type'},
      {type: 'maxSize', message: 'File size exceeded allowed limit'},
    ],
  }

  phase_01_filepath: any;
  phase_01_file_name = ''
  download_phase_01_filepath: any;
  regulation_text_annex_filepath: any;

  // form
  currentFormPage: string = "";
  previewImg: any = null;

  public showRegulationTextAnnexFileUploadErrors: boolean = false;
  display_regulation_text_annex_file_name: string = '';

  edit_regulation_id: any;
  edit_worklist_id: any;

  // Tree selector START
  treeControl = new NestedTreeControl<RegulationTextNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<RegulationTextNode>();
  hasChild = (_: number, node: RegulationTextNode) => !!node.children && node.children.length > 0;
  childlessSection = (_: number, node: RegulationTextNode) => !!node.children && node.children.length == 0 && node.nodeType == RegulationTextNodeType.SECTION;
  childlessSubsection = (_: number, node: RegulationTextNode) => node.nodeType == RegulationTextNodeType.SUBSECTION;
  regulation_sections_form: Map<string, any>;
  activeRegulationSection: any = null;
  activeRegulationSectionUuid: string = "";
  activeRegulationSubsection: any = null;
  activeRegulationSubsectionUuid: string = "";
  parentOfActiveRegulationSubsectionUuid: string = "";
  private _regulationTextFormIsInvalid: boolean = true;
  // Tree selector END

  commentMode: boolean = false;
  commentButtonsEnabled: boolean = false;
  commentPage: string;
  comment_details = new UntypedFormControl('', Validators.required);
  comment_regtext = new UntypedFormControl('', Validators.required);

  private currentRegulation:any;
  
  dataLoaded:any = {
    agency:false,
    sector:false,
    division:false,
    sob:false,
    case:false,
    sol:false,
    le:false,
    juris:false,
  }

  constructor(private afs: AngularFirestore,
    private store: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    // private location: Location,
    public worklistService: WorklistService,
    private pdf_render: PdfRenderService,
    private sanitizer: DomSanitizer,
    // private nE: NotificationsEmailer,
    private _fB: UntypedFormBuilder,
    private matDialog:MatDialog,
    private toastr: ToastrService,
		) {
    // Initial Regulation Section form
    this.regulation_sections_form = new Map();

    if (this.route.snapshot.params.id) {
      if (this.route.snapshot.url[3].path === 'comment') {
        this.commentMode = true;
        var lowest_level_users = [
          GovernmentAccountSubtype.AGENCY_ENC.valueOf(),
        ];
        this.commentButtonsEnabled = lowest_level_users.indexOf(sessionStorage.getItem('account_subtype') || '') < 0;
      }
      this.edit_regulation_id = this.route.snapshot.params.id;
      this.loadRegulationInformation();
    }
    else {
      // Initialize value changers because this.proposedregulationform won't be reinitialized
      this.valueChangeChecker1('reg_classification');
      this.valueChangeChecker1('has_sub_tags');
      this.setValueChanger();
            
      this.loadFormDependentReferences();
      
      const initialRegulationSectionUuid = uuidv4();

      // Initial Regulation Section selection tree
      this.dataSource.data = [
        {
          name: 'Edit new section',
          uuid: initialRegulationSectionUuid,
          children: [],
          nodeType: RegulationTextNodeType.SECTION
        }
      ];

      let newSectionInfo = new UntypedFormGroup({
        section_title: new UntypedFormControl('Edit new section', Validators.required),
        section_type: new UntypedFormControl('', Validators.required),
        section_text: new UntypedFormControl('', Validators.required),
      });

      let newRegulationSection = {
        info: newSectionInfo,
        subsections: new Map()
      }

      this.regulation_sections_form.set(
        initialRegulationSectionUuid,
        newRegulationSection
      );

      // (this.phase1form.controls.sub_tags as FormArray).push(this.buildNewEntry(EntityBuilderIndex.TAG))

      // First page when encoding
      this.currentFormPage = 'regulationdetails';

      // Disable reg_agency when encoding because default reg_instrument value does not contain "joint"
      this.phase1form.controls.reg_agency.disable();
    }
  }

  //Text of Regulation Modal
  sectionFormDialog(){
    const dialogRef = this.matDialog.open(UpdateExistingRegulationsModalComponent);
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    this.loadFormIndependentReferences();		
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    // function must return false if in comment mode AND either of the comment fields are filled up
    return !(this.commentMode && (this.comment_details.valid || this.comment_regtext.valid))
  }

  valueChangeChecker1(control1:any){
    (this.phase1form.controls[control1] as UntypedFormControl).valueChanges
    .subscribe({
      next:(value)=>{
        // console.log('valueChangeChecker1: ',value)
        let tempArr:any[] = [];
				switch(control1){
					case 'reg_sector':{ tempArr = this.sectors; break;}
					case 'reg_division':{ tempArr = this.divisions_select; break;}
					case 'reg_business':{ tempArr = this.sobs; break;}
					case 'reg_case':{ tempArr = this.cases_select; break;}
					case 'reg_stageoflife':{ tempArr = this.sols; break;}
					case 'reg_juris':{ tempArr = this.lifeevents_select; break;}
          case 'reg_classification':{
            this.phase1form.patchValue({
              reg_sector: '',
              reg_business: '',
              reg_division: '',
              reg_case: '',
              reg_stageoflife: '',
              reg_lifeevent: '',
            });
          switch (value) {
            case 'Business':
              // Empty Non-Business fields
                this.businessValidators()
              break;
            case 'Non-Business':
              // Empty Business fields
                this.nonBusinessValidators()
              break;
          }
            break;
          }
          case 'has_sub_tags':{
            if(value){
              if(((this.phase1form.controls.sub_tags as UntypedFormArray).controls as UntypedFormArray[]).length == 0)
                (this.phase1form.controls.sub_tags as UntypedFormArray).push(this.buildNewEntry(EntityBuilderIndex.TAG))
            }
            else (this.phase1form.controls.sub_tags as UntypedFormArray).clear()
            break;
          }
				}
      }
    });
  }

  businessValidators(){
    console.log('businessValidators');
		//
    console.log('phase1form',this.phase1form);

    (this.phase1form.controls.reg_sector as UntypedFormControl).setValidators([Validators.required]);
    // ((this.proposedregulationform.controls[controlGroup] as FormGroup).controls.reg_division as FormControl).setValidators([Validators.required]);
    (this.phase1form.controls.reg_business as UntypedFormControl).setValidators([Validators.required]);
    (this.phase1form.controls.reg_case as UntypedFormControl).setValidators([Validators.required]);
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).clearValidators();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).clearValidators();

    (this.phase1form.controls.reg_sector as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_division as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_business as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_case as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).markAsPristine();

    (this.phase1form.controls.reg_sector as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_division as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_business as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_case as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    (this.phase1form.controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_division as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_business as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_case as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  nonBusinessValidators(){
    console.log('nonBusinessValidators');
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).setValidators([Validators.required]);
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).setValidators([Validators.required]);

    (this.phase1form.controls.reg_sector as UntypedFormControl).clearValidators();
    // (this.crisform.controls.reg_division as FormControl).clearValidators();
    (this.phase1form.controls.reg_business as UntypedFormControl).clearValidators();
    (this.phase1form.controls.reg_case as UntypedFormControl).clearValidators();

    (this.phase1form.controls.reg_sector as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_division as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_business as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_case as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).markAsPristine();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).markAsPristine();

    (this.phase1form.controls.reg_sector as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_division as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_business as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_case as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).markAsUntouched();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).markAsUntouched();

    (this.phase1form.controls.reg_sector as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_division as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_business as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_case as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_stageoflife as UntypedFormControl).updateValueAndValidity();
    (this.phase1form.controls.reg_lifeevent as UntypedFormControl).updateValueAndValidity();
  }

  setValueChanger(){
    this.phase1form.controls['has_annex_files'].valueChanges.subscribe({
      next:(value)=>{
        if(value == true){
          if(!this.uploadsForm.contains('regulationTextAnnex')){
            this.uploadsForm.addControl('regulationTextAnnex', new UntypedFormGroup({
              name: new UntypedFormControl(''),
              count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
              type: new UntypedFormControl('',this.checkUploadType()),
              size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
              newFile: new UntypedFormControl(false)
            }))
          }
          this.uploadsForm.controls['regulationTextAnnex'].markAllAsTouched();
          this.uploadsForm.controls['regulationTextAnnex'].markAsDirty();
        }
        else if(value == false){
          if(this.uploadsForm.contains('regulationTextAnnex')){
            this.resetNewUpload('regulationTextAnnex')
          }
        }
      }
    })
  }

  checkPageLoader(){
    let loaded;
    for(let check of Object.keys(this.dataLoaded)){
      if(loaded == undefined || loaded == null) loaded = this.dataLoaded[check]
      else loaded = loaded && this.dataLoaded[check]
    }
    return loaded
  }

  async loadRegulationInformation() {
    const params = await firstValueFrom(this.route.queryParams.pipe(first()));

    if(params.worklistId) {
      this.edit_worklist_id = params.worklistId;
    }
    
    if (this.commentMode) {
      // First page when commenting on regulation
      this.phase1form.disable();

      this.commentPage = params.type;
      switch (params.type) {
        case 'regtext':
          this.currentFormPage = 'regulationtext';
          break;
        case 'regdetails':
          this.currentFormPage = 'regulationdetails';
          break;
        default:
          this.currentFormPage = 'regulationdetails';
          break;
      }
    } else {
      // First page when editing regulation
      this.currentFormPage = 'regulationdetails';
    }

    let regulation_ref = this.afs.collection(`regulations-existing-phase-02`).doc(this.edit_regulation_id);

    regulation_ref.snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            let pageinfo = data.payload.data();
            this.currentRegulation = pageinfo
            this.currentRegulation.id = data.payload.id
            console.log(this.currentRegulation)

            // Slightly different order because Existing Regulation editing uses reinitialization of the phase1form FormGroup
            let reg_legal = new UntypedFormArray([], Validators.required);
            for (let item of pageinfo.reg_legal) {
              reg_legal.push(new UntypedFormControl(item,[Validators.required]));
            }

            let reg_intl = new UntypedFormArray([]);
            for (let item of pageinfo.reg_intl) {
              reg_intl.push(new UntypedFormControl(item));
            }

            let reg_lapse = new UntypedFormArray([]);
            for (let item of pageinfo.reg_lapse) {
              reg_lapse.push(new UntypedFormControl(this.buildNewRegEntry(item)));
            }

            let reg_repeal = new UntypedFormArray([]);
            for (let item of pageinfo.reg_repeal) {
              reg_repeal.push(new UntypedFormControl(this.buildNewRegEntry(item)));
            }
            
            this.phase1form = new UntypedFormGroup({ //date
              doc_date: new UntypedFormControl(pageinfo.doc_date, Validators.required),
              file_file_name: new UntypedFormControl(pageinfo.file_file_name, Validators.required),
              firebase_folder: new UntypedFormControl(pageinfo.firebase_folder),
              subject: new UntypedFormControl(pageinfo.subject, Validators.required),
              title: new UntypedFormControl(pageinfo.title, Validators.required),

              reg_classification: new UntypedFormControl(pageinfo.reg_classification),
              reg_instrument: new UntypedFormControl(pageinfo.reg_instrument, Validators.required),
              reg_agency: new UntypedFormControl(pageinfo.reg_agency, Validators.required),
              reg_number: new UntypedFormControl(pageinfo.reg_number),
              reg_significance: new UntypedFormControl(pageinfo.reg_significance),

              // Business only
              reg_sector: new UntypedFormControl(''),
              reg_business: new UntypedFormControl(''),
              reg_division: new UntypedFormControl(''),
              reg_case: new UntypedFormControl(''),

              // Non-Business only
              reg_stageoflife: new UntypedFormControl(''),
              reg_lifeevent: new UntypedFormControl(''),

              has_sub_tags:new UntypedFormControl(pageinfo.has_sub_tags ? pageinfo.has_sub_tags : false, Validators.required),
              sub_tags: new UntypedFormArray([]),

              reg_juris: new UntypedFormControl(pageinfo.reg_juris, Validators.required),
              reg_legal: reg_legal,
              reg_intl: reg_intl,
              // date_repeal and repealed_regulations have values if regulation_lapse is true
              regulation_lapse: new UntypedFormControl(pageinfo.regulation_lapse, Validators.required),
              date_repeal: new UntypedFormControl(pageinfo.date_repeal),
              // regulation repealed has a value if regulation_repeal is true
              regulation_repeal: new UntypedFormControl(pageinfo.regulation_repeal, Validators.required),
              reg_lapse: reg_lapse,
              reg_repeal: reg_repeal,

              date_published_onar: new UntypedFormControl(pageinfo.date_published_onar),
              date_published_gazette: new UntypedFormControl(pageinfo.date_published_gazette),
              date_effective: new UntypedFormControl(pageinfo.date_effective),
              effectivity_type: new UntypedFormControl(pageinfo.effectivity_type, Validators.required),

              has_annex_files: new UntypedFormControl(pageinfo.has_annex_files, Validators.required),
              regulation_text_annex_file_name: new UntypedFormControl(pageinfo.regulation_text_annex_file_name),
              regulation_text_annex_file_folder: new UntypedFormControl(pageinfo.regulation_text_annex_file_folder),
            },
            {
              validators: [regulationRequiredByClassification(),hasSubTagValidator()]
            });

            switch(pageinfo.reg_classification){
              case 'Business':{
                this.phase1form.patchValue({
                  reg_sector: pageinfo.reg_sector,
                  reg_business: pageinfo.reg_business,
                  reg_division: pageinfo.reg_division,
                  reg_case: pageinfo.reg_case,
                })
                this.businessValidators();
                break;
              }
              case 'Non-Business':{
                this.phase1form.patchValue({
                  reg_stageoflife: pageinfo.reg_stageoflife,
                  reg_lifeevent: pageinfo.reg_lifeevent,
                })
                this.nonBusinessValidators();
                break;
              }
            }

            // Initialize value changers here because this.proposedregulationform was reinitialized
            this.valueChangeChecker1('reg_classification');
            this.setValueChanger();
            this.loadFormDependentReferences();

            const uploadedDocs = [
              'documentFile',
              'regulationTextAnnex'
            ]

            for(let name of uploadedDocs){
              this.handleLoadForEditUploads(name)
            }

            // if(pageinfo.firebase_folder && pageinfo.file_file_name){
            //   let path = `${pageinfo.firebase_folder}/${pageinfo.file_file_name}`;
              
            //   this.pdf_render.getFileUrl(path).toPromise().then((url: any) => {
            //     this.pdf_render.getAnyFile(url).toPromise().then((blob: any) => {
            //       this.phase_01_filepath = new File([blob], pageinfo.file_file_name, { type: blob.type });
            //       this.phase_01_file_name = this.phase_01_filepath.name
            //       this.download_phase_01_filepath = url;
            //     });
            //   });
            // }

            if(pageinfo.has_sub_tags && pageinfo.sub_tags){
              pageinfo.sub_tags.map((sub_tag:any)=>{
                this.addSubtag('sub_tags',sub_tag)
              })
            }

            this.valueChangeChecker1('has_sub_tags');

            if(this.commentMode) {
              this.phase1form.disable();
            }

            this.fillUpRegulationText(pageinfo.regulation_text);

            this.phase1form.markAllAsTouched()
            this.phase1form.markAsTouched()
          }
        ),
        take(1)
      )
      .subscribe();
  }

  /**
   *  ________________________
   * |                        |
   * |        Reminder        |
   * |________________________|
   * 
   * Please move all firestore references
   * to 'References'=>'common/PBRIS/ARTEMIS'=>...
   * 
   */

   loadFormIndependentReferences() {
    this.loadAgencies();
    this.loadSectors();
    this.loadSobs();
    this.loadJuris();
    this.loadSols();
    this.loadLegalBases();
    this.loadInternationalAgreements();
    this.loadRegulations();
  }

  loadFormDependentReferences() {
    this.loadInstruments();
    this.loadDivisions();
    this.loadCases();
    this.loadLifeEvents();
  }

  loadAgencies() {
    this.afs.collection(`Agency`).snapshotChanges().subscribe({
      next:(data: any) => {
        this.loader = false;
        this.agencies = []
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
            
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.agencies.includes(item) === false) {
            this.agencies.push(item);
          }

        });
        
        this.dataLoaded.agency = true
      },
      error:(err)=> this.dataLoaded.agency = true
    });
  }

  loadSectors() {
    this.afs.collection(`Sector`, filter => filter.orderBy('section')).snapshotChanges().subscribe({
      next:(data: any) => {
        this.sectors = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
            
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.sectors.includes(item) === false) {
            this.sectors.push(item);
          }
        });

        this.dataLoaded.sector = true
      },
      error:(err)=> this.dataLoaded.sector = true
    });
  }

  loadSobs() {
    this.afs.collection(`Stage of Business`, filter => filter.orderBy('section')).snapshotChanges().subscribe({
      next:(data: any) => {
        this.sobs = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
            
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.sobs.includes(item) === false) {
            this.sobs.push(item);
          }
        });
        this.dataLoaded.sob = true
      },
      error:(err)=> this.dataLoaded.sob = true
    });
  }

  loadSols() {
    this.afs.collection(`Stage of Life`).snapshotChanges().subscribe({
      next:(data: any) => {
        this.sols = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
            
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.sols.includes(item) === false) {
            this.sols.push(item);
          }
        });
        this.dataLoaded.sol = true
      },
      error:(err)=>this.dataLoaded.sol = true
    });
  }

  loadLifeEvents() {
    this.afs.collection(`Life Event`).snapshotChanges().subscribe({
      next:(data: any) => {
        this.lifeevents = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
              
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.lifeevents.includes(item) === false) {
            this.lifeevents.push(item);
          }
        });

        // Now form-dependent on reg_stageoflife
        if(this.phase1form.value.reg_stageoflife) {
          let stageOfLife = this.sols.find((option) => option.id === this.phase1form.value.reg_stageoflife).stage.toLowerCase();
          this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
        }     
        this.dataLoaded.le = true   
      },
      error:(err)=>this.dataLoaded.le = true
    });
  }

  loadDivisions() {
    this.afs.collection(`Division`, filter => filter.orderBy('section')).snapshotChanges().subscribe({
      next:(data: any) => {
        this.divisions = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
              
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.divisions.includes(item) === false) {
            this.divisions.push(item);
          }
        });

        // Now form-dependent on reg_sector
        if(this.phase1form.value.reg_sector) {
          let section = this.sectors.find((option) => option.id === this.phase1form.value.reg_sector).section.toLowerCase();
          this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
        }   
        this.dataLoaded.division = true                    
      },
      error:(err)=>this.dataLoaded.division = true
    });
  }

  loadJuris() {
    this.afs.collection(`Jurisdiction`, filter => filter.orderBy('section')).snapshotChanges().subscribe({
      next:(data: any) => {
        this.jurisdictions = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
                
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.jurisdictions.includes(item) === false) {
            this.jurisdictions.push(item);
          }
          this.dataLoaded.juris = true
        });
      },
      error:(err)=>this.dataLoaded.juris = true
    });
  }

  loadCases() {
    this.afs.collection(`Case Use`, filter => filter.orderBy('section')).snapshotChanges().subscribe({
      next:(data: any) => {
        this.cases = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;
                  
          if(item.ref) delete item.ref
          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy

          if (this.cases.includes(item) === false) {
            this.cases.push(item);
          }
        });

        // Now form-dependent on reg_business
        if(this.phase1form.value.reg_business) {
          let section = this.sobs.find((option) => option.id === this.phase1form.value.reg_business).section.toLowerCase();
          this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
        }   
        this.dataLoaded.case = true         
      },
      error:(err)=>this.dataLoaded.case = true
    });
  }

  loadInstruments() {
    this.afs.collection(`Instrument`, filter => filter.orderBy('section')).snapshotChanges().subscribe(
      (data: any) => {
        this.instruments = []
        this.loader = false;
        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (this.instruments.includes(item) === false) {
            this.instruments.push(item);
          }
        });

        // Set multipleIssuingAgency during loading
        // Now a "form-dependent reference data call, so put it inside the subscribe"
        this.updateIssuingAgency(this.phase1form.value.reg_instrument);
      });
  }

  loadLegalBases() {
    this.legalbases = this.afs.collection(`Legal Bases`, filter => filter.orderBy('name')).valueChanges();
    // Insert default legal base input because it is required, but check for edit_regulation_id to ensure no race condition when editing a regulation
    if(!this.edit_regulation_id) {
      this.addLegalBase();
    }
  }

  loadInternationalAgreements() {
    this.intlagreements = this.afs.collection(`International Agreements`, filter => filter.orderBy('name')).valueChanges();
  }

  loadRegulations() {
    let batches = [];
    // let fromCollection:any[] = [];

    batches.push(
      this.afs.collection('PBRIS 1 Regulations', filter => filter.orderBy('short_title')).snapshotChanges()
    );
    // fromCollection.push('pbris 1')

    batches.push(
      this.afs.collection('regulations-existing-phase-02', filter => filter.where('is_posted', '==', true)).snapshotChanges()
    );

    combineLatest(batches)
      .pipe(
        tap(
          (results) => {
            let index = 0;
            let reg_results: any[] = [];
            
            results.forEach(content => {
              // let currentCollection = fromCollection[index]
              content.forEach(async (results: any) => {
                let item: any = results.payload.doc.data();
                item.id = results.payload.doc.id;
                // item.from = currentCollection
                let resultObj = this.buildNewRegEntry(index == 0 ? item.short_title : item.subject);
                reg_results.push(resultObj);
              });
              index++;
            });

            reg_results.sort((a:any,b:any)=>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            );
            this.regulations = of(reg_results);
          }
        ),
        take(1)
      )
      .subscribe();
  }

  private _filter(value: string, autocompletecode: string): Observable<any[]> {
    const filterValue = value.toLowerCase();

    switch (autocompletecode) {
      case "legal":
        return this.legalbases.pipe(
          map(legalbases => {
            return legalbases.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      case "intl":
        return this.intlagreements.pipe(
          map(intlagreements => {
            return intlagreements.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      case "reg":
        return this.regulations.pipe(
          map(regulations => {
            return regulations.filter((option) => filterValue.trim() != '' && option.name.toLowerCase().includes(filterValue))
          })
        );
      default:
        return of([]);
    }
  }

  get reg_legal_val() {
    return this.phase1form.get('reg_legal') as UntypedFormArray;
  }

  get reg_intl_val() {
    return this.phase1form.get('reg_intl') as UntypedFormArray;
  }

  get reg_lapse_val() {
    return this.phase1form.get('reg_lapse') as UntypedFormArray;
  }

  get reg_repeal_val() {
    return this.phase1form.get('reg_repeal') as UntypedFormArray;
  }

  addLegalBase() {
    let newControl = new UntypedFormControl('',[Validators.required]);
    this.legalbasesobs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(value, "legal"))
      )
    );
    this.reg_legal_val.push(newControl);
  }

  removeLegalBase(index: number) {
    this.reg_legal_val.removeAt(index);
    this.legalbasesobs.splice(index,1);
  }

  addInternationalAgreement() {
    let newControl = new UntypedFormControl('');
    this.intlagreementsobs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(value, "intl"))
      )
    )
    this.reg_intl_val.push(newControl);
  }

  removeInternationalAgreement(index: number) {
    this.reg_intl_val.removeAt(index);
    this.intlagreementsobs.splice(index,1);
  }
  
  addRepealingRegulation() {
    let newControl = new UntypedFormControl('');
    this.reg_lapse_obs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(typeof value === 'string' ? value : value.name, "reg"))
      )
    )
    this.reg_lapse_val.push(newControl);
  }

  removeRepealingRegulation(index: number) {
    this.reg_lapse_val.removeAt(index);
    this.reg_lapse_obs.splice(index,1);
  }

  addRegulationRepealed() {
    let newControl = new UntypedFormControl('');
    this.reg_repeal_obs.push(
      newControl.valueChanges.pipe(
        startWith(""),
        debounceTime(300),
        flatMap(value => this._filter(typeof value === 'string' ? value : value.name, "reg"))
      )
    )
    this.reg_repeal_val.push(newControl);
  }

  removeRegulationRepealed(index: number) {
    this.reg_repeal_val.removeAt(index);
    this.reg_repeal_obs.splice(index,1);
  }

  displayReg(reg: any): string {
    return reg && reg.name ? reg.name : '';
  }

  private buildNewRegEntry(name: string): any {
    return {
      name: name
    }
  }

  instrumentOnChange(event: any) {
    this.updateIssuingAgency(event.target.value);
  }

  private updateIssuingAgency(instrumentVal: string) {
    // For copying to other pages
    const useForm = this.phase1form;

    // Handle blank reg_instrument
    if(!instrumentVal) {
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
      return;
    }

    let instrument = this.instruments.find((option) => option.id === instrumentVal).name.toLowerCase();
    this.multipleIssuingAgency = instrument.includes('joint');
    // Force reg_agency values after switching reg_instrument value
    if(this.multipleIssuingAgency) {
      // joint: reg_agency is an array
      useForm.controls.reg_agency.enable();
      useForm.patchValue({
        reg_agency: [sessionStorage.getItem("agency_id") || '']
      });
    }
    else {
      // non-joint: reg_agency is a string
      useForm.controls.reg_agency.disable();
      useForm.patchValue({
        reg_agency: sessionStorage.getItem("agency_id") || ''
      });
    }
  }

  sectorOnChange(event: any) {
    let section = this.sectors.find((option) => option.id === event.target.value).section.toLowerCase();
    this.divisions_select = this.divisions.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
  }

  sobOnChange(event: any) {
    let section = this.sobs.find((option) => option.id === event.target.value).section.toLowerCase();
    this.cases_select = this.cases.filter((option) => section.trim() != '' && option.section.toLowerCase().includes(section));
  }

  solOnChange(event: any) {
    let stageOfLife = this.sols.find((option) => option.id === event.target.value).stage.toLowerCase();
    this.lifeevents_select = this.lifeevents.filter((option) => stageOfLife.trim() != '' && option.stage.toLowerCase().includes(stageOfLife));
  }

  togglePreview() {
    if (this.isPhase1) {
      this.isPhase1 = false;
    }
    else {
      this.isPhase1 = true;
    }

  }

  updateTreeSectionName(event: any) {
    if (this.activeRegulationSection && this.activeRegulationSectionUuid) {
      this.dataSource.data.forEach(e => {
        if (e && e.uuid === this.activeRegulationSectionUuid) {
          const parentRegulationSection: RegulationTextNode = e;
          parentRegulationSection.name = event.target.value;
          return;
        }
      });
    }
  }

  updateTreeSubsectionName(event: any) {
    if (this.activeRegulationSubsection && this.activeRegulationSubsectionUuid) {
      this.dataSource.data.forEach(e => {
        if (e && e.uuid === this.parentOfActiveRegulationSubsectionUuid) {
          const parentRegulationSection: RegulationTextNode = e;
          parentRegulationSection.children?.forEach(f => {
            if (f && f.uuid === this.activeRegulationSubsectionUuid) {
              const subsection: RegulationTextNode = f;
              subsection.name = event.target.value;
              return;
            }
          })
          return;
        }
      });
    }
  }

  showSubsectionImagePreview() {
    if (this.activeRegulationSubsection && this.activeRegulationSubsection.value.subsection_image_file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(this.activeRegulationSubsection.value.subsection_image_file);
      fileReader.onload = () => {
        this.previewImg = fileReader.result as string;
      }
    }
    else {
      this.previewImg = null;
    }
  }

  async saveAsDraft() {
    this.saveAndUpload(WorklistStatusPBRISRegulation.DRAFT);
  }

  async submitRegulation() {
    this.validateFields()
    console.log('uploadsForm: ',this.uploadsForm.valid)
    console.log('phase1form: ',this.phase1form.valid)
    console.log('sub tags: ',this.phase1form.controls.sub_tags.valid)
    
    if(this.uploadsForm.valid && this.phase1form.valid){
      const annexFileCheck = ((this.phase1form.value.has_annex_files == true && this.saveAndUploadCheckUpload('regulationTextAnnex') && !this.regulationTextFormIsInvalid) || this.phase1form.value.has_annex_files == false)
      console.log('annexFileCheck: ',annexFileCheck)
      if(annexFileCheck) this.saveAndUpload(WorklistStatusPBRISRegulation.FOR_VERIFICATION);
      else{
        if(!this.uploadsForm.contains('regulationTextAnnex')){
          this.uploadsForm.addControl('regulationTextAnnex',new UntypedFormGroup({
            name: new UntypedFormControl(''),
            count: new UntypedFormControl(0,[Validators.min(1),Validators.max(1)]),
            type: new UntypedFormControl('',this.checkUploadType()),
            size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
            newFile: new UntypedFormControl(false)
          }))
          this.uploadsForm.updateValueAndValidity()
          this.uploadsForm.markAllAsTouched()
          this.uploadsForm.markAsDirty()
        }
        this.toastr.error('Invalid Details / Uploads!', 'Error')
      }
    }
    else{
      this.toastr.error('Invalid Details / Uploads!', 'Error')
      let invalids = this.findInvalidControls()
      console.log('invalids: ',invalids)
    }
  }
  
  public findInvalidControls() {
    console.log('findInvalidControls')
    const invalid = [];
    const controls = this.phase1form.controls;
    for (const name in controls) {
      if (controls[name].invalid) invalid.push(name);
    }
    return invalid;
  }

  // Upload is now required and and must be valid
  saveAndUploadCheckUpload(controlName:string){
    return this.uploadsForm.contains(controlName) && this.uploadsForm.controls[controlName].valid
  }

  async saveAndUpload(status: string) {
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    this.loading = true;
    let documentID: any;
    //
    let uploadProgress!: Observable<number | undefined>;

    const user = await this.auth.currentUser;
    let fromAgency = sessionStorage.getItem("agency_name");
    let fromOfficer = user?.displayName;

    let regulation_ref: any;
    let worklist_ref: any;
    //regulations-phase-02
    if(this.edit_regulation_id) regulation_ref = this.afs.firestore.collection(`regulations-existing-phase-02`).doc(this.edit_regulation_id);
    else regulation_ref = this.afs.firestore.collection(`regulations-existing-phase-02`).doc();
    
    if(this.edit_worklist_id) worklist_ref = this.afs.firestore.collection(`Worklist-Existing Regulations`).doc(this.edit_worklist_id);
    else worklist_ref = this.afs.firestore.collection(`Worklist-Existing Regulations`).doc();

    let destinationPath = "";
    const uploadNames = [
      'documentFile',
      'regulationTextAnnex',
    ];
    let folderName = `regulationsExistingPhase02/${this.phase1form.value.doc_date}/${regulation_ref.id}`

    uploadNames.map((name)=>{
      const uploadInput = this.uploadInputs.get(name);
      if(uploadInput){
        console.log('uploading '+name+': ',uploadInput.file_path)
        // uncomment ---------------------------------------------------
        let fileName = ''
        switch(name){
          case 'documentFile':{
            if(uploadInput.newFile == true){
              fileName = this.phase1form.value.file_file_name
              this.phase1form.patchValue({
                firebase_folder: folderName
              })
              const filePath  = folderName+'/'+fileName
              console.log('uploading '+name)
              const task = this.store.upload(filePath, uploadInput.file_path);
            }
            break;
          }
          case 'regulationTextAnnex':{ 
            if(this.phase1form.value.has_annex_files == true){
              console.log('has regulationTextAnnex')
              if(uploadInput.newFile == true){
                console.log('newfile regulationTextAnnex')
                console.log('regulation_text_annex_file_name',this.phase1form.value.regulation_text_annex_file_name)
                fileName = this.phase1form.value.regulation_text_annex_file_name
                folderName += '/regulationTextAnnex'
                this.phase1form.patchValue({
                  regulation_text_annex_file_folder: folderName
                })
                console.log('regulation_text_annex_file_folder',this.phase1form.value.regulation_text_annex_file_folder)
                const filePath  = folderName+'/'+fileName
                console.log('uploading '+name)
                const task = this.store.upload(filePath, uploadInput.file_path);
              }
            }
            else{
              console.log('no regulationTextAnnex')
              this.phase1form.patchValue({
                regulation_text_annex_file_folder: '',
                regulation_text_annex_file_name: ''
              })
            }
            break;
          }
        }
        // !uncomment ---------------------------------------------------

        // else{
        //   switch(name){
        //     case 'documentFile':{
        //       break;
        //     }
        //     case 'regulationTextAnnex':{
        //       break;
        //     }
        //   }
        // }
      }
    })

    // if (this.phase_01_filepath) {
    //   // Subdirectory format for uniqueness. doc_date is required so assume it has a value
    //   this.phase1form.patchValue({
    //     firebase_folder: `regulationsExistingPhase02/${this.phase1form.value.doc_date}/${regulation_ref.id}`
    //   })
    //   destinationPath = `${this.phase1form.value.firebase_folder}/${this.phase_01_filepath.name}`; //alert(Date.now().toString());
    //   const destinationRef = this.store.ref(destinationPath);
    //   const task = this.store.upload(destinationPath, this.phase_01_filepath);

    //   uploadProgress = task.percentageChanges();
    // }

    let regulationJSON = this.phase1form.value;

    if(regulationJSON.has_sub_tags == false){
      regulationJSON.sub_tags = []
    }

    // Gotcha: disabled form controls will not be part of form.value, similar to disabled input tags in a form tag
    // You need to get values from a disabled form control through controls['controlname'].value
    if(this.phase1form.controls.reg_agency.disabled) {
      regulationJSON.reg_agency = this.phase1form.controls.reg_agency.value;
    }

    // Must be an array
    var regulationText: Array<any> = [];

    /* 
      Because order matters for both regulation sections and subsections,
      regulation text must be stored in Firestore as an ordered array.
      However, the UUIDs generated during encoding are still used for the subsection image paths.
    */
    this.regulation_sections_form.forEach((v: any) => {
      // Do not return the FormGroup objects associated with regulation section and regulation subsections
      // Only return the actual FormGroup value
      let returnRegulationSection =
      {
        info: v.info.value,
        // Must be an array
        subsections: [] as Array<any>
      };

      // Must be an array
      let returnRegulationSubsections: Array<any> = [];

      // Keep generated UUIDs as a unique path folder name for every subsection image uploaded
      v.subsections.forEach((v: UntypedFormGroup, k: string) => {
        let regulationSubsection = v.value;

        // Upload subsection images
        if (regulationSubsection.subsection_image_file) {

          const temp_subsection_image_file = regulationSubsection.subsection_image_file;
          // Subdirectory format for uniqueness. doc_date is required so assume it has a value
          const new_subsection_image_file_path = `regulationsExistingPhase02/${this.phase1form.value.doc_date}/${regulation_ref.id}/subsections/${k}`;
          
          let subsectionImgDestinationPath = `${new_subsection_image_file_path}/${temp_subsection_image_file.name}`;
          const destinationRef = this.store.ref(subsectionImgDestinationPath);
          const task = this.store.upload(subsectionImgDestinationPath, temp_subsection_image_file);
    
          // Replace regulationSubsection.subsection_image_file values with string name and folder so it can be saved in Firestore
          regulationSubsection.subsection_image_folder = new_subsection_image_file_path;
          regulationSubsection.subsection_image_file = temp_subsection_image_file.name;
          // TODO: Handle collective upload percentage
          // uploadProgress = task.percentageChanges();
        }

        returnRegulationSubsections.push(regulationSubsection);
      })

      returnRegulationSection.subsections = returnRegulationSubsections;
      regulationText.push(returnRegulationSection);
    });

    regulationJSON.regulation_text = regulationText;

    // Upload regulation text annex files
    // if (this.regulation_text_annex_filepath) {
    //   // Subdirectory format for uniqueness. doc_date is required so assume it has a value
    //   this.phase1form.patchValue({
    //     regulation_text_annex_file_folder: `regulationsProposedPhase02/${this.phase1form.value.doc_date}/${regulation_ref.id}/regulationTextAnnex`,
    //   });
    //   regulationJSON.regulation_text_annex_file_folder = this.phase1form.value.regulation_text_annex_file_folder
    //   let regTextAnnexDestinationPath = `${this.phase1form.value.regulation_text_annex_file_folder}/${this.regulation_text_annex_filepath.name}`;
    //   const destinationRef = this.store.ref(regTextAnnexDestinationPath);
    //   const task = this.store.upload(
    //     regTextAnnexDestinationPath,
    //     this.regulation_text_annex_filepath
    //   );
    // } else {
    //   // Handling in case the user uploaded an annex file but changed their mind and unticked has_annex_file
    //   this.phase1form.patchValue({
    //     regulation_text_annex_file_name: '',
    //   });
    // }

    console.log('edit_regulation_id: ',this.edit_regulation_id)
    console.log('edit_worklist_id: ',this.edit_worklist_id)
    if(this.edit_regulation_id && this.edit_worklist_id) {
      console.log('updating')
      // https://stackoverflow.com/questions/12710905/how-do-i-dynamically-assign-properties-to-an-object-in-typescript
      let worklistJSON: Record<string,any> = {
        fromAgency: fromAgency,
        fromOfficer: fromOfficer,
        status: status,
        documentTitle: this.phase1form.value.subject,
      }

      if(destinationPath) {
        worklistJSON.documentFile = destinationPath;
      }
      
      console.log('worklistJSON: ',worklistJSON)
      batch.update(regulation_ref, regulationJSON);
      batch.update(worklist_ref, worklistJSON);
    }
    else {
      console.log('setting')
      let worklistJSON = {
        dateGenerated: Timestamp.now().toDate(),
        fromAgency: fromAgency,
        fromOfficer: fromOfficer,
        status: status,
        documentFile: destinationPath,
        documentId: regulation_ref.id,
        documentTitle: this.phase1form.value.subject,
        dueDate: Timestamp.now().toDate()
      }

      console.log('worklistJSON: ',worklistJSON)
      batch.set(regulation_ref, regulationJSON);
      batch.set(worklist_ref, worklistJSON);
    }

    console.log('regulationJSON: ',regulationJSON)

    // uncomment ---------------------------------------------------
    console.log('committing')
    await batch.commit().then(() => {
      this.toastr.success('Created Successfully!');
      console.log('Created',worklist_ref.id,regulation_ref.id)
      this.forReturnToRegulatoryManagement(worklist_ref.id,regulation_ref.id)
      this.router.navigate(['/pbris/regulatory-management']);
      this.loading = false
    }).catch(error => {
      this.loading = false
      alert(error.message)
    });
    // !uncomment ---------------------------------------------------

  }

  nextForm() {
    /**
     * step 1 : agencyprofile
     * step 2 : committee
     * step 3 : servicenameanddetails
     *        : standardrequirement
     * step 4 : servicesteps
     * step 5 : 
     * step 6 : 
     * step 7 : eodbtags
     * step 8 : feedback
     */
    switch (this.currentFormPage) {
      case 'regulationdetails':
        this.currentFormPage = "regulationtext";
        break;
    }
  }

  gotoForm(selectedForm: any) {
    /**
     * step 1 : regulationdetails
     * step 2 : regulationtext
     */
    this.currentFormPage = selectedForm;
  }

  toCancel(){
    if(this.commentMode) this.forReturnToRegulatoryManagement(this.edit_worklist_id,this.edit_regulation_id)
    else if(!this.commentMode) this.uploadsReturnToRegulatoryManagement()
    this.router.navigate(['/pbris/regulatory-management'])
  }

  forReturnToRegulatoryManagement(worklistId:any,regulatiionId:any){
    sessionStorage.setItem('regulatoryActive','phase 2 existing')
    sessionStorage.setItem('worklistId',worklistId)
    sessionStorage.setItem('regulationId',regulatiionId)
  }

  uploadsReturnToRegulatoryManagement(){
    sessionStorage.setItem('regulatoryActive','regulatoryStock')
  }

  async fillUpRegulationText(regulationText: any) {
    // Load client steps
    if (regulationText) {
      let newData: RegulationTextNode[] = [];

      for (let regulationSection of regulationText) {
        // Not reused 
        const initialRegulationSectionUuid = uuidv4();

        // Client step agency action
        let newRegulationSectionDataSourceChildren: RegulationTextNode[] = [];
        let newRegulationSectionDataSource: RegulationTextNode = {
          name: regulationSection.info.section_title,
          uuid: initialRegulationSectionUuid,
          children: newRegulationSectionDataSourceChildren,
          nodeType: RegulationTextNodeType.SECTION
        }

        let newSectionInfo = new UntypedFormGroup({
          section_title: new UntypedFormControl({ value: regulationSection.info.section_title, disabled: this.commentMode }, Validators.required),
          section_type: new UntypedFormControl({ value: regulationSection.info.section_type, disabled: this.commentMode }, Validators.required),
          section_text: new UntypedFormControl({ value: regulationSection.info.section_text, disabled: this.commentMode }, Validators.required),
        });

        let newRegulationSection = {
          info: newSectionInfo,
          subsections: new Map()
        };

        for (let regulationSubsection of regulationSection.subsections) {
          let initialRegulationSubsectionUuid = '';
          if (regulationSubsection.subsection_image_folder
            && regulationSubsection.subsection_image_folder.split("/")
            && regulationSubsection.subsection_image_folder.split("/").length == 5) {
            // Reuse UUID previously saved
            // Format is "regulationsExistingPhase02/<doc date>/<regulation id>/subsections/<subsection uuid>", so get the fifth one
            initialRegulationSubsectionUuid = regulationSubsection.subsection_image_folder.split("/")[4];
          }
          else {
            initialRegulationSubsectionUuid = uuidv4();
          }

          // Agency action data source
          const newRegulationSubsectionDataSource: RegulationTextNode = {
            name: regulationSubsection.subsection_title,
            uuid: initialRegulationSubsectionUuid,
            parentUuid: initialRegulationSectionUuid,
            nodeType: RegulationTextNodeType.SUBSECTION
          }
          // Push each agency action to children array of parent client step first
          newRegulationSectionDataSource.children?.push(newRegulationSubsectionDataSource);
          // Agency action form

          let newSubsection = new UntypedFormGroup({
            subsection_title: new UntypedFormControl({ value: regulationSubsection.subsection_title, disabled: this.commentMode }, Validators.required),
            subsection_text: new UntypedFormControl({ value: regulationSubsection.subsection_text, disabled: this.commentMode }, Validators.required),
            // TODO: load actual file object
            subsection_image_file: new UntypedFormControl(null),
          });

          // Load images
          if(regulationSubsection.subsection_image_folder && regulationSubsection.subsection_image_file) {
            firstValueFrom(this.store.ref(`${regulationSubsection.subsection_image_folder}/${regulationSubsection.subsection_image_file}`)
            .getDownloadURL())
            .then(
              (url) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    var blob = xhr.response;
                    newSubsection.patchValue({
                      subsection_image_file: new File([blob], regulationSubsection.subsection_image_file, { type: blob.type })
                    })
                    newRegulationSection.subsections.set(initialRegulationSubsectionUuid, newSubsection);
                };
                xhr.open('GET', url);
                xhr.send();
              }
            );
          }
          else {
            newRegulationSection.subsections.set(initialRegulationSubsectionUuid, newSubsection);
          }
        }

        // Push to form
        this.regulation_sections_form.set(
          initialRegulationSectionUuid,
          newRegulationSection
        );

        // Finally, push client step to newData which will be the final dataSource.data
        newData.push(newRegulationSectionDataSource);
      }

      // Initial Client Steps selection tree (loaded)
      this.dataSource.data = newData;
      this.checkRegulationTextFormIsInvalid();

      const params = await firstValueFrom(this.route.queryParams.pipe(first()));
      if(params.type === 'regtext') {
        // Automatically select the regulation section or subsection the user clicked from
        const parentSection = newData[params.sectionId];

        if(parentSection && parentSection.children && params.subsectionId != null) {
          const subsection = parentSection.children[params.subsectionId];
          // Select the parent section to force it to expand, it will be deselected by getRegulationSubsectionInfo
          this.getRegulationSectionInfo(parentSection);
          this.getRegulationSubsectionInfo(parentSection.uuid, subsection.uuid);
        }
        else if(parentSection && params.sectionId != null) {
          this.getRegulationSectionInfo(parentSection);
        }
      }
    }
  }

  getRegulationSectionInfo(node: RegulationTextNode) {
    const uuid = node.uuid;

    this.activeRegulationSubsection = null;
    this.activeRegulationSubsectionUuid = "";
    this.activeRegulationSection = this.regulation_sections_form.get(uuid);
    this.activeRegulationSectionUuid = uuid;

    // Automatically expand node upon adding new subsection
    this.treeControl.expand(node);
  }

  getRegulationSubsectionInfo(regulationSectionUuid: string, uuid: string) {
    this.activeRegulationSection = null;
    this.activeRegulationSectionUuid = "";
    this.parentOfActiveRegulationSubsectionUuid = regulationSectionUuid;
    const parentRegulationSection = this.regulation_sections_form.get(regulationSectionUuid);
    if (parentRegulationSection) {
      this.activeRegulationSubsection = parentRegulationSection.subsections.get(uuid);
      this.activeRegulationSubsectionUuid = uuid;
      this.showSubsectionImagePreview();
    }
  }

  addNewRegulationSection() {
    const newRegulationSectionUuid = uuidv4();

    let newData = this.dataSource.data;
    let newNode = {
      name: 'Edit new section',
      uuid: newRegulationSectionUuid,
      children: [],
      nodeType: RegulationTextNodeType.SECTION
    };
    newData.push(newNode);

    this.refreshNodeTree();

    let newSectionInfo = new UntypedFormGroup({
      section_title: new UntypedFormControl('', Validators.required),
      section_type: new UntypedFormControl('', Validators.required),
      section_text: new UntypedFormControl('', Validators.required),
    });

    let newRegulationSection = {
      info: newSectionInfo,
      subsections: new Map()
    };

    this.regulation_sections_form.set(
      newRegulationSectionUuid,
      newRegulationSection
    );

    this.getRegulationSectionInfo(newNode);
  }

  addNewRegulationSubsection(node: RegulationTextNode) {
    const newRegulationSubsectionUuid = uuidv4();
    const regulationSectionUuid = node.uuid;

    this.dataSource.data.forEach(e => {
      if (e && e.uuid === regulationSectionUuid) {
        const parentRegulationSection: RegulationTextNode = e;

        let newSubsectionNode = {
          name: 'Edit new subsection',
          uuid: newRegulationSubsectionUuid,
          parentUuid: regulationSectionUuid,
          nodeType: RegulationTextNodeType.SUBSECTION
        };
        parentRegulationSection.children?.push(newSubsectionNode);

        this.refreshNodeTree();

        const parentRegulationSectionForm = this.regulation_sections_form.get(regulationSectionUuid);
        parentRegulationSectionForm.subsections.set(newRegulationSubsectionUuid, new UntypedFormGroup({
          subsection_title: new UntypedFormControl('', Validators.required),
          subsection_text: new UntypedFormControl('', Validators.required),
          subsection_image_file: new UntypedFormControl(null),
        }));

        // Select the parent section to force it to expand, it will be deselected by getRegulationSubsectionInfo
        this.getRegulationSectionInfo(parentRegulationSection);
        this.getRegulationSubsectionInfo(regulationSectionUuid, newRegulationSubsectionUuid);

        return;
      }
    });

    // Automatically expand node upon adding new subsection
    this.treeControl.expand(node);
  }

  removeRegulationSection(uuid: string) {
    this.activeRegulationSection = null;
    this.activeRegulationSectionUuid = "";
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === uuid) {
        this.dataSource.data.splice(i, 1);
        this.refreshNodeTree();
      }
    }
    this.regulation_sections_form.delete(uuid);
  }

  removeRegulationSubsection(RegulationSectionUuid: string, uuid: string) {
    this.activeRegulationSubsection = null;
    this.activeRegulationSubsectionUuid = "";
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].uuid === RegulationSectionUuid) {

        var RegulationSubsections = this.dataSource.data[i].children || [];

        for (var j = 0; j < RegulationSubsections.length; j++) {
          if (RegulationSubsections[j].uuid === uuid) {
            RegulationSubsections.splice(j, 1)

          }
        }

        if (this.regulation_sections_form.get(RegulationSectionUuid)) {
          this.regulation_sections_form.get(RegulationSectionUuid).subsections.delete(uuid);
        }
        this.refreshNodeTree();
      }
    }
  }

  private refreshNodeTree() {
    let newData = this.dataSource.data;
    // Workaround for updating nested tree nodes, do not remove
    this.dataSource.data = [];
    this.dataSource.data = newData;
    this.checkRegulationTextFormIsInvalid();
  }

  checkRegulationTextFormIsInvalid() {
    for (let [k, v] of this.regulation_sections_form) {
      if (!v.info.valid) {
        this._regulationTextFormIsInvalid = true;
        return;
      }

      for (let [k, v2] of v.subsections) {
        if (!v2.valid) {
          this._regulationTextFormIsInvalid = true;
          return;
        }
      }
    }
    this._regulationTextFormIsInvalid = false;
  }

  get regulationTextFormIsInvalid(): boolean {
    return this._regulationTextFormIsInvalid
  }

  async submitCommentsAndReturn() {
    let status = '';
    if(sessionStorage.getItem("user_type") === "arta") {
      status = WorklistStatusPBRISRegulation.FOR_MODIFICATION;
    }
    else {
      status = WorklistStatusPBRISRegulation.FOR_REVISION;
    }
    
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    this.loading = true;

    let worklist_ref: any;
    
    if(this.edit_worklist_id) {
      worklist_ref = this.afs.firestore.collection(`Worklist-Existing Regulations`).doc(this.edit_worklist_id);
      
      let worklistJSON: Record<string,any> = await this.worklistService.updateWorklistStatusObj(status);
      worklistJSON.commentDetails = this.comment_details.value;
      worklistJSON.commentRegText = this.comment_regtext.value;
      
      batch.update(worklist_ref, worklistJSON);
    }

    await batch.commit().then(() => {
      alert("Comments updateddsdsdsd.");
      this.getDetailsForEmailer(worklist_ref);
    }).catch(error => {
      this.loading = false
      alert(error.message)
    });
  }

  async getDetailsForEmailer(worklist_id:any){
    const worklist: any  = await this.getWorklist(worklist_id)
    console.log('worklist',worklist)
    if(worklist){
      const agency:any = await this.getAgency(worklist.fromAgency)
      console.log('agency',agency)
      if(agency){
        const user = await this.auth.currentUser;
        console.log('user',user)
        if(user && user.email){
          let userData = {
            regulationTitle: this.currentRegulation.title,
            agencyTitle: agency.agencyDetails ? agency.agencyDetails.agencyName ? agency.agencyDetails.agencyName : agency.name : agency.name,
          }
          this.toSendNotifEmail([user.email],84,userData)
        }
      }
    }
  }

  getAgency(agencyCode:any){
    return new Promise((res, rej)=>{
      let batches = []
      let batchCode:any = []
      batches.push(this.afs.collection('Agency').doc(agencyCode).snapshotChanges())
      batchCode.push('id')

      batches.push(this.afs.collection('Agency', filter=> filter.where('agencyDetails.agencyName','==',agencyCode)).snapshotChanges())
      batchCode.push('name')

      batches.push(this.afs.collection('Agency', filter=> filter.where('name','==',agencyCode)).snapshotChanges())
      batchCode.push('name')

      combineLatest(batches)
      .pipe(first())
      .subscribe({
        next:(result)=>{
          let ctr = 0
          let agency:any;
          result.every((content:any,ctr:number)=>{
            if(batchCode[ctr] == 'id'){
              if(content.payload.exists){
                agency = content.payload.data()
                agency.id = content.payload.id
                return false
              }
            }
            else if(batchCode[ctr] == 'name'){
              content.every((item:any)=>{
                if(item.payload.doc.exists){
                  agency = item.payload.doc.data()
                  agency.id = item.payload.doc.id
                  return false
                }
                return true
              })
              if(agency) return false
            }
            return true
          })
          if(agency) return res(agency)
          return res(false)
        },
        error:(err)=>{
          console.error('getAgency error: ',err)
          return res(false)
        }
      })
    })
  }

  getWorklist(worklist_id:any){
    return new Promise((res,rej)=>{
      this.afs.collection(`Worklist-Existing Regulations`).doc(worklist_id).snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result:any)=>{
          let worklist:any = result.payload.data()
          worklist.id = result.payload.id
          return res(worklist)
        },
        error:(err:any)=>{
          console.error('worklist error: ',err)
          return res(false)
        }
      })
    })
  }
  
  toSendNotifEmail(currentUsers:any[], templateCode:number,data:any){
    console.log('sending email: ',currentUsers, templateCode)
    if(templateCode > 0){
      // this.nE.sendEmailNotiftoUser(currentUsers,1,templateCode,data)
      // .subscribe({
      //     next:(apiResponse)=>{
      //         console.log('RegulationViewExistingComponent emailer: ',apiResponse)
      //     },
      //     error:(err)=>{
      //         console.error('RegulationViewExistingComponent emailer error: ',err)
      //     }
      // })
    } else console.error('RegulationViewExistingComponent emailer error: no email template for account type')
  }

  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.TAG:{
        if(existing_obj && existing_obj.reg_classification){
          switch(existing_obj.reg_classification){
            case 'Business': return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS,existing_obj)
            case 'Non-Business': return this.buildNewEntry(EntityBuilderIndex.TAG_NON_BUSINESS,existing_obj)
            default: return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS,existing_obj)
          }
        }else return this.buildNewEntry(EntityBuilderIndex.TAG_BUSINESS)
      }
      case EntityBuilderIndex.TAG_BUSINESS:{
        let group = new UntypedFormGroup({
          reg_classification: new UntypedFormControl(existing_obj?.reg_classification ? existing_obj.reg_classification : 'Business',[Validators.required]),
          reg_sector: new UntypedFormControl(existing_obj?.reg_sector ? existing_obj.reg_sector : '', [Validators.required]),
          reg_business: new UntypedFormControl(existing_obj?.reg_business ? existing_obj.reg_business : '', [Validators.required]),
          reg_division: new UntypedFormControl(''),
          reg_case: new UntypedFormControl('', [Validators.required]),
        })

        this.subTagsClassificationChange(group)
        
        this.subTagControlClear(group,'reg_sector','reg_division')
        this.subTagControlClear(group,'reg_business','reg_case')

        if(existing_obj){
          this.subTagMarked(group.controls.reg_classification)
          this.subTagMarked(group.controls.reg_sector)
          this.subTagMarked(group.controls.reg_business)
          this.subTagMarked(group.controls.reg_division)
          this.subTagMarked(group.controls.reg_case)
        }

        group.patchValue({
          reg_division: existing_obj?.reg_division ? existing_obj.reg_division : '',
          reg_case: existing_obj?.reg_case ? existing_obj.reg_case : '',
        })
        
        return group
      }
      case EntityBuilderIndex.TAG_NON_BUSINESS:{
        // console.log('existing_obj reg_classification: ',existing_obj.reg_classification)
        // console.log('existing_obj reg_stageoflife: ',existing_obj.reg_stageoflife)
        // console.log('existing_obj reg_lifeevent: ',existing_obj.reg_lifeevent)
        let group =  new UntypedFormGroup({
          reg_classification: new UntypedFormControl(existing_obj?.reg_classification ? existing_obj.reg_classification : 'Non-Business',[Validators.required]),
          reg_stageoflife: new UntypedFormControl(existing_obj?.reg_stageoflife ? existing_obj.reg_stageoflife : '',[Validators.required]),
          reg_lifeevent: new UntypedFormControl('',[Validators.required]),
        })

        this.subTagsClassificationChange(group)

        this.subTagControlClear(group,'reg_stageoflife','reg_lifeevent')

        if(existing_obj){
          this.subTagMarked(group.controls.reg_classification)
          this.subTagMarked(group.controls.reg_stageoflife)
          this.subTagMarked(group.controls.reg_lifeevent)
        }

        group.patchValue({
          reg_lifeevent: existing_obj?.reg_lifeevent ? existing_obj.reg_lifeevent : ''
        })

        return group
      }
      default:
        return new UntypedFormGroup({});
    }
  }

	// sub tags handler
	getFormsArray(listName:string):UntypedFormGroup[]{
    switch(listName){
      case 'sub_tags': return ((this.phase1form.controls.sub_tags as UntypedFormArray).controls as UntypedFormGroup[])
      default: return []
    }
  }

  addSubtag(listName:string, item?:any){
    switch(listName){
      case 'sub_tags': (this.phase1form.controls.sub_tags as UntypedFormArray).push(this.buildNewEntry(EntityBuilderIndex.TAG, item))
			break;
    }
  }

  removeSubTag(listName:string, index:number){
    switch(listName){
      case 'sub_tags': 
        // need to unsubscribe to each control valuechanges before removing whole group
        // reg_classification
        // reg_stageoflife
        // reg_lifeevent
        // reg_sector
        // reg_division
        // reg_business
        // reg_case
        // ((((this.proposedregulationform.controls.sub_tags as FormArray).controls as FormGroup[])[index] as FormGroup).controls.reg_classification as FormControl).valueChanges ;
        (this.phase1form.controls.sub_tags as UntypedFormArray).removeAt(index);
			break;
    }
  }

  subTagsClassificationChange(group: UntypedFormGroup){
    // need to unsubscribe later when remove to prevent memory leak
    // const valueChanges = 
    group.controls['reg_classification'].valueChanges.subscribe({
      next:(val)=>{
        switch(val){
          case 'Business':{
            // unsubscribe valuechange before removing
            // reg_stageoflife
            // reg_lifeevent
            if(group.contains('reg_stageoflife')) group.removeControl('reg_stageoflife')
            if(group.contains('reg_lifeevent')) group.removeControl('reg_lifeevent')
  
            if(!group.contains('reg_sector')) group.addControl('reg_sector', new UntypedFormControl('',[Validators.required]))
            if(!group.contains('reg_division')) group.addControl('reg_division', new UntypedFormControl(''))
            if(!group.contains('reg_business')) group.addControl('reg_business', new UntypedFormControl('',[Validators.required]))
            if(!group.contains('reg_case')) group.addControl('reg_case', new UntypedFormControl('',[Validators.required]))

            this.subTagControlClear(group,'reg_sector','reg_division')
            this.subTagControlClear(group,'reg_business','reg_case')
            break; 
          }
          case 'Non-Business':{
            // unsubscribe valuechange before removing
            // reg_sector
            // reg_division
            // reg_business
            // reg_case
            if(group.contains('reg_sector')) group.removeControl('reg_sector')
            if(group.contains('reg_division')) group.removeControl('reg_division')
            if(group.contains('reg_business')) group.removeControl('reg_business')
            if(group.contains('reg_case')) group.removeControl('reg_case')
  
            if(!group.contains('reg_stageoflife')) group.addControl('reg_stageoflife', new UntypedFormControl('',[Validators.required]))
            if(!group.contains('reg_lifeevent')) group.addControl('reg_lifeevent', new UntypedFormControl('',[Validators.required]))

            this.subTagControlClear(group,'reg_stageoflife','reg_lifeevent')

            break; 
          }
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
      }
    })

    // this.subscriptions.push(valueChanges)
    // console.log('subcriptions: ',this.subscriptions)
    // console.log('valueChanges: ',valueChanges)
  }
  
  subTagControlClear(group:UntypedFormGroup,mainControl:string,dependentControl:string){
    // need to unsubscribe later when remove to prevent memory leak
    // const valueChanges = 
    group.controls[mainControl].valueChanges.subscribe({
      next:()=>{
        group.patchValue({[dependentControl]:''})
      },
      error:(err:any)=>{
        console.error('Error: ',err)
      }
    })

    // this.subscriptions.push(valueChanges)
    // console.log('subcriptions: ',this.subscriptions)
    // console.log('valueChanges: ',valueChanges)
  }

  subTagMarked(control:any){
    control.markAllAsTouched()
    control.markAsTouched()
    control.updateValueAndValidity()
  }

  filterSelection(controlName:string, value:any):Array<any>{
    if(value != null && value != undefined && value != ''){
      switch(controlName){
        case 'reg_division':{
          let section = this.sectors.find((option) => option.id === value)
          if(section){
            const sectionName = section.section.toLowerCase()
            return this.divisions.filter((option) => sectionName.trim() != '' && option.section.toLowerCase().includes(sectionName));
          } else return []
        }
        case 'reg_case':{
          let section = this.sobs.find((option) => option.id === value)
          if(section){
            const sectionName = section.section.toLowerCase()
            return this.cases.filter((option) => sectionName.trim() != '' && option.section.toLowerCase().includes(sectionName));
          } else return []
        }
        case 'reg_lifeevent':{
          let section = this.sols.find((option) => option.id === value)
          if(section){
            const sectionName = section.stage.toLowerCase()
            return this.lifeevents.filter((option) => sectionName.trim() != '' && option.stage.toLowerCase().includes(sectionName));
          } else return []
        }
        default:return []
      }
    } return []
  }

  subTagOptionSelected(optionValue:any, itemValue:any){
    return optionValue == itemValue ? true : false
  }
	// !sub tags handler

  saveImageInformation(event: any) {
    if (this.activeRegulationSubsection) {
      var allowedExtensions = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
      if (!allowedExtensions.exec(event.target.files[0].name)) {
        this.activeRegulationSubsection.patchValue({
          subsection_image_file: null
        });
      }
      else {
        this.activeRegulationSubsection.patchValue({
          subsection_image_file: event.target.files[0]
        });
        this.showSubsectionImagePreview();
      }
    }
  }

  // file handlers
  async handleLoadForEditUploads(controlCode:string){
    let uploadName = ''
    let folderName = ''
    let filePath = '';

    switch(controlCode){
      case 'documentFile':{
        if(this.phase1form.value.file_file_name) uploadName = this.phase1form.value.file_file_name;
        if(this.phase1form.value.firebase_folder) folderName = this.phase1form.value.firebase_folder
        if(uploadName && folderName) filePath = this.phase1form.value.firebase_folder+'/'+this.phase1form.value.file_file_name;
        break;
      }
      case 'regulationTextAnnex':{
        if(this.phase1form.value.regulation_text_annex_file_name) uploadName = this.phase1form.value.regulation_text_annex_file_name;
        if(this.phase1form.value.regulation_text_annex_file_folder) folderName = this.phase1form.value.regulation_text_annex_file_folder
        if(uploadName && folderName) filePath = this.phase1form.value.regulation_text_annex_file_folder+'/'+this.phase1form.value.regulation_text_annex_file_name;
        break;
      }
    }

    if(filePath){
      this.og_uploads[controlCode].name = uploadName;
      this.og_uploads[controlCode].count = 1;
      this.og_uploads[controlCode].newFile = false;

      const fileData:any = await this.refToURL(filePath)
      if(fileData){
        if(fileData.url) this.og_uploads[controlCode].url = fileData.url
        if(fileData.metaData){
          this.og_uploads[controlCode].type = fileData.metaData.contentType
          this.og_uploads[controlCode].size = fileData.metaData.size
        }
        console.log('url: ',fileData.url)
        this.saveFileInformation([{name:this.og_uploads[controlCode].name,size:this.og_uploads[controlCode].size,type:this.og_uploads[controlCode].type,url:this.og_uploads[controlCode].url}],controlCode,false,)
      }
      else this.saveFileInformation([{name:this.og_uploads[controlCode].name,size:0,type:'',url:''}],controlCode,false)
      // this.store.ref(filePath).getMetadata()
      // .subscribe({
      //   next:(metadata)=>{
      //     // console.log('metadata: ',metadata)
      //     this.og_uploads[controlCode].type = metadata.contentType
      //     this.og_uploads[controlCode].size = metadata.size
      //     this.saveFileInformation([{name:this.og_uploads[controlCode].name,size:this.og_uploads[controlCode].size,type:this.og_uploads[controlCode].type}],controlCode,false)
      //   },
      //   error:(err)=>{
      //     console.error('Error: ',err,'path: '+filePath)
      //     this.saveFileInformation([{name:this.og_uploads[controlCode].name,size:0,type:''}],controlCode,false)
      //   }
      // })
    }

    // console.log('filePath: ',filePath)
  }

  refToURL(filePath:string){
    return new Promise((res,rej)=>{
      this.store.ref(filePath).getDownloadURL()
      .subscribe({
        next:(data1)=>{
          this.store.ref(filePath).getMetadata()
          .subscribe({
            next:(data2)=>{
              let item:any = {
                url: data1,
                metaData: data2,
              }
              return res(item)
            },
            error:(err)=>{
              // console.error('refToURL Error: ',err)
              let item:any = {
                url: data1,
                metaData: null,
              }
              return res(item)
            }
          })
        },
        error:(err)=>{
          // console.error('refToURL Error: ',err)
          return res(false)
        }
      })
    })
  }

  buttonUpload(controlCode:string, event:any){
    this.saveFileInformation(event.target.files, controlCode,true)
  }
	
  dropzone(controlCode:string, files:File[]){
    this.saveFileInformation(files,controlCode,true)
  }
	
  // saveFileInformation(fieldName: string, event: any) {
  //   switch (fieldName) {
  //     case 'documentFile':
  //       this.phase_01_filepath = event.target.files[0];
  //       if (this.phase_01_filepath) {
  //         if(this.download_phase_01_filepath) {
  //           URL.revokeObjectURL(this.download_phase_01_filepath);
  //         }
          
  //         this.download_phase_01_filepath = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.phase_01_filepath));
  //         this.phase1form.get("file_file_name")?.setValue(this.phase_01_filepath.name);
  //       }
  //       break;

  //     case 'regulationTextAnnex':
  //       var allowedExtensions =
  //         /(\.doc|\.docx|\.ppt|\.pptx|\.xls|\.xlsx|\.pdf)$/i;
  //       if (!allowedExtensions.exec(event.target.files[0].name)) {
  //         this.phase1form
  //           .get('regulation_text_annex_file_name')
  //           ?.reset();
  //         this.display_regulation_text_annex_file_name = '';
  //         this.showRegulationTextAnnexFileUploadErrors = true;
  //         return;
  //       }
  //       this.showRegulationTextAnnexFileUploadErrors = false;
  //       this.regulation_text_annex_filepath = event.target.files[0];
  //       this.display_regulation_text_annex_file_name =
  //         this.regulation_text_annex_filepath.name;
  //       if (this.regulation_text_annex_filepath) {
  //         this.phase1form
  //           .get('regulation_text_annex_file_name')
  //           ?.setValue(this.regulation_text_annex_filepath.name);
  //       }
  //       break;
  //   }
  // }
  
  saveFileInformation(files: any, controlCode:any, newFile?:boolean) {
    // console.log('files: ',files)
    // console.log('controlCode: ',controlCode)
    
    let file
    if(files.length > 0) file = files[0]
    this.saveFileInformationSetControl(controlCode, file, files.length, newFile)

    switch(controlCode){
			case 'documentFile':{
        this.phase_01_filepath = file
        this.phase_01_file_name = file.name

        let uploadInput = this.uploadInputs.get(controlCode);
        if(uploadInput && newFile == false) this.download_phase_01_filepath = uploadInput.url
        else this.download_phase_01_filepath = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        

        // if(this.download_phase_01_filepath) {
        //   URL.revokeObjectURL(this.download_phase_01_filepath);
        // }
        // this.download_phase_01_filepath = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
				this.phase1form.get("file_file_name")?.setValue(this.uploadsForm.value[controlCode].name);
				break;
			}
      case 'regulationTextAnnex':{
        this.showRegulationTextAnnexFileUploadErrors = this.uploadsForm.controls[controlCode].invalid;
        this.display_regulation_text_annex_file_name = this.uploadsForm.value[controlCode].name
        this.phase1form.get('regulation_text_annex_file_name')?.setValue(this.uploadsForm.value[controlCode].name);
				this.regulation_text_annex_filepath = file;
				break;
			}
    }
  }
  
  saveFileInformationSetControl(controlName:string, file:any, fileL:number, newFile?:boolean){
    const bool = newFile == false ? newFile : true
    let uploadInput = this.uploadInputs.get(controlName);

    if(!this.uploadsForm.contains(controlName)){
      let countValidators = [Validators.max(1)]
			switch(controlName){
				case 'documentFile':{ countValidators.push(Validators.min(1)); break;}
				case 'regulationTextAnnex':{ countValidators.push(Validators.min(1)); break;}
			}
      // if(controlName == 'regulationTextAnnex') countValidators.push(Validators.min(1))
      this.uploadsForm.addControl(controlName, new UntypedFormGroup({
        name: new UntypedFormControl(''),
        count: new UntypedFormControl(0,countValidators),
        type: new UntypedFormControl('',this.checkUploadType()),
        size: new UntypedFormControl(0,Validators.max(this.oneMBinByte*10)),
        newFile: new UntypedFormControl(bool)
      }))
      this.uploadsForm.controls[controlName].markAllAsTouched();
      this.uploadsForm.controls[controlName].markAsDirty();
    }
    else{
      if(this.uploadsForm.controls[controlName].untouched) this.uploadsForm.controls[controlName].markAllAsTouched();
      if(this.uploadsForm.controls[controlName].pristine ) this.uploadsForm.controls[controlName].markAsDirty();
    }

    if(uploadInput){
      console.log('uploadInput: ',uploadInput)
      uploadInput.show_errors = false;
      if(fileL > 0){
        if(fileL == 1){
          this.uploadsForm.controls[controlName].patchValue({
            name: file.name,
            count: 1,
            type: file.type,
            size: file.size,
            newFile: bool,
          });

          uploadInput.file_path = file;
          uploadInput.display_file_name = file.name;
          uploadInput.newFile = bool;
          if(!newFile) uploadInput.url = file.url
          else uploadInput.url = file;
          // uploadInput.url = file;
          uploadInput.show_errors = false;
        }
        else{
          uploadInput.display_file_name = file.name+', ...';
          uploadInput.show_errors = true;
          uploadInput.newFile = bool;
          this.uploadsForm.controls[controlName].patchValue({
            name: file.name+', ...',
            count: fileL,
            type: '',
            size: 0,
            newFile: bool,
          });
        }
      }else{
        uploadInput.display_file_name = '';
        uploadInput.show_errors = true;
        uploadInput.newFile = bool;
        if((document.getElementById(controlName) as HTMLInputElement).value) (document.getElementById(controlName) as HTMLInputElement).value = "";
        this.uploadsForm.controls[controlName].patchValue({
          name: '',
          count: fileL,
          type: '',
          size: 0,
          newFile: bool,
        });
      }
    }else{
      if((document.getElementById(controlName) as HTMLInputElement).value) (document.getElementById(controlName) as HTMLInputElement).value = "";
      this.uploadsForm.controls[controlName].patchValue({
        name: '',
        count: 0,
        type: '',
        size: 0,
        newFile: bool,
      });
    }

    // if(!this.uploadsForm.contains(controlName)){
    //   let countValidators = [Validators.max(1)]
		// 	switch(controlName){
		// 		case 'documentFile':{ countValidators.push(Validators.min(1)); break;}
		// 		case 'regulationTextAnnex':{ countValidators.push(Validators.min(1)); break;}
		// 	}
    //   // if(controlName == 'regulationTextAnnex') countValidators.push(Validators.min(1))
    //   this.uploadsForm.addControl(controlName, new FormGroup({
    //     name: new FormControl(''),
    //     count: new FormControl(0,countValidators),
    //     type: new FormControl('',this.checkUploadType()),
    //     size: new FormControl(0,Validators.max(this.oneMBinByte*10)),
    //     newFile: new FormControl(bool)
    //   }))
    //   this.uploadsForm.controls[controlName].markAllAsTouched();
    //   this.uploadsForm.controls[controlName].markAsDirty();
    // }
    // if(fileL > 0){
    //   if(fileL == 1){
    //     this.uploadsForm.controls[controlName].patchValue({
    //       name: file.name,
    //       count: 1,
    //       type: file.type,
    //       size: file.size,
    //       newFile: bool,
    //     });
    //   }
    //   else{
    //     this.uploadsForm.controls[controlName].patchValue({
    //       name: file.name+', ...',
    //       count: fileL,
    //       type: '',
    //       size: 0,
    //       newFile: bool,
    //     });
    //   }
    // }else{
    //   this.uploadsForm.controls[controlName].patchValue({
    //     name: '',
    //     count: fileL,
    //     type: '',
    //     size: 0,
    //     newFile: bool,
    //   });
    // }
  }

  resetNewUpload(controlCode:string){
    if(this.uploadsForm.contains(controlCode)){
      console.log('og_uploads: ',this.og_uploads[controlCode])
      this.uploadsForm.controls[controlCode].patchValue({
        name: this.og_uploads[controlCode].name,
        count: this.og_uploads[controlCode].count,
        size: this.og_uploads[controlCode].size,
        type: this.og_uploads[controlCode].type,
        newFile: this.og_uploads[controlCode].newFile,
      })

      switch(controlCode){
				case 'documentFile':{
          this.phase_01_filepath = null
          this.download_phase_01_filepath = this.og_uploads[controlCode].url
          this.phase_01_file_name = this.og_uploads[controlCode].name
          this.phase1form.get('file_file_name')?.setValue(this.og_uploads[controlCode].name);
					break;
				}
        case 'regulationTextAnnex':{
          this.showRegulationTextAnnexFileUploadErrors = false;
          this.display_regulation_text_annex_file_name = this.og_uploads[controlCode].name
          this.phase1form.get('regulation_text_annex_file_name')?.setValue(this.og_uploads[controlCode].name);
					break;
				}
      };

      let uploadInput = this.uploadInputs.get(controlCode);
      if(uploadInput){
        uploadInput.display_file_name = this.og_uploads[controlCode].name
        uploadInput.newFile = this.og_uploads[controlCode].newFile
        uploadInput.url = this.og_uploads[controlCode].ur
        uploadInput.show_errors = this.uploadsForm.controls[controlCode].invalid
      }

      (document.getElementById(controlCode) as HTMLInputElement).value = "";
    }
  }
  // !file handlers
	
  // validators
  checkUploadType():ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>{
      if(control != undefined){
        const val = control.value ? control.value : null // read '' as any in indexOf
        if(val){
          let tempbool: boolean = false
          let errors = {
            type: false,
          }
          if(!this.acceptedFileType.includes(val)){
            tempbool = true
            errors.type = true
          }
          return tempbool ? errors : null
        }
      }
      return null
    }
  }
  // !validators

  // validations
  validateNode(node: any){
    let errors:any = []
    if(node){
      if(node.nodeType == 'SECTION'){
        let item:any = this.regulation_sections_form.get(node.uuid);
        if(item && item.info){
          let nodeParent : UntypedFormGroup = item.info
          if(!nodeParent.valid) errors.push('Section')
          if(item.subsections){
            if(node.children && node.children.length > 0){
              node.children.map((nodeChildren:RegulationTextNode)=>{
                if(nodeChildren && nodeChildren.uuid){
                  let nodeChild : UntypedFormGroup = item.subsections.get(nodeChildren.uuid)
                  if(nodeChild && !nodeChild.valid){
                    if(!errors.includes('Subsection/s')) errors.push('Subsection/s')
                  }
                }
              })
            }
          }
        }
      }
      else if(node.nodeType == 'SUBSECTION'){
        let item:any = this.regulation_sections_form.get(node.parentUuid);
        if(item && item.subsections){
          let nodeChild : UntypedFormGroup = item.subsections.get(node.uuid);
          if(nodeChild && !nodeChild.valid){
            if(!errors.includes('Subsection')) errors.push('Subsection')
          }
        }
      }
    }else{
      errors.push('Internal Error')
    }
    return errors
  }

  arrayIsTouched(controlName:any){
    return controlName.touched;
  }

  arrayHasErrors(controlName:any, validType:string){
    return controlName.hasError(validType) 
    && (controlName.dirty 
    || controlName.touched)
  }

  isTouched(form:UntypedFormGroup ,controlName:string, controlGroup?:string,){
    if(controlGroup){
      return ((form.controls[controlGroup] as UntypedFormGroup).get(controlName) as UntypedFormControl).touched;
    }
    else{
      return (form.get(controlName) as UntypedFormControl).touched;
    }
  }

  validateFields(){
    if(!this.uploadsForm.valid){
      this.uploadsForm.markAsTouched()
      for (const control in this.uploadsForm.controls) {
        if (this.uploadsForm.controls.hasOwnProperty(control)) {
          this.uploadsForm.controls[control].markAllAsTouched();
          this.uploadsForm.controls[control].markAsDirty();
        }
      }
    }

    if(!this.phase1form.valid){
      this.phase1form.markAsTouched()
      for (const control in this.phase1form.controls) {
        if (this.phase1form.controls.hasOwnProperty(control)) {
          this.phase1form.controls[control].markAllAsTouched();
          this.phase1form.controls[control].markAsDirty();
        }
      }
    }
  }

  hasError(formGroupName:any, controlName: string, errorType: string, forSize?:boolean, formGroup?:any){
    // console.log('formGroupName : ',formGroupName)
    // console.log('controlName : ',controlName)
    switch(formGroupName){
      case 'uploadsForm':{
        const formGroup = (this.uploadsForm.controls[controlName] as UntypedFormGroup)
        if(formGroup){
          let uploadInput = this.uploadInputs.get(controlName)
          if(uploadInput) uploadInput.show_errors = formGroup.invalid
          if(forSize)
            return (formGroup.get('size')?.hasError(errorType) && (formGroup.get('size')?.dirty || formGroup.get('size')?.touched))
          else
            return (formGroup.get('type')?.hasError(errorType) && (formGroup.get('type')?.dirty || formGroup.get('type')?.touched)) 
                || (formGroup.get('count')?.hasError(errorType) && (formGroup.get('count')?.dirty || formGroup.get('count')?.touched));
        } else return null
      }
      case 'phase1form':{
        if(this.phase1form.contains(controlName)){
          let phase1Control = this.phase1form.get(controlName) as UntypedFormControl
          return (phase1Control.hasError(errorType) 
            && (phase1Control.dirty 
            || phase1Control.touched))
        } else return null
      }
      case 'sub_tags':{
        if(formGroup.contains(controlName)){
          let formGroupControl = formGroup.get(controlName) as UntypedFormControl
          return (formGroupControl.hasError(errorType) 
            && (formGroupControl.dirty 
            || formGroupControl.touched))
        } else return null
      }
      case 'activeRegulationSection':
      case 'activeRegulationSubsection':  
      {
        if(formGroup.contains(controlName)){
          return ((formGroup.get(controlName) as UntypedFormControl).hasError(errorType) 
          && ((formGroup.get(controlName) as UntypedFormControl).dirty 
          || (formGroup.get(controlName) as UntypedFormControl).touched))
        }else return false;
      }
      default:
        return null
    }
  }
  // !validations
}
