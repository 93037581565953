<div class="container">
  <div class="content">
    <form [formGroup] = "newTicket" (ngSubmit) = "onSubmit()">

      <h3>File a Ticket</h3>
      <button type= "button" class="btn btn-primary" (click) = "openNewTag()"
      *ngIf="!new_tag_panel">Request for a New Tag</button>

      <div class = "row form-inline my-3">
        <div class = "col form-group">
          <label>Nature of Concern *</label>
            <select formControlName = "nature"
              class="form-select"
            >
              <option value="" disabled selected>
              --
              </option>
              <option value="technical">
                Technical Issue
              </option>
              <option value="functional">
                Functional Issue
              </option>
              <option value="tag">
                New Tag
              </option>
              <option value="complaint">
                Complaint
              </option>

              
            </select>
          </div>

          <div class = "col form-group">
            <label>Related Regulation *</label>
    
            <select class="form-select" searchable="Search here.." [disabled] = "!regulations"
            formControlName = "regulation">

                <option value="" disabled selected>
                --
                </option>
                <option *ngFor="let entry of regulations" value=" {{entry.short_title}}">
                  {{ (entry.short_title.length>150) ? (entry.short_title | slice:0:150) + '...' : (entry.short_title) }}
                </option>
                
              </select>
            </div>

      </div>

    

      <div class="form-group">
        <label>
          Text
        </label>
        <textarea class = "form-control" formControlName = "ticket_text"></textarea>
      </div>

      <table class="table table-bordered my-3 bg-light" formArrayName="tags_list" *ngIf = "new_tag_panel">
        <thead>
          <tr>
            <th colspan="3">Add Multiple Quantity:</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i">
          <td>
              <label>Type of Tag *</label>
                <select formControlName = "tag_type"
                  class="form-select"
                >
                  <option value="" disabled selected>
                  --
                  </option>
                  
                  <option value="sector">Sector</option>
                  <option value="org_code">Organization Code</option>
                  <option value="location">Location Code</option>
                  <option value="psic">PSIC Code</option>
                  <option value="sob">Stage of Business</option>
                  <option value="division">Division</option>
                  <option value="jurisdiction">Jurisdiction</option>
                  <option value="case_use">Case Use</option>
                  <option value="agency_location">Agency Location</option>
                  <option value="document">Document</option>
                  <option value="instrument">Instruments</option>
                  
                </select>
          </td>
          <td>
              <label>Name of New Tag *</label>
                <input type = "text"
                  class="form-control"
                  formControlName = "tag_name"
                >

                
          </td>
          <td>
              <button type = "button" (click)="removeQuantity(i)" class="btn btn-danger">X</button>
          </td>
        </tr>
        <tr >
          <td colspan="3"><button type="button" (click)="addQuantity()" class="btn btn-primary float-end">Add More</button></td>
          
        </tr>
        </tbody>
      

      </table>

      <hr>

      <div class="form-group">
        <h3>Upload Related Documents</h3>

        <label for="firstname"
          >Valid file types include: jpg, jpeg, pdf, png. Each file
          cannot exceed 10MB.</label
        >
      </div>

      <div class="form-group">
        <input
          type="file"
          class="form-control"
          
          ng-required="true"
          style="display: none"
        />
        <!-- formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  -->
        <div class="file-input p-3 row">
          <div class="form-group">
            <label for="govid">Government Issued ID</label>
            <input
              type="file"
              (change)="upload($event, 'ticket')"
              class="form-control"
              formControlName="ticket_id"
              ng-required="true"
              style="display: none"
              #ticket_id_input
            />
            <!-- formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  -->
            <div class="file-input p-3">
              <label>Drop files here or</label>
              <button
              type = "button"
                (click)="ticket_id_input.click()"
                class="btn btn-light"
              >
                Browse
              </button>
            </div>
            {{ display_ticket_id }}
            <div class="invalid-feedback">
              <!-- *ngIf="submitted && f.username.errors"  -->
              <div>Ticket File is required</div>
              <!-- *ngIf="f.username.errors.required" -->
            </div>
            <!-- Progress Bar -->
            <div
              class="progress m-3"
              style="height: 20px"
              *ngIf="uploadProgress && !showFormErrors"
            >
              <div
                class="
                  progress-bar progress-bar-striped progress-bar-animated
                  bg-success
                "
                role="progressbar"
                [style.width]="(uploadProgress | async) + '%'"
                [attr.aria-valuenow]="uploadProgress | async"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class = "row">
        <button type = "reset" class="col m-3 btn btn-lg fw-bold btn-light" (click) = "refreshComponent()">Cancel</button>
        <button type = "submit" class="col m-3 btn btn-lg btn-success" [disabled]="newTicket.invalid || loading">
          <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading">
        </span>Submit</button>
      </div>       
    </form>    
  </div>
</div>