import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EntityBuilderIndex, RequirementBuilderService } from 'src/app/service/requirement-builder.service';

@Component({
  selector: 'app-artemis-requirement-builder',
  templateUrl: './artemis-requirement-builder.component.html',
  styleUrls: ['./artemis-requirement-builder.component.scss']
})
export class ArtemisRequirementBuilderComponent implements OnInit {

  formGroup = new UntypedFormGroup({

    requirementArray : new UntypedFormArray([])

  })


  get requirementArray(){
    return this.formGroup.get('requirementArray') as UntypedFormArray
  }


  constructor(
    private reqBuilder: RequirementBuilderService

  ) { }

  ngOnInit(): void {
  }

  reqType = EntityBuilderIndex


  addRequirement(type: EntityBuilderIndex){

    const form = this.reqBuilder.buildRequirement(type )

    this.requirementArray.push(form)

 
      

  }

}
