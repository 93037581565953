<app-navbar [currentPage]="'Management'"></app-navbar>

<!--Page Title -->
<div class="row" style="background-color:#f8f6ed;">
  <nav aria-label="breadcrumb" class="col-6 py-2">
    <ol class="breadcrumb my-1 ms-4">
      <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page">
        CREATE A PROPOSED REGULATIONS<i class="fas fa-chevron-right mx-2"></i>PRE - RULE STAGE<i class="fas fa-chevron-right mx-2"></i>PROPOSED RULE STAGE
      </li>
    </ol>
  </nav>
  <!-- <div class="col-6">
    <button class="my-2 me-4 btn btn-dark btn-sm float-end" (click)="toCancel()">Back</button>
  </div> -->
</div>

<div class="content padded">
  <ng-container *ngIf="checkPageLoader(); else notYetLoaded">
    <div class="container-fluid">

      <div class="card my-4" style="border-top:1px solid #D9D9D9;">
        <div class="card-body form-container">

          <!-- uploads -->
          <form *ngIf="currentFormPage == 'uploadstatements'">
            <div class="card-text">
              <p>
                Regulations that meet the threshold must undergo a full RIA to
                assess and justify the impacts of proposed regulatory or
                non-regulatory options on affected parties or stakeholders of
                regulatory proposals. As such, the drafted RIS will be subjected
                by the Agency to a mandatory consultation period with stakeholders
                through the PBRIS and the agency's website. The objective is to
                improve the agency's assessment of its proposed regulation and
                help it finalize its RIS.
              </p>
              <p>
                After undertaking RIA, the proponent agency shall submit the RIS
                and a copy of the final draft of the proposed regulation for ARTA
                review. ARTA is responsible for ensuring the quality of the RIA
                process undertaken and the RIS prepared by the proponent agency.
              </p>
            </div>
  
            <div>
              <div class="card-header card-header-custom mt-2 mb-4">
                <div class="row">
                  <div class="ms-3">
                    <h6 class="fw-bold text-center">REGULATORY IMPACT ASSESSMENT (CONSULTATION)</h6>
                  </div>
                </div>
              </div>
  
              <nav aria-label="breadcrumb" class="breadcrumb-custom my-4">
                <ol class="breadcrumb p-2">
                  <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">Upload Drafted Regulatory Impact Statement</li>
                </ol>
              </nav>
  
              <div class="card-text">
                <p>
                  Please upload the drafted Regulatory Impact Statement in PDF or
                  Microsoft Word format. It is assumed that the RIS that will be
                  submitted has undergone the necessary clearances within your
                  agency. As such kindly provide the necessary dates of approval
                  within the document. Further, kindly ensure that all sections have
                  been fully accomplished.
                </p>
              </div>

              <nav aria-label="breadcrumb" class="breadcrumb-custom">
                <ol class="breadcrumb p-2 mb-0">
                  <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">* REGULATORY IMPACT STATEMENT: 
                    <span><a [routerLink]="[]" (click)="refreshUpload('regulatoryImpactStatement')"><i class="fas fa-redo mx-2"></i></a></span>
                  </li>
                </ol>
              </nav>
  
              <!-- <div class="form-group">
                                              <label class="form-input-header fw-bold" for="title"
                                                  ></label
                                                >
                <input
                  type="file"
                  (change)="
                    saveFileInformation('regulatoryImpactStatement', $event)
                  "
                  class="form-control"
                  ng-required="true"
                  style="display: none"
                  #regulatory_impact_statement_file_input
                />
                <div class="file-input p-3">
                  <label>Drop files here or</label>
                  <button
                    (click)="regulatory_impact_statement_file_input.click()"
                    class="btn btn-light"
                  >
                    Browse
                  </button>
                </div>
                {{
                  uploadInputs.get("regulatoryImpactStatement")?.display_file_name
                }}
              </div> -->
              
              <div class="file-uploader-wrapper mt-3 mb-5">
                <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('regulatoryImpactStatement',$event)">
                  <button type="button" class="btn btn-link dropzone-btn" (click)="regulatoryImpactStatement.click()">
                    <div *ngIf="uploadInputs.get('regulatoryImpactStatement')?.display_file_name">
                      {{ uploadInputs.get("regulatoryImpactStatement")?.display_file_name }}
                    </div>
                    <div *ngFor="let formValid of checkUploadsForm.regulatoryImpactStatement">
                      <div class="error" *ngIf="hasError('uploadsForm','regulatoryImpactStatement', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                        {{formValid.message}}  
                      </div>
                    </div>
                    <span><strong>Drop file here to upload or click to choose file</strong></span>
                    <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                  </button>
                  <input #regulatoryImpactStatement id="regulatoryImpactStatement"  type="file" multiple (change)="onMultipleChange('regulatoryImpactStatement',$event)"/>
                </div>
              </div>
  
              <nav aria-label="breadcrumb" class="breadcrumb-custom">
                <ol class="breadcrumb p-2 mb-0">
                  <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">ANNEXES AND WORKSHEETS
                     <span><a [routerLink]="[]" (click)="refreshUpload('others1')"><i class="fas fa-redo mx-2"></i></a></span>
                  </li>
                </ol>
              </nav>
  
              <div class="form-group row mt-3">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    * Policy Problem
                    <span><a [routerLink]="[]" (click)="refreshUpload('policyProblem')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="saveFileInformation('policyProblem', $event)"
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #policy_problem_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="policy_problem_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{ uploadInputs.get("policyProblem")?.display_file_name }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('policyProblem',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="policyProblem.click()">
                        <div *ngIf="uploadInputs.get('policyProblem')?.display_file_name">
                          {{ uploadInputs.get("policyProblem")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.policyProblem">
                          <div class="error" *ngIf="hasError('uploadsForm','policyProblem', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #policyProblem id="policyProblem"  type="file" multiple (change)="onMultipleChange('policyProblem',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    * Policy Options
                    <span><a [routerLink]="[]" (click)="refreshUpload('policyOptions')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="saveFileInformation('policyOptions', $event)"
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #policy_options_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="policy_options_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{ uploadInputs.get("policyOptions")?.display_file_name }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('policyOptions',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="policyOptions.click()">
                        <div *ngIf="uploadInputs.get('policyOptions')?.display_file_name">
                          {{ uploadInputs.get("policyOptions")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.policyOptions">
                          <div class="error" *ngIf="hasError('uploadsForm','policyOptions', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #policyOptions id="policyOptions"  type="file" multiple (change)="onMultipleChange('policyOptions',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    * Assessment of Policy Options
                    <span><a [routerLink]="[]" (click)="refreshUpload('policyOptionsAssessment')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="
                      saveFileInformation('policyOptionsAssessment', $event)
                    "
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #policy_options_assessment_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="policy_options_assessment_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{
                    uploadInputs.get("policyOptionsAssessment")?.display_file_name
                  }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('policyOptionsAssessment',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="policyOptionsAssessment.click()">
                        <div *ngIf="uploadInputs.get('policyOptionsAssessment')?.display_file_name">
                          {{ uploadInputs.get("policyOptionsAssessment")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.policyOptionsAssessment">
                          <div class="error" *ngIf="hasError('uploadsForm','policyOptionsAssessment', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #policyOptionsAssessment id="policyOptionsAssessment"  type="file" multiple (change)="onMultipleChange('policyOptionsAssessment',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    * Impact Assessment (Tools & Lenses)
                    <span><a [routerLink]="[]" (click)="refreshUpload('impactAssessment')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="saveFileInformation('impactAssessment', $event)"
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #impact_assessment_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="impact_assessment_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{ uploadInputs.get("impactAssessment")?.display_file_name }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('impactAssessment',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="impactAssessment.click()">
                        <div *ngIf="uploadInputs.get('impactAssessment')?.display_file_name">
                          {{ uploadInputs.get("impactAssessment")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.impactAssessment">
                          <div class="error" *ngIf="hasError('uploadsForm','impactAssessment', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #impactAssessment id="impactAssessment"  type="file" multiple (change)="onMultipleChange('impactAssessment',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    Consultation Documentation
                    <span><a [routerLink]="[]" (click)="refreshUpload('consultationDocumentation')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="
                      saveFileInformation('consultationDocumentation', $event)
                    "
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #consultation_documentation_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="consultation_documentation_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{
                    uploadInputs.get("consultationDocumentation")
                      ?.display_file_name
                  }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('consultationDocumentation',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="consultationDocumentation.click()">
                        <div *ngIf="uploadInputs.get('consultationDocumentation')?.display_file_name">
                          {{ uploadInputs.get("consultationDocumentation")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.consultationDocumentation">
                          <div class="error" *ngIf="hasError('uploadsForm','consultationDocumentation', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #consultationDocumentation id="consultationDocumentation"  type="file" multiple (change)="onMultipleChange('consultationDocumentation',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    * Implementation & Enforcement
                    <span><a [routerLink]="[]" (click)="refreshUpload('implementationEnforcement')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="
                      saveFileInformation('implementationEnforcement', $event)
                    "
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #implementation_enforcement_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="implementation_enforcement_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{ 
                    uploadInputs.get("implementationEnforcement")
                      ?.display_file_name
                  }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('implementationEnforcement',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="implementationEnforcement.click()">
                        <div *ngIf="uploadInputs.get('implementationEnforcement')?.display_file_name">
                          {{ uploadInputs.get("implementationEnforcement")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.implementationEnforcement">
                          <div class="error" *ngIf="hasError('uploadsForm','implementationEnforcement', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #implementationEnforcement id="implementationEnforcement"  type="file" multiple (change)="onMultipleChange('implementationEnforcement',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-4">
                  <label class="form-input-header fw-bold" for="title">
                    * Monitoring & Evaluation
                    <span><a [routerLink]="[]" (click)="refreshUpload('monitoringEvaluation')"><i class="fas fa-redo"></i></a></span>
                  </label>
                </div>
                <div class="col-8">
                  <!-- <input
                    type="file"
                    (change)="saveFileInformation('monitoringEvaluation', $event)"
                    class="form-control"
                    ng-required="true"
                    style="display: none"
                    #monitoring_evaluation_file_input
                  />
                  <div class="file-input p-3">
                    <label>Drop files here or</label>
                    <button
                      (click)="monitoring_evaluation_file_input.click()"
                      class="btn btn-light"
                    >
                      Browse
                    </button>
                  </div>
                  {{
                    uploadInputs.get("monitoringEvaluation")?.display_file_name
                  }} -->
                  <div class="file-uploader-wrapper" >
                    <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('monitoringEvaluation',$event)">
                      <button type="button" class="btn btn-link dropzone-btn" (click)="monitoringEvaluation.click()">
                        <div *ngIf="uploadInputs.get('monitoringEvaluation')?.display_file_name">
                          {{ uploadInputs.get("monitoringEvaluation")?.display_file_name }}
                        </div>
                        <div *ngFor="let formValid of checkUploadsForm.monitoringEvaluation">
                          <div class="error" *ngIf="hasError('uploadsForm','monitoringEvaluation', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                            {{formValid.message}}  
                          </div>
                        </div>
                        <span><strong>Drop file here to upload or click to choose file</strong></span>
                        <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                      </button>
                      <input #monitoringEvaluation id="monitoringEvaluation"  type="file" multiple (change)="onMultipleChange('monitoringEvaluation',$event)"/>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- <div class="form-group">
                <label for="page_1_file_input"
                  >Valid file types include: docx, pdf, pptx, xlsx. Each file
                  cannot exceed 10MB.</label
                >
              </div> -->
            </div>

            <!-- Footer Bottons -->
            <div class="row g-3 mt-5">
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="me-2 btn btn-dark float-start" (click)="toCancel()">Cancel</button>
                <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
              </div>
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="btn btn-primary float-end" (click)="gotoForm('details')">Next<i class="fas fa-arrow-circle-right ms-1"></i></button>
              </div>
            </div>
          </form>

          <!-- tagging -->
          <form [formGroup]="crisform" *ngIf="currentFormPage == 'details'">
            <div class="card-header card-header-custom mt-2 mb-4">
              <div class="row">
                <div class="ms-3">
                  <h6 class="fw-bold text-center">REGULATORY IMPACT ASSESSMENT (CONSULTATION)</h6>
                </div>
              </div>
            </div>
  
            <nav aria-label="breadcrumb" class="breadcrumb-custom my-4">
              <ol class="breadcrumb p-2">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">Regulation Details</li>
              </ol>
            </nav>

            <div class="row">
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="form-input-header fw-bold" for="title">Regulation Title *</label>
                  <input type="text" class="form-control" formControlName="title" />
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'title')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.title">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','title',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>

                <div class="col-md-6 form-group fw-bold">
                  <label class="form-input-header" for="subject">Regulation Short Title *</label>
                  <input type="text" class="form-control" formControlName="subject" />
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'subject')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.subject">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','subject',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
              </div>

              <div class="row form-inline my-4">
                <div class="col-md-4 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Regulation Instrument *</label>
                  <select class="form-control" formControlName="reg_instrument" (change)="instrumentOnChange($event)">
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of instruments" [value]="x['id']">
                      {{ x["name"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_instrument')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_instrument">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_instrument',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>

                <div class="col-md-4 form-inline fw-bold">
                  <label class="form-input-header" for="reg_agency">Issuing Agency *</label>
                  <mat-select
                    *ngIf="!multipleIssuingAgency"
                    class="form-control"
                    formControlName="reg_agency"
                  >
                    <mat-option *ngFor="let x of agencies" [value]="x['id']">
                      {{ x["name"] }}
                    </mat-option>
                  </mat-select>
                  <mat-select
                    *ngIf="multipleIssuingAgency"
                    class="form-control"
                    formControlName="reg_agency"
                    multiple
                  >
                    <mat-option *ngFor="let x of agencies" [value]="x['id']">
                      {{ x["name"] }}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_agency')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_agency">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_agency',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>

                <div class="col-md-4 form-inline fw-bold">
                  <label class="form-input-header" for="subject">PBRIS Reference Number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="reg_number"
                  />
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_number')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_number">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_number',item.type)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
              </div>
    
              <hr class="my-3"/>

              <!-- Second phase -->
              <div class="row form-group fw-bold">
                <label class="form-input-header">Classification</label>
                <div class="col-md-12 form-check p-3">
                  <mat-radio-group
                    formControlName="reg_classification"
                    class="example-radio-group"
                  >
                    <mat-radio-button
                      checked
                      value="Business"
                      class="example-radio-button"
                      >Business</mat-radio-button
                    >
                    <mat-radio-button
                      value="Non-Business"
                      class="example-radio-button"
                      >Non-Business</mat-radio-button
                    >
                  </mat-radio-group>
                  <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_number')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_number">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_number',item.type)">* {{item.message}}</mat-error>
                  </ng-container>  -->
                </div>
              </div>
    
              <div class="row" *ngIf="this.crisform.get('reg_classification')?.value == 'Business'">
                <div class="col-md-6 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Sector *</label>
                  <select
                    class="form-control"
                    formControlName="reg_sector"
                    (change)="sectorOnChange($event)"
                  >
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of sectors" [value]="x['id']">
                      {{ x["name"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_sector')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_sector">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_sector',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
    
                <div class="col-md-6 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Division *</label>
                  <select class="form-control" formControlName="reg_division">
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of divisions_select" [value]="x['id']">
                      {{ x["name"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_division')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_division">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_division',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
    
                <div class="col-md-6 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Stage of Business *</label>
                  <select
                    class="form-control"
                    formControlName="reg_business"
                    (change)="sobOnChange($event)"
                  >
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of sobs" [value]="x['id']">
                      {{ x["name"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_business')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_business">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_business',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
    
                <div class="col-md-6 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Case Use *</label>
                  <select class="form-control" formControlName="reg_case">
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of cases_select" [value]="x['id']">
                      {{ x["name"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_case')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_case">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_case',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
              </div>

              <div class="row" *ngIf="this.crisform.get('reg_classification')?.value == 'Non-Business'">
                <div class="col-md-6 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Stage of Life *</label>
                  <select
                    class="form-control"
                    formControlName="reg_stageoflife"
                    name="reg_stageoflife"
                    id="reg_stageoflife"
                    (change)="solOnChange($event)"
                  >
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of sols" [value]="x['id']">
                      {{ x["stage"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_stageoflife')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_stageoflife">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_stageoflife',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
    
                <div class="col-md-6 form-inline fw-bold">
                  <label class="form-input-header" for="subject">Life Event</label>
                  <select class="form-control" formControlName="reg_lifeevent">
                    <option value="" disabled selected>--</option>
                    <option *ngFor="let x of lifeevents_select" [value]="x['id']">
                      {{ x["event"] }}
                    </option>
                  </select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_lifeevent')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_lifeevent">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_lifeevent',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
              </div>
    
              <div class="col-md-6 form-inline fw-bold">
                <label class="form-input-header" for="subject">Jurisdiction *</label>
                <select class="form-control" formControlName="reg_juris">
                  <option value="" disabled selected>--</option>
                  <option *ngFor="let x of jurisdictions" [value]="x['id']">
                    {{ x["name"] }}
                  </option>
                </select>
                <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_juris')">Input Required</mat-hint>
                <ng-container *ngFor="let item of checkCrisform.reg_juris">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform','reg_juris',item.type)">* {{item.message}}</mat-error>
                </ng-container>
              </div>
    
              <hr class="my-5"/>

              <div class="col-md-12 form-inline fw-bold">
                <label class="form-input-header" for="subject">Legal Bases *</label>
                <div>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(crisform, 'reg_legal')">Legal Bases Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.reg_legal">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('crisform', 'reg_legal',item.type)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
                <div formArrayName="reg_legal">
                  <mat-list role="list">
                    <mat-list-item
                      *ngFor="let text of reg_legal_val?.controls; let $i = index"
                      role="listitem"
                    >
                      <div style="margin: 0 1em;">
                        <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!arrayIsTouched(text)">Legal Bases Required</mat-hint>
                        <ng-container *ngFor="let item of checkCrisform.reg_legal_item">
                            <mat-error class="fw-bold" style="color:red" *ngIf="arrayHasErrors(text, item.type)">* {{item.message}}</mat-error>
                        </ng-container>
                      </div>
                      <button
                        mat-stroked-button
                        mat-icon
                        class="btn btn-outline-dark m-1"
                        (click)="removeLegalBase($i)"
                      >
                        <span class="material-icons"> remove_circle </span>
                      </button>
                      <div mat-line>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="{{ $i }}"
                          [matAutocomplete]="auto_reg_legal"
                        />
                        <mat-autocomplete #auto_reg_legal="matAutocomplete">
                          <mat-option
                            *ngFor="let option of legalbasesobs[$i] | async"
                            [value]="option.name"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
    
                <div class="row">
                  <div class="col">
                    <button
                      mat-stroked-button
                      class="btn m-1"
                      class="btn"
                      (click)="addLegalBase()"
                    >
                      <span class="material-icons"> add_circle_outline </span>Add
                      More
                    </button>
                  </div>
                </div>
              </div>
    
              <div class="col-md-12 mt-4 form-inline fw-bold">
                <label class="form-input-header" for="subject">International Agreements</label>
                <div formArrayName="reg_intl">
                  <mat-list role="list">
                    <mat-list-item
                      *ngFor="let text of reg_intl_val?.controls; let $i = index"
                      role="listitem"
                    >
                      <button
                        mat-stroked-button
                        mat-icon
                        class="btn btn-outline-dark m-1"
                        (click)="removeInternationalAgreement($i)"
                      >
                        <span class="material-icons"> remove_circle </span>
                      </button>
                      <div mat-line>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="{{ $i }}"
                          [matAutocomplete]="auto_reg_intl"
                        />
                        <mat-autocomplete #auto_reg_intl="matAutocomplete">
                          <mat-option
                            *ngFor="let option of intlagreementsobs[$i] | async"
                            [value]="option.name"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
    
              <div class="row">
                <div class="col">
                  <button
                    mat-stroked-button
                    class="btn m-1"
                    class="btn"
                    (click)="addInternationalAgreement()"
                  >
                    <span class="material-icons"> add_circle_outline </span>Add More
                  </button>
                </div>
              </div>

              <hr class="my-3"/>
    
              <div class="form-group m-3">
                <div class="form-check fw-bold">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="regulation_repeal"
                    formControlName="regulation_repeal"
                  />
                  <label for="regulation_repeal" class="form-check-label">Check if the regulation repeals another regulation</label>
                </div>
    
                <div class="form-inline fw-bold" *ngIf="this.crisform?.get('regulation_repeal')?.value === true">
                  <label class="form-input-header">Regulation/s Repealed</label>
                  <div formArrayName="reg_repeal">
                    <mat-list role="list">
                      <mat-list-item
                        *ngFor="let obj of reg_repeal_val?.controls; let $i = index"
                        role="listitem"
                      >
                        <button
                          mat-stroked-button
                          mat-icon
                          class="btn btn-outline-dark m-1"
                          (click)="removeRegulationRepealed($i)"
                        >
                          <span class="material-icons"> remove_circle </span>
                        </button>
                        <div mat-line>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="{{ $i }}"
                            [matAutocomplete]="auto_reg_repeal"
                          />
                          <mat-autocomplete
                            #auto_reg_repeal="matAutocomplete"
                          >
                            <mat-option
                              *ngFor="let option of reg_repeal_obs[$i] | async"
                              [value]="option.name"
                            >
                              {{ option.name }}
                            </mat-option>
                          </mat-autocomplete>
                        </div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button
                        mat-stroked-button
                        class="btn m-1"
                        class="btn"
                        (click)="addRegulationRepealed()"
                      >
                        <span class="material-icons"> add_circle_outline </span>Add
                        More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="form-group m-3">
                <div class="form-check fw-bold">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="has_sub_tags"
                    formControlName="has_sub_tags"
                  />
                  <label for="has_sub_tags" class="form-check-label">Check if there are additional taggings</label>
                </div>
              </div>
    
            </div>

            <div class="row">
              <div class="mt-5">
                <p class="float-end fst-italic fw-bold">
                  Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                </p>
              </div>
            </div>

            <!-- Footer Bottons -->
            <div class="row g-3">
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="me-2 btn btn-dark float-start" (click)="toCancel()">Cancel</button>
                <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
              </div>
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="btn btn-primary float-end" (click)="gotoForm(crisform.value.has_sub_tags ? 'subtagging' : 'regulationtext')">Next<i class="fas fa-arrow-circle-right ms-1"></i></button>
                <button class="me-2 btn btn-primary float-end" (click)="gotoForm('uploadstatements')"><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
              </div>
            </div>
          </form>
  
          <!-- Sub tags -->
          <form [formGroup]="crisform" *ngIf="currentFormPage == 'subtagging'">
            <div class="card-header card-header-custom mt-2 mb-4">
              <div class="row">
                <div class="ms-3">
                  <h6 class="fw-bold text-center">REGULATORY IMPACT ASSESSMENT (CONSULTATION)</h6>
                </div>
              </div>
            </div>

            <nav aria-label="breadcrumb" class="breadcrumb-custom mt-4">
              <ol class="breadcrumb p-2">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">Sub Tags</li>
              </ol>
            </nav>
  
            <div>
              <ng-container formArrayName="sub_tags">
                <ng-container *ngFor="let row of getFormsArray('sub_tags'); let i = index; let c = count;">
                  <div class="p-2 form-group fw-bold" [formGroup]="row">
                    <div class="d-flex flex-row-reverse">
                      <button class="btn btn-danger m-1 d-flex align-items-center" type="button" (click)="removeSubTag('sub_tags',i)"><span class="material-icons"> remove_circle </span></button>
                    </div>
                    <div class="row">
                      <div class="col-6 d-flex flex-row">
                        <label class="form-input-header d-flex align-items-center">Classification</label>
                        <mat-radio-group formControlName="reg_classification" class="example-radio-group d-flex  justify-content-evenly flex-row w-100">
                          <mat-radio-button value="Business" class="example-radio-button">Business</mat-radio-button>
                          <mat-radio-button value="Non-Business" class="example-radio-button">Non-Business</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <ng-conainer [ngSwitch]="row.value.reg_classification">
                          <ng-container *ngSwitchCase="'Business'">
                            <!-- sector -->
                            <div class="input-content">
                              <div class="row">
                                <div class="col-2">
                                  <label class="form-input-header" for="reg_sector">Sector *</label>
                                </div>
                                <div class="col">
                                  <select class="form-control" formControlName="reg_sector">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of sectors" [value]="x['id']">
                                      {{ x["name"] }}
                                    </option>
                                  </select>
                                </div>
                                <!--  (change)="sectorOnChange($event)" -->
                              </div>
                              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(row, 'reg_sector')">Input Required</mat-hint>
                              <ng-container *ngFor="let item of checkCrisform.reg_sector">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasError('sub_tags', 'reg_sector',item.type,undefined,row)">* {{item.message}}</mat-error>
                              </ng-container> 
                            </div>
  
                            <!-- div -->
                            <div class="input-content">
                              <div class="row">
                                <div class="col-2">
                                  <label class="form-input-header" for="reg_division">Division *</label>
                                </div>
                                <div class="col">
                                  <select class="form-control" formControlName="reg_division">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of filterSelection('reg_division',row.value.reg_sector)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_division)" [value]="x['id']">
                                      {{ x["name"] }}
                                    </option>
                                  </select>
                                </div>
                                <!--  (change)="sectorOnChange($event)" -->
                              </div>
                              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(row, 'reg_division')">Input Required</mat-hint>
                              <ng-container *ngFor="let item of checkCrisform.reg_division">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasError('sub_tags', 'reg_division',item.type,undefined,row)">* {{item.message}}</mat-error>
                              </ng-container> 
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Non-Business'">
                            <!-- sol -->
                            <div class="input-content">
                              <div class="row">
                                <div class="col-3">
                                  <label class="form-input-header" for="reg_stageoflife">Stage of Life *</label>
                                </div>
                                <div class="col">
                                  <select class="form-control" formControlName="reg_stageoflife">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of sols" [value]="x['id']">
                                      {{ x["stage"] }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(row, 'reg_stageoflife')">Input Required</mat-hint>
                              <ng-container *ngFor="let item of checkCrisform.reg_stageoflife">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasError('sub_tags', 'reg_stageoflife',item.type,undefined,row)">* {{item.message}}</mat-error>
                              </ng-container> 
                            </div>
                          </ng-container>
                        </ng-conainer>
                      </div>
                      <div class="col-6">
                        <ng-conainer [ngSwitch]="row.value.reg_classification">
                          <ng-container *ngSwitchCase="'Business'">
                            <!-- sob -->
                            <div class="input-content">
                              <div class="row">
                                <div class="col-3">
                                  <label class="form-input-header" for="reg_business">Stage of Business *</label>
                                </div>
                                <div class="col">
                                  <select class="form-control" formControlName="reg_business">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of sobs" [value]="x['id']">
                                      {{ x["name"] }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(row, 'reg_business')">Input Required</mat-hint>
                              <ng-container *ngFor="let item of checkCrisform.reg_business">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasError('sub_tags', 'reg_business',item.type,undefined,row)">* {{item.message}}</mat-error>
                              </ng-container> 
                            </div>
  
                            <!-- case -->
                            <div class="input-content">
                              <div class="row">
                                <div class="col-3">
                                  <label class="form-input-header" for="reg_case">Case Use *</label>
                                </div>
                                <div class="col">
                                  <select class="form-control" formControlName="reg_case">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of filterSelection('reg_case',row.value.reg_business)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_case)" [value]="x['id']">
                                      {{ x["name"] }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(row, 'reg_case')">Input Required</mat-hint>
                              <ng-container *ngFor="let item of checkCrisform.reg_case">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasError('sub_tags', 'reg_case',item.type,undefined,row)">* {{item.message}}</mat-error>
                              </ng-container> 
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'Non-Business'">
                            <!-- le -->
                            <div class="input-content">
                              <div class="row">
                                <div class="col-3">
                                  <label class="form-input-header" for="reg_lifeevent">Life event *</label>
                                </div>
                                <div class="col">
                                  <select class="form-control" formControlName="reg_lifeevent">
                                    <option value="" disabled selected>--</option>
                                    <option *ngFor="let x of filterSelection('reg_lifeevent',row.value.reg_stageoflife)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_lifeevent)" [value]="x['id']">
                                      {{ x["event"] }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(row, 'reg_lifeevent')">Input Required</mat-hint>
                              <ng-container *ngFor="let item of checkCrisform.reg_lifeevent">
                                  <mat-error class="fw-bold" style="color:red" *ngIf="hasError('sub_tags', 'reg_lifeevent',item.type,undefined,row)">* {{item.message}}</mat-error>
                              </ng-container> 
                            </div>
                          </ng-container>
                        </ng-conainer>
                      </div>
                    </div>
                  </div>
                  <hr *ngIf="i < (c-1)">
                </ng-container>
              </ng-container>
            </div>
  
            <div class="d-flex flex-row-reverse">
              <button class="btn btn-primary m-1 d-flex align-items-center" type="button" (click)="addSubtag('sub_tags')"><span class="material-icons mx-2">add_circle_outline </span> Add Tags</button>
            </div>

            <!-- Footer Bottons -->
            <div class="row g-3 mt-5">
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="me-2 btn btn-dark float-start" (click)="toCancel()">Cancel</button>
                <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
              </div>
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="btn btn-primary float-end" (click)="gotoForm('regulationtext')">Next<i class="fas fa-arrow-circle-right ms-1"></i></button>
                <button class="me-2 btn btn-primary float-end" (click)="gotoForm('details')"><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
              </div>
            </div>
          </form>
  
          <!-- regulation text -->
          <form [formGroup]="crisform" *ngIf="currentFormPage == 'regulationtext'">
            <nav aria-label="breadcrumb" class="breadcrumb-custom mt-4">
              <ol class="breadcrumb p-2">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">Text of the Regulation (Consultation)</li>
              </ol>
            </nav>
  
            <mat-tree
              [dataSource]="dataSource"
              [treeControl]="treeControl"
              class="example-tree"
            >
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node
                *matTreeNodeDef="let node; when: childlessSection"
                matTreeNodeToggle
              >
                <div
                  class="mat-tree-node d-flex justify-content-between childless-section"
                >
                  <div
                    class="d-flex justify-content-start align-content-stretch flex-grow-1"
                    [class.bg-info]="node.uuid === activeRegulationSectionUuid"
                  >
                    <div
                      class="tree-select d-flex align-self-center"
                      (click)="getRegulationSectionInfo(node)"
                    >
                      <!-- {{ node.name }} -->
                      <div class="d-flex align-self-center" [ngClass]="node.name ? 'color-black' : 'color-red'">
                        {{ node.name ? node.name : '* Section Title is required' }}
                      </div>
                      <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">
                        * Invalid: 
                        <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                          <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                        </ng-container>
                      </mat-error>
                    </div>
                  </div>
                  <div class="justify-content-end">
                    <button
                      mat-stroked-button
                      class="btn m-1"
                      class="btn"
                      (click)="addNewRegulationSubsection(node)"
                    >
                      <span class="material-icons"> add_circle_outline </span>Add
                      Subsection
                    </button>
                    <button
                      mat-stroked-button
                      class="btn btn-outline-dark m-1"
                      (click)="removeRegulationSection(node.uuid)"
                    >
                      <span class="material-icons"> remove_circle </span>
                    </button>
                  </div>
                </div>
              </mat-tree-node>
  
              <mat-tree-node
                *matTreeNodeDef="let node; when: childlessSubsection"
                matTreeNodeToggle
              >
                <div class="mat-tree-node d-flex justify-content-between">
                  <div
                    class="d-flex justify-content-start align-content-stretch flex-grow-1"
                    [class.bg-info]="node.uuid === activeRegulationSubsectionUuid"
                  >
                    <div
                      class="tree-select d-flex align-self-center"
                      (click)="
                        getRegulationSubsectionInfo(node.parentUuid, node.uuid)
                      "
                    >
                      <!-- {{ node.name }} -->
                      <div class="d-flex align-self-center" [ngClass]="node.name ? 'color-black' : 'color-red'">
                        {{ node.name ? node.name : '* Subsection is required' }}
                      </div>
                      <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">
                        * Invalid: 
                        <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                          <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                        </ng-container>
                      </mat-error>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button
                      mat-stroked-button
                      class="btn btn-outline-dark m-1"
                      (click)="
                        removeRegulationSubsection(node.parentUuid, node.uuid)
                      "
                    >
                      <span class="material-icons"> remove_circle </span>
                    </button>
                  </div>
                </div>
              </mat-tree-node>
  
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node d-flex justify-content-between">
                  <div
                    class="d-flex justify-content-start flex-grow-1"
                    [class.bg-info]="node.uuid === activeRegulationSectionUuid"
                  >
                    <button
                      mat-icon-button
                      matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name"
                    >
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{
                          treeControl.isExpanded(node)
                            ? "expand_more"
                            : "chevron_right"
                        }}
                      </mat-icon>
                    </button>
                    <div
                      class="tree-select d-flex align-self-center"
                      (click)="getRegulationSectionInfo(node)"
                      [ngClass]="node.name ? 'color-black' : 'color-red'"
                    >
                      <!-- {{ node.name }} -->
                      {{ node.name ? node.name : '* Section Title is required' }}
                      <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">
                        * Invalid: 
                        <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                          <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                        </ng-container>
                      </mat-error>
                    </div>
                  </div>
  
                  <div class="justify-content-end">
                    <button
                      mat-stroked-button
                      class="btn m-1"
                      class="btn"
                      (click)="addNewRegulationSubsection(node)"
                    >
                      <span class="material-icons"> add_circle_outline </span>Add
                      Subsection
                    </button>
                    <button
                      mat-stroked-button
                      class="btn btn-outline-dark m-1"
                      (click)="removeRegulationSection(node.uuid)"
                    >
                      <span class="material-icons"> remove_circle </span>
                    </button>
                  </div>
                </div>
                <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
                <div
                  [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                  role="group"
                >
                  <ng-container matTreeNodeOutlet></ng-container>
                </div>
              </mat-nested-tree-node>
            </mat-tree>
  
            <div class="form-group mt-5">
              <button
                mat-stroked-button
                class="btn m-1"
                (click)="addNewRegulationSection()"
              >
                <span class="material-icons"> add_circle_outline </span>Add
                Another Section
              </button>
            </div>
  
            <div *ngIf="activeRegulationSection">
              <form [formGroup]="activeRegulationSection.info">
                <div class="form-group">
                  <h4>Text of the Section</h4>
                  <label class="form-input-header fw-bold" for="title"
                    >Section Title *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="section_title"
                    (keyup)="updateTreeSectionName($event)"
                    (change)="checkRegulationTextFormIsInvalid()"
                  />
                  <ng-container *ngFor="let item of checkCrisform.activeRegulationSection.section_title">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasError('activeRegulationSection','section_title',item.type,false,activeRegulationSection.info)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
  
                <div class="form-inline fw-bold">
                  <label class="form-input-header" for="subject"
                    >Section Type*</label
                  >
                  <mat-select
                    class="form-control"
                    multiple
                    formControlName="section_type"
                    (selectionChange)="checkRegulationTextFormIsInvalid()"
                  >
                    <mat-option value="Type A">Type A</mat-option>
                    <mat-option value="Type B">Type B</mat-option>
                    <mat-option value="Type C">Type C</mat-option>
                  </mat-select>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(activeRegulationSection.info, 'section_type')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.activeRegulationSection.section_type">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('activeRegulationSection', 'section_type',item.type,false,activeRegulationSection.info)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
  
                <div class="form-group">
                  <label class="form-input-header fw-bold" for="title"
                    >Section Text *</label
                  >
                  <editor
                    formControlName="section_text"
                    (onSelectionChange)="checkRegulationTextFormIsInvalid()"
                    [init]="{
                      base_url: '/tinymce',
                      suffix: '.min',                    
                      height: 250,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                                          alignleft aligncenter alignright alignjustify | \
                                          bullist numlist outdent indent | removeformat | help'
                    }"
                  ></editor>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(activeRegulationSection.info, 'section_text')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.activeRegulationSection.section_text">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('activeRegulationSection','section_text',item.type,false,activeRegulationSection.info)">* {{item.message}}</mat-error>
                  </ng-container> 
                </div>
              </form>
            </div>
  
            <div *ngIf="activeRegulationSubsection">
              <form [formGroup]="activeRegulationSubsection">
                <div class="form-group">
                  <h4>Text of the Subsection</h4>
                  <label class="form-input-header fw-bold" for="title"
                    >Subsection Title *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="subsection_title"
                    (keyup)="updateTreeSubsectionName($event)"
                    (change)="checkRegulationTextFormIsInvalid()"
                  />
                  <ng-container *ngFor="let item of checkCrisform.activeRegulationSection.subsection_title">
                    <mat-error class="fw-bold" style="color:red" *ngIf="hasError('activeRegulationSubsection','subsection_title',item.type,false,activeRegulationSubsection)">* {{item.message}}</mat-error>
                  </ng-container>
                </div>
  
                <div class="form-group">
                  <label class="form-input-header fw-bold" for="title"
                    >Subsection Text *</label
                  >
                  <editor
                    formControlName="subsection_text"
                    (onSelectionChange)="checkRegulationTextFormIsInvalid()"
                    [init]="{
                      base_url: '/tinymce',
                      suffix: '.min',                    
                      height: 250,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                                          alignleft aligncenter alignright alignjustify | \
                                          bullist numlist outdent indent | removeformat | help'
                    }"
                  ></editor>
                  <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(activeRegulationSubsection, 'subsection_text')">Input Required</mat-hint>
                  <ng-container *ngFor="let item of checkCrisform.activeRegulationSection.subsection_text">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasError('activeRegulationSubsection','subsection_text',item.type,false,activeRegulationSubsection)">* {{item.message}}</mat-error>
                  </ng-container>
  
                  <div class="form-group">
                    <label class="form-input-header" for="uacs_organization_code"
                      >Add Image</label
                    >
                    <input
                      type="file"
                      accept="image/*"
                      class="form-control"
                      (change)="saveImageInformation($event)"
                    />
                    <div class="col-3">
                      <img
                        [src]="previewImg"
                        class="img-fluid img-thumbnail"
                        *ngIf="previewImg"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
  
            <div>
              <input
                class="form-check-input"
                type="checkbox"
                id="has_annex_files"
                formControlName="has_annex_files"
              />
              <label for="has_annex_files" class="form-check-label">
                Check if annexes to the regulation are available
                <span><a [routerLink]="[]" (click)="refreshUpload('regulationTextAnnex')"><i class="fas fa-redo"></i></a></span>
              </label>
            </div>
  
            <div *ngIf="crisform.get('has_annex_files')?.value === true">
              <!-- upload files goes here -->
              <div class="form-group">
                <!-- <label class="form-input-header fw-bold" for="title"
                  >*Upload Text-Readable/Searchable documents:</label
                >
                <input
                  type="file"
                  (change)="saveFileInformation('regulationTextAnnex', $event)"
                  class="form-control"
                  ng-required="true"
                  style="display: none"
                  #regulation_text_annex_file_input
                />
                <div class="file-input p-3">
                  <label>Drop files here or</label>
                  <button
                    (click)="regulation_text_annex_file_input.click()"
                    class="btn btn-light"
                  >
                    Browse
                  </button>
                </div>
                {{ uploadInputs.get("regulationTextAnnex")?.display_file_name }} -->
                <label class="form-input-header fw-bold" for="title">
                  Upload Text-Readable/Searchable documents:
                </label>
                <div class="file-uploader-wrapper" >
                  <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('regulationTextAnnex',$event)">
                    <button type="button" class="btn btn-link dropzone-btn" (click)="regulationTextAnnex.click()">
                      <div *ngIf="uploadInputs.get('regulationTextAnnex')?.display_file_name">
                        {{ uploadInputs.get("regulationTextAnnex")?.display_file_name }}
                      </div>
                      <div *ngFor="let formValid of checkUploadsForm.regulationTextAnnex">
                        <div class="error" *ngIf="hasError('uploadsForm','regulationTextAnnex', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                          {{formValid.message}}  
                        </div>
                      </div>
                      <span><strong>Drop file here to upload or click to choose file</strong></span>
                      <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                    </button>
                    <input #regulationTextAnnex id="regulationTextAnnex"  type="file" multiple (change)="onMultipleChange('regulationTextAnnex',$event)"/>
                  </div>
                </div>
              </div>
              <!-- <div
                class="form-helper text-danger"
                *ngIf="uploadInputs.get('regulationTextAnnex')?.show_errors"
              >
                Invalid file type
              </div> -->
              <!-- <div class="form-group">
                <label for="regulation_text_annex_file_input"
                  >Valid file types include: docx, pdf, pptx, xlsx. Each file
                  cannot exceed 10MB.</label
                >
              </div> -->
            </div>

            <!-- Footer Bottons -->
            <div class="row g-3 mt-5">
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="me-2 btn btn-dark float-start" (click)="toCancel()">
                  Cancel
                </button>
                <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
              </div>
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="btn btn-primary float-end" (click)="gotoForm('consultationschedule')">Next<i class="fas fa-arrow-circle-right ms-1"></i></button>
                <button class="me-2 btn btn-primary float-end" (click)="gotoForm(crisform.value.has_sub_tags ? 'subtagging' : 'details')"><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
              </div>
            </div>
          </form>
  
          <!-- consultation -->
          <form [formGroup]="consultationform" *ngIf="currentFormPage == 'consultationschedule'">
            <div class="card-header card-header-custom mt-2 mb-4">
              <div class="row">
                <div class="ms-3">
                  <h6 class="fw-bold text-center">SHEDULE THE PBRIS CONSULTATION</h6>
                </div>
              </div>
            </div>

            <nav aria-label="breadcrumb" class="breadcrumb-custom mt-4">
              <ol class="breadcrumb p-2">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">Consultation Details</li>
              </ol>
            </nav>
  
            <div class="form-group fw-bold">
              <label class="form-input-header" for="consultation_type"
                >Consultation Type</label
              >
              <select class="form-control" formControlName="consultation_type">
                <option value="Online Commenting">Online Commenting</option>
                <option value="Focus Group Discussion">
                  Focus Group Discussion
                </option>
                <option value="Survey">Survey</option>
              </select>
            </div>
  
            <div class="form-group fw-bold">
              <label class="form-input-header" for="consultation_purpose"
                >Purpose of the Consultation</label
              >
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <textarea
                  matInput
                  placeholder="Input"
                  type="text"
                  formControlName="consultation_purpose"
                ></textarea>
              </mat-form-field>
            </div>
  
            <div class="form-group fw-bold">
              <label class="form-input-header" for="stakeholder_desc"
                >Description of Stakeholders to Consult:</label
              >
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <textarea
                  matInput
                  placeholder="Input"
                  type="text"
                  formControlName="stakeholder_desc"
                ></textarea>
              </mat-form-field>
            </div>
  
            <div
              class="form-group fw-bold"
              *ngIf="
                consultationType === 'Focus Group Discussion' ||
                consultationType === 'Survey'
              "
            >
              <label
                class="form-input-header"
                for="consultation_url"
                *ngIf="consultationType === 'Focus Group Discussion'"
                >Link to Video Chat (e.g. Zoom, Microsoft Teams, Google
                Meet)</label
              >
              <label
                class="form-input-header"
                for="consultation_url"
                *ngIf="consultationType === 'Survey'"
                >Link to Survey (e.g. SurveyMonkey, Google Forms)</label
              >
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <!-- id property for browser-side URL input validationi -->
                <input
                  matInput
                  id="consultation_url"
                  placeholder="Input"
                  type="url"
                  formControlName="consultation_url"
                />
                <mat-hint
                  *ngIf="!consultationform.get('consultation_url')?.touched"
                  >Required</mat-hint
                >
                <mat-hint
                  style="color: red"
                  *ngIf="
                    consultationform.get('consultation_url')?.touched &&
                    !consultationUrlValid
                  "
                  >Input a valid URL</mat-hint
                >
              </mat-form-field>
            </div>
  
            <div class="text-left">
              <button class="m-1 btn btn-warning" (click)="addConsultation()">Add New Consultation to Calendar</button>
            </div>
  
            <div class="text-left" *ngIf="consultationEditIndex > -1">
              <button class="m-1 btn btn-warning" (click)="saveConsultation()">
                Save Edited Consultation
              </button>
            </div>
  
            <nav aria-label="breadcrumb" class="breadcrumb-custom mt-5">
              <ol class="breadcrumb p-2">
                <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">Edit consultations</li>
              </ol>
            </nav>

            <div class="row">
              <!-- <table mat-table
                [dataSource]="consultations_data_source"
                class="mat-elevation-z8 demo-table"
              >
                <ng-container matColumnDef="purpose">
                  <th class="theme-default" mat-header-cell *matHeaderCellDef>
                    Purpose
                  </th>
                  <td mat-cell *matCellDef="let element;">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <input
                        readonly
                        matInput
                        [(ngModel)]="element.title"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </mat-form-field>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="fromdate">
                  <th class="theme-default" mat-header-cell *matHeaderCellDef>
                    Date Start
                  </th>
                  <td mat-cell *matCellDef="let element; let $i = index">
                    <mat-form-field appearance="fill">
                      <mat-label>Choose a date</mat-label>
                      <input readonly matInput
                        [matDatepicker]="picker"
                        [(ngModel)]="element.start"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="
                          updateConsultationFromDate(element.start, $i);
                          refresh.next()
                        "
                        [disabled]="viewDateToday > element.end"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="todate">
                  <th class="theme-default" mat-header-cell *matHeaderCellDef>
                    Date End
                  </th>
                  <td mat-cell *matCellDef="let element; let $i = index">
                    <mat-form-field appearance="fill">
                      <mat-label>Choose a date</mat-label>
                      <input readonly matInput
                        [matDatepicker]="picker"
                        [(ngModel)]="element.end"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="
                          updateConsultationToDate(element.end, $i);
                          refresh.next()
                        "
                        [disabled]="viewDateToday > element.end"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="edit">
                  <th class="theme-default" mat-header-cell *matHeaderCellDef>
                    Edit
                  </th>
                  <td mat-cell *matCellDef="let element; let $i = index">
                    Put edit button
                    <button mat-stroked-button
                      class="btn btn-outline-dark m-1"
                      (click)="loadConsultationDetails($i)"
                      [disabled]="viewDateToday > element.end"
                    >
                      <span class="material-icons"> edit </span>
                    </button>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="delete">
                  <th class="theme-default" mat-header-cell *matHeaderCellDef>
                    Delete
                  </th>
                  <td mat-cell *matCellDef="let element; let $i = index">
                    <button mat-stroked-button
                      class="btn btn-outline-dark m-1"
                      (click)="removeConsultation($i)"
                      [disabled]="viewDateToday > element.end"
                    >
                      <span class="material-icons"> remove_circle </span>
                    </button>
                  </td>
                </ng-container>
  
                <tr mat-header-row
                  *matHeaderRowDef="displayedColumnsConsultations"
                ></tr>
                <tr mat-row
                  *matRowDef="let row; columns: displayedColumnsConsultations"
                ></tr>
              </table> -->

              <table mat-table [dataSource]="consultations_datasource" multiTemplateDataRows class="mat-elevation-z8 demo-table">
                <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumnsConsultations">
                  <th class="theme-default" mat-header-cell *matHeaderCellDef> 
                    <ng-container *ngIf="column == 'purpose'">
                      Purpose
                    </ng-container>
                    <ng-container *ngIf="column == 'type'">
                      Type
                    </ng-container>
                    <ng-container *ngIf="column == 'fromdate'">
                      Date Start
                    </ng-container>
                    <ng-container *ngIf="column == 'todate'">
                      Date End
                    </ng-container>
                    <ng-container *ngIf="column == 'actions'">
                      Actions
                    </ng-container>
                  </th>
                  <td mat-cell *matCellDef="let element; let $i = index">
                    <ng-container *ngIf="column == 'purpose'">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <input
                          readonly
                          matInput
                          [(ngModel)]="element.purpose"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="column == 'type'">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <input
                          readonly
                          matInput
                          [(ngModel)]="element.type"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="column == 'fromdate'">
                      <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input readonly matInput
                          [matDatepicker]="picker"
                          [(ngModel)]="element.from_date"
                          [ngModelOptions]="{ standalone: true }"
                          (ngModelChange)="
                            updateConsultationFromDate(element.from_date, $i, element.id);
                            refresh.next()
                          "
                          [disabled]="element.completed"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="column == 'todate'">
                      <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input readonly matInput
                          [matDatepicker]="picker"
                          [(ngModel)]="element.to_date"
                          [ngModelOptions]="{ standalone: true }"
                          (ngModelChange)="
                            updateConsultationToDate(element.to_date, $i, element.id);
                            refresh.next()
                          "
                          [disabled]="element.completed"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="column == 'actions'">
                      <button mat-stroked-button
                        class="btn btn-outline-dark m-1"
                        (click)="loadConsultationDetails($i, element.id)"
                        [disabled]="element.completed">
                        <span class="material-icons"> edit </span>
                      </button>
                      <button mat-stroked-button
                        class="btn btn-outline-dark m-1"
                        (click)="removeConsultation($i, element.id)"
                        [disabled]="element.completed">
                        <span class="material-icons"> remove_circle </span>
                      </button>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="possibleConflicts">
                  <th mat-header-cell class="theme-default" *matHeaderCellDef aria-label="row actions">Possible Conflicts</th>
                  <td mat-cell *matCellDef="let element">
                    <!-- {{checkValue(element)}} -->
                    <div class="d-flex justify-content-center">
                      <h3>
                        <span class="badge badge-pill badge-danger">
                          {{element && element.conflicts && element.conflicts.length ? element.conflicts.length : '0'}}
                        </span>
                      </h3>
                      <button mat-icon-button aria-label="expand row" (click)="element && element.conflicts && element.conflicts.length > 0 ? (expandedElement = expandedElement === element ? null : element) : null; $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="possibleConflictsDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsConsultationsWConflicts.length">
                    <div class="detail-expander" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <ng-container *ngIf="element.conflicts && element.conflicts.length > 0">
                        <table class="table" style="padding-left: 2em">
                          <tbody >
                            <!-- style=" display: block; max-height: 40vh; overflow: auto;" -->
                            <tr *ngFor="let conflict of element.conflicts ; let i = index" >
                              <!-- style="display: table; width: 100%; table-layout: fixed;" -->
                              <ng-container *ngIf="i < 5">
                                <td>
                                  <div class="row">
                                    <div class="col-1 d-flex justify-content-center align-items-center box" style="margin:0.3em">
                                      <div class="sideways-bar">
                                        <h1>PR</h1>
                                      </div>
                                    </div>
                                    <div class="col">
                                      <h5>{{conflict.type}}</h5>
                                      <div><p>{{conflict.purpose}}</p></div>
                                      <div><span style="font-weight:bold">Sector</span> : {{conflict.sector.name}}</div>
                                      <div><span style="font-weight:bold">Consultation Date</span> : {{conflict.formated_from_date}} - {{conflict.formated_to_date}}</div>
                                      <div>
                                        <span style="font-weight:bold">Issuing Agency/ies</span> : 
                                        <span *ngFor="let agency of conflict.agencies; let i2 = index; let c2 = count">
                                          {{agency.name}}<span *ngIf="i2 < (c2-1)">,</span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsConsultationsWConflicts"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumnsConsultationsWConflicts" [class.example-expanded-row]="expandedElement === element"></tr>
                <!-- (click)="expandedElement = expandedElement === element ? null : element" -->
                <tr mat-row *matRowDef="let row; columns: ['possibleConflictsDetail']" class="details-row"></tr>
              </table>
            </div>
  
            <hr>
  
            <!-- <div>
              <table class="table">
                <thead>
                  <tr>
                    <th><h3>Possible Conflicts</h3></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="consultation_conflicts && consultation_conflicts.length > 0; else noConflicts">
                    <tr *ngFor="let consultation of consultation_conflicts;">
                                                     <ng-container *ngIf="consultation.conflicts && consultation.conflicts"> 0">
                        <td>
                          <div>
                            <h4>Type: {{consultation.type}}</h4>
                            <h4>Purpose: {{consultation.purpose}}</h4>
                            <h4>Stakeholder: {{consultation.stakeholder}}</h4>
                                                     <h4>Date: </h4>
                          </div>
                          <table class="table" style="padding-left: 2em">
                            <tbody style=" display: block; max-height: 40vh; overflow: auto;">
                              <tr *ngFor="let conflict of consultation.conflicts ; let i = index" style="display: table; width: 100%; table-layout: fixed;">
                                <ng-container *ngIf="i < 5">
                                  <td>
                                    <div class="row">
                                      <div class="col-1 d-flex justify-content-center align-items-center box" style="margin:0.3em">
                                        <div class="sideways-bar">
                                          <h1>PR</h1>
                                        </div>
                                      </div>
                                      <div class="col">
                                        <h5>{{conflict.type}}</h5>
                                        <div><span style="font-weight:bold">{{conflict.sector.name}}</span> : {{conflict.formated_from_date}} - {{conflict.formated_to_date}}</div>
                                        <div>
                                          <span style="font-weight:bold">Issuing Agency/ies</span> : 
                                          <span *ngFor="let agency of conflict.agencies; let i2 = index; let c2 = count">
                                            {{agency.name}}<span *ngIf="i2 < (c2-1)">,</span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                        </td> 
                                                              </ng-container>
                    </tr>
                  </ng-container>
                  <ng-template #noConflicts>
                    <tr>
                      <td class="d-flex justify-content-center">
                        No Conflicts
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div> -->
  
            <!-- <hr /> -->
  
            <div class="row">
              <div class="col">
                <div class="row text-center">
                  <div class="col-md-4">
                    <div class="btn-group">
                      <div class="btn btn-primary"
                        mwlCalendarPreviousView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                        Previous
                      </div>
                      <div class="btn btn-outline-secondary"
                        mwlCalendarToday
                        [(viewDate)]="viewDate"
                      >
                        Today
                      </div>
                      <div class="btn btn-primary"
                        mwlCalendarNextView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                        Next
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <h3>
                      {{ viewDate | calendarDate: view + "ViewTitle":"en" }}
                    </h3>
                  </div>
                  <div class="col-md-4"></div>
                </div>
                <br />
                <div>
                  <!-- TODO: Handle when event is clicked -->
                  <mwl-calendar-month-view
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    [activeDayIsOpen]="activeDayIsOpen"
                    (dayClicked)="dayClicked($event.day)"
                  >
                  </mwl-calendar-month-view>
                </div>
              </div>
            </div>
  
            <!-- <div>
              <h3>Possible Conflicts</h3>
              <div class="row" *ngFor="let consultation of consultation_reserve; let i = index; let c = count">
                <div class="col" *ngIf="consultation.conflicts && consultation.conflicts > 0">
                  <div>
                    <h4>Purpose: {{consultation.purpose}}</h4>
                  </div>
                  <div class="row" *ngFor="let conflict of consultation.conflicts">
                    <div class="coll">
                      {{conflict.type}} : {{conflict.from_date}} - {{conflict.to_date}}
                    </div>
                  </div>
                </div>
                <hr *ngIf="i < c">
              </div>
            </div> -->
  
            <hr />
  
            <!-- upload files goes here -->
            <div class="form-group">
              <label class="form-input-header fw-bold">
                * Upload Consultation-Related documents:
                <span><a [routerLink]="[]" (click)="refreshUpload('consultation')"><i class="fas fa-redo"></i></a></span>
              </label>
              <!-- <input
                type="file"
                (change)="saveFileInformation('consultation', $event)"
                class="form-control"
                ng-required="true"
                style="display: none"
                #consultation_file_input
              />
              <div class="file-input p-3">
                <label>Drop files here or</label>
                <button
                  (click)="consultation_file_input.click()"
                  class="btn btn-light"
                >
                  Browse
                </button>
              </div>
              {{ uploadInputs.get("consultation")?.display_file_name }} -->
              <div class="file-uploader-wrapper" >
                <div appFileUploader class="dropzone" (filesChangeEmiter)="saveFileInformation('consultation',$event)">
                  <button type="button" class="btn btn-link dropzone-btn" (click)="consultation.click()">
                    <div *ngIf="uploadInputs.get('consultation')?.display_file_name">
                      {{ uploadInputs.get("consultation")?.display_file_name }}
                    </div>
                    <div *ngFor="let formValid of checkUploadsForm.consultation">
                      <div class="error" *ngIf="hasError('uploadsForm','consultation', formValid.type == 'maxSize' ? 'max' : formValid.type, formValid.type == 'maxSize' ? true : false)">
                        {{formValid.message}}  
                      </div>
                    </div>
                    <span><strong>Drop file here to upload or click to choose file</strong></span>
                    <span>(docx, pdf, pptx, xlsx). file cannot exceed 10MB.</span>
                  </button>
                  <input #consultation id="consultation"  type="file" multiple (change)="onMultipleChange('consultation',$event)"/>
                </div>
              </div>
            </div>
            <!-- <div
              class="form-helper text-danger"
              *ngIf="showRegulationTextAnnexFileUploadErrors"
            >
              Invalid file type
            </div>
            <div class="form-group">
              <label for="regulation_text_annex_file_input"
                >Valid file types include: docx, pdf, pptx, xlsx. Each file
                cannot exceed 10MB.</label
              >
            </div> -->
  
            <!-- Footer Bottons -->
            <div class="row g-3 mt-5">
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="me-2 btn btn-dark float-start" (click)="toCancel()">
                  Cancel
                </button>
                <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
              </div>
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="btn btn-primary float-end" (click)="gotoForm('finalize')">Next<i class="fas fa-arrow-circle-right ms-1"></i></button>
                <button class="me-2 btn btn-primary float-end" (click)="gotoForm('regulationtext')"><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
              </div>
            </div>
          </form>
  
          <!-- finalize -->
          <form *ngIf="currentFormPage == 'finalize'">
            <div class="card-header card-header-custom mt-2">
              <div class="row">
                <div class="ms-3">
                  <h6 class="fw-bold text-center">FINALIZE RIS (DECISION)</h6>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col ms-5 m-5">
                <div class="row">
                  <table
                    mat-table
                    [dataSource]="crisSections"
                    class="mat-elevation-z8 demo-table"
                  >
                    <ng-container matColumnDef="section">
                      <th class="theme-default" mat-header-cell *matHeaderCellDef>
                        Section
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.link; else noLink">
                          <a [routerLink]="[]" (click)="downloadFile(element.link)">{{ element.section }}</a>
                        </ng-container>
                        <ng-template #noLink>
                          <ng-container *ngIf="element.form; else noPage">
                            <a [routerLink]="[]" (click)="gotoForm(element.form)">{{ element.section }}</a>
                          </ng-container>
                          <ng-template #noPage>
                            {{ element.section }}
                          </ng-template>
                        </ng-template>
                        <!-- {{ element.section }} -->
                      </td>
                    </ng-container>
  
                    <ng-container matColumnDef="status">
                      <th class="theme-default" mat-header-cell *matHeaderCellDef>
                        Status
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.status }}
                      </td>
                    </ng-container>
  
                    <tr
                      mat-header-row
                      *matHeaderRowDef="displayedColumnsFinalizeCris"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumnsFinalizeCris"
                    ></tr>
                  </table>
                </div>
              </div>
            </div>
  
            <!-- Footer Bottons -->
            <div class="row g-3 MT-5">
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="me-2 btn btn-dark float-start" (click)="toCancel()">Cancel</button>
                <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
              </div>
              <div class="card-footer text-center col-md-6 mt-2">
                <button class="btn btn-danger float-end" (click)="submitRegulation()" id="bt " [disabled]="false">
                  <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading" ></span>
                  <i class="fas fa-share-square me-1"></i>Submit <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                </button>
                <button class="me-2 btn btn-primary float-end" (click)="gotoForm('consultationschedule')"><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
              </div>
            </div>
          </form>

        </div>
      </div>

    </div>
  </ng-container>

  <ng-template #notYetLoaded>
    <div class="d-flex justify-content-center align-items-center loader-wrapper">
      <div class="spinner-border text-light spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text">Loading . . .</span>
    </div>
  </ng-template>

</div>
