import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pbris-comment-page',
  templateUrl: './pbris-comment-page.component.html',
  styleUrls: ['./pbris-comment-page.component.scss']
})
export class PbrisCommentPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
