import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artemis-loading-animation',
  templateUrl: './artemis-loading-animation.component.html',
  styleUrls: ['./artemis-loading-animation.component.scss']
})
export class ArtemisLoadingAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
