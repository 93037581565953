import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-announcement-crud',
  templateUrl: './announcement-crud.component.html',
  styleUrls: ['./announcement-crud.component.scss']
})
export class AnnouncementCrudComponent implements OnInit {
  method!: string;
  collection!: string;
  id?:any;
  show_form: boolean = false;
  del_confirm: boolean = false;
  date: any;
  
  form = new UntypedFormGroup({
    title: new UntypedFormControl('', Validators.required),
    tag: new UntypedFormControl('common', Validators.required),
    category: new UntypedFormControl('general_announcement', Validators.required),
    description: new UntypedFormControl('')
  });
  
  title: string = "";

  constructor(private afs: AngularFirestore, private router: Router,
    public modalRef: MDBModalRef) {
  }

  ngOnInit(): void {
    this.title = this.method.charAt(0).toUpperCase() + this.method.slice(1) + " " + this.collection;

   if(this.method === "create"){
    this.show_forms();
   }
   if(this.method === "update"){
    this.show_forms();

    this.afs.doc(`${this.collection}/${this.id}`).get().subscribe(data => {
      this.form.patchValue({
        title: data.get('title'),
        tag: data.get('tag'),
        category: data.get('category'),
        description: data.get('description')
      })
      this.date = data.get('date')
    })
   }
   else if(this.method === "delete"){
    this.show_form = false;
    this.del_confirm = true;
   }
  }

  show_forms(){
    this.show_form = true;
    this.del_confirm = false;
  }

  delete(){
    this.afs.doc(`${this.collection}/${this.id}`).delete().then((data => {
      alert("One of your " + this.method + " has been deleted");
      this.modalRef.hide()
    }));
  }

  update(){
    let title = this.form.value.title;
    let tag = this.form.value.tag;
    let category = this.form.value.category;
    let description = this.form.value.description;
    let date = new Date();

    this.afs.doc(`${this.collection}/${this.id}`).update({
      title, tag, category, description, date
    }).then(() => {
      alert(this.title + " successful")
      this.modalRef.hide();
    }).catch(error =>{
      alert(error.message)
    });
  }

  create(){
    let title = this.form.value.title;
    let tag = this.form.value.tag;
    let category = this.form.value.category;
    let description = this.form.value.description;
    let date = new Date();

    this.afs.collection(`${this.collection}`).add({
      title, tag, category, description, date
    }).then(() => {
      alert(this.title + " successful")
      this.modalRef.hide();
    }).catch(error =>{
      alert(error.message)
    })
  }

}
