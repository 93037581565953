import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first, take, tap } from 'rxjs/operators';


@Component({
  selector: 'app-artemis-agency-mini-profile',
  templateUrl: './artemis-agency-mini-profile.component.html',
  styleUrls: ['./artemis-agency-mini-profile.component.scss']
})
export class ArtemisAgencyMiniProfileComponent implements OnInit {

@Input() agency_id: any;

 agency_data: any;
loader:boolean =true;

  constructor(
    private afs : AngularFirestore
  ) { }

  ngOnInit(): void {

    this.loadAgencyProfile()
  }

  loadAgencyProfile(){
    if(this.agency_id){
      this.loader =true;
      this.afs.collection('Agency')
      .doc(this.agency_id)
      .snapshotChanges()
      .pipe(first())
      .subscribe((info) => {

        if(info.payload.exists){
          let agency: any = info.payload.data();

          const data: any = {

            agency_name : agency.agencyDetails.agencyName,
            agency_abrev : agency.agencyDetails.agencyAbbreviation,
            agency_contact : agency.contact,
            agency_email : agency.email,
            agency_logo_url : agency.agencyDetails.logoURL,
            agency_motheragency :agency.agencyDetails.motherAgency.motheragency

          }
          this.agency_data = data;
          //console.log(agency.email)
          this.loader =false
          
        }
      })

    }

    
  }

}
