<section>
    <div class="card-header border-bottom border-4 border-dark">
        <h3>Edit Regulation Details</h3>                           
    </div>
    <div [formGroup]="editRegForm" style="margin-top:2em">
        <div class="row">
            <div class="col-6 d-flex flex-column align-items-center">
                <div>
                    <div>
                        <label>Date Signed (ONAR)</label>
                        <a  style="margin-left:1em; cursor: pointer;" (click)="refreshDate('onar')"><i class="fas fa-sync"></i></a>
                    </div>
                    <!-- appearance="fill" -->
                    <mat-form-field class="" >
                        <!-- <mat-label>Date Signed (ONAR)</mat-label> -->
                        <input matInput readonly [matDatepicker]="date_published_onar" formControlName="date_published_onar" placeholder="YYYY-MM-DD">
                        <mat-datepicker-toggle matSuffix [for]="date_published_onar"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #date_published_onar></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-6 d-flex flex-column align-items-center">
                <div>
                    <div>
                        <label>Date Published (Gazette/Newspaper)</label>
                        <a  style="margin-left:1em; cursor: pointer;" (click)="refreshDate('gazette')"><i class="fas fa-sync"></i></a>
                    </div>
                    <mat-form-field class="">
                        <!-- <mat-label>Date Published (Gazette/Newspaper)</mat-label> -->
                        <input matInput readonly [matDatepicker]="date_published_gazette" formControlName="date_published_gazette" placeholder="YYYY-MM-DD">
                        <mat-datepicker-toggle matSuffix [for]="date_published_gazette"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #date_published_gazette></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            
        </div>
        <div class="d-flex flex-row-reverse">
            <button class="btn btn-primary" type="button" (click)="submit()">Edit Regulation</button>
        </div>
    </div>
</section>