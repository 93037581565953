<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - SPECIFIC AGENCY</th>
            </tr>
            <tr>
                <th colspan = "2">
                    <select class="form-select-sm bg-white" #agency (change)="updateAgency(agency.value)">
                        <option selected disabled>Select an Agency</option>
                        <option *ngFor="let x of agency_list" [value]="x.name"
                        >{{x.name}}</option>
                    </select>
                </tr>
        </thead>
        
          </table>

          <!-- CC SUBMISSION UPDATE-->
                <div class = "container m-3">
                    <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-warning">
                            <tr>
                                <th colspan = "2">CC SUBMISSION UPDATE</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>DATE/TIME DRAFT WAS CREATED:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>FOR VALIDATION DATE/TIME: </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>APPROVED DATE/TIME:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>FOR ARTA REVIEW DATE/TIME:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>FOR MODIFICATION DATE/TIME:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>POSTED DATE/TIME:</td>
                                <td></td>
                            </tr>
                            
                        </tbody>
                        
                   </table>

                   <table class = "table table-bordered mb-0">
                    
                    <thead class = "table table-warning">
                        <tr>
                            <th colspan = "2">MULTI-STAGE</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr>
                            <td>COUNT OF PENDING MULTI-STAGE APPLICATIONS:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>COUNT OF APPROVED MULTI-STAGE APPLICATIONS:</td>
                            <td></td>
                        </tr>
                    </tbody>
                    
               </table>
                </div>
                
                <!-- MULTI-STAGE -->
                <div class = "container m-3">
                    <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-warning">
                            <tr>
                                <th colspan = "2">COUNT PER CLASSIFICATION</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>Total Number of Services:</td>
                                <td>{{svc_count}}</td>
                            </tr>
                            <tr>
                                <td>Simple:</td>
                                <td>{{ simple_svc_count }}</td>
                            </tr>
                            <tr>
                                <td>Complex:</td>
                                <td>{{ complex_svc_count }}</td>
                            </tr>
                            <tr>
                                <td>Highly - Technical:</td>
                                <td>{{ high_tech_svc_count }}</td>
                            </tr>
                            <tr>
                                <td>Highly - Technical exceeding 20 Days:</td>
                                <td>{{ high_tech_excess_svc_count }}</td>
                            </tr>
                        </tbody>
                        
                   </table>
                </div>

               
               
             
</div>