import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ArtemisNewCharterClientServiceModalComponent } from '../../artemis-new-charter-client-service-modal/artemis-new-charter-client-service-modal.component';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { L } from '@angular/cdk/keycodes';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { compileInjectable } from '@angular/compiler';
import { RequirementType } from 'src/app/service/requriements.service';
import { atLeastOne } from 'src/app/validators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { tap } from 'rxjs/operators';
import { info } from 'console';

enum EntityBuilderIndex {
  SITUATIONAL_REQUIREMENT = 1,
  REQUIREMENT = 3,
  CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS = 7,
  REQUIREMENT_TYPE_DOCUMENT = 8,
  REQUIREMENT_TYPE_DOCUMENT_ORIGINAL = 9,
  REQUIREMENT_TYPE_DOCUMENT_PHOTO = 10,
  REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC = 11,
  REQUIREMENT_TYPE_OPERATOR = 12,
  REQUIREMENT_TYPE_OPERATOR_AND = 13,
  REQUIREMENT_TYPE_OPERATOR_OR = 14,
  REQUIREMENT_TYPE_PARENTHESIS = 15,
  REQUIREMENT_TYPE_PARENTHESIS_OPEN = 16,
  REQUIREMENT_TYPE_PARENTHESIS_CLOSE = 17,
  REQUIREMENT_TYPE_DOCUMENT_CERTIFIED_TRUE_COPY = 18,
}

@Component({
  selector: 'app-new-requirements-modal',
  templateUrl: './new-requirements-modal.component.html',
  styleUrls: ['./new-requirements-modal.component.scss'],
})
export class NewRequirementsModalComponent implements OnInit {
  selected_requirement_tab: number = 0;
  activeTabIndex: number = 0;
  isupdating:boolean =false
  chooseany: boolean = false
  standardEditMode: boolean =false

  //classify requirement
  requirement_Type : RequirementType
  enum_req_type = RequirementType

  numberOfCopiesWO0 = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  numberOfCopiesW0 = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  type_of_requirement = ['Documentary', 'Sample', 'Id'];
  standard_requirement_form: UntypedFormArray;

  standardRequirementFormDisplayedColumns: string[] = ['content'];

  requirementForm: UntypedFormGroup = new UntypedFormGroup({
    chooseany: new UntypedFormControl(false),
    requirements: new UntypedFormArray([]),
  });

  get requirements() {
    return this.requirementForm.get('requirements') as UntypedFormArray;
  }

  isRequirementComplete(index:number){
      return this.requirements.at(index).valid
  }

  getRequirementName(index: number) {
    return this.requirements.at(index).get('requirement')?.value;
  }

  valuechange(event:any){
    const value = event.target.value
    if(value){
      this.requirementForm.addControl('title',new UntypedFormControl(''))
    }else{
      this.requirementForm.controls['title'].clearValidators();
      this.requirementForm.removeControl('title')
      
      this.requirementForm.controls['title'].updateValueAndValidity();
      this.chooseany =false
    }

  }

  removeAgencyDivision(event:any,rowCtr : number){
    const isapplicant = event.target.checked;

    let formGroup = (this.requirements.controls as UntypedFormGroup[])[
      rowCtr
    ] as UntypedFormGroup;
    
    if(isapplicant){
          formGroup = this.valueChangedFormControlRemoveValidators(
            formGroup,
            'agency'
          );
          formGroup = this.valueChangedFormControlRemoveValidators(
            formGroup,
            'bureau_division'
          );
    }else{
      formGroup = this.valueChangedFormControlSetValidators(
        formGroup,
        'agency'
      );

      formGroup = this.valueChangedFormControlSetValidators(
        formGroup,
        'bureau_division'
      );
    
    }


  }

  addRequirement(existing_obj: any = null) {
    let requirementType: EntityBuilderIndex = EntityBuilderIndex.REQUIREMENT;
    if (this.isSituational)
      requirementType = EntityBuilderIndex.SITUATIONAL_REQUIREMENT;

    const newerequirement = this.buildNewEntry(requirementType, existing_obj);
   
    this.requirements.push(newerequirement);
    this.data.requirementIndex = this.requirements.length - 1
    
  }

  removeRequirement(index: number) {
    this.requirements.removeAt(index);
    this.storedRequirements.removeAt(index)
  }

  situational_requirement_array = new UntypedFormArray([]);

  document_type_options = ['Original Copy', 'Photo Copy', 'Electronic Copy', 'Certified True Copy'];
  copy_format_logic_options = ['And', 'Or'];

  checkRequirementForm = {
    requirement: [
      { type: 'required', message: 'Requirement title is required' },
      { type: 'duplicate', message: 'Duplicated requirement title' },
    ],
    // originalCopy:[
    //   { type: "required", message: "Original Copy is required" },
    // ],
    // photoCopy:[
    //   { type: "required", message: "Photo Copy is required" },
    // ],
    // electronicCopy:[
    //   { type: "required", message: "Electronic Copy is required" },
    // ],
    number: [{ type: 'required', message: 'Number is required' }],
    unit: [{ type: 'required', message: 'Unit is required' }],
    agency: [{ type: 'required', message: 'Agency is required' }],
    bureau_division: [
      { type: 'required', message: 'Bureau/Division is required' },
    ],
    requirementArray: [
      {
        type: 'hasDuplicateRequirement',
        message: 'Requirments has a duplicated requirement title',
      },
    ],
    copyFormat: [
      { type: 'format', message: 'Documentary Copies format invalid' },
      { type: 'minlength', message: 'Documentary Copies format is required' },
      { type: 'required', message: 'Documentary Copies format is required' },
    ],
    listOfIds: [{ type: 'required', message: "List of ID's is required" }],
   
  };
  storedRequirements:any = []
  isSubsituational:boolean =false
  standard_requirement_form_datasource: MatTableDataSource<AbstractControl>;
  constructor(private snackbarService: SnackbarNotifService,
    public dialogRef: MatDialogRef<ArtemisNewCharterClientServiceModalComponent>,

    private store: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private afs : AngularFirestore
  ) {

    this.getlistofIds();
    if (this.data) {

      
      this.requirement_Type = this.data.type
      if(this.data?.update){
        this.isupdating = this.data?.update
        switch(this.data.type){
          
          case RequirementType.STANDARD_NO_TITLE:
          case RequirementType.STANDARD_WITH_SUB_REQUIREMENT:
              this.requirementForm.removeControl('title')
               let temp: any = [];
               this.standardEditMode =true
               temp.push(this.data.requirement)
               this.patchRequirement(temp)
               break;
          
          default:
              let req:any = this.data.requirement;
              //this.storedRequirements = req.requirement
              this.requirementForm.controls.title.patchValue(req.title)
              //console.log("requirement data",this.data)
              this.chooseany =this.data.requirement?.chooseany
              this.requirementForm.controls.chooseany.patchValue(this.data.requirement?.chooseany)
              this.patchRequirement(req.requirement)
              this.isSituational =true
              break;
        }

        if(this.requirement_Type === RequirementType.STANDARD_WITH_SUB_REQUIREMENT){
          this.storedRequirements = this.data.requirement.requirement
        }
        
        /**
         * if (this.data.isSituational) {
          this.isSituational = true;
          let requirements = this.data.requirement;
          this.isSubsituational = this.data.subsituational? true: false
          this.storedRequirements  = requirements.requirement
          
          this.requirementForm.controls.title.patchValue(requirements.title)
          this.patchRequirement(requirements.requirement)
        }else{
          let temp:any = []
          this.standardEditMode =true
          temp.push(this.data.requirement)
          this.requirementForm.removeControl('title')
          this.patchRequirement(temp)
        }
         */
       
      }else{
         this.InitializeRequirement(this.data.type)
      } 
    }
  }

  InitializeRequirement(type: RequirementType){
    switch(type){
      case RequirementType.STANDARD_NO_TITLE:
            this.requirementForm.removeControl('title')
            break;
    }
    this.addRequirement()
  }

  isSituational: boolean = false;
  
  //patch requirement object to form control
  patchRequirement(requirementsArr:any ){
    for (let standardRequirement of requirementsArr) {
      switch(standardRequirement.requirementType){
        case 'Documentary':{
          let copyFormat = new UntypedFormArray([],[this.checkDocumentFormat(),Validators.required])

          const docFormat:any = standardRequirement.documentFormat? standardRequirement.documentFormat : standardRequirement.copyFormat
          for(let var1 of docFormat){
            switch(var1.type){
              case 'operator': copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR,var1));break;
              case 'parenthesis': copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS,var1));break;
              case 'document': 
              default: copyFormat.push(this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT,var1));break;
            }
          }
          let newStandardRequirement = new UntypedFormGroup({
            requirement: new UntypedFormControl(standardRequirement.requirement?standardRequirement.requirement:'', [Validators.required]),
            requirementType: new UntypedFormControl(standardRequirement.requirementType?standardRequirement.requirementType:'Documentary'),
            copyFormatDisabled: new UntypedFormControl(false),
            copyFormat: copyFormat,
            number: new UntypedFormControl({value:'',disabled:true}),
            unit: new UntypedFormControl({value:'',disabled:true}),
            listOfIds: new UntypedFormControl({value:'',disabled:true}),
            agency: new UntypedFormControl(standardRequirement.agency?standardRequirement.agency:'',standardRequirement.applicant? [] : [Validators.required]),//,[Validators.required]
            bureau_division: new UntypedFormControl(standardRequirement.bureau_division?standardRequirement.bureau_division:'',standardRequirement.applicant? [] : [Validators.required]),//,[Validators.required]
            remarks_or_reminders: new UntypedFormControl(standardRequirement.remarks_or_reminders?standardRequirement.remarks_or_reminders:''),
            applicant : new UntypedFormControl(standardRequirement.applicant? standardRequirement.applicant : false)
          });
         
          this.requirements.push(newStandardRequirement);
          break;
        }
        case 'Sample':{
          let newStandardRequirement = new UntypedFormGroup({
            requirement: new UntypedFormControl(standardRequirement.requirement?standardRequirement.requirement:'', [Validators.required]),
            requirementType: new UntypedFormControl('Sample'),
            copyFormat: new UntypedFormArray([
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC),
            ]),
            copyFormatDisabled: new UntypedFormControl(true),
            number: new UntypedFormControl(standardRequirement.number?standardRequirement.number:'', [Validators.required]),
            unit: new UntypedFormControl(standardRequirement.unit?standardRequirement.unit:'', Validators.required),
            agency: new UntypedFormControl(standardRequirement.agency?standardRequirement.agency:''),//, Validators.required
            bureau_division: new UntypedFormControl(standardRequirement.bureau_division?standardRequirement.bureau_division:''),//,[Validators.required]
            listOfIds: new UntypedFormControl({value:'',disabled:true}),
            remarks_or_reminders: new UntypedFormControl(standardRequirement.remarks_or_reminders?standardRequirement.remarks_or_reminders:''),
            applicant : new UntypedFormControl(standardRequirement.applicant? standardRequirement.applicant : false)
          });
 
          this.requirements.push(newStandardRequirement);
          break;
        }
        case 'Id':{
          let newStandardRequirement = new UntypedFormGroup({
            requirement: new UntypedFormControl(standardRequirement.requirement?standardRequirement.requirement:'', [Validators.required]),
            requirementType: new UntypedFormControl('Id'),
            copyFormat: new UntypedFormArray([
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND),
              this.buildNewEntry(EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC),
            ]),
            copyFormatDisabled: new UntypedFormControl(true),
            number: new UntypedFormControl({value:'',disabled:true}),
            unit: new UntypedFormControl({value:'',disabled:true}),
            listOfIds: new UntypedFormControl(standardRequirement.listOfIds?standardRequirement.listOfIds:'',[Validators.required]),
            agency: new UntypedFormControl({value:'',disabled:true}),
            bureau_division: new UntypedFormControl({value:'',disabled:true}),
            remarks_or_reminders: new UntypedFormControl(standardRequirement.remarks_or_reminders?standardRequirement.remarks_or_reminders:''),
            applicant : new UntypedFormControl({value : false, disabled: true})
          });
          this.requirements.push(newStandardRequirement);
          break;
        }
      }
    }

  }

  checkRequirementDuplicate(): ValidatorFn {
    // console.log('trying validator')
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log('checkRequirementDuplicate control',formArray)
      if (control instanceof UntypedFormArray) {
        let tempArr: any = [];
        let errors = {
          hasDuplicateRequirement: false,
        };
        let tempbool: boolean = false;
        control.controls.forEach((item, index) => {
          let unprocessedErrors = (
            (control.controls[index] as UntypedFormGroup).controls[
              'requirement'
            ] as UntypedFormControl
          ).errors;
          // console.log(index+': unprocessedErrors',unprocessedErrors);

          // console.log(index+': processedErrors',processedErrors);

          let name = item.value.requirement.trim().toLowerCase();
          if (name && name != '' && name != undefined && name != null) {
            const ctr = tempArr
              .map((item: any) => item.name.trim().toLowerCase())
              .indexOf(name);
            if (ctr >= 0) {
              tempArr[ctr].value = tempArr[ctr].value + 1;
              tempArr[ctr].indexes.push(index);
              tempbool = true;
              errors.hasDuplicateRequirement = true;
            } else {
              tempArr.push({ name: name, value: 1, indexes: [index] });
            }
          }
        });
        // console.log('tempArr',tempArr)
        tempArr.forEach((item: any) => {
          if (item.indexes.length > 1) {
            item.indexes.forEach((item2: any) => {
              (
                (control.controls[item2] as UntypedFormGroup).controls[
                  'requirement'
                ] as UntypedFormControl
              ).setErrors({ duplicate: true });
              // ((control.controls[index] as FormGroup).controls['requirement'] as FormControl).updateValueAndValidity();
            });
          }
        });
        return tempbool ? errors : null;
      }
      return null;
    };
  }

  ngOnInit(): void {
    //console.log('Requirements',)
  }

  getcopyformatArray(index:number) {
    return this.requirements.at(index).get('copyFormat')?.value
  }

  getDocFormat(index: number) {
    return this.requirements.at(index).get('copyFormat') as UntypedFormArray;
  }

  // document format functions
  drop(
    controlCode: string,
    event: CdkDragDrop<string[]>,
    rowCtr: number,
    formCtr?: any
  ) {
    // console.log('controlCode: ',controlCode)
    if (event.currentIndex != event.previousIndex) {
      // console.log('rowCtr: ',rowCtr);
      // console.log('changed');

      switch (controlCode) {
        case 'standard': {
          let formArray = (
            (this.standard_requirement_form.controls as UntypedFormGroup[])[
              rowCtr
            ] as UntypedFormGroup
          ).controls['copyFormat'] as UntypedFormArray;
          formArray = this.drop2(formArray, event);
          break;
        }
        case 'situational': {
          let formArray = (
            (
              (
                (
                  (this.situational_requirement_array.controls as UntypedFormGroup[])[
                    formCtr
                  ] as UntypedFormGroup
                ).controls['situational_requirement_form'] as UntypedFormArray
              ).controls as UntypedFormGroup[]
            )[rowCtr] as UntypedFormGroup
          ).controls['copyFormat'] as UntypedFormArray;
          formArray = this.drop2(formArray, event);
          break;
        }
      }
    }
  }

  drop2(formArray: UntypedFormArray, event: CdkDragDrop<string[]>) {
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    formArray = this.moveItemInFormArray(
      formArray,
      event.previousIndex,
      event.currentIndex
    );
    return formArray;
  }

  moveItemInFormArray(
    formArray: UntypedFormArray,
    fromIndex: number,
    toIndex: number
  ) {
    const dir = toIndex > fromIndex ? 1 : -1;
    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
    return formArray;
  }



  // requirements tab row functions
  valueChanged(controlName: any, rowCtr: number) {
    switch (controlName) {
      case 'requirementType': {
        let formGroup = (this.requirements.controls as UntypedFormGroup[])[
          rowCtr
        ] as UntypedFormGroup;
        const requirementType = formGroup.controls[controlName].value;
        switch (requirementType) {
          case 'Documentary': {
            // default: requirement title, requirement type,
            // require:  documentary copies
            // optional: agency, div
            // disable: number, unit, list of ids
            if (formGroup.value['copyFormatDisabled'] == true)
              formGroup = this.valueChangedFormArraySetValidators(
                formGroup,
                'copyFormat'
              );

            if ((formGroup.controls['agency'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'agency'
              );
            if ((formGroup.controls['bureau_division'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'bureau_division'
              );

            if (!(formGroup.controls['unit'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'unit'
              );
            if (!(formGroup.controls['number'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'number'
              );

            if (!(formGroup.controls['listOfIds'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'listOfIds'
              );

            if((formGroup.controls['applicant'] as UntypedFormControl).disabled )
                formGroup =this.valueChangedFormControlSetValidators(formGroup,'applicant', false);
            break;
          }
          case 'Sample': {
            // default: requirement title, requirement type,
            // require: number, unit
            // optional: agency, div
            // disable: documentary copies, list of ids

            if ((formGroup.controls['unit'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'unit',
                true
              );
            if ((formGroup.controls['number'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'number',
                true
              );

            if ((formGroup.controls['agency'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'agency'
              );
            if ((formGroup.controls['bureau_division'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'bureau_division'
              );

            if (formGroup.value['copyFormatDisabled'] == false){
              formGroup = this.valueChangedFormArrayRemoveValidators(
                formGroup,
                'copyFormat'
              );
            }
              

            if (!(formGroup.controls['listOfIds'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'listOfIds'
              );

            if((formGroup.controls['applicant'] as UntypedFormControl).disabled )
              formGroup =this.valueChangedFormControlSetValidators(formGroup,'applicant', false);
             
            break;
          }
          case 'Id': {
            // default: requirement title, requirement type,
            // require: list of ids
            // disable: documentary copies, number, unit, agency, div

            if ((formGroup.controls['listOfIds'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlSetValidators(
                formGroup,
                'listOfIds',
                true
              );

            if (formGroup.value['copyFormatDisabled'] == false)
              formGroup = this.valueChangedFormArrayRemoveValidators(
                formGroup,
                'copyFormat'
              );

            if (!(formGroup.controls['number'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'number'
              );
            if (!(formGroup.controls['unit'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'unit'
              );

            if (!(formGroup.controls['agency'] as UntypedFormControl).disabled)
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'agency'
              );
            if (
              !(formGroup.controls['bureau_division'] as UntypedFormControl).disabled
            )
              formGroup = this.valueChangedFormControlRemoveValidators(
                formGroup,
                'bureau_division'
              );
            if( !(formGroup.controls['applicant'] as UntypedFormControl).disabled)
                formGroup = this.valueChangedFormControlRemoveValidators(
                  formGroup,
                  'applicant'
                );
               
            break;
          }
        }
        break;
      }
    }
  }

  // Requirement Validators
  checkRequirementHint(control: any) {
    // if(control && ((control as any)._rawValidators) != null && ((control as any)._rawValidators).map((item:any)=>item.name).includes('required') && !(control as FormControl).touched) return true;
    if (
      control &&
      (control as UntypedFormControl).enabled &&
      !(control as UntypedFormControl).touched
    )
      return true;
    return false;
  }

  valueChangedFormControlRemoveValidators(
    formGroup: UntypedFormGroup,
    controlName: any
  ) {
    (formGroup.controls[controlName] as UntypedFormControl).clearValidators();
    (formGroup.controls[controlName] as UntypedFormControl).disable({
      onlySelf: true,
    });
    (formGroup.controls[controlName] as UntypedFormControl).updateValueAndValidity();
    return formGroup;
  }

  // validations
  hasError(control: UntypedFormControl, validType: string) {
    return control.hasError(validType) && (control.dirty || control.touched);
  }

  valueChangedFormArraySetValidators(formGroup: UntypedFormGroup, controlName: any) {
    formGroup.patchValue({ copyFormatDisabled: false });
    let formArray = formGroup.controls[controlName] as UntypedFormArray;

    formArray.setValidators([this.checkDocumentFormat(), Validators.required]);
    for (let ctr in formArray.controls as UntypedFormGroup[]) {
      ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).patchValue({
        disabled: false,
      });
      (
        (formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup
      ).updateValueAndValidity();
      let val = (
        ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
          'type'
        ] as UntypedFormControl
      ).value;
      if (val == 'operator' || val == 'document') {
        (
          ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
            'val'
          ] as UntypedFormControl
        ).enable({ onlySelf: true });
        (
          ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
            'val'
          ] as UntypedFormControl
        ).updateValueAndValidity();
        if (val == 'document') {
          (
            ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
              'copyCount'
            ] as UntypedFormControl
          ).enable({ onlySelf: true });
          (
            ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
              'copyCount'
            ] as UntypedFormControl
          ).updateValueAndValidity();
        }
      }
    }
    (formGroup.controls[controlName] as UntypedFormArray) = formArray;
    return formGroup;
  }

  valueChangedFormControlSetValidators(
    formGroup: UntypedFormGroup,
    controlName: any,
    hasValidators?: boolean
  ) {
    (formGroup.controls[controlName] as UntypedFormControl).enable({ onlySelf: true });
    if (hasValidators == true)
      (formGroup.controls[controlName] as UntypedFormControl).setValidators([
        Validators.required,
      ]);
    (formGroup.controls[controlName] as UntypedFormControl).updateValueAndValidity();
    return formGroup;
  }

  valueChangedFormArrayRemoveValidators(
    formGroup: UntypedFormGroup,
    controlName: any
  ) {
    formGroup.patchValue({ copyFormatDisabled: true });
    let formArray = formGroup.controls[controlName] as UntypedFormArray;
    //clear copyformat validators if ID or Sample was selected
    formArray.clearValidators()
    formArray.updateValueAndValidity();
    for (let ctr in formArray.controls as UntypedFormGroup[]) {
      ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).patchValue({
        disabled: true,
      });
      (
        (formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup
      ).updateValueAndValidity();
      let val = (
        ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
          'type'
        ] as UntypedFormControl
      ).value;
      if (val == 'operator' || val == 'document') {
        (
          ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
            'val'
          ] as UntypedFormControl
        ).disable({ onlySelf: true });
        (
          ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
            'val'
          ] as UntypedFormControl
        ).updateValueAndValidity();
        if (val == 'document') {
          (
            ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
              'copyCount'
            ] as UntypedFormControl
          ).disable({ onlySelf: true });
          (
            ((formArray.controls as UntypedFormGroup[])[ctr] as UntypedFormGroup).controls[
              'copyCount'
            ] as UntypedFormControl
          ).updateValueAndValidity();
        }
      }
    }
    (formGroup.controls[controlName] as UntypedFormArray) = formArray;
    return formGroup;
  }

  checkDocumentFormat(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control instanceof UntypedFormArray) {
        let len: any = 0;
        let documentArr: any = 0;
        let origArr: any = 0;
        let photoArr: any = 0;
        let electronicArr: any = 0;
        let certifiedTrueArr: any = 0;
        let openArr: any = 0;
        let parenthesisArr: any = 0;
        let closeArr: any = 0;
        let operatorArr: any = 0;
        let errors = {
          format: false,
          hasOriginalVal: false,
          hasPhotoVal: false,
          hasElectronicVal: false,
        };
        let tempbool: boolean = false;

        control.controls.forEach((item: any) => {
          len++;
          if (item.value.type == 'document') {
            documentArr++;
            if (item.controls.val.value == 'Original Copy') {
              origArr++;
            }
            if (item.controls.val.value == 'Photo Copy') {
              photoArr++;
            }
            if (item.controls.val.value == 'Electronic Copy') {
              electronicArr++;
            }
            if(item.controls.val.value == 'Certified True Copy') {
              certifiedTrueArr++;
            }
          } else if (item.value.type == 'operator') {
            operatorArr++;
          } else if (item.value.type == 'parenthesis') {
            parenthesisArr++;
            if (item.controls.val.value == '(') openArr++;
            if (item.controls.val.value == ')') closeArr++;
          }
        });

        if (len > 0) {
          if (documentArr > 0) {
            if (origArr == 0 && photoArr == 0 && electronicArr == 0 && certifiedTrueArr == 0) {
              tempbool = true;
              errors.format = true;
            }
          } else {
            tempbool = true;
            errors.format = true;
          }
          if (parenthesisArr % 2 == 0) {
            if (openArr != closeArr) {
              tempbool = true;
              errors.format = true;
            }
          } else {
            tempbool = true;
            errors.format = true;
          }
          if (operatorArr != documentArr - 1) {
            tempbool = true;
            errors.format = true;
          }
        }

        if (tempbool == false && len > 0) {
          let tempArr: any = [];
          control.value.forEach((item2: any) => {
            tempArr.push(item2.val);
          });
          // console.log(tempArr)
          let boolTemp = this.checkExpressionValidity(tempArr);
          // console.log('boolTemp',boolTemp)
          if (boolTemp == false) {
            tempbool = true;
            errors.format = true;
          }
        }

        return tempbool ? errors : null;
      }
      return null;
    };
  }

  checkExpressionValidity(arr: any): boolean {
    // console.log('checkExpressionValidity: ',arr)
    if (arr.length > 0) {
      if (arr[0] == '(' && arr[arr.length - 1] == ')') {
        // arr.shift();
        // arr.splice(-1);
        // // console.log('after shift: ',arr)
        // if((arr.length) > 0){
        //   return this.checkExpressionValidity(arr);
        // }
        // else return false

        let tempPar = 0;
        let ctr = 0;
        let tempArr: any = [];
        arr.every((item: any) => {
          ctr++;
          tempArr.push(item);
          if (item == ')') {
            tempPar--;
            if (tempPar == 0) {
              return false;
            } else {
              // tempArr.push(item)
              return true;
            }
          } else {
            if (item == '(') tempPar++;
            // tempArr.push(item)
            return true;
          }
        });
        if (arr.length == ctr) {
          arr.shift();
          arr.splice(-1);
          if (arr.length > 0) {
            return this.checkExpressionValidity(arr);
          } else return false;
        } else {
          let newArr = arr.slice(ctr, arr.length);
          if (newArr.length == 0) {
            return true;
          } else {
            let bool1 = this.checkOper(newArr[0]);
            if (!bool1) return false;
            newArr.shift();
            return this.checkExpressionValidity(newArr);
          }
        }
      } else if (arr[0] != '(' || arr[arr.length - 1] != ')') {
        // console.log('checking for "Doc": ',arr)
        // check for (A or B) and C kind
        if (arr[0] == '(') {
          let tempPar = 1;
          let tempArr: any = [];
          arr.shift();
          arr.every((item: any) => {
            if (item == ')') {
              tempPar--;
              if (tempPar == 0) {
                return false;
              } else {
                tempArr.push(item);
                return true;
              }
            } else {
              if (item == '(') tempPar++;
              tempArr.push(item);
              return true;
            }
          });
          // console.log('after every: ',tempArr)
          return this.checkExpressionValidity(tempArr);
        } else {
          let bool1 = this.checkDoc(arr[0]);
          if (!bool1) return false;

          arr.shift();
          // console.log('after shift: ',arr)
          if (arr.length == 0) {
            return true;
          } else {
            // console.log('checking for Doc Oper "S": ',arr)
            let bool2 = this.checkOper(arr[0]);
            if (!bool2) return false;
            arr.shift();
            // console.log('after shift: ',arr)
            return this.checkExpressionValidity(arr);
          }
        }
      } else return false;
    } else return false;
  }

  checkDoc(val: string) {
    // console.log('checkDoc: ',val)
    if (
      val == 'Original Copy' ||
      val == 'Photo Copy' ||
      val == 'Electronic Copy'||
      val == 'Certified True Copy'
    ) {
      return true;
    }
    return false;
  }

  checkOper(val: string) {
    // console.log('checkOper: ',val)
    if (val == 'And' || val == 'Or') {
      return true;
    }
    return false;
  }

  addNode(controlCode: string, nodeType: any, rowCtr: any, formCtr?: any) {
    switch (controlCode) {
      case 'standard': {
        let formArray = (
          (this.requirements.controls as UntypedFormGroup[])[rowCtr] as UntypedFormGroup
        ).controls['copyFormat'] as UntypedFormArray;
        formArray = this.addNode2(formArray, nodeType);
        break;
      }
      case 'situational': {
        let formArray = (
          (
            (
              (
                (this.requirements.controls as UntypedFormGroup[])[
                  formCtr
                ] as UntypedFormGroup
              ).controls['situational_requirement_form'] as UntypedFormArray
            ).controls as UntypedFormGroup[]
          )[rowCtr] as UntypedFormGroup
        ).controls['copyFormat'] as UntypedFormArray;
        formArray = this.addNode2(formArray, nodeType);
        break;
      }
    }
  }

  addNode2(formArray: UntypedFormArray, nodeType: any) {
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    switch (nodeType) {
      case 'parenthesis': {
        formArray.push(
          this.buildNewEntry(
            EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_OPEN,
            true
          )
        );
        formArray.push(
          this.buildNewEntry(
            EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_CLOSE,
            true
          )
        );
        return formArray;
      }
      case 'document': {
        formArray.push(
          this.buildNewEntry(
            EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
            true
          )
        );
        return formArray;
      }
      case 'operator': {
        formArray.push(
          this.buildNewEntry(
            EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND,
            true
          )
        );
        return formArray;
      }
      default:
        return formArray;
    }
  }

  getcopyFormats(index: number) {
    return this.requirements.at(index).get('copyFormat') as UntypedFormArray;
  }

  copyFormatValue(index: number, value: number) {
    return this.getcopyFormats(index).at(value).get('value')?.value;
  }
  
  // Partial existing_obj handling for Agency Action's persons_responsible FormArray property, but should refactored to fully match the style used in PBRIS modules
  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.SITUATIONAL_REQUIREMENT:
        let newSituationalRequirement = new UntypedFormGroup({
          requirement: new UntypedFormControl(existing_obj?.requirement || '',
            Validators.required),
          requirementType: new UntypedFormControl(
            existing_obj?.requirementType 
          ,Validators.required),
          copyFormat: new UntypedFormArray([
            this.buildNewEntry(
              EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
              existing_obj
            )
          ]),
          /**
           * listOfIds: new FormGroup(
            {
              type_g2c: new FormControl(false),
              type_g2g: new FormControl(false),
              type_g2b: new FormControl(false),
            },
            { validators: [atLeastOne(Validators.requiredTrue)] }
          ),
           */
          listOfIds: new UntypedFormControl({ value: '', disabled: true }),
          copyFormatDisabled: new UntypedFormControl(true),
          number: new UntypedFormControl('', Validators.required),
          unit: new UntypedFormControl('', Validators.required),
          // isGovId: new FormControl({value:false,disabled:true}), // disables bureau_division if true
          agency: new UntypedFormControl('', Validators.required), //,[Validators.required]
          bureau_division: new UntypedFormControl('', Validators.required), //,[Validators.required]
          remarks_or_reminders: new UntypedFormControl(''),
          applicant: new UntypedFormControl(existing_obj?.applicant || false),
        });
        return newSituationalRequirement;
      case EntityBuilderIndex.REQUIREMENT:
        let newStandardRequirement = new UntypedFormGroup({
          requirement: new UntypedFormControl(existing_obj?.requirement || '', Validators.required),
          requirementType: new UntypedFormControl(existing_obj?.requirementType ,Validators.required),
          copyFormat: new UntypedFormArray([
            this.buildNewEntry(
              EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL,
              existing_obj
            )
          ]),
          listOfIds: new UntypedFormControl({ value: '', disabled: true }),
          copyFormatDisabled: new UntypedFormControl(true),
          number: new UntypedFormControl('', Validators.required),
          unit: new UntypedFormControl('', Validators.required),
          // isGovId: new FormControl({value:false,disabled:true}), // disables bureau_division if true
          agency: new UntypedFormControl('', Validators.required), //,[Validators.required]
          bureau_division: new UntypedFormControl('', Validators.required), //,[Validators.required]
          remarks_or_reminders: new UntypedFormControl(''),
          applicant: new UntypedFormControl(existing_obj?.applicant || false),
        });
        return newStandardRequirement;
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT:{
          return new UntypedFormGroup({
            val: new UntypedFormControl({value:existing_obj.val || existing_obj.value || 'Original copy',disabled:false}),
            disabled:new UntypedFormControl(false),
            type:new UntypedFormControl('document'),
            copyCount: new UntypedFormControl({value: existing_obj.copyCount || 1,disabled:false}),
          })
        }

      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ORIGINAL: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Original Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_PHOTO: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Photo Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Electronic Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_DOCUMENT_CERTIFIED_TRUE_COPY: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Certified True Copy',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('document'),
          copyCount: new UntypedFormControl({
            value: 1,
            disabled: existing_obj ? false : true,
          }),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: existing_obj.val || existing_obj.value || 'And',
            disabled: false,
          }),
          disabled: new UntypedFormControl(false),
          type: new UntypedFormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_AND: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'And',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_OPERATOR_OR: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: 'Or',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('operator'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: existing_obj.val || existing_obj.value || '(',
            disabled: false,
          }),
          disabled: new UntypedFormControl(false),
          type: new UntypedFormControl('parenthesis'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_OPEN: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: '(',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('parenthesis'),
        });
      }
      case EntityBuilderIndex.REQUIREMENT_TYPE_PARENTHESIS_CLOSE: {
        return new UntypedFormGroup({
          val: new UntypedFormControl({
            value: ')',
            disabled: existing_obj ? false : true,
          }),
          disabled: new UntypedFormControl(existing_obj ? false : true),
          type: new UntypedFormControl('parenthesis'),
        });
      }
      default:
        return new UntypedFormGroup({});
    }
  }

  private preProcessStandardReq(standardRequirements: any) {
    if (standardRequirements && standardRequirements.length > 0) {
      standardRequirements.map((docItem: any) => {
        switch (docItem.requirementType) {
          case 'Documentary': {
            if (docItem.copyFormat && docItem.copyFormat.length > 0) {
              docItem.documentString = '';
              docItem.copyFormat.map((formatItem: any, index: any) => {
                // console.log('formatItem: ',formatItem)
                switch (formatItem.type) {
                  case 'document': {
                    if (index > 0) docItem.documentString += ' ';
                    switch (formatItem.value) {
                      case 'Original Copy': {
                        docItem.documentString +=
                          '(' +
                          (formatItem.copyCount
                            ? formatItem.copyCount
                            : docItem.originalCopy
                            ? docItem.originalCopy
                            : 'Undefined') +
                          ') ' +
                          formatItem.value;
                        break;
                      }
                      case 'Photo Copy': {
                        docItem.documentString +=
                          '(' +
                          (formatItem.copyCount
                            ? formatItem.copyCount
                            : docItem.photoCopy
                            ? docItem.photoCopy
                            : 'Undefined') +
                          ') ' +
                          formatItem.value;
                        break;
                      }
                      case 'Electronic Copy': {
                        docItem.documentString +=
                          '(' +
                          (formatItem.copyCount
                            ? formatItem.copyCount
                            : docItem.electronicCopy
                            ? docItem.electronicCopy
                            : 'Undefined') +
                          ') ' +
                          formatItem.value;
                        break;
                      }
                      case 'Certified True Copy': {
                        docItem.documentString +=
                          '(' +
                          (formatItem.copyCount
                            ? formatItem.copyCount
                            : docItem.certifiedTrueCopy
                            ? docItem.certifiedTrueCopy
                            : 'Undefined') +
                          ') ' +
                          formatItem.value;
                        break;
                      }
                    }
                    break;
                  }
                  case 'operator': {
                    switch (formatItem.value) {
                      case 'Or': {
                        docItem.documentString +=
                           ' '+ formatItem.value ;
                        break;
                      }
                      default: {
                        if (index > 0) docItem.documentString += ' ';
                        docItem.documentString += formatItem.value;
                      }
                    }
                    break;
                  }
                  default: {
                    if (index > 0) docItem.documentString += ' ';
                    docItem.documentString += formatItem.value;
                  }
                }
              });
            }
            break;
          }
        }

        // }
      });
    }
    return standardRequirements;
  }

  removeDocumentNode(
    controlCode: string,
    formatCtr: any,
    rowCtr: any,
    formCtr?: any
  ) {
    switch (controlCode) {
      case 'standard': {
        let formArray = (
          (this.requirements.controls as UntypedFormGroup[])[rowCtr] as UntypedFormGroup
        ).controls['copyFormat'] as UntypedFormArray;
        formArray = this.removeDocumentNode2(formArray, formatCtr);
        break;
      }
      case 'situational': {
        let formArray = (
          (
            (
              (
                (this.requirements.controls as UntypedFormGroup[])[
                  formCtr
                ] as UntypedFormGroup
              ).controls['situational_requirement_form'] as UntypedFormArray
            ).controls as UntypedFormGroup[]
          )[rowCtr] as UntypedFormGroup
        ).controls['copyFormat'] as UntypedFormArray;
        formArray = this.removeDocumentNode2(formArray, formatCtr);
        break;
      }
    }
  }

  removeDocumentNode2(formArray: UntypedFormArray, formatCtr: number) {
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    formArray.removeAt(formatCtr);
    return formArray;
  }

  clearNodes(controlCode: string, rowCtr: any, formCtr?: any) {
    switch (controlCode) {
      case 'standard': {
        let formArray = (
          (this.requirements.controls as UntypedFormGroup[])[rowCtr] as UntypedFormGroup
        ).controls['copyFormat'] as UntypedFormArray;
        formArray = this.clearNodes2(formArray);
        break;
      }
      case 'situational': {
        let formArray = (
          (
            (
              (
                (this.requirements.controls as UntypedFormGroup[])[
                  formCtr
                ] as UntypedFormGroup
              ).controls['situational_requirement_form'] as UntypedFormArray
            ).controls as UntypedFormGroup[]
          )[rowCtr] as UntypedFormGroup
        ).controls['copyFormat'] as UntypedFormArray;
        formArray = this.clearNodes2(formArray);
        break;
      }
    }
  }

  clearNodes2(formArray: UntypedFormArray) {
    formArray.markAllAsTouched();
    formArray.markAsDirty();
    formArray.clear();
    return formArray;
  }

  defaultAlert:any =[]

  //Back-end Check invalid controls
  public findInvalidControls() {
    const invalid = [];

    for(var cntrl of this.requirements.controls){
      let frmGrouop = cntrl as UntypedFormGroup
      const controls = frmGrouop.controls
      for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    }
    
   //console.log("INvalid Controls",invalid)
}



  isnvalidForm(){
    this.defaultAlert =[]
    for(var cntrl of this.requirements.controls){
        let frmGrouop = cntrl as UntypedFormGroup
        //console.log(frmGrouop.valid)
        //console.log('Form Group Values',frmGrouop.value)
        if(frmGrouop.invalid){
          return true
        }
        
    }
    if(this.requirementForm.invalid) return true

  return false
    
  }

  saveRequirement() {

    
    let standardRequirements: any = [];
    const dt = this.requirements;
    if(this.chooseany){
      if(dt.controls.length <= 1){
        this.snackbarService.displayNotif('Error','Any of the following option is only applicable on multiple requirements. Please add more requirement')
        return;
      }
     
    }
    //this.findInvalidControls();
    if(this.isnvalidForm()){
      this.requirements.markAllAsTouched()
      this.requirements.markAsDirty()
      this.snackbarService.displayNotif('Error','Please fill up all incomplete or invalid inputs')
  
      return;
    }

    let index= 0;
    for (var x of dt.controls) {
      let val = x.value;
      
      switch (val.requirementType) {
        case 'Documentary': {
          let copyFormat: any = [];
          val.copyFormat.forEach((item: any, index: number) => {
            switch (item.type) {
              case 'document': {
                copyFormat.push({
                  value: item.val,
                  sequence: index + 1,
                  type: item.type,
                  copyCount: item.copyCount ? item.copyCount : 1,
                });
                break;
              }
              default: {
                copyFormat.push({
                  value: item.val,
                  sequence: index + 1,
                  type: item.type,
                });
                break;
              }
            }
          });
          let newVal: any = {
            copyFormat: copyFormat,
            bureau_division: val.applicant? '' : val.bureau_division,
            remarks_or_reminders: val.remarks_or_reminders,
            requirement: val.requirement,
            requirementType: val.requirementType,
            agency: val.applicant? '' : val.agency,
            applicant: val.applicant
          };
          if( this.requirement_Type === RequirementType.STANDARD_WITH_SUB_REQUIREMENT){
            newVal.subRequirement = this.storedRequirements[index]?.subRequirement ?this.storedRequirements[index].subRequirement : []
          }
          standardRequirements.push(newVal);
          break;
        }
        case 'Sample': {
          let newVal: any = {
            bureau_division: val.applicant? '' : val.bureau_division,
            number: val.number,
            remarks_or_reminders: val.remarks_or_reminders,
            requirement: val.requirement,
            requirementType: val.requirementType,
            unit: val.unit,
            agency: val.applicant? '' : val.agency,
            applicant: val.applicant
          };
          if( this.requirement_Type === RequirementType.STANDARD_WITH_SUB_REQUIREMENT){
            newVal.subRequirement = this.storedRequirements[index]?.subRequirement ?this.storedRequirements[index].subRequirement : []
          }
          standardRequirements.push(newVal);
          break;
        }
        case 'Id': {
          let newVal: any = {
            remarks_or_reminders: val.remarks_or_reminders,
            requirement: val.requirement,
            requirementType: val.requirementType,
            listOfIds: val.listOfIds,
          };
          if( this.requirement_Type === RequirementType.STANDARD_WITH_SUB_REQUIREMENT){
            newVal.subRequirement = this.storedRequirements[index]?.subRequirement ?this.storedRequirements[index].subRequirement : []
          }
          standardRequirements.push(newVal);
          break;
        }
      }
      index++;
    }


    let returnEntity = {
      title: this.requirementForm.value.title,
      chooseany: this.requirementForm.value.chooseany,
      requirement: this.preProcessStandardReq(standardRequirements),
      submitFlag: true,
    };

    //console.log('Service Requirements',returnEntity)

    this.snackbarService.openPlainNotif('Requirements Saved!','close')

    this.dialogRef.close(returnEntity);
  }


  identification_list: any = []

  getlistofIds(){

    this.afs.collection("Identification References")
    .snapshotChanges()
    .pipe(tap((data:any)=>{

      this.identification_list = []
        data.forEach((info:any)=>{
          let item:any = info.payload.doc.data();
          item.id = info.payload.doc.id;
          this.identification_list.push(item);
        })

        //console.log(this.identification_list)

    })).subscribe()

  }
}
