<!-- Content -->
<div class="card-body">
    <div class="col">
        <div class="row">

            <!-- Summary -->
            <div class="row table-responsive">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr>
                        </tr>
                    </thead>

                    <tbody class="info-subheader">
                        <tr>
                            <th><h6>Summary of the regulatory proposal</h6></th>
                        </tr>
                        <tr>
                            <td *ngIf="currentProposedRegulation && currentProposedRegulation.pia_summary">
                                <div  [innerHtml]="currentProposedRegulation.pia_summary"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Section 1 -->
            <div class="row table-responsive">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr></tr>
                    </thead>

                    <tbody class="info-subheader">
                        <tr>
                            <th><h6>Section 1 - Identification of the policy problem</h6></th>
                        </tr>
                        <tr>
                            <td *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_1">
                                <div [innerHtml]="currentProposedRegulation.pia_section_1.policy_problem_desc"></div>
                            </td>
                        </tr>
                        <tr>
                            <td class="py-2 px-0">
                                <div class="d-flex justify-content-center"  *ngIf="currentProposedRegulation.pia_section_1.file_type == 'zip'; else pia1NotZipFile">
                                    <button class="btn btn-primary" type="button" (click)="downloadFile(currentProposedRegulation.pia_section_1.policy_problem_download_link,currentProposedRegulation.pia_section_1.file_name,'pia_section_1')">
                                        Section 1 - Download: {{currentProposedRegulation.pia_section_1.file_name}}
                                    </button>
                                </div>

                                <ng-template #pia1NotZipFile>
                                    <!-- <div style="width: 700px;" *ngIf="currentProposedRegulation.pia_section_1.file" class="row minispace">
                                        <div class="d-flex justify-content-center">
                                            <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia1')" [stick-to-page]="true" [page]="currentProposedRegulation.pia_section_1.file_page" [show-all]="false" [original-size]=false [src]="currentProposedRegulation.pia_section_1.file"></pdf-viewer>
                                        </div>
                                        <div class="d-flex justify-content-evenly">
                                            <button (click)="pageShift('back','pia1')" class="btn btn-outline-primary"><</button>
                                            <div><p>{{currentProposedRegulation.pia_section_1.file_page}} / {{currentProposedRegulation.pia_section_1.file_total_page}}</p></div>
                                            <button (click)="pageShift('next','pia1')" class="btn btn-outline-primary">></button>
                                        </div>
                                    </div>  -->
                                    
                                    <div class="row file-container" >
                                        <div class="col-1">
                                            <button *ngIf="currentProposedRegulation.pia_section_1.file" (click)="pageShift('back','pia1')" type="button" class="h-100 left-button btn-dark">
                                                <i class="fas fa-chevron-left"></i>
                                            </button>
                                        </div>

                                        <div class="col-10">
                                            <div *ngIf="currentProposedRegulation.pia_section_1.file" >
                                                <div class="d-flex justify-content-center">
                                                    <p>{{currentProposedRegulation.pia_section_1.file_page}} / {{currentProposedRegulation.pia_section_1.file_total_page ? currentProposedRegulation.pia_section_1.file_total_page : "1"}}</p>
                                                </div>
                                                <pdf-viewer *ngIf="currentProposedRegulation.pia_section_1.file"
                                                    style="min-height: 1070px; max-height: 1200px;"
                                                    (after-load-complete)="afterLoadComplete($event,'pia1')"
                                                    [show-all]="false"
                                                    [page]="currentProposedRegulation.pia_section_1.file_page"
                                                    [stick-to-page]="true"
                                                    [src]="currentProposedRegulation.pia_section_1.file">
                                                </pdf-viewer>
                                                <div class="d-flex justify-content-center">
                                                    <p>{{currentProposedRegulation.pia_section_1.file_page}} / {{currentProposedRegulation.pia_section_1.file_total_page ? currentProposedRegulation.pia_section_1.file_total_page : "1"}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-1">
                                            <button *ngIf="currentProposedRegulation.pia_section_1.file" (click)="pageShift('next','pia1')" type="button" class=" h-100 right-button btn-dark">
                                                <i class="fas fa-chevron-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>

                </table>

            </div>

            <!-- Section 2 -->
            <div class="row table-responsive mt-4">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr>
                        </tr>
                    </thead>

                    <tbody class="info-subheader">
                        <tr>
                            <th><h6>Section 2 - Objectives of government action</h6></th>
                        </tr>
                        <tr>
                            <td *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_2">
                                <div [innerHtml]="currentProposedRegulation.pia_section_2"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Section 3 -->
            <div class="row table-responsive">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr>
                        </tr>
                    </thead>

                    <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_3">
                        <tr>
                            <th colspan="2"><h6>Section 3 - Consideration of alternative options</h6></th>
                        </tr>
                        <ng-container *ngFor="let pia3 of currentProposedRegulation.pia_section_3">
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Policy Option Title:</td>
                                <td class="col-md-9 fw-bold">{{pia3.policy_option}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Policy Option Type:</td>
                                <td class="col-md-9">{{pia3.policy_option_type}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Policy Option Desciption:</td>
                                <td class="col-md-9">{{pia3.policy_option_desc}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!-- Section 4 -->
            <div class="row table-responsive">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr>
                        </tr>
                    </thead>

                    <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_4">
                        <tr>
                            <th colspan="2"><h6>Section 4 - Impact analysis of policy options</h6></th>
                        </tr>
                        <ng-container *ngFor="let pia4 of currentProposedRegulation.pia_section_4; let i = index">
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Policy Option Title:</td>
                                <td class="col-md-9 fw-bold">{{pia4.policy_option}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Impacts:</td>
                                <td class="col-md-9">
                                    <div class="d-flex justify-content-evenly">
                                        <span><mat-icon style="margin-left:40%">{{pia4.impacts.impact_economic ? "check_circle_outline" : "cancel"}}</mat-icon><br> Economic</span>
                                        <span><mat-icon style="margin-left:40%">{{pia4.impacts.impact_social ? "check_circle_outline" : "cancel"}}</mat-icon><br> Social</span>
                                        <span><mat-icon style="margin-left:40%">{{pia4.impacts.impact_disaster_risk ? "check_circle_outline" : "cancel"}}</mat-icon><br> Disaster Risk</span>
                                        <span><mat-icon style="margin-left:40%">{{pia4.impacts.impact_environment ? "check_circle_outline" : "cancel"}}</mat-icon><br> Environment</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Narrative of Benefits:</td>
                                <td class="col-md-9">{{pia4.benefits_desc}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Narrative of Costs:</td>
                                <td class="col-md-9">{{pia4.costs_desc}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Narrative of Impacts:</td>
                                <td class="col-md-9">{{pia4.impacts_desc}}</td>
                            </tr>
                            <tr>
                                <div style="width: 700px;" *ngIf="pia4.file" class="row minispace">
                                    <div class="d-flex justify-content-center">
                                        <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia4',i)" [stick-to-page]="true" [page]="pia4.file_page" [show-all]="false" [original-size]=false [src]="pia4.file"></pdf-viewer>
                                    </div>
                                    <div class="d-flex justify-content-evenly">
                                        <button (click)="pageShift('back','pia4',i)" class="btn btn-outline-primary"><</button>
                                        <div><p>{{pia4.file_page}} / {{pia4.file_total_page}}</p></div>
                                        <button (click)="pageShift('next','pia4',i)" class="btn btn-outline-primary">></button>
                                    </div>
                                </div>
                            </tr>
                        </ng-container>
                    </tbody>

                </table>
            </div>

            <!-- Section 4 - Uploaded file-->
            <div class="row table-responsive">
                <table class="table table-striped table-bordered">
                    <tbody class="info-subheader">
                        <tr>
                            <td>
                                <ng-container *ngIf="currentProposedRegulation.pia_section_4_file">
                                    <div class="d-flex justify-content-center"  *ngIf="currentProposedRegulation.pia_section_4_file.file_type == 'zip'; else pia4NotZipFile">
                                        <button class="btn btn-primary" type="button" (click)="downloadFile(currentProposedRegulation.pia_section_4_file.file_download_link,currentProposedRegulation.pia_section_4_file.file_name,'file')">
                                            Section 4 - Download: {{currentProposedRegulation.pia_section_4_file.file_name}}
                                        </button>
                                    </div>
                                    <ng-template #pia4NotZipFile>
                                        <!-- <div style="width: 700px;" *ngIf="currentProposedRegulation.pia_section_4_file.file" class="row minispace">
                                            <div class="d-flex justify-content-center">
                                                <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia4')" [stick-to-page]="true" [page]="currentProposedRegulation.pia_section_4_file.file_page" [show-all]="false" [original-size]=false [src]="currentProposedRegulation.pia_section_4_file.file"></pdf-viewer>
                                            </div>
                                            <div class="d-flex justify-content-evenly">
                                                <button (click)="pageShift('back','pia4')" class="btn btn-outline-primary"><</button>
                                                <div><p>{{currentProposedRegulation.pia_section_4_file.file_page}} / {{currentProposedRegulation.pia_section_4_file.file_total_page}}</p></div>
                                                <button (click)="pageShift('next','pia4')" class="btn btn-outline-primary">></button>
                                            </div>
                                        </div> -->
                                        
                                        <div class="row p-2 w-100 ms-0 file-container" >
                                            <div class="col-1">
                                                <button *ngIf="currentProposedRegulation.pia_section_4_file.file" (click)="pageShift('back','pia4')" type="button" class=" h-100 left-button btn-dark">
                                                    <i class="fas fa-chevron-left"></i>
                                                </button>
                                            </div>
                                            <div class=" col-10">
                                                <div *ngIf="currentProposedRegulation.pia_section_4_file.file" >
                                                    <div class="d-flex justify-content-center"><p>{{currentProposedRegulation.pia_section_4_file.file_page}} / {{currentProposedRegulation.pia_section_4_file.file_total_page ? currentProposedRegulation.pia_section_4_file.file_total_page : "1"}}</p></div>
                                                        <pdf-viewer *ngIf="currentProposedRegulation.pia_section_4_file.file"
                                                        style="min-height: 1070px; max-height: 1200px;"
                                                        (after-load-complete)="afterLoadComplete($event,'pia4')"
                                                        [show-all]="false"
                                                        [page]="currentProposedRegulation.pia_section_4_file.file_page"
                                                        [stick-to-page]="true"
                                                        [src]="currentProposedRegulation.pia_section_4_file.file"></pdf-viewer>
                                                    <div class="d-flex justify-content-center"><p>{{currentProposedRegulation.pia_section_4_file.file_page}} / {{currentProposedRegulation.pia_section_4_file.file_total_page ? currentProposedRegulation.pia_section_4_file.file_total_page : "1"}}</p></div>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <button *ngIf="currentProposedRegulation.pia_section_4_file.file" (click)="pageShift('next','pia4')" type="button" class=" h-100 right-button btn-dark">
                                                    <i class="fas fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Section 5 -->
            <div class="row table-responsive mt-4">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr>
                        </tr>
                    </thead>

                    <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_5">
                        <tr>
                            <th colspan="6"><h6>Section 5 - Consultation</h6></th>
                        </tr>
                        <ng-container *ngFor="let pia5 of currentProposedRegulation.pia_section_5; let i = index">
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Consultation Title:</td>
                                <td class="col-md-9 fw-bold" colspan="5">{{pia5.consultation_title}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Your Agency already conducted a consultation on this policy?</td>
                                <td class="col-md-9" colspan="5">
                                    <mat-icon>{{pia5.has_agency_consulted ? "check_circle_outline" : "cancel"}}</mat-icon>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Methods:</td>
                                <td class="col-md-9" colspan="5">
                                    <div class="d-flex justify-content-evenly">
                                        <span><mat-icon style="margin-left:40%">{{pia5.methods.method_public_commenting ? "check_circle_outline" : "cancel"}}</mat-icon><br> Public Commenting</span>
                                        <span><mat-icon style="margin-left:40%">{{pia5.methods.method_forum ? "check_circle_outline" : "cancel"}}</mat-icon><br> Public Forum</span>
                                        <span><mat-icon style="margin-left:40%">{{pia5.methods.method_focus_group ? "check_circle_outline" : "cancel"}}</mat-icon><br> Focus Group Discussion</span>
                                        <span><mat-icon style="margin-left:40%">{{pia5.methods.method_other ? "check_circle_outline" : "cancel"}}</mat-icon><br> Other</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Other Method:</td>
                                <td class="col-md-9" colspan="5">{{pia5.method_other_desc}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3" style="text-align:left;">Summary of Consulation:</td>
                                <td class="col-md-9" colspan="5">{{pia5.consultation_desc}}</td>
                            </tr>
                            <tr>
                                <td class="col-md-3 fw-bold" colspan="6">PARTICIPANTS</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Stakeholder</td>
                                <td class="fw-bold">Commenting As</td>
                                <td class="fw-bold">Gender</td>
                                <td class="fw-bold">Age</td>
                                <td class="fw-bold">Income Class</td>
                                <td class="fw-bold">Size of firm</td>
                            </tr>
                            <ng-container *ngFor="let participant of pia5.participants">
                                <tr>
                                  <td rowspan="3">{{participant.stakeholder}}</td>
                                  <td>{{participant.commenting_as}}</td>
                                  <td>{{participant.gender}}</td>
                                  <td>{{participant.age}}</td>
                                  <td>{{participant.income_class}}</td>
                                  <td>{{participant.size_of_firm}}</td>
                                </tr>
                                <tr>
                                  <td colspan="5">
                                    <div class="row">
                                      <div class="col">
                                        <label class="form-input-header fw-bold"  for="impacts_desc">Issues and Concerns:</label>
                                        <p class="desc">
                                          {{ participant.issues_and_concerns }}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="5">
                                    <div class="row">
                                      <div class="col">
                                        <label class="form-input-header fw-bold" for="impacts_desc">Actions:</label>
                                        <p class="desc">
                                          {{ participant.actions_taken }}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </ng-container>

                        </ng-container>
                    </tbody>

                </table>
            </div>

            <!-- Section 6 -->
            <div class="row table-responsive mt-4">
                <table class="table table-striped table-bordered">
                    <thead class="info-subheader">
                        <tr>
                        </tr>
                    </thead>

                    <tbody class="info-subheader" *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_6">
                        <tr>
                            <th colspan="2"><h6>Section 6 - Conclusion and recommended option</h6></th>
                        </tr>
                        <tr>
                            <td class="col-md-3" style="text-align:left;">Recommended Policy Option</td>
                            <td class="col-md-9 fw-bold">{{currentProposedRegulation.pia_section_6.recommended_policy_option}}</td>
                        </tr>
                        <tr>
                            <td style="text-align:left;">Summary of Impact Analysis and Justification for Recommended Policy Option:</td>
                            <td>{{currentProposedRegulation.pia_section_6.impact_summary_desc}}</td>
                        </tr>
                        <tr>
                            <td style="text-align:left;">Explanation for Implementation and Compliance of Stakeholders</td>
                            <td>{{ currentProposedRegulation.pia_section_6.compliance_desc }}</td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>

            <!-- Section 6 - Uploaded file-->
            <div class="row table-responsive">
                <table class="table table-striped table-bordered">
                    <tbody class="info-subheader">
                        <tr>
                            <td>
                                <ng-conntainer *ngIf="currentProposedRegulation && currentProposedRegulation.pia_section_6">
                                    <div class="d-flex justify-content-center" *ngIf="currentProposedRegulation.pia_section_6.file_type == 'zip'; else pia6NotZipFile">
                                        <button class="btn btn-primary" type="button" (click)="downloadFile(currentProposedRegulation.pia_section_6.consultation_and_recommendation_download_link,currentProposedRegulation.pia_section_6.file_name,'pia_section_6')">
                                            Section 6 - Download: {{currentProposedRegulation.pia_section_6.file_name}}
                                        </button>
                                    </div>
                                    <ng-template #pia6NotZipFile>
                                        <!-- <div style="width: 700px;" *ngIf="currentProposedRegulation.pia_section_6.file" class="row minispace">
                                            <div class="d-flex justify-content-center">
                                                <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia6')" [stick-to-page]="true" [page]="currentProposedRegulation.pia_section_6.file_page" [show-all]="false" [original-size]=false [src]="currentProposedRegulation.pia_section_6.file"></pdf-viewer>
                                            </div>
                                            <div class="d-flex justify-content-evenly">
                                                <button (click)="pageShift('back','pia6')" class="btn btn-outline-primary"><</button>
                                                <div><p>{{currentProposedRegulation.pia_section_6.file_page}} / {{currentProposedRegulation.pia_section_6.file_total_page}}</p></div>
                                                <button (click)="pageShift('next','pia6')" class="btn btn-outline-primary">></button>
                                            </div>
                                        </div> -->
                                
                                        <div class="row p-2 w-100 ms-0 file-container" >
                                            <div class="col-1">
                                                <button *ngIf="currentProposedRegulation.pia_section_6.file" (click)="pageShift('back','pia6')" type="button" class=" h-100 left-button btn-dark">
                                                    <i class="fas fa-chevron-left"></i>
                                                </button>
                                            </div>
                                            <div class=" col-10">
                                                <div *ngIf="currentProposedRegulation.pia_section_6.file" >
                                                    <div class="d-flex justify-content-center"><p>{{currentProposedRegulation.pia_section_6.file_page}} / {{currentProposedRegulation.pia_section_6.file_total_page ? currentProposedRegulation.pia_section_6.file_total_page : "1"}}</p></div>
                                                        <pdf-viewer *ngIf="currentProposedRegulation.pia_section_6.file"
                                                        style="min-height: 1070px; max-height: 1200px;"
                                                        (after-load-complete)="afterLoadComplete($event,'pia6')"
                                                        [show-all]="false"
                                                        [page]="currentProposedRegulation.pia_section_6.file_page"
                                                        [stick-to-page]="true"
                                                        [src]="currentProposedRegulation.pia_section_6.file"></pdf-viewer>
                                                    <div class="d-flex justify-content-center"><p>{{currentProposedRegulation.pia_section_6.file_page}} / {{currentProposedRegulation.pia_section_6.file_total_page ? currentProposedRegulation.pia_section_6.file_total_page : "1"}}</p></div>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <button *ngIf="currentProposedRegulation.pia_section_6.file" (click)="pageShift('next','pia6')" type="button" class=" h-100 right-button btn-dark">
                                                    <i class="fas fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-conntainer>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>