import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { PbrisAccountSettingComponent } from './../pbris-account-settings/pbris-account-setting.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {Sort} from '@angular/material/sort';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';

@Component({
    selector: 'app-pbris-account-user.component',
    templateUrl: './pbris-account-user.component.html',
    styleUrls: ['./pbris-account-user.component.scss']
})
export class PbrisAccountUserComponent  implements OnInit {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    notificationsDataSource:any= []
    notificationsDataSourceColumns: string[] = ['event','notif_type','message']
    loadedData: any[] = [];
    loadedDataS: any[] = []

    loader = true;
    currentUserId:any =  this.route.snapshot.params.accountId
    currentUser:any;
    userType: any = sessionStorage.getItem("user_type");
    isAdmin: any = sessionStorage.getItem("arta_admin");
    isAgencyMember:any = false;
    usersAgency:any;

    filtersForm = new UntypedFormGroup({
        search: new UntypedFormControl(''),
    })

    settingsModel:{
        // all
        regulations:{
            create: boolean, //all
            review: boolean, // admin / agencymemb / arta
        },
        consultations:{
            create: boolean, //all
            review: boolean, // admin / agencymemb / arta
        },
        citizenscharters:{
            create: boolean, //all
            review: boolean, // admin / agencymemb / arta
        },
        services:{
            create: boolean, //all
            review: boolean, // admin / agencymemb / arta
        },

        // admin / arta
        agency: {
            complaint: boolean,
            create: boolean,
        },

        //admin
        worklist: {
            create: boolean,
            review: boolean,
        }
        accounts: {
            create: boolean,
            review: boolean,
        }

    }

    constructor(
        // public auth: AngularFireAuth,
        // private router: Router,
        private afs: AngularFirestore,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        private nE: NotificationsEmailer,
        ) {}

    ngOnInit(): void {
        this.loader = true
        this.afs.collection('Users').doc(this.currentUserId).snapshotChanges()
        .pipe(first())
        .subscribe(info=>{
            this.currentUser = info.payload.data()
            this.currentUser.id = info.payload.id
            if(this.currentUser.government && this.currentUser.government.agency){
                this.usersAgency = this.currentUser.government.agency
                this.isAgencyMember = true
            }
            
            this.checkSubs()
        })
    }

    async checkSubs(){
        this.loader = true
        if(this.currentUser.settings){
            let  promiseArr:any = []
            if(this.currentUser.settings.regulations && this.currentUser.settings.regulations.create){
                promiseArr.push(await this.getData('Regulatory','Creation'))
            }
            if(this.currentUser.settings.consultations && this.currentUser.settings.consultations.create){
                promiseArr.push(await this.getData('Consultation','Creation'))
            }
            if(this.currentUser.settings.citizenscharters && this.currentUser.settings.citizenscharters.create){
                promiseArr.push(await this.getData('Citizens Charter','Creation'))
            }
            if(this.currentUser.settings.services && this.currentUser.settings.services.create){
                promiseArr.push(await this.getData('Service','Creation'))
            }
            

            if(this.isAdmin || this.isAgencyMember || this.userType == 'arta'){
                if(this.currentUser.settings.regulations && this.currentUser.settings.regulations.review){
                    promiseArr.push(await this.getData('Regulatory','Review'))
                }
                if(this.currentUser.settings.consultations && this.currentUser.settings.consultations.review){
                    promiseArr.push(await this.getData('Consultation','Review'))
                }
                if(this.currentUser.settings.citizenscharters && this.currentUser.settings.citizenscharters.review){
                    promiseArr.push(await this.getData('Citizens Charter','Review'))
                }
                if(this.currentUser.settings.services && this.currentUser.settings.services.review){
                    promiseArr.push(await this.getData('Service','Review'))
                }
            }

            if(this.isAdmin || this.userType == 'arta'){
                if(this.currentUser.settings.agency && this.currentUser.settings.agency.complaint){
                    promiseArr.push(await this.getData('Tickets','Tickets'))
                }
                // if(this.currentUser.settings.agency && this.currentUser.settings.agency.create){
                //     promiseArr.push(await this.getData('',''))
                // }
            }

            if(this.isAdmin){
                if(this.currentUser.settings.accounts && this.currentUser.settings.accounts.create){
                    promiseArr.push(await this.getData('Acccount','Creation'))
                }
                // if(this.currentUser.settings.accounts && this.currentUser.settings.accounts.review){
                //     promiseArr.push(await this.getData('',''))
                // }

                // if(this.currentUser.settings.worklist && this.currentUser.settings.worklist.create){
                //     promiseArr.push(await this.getData('',''))
                // }
                // if(this.currentUser.settings.worklist && this.currentUser.settings.worklist.review){
                //     promiseArr.push(await this.getData('',''))
                // }
            }

            Promise.all(promiseArr)
            .then((results:any[])=>{
                let tempArr:any=[]
                results.forEach((result:any[])=>{
                    if(result.length > 0){
                        result.forEach((item:any)=>{
                            tempArr.push(item)
                        })
                    }
                })
                
                this.loadedData = tempArr
                this.notificationsDataSource = new MatTableDataSource<any>(tempArr)
                this.resetSortPage()
                this.loader = false
            })
        }else{
            this.loader = false
        }
    }
    
    resetSortPage(){
        this.notificationsDataSource.paginator = this.paginator
        this.notificationsDataSource.sort = this.sort
        this.settingFilter()
    }

    settingFilter(){
        this.notificationsDataSource.filterPredicate = (data:any, filter:string): boolean=>{
            return data.event.toLowerCase().includes(filter.toLowerCase())
            || data.message.toLowerCase().includes(filter.toLowerCase())
            || data.notif_type.toLowerCase().includes(filter.toLowerCase())
        }
    }

    search(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value
        // console.log(filterValue)
        this.notificationsDataSource.filter = filterValue.trim().toLowerCase()
        // this.resetSortPage()
        
        if (this.notificationsDataSource.paginator) {
            this.notificationsDataSource.paginator.firstPage();
        }
    }

    sortChange(sort: Sort){
        const data = this.loadedData.slice()
        if(!sort.active || sort.direction === ''){
            this.loadedDataS = data
            return;
        }
        this.loadedDataS = data.sort((a:any,b:any)=>{
            const isAsc = sort.direction === 'asc'
            switch (sort.active){
                case 'event':
                return this.compare(a.event.trim().toLowerCase(), b.event.trim().toLowerCase(), isAsc)
                case 'notif_type':
                return this.compare(a.notif_type.trim().toLowerCase(), b.notif_type.trim().toLowerCase(), isAsc)
                default:
                return 0
            }
        })
        this.notificationsDataSource = new MatTableDataSource<any>(this.loadedDataS)
        this.resetSortPage()
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getData(start:any, end:any){
        return this.afs.collection('Notifications').ref //add filter when theres date
        .where('notif_type','>=',start)
        .where('notif_type','<=',end+"~")
        .get()
        .then(info=>{
            let tempArr:any=[]
            if(info.size>0){
                info.forEach(data=>{
                    let item:any = data.data()
                    item.id = data.id
                    tempArr.push(item)
                })
            }
            return tempArr
        })
        .catch(err=>{
            console.error("failed to get data",err,start,end)
        })
    }

    openDialog(){
        let itemData:any = {}
        if(this.currentUser.settings){
            itemData.settings = this.currentUser.settings
        }else{
            itemData.settings = {
                // all
                regulations:{
                    creation: false, //all
                    review: false, // admin / agencymemb / arta
                },
                consultations:{
                    creation: false, //all
                    review: false, // admin / agencymemb / arta
                },
                citizenscharters:{
                    creation: false, //all
                    review: false, // admin / agencymemb / arta
                },
                services:{
                    creation: false, //all
                    review: false, // admin / agencymemb / arta
                },
        
                // admin / arta
                agency: {
                    complaints: false,
                    creation: false,
                    update: false,
                },
        
                //admin
                accounts: {
                    creation: false,
                }
        
            }
        }
        itemData.id = this.currentUserId
        itemData.isAdmin = this.isAdmin
        itemData.isAgencyMember = this.isAgencyMember
        itemData.usersAgency = this.usersAgency
        itemData.isArta = (this.userType) == 'arta' ? true : false;
        itemData.email = this.currentUser.credentials.email

        const dialogRef = this.dialog.open(PbrisAccountSettingComponent,
            {data: itemData})
        
        dialogRef.afterClosed().subscribe((result:any)=>{
            if(result.success){
                this.currentUser.settings = result.settings;
                
                this.checkSubs();
            }
        })
    }
    
    toSendEmail(){
        console.log('sending email')
        const data = { }
        // this.nE.sendEmailNotiftoUser(['emailtest'],1,500,data)
        // .subscribe({
        //     next:(apiResponse)=>{
        //         console.log('PbrisAccountComponent emailer: ',apiResponse)
        //     },
        //     error:(err)=>{
        //         console.error('PbrisAccountComponent emailer error: ',err)
        //     }
        // })
    }
}