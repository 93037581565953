<app-navbar [currentPage]="'Management'"></app-navbar>

<!--Page Title -->
<div class="row" style="background-color:#f8f6ed;">
  <nav aria-label="breadcrumb" class="col-6 py-2">
    <ol class="breadcrumb my-1 ms-4">
        <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page" *ngIf="currentFormPage == 'preface'">CREATE A PROPOSED REGULATIONS</li>
        <li class="breadcrumb-item active fw-bold" style="color:#595959;font-size:12px" aria-current="page" *ngIf="currentFormPage != 'preface'">CREATE A PROPOSED REGULATIONS<i class="fas fa-chevron-right mx-2"></i>PRE - RULE STAGE</li>
    </ol>
  </nav>
  <div class="col-6">
    <button class="my-2 me-4 btn btn-dark btn-sm float-end" (click)="toCancel()">Return To Regulatory Stock</button>
  </div>
</div>

<div class="content padded">
  <ng-container *ngIf="checkPageLoader(); else notYetLoaded">
    <div class="container-fluid">

      <div class="card-text mt-2" *ngIf="currentFormPage == 'preface'">
        <p>Pursuant to Section 5 of RA 11032, all proposed regulations of government agencies under Section 3 of the same Act shall undergo regulatory 
          impact assessment to establish if the proposed regulation does not add undue regulatory burden and cost to these agencies and the applicants or requesting parties.
        </p>
        <p>To facilitate and implement the RIA process, rules on exemptions and proportionality, the rulemaking process will follow four 
          stages and one optional procedure.
        </p>
      </div>

      <div class="card my-4" *ngIf="isPhase1" style="border-top:1px solid #D9D9D9;">
        <div class="card-body form-container">

          <!-- Stage & Desciption -->
          <form *ngIf="currentFormPage == 'preface'">
  
            <div class="card-header card-header-custom">
              <div class="row">
                <div class="col-md-2 text-center">
                  <h5><b>STAGE</b></h5>
                </div>
                <div class="col-md-10 text-center">
                  <h5><b>DESCRIPTION</b></h5>
                </div>
              </div>
            </div>
  
            <div class="card-body rights-container">
              <div class="row alt-white">
                <div class="col-md-2"><b>PRE-RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    Government agencies must notify ARTA of every formulation,
                    modification, or repeal of regulations with its
                    corresponding rationale, objectives, expected impacts, and
                    targets dates through the Annual Regulatory Plan or the
                    submission of the Regulatory Notification Form.
                  </p>
                  <p>
                    The regulatory agency intends to draft a regulation and is
                    determining viable non-regulatory options as well as through
                    preliminary impact assessment. Should the assessment support
                    the implementation of a regulation, the agency submits its
                    preliminary impact statement (PIS) to ARTA.
                  </p>
                </div>
              </div>
  
              <div class="row alt-gray">
                <div class="col-md-2">ARTA Preliminary Review</div>
                <div class="col-md-10">
                  <p>
                    ARTA is reviewing the PIS submitted by the agency and
                    evaluating whether it has adequately explained and justified
                    the proposed regulation. If ARTA deems the proposed regulation
                    to have a significant impact on the economy, businesses,
                    individuals or households, or the environment, ARTA will
                    require the regulatory agency to conduct a full RIA process of
                    the proposed regulation (Proposed Rule Stage). Otherwise, the
                    regulation proceeds to the Interim Rule Stage.
                  </p>
                </div>
              </div>
  
              <div class="row alt-white">
                <div class="col-md-2"><b>PROPOSED RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    The regulation has been assessed as major and is undergoing
                    regulatory impact assessment. The drafted regulatory impact
                    statement (RIS) shall be submitted to the PBRIS for public
                    consultation. Upon completion of the consultation, the
                    regulatory agency finalizes the RIS and submits it to ARTA.
                  </p>
                </div>
              </div>
  
              <div class="row alt-gray">
                <div class="col-md-2">ARTA Proposed Rule Review</div>
                <div class="col-md-10">
                  <p>
                    The proposed regulation and its regulatory impact statement
                    has been submitted to ARTA and is undergoing proposed rule
                    review. ARTA may endorse or return a RIS based on its quality.
                    Regardless of the assessment, all regulations will proceed to
                    the Interim Rule Stage.
                  </p>
                </div>
              </div>
  
              <div class="row alt-white">
                <div class="col-md-2"><b>INTERIM RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    The proposed regulation and its impact statement has been
                    assessed by ARTA and is returned to the agency. The
                    regulatory agency shall inform ARTA of its next course of
                    action:
                  </p>
                  <ul>
                    <li>
                      <b>For an Endorsed RIS:</b> Regulatory agencies are
                      required to submit the implementation form which shall
                      detail dates of promulgation, filing with ONAR,
                      publication in the Official Gazette/Newspaper of General
                      Circulation, and the effectivity of the regulation.
                    </li>
                    <li>
                      <b>For a Returned RIA:</b> RIA will act as a trigger for
                      the proponent agency to revise and improve the conducted
                      RIA and drafted RIS. The agency should consider ARTA's
                      findings and recommendation and should re-submit the
                      revised RIS for ARTA review and endorsement.
                    </li>
                    <li>
                      <b>For minor regulations:</b> a Monitoring & Evaluation
                      Plan must be submitted to ARTA with the Notification Form.
                    </li>
                  </ul>
                </div>
              </div>
  
              <div class="row alt-gray">
                <div class="col-md-2"><b>FINAL RULE STAGE</b></div>
                <div class="col-md-10">
                  <p>
                    The proposed regulation and its regulatory impact statement is
                    for promulgation, publishing and implementation.
                  </p>
                </div>
              </div>
  
              <div class="row alt-white">
                <div class="col-md-2"><b>SUBMISSION TO CONGRESS*</b></div>
                <div class="col-md-10">
                  <p>
                    ARTA, if it deems necessary, may forward the results of the
                    RIA undertaken by the Agency and the results of the ARTA
                    review to Congress for appropriate action.
                  </p>
                </div>
              </div>
            </div>
            
            <div class="card-footer text-center mt-2">
              <button (click)="toCancel()" class="me-2 btn btn-dark">Cancel</button>
              <button class="btn btn-primary btn-primary" (click)="gotoForm('regulationdetails')">Proceed <i class="fas fa-arrow-circle-right ms-1"></i></button>
            </div>

          </form>

          <!-- REGULATORY NOTIFICATION FORM (RNF) -->
          <form [formGroup]="proposedregulationform" *ngIf="currentFormPage == 'regulationdetails'">

            <div class="card-header card-header-custom mt-2 mb-4">
              <div class="row">
                <div class="ms-3">
                  <h6 class="fw-bold">REGULATORY NOTIFICATION FORM (RNF)</h6>
                </div>
              </div>
            </div>

              <div class="card-text">
                <p>All government agencies (national government agencies and offices, GOCCs, government instrumentalities, and all LGUs) covered under
                  Section 3 of the EODB Act must notify ARTA of every formulation, modification, or repeal of regulations with its corresponding
                  rationale, objectives, expected impacts, and target dates.
                </p>
                <p>For this purpose, it is recommended that all agencies should submit to ARTA an Annual Regulatory Plan as an advance
                  notification. For instances wherein an agency intends to formulate/modify a regulation not included in the Annual
                  Regulatory Plan, it should submit an RNF.
                </p>
              </div>
    
              <nav aria-label="breadcrumb" class="breadcrumb-custom my-4">
                <ol class="breadcrumb p-2">
                  <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">REGULATORY PLANNING</li>
                </ol>
              </nav>

              <div>
                <div class="form-group">
                  <mat-label class="form-input-header" for="title">Is your regulation included/identified in your Agency's Annual Regulatory Plan? <span class="asterisk">*</span></mat-label>
                  <div class="my-4">
                    <div class="form-check form-check-inline">
                      <input disabled class="form-check-input" type="radio" name="part_of_reg_plan" id="inlineRadio1" />
                      <!--
                        return these attributes when reenabled
                        formControlName="part_of_reg_plan"
                        [value]="true"
                      -->
                      <label class="form-check-label" for="inlineRadio1">Yes</label>
                    </div>
    
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="part_of_reg_plan" id="inlineRadio2" formControlName="part_of_reg_plan" [value]="false" />
                      <label class="form-check-label" for="inlineRadio2">No</label>
                    </div>
                  </div>
                </div>
              </div>
    
              <div *ngIf="proposedregulationform.controls.part_of_reg_plan.value === true">
                <!-- TODO: Collection for plan, to bo done soon -->
                Coming soon!
              </div>
    
              <!-- REGULATION DETAILS -->
              <div *ngIf="proposedregulationform.controls.part_of_reg_plan.value === false">
    
                <nav aria-label="breadcrumb" class="breadcrumb-custom my-4">
                  <ol class="breadcrumb p-2">
                    <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">REGULATION DETAILS</li>
                  </ol>
                </nav>
                
                <!-- Title -->
                <div class="row g-3 mt-2">
                  <mat-form-field appearance="outline" class="form-group col-md-6">
                    <mat-label class="form-input-header" for="title">Regulation Title <span class="asterisk">*</span></mat-label>
                      <textarea matInput placeholder="Input" formControlName="title"></textarea>

                    <mat-hint class="my-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'title')">Required</mat-hint>
                    <ng-container *ngFor="let item of checkProposedRegulationForm.title">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'title',item.type)">Required</mat-error>
                    </ng-container>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="form-group col-md-6">
                    <mat-label class="form-input-header" for="subject">Regulation Short Title <span class="asterisk">*</span></mat-label>
                      <textarea matInput placeholder="Input" formControlName="subject"></textarea>

                    <mat-hint class="my-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'subject')">Required</mat-hint>
                    <ng-container *ngFor="let item of checkProposedRegulationForm.subject">
                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'subject',item.type)">Required</mat-error>
                    </ng-container>
                  </mat-form-field>
                </div>

                <!-- Regulation Instrument & Issuing Agency -->
                <div class="row g-3 mt-4">
                  <div class="col-md-6">
                    <label class="form-input-header" for="subject">Regulation Instrument <span class="asterisk">*</span></label>
                    <select class="form-control" formControlName="reg_instrument" (change)="instrumentOnChange($event)">
                      <option value="" disabled selected>--</option>
                      <option *ngFor="let x of instruments" [value]="x['id']">
                        {{ x["name"] }}
                      </option>
                    </select>
                    <mat-hint style="color:rgb(45, 108, 233);font-size:12px;">Required</mat-hint>
                    <!--<mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(proposedregulationform, 'reg_instrument')">Input Required</mat-hint>
                    <ng-container *ngFor="let item of checkProposedRegulationForm.reg_instrument">
                        <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'reg_instrument',item.type)">* {{item.message}}</mat-error>
                    </ng-container>-->
                  </div>

                  <div class="col-md-6">
                    <label class="form-input-header" for="subject">Issuing Agency <span class="asterisk">*</span></label>
                    <mat-select class="form-control" *ngIf="!multipleIssuingAgency" formControlName="reg_agency">
                      <mat-option *ngFor="let x of agencies" [value]="x['id']">
                        {{ x["name"] }}
                      </mat-option>
                    </mat-select>
                    <mat-select class="form-control" *ngIf="multipleIssuingAgency" formControlName="reg_agency" multiple>
                      <mat-option *ngFor="let x of agencies" [value]="x['id']">
                        {{ x["name"] }}
                      </mat-option>
                    </mat-select>
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_agency')">Required</mat-hint>
                    <!--<ng-container *ngFor="let item of checkProposedRegulationForm.reg_agency">
                        <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'reg_agency',item.type)">* {{item.message}}</mat-error>
                    </ng-container>-->
                  </div>
                </div>

                <!-- Regulatory Action -->
                <div class="row g-3 mt-4">
                  <div class="form-group">
                    <mat-label class="form-input-header" for="title">Please classify your regulatory action <span class="asterisk">*</span></mat-label>
                    <div class="mt-3" formGroupName="reg_action_classification">
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" id="reg_action_new" formControlName="reg_action_new" value="New Regulation"/>
                        <label class="form-check-label" for="reg_action_new">New Regulation</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" id="reg_action_modification" formControlName="reg_action_modification" value="Modification of Existing"/>
                        <label class="form-check-label" for="reg_action_modification">Modification of Existing</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" id="reg_action_repeal"  formControlName="reg_action_repeal" value="Modification of Existing"/>
                        <label class="form-check-label" for="reg_action_repeal">Repeal of Existing</label>
                      </div>
                    </div>
                    <mat-hint class="my-1 mb-4" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_action_classification')">Required</mat-hint>
                    <ng-container *ngFor="let item of checkProposedRegulationForm.reg_action_classification">
                        <mat-error class="mt-1 mb-4" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_action_classification',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                  </div>
                </div>
                
                <div class="row g-3 mt-4" *ngIf="reg_action_classification_val.controls.reg_action_modification.value === true">
                  <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label class="form-input-header">Regulation to Amend</mat-label>
                    <!-- Multiple select for regulations is a placeholder -->
                    <!-- TODO: This must be properly reworked to hand the sheer number of regulations -->
                    <mat-select placeholder="Select" multiple>
                      <mat-option value="">--</mat-option>
                    </mat-select>
                  </mat-form-field>
                  

                  <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label class="form-input-header">Section to Amend</mat-label>
                    <!-- Multiple select for regulations is a placeholder -->
                    <!-- TODO: This must be properly reworked to hand the sheer number of regulations -->
                    <mat-select placeholder="Select" multiple>
                      <mat-option value="">--</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
      
                <div class="row g-3 mt-4">
                  <mat-form-field appearance="outline" class="col-md-6" style="border:#000;" *ngIf=" reg_action_classification_val.controls.reg_action_repeal.value === true">
                    <mat-label class="form-input-header">Regulation to Repeal</mat-label>
                    <!-- Multiple select for regulations is a placeholder -->
                    <!-- TODO: This must be properly reworked to hand the sheer number of regulations -->
                    <mat-select placeholder="Select" multiple>
                      <mat-option value="">--</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
    
                <!-- Stakeholder Consultation and Comments -->
                <div class="row mt-4 mb-5">
                  <div class="form-group">
                    <mat-label class="form-input-header" for="title">Has this been subjected to stakeholder consultation and comments? <span class="asterisk">*</span></mat-label>
                    <div class="mt-3">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="has_consultation_comments" id="inlineRadioCons1" formControlName="has_consultation_comments" [value]="true"/>
                        <label class="form-check-label" for="inlineRadioCons1">Yes</label>
                      </div>
      
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="has_consultation_comments" id="inlineRadioCons2" formControlName="has_consultation_comments" [value]="false"/>
                        <label class="form-check-label" for="inlineRadioCons2">No</label>
                      </div>
                    </div>
                    
                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'has_consultation_comments')">Required</mat-hint>
                    <ng-container *ngFor="let item of checkProposedRegulationForm.has_consultation_comments">
                        <mat-error class="mt-1" style="color:red;font-size:10px;" *ngIf="hasErrors(proposedregulationform, 'has_consultation_comments',item.type)">* {{item.message}}</mat-error>
                    </ng-container> 
                  </div>
                </div>
    
                <div class="row">
                  <div class="mt-5" *ngIf="proposedregulationform.invalid">
                    <p class="float-end fst-italic fw-bold">
                      Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                    </p>
                  </div>
                </div>
              </div>
    
              <div class="row g-3">
                <div class="card-footer text-center col-md-6 mt-2">
                  <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                  <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                </div>
                <div class="card-footer text-center col-md-6 mt-2">
                  <button class="btn btn-danger float-end" (click)="gotoForm('pia')">Proceed to PIA <i class="fas fa-arrow-circle-right ms-1"></i></button>
                </div>
              </div>

          </form>

          <!-- Stepper -->
          <div *ngIf="currentFormPage == 'pia'">
            <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper" role="tab" aria-selected="true">

              <!-- Page 1: PIA -->
              <mat-step [stepControl]="PIAFormGroup">
                <form [formGroup]="proposedregulationform">
                  <ng-template matStepLabel>PIA</ng-template>

                  <div class="row">
                    <div *ngIf="proposedregulationform.invalid">
                      <p class="float-start fw-bold">
                        Complete all fields below. Asterisks (<span class="asterisk">*</span>) indicate required fields.
                      </p>
                    </div>
                  </div>

                  <div class="card-header card-header-custom mt-2 mb-4">
                    <div class="row">
                      <div class="ms-3">
                        <h6 class="fw-bold">PRELIMINARY IMPACT ASSESSMENT (PIA)</h6>
                      </div>
                    </div>
                  </div>

                  <div class="card-text">
                    <p>
                      Following the Annual Regulatory Plan and RNF, the agency should
                      conduct a Preliminary Impact Assessment (PIA) as it formulates its
                      regulation. The resulting PIS must substantiate the identified
                      impacts of government intervention and provide an analysis of the
                      policy options considered by the agency in the regulation they are
                      drafting.
                    </p>
                    <p>
                      The PIS and a copy of the initial draft of the proposed regulation
                      (if available) must be submitted to ARTA. This information will
                      enable the ARTA to make a sound assessment of the regulatory
                      proposal vis-à-vis the rules on exemptions and proportionality.
                    </p>
                  </div>
        
                  <!-- Summary of the regulatory proposal-->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SUMMARY OF THE REGULATORY PROPOSAL <span class="asterisk">*</span></li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container">
                      <mat-label class="form-input-header fw-bold" for="title">Briefly describe the proposal in a clear and concise statement:</mat-label>

                      <ul>
                        <li>Explain the policy problem and the objectives of any government action.</li>
                        <li>Identify alternative options considered to address the policy problem and summarize their impacts.</li>
                        <li>Provide a justification for the recommended option and rejection of other options.</li>
                      </ul>

                      <editor
                        [disabled]="commentMode"
                        formControlName="pia_summary"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                  
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                        }">
                      </editor>

                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform,'pia_summary')">Required</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.pia_summary">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform,'pia_summary',item.type)">* {{item.message}}</mat-error>
                      </ng-container>

                    </div>
                  </div>

                  <!-- Section 1 -->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SECTION 1 - IDENTIFICATION OF THE POLICY PROBLEM <span class="asterisk">*</span></li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container" formGroupName="pia_section_1">
                      <mat-label class="form-input-header fw-bold" for="title">Briefly explain the problem:</mat-label>
                      <ul>
                        <li>Provide a clear and concise problem statement.</li>
                        <li>Show evidence of the magnitude or seriousness of the problem and/or number of affected stakeholders.</li>
                        <li>Explain how current regulation fails to address the policy problem.</li>
                      </ul>

                      <editor
                        [disabled]="commentMode"
                        formControlName="policy_problem_desc"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                  
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                          alignleft aligncenter alignright alignjustify | \
                                          bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>

                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform,'policy_problem_desc','pia_section_1')">Required</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.policy_problem_desc">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform,'policy_problem_desc',item.type,'pia_section_1')">* {{item.message}}</mat-error>
                      </ng-container> 
        
                      <!-- upload files goes here -->
                      <div class="form-group">
                        <label class="form-input-header fw-bold mt-3" for="title">You may upload supporting documents as annexes
                          <span style="color:rgb(45, 108, 233);font-size:12px;"><a [routerLink]="[]" (click)="resetNewUpload('policyProblem')"><i class="fas fa-redo mx-1"></i>Reset Upload</a></span>
                        </label>
                        
                        <div class="form-group file-uploader-wrapper">
                          <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'policyProblem')">
                            <button type="button" class="btn btn-link dropzone-btn" (click)="policyProblem.click()">
                              <div *ngIf="display_policy_problem_file_name">
                                <h6><strong>{{ display_policy_problem_file_name }}</strong></h6>
                              </div>
                              <div *ngFor="let formValid of checkUploadedDocs.all">
                                <div class="error fw-bold" style="color:red;font-size:12px;" *ngIf="hasErrors(uploadsForm,'policyProblem', formValid.type == 'maxSize' ? 'max' : formValid.type, 'uploadsForm', formValid.type == 'maxSize' ? true : false)">
                                  {{formValid.message}}  
                                </div>
                              </div>
                              <span>Drop file here to upload or click to choose file</span>
                              <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                            </button>
                            <input #policyProblem id="policyProblem"  type="file" multiple (change)="buttonUpload($event,'policyProblem')"/>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
        
                  <!-- Section 2 -->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SECTION 2 - OBJECTIVES OF GOVERNMENT ACTION <span class="asterisk">*</span></li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container">
                      <label class="form-input-header fw-bold" for="title">Briefly identify clear and specific policy objectives of government action:</label>
                      <ul>
                        <li>State the objectives of the proposal in terms of what is to be achieved.</li>
                        <li>The objectives should be SMART (specific, measurable, accountable, realistic, time-bound)</li>
                      </ul>

                      <editor
                        [disabled]="commentMode"
                        formControlName="pia_section_2"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                  
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                          alignleft aligncenter alignright alignjustify | \
                                          bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>

                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform,'pia_section_2')">Required</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_2">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform,'pia_section_2',item.type)">* {{item.message}}</mat-error>
                      </ng-container> 
                    </div>
                  </div>
        
                  <!-- Section 3 -->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SECTION 3 - CONSIDERATION OF ALTERNATIVE OPTIONS</li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container">
                      <mat-label class="form-input-header fw-bold" for="title">Briefly identify alternative options to address the policy problem:</mat-label>
                      <ul>
                        <li>the options that are being considered (including no action) to achieve the objective</li>
                        <li>identify any options which are limited or constrained (e.g. due to election commitments, other regulations)</li>
                      </ul>
        
                      <mat-list>
                        <mat-list-item *ngFor="let x of pia_section_3_and_4_val.controls; let $i = index">
                          <div class="d-flex flex-row p-2 me-2" mat-line [class.bg-warning]="$i === active_pia_section_3_and_4_index" (click)="loadPIASectionInformation(3, x, $i)"
                            [ngClass]="x.value.policy_option? 'color-black' : 'color-red'" style="background-color:#F2F2F2;border-bottom:1px solid #D9D9D9;">{{ x.value.policy_option ? x.value.policy_option : '* Policy Option Title is required' }}
                            <mat-error style="margin-left:1em" *ngIf="!x.valid" class="color-red">* Invalid Policy entry</mat-error>
                          </div>

                          <div class="d-flex justify-content-end">
                            <button *ngIf="!commentMode" mat-stroked-button class="btn btn-danger" (click)="removePIASectionEntry(3, $i)">
                              <span class="material-icons">remove_circle</span>
                            </button>
                          </div>
                        </mat-list-item>
                      </mat-list>
        
                      <div class="text-center mt-4 mb-5">
                        <button *ngIf="!commentMode" mat-stroked-button (click)="addPIASectionEntry(3)" class="btn btn-warning committee">
                          <span class="material-icons me-1">add_circle</span>Add Another Policy Option
                        </button>
                      </div>
        
                      <div *ngIf="active_pia_section_3_and_4_form" [formGroup]="active_pia_section_3_and_4_form">
                        <!-- Policy Title & Typee -->
                        <div class="row g-3 mt-2">
                          <nav aria-label="breadcrumb" class="my-1">
                            <ol class="breadcrumb breadcrumb-custom p-2 mb-0">
                              <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">I. POLICY OPTION DETAILS</li>
                            </ol>
                          </nav>

                          <mat-form-field appearance="outline" class="form-group col-md-6">
                            <mat-label class="form-input-header" for="policy_option">Policy Option Title <span class="asterisk">*</span></mat-label>
                            <textarea matInput placeholder="Input" formControlName="policy_option"></textarea>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'policy_option')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_3.policy_option">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'policy_option',item.type)">Required</mat-error>
                            </ng-container>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="form-group col-md-6">
                            <mat-label class="form-input-header">Policy Option Type <span class="asterisk">*</span></mat-label>
                            <mat-select placeholder="Select" formControlName="policy_option_type">
                              <mat-option value="Do Nothing/Status Quo" selected> Do Nothing/Status Quo</mat-option>
                              <mat-option value="Providing Information"> Providing Information</mat-option>
                              <mat-option value="Incentives/Subsidies">Incentives/Subsidies</mat-option>
                              <mat-option value="Self-Regulation">Self-Regulation</mat-option>
                              <mat-option value="Co-Regulation">Co-Regulation</mat-option>
                              <mat-option value="Command & Control">Command & Control</mat-option>
                            </mat-select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'policy_option_type')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_3.policy_option_type">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'policy_option_type',item.type)">Required</mat-error>
                            </ng-container>
                          </mat-form-field>

                        </div>
        
                        <div class="row g-3 mt-1">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="policy_option_desc">Policy Option Description <span class="asterisk">*</span></mat-label>
                              <textarea matInput placeholder="Input" type="text" formControlName="policy_option_desc"></textarea>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'policy_option_desc')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_3.policy_option_desc">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'policy_option_desc',item.type)">Required</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>
                  </div>
        
                  <!-- Section 4 -->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SECTION 4 - IMPACT ANALYSIS OF POLICY OPTIONS <span class="asterisk">*</span></li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container">
                      <label class="form-input-header fw-bold" for="title">Undertake an impact analysis of the different options and state the following:</label>
                      <ul>
                        <li>a brief and concise description of benefits and costs of each option</li>
                        <li>information on economic, social, or environmental impacts on stakeholders (identify who these are)</li>
                        <li>cost of compliance by affected stakeholders, e.g. firms</li>
                      </ul>
        
                      <mat-list>
                        <mat-list-item *ngFor="let x of pia_section_3_and_4_val.controls; let $i = index">
                          <div class="d-flex flex-row p-2" mat-line [class.bg-warning]="$i === active_pia_section_3_and_4_index" (click)="loadPIASectionInformation(3, x, $i)" 
                            [ngClass]="x.value.policy_option? 'color-black' : 'color-red'" style="background-color:#F2F2F2;border-bottom:1px solid #D9D9D9;">{{ x.value.policy_option ? x.value.policy_option : '* Policy Option Title is required' }}
                            <mat-error style="margin-left:1em" *ngIf="!x.valid" class="color-red">* Invalid Policy entry</mat-error>
                          </div>
                        </mat-list-item>
                      </mat-list>
        
                      <div *ngIf="active_pia_section_3_and_4_form" [formGroup]="active_pia_section_3_and_4_form">
                        <!-- <div class="row g-3 mt-2">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="policy_option">Policy Option Title <span class="asterisk">*</span></mat-label>
                            <textarea matInput placeholder="Input" formControlName="policy_option"></textarea>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'policy_option')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_4.policy_option">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'policy_option',item.type)">Required</mat-error>
                            </ng-container> 
                          </mat-form-field>
                        </div> -->
        
                        <!-- Impacts -->
                        <div class="row g-3 mt-2">
                          <mat-label class="form-input-header col-md-12" for="title">Impacts</mat-label>
                          <div formGroupName="impacts">
                            <div class="form-check form-check-inline">
                              <input type="checkbox" class="form-check-input" id="impact_economic" formControlName="impact_economic" value="Economic"/>
                              <label class="form-check-label" for="impact_economic">Economic</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input type="checkbox" class="form-check-input" id="impact_social" formControlName="impact_social" value="Social"/>
                              <label class="form-check-label" for="impact_social">Social</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input type="checkbox" class="form-check-input" id="impact_disaster_risk" formControlName="impact_disaster_risk" value="Disaster Risk"/>
                              <label class="form-check-label" for="impact_disaster_risk">Disaster Risk</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input type="checkbox" class="form-check-input" id="impact_environment" formControlName="impact_environment" value="Environment"/>
                              <label class="form-check-label" for="impact_environment">Environment</label>
                            </div>
                          </div>

                          <div class="mt-0">
                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'impacts')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_4.impacts">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'impacts',item.type)">* {{item.message}}</mat-error>
                            </ng-container>
                          </div> 
                        </div>

                        <!-- Narrative of benefits -->
                        <div class="row g-3 mt-0">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="benefits_desc">Narrative of Benefits <span class="asterisk">*</span></mat-label>
                            <textarea matInput placeholder="Input" type="text" formControlName="benefits_desc"></textarea>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'benefits_desc')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_4.benefits_desc">
                              <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'benefits_desc',item.type)">Required</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>

                        <!-- Narrative of costs -->
                        <div class="row g-3 mt-0">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="costs_desc">Narrative of Costs <span class="asterisk">*</span></mat-label>
                            <textarea matInput placeholder="Input" type="text" formControlName="costs_desc"></textarea>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'costs_desc')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_4.costs_desc">
                              <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'costs_desc',item.type)">Required</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>

                        <!-- Narrative of Impacts -->
                        <div class="row g-3 mt-0">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="impacts_desc">Narrative of Impacts <span class="asterisk">*</span></mat-label>
                            <textarea matInput placeholder="Input" type="text" formControlName="impacts_desc"></textarea>
  
                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_3_and_4_form,'impacts_desc')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_4.impacts_desc">
                              <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_3_and_4_form,'impacts_desc',item.type)">Required</mat-error>
                            </ng-container>
                          </mat-form-field>
                        </div>
                        
                      </div>
        
                      <!-- Moved Cost Compliance file upload up from row-level to section-level -->
                      <div class="row g-3 mt-2">
                        <!-- upload files goes here -->
                        <div class="form-group col-md-12">
                          <label class="form-input-header fw-bold" for="title">Upload Cost Compliance Related Documents
                            <span style="color:rgb(45, 108, 233);font-size:12px;"><a [routerLink]="[]" (click)="resetNewUpload('costCompliance')"><i class="fas fa-redo me-1"></i>Reset Upload</a></span>
                          </label>
                          <!-- <input
                            type="file"
                            (change)="saveFileInformation('costCompliance', $event)"
                            class="form-control"
                            ng-required="true"
                            style="display: none"
                            #cost_compliance_file_input
                          />
                          <div class="file-input p-3">
                            <label>Drop files here or</label>
                            <button
                              (click)="cost_compliance_file_input.click()"
                              class="btn btn-light"
                            >
                              Browse
                            </button>
                          </div>
                          {{ display_cost_compliance_file_name }} -->
                          <div class="form-group file-uploader-wrapper">
                            <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'costCompliance')">
                              <button type="button" class="btn btn-link dropzone-btn" (click)="costCompliance.click()">
                                <div *ngIf="display_cost_compliance_file_name">
                                  <h6><strong>{{ display_cost_compliance_file_name }}</strong></h6>
                                </div>
                                <div *ngFor="let formValid of checkUploadedDocs.all">
                                  <div class="error fw-bold" style="color:red;font-size:12px;" *ngIf="hasErrors(uploadsForm,'costCompliance', formValid.type == 'maxSize' ? 'max' : formValid.type, 'uploadsForm', formValid.type == 'maxSize' ? true : false)">
                                    {{formValid.message}}  
                                  </div>
                                </div>
                                <span>Drop file here to upload or click to choose file</span>
                                <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                              </button>
                              <input #costCompliance id="costCompliance"  type="file" multiple (change)="buttonUpload($event,'costCompliance')"/>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="form-helper text-danger"
                          *ngIf="showCostComplianceFileUploadErrors"
                        >
                          Invalid file type
                        </div> -->
                        <!-- <div class="form-group">
                          <label for="firstname"
                            >Valid file types include: docx, pdf, pptx, xlsx. Each
                            file cannot exceed 10MB.</label
                          >
                        </div> -->
                      </div>

                    </div>
                  </div>
        
                  <!-- Section 5 -->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SECTION 5 - CONSULTATION <span class="asterisk">*</span></li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container">
                      <label class="form-input-header fw-bold" for="title">Provide information on the following:</label>
                      <ul>
                        <li>List of stakeholders consulted, categorized according to gender, age and income class (for individuals), and size of firms (micro, small, medium, and large)</li>
                        <li>Issues and concerns raised by stakeholders</li>
                        <li>Action taken by Agency on issues and concerns</li>
                      </ul>
        
                      <mat-list>
                        <mat-list-item *ngFor="let x of pia_section_5_val.controls; let $i = index" >
                          <div class="d-flex flex-row p-2 me-2" mat-line [class.bg-warning]="$i === active_pia_section_5_index" (click)="loadPIASectionInformation(5, x, $i)" [ngClass]="x.value.consultation_title ? 'color-black' : 'color-red' " style="background-color:#F2F2F2;border-bottom:1px solid #D9D9D9;">
                            <!-- {{ x.value.consultation_title }} -->
                            {{ x.value.consultation_title ? x.value.consultation_title : '* Consultation Title is required'}}
                            <mat-error style="margin-left:1em" *ngIf="!x.valid"class="color-red">* Invalid Consultation Entry</mat-error>
                          </div>

                          <div class="d-flex justify-content-end">
                            <button *ngIf="!commentMode" mat-stroked-button class="btn btn-danger" (click)="removePIASectionEntry(5, $i)">
                            <!-- class="btn btn-outline-dark m-1" -->
                              <span class="material-icons">remove_circle</span>
                            </button>
                          </div>
                        </mat-list-item>
                      </mat-list>
        
                      <div class="text-center">
                        <button *ngIf="!commentMode" mat-stroked-button (click)="addPIASectionEntry(5)" class="btn btn-warning committee mt-4 mb-5">
                          <span class="material-icons me-1">add_circle</span>Add Another Consultation
                        </button>
                      </div>
        
                      <div *ngIf="active_pia_section_5_form" [formGroup]="active_pia_section_5_form">
                        <!-- Consultation -->
                        <div class="row mt-2">
                          <nav aria-label="breadcrumb" class="my-1">
                            <ol class="breadcrumb breadcrumb-custom p-2 mb-0">
                              <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">I. CONSULTATION DETAILS</li>
                            </ol>
                          </nav>

                          <mat-form-field appearance="outline" class="form-group col-md-12 mt-3">
                            <mat-label class="form-input-header" for="consultation_title">Consultation Title <span class="asterisk">*</span></mat-label>
                            <textarea matInput placeholder="Input" formControlName="consultation_title"></textarea>

                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_5.consultation_title">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_5_form,'consultation_title',item.type)">* {{item.message}}</mat-error>
                            </ng-container> 
                          </mat-form-field>
                        </div>
        
                        <div class="row mt-1">
                          <mat-label class="form-input-header fw-bold" for="title">Has your agency conducted a consultation on this policy? <span class="asterisk">*</span></mat-label>
                          <div class="mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="has_agency_consulted" id="has_agency_consulted_1" formControlName="has_agency_consulted" [value]="true"/>
                              <label class="form-check-label" for="has_agency_consulted_1">Yes</label>
                            </div>
        
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="has_agency_consulted" id="has_agency_consulted_2" formControlName="has_agency_consulted" [value]="false"/>
                              <label class="form-check-label" for="has_agency_consulted_2">No</label>
                            </div>
                          </div>
                          
                          <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_5_form,'has_agency_consulted')">Required</mat-hint>
                          <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_5.has_agency_consulted">
                              <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_5_form,'has_agency_consulted',item.type)">* {{item.message}}</mat-error>
                          </ng-container> 
                        </div>
        
                        <div class="row mt-3">
                          <div class="col-md-5">
                            <mat-label class="form-input-header fw-bold" for="date_conducted">Date {{ active_pia_section_5_form?.get('has_agency_consulted')?.value ? '' : 'to be' }} Conducted <span class="asterisk">*</span></mat-label>
                            <input type="date" placeholder="mm/dd/yyyy" class="form-control mt-2" formControlName="date_conducted"/>

                            <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(active_pia_section_5_form,'date_conducted')">Consultation Method/s Required</mat-hint> -->
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_5.date_conducted">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_5_form,'date_conducted',item.type)">* {{item.message}}</mat-error>
                            </ng-container> 
                          </div>
        
                          <div class="col-7">
                            <label class="form-input-header fw-bold" for="title">Methods <span class="asterisk">*</span></label>
                            <div class="mt-2" formGroupName="methods">
                              <div class="form-check form-check-inline">
                                <input type="checkbox" class="form-check-input" id="method_public_commenting" formControlName="method_public_commenting" value="Public Commenting"/>
                                <label class="form-check-label" for="method_public_commenting">Public Commenting</label>
                              </div>

                              <div class="form-check form-check-inline">
                                <input type="checkbox" class="form-check-input" id="method_forum" formControlName="method_forum" value="Meeting/Forum"/>
                                <label class="form-check-label" for="method_forum">Meeting/Forum</label>
                              </div>

                              <div class="form-check form-check-inline">
                                <input type="checkbox" class="form-check-input" id="method_focus_group" formControlName="method_focus_group" value="Focus Group Discussion"/>
                                <label class="form-check-label" for="method_focus_group">Focus Group Discussion</label>
                              </div>

                              <div class="form-check form-check-inline">
                                <input type="checkbox" class="form-check-input" id="method_other" formControlName="method_other" value="Other"/>
                                <label class="form-check-label" for="method_other">Other</label>
                              </div>
                            </div>
                            
                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(active_pia_section_5_form,'methods')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.pia_section_5.methods">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(active_pia_section_5_form,'methods',item.type)">* {{item.message}}</mat-error>
                            </ng-container> 
                          </div>
                        </div>
        
                        <div class="row mt-2" *ngIf="active_pia_section_5_form.controls.methods.controls.method_other.value === true">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="method_other_desc">Other Method</mat-label>
                            <input matInput placeholder="Input" formControlName="method_other_desc" >
                          </mat-form-field>
                        </div>
        
                        <div class="row mt-2">
                          <mat-form-field appearance="outline" class="form-group col-md-12">
                            <mat-label class="form-input-header" for="consultation_desc">Narrative Summary of Consultation</mat-label>
                              <textarea matInput placeholder="Input" type="text" formControlName="consultation_desc"></textarea>
                          </mat-form-field>
                        </div>
        
                        <div class="row mt-2" *ngIf="active_pia_section_5_form.controls.has_agency_consulted.value === true">
                          <label class="form-input-header fw-bold" for="participants">Participants <span class="asterisk">*</span></label>

                          <!-- table goes here -->
                          <!-- Table might be reused due to tabular input *per consultation* -->
                          <table mat-table formArrayName="participants" [dataSource]="pia_section_5_datasource" class="bg-gray">
                            <ng-container matColumnDef="stakeholder">
                              <th mat-header-cell *matHeaderCellDef>Stakeholder</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <input class="form-control" formControlName="stakeholder" type="text"/>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="commenting_as">
                              <th mat-header-cell *matHeaderCellDef>Commenting As</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <select class="form-control" formControlName="commenting_as">
                                  <option value="Individual" selected>Individual</option>
                                  <option value="Representative">Representative</option>
                                </select>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="gender">
                              <th mat-header-cell *matHeaderCellDef>Gender</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <select class="form-control" formControlName="gender">
                                  <option value="Male" selected>Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="age">
                              <th mat-header-cell *matHeaderCellDef>Age</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <input type="text" class="form-control" formControlName="age"/>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="income_class">
                              <th mat-header-cell *matHeaderCellDef>Income Class</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <select class="form-control" formControlName="income_class">
                                  <option value="A" selected>A</option>
                                  <option value="B">B</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                </select>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="size_of_firm">
                              <th mat-header-cell *matHeaderCellDef>Size of Firm</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <input type="text" class="form-control" formControlName="size_of_firm"/>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="issues_and_concerns">
                              <th mat-header-cell *matHeaderCellDef>Issues and Concerns</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <input type="text" class="form-control" formControlName="issues_and_concerns"/>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="actions_taken">
                              <th mat-header-cell *matHeaderCellDef>Actions Taken</th>
                              <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <input type="text" class="form-control" formControlName="actions_taken"/>
                              </td>
                            </ng-container>
        
                            <ng-container matColumnDef="delete">
                              <th mat-header-cell *matHeaderCellDef></th>
                              <td mat-cell *matCellDef="let element; let $i = index">
                                <button *ngIf="!commentMode" mat-stroked-button class="btn btn-danger committee" (click)="removePIASection5Participant($i)">
                                <!-- class="btn committee" -->
                                  <span class="material-icons">remove_circle</span>
                                </button>
                              </td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="piaSection5DisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: piaSection5DisplayedColumns"></tr>
                          </table>
                        </div>
        
                        <div class="text-center my-4">
                          <button *ngIf="!commentMode && active_pia_section_5_form.controls.has_agency_consulted.value === true" mat-stroked-button (click)="addPIASection5Participant()" class="btn btn-warning committee">
                            <span class="material-icons me-1">add_circle</span>Add Participant
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
        
                  <!-- Section 6 -->
                  <div class="card my-5">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SECTION 6 - CONCLUSION AND RECOMMENDED OPTION <span class="asterisk">*</span></li>
                      </ol>
                    </nav>
        
                    <div class="card-body p-3 rights-container">
                      <label class="form-input-header" for="title">Undertake and impact analysis of the different options and state the following:</label>
                      <ul>
                        <li>Provide a summary of the impact analysis of the different policy options.</li>
                        <li>State the recommended option and explain briefly how it will confer the greatest net benefit for the stakeholders, and the economy as a whole.</li>
                        <li>Explain briefly how stakeholders will comply with the recommended option.</li>
                      </ul>
                      
                      <div formGroupName="pia_section_6">
                        <div class="form-inline mt-2">
                          <label class="form-input-header" for="recommended_policy_option">Recommended Policy Option</label>
                          <select class="form-control" formControlName="recommended_policy_option">
                            <option *ngFor="let x of pia_section_3_and_4_val.controls" [value]="x.value.policy_option">
                              {{ x.value.policy_option }}
                            </option>
                          </select>
                          <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(proposedregulationform,'recommended_policy_option','pia_section_6')">Input Required</mat-hint>
                          <ng-container *ngFor="let item of checkProposedRegulationForm.pia_sections_6.recommended_policy_option">
                              <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform,'recommended_policy_option',item.type,'pia_section_6')">* {{item.message}}</mat-error>
                          </ng-container>  -->
                        </div>
        
                        <div class="form-group mt-4">
                          <label class="form-input-header" for="impact_summary_desc">Summary of Impact Analysis and Justification for Recommended Policy Option</label>
                          <mat-form-field style="width: 100%" appearance="fill" hideRequiredMarker floatLabel="never">
                            <textarea matInput placeholder="" type="text" formControlName="impact_summary_desc" class="py-3"></textarea>
                          </mat-form-field>
                        </div>
        
                        <div class="form-group mt-2">
                          <label class="form-input-header" for="compliance_desc">Explanation for Implementation and Compliance of Stakeholders</label>
                          <mat-form-field style="width: 100%" appearance="fill" hideRequiredMarker floatLabel="never">
                            <textarea matInput placeholder="" type="text" formControlName="compliance_desc" class="py-3"></textarea>
                          </mat-form-field>
                        </div>
        
                        <!-- upload files goes here -->
                        <div class="form-group mt-2">
                          <label class="form-input-header fw-bold" for="title">
                            Upload Consultation and recommended option documents here:
                            <span><a [routerLink]="[]" (click)="resetNewUpload('consultationAndRecommendation')"><i class="fas fa-redo"></i></a></span>
                          </label>
                          <!-- <input
                            type="file"
                            (change)="saveFileInformation('consultationAndRecommendation', $event)"
                            class="form-control"
                            ng-required="true"
                            style="display: none"
                            #consultation_and_recommendation_file_input
                          />
                          <div class="file-input p-3">
                            <label>Drop files here or</label>
                            <button
                              (click)="consultation_and_recommendation_file_input.click()"
                              class="btn btn-light"
                            >
                              Browse
                            </button>
                          </div>
                          {{ display_consultation_and_recommendation_file_name }} -->
                          
                          <div class="form-group file-uploader-wrapper">
                            <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'consultationAndRecommendation')">
                              <button type="button" class="btn btn-link dropzone-btn" (click)="consultationAndRecommendation.click()">
                                <div *ngIf="display_consultation_and_recommendation_file_name">
                                  <h6><strong>{{ display_consultation_and_recommendation_file_name }}</strong></h6>
                                </div>
                                <div *ngFor="let formValid of checkUploadedDocs.all">
                                  <div class="error fw-bold" tyle="color:red;font-size:12px;" *ngIf="hasErrors(uploadsForm,'consultationAndRecommendation', formValid.type == 'maxSize' ? 'max' : formValid.type, 'uploadsForm', formValid.type == 'maxSize' ? true : false)">
                                    {{formValid.message}}  
                                  </div>
                                </div>
                                <span>Drop file here to upload or click to choose file</span>
                                <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                              </button>
                              <input #consultationAndRecommendation id="consultationAndRecommendation"  type="file" multiple (change)="buttonUpload($event,'consultationAndRecommendation')"/>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="form-helper text-danger"
                          *ngIf="showConsultationAndRecommendationFileUploadErrors"
                        >
                          Invalid file type
                        </div> -->
                        <!-- <div class="form-group">
                          <label for="consultation_and_recommendation_file_input"
                            >Valid file types include: docx, pdf, pptx, xlsx, zip. Each file
                            cannot exceed 10MB.</label
                          >
                        </div> -->
                      </div>

                    </div>
                  </div>

                  <!-- Comment -->
                  <div class="row" *ngIf="commentMode">
                    <div class="card col-md-12" style="margin:80px 0 0;">
                      <nav aria-label="breadcrumb" class="breadcrumb-custom theme-agency">
                        <ol class="breadcrumb p-2 mb-0">
                          <li class="breadcrumb-item active ms-3" for="vision" aria-current="page" style="color:#FFFFFF;">
                            <i class="fas fa-comments me-2"></i>Enter your comment for PIA:
                          </li>
                        </ol>
                      </nav>

                      <div class="card-body col-md-12 p-3 rights-container">
                        <mat-form-field appearance="fill" class="col-md-12">
                          <textarea matInput placeholder="write a comment" type="text" [formControl]="comment_pia"></textarea>
                        </mat-form-field>
                      </div>

                      <!-- Comment Footer Bottons -->
                      <div class="row g-3">
                        <div class="card-footer text-center col-md-12 mt-2">
                          <button class="me-2 btn btn-dark float-start" (click)="backToInbox()">Cancel</button>
                          <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="mt-5" *ngIf="proposedregulationform.invalid">
                      <p class="float-end fst-italic fw-bold">
                        Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                      </p>
                    </div>
                  </div>
                
                  <!-- Footer Bottons -->
                  <div class="row g-3" *ngIf="!commentMode">
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('regulationdetails')">Back</button>
                      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                    </div>
                  </div>
                    
                </form>
              </mat-step>
        
              <!-- Page 2: Tags -->
              <mat-step [stepControl]="TagsFormGroup">
                <form [formGroup]="proposedregulationform">
                  <ng-template matStepLabel>TAGS</ng-template>

                  <div class="row">
                    <div *ngIf="proposedregulationform.invalid">
                      <p class="float-start fw-bold">
                        Complete all fields below. Asterisks (<span class="asterisk">*</span>) indicate required fields.
                      </p>
                    </div>
                  </div>

                  <div class="card-header card-header-custom mt-2 mb-4">
                    <div class="row">
                      <div class="ms-3">
                        <h6 class="fw-bold">REVIEW OF TAGS</h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p>
                      Following the Annual Regulatory Plan and RNF, the agency should
                      conduct a Preliminary Impact Assessment (PIA) as it formulates its
                      regulation. The resulting PIS must substantiate the identified
                      impacts of government intervention and provide an analysis of the
                      policy options considered by the agency in the regulation they are
                      drafting.
                    </p>
                    <p>
                      The PIS and a copy of the initial draft of the proposed regulation
                      (if available) must be submitted to ARTA. This information will
                      enable the ARTA to make a sound assessment of the regulatory
                      proposal vis-à-vis the rules on exemptions and proportionality.
                    </p>
                  </div>

                  <nav aria-label="breadcrumb" class="breadcrumb-custom mt-4">
                    <ol class="breadcrumb p-2">
                      <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">MAIN TAG</li>
                    </ol>
                  </nav>
        
                  <div formGroupName="tags">
                    <div class="row g-3 mt-2">
                      <mat-label class="form-input-header">Classification <span class="asterisk">*</span></mat-label>
                      <div class="form-check mt-2">
                        <mat-radio-group formControlName="reg_classification" aria-label="Select an option">
                        <!-- (change)="classificationChange($event)" -->
                          <mat-radio-button checked value="Business" class="me-3">Business</mat-radio-button>
                          <mat-radio-button value="Non-Business">Non-Business</mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(proposedregulationform, 'tags')">Input Required</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.tags.tags">
                          <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'tags',item.type)">* {{item.message}}</mat-error>
                      </ng-container>  -->
                    </div>
          
                    <!-- Business -->
                    <div *ngIf="proposedregulationform?.get('tags')?.get('reg_classification')?.value == 'Business'">
                        <div class="row g-3 mt-2">
                          <div class="form-group col-md-6">
                            <label class="form-input-header" for="subject">Sector <span class="asterisk">*</span></label>
                            <select placeholder="Select" class="form-control" formControlName="reg_sector" (change)="sectorOnChange($event)">
                              <option *ngFor="let x of sectors" [value]="x['id']">{{ x["name"] }}</option>
                            </select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_sector','tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_sector">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_sector',item.type,'tags')">Required</mat-error>
                            </ng-container>
                          </div>
          
                          <div class="form-group col-md-6">
                            <label class="form-input-header" for="subject">Division <span class="asterisk">*</span></label>
                            <select class="form-control" placeholder="Select" formControlName="reg_division" (change)="selectOnChange1('reg_division')">
                              <option value="" disabled selected>--</option>
                              <option *ngFor="let x of divisions_select" [value]="x['id']">{{ x["name"] }}</option>
                            </select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_division','tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_division">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_division',item.type,'tags')">Required</mat-error>
                            </ng-container> 
                          </div>
                        </div>
          
                        <div class="row g-3 mt-2">
                          <div class="col-md-6">
                            <label class="form-input-header" for="subject">Stage of Business <span class="asterisk">*</span></label>
                            <select class="form-control" formControlName="reg_business" (change)="sobOnChange($event)">
                              <option value="" disabled selected>--</option>
                              <option *ngFor="let x of sobs" [value]="x['id']">{{ x["name"] }}</option>
                            </select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_business','tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_business">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_business',item.type,'tags')">Required</mat-error>
                            </ng-container> 
                          </div>
          
                          <div class="form-group col-md-6">
                            <label class="form-input-header" for="subject">Case Use <span class="asterisk">*</span></label>
                            <select class="form-control" placeholder="Select" formControlName="reg_case" (change)="selectOnChange1('reg_case')">
                              <option value="" disabled selected>--</option>
                              <option *ngFor="let x of cases_select" [value]="x['id']">{{ x["name"] }}</option>
                            </select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_case','tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_case">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_case',item.type,'tags')">Required</mat-error>
                            </ng-container> 
                          </div>
                        </div>
                    </div>

                    <!-- Non-Business -->
                    <div  *ngIf="proposedregulationform?.get('tags')?.get('reg_classification')?.value == 'Non-Business'">
                        <div class="row g-3 mt-2">
                          <div class="col-md-6">
                            <label class="form-input-header" for="subject">Stage of Life <span class="asterisk">*</span></label>
                            <select class="form-control" formControlName="reg_stageoflife" name="reg_stageoflife" id="reg_stageoflife" (change)="solOnChange($event)">
                              <option value="" disabled selected>--</option>
                              <option *ngFor="let x of sols" [value]="x['id']">{{ x["stage"] }}</option>
                            </select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_stageoflife','tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_stageoflife">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_stageoflife',item.type,'tags')">Required</mat-error>
                            </ng-container> 
                          </div>
          
                          <div class="form-group col-md-6">
                            <label class="form-input-header" for="subject">Life Event</label>
                            <select class="form-control" placeholder="Select" formControlName="reg_lifeevent" (change)="selectOnChange1('reg_lifeevent')">
                              <option value="" disabled selected>--</option>
                              <option *ngFor="let x of lifeevents_select" [value]="x['id']">{{ x["event"] }}</option>
                            </select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_lifeevent','tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_lifeevent">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_lifeevent',item.type,'tags')">Required</mat-error>
                            </ng-container> 
                          </div>
                        </div>
                    </div>
          
                    <!-- Jurisdiction -->
                    <div class="row g-3 mt-2">
                      <div class="form-group col-md-6">
                        <label class="form-input-header" for="subject" >Jurisdiction <span class="asterisk">*</span></label>
                        <select class="form-control" placeholder="Select" formControlName="reg_juris" (change)="selectOnChange1('reg_juris')">
                          <option *ngFor="let x of jurisdictions" [value]="x['id']">{{ x["name"] }}</option>
                        </select>

                        <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_juris','tags')">Required</mat-hint>
                        <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_juris">
                            <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_juris',item.type,'tags')">Required</mat-error>
                        </ng-container>
                      </div>
                    </div>

                    <!-- Legal Bases & International Agreements -->
                    <div class="row g-2 mt-3 mb-3">
                        <div class="form-group col-md-12">
                          <mat-label class="form-input-header" for="subject">Legal Bases <span class="asterisk">*</span></mat-label>
                          <!--<div>
                            <mat-hint class="mt-0" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_legal', 'tags')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_legal">
                                <mat-error class="mt-0" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_legal',item.type, 'tags')">* {{item.message}}</mat-error>
                            </ng-container>
                          </div>-->

                          <div class="mt-2 mb-4">
                            <button *ngIf="!commentMode" mat-stroked-button class="btn btn-warning" (click)="addLegalBase()">
                              <span class="material-icons me-2">add_circle</span>Add More
                            </button>
                          </div>
            
                          <div formArrayName="reg_legal">
                            <mat-list role="list">
                              <mat-list-item *ngFor="let text of reg_legal_val?.controls; let $i = index" role="listitem">
                                <div class="me-2">
                                  <mat-hint class="fw-bold ms-2" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!arrayIsTouched(text)">Required</mat-hint>
                                  <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_legal_item">
                                      <mat-error class="fw-bold ms-2" style="color:red;font-size:12px;" *ngIf="arrayHasErrors(text, item.type)">Required</mat-error>
                                  </ng-container>
                                </div>

                                <button *ngIf="!commentMode" mat-stroked-button mat-icon class="btn btn-danger" (click)="removeLegalBase($i)">
                                <!-- class="btn btn-outline-dark m-1" -->
                                  <span class="material-icons">remove_circle</span>
                                </button>

                                <mat-form-field appearance="fill" mat-line>
                                  <input matInput formControlName="{{ $i }}" [matAutocomplete]="auto_reg_legal" class="py-3">
                                  <mat-autocomplete #auto_reg_legal="matAutocomplete">
                                    <mat-option *ngFor="let option of legalbasesobs[$i] | async" [value]="option.name">{{ option.name }}</mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>

                              </mat-list-item>
                            </mat-list>
                          </div>
            
                        </div>
          
                        <!-- International Agreements -->
                        <div class="form-group col-md-12 mt-4">
                          <mat-label class="form-input-header" for="subject">International Agreements</mat-label>

                          <div class="mt-2 mb-4">
                            <button *ngIf="!commentMode" mat-stroked-button  class="btn btn-warning" (click)="addInternationalAgreement()">
                              <span class="material-icons me-2">add_circle</span>Add More
                            </button>
                          </div>

                          <div formArrayName="reg_intl">
                            <mat-list role="list">
                              <mat-list-item *ngFor="let text of reg_intl_val?.controls; let $i = index" role="listitem">
                                <button *ngIf="!commentMode" mat-stroked-button mat-icon class="btn btn-danger ms-2" (click)="removeInternationalAgreement($i)">
                                <!-- class="btn btn-outline-dark m-1" -->
                                  <span class="material-icons">remove_circle</span>
                                </button>

                                <mat-form-field appearance="fill" mat-line>
                                  <input matInput formControlName="{{ $i }}"  [matAutocomplete]="auto_reg_intl" class="py-3">
                                  <mat-autocomplete #auto_reg_intl="matAutocomplete">
                                    <mat-option *ngFor="let option of intlagreementsobs[$i] | async" [value]="option.name">{{ option.name }}</mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </mat-list-item>
                            </mat-list>
                          </div>

                        </div>
                    </div>
                      
                    <!-- Checkbox Repeals a Regulation -->
                    <div class="form-group my-3">
                        <div class="form-check fw-bold">
                          <input class="form-check-input" type="checkbox" id="regulation_repeal" formControlName="regulation_repeal"/>
                          <label for="regulation_repeal" class="form-check-label">Check if the regulations repeals a regulation</label>
                        </div>
          
                        <div class="row g-3 mt-2" *ngIf="this.proposedregulationform.get('tags')?.get('regulation_repeal')?.value === true">
                          <mat-label class="form-input-header">Regulation/s Repealed</mat-label>
                          <div formArrayName="reg_repeal">
                            <mat-list role="list">
                              <mat-list-item *ngFor="let obj of reg_repeal_val?.controls;let $i = index" role="listitem">

                                <button *ngIf="!commentMode" mat-stroked-button mat-icon class="btn btn-danger ms-1" (click)="removeRegulationRepealed($i)">
                                <!-- class="btn btn-outline-dark m-1" -->
                                  <span class="material-icons">remove_circle</span>
                                </button>

                                <mat-form-field mat-line>
                                  <input matInput formControlName="{{ $i }}" [matAutocomplete]="auto_reg_repeal">
                                  <mat-autocomplete #auto_reg_repeal="matAutocomplete" [displayWith]="displayReg">
                                    <mat-option *ngFor="let option of reg_repeal_obs[$i] | async" [value]="option">{{ option.name }}</mat-option>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </mat-list-item>
                            </mat-list>
                          </div>

                          <div class="mt-2 mb-4">
                            <button *ngIf="!commentMode" mat-stroked-button class="btn btn-primary" (click)="addRegulationRepealed()">
                              <span class="material-icons me-1">add_circle</span>Add
                            </button>
                          </div>

                        </div>
                    </div>

                  </div>

                  <!-- Checkbox Additional Taggings -->
                  <div class="form-group mt-3 mb-5">
                      <div class="form-check fw-bold">
                        <input class="form-check-input" type="checkbox" id="has_sub_tags" formControlName="has_sub_tags"/>
                        <label for="has_sub_tags" class="form-check-label">Check if there are additional taggings</label>
                      </div>
                      <!--<div class="my-4">
                        <button mat-stroked-button class="btn btn-primary" (click)="gotoForm(proposedregulationform.value.has_sub_tags ? 'subtagging' : 'regulationtext')">
                          <span class="material-icons me-1">add_circle</span>Add Sub Tag
                        </button>
                      </div>-->
                  </div>

                  <div class="row" *ngIf="this.proposedregulationform.get('has_sub_tags')?.value === false">
                    <div class="mt-5" *ngIf="proposedregulationform.invalid">
                      <p class="float-end fst-italic fw-bold">
                        Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form
                      </p>
                    </div>
                  </div>

                  <div class="row g-3" *ngIf="this.proposedregulationform.get('has_sub_tags')?.value === false">
                    <div class="card-footer text-center col-md-6 mt-2" *ngIf="!commentMode">
                      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                      <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                    </div>
                  </div>

                </form>
              
                <!-- Sub tags -->
                <form [formGroup]="proposedregulationform" *ngIf="this.proposedregulationform.get('has_sub_tags')?.value === true">
                  
                  <nav aria-label="breadcrumb" class="breadcrumb-custom mt-2">
                    <ol class="breadcrumb p-2">
                      <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">SUB TAG</li>
                    </ol>
                  </nav>

                  <div>
                    <ng-container formArrayName="sub_tags">
                      <ng-container *ngFor="let row of getFormsArray('sub_tags'); let i = index; let c = count;">
                        <div class="p-2 form-group fw-bold" [formGroup]="row">

                          <div class="d-flex flex-row-reverse" *ngIf="c > 0">
                            <button mat-stroked-button mat-icon class="btn btn-danger" (click)="removeSubTag('sub_tags',i)" *ngIf="!commentMode">
                              <span class="material-icons">remove_circle</span>
                            </button>
                          </div>

                          <div class="row">
                            <div class="col-6 d-flex flex-row">
                              <label class="form-input-header d-flex align-items-center">Classification</label>
                              <mat-radio-group formControlName="reg_classification" class="example-radio-group d-flex justify-content-evenly flex-row w-100">
                                <mat-radio-button value="Business" class="example-radio-button">Business</mat-radio-button>
                                <mat-radio-button value="Non-Business" class="example-radio-button">Non-Business</mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-6">
                              <ng-conainer [ngSwitch]="row.value.reg_classification">
                                <ng-container *ngSwitchCase="'Business'">
                                  <!-- sector -->
                                  <div class="input-content">
                                    <div class="row">
                                      <label class="form-input-header" for="reg_sector">Sector <span class="asterisk">*</span></label>
                                      <div class="col">
                                        <select class="form-control" formControlName="reg_sector">
                                          <option value="" disabled selected>--</option>
                                          <option *ngFor="let x of sectors" [value]="x['id']">
                                            {{ x["name"] }}
                                          </option>
                                        </select>
                                      </div>
                                      <!--  (change)="sectorOnChange($event)" -->
                                    </div>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_sector')">Required</mat-hint>
                                    <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_sector">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(row, 'reg_sector',item.type)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
        
                                  <!-- divion -->
                                  <div class="input-content">
                                    <div class="row">
                                      <label class="form-input-header" for="reg_division">Division <span class="asterisk">*</span></label>
                                      <div class="col">
                                        <select class="form-control" formControlName="reg_division">
                                          <option value="" disabled selected>--</option>
                                          <option *ngFor="let x of filterSelection('reg_division',row.value.reg_sector)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_division)" [value]="x['id']">
                                            {{ x["name"] }}
                                          </option>
                                        </select>
                                      </div>
                                      <!--  (change)="sectorOnChange($event)" -->
                                    </div>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_division')">Required</mat-hint>
                                    <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_division">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(row, 'reg_division',item.type)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
                                </ng-container>
                                
                                <ng-container *ngSwitchCase="'Non-Business'">
                                  <!-- sol -->
                                  <div class="input-content">
                                    <div class="row">
                                      <label class="form-input-header" for="reg_stageoflife">Stage of Life <span class="asterisk">*</span></label>
                                      <div class="col">
                                        <select class="form-control" formControlName="reg_stageoflife">
                                          <option value="" disabled selected>--</option>
                                          <option *ngFor="let x of sols" [value]="x['id']">
                                            {{ x["stage"] }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_stageoflife')">Required</mat-hint>
                                    <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_stageoflife">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(row, 'reg_stageoflife',item.type)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
                                </ng-container>
                              </ng-conainer>
                            </div>

                            <div class="col-6">
                              <ng-conainer [ngSwitch]="row.value.reg_classification">
                                <ng-container *ngSwitchCase="'Business'">
                                  <!-- sob -->
                                  <div class="input-content">
                                    <div class="row">
                                      <label class="form-input-header" for="reg_business">Stage of Business <span class="asterisk">*</span></label>
                                      <div class="col">
                                        <select class="form-control" formControlName="reg_business">
                                          <option value="" disabled selected>--</option>
                                          <option *ngFor="let x of sobs" [value]="x['id']">
                                            {{ x["name"] }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_business')">Required</mat-hint>
                                    <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_business">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(row, 'reg_business',item.type)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
        
                                  <!-- case -->
                                  <div class="input-content">
                                    <div class="row">
                                      <label class="form-input-header" for="reg_case">Case Use <span class="asterisk">*</span></label>
                                      <div class="col">
                                        <select class="form-control" formControlName="reg_case">
                                          <option value="" disabled selected>--</option>
                                          <option *ngFor="let x of filterSelection('reg_case',row.value.reg_business)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_case)" [value]="x['id']">
                                            {{ x["name"] }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_case')">Required</mat-hint>
                                    <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_case">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(row, 'reg_case',item.type)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
                                </ng-container>

                                <!-- Non-business -->
                                <ng-container *ngSwitchCase="'Non-Business'">
                                  <!-- le -->
                                  <div class="input-content">
                                    <div class="row">
                                      <label class="form-input-header" for="reg_lifeevent">Life event <span class="asterisk">*</span></label>
                                      <div class="col">
                                        <select class="form-control" formControlName="reg_lifeevent">
                                          <option value="" disabled selected>--</option>
                                          <option *ngFor="let x of filterSelection('reg_lifeevent',row.value.reg_stageoflife)" [selected]="subTagOptionSelected(x['id'],row?.value?.reg_lifeevent)" [value]="x['id']">
                                            {{ x["event"] }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(row, 'reg_lifeevent')">Required</mat-hint>
                                    <ng-container *ngFor="let item of checkProposedRegulationForm.tags.reg_lifeevent">
                                        <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(row, 'reg_lifeevent',item.type)">* {{item.message}}</mat-error>
                                    </ng-container> 
                                  </div>
                                </ng-container>
                              </ng-conainer>
                            </div>

                          </div>
                        </div>
                        <hr *ngIf="i < (c-1)">
                      </ng-container>
                    </ng-container>
                  </div>
        
                  <div class="text-center mt-4 mb-5" *ngIf="!commentMode">
                    <button mat-stroked-button class="btn btn-warning" type="button" (click)="addSubtag('sub_tags')">
                      <span class="material-icons me-1">add_circle</span>Add Another Tag</button>
                  </div>

                  <div class="row">
                    <div class="mt-5" *ngIf="proposedregulationform.invalid">
                      <p class="float-end fst-italic fw-bold">
                        Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                      </p>
                    </div>
                  </div>

                  <div class="row g-3" *ngIf="!commentMode">
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                      <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                    </div>
                  </div>

                </form>

                <!-- Comment -->
                <div class="row" *ngIf="commentMode">
                  <div class="card col-md-12" style="margin:80px 0 0;">
                    <nav aria-label="breadcrumb" class="breadcrumb-custom theme-agency">
                      <ol class="breadcrumb p-2 mb-0">
                        <li class="breadcrumb-item active ms-3" for="vision" aria-current="page" style="color:#FFFFFF;">
                          <i class="fas fa-comments me-2"></i>Enter your comment for Review of Tags:
                        </li>
                      </ol>
                    </nav>

                    <div class="card-body col-md-12 p-3 rights-container">
                      <mat-form-field appearance="fill" class="col-md-12">
                        <textarea matInput placeholder="write a comment" type="text" [formControl]="comment_details"></textarea>
                      </mat-form-field>
                    </div>

                    <!-- Comment Footer Bottons -->
                    <div class="row g-3">
                      <div class="card-footer text-center col-md-12 mt-2">
                        <button class="me-2 btn btn-dark float-start" (click)="backToInbox()">Cancel</button>
                        <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                        <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-step>
        
              <!-- Page 3: Text of the Regulation -->
              <mat-step [stepControl]="TextFormGroup">
                <form [formGroup]="proposedregulationform">
                  <ng-template matStepLabel>TEXT OF THE REGULATION</ng-template>

                  <div class="row">
                    <div *ngIf="proposedregulationform.invalid">
                      <p class="float-start fw-bold">
                        Complete all fields below. Asterisks (<span class="asterisk">*</span>) indicate required fields.
                      </p>
                    </div>
                  </div>

                  <div class="card-header card-header-custom mt-2 mb-4">
                    <div class="row">
                      <div class="ms-3">
                        <h6 class="fw-bold">TEXT OF THE REGULATION</h6>
                      </div>
                    </div>
                  </div>
        
                  <div class="mt-4">
                    <input class="form-check-input" type="checkbox" id="has_draft_text" formControlName="has_draft_text" (change)="checkRegulationTextFormIsInvalid()"/>
                    <label for="has_draft_text" class="form-check-label">Check if the draft text of the proposed regulation is available</label>
                  </div>
                  <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;;" *ngIf="!isTouched(proposedregulationform, 'has_draft_text')">Optional</mat-hint>
                  <!-- <ng-container *ngFor="let item of checkProposedRegulationForm.has_draft_text">
                      <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'has_draft_text',item.type)">* {{item.message}}</mat-error>
                  </ng-container>  -->
        
                  <div class="mt-4" *ngIf="proposedregulationform.get('has_draft_text')?.value === true">
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">

                      <!-- This is the tree node template for leaf nodes -->
                      <!-- Section -->
                      <mat-tree-node *matTreeNodeDef="let node; when: childlessSection" matTreeNodeToggle>
                        <div class="mat-tree-node d-flex justify-content-between childless-section my-1" style="background-color:#D9D9D9;" [class.bg-warning]="node.uuid === activeRegulationSectionUuid" (click)="getRegulationSectionInfo(node)">
                          <div class="d-flex justify-content-start align-content-stretch flex-grow-1 tree-select p-1">
                            <div class="d-flex align-self-center" [ngClass]="node.name ? 'color-black' : 'color-red'">
                            <!-- (click)="getRegulationSectionInfo(node)" -->
                              {{ node.name ? node.name : '* Section Title is required' }}
                              <!-- {{ node.name }} -->
                            </div>
                            <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">* Invalid: 
                              <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                                <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                              </ng-container>
                            </mat-error>
                          </div>
                        </div>

                        <div class="justify-content-end ms-1">
                          <button *ngIf="!commentMode" mat-stroked-button class="btn btn-warning" (click)="addNewRegulationSubsection(node)">
                            <span class="material-icons me-1">add_circle</span>Add Subsection
                          </button>
                          <button *ngIf="!commentMode" mat-stroked-button class="btn btn-danger ms-1" (click)="removeRegulationSection(node.uuid)">
                            <span class="material-icons">remove_circle</span>
                          </button>
                        </div>
                      </mat-tree-node>
        
                      <!-- Subsection -->
                      <mat-tree-node *matTreeNodeDef="let node; when: childlessSubsection" matTreeNodeToggle>
                        <div class="mat-tree-node d-flex justify-content-between my-1" style="background-color:#F2F2F2;" [class.bg-warning]="node.uuid === activeRegulationSubsectionUuid" (click)="getRegulationSubsectionInfo(node.parentUuid, node.uuid)">
                          <div class="d-flex justify-content-start align-content-stretch flex-grow-1 tree-select">
                            <div class="d-flex align-self-center ms-4" [ngClass]="node.name ? 'color-black' : 'color-red'">
                              <!-- {{ node.name }} -->
                              {{ node.name ? node.name : '* Subsection is required' }}
                            </div>
                            <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">* Invalid: 
                              <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                                <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                              </ng-container>
                            </mat-error>
                          </div>
                        </div>

                        <div class="d-flex justify-content-end">
                          <button *ngIf="!commentMode" mat-stroked-button class="btn btn-danger ms-1" (click)="removeRegulationSubsection(node.parentUuid, node.uuid)">
                            <span class="material-icons">remove_circle</span>
                          </button>
                        </div>
                      </mat-tree-node>
        
                      <!-- This is the tree node template for expandable nodes -->
                      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <div class="mat-tree-node d-flex justify-content-between my-1">
                          <div class="d-flex justify-content-start flex-grow-1 p-1" style="background-color:#D9D9D9;" [class.bg-warning]="node.uuid === activeRegulationSectionUuid">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                              <mat-icon class="mat-icon-rtl-mirror">
                                {{
                                  treeControl.isExpanded(node)
                                    ? "expand_more"
                                    : "chevron_right"
                                }}
                              </mat-icon>
                            </button>
                            <div class="tree-select d-flex align-self-center" (click)="getRegulationSectionInfo(node)" [ngClass]="node.name ? 'color-black' : 'color-red'">
                              <!-- {{ node.name }} -->
                              {{ node.name ? node.name : '* Section Title is required' }}
                              <mat-error style="margin-left:1em" *ngIf="validateNode(node).length > 0"class="color-red">* Invalid: 
                                <ng-container *ngFor="let nodeError of validateNode(node); let i = index; let c = count">
                                  <span>{{nodeError}}</span><span *ngIf="i<(c-1)">, </span>
                                </ng-container>
                              </mat-error>
                            </div>
                          </div>

                          <div class="justify-content-end ms-1">
                            <button *ngIf="!commentMode" mat-stroked-button class="btn btn-warning" (click)="addNewRegulationSubsection(node)">
                              <span class="material-icons me-1">add_circle</span>Add Subsection
                            </button>
                            <button *ngIf="!commentMode" mat-stroked-button class="btn btn-danger ms-1" (click)="removeRegulationSection(node.uuid)">
                              <span class="material-icons">remove_circle</span>
                            </button>
                          </div>
                        </div>

                        <!-- There is inline padding applied to this div using styles.
                        This padding value depends on the mat-icon-button width.  -->
                        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                          <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                      </mat-nested-tree-node>
                    </mat-tree>
        
                    <div class="text-center mt-4 mb-5">
                      <button *ngIf="!commentMode" mat-stroked-button class="btn btn-warning" (click)="addNewRegulationSection()">
                        <span class="material-icons me-1">add_circle</span>Add Another Section
                      </button>
                    </div>
        
                    <!-- Section Details -->
                    <div [formGroup]="proposedregulationform" *ngIf="activeRegulationSection">
                      <form [formGroup]="activeRegulationSection.info">

                        <nav aria-label="breadcrumb" class="breadcrumb-custom my-4">
                          <ol class="breadcrumb breadcrumb-custom p-2">
                              <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">I. SECTION DETAILS</li>
                          </ol>
                        </nav>

                        <div class="row g-3 mt-2">
                          <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label class="form-input-header" for="title">Section Title <span class="asterisk">*</span></mat-label>
                            <input matInput formControlName="section_title" (keyup)="updateTreeSectionName($event)" (change)="checkRegulationTextFormIsInvalid()"/>
                            <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(activeRegulationSection.info, 'section_title')">Input Required</mat-hint> -->
                            <ng-container *ngFor="let item of checkProposedRegulationForm.activeRegulationSection.section_title">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(activeRegulationSection.info, 'section_title',item.type)">Required</mat-error>
                            </ng-container> 
                          </mat-form-field>
        
                          <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label class="form-input-header" for="subject">Section Type <span class="asterisk">*</span></mat-label>
                            <mat-select multiple formControlName="section_type" (selectionChange)="checkRegulationTextFormIsInvalid()">
                              <mat-option value="Type A">Type A</mat-option>
                              <mat-option value="Type B">Type B</mat-option>
                              <mat-option value="Type C">Type C</mat-option>
                            </mat-select>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(activeRegulationSection.info, 'section_type')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.activeRegulationSection.section_type">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(activeRegulationSection.info, 'section_type',item.type)">Required</mat-error>
                            </ng-container> 
                          </mat-form-field>
                        </div>
        
                        <div class="row">
                          <div class="form-group">
                            <label class="form-input-header fw-bold" for="title">Section Text <span class="asterisk">*</span></label>
                            <editor [disabled]="commentMode" formControlName="section_text" (onSelectionChange)="checkRegulationTextFormIsInvalid()"
                              [init]="{
                                base_url: '/tinymce',
                                suffix: '.min',                      
                                height: 250,
                                menubar: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                  'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                              }"
                            ></editor>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(activeRegulationSection.info, 'section_text')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.activeRegulationSection.section_text">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(activeRegulationSection.info, 'section_text',item.type)">Required</mat-error>
                            </ng-container> 
                          </div>
                        </div>

                      </form>
                    </div>

                    <!-- Subsection Details -->
                    <div *ngIf="activeRegulationSubsection">
                      <form [formGroup]="activeRegulationSubsection">

                        <nav aria-label="breadcrumb" class="breadcrumb-custom my-4">
                          <ol class="breadcrumb p-2">
                              <li class="breadcrumb-item breadcrumb-item-custom active ms-3" aria-current="page">II. SUBSECTION DETAILS</li>
                          </ol>
                        </nav>

                        <div class="row g-3 mt-2">
                          <mat-form-field appearance="outline" class="col-md-12">
                            <mat-label class="form-input-header fw-bold" for="title">Subsection Title <span class="asterisk">*</span></mat-label>
                            <input matInput formControlName="subsection_title" (keyup)="updateTreeSubsectionName($event)" (change)="checkRegulationTextFormIsInvalid()"/>

                            <!-- <mat-hint class="fw-bold" style="color:rgb(45, 108, 233)" *ngIf="!isTouched(activeRegulationSubsection, 'subsection_title')">Input Required</mat-hint> -->
                            <ng-container *ngFor="let item of checkProposedRegulationForm.activeRegulationSection.subsection_title">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(activeRegulationSubsection, 'subsection_title',item.type)">Required</mat-error>
                            </ng-container> 
                          </mat-form-field>
                        </div>
        
                        <div class="row">
                          <div class="form-group">
                            <label class="form-input-header fw-bold" for="title">Subsection Text <span class="asterisk">*</span></label>
                            <editor [disabled]="commentMode" formControlName="subsection_text" (onSelectionChange)="checkRegulationTextFormIsInvalid()"
                              [init]="{
                                base_url: '/tinymce',
                                suffix: '.min',                      
                                height: 250,
                                menubar: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                  'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                              }"
                            ></editor>

                            <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(activeRegulationSubsection, 'subsection_text')">Required</mat-hint>
                            <ng-container *ngFor="let item of checkProposedRegulationForm.activeRegulationSection.subsection_text">
                                <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(activeRegulationSubsection, 'subsection_text',item.type)">Required</mat-error>
                            </ng-container>
                          </div>
                        </div>
        
                        <div class="row g-3 my-2">
                          <div class="form-group col-md-12">
                            <label class="form-input-header" for="uacs_organization_code">Add Image</label>
                            <input type="file" accept="image/*" class="form-control" (change)="saveImageInformation($event)"/>
                            <div class="col-4">
                              <img [src]="previewImg" class="img-fluid img-thumbnail" *ngIf="previewImg"/>
                            </div>  
                          </div>
                        </div>
                      </form>
                    </div>
        
                    <!-- TODO: Annex file uploads -->
        
                    <!-- Check if annexes to the regulation are available -->
                    <div class="mt-4 mb-5">
                      <input class="form-check-input" type="checkbox" id="has_annex_files" formControlName="has_annex_files"/>
                      <label for="has_annex_files" class="form-check-label">
                        Check if annexes to the regulation are available
                      </label>
                    
                    
                      <!-- <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'has_annex_files')">Annex Optional</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.has_annex_files">
                          <mat-error class="fw-bold" style="color:red" *ngIf="hasErrors(proposedregulationform, 'has_annex_files',item.type)">* {{item.message}}</mat-error>
                      </ng-container> -->
          
                      <div *ngIf="proposedregulationform.get('has_annex_files')?.value === true">
                        <!-- upload files goes here -->
                        <div class="form-group my-3">
                          <label class="form-input-header" for="title">Upload Text-Readable / Searchable documents
                            <span style="color:rgb(45, 108, 233);font-size:12px;"><a [routerLink]="[]" (click)="resetNewUpload('regulationTextAnnex')"><i class="fas fa-redo mx-1"></i>Reset Upload</a></span>
                          </label>
                          <!-- <input
                            type="file"
                            (change)="saveFileInformation('regulationTextAnnex', $event)"
                            class="form-control"
                            ng-required="true"
                            style="display: none"
                            #regulation_text_annex_file_input
                          />
                          <div class="file-input p-3">
                            <label>Drop files here or</label>
                            <button
                              (click)="regulation_text_annex_file_input.click()"
                              class="btn btn-light"
                            >
                              Browse
                            </button>
                          </div>
                          {{ display_regulation_text_annex_file_name }} -->
                          <div class="form-group file-uploader-wrapper">
                            <div appFileUploader class="dropzone" (filesChangeEmiter)="dropzone($event, 'regulationTextAnnex')">
                              <button type="button" class="btn btn-link dropzone-btn" (click)="regulationTextAnnex.click()">
                                <div *ngIf="display_regulation_text_annex_file_name">
                                  <h6><strong>{{ display_regulation_text_annex_file_name }}</strong></h6>
                                </div>
                                <div *ngFor="let formValid of checkUploadedDocs.all">
                                  <div class="error fw-bold" style="color:red;font-size:12px;" *ngIf="hasErrors(uploadsForm,'regulationTextAnnex', formValid.type == 'maxSize' ? 'max' : formValid.type, 'uploadsForm', formValid.type == 'maxSize' ? true : false)">
                                    {{formValid.message}}  
                                  </div>
                                </div>
                                <span>Drop file here to upload or click to choose file</span>
                                <span>(doc, docx, pdf). file cannot exceed 10MB.</span>
                              </button>
                              <input #regulationTextAnnex id="regulationTextAnnex"  type="file" multiple (change)="buttonUpload($event,'regulationTextAnnex')"/>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="form-helper text-danger"
                          *ngIf="showRegulationTextAnnexFileUploadErrors"
                        >
                          Invalid file type
                        </div> -->
                        <!-- <div class="form-group">
                          <label for="regulation_text_annex_file_input"
                            >Valid file types include: docx, pdf, pptx, xlsx. Each file
                            cannot exceed 10MB.</label
                          >
                        </div> -->
                      </div>

                    </div>
                  </div>

                  <!-- Comment -->
                  <div class="row" *ngIf="commentMode">
                    <div class="card col-md-12" style="margin:80px 0 0;">
                      <nav aria-label="breadcrumb" class="breadcrumb-custom theme-agency">
                        <ol class="breadcrumb p-2 mb-0">
                          <li class="breadcrumb-item active ms-3" for="vision" aria-current="page" style="color:#FFFFFF;">
                            <i class="fas fa-comments me-2"></i>Enter your comment for Text of the Regulation:
                          </li>
                        </ol>
                      </nav>

                      <div class="card-body col-md-12 p-3 rights-container">
                        <mat-form-field appearance="fill" class="col-md-12">
                          <textarea matInput placeholder="write a comment" type="text" [formControl]="comment_regtext"></textarea>
                        </mat-form-field>
                      </div>

                      <!-- Comment Footer Bottons -->
                      <div class="row g-3">
                        <div class="card-footer text-center col-md-12 mt-2">
                          <button class="me-2 btn btn-dark float-start" (click)="backToInbox()">Cancel</button>
                          <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                          <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                        </div>
                      </div>
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="mt-5" *ngIf="proposedregulationform.invalid">
                      <p class="float-end fst-italic fw-bold">
                        Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                      </p>
                    </div>
                  </div>
  
                  <!-- Footer Bottons -->
                  <div class="row g-3" *ngIf="!commentMode">
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                      <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                    </div>
                  </div>

                </form>
              </mat-step>
        
              <!-- Page 4: Agency Self-Assessment -->
              <mat-step [stepControl]="AgencyFormGroup">
                <form [formGroup]="proposedregulationform">
                  <ng-template matStepLabel>AGENCY SELF-ASSESSMENT</ng-template>

                  <div class="row">
                    <div *ngIf="proposedregulationform.invalid">
                      <p class="float-start fw-bold">
                        Complete all fields below. Asterisks (<span class="asterisk">*</span>) indicate required fields.
                      </p>
                    </div>
                  </div>

                  <div class="card-header card-header-custom mt-2 mb-4">
                    <div class="row">
                      <div class="ms-3">
                        <h6 class="fw-bold">AGENCY SELF-ASSESSMENT</h6>
                      </div>
                    </div>
                  </div>
        
                  <!--Significance & Narrative -->
                  <div class="form-group mt-2">
                    <mat-label class="form-input-header">Significance <span class="asterisk">*</span></mat-label>
                    <mat-radio-group formControlName="reg_significance" class="example-radio-group">
                      <div class="form-group row">
                        <div class="col-md-2">
                          <mat-radio-button checked value="Major" class="example-radio-button fw-bold">Major</mat-radio-button>
                        </div>
                        <p class="col-md-10">
                          Regulations that meet the ARTA’s Proportionality Rule will
                          require a full regulatory impact assessment and must undergo
                          ARTA’s review.
                        </p>
                      </div>
          
                      <div class="form-group row">
                        <div class="col-md-2">
                          <mat-radio-button value="Minor" class="example-radio-button fw-bold">Minor</mat-radio-button>
                        </div>
                        <p class="col-md-10">
                          Regulations that have no expected significant socio-economic
                          impacts. They do not need to undergo full regulatory impact
                          assessment.
                        </p>
                      </div>
          
                      <div class="form-group row">
                        <div class="col-md-2">
                          <mat-radio-button value="Exempted" class="example-radio-button fw-bold">Exempted</mat-radio-button>
                        </div>
                        <p class="col-md-10">
                          Regulations that are identified to be outside the scope of the
                          RIA Process.
                        </p>
                      </div>
          
                      <div class="form-group row">
                        <div class="col-md-2">
                          <mat-radio-button value="Emergency" class="example-radio-button fw-bold">Emergency</mat-radio-button>
                        </div>
                        <p class="col-md-10">
                          Exempted Regulations due to exceptional circumstances which
                          require immediate response such as national emergencies. The
                          submission of proper documentation to ARTA for such can be
                          done post-promulgation/issuance.
                        </p>
                      </div>
                    </mat-radio-group>
          
                    <div class="row mt-2">
                      <mat-label class="form-input-header">Narrative / Justification <span class="asterisk">*</span></mat-label>
                      <editor [disabled]="commentMode" formControlName="reg_significance_comments"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                                      alignleft aligncenter alignright alignjustify | \
                                                      bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>

                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_significance_comments')">Required</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.reg_significance_comments">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_significance_comments',item.type)">Required</mat-error>
                      </ng-container>
                    </div>
                  </div>
          
                  <!-- Lenses Required -->
                  <div class="form-group mt-4">
                    <mat-label class="form-input-header">Lenses Required <span class="asterisk">*</span></mat-label>
                    <div class="form-check p-3" formGroupName="reg_lenses">
                      <div class="form-group row">
                        <div class="col-md-4">
                          <input class="form-check-input" type="checkbox" id="lense_competition" formControlName="lense_competition" value="Competition Assessment"/>
                          <label for="lense_competition" class="form-check-label fw-bold">Competition Assessment</label>
                        </div>
                        <p class="col-md-8">
                          Competition assessment may demonstrate potential negative
                          impacts of a policy/regulation on competition i.e., (1)
                          creation of barrier/s to market entry, (2) restriction on the
                          ability of market players to compete, (3) promotion of
                          anti-competitive behavior among market players, and (4)
                          limitation of consumer information and choice.
                        </p>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-4">
                          <input class="form-check-input" type="checkbox" id="lense_environmental" formControlName="lense_environmental" value="Environment Assessment"/>
                          <label for="lense_environmental" class="form-check-label fw-bold">Environment Assessment</label>
                        </div>
                        <p class="col-md-8">
                          Regulations that will likely cause negative or irreversible
                          impact to the environment should be carefully evaluated before
                          they get approved and implemented. Regulations that have been
                          assessed using an environmental lens may also help in
                          preventing the implementation of unnecessary programs or
                          projects arising from the proposed or existing regulations.
                        </p>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-4">
                          <input class="form-check-input" type="checkbox" id="lense_inclusive" formControlName="lense_inclusive" value="Gender and Social Inclusivity Assessment"/>
                          <label for="lense_inclusive" class="form-check-label fw-bold">Gender and Social Inclusivity Assessment</label>
                        </div>
                        <p class="col-md-8">
                          The GESIA recognizes that gender intersects or interacts with
                          other categories of social exclusion, often solidified through
                          discrimination against certain groups or subpopulations based
                          on their identity as defined by age, (dis)ability, ethnicity,
                          location (urban or rural), class, faith, or sector.
                        </p>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-4">
                          <input class="form-check-input" type="checkbox" id="lense_disaster" formControlName="lense_disaster" value="Disaster Risk Assessment"/>
                          <label for="lense_disaster" class="form-check-label fw-bold">Disaster Risk Assessment</label>
                        </div>
                        <p class="col-md-8">
                          The need to investigate how regulation will impact the
                          achievement of disaster risk reduction (DRR) objectives is
                          paramount since disasters and bad development policies,
                          including bad regulations are closely interlinked.
                        </p>
                      </div>
                    </div>
          
                    <!-- Narrative / Justification -->
                    <div class="row g-3 mt-2 mb-5">
                      <mat-label class="form-input-header">Narrative / Justification <span class="asterisk">*</span></mat-label>
                      <editor [disabled]="commentMode" formControlName="reg_lenses_comments"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',                
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                                      alignleft aligncenter alignright alignjustify | \
                                                      bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>

                      <mat-hint class="mt-1" style="color:rgb(45, 108, 233);font-size:12px;" *ngIf="!isTouched(proposedregulationform, 'reg_lenses_comments')">Required</mat-hint>
                      <ng-container *ngFor="let item of checkProposedRegulationForm.reg_lenses_comments">
                          <mat-error class="mt-1" style="color:red;font-size:12px;" *ngIf="hasErrors(proposedregulationform, 'reg_lenses_comments',item.type)">Required</mat-error>
                      </ng-container> 
                    </div>

                  </div>

                  <!-- Comment -->
                  <div class="row" *ngIf="commentMode">
                    <div class="card col-md-12" style="margin:80px 0 0;">
                      <nav aria-label="breadcrumb" class="breadcrumb-custom theme-agency">
                        <ol class="breadcrumb p-2 mb-0">
                          <li class="breadcrumb-item active ms-3" for="vision" aria-current="page" style="color:#FFFFFF;">
                            <i class="fas fa-comments me-2"></i>Enter your comment for Agency Self-Assessment:
                          </li>
                        </ol>
                      </nav>

                      <div class="card-body col-md-12 p-3 rights-container">
                        <mat-form-field appearance="fill" class="col-md-12">
                          <textarea matInput placeholder="Input" pe="text" [formControl]="comment_self_assessment"></textarea>
                        </mat-form-field>
                      </div>

                      <!-- Comment Footer Bottons -->
                      <div class="row g-3">
                        <div class="card-footer text-center col-md-12 mt-2">
                          <button class="me-2 btn btn-dark float-start" (click)="backToInbox()">Cancel</button>
                          <button *ngIf="commentButtonsEnabled" class="btn btn btn-danger float-end" (click)="submitCommentsAndReturn()" id="bt "
                            [disabled]="(comment_details.invalid && comment_regtext.invalid && comment_pia.invalid && comment_self_assessment.invalid) || loading">
                            <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                            <i class="fas fa-share-square me-1"></i>Submit Comments and Return
                          </button>
                          <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="mt-5" *ngIf="proposedregulationform.invalid">
                      <p class="float-end fst-italic fw-bold">
                        Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                      </p>
                    </div>
                  </div>
                
                  <div class="row g-3" *ngIf="!commentMode">
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="btn btn-primary float-end" matStepperNext>Next <i class="fas fa-arrow-circle-right ms-1"></i></button>
                      <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                    </div>
                  </div>

                </form>
              </mat-step>
        
              <!-- Page 5: PIS summary -->
              <mat-step [stepControl]="PISSummaryFormGroup" *ngIf="!commentMode">
                <ng-template matStepLabel>PIS SUMMARY</ng-template>

                <div class="card-header card-header-custom my-4">
                  <div class="row">
                    <div class="ms-3">
                      <h6 class="fw-bold">PRELIMINARY IMPACT STATEMENT (PIS) SUMMARY</h6>
                    </div>
                  </div>
                </div>

                <div>
                  <table class="table mb-5">
                    <thead>
                      <tr class="alt-gray">
                        <th><strong>SECTION</strong></th>
                        <th><strong>STATUS</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let section of proposedRegulationFile">
                        <ng-container *ngIf="(section.code != 'subTags') || (section.code == 'subTags' && proposedregulationform.value.has_sub_tags == true)">
                          <td>
                            <a [routerLink]="[]" (click)="gotoForm(section.path)">{{section.title}}</a>
                          </td>
                          <td>
                            {{proposedRegulationChecker(section.code) ? 'Valid' : 'Invalid'}}
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
        
                  <!-- Comment -->
                  <div class="row" *ngIf="commentMode">
                    <div class="card col-md-12" style="margin:80px 0 0;">

                      <!-- Comment Footer Bottons -->
                      <div class="row g-3">
                        <div class="card-footer text-center col-md-12 mt-2">
                          <button class="me-2 btn btn-dark" (click)="toCancel()">Cancel</button>
                          <button class="me-2 btn btn-primary" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                          <button *ngIf="commentButtonsEnabled" class="btn btn btn-primary m-3 float-end" (click)="submitCommentsAndReturn()" id="bt "
                            [disabled]="(comment_details.invalid && comment_regtext.invalid && comment_pia.invalid && comment_self_assessment.invalid) || loading">
                            <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                            <i class="fas fa-share-square me-1"></i>Submit Comments and Return
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="mt-5" *ngIf="proposedregulationform.invalid">
                      <p class="float-end fst-italic fw-bold">
                        Asterisks (<span class="asterisk">*</span>) indicate required fields - must be filled in order to Submit the form.
                      </p>
                    </div>
                  </div>

                  <div class="row g-3" *ngIf="!commentMode">
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="me-2 btn btn-dark float-start" (click)="gotoForm('preface')">Cancel</button>
                      <button class="btn btn-info float-start" (click)="saveAsDraft()"><i class="fas fa-save me-1"></i>Save as Draft</button>
                    </div>
                    <div class="card-footer text-center col-md-6 mt-2">
                      <button class="btn btn-danger float-end" (click)="submitRegulation()" id="bt">
                        <i class="fas fa-share-square me-1"></i>Submit <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span>
                      </button>
                      <button class="me-2 btn btn-primary float-end" matStepperPrevious><i class="fas fa-arrow-circle-left me-1"></i>Back</button>
                    </div>
                  </div>

                </div>
              </mat-step>

            </mat-horizontal-stepper>
          </div>

        </div>
      </div>
      
    </div>
  </ng-container>

  <ng-template #notYetLoaded>
    <div class="d-flex justify-content-center align-items-center loader-wrapper">
      <div class="spinner-border text-light spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text">Loading . . .</span>
    </div>
  </ng-template>
</div>
