<app-navbar [currentPage]="'Home'"></app-navbar>

<div class="content">
  <!-- Carousel-->
  <div class="row">
    <div id="carouselExampleIndicators" class="carousel slide mb-5 col-12" data-mdb-ride="carousel">
      <ol class="carousel-indicators">
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="0" class="active"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="1"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="2"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="3"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="4"></li>
        <li data-mdb-target="#carouselExampleIndicators" data-mdb-slide-to="5"></li>
      </ol>

      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="/assets/Assets/Banner/01 - CarouselBanner_Order.png" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="/assets/Assets/Banner/02 - CarouselBanner_Better.png" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="/assets/Assets/Banner/03 -CarouselBanner_Inclusive.png" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="/assets/Assets/Banner/04 - CarouselBanner_Easier.png" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="/assets/Assets/Banner/05 - CarouselBanner_Smarter.png" class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="/assets/Assets/Banner/06 - CarouselBanner_Policy.png" class="d-block w-100" alt="..." />
        </div>
      </div>

      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-mdb-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-mdb-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>

  <!-- Consultations and Regulations Pane -->
  <div class="row">
    <!-- Consultations/Stay Connected/Essential Links -->
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 side-panel padded">

      <!-- Advance Search -->
      <app-pbris-advanced-search></app-pbris-advanced-search> 

      <!-- Consultations-->
      <div class="side-group text-center">
        <h5 class="p-3 mb-0 fw-bold text-primary"><i class="fas fa-comments me-3"></i>CONSULTATIONS</h5>
        <div class="card-body side-list">
          <ul class="list-unstyled " *ngFor="let filter of filterList">
            <li class="row m-2 side-filter" (click)="setFilter(filter.value)" [ngClass]="chosenFilter == filter.value? 'active':''">
              <span class="col-lg-2 col-sm-12 side-icon d-none d-sm-block"><i class="{{filter.icon}}"></i></span>
              <span class="col-lg-6 col-sm-12 side-desc">{{filter.name}}</span>
              <span class="col-lg-4 col-sm-12 counter" *ngIf="!loader">{{filterCounter(filter.value)}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Regulations Pane-->
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 table-documents padded">
      <div class="card table-responsive-sm">
        <table class="table table-light table-hover">
          <thead>
            <tr>
              <th colspan="3" class="text-primary list-header h3 p-4">
                <div class="row">
                  <div class="pb-0 col-lg-4 col-sm-12 d-flex align-items-center justify-content-start">
                    <!-- <i class="fas fa-copy"></i> -->
                    <button mat-button [matMenuTriggerFor]="menu">
                      <span class="text-primary fw-bold" style="font-size: 1.4rem;">{{chosen_pane}} <i class="fas fa-chevron-down ms-3"></i></span></button>
                      <mat-menu #menu="matMenu">
                        <div *ngFor="let pane of paneOptions">
                          <button (click)="choosePane(pane.title)" mat-menu-item>{{pane.title}}</button>
                        </div>
                      </mat-menu>
                  </div>

                  <div class="col-lg-8 col-sm-12 text-center">
                    <ul class="nav" id="pills-tab" role="tablist"  *ngIf="chosen_pane =='Regulations Pane'">
                      <li class="nav-item mx-1 p-1" style="cursor: pointer;">
                        <button class="btn nav-link rounded-9" type="button" [ngClass]="uploadRView? 'btn-primary':' btn-outline-dark '" (click)="onUploadRView()"  data-toggle="pill"  role="tab" aria-controls="pills-uploadr" aria-selected="false">Recently Uploaded</button>
                      </li>
                      <li class="nav-item mx-1 p-1" style="cursor: pointer;">
                        <button class="btn nav-link rounded-9" type="button" [ngClass]="effectiveRView? 'btn-primary':' btn-outline-dark '" (click)="onEffectieRView()"   data-toggle="pill"  role="tab" aria-controls="pills-effectiver" aria-selected="false">Recently Effective</button>
                      </li>
                      <li class="nav-item mx-1 p-1" style="cursor: pointer;">
                        <button class="btn nav-link rounded-9" type="button" [ngClass]="oldRView? 'btn-primary':' btn-outline-dark '" (click)="onOldRView()"   data-toggle="pill"  role="tab" aria-controls="pills-oldr" aria-selected="true">Old Regulations</button>
                      </li>
                    </ul>

                    <div class="row">
                      <ul [formGroup]="filtersForm" class="nav" id="pills-tab" role="tablist"  *ngIf="chosen_pane =='Consultations Pane'">
                        <li class="nav-item col-lg-2 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                          <span class="search"><h6>Search</h6></span>
                        </li> 
                        <li class="nav-item col-lg-5 col-sm-12 col-xs-12" style="cursor: pointer;">
                          <mat-form-field appearance="outline" class="w-100">
                              <!-- <mat-label>DTI Case No.</mat-label> -->
                              <input matInput type="text" (keyup)="search($event)"  formControlName="search" style="font-size: 16px; padding-bottom: 0;">
                          </mat-form-field>
                          <!-- <input class="form-control form-control-lg" (keyup)="search($event)" formControlName="search" type="text" placeholder="Search here"> -->
                        </li>

                        <li class="nav-item col-lg-5 col-sm-12 col-xs-12" style="cursor: pointer;">
                          <mat-form-field appearance="outline" class="w-100">
                            <form formGroupName="range">
                                <mat-date-range-input  [rangePicker]="pickedDate" class="pb-0">
                                    <input readonly matInput matStartDate formControlName="sDate" style="font-size: 16px;">
                                    <input readonly matInput matEndDate  formControlName="eDate" style="font-size: 16px;">
                                </mat-date-range-input>
                            </form>
                            <mat-datepicker-toggle matSuffix [for]="pickedDate"></mat-datepicker-toggle>
                            <mat-date-range-picker #pickedDate>
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="secondary" (click)="refreshDatePicker()" matDateRangePickerCancel>Refresh</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyDateRange()">Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                          </mat-form-field>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="chosen_pane =='Regulations Pane'">
            <ng-container *ngIf="uploadRView">
              <tr *ngIf="regulationsOrderedByUpload.length == 0">
                <td colspan="3" class="regulation-row d-flex justify-content-center align-items-center">
                  <p>No regulation recently uploaded.</p>
                </td>
              </tr>

              <tr *ngFor="let entry of regulationsOrderedByUpload" class="regulation-row col-12" (click)="viewRegulation(entry.id, entry.regulations_from)" title="{{entry.short_title}}">
                <td class="d-none d-sm-table-cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <img *ngIf="entry.regulation_type === 'Existing'" src="/assets/Assets/Icons/Existing%20Regulation.png" class="reg-icon" alt="Existing Regulation" />
                    <img *ngIf="entry.regulation_type === 'Proposed'" src="/assets/Assets/Icons/Proposed%20Regulation.png" class="reg-icon" alt="Proposed Regulation" />
                  </div>
                </td>
                <td class="d-none d-sm-table-cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="reg-icon" src="{{entry.agencyLogo}}" alt="Agency Logo"/>
                  </div>
                </td>
                <td style="width:80%;">
                  <p class="fw-bold"><b>{{ entry.short_title }}</b></p>
                  <p class="mb-0">Regulator: {{entry.agency_name }}</p>
                  <p class="mb-0">Issued Date: {{ entry.displayDate }}</p>
                  <!-- <p class="mx-1" *ngIf="entry.datePosted">Posted Date: {{ entry.datePosted }}</p> -->
                  <p class="mb-0" *ngIf="entry.effectiveDate">Effective Date: {{ entry.effectiveDate }}</p>
                  <p class="mb-0">Sector: <span *ngIf="entry.sector_data">{{entry.sector_data.name}}</span></p>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="effectiveRView">
              <tr *ngIf="regulationsOrderedByEffectivity.length == 0">
                <td colspan="3" class="regulation-row d-flex justify-content-center align-items-center">
                  <p>No regulation recently effective.</p>
                </td>
              </tr>
              <tr *ngFor="let entry of regulationsOrderedByEffectivity" class="regulation-row" (click)="viewRegulation(entry.id, entry.regulations_from)" title="{{entry.short_title}}">
                <td class="d-none d-sm-table-cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <img *ngIf="entry.regulation_type === 'Existing'" src="/assets/Assets/Icons/Existing%20Regulation.png" class="reg-icon" alt="Existing Regulation" />
                    <img *ngIf="entry.regulation_type === 'Proposed'" src="/assets/Assets/Icons/Proposed%20Regulation.png" class="reg-icon" alt="Proposed Regulation"
                    />
                  </div>
                </td>
                <td class="d-none d-sm-table-cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="reg-icon" src="{{entry.agencyLogo}}" alt="Agency Logo"/>
                  </div>
                </td>
                <td style="width:80%;">
                  <p class="fw-bold"><b>{{ entry.short_title }}</b></p>
                  <p class="mb-0">Regulator: {{entry.agency_name}}</p>
                  <p class="mb-0">Issued Date: {{ entry.displayDate }}</p>
                  <p class="mb-0">Effective Date: {{ entry.effectiveDate }}</p>
                  <p class="mb-0">Sector: <span *ngIf="entry.sector_data">{{entry.sector_data.name}}</span></p>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="oldRView">
              <tr *ngIf="regulations.length == 0">
                <td colspan="3" class="regulation-row d-flex justify-content-center align-items-center">
                  <p>No Data</p>
                </td>
              </tr>
              <tr *ngFor="let entry of regulations" (click)="viewRegulation(entry.id, entry.regulations_from)" class="regulation-row" title="{{entry.title}}">
                <td class="d-none d-sm-table-cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <img *ngIf="entry.effectivity_type === 'In Effect' || entry.effectivity_type === 'In-Effect'" src="/assets/Assets/Icons/Existing%20Regulation.png" class="reg-icon" alt="Existing Regulation" />
                    <!-- <img *ngIf="entry.regulation_type === 'Proposed'" src="/assets/Assets/Icons/Proposed%20Regulation.png" class="reg-icon" alt="Proposed Regulation" /> -->
                  </div>
                </td>
                <td class="d-none d-sm-table-cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="reg-icon" src="{{entry.agencyLogo}}" alt="Agency Logo"/>
                  </div>
                </td>
                <td style="width: 75%;">
                  <p class="fw-bold">{{ entry.title }}</p>
                  <p class="mb-0">Regulator: {{ entry.agency_name }}</p>
                  <p class="mb-0">Date Issued: {{ entry.displayDate }}</p>
                  <p class="mb-0">Sector: {{ entry.sector_data.name }}</p>
                </td>
              </tr>
              <tr class="text-center">
                <td colspan="3"><a class="btn btn-dark rounded-9 float-end" routerLink="/pbris/regulations/existing" role="button">view more</a></td>
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="chosen_pane =='Consultations Pane'">
            <ng-container>
              <tr *ngIf="displayed_consultations.length == 0">
                <td colspan="3" class="regulation-row d-flex justify-content-center align-items-center">
                  <p>No data on consultations.</p>
                </td>
              </tr>
              <tr *ngFor="let entry of displayed_consultations" class="regulation-row"
                (click)="toPage(entry)">
                <td>
                  <div class="d-flex justify-content-center align-items-center">
                    <img *ngIf="entry.regulation_type === 'Existing'" src="/assets/Assets/Icons/Existing%20Regulation.png" class="reg-icon" alt="Existing Regulation" />
                    <img *ngIf="entry.regulation_type === 'Proposed'" src="/assets/Assets/Icons/Proposed%20Regulation.png" class="reg-icon" alt="Proposed Regulation" />
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-center align-items-center">
                    <img class="reg-icon" src="{{entry.agencyLogo}}" alt="Agency Logo"/>
                  </div>
                </td>
                <td style="width:75%">
                  <div class="" style="cursor:pointer;">
                    <ul  class="list-unstyled">
                      <li><h4><u>{{entry.regulation_title}}</u></h4> /li>
                      <li>Sector/Stage of Life: <strong>{{entry.life_status}}</strong></li>
                      <li>Duration of Consultation: <strong>{{entry.formated_from_date}} to {{entry.formated_to_date}} </strong></li>
                    </ul>
                </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <!-- <tfoot>
            <div class="btn-group m-3">
              <div class="btn btn-sm btn-light">Previous</div>
              <div class="btn btn-sm btn-light">Next</div>
            </div>
          </tfoot> -->
        </table>
      </div>
    </div>
  </div>

  <!-- Pre Footer -->
  <div class="jumbotron bg-dark my-5 p-4">
    <div class="row">
      <!-- Featured Video -->
      <div class="col-lg-5 col-md-5 col-sm-12 mb-3">
        <h5 class="text-warning list-header p-2">Featured Video</h5>
        <iframe class="my-3 ms-0 border rounded" height="80%" width="100%"
          src="https://www.youtube-nocookie.com/embed/xOUVKwxKbHw"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
      </div>

      <!-- Announcements -->
      <div class="col-lg-7 col-md-7 col-sm-12">
        <h5 class="text-warning list-header p-3">Announcements</h5>
        <div class="spinner-grow" role="status" *ngIf="loader">
          <span class="visually-hidden">Loading...</span>
        </div>
        <ul class="list-group text-white overflow-auto" *ngIf="announcements" [disabled]="loader">
          <li *ngFor="let entry of announcements" class="list-group-flush bg-dark text-white mb-1 p-3">
            <div class="row">
              <div class="col-lg-1 col-sm-1 col-xs-1">
                <img src="/assets/Assets/Icons/HomePage/HomePage_Announcement_Maitenance.png" class="me-3" height="30" alt="..." />
              </div>
              <div class="col-lg-11 col-sm-11 col-xs-11">
                <h6 class="list-header announcement-title" (click)="callModal(entry.title, entry.description)">
                  {{ entry.title }} <br>
                  <small>Posted on {{ entry.date.toDate() | date: "MMMM dd, yyyy" }}</small>
                </h6>
              </div>
            </div>
          </li>
          <!-- Filler in case announcements is less than max announcements (currently 5) -->
          <li *ngFor="let e of [].constructor(maxAnnouncements - announcements.length)" class="list-group-flush bg-dark p-4"></li>
        </ul>

        <!-- Stay Connected -->
        <h5 class="text-warning list-header p-3 pb-0">Stay Connected</h5>
        <div class="card-body mx-auto">
          <a type="button" target="_new" href="https://www.facebook.com/ARTAPh" class="btn btn-lg btn-primary btn-floating mx-1">
            <i class="fab fa-facebook-f fa-2x"></i>
          </a>
          <a type="button" target="_new" href="https://twitter.com/ARTAgovph" class="btn btn-lg btn-info btn-floating mx-1">
            <i class="fab fa-twitter fa-2x"></i>
          </a>
          <a type="button" target="_new" href="https://www.instagram.com/artagovph/" class="btn btn-lg btn-danger btn-floating mx-1">
            <i class="fab fa-instagram fa-2x"></i>
          </a>
          <a type="button" target="_new" href="https://www.youtube.com/channel/UChQr6Tl3lqcKfMd4ANNN75w" class="btn btn-lg btn-danger btn-floating mx-1">
            <i class="fab fa-youtube fa-2x"></i>
          </a>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- footer -->
<footer class="text-center text-lg-start">
  <div class="padded mb-5">
    <div class="row pb-4">
      <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center">
        <img src="/assets/Assets/Bagong-Pilipinas-Logo.png" height="100" alt="..." />
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center">
        <a href="https://arta.gov.ph/">
          <img src="/assets/Assets/ARTA%20LOGO.png" height="110" alt="..." />
        </a>
      </div>
      <div class=" col-xl-7 col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-center">
        <p class="text-center m-0">
          The Anti-Red Tape Authority manages the Philippine Business Regulations
          Information System (PBRIS) in close coordination with regulatory government agencies:
        </p>
      </div>
    </div>
   
    <div class="row">
      <div class="card-footer pt-4">
        <!-- <h4 class="text-primary list-header">
          <img
            src="/assets/Assets/Icons/HomePage/HomePage_EssentialLinks.png"
            height="24"
            alt="..."
          />
          Essential Links
        </h4> -->
        <ul class="list-group list-group-flush list-group-horizontal-md">
          <li class="list-group-item border-0">
            <a href="https://www.officialgazette.gov.ph/" target="_blank" class="list-group-item align-self-center">
            <img src="../../assets/essential-links/gazette.png" class="img-fluid m-0 align-self-center" /></a>
          </li>
          <li class="list-group-item border-0">
            <a href="https://law.upd.edu.ph/onar/" target="_blank" class="list-group-item align-self-center">
            <img src="../../assets/essential-links/ONAR.png" class="img-fluid m-0 align-self-center" /></a>
          </li>
          <li class="list-group-item border-0">
            <a href="https://arta.gov.ph" target="_blank" class="list-group-item align-self-center">
            <img src="../../assets/essential-links/ARTA.png" class="img-fluid m-0 align-self-center" /></a>
          </li>
          <li class="list-group-item border-0">
            <a [href]="artemisSite" target="_blank" class="list-group-item align-self-center">
            <img src="../../assets/essential-links/ARTEMIS.png" class="img-fluid m-0 align-self-center" /></a>
          </li>
          <li class="list-group-item border-0">
            <a href="https://business.gov.ph/home" target="_blank" class="list-group-item align-self-center">
            <img src="../../assets/essential-links/PBH.png" class="img-fluid m-0 align-self-center" /></a>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</footer>
