import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isAdmin: boolean = false;
  isAgency: boolean = false;
  isArta: boolean = false;
  cred: any;

  @Input() currentPage: any;

  /* If page is active */
  public activeHome: any;
  public activePolicy: any;
  public activeContactUs: any;
  public activeHelp: any;
  public activeManagement: any;
  public activeSurvey: any;
  public activeConsultation: any;
  public activeRegulations: any;

  public accountSettingsLink: any;
  public hasPage:boolean = false;
  public agencyPage:any ;
  public agencyMemberPage:any ;
  public isAgencyAdmin:boolean = false;
  public canViewCC:boolean = false;
  public toAgencyCCPage:string;
  public toAgencyCCPageQP: any = {}

  constructor(public auth: AngularFireAuth, private afs: AngularFirestore, private router: Router) { }

  ngOnInit(): void {
    this.loadUsers();

    this.activeHome = '';
    this.activePolicy = '';
    this.activeContactUs = '';
    this.activeHelp = '';
    this.activeManagement = '';
    this.activeSurvey = '';
    this.activeConsultation = '';
    this.activeRegulations = '';

    

    switch (this.currentPage) {
      case 'Home':
        this.activeHome = 'active';
        break;
      case 'Policy':
        this.activePolicy = 'active';
        break;
      case 'ContactUs':
        this.activeContactUs = 'active';
        break;
      case 'Help':
        this.activeHelp = 'active';
        break;
      case 'Management':
        this.activeManagement = 'active';
        break;
      case 'Survey':
        this.activeSurvey = 'active';
        break;
      case 'Consultation':
        this.activeConsultation = 'active';
        break;
      case 'Regulations':
        this.activeRegulations = 'active';
        break;
    }
  }

  async loadUsers() {
    //console.log(sessionStorage.getItem("user_type"))
    this.auth.user.subscribe(data => {
      this.afs.doc(`Users/${data?.uid}`).get().subscribe((cred: any) => {
        this.cred = cred.data();
        // if (cred.data() && cred.data().credentials.account_subtype.toLowerCase().includes('admin')) {
        //   this.isAdmin = true;
        // }
        if(cred.data()
        && (cred.data().credentials.account_subtype.toLowerCase().includes('personal')
          || cred.data().credentials.account_subtype.toLowerCase().includes('government')
          || cred.data().credentials.account_subtype.toLowerCase().includes('admin')
          || cred.data().credentials.account_subtype.toLowerCase().includes('arta'))){
          const id = cred.data().credentials.uid
          this.accountSettingsLink = "/account/"+id;

          if(cred.data().credentials.account_subtype.toLowerCase().includes('arta')){
            this.isArta = true;
            if(cred.data().credentials.account_subtype.toLowerCase().includes('admin')){
              this.isAdmin = true;
            }
          }

          if(cred.data().credentials.account_subtype.trim().toLowerCase().includes('government') && cred.data().government.agency){
            this.isAgency = true
            this.agencyPage = "/artemis/view/agency/"+cred.data().government.agency;
            this.hasPage = true;
            if(cred.data().credentials.account_subtype.trim().toLowerCase().includes('it/admin')){
              this.agencyMemberPage = "/agency/view/"+cred.data().government.agency+"/members";
              this.isAgencyAdmin = true;
            }

            if(cred.data().credentials.account_type.trim().toLowerCase().includes('government')
              && (cred.data().credentials.account_subtype.trim().toLowerCase().includes('encoder')
              || cred.data().credentials.account_subtype.trim().toLowerCase().includes('verifier')
              || cred.data().credentials.account_subtype.trim().toLowerCase().includes('agency head'))){
                
              this.toAgencyCCPage = "/artemis/citizen-charter/view/report"
              this.toAgencyCCPageQP.agency = cred.data().government.agency
              this.canViewCC = true
            }
          }
        }
      });
    })
  }

  // global settings
  userType: any = sessionStorage.getItem("user_type");
  agencyName: any = sessionStorage.getItem("agency_name");
  userSubtype: any = sessionStorage.getItem("account_subtype");
  user:any = {};
  userLoading:boolean = true

  logout() {
    this.auth.signOut().then(() => {
      sessionStorage.clear();
      this.router.navigate(['/']);
    });
  }

  goToCCReport(){
    //console.log(this.toAgencyCCPage+'?',this.toAgencyCCPageQP)
    this.router.navigate([this.toAgencyCCPage],{
      queryParams:this.toAgencyCCPageQP
    })
  }

}
