


<div [formGroup]="officeGroup">
  <h1 mat-dialog-title>Agency Office</h1>
  <div mat-dialog-content>
    <div class="row">
      <mat-form-field appearance="outline" style="width: 400px;">
        <mat-label>Office Type</mat-label>
        <mat-select formControlName="type" required>
          <mat-option *ngFor="let office of officetypelist" [value]="office">
            {{office}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
     </div>

     <div class="row">
      <mat-form-field appearance="outline" style="width: 400px;">
        <mat-label>Office Name</mat-label>
        <input formControlName="name" required matInput type="text" placeholder="Input">
      </mat-form-field>
     </div>
    
  </div>
  <div [disabled]="loading"  mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary"  cdkFocusInitial (click)="save()">
      <span class="spinner-border spinner-border-sm mr-1" *ngIf="loading"></span> {{ isupdate? "Update":"Save"}}</button>
  </div>

 
</div>