

<!-- Sectors -->
    <div class="card">
        <div class="card-header">
            <button (click)="callTermsNConditions()" class="btn btn-outline-dark {{tab_tnc? '':'inactive'}}"><!-- [disabled]="loading"  -->
                Terms & Conditions
            </button>
            <button (click)="callPrivacyPolicy()" class="btn btn-outline-dark {{tab_pp? '':'inactive'}}"><!-- [disabled]="loading"  -->
                Privacy Policy
            </button>
        </div>
        <div class="card-body">
            <!--terms and conditions-->
            <ng-container *ngIf="tab_tnc">
                <!--
                <form #termsconditionsform="ngForm" (ngSubmit)="update(termsconditionsform,'terms_and_conditions')" >
                    <div *ngFor="let section of terms_and_conditions" class="form-group">
                        <label for="input-{{section.id}}">{{section.id | number}}</label>
                        <input type="text" name="input-text-{{section.id}}" class="form-control" [value]="section['title']" formControlName="input-text-{{section.id}}" placeholder="{{section['title']}}" ngModel>
                        <textarea name="input-textarea-{{section.id}}" rows="5" cols="60" class="form-control" formControlName="input-textarea-{{section.id}}" ngModel>{{section.description}}</textarea>
                    </div>
                    <input type="submit" value="UPDATE" class="btn btn-sm btn-warning">
                </form>
                -->
                <div *ngFor="let formListItem of termsAndConditionsForm">
                    <form [formGroup]="formListItem">
                        <div class="form-group">
                            <input type="text" formControlName="title" class="form-control" >
                        </div>
                        <div class="form-group">
                            <textarea formControlName="description" rows="5" cols="60" class="form-control"></textarea>
                        </div>
                    </form>
                </div>
                <button (click)="updateTermsAndConditions()" class="btn">Update Terms and Conditions</button>
            </ng-container>
            <!--privacy policy-->
            <ng-container *ngIf="tab_pp">
                <div *ngFor="let formListItem of privacyPolicyForm">
                    <form [formGroup]="formListItem">
                        <div class="form-group">
                            <input type="text" formControlName="title" class="form-control" >
                        </div>
                        <div class="form-group">
                            <textarea formControlName="description" rows="5" cols="60" class="form-control"></textarea>
                        </div>
                    </form>
                </div>
                <button (click)="updatePrivacyPolicy()" class="btn">Update Terms and Conditions</button>
            </ng-container>
        </div>
    </div>