import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { map, tap, take } from 'rxjs/operators';
import { Observable, combineLatest, defer } from 'rxjs';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  
  // loader: boolean = true;
  
  notifList:any;
  // notifItem:any;

  searchFilters: Array<string> = [
    "agency_name",  
    "event",
    "level",
    "message", 
    "display_name",
];

  selectedOption: String;
  isSelect:any;

  first_name: any;
  last_name:any;
  display_name : any;
  agency_name:any;
  agency_id:any;
  
  uid:any;

  options = [
    { name: "All", value: 0 },
    { name: "New User", value: 1 },
    { name: "Updated Regulation", value: 2 },
    { name: "Ticket Filed", value: 3 }
  ]


  searchform: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('', Validators.required),    
    eventCategory: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    public auth: AngularFireAuth) { 
          
      // this.getNotifs();
    }

  ngOnInit(): void {
    //  this.getUserNotif();

     let JSONfields: Map<string, string> = new Map();

     this.route.queryParams.subscribe((params: any) => {
       this.searchform.patchValue({
         keyword: params.keyword
       });
 
       if (params.keyword === "") {
         JSONfields.set("title", "");
       }
       else {
         JSONfields.set("title", params.keyword);
       }
 
       for (var x in this.searchFilters) {
 
         const searchFilter = this.searchFilters[x];
         if (params.keyword === "") {
           JSONfields.set(searchFilter, "");
         }
         else {
           JSONfields.set(searchFilter, params.keyword);
         }
       }
       this.getNotifs(JSONfields);
     })
  }
  
  getNotifs(inputJSON: Map<string, string>){
    
    // let userList: any = [];

    this.afs.collection('Notifications', (filter) =>
      filter
      .where('notif_in','==','pbris')
    )
    .snapshotChanges()
    .subscribe(data => {
      this.notifList = [];
      
      data.forEach(async (res) =>{
        
        let item:any = res.payload.doc.data()
        item.id = res.payload.doc.id
        
        // console.log("item: ", item)
         
        this.afs.collection('Users').doc(item.uid)
        .snapshotChanges()
        .subscribe(
        data =>{
          let user_info: any = data.payload.data()   
          item.display_name = user_info.personal.first_name +" "+ user_info.personal.last_name
          
          this.afs.collection('Agency').doc(item.agency_id)
          .snapshotChanges()
          .subscribe(
            data =>{
              let agency_info: any = data.payload.data()      
              item.agency_name = agency_info.name

              if (this.notifList.includes(item) === false) {

                  if (inputJSON.get("title") === undefined) {
                    this.isAcceptedItem("", inputJSON, item) //TODO: Shall we remove this??
                    this.notifList.push(item);
        
                  }else {
                    for (var x in this.searchFilters) {
                      const searchFilter = this.searchFilters[x];
        
                      let byName = this.isAcceptedItem(searchFilter, inputJSON,item)
                      let allBool = byName
        
                        if (allBool) {
                          // item.displayName= item.event;
        
                          this.notifList.push(item);
                          break;
                        }
                    }
                    
                  }
                }
            }) 
          }) 
      })
    })
  }

  isAcceptedItem(keyName: string, inputJSON: Map<string, string>, item: any) {
    

    if (inputJSON.get(keyName) === '') {
      return true;
    }
    // if field is from keyword, convert all to lower-case then compare
    if (this.searchFilters.includes(keyName)) {
      let inputStr = inputJSON.get(keyName) || "";

      if (
        item[keyName] && item[keyName].toLowerCase().includes(inputStr.toLowerCase())) {
        
        return true;
      }
    }
    // if field has data that is not from keyword
    else if (item[keyName] === inputJSON.get(keyName)) {
      
      return true
    }
    else if (item[keyName] === undefined || inputJSON.get(keyName) === undefined) {
      // console.log("hit1")
      return false;
    }
    
    // console.log("hit2")
    return false;
  }

  search( ) {
    let JSONarray = {
      keyword: this.searchform.value.keyword,
    };
    
    this.router.navigate([`/pbris/regulatory-management`], { queryParams: JSONarray });  // define your component where you want to go
    
  }

  eventSelected(){
  //  this.isSelect =  selectedOption.target.value;

  //  console.log("isSelect: ",this.isSelect)
  
   if(this.selectedOption == "All"){
      this.isSelect = "";
   }else{
     
    this.isSelect = this.selectedOption;
   }

    let JSONarray = {
      keyword: this.isSelect
    };
    
    this.router.navigate([`/pbris/regulatory-management`], { queryParams: JSONarray });  // define your component where you want to go
    
  }
}
