import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject  } from 'rxjs';

@Component({
  selector: 'app-artemis-agency-crud',
  templateUrl: './artemis-agency-crud.component.html',
  styleUrls: ['./artemis-agency-crud.component.scss']
})
export class ArtemisAgencyCrudComponent implements OnInit {

  status: string = "";

  agencies:any = [];
  
  loading: boolean = true;
  searchform: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('', Validators.required),
  });

  dtOptions: any = {};

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  searchFilters: Array<string> = ["name", "abbreviation"];

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthChange: false,
      searching: false
    };

    this.loadStatus(this.route.snapshot.params.status);

    let JSONfields: Map<string, string> = new Map();

    this.route.queryParams.subscribe((params: any) => {
      this.searchform.patchValue({
        keyword: params.keyword
      });

      if (params.keyword === "") {
        JSONfields.set("title", "");
      }
      else {
        JSONfields.set("title", params.keyword);
      }

      for (var x in this.searchFilters) {
        const searchFilter = this.searchFilters[x];
        if (params.keyword === "") {
          JSONfields.set(searchFilter, "");
        }
        else {
          JSONfields.set(searchFilter, params.keyword);
        }
      }
      this.loadAgencies(JSONfields);
    })
    
    // this.loadAgencies();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  loadStatus(getStatus: string) {
    if (getStatus) {
      this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
    }
  }

  async loadAgencies(inputJSON: Map<string, string>){
    this.afs.collection('Agency', (filter) => 
      filter
      .where("agencyDetails","!=", null)
      // .orderBy('agencyDetails.agencyName')
    )
    .snapshotChanges()
    .subscribe((data: any) => {
      this.agencies = [];

      data.forEach((results: any) => {
        let item: any = results.payload.doc.data();
        item.id = results.payload.doc.id;

        // this.services.push(item);

        if (this.agencies.includes(item) === false) {

          if (inputJSON.get("title") === undefined) {
            this.isAcceptedItem("", inputJSON, item) //TODO: Shall we remove this??
            
            this.agencies.push(item);
            // console.log(this.agencies)
          }
          else {
            for (var x in this.searchFilters) {
              const searchFilter = this.searchFilters[x];
              let byName = this.isAcceptedItem(searchFilter, inputJSON, item)
              let allBool = byName

                if (allBool) {
                  this.agencies.push(item);
                  break;
                }
            }
          }
        }
      });
      this.loading = false;
      this.dtTrigger.next(this.dtOptions);
      // TODO: Make pagination take effect when searching again when data already has been loaded
      this.dtTrigger.unsubscribe();
    })
  }

  isAcceptedItem(keyName: string, inputJSON: Map<string, string>, item: any) {

    // if field is left empty

    if (inputJSON.get(keyName) === '') {
      return true;
    }
    // if field is from keyword, convert all to lower-case then compare
    if (this.searchFilters.includes(keyName)) {
      let inputStr = inputJSON.get(keyName) || "";
      if (
        item[keyName] && item[keyName].toLowerCase().includes(inputStr.toLowerCase())) {
        return true;
      }
    }
    // if field has data that is not from keyword
    else if (item[keyName] === inputJSON.get(keyName)) {
      return true
    }
    else if (item[keyName] === undefined || inputJSON.get(keyName) === undefined) {
      return false;
    }
    return false;
  }

  search() {
    let JSONarray = {
      keyword: this.searchform.value.keyword,
    };


    this.router.navigate([`/artemis/agencies`], { queryParams: JSONarray });  // define your component where you want to go
  }


  viewAgency(entryId: string){
    //TODO: Temporarily reusing the view details for worklisting
    //TODO: Create a new component for viewing separate from 
    this.router.navigate([`artemis/view/agency/${entryId}`])
  }

  getImage(agency:any){
    if(agency.agencyDetails){
      if(agency.agencyDetails.logoURL)
        return agency.agencyDetails.logoURL;
    }
    return "/assets/GOP_Logo.png"
  }
}
