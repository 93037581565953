
<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="loader">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!loader">
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Filter</mat-label>
        <input  matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
      
    <div class="row pb-3">

        <mat-button-toggle-group class="col" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle [hidden]="entries < 1" (click)="multARTEMIS(false)" class="btn btn-outline-danger">Restrict </mat-button-toggle>
            <mat-button-toggle [hidden]="entries < 1" (click)="multPBRIS(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-primary">PBRIS</strong></mat-button-toggle>
            <mat-button-toggle [hidden]="entries < 1" (click)="multAll(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-dark">ALL</strong></mat-button-toggle>
        </mat-button-toggle-group>
        

        <mat-button-toggle-group class="col d-flex justify-content-end" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle [hidden]="entries < 1" (click)="multARTEMIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></mat-button-toggle>
            <mat-button-toggle [hidden]="entries < 1" (click)="multPBRIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></mat-button-toggle>
            <mat-button-toggle [hidden]="entries < 1" (click)="multAll(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-dark">ALL</strong></mat-button-toggle>
            <mat-button-toggle [hidden]="entries < 1" (click)="multDelete()" class="btn btn-danger">Delete Account</mat-button-toggle>
        </mat-button-toggle-group>

        <!-- <div class="col btn-group float-end">
            <button [hidden]="entries < 1" (click)="multARTEMIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></button>
            <button [hidden]="entries < 1" (click)="multPBRIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></button>
            <button [hidden]="entries < 1" (click)="multAll(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-dark">ALL</strong></button>
            <button [hidden]="entries < 1" (click)="multDelete()" class="btn btn-danger">Delete Account</button>
        </div> -->
    </div>

    <mat-sidenav-container fxFlex="0 1 auto">
        <mat-sidenav-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" matSort >
                
                    <!-- Selector Column -->
                    <ng-container matColumnDef="selector" sticky>
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let row"> 
                        <input 
                            type="checkbox" 
                            [disabled]='!row.credentials.account_subtype' 
                            [hidden]='row.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' 
                            [value]="row" [checked]="checkCheckBoxSelected(row)" 
                            (change)="checkCheckBoxvalue($event, row)" 
                            class="form-check-input" 
                            id="flexCheckDefault" />    
                    </td>
                    </ng-container>
                
                    <!-- Timestamp Column -->
                    <ng-container matColumnDef="Timestamp" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
                    <td mat-cell *matCellDef="let row"> {{row.sinceMember }} </td>
                    </ng-container>
                
                    <!-- Agency Column -->
                    <ng-container matColumnDef="Email" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                    </ng-container>
                
                    <!-- Agency Column -->
                    <ng-container matColumnDef="Agency">
                    <th mat-header-cell *matHeaderCellDef> Agency </th>
                    <td mat-cell *matCellDef="let row"> 
                        <select class="form-select-sm w-100" 
                            [disabled]='agencySelectDisabler(row.credentials.account_subtype, enum_govt_account_subtype.ARTA_ADMIN, row.credentials.account_type)' 
                            #agency_dropdown 
                            (change)="updateAgency(row, agency_dropdown.value)">
                                <option selected disabled>Update Agency</option>
                                <option 
                                *ngFor="let agency of filterAgency(row.credentials.account_type)"
                                [value]="agency.id"
                                [selected] = "agencySelector(agency.id, row.government.agency)"
                                >{{agency.name}}</option>
                        </select>
                    </td>
                    </ng-container>
                
                    <!-- User Type Column -->
                    <ng-container matColumnDef="User Type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> User Type </th>
                    <td mat-cell *matCellDef="let row"> 
                        {{row.account_type}}
                    </td>
                    </ng-container>
            
                    <!-- User Type Column -->
                    <ng-container matColumnDef="User Subtype Type">
                        <th mat-header-cell *matHeaderCellDef> User Subtype Type </th>
                        <td mat-cell *matCellDef="let row"> 
                            <select class="form-select-sm w-100" [disabled]='row.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' #subtype (change)="updateSubType(row, subtype.value)">
                                <option selected disabled>Update subtype</option>
                                <option *ngFor="let x of filterType(row.credentials.account_type)" [value]="x"
                                [selected] = "x === row.credentials.account_subtype"
                                >{{x}}</option>
                            </select>
                        </td>
                    </ng-container>
                
                    <!-- Actions Column -->
                    <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell style="width: 15%;" *matCellDef="let row"> 
                        <mat-button-toggle-group class="col d-flex justify-content-center" name="fontStyle" aria-label="Font Style">
                            <mat-button-toggle (click)="setARTAccessibility(row, false)" [hidden]="!row.credentials.access_artemis" [disabled]='row.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-danger">Restrict <strong class = "badge bg-danger">ARTEMIS</strong></mat-button-toggle>
                            <mat-button-toggle (click)="setARTAccessibility(row, true)" [hidden]="row.credentials.access_artemis " [disabled]='row.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></mat-button-toggle>
                            <a routerLink="/admin/files/{{row.id}}" class="btn btn-sm btn-link">Files</a>
                            <mat-button-toggle (click)="setPBRAccessibility(row, false)" [hidden]="!row.credentials.access_pbris" [disabled]='row.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-danger">Restrict <strong class = "badge bg-primary">PBRIS</strong></mat-button-toggle>
                            <mat-button-toggle (click)="setPBRAccessibility(row, true)" [hidden]="row.credentials.access_pbris" [disabled]='row.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></mat-button-toggle>
                        </mat-button-toggle-group>
                    </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                </table>
                </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</div>
  
<!-- <div class="card-header">
    <div class="col">
        <h3 class="card-title">Users</h3>
        <small class="card-subtitle">List of Users</small>
    </div>
</div>
<div class="card-body">
    <div class="col btn-group float-start">
        <button [hidden]="entries < 1" (click)="multARTEMIS(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-danger">ARTEMIS</strong></button>
        <button [hidden]="entries < 1" (click)="multPBRIS(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-primary">PBRIS</strong></button>
        <button [hidden]="entries < 1" (click)="multAll(false)" class="btn btn-outline-danger">Restrict <strong class = "badge bg-dark">ALL</strong></button>
    </div>

    <div class="col btn-group float-end">
        <button [hidden]="entries < 1" (click)="multARTEMIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></button>
        <button [hidden]="entries < 1" (click)="multPBRIS(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></button>
        <button [hidden]="entries < 1" (click)="multAll(true)" class="btn btn-outline-success">Allow <strong class = "badge bg-dark">ALL</strong></button>
        <button [hidden]="entries < 1" (click)="multDelete()" class="btn btn-danger">Delete Account</button>
    </div>
    <div class="d-flex justify-content-center align-self-center">
        <div class="spinner-grow" role="status" *ngIf="loader">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <table class="table table-hover table-responsive w-100" *ngIf="!loader">
        <thead>
            <tr>
                <th>

                </th>
                <th>Timestamp</th>
                <th>Email</th>
                <th>Agency</th>
                <th>User Type</th>
                <th>User Subtype</th>
                <th>Action(s)</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let entry of users">
                <td>
                    <input type="checkbox" [disabled]='!entry.credentials.account_subtype' [hidden]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' [value]="entry" [checked]="checkCheckBoxSelected(entry)" (change)="checkCheckBoxvalue($event, entry)" class="form-check-input"
                        type="checkbox" id="flexCheckDefault" />
                </td>
                <th>{{ entry.credentials.sinceMember }}</th>
                <th>{{ entry.credentials.email }}</th>
                <td scope="row">
                       
                    <select class="form-select-sm" 
                    [disabled]='agencySelectDisabler(entry.credentials.account_subtype, enum_govt_account_subtype.ARTA_ADMIN, entry.credentials.account_type)' 
                    #agency_dropdown 
                    (change)="updateAgency(entry, agency_dropdown.value)">
                        <option selected disabled>Update Agency</option>
                        <option 
                            *ngFor="let agency of filterAgency(entry.credentials.account_type)"
                            [value]="agency.id"
                            [selected] = "agencySelector(agency.id, entry.government.agency)"
                            >{{agency.name}}</option>
                    </select>
                </td>
                <td>{{entry.credentials.account_type}}</td>
                <td scope="row">
                    <select class="form-select-sm" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' #subtype (change)="updateSubType(entry, subtype.value)">
                        <option selected disabled>Update subtype</option>
                        <option *ngFor="let x of filterType(entry.credentials.account_type)" [value]="x"
                        [selected] = "x === entry.credentials.account_subtype"
                        >{{x}}</option>
                    </select>
                </td>
                <td scope="row">
                    <div class="btn-group">
                        <button (click)="setARTAccessibility(entry, false)" [hidden]="!entry.credentials.access_artemis" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-danger">Restrict <strong class = "badge bg-danger">ARTEMIS</strong></button>
                        <button (click)="setARTAccessibility(entry, true)" [hidden]="entry.credentials.access_artemis " [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-success">Allow <strong class = "badge bg-danger">ARTEMIS</strong></button>
                        <a routerLink="/admin/files/{{entry.id}}" class="btn btn-sm btn-link">Files</a>
                        <button (click)="setPBRAccessibility(entry, false)" [hidden]="!entry.credentials.access_pbris" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-danger">Restrict <strong class = "badge bg-primary">PBRIS</strong></button>
                        <button (click)="setPBRAccessibility(entry, true)" [hidden]="entry.credentials.access_pbris" [disabled]='entry.credentials.account_subtype == enum_govt_account_subtype.ARTA_ADMIN' class="btn btn-sm btn-outline-success">Allow <strong class = "badge bg-primary">PBRIS</strong></button>
                    </div>

                </td>

            </tr>
        </tbody>
        <tbody *ngIf="users.length < 1 && !loader">
            <tr>
                <td colspan="4">No users</td>
            </tr>
        </tbody>

    </table>

</div> -->