<footer class="text-center">
    <div class="container p-4" >
        <div class="row d-flex align-items-center">
            <div class="col-md-4 p-4"><!--arta logo-->
                <img src="/assets/Assets/ARTA%20LOGO.png" width="100%" height="auto" alt="..." />
            </div>  
            <div class="col-md-8">
                <p class="text-center m-0">
                    The Anti-Red Tape Authority manages the Anti-Red Tape Electronic Management Information System (ARTEMIS)
                     which contains the Citizen's Charter submissions of all government agencies under the executive branch of the government:</p>
            </div>            
        </div>
        <div class="row ">
            <div class="d-flex justify-content-center">
                <!--gov links-->
                <ul class="list-group list-group-flush list-group-horizontal-md ">
                    <li class="list-group-item border-0">
                        <a href="https://www.officialgazette.gov.ph/" target="_blank">
                            <img src="../../assets/essential-links/gazette.png"/>
                        </a>
                    </li>
                    <li class="list-group-item border-0">
                        <a href="https://arta.gov.ph" target="_blank">
                        <img src="../../assets/essential-links/ARTA.png" />
                        </a>
                    </li>
                    <li class="list-group-item border-0">
                        <a href="#purpose-content" target="_blank">
                        <img src="../../assets/essential-links/ARTEMIS.png"/>
                        </a>
                    </li>
                </ul>
            </div>                
        </div>
    </div>
</footer>