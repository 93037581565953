
      
                <div>
                  <hr />
                  <label class="form-input-header" for="vision"
                    ><b>Comments of User</b></label
                  >
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <textarea
                      matInput
                      placeholder="Input"
                      type="text"
                      [formControl]="comment_details"
                    ></textarea>
                  </mat-form-field>
                </div>



                <div>
                  <label class="form-input-header" for="vision"
                    ><b>Comments of User</b></label
                  >
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <textarea
                      matInput
                      placeholder="Input"
                      type="text"
                      [formControl]="comment_regtext"
                    ></textarea>
                  </mat-form-field>
                </div>

                <!-- Buttons for comment mode -->
                <div class="text-center">
                    <button
                      class="m-1 btn btn-lg btn-outline-dark"
                      (click)="gotoForm('regulationdetails')"
                    >
                      Back
                    </button>
                    <!-- <a
                      href="/pbris/regulatory-management"
                      class="m-1 btn btn-lg btn-outline-dark"
                      >Cancel</a
                    > -->
                    
                    <button (click)="toCancel()"
                    class="m-1 btn btn-lg btn-outline-dark"
                      >Cancel</button
                    >
                    <button
                     
                      class="btn btn btn-primary m-3 float-end"
                      (click)="submitCommentsAndReturn()"
                      id="bt "
                      [disabled]="
                        (comment_details.invalid && comment_regtext.invalid) || loading
                      "
                    >
                      <span
                        class="spinner-border spinner-border-sm mr-1"
                        *ngIf="loading"
                      ></span>
                      Submit Comments and Return
                    </button>
                  </div>
              
                