import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, FormBuilder } from '@angular/forms';
import { ARTEMIS, PBRIS } from '../entities/constant';
import { NotificationsEmailer } from '../service/notifications-emailer.service';
import Quill from 'quill';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EmailConfigurationTemplateDialogComponent } from './email-configuration-template/email-configuration-template.component';
import { SnackbarNotifService } from '../service/snackbar-notif.service';

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent implements OnInit {
  quillEditor:any;

  templateList:any = []

  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  pbris = PBRIS
  artemis = ARTEMIS
  currentSystem:any = sessionStorage.getItem('currentSystem');

  emailCollection = 'Email Configuration'
  config:any = null;

  emailForm: UntypedFormGroup = new UntypedFormGroup({
    emails: new UntypedFormControl('', [Validators.required]),
    appCode: new UntypedFormControl(1)
  })

  configForm: UntypedFormGroup = new UntypedFormGroup({
    id:new UntypedFormControl('',[Validators.required]),
    subject:new UntypedFormControl(''),
    content:new UntypedFormControl(''),
    email:new UntypedFormControl(''),
    code:new UntypedFormControl(''),
    enabled: new UntypedFormControl(),
  })

  variablesForm: UntypedFormGroup = new UntypedFormGroup({
    variablesArray: new UntypedFormArray([])
  })

  selectedTemplate:any = null
  toEdit:boolean = false

  constructor(
    private afs: AngularFirestore,
    private notifE: NotificationsEmailer,
    public dialog: MatDialog,
    private snackbar: SnackbarNotifService
    ) { }

  ngOnInit(): void {
    this.configLoader()
  }

  editorCreated(quill:any){
    this.quillEditor = quill;
  }

  configLoader(){
    this.afs.collection(this.emailCollection, filter=>filter.orderBy('configCode','asc')).snapshotChanges()
    .subscribe({
      next:(response1)=>{
        this.templateList = response1.map((response2)=>{
          let item:any = response2.payload.doc.data()
          item.id = response2.payload.doc.id
          return item
        })
      },
      error:(err)=>{
        console.error('Error: ', err)
      }
    })
  }

  selectTemplate(template:any){
    console.log('template: ',template)
    this.toEdit = false
    this.selectedTemplate = template
    this.configForm.patchValue({
      id:template.id,
      code:template.configCode,
      subject:template.subject,
      content:template.content,
      enabled:template.enabled
    })
    this.handleVariableTestList(template.variables)
    // console.log(template)
  }

  handleVariableTestList(variables:any){
    this.variablesArray.clear()
    variables.map((item:any)=>{
      this.variablesArray.push(
        new UntypedFormGroup({ 
          key: new UntypedFormControl(item.key),
          title: new UntypedFormControl(item.title),
          itemValue: new UntypedFormControl('')
        })
      )
    })
  }

  getEmailConfig(configCode:any){
    return new Promise((res,rej)=>{
      this.afs.collection(this.emailCollection,filter=>filter.where("configCode","==",configCode).orderBy("configCode","desc")).snapshotChanges()
      .subscribe({
        next:(responses)=>{
          let item:any = false;
          responses.map((response)=>{
            item = response.payload.doc.data();
            item.id = response.payload.doc.id;
          })
          return res(item)
        },
        error:(err)=>{
          console.error('Error: ',err)
          return res(false)
        }
      })
    })
  }

  get variablesArray(){
    return (this.variablesForm.controls['variablesArray'] as UntypedFormArray)
  }

  getFormsArray(){
    return this.variablesArray.controls as UntypedFormGroup[]
  }

  editTemplate(template:any){
    this.configForm.patchValue({
      id:template.id,
      code:template.configCode,
      subject:template.subject,
      content:template.content
    })
    this.toEdit = true
  }

  cancelTemplate(template:any){
    this.toEdit = false
    this.configForm.patchValue({
      id:template.id,
      code:template.configCode,
      subject:template.subject,
      content:template.content
    })
  }

  deleteTemplate(template:any){
    this.toEdit = false
    this.snackbar.confirmDialog("Delete template?").then(result=>{
      if(result){
        this.afs.collection(this.emailCollection).doc(template.id).delete()
      }
    })

    

  }

  updateTemplate(){
    if(this.configForm.valid){
      this.afs.collection(this.emailCollection).doc(this.configForm.value.id).update({
        subject:this.configForm.value.subject,
        content:this.configForm.value.content,
        enabled:this.configForm.value.enabled,
      })
      .then(()=>{
        this.selectedTemplate.subject = this.configForm.value.subject
        this.selectedTemplate.content = this.configForm.value.content
        this.selectedTemplate.enabled = this.configForm.value.enabled
        this.toEdit = false
      })
      .catch((err)=>{
        console.error('config update error',err);
        alert("Error");
      })
    }
  }

  addVariable(variable:any){
    let range = this.quillEditor.getSelection();
    console.log({range})
    if(range){
      this.quillEditor.insertText(range.index, "{{"+variable+"}}",true);
    }else{
      alert("Focus on where to insert variable inside the editor.")
    }
  }

  // emails input handler
  addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.emails.push(value);
    }
    
    // console.log('event',event)
    // Clear the input value
    // event.chipInput!.clear();
    this.emailForm.controls.emails.setValue(null);
  }

  removeEmail(fruit: string): void {
    const index = this.emails.indexOf(fruit);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  // !emails input handler

  sendEmail(){
    console.log('config',this.configForm.value)
    if(this.emails.length>0){
      let variables:any = {}

      this.variablesArray.value.map((item:any)=>{
        variables[item.key] = item.itemValue
      })

      let body = {
        recipients: this.emails,
        app_code: this.emailForm.value.appCode,
        mail_code: this.configForm.value.code,
        data:{
          content: (this.toEdit == false) ? this.selectedTemplate.content : this.configForm.value.content,
          variables:variables,
          subject: (this.toEdit == false) ? this.selectedTemplate.subject : this.configForm.value.subject,
        },
      }
      console.log(body)
      if(this.toEdit == false || (this.toEdit == true && this.configForm.value.enabled == true)){
        this.notifE.sendEmailNotiftoUser(body)
        .subscribe({
          next:(response)=>{
            console.log('response: ',response)
            if(response && response.description == "Approved") alert('Email Successfully sent')
            else alert('Failed to send an email')
          },
          error:(err)=>{
            console.error('Error: ',err)
          }
        })
      }else alert('Email notification for template is disabled!')
    }else{
      alert("No Recipient for test email!")
    }
  }

  addTemplate(){
    const dialogRef = this.dialog.open(EmailConfigurationTemplateDialogComponent);

    // dialogRef.afterClosed().subscribe((result:any)=>{})
  }
}
