<mat-card>
  <mat-card-header>
    <mat-card-title class="text-primary"><strong>Agency List</strong></mat-card-title>
  </mat-card-header>
  <mat-card-actions>

    <div [formGroup]="searchForm" class="px-2 form-group d-flex flex-row align-items-center" style="margin-top:.6em;">
      <!-- (keyup)="search($event)" -->
      <label for="search" style="margin-right:.3em;">Search:</label><input  formControlName="keyword" id="search"
        name="search" class="form-control" />
    </div>
  </mat-card-actions>

  <mat-card-content>
    <table class="table-striped  table-responsive table-hover" mat-table [dataSource]="dataSource" matSort
      (matSortChange)="sortChange($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="date_submitted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Submitted </th>
        <td mat-cell *matCellDef="let row"> {{row.dateSubmittedFormatted}} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="agecy_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Agency Name </th>
        <td mat-cell *matCellDef="let row" style="cursor:pointer;" (click)="openCharterServices(row)"> <a
            [routerLink]="[]">{{row.fromAgencyName }}</a></td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Agency Category </th>
        <td mat-cell *matCellDef="let row"> {{row.agencyCategory ? row.agencyCategory.name ? row.agencyCategory.name :
          '' : '' }}</td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="cc_version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CC Version </th>
        <td mat-cell *matCellDef="let row"> {{ transform(row.edition,row.year) }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>

        <td mat-cell *matCellDef="let worklist">



          <div class="align-items-center">
            <div [ngSwitch]="worklist.status">
              <div *ngSwitchCase="'ONGOING ARTA REVIEW'">

                <h6><span class="badge  bg-info ">{{worklist.status}}</span></h6>

              </div>
              <div *ngSwitchCase="'FOR ARTA REVIEW'">
                <h6>
                  <span class="badge  bg-info ">{{worklist.status}}</span>
                </h6>
              </div>
              <div *ngSwitchDefault>

                <h6>
                  <span class="badge  bg-danger ">{{worklist.status}}</span>
                </h6>
              </div>

            </div>
          </div>

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="action_due">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action Due </th>
        <td mat-cell *matCellDef="let row"> {{row.dueDateFormatted}}</td>
      </ng-container>



      <!-- Header ID Column -->
      <ng-container matColumnDef="header_date_submitted">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <!-- Header Progress Column -->
      <ng-container matColumnDef="header_agecy_name">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <!-- Header Category Column -->
      <ng-container matColumnDef="header_category">
        <th mat-header-cell *matHeaderCellDef>
          <select class="form-control" #headerCategory (change)="filterTable('agencyCategory',headerCategory.value)">
            <option value="" selected>All</option>
            <option *ngFor="let option of agencyCategories" [value]="option.name">({{option.abbreviation}})
              {{option.name}}</option>
          </select>
        </th>
      </ng-container>

      <!-- Header Progress Column -->
      <ng-container matColumnDef="header_cc_version">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <!-- Header Name Column -->
      <ng-container matColumnDef="header_status">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <!-- Header Name Column -->
      <ng-container matColumnDef="header_action_due">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-header-row
        *matHeaderRowDef="['header_date_submitted', 'header_agecy_name', 'header_category', 'header_cc_version', 'header_status', 'header_action_due']">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

  </mat-card-content>

  <mat-card-actions>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]" aria-label="Select page of users"></mat-paginator>
  </mat-card-actions>
</mat-card>



<!----
<div class="container-fluid shadow">
  <div class="row">
    <div class="inbox-title">
      <h3>Agency List</h3>
    </div>

    <div [formGroup]="searchForm" class="form-group d-flex flex-row align-items-center" style="margin-top:1em;">
     (keyup)="search($event)" 
      <label for="search" style="margin-right:1em;">Search:</label><input formControlName="keyword" id="search" name="search"  class="form-control"/>
    </div>
    <table class="table-striped  table-sm" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">
      
       ID Column 
      <ng-container matColumnDef="date_submitted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Submitted </th>
        <td mat-cell *matCellDef="let row"> {{row.dateSubmittedFormatted}} </td>
      </ng-container>
  
   Progress Column 
      <ng-container matColumnDef="agecy_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Agency Name </th>
        <td mat-cell *matCellDef="let row" style="cursor:pointer;" (click)="openCharterServices(row)"> <a [routerLink]="">{{row.fromAgencyName }}</a></td>
      </ng-container>

       Category Column 
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Agency Category </th>
        <td mat-cell *matCellDef="let row"> {{row.agencyCategory ? row.agencyCategory.name ? row.agencyCategory.name : '' : '' }}</td>
      </ng-container>

  Progress Column 
      <ng-container matColumnDef="cc_version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CC Version </th>
        <td mat-cell *matCellDef="let row"> {{row.dateFormatted }}</td>
      </ng-container>
  
  Name Column 
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th> 
        
        <td mat-cell *matCellDef="let worklist">
           
            
           
          <div class="align-items-center">
            <div [ngSwitch]="worklist.status" >
              <div *ngSwitchCase="'ONGOING ARTA REVIEW'">

                <h6><span class="badge  bg-info ">{{worklist.status}}</span></h6>

              </div>
              <div *ngSwitchCase="'FOR ARTA REVIEW'">
                <h6>
                  <span class="badge  bg-info ">{{worklist.status}}</span>
                </h6>
              </div>
              <div *ngSwitchDefault>
                    
                  <h6>
                    <span class="badge  bg-danger ">{{worklist.status}}</span>
                  </h6>
              </div>

            </div>
          </div>
          
        </td>
      </ng-container>
  
  Name Column 
      <ng-container matColumnDef="action_due">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action Due </th>  
        <td mat-cell *matCellDef="let row"> {{row.dueDateFormatted}}</td>
      </ng-container>

      
      
     Header ID Column 
      <ng-container matColumnDef="header_date_submitted">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
  
      Header Progress Column
      <ng-container matColumnDef="header_agecy_name">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

     Header Category Column 
      <ng-container matColumnDef="header_category">
        <th mat-header-cell *matHeaderCellDef>
          <select class="form-control" #headerCategory (change)="filterTable('agencyCategory',headerCategory.value)">
            <option value="" selected>All</option>
            <option *ngFor="let option of agencyCategories" [value]="option.name">({{option.abbreviation}}) {{option.name}}</option>
          </select>
        </th>
      </ng-container>

    Header Progress Column 
      <ng-container matColumnDef="header_cc_version">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>
  
     Header Name Column 
      <ng-container matColumnDef="header_status">
        <th mat-header-cell *matHeaderCellDef></th> 
      </ng-container>
  
      Header Name Column 
      <ng-container matColumnDef="header_action_due">
        <th mat-header-cell *matHeaderCellDef></th>  
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-header-row *matHeaderRowDef="['header_date_submitted', 'header_agecy_name', 'header_category', 'header_cc_version', 'header_status', 'header_action_due']"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    </table>
    
    <mat-paginator [pageSizeOptions]="[10, 25, 50]" aria-label="Select page of users"></mat-paginator>

    <table class="table table-hover table-responsive">
      <thead>
        <tr>
          <th class="table-header">Date Submitted</th>
          <th class="table-header">Agency Name</th>
          <th class="table-header">CC Version</th>
          <th class="table-header">Status</th>
          <th class="table-header">Action Due</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let charter of results">
          <td>
            <h5>
              {{ charter.dateSubmitted}}
            </h5>
          </td>
          <td>
            <h5
              class="service-link"
              (click)="openCharterServices(charter)"
            >
              <u>{{ charter.fromAgencyName }}</u>
            </h5>
          </td>
          <td>
            <h5>
              {{ charter.date}}
            </h5>
          </td>
          <td>
            <h5>
              <u>
                {{ charter.status }}
              </u>
            </h5>
          </td>
          <td>
            <h5>
              {{
                charter.dueDate
                  ? (charter.dueDate)
                  : "N/A"
              }}
            </h5>
          </td>
        </tr>
      </tbody>
    </table> 
  </div>
</div>
-->