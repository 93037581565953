import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { format } from 'date-fns';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
// import { ActivatedRoute, Router } from '@angular/router';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { PdfRenderService } from '../../pdf-render.service';
// import { format } from 'date-fns';
// import { Timestamp } from 'firebase/firestore';
// import Swal from 'sweetalert2';

@Component({
selector: 'app-regulations-cris-view',
templateUrl: './regulations-cris-view.component.html',
styleUrls: ['./regulations-cris-view.component.scss']
})
export class RegulationsCrisViewComponent implements OnInit {

  @Input() crisData:any;
  @Input() consultationsData:any;
  agencyOption:string;
  // agency_item:any;
  consultationTableDisplayCol: string[] = ['rowId'];
  consultationTabledataSource: any;
  crisLifeStatus:any;
  crisUploads:any= []
  
  constructor(
      private afs: AngularFirestore,
      private store: AngularFireStorage,
  ) { }

  ngOnInit(): void {
    this.crisUploads = []
    // console.log("crisData",this.crisData)
    // console.log("consultationsData",this.consultationsData)
    this.checkData()
    this.assignDataSource()
  }

  // load cris data
  async checkData(){
    if(this.crisData){
      this.crisData.instrument_name = await this.dataLoaded('Instrument',this.crisData.reg_instrument)
  
      if(this.crisData.reg_classification ==  'Business'){
        this.crisData.sector_name = await this.dataLoaded('Sector',this.crisData.reg_sector)
        if(this.crisData.sector_name) this.crisLifeStatus = this.crisData.sector_name
        this.crisData.business_name = await this.dataLoaded('Stage of Business',this.crisData.reg_business)
        this.crisData.division_name = await this.dataLoaded('Division',this.crisData.reg_division)
        this.crisData.case_name = await this.dataLoaded('Case Use',this.crisData.reg_case)
      }else if(this.crisData.reg_classification ==  'Non-Business'){
        this.crisData.sol_name = await this.dataLoaded('Stage of Life',this.crisData.reg_stageoflife)
        if(this.crisData.sol_name) this.crisLifeStatus = this.crisData.sol_name
        this.crisData.event_name = await this.dataLoaded('Life Event',this.crisData.reg_lifeevent)
      }
  
      this.crisData.juris_name = await this.dataLoaded('Jurisdiction',this.crisData.reg_juris)
  
      if(this.crisData.reg_agency) this.checkAgency(this.crisData.reg_agency)
      if(this.crisData.upload_folders) this.checkUploads(this.crisData.upload_folders)

      console.log('checkData crisData: ',this.crisData)
    }
  }

  checkUploads(upload_folders:any){
    console.log('upload_folders: ',upload_folders)
    // for(let key of Object.keys(upload_folders)){
    Object.keys(upload_folders).map(async (key)=>{
      let value = upload_folders[key]
      let value2 = value.split("/")
      // const fileData:any = await this.refToURL(value+"/"+value2[value2.length-1]) //remove others that value
      const fileData:any = await this.refToURL(value) //correct
      let name:any = ""
      if(key == 'policyOptions') name="Policy Options"
      else if(key == 'policyProblem') name="Policy Problem"
      else if(key == 'implementationEnforcement') name="Implementation Enforcement"
      else if(key == 'policyOptionsAssessment') name="Policy Options Assessment"
      else if(key == 'monitoringEvaluation') name="Monitoring Evaluation"
      else if(key == 'regulatoryImpactStatement') name="Regulatory Impact Statement"
      else if(key == 'impactAssessment') name="Impact Assessment"
      else if(key == 'consultationDocumentation') name="Consultation Documentation"
      else if(key == 'consultation') name="Consultation"
      let item:any = {
        key: key,
        name: name,
        value: value,
        fileBaseTotalPage: 1,
        fileBasePage: 1,
        downloadUrl: false,
        contentType: false,
      }
      if(fileData.url){
        item.downloadUrl = fileData.url
        item.contentType = fileData.metaData.contentType

        // console.log('item',item)
        this.crisUploads.push(item)
      }else{
        // console.log('item',item)
        this.crisUploads.push(item)
      }
    })
    // }
  }

  refToURL(filePath:string){
    return new Promise((res,rej)=>{
      this.store.ref(filePath).getDownloadURL()
      .subscribe({
        next:(data1)=>{
          this.store.ref(filePath).getMetadata()
          .subscribe({
            next:(data2)=>{
              let item:any = {
                url: data1,
                metaData: data2,
              }
              return res(item)
            },
            error:(err)=>{
              // console.error('refToURL Error: ',err)
              return res(false)
            }
          })
        },
        error:(err)=>{
          // console.error('refToURL Error: ',err)
          return res(false)
        }
      })
    })
  }

  async checkAgency(reg_agency:any){
    if(reg_agency){
      // load data first
      if(typeof reg_agency == 'string'){
        // this.agencyOption = 'single'
        // this.agency_item = reg_agency
        const singleAgencyData:any = await this.loadData('Agency',reg_agency)
        if(singleAgencyData){
          this.agencyOption = 'single'
          // this.agency_item = singleAgencyData ? singleAgencyData.agencyDetails ? singleAgencyData.agencyDetails.agencyName ? singleAgencyData.agencyDetails.agencyName : : : (singleAgencyData.name ? singleAgencyData.namesingleAgencyData.name : "Cannot get Agency")
          if(singleAgencyData){
            console.log('singleAgencyData',singleAgencyData)
            this.crisData.agency_item = this.agencyNaming1(singleAgencyData) 
          }else this.crisData.agency_item = this.agencyNaming2(singleAgencyData) 
        }
      }else if(Array.isArray(reg_agency)){
        this.agencyOption = 'multi'
        // this.agency_item = reg_agency
        const multiAgencyData:any = await this.multipleLoadData('Agency',reg_agency)
        if(multiAgencyData){
          console.log('multiAgencyData',multiAgencyData)
          this.crisData.agency_item = []
          multiAgencyData.map((singleAgencyData:any)=>{
            this.crisData.agency_item.push(this.agencyNaming1(singleAgencyData))
          })
        } else this.crisData.agency_item.push(this.agencyNaming2(null));
      }
    }
  }

  agencyNaming1(singleAgencyData:any){
    if(singleAgencyData.agencyDetails){
      if(singleAgencyData.agencyDetails.agencyName){
        return singleAgencyData.agencyDetails.agencyName
      }else return this.agencyNaming2(singleAgencyData)
    } else return this.agencyNaming2(singleAgencyData)
  }

  agencyNaming2(singleAgencyData:any){
    if(singleAgencyData.name){
      return singleAgencyData.name
    }else return "Cannot get Agency"
  }

  multipleLoadData(collection:any, arrValue:any){
    let tempArr:any = []
    return Promise.all(
      arrValue.map(async (data:any)=>{
        const item:any = await this.loadData(collection,data)
        if(item){
          if(!tempArr.map((item:any)=>item.id).includes(item.id)) tempArr.push(item)
        }
        return true
      })
    ).then(()=>tempArr)
  }

  async dataLoaded(collection:any, value:any){
    if(collection && value){
      const loadedData:any = await this.loadData(collection,value)
      if(loadedData){
        // if(collection == 'Sector' || collection == 'Stage of Life') this.crisLifeStatus = loadedData
        return loadedData.name
      }else return ""
    }else return ""
  }

  loadData(collection:any, value:any){
    return new Promise((res,rej)=>{
      this.afs.collection(collection).doc(value).snapshotChanges()
      .subscribe({
        next:(data)=>{
          if(data.payload.exists){
            let item:any = data.payload.data()
            item.id = data.payload.id
            return res(item)
          } else return res(false)
        },
        error:(err)=>{
          console.error('loadData Error: ',err)
          return res(false)
        }
      })
    })
  }
  // !load cris data

  assignDataSource(){
    let consultations:any[] = this.consultationsData
    console.log('consultations: ',consultations)
    consultations.map((item)=>{
      item.formated_from_date = format(item.from_date.toDate(), 'MM/dd/yyyy');
      item.formated_to_date = format(item.to_date.toDate(), 'MM/dd/yyyy');
    })
    this.consultationTabledataSource = new MatTableDataSource<any>(consultations)
  }

  // file functions
  checkAuthLetterFileType(type:any,fileType:any){
    if(fileType === type) return true
    return false
  }

  pageShift(action:any,ctr:any){
    if(action == "next"){
        if(this.crisUploads[ctr].fileBasePage < this.crisUploads[ctr].fileBaseTotalPage)
            this.crisUploads[ctr].fileBasePage++;
    }
    if(action == "back"){
        if(this.crisUploads[ctr].fileBasePage > 1)
            this.crisUploads[ctr].fileBasePage--;
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy, ctr:any){
    this.crisUploads[ctr].fileBaseTotalPage = pdf.numPages;
  }
  // !file functions

  // loadCris(id:any, from:any, collection:any,find:any){
  //   this.afs.collection(collection).ref
  //   .where(find,'==',id)
  //   .get()
  //   .then(info=>{
  //     let tempArr:any=[]
  //     info.forEach(data=>{
  //       let item:any = data.data();
  //       item.id = data.id;
  //       item.subject_full_title = item.title;
  //       item.short_title = item.subject;
  //       item.displayDate = item.doc_date;
  //       item.version = from;

  //       if(item.reg_business){
  //         this.afs.collection('Stage of Business').doc(item.reg_business).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_business_doc = info.payload.data()
  //         })
  //       }
  //       if(item.reg_case){
  //         this.afs.collection('Case Use').doc(item.reg_case).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_case_doc = info.payload.data()
  //         })
  //       }
  //       if(item.reg_division){
  //         this.afs.collection('Division').doc(item.reg_division).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_division_doc = info.payload.data()
  //         })
  //       }
  //       if(item.reg_juris){
  //         this.afs.collection('Jurisdiction').doc(item.reg_juris).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_juris_doc = info.payload.data()
  //         })
  //       }
  //       if(item.reg_lifeevent){
  //         this.afs.collection('Life Event').doc(item.reg_lifeevent).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_lifeevent_doc = info.payload.data()
  //         })
  //       }
  //       if(item.reg_sector){
  //         this.afs.collection('Sector').doc(item.reg_sector).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_sector_doc = info.payload.data()
  //         })
  //       }
  //       if(item.reg_stageoflife){
  //         this.afs.collection('Stage of Life').doc(item.reg_stageoflife).snapshotChanges()
  //         .subscribe(info=>{
  //           item.reg_stageoflife_doc = info.payload.data()
  //         })
  //       }
  //       tempArr.push(item)
  //     })
  //     if(from === 'existing'){
  //       this.currentExistingCris = tempArr[0]
  //       this.chooseVersion = this.currentExistingCris
  //     }
  //     if(from === 'proposed'){
  //       this.currentProposedCris = tempArr[0]
  //     }
  //   })
  //   .catch(err=>{
  //     if(from === 'existing')
  //       this.currentExistingCris ={ version: from }
  //     // else if(from === 'proposed')
  //     //   this.currentProposedCris = { version: from }
  //   })
  // }

  // chooseVersion(regulationName:any){
  //   if(regulationName === 'existing'){
  //     this.chosenCris = this.currentExistingCris;
  //   }
  //   else if(regulationName === 'proposed'){
  //     this.chosenCris = this.currentProposedCris;
  //   }
  // }

}
