<div class="container">
  
<div class="row mb-1">
  <div class="col-6">
    <label for=""><strong>Requirement</strong></label>
    
  </div>
  <div class="col-6">
  
    <button
    (click)="addRequirement()"
    mat-button class="float-end "
    [disabled]="standardEditMode"
  >
 <span><i class="fas fa-plus"></i> Add Requirement</span> 
  </button>
  </div>
</div>
<div class="row">
  <div *ngFor="let alerts of defaultAlert">
    <alert [dismissible]="true" [dismissOnTimeout]="alerts.timeout" [type]="alerts.type">
        <strong>Message: </strong> {{alerts.msg}}
    </alert>
</div>
</div> 
<form [formGroup]="requirementForm">

<mat-dialog-content>
  <div class="container-fluid">
    <div class="row full-height">

      <h4 *ngIf="requirements.controls.length == 0">Click add requirement</h4>
 
      <div class="row" >
        <div class="form-group row"  *ngIf="chooseany">
          <mat-form-field appearance="outline">
            <mat-label>{{requirement_Type == enum_req_type.STNDARD_WITH_TITLE ? 'Title': 'Situation'}} </mat-label>
            <textarea formControlName="title" matInput></textarea>
          </mat-form-field>

         
        </div>

        <div *ngIf="!isupdating" class="form-group row mb-2">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              class="form-check-input"
              id="chooseany"
              formControlName="chooseany"
              (change)="valuechange($event)"
              [(ngModel)]="chooseany"
            />
            <label class="form-check-label" for="chooseany"
              >With Substitute Requirement</label
            >
          </div>
        </div>
        
      </div>
  
        <div class="container" formArrayName="requirements">
  
          <mat-accordion>
            <div *ngFor="let requirement of requirements.controls; let i = index">
  
              <div  formGroupName="{{i}}">
                <mat-expansion-panel expanded="{{  data.requirementIndex == i ? true: false }}">
                  <mat-expansion-panel-header matTooltip="{{getRequirementName(i)}}">
                    <mat-panel-title class="text-bold">
                      {{i + 1 }}. {{ getRequirementName(i)?  (getRequirementName(i).length > 70 ) ? (getRequirementName(i) | slice:0:70) + '...' : getRequirementName(i) : isSituational? 'Situational Requirement':'Standard Requirement'}}
                    </mat-panel-title>
                    <mat-panel-description *ngIf="!isRequirementComplete(i)">
                       <span class="text-danger">Incomplete or Invalid Input/s!</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
  
                  
                  <div class="mx-1 row">
                    <div class="col">
                      
                      <!-- requirement inputs -->
                      <div class="row">
                        <div class="col-6">
                       
                          <mat-form-field style="width: 100%"   appearance="outline">
                            <mat-label>Documentary type</mat-label>
                            <mat-select  formControlName="requirementType" class="w-100" (selectionChange)="valueChanged('requirementType',i)">
                              <ng-container *ngFor="let option of type_of_requirement">
                                <mat-option [value]="option">{{option == "Id" ? "Identification Card": option == "Sample" ? "Non-Documentary" : option}}</mat-option>
                              </ng-container>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        
                      </div>
                      <div class="row">
                    
                        <mat-form-field style="width: 100%"  appearance="outline" >
                            <mat-label>Requirement Name</mat-label>
                            <input matInput placeholder="Input" type="text" formControlName="requirement"/>
                        </mat-form-field>
                      </div>
  
                         <div >
                          <!-- documentary type data-->
                         <ng-container [ngSwitch]="requirement.value.requirementType">
                          <!-- documentary -->
                          <div *ngSwitchCase="'Documentary'">
                            <label class="fw-bold">Documentary Copies</label>
                            <div  style="padding-top:1em;" class="d-flex flex-column">
                              <div class="d-flex flex-row align-items-center justify-content-evenly" style="padding-bottom:1em">
                             
                                <button type="button" mat-stroked-button class="btn btn-info "  (click)="addNode('standard','document',i)"><span class="material-icons">add_circle_outline</span> Add Document</button>
                                <button type="button" mat-stroked-button class="btn btn-info"  (click)="addNode('standard','operator',i)"><span class="material-icons">add_circle_outline</span> Add Operator</button>
                                <button type="button" mat-stroked-button class="btn btn-danger"  (click)="clearNodes('standard',i)"><span class="material-icons"> remove_circle </span> Clear</button>
                              
                              </div>
                              <div formArrayName="copyFormat" style="display: -moz-inline-grid; display: -ms-inline-grid; display: inline-grid">
                                <!-- min-width: 20em; -->
                                <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop('standard',$event,i)">
                                  <span class="example-box relative" *ngFor="let item of getcopyFormats(i).controls; let b = index;" cdkDrag [cdkDragDisabled]="item.value.disabled">
                                
                                    <div *ngIf="item.value.type == 'document'"  [formGroupName]="b"  class="d-flex flex-row">
                                                                                      <!-- style="width: 100%" -->
                                      <mat-form-field  floatLabel="never" style="width: 4em" appearance="outline">
                                        <mat-select formControlName="copyCount">
                                          <ng-container *ngFor="let option of numberOfCopiesWO0">
                                            <mat-option [value]="option">{{option}}</mat-option>
                                          </ng-container>
                                        </mat-select>
                                      </mat-form-field>
                       
                                      <mat-form-field   floatLabel="never" appearance="outline" style="width: 10em">
                                        <mat-select formControlName="val">
                                          <ng-container *ngFor="let option of document_type_options">
                                            <mat-option selected="{{(option == copyFormatValue(i,b) ) ? true : (option == 'Original copy') ? true : (option == 'Photo copy') ? true : (option == 'Electronic copy') ? true : (option == 'Certified True copy') ? true : false}}" [value]="option">{{option}}</mat-option>
                                          </ng-container>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
            
                                                                                      <!-- *ngIf="item.value.val == 'And' || item.value.val == 'Or'"  -->
                                    <ng-container  *ngIf="item.value.type == 'operator'" [formGroupName]="b">
                                      <mat-form-field   floatLabel="never" style="width: 5em" appearance="outline">
                                        <mat-select formControlName="val">
                                          <ng-container *ngFor="let option of copy_format_logic_options">
                                            <mat-option selected="{{(option == copyFormatValue(i,b) )?true:(option == 'And'?true:false)}}" [value]="option">{{option}}</mat-option>
                                          </ng-container>
                                          </mat-select>
                                      </mat-form-field>
                                    </ng-container>
                                                                                      <!-- (item.value.type == 'parenthesis' || item.value.type == 'parenthesis') &&  -->
                                    <a *ngIf="!requirement.value.copyFormatDisabled" style="cursor: pointer;" class="absolute upper-right remove" (click)="removeDocumentNode('standard',b,i)" title="Remove">
                                      <mat-icon class="example-tab-icon">remove_circle</mat-icon>
                                    </a>
                                  </span>
                                </div>

                               
                                
                              
                              </div>

                              <mat-error *ngIf="getcopyFormats(i).invalid">
                                <span style="color:red" >
                                  Invalid Format
                                </span>
                              </mat-error>
                              
                             
            
                              
                              
                            </div>

                            <div class="documentary-copies-preview-box" [ngClass]="getcopyFormats(i).invalid ? 'documentary-copies-preview-box-error' : ''">
                              <label class="fw-bold">Preview:</label>
                              <hr class="no-margin">
                              <div style="padding-left: 1em">
                                <ng-container *ngFor="let item of getcopyformatArray(i)">
                                  <ng-container [ngSwitch]="item.type">
                                    <ng-container  *ngSwitchCase="'document'">
                                      ({{item.copyCount ? item.copyCount : '-'}}) {{item.val}}
                                    </ng-container>
                                    <ng-container  *ngSwitchCase="'operator'">
                                      <ng-container [ngSwitch]="item.val">
                                        <ng-container  *ngSwitchCase="'Or'">
                                          <br>
                                          {{item.val.toLowerCase()}}
                                          <br>
                                        </ng-container>
                                        <ng-container  *ngSwitchDefault>
                                          {{item.val.toLowerCase()}}
                                        </ng-container>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </div>
                          </div>
            
                          <!-- sample -->
                          <div *ngSwitchCase="'Sample'" class="row">
                            <div class="col-6">
                            
                              <mat-form-field style="width: 100%"   appearance="outline">
                                <mat-label>Number/Amount/Scale</mat-label>
                                <input matInput placeholder="Input" type="text" formControlName="number"/>
                              </mat-form-field>
                            </div>
                            <div class="col-6">

                              <mat-form-field style="width: 100%"  appearance="outline">
                                <mat-label>Unit of Measurement</mat-label>
                                <input matInput placeholder="Input" type="text" formControlName="unit"/>
                     
                              </mat-form-field>
                            </div>
                          </div>
            
                          <!-- id -->
                          <div *ngSwitchCase="'Id'">
                            <label class="fw-bold">List of ID's (Indicate name of ID and Agency)</label>
                            <!--to do : phase 3.2 <ng-container>
                                <div *ngFor="let id of identification_list">
                                    <div class="row">
                                      <label for="{{id.id}}"><span class="fw-bold">{{id.identification}} </span><br> {{id.bureau_agency}}</label>
                                      <input type="checkbox" id="{{id.id}}">
                                    </div>
                                </div>
                            </ng-container>-->
                            <mat-form-field style="width: 100%"  floatLabel="never">
                              <input matInput hidden/>
                              <editor
                                formControlName="listOfIds"
                                [init]="{
                                  base_url: '/tinymce',
                                  suffix: '.min',                    
                                  height: 250,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                }"
                              ></editor>
                            </mat-form-field>
                          </div>
                      </ng-container>
                   
                      
                      <!-- where to secure -->
                      <ng-container *ngIf="requirement.value.requirementType == 'Documentary' || requirement.value.requirementType == 'Sample'">
                        <label class="fw-bold" style="color:blueviolet; margin-top:1em;">Where to Secure</label>
                        <div class="row">
                          <div class="form-check form-check-inline">
                            <input
                            style="font-size: 12px;"
                            (change)="removeAgencyDivision($event,i)"
                              type="checkbox"
                              id="applicant_{{i}}"
                              formControlName="applicant"
                            />
                            <label style="font-size: 12px;" class="mt-1 mb-1" for="applicant_{{i}}"
                              > Check if Client will produce the requirement</label
                            >
                          </div>
                        </div>
                        <div *ngIf="requirement.value.applicant" class="row ms-3">
                            <p>Client</p>
                        </div>
                        <div *ngIf="!requirement.value.applicant" class="row">
                          <div class="col-6">
                          
                            <mat-form-field style="width: 100%"   appearance="outline">
                                <mat-label>Agency</mat-label>
                              <input required matInput placeholder="Input" type="text" formControlName="agency"/>
                             
                            </mat-form-field>
                          </div>
                          <div class="col-6">
                            
                            <mat-form-field style="width: 100%"   appearance="outline">
                                <mat-label>Bureau/Division</mat-label>
                              <input required matInput placeholder="Input" type="text" formControlName="bureau_division"/>
                            </mat-form-field>
                          </div>
                        </div>
                      </ng-container>
            
                      <!-- remarks -->
                          
                         </div>
                      <div>

                        <label class="fw-bold">General Remarks</label>
                        <mat-form-field style="width: 100%"  floatLabel="never">
                          <input matInput hidden/>
                          <editor
                            formControlName="remarks_or_reminders"
                            [init]="{
                              base_url: '/tinymce',
                              suffix: '.min',                    
                              height: 250,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                            }"
                          ></editor>
                        </mat-form-field>
                       
                       <!-- <mat-form-field style="width: 100%"   appearance="outline">
                            <mat-label>General Remarks</mat-label>
                          <textarea  matInput placeholder="Input" cdkAutosizeMaxRows="5" type="text" formControlName="remarks_or_reminders"></textarea>
  
                          
                        </mat-form-field>-->
                      </div>
                    </div>
                  
                  </div>
  
                  <div class="float-end">
                    <button  mat-button color="warn" [matMenuTriggerFor]="menu">
                      <span> <mat-icon>delete</mat-icon> Remove Requirement</span>
                    </button>
                    <mat-menu #menu="matMenu">
                      <div class="container">
                        <div class="row">
                          Are you sure?
                        </div>
                        <button (click)="removeRequirement(i)" mat-button color="warn">Yes</button>
                        <button  mat-button >No</button>
                      
                      </div>
                    </mat-menu>
                  </div>
  
  
                </mat-expansion-panel>
              </div>

              <div *ngIf="chooseany && requirements.controls.length - 1 != i" class="row">or</div>
  
            </div>
          </mat-accordion>
  
        </div>
  
  

  
     
    </div>

  </div>
   
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="warn" mat-dialog-close>Cancel</button>
  <button
    (click)="saveRequirement()"
    mat-flat-button
    color="primary"
  >
    Save Requirement
  </button>
</mat-dialog-actions>
</form>
</div>
  
  