import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { tap } from 'rxjs/operators';
import { GovernmentAccountSubtype, RemarkLevelCitizensCharter, RemarkLevelRoleMappingCitizensCharter, RemarkResolvedStatusCitizensCharter } from 'src/app/entities/worklist';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { ArtemisEditRemarkReplyComponent } from '../artemis-edit-remark-reply/artemis-edit-remark-reply.component';


export enum RemarkLevel {
  UNRESOLVED = 'Unresolved',
  RESOLVED = 'Resolved',
  DELETED = 'Deleted'
}

export enum RemarkView{
  AGENCY = 'Agency',
  ARTA = 'ARTA'
}

export const RemarkViewRoleMapping: any =  {

  [GovernmentAccountSubtype.AGENCY_ENC] : [RemarkView.AGENCY , RemarkView.ARTA] ,
  [GovernmentAccountSubtype.AGENCY_VER] :  [RemarkView.AGENCY , RemarkView.ARTA],
  [GovernmentAccountSubtype.AGENCY_DIR] :  [RemarkView.AGENCY , RemarkView.ARTA],
  [GovernmentAccountSubtype.ARTA_CMEO_PO] : [RemarkView.ARTA],
  [GovernmentAccountSubtype.ARTA_CMEO_DC] :[RemarkView.ARTA],
  [GovernmentAccountSubtype.ARTA_CMEO_DIR] : [RemarkView.ARTA],

}

@Component({
  selector: 'app-artemis-service-remark',
  templateUrl: './artemis-service-remark.component.html',
  styleUrls: ['./artemis-service-remark.component.scss']
})
export class ArtemisServiceRemarkComponent implements OnInit {
[x: string]: any;

  @Input() remark : any

  @Input() remark_form : UntypedFormGroup

  @Input() allowedAction: boolean =true

  isArtaRemark: boolean =false

  enum_remark_resolved_status = RemarkResolvedStatusCitizensCharter;
  remark_level = RemarkLevelCitizensCharter
  user_position: any;
  position_type = GovernmentAccountSubtype

  can_resolve: boolean
  can_view: boolean ;
  view_level  = RemarkView
  limit :number = 4
  cur_user_id : any

  constructor(
    private remarkService : GovServiceService,
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    private dialog: MatDialog
  ) { 


  }

  ngOnInit(): void {

    this.check_user_id()
    this.loadReplies();
  }
  sending:boolean =false
   writeReply(component: any){
    this.sending =true
    this.remarkService.writeReply(this.remark._id,component).then(()=>{
      this.sending =false
      component.value = ""
    })
 
   
  }

  remove(){
    this.remarkService.removeComment(this.remark._id)
  }

   async check_user_id() {

      const user = await this.auth.currentUser;

      const user_position = sessionStorage.getItem('account_subtype') || '';

      if(user_position){
        this.user_position = user_position
      }

      const level_type :RemarkLevel = RemarkViewRoleMapping[this.user_position];

      if(level_type.indexOf(this.remark.level) != -1){
          this.can_view =true;
      }

      if(this.remark.level == this.view_level.ARTA){
        this.isArtaRemark=true;
      }
      
      if(user?.uid){
        if(user.uid === this.remark.user.user_id){
          this.can_resolve =true
        }else{
          this.can_resolve =false
        }
        this.cur_user_id =user.uid
        
      }else  this.can_resolve =false
  }

  resolve(){
    this.remarkService.resolveComment(this.remark._id,this.remark)
  }

  replies:any = []

  loadReplies(){
    this.afs.collection("Service Remarks").doc(this.remark._id).collection("Remark Reply",
      filter => filter.orderBy("date",'desc')
    ).snapshotChanges()
    .pipe(tap((data:any)=>{
      this.replies = []
      data.forEach((info:any)=>{
        let reply: any = info.payload.doc.data();
        reply.id = info.payload.doc.id;

        this.replies.push(reply)
      })
      this.total = this.replies.length
    })).subscribe()

    
  }

  total:number = 0;

  seeMoreReply(){
    if(this.limit == 4){
      this.limit = this.total;
    }else{
      this.limit = 4
    }

  }

  edit(isReply:boolean =false,object :any){

    this.dialog.open(ArtemisEditRemarkReplyComponent,{
      width: '50vw',
      height: '50vh',
      maxWidth: '50vw',
      maxHeight: '60vh',
      data : {
        obj : {
          message: object.message,
          remark_id : this.remark._id,
          type: isReply ? "Reply" : "Remark",
          user: object.user,
          reply_id : isReply? object.id : ''
        }
      }
    })
  }

  removeReply(reply:any){
    this.afs.collection("Service Remarks").doc(this.remark._id)
    .collection("Remark Reply").doc(reply.id).delete()
  }

}
