<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="!isLoaded">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>


<div class="content" *ngIf="isLoaded">
    <div class="container-fluid">
        <div class="card shadow p-3">
            <div class="card-body form-container">
                <div class="card-header border-bottom border-4 border-dark">
                    <h3>PIA</h3>                           
                </div>
                <div style="padding: 1em;">

                    <!-- summary -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Summary of the regulatory proposal</b></h5></label
                        >
                        <div style="padding-left:1em;">
                            <div [innerHtml]="regulation.pia_sections.pia_summary"></div>
                        </div>
                    </div>

                    <!-- sec1 -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Section 1 - Identification of the policy problem</b></h5></label
                        >
                        <div style="padding-left:1em;">
                            <div [innerHtml]="regulation.pia_sections.pia_section_1.policy_problem_desc"></div>

                            <div class="d-flex justify-content-center"  *ngIf="regulation.pia_sections.pia_section_1.file_type == 'zip'; else pia1NotZipFile">
                                <button class="btn btn-primary" type="button" (click)="downloadFile(regulation.pia_sections.pia_section_1.file_download_link)">
                                    Section 1 - Download: {{regulation.pia_sections.pia_section_1.fileName}}
                                </button> 
                            </div>
                            <ng-template #pia1NotZipFile>
                                <ng-container *ngIf="regulation.pia_sections.pia_section_1 && regulation.pia_sections.pia_section_1.file">
                                    <app-pdf-file-view [pdfFileData]="regulation.pia_sections.pia_section_1"></app-pdf-file-view>
                                </ng-container>
                                <!-- <ng-template #noPDFData>
                                    <div class="card d-flex justify-content-center" style="padding:1em;">
                                        <div class="row" style="padding:0.5em;">
                                            <label class="form-input-header fw-bold" for="feedback_remark">
                                                <h3><b>No PIA Section 1 no  Document</b></h3>
                                            </label>
                                        </div>
                                    </div>
                                </ng-template> -->
                                <!-- <div style="width: 700px;" *ngIf="regulation.pia_sections.pia_section_1.policy_problem_file" class="row minispace">
                                    <div class="d-flex justify-content-center">
                                        <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia1')" [stick-to-page]="true" [page]="regulation.pia_sections.pia_section_1.policy_problem_file_page" [show-all]="false" [original-size]=false [src]="regulation.pia_sections.pia_section_1.policy_problem_file"></pdf-viewer>
                                    </div>
                                    <div class="d-flex justify-content-evenly">
                                        <button (click)="pageShift('back','pia1')" class="btn btn-outline-primary"><</button>
                                        <div><p>{{regulation.pia_sections.pia_section_1.policy_problem_file_page}} / {{regulation.pia_sections.pia_section_1.policy_problem_file_total_page}}</p></div>
                                        <button (click)="pageShift('next','pia1')" class="btn btn-outline-primary">></button>
                                    </div>
                                </div> -->
                            </ng-template>
                        </div>
                    </div>

                    <!-- sec2 -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Section 2 - Objectives of government action</b></h5></label
                        >
                        <div style="padding-left:1em;">
                            <div [innerHtml]="regulation.pia_sections.pia_section_2"></div>
                        </div>
                    </div>

                    <!-- sec3 -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Section 3 - Consideration of alternative options</b></h5></label>
                        <div style="padding-left:1em;" *ngFor="let pia3 of regulation.pia_sections.pia_section_3">
                            <label class="form-input-header fw-bold" for="feedback_remark"
                                ><h6><b>{{pia3.policy_option}}</b></h6></label>
                            <div style="padding-left:1em;" >
                                <div class="row">
                                    <div class="col-2 fw-bold"><label class="form-input-header fw-bold" for="subject">Option Type</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <p>{{pia3.policy_option_type}}</p>
                                    </div>
                                    <div>
                                        <p class="desc">{{pia3.policy_option_desc}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- sec4 -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Section 4 - Impact analysis of policy options</b></h5></label>
                        <div style="padding-left:1em;" *ngFor="let pia4 of regulation.pia_sections.pia_section_4; let i = index">
                            <label class="form-input-header fw-bold" for="feedback_remark"
                                ><h6><b>{{pia4.policy_option}}</b></h6></label>
                            <div style="padding-left:1em;">
                                <div class="row minispace">
                                    <label class="form-input-header fw-bold">Impacts :</label>
                                    <div class="p-3 d-flex justify-content-evenly">
                                        <p><mat-icon>{{pia4.impacts.impact_economic ? "check_circle_outline" : "cancel"}}</mat-icon> Economic</p>
                                        <p><mat-icon>{{pia4.impacts.impact_social ? "check_circle_outline" : "cancel"}}</mat-icon> Social</p>
                                        <p><mat-icon>{{pia4.impacts.impact_disaster_risk ? "check_circle_outline" : "cancel"}}</mat-icon> Disaster Risk</p>
                                        <p><mat-icon>{{pia4.impacts.impact_environment ? "check_circle_outline" : "cancel"}}</mat-icon> Environment</p>
                                    </div>
                                </div>

                                <div class="row minispace">
                                    <label class="form-input-header fw-bold" for="benefits_desc"
                                        ><b>Narrative of Benefits :</b></label>
                                    <p class="desc">{{pia4.benefits_desc}}</p>
                                </div>

                                <div class="row minispace">
                                    <label class="form-input-header fw-bold" for="costs_desc"
                                        ><b>Narrative of Costs :</b></label>
                                    <p class="desc">{{pia4.costs_desc}}</p>
                                </div>

                                <div class="row minispace">
                                    <label class="form-input-header fw-bold" for="impacts_desc"
                                        ><b>Narrative of Impacts :</b></label>
                                    <p class="desc">{{pia4.impacts_desc}}</p>
                                </div>

                                <!-- <div style="width: 700px;" *ngIf="pia4.compliance_file" class="row minispace">
                                    
                                    <div class="d-flex justify-content-center">
                                        <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia4',i)" [stick-to-page]="true" [page]="pia4.compliance_file_page" [show-all]="false" [original-size]=false [src]="pia4.compliance_file"></pdf-viewer>
                                    </div>
                                    <div class="d-flex justify-content-evenly">
                                        <button (click)="pageShift('back','pia4',i)" class="btn btn-outline-primary"><</button>
                                        <div><p>{{pia4.compliance_file_page}} / {{pia4.compliance_file_total_page}}</p></div>
                                        <button (click)="pageShift('next','pia4',i)" class="btn btn-outline-primary">></button>
                                    </div>
                                </div> -->
                                
                                <div class="row minispace" *ngIf="regulation && regulation.pia_sections.pia_section_4_file" style="padding-left:1em;">
                                    <div class="d-flex justify-content-center" *ngIf="regulation.pia_sections.pia_section_4_file.file_type == 'zip'; else pia4NotZipFile">
                                        <button class="btn btn-primary" type="button" (click)="downloadFile(regulation.pia_sections.pia_section_4_file.file_download_link)">
                                            Section 4 - Download: {{regulation.pia_sections.pia_section_4_file.fileName}}
                                        </button>
                                    </div>
                                    <ng-template #pia4NotZipFile>
                                        <ng-container *ngIf="regulation.pia_sections.pia_section_4_file && regulation.pia_sections.pia_section_4_file.file">
                                            <app-pdf-file-view [pdfFileData]="regulation.pia_sections.pia_section_4_file"></app-pdf-file-view>
                                        </ng-container>
                                        <!-- <ng-template #noPDFData>
                                            <div class="card d-flex justify-content-center" style="padding:1em;">
                                                <div class="row" style="padding:0.5em;">
                                                    <label class="form-input-header fw-bold" for="feedback_remark">
                                                        <h3><b>No PIA Section 4 Document</b></h3>
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-template> -->
                                    </ng-template>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <!-- sec5 -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Section 5 - Consultation</b></h5></label>
                        <div style="padding-left:1em;" *ngFor="let pia5 of regulation.pia_sections.pia_section_5; let i = index">
                            <label class="form-input-header fw-bold" for="feedback_remark"
                                ><h6><b>{{pia5.consultation_title}}</b></h6></label>
                            <div style="padding-left:1em;">
                                <div class="row minispace">
                                    <div class="row">
                                        <div class="col-7 fw-bold"><label class="form-input-header fw-bold" for="subject">Your Agency already conducted a consultation on this policy</label></div>
                                        <div class="col-1">:</div>
                                        <div class="col">
                                            <mat-icon>{{pia5.has_agency_consulted ? "check_circle_outline" : "cancel"}}</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="row minispace">
                                    <label class="form-input-header fw-bold">Methods  :</label>
                                    <div class="p-3 d-flex justify-content-evenly">
                                        <p><mat-icon>{{pia5.methods.method_public_commenting ? "check_circle_outline" : "cancel"}}</mat-icon> Public Commenting</p>
                                        <p><mat-icon>{{pia5.methods.method_forum ? "check_circle_outline" : "cancel"}}</mat-icon> Public Forum</p>
                                        <p><mat-icon>{{pia5.methods.method_focus_group ? "check_circle_outline" : "cancel"}}</mat-icon> Focus Group Discussion</p>
                                        <p><mat-icon>{{pia5.methods.method_other ? "check_circle_outline" : "cancel"}}</mat-icon> Other</p>
                                    </div>
                                </div>
                                <div class="row minispace">
                                    <label class="form-input-header fw-bold" for="impacts_desc"
                                        ><b>Other Method :</b></label>
                                    <p class="desc">{{pia5.method_other_desc}}</p>
                                </div>
                                <div class="row minispace">
                                    <label class="form-input-header fw-bold" for="impacts_desc"
                                        ><b>Summary :</b></label>
                                    <p class="desc">{{pia5.consultation_desc}}</p>
                                </div>
                                <div class="row minispace">
                                    <label class="form-input-header fw-bold" for="impacts_desc"
                                        ><b>Participants :</b></label>
                                    <table class="table table-responsive table-hover">
                                        <thead>
                                            <tr>
                                                <th class="fw-bold">Stakeholder</th>
                                                <th class="fw-bold">Commenting As</th>
                                                <th class="fw-bold">Gender</th>
                                                <th class="fw-bold">Age</th>
                                                <th class="fw-bold">Income Class</th>
                                                <th class="fw-bold">Size of firm</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <ng-container *ngFor="let participant of pia5.participants">
                                                <tr>
                                                    <th class="fw-bold" rowspan="2">{{participant.stakeholder}}</th>
                                                    <td class="fw-bold">{{participant.commenting_as}}</td>
                                                    <td class="fw-bold">{{participant.gender}}</td>
                                                    <td class="fw-bold">{{participant.age}}</td>
                                                    <td class="fw-bold">{{participant.income_class}}</td>
                                                    <td class="fw-bold">{{participant.size_of_firm}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="5">
                                                        <div class="row">
                                                            <div class="col">
                                                                <label class="form-input-header fw-bold" for="impacts_desc"
                                                                    ><b>Issues and Concerns :</b></label>
                                                                <p class="desc">{{participant.issues_and_concerns}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <label class="form-input-header fw-bold" for="impacts_desc"
                                                                    ><b>Actions :</b></label>
                                                                <p class="desc">{{participant.actions_taken}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- sec6 -->
                    <div class="form-group space">
                        <label class="form-input-header fw-bold" for="feedback_remark"
                            ><h5><b>Section 6 - Conclusion and recommended option</b></h5></label>
                        <div style="padding-left:1em;">
                            <div class="row minispace">
                                <div class="row">
                                    <div class="col-4 fw-bold"><label class="form-input-header fw-bold" for="subject">Recommended Policy Option</label></div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <p>{{regulation.pia_sections.pia_section_6.recommended_policy_option}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row minispace">
                                <label class="form-input-header fw-bold" for="impacts_desc"
                                    ><b>Summary of Impact Analysis and Justification for Recommended Policy Option :</b></label>
                                <p class="desc">{{regulation.pia_sections.pia_section_6.impact_summary_desc}}</p>
                            </div>
                            <div class="row minispace">
                                <label class="form-input-header fw-bold" for="impacts_desc"
                                    ><b>Explanation for Implementation and Compliance of Stakeholders :</b></label>
                                <p class="desc">{{regulation.pia_sections.pia_section_6.compliance_desc}}</p>
                            </div>
                            <div class="row minispace">
                                <div *ngIf="regulation && regulation.pia_sections.pia_section_6" style="padding-left:1em;">
                                    <div class="d-flex justify-content-center" *ngIf="regulation.pia_sections.pia_section_6.file_type == 'zip'; else pia6NotZipFile">
                                        <button class="btn btn-primary" type="button" (click)="downloadFile(regulation.pia_sections.pia_section_6.file_download_link)">
                                            Section 6 - Download: {{regulation.pia_sections.pia_section_6.fileName}}
                                        </button>
                                    </div>
                                    <ng-template #pia6NotZipFile>
                                        <ng-container *ngIf="regulation.pia_sections.pia_section_6 && regulation.pia_sections.pia_section_6.file">
                                            <app-pdf-file-view [pdfFileData]="regulation.pia_sections.pia_section_6"></app-pdf-file-view>
                                        </ng-container>
                                        <!-- <ng-template #noPDFData>
                                            <div class="card d-flex justify-content-center" style="padding:1em;">
                                                <div class="row" style="padding:0.5em;">
                                                    <label class="form-input-header fw-bold" for="feedback_remark">
                                                        <h3><b>No PIA Section 6 Document</b></h3>
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-template> -->
                                        <!-- <div style="width: 700px;" *ngIf="regulation.pia_section_6.consultation_and_recommendation_file" class="row minispace">
                                            <div class="d-flex justify-content-center">
                                                <pdf-viewer style="width: 700px;" (after-load-complete)="afterLoadComplete($event,'pia6')" [stick-to-page]="true" [page]="regulation.pia_section_6.consultation_and_recommendation_file_page" [show-all]="false" [original-size]=false [src]="regulation.pia_section_6.consultation_and_recommendation_file"></pdf-viewer>
                                            </div>
                                            <div class="d-flex justify-content-evenly">
                                                <button (click)="pageShift('back','pia6')" class="btn btn-outline-primary"><</button>
                                                <div><p>{{regulation.pia_section_6.consultation_and_recommendation_file_page}} / {{regulation.pia_section_6.consultation_and_recommendation_file_total_page}}</p></div>
                                                <button (click)="pageShift('next','pia6')" class="btn btn-outline-primary">></button>
                                            </div>
                                        </div> -->
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>