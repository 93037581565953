<mat-tab-group
  dynamicHeight
  mat-align-tabs="center"
  class="parent-tab-group"
  #tabGroup
  [selectedIndex]="activeTabIndex"
  (selectedTabChange)="tabChanged($event)"
>
  <!-- Service Details START --> 
  <mat-tab label="(1) Service Information" [aria-label]="validityMap?.serviceForm === false ? 'error' : ''"> 
    <ng-template matTabContent>
    <div class="row full-height">
      <div class="col-1"></div>
      <div class="col-10">
        <mat-dialog-content class="form">
          <form [formGroup]="service_name_and_details_form">
            <div class="form-header">
              <h3>Service Information</h3>
            </div>
            <div class="form-group mt-5 row">
              <div class="col">
                <label class="form-input-header" for="servicename "
                  >Service Name
                </label>
                <mat-form-field
                  style="width: 100%"
                  hideRequiredMarker
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Input"
                    type="text"
                    formControlName="service_name"
                  />
                  <mat-hint
                    *ngIf="
                      !service_name_and_details_form.get('service_name')
                        ?.touched
                    "
                    >Required</mat-hint
                  >
                  <mat-error
                    style="color: red"
                    *ngIf="
                      service_name_and_details_form.get('service_name')?.errors
                    "
                    >Input a value</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col">
                <label class="form-input-header" for="servicecategory "
                  >Service Category</label
                >
                <mat-form-field
                  style="width: 100%"
                  hideRequiredMarker
                  floatLabel="never"
                >
                  <mat-select formControlName="service_category">
                    <mat-option value="" disabled selected
                      >Service Category</mat-option
                    >
                    <mat-option
                      *ngFor="let row of valueSuggestions.service_category"
                      [value]="row.name"
                      >{{ row.name }}</mat-option
                    >
                  </mat-select>
                  <mat-hint
                    *ngIf="
                      !service_name_and_details_form.get('service_category')
                        ?.touched
                    "
                    >Required</mat-hint
                  >
                  <mat-error
                    style="color: red"
                    *ngIf="
                      service_name_and_details_form.get('service_category')
                        ?.errors
                    "
                    >Select a value</mat-error
                  >
                </mat-form-field>
                <!--<input type="text " class="form-control " formControlName="service_category " />-->
              </div>
            </div>
            <div class="form-group mt-5">
              <label class="form-input-header" for="service_description "
                >Service Description
              </label>
              <mat-form-field
                style="width: 100%"
                hideRequiredMarker
                floatLabel="never"
              >
                <!-- <input
                  matInput
                  placeholder="Input"
                  type="text"
                  formControlName="service_description"
                /> -->
                <input matInput hidden/>
                <editor
                  formControlName="service_description"
                  [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',                    
                    height: 250,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help'
                  }"
                ></editor>
                <mat-hint
                  *ngIf="
                    !service_name_and_details_form.get('service_description')
                      ?.touched
                  "
                  >Required</mat-hint
                >
                <mat-error
                  style="color: red"
                  *ngIf="
                    service_name_and_details_form.get('service_description')
                      ?.errors
                  "
                  >Input a value</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-group mt-5">
              <input type="checkbox" />
              <label class="" for="interagency ">
                Inter-agency Submission</label
              >
            </div>
            <div class="form-group mt-5 row">
              <div class="col">
                <label class="form-input-header" for="classification "
                  >Classification
                </label>
                <mat-form-field
                  style="width: 100%"
                  hideRequiredMarker
                  floatLabel="never"
                >
                  <mat-select
                    name="classification"
                    formControlName="classification"
                  >
                    <mat-option value="" disabled selected
                      >Classification</mat-option
                    >
                    <mat-option
                      *ngFor="let row of valueSuggestions.classification"
                      [value]="row.name"
                      >{{ row.name }}</mat-option
                    >
                  </mat-select>
                  <mat-hint
                    *ngIf="
                      !service_name_and_details_form.get('classification')
                        ?.touched
                    "
                    >Required</mat-hint
                  >
                  <mat-error
                    style="color: red"
                    *ngIf="
                      service_name_and_details_form.get('classification')
                        ?.errors
                    "
                    >Select a value</mat-error
                  >
                </mat-form-field>
                <!--<input type="text " class="form-control " formControlName="classification " />-->
              </div>
              <div class="col">
                <label class="form-input-header" for="type_of_transaction"
                  >Type of Transaction
                </label>
                <div formGroupName="type_of_transaction" class="mt-3">
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="type_g2c"
                      formControlName="type_g2c"
                      value="G2C"
                    />
                    <label class="form-check-label" for="type_g2c">G2C</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="type_g2g"
                      formControlName="type_g2g"
                      value="G2G"
                    />
                    <label class="form-check-label" for="type_g2g">G2G</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="type_g2b"
                      formControlName="type_g2b"
                      value="G2B"
                    />
                    <label class="form-check-label" for="type_g2b">G2B</label>
                  </div>

                  <mat-error
                    style="color: red"
                    *ngIf="
                      service_name_and_details_form.get('type_of_transaction')
                        ?.errors
                    "
                    >Select at least one value</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="form-group mt-5 row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-input-header" for="agency "
                      >Agency
                    </label>
                    <mat-form-field
                      style="width: 100%"
                      hideRequiredMarker
                      floatLabel="never"
                    >
                      <mat-select name="agency " formControlName="agency">
                        <mat-option value="" disabled selected
                          >Agency</mat-option
                        >
                        <!-- 
                          [value]="row.name" -->
                        <mat-option
                          *ngFor="let row of valueSuggestions.agency"
                          [value]="row.name ? row.name : row.agencyDetails ? row.agencyDetails.agencyName ? row.agencyDetails.agencyName : '' : ''"
                          >{{ row.name ? row.name : row.agencyDetails ? row.agencyDetails.agencyName ? row.agencyDetails.agencyName : '' : ''  }}</mat-option
                        >
                      </mat-select>
                      <mat-hint
                        *ngIf="
                          !service_name_and_details_form.get('agency')?.touched
                        "
                        >Required</mat-hint
                      >
                      <mat-error
                        style="color: red"
                        *ngIf="
                          service_name_and_details_form.get('agency')?.errors
                        "
                        >Select a value</mat-error
                      >
                    </mat-form-field>
                    <!--<input type="text " class="form-control " formControlName="agency " />-->
                  </div>
                  <div class="col">
                    <label class="form-input-header" for="process_owner"
                      >Process Owner</label
                    >
                    <mat-form-field
                      style="width: 100%"
                      hideRequiredMarker
                      floatLabel="never"
                    >
                      <mat-select formControlName="process_owner">
                        <mat-option value="" disabled selected>Division</mat-option>
                        <mat-option
                          *ngFor="let row of valueSuggestions.division"
                          [value]="row.name"
                          >{{ row.name }}</mat-option
                        >
                      </mat-select>
                      <mat-hint
                        *ngIf="
                          !service_name_and_details_form.get('process_owner')
                            ?.touched
                        "
                        >Required</mat-hint
                      >
                      <mat-error
                        style="color: red"
                        *ngIf="
                          service_name_and_details_form.get('process_owner')
                            ?.errors
                        "
                        >Input a value</mat-error
                      >
                    </mat-form-field>
                  </div>                  
                </div>
              </div>
            </div>

            <div class="form-group mt-5 row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-input-header" for="office_division "
                      >Office/Division</label
                    >
                    <mat-form-field
                      style="width: 100%"
                      hideRequiredMarker
                      floatLabel="never"
                    >
                      <input matInput hidden/>
                      <editor
                        formControlName="office_division"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>
                      <mat-hint
                        *ngIf="
                          !service_name_and_details_form.get('office_division')
                            ?.touched
                        "
                        >Required</mat-hint
                      >
                      <mat-error
                        style="color: red"
                        *ngIf="
                          service_name_and_details_form.get('office_division')
                            ?.errors
                        "
                        >Input a value</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <label class="form-input-header" for="who_may_avail "
                      >Who may avail</label
                    >
                    <mat-form-field
                      style="width: 100%"
                      hideRequiredMarker
                      floatLabel="never"
                    >
                      <!-- <input
                        matInput
                        placeholder="Input"
                        type="text"
                        formControlName="who_may_avail"
                      /> -->
                      <input matInput hidden/>
                      <editor
                        formControlName="who_may_avail"
                        [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>
                      <mat-hint
                        *ngIf="
                          !service_name_and_details_form.get('who_may_avail')
                            ?.touched
                        "
                        >Required</mat-hint
                      >
                      <mat-error
                        style="color: red"
                        *ngIf="
                          service_name_and_details_form.get('who_may_avail')?.errors
                        "
                        >Input a value</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-5 row">
              <div class="col">
                <label class="form-input-header" for="operating_hours "
                  >Operating Hours
                </label>
                <mat-form-field
                  style="width: 100%"
                  hideRequiredMarker
                  floatLabel="never"
                >
                  <mat-select formControlName="operating_hours" name="operating_hours" 
                  (ngModelChange)="getOthers($event)" >
                    <mat-option *ngFor="let hrs of operatingHours" [value]="hrs.value">
                      {{hrs.viewValue}}
                    </mat-option>
                  </mat-select>
                    <mat-hint
                      *ngIf="
                        !service_name_and_details_form.get('operating_hours')
                          ?.touched
                      "
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="
                        service_name_and_details_form.get('operating_hours')
                          ?.errors
                      "
                      >Input a value</mat-error
                    >
                </mat-form-field>
              </div>
              <div class="col">
                <div  *ngIf="service_name_and_details_form.value.operating_hours == 'Others'">
                  <label class="form-input-header" for="operating_hours "
                  >Other Operating Hours
                  </label>
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <input
                      matInput
                      placeholder="Input"
                      type="text"
                      formControlName="operating_hours_other" name="operating_hours_other" 
                    />
                    <mat-hint
                      *ngIf="
                        !service_name_and_details_form.get('operating_hours_other')
                          ?.touched
                      "
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="
                        service_name_and_details_form.get('operating_hours_other')
                          ?.errors
                      "
                      >Input a value</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="form-group mt-5 row">
              <div class="col">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="has_statute"
                  formControlName="has_statute"
                  value="true"
                  [(ngModel)]="isChecked"
                />
                <label class="" for="has_statute">
                  Check if Service is based on a statute</label
                >
              </div>
              <div class="col">
                <label class="form-input-header" for="statute ">Statute </label>
                <mat-form-field
                  style="width: 100%"
                  hideRequiredMarker
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Input"
                    type="text"
                    formControlName="statute"
                    [required]="isChecked"
                  />
                  <mat-hint
                    *ngIf="
                    isChecked && !service_name_and_details_form.get('statute')?.touched
                    "
                    >Required</mat-hint
                  >
                  <mat-error
                    style="color: red"
                    *ngIf="service_name_and_details_form.get('statute')?.errors"
                    >Input a value</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
            <!--hotFIX hide total total fees-->
            <div *ngIf="false" class="form-group mt-5 row">
              <div class="col"></div>
              <div class="col">
                <label class="form-input-header" for="total_fees "
                  >Total Fees
                </label>
                <p [innerHtml]="service_name_and_details_form.get('total_fees')?.value"></p>
              </div>
            </div>

            <!-- RWL 20230308 Schedule of fees file upload to be hidden and eventually removed if the reworked fees input proves sufficient -->
            <!-- <div class="form-group mt-5 row" [hidden]="hideFeesUpload"> -->
            <div class="form-group mt-5 row" [hidden]="true">
              <!-- upload files goes here -->
              <div class="form-group">
                <label class="form-input-header" for="title"
                  >Scheduling of Fees:</label
                >
                <input
                  type="file"
                  (change)="saveFileInformation($event)"
                  class="form-control"
                  ng-required="true"
                  style="display: none"
                  #schedule_of_fees_file_input
                />
                <div class="file-input p-3">
                  <label>Drop files here or </label>
                  <a class="browse"
                    (click)="schedule_of_fees_file_input.click()"
                  >
                  Browse
                </a>
                </div>
              </div>
              <div class="form-group" for="schedule_of_fees_file_input">
                Valid file types include gif, jpg, jpeg, png. PDF files are also
                allowed. Each file cannot exceed 10MB.
              </div>
              <div
                class="form-helper text-danger"
                *ngIf="showScheduleOfFeesFileUploadErrors"
              >
                Invalid file type
              </div>
              <div class="form-group">
                <div class="col-3">
                  <div class="progress" *ngIf="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [style.width]="progress+'%'"> 
                      <span>{{progress}}%</span>
                    </div>
                  </div>
                  <div *ngIf="previewImgScheduleOfFees" class="relative">
                    <img
                      [src]="previewImgScheduleOfFees"
                      class="img-fluid img-thumbnail"
                    />
                    <a class="absolute upper-right-upload" style="cursor: pointer;" (click)="removeUploadedFee()">x</a>
                  </div>

                  <!-- Div for uploaded PDFs -->
                  <div *ngIf="!previewImgScheduleOfFees && schedule_of_fees_filepath">
                    <i
                      class="fa fa-file-pdf"
                      style="font-size: 50px"
                      aria-hidden="true"
                    ></i>
                    <p class="text-wrap">
                      {{ schedule_of_fees_filepath.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-dialog-content>
      </div>
      <div class="col-1">
        <button class="btn btn-light w-100 h-100" (click)="setTab(1)"><i class="fas fa-angle-right arrows"></i></button>
      </div>
    </div>
    </ng-template>
  </mat-tab>
  <!-- Service Details END -->

  <!-- Standard Requirements START -->
  <!-- label="(2) Requirements" -->
  <mat-tab [aria-label]="validityMap?.requirements === false ? 'error' : ''">
    <ng-template mat-tab-label>
      (2) Requirements
    </ng-template>
    <ng-template matTabContent>
      <div class="row full-height">
        <div class="col-1">
          <!-- <button class="btn btn-dark w-100 h-100" (click)="setTab(0)">&lt;</button> -->        
          <button class="btn btn-light w-100 h-100" (click)="setTab(0)"><i class="fas fa-angle-left arrows"></i></button>
        </div>
        <div class="col-10 tab-column">
          <div class="form-header">
            <h3>Add Requirements</h3>
          </div>
          <!--  [selectedIndex]="selectedRequirementTab.value"
            (selectedIndexChange)="selectedRequirementTab.setValue($event)" -->
          <mat-tab-group class="requirement-tab-group" [disableRipple]="true"
            [selectedIndex]="selected_requirement_tab" (selectedIndexChange)="indexChange($event)">
  
            <!-- standard tabs -->
            <mat-tab label="Standard Requirements">
              <ng-template matTabContent>
                <mat-dialog-content class="tabular">
                  <div class="form-group ">
                    <!-- TODO: Add a situation column -->
                    <table *ngIf="standard_requirement_form.length > 0"
                      mat-table [formArray]="standard_requirement_form"
                      [dataSource]="standard_requirement_form_datasource"
                      class="table table-striped table-bordered table-space-bot mat-elevation-z8 w-100">
    
                      <!-- content -->
                      <ng-container matColumnDef="content">
                        <!-- <th mat-header-cell *matHeaderCellDef></th> -->
                        <td mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" style="padding-top:1em">
                          <div class="row">
                            <div class="col-11">
                              
                              <!-- requirement inputs -->
                              <div class="row">
                                <div class="col-6">
                                  <label class="fw-bold">Requirement type</label>
                                  <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                    <mat-select formControlName="requirementType" class="w-100" (selectionChange)="valueChanged('requirementType',i)">
                                      <ng-container *ngFor="let option of type_of_requirement">
                                        <mat-option [value]="option">{{option}}</mat-option>
                                      </ng-container>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-6">
                                  <label class="fw-bold">Requirement Title</label>
                                  <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline" >
                                    <input matInput placeholder="Input" type="text" formControlName="requirement"/>
                                    <mat-hint *ngIf="checkRequirementHint(element.get('requirement'))">Input a value</mat-hint>
                                    <mat-error *ngFor="let item of checkRequirementForm.requirement">
                                      <span style="color:red" *ngIf="hasError(element.get('requirement'),item.type)">
                                        * {{item.message}}
                                      </span>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              
                              <!-- documentary type data-->
                              <ng-container [ngSwitch]="element.value.requirementType">
                                  <!-- documentary -->
                                  <div *ngSwitchCase="'Documentary'">
                                    <label class="fw-bold">Documentary Copies</label>
                                    <div style="padding-top:1em;" class="d-flex flex-column">
                                      <div class="d-flex flex-row align-items-center justify-content-evenly" style="padding-bottom:1em">
                                        <!-- <button type="button" mat-stroked-button class="btn btn-info" [disabled]="element.value.copyFormatDisabled" (click)="addNode('standard','parenthesis',i)"><span class="material-icons">add_circle_outline</span> Add Parenthesis</button> -->
                                        <!-- <span class="material-icons">insert_drive_file</span> -->
                                        <button type="button" mat-stroked-button class="btn btn-info " [disabled]="element.value.copyFormatDisabled" (click)="addNode('standard','document',i)"><span class="material-icons">add_circle_outline</span> Add Document</button>
                                        <button type="button" mat-stroked-button class="btn btn-info" [disabled]="element.value.copyFormatDisabled" (click)="addNode('standard','operator',i)"><span class="material-icons">add_circle_outline</span> Add Operator</button>
                                        <button type="button" mat-stroked-button class="btn btn-danger" [disabled]="element.value.copyFormatDisabled" (click)="clearNodes('standard',i)"><span class="material-icons"> remove_circle </span> Clear</button>
                                      </div>
                                      <div style="display: -moz-inline-grid; display: -ms-inline-grid; display: inline-grid">
                                        <!-- min-width: 20em; -->
                                        <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop('standard',$event,i)">
                                          <span class="example-box relative" *ngFor="let item of element.get('copyFormat').controls; let b = index;" cdkDrag [cdkDragDisabled]="item.value.disabled">
                                                                                              <!-- || item.value.val == 'Original Copy' || item.value.val == 'Photo Copy' || item.value.val == 'Electronic Copy' -->
                                                                                              <!--  -->
                                            <!-- <div class="d-flex justify-content-center" style="width: 2em" *ngIf="item.value.type == 'parenthesis'">
                                                {{item.value.val}}
                                            </div> -->
              
                                            <div *ngIf="item.value.type == 'document'" [formGroup]="item" class="d-flex flex-row">
                                                                                              <!-- style="width: 100%" -->
                                              <mat-form-field hideRequiredMarker floatLabel="never" style="width: 4em" appearance="outline">
                                                <mat-select formControlName="copyCount">
                                                  <ng-container *ngFor="let option of numberOfCopiesWO0">
                                                    <mat-option [value]="option">{{option}}</mat-option>
                                                  </ng-container>
                                                </mat-select>
                                              </mat-form-field>
                                                                                              <!-- <mat-hint *ngIf="checkRequirementHint(element.get('originalCopy'))">Choose a value</mat-hint>
                                                                                              <mat-error *ngFor="let item of checkRequirementForm.originalCopy">
                                                                                                <span style="color:red" *ngIf="hasError(element.get('originalCopy'),item.type)">
                                                                                                  * {{item.message}}
                                                                                                </span>
                                                                                              </mat-error> -->
                                                                                              <!-- style="width: 10em" -->
                                              <mat-form-field  hideRequiredMarker floatLabel="never" appearance="outline" style="width: 10em">
                                                <mat-select formControlName="val">
                                                  <ng-container *ngFor="let option of document_type_options">
                                                    <mat-option selected="{{(option == item ) ? true : (option == 'Original copy') ? true : (option == 'Photo copy') ? true : (option == 'Electronic copy') ? true : (option == 'Certified True copy') ? true : false}}" [value]="option">{{option}}</mat-option>
                                                  </ng-container>
                                                </mat-select>
                                              </mat-form-field>
                                            </div>
              
                                                                                              <!-- *ngIf="item.value.val == 'And' || item.value.val == 'Or'"  -->
                                            <ng-container  *ngIf="item.value.type == 'operator'" [formGroup]="item">
                                              <mat-form-field  hideRequiredMarker floatLabel="never" style="width: 5em" appearance="outline">
                                                <mat-select formControlName="val">
                                                  <ng-container *ngFor="let option of copy_format_logic_options">
                                                    <mat-option selected="{{(option == item )?true:(option == 'And'?true:false)}}" [value]="option">{{option}}</mat-option>
                                                  </ng-container>
                                                  </mat-select>
                                              </mat-form-field>
                                            </ng-container>
                                                                                              <!-- (item.value.type == 'parenthesis' || item.value.type == 'parenthesis') &&  -->
                                            <a *ngIf="!element.value.copyFormatDisabled" style="cursor: pointer;" class="absolute upper-right remove" (click)="removeDocumentNode('standard',b,i)" title="Remove">
                                              <mat-icon class="example-tab-icon">close</mat-icon>
                                            </a>
                                          </span>
                                        </div>
                                        <mat-error *ngFor="let item of checkRequirementForm.copyFormat">
                                          <span style="color:red" *ngIf="hasError(element.get('copyFormat'),item.type)">
                                            * {{item.message}}
                                          </span>
                                        </mat-error>
                                      </div>

                                      <div class="documentary-copies-preview-box" [ngClass]="element.get('copyFormat').invalid ? 'documentary-copies-preview-box-error' : ''">
                                        <label class="fw-bold">Preview:</label>
                                        <hr class="no-margin">
                                        <div style="padding-left: 1em">
                                          <ng-container *ngFor="let item of element.value.copyFormat">
                                            <ng-container [ngSwitch]="item.type">
                                              <ng-container  *ngSwitchCase="'document'">
                                                ({{item.copyCount ? item.copyCount : '-'}}) {{item.val}}
                                              </ng-container>
                                              <ng-container  *ngSwitchCase="'operator'">
                                                <ng-container [ngSwitch]="item.val">
                                                  <ng-container  *ngSwitchCase="'Or'">
                                                    <br>
                                                    {{item.val}}
                                                    <br>
                                                  </ng-container>
                                                  <ng-container  *ngSwitchDefault>
                                                    {{item.val}}
                                                  </ng-container>
                                                </ng-container>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>

                                  <!-- sample -->
                                  <div *ngSwitchCase="'Sample'" class="row">
                                    <div class="col-6">
                                      <label class="fw-bold">Number</label>
                                      <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                        <input matInput placeholder="Input" type="text" formControlName="number"/>
                                        <mat-hint *ngIf="checkRequirementHint(element.get('number'))">Input a value</mat-hint>
                                        <mat-error *ngFor="let item of checkRequirementForm.number">
                                          <span style="color:red" *ngIf="hasError(element.get('number'),item.type)">
                                            * {{item.message}}
                                          </span>
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                      <label class="fw-bold">Unit</label>
                                      <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                        <input matInput placeholder="Input" type="text" formControlName="unit"/>
                                        <mat-hint *ngIf="checkRequirementHint(element.get('unit'))">Input a value</mat-hint>
                                        <mat-error *ngFor="let item of checkRequirementForm.unit">
                                          <span style="color:red" *ngIf="hasError(element.get('unit'),item.type)">
                                            * {{item.message}}
                                          </span>
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>

                                  <!-- id -->
                                  <div *ngSwitchCase="'Id'">
                                    <label class="fw-bold">List of ID's (Indicate name of ID and Agency)</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never">
                                      <input matInput hidden/>
                                      <editor
                                        formControlName="listOfIds"
                                        [init]="{
                                          base_url: '/tinymce',
                                          suffix: '.min',                    
                                          height: 250,
                                          menubar: false,
                                          plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                          ],
                                          toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                        }"
                                      ></editor>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('listOfIds'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.listOfIds">
                                        <span style="color:red" *ngIf="hasError(element.get('listOfIds'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                              </ng-container>
                              
                              <!-- where to secure -->
                              <ng-container *ngIf="element.value.requirementType == 'Documentary' || element.value.requirementType == 'Sample'">
                                <label class="fw-bold" style="color:blueviolet; margin-top:1em;">Where to Secure</label>
                                <div *ngFor="let whereToSecure of []; let b = index; let whereToSecureCount = count" class="row">
                                  <div class="col-6">
                                    <label class="fw-bold">Agency</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                      <input matInput placeholder="Input" type="text" formControlName="agency"/>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('agency'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.agency">
                                        <span style="color:red" *ngIf="hasError(element.get('agency'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-6">
                                    <label class="fw-bold">Bureau/Division</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                      <input matInput placeholder="Input" type="text" formControlName="bureau_division"/>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('bureau_division'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.bureau_division">
                                        <span style="color:red" *ngIf="hasError(element.get('bureau_division'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <!-- <div class="row">
                                  <div class="col-6">
                                    <label class="fw-bold">Agency</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                      <input matInput placeholder="Input" type="text" formControlName="agency"/>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('agency'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.agency">
                                        <span style="color:red" *ngIf="hasError(element.get('agency'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-6">
                                    <label class="fw-bold">Bureau/Division</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                      <input matInput placeholder="Input" type="text" formControlName="bureau_division"/>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('bureau_division'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.bureau_division">
                                        <span style="color:red" *ngIf="hasError(element.get('bureau_division'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div> -->
                              </ng-container>
        
                              <!-- remarks -->
                              <div>
                                <label class="fw-bold">Remarks</label>
                                <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                  <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" matInput placeholder="Input" cdkAutosizeMaxRows="5" type="text" formControlName="remarks_or_reminders"></textarea>
                                  <mat-hint *ngIf="checkRequirementHint(element.get('remarks_or_reminders'))">Input a value</mat-hint>
                                  <!-- <mat-error *ngFor="let item of checkRequirementForm.remarks_or_reminders">
                                    <span style="color:red" *ngIf="hasError(element.get('remarks_or_reminders'),item.type)">
                                      * {{item.message}}
                                    </span>
                                  </mat-error> -->
                                </mat-form-field>
                              </div>
                            </div>
                            <!--  d-flex justify-content-center align-items-center -->
                            <div class="col-1">
                              <!-- mat-stroked-button committee style="height: 95%;"-->
                              <button (click)="removeStandardRequirement(i)"
                                class="btn btn-danger d-flex  align-items-center flex-column justify-content-evenly"
                                style="height: 95%; padding:0;"
                              >
                                <div class="material-icons"> remove_circle </div>
                                <div class="sideways-bar">
                                  Remove Requirement
                                </div>
                                <div class="material-icons"> remove_circle </div>
                              </button>
                            </div>
                          </div>
                        </td>
                      </ng-container>

                      <!-- <tr mat-header-row *matHeaderRowDef="standardRequirementFormDisplayedColumns"></tr> -->
                      <tr mat-row *matRowDef=" let row; columns: standardRequirementFormDisplayedColumns; let i = index;"  id="{{'standard_row_'+i}}"></tr>
                    </table>
                  </div>
                </mat-dialog-content>
                <div class="d-flex flex-row justify-content-between">
                  <!-- mat-stroked-button -->
                  <button (click)="addNewRequirement()"
                    class="btn m-1 btn-primary d-flex justify-content-center align-items-center">
                    <span class="material-icons"> add_circle_outline </span>
                    Add Requirment
                  </button>
    
                  <!-- (click)="addNewRequirement()" mat-stroked-button-->
                  <button (click)="addSituationalForm()"
                    class="btn m-1 btn-success d-flex justify-content-center align-items-center">
                    <span class="material-icons"> add_circle_outline </span>
                    Add Situation
                  </button>
                </div>
              </ng-template>
            </mat-tab>
  
            <!-- situational tabs -->
            <mat-tab *ngFor="let tab of situational_requirement_tab; let a = index" >
              <ng-template mat-tab-label>
                <mat-form-field style="width: 100%"
                  hideRequiredMarker
                  floatLabel="never"
                  [formGroup]="tab">
                  <input matInput placeholder="Situational Title" type="text" formControlName="title"/>
                </mat-form-field>
                <a style="cursor: pointer;" (click)="deleteSituationalForm(a)">
                  <mat-icon class="example-tab-icon">close</mat-icon>
                </a>
              </ng-template>
              <ng-template matTabContent>
                <mat-dialog-content class="tabular">
                  <div class="form-group ">
                    <table *ngIf="tab.controls.situational_requirement_form.value.length > 0"
                      mat-table [formArray]="tab.controls.situational_requirement_form"
                      [dataSource]="getDataSource(a)"
                      class="table table-striped table-bordered table-space-bot mat-elevation-z8 w-100">
    
                      <!-- content -->
                      <ng-container matColumnDef="content">
                        <!-- <th mat-header-cell *matHeaderCellDef></th> -->
                        <td mat-cell *matCellDef="let element; let i = index;" [formGroup]="element" style="padding-top:1em">
                          <div class="row">
                            <div class="col-11">
                              
                              <!-- requirement inputs -->
                              <div class="row">
                                <div class="col-6">
                                  <label class="fw-bold">Requirement type</label>
                                  <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                    <mat-select formControlName="requirementType" class="w-100" (selectionChange)="situationalValueChanged('requirementType',a,i)">
                                      <ng-container *ngFor="let option of type_of_requirement">
                                        <mat-option [value]="option">{{option}}</mat-option>
                                      </ng-container>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-6">
                                  <label class="fw-bold">Requirement Title</label>
                                  <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline" >
                                    <input matInput placeholder="Input" type="text" formControlName="requirement"/>
                                    <mat-hint *ngIf="checkRequirementHint(element.get('requirement'))">Input a value</mat-hint>
                                    <mat-error *ngFor="let item of checkRequirementForm.requirement">
                                      <span style="color:red" *ngIf="hasError(element.get('requirement'),item.type)">
                                        * {{item.message}}
                                      </span>
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              </div>
                              
                              <!-- documentary type data-->
                              <ng-container [ngSwitch]="element.value.requirementType">
                                  <!-- documentary -->
                                  <div *ngSwitchCase="'Documentary'">
                                    <label class="fw-bold">Documentary Copies</label>
                                    <div style="padding-top:1em;" class="d-flex flex-column">
                                      <div class="d-flex flex-row align-items-center justify-content-evenly" style="padding-bottom:1em">
                                        <!-- <button type="button" mat-stroked-button class="btn btn-info" [disabled]="element.value.copyFormatDisabled" (click)="addNode('situational','parenthesis',i,a)"><span class="material-icons">add_circle_outline</span> Add Parenthesis</button> -->
                                        <!-- <span class="material-icons">insert_drive_file</span> -->
                                        <button type="button" mat-stroked-button class="btn btn-info" [disabled]="element.value.copyFormatDisabled" (click)="addNode('situational','document',i,a)"><span class="material-icons">add_circle_outline</span> Add Document</button>
                                        <button type="button" mat-stroked-button class="btn btn-info" [disabled]="element.value.copyFormatDisabled" (click)="addNode('situational','operator',i,a)"><span class="material-icons">add_circle_outline</span> Add Opertor</button>
                                        <button type="button" mat-stroked-button class="btn btn-danger" [disabled]="element.value.copyFormatDisabled" (click)="clearNodes('situational',i,a)"><span class="material-icons"> remove_circle </span> Clear</button>
                                      </div>
                                      <div style="display: -moz-inline-grid; display: -ms-inline-grid; display: inline-grid">
                                        <!-- min-width: 20em; -->
                                        <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop('situational',$event,i,a)">
                                          <span class="example-box relative" *ngFor="let item of element.get('copyFormat').controls; let b = index;" cdkDrag [cdkDragDisabled]="item.value.disabled">
                                                                                              <!-- || item.value.val == 'Original Copy' || item.value.val == 'Photo Copy' || item.value.val == 'Electronic Copy' -->
                                                                                              <!--  -->
                                            <!-- <div class="d-flex justify-content-center" style="width: 2em" *ngIf="item.value.type == 'parenthesis' || item.value.type == 'parenthesis'">
                                                {{item.value.val}}
                                            </div> -->
              
                                            <div *ngIf="item.value.type == 'document'" [formGroup]="item" class="d-flex flex-row">
                                                                                              <!-- style="width: 100%" -->
                                              <mat-form-field hideRequiredMarker floatLabel="never" style="width: 4em" appearance="outline">
                                                <mat-select formControlName="copyCount">
                                                  <ng-container *ngFor="let option of numberOfCopiesWO0">
                                                    <mat-option [value]="option">{{option}}</mat-option>
                                                  </ng-container>
                                                </mat-select>
                                              </mat-form-field>
                                                                                              <!-- <mat-hint *ngIf="checkRequirementHint(element.get('originalCopy'))">Choose a value</mat-hint>
                                                                                              <mat-error *ngFor="let item of checkRequirementForm.originalCopy">
                                                                                                <span style="color:red" *ngIf="hasError(element.get('originalCopy'),item.type)">
                                                                                                  * {{item.message}}
                                                                                                </span>
                                                                                              </mat-error> -->
                                                                                              <!-- style="width: 10em" -->
                                              <mat-form-field  hideRequiredMarker floatLabel="never" appearance="outline" style="width: 10em">
                                                <mat-select formControlName="val">
                                                  <ng-container *ngFor="let option of document_type_options">
                                                    <mat-option selected="{{(option == item ) ? true : (option == 'Original copy') ? true : (option == 'Photo copy') ? true : (option == 'Electronic copy') ? true : (option == 'Certifide True copy') ? true : false}}" [value]="option">{{option}}</mat-option>
                                                  </ng-container>
                                                </mat-select>
                                              </mat-form-field>
                                            </div>
              
                                                                                              <!-- *ngIf="item.value.val == 'And' || item.value.val == 'Or'"  -->
                                            <ng-container  *ngIf="item.value.type == 'operator'" [formGroup]="item">
                                              <mat-form-field  hideRequiredMarker floatLabel="never" style="width: 5em" appearance="outline">
                                                <mat-select formControlName="val">
                                                  <ng-container *ngFor="let option of copy_format_logic_options">
                                                    <mat-option selected="{{(option == item )?true:(option == 'And'?true:false)}}" [value]="option">{{option}}</mat-option>
                                                  </ng-container>
                                                  </mat-select>
                                              </mat-form-field>
                                            </ng-container>
                                                                                              <!-- (item.value.type == 'parenthesis' || item.value.type == 'parenthesis') &&  -->
                                            <a *ngIf="!element.value.copyFormatDisabled" style="cursor: pointer;" class="absolute upper-right remove" (click)="removeDocumentNode('situational',b,i,a)" title="Remove">
                                              <mat-icon class="example-tab-icon">close</mat-icon>
                                            </a>
                                          </span>
                                        </div>
                                        <mat-error *ngFor="let item of checkRequirementForm.copyFormat">
                                          <span style="color:red" *ngIf="hasError(element.get('copyFormat'),item.type)">
                                            * {{item.message}}
                                          </span>
                                        </mat-error>
                                      </div>

                                      <div class="documentary-copies-preview-box" [ngClass]="element.get('copyFormat').invalid ? 'documentary-copies-preview-box-error' : ''">
                                        <label class="fw-bold">Preview:</label>
                                        <hr class="no-margin">
                                        <div style="padding-left: 1em">
                                          <ng-container *ngFor="let item of element.value.copyFormat">
                                            <ng-container [ngSwitch]="item.type">
                                              <ng-container  *ngSwitchCase="'document'">
                                                ({{item.copyCount ? item.copyCount : '-'}}) {{item.val}}
                                              </ng-container>
                                              <ng-container  *ngSwitchCase="'operator'">
                                                <ng-container [ngSwitch]="item.val">
                                                  <ng-container  *ngSwitchCase="'Or'">
                                                    <br>
                                                    {{item.val}}
                                                    <br>
                                                  </ng-container>
                                                  <ng-container  *ngSwitchDefault>
                                                    {{item.val}}
                                                  </ng-container>
                                                </ng-container>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- sample -->
                                  <div *ngSwitchCase="'Sample'" class="row">
                                    <div class="col-6">
                                      <label class="fw-bold">Number</label>
                                      <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                        <input matInput placeholder="Input" type="text" formControlName="number"/>
                                        <mat-hint *ngIf="checkRequirementHint(element.get('number'))">Input a value</mat-hint>
                                        <mat-error *ngFor="let item of checkRequirementForm.number">
                                          <span style="color:red" *ngIf="hasError(element.get('number'),item.type)">
                                            * {{item.message}}
                                          </span>
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                      <label class="fw-bold">Unit</label>
                                      <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                        <input matInput placeholder="Input" type="text" formControlName="unit"/>
                                        <mat-hint *ngIf="checkRequirementHint(element.get('unit'))">Input a value</mat-hint>
                                        <mat-error *ngFor="let item of checkRequirementForm.unit">
                                          <span style="color:red" *ngIf="hasError(element.get('unit'),item.type)">
                                            * {{item.message}}
                                          </span>
                                        </mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>

                                  <!-- id -->
                                  <div *ngSwitchCase="'Id'">
                                    <label class="fw-bold">List of ID's (Indicate name of ID and Agency)</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never">
                                      <input matInput hidden/>
                                      <editor
                                        formControlName="listOfIds"
                                        [init]="{
                                          base_url: '/tinymce',
                                          suffix: '.min',                    
                                          height: 250,
                                          menubar: false,
                                          plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                          ],
                                          toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | help'
                                        }"
                                      ></editor>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('listOfIds'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.listOfIds">
                                        <span style="color:red" *ngIf="hasError(element.get('listOfIds'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                              </ng-container>
                              
                              <!-- where to secure -->
                              <ng-container *ngIf="element.value.requirementType == 'Documentary' || element.value.requirementType == 'Sample'">
                                <label class="fw-bold" style="color:blueviolet; margin-top:1em;">Where to Secure</label>
                                <div class="row">
                                  <div class="col-6">
                                    <label class="fw-bold">Agency</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                      <input matInput placeholder="Input" type="text" formControlName="agency"/>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('agency'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.agency">
                                        <span style="color:red" *ngIf="hasError(element.get('agency'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-6">
                                    <label class="fw-bold">Bureau/Division</label>
                                    <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                      <input matInput placeholder="Input" type="text" formControlName="bureau_division"/>
                                      <mat-hint *ngIf="checkRequirementHint(element.get('bureau_division'))">Input a value</mat-hint>
                                      <mat-error *ngFor="let item of checkRequirementForm.bureau_division">
                                        <span style="color:red" *ngIf="hasError(element.get('bureau_division'),item.type)">
                                          * {{item.message}}
                                        </span>
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </ng-container>
        
                              <!-- remarks -->
                              <div>
                                <label class="fw-bold">Remarks</label>
                                <mat-form-field style="width: 100%" hideRequiredMarker floatLabel="never" appearance="outline">
                                  <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" matInput placeholder="Input" cdkAutosizeMaxRows="5" type="text" formControlName="remarks_or_reminders"></textarea>
                                  <mat-hint *ngIf="checkRequirementHint(element.get('remarks_or_reminders'))">Input a value</mat-hint>
                                  <!-- <mat-error *ngFor="let item of checkRequirementForm.remarks_or_reminders">
                                    <span style="color:red" *ngIf="hasError(element.get('remarks_or_reminders'),item.type)">
                                      * {{item.message}}
                                    </span>
                                  </mat-error> -->
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- d-flex align-items-center justify-content-center -->
                            <div class="col-1">
                              <!-- mat-stroked-button -->
                              <!-- <button (click)="removeSituationalRequirement(a,i)"
                                class="btn committee btn-danger"
                                style="height: 95%; padding:0;"
                              >
                                <span class="material-icons"> remove_circle </span>
                                <div class="sideways-bar">
                                  Remove Requirement
                                </div>
                              </button> -->
                              <button (click)="removeSituationalRequirement(a,i)"
                                class="btn btn-danger d-flex  align-items-center flex-column justify-content-evenly"
                                style="height: 95%; padding:0;"
                              >
                                <div class="material-icons"> remove_circle </div>
                                <div class="sideways-bar">
                                  Remove Requirement
                                </div>
                                <div class="material-icons"> remove_circle </div>
                              </button>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <!-- <tr mat-header-row *matHeaderRowDef="standardRequirementFormDisplayedColumns"></tr> -->
                      <tr mat-row *matRowDef=" let row; columns: standardRequirementFormDisplayedColumns; let i = index;"  id="{{'situational_'+a+'_row_'+i}}"></tr>
                    </table>
                  </div>
                </mat-dialog-content>
                <div class="d-flex flex-row justify-content-between">
                  <!--  mat-stroked-button -->
                  <button class="btn m-1 btn-primary  d-flex justify-content-center align-items-center"
                    (click)="addNewRequirementToSituational(a)">
                    <span class="material-icons"> add_circle_outline </span>
                    Add Requirement
                  </button>
    
                  <!-- (click)="addNewRequirement()"  mat-stroked-button-->
                  <button (click)="addSituationalForm()"
                    class="btn m-1 btn-success  d-flex justify-content-center align-items-center">
                    <span class="material-icons"> add_circle_outline </span>
                    Add Situation
                  </button>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="col-1">
          <!-- <button class="btn btn-dark w-100 h-100" (click)="setTab(2)">&gt;</button> -->
          <button class="btn btn-light w-100 h-100" (click)="setTab(2)"><i class="fas fa-angle-right arrows"></i></button>
        </div>
      </div>
    </ng-template>
  </mat-tab>
  <!-- Standard Requirements END -->

  <!-- Client Steps START -->
  <mat-tab label="(3) Client Steps"  [aria-label]="validityMap?.clientSteps === false ? 'error' : ''">
    <ng-template matTabContent>
    <div class="row full-height">
      <div class="col-1">
        <!-- <button class="btn btn-dark w-100 h-100" (click)="setTab(1)">&lt;</button> -->
        <button class="btn btn-light w-100 h-100" (click)="setTab(1)"><i class="fas fa-angle-left arrows"></i></button>
      </div>
      <div class="col-10">
        <mat-dialog-content class="form">
          <mat-tree
            [dataSource]="dataSource"
            [treeControl]="treeControl"
            class="example-tree mb-4"
          >
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <div class="mat-tree-node d-flex justify-content-between px-3">
                <div
                  class="d-flex justify-content-start align-content-stretch flex-grow-1"
                  [class.bg-info]="node.uuid === activeAgencyActionUuid"
                >
                  <div
                    class="tree-select d-flex align-self-center"
                    (click)="getAgencyActionInfo(node.parentUuid, node.uuid)"
                    [class.fw-bold]="node.uuid === activeAgencyActionUuid"
                  >
                    <span>
                      {{ node.name }} <i *ngIf="!this.client_steps_form.get(node.parentUuid)?.agency_actions?.get(node.uuid)?.valid" class="fas fa-exclamation-circle" style="color: #ff0000;"></i>
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-end mr-2">
                  <button
                    mat-stroked-button type="button"
                    class="a-bt btn btn-info m-1"
                    (click)="addNewAgencyAction(node)"
                  >
                    <span class="material-icons" style="font-size:2rem"> add_circle_outline  </span>
                  </button>
                  <button
                    *ngIf="!(dataSource.data.length === 1 && dataSource.data[0].children?.length === 1)"
                    mat-stroked-button type="button"
                    class="a-btn btn btn-danger m-1"
                    (click)="removeAgencyAction(node.parentUuid, node.uuid)"
                  >
                    <span class="material-icons" style="font-size:2rem"> remove_circle_outline  </span>
                    
                  </button>
                </div>
              </div>
            </mat-tree-node>

            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <div class="mat-tree-node d-flex justify-content-between">
                <div
                  class="d-flex justify-content-start flex-grow-1"
                  [class.bg-info]="node.uuid === activeClientStepUuid"
                >
                  <button
                    mat-icon-button
                    matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{
                        treeControl.isExpanded(node)
                          ? "expand_more"
                          : "chevron_right"
                      }}
                    </mat-icon>
                  </button>
                  <div
                    class="tree-select d-flex align-self-center"
                    (click)="getClientStepInfo(node)"
                    [class.fw-bold]="node.uuid === activeClientStepUuid"
                  >
                    <span>
                      {{ node.name }} <i *ngIf="!this.client_steps_form.get(node.uuid)?.info?.valid" class="fas fa-exclamation-circle" style="color: #ff0000;"></i>
                    </span>
                  </div>

                </div>

                <div class="d-flex align-items-center justify-content-end">
                  <button
                    mat-stroked-button type="button"
                    class="a-bt btn btn-info m-1"
                    (click)="addNewClientStep()"
                  >
                    <span class="material-icons" style="font-size:2rem"> add_circle_outline  </span>
                  </button>

                  <button *ngIf="dataSource.data.length > 1"
                    mat-stroked-button type="button"
                    class="a-btn btn btn-danger m-1"
                    (click)="removeClientStep(node.uuid)"
                  >
                  <span class="material-icons" style="font-size:2rem"> remove_circle_outline  </span>
                  </button>
                </div>
                
              </div>
              <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
              <div
                [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                role="group"
              >
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>

          <!-- <div class="form-group mt-5">
            <button
              mat-stroked-button
              class="btn m-1"
              (click)="addNewClientStep()"
            >
              <span class="material-icons"> add_circle_outline </span>Add
              Another Step
            </button>
          </div> -->

          <!-- *ngIf="selectedClientStepDataType == 'step' " -->
          <!-- *ngIf="selectedClientStepDataType == 'action' " -->

          <!--client steps details-->
          <div *ngIf="activeClientStep" class="col">
            <form [formGroup]="activeClientStep.info">
              <div class="row p-3">
                <div class="mat-elevation-z8 p-3">

                  <div class="border border-info card-title p-3 w-25">Sequence # <span>{{ activeClientStep.sequence_number }}</span></div>
                  
                  <div style="top: -5%; position: relative;">
                    <table class="table client-step" style="width:100%">
                      <thead>
                        <tr>
                          <th class="form-input-header" colspan="2 ">
                            Client Step
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="2 ">
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="outline"
                            >
                              <input
                                matInput
                                placeholder="Input"
                                type="text"
                                formControlName="title"
                                (keyup)="updateTreeClientStepName($event)"
                              />
                              <mat-error
                                style="color: red"
                                *ngIf="activeClientStep.info.get('title')?.errors"
                                >Input a value</mat-error
                              >
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2 " class="form-input-header-detail">
                            Client Step/Action Details
                          </td>
                        </tr>
                        <tr>
                          <td class="form-input-header-detail">
                            No payment needed for client step?
                          </td>
                          <td>
                            <div class="form-check form-check-inline">
                              <input type="checkbox" class="form-check-input" id="client_step_payment_option" formControlName="client_step_payment_option" (change)="clientStepPaymentOptionChange()">
                              <label class="form-check-label" for="client_step_payment_option">Yes</label>
                            </div>
                          </td>
                        </tr>
                        <tr >
                          <!--<tr *ngIf="activeClientStep.info.get('client_step_payment_option')?.value != true">-->
                          <td class="form-input-header-detail">
                            Situational Step?
                          </td>
                          <td>
                            <div class="form-check form-check-inline">
                              <!-- [readonly]="isSituationalStepReadOnly" -->
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="is_situational_step"
                                formControlName="is_situational_step"
                                (change)="serviceOptionsControl()"
                              />
                              <label
                                class="form-check-label"
                                for="is_situational_step"
                                >Yes</label
                              >
                            </div>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            activeClientStep.info.get('is_situational_step')?.value
                          "
                        >
                          <td class="form-input-header-detail">Situation</td>
                          <td>
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="fill"
                            >
                              <textarea
                                matInput
                                placeholder="Input"
                                type="text"
                                formControlName="situation"
                              ></textarea>
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="form-input-header-detail">Location</td>
                          <td>
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="outline"
                            >
                              <input
                                matInput
                                placeholder="Input"
                                type="text"
                                formControlName="location"
                              />
                              <mat-hint
                                *ngIf="
                                  !activeClientStep.info.get('location')?.touched
                                "
                                >Required</mat-hint
                              >
                              <mat-error
                                style="color: red"
                                *ngIf="
                                  activeClientStep.info.get('location')?.errors
                                "
                                >Input a value</mat-error
                              >
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="form-input-header-detail">
                            Notes/Instructions
                          </td>
                          <td>
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="outline"
                            >
                              <input
                                matInput
                                placeholder="Input"
                                type="text"
                                formControlName="notes"
                              />
                            </mat-form-field>
                          </td>
                        </tr>
                      </tbody>
                    </table> 
                  </div> 
                </div>
              </div>
              <div class="row" *ngIf="activeClientStep.info.get('client_step_payment_option')?.value != true">
                <div class="mat-elevation-z8 p-3">
                  <div class="d-flex flex-row p-3">
                    <label style="margin:0 1em" class="form-input-header add-fees" for="fees ">Add Fees to be Paid</label>
                    <!-- (change)="valueChanged('isGovId',i)" -->
                    <!-- (change)="possibleFeesChange()" -->
                  </div>

                  <div class="d-flex flex-row p-3">
                    <span style="margin:0 1em">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="standard_fees"
                        formControlName="standard_fees"
                        (change)="serviceOptionsControl();checkAddNewFee('standard-fees');"
                      >
                      <label for="standard_fees">Standard fees</label>
                    </span>
                  </div>
                  
                  <ng-container *ngIf="activeClientStep.info.get('standard_fees').value">
                    <div class="row my-3">
                      <mat-list formArrayName="fees_arr">
                        <mat-list-item
                          *ngFor="
                            let x of active_standard_fees_arr;
                            let $i = index
                          "
                        >
                          <div
                            class="d-flex flex-row mat-elevation-z8 p-3"
                            mat-line
                            [formGroup]="x"
                          >
                            <mat-form-field
                              class="p-1"
                              style="width: 70%"
                              hideRequiredMarker
                              floatLabel="never"
                            >
                              <input
                                matInput
                                placeholder="Fee Type"
                                type="text"
                                formControlName="fee_type"
                              />
                              <mat-hint
                                *ngIf="!x.get('fee_type')?.touched"
                                >Required</mat-hint
                              >
                              <mat-error
                                style="color: red"
                                *ngIf="x.get('fee_type')?.errors"
                                >Input a value</mat-error
                              >
                            </mat-form-field>
  
                            <mat-form-field
                              class="p-1"
                              style="width: 50%"
                              hideRequiredMarker
                              floatLabel="never"
                            >
                              <input
                                matInput
                                placeholder="Amount"
                                type="text"
                                formControlName="amount"
                                currencyMask
                                [options]="{ prefix: '₱' }"
                              />
  
                              <mat-hint
                                *ngIf="!x.get('amount')?.touched"
                                >Required</mat-hint
                              >
                              <mat-error
                                style="color: red"
                                *ngIf="x.get('amount')?.errors"
                                >Input a value</mat-error
                              >
                            </mat-form-field>
                          </div>
                          <div class="d-flex justify-content-end">
                            <button
                              mat-stroked-button
                              color="warn"
                              class="a-bt btn btn-danger m-1"
                              (click)="removeStandardFee($i)"
                            >
                              <span
                                class="material-icons"
                                style="font-size: 2rem"
                              >
                                remove_circle_outline
                              </span>
                            </button>
                          </div>
                        </mat-list-item>
                      </mat-list>
                    </div>

                    <div class="p-3">
                      <button
                        mat-stroked-button
                        type="button"
                        class="a-bt btn btn-info m-1"
                        (click)="addStandardFee()"
                      >
                        <span
                          class="material-icons"
                          style="font-size: 2rem"
                        >
                          add_circle_outline
                        </span>
                          Add Standard Fee
                      </button>
                    </div>
                  </ng-container>
                  
                  <div class="d-flex flex-row p-3">
                    <span style="margin:0 1em">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="list_of_possible_fees_or_formulas"
                        formControlName="list_of_possible_fees_or_formulas"
                        (change)="serviceOptionsControl();checkAddNewFee('list-of-possible-fees');"
                      >
                      <label for="list_of_possible_fees_or_formulas">List of Possible Fees/Formulas</label>
                    </span>
                    <!-- <mat-form-field style="width: 100%"
                      hideRequiredMarker
                      floatLabel="never"
                      *ngIf="activeClientStep.info.get('list_of_possible_fees_or_formulas').value"
                    >
                      <input matInput hidden/>
                      <editor
                        formControlName="list_of_fees_val"
                        [init]="{
                          height: 250,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                                              alignleft aligncenter alignright alignjustify | \
                                              bullist numlist outdent indent | removeformat | help'
                        }"
                      ></editor>
                      <mat-hint *ngIf="!activeClientStep.info.get('list_of_fees_val')?.touched"
                        >Required</mat-hint
                      >
                      <mat-error
                        style="color: red"
                        *ngIf="activeClientStep.info.get('list_of_fees_val')?.errors"
                        >Input a value</mat-error
                      >
                    </mat-form-field> -->
                  </div>

                  <ng-container *ngIf="activeClientStep.info.get('list_of_possible_fees_or_formulas').value">
                    <div class="row my-5 p-3 list-of-fees">
                      <mat-list formArrayName="list_of_fees_arr">
                        <mat-list-item
                          *ngFor="
                            let x of active_list_of_fees_formulas_arr;
                            let $i = index
                          "
                        >
                          <div class="d-flex flex-row" mat-line [formGroup]="x">
                            <mat-form-field
                              class="p-1"
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                            >
                              <input
                                matInput
                                placeholder="Fee Type"
                                type="text"
                                formControlName="fee_type"
                              />
                              <mat-hint *ngIf="!x.get('fee_type')?.touched"
                                >Required</mat-hint
                              >
                              <mat-error
                                style="color: red"
                                *ngIf="x.get('fee_type')?.errors"
                                >Input a value</mat-error
                              >
                            </mat-form-field>

                            <div class="d-flex justify-content-end">
                              <button
                                mat-stroked-button
                                color="warn"
                                class="a-bt btn btn-danger m-1"
                                (click)="removeListOfFeesFormulas($i)"
                              >
                                <span
                                  class="material-icons"
                                  style="font-size: 2rem"
                                >
                                  remove_circle_outline
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="d-flex flex-row p-3" mat-line [formGroup]="x">
                            <mat-form-field
                              style="width: 90%"
                              hideRequiredMarker
                              floatLabel="never"
                            >
                              <input matInput hidden />
                              <editor
                                formControlName="list_fee_desc"
                                [init]="{
                                  base_url: '/tinymce',
                                  suffix: '.min',                                  
                                  height: 200,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                                  alignleft aligncenter alignright alignjustify | \
                                                  bullist numlist outdent indent | removeformat | help'
                                }"
                              ></editor>

                              <mat-hint
                                *ngIf="!x.get('list_fee_desc')?.touched"
                                >Required</mat-hint
                              >
                              <mat-error
                                style="color: red"
                                *ngIf="x.get('list_fee_desc')?.errors"
                                >Input a value</mat-error
                              >
                            </mat-form-field>
                          </div>
                        </mat-list-item>
                      </mat-list>
                    </div>
                    
                    <div class="p-3">
                      <button
                        mat-stroked-button
                        type="button"
                        class="a-bt btn btn-info m-1"
                        (click)="addListOfFeesFormulas()"
                      >
                        <span
                          class="material-icons"
                          style="font-size: 2rem"
                        >
                          add_circle_outline
                        </span>
                        Add Possible Fee/Formula
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>

          <!--Corresponding Agency Action -->
          <div *ngIf="activeAgencyAction" class="col">
            <div class="row p-3">
              <div class="mat-elevation-z8 p-3">
                <div class="border border-info card-title p-3 w-25" style="top: -6%;">Corresponding Agency Action</div>
                    
                <div style="top: -4%; position: relative;">
                  <form [formGroup]="activeAgencyAction" >

                    <div class="row border border-light p-2 justify-content-center">
                      <div class="row justify-content-center">
                        <mat-form-field
                          style="width: 100%"
                          hideRequiredMarker
                          floatLabel="never"
                          appearance="outline"
                        >
                          <input
                            matInput
                            placeholder="Input"
                            type="text"
                            formControlName="title"
                            (keyup)="updateTreeAgencyActionName($event)"
                          />
                          <mat-error
                            style="color: red"
                            *ngIf="activeAgencyAction.get('title')?.errors"
                            >Input a value</mat-error
                          >
                        </mat-form-field>
                        
                      </div>
                      <div class="row border p-2 mb-2 justify-content-center">
                        <div class="row" >
                          <div
                            class="col-12 form-input-header p-2 d-flex align-items-center"
                          >
                            <h3>Person/s Responsible</h3>
                          </div>

                          <div class="row my-3">
                            <mat-list formArrayName="persons_responsible">
                              <mat-list-item
                                *ngFor="
                                  let x of active_persons_responsible_arr;
                                  let $i = index
                                "
                              >
                                <div
                                  class="d-flex flex-row mat-elevation-z8 p-3"
                                  mat-line
                                  [formGroup]="x"
                                >
                                  <mat-form-field
                                    style="width: 100%"
                                    hideRequiredMarker
                                    floatLabel="never"
                                  >
                                    <input
                              
                                      matInput
                                      placeholder="Designation"
                                      type="text"
                                      formControlName="designation"
                                    />
                                    <mat-hint
                                      *ngIf="!x.get('designation')?.touched"
                                      >Required</mat-hint
                                    >
                                    <mat-error
                                      style="color: red"
                                      *ngIf="x.get('designation')?.errors"
                                      >Input a value</mat-error
                                    >
                                  </mat-form-field>

                                  <mat-form-field
                                    style="width: 100%"
                                    hideRequiredMarker
                                    floatLabel="never"
                                  >
                                    <input
                                      matInput
                                      placeholder="Division"
                                      type="text"
                                      formControlName="division_group"
                                    />

                                    <mat-hint
                                      *ngIf="!x.get('division_group')?.touched"
                                      >Required</mat-hint
                                    >
                                    <mat-error
                                      style="color: red"
                                      *ngIf="x.get('division_group')?.errors"
                                      >Input a value</mat-error
                                    >
                                  </mat-form-field>
                                </div>
                                <div class="d-flex justify-content-end"><!--button remove row-->
                                  <button
                                    mat-stroked-button
                                    type="button"
                                    color="warn"
                                    class="a-bt btn btn-danger m-1 "
                                    (click)="removePersonResponsible($i)"
                                  >
                                    <span
                                      class="material-icons"
                                      style="font-size: 2rem"
                                    >
                                      remove_circle_outline
                                    </span>
                                  </button>
                                </div>
                              </mat-list-item>
                            </mat-list>

                            <div class="p-3">  <!--button add row-->
                              <button  (keydown.enter)="$event.preventDefault()"
                                mat-stroked-button
                                type="button"
                                class="a-bt btn btn-info m-1"
                                (click)="addPersonResponsible()"
                              >
                                <span
                                  class="material-icons"
                                  style="font-size: 2rem"
                                >
                                  add_circle_outline
                                </span>
                                Add row
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row px-3 justify-content-center">
                        <div class="row form-input-header">
                          <h3>Processing Time</h3>
                        </div>
                        <div class="row my-3">
                          <div class="col-4">
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="outline"
                            >
                            <mat-label>Days</mat-label>
                            <input
                              matInput
                              placeholder="Input"
                              type="text"
                              formControlName="processing_time_days"
                            />
                            <mat-hint
                              *ngIf="
                                !activeAgencyAction.get('processing_time_days')
                                  ?.touched
                              "
                              >Required</mat-hint
                            >
                            <mat-error
                              style="color: red"
                              *ngIf="
                                activeAgencyAction.get('processing_time_days')
                                  ?.errors
                              "
                              >Input a value</mat-error
                            >
                            </mat-form-field>
                          </div>
                          
                          <div class="col-4">
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="outline"
                            >
                              <mat-label>Hours (0-7)</mat-label>
                              <input
                                matInput
                                placeholder="Input"
                                type="text"
                                formControlName="processing_time_hours"
                              />
                              <mat-error
                                style="color: red"
                                *ngIf="
                                  activeAgencyAction.get('processing_time_hours')
                                    ?.errors
                                "
                                >Input a value</mat-error
                              >
                            </mat-form-field>
                            
                          </div>

                          <div class="col-4">
                            <mat-form-field
                              style="width: 100%"
                              hideRequiredMarker
                              floatLabel="never"
                              appearance="outline"
                            >
                              <mat-label>Minutes (0-59)</mat-label>
                              <input
                                matInput
                                placeholder="Input"
                                type="text"
                                formControlName="processing_time_minutes"
                              />
                              <mat-error
                                style="color: red"
                                *ngIf="
                                  activeAgencyAction.get('processing_time_minutes')
                                    ?.errors
                                "
                                >Input a value</mat-error
                              >
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <form [formGroup]="service_name_and_details_form" class="p-3">
                    <div class="form-group mt-3 p-2 row justify-content-center">
                      <div class="row form-input-header">
                        <h3 for="total_processing_time " class=""
                        >Total Processing Time
                        </h3>
                      </div>
                        
                      <div class="row my-3">
                        <mat-form-field
                          style="width: 100%"
                          hideRequiredMarker
                          appearance="outline"
                        >
                          <input
                            matInput
                            readonly
                            placeholder="Input"
                            formControlName="total_processing_time"
                          />
      
                          <mat-hint style="color: red" *ngIf="service_name_and_details_form.get('classification')?.errors"
                            >Please supply a classification for this service</mat-hint
                          >

                          <mat-hint style="color: red" *ngIf="service_name_and_details_form.get('total_processing_time')?.errors && !(service_name_and_details_form.get('classification')?.errors)"
                            >Processing time can not be zero</mat-hint
                          >
                          
                          <mat-hint style="color: red" *ngIf="exceedsMaxDays"
                            >Processing time exceeds the service's limit</mat-hint
                          >
                        </mat-form-field>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mat-elevation-z8 p-3">
              <div class="d-flex flex-row p-3">
                <label class="form-input-header" for="total_fees ">
                  Total Fees
                </label>              
              </div>
              <div class="d-flex flex-row p-3">
                <p [innerHtml]="service_name_and_details_form.get('total_fees')?.value"></p>
              </div>
            </div>
          </div>
        </mat-dialog-content>
      </div>
      <div class="col-1">
        <!-- <button class="btn btn-dark w-100 h-100" (click)="setTab(3)">&gt;</button> -->
        
        <button class="btn btn-light w-100 h-100" (click)="setTab(3)"><i class="fas fa-angle-right arrows"></i></button>
      </div>
    </div>
    </ng-template>
  </mat-tab>
  <!-- Client Steps END -->

  <!-- EODB Tags START -->
  <mat-tab label="(4) EODB Tags" [aria-label]="validityMap?.eodbTags === false ? 'error' : ''">
    <div class="row full-height">
      <div class="col-1">
        <!-- <button class="btn btn-dark w-100 h-100" (click)="setTab(2)">&lt;</button> -->
        
        <button class="btn btn-light w-100 h-100" (click)="setTab(2)"><i class="fas fa-angle-left arrows"></i></button>
      </div>
      <div class="col-10 tab-column">
        <div class="form-header">
          <h3>Add EODB Tags</h3>
        </div>
        <mat-dialog-content class="tabular">
          <div class="form-group">
            <table
              *ngIf="eodb_tags_form.length > 0"
              mat-table
              [formArray]="eodb_tags_form"
              [dataSource]="eodb_tags_form_datasource"
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="classification">
                <th mat-header-cell *matHeaderCellDef>Classification</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <mat-select formControlName="classification" multiple>
                      <mat-option value="Business">Business</mat-option>
                      <mat-option value="Non-Business">Non-Business</mat-option>
                    </mat-select>

                    <mat-hint *ngIf="!element.get('classification')?.touched"
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="element.get('classification')?.errors"
                      >Input a value</mat-error
                    >
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="sector">
                <th mat-header-cell *matHeaderCellDef>Sector</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <mat-select formControlName="sector">
                      <mat-option value="" disabled selected>Sector</mat-option>
                      <mat-option
                        *ngFor="let row of valueSuggestions.sector"
                        [value]="row.name"
                        >{{ row.name }}</mat-option
                      >
                    </mat-select>

                    <mat-hint *ngIf="!element.get('sector')?.touched"
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="element.get('sector')?.errors"
                      >Input a value</mat-error
                    >
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="division">
                <th mat-header-cell *matHeaderCellDef>Division</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <mat-select formControlName="division">
                      <mat-option value="" disabled selected
                        >Division</mat-option
                      >
                      <mat-option
                        *ngFor="let row of filteredDivision(element.get('sector')?.value)"
                        [value]="row.name"
                        >{{ row.name }}</mat-option
                      >
                    </mat-select>

                    <mat-hint *ngIf="!element.get('division')?.touched"
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="element.get('division')?.errors"
                      >Input a value</mat-error
                    >
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="stage_of_business">
                <th mat-header-cell *matHeaderCellDef>Stage of Business</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <mat-select formControlName="stage_of_business">
                      <mat-option value="" disabled selected
                        >Stage of Business</mat-option
                      >
                      <mat-option
                        *ngFor="let row of valueSuggestions.stage_of_business"
                        [value]="row.name"
                        >{{ row.name }}</mat-option
                      >
                    </mat-select>

                    <mat-hint *ngIf="!element.get('stage_of_business')?.touched"
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="element.get('stage_of_business')?.errors"
                      >Input a value</mat-error
                    >
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="case_use">
                <th mat-header-cell *matHeaderCellDef>Case Use</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <mat-select formControlName="case_use">
                      <mat-option value="" disabled selected
                        >Case Use</mat-option
                      >
                      <mat-option
                        *ngFor="let row of filteredCaseUse(element.get('stage_of_business')?.value)"
                        [value]="row.name"
                        >{{ row.name }}</mat-option
                      >
                    </mat-select>

                    <mat-hint *ngIf="!element.get('case_use')?.touched"
                      >Required</mat-hint
                    >
                    <mat-error
                      style="color: red"
                      *ngIf="element.get('case_use')?.errors"
                      >Input a value</mat-error
                    >
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="jurisdiction">
                <th mat-header-cell *matHeaderCellDef>Jurisdiction</th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <mat-form-field
                    style="width: 100%"
                    hideRequiredMarker
                    floatLabel="never"
                  >
                    <mat-select formControlName="jurisdiction">
                      <mat-option value="" disabled selected
                        >Jurisdiction</mat-option
                      >
                      <mat-option
                        *ngFor="let row of valueSuggestions.jurisdiction"
                        [value]="row.name"
                        >{{ row.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let $i = index">
                  <button
                    mat-stroked-button
                    (click)="removeEodbTag($i)"
                    class="btn committee"
                  >
                    <span class="material-icons"> remove_circle </span>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="eodbTagsFormDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: eodbTagsFormDisplayedColumns"
              ></tr>
            </table>
          </div>
        </mat-dialog-content>
        <button mat-stroked-button (click)="addNewEodbTag()" class="btn m-1">
          <span class="material-icons"> add_circle_outline </span>
          Add Row
        </button>
      </div>
      <div class="col-1"></div>
    </div>
  </mat-tab>
  <!-- EODB Tags END -->
</mat-tab-group>
<div class="modal-footer justify-content-between flex-row">
  <div>
    <span> {{
      activeClientStepName
        ? "You are editing client step: " + activeClientStepName
        : ""
    }}</span>
    <!-- <div [formGroup]="serviceGlobalPaymentForm">
      <input type="checkbox" class="form-check-input" name="servicePaymentOption" formControlName="servicePaymentOption" (change)="servicePaymentOptionChange()"><label for="servicePaymentOption">No payment needed for service</label>
    </div> -->
  </div>
  <div>
    <button (click)="save()" class="btn btn-primary m-1">Save</button>
    <button (click)="cancel()" class="btn btn-secondary m-1">Close</button>
  </div>
</div>
