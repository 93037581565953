import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { WorklistStatusCitizensCharter,
CharterStatusCitizensCharter } from 'src/app/entities/worklist';

import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';

@Component({
  selector: 'app-artemis-home-page',
  templateUrl: './artemis-home-page.component.html',
  styleUrls: ['./artemis-home-page.component.scss']
})
export class ArtemisHomePageComponent implements OnInit {



  receivedfromserviceFinder:any;
  
  // displayedColumns = [
  //   'DRAFT',
  //   'FOR VERIFICATION',
  //   'VERIFIED',
  //   'FOR APPROVAL',
  //   'APPROVED',
  //   'FOR REVISION',
  //   'FOR MODIFICATION',
  //   'DISAPPROVED',
  //   'RESUBMITTED',
  //   'FOR ARTA REVIEW',
  //   'APPROVED - ARTA CMEO PO',
  //   'FOR ARTA REVIEW - DC',
  //   'APPROVED - ARTA CMEO DC'
  //   'FOR ARTA REVIEW - DIRECTOR',
  //   'APPROVED - ARTA CMEO DIRECTOR',
  //   'RETURN SERVICE TO AGENCY',
  //   'POSTED',
  
  // ];
  dataSource: MatTableDataSource<any>;

  sessionVar: any;
  displayedColumns: any = [];  

  charters: any = [];

  
  draft_list: any = [];
  for_verification_list: any = [];
  verified_list: any = [];
  for_approval_list: any = [];
  approved_list: any = [];
  for_revision_list: any = [];
  for_notification_list: any = [];
  disapproved__list: any = [];
  resubmitted__list: any = [];
  for_arta_review_list: any = [];
  approved_arta_po_list: any = [];
  for_arta_review_dc_list: any = [];
  approved_arta_dc_list: any = [];
  for_arta_review_director_list: any = [];
  approved_director_list: any = [];
  return_to_agency_list: any = [];
  posted_list: any = [];
  
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  @ViewChild('sort', { static: false }) sort: MatSort;

  /**
  * social links
  */
  // link_email: any;
  // link_facebook: any;
  // link_instagram: any;
  // link_twitter: any;
  // link_youtube: any;

  artemisSite: string = environment.redirectUrls.artemisHome


  // constructor(private afs: AngularFirestore) {
  //   let JSONholder: any = [];
  //   this.afs.collection("social links").snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
  //     (data: any) => {
  //       data.forEach((info: any) => {

  //         let item: any = info.payload.doc.data();
  //         item.id = info.payload.doc.id;

  //         if (JSONholder.includes(item) === false) {
  //           switch (item["id"]) {
  //             //TODO Make this in line and comment this out
  //             case "ARTEMIS":
  //               this.link_email = item["email"];
  //               this.link_facebook = item["facebook"];
  //               this.link_instagram = item["instagram"];
  //               this.link_twitter = item["twitter"];
  //               this.link_youtube = item["youtube"];
  //           }
  //         }
  //       });
  //     });
  // }

  
  constructor(
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.getWorklist();
    this.getCharter();
    // console.log('worklist: ', WorklistStatusCitizensCharter)
    this.sessionVar = sessionStorage.getItem("user_type")
    //console.log("Session User",this.sessionVar)
  }

  serviceFinderResultView:boolean =false;

  closeSearchResults($event:any){
    //console.log('Sent by service result',$event)
    this.serviceFinderResultView =$event;
  }

  //forwared message to search results
  forwardSearchFilters($event:any ){
    this.receivedfromserviceFinder = $event;
    this.serviceFinderResultView =true;
    //console.log("Search filters to Parent",this.receivedfromserviceFinder)
  }
  
  // ngAfterViewInit(): void {
  //   this.dataSource.sort = this.sort;
  //   this.dataSource.paginator = this.paginator;    
  // }

  getCharter(){
    this.afs.collection('citizens-charter-phase-02')
    .snapshotChanges()
    .subscribe(data=>{
      this.charters = [];
      data.forEach((info:any)=>{
        let item:any = info.payload.doc.data()

        // console.log('item: ', item)
        this.charters.push(item)

        if(item.status =='DRAFT'){
          this.draft_list.push(item)
          
        }else if(item.status =='FOR VERIFICATION'){
          this.for_verification_list.push(item)

        }else if(item.status =='VERIFIED'){
          this.verified_list.push(item)

        }else if(item.status =='FOR APPROVAL'){
          this.for_approval_list.push(item)

        }else if(item.status =='APPROVED'){
          this.approved_list.push(item)

        }else if(item.status =='FOR REVISION'){
          this.for_revision_list.push(item)

        }else if(item.status =='FOR MODIFICATION'){
          this.for_notification_list.push(item)

        }else if(item.status =='DISAPPROVED'){
          this.disapproved__list.push(item)

        }else if(item.status =='RESUBMITTED'){
          this.resubmitted__list.push(item)

        }else if(item.status =='FOR ARTA REVIEW'){
          this.for_arta_review_list.push(item)

        }else if(item.status =='APPROVED - ARTA CMEO PO'){
          this.approved_arta_po_list.push(item)

        }else if(item.status =='FOR ARTA REVIEW - DC'){
          this.for_arta_review_dc_list.push(item)

        }else if(item.status =='APPROVED - ARTA CMEO DC'){
          this.approved_arta_dc_list.push(item)

        }else if(item.status =='FOR ARTA REVIEW - DIRECTOR'){
          this.for_arta_review_director_list.push(item)

        }else if(item.status =='APPROVED - ARTA CMEO DIRECTOR'){
          this.approved_director_list.push(item)

        }else if(item.status =='RETURN SERVICE TO AGENC'){
          this.return_to_agency_list.push(item)

        }else if(item.status =='POSTED'){
          this.posted_list.push(item)
        }

      })
      
      this.dataSource = new MatTableDataSource(this.charters);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
     
    })
  }

  statusMapper(status:any){

  }

  getWorklist(){
    const arrayObjects = []  
      
    for (const [propertyKey, propertyValue] of Object.entries(WorklistStatusCitizensCharter)) {  
          if (!Number.isNaN(Number(propertyKey))) {  
            continue;  
        }  
        arrayObjects.push({ name: propertyValue, id: propertyKey });  
    }  
    this.displayedColumns = arrayObjects
    ///console.log('arrayObjects: ',arrayObjects);
  }

  // toArrayColumn(){
  //   const
  // }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
