import { Component, OnInit ,ViewChild} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTable as MatTable,MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Sort} from '@angular/material/sort';


@Component({
  selector: 'app-artemis-inbox-user-feedback',
  templateUrl: './artemis-inbox-user-feedback.component.html',
  styleUrls: ['./artemis-inbox-user-feedback.component.scss']
})
export class ArtemisInboxUserFeedbackComponent implements OnInit {
  feedbackList: any;
  //Modal
  application: any;
  created_at: any;
  discover: any;
  elaborate: any;
  enhance: any;
  experience: any;
  feature: any;
  improve: any;
  otherDiscover: any;
  others: any;
  overall: any;
  refer: any;
  visit: any;
  seqNumber = 0;
  results: any= []
  allResults: any = []

  stars: number[] = [1, 2, 3, 4, 5];
  
  dataSource: MatTableDataSource<any>;

  /**
   * Star Rating
   * 1 Good
      2 Very Good
      3 Satisfy
      4 Very Satisfy
      5 Excellent
   */
  displayedColumns = ['No','application','feature','discover','dategenerated','rating','action']

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  rating:number = 5;
  starCount:number = 5;
   color: string;

  

  constructor(private afs: AngularFirestore) { }

  ngOnInit(): void {

    this.loadArtemisFeedback()
    /***
     * 
     *this.getFeedbacks().subscribe(data => {
      let item = data.map(e => {

        return {
          id: e.payload.doc.id,
          data: e.payload.doc.data()

        };
      })
    })
     */
  }


  loadArtemisFeedback(){
    this.afs
    .collection('Feedback Artemis',ref => ref.where('application', '==', 'feedback-artemis'))
    .snapshotChanges()
    .subscribe(data =>{

      let item: any = data.map(e=>{
        let x: any = e.payload.doc.data();
        x.id = e.payload.doc.id;
        let r: number = parseInt(this.getStarRating(x.overall).toString())
        x.rates = r;
        
        this.seqNumber +=1;
        x.no  = this.seqNumber;
        return x
      })
      this.results = item;
      this.dataSource = new MatTableDataSource<any>(this.results)
      this.resetSortPage()

    })
  }

  getStarRating(rating: any){
    switch(rating){
      case 'Good':
          return 1;
      case 'Very good':
          return 2
      case 'Satisfy':
          return 3
      case 'Very satisfy':
          return 4
      case 'Excellent':
          return 5
      default:
        return 0
  }
  }

  resetSortPage(){
    this.dataSource.paginator =this.paginator;
    this.dataSource.sort = this.sort
    this.settingFilter();
  }

  settingFilter(){
    this.dataSource.filterPredicate = (data:any, filter:string): boolean=>{
        return data.type.type_title.toLowerCase().includes(filter.toLowerCase())
        || data.status.toLowerCase().includes(filter.toLowerCase()) 
        || data.user.name.toLowerCase().includes(filter.toLocaleLowerCase())
        || data.comments.user.toLowerCase().includes(filter.toLocaleLowerCase())
    }
  }

  sortChange(sort: Sort){
    // console.log('sort', sort)
    const data = this.results.slice()
    if(!sort.active || sort.direction === ''){
        this.allResults = data
        return;
    }
    this.allResults = data.sort((a:any,b:any)=>{
        const isAsc = sort.direction === 'asc'
        switch (sort.active){
            case 'dategenerated':
              return this.compare(a.created_at.trim().toLowerCase(), b.created_at.trim().toLowerCase(), isAsc)
            case 'rating':
              return this.compare(a.rates, b.rates, isAsc)
            
            default:
              return 0
        }
    })
    this.dataSource = new MatTableDataSource<any>(this.allResults)
    this.resetSortPage()
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openModal(data: any) {
    this.application = data.application;
    this.created_at = data.created_at;
    this.discover = data.discover;
    this.elaborate = data.elaborate;
    this.enhance = data.enhance;
    this.experience = data.experience;
    this.feature = data.feature;
    this.improve = data.improve;
    this.otherDiscover = data.otherDiscover;
    this.others = data.others;
    this.overall = data.overall;
    this.refer = data.refer;
    this.visit = data.visit;
  }

 /**
  *  getFeedbacks() {
    return this.afs.collection('Feedback Artemis', ref => ref.where('application', '==', 'feedback-artemis')).snapshotChanges();
  }
  */
}

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}
