import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-agreements',
  templateUrl: './registration-agreements.component.html',
  styleUrls: ['./registration-agreements.component.scss']
})
export class RegistrationAgreementsComponent implements OnInit {

  @Input() PBRISorARTEMIS: any;
  /**
   * FormGroup Variables
   */
  termsAndConditionsForm: UntypedFormGroup[] = [];
  privacyPolicyForm: UntypedFormGroup[] = [];

  /**
   * Variables
   */
  tab_tnc = false;
  tab_pp = false;
  tempvar: any;
  tempfile: any;

  constructor(private router: Router, public auth: AngularFireAuth, private afs: AngularFirestore) {
  }

  ngOnInit(): void {
    this.getFirestoreData();
    this.callTermsNConditions();
  }


  getFirestoreData() {
    /**
     * these variables are used to prevent duplicate data entry
     */
    let terms_and_conditions: any = [];
    let privacy_policy: any = [];
    /**
     * Terms & Conditions
     */
    this.afs.collection('registration contracts').doc(this.PBRISorARTEMIS).collection('terms_and_conditions').snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
      (data: any) => {
        data.forEach((info: any) => {
          
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (terms_and_conditions.includes(item) === false){
            terms_and_conditions.push(item);
            this.termsAndConditionsForm.push(
                new UntypedFormGroup({
                  title: new UntypedFormControl(item.title, Validators.required),
                  description: new UntypedFormControl(item.description, Validators.required),
                  id: new UntypedFormControl(item.id, Validators.required),
                })
              )
          } 

        });
    });
    /**
     * Privacy Policy
     */
    this.afs.collection('registration contracts').doc(this.PBRISorARTEMIS).collection('privacy_policy').snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
      (data: any) => {
        data.forEach((info: any) => {
          
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (privacy_policy.includes(item) === false){
            privacy_policy.push(item);
            this.privacyPolicyForm.push(
                new UntypedFormGroup({
                  title: new UntypedFormControl(item.title, Validators.required),
                  description: new UntypedFormControl(item.description, Validators.required),
                  id: new UntypedFormControl(item.id, Validators.required),
                })
              )
          } 

        });
    });
  }

  /**
   * Switch Tabs between ARTEMIS & PBRIS
   */
  callTermsNConditions() {
    this.tab_tnc = true;
    this.tab_pp = false;
  }
  callPrivacyPolicy() {
    this.tab_tnc = false;
    this.tab_pp = true;
  }

  onSubmit(){
    //this.termsNconditions = this.artemisform.value.termsNconditions;
    //this.privacypolicy = this.artemisform.value.privacypolicy;
  }

  updateTermsAndConditions(){
    let Ref = this.afs.collection("registration contracts").doc(this.PBRISorARTEMIS).collection("terms_and_conditions");
    for(let formListItem of this.termsAndConditionsForm) {
      Ref.doc(formListItem.value.id).update({
        title: formListItem.value.title,
        description: formListItem.value.description,
      }).catch(error =>{
        alert(error.message)
      });
    }
    alert("terms and conditions update successful")
  }

  updatePrivacyPolicy(){
    let Ref = this.afs.collection("registration contracts").doc(this.PBRISorARTEMIS).collection("privacy_policy");
    for(let formListItem of this.privacyPolicyForm) {
      Ref.doc(formListItem.value.id).update({
        title: formListItem.value.title,
        description: formListItem.value.description,
      }).catch(error =>{
        alert(error.message)
      });
    }
    alert("terms and conditions update successful")
  }

}
