import { Component,Input, OnInit } from '@angular/core';
import { AddAclCallback } from '@google-cloud/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-artemis-service-view',
  templateUrl: './artemis-service-view.component.html',
  styleUrls: ['./artemis-service-view.component.scss']
})
export class ArtemisServiceViewComponent implements OnInit {
 
  agency_logo:any;
  defaultImgSrc = "/assets/GOP_Logo.png"
  agency_id:any;
  agency_name:any
  @Input() s_data:any
  @Input('agency') d_id:any


  constructor(
    private router: Router,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder,
  ) {}

  agencyLogoForm = this.fb.group({
    logoURL: new UntypedFormControl(''),
    logoSrc: new UntypedFormControl(''),
    logoFile: new UntypedFormControl('')
  })

  ngOnInit(): void {
//console.log('docID',this.d_id);
this.loadInfo(this.d_id);
  }

  loadInfo(id:any){
    this.afs.collection('Agency').doc(id).snapshotChanges()
    .pipe(first())
    .subscribe(
      data =>{
        if(data){
          let item:any  = data.payload.data();
          item.id = data.payload.id;
          this.agency_name = item?.agencyDetails?.agencyName ? item.agencyDetails.agencyName : item?.name ? item.name : ""
          this.agency_id = item.id

          if(item.agencyDetails != null){
            this.agency_logo = item.agencyDetails.logoURL;
            if(this.agency_logo) this.defaultImgSrc = item.agencyDetails.logoURL
          }
          else
            this.agency_logo = ""

          this.agencyLogoForm.patchValue({
            logoURL: this.agency_logo
          })
        }


    })
  }


  goToService(serviceId:any){
    this.router.navigate(['/artemis/view/detail',serviceId])
  }
}
