import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-essential-links',
  templateUrl: './essential-links.component.html',
  styleUrls: ['./essential-links.component.scss']
})
export class EssentialLinksComponent implements OnInit {
  links: any = [];
  form = new UntypedFormGroup({
    title1: new UntypedFormControl('', Validators.required),
    url1: new UntypedFormControl('', Validators.required),
    title2: new UntypedFormControl('', Validators.required),
    url2: new UntypedFormControl('', Validators.required),
    title3: new UntypedFormControl('', Validators.required),
    url3: new UntypedFormControl('', Validators.required),
    title4: new UntypedFormControl('', Validators.required),
    url4: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public auth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadItems()
  }

  loadItems(){
    this.afs.doc(`Essential Links/url1`).get().subscribe((
      (data:any) => {
        this.form.patchValue({
          title1: data.get('title'),
          url1: data.get('url')
        })
      }
    ))

    this.afs.doc(`Essential Links/url2`).get().subscribe((
      (data:any) => {
        this.form.patchValue({
          title2: data.get('title'),
          url2: data.get('url')
        })
      }
    ))

    this.afs.doc(`Essential Links/url3`).get().subscribe((
      (data:any) => {
        this.form.patchValue({
          title3: data.get('title'),
          url3: data.get('url')
        })
      }
    ))

    this.afs.doc(`Essential Links/url4`).get().subscribe((
      (data:any) => {
        this.form.patchValue({
          title4: data.get('title'),
          url4: data.get('url')
        })
      }
    ))

    

       
   }

   update(){
    let section = this.form.value.section;
    let name = this.form.value.sector_name;
    let definition = this.form.value.sector_def;

    let title1 = this.form.value.title1;
    let url1 = this.form.value.url1;
    let title2 = this.form.value.title2;
    let url2 = this.form.value.url2;
    let title3 = this.form.value.title3;
    let url3 = this.form.value.url3;
    let title4 = this.form.value.title4;
    let url4 = this.form.value.url4;

    this.afs.doc(`Essential Links/url1`).update({
      title: title1, url: url1
    })

    this.afs.doc(`Essential Links/url2`).update({
      title: title2, url: url2
    })

    this.afs.doc(`Essential Links/url3`).update({
      title: title3, url: url3
    })

    this.afs.doc(`Essential Links/url4`).update({
      title: title4, url: url4
    })

    alert("Update has been applied")
  }

}
