import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { firstValueFrom, Observable } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import { PdfRenderService } from 'src/app/pdf-render.service';
import { ArtemisNewCharterClientServiceModalComponent } from './artemis-new-charter-client-service-modal/artemis-new-charter-client-service-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Timestamp } from 'firebase/firestore';
import { EffectivityCitizensCharter, PostedServiceStatusCitizensCharter, WorklistStatusCitizensCharter } from 'src/app/entities/worklist';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { format } from 'date-fns';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes.guard';
import { v4 as uuidv4 } from 'uuid';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { NewServiceModalComponent } from './new-service-modal/new-service-modal.component';

@Component({
  selector: 'app-artemis-new-charter',
  templateUrl: './artemis-new-charter.component.html',
  styleUrls: ['./artemis-new-charter.component.scss']
})
export class ArtemisNewCharterComponent implements OnInit, ComponentCanDeactivate {

  style_theme: any = 'theme-default'; //current theme

  // Used to upload file, but file upload input was in Phase 01 metadata form, which was removed RWL 20220519

  // edit mode shows save button, next button for create mode
  isEditMode: boolean = false; // for entire page
  editedFlag: boolean = false;
  isServiceEditMode: boolean = false;
  newUpdateWorklist: boolean = false;
  updateServiceId: string = '';
  updateWorklistId: string = '';
  // true if initial encoding or editing an encoded draft
  // false if encoded service's status is not DRAFT (FOR REVISION or other variants of FOR REVISION)
  deleteEnabled: boolean = true;

  isAgency: boolean = false;
  isAdmin: boolean = true;

  pageTag: any;
  currentStatus: any;

  enum_service_status = WorklistStatusCitizensCharter;

  /**
   * Form Variables
   */
  citizens_charter_worklist_collection_name: string = 'Worklist-Citizens Charter'; // test collection 'CitizensCharter'
  citizens_charter_service_collection_name: string = 'Services Phase 02'; // test collection 'CitizensCharter'
  
  // Used for editing CC/Services
  service_form: any = [];
  display_service_form: any = [];
  service_id_array: any = [];

  // form
  currentFormPage: string = "";

  savedFlag: boolean = false;
  validForm: boolean = false;

  processLoader: boolean = false;
  saveAllAsDraftLoader: boolean = false;
  submitAllFormsLoader: boolean = false;
  updateServiceLoader: boolean = false;
  submitServiceLoader: boolean = false;

  constructor(private afs: AngularFirestore, private store: AngularFireStorage, private router: Router, private route: ActivatedRoute, private pdf_render: PdfRenderService,
     public dialog: MatDialog, public auth: AngularFireAuth, private snackBarService: SnackbarNotifService , public serviceDialog: MatDialog) {
  }

  ngOnInit(): void {
    /**
     * step 4 : servicesteps
     */

    /**
     * phase 1 add charter
     * phase 1 add service
     * preview
     */
    this.pageTag = "preview";

    if(sessionStorage.getItem("user_type")) {
      if(sessionStorage.getItem("user_type") == 'agency'){
        this.isAgency = true;
      }
    }
    if(sessionStorage.getItem("arta_admin")) {
      if(sessionStorage.getItem("arta_admin") == 'true'){
        this.isAdmin = true;
      }
    }
    // this.route.snapshot.params.id will now be service ID instead of CC ID
    if(this.route.snapshot.params.id) {
      this.updateServiceId = this.route.snapshot.params.id;

      // this.loadCitizensCharterInformation();
      this.loadServiceInformation();
      
      if(this.route.snapshot.url[2].path == "updatecharter") {
        // Load existing service but save as a new worklsit (Update posted service)
        this.newUpdateWorklist = true;
      }
      else {
        // Edit ongoing service
        this.isEditMode = true;
      }

      this.currentFormPage = "servicelist"
    }
    else{
      this.currentFormPage = "servicelist"
    }
    this.getSuggestions();
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    let confirmFlag = !this.savedFlag && (
      (!this.isEditMode && this.display_service_form.length > 0) ||
      (this.isEditMode && this.editedFlag)
    )
    // function must return false if service form contains service values and savedFlag is not yet enabled
    return !confirmFlag;
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        if(sessionStorage.getItem("arta_admin") == "true" ){
          this.isAdmin = true;
        }
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      case 'personal':
        this.style_theme = 'theme-default';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }
  
  async loadServiceInformation() {
    const params = await firstValueFrom(this.route.queryParams.pipe(first()));

    if(params.worklistId) {
      this.updateWorklistId = params.worklistId;

      const worklist_ref = this.afs.firestore.collection(this.citizens_charter_worklist_collection_name).doc(this.updateWorklistId)
      worklist_ref.get().then((result) => {
        const resultData: any = result.data();

        if(resultData.status !== WorklistStatusCitizensCharter.DRAFT) {
          this.deleteEnabled = false;
        }
      });
    }
    
    // this.route.snapshot.params.id will now be service ID instead of CC ID
    const service_ref = this.afs.firestore.collection(this.citizens_charter_service_collection_name).doc(this.updateServiceId);

    this.service_form = [];
    this.service_id_array = [];
    this.service_id_array.push(this.updateServiceId);

    service_ref.get().then((result) => {
      const resultData: any = result.data();

      let new_service_form_json =
      {
        id: this.updateServiceId,
        full_service_form: resultData,
        service_name: resultData.serviceDetails.service_name,
        // processed as TypeScript map in initial encoding, but must be loaded as a JS object
        client_steps_length: resultData.clientSteps.length,
        total_fees:
          typeof resultData.serviceDetails.total_fees == 'string'
            ? resultData.serviceDetails.total_fees.replace(/(?![\d\.])./g, '')
            : resultData.serviceDetails.total_fees,
        total_processing_time: resultData.serviceDetails.total_processing_time,
        // TODO: Save validityMap to the document?
        is_valid_str: "CLICK EDIT TO CHECK"
      }
      // Save the full service_form details from modal payload
      this.service_form.push(resultData);
      // display_service_form contains display table
      this.display_service_form.push(new_service_form_json);
    })
  }

  valueSuggestions: any = {};
  async getSuggestions(){
    /* main */
    // TODO: Move the dropdown values to the Top Level Collections
    let referencesRef = this.afs.collection("References");
    /* sub */
    //let commonRef = referencesRef.doc('common');
    let artemisRef = referencesRef.doc('ARTEMIS');
    //let pbrisRef = referencesRef.doc('PBRIS');
    /* sub sub */
    let collectionArray: any = [];
    /* common */
    /* common reference data has not yet been moved to the latest collection
    collectionArray.push({ collname: 'location_code', collRef: commonRef.collection('location_code') });
    collectionArray.push({ collname: 'notif_messages', collRef: commonRef.collection('notif_messages') });
    collectionArray.push({ collname: 'organization_code', collRef: commonRef.collection('organization_code') });
    collectionArray.push({ collname: 'psic_code', collRef: commonRef.collection('psic_code') });
    collectionArray.push({ collname: 'sector', collRef: commonRef.collection('sector') });
    */
    collectionArray.push({ collname: 'location_code', collRef: this.afs.collection('Location Code') });
    collectionArray.push({ collname: 'organization_code', collRef: this.afs.collection('Organization Code') });
    collectionArray.push({ collname: 'psic_code', collRef: this.afs.collection('PSIC Code') });
    collectionArray.push({ collname: 'sector', collRef: this.afs.collection('Sector') });
    /* artemis */
    collectionArray.push({ collname: 'agency', collRef: this.afs.collection('Agency', filter => filter.orderBy('agencyDetails.agencyName')) });
    collectionArray.push({ collname: 'agency_category', collRef: artemisRef.collection('agency_category') });
    collectionArray.push({ collname: 'classification', collRef: artemisRef.collection('classification', filter => filter.orderBy('order')) });
    collectionArray.push({ collname: 'list_of_services', collRef: artemisRef.collection('list_of_services') });
    collectionArray.push({ collname: 'service_category', collRef: artemisRef.collection('service_category') });
    collectionArray.push({ collname: 'stage_of_life', collRef: artemisRef.collection('stage_of_life') });
    /* pbris */
    /* pbris reference data has not yet been moved to the latest collection
    collectionArray.push({ collname: 'agency_location', collRef: pbrisRef.collection('agency_location') });
    collectionArray.push({ collname: 'case_use', collRef: pbrisRef.collection('case_use') });
    collectionArray.push({ collname: 'division', collRef: pbrisRef.collection('division') });
    collectionArray.push({ collname: 'document', collRef: pbrisRef.collection('document') });
    collectionArray.push({ collname: 'instrument', collRef: pbrisRef.collection('instrument') });
    collectionArray.push({ collname: 'jurisdiction', collRef: pbrisRef.collection('jurisdiction') });
    collectionArray.push({ collname: 'stage_of_business', collRef: pbrisRef.collection('stage_of_business') });
    */
    collectionArray.push({ collname: 'agency_location', collRef: this.afs.collection('Agency Location') });
    collectionArray.push({ collname: 'case_use', collRef: this.afs.collection('Case Use') });
    collectionArray.push({ collname: 'document', collRef: this.afs.collection('Document') });
    collectionArray.push({ collname: 'instrument', collRef: this.afs.collection('Instrument') });
    collectionArray.push({ collname: 'jurisdiction', collRef: this.afs.collection('Jurisdiction') });
    collectionArray.push({ collname: 'stage_of_business', collRef: this.afs.collection('Stage of Business') });

    let divisionFilter: QueryFn;
    divisionFilter = (filter) => filter;
    let fromAgency = sessionStorage.getItem("agency_id");

    if(fromAgency) {
      divisionFilter = (filter) => filter.where('fromAgencyId','==',fromAgency);
    }
    collectionArray.push({ collname: 'division', collRef: this.afs.collection('Agency-Offices-Division', divisionFilter) });
    /* loop */
    for(let collJSON of collectionArray) {
      let suggestArray: any = [];
      collJSON.collRef.snapshotChanges()
      .pipe(
        tap(
          (data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              if(Object.keys(item).length > 0)
                suggestArray.push(item);
            });
          }
        ),
        take(1))
      .subscribe();
      this.valueSuggestions[collJSON.collname] = suggestArray;
    }
  }

  /**
   * Call CRUDs
   */
  callServiceCRUD(full_service_form?: any, service_id?: string, index?: number, validity_map?: any) {
    let serviceForm = null;
    let serviceId = '';
    // For edit service
    if(full_service_form && index != undefined){
      serviceForm = full_service_form;
    }
    if(service_id && index != undefined){
      serviceId = service_id;
    }

    let sendValidityMap = {};
    if(validity_map) {
      sendValidityMap = validity_map;
    }

    const dialogRef = this.dialog.open(ArtemisNewCharterClientServiceModalComponent,
      {
        disableClose: true,
        data: {
          valueSuggestions: this.valueSuggestions,
          serviceForm: serviceForm,
          validityMap: sendValidityMap
        },
        maxWidth: "87vw",
        width: "87vw",
        height: "76vh",
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          //console.log("successfully saved!",result);

          if(this.isEditMode) this.editedFlag = true;

          if(result.validityMap?.overall) {
            var tempValidForm = true;
            for(var x of this.display_service_form) {
              if(x.is_valid_str === "INCOMPLETE") {
                tempValidForm = false;
                break;
              }
            }
            this.validForm = tempValidForm;
          }
          else {
            this.validForm = false;
          }

          let new_service_form_json = 
          {
            // Dummy UUID value so that editing initially encoded service does not duplicate
            id: uuidv4(),
            // allow editing even during encoding
            full_service_form: result,
            service_name: result.serviceDetails.service_name,
            client_steps_length: result.clientSteps.length,
            total_fees:
              typeof result.serviceDetails.total_fees == 'string'
                ? result.serviceDetails.total_fees.replace(/(?![\d\.])./g, '')
                : result.serviceDetails.total_fees,
            total_processing_time: result.serviceDetails.total_processing_time,
            validity_map: result.validityMap,
            is_valid_str: result.validityMap?.overall ? "VALID" : "INCOMPLETE"
          }

          let reopenIdx = 0;
          if (service_id && index != undefined) {
            // Update service in list
            new_service_form_json.id = serviceId;
            this.service_form[index] = result;
            this.display_service_form[index] = new_service_form_json;
            reopenIdx = index;
          }
          else {
            // Add service to list
            // Save the full service_form details from modal payload
            this.service_form.push(result);
            // display_service_form contains display table
            this.display_service_form.push(new_service_form_json);
            reopenIdx = this.service_form.length-1;
          }

          if(result.validityMap?.overall) {
            this.snackBarService.openPlainNotif("Service saved!","Close");
          }
          else {
            // Reopen service crud and notify of errors
            this.callServiceCRUD(
              new_service_form_json.full_service_form,
              new_service_form_json.id,
              reopenIdx,
              new_service_form_json.validity_map
            );

            this.snackBarService.openPlainNotif("Errors detected while encoding service.","Close");
          }
        }
        else{
          //console.log("cancelled!");
        }
      });
  }

  gotoForm(selectedForm: any) {
    /**
     * step 4 : servicesteps
     */

    // RWL 20220519 only 'pdfpreview', 'summary', and 'servicelist' remain
    this.currentFormPage = selectedForm;
  }

  async firestoreSetCitizensCharter(status: string){
    this.processLoader = true
    const batch = this.afs.firestore.batch(); // batch uploader, firestore // do not comment
   
    /**
     * file upload secction
     */
    let uploadProgress!: Observable<number | undefined>;

    let destinationPath = "";
   
    const user = await this.auth.currentUser;
    // Agency ID instead of Agency Name due to Agency Maintenance
    let fromAgency = sessionStorage.getItem("agency_id");
    let fromOfficer = user?.displayName;

    // Services
    let serviceID = [];
    if(this.service_form) {
      for(let service of this.service_form) {
        let service_ref = this.afs.firestore.collection(this.citizens_charter_service_collection_name).doc();

        if (service.serviceDetails.schedule_of_fees_filepath) {
          let serviceDetailsTemp = service.serviceDetails;
          // Object destructuring to split the schedule_of_fees_filepath File value from the service object to be saved as Firestore document
          const { schedule_of_fees_filepath, ...serviceDetailsNoFile } = serviceDetailsTemp;
          
          const dateUploadedStr = format(new Date(), 'yyyy-MM-dd');
          // Subdirectory format for uniqueness. Uses UId of service with date uploaded
          serviceDetailsNoFile.firebase_folder = `citizensCharterPhase02/${dateUploadedStr}/${service_ref.id}`

          destinationPath = `${serviceDetailsNoFile.firebase_folder}/${serviceDetailsTemp.schedule_of_fees_filepath.name}`;
          const destinationRef = this.store.ref(destinationPath);
          const task = this.store.upload(destinationPath, serviceDetailsTemp.schedule_of_fees_filepath);

          uploadProgress = task.percentageChanges();

          service.serviceDetails = serviceDetailsNoFile;
        }

        // Set fromAgencyId for use in service management lists
        service.fromAgencyId = fromAgency;

        if (this.newUpdateWorklist && this.updateServiceId) {
          service.parentServiceId = this.updateServiceId;
        }

        batch.set(service_ref, await service); // do not comment
        serviceID.push(service_ref.id);

        // One CC worklist entry per service, not per CC
        let worklist_ref = this.afs.firestore.collection(this.citizens_charter_worklist_collection_name).doc();
        let worklistJSON: any = {
          dateGenerated: Timestamp.now().toDate(),
          fromAgency: fromAgency,
          fromOfficer: fromOfficer,
          status: status,
          documentFile: destinationPath,
          documentId: service_ref.id,
          documentTitle: service?.serviceDetails.service_name,
          dueDate: Timestamp.now().toDate(),
          effectivity: EffectivityCitizensCharter.NEW,
        }
        

        if (this.newUpdateWorklist && this.updateServiceId) {
          worklistJSON.effectivity = EffectivityCitizensCharter.UPDATE;
          worklistJSON.previousDocumentId = this.updateServiceId;

          const service_ref = this.afs.firestore
            .collection(this.citizens_charter_service_collection_name)
            .doc(this.updateServiceId);

          batch.update(service_ref, {
            posted_status: PostedServiceStatusCitizensCharter.FOR_UPDATING,
          }); // do not comment
        }

        //console.log('worklistJSON',worklistJSON)
        batch.set(worklist_ref, worklistJSON);  // do not comment
      }
    }

    // do not comment
    await batch.commit().then(() => {
      let alert_message = '';
      switch(status) {
        case WorklistStatusCitizensCharter.DRAFT:
          alert_message = 'Draft Saved!';
          this.processLoader = false;
          this.saveAllAsDraftLoader = false
          break;
        case WorklistStatusCitizensCharter.FOR_VERIFICATION:
          alert_message = "Submitted for Verification"
          this.processLoader = false;
          this.submitAllFormsLoader = false
          break;
      }
      alert(alert_message);
    })
    .catch((err)=>{
      console.error('Error')
      this.processLoader = false;
    });
    this.returnRedirect();
  }

  /*end of draft function */
  async saveAllAsDraft(){
    this.saveAllAsDraftLoader = true
    this.firestoreSetCitizensCharter(WorklistStatusCitizensCharter.DRAFT);
  }

  submitAllForms() {
    this.submitAllFormsLoader = true
    this.firestoreSetCitizensCharter(WorklistStatusCitizensCharter.FOR_VERIFICATION);
  }
 
  async updateService() {
    this.updateServiceLoader = true
    this.firestoreUpdateCitizensCharter(WorklistStatusCitizensCharter.DRAFT);
  }
  
  async submitService() {
    this.submitServiceLoader = true
    this.firestoreUpdateCitizensCharter(WorklistStatusCitizensCharter.FOR_VERIFICATION);
  }

  async firestoreUpdateCitizensCharter(status: string) {
    this.processLoader = true
    const batch = this.afs.firestore.batch(); // batch uploader, firestore

    // Services
    let serviceID = [];
    if(this.display_service_form) {
      // Assume no changes to the structure of this.display_service_form (no new entries added to it)
      for (let service of this.display_service_form) {
        let service_ref = this.afs.firestore.collection(this.citizens_charter_service_collection_name).doc(service.id);

        /**
         * file upload secction
         */
        let uploadProgress!: Observable<number | undefined>;
        let destinationPath = "";
        
        if (service.full_service_form.serviceDetails.schedule_of_fees_filepath) {
          let serviceDetailsTemp = service.full_service_form.serviceDetails;
          // Object destructuring to split the schedule_of_fees_filepath File value from the service object to be saved as Firestore document
          const { schedule_of_fees_filepath, ...serviceDetailsNoFile } = serviceDetailsTemp;

          // Do not change the firebase_folder value, but use it only if it exists
          if(serviceDetailsNoFile.firebase_folder) {
            destinationPath = `${serviceDetailsNoFile.firebase_folder}/${serviceDetailsTemp.schedule_of_fees_filepath.name}`;
            const destinationRef = this.store.ref(destinationPath);
            const task = this.store.upload(destinationPath, serviceDetailsTemp.schedule_of_fees_filepath);
  
            uploadProgress = task.percentageChanges();
          }

          service.full_service_form.serviceDetails = serviceDetailsNoFile;
        }

        batch.update(service_ref, await service.full_service_form);
        serviceID.push(service.id);

        // TODO: Update Document Title in Worklist
        if (this.updateWorklistId) {
          let worklist_ref = this.afs.firestore.collection(this.citizens_charter_worklist_collection_name).doc(this.updateWorklistId);
          let worklistJSON: any = {
            documentFile: destinationPath,
            documentTitle: service.full_service_form.serviceDetails.service_name,
          }

          if(status === WorklistStatusCitizensCharter.FOR_VERIFICATION) {
            worklistJSON.status = status;
          }

          batch.update(worklist_ref, worklistJSON);
        }
        
      }

      await batch.commit().then(() => {
        let alert_message = 'Services updated!';
        alert(alert_message);
        this.processLoader = false;
        this.updateServiceLoader = false
        this.submitServiceLoader = false
      });
    }

    this.returnRedirect();
  }


  /**
   * Delete service
   * Handles deleting of encoded service
   */
  async deleteService(id: string, index: number) {
    this.service_form.splice(index, 1);
    this.display_service_form.splice(index, 1);

    if(this.deleteEnabled && id === this.updateServiceId && this.updateWorklistId) {
      const batch = this.afs.firestore.batch(); // batch uploader, firestore

      let serviceRef = this.afs.firestore.collection(this.citizens_charter_service_collection_name).doc(this.updateServiceId);
      batch.delete(serviceRef);

      let worklistRef = this.afs.firestore.collection(this.citizens_charter_worklist_collection_name).doc(this.updateWorklistId);
      batch.delete(worklistRef);

      await batch.commit().then(() => {
        alert('Draft deleted');
        this.returnRedirect();
      });
    }
  }

  /**
   * CANCEL
   */
  returnRedirect() {
    this.savedFlag = true;
    this.backToCharter();
  }

  backToCharter() {
    this.router.navigate(['/artemis/charter']);
  }


  openNewServiceDialog(){

    let dialogref = this.serviceDialog.open(NewServiceModalComponent,{
      width: '90vw'



    }).afterClosed().subscribe(


    )

  }


}
