
<mat-card>
  <mat-card-header>
    <mat-card-title><Strong><i class="fas fa-cogs"></i> <span class="text-primary"> Services</span></Strong></mat-card-title>

  </mat-card-header>

  <mat-card-content>
    
    <div class="row">

      <div class="col">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
          <mat-button-toggle (click)="filterBy('')" checked >All Services</mat-button-toggle>
          <mat-button-toggle  (click)="filterBy('Simple')" >Simple</mat-button-toggle>
          <mat-button-toggle  (click)="filterBy('Complex')"  >Complex</mat-button-toggle>
          <mat-button-toggle  (click)="filterBy('Highly Technica')"  >Highly Technical</mat-button-toggle>
        </mat-button-toggle-group>
        
      </div>
      <div class="col-4  mb-3 searchFilter">
        <div class="input-group rounded">
          <input type="search" class="form-control " placeholder="Search service..." aria-label="Search" aria-describedby="search-addon" (keyup)="applyFilter($event)" #input />
          <span class="input-group-text border-0" id="search-addon">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>
    <table class="table table-responsive table-striped table-hover" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" >
              
      
      <!--Auto generated column number -->
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef > No.</th>
        <td mat-cell *matCellDef="let row; ">{{row.no}}</td>
       </ng-container>


      <!-- Date Column -->
      <ng-container matColumnDef="Date">
        <th mat-header-cell class="mat-header" *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let row"> {{ row?.date_posted?.toDate() ? row.date_posted?.toDate() :'2023-02-03' | date: "yyyy-MMM-dd" }} </td>
      </ng-container>
  
      <!-- Service # Column -->
      <ng-container matColumnDef="Classification">
        <th mat-header-cell class="mat-header"  *matHeaderCellDef mat-sort-header> Classification</th>
        <td mat-cell *matCellDef="let row"> 
          {{row.serviceDetails.classification}}
        </td>
      </ng-container>
  
      <!-- Service Name Column -->
      <ng-container matColumnDef="Service Name">
        <th mat-header-cell class="mat-header"  *matHeaderCellDef mat-sort-header> Service Name </th>
        <td mat-cell  *matCellDef="let row"> {{row.serviceDetails.service_name }} </td>
      </ng-container>
  
      <!-- Status Column -->
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        
        <td mat-cell *matCellDef="let row"> 
          <div class="align-items-center">
            <div [ngSwitch]="row.posted_status" >
              <div *ngSwitchCase="'POSTED'">

                <span class="badge bg-primary rounded-pill d-inline " [innerHtml]='row.posted_status'></span>
            
              </div>

              <div *ngSwitchCase="'DELISTED'">

                <span class="badge bg-danger rounded-pill d-inline" [innerHtml]='row.posted_status'></span>
           
              </div>

              <div *ngSwitchDefault>
                    
                <span class="badge bg-info rounded-pill d-inline" [innerHtml]='row.posted_status'></span>
           
            </div>


              </div>

          </div>

        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container  *ngIf="account_type == enum_account_subtype.AGENCY_ENC" matColumnDef="Actions">
        <th mat-header-cell  class="mat-column" *matHeaderCellDef > Actions </th>
        <td mat-cell  *matCellDef="let row"> 
          <div class="button-container">

            <button mat-mini-fab color="primary" 
                    matTooltip="Edit Service"
        
                    (click)="gotoserviceEditor(row.id)"
                    
                    [disabled]="row.posted_status != enum_posted_status.POSTED && row.posted_status != enum_posted_status.DELISTED">
                    <i class="fas fa-edit"></i>
            </button>
            <button mat-mini-fab color="warn"
                    matTooltip="Delist Service"
                    (click)="openReasonForDelisting(row)"
                    [disabled]="row.posted_status != enum_posted_status.POSTED"><i class="fas fa-minus-circle"></i>
            </button>
          
            
          </div> 
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the search "{{input.value}}"</td>
      </tr>
    </table>
  </mat-card-content>

  <mat-card-footer>
    <mat-paginator [pageSizeOptions]="[15, 30, 50 , 100]" aria-label="Select page of users"></mat-paginator>
         
  </mat-card-footer>
</mat-card>

<!---
<div class="container-fluid  px-4">
 
  <div class="row ">
    <div >
      <h3 class="mb-3" ><strong><i class="fas fa-cogs"></i> Services</strong></h3>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="row">
      
          <mat-form-field appearance="outline" class="mt-1">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
          </mat-form-field>
          <div class="col buttonGroup">
            <div class="btn-group shadow-0" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-secondary " value="" (click)="loadAgencyServicesList('')" >All Services</button>
              <button type="button" class="btn btn-secondary " (click)="loadAgencyServicesList('Simple')">Simple</button>
              <button type="button" class="btn btn-secondary " (click)="loadAgencyServicesList('Complex')">Complex</button>
              <button type="button" class="btn btn-secondary " (click)="loadAgencyServicesList('Highly Technical')">Highly Techinical</button>
            
            </div>
            
          </div>
      
      
          <div class="col-4 mb-3 searchFilter">
            <div class="input-group rounded">
              <input type="search" class="form-control " placeholder="Search service..." aria-label="Search" aria-describedby="search-addon" (keyup)="applyFilter($event)" #input />
              <span class="input-group-text border-0" id="search-addon">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
       
      
          <div >
            <table class="table table-responsive table-hover" mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" >
              
      
            Auto generated column number 
              <ng-container matColumnDef="No">
                <th mat-header-cell *matHeaderCellDef > No.</th>
                <td mat-cell *matCellDef="let row; ">{{row.no}}</td>
               </ng-container>
      
      
              Date Column 
              <ng-container matColumnDef="Date">
                <th mat-header-cell class="mat-header" *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let row"> {{ row.date_posted.toDate() | date: "yyyy-MMM-dd" }} </td>
              </ng-container>
          
            Service # Column 
              <ng-container matColumnDef="Classification">
                <th mat-header-cell class="mat-header"  *matHeaderCellDef mat-sort-header> Classification</th>
                <td mat-cell *matCellDef="let row"> 
                  {{row.serviceDetails.classification}}
                </td>
              </ng-container>
          
              Service Name Column
              <ng-container matColumnDef="Service Name">
                <th mat-header-cell class="mat-header"  *matHeaderCellDef mat-sort-header> Service Name </th>
                <td mat-cell  *matCellDef="let row"> {{row.serviceDetails.service_name }} </td>
              </ng-container>
          
          Status Column 
              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                
                <td mat-cell *matCellDef="let row"> 
      
                  <div class="align-items-center">
                    <div [ngSwitch]="row.posted_status" >
                      <div *ngSwitchCase="'POSTED'">
        
                        <span class="badge bg-primary rounded-pill d-inline " [innerHtml]='row.posted_status'></span>
                    
                      </div>
        
                      <div *ngSwitchCase="'DELISTED'">
        
                        <span class="badge bg-danger rounded-pill d-inline" [innerHtml]='row.posted_status'></span>
                   
                      </div>
        
                      <div *ngSwitchDefault>
                            
                        <span class="badge bg-info rounded-pill d-inline" [innerHtml]='row.posted_status'></span>
                   
                    </div>
        
        
                      </div>
        
                  </div>
      
                </td>
              </ng-container>
      
            Actions Column 
              <ng-container  *ngIf="account_type == enum_account_subtype.AGENCY_ENC" matColumnDef="Actions">
                <th mat-header-cell  class="mat-column" *matHeaderCellDef > Actions </th>
                <td mat-cell  *matCellDef="let row"> 
                  <div>
      
                    <button mat-flat-button color="primary" 
                            class="m-1"
                            matTooltip="Edit Service"
                            [routerLink]="['/artemis/charter/updatecharter', row.id]"
                            [disabled]="row.posted_status != enum_posted_status.POSTED && row.posted_status != enum_posted_status.DELISTED">
                            <i class="fas fa-edit fa-xs"></i> Edit
                    </button>
                    <button mat-flat-button color="warn"
                            class="m-1"
                            matTooltip="Delist Service"
                            (click)="openReasonForDelisting(row)"
                            [disabled]="row.posted_status != enum_posted_status.POSTED"><i class="fas fa-minus-circle fa-xs"></i> Delist
                    </button>
                  
                    
                  </div> 
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
       Row shown when there is no matching data. 
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the search "{{input.value}}"</td>
              </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 50 , 100]" aria-label="Select page of users"></mat-paginator>
         
          
            </div>
        </div>
    </div>
  </div>
</div>
-->