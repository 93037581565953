import { Component, OnInit, Input, Output } from '@angular/core';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { GovernmentAccountSubtype, RegulationCommentStatus } from 'src/app/entities/worklist';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { first } from 'rxjs/operators';
import { EmailAppCode, EmailTemplateCode } from 'src/app/entities/emailTemplates';
import { SubmitAs } from 'src/app/entities/consultation';
import { ConsultationCommentModal } from './consultation-comment-modal/consultation-comment-modal.component';
// import { MDBModalService } from 'ng-uikit-pro-standard';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-pbris-comment-create',
  templateUrl: './pbris-comment-create.component.html',
  styleUrls: ['./pbris-comment-create.component.scss']
})
export class PbrisCommentCreateComponent implements OnInit {

  // Modal
  comment: any;
  currenUser:any;
  user: any;
  url: any;
  document: any;
  created_at: any;

  loading: boolean = false;

  // File
  temp_file: any = [];
  // Date
  todayDate: Date = new Date();

  // Path storage
  path: any = '';
  
  data: any = [];

  // User id
  user_id: any;
  user_info: any = [];

  //File  
  uploadedFiles:any = {
    list: [],
    invalidList: []
  }

  submitAs = SubmitAs

  @Input() data_catch: any;
  @Input() data_catch_id: any = '';

  currentConsultation:any;

  checkPbrisCommentForm = {
    comment:[
      {type: 'required', message: 'Comment required'}
    ],
    user:[
      {type: 'required', message: 'User type required'}
    ],
  }

  pbrisCommentForm = this._fb.group({
    comment: new UntypedFormControl('',Validators.required),
    user: new UntypedFormControl('',Validators.required),
    document: new UntypedFormControl(''),
    url: new UntypedFormControl(''),
  });

  constructor(
    public auth: AngularFireAuth, 
    private afs: AngularFirestore, 
    private store: AngularFireStorage,
    // private route: ActivatedRoute,
    private nE: NotificationsEmailer,
    private _fb: UntypedFormBuilder,
    // private modalService: MDBModalService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.auth.user.subscribe( data => {
      this.user_info = {
        user_id: data?.uid,
        name: data?.displayName,
        email: data?.email
      }

      this.afs.collection('Users').doc(data?.uid).snapshotChanges()
      .subscribe({
        next:(res)=>{
          let user:any = res.payload.data()
          this.currenUser = user
          console.log('user: ',user)
        }
      })
    });
  }  

  async onSubmit(result: any) {
    // console.log("the ID of consultation",this.data_catch_id)
    console.log("consultation",this.data_catch)
    this.loading = true;
    this.validateFields()
    if(this.pbrisCommentForm.valid){
      Swal.fire({
        title: 'Processing',
        
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: async () => {
          Swal.showLoading();
          let tempURLArr:any[] = [];
          let temp_file_list = []
          if(this.uploadedFiles.list){
            // console.log('uploadedFiles.list: ', this.uploadedFiles.list)
  
            for await (let file of this.uploadedFiles.list){
              // console.log('file: ',file)
              temp_file_list.push({
                name: file.name,
                type: file.type,
                size: file.size,
              })
              this.path = 'comment_pbris/'+this.user_info.user_id+'/files/';
              let filePath = 'comment_pbris/'+this.user_info.user_id+'/files/'+file.name;
              // const url = await this.uploadFile(filePath,file)
              const url = await this.uploadFile(filePath,file)
              
              // const url ='test'
              tempURLArr.push(url)
    
            }
          }
  
          let typeObj = {
            type_flag: this.data_catch.regulation_type,
            type_id: this.data_catch_id,
            type_title: this.data_catch.short_title,
          };
  
          this.pbrisCommentForm.value.document = temp_file_list;
          this.pbrisCommentForm.value.path = this.path;
          // change this.user_info depending on pbrisCommentForm
          const pbrisCommentVal = this.pbrisCommentForm.value

          pbrisCommentVal.captcha = result.resultCaptcha;

          let userInfo:any = {
            user_id: null,
            name: null,
            email: null,
            type: null
          }
          if(pbrisCommentVal.user == this.submitAs.INIVIDUAL) {
            userInfo = this.user_info
            // userInfo.type = SubmitAs.INIVIDUAL
          }
          else if (pbrisCommentVal.user == this.submitAs.ORGANIZATION){
            // get org
            const organization:any = await this.getFirebaseDocument('Agency',this.currenUser.government.agency)
            if(organization){
              userInfo = {
                user_id: this.user_info.user_id,
                org_id: this.currenUser.government.agency,
                name: this.user_info.name,
                org_name: organization.name,
                email: this.user_info.email,
                org_email: organization.email,
                // type: SubmitAs.ORGANIZATION,
              }
            }else userInfo = this.user_info
          }
          else if(pbrisCommentVal.user == this.submitAs.ANONYMOUS){
            userInfo = {
              user_id: this.user_info.user_id,
              name: 'Anonymous',
              email: this.user_info.email,
              // type: SubmitAs.ANONYMOUS,
            }
          }

          console.log('userInfo: ',userInfo)
  
          this.data.push({
            uuid: this.afs.createId(),
            status: RegulationCommentStatus.UNRESOLVED,
            comments: pbrisCommentVal,//this.pbrisCommentForm.value, // <------------------------------------------------------------
            type: typeObj,
            user: userInfo,//this.user_info, // <------------------------------------------------------------
            reply: '',
            info: this.data_catch,
            created_at: formatDate(this.todayDate, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0800')
          });
  
          // console.log('data: ', this.data[0])
  
          this.afs.collection(`Comment PBRIS`).add(this.data[0])
          .then((docRef:any) => {
            // console.log('hit add: ', docRef)
            // let doc:any = docRef
            this.loading = false;
            // console.log('data: ',this.data[0])
            // console.log('docRef: ',docRef)
            this.codeToSendForNotifEmail()
            Swal.close();
            Swal.fire({
              title:'Successfully processed',
              icon: 'info'
            });
            this.resetForm();
          })
          .catch((error:any) =>{
            this.loading = false;
            console.log('error',error)
            Swal.close();
            Swal.fire({
              title:'System error',
              titleText: 'Please refresh the page',
              icon: 'error'
            });
          });
        }
      })
    } else console.warn('Invalid Form')
  }

  async getFirebaseDocument(collection:string,id:any){
    return new Promise((res,re)=>{
      this.afs.collection(collection).doc(id).snapshotChanges()
      .subscribe({
        next:(result)=>{
          if(result.payload.exists){
            let item:any = result.payload.data()
            item.id = result.payload.id
            return res(item)
          }
          else return res(false)
        },
        error:(err)=>{
          return res(false)
        }
      })
    })
  }

  async uploadFile(filePath:any, file:File){
    // this.showProgress = true    
    // this.generateProgress = 0
    // console.log('hit 1')
    
    return await this.store.upload(filePath, file)
    .then((snapshot:any)=>{
      console.log('snapshot: ', snapshot)
      return snapshot.ref.getDownloadURL()
    })

  }

  onMultipleChange(event: any): void {
    this.onDropzoneMultipleChange(event.target.files);
  }

  onDropzoneMultipleChange(fileList: any): void {
    if(fileList.length > 0){
      for (const file of fileList) {
        // console.log('type',file.type)
        if(file.type == 'application/pdf' || file.type == 'image/jpeg' || 
            file.type == 'image/png' || file.type == 'image/jpg' ||
            file.type == 'image/bmp' || file.type == 'image/gif' ||
            file.type == 'document/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' 
            ){ 
          const l = this.uploadedFiles.list.push(file);
          this.read(file, this.uploadedFiles.list[l - 1]);
        }
        else{
          this.clearFile();
          alert('file type not accepted')
          
        // console.log('type',file.type)
        }
      }
    }else{
      this.clearFile()
    }
    
    if(this.uploadedFiles.list.length > 0){
      (this.pbrisCommentForm.controls['document'] as UntypedFormControl).setValue(this.uploadedFiles.list.length);

    }
  }

  clearFile(){
    (document.getElementById("hidden-file-btn") as HTMLInputElement).value = "";
    // this.position_file_name ='File Name Here.file (DOC, DOCX, PDF, & ZIP ONLY)'; 
    // this.position_file = null;
  }

  read(file: File, store: any): void {
    store.total = (file.size / 1024).toFixed(2);
    store.progress = 0;
    store.loaded = 0;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      store.url = e.target.result;
    };

    reader.onprogress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        store.progress = Math.round((e.loaded / e.total) * 100);
        store.loaded = (e.loaded / 1024).toFixed(2);
      }
    };

    reader.readAsDataURL(file);
  }

  removeFile(index:number){
    (this.pbrisCommentForm.controls['document'] as UntypedFormControl).markAsTouched();
    (this.pbrisCommentForm.controls['document'] as UntypedFormControl).markAsDirty();
    this.uploadedFiles.list.splice(index,1)
    if(this.uploadedFiles.list.length == 0){
      (this.pbrisCommentForm.controls['document'] as UntypedFormControl).setValue(0);
      (document.getElementById("hidden-file-btn") as HTMLInputElement).value = "";
    }
  }

  resetForm() {
    this.pbrisCommentForm.reset();
    this.data = [];
    this.temp_file = [];
    this.uploadedFiles.list = [];
    this.uploadedFiles.invalidList = [];
  }

  documentOnChange(data: any) {
    if (data.target.files.length > 0) {
      let temp = data.target.files;
      for (let x = 0; x < temp.length; x++) {
        if (Object.keys(this.temp_file).length == 0) {
          this.temp_file.push(temp[x]);
        } else {
          let found = this.temp_file.find((elements: any) => elements.name === temp[x].name);
          if (!found) {
            this.temp_file.push(temp[x]);
          }
        }
      }
      this.pbrisCommentForm.value.document = this.temp_file;
    }
  }

  deleteFile(data: any) {
    this.temp_file = this.temp_file.filter((element: any) => element.name !== data);
  }

  openCommentModal(){
    // const modalRef = this.modalService.open(ConsultationCommentModal)
    this.validateFields()
    if(this.pbrisCommentForm.valid){
      const dialogRef = this.dialog.open(ConsultationCommentModal, {
        data: {
          pbrisCommentForm:this.pbrisCommentForm.value,
          temp_file:this.uploadedFiles.list
        },
        disableClose: true,
        // height: '480px',
        // height: '100%',
        // height:'max-content',
        width: '800px',
        panelClass: 'custom-dialog-container',
      });
  
      dialogRef.afterClosed().subscribe({
        next:(result)=>{
          console.log('result: ',result)
          if(result.resultFlag == true) this.onSubmit(result)
        },
        error:(err)=>{
          console.error('Error: ',err)
        }
      })
    }
    else console.warn('Invalid Form')
  }
  
  // email handler
  async codeToSendForNotifEmail(){
    const currentConsultation:any = await this.getConsultation(this.data_catch_id)
    console.log('codeToSendForNotifEmail',currentConsultation)
    if(currentConsultation){
      this.sendToCommenter(currentConsultation)
      this.sendToArta(currentConsultation)
    } else console.error('no consultation data')
  }

  async sendToArta(currentConsultation:any){
    let templateCode: number = 0
    if( this.currenUser.credentials.account_type == 'Government') templateCode = EmailTemplateCode.GOV_END_OF_DAY_MAIL
    // 142
    console.log('sendToArta templateCode: ',templateCode)
    if(templateCode > 0){
      const artaAdminUsers:any = await this.getArtaAdmin()
      if(artaAdminUsers.length > 0){
        let emails:any = []
        artaAdminUsers
        // .filter((filterItem:any)=>
        // (
        //   this.trueIfNullOrUndefined(filterItem.settings) ||
        //   this.trueIfNullOrUndefined(filterItem.settings.consultations) ||
        //   this.trueIfNullOrUndefined(filterItem.settings.consultations.create) ||
        //   filterItem.settings.consultations.create == true
        // ) 
        // ? true : false) //////////////////////////////
        .map((user:any)=>{if(!emails.includes(user.credentials.email)) emails.push(user.credentials.email)})
        if (emails && emails.length > 0){
          let data = {
            consultationTitle: this.data_catch.regulation_title,
            consultationTrackNo: this.data_catch_id
          }
          console.log('sending sendToArta: ',{emails},{templateCode})
          // this.toSendNotifEmail(emails,templateCode,data)
          this.nE.emailServiceHandler(emails,EmailAppCode.ARTA,templateCode,data)
        } else console.warn('no user subscribed to consultations')
      } else console.warn('no user that is arta admin')
    } else console.warn('User account settings consultations is not set to true')
  }

  trueIfNullOrUndefined(value:any){
    return value == null || value == undefined ? true : false
  }

  async sendToCommenter(currentConsultation:any){
    let templateCode: number = 0
    if(this.currenUser.credentials.account_type == 'Personal'
    || this.currenUser.credentials.account_type == 'Company'
    || this.currenUser.credentials.account_type == 'Government') templateCode = EmailTemplateCode.USER_SUBMIT_COMMENT
    // 5
    console.log('sendToArta templateCode: ',templateCode)
    if(templateCode > 0){
      // if(this.currenUser.setting && this.currenUser.setting.consultations.create == true){
        let data = {
          consultationTitle: this.data_catch.regulation_title,
          // consultationDate: moment().format('MMMM-DD-YYYY'),//,currentConsultation.from_date.toDate(),
          consultationTrackNo: this.data_catch_id
        }
        console.log('sending sendToCommenter: ',this.currenUser.credentials.email,{templateCode})
        // this.toSendNotifEmail([this.currenUser.credentials.email],templateCode,data) // 
        this.nE.emailServiceHandler([this.currenUser.credentials.email],EmailAppCode.ARTA,templateCode,data)
      // } else console.warn('user not subscribed to consultations')
    } else console.error('PbrisCommentCreateComponent emailer error: no email template for account type')
  }

  getConsultation(id:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Public Consultations').doc(id).snapshotChanges()
      .pipe(first())
      .subscribe({
        next:(result)=>{
          let consultation:any = result.payload.data()
          // console.log('getConsultation',consultation)
          return res(consultation)
        },
        error: (err)=>{
          return res(false)
        }
      })
    })
  }

  getArtaAdmin(){
    const agencyIds = this.data_catch.agencyIds ? this.data_catch.agencyIds : null
    if(agencyIds){
      return new Promise((res,rej)=>{
        // if(agencyIds.length > 10){
        //   console.log('agencyIds more than 10')
        //   return res(false)
        // }
        // else{
        //   this.afs.collection('Users',filter=>filter.where('credentials.account_type','==','Government').where('credentials.account_subtype','==','ARTA - IT/Admin')).snapshotChanges() //.where('government.agency','in',agencyIds)
        //   .pipe(first())
        //   .subscribe({
        //     next:(result)=>{
        //       let tempArr:any = [];
        //       console.log('arta it admins',result.length)
        //       result.map(data=>{
        //         let user:any = data.payload.doc.data()
        //         user.id = data.payload.doc.id
        //         // console.log('admin:',user)
        //         if(!tempArr.map((item:any)=>item.id).includes(user.id)) tempArr.push(user)
        //       })
        //       return res(tempArr)
        //     },
        //     error:(err)=>{
        //       console.error('getArtaAdmin',err)
        //       return res(false)
        //     }
        //   })
        // }
        this.afs.collection('Users',filter=>filter.where('credentials.account_type','==','Government').where('credentials.account_subtype','==',GovernmentAccountSubtype.ARTA_ADMIN)).snapshotChanges() //.where('government.agency','in',agencyIds)
        .pipe(first())
        .subscribe({
          next:(result)=>{
            let tempArr:any = [];
            console.log('arta it admins',result.length)
            result.map(data=>{
              let user:any = data.payload.doc.data()
              user.id = data.payload.doc.id
              // console.log('admin:',user)
              if(!tempArr.map((item:any)=>item.id).includes(user.id)) tempArr.push(user)
            })
            return res(tempArr)
          },
          error:(err)=>{
            console.error('getArtaAdmin',err)
            return res(false)
          }
        })
      })
    } else return false
  }

  // toSendNotifEmail(currentUsers:any[], templateCode:number,data:any){
  //   if(templateCode > 0 && currentUsers.length > 0){
  //     this.nE.emailServiceHandler(currentUsers,EmailAppCode.ARTA,templateCode,data)
  //   }
  // }
  // !email handler

  // validations
  validateFields(){
    if(!this.pbrisCommentForm.valid){
      this.pbrisCommentForm.markAsTouched()
      for (const control in this.pbrisCommentForm.controls) {
        if (this.pbrisCommentForm.controls.hasOwnProperty(control)) {
          this.pbrisCommentForm.controls[control].markAllAsTouched();
          this.pbrisCommentForm.controls[control].markAsDirty();
        }
      }
    }
  }

  hasError(controlName: string, errorType: string){
    const formGroup = (this.pbrisCommentForm as UntypedFormGroup)
    return (formGroup.get(controlName)?.hasError(errorType) && (formGroup.get(controlName)?.dirty || formGroup.get(controlName)?.touched))
  }
  // !validations
}
