<div class="content col-md-12">
    <div class="container-fluid">    

        <div class="row">
            <div class="card padded">
                <div class="card-header info-header">
                    <div class="row">
                        <div class="ms-3">
                            <h6 class="fw-bold">{{baseCode}} Uploaded documents</h6>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">

                        <ul>
                            <ng-container *ngFor="let uploadData2 of objectUploads; let i = index; let c = count">
                                <li>
                                    <div class="row">
                                        <div class="col-4 fw-bold">
                                            {{uploadData2.name}} :
                                        </div>
                                        <div class="col">
                                            {{uploadData2.fileName ? uploadData2.fileName : '- Unavailable -'}} 
                                            <ng-container *ngIf="uploadData2.downloadUrl" title="click to download file">
                                                <a [routerLink]="[]" (click)="downloadFile(uploadData2.downloadUrl)"><i class="fas fa-file-download"></i></a>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr *ngIf="i<(c-1)">
                                </li>
                            </ng-container>
                        </ul>

                    </div>
                </div>

            </div>
        </div>

    </div>
</div>