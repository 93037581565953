<div class="container-fluid">
  <h3 class="mb-3"><Strong><i class="fas fa-comment-alt"></i> User Feedback</Strong></h3>

<div class="card">
  <div class="card-body">
    <table class="table table-responsive   table-hover"  mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" >
    
      <!--Column Auto number-->
   <ng-container matColumnDef="No">
     <th mat-header-cell class="col-header" *matHeaderCellDef > No.</th>
     <td mat-cell *matCellDef="let row;">
        {{ row.no }}</td>
    </ng-container>

   <!--Column Commentor Name-->
   <ng-container matColumnDef="application">
     <th mat-header-cell class="col-header"  *matHeaderCellDef > Application </th>
     <td mat-cell *matCellDef="let row"> {{ row.application}} </td>
   </ng-container>


    <!--Column Service Name Name-->
    <ng-container matColumnDef="feature">
     <th mat-header-cell  *matHeaderCellDef mat-sort-header> Feature </th>
     <td mat-cell *matCellDef="let row"> {{ row.feature}} </td>
   </ng-container>

 

    <!--Column Date Generated-->
    <ng-container matColumnDef="discover">
     <th mat-header-cell  *matHeaderCellDef mat-sort-header> Discover </th>
     <td mat-cell *matCellDef="let row"> {{row.discover}} </td>
   </ng-container>

    <!--Column Date Generated-->
    <ng-container matColumnDef="dategenerated">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header> Date Generated </th>
      <td mat-cell *matCellDef="let row"> {{row.created_at}} </td>
    </ng-container>

    <ng-container matColumnDef="rating">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header> Ratings </th>
      <td mat-cell *matCellDef="let row"> 

        <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block" >
          <li [ngClass]="{'selected': (star <= row.rates)}">
          <i class="fas fa-star"></i> </li> 
      </ul>
         
     
      </td>
    </ng-container>

     <!--Column Date Generated-->
     <ng-container matColumnDef="action">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let row">

        <button mat-stroked-button 
        class="m-1 viewBtn"
        matTooltip="View Feedback"
        (click)="sampleModal.show(); openModal(row)">View
          </button>
        
      </td>
    </ng-container>

   
 

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

 </table>

 <mat-paginator #paginator [pageSizeOptions]="[10,25,50, 100]" aria-label="Select page of users"></mat-paginator>
   
  </div>
</div>

<!---End of table-->


<div >

  <!---<div class="card-body p-0">
    <div class="table-responsive" style="max-height: 500px;">
      <table class="table">
        <thead class="text-muted">
          <tr>
            <th class="table-header">Application</th>
            <th class="table-header">Feature</th>
            <th class="table-header">Discover</th>
            <th class="table-header">Date Generated</th>
            <th class="table-header">Action</th>
          </tr>
        </thead>
        <tbody class="card-text" *ngFor="let list of feedbackList; let i = index">
          <tr>
            <th scope="row">{{ list.data.application }}</th>
            <td>{{ list.data.feature }}</td>
            <td>{{ list.data.discover }}</td>
            <td>{{ list.data.created_at }}</td>
            <td>
              <button type="button" class="btn btn-info" (click)="sampleModal.show(); openModal(list.data)" >View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>-->

  <!-- Modal -->
  <div style="overflow-y: auto" class="modal fade" mdbModal #sampleModal="mdbModal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review Feedback</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sampleModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <dl class="row">
            <dt class="col-sm-3">Application</dt>
            <dd class="col-sm-9">{{ application ? application : 'N/A' }}</dd>
          
            <dt class="col-sm-3">Feature</dt>
            <dd class="col-sm-9">{{ feature ? feature : 'N/A' }}</dd>
          
            <dt class="col-sm-3 text-truncate">Enhancement</dt>
            <dd class="col-sm-9">{{ enhance ? enhance : 'N/A' }}</dd>
          
            <dt class="col-sm-3 text-truncate">Improvements</dt>
            <dd class="col-sm-9">{{ improve ? improve : 'N/A' }}</dd>
          
            <dt class="col-sm-3 text-truncate">Elaborate</dt>
            <dd class="col-sm-9">{{ elaborate ? elaborate : 'N/A' }}</dd>

            <dt class="col-sm-3">Experience</dt>
            <dd class="col-sm-9">{{ experience ? experience : 'N/A' }}</dd>

            <dt class="col-sm-3 text-truncate">Others</dt>
            <dd class="col-sm-9">{{ others ? others : 'N/A' }}</dd>

            <dt class="col-sm-3 text-truncate">Discover</dt>
            <dd class="col-sm-9">{{ otherDiscover ? otherDiscover : 'N/A' }}</dd>

            <dt class="col-sm-3 text-truncate">Overall</dt>
            <dd class="col-sm-9">{{ overall ? overall : 'N/A' }}</dd>

            <dt class="col-sm-3 text-truncate">Refer</dt>
            <dd class="col-sm-9">{{ refer ? refer : 'N/A' }}</dd>

            <dt class="col-sm-3 text-truncate">Visit</dt>
            <dd class="col-sm-9">{{ visit ? visit : 'N/A' }}</dd>
          </dl>
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="sampleModal.hide()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>



</div>