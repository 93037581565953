import { Component, EventEmitter, OnInit, Output,AfterViewInit, ViewChild} from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Subject,combineLatest, BehaviorSubject  } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';
import {format, isThursday } from 'date-fns';
import {
  CharterStatusCitizensCharter,
  GovernmentAccountSubtype,
  WorklistArtaMappedStatusCitizensCharter,
} from 'src/app/entities/worklist';

import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-artemis-charter-inbox-page-arta-charter-list',
  templateUrl: './artemis-charter-inbox-page-arta-charter-list.component.html',
  styleUrls: ['./artemis-charter-inbox-page-arta-charter-list.component.scss'],
})
export class ArtemisCharterInboxPageArtaCharterListComponent implements OnInit {

  //number formatting
  ordinals: string[] = ['th', 'st', 'nd', 'rd'];

  results: any = []; // main countainer for the inbox results list

  agencies:any=[]

  agencyCategories:any=[]
  filterType = '';
  
  displayedColumns: string[] = ['date_submitted', 'agecy_name', 'category',
    'cc_version', 'status', 'action_due'];
    dataSource: MatTableDataSource<any>;
  // dataSource:any= [];

  sortOrder = 1;
  collator = new Intl.Collator(undefined,{
    numeric:true,
    sensitivity:"base",
  })

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('')
  })

  dtTrigger: Subject<any> = new Subject<any>();

  filteredValues:any = {
    global: '',
    agencyCategory: '',
  }

  private charterStatusesUnderArta = [
    CharterStatusCitizensCharter.FOR_ARTA_REVIEW,
    CharterStatusCitizensCharter.ONGOING_ARTA_REVIEW,
    CharterStatusCitizensCharter.FOR_DC_APPROVAL,
    CharterStatusCitizensCharter.FOR_DIR_APPROVAL,
  ];

  @Output() toInboxComponent = new EventEmitter<object>();

  constructor(private afs: AngularFirestore) {}
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.formValueChanger()
    this.loadCounts();
    this.loadAgencyCategory();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    // this.dtTrigger.unsubscribe();
  }

  //format certificate of compliance edition and version
  transform(n: number, year:any , keepNumber: boolean = true) {
    let v = n % 100;
    return year + ' ' + (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]) +  " Edition";
  }
  async loadCounts() {
    this.results = [];

    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('status', 'in', this.charterStatusesUnderArta)
        .orderBy('dateSubmitted', 'desc');

    this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .snapshotChanges()
      .pipe(
        tap(async (data: any) => {
          // orig code
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            item.origDate = item.date.toDate(),
            // item.date = format( item.date.toDate(), 'yyyy-MMM-dd')
            item.date = item.date.toDate()
            item.dateFormatted = format( item.date, 'yyyy-MMM-dd')
            // item.dateSubmitted = format( item.dateSubmitted.toDate(), 'yyyy-MMM-dd')
            item.dateSubmitted = item.dateSubmitted.toDate()
            item.dateSubmittedFormatted  = format( item.dateSubmitted, 'yyyy-MMM-dd hh:mm aaa')
            // item.dueDate = item.dueDate ? item.dueDate = format( item.dueDate.toDate(), 'yyyy-MMM-dd'): 'N/A'
            item.dueDate = item.dueDate ? item.dueDate.toDate() : null
            item.dueDateFormatted = item.dueDate ? format( item.dueDate, 'yyyy-MMM-dd'): 'N/A'
            item.year = new Date(item.dateSubmitted).getFullYear()
            
            // console.log('agency',item)
            this.results.push(item);
          });
          //console.log('charters', this.results)
          this.dataSource = new MatTableDataSource(this.results);

          this.resetSortPage();
          let sortData: Sort = {
            active:'returnedByArtaForRevision',
            direction:'asc'
          }
          this.sortChange(sortData)

          // console.log('sort',this.dataSource.sort)
          //

          // const promisedArr:any = await Promise.all(
          //   data.map(async (info: any)=>{
          //     let item: any = info.payload.doc.data();
          //     item.id = info.payload.doc.id;
          //     item.date = format( item.date.toDate(), 'yyyy-MMM-dd')
          //     item.dateSubmitted = format( item.dateSubmitted.toDate(), 'yyyy-MMM-dd')
          //     item.dueDate? item.dueDate = format( item.dueDate.toDate(), 'yyyy-MMM-dd'): 'N/A'
          //     item.agency = await this.getAgency(item.fromAgencyId)
          //     console.log('item',item)
          //     return item
          //   })
          // )
          // if(promisedArr){
          //   // console.log('agencies',this.agencies)
          //   // console.log('promisedArr',promisedArr)
          //   this.results = promisedArr
          //   this.dataSource = new MatTableDataSource(this.results);
          //   this.resetSortPage();
          // }
        }),
        take(1)
      )
      .subscribe();
  }

  loadAgencyCategory(){
    this.afs.collection('Agency Category').snapshotChanges()
    .subscribe({
      next:(result)=>{
        let tempArr:any = []
        result.map(data=>{
          let item:any = data.payload.doc.data()
          item.id = data.payload.doc.id
          tempArr.push(item)
        })
        this.agencyCategories = tempArr
      },
      error:()=>{

      }
    })
  }

  async getAgency(agencyId:any){
    const agencyCtr = this.agencies.map((item:any)=>item.id).indexOf(agencyId)
    if(agencyCtr >= 0){
      return this.agencies[agencyCtr]
    }else{
      // load agency
      return await this.loadAgency(agencyId)
    }
  }

  loadAgency(agencyId:any){
    return new Promise((res,rej)=>{
      this.afs.collection('Agency').doc(agencyId).snapshotChanges()
      .pipe(first())
      .subscribe({
          next:(item)=>{
          let agency:any = item.payload.data()
          agency.id = item.payload.id

          if(!this.agencies.map((item:any)=>item.id).includes(agency.id)) this.agencies.push(agency)

          return res(agency)
        },
        error:(err)=>{
          console.error('Error: ',err)
          return res(null)
        }
      })
    })
  }

  sortChange(sort: Sort){
    const data = this.results.slice()
    // console.log('sort.active',sort.active)
    // console.log('sort.direction',sort.direction)
    if(!sort.active || sort.direction === ''){
      this.results = data
      return;
    }
    this.results = data.sort((a:any,b:any)=>{      
      // console.log('results',this.results)
      const isAsc = sort.direction === 'asc'
      switch (sort.active){        
        case 'date_submitted':   
        // console.log('a:',a.dateSubmitted.trim().toLowerCase(),',b:', b.dateSubmitted.trim().toLowerCase())       
          return this.compare(a.dateSubmitted, b.dateSubmitted, isAsc)
        case 'agecy_name':          
          return this.compare(a.fromAgencyName.trim().toLowerCase(), b.fromAgencyName.trim().toLowerCase(), isAsc)
        case 'category':          
          return this.compare((a.agencyCategory ? a.agencyCategory.name ? a.agencyCategory.name : '' : '').trim().toLowerCase(), (b.agencyCategory ? b.agencyCategory.name ? b.agencyCategory.name : '' : '').trim().toLowerCase(), isAsc)
        case 'cc_version': 
          return this.compare(a.date.trim().toLowerCase(), b.date.trim().toLowerCase(), isAsc)
        case 'status': 
          return this.compare(a.status.trim().toLowerCase(), b.status.trim().toLowerCase(), isAsc)
        case 'action_due': 
          return this.compare(a.dueDate, b.dueDate, isAsc)
        case 'returnedByArtaForRevision':
          return ((a.returnedByArtaForRevision == true && b.returnedByArtaForRevision != true) ? 1 : -1)
        default:
          return null
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.results)
    this.resetSortPage()

    if(this.checkFilters()) this.dataSource.filter = this.filteredValues
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // search functions
  search(event:Event){
    let searchValue = (event.target as HTMLInputElement).value
    this.filteredValues.global = searchValue
    // this.dataSource.filter = searchValue
    let bool = this.checkFilters()
    //console.log('bool: ',bool)
    if(bool) this.dataSource.filter = this.filteredValues
    else this.dataSource.filter = searchValue
  }

  formValueChanger(){
    this.searchForm.controls.keyword.valueChanges.subscribe({
      next:(value)=>{
        // let searchValue = (event.target as HTMLInputElement).value
        this.filteredValues.global = value
        this.filtering(value)
      }
    })
  }

  filterTable(filter:string, value:any){
    this.filteredValues[filter] = value ? value.trim().toLowerCase() : ''
    this.dataSource.filter = this.filteredValues
    this.filtering(value)
  }

  filtering(value:any){
    if(this.checkFilters()) this.dataSource.filter = this.filteredValues
    else this.dataSource.filter = value
  }
  
  settingFilters(){
    this.dataSource.filterPredicate = (option:any, searchValue:string): boolean =>{
      // if(searchValue){
      //   this.globalSearch(option,searchValue)
      // }else return false 
      if(this.filteredValues.agencyCategory){
        if(this.filteredValues.global != '' && this.filteredValues.global != null && this.filteredValues.global != undefined)
          return (this.checkCategory(option.agencyCategory, this.filteredValues.agencyCategory) && this.globalSearch(option,this.filteredValues.global))
        
        else return this.checkCategory(option.agencyCategory, this.filteredValues.agencyCategory)
      }
      else {
        if(this.filteredValues.global){
          return this.globalSearch(option,this.filteredValues.global)
        } else return false
      }
    }
  }

  globalSearch(option:any, searchValue:any){
    return (
      // (option.agency.abbreviation)?.trim().toLowerCase().includes(searchValue)
      // || (option.agency.name)?.trim().toLowerCase().includes(searchValue)
      // || (option.agency.agencyDetails.agencyName)?.trim().toLowerCase().includes(searchValue)
      // || (option.agency.agencyDetails.agencyAbbreviation)?.trim().toLowerCase().includes(searchValue)
      (this.checkKeywords(option.keywords, searchValue))
      || this.checkCategory(option.agencyCategory, searchValue)
      || (option.fromAgencyName)?.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
      // || (option.date).includes(searchValue)
      || (option.dateFormatted)?.includes(searchValue.trim().toLowerCase())
      // || (option.dateSubmitted)?.includes(searchValue)
      || (option.dateSubmittedFormatted)?.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
      // || (option.dueDate)?.includes(searchValue)
      || (option.dueDateFormatted)?.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
      || (option.status)?.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
    )
  }

  checkCategory(category:any, searchValue:string){
    if(searchValue){
      searchValue = searchValue.trim().toLowerCase()
    }
    if(category && Object.keys(category).length > 0){
      for(let key of Object.keys(category)){
        if(key != 'id'){
          if(category[key].trim().toLowerCase().includes(searchValue)) return true
        }
      }
      return false
    }else return false
  }

  checkKeywords(keywords:any, searchValue:string){
    if(searchValue){
      searchValue = searchValue.trim().toLowerCase()
    }
    if(keywords && Object.keys(keywords).length > 0){
      for(let key of Object.keys(keywords)){
        if(keywords[key].trim().toLowerCase().includes(searchValue)) return true
      }
      return false
    }else return false
  }

  checkFilters(){
    for(let key of Object.keys(this.filteredValues)){
      if(this.filteredValues[key]) return true
    }
    return false
  }
  // 

  openCharterServices(charterObj: any) {
    //console.log('Agency Data',charterObj)
    this.toInboxComponent.emit({
      service_ids: charterObj.service_id,
      citizens_charter: charterObj.id,
      agency_id: charterObj.fromAgencyId,
      agency_name: charterObj.fromAgencyName
    });
  }

  resetSortPage(){
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.settingFilters()
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      //console.log(`Sorted ${sortState.direction}ending`);
    } else {
      //console.log('Sorting cleared');
    }
  }
}
