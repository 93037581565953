import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DocumentToJSONToTextFileEmailer{
	createTextFile(name:string, item:any){
		const element = document.createElement('a');
		const stringfied = JSON.stringify(item);
		let blob = new Blob([stringfied], {type: 'text/plain'});
		// let downloadLink = window.URL.createObjectURL(blob);
		// window.open(downloadLink)
		element.href = URL.createObjectURL(blob);
		element.download = name+'.txt';
		element.click();
	}
}