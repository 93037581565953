<div class="container-fluid">
    <div class="statusHeader">
        <h1>Verify Draft Existing Regulation</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum temporibus, ut earum eum consequuntur quibusdam architecto officia voluptates quia impedit saepe, veritatis minus voluptatem ea explicabo aspernatur non error qui.</p>
    </div>
    <hr>
    <!-- Title of the Regulation-->
    <div class="titleOfRegulation">
        <h1>[Title of the Regulation]</h1>
        <p>Submitted on [Date] </p>
    </div>
    <hr>
    <!-- Sections-->
    <div class="sectionList">
        <div class="sections overflow-auto">
            <h4>[Section Head] </h4>
            <p>[Section Body]</p>
        </div>
        <div class="addComment">
            <button>Add Comment</button>
            <!-- comment section display-->
            <div class="form-floating">
                <textarea class="form-control" id="floatingTextarea2" style="height: 100px">
                    <label for="floatingTextarea2">Commented By: [agency Head Name]</label></textarea>

            </div>
        </div>
    </div>
</div>