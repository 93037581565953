import { AfterViewInit, Component, OnDestroy, OnInit, ResolvedReflectiveFactory, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { first, take, tap } from 'rxjs/operators';
import {Query, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { DataTableDirective } from 'angular-datatables';
import {LegacyProgressSpinnerMode as ProgressSpinnerMode} from '@angular/material/legacy-progress-spinner';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';
import { CollectionName } from 'src/app/entities/constant';

export enum FromCollectionCode {
  PHASE1 = 'phase1',
  PHASE2_EXISTING = 'phase2existing',
  PHASE2_PROPOSED = 'phase2proposed',
}

@Component({
  selector: 'app-regulations-result',
  templateUrl: './regulations-result.component.html',
  styleUrls: ['./regulations-result.component.scss']
})
export class RegulationsResultComponent implements OnInit, AfterViewInit, OnDestroy  { //, , AfterViewInit

  allowNonPosted:boolean = false;

  status: string = "";
  regulations: any[] = [];
  regulationsLoading : boolean = true
  totalRegulationsNum : number = 0;
  currentRegulationNum : number = 0;
  currentRegulationInPercent: number = 0;
  searchQueryJSON: any;

  loading: boolean = false;

  regulation_info: any;
  regulation_status: boolean = false;
  reg_id: any = null;
  user_id: any = null;
  currentUser:any = null;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  hasKeyword:boolean = false;
  // loadedSector: any[] = [];
  private loadedSectorMap: Map<string, any> = new Map();
  // loadedDivision: any[] = [];
  private loadedDivisionMap: Map<string, any> = new Map();
  // loadedStageOfBusiness: any[] = [];
  private loadedStageOfBusinessMap: Map<string, any> = new Map();
  // loadedStageofLife: any[] = [];
  private loadedStageofLifeMap: Map<string, any> = new Map();
  // loadedLifeEvent: any[] = [];
  private loadedLifeEventMap: Map<string, any> = new Map();
  // loadedJurisdiction: any[] = [];
  private loadedJurisdictionMap: Map<string, any> = new Map();
  // loadedCaseUse: any[] = [];
  private loadedCaseUseMap: Map<string, any> = new Map();
  // loadedDocument: any[] = [];
  private loadedDocumentMap: Map<string, any> = new Map();
  // loadedAgency: any[] = [];
  private loadedAgencyMap: Map<string, any> = new Map();
  // loadedInstrument: any[] = [];
  private loadedInstrumentMap: Map<string, any> = new Map();

  private loadedWorklistProposedMap: Map<string, any> = new Map(); 

  searchform: UntypedFormGroup = new UntypedFormGroup({
    //keyword: new FormControl('', Validators.required),
    keyword: new UntypedFormControl(''),
    classification: new UntypedFormControl(''),
    effectivity_status: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    keyword_sector: new UntypedFormControl(false),
    keyword_sob: new UntypedFormControl(false),
    keyword_juris: new UntypedFormControl(false),
    keyword_effectivity: new UntypedFormControl(false),
    keyword_div: new UntypedFormControl(false),
    keyword_case: new UntypedFormControl(false),
    keyword_agency: new UntypedFormControl(false),
  });

  onClassView:boolean = false
  onSectorView:boolean = false
  onSobView:boolean = false
  onJurisView:boolean = false
  onEffectivityView:boolean = false
  onDivView:boolean = false
  onCaseView:boolean = false
  onAgencyView:boolean = false

  isDtInitialized:boolean = false

  // private referenceCollections: Map<string, any[]>;
  private referenceCollections: Map<string, Map<string, any>>;
  
  private referenceArr:any [] = [];
  private referenceArrMap: Map<string, any> = new Map();
  
  // private agencies: any[] = [];
  // private sectors: any[] = [];
  // private sobs: any[] = [];
  // private divisions: any[] = [];
  // private jurisdictions: any[] = [];
  // private cases: any[] = [];
  // private instruments: any[] = [];
  // private sols: any[] = [];
  // private lifeevents: any[] = [];

  dtInstanceTriggered:boolean = false

  page = 1
  documentsPerPage = 15

  constructor(private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router, 
    public auth: AngularFireAuth,
    private http: HttpClient,
    private nE: NotificationsEmailer,
    private  chRef: ChangeDetectorRef,) {

    route.queryParams.subscribe(params => {
      this.searchQueryJSON = params;
      // console.log("search query",this.searchQueryJSON)
    })

    this.loadReferenceData();
  }

  ngOnInit() {
    this.regulations = []
    this.settingDataTable()
    this.userType()
    this.loadStatus(this.route.snapshot.params.status)
    this.onUser()
    this.handleSearch()
  }

  
  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtOptions);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  
  settingDataTable(){
    this.dtOptions = {
      searching: false,
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 15,
      lengthChange: false,
    };
  }

  userType(){
    const uType = sessionStorage.getItem('user_type')
    // || uType == 'agency'
    if(uType == 'arta') this.allowNonPosted = true
    console.log('uType: ',uType)
  }
  
  loadStatus(getStatus: string) {
    if (getStatus) {
      this.status = getStatus.charAt(0).toUpperCase() + getStatus.slice(1)
    }
  }

  onUser(){ 
    this.auth.user.subscribe( data => {
      this.user_id = data?.uid
      this.afs.collection('Users').doc(data?.uid).snapshotChanges()
      .pipe(first())
      .subscribe(res=>{
        this.currentUser = res.payload.data()
      })
    });
  }

  handleSearch(){
    this.route.queryParams.subscribe(async params => {
      let JSONfields:any = params;
      let loadAll = false;
      // console.log(JSONfields.status)
      if(JSONfields.status != '' && JSONfields.status != null && JSONfields.status != undefined) loadAll = false
      else loadAll = true

      if(Object.keys(JSONfields).length > 0) {
        if(JSONfields.sector != '' && JSONfields.sector != null ){
          const sectorId = await this.getIDByValue('Sector','name',JSONfields.sector)
          JSONfields = Object.assign({sectorId: sectorId},JSONfields)
        } else JSONfields = Object.assign({sectorId: ''},JSONfields)

        if(JSONfields.division != '' && JSONfields.division != null ){
          const divisionId = await this.getIDByValue('Division','name',JSONfields.division)
          JSONfields = Object.assign({divisionId: divisionId},JSONfields)
        }else JSONfields = Object.assign({divisionId: ''},JSONfields)

        if(JSONfields.stageofbusiness != '' && JSONfields.stageofbusiness != null ){
          const stageofbusinessId = await this.getIDByValue('Stage of Business','name',JSONfields.stageofbusiness)
          JSONfields = Object.assign({stageofbusinessId: stageofbusinessId},JSONfields)
        }else JSONfields = Object.assign({stageofbusinessId: ''},JSONfields)

        if(JSONfields.caseuse != '' && JSONfields.caseuse != null ){
          const caseuseId = await this.getIDByValue('Case Use','name',JSONfields.caseuse)
          JSONfields = Object.assign({caseuseId: caseuseId},JSONfields)
        }else JSONfields = Object.assign({caseuseId: ''},JSONfields)

        if(JSONfields.jurisdiction != '' && JSONfields.jurisdiction != null ){
          const jurisdictionId = await this.getIDByValue('Jurisdiction','name',JSONfields.jurisdiction)
          JSONfields = Object.assign({jurisdictionId: jurisdictionId},JSONfields)
        }else JSONfields = Object.assign({jurisdictionId: ''},JSONfields)

        if(JSONfields.agencylocation != '' && JSONfields.agencylocation != null ){
          const agencylocationId = await this.getIDByValue('AgencyLocation','name',JSONfields.agencylocation)
          JSONfields = Object.assign({agencylocationId: agencylocationId},JSONfields)
        }else JSONfields = Object.assign({agencylocationId: ''},JSONfields)

        if(JSONfields.document != '' && JSONfields.document != null ){
          const documentId = await this.getIDByValue('Document','name',JSONfields.document)
          JSONfields = Object.assign({documentId: documentId},JSONfields)
        }else JSONfields = Object.assign({documentId: ''},JSONfields)

        if(JSONfields.instrument != '' && JSONfields.instrument != null ){
          const instrumentId = await this.getIDByValue('Instrument','name',JSONfields.instrument)
          JSONfields = Object.assign({instrumentId: instrumentId},JSONfields)
        }else JSONfields = Object.assign({instrumentId: ''},JSONfields)

        if(JSONfields.stageoflife != '' && JSONfields.stageoflife != null ){
          const stageoflifeId = await this.getIDByValue('Stage of Life','stage',JSONfields.stageoflife)
          // console.log('stageoflifeId',stageoflifeId)
          JSONfields = Object.assign({stageoflifeId: stageoflifeId},JSONfields)

          if(JSONfields.lifeevent != '' && JSONfields.lifeevent != null ){
            const lifeeventId = await this.getIDByValue('Life Event','code',JSONfields.stageoflife+'-'+JSONfields.lifeevent)
            JSONfields = Object.assign({lifeeventId: lifeeventId},JSONfields)
          }else JSONfields = Object.assign({lifeeventId: ''},JSONfields)
        }else{
          JSONfields = Object.assign({stageoflifeId: ''},JSONfields)
          JSONfields = Object.assign({lifeeventId: ''},JSONfields)
        }

        if((JSONfields.agency != '' && JSONfields.agency != null)  || (JSONfields.agencyaddress != '' && JSONfields.agencyaddress != null)){
          const agencyIds = await this.getAgency(JSONfields)
          JSONfields = Object.assign({agencyIds: agencyIds},JSONfields)
        }else JSONfields = Object.assign({agencyIds: []},JSONfields)

        if(JSONfields.classification != '' && JSONfields.classification != null){
          this.searchform.patchValue({classification: JSONfields.classification})
        }
        
        if(JSONfields.effectivity_status != '' && JSONfields.effectivity_status != null){
          this.searchform.patchValue({effectivity_status: JSONfields.effectivity_status})
        }
        if(JSONfields.keyword_sector == true){this.searchform.patchValue({keyword_sector: true})}
        if(JSONfields.keyword_sob == true){this.searchform.patchValue({keyword_sob: true})}
        if(JSONfields.keyword_juris == true){this.searchform.patchValue({keyword_juris: true})}
        if(JSONfields.keyword_div == true){this.searchform.patchValue({keyword_div: true})}
        if(JSONfields.keyword_case == true){this.searchform.patchValue({keyword_case: true})}
        if(JSONfields.keyword_agency == true){this.searchform.patchValue({keyword_agency: true})}
        
      }
      // JSONfields.short_title = params.keyword
      JSONfields = Object.assign({short_title: params.keyword},JSONfields)

      // const newObj = Object.assign({selected: false}, data);
      // if(params){
      //   JSONfields = params;
      //   JSONfields.short_title = params.keyword
      // }else{
      //   JSONfields.short_title = ''
      // }

      // if (params.keyword === "") {
      //   JSONfields.short_title = ""
      // }
      // else {
      //   JSONfields.short_title = params.keyword
      // }

      if(JSONfields.keyword != '' && JSONfields.keyword != null){
        this.searchform.patchValue({
          keyword: JSONfields.keyword
        })
        this.hasKeyword = true
      }

      // console.log("passed json", JSONfields)
      this.loadRegulations(JSONfields,loadAll);
    })
  }

  getAgency(JSONfields:any){
    if(JSONfields.agency != '' && JSONfields.agency != null){
      return this.afs.collection('Agency').ref.where('name','==',JSONfields.agency).get()
      .then(info=>{
        let tempArr:any = []
        info.forEach(data=>{
          if(data.exists){
            let agency:any = data.data()
            agency.id = data.id

            if(JSONfields.agencyaddress != '' && JSONfields.agencyaddress != null){
              if(agency.addressDetails){
                if(agency.addressDetails.addressLine) {
                  if (agency.addressDetails.addressLine.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.barangay) {
                  if (agency.addressDetails.barangay.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.city ) {
                  if (agency.addressDetails.city.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.postal ) {
                  if (agency.addressDetails.postal.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.province ) {
                  if (agency.addressDetails.province.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
              }
              else if (agency.address && (agency.address.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase()))){
                if(!tempArr.includes(agency.id))tempArr.push(agency.id)
              }
            }else tempArr.push(agency.id)

            if(this.hasKeyword){
              // if(!this.loadedAgency.includes(agency)) this.loadedAgency.push(agency)
              if(!this.loadedAgencyMap.has(agency.id)) this.loadedAgencyMap.set(agency.id,agency)
            }
          }
        })
        return tempArr
      })
    }
    else if(JSONfields.agencyaddress != '' && JSONfields.agencyaddress != null){
      return new Promise((res,rej)=>{
        return this.afs.collection('Agency').snapshotChanges()
        .pipe(first())
        .subscribe(info=>{
          let tempArr:any = []
          info.map(data=>{
            if(data.payload.doc.exists){
              let agency:any = data.payload.doc.data()
              agency.id = data.payload.doc.id
              // tempArr.push(agency)
              if(agency.addressDetails){
                if(agency.addressDetails.addressLine) {
                  if (agency.addressDetails.addressLine.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.barangay) {
                  if (agency.addressDetails.barangay.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.city ) {
                  if (agency.addressDetails.city.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.postal ) {
                  if (agency.addressDetails.postal.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
                if(agency.addressDetails.province ) {
                  if (agency.addressDetails.province.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase())){
                    if(!tempArr.includes(agency.id))tempArr.push(agency.id)
                  }
                }
              }
              else if (agency.address && (agency.address.trim().toLowerCase().includes(JSONfields.agencyaddress.trim().toLowerCase()))){
                if(!tempArr.includes(agency.id))tempArr.push(agency.id)
              }
              if(this.hasKeyword){
                // if(!this.loadedAgency.includes(agency)) this.loadedAgency.push(agency)
                if(!this.loadedAgencyMap.has(agency.id)) this.loadedAgencyMap.set(agency.id,agency)
              }
            }
          })
          return res(tempArr)
        })
      })
      // .then((result:any)=>{
      //   let tempArr:any = []
      //   result.forEach((agencies:any)=>{

      //   })
      // })
    }
    else return []
  }

  getIDByValue(collection:any,find:any, value:any){
    return this.afs.collection(collection).ref.where(find,'==',value).get()
    .then(info=>{
      let tempArr:any = []
      info.forEach(data=>{
        if(data.exists){
          let item:any = data.data()
          item.id = data.id
          tempArr.push(item.id)
          
          if(collection == 'Sector') {if(!this.loadedSectorMap.has(item.id)) this.loadedSectorMap.set(item.id,item)}
          else if(collection == 'Division')  {if(!this.loadedDivisionMap.has(item.id)) this.loadedDivisionMap.set(item.id,item)}
          else if(collection == 'Stage of Business') {if(!this.loadedStageOfBusinessMap.has(item.id)) this.loadedStageOfBusinessMap.set(item.id,item)}
          else if(collection == 'Stage of Life') {if(!this.loadedStageofLifeMap.has(item.id)) this.loadedStageofLifeMap.set(item.id,item)}
          else if(collection == 'Life Event') {if(!this.loadedLifeEventMap.has(item.id)) this.loadedLifeEventMap.set(item.id,item)}
          else if(collection == 'Jurisdiction') {if(!this.loadedJurisdictionMap.has(item.id)) this.loadedJurisdictionMap.set(item.id,item)}
          else if(collection == 'Case use') {if(!this.loadedCaseUseMap.has(item.id)) this.loadedCaseUseMap.set(item.id,item)}
          else if(collection == 'Document') {if(!this.loadedDocumentMap.has(item.id)) this.loadedDocumentMap.set(item.id,item)}
          else if(collection == 'Agency') {if(!this.loadedAgencyMap.has(item.id)) this.loadedAgencyMap.set(item.id,item)}
          else if(collection == 'Instrument') {if(!this.loadedInstrumentMap.has(item.id)) this.loadedInstrumentMap.set(item.id,item)}
          // if(this.hasKeyword){
          // if(collection == 'Sector') {if(!this.loadedSector.map((element)=>element.id).includes(item.id)) {this.loadedSector.push(item)}}
          // else if(collection == 'Division') {if(!this.loadedDivision.map((element)=>element.id).includes(item.id)) {this.loadedDivision.push(item)}}
          // else if(collection == 'Stage of Business') {if(!this.loadedStageOfBusiness.map((element)=>element.id).includes(item.id)) {this.loadedStageOfBusiness.push(item)}}
          // else if(collection == 'Stage of Life') {if(!this.loadedStageofLife.map((element)=>element.id).includes(item.id)) {this.loadedStageofLife.push(item)}}
          // else if(collection == 'Life Event') {if(!this.loadedLifeEvent.map((element)=>element.id).includes(item.id)) {this.loadedLifeEvent.push(item)}}
          // else if(collection == 'Jurisdiction') {if(!this.loadedJurisdiction.map((element)=>element.id).includes(item.id)) {this.loadedJurisdiction.push(item)}}
          // else if(collection == 'Case use') {if(!this.loadedCaseUse.map((element)=>element.id).includes(item.id)) {this.loadedCaseUse.push(item)}}
          // else if(collection == 'Document') {if(!this.loadedDocument.map((element)=>element.id).includes(item.id)) {this.loadedDocument.push(item)}}
          // else if(collection == 'Agency') {if(!this.loadedAgency.map((element)=>element.id).includes(item.id)) {this.loadedAgency.push(item)}}
          // else if(collection == 'Instrument') {if(!this.loadedInstrument.map((element)=>element.id).includes(item.id)) {this.loadedInstrument.push(item)}}
          // }
        }
      })
      if(tempArr.length > 0){
        return tempArr[0]
      }
      else return null
    })
    .catch(error=>{
      console.log("error getting "+value+" from "+find, error)
      return null
    })
  }

  // && (this.searchform.value.keyword_sector == true || this.searchform.value.keyword_sob == true || this.searchform.value.keyword_juris == true
  //   || this.searchform.value.keyword_div == true || this.searchform.value.keyword_case == true || this.searchform.value.keyword_agency == true)
  search(status: any) {
    //
    this.searchform.patchValue({
      status: status
    })
    let JSONarray = this.searchform.value
    if(this.searchform.valid ){
      // console.log('form valid')
      this.router.navigate([`/pbris/regulations/${status}`], { queryParams: JSONarray });  // define your component where you want to go
    }else{
      console.warn('form invalid')
    }
  }

  advanced_search() {
    // let JSONarray = {
    //   keyword: this.searchform.value.keyword,
    // };
    // , { queryParams: JSONarray }
    this.router.navigate(['/pbris/advanced-search/']);  // define your component where you want to go
  }

  quick_search(){
    // let JSONarray = {
    //   keyword: this.searchform.value.keyword,
    // };
    // , { queryParams: JSONarray }
    this.router.navigate(['/pbris/quick-search/']);  // define your component where you want to go
  }

  universalOption(optionCode:any){
    if(optionCode == 'class'){
      return ['Business','Non-Business']
    }
    else if(optionCode == 'effectivity_status'){
      return ['In-effect','Repealed/Lapsed','Amended','Emergency']
    }
    return []
  }

  universalSelector(selectCode:any, value:any){
    if(selectCode == 'class'){
      if(value == this.searchform.value.classification) return true
    }
    else if(selectCode == 'effectivity_status'){
      if(value == this.searchform.value.effectivity_status) return true
    }
    return false
  }

  universalChange(changeCode:any, value:any){
    if(changeCode == 'class'){
      this.searchform.patchValue({
        classification: value
      })
    }
    else if(changeCode == 'effectivity_status'){
      this.searchform.patchValue({
        effectivity_status: value
      })
    }
  }
  
  async loadRegulations(inputJSON: any,loadAll:boolean) {
    this.regulations = []
    let batches:any = [];
    let collection:any = [];
    this.regulationsLoading = true;

    //bacth push pbris1
    if(inputJSON.status == 'existing' || loadAll){
      if(this.filterChecker(!loadAll,'phase1',inputJSON)){
        // console.log("finding pbris 1")
        batches.push(
          this.afs.collection(CollectionName.REGULATION_EXISTING_PHASE_1, filter => this.filterFunc('phase1',inputJSON,filter,!loadAll)).snapshotChanges()
        );
        collection.push(FromCollectionCode.PHASE1)
      }else console.log('invalid values for pbris 1')
    }
    
    //bacth push p2 existing
    if(inputJSON.status == 'existing' || loadAll ){
      // console.log('finding existing')
      if(inputJSON.agencyIds != '' && inputJSON.agencyIds != null){//Array.isArray(inputJSON.agencyIds)){//inputJSON.agencyIds != '' && inputJSON.agencyIds != null
        if(inputJSON.agencyIds.length > 0){
          if(this.filterChecker(!loadAll,'phase2',inputJSON)){
            inputJSON.agencyIds.forEach((agencyId:any)=>{
              batches.push(
                this.afs.collection(CollectionName.REGULATION_EXISTING_PHASE_2, filter => this.filterFunc('phase2',inputJSON,filter,!loadAll,agencyId)).snapshotChanges()
              );
              collection.push(FromCollectionCode.PHASE2_EXISTING)
            })
          } else console.log('invalid values for pbris 2 existing')
        }
      } else if(inputJSON.agencyIds == '' || inputJSON.agencyIds == null){
        if(this.filterChecker(!loadAll,'phase2',inputJSON)){
          batches.push(
            this.afs.collection(CollectionName.REGULATION_EXISTING_PHASE_2, filter => this.filterFunc('phase2',inputJSON,filter,!loadAll)).snapshotChanges()
          );
          collection.push(FromCollectionCode.PHASE2_EXISTING)
        } else console.log('invalid values for pbris 2 existing')
      }
    }

    // bacth push p2 proposed
    if(inputJSON.status == 'proposed' || loadAll ){
      // console.log('finding proposed')
      if(inputJSON.agencyIds != '' && inputJSON.agencyIds != null){
        if(inputJSON.agencyIds.length > 0){
          if(this.filterChecker(!loadAll,'phase2',inputJSON)){
            inputJSON.agencyIds.forEach((agencyId:any)=>{
              batches.push(
                this.afs.collection(CollectionName.REGULATION_PROPOSED_PHASE_2, filter => this.filterFunc('phase2',inputJSON,filter,!loadAll,agencyId)).snapshotChanges()
              );
              collection.push(FromCollectionCode.PHASE2_PROPOSED)
            })
          } else console.log('invalid values for pbris 2 proposed')
        }
      } else if(inputJSON.agencyIds == '' || inputJSON.agencyIds == null){
        if(this.filterChecker(!loadAll,'phase2',inputJSON)){
          batches.push(
            this.afs.collection(CollectionName.REGULATION_PROPOSED_PHASE_2, filter => this.filterFunc('phase2',inputJSON,filter,!loadAll)).snapshotChanges()
          );
          collection.push(FromCollectionCode.PHASE2_PROPOSED)
        } else console.log('invalid values for pbris 2 proposed')
      }
    }

    combineLatest(batches)
    .pipe(
      tap(
        async (results:any) => {
          let index = 0;
          this.regulations = []
          this.totalRegulationsNum = 0;
          this.currentRegulationNum = 0;
          this.currentRegulationInPercent = 0;
          for(let content of results){
            this.totalRegulationsNum += content.length;
          }

          Promise.all(
            results.map(async (content:any[],index:number) =>{
              let fromCollection = collection[index]
              return this.loadRegulations2(fromCollection, content, inputJSON, loadAll)
            })
          )
          .then((arr)=>{
            // console.log('arr: ',arr)
            this.regulations = []

            let result:any = []

            arr.map((data:any)=>{
              result = result.concat(data)
            })
            // console.log('result',result.length,result)
            this.regulations = result
            this.rerender() 
            this.regulationsLoading = false;
          })
        }
      ),
      take(1)
    )
    .subscribe();
  }

  loadRegulations2(fromCollection:any, content:any[], inputJSON:any, loadAll:boolean){
    // let tempRegulations:any = []
    // this.referenceArr.push([])
    // let referenceCurrentMap: Map<string,any> = 
    this.referenceArrMap.set(fromCollection, new Map())
    
    return Promise.all(
      content.map(async (result)=>{
        let item: any = result.payload.doc.data();
        item.id = result.payload.doc.id;
        
        // if load all
        // load all true
        // -- check fromCollection
        // -- if phase2existing
        // -- -- check has proposed_id
        // -- -- has proposed_id
        // -- -- -- getWorklist proposed data
        // -- -- -- check if document id in map
        // -- -- -- has document id in map
        // -- -- -- -- remove regulation with document id in map
        // -- -- -- -- proceed normally
        // -- -- -- no document id in map
        // -- -- -- -- proceed normally
        // -- -- no proposed_id
        // -- -- -- proceed normally
        // -- else if phase2proposed
        // -- -- check if posted
        // -- -- is posted
        // -- -- -- getWorklist proposed data id
        // -- -- -- check if worklist id in any of existing
        // -- -- -- has worklist id
        // -- -- -- -- skip
        // -- -- -- no worklist id
        // -- -- -- -- proceed normally
        // -- -- not posted
        // -- -- -- proceed normally
        // -- else
        // -- -- proceed normally
        // load all false
        // -- proceed normally

        if(loadAll == true){
          switch(fromCollection){
            case FromCollectionCode.PHASE2_EXISTING:{
              if(item.proposed_id){
                if(this.referenceCollections.has(CollectionName.WORKLIST_PROPOSED_REGULATIION) && (this.referenceCollections.get(CollectionName.WORKLIST_PROPOSED_REGULATIION) as Map<string,any>).has(item.proposed_id)){
                // if(this.referenceCollections.has(item.proposed_id)){
                  const worklistData = (this.referenceCollections.get(CollectionName.WORKLIST_PROPOSED_REGULATIION) as Map<string,any>).get(item.proposed_id)
                  return this.loadRegulations2Sub1(fromCollection, item, inputJSON, worklistData, loadAll)
                }else{
                  const worklistData = await this.loadDataFromCollectionByID(CollectionName.WORKLIST_PROPOSED_REGULATIION,item.proposed_id)
                  if(worklistData) return this.loadRegulations2Sub1(fromCollection, item, inputJSON, worklistData, loadAll)
                  else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
                }
              }
              else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
              // break;
            }
            case FromCollectionCode.PHASE2_PROPOSED:{
              if(item.is_posted && item.is_posted == true){
                if(this.loadedWorklistProposedMap.has(CollectionName.WORKLIST_PROPOSED_REGULATIION)){
                  const worklistData:any = (Array.from((this.loadedWorklistProposedMap.get(CollectionName.WORKLIST_PROPOSED_REGULATIION) as Map<string,any>).values())).filter((worklistData:any)=>worklistData.documentId == item.id)
                  return this.loadRegulations2Sub2(fromCollection, item, inputJSON, worklistData, loadAll)
                } 
                else{
                  const worklistDataArr = await this.loadDataFromCollectionByWhere(CollectionName.WORKLIST_PROPOSED_REGULATIION,'documentId','==',item.id)
                  if(worklistDataArr && Array.isArray(worklistDataArr) && worklistDataArr.length > 0){
                    return Promise.all(
                      worklistDataArr.map((worklistData)=>{
                        return this.loadRegulations2Sub2(fromCollection, item, inputJSON, worklistData, loadAll)
                      })
                    )
                  } else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll)
                }
              }
              else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
              // break;
            }
            default: return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
          }
        }
        else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
      })
    ).then((items)=>{
      const returnValue:any = [];
      // console.log('referenceArrMap | '+fromCollection+': ',this.referenceArrMap.get(fromCollection));
      (this.referenceArrMap.get(fromCollection) as Map<string,any>).forEach((regulation)=>{
        returnValue.push(regulation)
      })
      return returnValue
    })
  }

  loadRegulations2Sub1(fromCollection:any, item:any, inputJSON:any, worklistData:any, loadAll:boolean){
    if(this.referenceArrMap.has(FromCollectionCode.PHASE2_PROPOSED)){
      const findProposedMap:any = Array.from((this.referenceArrMap.get(FromCollectionCode.PHASE2_PROPOSED) as Map<string,any>).values()).find((regulation:any)=>regulation.id == worklistData.documentId)//.filter((regulation:any)=>regulation.id == worklistId.documentId)
      if(findProposedMap){
        (this.referenceArrMap.get(FromCollectionCode.PHASE2_PROPOSED) as Map<string,any>).delete(worklistData.documentId)
        return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
      } else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
    } else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
  }

  async loadRegulations2Sub2(fromCollection:any, item:any, inputJSON:any, worklistData:any, loadAll:boolean){
    if(this.referenceArrMap.has(FromCollectionCode.PHASE2_EXISTING)){
      const filteredExistingMap = Array.from((this.referenceArrMap.get(FromCollectionCode.PHASE2_EXISTING) as Map<string,any>).values()).filter((regulation:any)=>regulation.proposed_id == worklistData)
      if(filteredExistingMap.length == 0) return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
      else return true
      // else skip
    } else return this.loadRegulations3(fromCollection, item, inputJSON, loadAll);
  }

  async loadRegulations3(fromCollection:any, item:any, inputJSON:any, loadAll:boolean){
    if(inputJSON.keyword != '' && inputJSON.keyword != null ){
      let bool:Boolean = await this.keywordFunc(item,inputJSON,fromCollection)
      if(bool) {
        const regulation = await this.loadRegulations5(fromCollection,item,inputJSON)
        if(regulation != false && regulation != null) return this.loadRegulations4(fromCollection,regulation,loadAll)
        else{ 
          this.computeLoadingPercentage()
          return true
        }
      } else{
        this.computeLoadingPercentage()
        return true
      }
    }
    else {
      const regulation = await this.loadRegulations5(fromCollection,item,inputJSON)
      if(regulation != false && regulation != null) return this.loadRegulations4(fromCollection,regulation,loadAll)
      else{
        this.computeLoadingPercentage()
        return true
      }
    }
  }

  async loadRegulations4(fromCollection:any, item:any, loadAll:boolean){
    if(loadAll == true){
      switch(fromCollection){
        case FromCollectionCode.PHASE2_EXISTING:{
          if(item.proposed_id){
            // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('bool: ',item.id+' | '+item.proposed_id,fromCollection)
            if(this.referenceCollections.has(CollectionName.WORKLIST_PROPOSED_REGULATIION) && (this.referenceCollections.get(CollectionName.WORKLIST_PROPOSED_REGULATIION) as Map<string,any>).has(item.proposed_id)){
              // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('1')
              const worklistData1 = (this.referenceCollections.get(CollectionName.WORKLIST_PROPOSED_REGULATIION) as Map<string,any>).get(item.proposed_id)
              this.loadRegulations4Sub1(item, worklistData1)
              let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
              if(!referenceCurrentMap.has(item.id)){
                // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('adding existing '+item.id+' to reference')
                referenceCurrentMap.set(item.id,item)
                this.referenceArrMap.set(fromCollection, referenceCurrentMap)
              }
              this.computeLoadingPercentage()
              return true
            }else{
              // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('2')
              const worklistData1 = await this.loadDataFromCollectionByID(CollectionName.WORKLIST_PROPOSED_REGULATIION,item.proposed_id)
              if(worklistData1){
                // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('3')
                this.loadRegulations4Sub1(item, worklistData1)
                let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
                if(!referenceCurrentMap.has(item.id)){
                  // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('adding existing '+item.id+' to reference')
                  referenceCurrentMap.set(item.id,item)
                  this.referenceArrMap.set(fromCollection, referenceCurrentMap)
                }
                this.computeLoadingPercentage()
                return true
              }
              else{
                // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('4')
                let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
                if(!referenceCurrentMap.has(item.id)){
                  // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('adding existing '+item.id+' to reference')
                  referenceCurrentMap.set(item.id,item)
                  this.referenceArrMap.set(fromCollection, referenceCurrentMap)
                }
                this.computeLoadingPercentage()
                return true
              }
            }
          } else{
            let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
            if(!referenceCurrentMap.has(item.id)){
              // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('adding existing '+item.id+' to reference')
              referenceCurrentMap.set(item.id,item)
              this.referenceArrMap.set(fromCollection, referenceCurrentMap)
            }
            this.computeLoadingPercentage()
            return true
          }
        }
        case FromCollectionCode.PHASE2_PROPOSED:{
          if(item.is_posted && item.is_posted == true){
            // if(item.id == 'RYgTyV2EL5SPDyvydvzq') console.log('bool: ',item.id,fromCollection)
            if(this.loadedWorklistProposedMap.has(CollectionName.WORKLIST_PROPOSED_REGULATIION)){
              const worklistData:any = (Array.from((this.loadedWorklistProposedMap.get(CollectionName.WORKLIST_PROPOSED_REGULATIION) as Map<string,any>).values())).filter((worklistData:any)=>worklistData.documentId == item.id)
              return this.loadRegulations4Sub2(fromCollection, item, worklistData, item)
            } 
            else{
              const worklistDataArr = await this.loadDataFromCollectionByWhere(CollectionName.WORKLIST_PROPOSED_REGULATIION,'documentId','==',item.id)
              if(worklistDataArr && Array.isArray(worklistDataArr) && worklistDataArr.length > 0){
                worklistDataArr.map((worklistData)=>{
                  this.loadRegulations4Sub2(fromCollection, item, worklistData, item)
                })
                this.computeLoadingPercentage()
                return true
              }
              else{
                let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
                if(!referenceCurrentMap.has(item.id)){
                  // if(item.id == 'RYgTyV2EL5SPDyvydvzq') console.log('adding proposed '+item.id+' to reference')
                  // console.log('adding proposed '+item.id+' to reference '+fromCollection)
                  referenceCurrentMap.set(item.id,item)
                  this.referenceArrMap.set(fromCollection, referenceCurrentMap)
                }
                this.computeLoadingPercentage()
                return true
              }
            }
          }
          else{
            let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
            if(!referenceCurrentMap.has(item.id)){
              // console.log('adding proposed '+item.id+' to reference')
              referenceCurrentMap.set(item.id,item)
              this.referenceArrMap.set(fromCollection, referenceCurrentMap)
            }
            this.computeLoadingPercentage()
            return true
          }
        }
        default:{
          let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
          if(!referenceCurrentMap.has(item.id)){
            referenceCurrentMap.set(item.id,item)
            this.referenceArrMap.set(fromCollection, referenceCurrentMap)
          }
          this.computeLoadingPercentage()
          return true
        }
      }
    }
    else{
      let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
      if(!referenceCurrentMap.has(item.id)){
        referenceCurrentMap.set(item.id,item)
        this.referenceArrMap.set(fromCollection, referenceCurrentMap)
      }
      this.computeLoadingPercentage()
      return true
    }
  }

  loadRegulations4Sub1(item:any, worklistData:any){
    // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log('loadRegulations3Sub1 ',item.id+' | '+worklistData.documentId)
    if(this.referenceArrMap.has(FromCollectionCode.PHASE2_PROPOSED)){
      // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log(item.id+' | has referenceArrMap proposed')
      const findProposedMap:any = Array.from((this.referenceArrMap.get(FromCollectionCode.PHASE2_PROPOSED) as Map<string,any>).values()).find((regulation:any)=>regulation.id == worklistData.documentId)//.filter((regulation:any)=>regulation.id == worklistId.documentId)
      // if(item.id == 'nvvMP5oEwmkU5bH1XyHj') console.log(item.id+' | '+findProposedMap)
      if(findProposedMap) (this.referenceArrMap.get(FromCollectionCode.PHASE2_PROPOSED) as Map<string,any>).delete(worklistData.documentId);
    }
    return true
  }

  loadRegulations4Sub2(fromCollection:any, item:any, worklistData:any, regulation:any){
    // if(item.id == 'RYgTyV2EL5SPDyvydvzq') console.log('loadRegulations3Sub2( '+FromCollectionCode.PHASE2_EXISTING+' ): ',item.id+' | '+worklistData.id+': ',this.referenceArrMap.get(FromCollectionCode.PHASE2_EXISTING))
    if(this.referenceArrMap.has(FromCollectionCode.PHASE2_EXISTING)){
      // if(item.id == 'RYgTyV2EL5SPDyvydvzq')console.log(item.id+' | '+worklistData.id+' | ',Array.from((this.referenceArrMap.get(FromCollectionCode.PHASE2_EXISTING) as Map<string,any>).values()))
      const filteredExistingMap = Array.from((this.referenceArrMap.get(FromCollectionCode.PHASE2_EXISTING) as Map<string,any>).values()).filter((regulation:any)=>regulation.proposed_id == worklistData.id);
      // if(item.id == 'RYgTyV2EL5SPDyvydvzq')console.log(item.id+' | has referenceArrMap existing: ',filteredExistingMap.length)
      if(filteredExistingMap.length == 0){
        let referenceCurrentMap = (this.referenceArrMap.get(fromCollection) as Map<string,any>)
        if(!referenceCurrentMap.has(regulation.id)){
          // console.log('adding proposed '+item.id+' to reference '+fromCollection)
          referenceCurrentMap.set(regulation.id,regulation)
          this.referenceArrMap.set(fromCollection, referenceCurrentMap)
        }
      }
      // else skip
    }
    else{
      let referenceCurrentMap: Map<string,any> = this.referenceArrMap.get(fromCollection)
      if(!referenceCurrentMap.has(regulation.id)){
        // console.log('adding proposed '+item.id+' to reference '+fromCollection)
        referenceCurrentMap.set(regulation.id,regulation)
        this.referenceArrMap.set(fromCollection, referenceCurrentMap)
      }
    }
    this.computeLoadingPercentage()
    return true
  }

  filterChecker(doSearch:any, filterFor:any, inputJSON:any){
    //browser filter checker to check if particular collection has these fields or values
    //remove from here and add to their corresponding collection filter
    if(doSearch){
      if(filterFor == 'phase1'){
        if(inputJSON.classification != '' && inputJSON.classification != null) return false
        if(inputJSON.stageoflife != '' && inputJSON.stageoflife != null ) return false
        if(inputJSON.lifeevent != '' && inputJSON.lifeevent != null ) return false
        if(inputJSON.division != '' && inputJSON.division != null ) return false
        if(inputJSON.stageofbusiness != '' && inputJSON.stageofbusiness != null ) return false
        if(inputJSON.caseuse != '' && inputJSON.caseuse != null ) return false
        if(inputJSON.jurisdiction != '' && inputJSON.jurisdiction != null ) return false
        if(inputJSON.instrument != '' && inputJSON.instrument != null ) return false
        if(inputJSON.document != '' && inputJSON.document != null ) return false

        if(inputJSON.publication == 'Exactly on'){
          if(inputJSON.publicationexact != '' && inputJSON.publicationexact != null){
            let splitDate = inputJSON.publicationexact.split('-')
            if(splitDate.length > 0){
              // let year = splitDate[0]
              // let month = splitDate[1]
              // let day = splitDate[2]
              // let startDate = new Date(year,month-1,day,0,0,0,0)
              // let endDate = new Date(year,month-1,day,23,59,59,999)
            }else return false
          }else return false
        }
        else if(inputJSON.publication == 'In Year'){
          if(inputJSON.publicationyear != '' && inputJSON.publicationyear != null){
            let splitDate = inputJSON.publicationyear.split('-')
            if(splitDate.length > 0){
              // let year = splitDate[0]
              // let startDate = new Date(year,0,1,0,0,0,0)
              // let endDate = new Date(year,11,31,23,59,59,999)
              // console.log(startDate,endDate)
            }else return false
          }else return false
        }
        else if(inputJSON.publication == 'Range'){
          if(inputJSON.publicationstartdate != '' && inputJSON.publicationstartdate != null && inputJSON.publicationenddate != '' && inputJSON.publicationenddate != null){
            if (inputJSON.publicationstartdate <= inputJSON.publicationenddate) {
              let splitStartDate = inputJSON.publicationstartdate.split('-')
              let splitEndDate = inputJSON.publicationenddate.split('-')
              if(splitStartDate.length > 0 && splitEndDate.length > 0){
                // let startYear = splitStartDate[0]
                // let startMonth = splitStartDate[1]
                // let startDay = splitStartDate[2]
    
                // let endYear = splitEndDate[0]
                // let endMonth = splitEndDate[1]
                // let endDay = splitEndDate[2]
    
                // let startDate = new Date(startYear,startMonth-1,startDay,0,0,0,0)
                // let endDate = new Date(endYear,endMonth-1,endDay,23,59,59,999)
                // console.log(startDate,endDate)
              }else return false
            }
            else if(inputJSON.publicationstartdate >= inputJSON.publicationenddate) {
              let splitStartDate = inputJSON.publicationenddate.split('-')
              let splitEndDate = inputJSON.publicationstartdate.split('-')
              if(splitStartDate.length > 0 && splitEndDate.length > 0){
                // let startYear = splitStartDate[0]
                // let startMonth = splitStartDate[1]
                // let startDay = splitStartDate[2]
    
                // let endYear = splitEndDate[0]
                // let endMonth = splitEndDate[1]
                // let endDay = splitEndDate[2]
    
                // let startDate = new Date(startYear,startMonth-1,startDay,0,0,0,0)
                // let endDate = new Date(endYear,endMonth-1,endDay,23,59,59,999)
                // console.log(startDate,endDate)
                // return true
              } else return false
            } else return false
          }else return false
        }

        if(inputJSON.effectivity == 'Exactly on') return false
        else if(inputJSON.effectivity == 'In Year') return false
        else if(inputJSON.effectivity == 'Range') return false
        
        if(inputJSON.amend == 'Exactly on') return false
        else if(inputJSON.amend == 'In Year') return false
        else if(inputJSON.amend == 'Range') return false

        if(inputJSON.effectivity_status == "Repealed/Lapsed" ) return false
        else if(inputJSON.effectivity_status == "Amended" ) return false
        else if(inputJSON.effectivity_status == "Emergency" ) return false
        
        if(inputJSON.significance  != '' && inputJSON.significance != null) return false
        return true
      }
      else if(filterFor == 'phase2'){
        if(inputJSON.status == 'existing'){

          if(inputJSON.document != '' && inputJSON.document != null ) return false

          if(inputJSON.publication == 'Exactly on'){
            if(inputJSON.publicationexact == '' || inputJSON.publicationexact == null || inputJSON.publicationexact == undefined) return false
          }
          else if(inputJSON.publication == 'In Year'){
            if(inputJSON.publicationyear == '' || inputJSON.publicationyear == null || inputJSON.publicationyear == undefined) return false
          }
          else if(inputJSON.publication == 'Range'){
            if(inputJSON.publicationstartdate == '' || inputJSON.publicationstartdate == null || inputJSON.publicationstartdate == undefined) return false
            if(inputJSON.publicationenddate == '' || inputJSON.publicationenddate == null || inputJSON.publicationenddate == undefined) return false
          }

          if(inputJSON.effectivity == 'Exactly on'){
            if(inputJSON.effectivityexact == '' || inputJSON.effectivityexact == null || inputJSON.effectivityexact == undefined) return false
          }
          else if(inputJSON.effectivity == 'In Year'){
            if(inputJSON.effectivityyear == '' || inputJSON.effectivityyear == null || inputJSON.effectivityyear == undefined) return false
          }
          else if(inputJSON.effectivity == 'Range'){
            if(inputJSON.effectivitystartdate == '' || inputJSON.effectivitystartdate == null || inputJSON.effectivitystartdate == undefined) return false
            if(inputJSON.effectivityenddate == '' || inputJSON.effectivityenddate == null || inputJSON.effectivityenddate == undefined) return false
          }

          if(inputJSON.amend == 'Exactly on'){
            if(inputJSON.amendexact == '' || inputJSON.amendexact == null || inputJSON.amendexact == undefined) return false
          }
          else if(inputJSON.amend == 'In Year'){
            if(inputJSON.amendyear == '' || inputJSON.amendyear == null || inputJSON.amendyear == undefined) return false
          }
          else if(inputJSON.amend == 'Range'){
            if(inputJSON.amendstartdate == '' || inputJSON.amendstartdate == null || inputJSON.amendstartdate == undefined) return false
            if(inputJSON.amendenddate == '' || inputJSON.amendenddate == null || inputJSON.amendenddate == undefined) return false
          }

          if(inputJSON.effectivity_status == "Repealed/Lapsed" ) return false
          else if(inputJSON.effectivity_status == "Amended" ) return false
          else if(inputJSON.effectivity_status == "Emergency" ) return false

          if(inputJSON.agencyIds != '' && inputJSON.agencyIds != null && inputJSON.agencyIds.length == 0) return false

          return true
        }else if(inputJSON.status == 'proposed'){
          if(inputJSON.document != '' && inputJSON.document != null ) return false

          if(inputJSON.publication == 'Exactly on') return false
          else if(inputJSON.publication == 'In Year') return false
          else if(inputJSON.publication == 'Range') return false
          
          if(inputJSON.effectivity == 'Exactly on') return false
          else if(inputJSON.effectivity == 'In Year') return false
          else if(inputJSON.effectivity == 'Range') return false
          
          if(inputJSON.amend == 'Exactly on') return false
          else if(inputJSON.amend == 'In Year') return false
          else if(inputJSON.amend == 'Range') return false

          if(inputJSON.effectivity_status == "In-effect" )return false
          else if(inputJSON.effectivity_status == "Repealed/Lapsed" ) return false
          else if(inputJSON.effectivity_status == "Amended" ) return false
          else if(inputJSON.effectivity_status == "Emergency" ) return false

          if(inputJSON.agencyIds != '' && inputJSON.agencyIds != null && inputJSON.agencyIds.length == 0) return false

          return true
        }
      }
      return false
    }else return true
    // return false
  }

  computeLoadingPercentage(){
    this.currentRegulationNum++
    this.currentRegulationInPercent = parseInt(((this.currentRegulationNum / this.totalRegulationsNum)*100).toFixed(2))
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance) => {
      dtInstance.clear().draw();
      dtInstance.destroy();
      this.chRef.detectChanges()
      this.dtTrigger.next(this.dtOptions);
    });

    // if(this.dtInstanceTriggered == true){
    //   console.log('rerender true')
    //   this.dtElement.dtInstance.then((dtInstance) => {
    //     dtInstance.destroy();
    //     this.dtTrigger.next(this.dtOptions);
    //   });
    // }else if(this.dtInstanceTriggered == false){
    //   console.log('rerender false')
    //   this.dtTrigger.next(this.dtOptions);
    //   this.dtInstanceTriggered = true
    // }
  }

  // loadRegulations2
  async loadRegulations5(fromCollection:any, item:any, inputJSON:any){
    if (this.user_id && item.PRN) {
      // Pinned status
      this.loadPinnedRegulation(this.user_id, item.PRN).subscribe(data => {
        data.docs.map(res => {
          let info: any = res.data()
          item.status = info.status
          item.reg_id = res.id
          item.hasExist = true
        })
      });
    }
    else {
      item.status = false;
      item.hasExist = false;
    }

    if (item.status == undefined) {
      item.hasExist = false
      item.status = false
    }

    // let titleKey = (fromCollection == 'phase1') ? "short_title" : "subject";
    item.regulations_from = (fromCollection == FromCollectionCode.PHASE1) ? "regulations" : ((fromCollection == FromCollectionCode.PHASE2_EXISTING) ? "existing" : ( (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? "proposed" : null)); //to tell if from where
    if(fromCollection != FromCollectionCode.PHASE1) inputJSON.subject = inputJSON.short_title;
  
    item.displayDate = item.doc_date ? (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date) : ""
    item.effectiveDate = item.date_effective ? (item.date_effective instanceof Timestamp ? format(item.date_effective.toDate(), 'MM/dd/yyyy') : item.date_effective) : ""
    item.amendDate = item.date_repeal ? (item.date_repeal instanceof Timestamp ? format(item.date_repeal.toDate(), 'MM/dd/yyyy') : item.date_repeal) : ""
    if(fromCollection != FromCollectionCode.PHASE1) {
      item.short_title = item.subject;
      let sectorDocID = (fromCollection == FromCollectionCode.PHASE2_EXISTING) ? item.reg_sector : (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? item.tags && item.tags.reg_sector ? item.tags.reg_sector : item.reg_sector : '';
      // if(sectorDocID != '' && sectorDocID != null) item.sector = await this.getSector(sectorDocID);
      if(sectorDocID != '' && sectorDocID != null){
        if(this.loadedSectorMap.has(sectorDocID)){
          const sectorData = this.loadedSectorMap.get(sectorDocID)
          item.sector = sectorData.name;
        } else {
          const sector = await this.loadDataFromCollectionByID(CollectionName.SECTOR,sectorDocID);
          if(sector){
            item.sector = sector.name
          } else item.sector = ''
        }
      }

      let divDocId = (fromCollection == FromCollectionCode.PHASE2_EXISTING) ? item.reg_division : (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? item.tags && item.tags.reg_division ? item.tags.reg_division : item.reg_division : '';
      if(divDocId != '' && divDocId != null){
        if(this.loadedSectorMap.has(divDocId)){
          const data = this.loadedSectorMap.get(divDocId)
          item.division = data.name;
        } else {
          const data = await this.loadDataFromCollectionByID(CollectionName.DIVISION,divDocId);
          if(data){
            item.division = data.name
          } else item.division = ''
        }
      }

      let sobDocId = (fromCollection == FromCollectionCode.PHASE2_EXISTING) ? item.reg_business : (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? item.tags && item.tags.reg_business ? item.tags.reg_business : item.reg_business : '';
      if(sobDocId != '' && sobDocId != null){
        if(this.loadedStageOfBusinessMap.has(sobDocId)){
          const data = this.loadedStageOfBusinessMap.get(sobDocId)
          item.sob = data.name;
        } else {
          const data = await this.loadDataFromCollectionByID(CollectionName.STAGE_OF_BUSINESS,sobDocId);
          if(data){
            item.sob = data.name
          } else item.sob = ''
        }
      }

      let caseDocId = (fromCollection == FromCollectionCode.PHASE2_EXISTING) ? item.reg_case : (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? item.tags && item.tags.reg_case ? item.tags.reg_case : item.reg_case : '';
      if(caseDocId != '' && caseDocId != null){
        if(this.loadedCaseUseMap.has(caseDocId)){
          const data = this.loadedCaseUseMap.get(caseDocId)
          item.case = data.name;
        } else {
          const data = await this.loadDataFromCollectionByID(CollectionName.CASE_USE,caseDocId);
          if(data){
            item.case = data.name
          } else item.case = ''
        }
      }

      let solDocId = (fromCollection == FromCollectionCode.PHASE2_EXISTING) ? item.reg_stageoflife : (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? item.tags && item.tags.reg_stageoflife ? item.tags.reg_stageoflife : item.reg_stageoflife : '';
      if(solDocId != '' && solDocId != null){
        if(this.loadedStageofLifeMap.has(solDocId)){
          const data = this.loadedStageofLifeMap.get(solDocId)
          item.sol = data.stage;
        } else {
          const data = await this.loadDataFromCollectionByID(CollectionName.STAGE_OF_LIFE,solDocId);
          if(data){
            item.sol = data.stage
          } else item.sol = ''
        }
      }

      let leDocId = (fromCollection == FromCollectionCode.PHASE2_EXISTING) ? item.reg_lifeevent : (fromCollection == FromCollectionCode.PHASE2_PROPOSED) ? item.tags && item.tags.reg_lifeevent ? item.tags.reg_lifeevent : item.reg_lifeevent : '';
      if(leDocId != '' && leDocId != null){
        if(this.loadedLifeEventMap.has(leDocId)){
          const data = this.loadedLifeEventMap.get(leDocId)
          item.le = data.event;
        } else {
          const data = await this.loadDataFromCollectionByID(CollectionName.LIFE_EVENT,leDocId);
          if(data){
            item.le = data.event
          } else item.le = ''
        }
      }

      // this.regulations will not work
      // if(!this.referenceArr[index].map((element:any)=>element.id).includes(item.id)) return item //this.regulations.push(item);
      if(!this.referenceArrMap.get(fromCollection).has(item.id)) return item
      else return false
    }
    else if(!this.referenceArrMap.get(fromCollection).has(item.id)) return item
    else return false
  }

  /*
  async loadRegulations3(fromCollection:any, item:any, inputJSON:any){
    item.displayDate = item.doc_date ? (item.doc_date instanceof Timestamp ? format(item.doc_date.toDate(), 'MM/dd/yyyy') : item.doc_date) : ""
    item.effectiveDate = item.date_effective ? (item.date_effective instanceof Timestamp ? format(item.date_effective.toDate(), 'MM/dd/yyyy') : item.date_effective) : ""
    item.amendDate = item.date_repeal ? (item.date_repeal instanceof Timestamp ? format(item.date_repeal.toDate(), 'MM/dd/yyyy') : item.date_repeal) : ""
    // Handling for Phase 02 fields
    // if(index != 0) {
    if(fromCollection != 'phase1') {
      item.short_title = item.subject;
      // reg_sector for Existing, tags for Proposed
      if(item.reg_sector){
        // let sectorDocID = index == 1 ? item.reg_sector : item.tags.reg_sector;
        let sectorDocID = (fromCollection == 'phase2existing') ? item.reg_sector : item.tags.reg_sector;
        item.sector = await this.getSector(sectorDocID);
      }
      if(!this.regulations.includes(item)) this.regulations.push(item);
    }
    else {
      if(!this.regulations.includes(item)) this.regulations.push(item);
    }
  }
  */

  // filterFunc(phase:any,inputJSON:any, filter:any,doSearch:any, additionalCondition?:any,agencyId?:any){
  filterFunc(phase:any,inputJSON:any, filter:any,doSearch:any, agencyId?:any){
    console.log('phase: ',phase)
    console.log('doSearch: ',doSearch)
    let query:Query = filter
    if(phase=='phase1' && doSearch){
      // search filters for pbris 1
      query = this.pbris1Filters(inputJSON,query)
    }
    else if(phase=='phase2'){
      console.log('filterFunc allowNonPosted: ',this.allowNonPosted)
      if(!this.allowNonPosted){ 
        console.log('only is posted')
        query = query.where('is_posted', '==', true)
      }
      else {
        console.log('posted or not')
        // query = query.orderBy('is_posted','asc')
        // query = query.orderBy('short_title')
      }

      if(doSearch){
        if(inputJSON.status == 'existing'){
            //search filters for pbris2 exisitng 
          // query = this.pbris2ExistingFilters(inputJSON,query,additionalCondition,agencyId)
          query = this.pbris2ExistingFilters(inputJSON,query,agencyId)
        }
        else if(inputJSON.status == 'proposed'){
          //search fitlers for pbris2 proposed
          query = this.pbris2ProposedFilters(inputJSON,query,agencyId)
        }
      }
    }

    // for more delibiration
    // const documentStart = (this.page*this.documentsPerPage)-this.documentsPerPage
    // const documentEnd = (this.page*this.documentsPerPage)-1
    // console.log(documentStart+' - '+documentEnd)
    // query.startAt(documentStart).endAt(documentEnd).startAt(documentStart).endAt(documentEnd)
    return query
  }

  async keywordFunc(item:any,inputJSON:any,fromCollection:any){
    let keyword = inputJSON.keyword.toString().trim().toLowerCase()
    // console.log('item: ',item.id, item)
    const itemCleaned:any = await this.cleanItem(fromCollection,item,keyword)
    // console.log('itemcleaned: ',item.id, itemCleaned)
    if(itemCleaned == true) return true
    // if(typeof item === 'object') {
    //   try{
    //     const bool:boolean = await this.anArray(Object.values(itemCleaned),keyword)
    //     // console.log('/id',item.id)
    //     return bool
    //   }catch(error){
    //     console.error('Error: keywordFunc',error)
    //     return false
    //   }
    // }
    else return false
  }

  anArray(arr:any,keyword:any){
    let value = false;
    for(let ctr = 0; ctr < arr.length && value == false;ctr++){
      // console.log('arr['+ctr+']',arr[ctr])
      // console.log('is array: '+Array.isArray(arr[ctr]))
      if(Array.isArray(arr[ctr])){
        let bool:any =  this.anArray(arr[ctr],keyword)
        if(bool == true){
          // console.log('returning keyword found', true)
          value = true
          return value
        }
      }else{
        // let value: any = Object.values(values)
        // console.log('is object: ',typeof arr[ctr] === 'object')
        if(typeof arr[ctr] === 'object' && arr[ctr] !== null){
          let anObject: any = Object.values(arr[ctr])
          let bool:any =  this.anArray(anObject,keyword)
          if(bool == true){
            // console.log('returning keyword found', true)
            value = true
            return value
          }
        }
        else{
          // console.log('check keyword')
          if(arr[ctr] != null && arr[ctr] != undefined
            && (arr[ctr].toString().trim().toLowerCase().includes(keyword)
            || arr[ctr].toString().trim().toLowerCase() == keyword)){
            // console.log('keyword found', true)
            // console.log("value: ",values)
            value = true;
            return true
          }
        }
      }
    }
    return value
  }

  async cleanItem(fromCollection:any,item:any,keyword:string){
    // console.log('cleaning', id)
    // recreates items, not just to make reference of the same object
    // let itemToClean:any = item;
    // let itemToClean:any = angular.copy(item);
    let currentId = item.id
    let itemToClean:any = JSON.parse(JSON.stringify(item));
    if(fromCollection == 'phase1'){
      if(this.searchform.value.keyword_sob == true || this.searchform.value.keyword_agency == true || this.searchform.value.keyword_case == true
        || this.searchform.value.keyword_sector == true || this.searchform.value.keyword_juris == true || this.searchform.value.keyword_div == true){
          let newItem:any = {}
          // sector
          if(this.searchform.value.keyword_sector == true && itemToClean.sector != '' && itemToClean.sector != null){
            newItem.sector = itemToClean.sector
            if(this.checkobjectForKeyWordSearch(itemToClean.sector,keyword) == true) return true
          }
          if(this.checkobjectForKeyWordSearch(itemToClean,keyword) == true) return true
          return await newItem
      }
      else{
        itemToClean.file_file_name = ""
        itemToClean.firebase_folder = ""
        itemToClean.folder_name_id = ""
        itemToClean.id = ""
        if(this.checkobjectForKeyWordSearch(itemToClean,keyword) == true) return true
      }
    }
    else if(fromCollection == 'phase2existing'){
      if(this.searchform.value.keyword_sob == true || this.searchform.value.keyword_agency == true || this.searchform.value.keyword_case == true
        || this.searchform.value.keyword_sector == true || this.searchform.value.keyword_juris == true || this.searchform.value.keyword_div == true){
        let newItem:any = {}
        // sob
        if(this.searchform.value.keyword_sob == true && itemToClean.reg_business != '' && itemToClean.reg_business != null && itemToClean.reg_business != undefined){
          const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,itemToClean.reg_business,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_business = item
            else newItem.reg_business = ''
          }
        } else newItem.reg_business = ''

        // case
        if(this.searchform.value.keyword_case == true && itemToClean.reg_case != '' && itemToClean.reg_case != null && itemToClean.reg_case != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.CASE_USE,itemToClean.reg_case,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_case = item
            else newItem.reg_case = ''
          }
        } else newItem.reg_case = ''

        // sector
        if(this.searchform.value.keyword_sector == true && itemToClean.reg_sector != '' && itemToClean.reg_sector != null && itemToClean.reg_sector != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.SECTOR,itemToClean.reg_sector,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_case = item
            else newItem.reg_case = ''
          }
        } else newItem.reg_sector = ''

        // jusris
        if(this.searchform.value.keyword_juris == true && newItem.reg_juris == true && itemToClean.reg_juris != '' && itemToClean.reg_juris != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,itemToClean.reg_juris,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_juris = item
            else newItem.reg_juris = ''
          }
        } else newItem.reg_juris = ''

        // division
        if(this.searchform.value.keyword_div == true && itemToClean.reg_division != '' && itemToClean.reg_division != null && itemToClean.reg_division != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.DIVISION,itemToClean.reg_division,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_division = item
            else newItem.reg_division = ''
          }
        } else newItem.reg_division = ''

        // agency
        if(this.searchform.value.keyword_agency == true && itemToClean.reg_agency  && (itemToClean.reg_agency != undefined  && itemToClean.reg_agency != null)){
          if(Array.isArray(itemToClean.reg_agency)){
            newItem.reg_agency = []
            if(itemToClean.reg_agency.length > 0){
              for(let ctr in itemToClean.reg_agency){
                if(itemToClean.reg_agency[ctr] != '' && itemToClean.reg_agency[ctr] != null && itemToClean.reg_agency[ctr] != undefined){
                  let agency:any;
                  if(this.loadedAgencyMap.has(itemToClean.reg_agency[ctr])) agency = this.loadedAgencyMap.get(itemToClean.reg_agency[ctr])
                  else{
                    const reg_agency = await this.cleanItem2LoadData(CollectionName.AGENCY,itemToClean.reg_agency[ctr],keyword)
                    if(reg_agency instanceof Boolean && reg_agency == true) return true
                    agency = reg_agency
                  }
                  if(this.checkobjectForKeyWordSearch(agency,keyword) == true) return true
                  newItem.reg_agency.push(agency)
                }
                else newItem.reg_agency.push('')
              }
            }
          }
          else if(!Array.isArray(itemToClean.reg_agency)){
            if(itemToClean.reg_agency != '' && itemToClean.reg_agency != null && itemToClean.reg_agency != undefined){
              if(this.loadedAgencyMap.has(itemToClean.reg_agency)) newItem.reg_agency  = this.loadedAgencyMap.get(itemToClean.reg_agency)
              else{
                const reg_agency = await this.cleanItem2LoadData(CollectionName.AGENCY,itemToClean.reg_agency,keyword)
                if(reg_agency instanceof Boolean && reg_agency == true) return true
                else newItem.reg_agency = reg_agency
              }
              if(this.checkobjectForKeyWordSearch(newItem.reg_agency,keyword) == true) return true
            }
            else newItem.reg_agency = ''
          }
        }

        return await newItem
      }
      else{
        itemToClean.id = ""
        itemToClean.firebase_folder = ""
        itemToClean.folder_name_id = ""
        itemToClean.file_file_name = ""
        itemToClean.regulation_text_annex_file_folder = ""
        itemToClean.regulation_text_annex_file_name = ""
        itemToClean.regulations_from = ""
        
        let temp_reg_business = itemToClean.reg_business
        let temp_reg_case = itemToClean.reg_case
        let temp_reg_division = itemToClean.reg_division
        let temp_reg_instrument = itemToClean.reg_instrument
        let temp_reg_juris = itemToClean.reg_juris
        let temp_reg_sector = itemToClean.reg_sector
        let temp_reg_stageoflife = itemToClean.reg_stageoflife
        let temp_reg_lifeevent = itemToClean.reg_lifeevent
        let temp_agency = itemToClean.reg_agency

        itemToClean.reg_business = ''
        itemToClean.reg_case = ''
        itemToClean.reg_division = ''
        itemToClean.reg_instrument = ''
        itemToClean.reg_juris = ''
        itemToClean.reg_sector = ''
        itemToClean.reg_stageoflife = ''
        itemToClean.reg_lifeevent = ''
        itemToClean.reg_lifeevent = ''

        if(temp_agency){
          if(Array.isArray(temp_agency)) itemToClean.reg_agency = []
          else if(Array.isArray(temp_agency)) itemToClean.reg_agency = ""
        }
        
        // console.log('to clean',itemToClean)
        if(this.checkobjectForKeyWordSearch(itemToClean,keyword) == true) return true

        // sob
        if(temp_reg_business != '' && temp_reg_business != null && temp_reg_business != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,temp_reg_business,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_division = item
            else itemToClean.reg_division = ''
          }
        }

        // case
        if(temp_reg_case != '' && temp_reg_case != null && temp_reg_case != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.CASE_USE,temp_reg_case,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_case = item
            else itemToClean.reg_case = ''
          }
        }

        // div
        if(temp_reg_division != '' && temp_reg_division != null && temp_reg_division != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.DIVISION,temp_reg_division,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_division = item
            else itemToClean.reg_division = ''
          }
        }

        // instru
        if(temp_reg_instrument != '' && temp_reg_instrument != null && temp_reg_instrument != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.INSTRUMENT,temp_reg_instrument,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_instrument = item
            else itemToClean.reg_instrument = ''
          }
        }

        // juris
        if(temp_reg_juris != '' && temp_reg_juris != null && temp_reg_juris != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,temp_reg_juris,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_juris = item
            else itemToClean.reg_juris = ''
          }
        }

        // sector
        if(temp_reg_sector != '' && temp_reg_sector != null && temp_reg_sector != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.SECTOR,temp_reg_sector,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_sector = item
            else itemToClean.reg_sector = ''
          }
        }

        // sol
        if(temp_reg_stageoflife != '' && temp_reg_stageoflife != null && temp_reg_stageoflife != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_LIFE,temp_reg_stageoflife,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_stageoflife = item
            else itemToClean.reg_stageoflife = ''
          }
        }

        // le
        if(temp_reg_lifeevent != '' && temp_reg_lifeevent != null && temp_reg_lifeevent != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.LIFE_EVENT,temp_reg_lifeevent,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_lifeevent = item
            else itemToClean.reg_lifeevent = ''
          }
        }

        // agency
        if(temp_agency && temp_agency != undefined && temp_agency != undefined){
          if(Array.isArray(temp_agency)){
            if(temp_agency.length > 0){
              for(let ctr in temp_agency){
                if(temp_agency[ctr] != '' && temp_agency[ctr] != null && temp_agency[ctr] != undefined){
                  if(this.loadedAgencyMap.has(temp_agency[ctr])){
                    itemToClean.reg_agency[ctr] = this.loadedAgencyMap.get(temp_agency[ctr])
                    if(this.checkobjectForKeyWordSearch(this.removeRowyValues(itemToClean.reg_agency[ctr]),keyword) == true) return true
                  }
                  else{
                    const reg_agency = await this.cleanItem2LoadData(CollectionName.AGENCY,temp_agency[ctr],keyword)
                    if(reg_agency == true) return true
                    else itemToClean.reg_agency[ctr] = reg_agency
                  }
                }else itemToClean.reg_agency[ctr] = ''
              }
            }
          } 
          else if(!Array.isArray(temp_agency)){
            if(temp_agency != '' && temp_agency != null && temp_agency != undefined){
              if(this.loadedAgencyMap.has(temp_agency)){
                itemToClean.reg_agency = this.loadedAgencyMap.get(temp_agency)
                if(this.checkobjectForKeyWordSearch(this.removeRowyValues(itemToClean.reg_agency),keyword) == true) return true
              }
              else{
                const reg_agency = await this.cleanItem2LoadData(CollectionName.AGENCY,temp_agency,keyword)
                if(reg_agency == true) return true
                else itemToClean.reg_agency =  reg_agency
              }
            } else itemToClean.reg_agency = ''
          }
        }
      } 
    }
    else if(fromCollection == 'phase2proposed'){
      if(this.searchform.value.keyword_sob == true || this.searchform.value.keyword_agency == true || this.searchform.value.keyword_case == true
        || this.searchform.value.keyword_sector == true || this.searchform.value.keyword_juris == true || this.searchform.value.keyword_div == true){
        let newItem:any = {}

        if(itemToClean.tags){
          newItem.tags = {}
          // sob
          if(this.searchform.value.keyword_sob == true && itemToClean.tags.reg_business != '' && itemToClean.tags.reg_business != null){
            const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,itemToClean.tags.reg_business,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_business = item
              else itemToClean.tags.reg_business = ''
            }
          } else newItem.tags.reg_business = ''

          // case
          if(this.searchform.value.keyword_case == true && itemToClean.tags.reg_case != '' && itemToClean.tags.reg_case != null) {
            const item:any = await this.cleanItemSub(CollectionName.CASE_USE,itemToClean.tags.reg_case,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_case = item
              else itemToClean.tags.reg_case = ''
            }
          } else newItem.tags.reg_case = ''

          // div
          if(this.searchform.value.keyword_div == true && itemToClean.tags.reg_division != '' && itemToClean.tags.reg_division != null) {
            const item:any = await this.cleanItemSub(CollectionName.DIVISION,itemToClean.tags.reg_division,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_division = item
              else itemToClean.tags.reg_division = ''
            }
          } else newItem.tags.reg_division = ''

          // juris
          if(this.searchform.value.keyword_juris == true && itemToClean.tags.reg_juris != '' && itemToClean.tags.reg_juris != null) {
            const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,itemToClean.tags.reg_juris,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_juris = item
              else itemToClean.tags.reg_juris = ''
            }
          } else newItem.tags.reg_juris = ''

          // sector
          if(this.searchform.value.keyword_sector == true && itemToClean.tags.reg_sector != '' && itemToClean.tags.reg_sector != null) {
            const item:any = await this.cleanItemSub(CollectionName.SECTOR,itemToClean.tags.reg_sector,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_sector = item
              else itemToClean.tags.reg_sector = ''
            }
          } else newItem.tags.reg_sector = ''

        }
        
        // sob
        if(this.searchform.value.keyword_sob == true && itemToClean.reg_business != '' && itemToClean.reg_business != null){
          const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,itemToClean.reg_business,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_business = item
            else newItem.reg_business = ''
          }
        } else newItem.reg_business = ''

        // case
        if(this.searchform.value.keyword_case == true && itemToClean.reg_case != '' && itemToClean.reg_case != null) {
          const item:any = await this.cleanItemSub(CollectionName.CASE_USE,itemToClean.reg_case,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_case = item
            else newItem.reg_case = ''
          }
        } else newItem.reg_case = ''

        // sector
        if(this.searchform.value.keyword_sector == true && itemToClean.reg_sector != '' && itemToClean.reg_sector != null) {
          const item:any = await this.cleanItemSub(CollectionName.SECTOR,itemToClean.reg_sector,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_sector = item
            else newItem.reg_sector = ''
          }
        } else newItem.reg_sector = ''

        // jusris
        if(this.searchform.value.keyword_juris == true && itemToClean.reg_juris != '' && itemToClean.reg_juris != null) {
          const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,itemToClean.reg_juris,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_juris = item
            else newItem.reg_juris = ''
          }
        }else newItem.reg_juris = ''

        // division
        if(this.searchform.value.keyword_div == true && itemToClean.reg_division != '' && itemToClean.reg_division != null) {
          const item:any = await this.cleanItemSub(CollectionName.DIVISION,itemToClean.reg_division,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) newItem.reg_division = item
            else newItem.reg_division = ''
          }
        } else newItem.reg_division = ''

        //agency
        if(this.searchform.value.keyword_agency == true && itemToClean.reg_agency){
          if(Array.isArray(itemToClean.reg_agency)){
            newItem.reg_agency = []
            if(itemToClean.reg_agency.length > 0){
              for(let ctr in itemToClean.reg_agency){
                if(itemToClean.reg_agency[ctr] != '' && itemToClean.reg_agency[ctr] != null){
                  if(this.loadedAgencyMap.has(itemToClean.reg_agency[ctr])){
                    newItem.reg_agency[ctr] = this.loadedAgencyMap.get(itemToClean.reg_agency[ctr])
                    if(this.checkobjectForKeyWordSearch(this.removeRowyValues(newItem.reg_agency[ctr]),keyword) == true) return true
                  }
                  else{
                    const reg_agency = await this.cleanItem2LoadData(CollectionName.AGENCY,itemToClean.reg_agency[ctr],keyword)
                    if(reg_agency == true) return true
                    else newItem.reg_agency[ctr] = reg_agency
                  }
                }
              }
            }
          }
          else if(!Array.isArray(itemToClean.reg_agency)){
            if(itemToClean.reg_agency != '' && itemToClean.reg_agency != null){
              if(this.loadedAgencyMap.has(itemToClean.reg_agency)){
                newItem.reg_agency = this.loadedAgencyMap.get(itemToClean.reg_agency)
                if(this.checkobjectForKeyWordSearch(this.removeRowyValues(newItem.reg_agency),keyword) == true) return true
              }
              else{
                const reg_agency = await this.cleanItem2LoadData(CollectionName.AGENCY,itemToClean.reg_agency,keyword)
                if(reg_agency == true) return true
                else newItem.reg_agency = reg_agency
              }
            }
          }
        } else newItem.reg_agency = ''

        return await newItem
      }
      else{
        itemToClean.hasExist = ''
        itemToClean.has_annex_files = ''
        itemToClean.has_consultation_comments = ''
        itemToClean.has_draft_text = ''
        itemToClean.id = ''
        itemToClean.part_of_reg_plan = ''
        itemToClean.pia_section_1.policy_problem_file_folder = ''
        itemToClean.pia_section_1.policy_problem_file_name = ''
        //pia 4
        itemToClean.pia_section_4 = itemToClean.pia_section_4.map((result:any)=>{
          result.compliance_file_folder = ''
          result.compliance_file_name = ''
          result.impacts.impact_disaster_risk = ''
          result.impacts.impact_economic = ''
          result.impacts.impact_environment = ''
          result.impacts.impact_social = ''
          return result
        })

        //pia 5
        itemToClean.pia_section_5 = itemToClean.pia_section_5.map((result:any)=>{
          result.has_agency_consulted = ''
          result.methods.method_focus_group = ''
          result.methods.method_forum = ''
          result.methods.method_other = ''
          result.methods.method_public_commenting = ''
          return result
        })

        itemToClean.reg_action_classification.reg_action_modification = ''
        itemToClean.reg_action_classification.reg_action_new = ''
        itemToClean.reg_action_classification.reg_action_repeal = ''

        itemToClean.reg_lenses.lense_competition = ''
        itemToClean.reg_lenses.lense_disaster = ''
        itemToClean.reg_lenses.lense_environmental = ''
        itemToClean.reg_lenses.lense_inclusive = ''

        let temp_reg_business = itemToClean.reg_business
        let temp_reg_case = itemToClean.reg_case
        let temp_reg_division = itemToClean.reg_division
        let temp_reg_instrument = itemToClean.reg_instrument
        let temp_reg_juris = itemToClean.reg_juris
        let temp_reg_sector = itemToClean.reg_sector
        let temp_reg_stageoflife = itemToClean.reg_stageoflife
        let temp_reg_lifeevent = itemToClean.reg_lifeevent
        let temp_agency = itemToClean.reg_agency

        itemToClean.reg_business = ''
        itemToClean.reg_case = ''
        itemToClean.reg_division = ''
        itemToClean.reg_instrument = ''
        itemToClean.reg_juris = ''
        itemToClean.reg_sector = ''
        itemToClean.reg_stageoflife = ''
        itemToClean.reg_lifeevent = ''
        
        if(itemToClean.reg_agency){
          if(Array.isArray(itemToClean.reg_agency)) {
            // console.log(currentId+' agency is []');
            itemToClean.reg_agency = [];
          }
          else if(!Array.isArray(itemToClean.reg_agency)) {
            // console.log(currentId+' agency is \"\"');
            itemToClean.reg_agency = "";
          }
        }

        let temp_notif_business
        let temp_notif_case
        let temp_notif_div
        let temp_notif_juris
        let temp_notif_stageoflife
        let temp_notif_lifeevent
        let temp_notif_sector
        if(itemToClean.reg_notif_form){
          temp_notif_business = itemToClean.reg_notif_form.reg_business
          itemToClean.reg_notif_form.reg_business = ''

          temp_notif_case = itemToClean.reg_notif_form.reg_case
          itemToClean.reg_notif_form.reg_case = ''
          
          temp_notif_div = itemToClean.reg_notif_form.reg_division
          itemToClean.reg_notif_form.reg_division = ''
          
          temp_notif_juris = itemToClean.reg_notif_form.reg_juris
          itemToClean.reg_notif_form.reg_juris = ''

          temp_notif_stageoflife = itemToClean.reg_notif_form.reg_stageoflife
          itemToClean.reg_notif_form.reg_stageoflife = ''

          temp_notif_lifeevent = itemToClean.reg_notif_form.reg_lifeevent
          itemToClean.reg_notif_form.reg_lifeevent = ''
          
          temp_notif_sector = itemToClean.reg_notif_form.reg_sector
          itemToClean.reg_notif_form.reg_sector = ''
        }

        
        let tag_notif_business
        let tag_notif_case
        let tag_notif_div
        let tag_notif_juris
        let tag_notif_stageoflife
        let tag_notif_lifeevent
        let tag_notif_sector
        if(itemToClean.tags){
          tag_notif_business = itemToClean.tags.reg_business
          itemToClean.tags.reg_business = ''

          tag_notif_case = itemToClean.tags.reg_case
          itemToClean.tags.reg_case = ''
          
          tag_notif_div = itemToClean.tags.reg_division
          itemToClean.tags.reg_division = ''
          
          tag_notif_juris = itemToClean.tags.reg_juris
          itemToClean.tags.reg_juris = ''

          tag_notif_stageoflife = itemToClean.reg_stageoflife
          itemToClean.tags.reg_stageoflife = ''

          tag_notif_lifeevent = itemToClean.tags.reg_lifeevent
          itemToClean.tags.reg_lifeevent = ''
          
          tag_notif_sector = itemToClean.tags.reg_sector
          itemToClean.tags.reg_sector = ''
          
          itemToClean.tags.regulation_lapse = ''
          itemToClean.tags.regulation_repeal = ''
        }
        
        itemToClean.status = ''
        
        if(this.checkobjectForKeyWordSearch(itemToClean,keyword,currentId) == true) return true

        //reg_agency
        if(temp_agency){
          if(Array.isArray(temp_agency)){
            if(temp_agency.length > 0){
              for(let ctr in temp_agency){
                if(temp_agency[ctr] != '' && temp_agency[ctr] != null && temp_agency[ctr] != undefined){
                  if(this.loadedAgencyMap.has(itemToClean.reg_agency[ctr])){
                    itemToClean.reg_agency[ctr] = this.loadedAgencyMap.get(temp_agency[ctr])
                    if(this.checkobjectForKeyWordSearch(this.removeRowyValues(itemToClean.reg_agency[ctr]),keyword) == true) return true
                  }
                  else{
                    const reg_agency = await this.cleanItem2LoadData('Agency',temp_agency[ctr],keyword,currentId)
                    if(reg_agency == true) return true
                    else itemToClean.reg_agency[ctr] = reg_agency
                  }
                }
              }
            }
          }
          else if(!Array.isArray(temp_agency)){
            if(temp_agency != '' && temp_agency != null && temp_agency != undefined){
              if(this.loadedAgencyMap.has(temp_agency)){
                itemToClean.reg_agency = this.loadedAgencyMap.get(temp_agency)
                if(this.checkobjectForKeyWordSearch(this.removeRowyValues(itemToClean.reg_agency),keyword) == true) return true
              }
              else{
                const reg_agency = await this.cleanItem2LoadData('Agency',temp_agency,keyword,currentId)
                if(reg_agency == true) return true
                else itemToClean.reg_agency = reg_agency
              }
            }
          }
        }

        // instrument
        if(temp_reg_instrument != '' && temp_reg_instrument != null && temp_reg_instrument != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.INSTRUMENT,temp_reg_instrument,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_instrument = item
            else itemToClean.reg_instrument = ''
          }
        }

        // notif form
        if(itemToClean.reg_notif_form){
          if(temp_notif_business != '' && temp_notif_business != null && temp_notif_business != undefined){
            const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,temp_notif_business,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_business = item
              else itemToClean.reg_notif_form.reg_business = ''
            }
          }
          if(temp_notif_case != '' && temp_notif_case != null && temp_notif_case != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.CASE_USE,temp_notif_case,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_case = item
              else itemToClean.reg_notif_form.reg_case = ''
            }
          }
          if(temp_notif_sector != '' && temp_notif_sector != null && temp_notif_sector != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.SECTOR,temp_notif_sector,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_sector = item
              else itemToClean.reg_notif_form.reg_sector = ''
            }
          }
          if(temp_notif_div != '' && temp_notif_div != null && temp_notif_div != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.DIVISION,temp_notif_div,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_division = item
              else itemToClean.reg_notif_form.reg_division = ''
            }
          }
          if(temp_notif_juris != '' && temp_notif_juris != null && temp_notif_juris != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,temp_notif_juris,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_juris = item
              else itemToClean.reg_notif_form.reg_juris = ''
            }
          }
          if(temp_notif_stageoflife != '' && temp_notif_stageoflife != null && temp_notif_stageoflife != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_LIFE,temp_notif_stageoflife,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_stageoflife = item
              else itemToClean.reg_notif_form.reg_stageoflife = ''
            }
          }
          if(temp_notif_lifeevent != '' && temp_notif_lifeevent != null && temp_notif_lifeevent != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.LIFE_EVENT,temp_notif_lifeevent,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.reg_notif_form.reg_lifeevent = item
              else itemToClean.reg_notif_form.reg_lifeevent = ''
            }
          }
        }
        
        // tegs
        if(itemToClean.tags){
          // sob
          if(tag_notif_business != '' && tag_notif_business != null && tag_notif_business != undefined){
            const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,tag_notif_business,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_business = item
              else itemToClean.tags.reg_business = ''
            }
          }

          // case
          if(tag_notif_case != '' && tag_notif_case != null && tag_notif_case != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.CASE_USE,tag_notif_business,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_case = item
              else itemToClean.tags.reg_case = ''
            }
          }

          // div
          if(tag_notif_div != '' && tag_notif_div != null && tag_notif_div != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.DIVISION,tag_notif_div,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_division = item
              else itemToClean.tags.reg_division = ''
            }
          }

          // juris
          if(tag_notif_juris != '' && tag_notif_juris != null && tag_notif_juris != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,tag_notif_juris,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_juris = item
              else itemToClean.tags.reg_juris = ''
            }
          }

          // sol
          if(tag_notif_stageoflife != '' && tag_notif_stageoflife != null && tag_notif_stageoflife != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_LIFE,tag_notif_stageoflife,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_stageoflife = item
              else itemToClean.tags.reg_stageoflife = ''
            }
          }

          // life
          if(tag_notif_lifeevent != '' && tag_notif_lifeevent != null && tag_notif_lifeevent != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.LIFE_EVENT,tag_notif_lifeevent,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_lifeevent = item
              else itemToClean.tags.reg_lifeevent = ''
            }
          }

          // sector
          if(tag_notif_sector != '' && tag_notif_sector != null && tag_notif_sector != undefined) {
            const item:any = await this.cleanItemSub(CollectionName.SECTOR,tag_notif_sector,keyword)
            if(item instanceof Boolean && item == true) return true
            else{
              if(item) itemToClean.tags.reg_sector = item
              else itemToClean.tags.reg_sector = ''
            }
          }
        }

        // sob
        if(temp_reg_business != '' && temp_reg_business != null && temp_reg_business != undefined){
          const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_BUSINESS,temp_reg_business,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_business = item
            else itemToClean.reg_business = ''
          }
        }

        // case
        if(temp_reg_case!= '' && temp_reg_case != null && temp_reg_case != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.CASE_USE,temp_reg_case,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_case = item
            else itemToClean.reg_case = ''
          }
        }

        // div
        if(temp_reg_division != '' && temp_reg_division != null && temp_reg_division != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.DIVISION,temp_reg_division,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_division = item
            else itemToClean.reg_division = ''
          }
        }

        // jusris
        if(temp_reg_juris != '' && temp_reg_juris != null && temp_reg_juris != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.JURISDICTION,temp_reg_juris,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_juris = item
            else itemToClean.reg_juris = ''
          }
        }

        // sol
        if(temp_reg_stageoflife != '' && temp_reg_stageoflife != null && temp_reg_stageoflife != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.STAGE_OF_LIFE,temp_reg_stageoflife,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_stageoflife = item
            else itemToClean.reg_stageoflife = ''
          }
        }

        // life
        if(temp_reg_lifeevent != '' && temp_reg_lifeevent != null && temp_reg_lifeevent != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.LIFE_EVENT,temp_reg_lifeevent,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_lifeevent = item
            else itemToClean.reg_lifeevent = ''
          }
        }

        // sector
        if(temp_reg_sector != '' && temp_reg_sector != null && temp_reg_sector != undefined) {
          const item:any = await this.cleanItemSub(CollectionName.SECTOR,temp_reg_sector,keyword)
          if(item instanceof Boolean && item == true) return true
          else{
            if(item) itemToClean.reg_sector = item
            else itemToClean.reg_sector = ''
          }
        }
      }
    }

    return await itemToClean
  }

  async cleanItemSub(collection:any,id:any,keyword:any){
    let item:any;
    switch(collection){
      case CollectionName.STAGE_OF_BUSINESS:{
        if(this.loadedStageOfBusinessMap.has(id)){
          let item = this.loadedStageOfBusinessMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.CASE_USE:{
        if(this.loadedCaseUseMap.has(id)){
          let item = this.loadedCaseUseMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.SECTOR:{
        if(this.loadedSectorMap.has(id)){
          let item = this.loadedSectorMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.DIVISION:{
        if(this.loadedDivisionMap.has(id)){
          let item = this.loadedDivisionMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.STAGE_OF_LIFE:{
        if(this.loadedStageofLifeMap.has(id)){
          let item = this.loadedStageofLifeMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.LIFE_EVENT:{
        if(this.loadedLifeEventMap.has(id)){
          let item = this.loadedLifeEventMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.JURISDICTION:{
        if(this.loadedJurisdictionMap.has(id)){
          let item = this.loadedJurisdictionMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.INSTRUMENT:{
        if(this.loadedInstrumentMap.has(id)){
          let item = this.loadedInstrumentMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
      case CollectionName.AGENCY:{
        if(this.loadedAgencyMap.has(id)){
          let item = this.loadedAgencyMap.get(id)
          item = this.removeRowyValues(item)
        }
        break;
      }
    }

    if(item){
      if(this.checkobjectForKeyWordSearch(item,keyword) == true) return true
      return item
    }else{
      const item:any = await this.cleanItem2LoadData(collection,id,keyword)
      if(item instanceof Boolean && item == true) return true
      else return item
    }
  }

  async cleanItem2LoadData(control:string, dataId:any, keyword:any, currentId?:any){
    try{
      const item:any = await this.loadDataFromCollectionByID(control,dataId)
      if(item){
        // console.log(currentId+' '+control,item)
        switch(control){
          case CollectionName.STAGE_OF_BUSINESS: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_business');
          case CollectionName.CASE_USE: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_case');
          case CollectionName.SECTOR: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_sector');
          case CollectionName.DIVISION: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_division');
          case CollectionName.INSTRUMENT: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_instrument');
          case CollectionName.JURISDICTION: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_juris');
          case CollectionName.STAGE_OF_LIFE: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_stageoflife');
          case CollectionName.LIFE_EVENT: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_lifeevent');
          case CollectionName.AGENCY: return this.cleanItem2LoadDataSub(item,keyword,currentId,'reg_agency');
          default: return ''
        }
      }else return ''
    }
    catch(err){
      console.error('Error: '+control+' | '+dataId, err)
      return ''
    }
  }

  cleanItem2LoadDataSub(item:any,keyword:any,currentId:any,controlCode:any){
    const cleanedItem = this.removeRowyValues(item)
    if(this.checkobjectForKeyWordSearch(cleanedItem,keyword, currentId,controlCode) == true) return true
    else return cleanedItem
  }

  checkobjectForKeyWordSearch(item:any,keyword:string,id?:any,code?:any){
    // console.log('checkobjectForKeyWordSearch: '+id+" | "+code,item)
    let bool = false
    if(typeof item === 'object') {
      try{
        bool = this.anArray(Object.values(item),keyword)
        if(bool == true) return bool
      }catch(error){
        console.error('Error checkobjectForKeyWordSearch', item)
        return bool
      }
    }
    return bool
  }

  removeRowyValues(item:any){
    if(item){
      let itemToClean:any = JSON.parse(JSON.stringify(item));
      if(itemToClean.id) delete item.id
      if(itemToClean._createdBy) delete item._createdBy
      if(itemToClean._updatedBy) delete item._updatedBy
      if(itemToClean.ref) delete item.ref
      return itemToClean
    }
    else{
      console.error('Error removeRowyValues: ',item)
      return ''
    }
  }

  // getDatabyId(collection:any,id:any){
  //   let getCollection = this.referenceCollections.get(collection);
  //   return Object.assign({}, getCollection?.filter(
  //     (result) => result.id === id
  //   )[0]);
  // }

  pbris1Filters(inputJSON:any, query:any){
    /*
    // let time_ctr = 0;

    // classification
    // if(inputJSON.classification != '' && inputJSON.classification != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 class")
    // }

    // stage of life
    // if(inputJSON.stageoflife != '' && inputJSON.stageoflife != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 stageoflife")
    // }

    // life event
    // if(inputJSON.lifeevent != '' && inputJSON.lifeevent != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 lifeevent")
    // }
    // */

    // sector
    if(inputJSON.sector != '' && inputJSON.sector != null ){
      query = query.where('sector','==',inputJSON.sector)
      // console.log("phase1 sector")
    }

    /*
    // division
    // if(inputJSON.division != '' && inputJSON.division != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 division")
    // }

    // stageofbusiness
    // if(inputJSON.stageofbusiness != '' && inputJSON.stageofbusiness != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 stageofbusiness")
    // }

    // caseuse
    // if(inputJSON.caseuse != '' && inputJSON.caseuse != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 caseuse")
    // }

    // jurisdiction
    // if(inputJSON.jurisdiction != '' && inputJSON.jurisdiction != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 jurisdiction")
    // }

    // if(inputJSON.document != '' && inputJSON.document != null ){
    //   query = query.where('reg_document','==',inputJSON.document)
    //   console.log("existing jurisdiction")
    // }

    // instrument
    // if(inputJSON.instrument != '' && inputJSON.instrument != null ){
    //   query = query.where('error','==','error')
    //   console.log("phase1 instrument")
    // }
    // */

    //publication date
    if(inputJSON.publication == 'Exactly on'){
      // time_ctr++
      if(inputJSON.publicationexact != '' && inputJSON.publicationexact != null){
        let splitDate = inputJSON.publicationexact.split('-')
        if(splitDate.length > 0){
          let year = splitDate[0]
          let month = splitDate[1]
          let day = splitDate[2]
          let startDate = new Date(year,month-1,day,0,0,0,0)
          let endDate = new Date(year,month-1,day,23,59,59,999)
          // console.log(startDate,endDate)
          query = query.orderBy('doc_date','desc').where('doc_date', '>=', startDate).where('doc_date', '<=', endDate)
        } //else query = query.where('error','==','error')
      } //else query = query.where('error','==','error')
      //console.log("finding phase1 publication exact date")
    }
    else if(inputJSON.publication == 'In Year'){
      // time_ctr++
      if(inputJSON.publicationyear != '' && inputJSON.publicationyear != null){
        let splitDate = inputJSON.publicationyear.split('-')
        if(splitDate.length > 0){
          let year = splitDate[0]
          let startDate = new Date(year,0,1,0,0,0,0)
          let endDate = new Date(year,11,31,23,59,59,999)
          // console.log(startDate,endDate)
          query = query.orderBy('doc_date','desc').where('doc_date', '>=', startDate).where('doc_date', '<=', endDate)
        } // else query = query.where('error','==','error')
      }// else query = query.where('error','==','error')
      // query = query.orderBy('doc_date','desc').where('doc_date', '>=', inputJSON.publicationyear).where('doc_date', '<=', inputJSON.publicationyear + '\uF8FF')
      // console.log("finding phase1 publication exact year")
    }
    else if(inputJSON.publication == 'Range'){
      // time_ctr++
      if(inputJSON.publicationstartdate != '' && inputJSON.publicationstartdate != null && inputJSON.publicationenddate != '' && inputJSON.publicationenddate != null){
        if (inputJSON.publicationstartdate <= inputJSON.publicationenddate) {
          let splitStartDate = inputJSON.publicationstartdate.split('-')
          let splitEndDate = inputJSON.publicationenddate.split('-')
          if(splitStartDate.length > 0 && splitEndDate.length > 0){
            let startYear = splitStartDate[0]
            let startMonth = splitStartDate[1]
            let startDay = splitStartDate[2]

            let endYear = splitEndDate[0]
            let endMonth = splitEndDate[1]
            let endDay = splitEndDate[2]

            let startDate = new Date(startYear,startMonth-1,startDay,0,0,0,0)
            let endDate = new Date(endYear,endMonth-1,endDay,23,59,59,999)
            // console.log(startDate,endDate)
            query = query.orderBy('doc_date','desc').where('doc_date', '>=', startDate).where('doc_date', '<=', endDate)
          } //else query = query.where('error','==','error')
          // query = query.orderBy('doc_date','desc').where('doc_date','>=',inputJSON.publicationstartdate).where('doc_date','<=',inputJSON.publicationenddate + '\uF8FF')
        }
        else if(inputJSON.publicationstartdate >= inputJSON.publicationenddate) {
          let splitStartDate = inputJSON.publicationenddate.split('-')
          let splitEndDate = inputJSON.publicationstartdate.split('-')
          if(splitStartDate.length > 0 && splitEndDate.length > 0){
            let startYear = splitStartDate[0]
            let startMonth = splitStartDate[1]
            let startDay = splitStartDate[2]

            let endYear = splitEndDate[0]
            let endMonth = splitEndDate[1]
            let endDay = splitEndDate[2]

            let startDate = new Date(startYear,startMonth-1,startDay,0,0,0,0)
            let endDate = new Date(endYear,endMonth-1,endDay,23,59,59,999)
            // console.log(startDate,endDate)
            query = query.orderBy('doc_date','desc').where('doc_date', '>=', startDate).where('doc_date', '<=', endDate)
          } //else query = query.where('error','==','error')
          // query = query.orderBy('doc_date','desc').where('doc_date','>=',inputJSON.publicationenddate).where('doc_date','<=',inputJSON.publicationstartdate + '\uF8FF')
        } //else query = query.where('error','==',"error")
      } //else query = query.where('error','==',"error")
      // console.log("finding phase1 publication range date")
    }

    /*
    // effectivity date
    // if(inputJSON.effectivity == 'Exactly on'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding phase1 effectivity exact date")
    // }
    // else if(inputJSON.effectivity == 'In Year'){
    //   time_ctr++
      
    //   query = query.where('error','==','error')
    //   // query = query.orderBy('doc_date','desc').where('doc_date', '>=', inputJSON.publicationyear).where('doc_date', '<=', inputJSON.publicationyear + '\uF8FF')
    //   console.log("finding phase1 effectivity exact year")
    // }
    // else if(inputJSON.effectivity == 'Range'){
    //   time_ctr++
    //   query = query.where('error','==',"error")
    //   console.log("finding phase1 effectivity range date")
    // }

    // amend/repeal date
    // if(inputJSON.amend == 'Exactly on'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding phase1 effectivity exact date")
    // }
    // else if(inputJSON.amend == 'In Year'){
    //   time_ctr++
      
    //   query = query.where('error','==','error')
    //   // query = query.orderBy('doc_date','desc').where('doc_date', '>=', inputJSON.publicationyear).where('doc_date', '<=', inputJSON.publicationyear + '\uF8FF')
    //   console.log("finding phase1 effectivity exact year")
    // }
    // else if(inputJSON.amend == 'Range'){
    //   time_ctr++
    //   query = query.where('error','==',"error")
    //   console.log("finding phase1 effectivity range date")
    // }
    // */

    // regulation id
    if(inputJSON.regulationid != '' && inputJSON.regulationid != null){
      query = query.where('issuance_no','==',inputJSON.regulationid)
      // console.log("existing regulationid")
    }

    if(inputJSON.effectivity_status == "In-effect" ){
      // query = query.where('reg_number','==',inputJSON.regulationid)
      query = query.where('regulation_type','==',"Existing")
      // console.log("existing In-effect")
    }
    /*
    // else if(inputJSON.effectivity_status == "Repealed/Lapsed" ){
    //   // query = query.where('reg_number','==',inputJSON.regulationid)
    //   query = query.where('error','==',"error")
    //   console.log("existing Repealed/Lapsed")
    // }
    // else if(inputJSON.effectivity_status == "Amended" ){
    //   // query = query.where('reg_number','==',inputJSON.regulationid)
    //   query = query.where('error','==',"error")
    //   console.log("existing Amended")
    // }
    // else if(inputJSON.effectivity_status == "Emergency" ){
    //   // query = query.where('reg_number','==',inputJSON.regulationid)
    //   query = query.where('error','==',"error")
    //   console.log("existing Emergency")
    // }

    // // significance
    // if(inputJSON.significance  != '' && inputJSON.significance != null){
    //   query = query.where('error','==',"error")
    //   console.log("existing significance")
    // }
    // */

    if(inputJSON.agency != '' && inputJSON.agency != null) {
      query = query.where('issuing_agency','array-contains',inputJSON.agency)
      // console.log("existing agencies")
    } 
    
    // if(time_ctr == 0) query = query.orderBy('short_title')
    return query
  }

  // title
  // subject
  // short_title
  // pbris2ExistingFilters(inputJSON:any, query:any, additionalCondition:any,agencyId?:any){
  pbris2ExistingFilters(inputJSON:any, query:any, agencyId?:any){
    let time_ctr = 0
    // classification
    if(inputJSON.classification != '' && inputJSON.classification != null ){
      query = query.where('reg_classification','==',inputJSON.classification)
      // console.log("existing class")

       // stage of life
      if(inputJSON.stageoflife != '' && inputJSON.stageoflife != null ){
        query = query.where('reg_stageoflife','==',inputJSON.stageoflifeId)
        // console.log("existing stageoflife")

        // life event
        if(inputJSON.lifeevent != '' && inputJSON.lifeevent != null ){
          query = query.where('reg_lifeevent','==',inputJSON.lifeevent)
          // console.log("existing lifeevent")
        }
      }
    }

    // sector
    if(inputJSON.sector != '' && inputJSON.sector != null ){
      query = query.where('reg_sector','==',inputJSON.sectorId)
      // console.log("existing sector",inputJSON.sector)
    }

    // division
    if(inputJSON.division != '' && inputJSON.division != null ){
      query = query.where('reg_division','==',inputJSON.divisionId)
      // console.log("existing division")
    }

    // stageofbusiness
    if(inputJSON.stageofbusiness != '' && inputJSON.stageofbusiness != null ){
      query = query.where('reg_business','==',inputJSON.stageofbusinessId)
      // console.log("existing stageofbusiness")
    }

    // caseuse
    if(inputJSON.caseuse != '' && inputJSON.caseuse != null ){
      query = query.where('reg_case','==',inputJSON.caseuseId)
      // console.log("existing caseuse")
    }

    // jurisdiction
    if(inputJSON.jurisdiction != '' && inputJSON.jurisdiction != null ){
      query = query.where('reg_juris','==',inputJSON.jurisdictionId)
      // console.log("existing jurisdiction")
    }

    // if(inputJSON.document != '' && inputJSON.document != null ){
    //   query = query.where('reg_document','==',inputJSON.document)
    //   console.log("existing jurisdiction")
    // }

    // instrument
    if(inputJSON.instrument != '' && inputJSON.instrument != null ){
      query = query.where('reg_instrument','==',inputJSON.instrumentId)
      // console.log("existing instrument")
    }

    // if(inputJSON.agencylocation != '' && inputJSON.agencylocation != null ){
    //   query = query.where('reg_juris','==',inputJSON.agencylocation)
    //   console.log("existing jurisdiction")
    // }

    //publication date
    if(inputJSON.publication == 'Exactly on'){
      time_ctr++
      query = query.where('doc_date','==',inputJSON.publicationexact)
      // console.log("finding publication exact date")
    }
    else if(inputJSON.publication == 'In Year'){
      time_ctr++
      query = query.orderBy('doc_date','desc').where('doc_date', '>=', inputJSON.publicationyear).where('doc_date', '<=', inputJSON.publicationyear + '\uF8FF')
      // console.log("finding publication exact year")
    }
    else if(inputJSON.publication == 'Range'){
      time_ctr++
      if (inputJSON.publicationstartdate <= inputJSON.publicationenddate) {
        query = query.orderBy('doc_date','desc').where('doc_date','>=',inputJSON.publicationstartdate).where('doc_date','<=',inputJSON.publicationenddate + '\uF8FF')
      }
      else if(inputJSON.publicationstartdate > inputJSON.publicationenddate) {
        query = query.orderBy('doc_date','desc').where('doc_date','>=',inputJSON.publicationenddate).where('doc_date','<=',inputJSON.publicationstartdate + '\uF8FF')
      }
      // else {
      //   query = query.where('error','==',"error")
      // }
      // console.log("finding publication range date")
    }

    //effectivity date
    if(inputJSON.effectivity == 'Exactly on'){
      time_ctr++
      query = query.where('date_effective','==',inputJSON.effectivityexact)
      // console.log("finding effectivity exact date")
    }
    else if(inputJSON.effectivity == 'In Year'){
      time_ctr++
      query = query.orderBy('date_effective','desc').where('date_effective', '>=', inputJSON.effectivityyear).where('date_effective', '<=', inputJSON.effectivityyear + '\uF8FF')
      // console.log("finding effectivity exact year")
    }
    else if(inputJSON.effectivity == 'Range'){
      time_ctr++
      if (inputJSON.effectivitystartdate <= inputJSON.effectivityenddate) {
        query = query.orderBy('date_effective','desc').where('date_effective','>=',inputJSON.effectivitystartdate).where('date_effective','<=',inputJSON.effectivityenddate + '\uF8FF')
      }
      else if(inputJSON.effectivitystartdate > inputJSON.effectivityenddate) {
        query = query.orderBy('date_effective','desc').where('date_effective','>=',inputJSON.effectivityenddate).where('date_effective','<=',inputJSON.effectivitystartdate + '\uF8FF')
      }
      // else {
      //   query = query.where('error','==',"error")
      // }
      // console.log("finding effectivity range date")
    }

    //amend/repeal date
    if(inputJSON.amend == 'Exactly on'){
      // let startexactDate = Timestamp.fromDate(new Date())
      time_ctr++
      query = query.where('date_repeal','==',inputJSON.amendexact)
      // console.log("finding amend exact date")
    }
    else if(inputJSON.amend == 'In Year'){
      time_ctr++
      query = query.orderBy('date_repeal','desc').where('date_repeal', '>=', inputJSON.amendyear).where('date_repeal', '<=', inputJSON.amendyear + '\uF8FF')
      // console.log("finding amend exact year")
    }
    else if(inputJSON.amend == 'Range'){
      time_ctr++
      if (inputJSON.amendstartdate <= inputJSON.amendenddate) {
        query = query.orderBy('date_repeal','desc').where('date_repeal','>=',inputJSON.amendstartdate).where('date_repeal','<=',inputJSON.amendenddate + '\uF8FF')
      }
      else if(inputJSON.amendstartdate > inputJSON.amendenddate) {
        query = query.orderBy('date_repeal','desc').where('date_repeal','>=',inputJSON.amendenddate).where('date_repeal','<=',inputJSON.amendstartdate + '\uF8FF')
      }
      // else {
      //   query = query.where('error','==',"error")
      // }
      // console.log("finding amend range date")
    }
    

    // regulation id
    if(inputJSON.regulationid != '' && inputJSON.regulationid != null){
      query = query.where('reg_number','==',inputJSON.regulationid)
      // console.log("existing regulationid")
    }

    if(inputJSON.effectivity_status == "In-effect" ){
      // query = query.where('reg_number','==',inputJSON.regulationid)
      // console.log("existing In-effect")
    }
    else if(inputJSON.effectivity_status == "Repealed/Lapsed" ){
      query = query.where('error','==','error')
      console.log("existing Repealed/Lapsed")
    }
    else if(inputJSON.effectivity_status == "Amended" ){
      query = query.where('error','==','error')
      console.log("existing Amended")
    }
    else if(inputJSON.effectivity_status == "Emergency" ){
      query = query.where('error','==','error')
      console.log("existing Emergency")
    }


    // significance
    if(inputJSON.significance  != '' && inputJSON.significance != null){
      query = query.where('reg_significance','==',inputJSON.significance)
      // console.log("existing significance")
    }

    
    if(inputJSON.agencyIds.length > 0){
      query = query.where('reg_agency','array-contains',agencyId)
      // console.log("existing agencies",agencyId)
    } 
    // else if(inputJSON.agencyIds != '' && inputJSON.agencyIds != null && inputJSON.agencyIds.length == 0){
    //   query = query.where('error','==','error')
    // }
    
    
    // subject
    // short_title
    // if(time_ctr == 0) query = query.orderBy('subject')
    // query = query.orderBy(additionalCondition)
    query = query.orderBy('title')
    // query.startAt(((this.page*this.documentsPerPage)-this.documentsPerPage)).endAt(this.page*this.documentsPerPage)
    return query
  }

  pbris2ProposedFilters(inputJSON:any, query:any, agencyId?:any){
    // let time_ctr = 0
    // classification
    if(inputJSON.classification != '' && inputJSON.classification != null ){
      query = query.where('tags.reg_classification','==',inputJSON.classification)
      // console.log("existing class")

       // stage of life
      if(inputJSON.stageoflife != '' && inputJSON.stageoflife != null ){
        query = query.where('tags.reg_stageoflife','==',inputJSON.stageoflifeId)
        // console.log("existing stageoflife")

        // life event
        if(inputJSON.lifeevent != '' && inputJSON.lifeevent != null ){
          query = query.where('tags.reg_lifeevent','==',inputJSON.lifeevent)
          // console.log("existing lifeevent")
        }
      }
    }

    //get OR clause for tags.field and field

    // sector
    if(inputJSON.sector != '' && inputJSON.sector != null ){
      query = query.where('tags.reg_sector','==',inputJSON.sectorId)
      // console.log("existing sector",inputJSON.sector)
    }

    // division
    if(inputJSON.division != '' && inputJSON.division != null ){
      query = query.where('tags.reg_division','==',inputJSON.divisionId)
      // console.log("existing division")
    }

    // stageofbusiness
    if(inputJSON.stageofbusiness != '' && inputJSON.stageofbusiness != null ){
      query = query.where('tags.reg_business','==',inputJSON.stageofbusinessId)
      // console.log("existing stageofbusiness")
    }

    // caseuse
    if(inputJSON.caseuse != '' && inputJSON.caseuse != null ){
      query = query.where('tags.reg_case','==',inputJSON.caseuseId)
      // console.log("existing caseuse")
    }

    // jurisdiction
    if(inputJSON.jurisdiction != '' && inputJSON.jurisdiction != null ){
      query = query.where('tags.reg_juris','==',inputJSON.jurisdictionId)
      // console.log("existing jurisdiction")
    }

    // if(inputJSON.document != '' && inputJSON.document != null ){
    //   query = query.where('reg_document','==',inputJSON.document)
    //   console.log("existing jurisdiction")
    // }

    // instrument
    if(inputJSON.instrument != '' && inputJSON.instrument != null ){
      query = query.where('reg_instrument','==',inputJSON.instrumentId)
      // console.log("existing instrument")
    }

    // if(inputJSON.agencylocation != '' && inputJSON.agencylocation != null ){
    //   query = query.where('reg_juris','==',inputJSON.agencylocation)
    //   console.log("existing jurisdiction")
    // }

    // //publication date
    // if(inputJSON.publication == 'Exactly on'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding publication exact date")
    // }
    // else if(inputJSON.publication == 'In Year'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding publication exact year")
    // }
    // else if(inputJSON.publication == 'Range'){
    //   time_ctr++
    //   if (inputJSON.publicationstartdate < inputJSON.publicationenddate) {
    //     query = query.where('error','==','error')
    //   }
    //   else if(inputJSON.publicationstartdate > inputJSON.publicationenddate) {
    //     query = query.where('error','==','error')
    //   }
    //   else {
    //     query = query.where('error','==',"error")
    //   }
    //   console.log("finding publication range date")
    // }

    // //effectivity date
    // if(inputJSON.effectivity == 'Exactly on'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding effectivity exact date")
    // }
    // else if(inputJSON.effectivity == 'In Year'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding effectivity exact year")
    // }
    // else if(inputJSON.effectivity == 'Range'){
    //   time_ctr++
    //   if (inputJSON.effectivitystartdate < inputJSON.effectivityenddate) {
    //     query = query.where('error','==','error')
    //   }
    //   else if(inputJSON.effectivitystartdate > inputJSON.effectivityenddate) {
    //     query = query.where('error','==','error')
    //   }
    //   else {
    //     query = query.where('error','==',"error")
    //   }
    //   console.log("finding effectivity range date")
    // }

    // //amend/repeal date
    // if(inputJSON.amend == 'Exactly on'){
    //   // let startexactDate = Timestamp.fromDate(new Date())
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding amend exact date")
    // }
    // else if(inputJSON.amend == 'In Year'){
    //   time_ctr++
    //   query = query.where('error','==','error')
    //   console.log("finding amend exact year")
    // }
    // else if(inputJSON.amend == 'Range'){
    //   time_ctr++
    //   if (inputJSON.amendstartdate < inputJSON.amendenddate) {
    //     query = query.where('error','==','error')
    //   }
    //   else if(inputJSON.amendstartdate > inputJSON.amendenddate) {
    //     query = query.where('error','==','error')
    //   }
    //   else {
    //     query = query.where('error','==',"error")
    //   }
    //   console.log("finding amend range date")
    // }
    

    // regulation id
    // if(inputJSON.regulationid != '' && inputJSON.regulationid != null){
    //   query = query.where('error','==',"error")
    //   console.log("existing regulationid")
    // }

    // if(inputJSON.effectivity_status == "In-effect" ){
    //   // query = query.where('reg_number','==',inputJSON.regulationid)
    //   query = query.where('error','==','error')
    //   console.log("existing In-effect")
    // }
    // else if(inputJSON.effectivity_status == "Repealed/Lapsed" ){
    //   query = query.where('error','==','error')
    //   console.log("existing Repealed/Lapsed")
    // }
    // else if(inputJSON.effectivity_status == "Amended" ){
    //   query = query.where('error','==','error')
    //   console.log("existing Amended")
    // }
    // else if(inputJSON.effectivity_status == "Emergency" ){
    //   query = query.where('error','==','error')
    //   console.log("existing Emergency")
    // }


    // significance
    if(inputJSON.significance  != '' && inputJSON.significance != null){
      query = query.where('reg_significance','==',inputJSON.significance)
      // console.log("existing significance")
    }

    // console.log('getting agencies')
    if(inputJSON.agencyIds.length > 0){
      // console.log(agencyId)
      query = query.where('reg_agency','array-contains',agencyId)
      // console.log("existing agencies",agencyId)
    }
    // else if(inputJSON.agencyIds != '' && inputJSON.agencyIds != null && inputJSON.agencyIds.length == 0){
    //   query = query.where('error','==','error')
    // }
    
    
    // if(time_ctr == 0) query = query.orderBy('subject')
    
    // query = query.orderBy('short_title')
    query = query.orderBy('subject')
    return query
  }


  //previous keyword search
  isAcceptedItem(keyName: string, inputJSON: any, item: any) {

    // if field is left empty
    if (inputJSON[keyName] === '') {
      return true;
    }
    // if field is from keyword, convert all to lower-case then compare
    if (keyName === "short_title" || keyName === "subject") {
      if (
        item[keyName] && inputJSON[keyName] &&
        item[keyName].toLowerCase().includes(inputJSON[keyName].toLowerCase())) {
        return true; 
      }
    }
    // if field has data that is not from keyword
    else if (item[keyName] === inputJSON[keyName]) {
      return true
    }
    else if (item[keyName] === undefined || inputJSON[keyName] === undefined) {
      return false;
    }

    return false;
  }

  viewRegulation(entryId: string, from:any) {
    // console.log(entryId,from)
    if(from === "regulations")
      this.router.navigate([`/pbris/regulations/view/${entryId}`]);
    if(from === "existing" || from === "proposed")
      this.router.navigate(['/pbris/regulations/view/',from,entryId]);
  }

  onPinned(data: any) {
    // console.log('the data',data)
    if (data.hasExist) {
      let stat: any;
      let detail: any;
      if (data.status) {
        stat = false
        detail = "Successfully unpinned"
      }
      else {
        stat = true
        detail = "Successfully pinned"
      } 
 
      this.afs.collection(`PBRIS Pinned Regulation`)
        .doc(data.reg_id)
        .update({status: stat})
        .then((docRef:any) => {
        this.loading = false;
        this.regulations.map((reg: any) => {
          if(reg.PRN === data.PRN) {
            reg.status = data.hasExist ? data.status ? false : true : true;
          }
          return reg
        })
        Swal.close();
        Swal.fire({
          title: detail,
          icon: 'info'
        });
        // if(stat) this.toSendNotifEmail(data.short_title)
      }).catch((error:any) =>{
        this.loading = false;
        // console.log("Error",error)
        Swal.close();
        Swal.fire({
          title:'System error',
          titleText: 'Please refresh the page',
          icon: 'error'
        });
      });
    }
    else {
      let info: any = [];
      info.push({
        user_id: this.user_id,
        PRN: data.PRN,
        status: true
      });
      this.afs.collection(`PBRIS Pinned Regulation`)
        .add(info[0])
        .then((docRef:any) => {
        this.loading = false;
        this.ngOnInit()
        Swal.close();
        Swal.fire({
          title:'Successfully pinned',
          icon: 'info'
        });
        // this.toSendNotifEmail(data.short_title)
      }).catch((error:any) =>{
        this.loading = false;
        // console.log("Error",error)
        Swal.close();
        Swal.fire({
          title:'System error',
          titleText: 'Please refresh the page',
          icon: 'error'
        });
      });
    }
  }

  loadPinnedRegulation(id: any, PRN: any){
    return this.afs.collection(`PBRIS Pinned Regulation`, ref => ref.where('user_id', '==', id).where('PRN', '==', PRN)).get()
  }

  async getSector(sectorDocID: string) {
    const sectorDoc = await firstValueFrom(this.afs.collection(`Sector`).doc(sectorDocID).get());
    if(sectorDoc) {
      let sectorData: any = sectorDoc.data();
      sectorData.id = sectorDoc.id
      return sectorData;
      // return sectorData.name;
    }
    else {
      return false;
    }
  }

  resetFlags(){
    this.onClassView = false
    this.onSectorView = false
    this.onSobView = false
    this.onJurisView = false
    this.onEffectivityView = false
    this.onDivView = false
    this.onCaseView = false
    this.onAgencyView = false

    this.searchform.patchValue({
      keyworfilter: false,
      keyword_class: false,
      keyword_sector: false,
      keyword_sob: false,
      keyword_juris: false,
      keyword_effectivity: false,
      keyword_div: false,
      keyword_case: false,
      keyword_agency: false,
    })
  }

  // classDetailsView(){
  //   this.resetFlags()
  //   // this.onClassView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_class: true,
  //   })
  // }

  // sectorDetailsView(){
  //   this.resetFlags()
  //   // this.onSectorView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_sector: true,
  //   })
  // }

  // sobDetailsView(){
  //   this.resetFlags()
  //   // this.onSobView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_sob: true,
  //   })
  // }

  // jurisDetailsView(){
  //   this.resetFlags()
  //   // this.onJurisView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_juris: true,
  //   })
  // }

  // effectivityDetailsView(){
  //   this.resetFlags()
  //   // this.onEffectivityView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_effectivity: true,
  //   })
  // }

  // divDetailsView(){
  //   this.resetFlags()
  //   // this.onDivView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_div: true,
  //   })
  // }

  // caseDetailsView(){
  //   this.resetFlags()
  //   // this.onCaseView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_case: true,
  //   })
  // }

  // agencyDetailsView(){
  //   this.resetFlags()
  //   // this.onAgencyView = true
  //   this.searchform.patchValue({
  //     keyworfilter: true,
  //     keyword_agency: true,
  //   })
  // }

  classDetailsView(){
    // this.onClassView = true
    this.searchform.patchValue({
      keyword_class: !this.searchform.value.keyword_class,
    })
  }

  sectorDetailsView(){
    // this.onSectorView = true
    this.searchform.patchValue({
      keyword_sector: !this.searchform.value.keyword_sector,
    })
  }

  sobDetailsView(){
    // this.onSobView = true
    this.searchform.patchValue({
      keyword_sob: !this.searchform.value.keyword_sob,
    })
  }

  jurisDetailsView(){
    // this.onJurisView = true
    this.searchform.patchValue({
      keyword_juris: !this.searchform.value.keyword_juris,
    })
  }

  effectivityDetailsView(){
    // this.onEffectivityView = true
    this.searchform.patchValue({
      keyword_effectivity: !this.searchform.value.keyword_effectivity,
    })
  }

  divDetailsView(){
    // this.onDivView = true
    this.searchform.patchValue({
      keyword_div: !this.searchform.value.keyword_div,
    })
  }

  caseDetailsView(){
    // this.onCaseView = true
    this.searchform.patchValue({
      keyword_case: !this.searchform.value.keyword_case,
    })
  }

  agencyDetailsView(){
    // this.onAgencyView = true
    this.searchform.patchValue({
      keyword_agency: !this.searchform.value.keyword_agency,
    })
  }

  try(){
    console.log(this.searchform.value)
  }

  async loadReferenceData() {
    // this.referenceCollections = new Map<string, any[]>();
    this.referenceCollections = new Map<string, Map<string,any>>();
    this.loadDataFromCollection(CollectionName.AGENCY)
    this.loadDataFromCollection(CollectionName.SECTOR)
    this.loadDataFromCollection(CollectionName.STAGE_OF_BUSINESS)
    this.loadDataFromCollection(CollectionName.STAGE_OF_LIFE)
    this.loadDataFromCollection(CollectionName.LIFE_EVENT)
    this.loadDataFromCollection(CollectionName.DIVISION)
    this.loadDataFromCollection(CollectionName.JURISDICTION)
    this.loadDataFromCollection(CollectionName.CASE_USE)
    this.loadDataFromCollection(CollectionName.INSTRUMENT)
    // this.loadAgencies();
    // this.loadSectors();
    // this.loadSobs();
    // this.loadSols();
    // this.loadLifeEvents();
    // this.loadDivisions();
    // this.loadJuris();
    // this.loadCases();
    // this.loadInstruments();
  }

  loadDataFromCollection(collection:string){
    this.afs.collection(collection).snapshotChanges()
    .subscribe({
      next:(data)=>{
        data.map((info)=>{
          if(info.payload.doc.exists){
            let item:any = info.payload.doc.data()
            item.id = info.payload.doc.id

            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy
            if(item.ref) delete item.ref

            switch(collection){
              case CollectionName.AGENCY:{ 
                if(!this.loadedAgencyMap.has(item.id)){
                  this.loadedAgencyMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedAgencyMap)
                }
                break;
              }
              case CollectionName.SECTOR:{ 
                if(!this.loadedSectorMap.has(item.id)){
                  this.loadedSectorMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedSectorMap)
                }
                break;
              }
              case CollectionName.STAGE_OF_BUSINESS:{ 
                if(!this.loadedStageOfBusinessMap.has(item.id)){
                  this.loadedStageOfBusinessMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedStageOfBusinessMap)
                }
                break;
              }
              case CollectionName.STAGE_OF_LIFE:{ 
                if(!this.loadedStageofLifeMap.has(item.id)){
                  this.loadedStageofLifeMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedStageofLifeMap)
                }
                break;
              }
              case CollectionName.LIFE_EVENT:{ 
                if(!this.loadedLifeEventMap.has(item.id)){
                  this.loadedLifeEventMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedLifeEventMap)
                }
                break;
              }
              case CollectionName.DIVISION:{ 
                if(!this.loadedDivisionMap.has(item.id)){
                  this.loadedDivisionMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedDivisionMap)
                }
                break;
              }
              case CollectionName.JURISDICTION:{ 
                if(!this.loadedJurisdictionMap.has(item.id)){
                  this.loadedJurisdictionMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedJurisdictionMap)
                }
                break;
              }
              case CollectionName.CASE_USE:{ 
                if(!this.loadedCaseUseMap.has(item.id)){
                  this.loadedCaseUseMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedCaseUseMap)
                }
                break;
              }
              case CollectionName.INSTRUMENT:{ 
                if(!this.loadedInstrumentMap.has(item.id)){
                  this.loadedInstrumentMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedInstrumentMap)
                }
                break;
              }
              case CollectionName.WORKLIST_PROPOSED_REGULATIION:{
                if(!this.loadedInstrumentMap.has(item.id)){
                  this.loadedWorklistProposedMap.set(item.id, item);
                  this.referenceCollections.set(collection,this.loadedWorklistProposedMap)
                }
                break;
              }
            }
          }
        })
        // res.map((item)=>{
        //   ite
        // })
      }
    })
  }

  loadDataFromCollectionByID(collection:string,id:string){
    return firstValueFrom(this.afs.collection(collection).doc(id).get())
    .then((value)=>{
      if(value.exists){
        let item:any = value.data()
        item.id = value.id

        if(item._createdBy) delete item._createdBy
        if(item._updatedBy) delete item._updatedBy
        if(item.ref) delete item.ref

        switch(collection){
          case CollectionName.AGENCY:{ 
            if(!this.loadedAgencyMap.has(item.id)){
              this.loadedAgencyMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedAgencyMap)
            }
            break;
          }
          case CollectionName.SECTOR:{ 
            if(!this.loadedSectorMap.has(item.id)){
              this.loadedSectorMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedSectorMap)
            }
            break;
          }
          case CollectionName.STAGE_OF_BUSINESS:{ 
            if(!this.loadedStageOfBusinessMap.has(item.id)){
              this.loadedStageOfBusinessMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedStageOfBusinessMap)
            }
            break;
          }
          case CollectionName.STAGE_OF_LIFE:{ 
            if(!this.loadedStageofLifeMap.has(item.id)){
              this.loadedStageofLifeMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedStageofLifeMap)
            }
            break;
          }
          case CollectionName.LIFE_EVENT:{ 
            if(!this.loadedLifeEventMap.has(item.id)){
              this.loadedLifeEventMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedLifeEventMap)
            }
            break;
          }
          case CollectionName.DIVISION:{ 
            if(!this.loadedDivisionMap.has(item.id)){
              this.loadedDivisionMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedDivisionMap)
            }
            break;
          }
          case CollectionName.JURISDICTION:{ 
            if(!this.loadedJurisdictionMap.has(item.id)){
              this.loadedJurisdictionMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedJurisdictionMap)
            }
            break;
          }
          case CollectionName.CASE_USE:{ 
            if(!this.loadedCaseUseMap.has(item.id)){
              this.loadedCaseUseMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedCaseUseMap)
            }
            break;
          }
          case CollectionName.INSTRUMENT:{ 
            if(!this.loadedInstrumentMap.has(item.id)){
              this.loadedInstrumentMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedInstrumentMap)
            }
            break;
          }
          case CollectionName.WORKLIST_PROPOSED_REGULATIION:{
            if(!this.loadedInstrumentMap.has(item.id)){
              this.loadedWorklistProposedMap.set(item.id, item);
              this.referenceCollections.set(collection,this.loadedWorklistProposedMap)
            }
            break;
          }
        }
        return item
      } else return false
    })
    .catch((err)=>{
      console.error('Error getting document from '+collection+' with id: '+id,err)
      return false
    })
  }

  loadDataFromCollectionByWhere(collection:string,fieldName:string,operator:any,value:string){
    return this.afs.collection(collection).ref.where(fieldName,operator,value).get()
    .then((items)=>{
      let arr:any = []
      items.forEach((res)=>{
        if(res.exists){
          let item:any = res.data()
          item.id = res.id

          if(item._createdBy) delete item._createdBy
          if(item._updatedBy) delete item._updatedBy
          if(item.ref) delete item.ref

          switch(collection){
            case CollectionName.AGENCY:{ 
              if(!this.loadedAgencyMap.has(item.id)){
                this.loadedAgencyMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedAgencyMap)
              }
              break;
            }
            case CollectionName.SECTOR:{ 
              if(!this.loadedSectorMap.has(item.id)){
                this.loadedSectorMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedSectorMap)
              }
              break;
            }
            case CollectionName.STAGE_OF_BUSINESS:{ 
              if(!this.loadedStageOfBusinessMap.has(item.id)){
                this.loadedStageOfBusinessMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedStageOfBusinessMap)
              }
              break;
            }
            case CollectionName.STAGE_OF_LIFE:{ 
              if(!this.loadedStageofLifeMap.has(item.id)){
                this.loadedStageofLifeMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedStageofLifeMap)
              }
              break;
            }
            case CollectionName.LIFE_EVENT:{ 
              if(!this.loadedLifeEventMap.has(item.id)){
                this.loadedLifeEventMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedLifeEventMap)
              }
              break;
            }
            case CollectionName.DIVISION:{ 
              if(!this.loadedDivisionMap.has(item.id)){
                this.loadedDivisionMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedDivisionMap)
              }
              break;
            }
            case CollectionName.JURISDICTION:{ 
              if(!this.loadedJurisdictionMap.has(item.id)){
                this.loadedJurisdictionMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedJurisdictionMap)
              }
              break;
            }
            case CollectionName.CASE_USE:{ 
              if(!this.loadedCaseUseMap.has(item.id)){
                this.loadedCaseUseMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedCaseUseMap)
              }
              break;
            }
            case CollectionName.INSTRUMENT:{ 
              if(!this.loadedInstrumentMap.has(item.id)){
                this.loadedInstrumentMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedInstrumentMap)
              }
              break;
            }
            case CollectionName.WORKLIST_PROPOSED_REGULATIION:{
              if(!this.loadedInstrumentMap.has(item.id)){
                this.loadedWorklistProposedMap.set(item.id, item);
                this.referenceCollections.set(collection,this.loadedWorklistProposedMap)
              }
              break;
            }
          }

          arr.push(item)
        }
      })
      return arr
    })
    .catch((err)=>{
      console.error('Error getting document from '+collection+' with '+fieldName+' '+operator+' '+value,err)
      return false
    })
  }


  /*
  loadAgencies() {
    this.afs.collection(`Agency`)
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          // this.agencies = []
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if(item._createdBy) delete item._createdBy
            if(item._updatedBy) delete item._updatedBy
            if(item.ref) delete item.ref

            if (this.loadedAgencyMap.has(item.id)) this.loadedAgencyMap.set(item.id,item);
          });
        }
      ),
      take(1)
    )
    .subscribe(() => {
      this.referenceCollections.set(`Agency`, this.agencies)
    });
  }

  loadSectors() {
    this.afs.collection(`Sector`, filter => filter.orderBy('section'))
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.sectors = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.sectors.includes(item) === false) {
              this.sectors.push(item);
            }
          });
        }
      ),
      take(1)
    )
    .subscribe(() => {
      this.referenceCollections.set(`Sector`, this.sectors)
    });
  }

  loadSobs() {
    this.afs.collection(`Stage of Business`, filter => filter.orderBy('section'))
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.sobs = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.sobs.includes(item) === false) {
              this.sobs.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Stage of Business`, this.sobs)
    });
  }

  loadSols() {
    this.afs.collection(`Stage of Life`)
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.sols = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.sols.includes(item) === false) {
              this.sols.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Stage of Life`, this.sols)
    });
  }

  loadLifeEvents() {
    this.afs.collection(`Life Event`)
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.lifeevents = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.lifeevents.includes(item) === false) {
              this.lifeevents.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Life Event`, this.lifeevents)
    });
  }

  loadDivisions() {
    this.afs.collection(`Division`, filter => filter.orderBy('section'))
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.divisions = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.divisions.includes(item) === false) {
              this.divisions.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Division`, this.divisions)
    });
  }

  loadJuris() {
    this.afs.collection(`Jurisdiction`, filter => filter.orderBy('section'))
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.jurisdictions = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.jurisdictions.includes(item) === false) {
              this.jurisdictions.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Jurisdiction`, this.jurisdictions)
    });
  }

  loadCases() {
    this.afs.collection(`Case Use`, filter => filter.orderBy('section'))
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.cases = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.cases.includes(item) === false) {
              this.cases.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Case Use`, this.cases)
    });
  }

  loadInstruments() {
    this.afs.collection(`Instrument`, filter => filter.orderBy('section'))
    .snapshotChanges()
    .pipe(
      tap(
        (data: any) => {
          this.instruments = []
          data.forEach((info: any) => {
  
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;
  
            if (this.instruments.includes(item) === false) {
              this.instruments.push(item);
            }
          });
        }
      ),
      take(1)
    ).subscribe(() => {
      this.referenceCollections.set(`Instrument`, this.instruments)
    });
  }
  // */

  toSendNotifEmail(shortTitle:any){
    console.log('sending email')
    let templateCode:number = 0;
    let data:any = {}
    // console.log('setting: ',this.currentUser.settings)
    if(this.currentUser.settings && this.currentUser.settings.regulations.review == true){
      // console.log('setting: ',this.currentUser.credentials)
      if(this.currentUser.credentials.account_type == 'Personal' || this.currentUser.credentials.account_type == 'Company' || this.currentUser.credentials.account_type == 'Government'){
        templateCode = 2;
        data.newSubscriptions = [{name:shortTitle,ctr:0}]
        data.link = 'https://arta-respond.web.app/account/'+this.currentUser.credentials.uid;
      }
      if(templateCode > 0){
        // this.nE.sendEmailNotiftoUser([this.currentUser.credentials.email],1,templateCode,data)
        // .subscribe({
        //     next:(apiResponse)=>{
        //         console.log('RegulationsResultComponent emailer: ',apiResponse)
        //     },
        //     error:(err)=>{
        //         console.error('RegulationsResultComponent emailer error: ',err)
        //     }
        // })
      } else console.error('RegulationsResultComponent emailer error: no email template for account type')
    }
    else console.warn('User did not set notification from regulation to true')
  }
}
