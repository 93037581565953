import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-uploaded-files',
  templateUrl: './uploaded-files.component.html',
  styleUrls: ['./uploaded-files.component.scss']
})
export class UploadedFilesComponent implements OnInit {
  entries: any[] = []
  id: any;
  gov_url: any;
  com_url: any;
  auth_letter_url: any;

  constructor(private route: ActivatedRoute, private afs: AngularFirestore) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;

    this.afs.doc(`Users/${this.id}`).get().subscribe(user => {
      this.gov_url = user.get('file.government_id')
      this.com_url = user.get('file.company_id')
      this.auth_letter_url = user.get('file.authorization_letter')
    });

  }

}
