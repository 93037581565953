
<div class="d-flex justify-content-center align-self-center">
  <div class="spinner-grow" role="status" *ngIf="!isLoaded">
      <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div class="row"  *ngIf="isLoaded">
    <div class="row header">         
        <div class="col-lg-9 d-flex align-items-end  ">
          <mat-form-field appearance="outline" class="pt-3 w-100">
            <mat-label style="font-size: 1rem;"><i class="fas fa-search"></i> Search...</mat-label>
            <input matInput placeholder="Ex. Mia" 
            (keyup)="search($event)"
            #input>
        </mat-form-field>
      </div>
        <div class="col-lg-3">
          <div class="d-flex justify-content-center p-2">     
            <img
            [src]="getImage(agency_details)"
            class="img-fluid m-0 align-self-center card"
            style="width: 220px; height: 220px; object-fit: cover;"            >
          </div>
        </div>
    </div>
    
    <div class="row body"> 
        <div class="row item-list">
            <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="table table-hover"
            [hidden]="!temp_services"
          >
            <thead hidden>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
                <!-- Table Item -->
                <!-- <tr
                  *ngFor="let entry of services"
                  (click)="viewCitizensCharter(entry.id)"
                  class="charter-row"
                > -->
                <tr
                  *ngFor="let entry of temp_services"
                  (click)="viewService(entry.id)"
                  class="charter-row"
                >
                  <td>
                    <p>
                      <i class="mx-2" *ngIf="entry.serviceDetails.service_name"
                        ><strong class="fw-bold"> Name of Service:</strong>
                          {{entry.serviceDetails.service_name }}</i
                      >
                    </p>
                    <p>
                      <i class="mx-2" *ngIf="entry.serviceDetails.service_category"
                        ><strong class="fw-bold">Category</strong>
                        {{ entry.serviceDetails.service_category }}</i
                      >
                    </p>
                    <p>
                      <i class="mx-2" *ngIf="entry.serviceDetails.service_description"
                        ><strong class="fw-bold"> Description</strong>
                        <p [innerHtml]="entry.serviceDetails.service_description"></p></i
                      >
                    </p>
                    <p>
                      <i class="mx-2" *ngIf="entry.agency_name"
                        ><strong class="fw-bold">Agency:</strong>
                        {{ entry.agency_name }}</i
                      >
                    </p>
                    <!-- <p>
                      <i class="mx-2" *ngIf="entry.tags"
                        ><strong class="fw-bold">Tags:</strong>
                        {{ entry.tags }}</i
                      >
                    </p> -->
                  </td>
                </tr>
              </tbody>
          </table>
        </div>
    </div>

</div>
