import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restricted-access',
  templateUrl: './restricted-access.component.html',
  styleUrls: ['./restricted-access.component.scss']
})
export class RestrictedAccessComponent implements OnInit {

  constructor(public auth: AngularFireAuth, private router: Router) { }

  ngOnInit(): void {
    
  }

  homepage(){
    this.router.navigate(['/'])
  }

  logout() {
    this.auth.signOut().then(() => {
      this.homepage()
    });
  }

}
