<mat-dialog-content *ngIf="deleteWarning">
  <div class="bg-dark">
    <div class="m-4 row">
      <div class="col-4">
        <img
          src="/assets/Warning.svg"
          class="image-fluid"
          alt="Warning"
          style="height: 70px"
        />
      </div>
      <div class="col-8 align-self-center">
        <h1 class="text-warning">WARNING</h1>
      </div>
    </div>
    <div class="m-4 row">
      <b class="text-white">
        You will lose all encoded service data! Do you wish to proceed with deleting this service?
      </b>
    </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3">
        <button
          (click)="submit(enum_action_flag.HARD_DELETE_SERVICE)"
          class="btn btn-warning m-2"
        >
          Proceed
        </button>
      </div>
      <div class="col-3">
        <button
          (click)="cancel()"
          class="btn btn-dark border border-white m-2"
        >
          Cancel
        </button>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="finalWarning">
  <div class="bg-dark">
    <div class="m-4 row">
      <div class="col-4">
        <img
          src="/assets/Warning.svg"
          class="image-fluid"
          alt="Warning"
          style="height: 70px"
        />
      </div>
      <div class="col-8 align-self-center">
        <h1 class="text-warning">IMPORTANT</h1>
      </div>
    </div>
    <div class="m-4 row">
      <b class="text-white">
        By submitting, you certify that all necessary corrections/modifications
        raised by ARTA have been addressed. Furthermore, you also certify that
        all texts and information included are consistent with the text of the
        existing regulation. Any inaccuracy and/or misinterpretation of facts is
        punishable by existing Civil Service Commission Rules on Administrative
        Cases in the Civil Service, and other relevant laws, rules, and
        regulations.
      </b>
    </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3">
        <button
          (click)="submit(enum_action_flag.POST_CITIZENS_CHARTER)"
          class="btn btn-warning m-2"
        >
          Proceed
        </button>
      </div>
      <div class="col-3">
        <button
          (click)="cancel()"
          class="btn btn-dark border border-white m-2"
        >
          Cancel
        </button>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="verifiedWithRemarksWarning">
  <div class="bg-dark">
    <div class="m-4 row">
      <div class="col-4">
        <img
          src="/assets/Warning.svg"
          class="image-fluid"
          alt="Warning"
          style="height: 70px"
        />
      </div>
      <div class="col-8 align-self-center">
        <h1 class="text-warning">WARNING</h1>
      </div>
    </div>
    <div class="m-4 row">
      <b class="text-white">
        Remarks indicate that a service has problems that need to be resolved! Do you wish to proceed with verifying this service?
      </b>
    </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3">
        <button
          (click)="submit(enum_action_flag.TAG_AS_VERIFIED)"
          class="btn btn-warning m-2"
        >
          Proceed
        </button>
      </div>
      <div class="col-3">
        <button
          (click)="cancel()"
          class="btn btn-dark border border-white m-2"
        >
          Cancel
        </button>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</mat-dialog-content>