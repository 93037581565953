<!-- Sectors -->
<form [formGroup]="form">
    <div class="card shadow-lg bg-light m-3">
        <div class="card-header">
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" value="The Philippine RMS" formControlName="title1" class="form-control">
            </div>
        </div>
        <div class="card-body">
            <!--privacy policy-->
            <ng-container>
                <div>

                    <div class="form-group">
                        <label for="title">Link URL</label>
                        <input type="url" formControlName="url1" class="form-control" placeholder="must start with http:// or https://">

                    </div>
                </div>

            </ng-container>
        </div>
    </div>

    <div class="card shadow-lg bg-light m-3">
        <div class="card-header">
            <div class="form-group">
                <label for="title">Title</label>
                <input type="text" value="National Regulatory Agenda" formControlName="title2" class="form-control">
            </div>
        </div>
        <div class="card-body">
            <!--privacy policy-->
            <ng-container>
                <div>

                    <div class="form-group">
                        <label for="title">Link URL</label>
                        <input type="url" formControlName="url2" class="form-control" placeholder="must start with http:// or https://">

                    </div>
                </div>

            </ng-container>
        </div>
    </div>

    <div class="card shadow-lg bg-light m-3">
        <div class="card-header">
            <div class="form-group">
                <label for="title3">Title</label>
                <input type="text" value="Regulatory Impact Statements" formControlName="title3" class="form-control">
            </div>
        </div>
        <div class="card-body">
            <!--privacy policy-->
            <ng-container>
                <div>

                    <div class="form-group">
                        <label for="title">Link URL</label>
                        <input type="url" formControlName="url3" class="form-control" placeholder="must start with http:// or https://">

                    </div>
                </div>

            </ng-container>
        </div>
    </div>
    <div class="card shadow-lg bg-light m-3">
        <div class="card-header">
            <div class="form-group">
                <label for="title4">Title</label>
                <input type="text" value="Philippine Business Portal" formControlName="title4" class="form-control">
            </div>
        </div>
        <div class="card-body">
            <!--privacy policy-->
            <ng-container>
                <div>

                    <div class="form-group">
                        <label for="title">Link URL</label>
                        <input type="url" formControlName="url4" class="form-control" placeholder="must start with http:// or https://">
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
    <button class="btn btn-primary float-end m-3" (click)='update()'>Update</button>
</form>