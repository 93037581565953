<!--NAVBAR-->
<app-artemis-navigation-header
  [currentPage]="'Help'"
></app-artemis-navigation-header>

<div class="content">
  <div class="container">
    <!-- TABLE OF CONTENTS -->
    <div class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h2 class="fw-bold">TABLE OF CONTENTS</h2>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <ul type="none">
          <li><a [routerLink]="[]" fragment="Help">Help</a></li>
          <li>
            <a [routerLink]="[]" fragment="WhatCanIDoWithARTEMIS"
              >What can I do with ARTEMIS?</a
            >
          </li>
          <li><a [routerLink]="[]" fragment="ContactUs">Contact Us</a></li>
          <li>
            <a
              [routerLink]="[]"
              fragment="ARTEMISInitialFrequentlyAnsweredQuestions"
              >ARTEMIS Initial Frequently Answered Questions</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- ARTEMIS -->
    <div id="Help" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h5 class="fw-bold">
          THE ANTI-RED TAPE ELECTRONIC MANAGEMENT INFORMATION SYSTEM
        </h5>
        <h2 class="fw-bold">HELP</h2>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <div class="text-center m-3">
          <iframe
            width="560"
            height="315"
            class="shadow"
            src="https://www.youtube.com/embed/96Lu37Amu_8?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <p class="m-3">
          To establish commitment between the government and the transacting
          public, as well as transparency in the delivery of government
          services, Section 6 of Republic Act 11032 mandates the setting up of
          the most current and updated Citizens Charter of agencies. The
          Citizens Charter contains service level agreements for each government
          service in the form of information billboards and other published
          materials available at the government agency’s offices or at their
          respective websites. With this, the Authority with UPPAF-RESPOND,
          spearheaded the development of the Anti-Red Tape Electronic Management
          Information System (ARTEMIS) to facilitate efficient submission,
          evaluation, and monitoring of all Citizen’s Charter
        </p>

        <p class="m-3">
          The “ARTEMIS” is a web-based platform that will expedite both the
          submission and evaluation of Citizen’s Charter yielding a real-time on
          demand database and mapping of all government services. Last year, the
          Authority has conducted 30 Working Sessions attended by numerous
          agencies to develop the Functional Specifications that the developers
          will use for the creation of the system. Through the ARTEMIS, agencies
          will be able to easily submit and update their Citizens’ Charter while
          simultaneously notifying ARTA. The system will also enable citizens to
          view, download, and comment on submitted Citizen’s Charters and view
          they system developed process flows of specific services. ARTEMIS
          thereby provides an efficient monitoring mechanism for ARTA-CMEO, an
          uploading and updating facility for government agencies, and a useful
          information and transparency tool to our citizens.
        </p>
      </div>
    </div>

    <!-- What I can do-->
    <div id="WhatCanIDoWithARTEMIS" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h3 class="fw-bold">WHAT CAN I DO WITH ARTEMIS?</h3>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <p class="m-3">
          Through a responsive categorization of services, the search for
          government services is made easier and faster with ARTEMIS. The system
          contains features such as account creation to provide a more
          tailor-fit experience to its users. Available functionalities of
          ARTEMIS that users can utilize include:
        </p>

        <ul class="mx-5">
          <li>
            <p>
              Library of Citizen’s Charters submission by the government
              agencies
            </p>
          </li>
          <li>
            <p>Downloading of the Citizen’s Charter</p>
          </li>
          <li>
            <p>
              Subscription and notifications on the services/agencies specific
              to their concerns
            </p>
          </li>
          <li>
            <p>Commenting feature for services availed</p>
          </li>
        </ul>
      </div>
    </div>

    <!-- Contact Us -->
    <div id="ContactUs" class="card shadow my-5">
      <div class="card-header text-primary theme-default">
        <h3 class="fw-bold">Contact Us</h3>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <p class="m-3">
          The Anti-Red Tape Authority (ARTA) is the lead agency that will
          oversee the operation of the ARTEMIS and the implementation of the
          Citizen’s Charter
        </p>
        <hr />
        <p class="m-3">
          The <strong>Deputy Director General for Operations</strong> oversees
          matters pertaining to regulations which includes regulatory management
          and regulatory impact analysis. He directly leads the Better
          Regulations Office and its divisions in its mission of
          institutionalizing good regulatory practices in the Philippine
          Government.
        </p>
        <p class="m-3">
          <strong>Atty. Ernesto V. Perez, CPA</strong> serves as the incumbent
          Deputy Director General for Operations. For any concerns you may reach
          his office through:
          <a href="mailto: oddgoperations@arta.gov.ph "
            >oddgoperations@arta.gov.ph
          </a>
        </p>
        <hr />

        <strong>Compliance Monitoring and Evaluation Office (CMEO)</strong>
        <p class="m-3">
          The
          <strong>Compliance Monitoring and Evaluation Office</strong> conducts
          the monitoring of government agencies and LGUs with their compliance
          with the provisions of RA 11032, including the Citizen’s Charter
          requirement, streamlining efforts, report card survey, etc.
        </p>
        <p class="m-3">
          <strong>Ms. Grace L. Fernandez</strong>, serves as the Director for
          the Compliance Monitoring and Evaluation Office. For any concerns you
          may reach her office through:
          <a href="mailto: compliance@arta.gov.ph">compliance@arta.gov.ph</a>
        </p>
      </div>
    </div>

    <!-- FAQs -->
    <div
      id="ARTEMISInitialFrequentlyAnsweredQuestions"
      class="card shadow my-5"
    >
      <div class="card-header text-primary theme-default">
        <h3 class="fw-bold">
          ARTEMIS Initial Frequently Answered Questions
        </h3>
      </div>
      <div class="card-body p-3 bg-gray rights-container">
        <strong> Main Body: </strong>
        <p class="m-3">
          The ARTEMIS is conceptualized to facilitate the submission of
          Citizen’s Charters yielding a real-time on-demand database and mapping
          of all government services. Through the ARTEMIS, agencies will be able
          to easily submit and update their Citizens’
          Charter while simultaneously notifying ARTA. The system will also
          enable citizens to view, download, and comment on submitted Citizen’s
          Charters and view they system developed process flows of specific
          services. ARTEMIS thereby provides an efficient monitoring mechanism
          for ARTA-CMEO, an uploading and updating facility for government
          agencies, and a useful information and transparency tool to our
          citizens.
        </p>
        <p class="m-3">
          For more information, please see below the Frequent Answers to
          Questions grouped according to subject:
        </p>

        <strong class="text-primary">Citizen’s Charter</strong>
        <ol class="m-3">
          <li>
            What is a Citizen’s Charter?
            <p>
              The Citizen’s Charter is one of the primary tools that government
              agencies use to communicate their service standards on the
              delivery of government services to their citizens or clients.
              According to RA 11032, a Citizen’s Charter is defined as an
              official document, a service standard, that communicates, in
              simple terms, information on the services provided by the
              government to its citizens pursuant to Section 6 of RA 11032.
            </p>
            <p>
              The Citizen’s Charter handbook contains information on (a) the
              agency profile, (b) list of government services offered by the
              agency, (c) service specifications such as (i) service name and
              description, (ii) classification of service, (iii) type of
              transaction, (iv) who may avail, (v) checklist of requirements,
              (vi) steps and procedures, (vii) fees to be paid, (viii)
              processing time, and (ix) person responsible per step.
            </p>
            <p>
              You may access the Reference B – Citizen’s Charter Handbook
              Template for reference in formulating a Citizen’s Charter with
              this link:
              <a href="http://bit.ly/ReferenceB-CCTemplate"
                >http://bit.ly/ReferenceB-CCTemplate</a
              >
            </p>
          </li>

          <li>
            Who is covered by the Citizen’s Charter requirement?
            <p>
              The Citizen’s Charter requirement shall apply to all government
              offices and agencies that provide services covering
              business-related and nonbusiness transactions as defined in these
              rules (Section 1, Rule II of the IRR of RA 11032) This means that
              all government agencies under the executive branch of the
              government are covered. Namely LGUs, GOCCs and other government
              instrumentalities, whether located in the Philippines or abroad,
              that provide services covering business-related and non-business
              transactions as defined in the IRR.
            </p>
          </li>

          <li>
            How do government agencies comply with the Citizen’s Charter
            Requirement using the ARTEMIS?
            <p>
              Government agencies can comply with the Citizen’s Charter
              requirement if they encode all their services using the Citizen’s
              Charter template in the ARTEMIS, making sure that the services are
              compliant with the provisions of RA 11032 (e.x. no services
              exceeding the processing time, etc.). This is followed by the
              Agency Head sending the Citizen’s Charter with an accomplished
              Certificate of Compliance, vouching that the submitted Citizen’s
              Charter is compliant with the law and complete.
            </p>
          </li>

          <li>
            Are there any restrictions in the Citizen’s Charter template?
            <p>
              Yes, there are certain fields that can only accept text and number
              (e.x. fees, person responsible per step, etc.) The submitted
              Citizen’s Charter will also undergo evaluation to validate the
              information indicated in the document. This is to ensure
              uniformity of the Citizen’s Charter among government agencies.
            </p>
          </li>

          <li>
            When is the deadline in submitting the Citizen’s Charter?
            <p>
              As per the law, the deadline of the Citizen’s Charter is on March
              31 of every year (RA 11032) unless otherwise stated through an
              official issuance from the Authority.
            </p>
          </li>
        </ol>
        <hr />

        <strong>For All:</strong>
        <p class="m-3">What is ARTEMIS?</p>

        <p class="m-3">
          The ARTEMIS is conceptualized to facilitate the submission of
          Citizen’s Charters yielding a real-time on-demand database and mapping
          of all government services. Through the ARTEMIS, agencies will be able
          to easily submit and update their Citizens’
          Charter while simultaneously notifying ARTA. The system will also
          enable citizens to view, download, and comment on submitted Citizen’s
          Charters and view they system developed process flows of specific
          services. ARTEMIS thereby provides an efficient monitoring mechanism
          for ARTA-CMEO, an uploading and updating facility for government
          agencies, and a useful information and transparency tool to our
          citizens.
        </p>

        <p class="m-3">Who is ARTEMIS for?</p>
        <p class="m-3">
          ARTEMIS is open for everyone, however, the system offers 3 different
          types of account with different access to functionalities.
        </p>
        <ol class="m-3">
          <li>
            <i>The general public or the clients</i>
            <p>
              May avail functions such as quick search/advance search which
              enables them to easily avail information on the services they
              need, the commenting function to provide feedback on the quality
              of service received, and the notification function wherein clients
              are updated in their account should their selected agency release
              an update on their services.
            </p>
          </li>

          <li>
            <i>The government agencies</i>
            <p>
              May also avail features such as encoding and uploading of the
              Citizen’s Charter through the use of the ARTEMIS Citizen’s Charter
              uploading form, accountability feature to easily track changes and
              developments on the Citizen’s Charter’s submitted, automated
              process flow feature to easily visualize services and their
              process flow wherein multiple agencies are involved.
            </p>
          </li>

          <li>
            <i>The Authority</i>
            <p>
              May also avail features such as automated evaluation of the
              Citizen’s Charters received, automated collection of data on the
              user’s age, gender, etc. for the identification of demographics,
              report generation feature, and worklist management for the
              Authority.
            </p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
