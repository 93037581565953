
<div class="d-flex justify-content-center align-self-center">
    <div class="spinner-grow" role="status" *ngIf="!isLoaded">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="isLoaded">
    <div class="row" *ngFor="let row of details" >
        <h3 class="profileHeader w-100">{{row.name}}</h3>
        <div>
            <p>{{agency_details.agencyFeedBackMeasures[row.value]}}</p>
        </div>
    </div>
</div>