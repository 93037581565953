import { Component, Inject, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Timestamp } from 'firebase/firestore';
import { take } from 'rxjs/operators';
import { PdfRenderService } from 'src/app/pdf-render.service';

@Component({
  selector: 'app-artemis-coc-preview-page-arta',
  templateUrl: './artemis-coc-preview-page-arta.component.html',
  styleUrls: ['./artemis-coc-preview-page-arta.component.scss']
})
export class ArtemisCocPreviewPageArtaComponent implements OnInit {

  fileloading:boolean =true;
  file:any = {
    path: "",
    file_type:""
  };
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ArtemisCocPreviewPageArtaComponent>,private pdf_render: PdfRenderService,private afs: AngularFirestore,private store: AngularFireStorage) { }

  ngOnInit(): void {

    if(this.data.file){
      this.file.path =this.data.file;
      this.file.file_type = this.data.file_type;
      return;
    }else{
      this.file.file_type = this.data.file_type;
    }
    this.loadLatestcertificateofcompliance()
  }


  //open url in new window
  openfileinnewwindow(data:any){
    window.open(data.path,"_blank")
  }


  //load latest COC submitted
  loadLatestcertificateofcompliance(){
    
    this.afs.collection('Certificate of Compliance Submissions',
    ref => ref.where('citizensCharter_Id','==',this.data.charter_id)
    .where('status','==','Submitted')
    .orderBy('createdAt','desc')
    .limit(1))
    .snapshotChanges()
    .subscribe(
      {
        next: (resultArr) => {
          resultArr.map((info) => {
            if (info.payload.doc.exists) {
              let coc:any = info.payload.doc.data()
              coc.id = info.payload.doc.id;
              let path:any = coc.cocurl
              this.file.path = path;
            }
          })

        }

      }
    )
    
  }

}
