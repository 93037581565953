import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
selector: 'app-proposed-regulations-details-view',
templateUrl: './proposed-regulations-details-view.component.html',
styleUrls: ['./proposed-regulations-details-view.component.scss']
})
export class ProposedRegulationsDetailsViewComponent implements OnInit, OnChanges {

  @Input() objectData:any;
  @Input() baseCode:string = '';
  // @Input() consultationsData:any;
  consultationsData: Array<any> = [];
  agencyOption:string;
  // agency_item:any;
  consultationTableDisplayCol: string[] = ['rowId'];
  consultationTabledataSource: any;
  crisLifeStatus:any;
  crisUploads:any= []

  consultations_file:any;
  regulationsData:any = {};
  
  constructor(
      private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    if(this.objectData){
      this.regulationsData = this.objectData
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.objectData.currentValue){
      this.regulationsData = changes.objectData.currentValue
    }
  } 

  // load cris data
  // async checkData(){
  //   this.regulationsData.instrument_name = await this.dataLoaded('Instrument',this.regulationsData.reg_instrument)

  //   if(this.regulationsData.reg_classification ==  'Business'){
  //     this.regulationsData.sector_name = await this.dataLoaded('Sector',this.regulationsData.reg_sector)
  //     if(this.regulationsData.sector_name) this.crisLifeStatus = this.regulationsData.sector_name
  //     this.regulationsData.business_name = await this.dataLoaded('Stage of Business',this.regulationsData.reg_business)
  //     this.regulationsData.division_name = await this.dataLoaded('Division',this.regulationsData.reg_division)
  //     this.regulationsData.case_name = await this.dataLoaded('Case Use',this.regulationsData.reg_case)
  //   }else if(this.regulationsData.reg_classification ==  'Non-Business'){
  //     this.regulationsData.sol_name = await this.dataLoaded('Stage of Life',this.regulationsData.reg_stageoflife)
  //     if(this.regulationsData.sol_name) this.crisLifeStatus = this.regulationsData.sol_name
  //     this.regulationsData.event_name = await this.dataLoaded('Life Event',this.regulationsData.reg_lifeevent)
  //   }

  //   this.regulationsData.juris_name = await this.dataLoaded('Jurisdiction',this.regulationsData.reg_juris)

  //   if(this.regulationsData.reg_agency) this.checkAgency(this.regulationsData.reg_agency)
  // }

  // async checkAgency(reg_agency:any){
  //   if(reg_agency){
  //     // load data first
  //     if(typeof reg_agency == 'string'){
  //       // this.agencyOption = 'single'
  //       // this.agency_item = reg_agency
  //       const singleAgencyData:any = await this.loadData('Agency',reg_agency)
  //       if(singleAgencyData){
  //         this.agencyOption = 'single'
  //         // this.agency_item = singleAgencyData ? singleAgencyData.agencyDetails ? singleAgencyData.agencyDetails.agencyName ? singleAgencyData.agencyDetails.agencyName : : : (singleAgencyData.name ? singleAgencyData.namesingleAgencyData.name : "Cannot get Agency")
  //         if(singleAgencyData){
  //           this.regulationsData.agency_item = this.agencyNaming1(singleAgencyData) 
  //         }else this.regulationsData.agency_item = this.agencyNaming2(singleAgencyData) 
  //       }
  //     }else if(Array.isArray(reg_agency)){
  //       this.agencyOption = 'multi'
  //       // this.agency_item = reg_agency
  //       const multiAgencyData:any = await this.multipleLoadData('Agency',reg_agency)
  //       if(multiAgencyData){
  //         this.regulationsData.agency_item = []
  //         multiAgencyData.map((singleAgencyData:any)=>{
  //           this.regulationsData.agency_item.push(this.agencyNaming1(singleAgencyData))
  //         })
  //       } else this.regulationsData.agency_item.push(this.agencyNaming2(null));
  //     }
  //   }
  // }

  // agencyNaming1(singleAgencyData:any){
  //   if(singleAgencyData.agencyDetails){
  //     if(singleAgencyData.agencyDetails.agencyName){
  //       return singleAgencyData.agencyDetails.agencyName
  //     }else return this.agencyNaming2(singleAgencyData)
  //   } else return this.agencyNaming2(singleAgencyData)
  // }

  // agencyNaming2(singleAgencyData:any){
  //   if(singleAgencyData.name){
  //     return singleAgencyData.name
  //   }else return "Cannot get Agency"
  // }

  // multipleLoadData(collection:any, arrValue:any){
  //   let tempArr:any = []
  //   return Promise.all(
  //     arrValue.map(async (data:any)=>{
  //       const item:any = await this.loadData(collection,data)
  //       if(item){
  //         if(!tempArr.map((item:any)=>item.id).includes(item.id)) tempArr.push(item)
  //       }
  //       return true
  //     })
  //   ).then(()=>tempArr)
  // }

  // async dataLoaded(collection:any, value:any){
  //   if(collection && value){
  //     const loadedData:any = await this.loadData(collection,value)
  //     if(loadedData){
  //       // if(collection == 'Sector' || collection == 'Stage of Life') this.crisLifeStatus = loadedData
  //       return loadedData.name
  //     }else return ""
  //   }else return ""
  // }

  // loadData(collection:any, value:any){
  //   console.log('collection: ',collection)
  //   console.log('value: ',value)
  //   return new Promise((res,rej)=>{
  //     this.afs.collection(collection).doc(value).snapshotChanges()
  //     .subscribe({
  //       next:(data)=>{  
  //         if(data.payload.exists){
  //           let item:any = data.payload.data()
  //           item.id = data.payload.id
  //           return res(item)
  //         } else return res(false)
  //       },
  //       error:(err)=>{
  //         console.error('loadData Error: ',err)
  //         return res(false)
  //       }
  //     })
  //   })
  // }
}
