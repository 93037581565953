<!-- <app-wel-navbar></app-wel-navbar> -->
<!--<app-artemis-navigation-header *ngIf="currentSystem == artemis"></app-artemis-navigation-header>-->
<app-navbar *ngIf="currentSystem == pbris"></app-navbar>

<div class="content">
    <div class="p-4">
        <!-- <div class="container"> -->
            <div class="card">
                <h3 class="card-header">Users</h3>
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-5">
                            <div style="padding:0.5em;">
                                <form [formGroup]="filtersForm">
                                    <div class="row info-search sticky sticky-search form-group">
                                        <div class="col-2" style="display:flex; align-items: center; justify-content:center;">
                                            <label>Search</label>
                                        </div>
                                        <div class="col">
                                            <input class="full-width form-control" (keyup)="search($event)" formControlName="search" type="text">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div>
                                <table mat-table
                                    class="table table-hover table-responsive"
                                    id="matTable"
                                    [dataSource]="userDataSource"
                                    matSort
                                    (matSortChange)="sortChange($event)">

                                    <ng-container matColumnDef="first_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>User Name</h5>  </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="d-flex align-items-center" style="padding:0.3em;">
                                                <p>{{(element.personal.first_name || element.personal.last_name) ? (element.personal.first_name+" "+element.personal.last_name) : "N/A"}}</p>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Email</h5>  </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="d-flex align-items-center" style="padding:0.3em;">
                                                <p>{{element.credentials.email}}</p>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="account_type">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Account Type</h5>  </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="d-flex align-items-center" style="padding:0.3em;">
                                                <p>{{element.credentials.account_type}}</p>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="userDataSourceColumns; sticky: true"></tr>
                                    <tr mat-row matRipple (click)="userChosen(row)" style="cursor:pointer" *matRowDef="let row; columns: userDataSourceColumns;"></tr>
                                </table>
                                <mat-paginator pageSize="15" aria-label="Select page of users"></mat-paginator>
                            </div>
                        </div>
                        <div class="col" style="max-height:909px;">
                            <div class="card" style="padding:2em;">
                                <div class="row">
                                    <div class="col-2">ID</div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <ng-container  *ngIf="chosenUser">
                                            <p>{{ (chosenUser.id) ? (chosenUser.id) : "N/A" }}</p>
                                        </ng-container>
                                        <ng-container  *ngIf="!chosenUser">
                                            <p>Choose a User</p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-2">Name</div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <ng-container  *ngIf="chosenUser">
                                            <p>{{ (chosenUser.personal.first_name || chosenUser.personal.last_name) ? (chosenUser.personal.first_name+" "+chosenUser.personal.last_name) : "N/A" }}</p>
                                        </ng-container>
                                        <ng-container  *ngIf="!chosenUser">
                                            <p>Choose a User</p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-2">Email</div>
                                    <div class="col-1">:</div>
                                    <div class="col">
                                        <ng-container  *ngIf="chosenUser">
                                            <p>{{chosenUser.credentials.email}}</p>
                                        </ng-container>
                                        <ng-container  *ngIf="!chosenUser">
                                            <p>Choose a User</p>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">

                                        <!-- tab navs -->
                                        <ul class="nav nav-tabs nav-fill mb-3" id="myTab0" role="tablist">
                                            
                                            <li class="nav-item" role="presentation">
                                                <button
                                                class="nav-link active"
                                                id="personal-tab"
                                                data-mdb-toggle="tab"
                                                data-mdb-target="#personal"
                                                type="button"
                                                role="tab"
                                                aria-controls="personal"
                                                aria-selected="true"
                                                (click)="cancelAllForms()"
                                                > Personal</button>    
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <button
                                                class="nav-link"
                                                id="credential-tab"
                                                data-mdb-toggle="tab"
                                                data-mdb-target="#credential"
                                                type="button"
                                                role="tab"
                                                aria-controls="credential"
                                                aria-selected="false"
                                                (click)="cancelAllForms()"
                                                >Credential</button>
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <button
                                                class="nav-link"
                                                id="government-tab"
                                                data-mdb-toggle="tab"
                                                data-mdb-target="#government"
                                                type="button"
                                                role="tab"
                                                aria-controls="government"
                                                aria-selected="false"
                                                (click)="cancelAllForms()"
                                                >Government</button>    
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <button
                                                class="nav-link"
                                                id="company-tab"
                                                data-mdb-toggle="tab"
                                                data-mdb-target="#company"
                                                type="button"
                                                role="tab"
                                                aria-controls="company"
                                                aria-selected="false"
                                                (click)="cancelAllForms()"
                                                >Company</button>
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <button
                                                class="nav-link"
                                                id="file-tab"
                                                data-mdb-toggle="tab"
                                                data-mdb-target="#file"
                                                type="button"
                                                role="tab"
                                                aria-controls="file"
                                                aria-selected="false"
                                                (click)="cancelAllForms()"
                                                >File</button>
                                            </li>

                                            <li class="nav-item" role="presentation">
                                                <button
                                                class="nav-link"
                                                id="settings-tab"
                                                data-mdb-toggle="tab"
                                                data-mdb-target="#settings"
                                                type="button"
                                                role="tab"
                                                aria-controls="settings"
                                                aria-selected="false"
                                                (click)="cancelAllForms()"
                                                >Settings</button>
                                            </li>
                                        </ul>
                                        <!-- tab navs end -->

                                        <!-- tab-content -->
                                        <div class="tab-content" id="userDetails" style="max-height: 662px !important; overflow: auto;">

                                            <!-- personal -->
                                            <div class="tab-pane fade show active" id="personal" role="tabpanel" aria-labelledby="personal-tab">
                                                <div style="padding:1em;">
                                                    <form [formGroup]="personalForm">

                                                        <!-- name -->
                                                        <div class="row input-row">
                                                            <div class="col-2">Name</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="personalEditForm">
                                                                    <p>{{(personalForm.value.first_name || personalForm.value.last_name) ? (personalForm.value.first_name+" "+personalForm.value.last_name) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!personalEditForm">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <input type="text" class="form-control" formControlName="first_name" placeholder="First Name">
                                                                        </div>
                                                                        <div class="col">
                                                                            <input type="text" class="form-control" formControlName="last_name" placeholder="Last Name">
                                                                        </div>  
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- address -->
                                                        <div class="row input-row">
                                                            <div class="col-2">Address</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col">
                                                                <ng-container *ngIf="personalEditForm">
                                                                    <p>{{(personalForm.value.area_of_residence) ? (personalForm.value.area_of_residence) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!personalEditForm">
                                                                    <textarea class="form-control" formControlName="area_of_residence" placeholder="Address" oninput="this.style.height = ''; this.style.height = this.scrollHeight +'px'"></textarea>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- gender -->
                                                        <div class="row input-row">
                                                            <div class="col-2">Gender</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col">
                                                                <ng-container *ngIf="personalEditForm">
                                                                    <p>{{(personalForm.value.sex) ? (personalForm.value.sex) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!personalEditForm">
                                                                    <select class="form-select" #personal_sex (change)="universalUpdater('gender',personal_sex.value)">
                                                                        <option selected disabled>Gender</option>
                                                                        <option *ngFor="let gender of universalOption('gender')"
                                                                            [value]="gender"
                                                                            [selected]="universalSelector('gender',gender)"
                                                                            >{{gender}}</option>
                                                                    </select>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- id -->
                                                        <div class="row input-row">
                                                            <div class="col-2">Identification</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col">
                                                                <ng-container *ngIf="personalEditForm">
                                                                    <p>{{(personalForm.value.idtype || personalForm.value.idnumber) ? (personalForm.value.idtype+": "+personalForm.value.idnumber) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!personalEditForm">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <input type="text" class="form-control" formControlName="idtype" placeholder="ID Type">
                                                                        </div>
                                                                        <div class="col">
                                                                            <input type="text" class="form-control" formControlName="idnumber" placeholder="ID Number">
                                                                        </div>  
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-content-around" style="padding: 1em;">
                                                            <ng-container *ngIf="personalEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('personal','edit')" class="btn btn-info">Update</button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!personalEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('personal','cancel')" class="btn btn-danger">Cancel</button>
                                                                <button type="button" [disabled]="!chosenUser" (click)="submitUpdate('personal')" class="btn btn-primary">Confirm</button>
                                                            </ng-container>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <!-- credential -->
                                            <div class="tab-pane fade" id="credential" role="tabpanel" aria-labelledby="credential-tab">

                                                <div style="padding:1em;">
                                                    <form [formGroup]="credentialForm">

                                                        <!-- uid -->
                                                        <div class="row input-row">
                                                            <div class="col-3">UID  </div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container>
                                                                    <p>{{(credentialForm.value.uid) ? (credentialForm.value.uid) : "N/A"}}</p>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- email -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Email</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container>
                                                                    <p>{{(credentialForm.value.email) ? (credentialForm.value.email) : "N/A"}}</p>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- type -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Account Type</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="credentialEditForm">
                                                                    <p>{{(credentialForm.value.account_type) ? (credentialForm.value.account_type) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!credentialEditForm">
                                                                    <select class="form-select" #type_account (change)="universalUpdater('govtype',type_account.value)">
                                                                        <option selected disabled>Account Type</option>
                                                                        <option *ngFor="let item of universalOption('govtype')"
                                                                            [value]="item"
                                                                            [selected]="universalSelector('govtype',item)"
                                                                            >{{item}}</option>
                                                                    </select>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- subtype -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Account Subtype</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="credentialEditForm">
                                                                    <p>{{(credentialForm.value.account_subtype) ? (credentialForm.value.account_subtype) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!credentialEditForm">
                                                                    <select class="form-select" #subtype_account (change)="universalUpdater('govsubtype',subtype_account.value)">
                                                                        <option selected disabled>Account Subtype</option>
                                                                        <option *ngFor="let item of universalOption('govsubtype')"
                                                                            [value]="item"
                                                                            [selected]="universalSelector('govsubtype',item)"
                                                                            >{{item}}</option>
                                                                    </select>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!--access -->
                                                        <div class="row input-row">
                                                            <label>Access</label>
                                                            <div class="col">
                                                                <div class="row">
                                                                    <div class="col-3">Artemis</div>
                                                                    <div class="col-1">:</div>
                                                                    <div class="col form-group">
                                                                        <ng-container *ngIf="credentialEditForm">
                                                                            <p>{{(credentialForm.value.access_artemis) ? (credentialForm.value.access_artemis) : "N/A"}}</p>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!credentialEditForm">
                                                                            <select class="form-select" #artemis_access (change)="universalUpdater('access_artemis',artemis_access.value)">
                                                                                <option selected disabled>Artemis Access</option>
                                                                                <option *ngFor="let item of universalOption('traulse')"
                                                                                    [value]="item"
                                                                                    [selected]="universalSelector('access_artemis',item)"
                                                                                    >{{item}}</option>
                                                                            </select>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="row">
                                                                    <div class="col-3">PBRIS</div>
                                                                    <div class="col-1">:</div>
                                                                    <div class="col form-group">
                                                                        <ng-container *ngIf="credentialEditForm">
                                                                            <p>{{(credentialForm.value.access_pbris) ? (credentialForm.value.access_pbris) : "N/A"}}</p>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!credentialEditForm">
                                                                            <select class="form-select" #pbris_access (change)="universalUpdater('access_pbris',pbris_access.value)">
                                                                                <option selected disabled>Artemis Access</option>
                                                                                <option *ngFor="let item of universalOption('traulse')"
                                                                                    [value]="item"
                                                                                    [selected]="universalSelector('access_pbris',item)"
                                                                                    >{{item}}</option>
                                                                            </select>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- last login -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Mobile Number</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="credentialEditForm">
                                                                    <p>{{(credentialForm.value.mobile_number) ? (credentialForm.value.mobile_number) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!credentialEditForm">
                                                                    <input type="text" class="form-control" formControlName="mobile_number" placeholder="Mobile Number" >
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- member since -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Last Login</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container>
                                                                    <p>{{(credentialForm.value.loggedIn) ? (credentialForm.value.loggedIn.toDate()) : "N/A"}}</p>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div class="row input-row">
                                                            <div class="col-3">Member Since</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container>
                                                                    <p>{{(credentialForm.value.sinceMember) ? (credentialForm.value.sinceMember) : "N/A"}}</p>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-content-around" style="padding: 1em;">
                                                            <ng-container *ngIf="credentialEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('credential','edit')" class="btn btn-info">Update</button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!credentialEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('credential','cancel')" class="btn btn-danger">Cancel</button>
                                                                <button type="button" [disabled]="!chosenUser" (click)="submitUpdate('credential')" class="btn btn-primary">Confirm</button>
                                                            </ng-container>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            
                                            <!-- government -->
                                            <div class="tab-pane fade" id="government" role="tabpanel" aria-labelledby="government-tab">

                                                <div style="padding:1em;">
                                                    <form [formGroup]="governmentForm">

                                                        <!-- agency -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Agency</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="governmentEditForm">
                                                                    <p>{{(governmentForm.value.agency) ? (getAgencyName(governmentForm.value.agency)) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!governmentEditForm">
                                                                    <select class="form-select" #agency_val (change)="universalUpdater('agency',agency_val.value)">
                                                                        <option selected disabled>Agencies</option>
                                                                        <option *ngFor="let agency of universalOption('agency')"
                                                                            [value]="agency.id"
                                                                            [selected]="universalSelector('agency',agency.id)"
                                                                            >{{(agency.agencyDetails) ? (agency.agencyDetails.agencyName) ? (agency.agencyDetails.agencyName) : (agency.name) : (agency.name)}}</option>
                                                                    </select>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- designation -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Official Designation</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="governmentEditForm">
                                                                    <p>{{(governmentForm.value.official_designation) ? (governmentForm.value.official_designation) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!governmentEditForm">
                                                                    <input type="text" class="form-control" formControlName="official_designation" placeholder="Official Designation">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- sub agency -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Sub Agency</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="governmentEditForm">
                                                                    <p>{{(governmentForm.value.subagency) ? (governmentForm.value.subagency) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!governmentEditForm">
                                                                    <input type="text" class="form-control" formControlName="subagency" placeholder="Sub Agency">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-content-around" style="padding: 1em;">
                                                            <ng-container *ngIf="governmentEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('government','edit')" class="btn btn-info">Update</button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!governmentEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('government','cancel')" class="btn btn-danger">Cancel</button>
                                                                <button type="button" [disabled]="!chosenUser" (click)="submitUpdate('government')" class="btn btn-primary">Confirm</button>
                                                            </ng-container>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <!-- company -->
                                            <div class="tab-pane fade" id="company" role="tabpanel" aria-labelledby="company-tab">
                                                <div style="padding:1em;">
                                                    <form [formGroup]="companyForm">

                                                        <!-- org name -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Organization Name</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="companyEditForm">
                                                                    <p>{{(companyForm.value.name_of_organization) ? (companyForm.value.name_of_organization) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!companyEditForm">
                                                                    <input type="text" class="form-control" formControlName="name_of_organization" placeholder="Company Name">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- org desig -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Official Designation</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="companyEditForm">
                                                                    <p>{{(companyForm.value.official_designation) ? (companyForm.value.official_designation) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!companyEditForm">
                                                                    <input type="text" class="form-control" formControlName="official_designation" placeholder="Official Designation">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- org address -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Organization Address</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="companyEditForm">
                                                                    <p>{{(companyForm.value.organization_address) ? (companyForm.value.organization_address) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!companyEditForm">
                                                                    <input type="text" class="form-control" formControlName="organization_address" placeholder="Organization Address">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- sector -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Sector</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="companyEditForm">
                                                                    <p>{{(companyForm.value.sector) ? (companyForm.value.sector) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!companyEditForm">
                                                                    <input type="text" class="form-control" formControlName="sector" placeholder="Sector">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex justify-content-around" style="padding: 1em;">
                                                            <ng-container *ngIf="companyEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('company','edit')" class="btn btn-info">Update</button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!companyEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('company','cancel')" class="btn btn-danger">Cancel</button>
                                                                <button type="button" [disabled]="!chosenUser" (click)="submitUpdate('company')" class="btn btn-primary">Confirm</button>
                                                            </ng-container>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>

                                            <!-- file -->
                                            <div class="tab-pane fade" id="file" role="tabpanel" aria-labelledby="file-tab">
                                                <div style="padding:1em;">
                                                    <form [formGroup]="fileForm">

                                                        <!-- company id -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Company ID</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="fileEditForm">
                                                                    <p>{{(fileForm.value.company_id) ? (fileForm.value.company_id) : "N/A"}}</p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!fileEditForm">
                                                                    <input type="text" class="form-control" formControlName="company_id" placeholder="Company ID">
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- gov id -->
                                                        <div class="row input-row">
                                                            <div>Government ID :</div>
                                                            <div class="col d-flex justify-content-center">
                                                                <ng-container *ngIf="fileEditForm" >
                                                                    <img class="card" style="width:400px;height:300px; margin-bottom:1em;" [src]="checkLinkExpiry(fileForm.value.government_id,'gov_id')">
                                                                </ng-container>
                                                                <ng-container *ngIf="!fileEditForm">
                                                                    <div class="d-flex justify-content-center flex-column">
                                                                        <img class="card" style="width:400px;height:300px; margin-bottom:1em;" [src]="getFileImage('gov_id')">
                                                                        <input type="file" class="form-control" id="govIdSrc" (change)="onFileChange('gov_id',$event)" placeholder="JPG, JPEG or PNG images only">
                                                                        <button type="button" (click)="resetImg('gov_id')" class="form-control btn btn-warning"><i class="fa-solid fa-arrow-rotate-right">Reset Government ID Upload</i></button>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <!-- auth letter -->
                                                        <div class="row input-row">
                                                            <div class="col-3">Authorization Letter</div>
                                                            <div class="col-1">:</div>
                                                            <div class="col form-group">
                                                                <ng-container *ngIf="fileEditForm">
                                                                    <p><a href="{{(fileForm.value.authorization_letter) ? (fileForm.value.authorization_letter) : ''}}" target="_blank">Link to Authorization Letter</a></p>
                                                                </ng-container>
                                                                <ng-container *ngIf="!fileEditForm">
                                                                    <input type="file" class="form-control" id="authLetterSrc" (change)="onFileChange('auth_letter',$event)" placeholder="JPG, JPEG, PNG images or PDF files only">
                                                                    <button type="button" (click)="resetImg('auth_letter')" class="form-control btn btn-warning"><i class="fa-solid fa-arrow-rotate-right">Reset Authorization Upload</i></button>
                                                                </ng-container>
                                                            </div>
                                                            <ng-container *ngIf="fileEditForm">
                                                                <ng-container *ngIf="((checkAuthLetterFileType('image/jpeg') || checkAuthLetterFileType('image/jpg') || checkAuthLetterFileType('image/png')))">
                                                                    <img style="width: 600px;" [src]="checkLinkExpiry(fileForm.value.authorization_letter,'auth_letter')">
                                                                </ng-container>
                                                                <ng-container *ngIf="(checkAuthLetterFileType('application/pdf'))">

                                                                    <div class="d-flex justify-content-evenly" style="padding-bottom: 0.5em;padding-top:0.5em;">
                                                                        <button (click)="pageShift('back','baseFile')" class="btn btn-outline-primary"><</button>
                                                                        <div><p>{{fileBasePage}} / {{fileBaseTotalPage ? fileBaseTotalPage : "1"}}</p></div>
                                                                        <button (click)="pageShift('next','baseFile')" class="btn btn-outline-primary">></button>
                                                                    </div>
                                                                    <div class="d-flex justify-content-center">
                                                                        <pdf-viewer style="max-width: 600px;"
                                                                            (after-load-complete)="afterLoadComplete($event,'baseFile')"
                                                                            [stick-to-page]="true"
                                                                            [page]="fileBasePage"
                                                                            [show-all]="false" 
                                                                            [original-size]=false 
                                                                            [src]="checkLinkExpiry(fileForm.value.authorization_letter,'auth_letter')"></pdf-viewer>
                                                                    </div>
                                                                    <div class="d-flex justify-content-evenly" style="padding-bottom: 0.5em;padding-top:0.5em;">
                                                                        <button (click)="pageShift('back','baseFile')" class="btn btn-outline-primary"><</button>
                                                                        <div><p>{{fileBasePage}} / {{fileBaseTotalPage ? fileBaseTotalPage : "1"}}</p></div>
                                                                        <button (click)="pageShift('next','baseFile')" class="btn btn-outline-primary">></button>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngIf="!fileEditForm">
                                                                <ng-container *ngIf="((checkAuthLetterFileType('image/jpeg') || checkAuthLetterFileType('image/jpg') || checkAuthLetterFileType('image/png')))">
                                                                    <img style="width: 600px;" [src]="getFileImage('auth_letter')">
                                                                </ng-container>
                                                                <ng-container *ngIf="(checkAuthLetterFileType('application/pdf'))">

                                                                    <div class="d-flex justify-content-evenly" style="padding-bottom: 0.5em;padding-top:0.5em;">
                                                                        <button (click)="pageShift('back','baseFile')" class="btn btn-outline-primary"><</button>
                                                                        <div><p>{{fileBasePage}} / {{fileBaseTotalPage ? fileBaseTotalPage : "1"}}</p></div>
                                                                        <button (click)="pageShift('next','baseFile')" class="btn btn-outline-primary">></button>
                                                                    </div>
                                                                    <div class="d-flex justify-content-center">
                                                                        <pdf-viewer style="max-width: 600px;"
                                                                            (after-load-complete)="afterLoadComplete($event,'baseFile')"
                                                                            [stick-to-page]="true"
                                                                            [page]="fileBasePage"
                                                                            [show-all]="false" 
                                                                            [original-size]=false 
                                                                            [src]="getFileImage('auth_letter')"></pdf-viewer>
                                                                    </div>
                                                                    <div class="d-flex justify-content-evenly" style="padding-bottom: 0.5em;padding-top:0.5em;">
                                                                        <button (click)="pageShift('back','baseFile')" class="btn btn-outline-primary"><</button>
                                                                        <div><p>{{fileBasePage}} / {{fileBaseTotalPage ? fileBaseTotalPage : "1"}}</p></div>
                                                                        <button (click)="pageShift('next','baseFile')" class="btn btn-outline-primary">></button>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>

                                                        <div class="d-flex justify-content-around" style="padding: 1em;">
                                                            <ng-container *ngIf="fileEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('file','edit')" class="btn btn-info">Update</button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!fileEditForm">
                                                                <button type="button" [disabled]="!chosenUser" (click)="updateToggle('file','cancel')" class="btn btn-danger">Cancel</button>
                                                                <button type="button" [disabled]="!chosenUser" (click)="submitUpdate('file')" class="btn btn-primary">Confirm</button>
                                                            </ng-container>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>

                                            <!-- settings -->
                                            <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                                <div style="padding:1em;">

                                                    <!-- regulations  -->
                                                    <div class="row input-row">
                                                        <div>Regulations :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.regulations.create"
                                                                    (change)="toogleSlide('regulations','create')">
                                                                    View Newly Published Regulations
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.regulations.review"
                                                                (change)="toogleSlide('regulations','review')">
                                                                    Review Updated Regulations
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Consultation  -->
                                                    <div class="row input-row">
                                                        <div>Consultation :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.consultations.create"
                                                                    (change)="toogleSlide('consultations','create')">
                                                                    View Newly Published Consultation
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.consultations.review"
                                                                (change)="toogleSlide('consultations','review')">
                                                                    Review Updated Consultation
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Citizens Charter  -->
                                                    <div class="row input-row">
                                                        <div>Citizens Charter :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.citizenscharters.create"
                                                                    (change)="toogleSlide('citizenscharters','create')">
                                                                    View Newly Published Citizens Charter
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.citizenscharters.review"
                                                                (change)="toogleSlide('citizenscharters','review')">
                                                                    Review Updated Citizens Charter
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Services  -->
                                                    <div class="row input-row">
                                                        <div>Services :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.services.create"
                                                                    (change)="toogleSlide('services','create')">
                                                                    View Newly Published Citizens Services
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.services.review"
                                                                (change)="toogleSlide('services','review')">
                                                                    Review Updated Citizens Services
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Agency  -->
                                                    <div class="row input-row">
                                                        <div>Agency :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.agency.create"
                                                                    (change)="toogleSlide('agency','create')">
                                                                    View Newly Created Agencies
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.agency.complaint"
                                                                (change)="toogleSlide('agency','complaint')">
                                                                Check Complaints on Agencies
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Worklists  -->
                                                    <div class="row input-row">
                                                        <div>Worklists :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.worklist.create"
                                                                    (change)="toogleSlide('worklist','create')">
                                                                    View Newly Published Worklists
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.worklist.review"
                                                                (change)="toogleSlide('worklist','review')">
                                                                    Review Updated Worklists
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Worklists  -->
                                                    <div class="row input-row">
                                                        <div>Accounts :</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                    color="primary"
                                                                    [checked]="settings.accounts.create"
                                                                    (change)="toogleSlide('accounts','create')">
                                                                    View Newly Published Accounts
                                                                </mat-slide-toggle>
                                                            </div>
                                                            <div class="col">
                                                                <mat-slide-toggle [disabled]="settingsEditForm"
                                                                color="primary"
                                                                [checked]="settings.accounts.review"
                                                                (change)="toogleSlide('accounts','review')">
                                                                    Review Updated Accounts
                                                                </mat-slide-toggle>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex justify-content-around" style="padding: 1em;">
                                                        <ng-container *ngIf="settingsEditForm">
                                                            <button type="button" [disabled]="!chosenUser" (click)="updateToggle('settings','edit')" class="btn btn-info">Update</button>
                                                        </ng-container>
                                                        <ng-container *ngIf="!settingsEditForm">
                                                            <button type="button" [disabled]="!chosenUser" (click)="updateToggle('settings','cancel')" class="btn btn-danger">Cancel</button>
                                                            <button type="button" [disabled]="!chosenUser" (click)="submitUpdate('settings')" class="btn btn-primary">Confirm</button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
            
                                        </div>
                                        <!-- tab-content end-->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
</div>