<div class="container">
  <div class="py-4">
    <div class="text-primary fw-bold fs-1">
      My PBRIS Account
    </div>
    <hr class="my-0"/>
    <div class="mb-3">
      Welcome back, <b>{{ data_catch.personal.first_name }}</b>! Your last login was on {{ last_login }}. Click here if it were not you.
    </div>
    <div class="border rounded p-2 col-7 mt-5">
      <div class="text-primary fw-bold mb-1">
        Personal Dashboard
      </div>
      <div class="">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="float-start">
              Subscriptions
            </div>
            <div class="float-end">
              <span class="badge rounded-pill bg-primary">{{ this.subscription_count }}</span>
              Active Subscriptions
            </div>
          </li>
          <li class="list-group-item">
            <div class="float-start">
              Comments
            </div>
            <div class="float-end">
              <span class="badge rounded-pill bg-primary">{{ this.comment_count }}</span>
              New Response
            </div>
          </li>
          <li class="list-group-item">
            <div class="float-start">
              Pinned Regulations
            </div>
            <div class="float-end">
              <span class="badge rounded-pill bg-primary">{{ this.regulation_count }}</span>
              Regulations
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
