import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { privateDecrypt } from 'crypto';
import { tap } from 'rxjs/operators';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-artemis-identification-list-maintenance',
  templateUrl: './artemis-identification-list-maintenance.component.html',
  styleUrls: ['./artemis-identification-list-maintenance.component.scss'],
})
export class ArtemisIdentificationListMaintenanceComponent implements OnInit {
  displayedColumns: string[] = [
    'identification',
    'agency',
    'status',
    'level',
    'actions',
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('idlist') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  id_list: any = [];

  form = new UntypedFormGroup({
    identification: new UntypedFormControl('', Validators.required),
    bureau_agency: new UntypedFormControl('', Validators.required),
    active_status: new UntypedFormControl(true),
    option: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private afs: AngularFirestore,
    private notif: SnackbarNotifService
  ) {}

  ngOnInit(): void {
    this.loadIdentification();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setpageOptions() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.dataSource.filterPredicate = (
      option: any,
      searchValue: string
    ): boolean => {
      if (searchValue) {
        searchValue = searchValue.trim().toLowerCase();
        return (
          option.identification?.trim().toLowerCase().includes(searchValue) ||
          option.bureau_agency?.trim().toLowerCase().includes(searchValue)
        );
      } else return false;
    };
  }

  loadIdentification() {
    this.afs
      .collection('Identification References')
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          this.id_list = [];

          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            item.id = info.payload.doc.id;

            if (info.type == 'modified') {
              for (var x in this.id_list) {
                const d = this.id_list[x];
                if (item.id === d.id) {
                  this.id_list.splice(x, 1, item);
                }
              }
            } else {
              let addFlag = true;
              for (var worklist of this.id_list) {
                if (item.id === worklist.id) {
                  addFlag = false;
                  break;
                }
              }

              if (addFlag) {
                this.id_list.push(item);
              }
            }
            this.id_list.push(item);
          });

          this.dataSource = new MatTableDataSource<any>(this.id_list);
        })
      )
      .subscribe(() => {
        this.setpageOptions();
        const sorData: Sort ={
          active : 'agency',
          direction:'asc'
        }
        this.sortChange(sorData)
      });
  }

  sortChange(sort: Sort) {
    const data = this.id_list.slice()
    // console.log('sort.active',sort.active)
    // console.log('sort.direction',sort.direction)
    if (!sort.active || sort.direction === '') {
      this.id_list = data
      return;
    }
    this.id_list = data.sort((a: any, b: any) => {
      // console.log('results',this.results)
      const isAsc = sort.direction === 'asc'
      switch (sort.active) {

        case 'level':
          return this.compare(a.option, b.option, isAsc)
        case 'agency':
          return this.compare(a.bureau_agency.trim().toLowerCase(), b.bureau_agency.trim().toLowerCase(), isAsc)
        case 'identification':
          return this.compare(a.identification.trim().toLowerCase(), b.identification.trim().toLowerCase(), isAsc)

        default:
          return null
      }
    })
    this.dataSource = new MatTableDataSource<any>(this.id_list)
    this.setpageOptions()

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  removeId(row: any) {
    this.afs
      .collection('Identification References')
      .doc(row.id)
      .delete()
      .then(() => {
        this.notif.displayNotif('success', 'Success');
      });
  }

  saveIdentification() {
    if (this.form.valid) {
      const id: any = this.form.value;
      this.afs
        .collection('Identification References')
        .add(id)
        .then(() => {
          this.form.reset();
          this.notif.displayNotif('success', 'Success');
        });
    }
  }

  updating: any;

  updateIdentification(identification: any) {
    this.form.controls['identification'].patchValue(
      identification.identification
    );
    this.form.controls['active_status'].patchValue(
      identification.active_status
    );
    this.form.controls['bureau_agency'].patchValue(
      identification.bureau_agency
    );
    this.form.controls['option'].patchValue(identification?.option);
    this.updating = identification;
  }

  update() {
    if (this.form.valid) {
      const id: any = this.form.value;
      this.afs
        .collection('Identification References')
        .doc(this.updating.id)
        .update(id)
        .then(() => {
          this.form.reset();
          this.notif.displayNotif('success', 'Updated');
          this.updating = '';
        });
    } else {
      this.form.markAllAsTouched();
    }
  }
  resetform() {
    this.updating = '';
    this.form.reset();
    this.form.controls['active_status'].patchValue(true);
  }
}
