import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-manager',
  templateUrl: './modal-manager.component.html',
  styleUrls: ['./modal-manager.component.scss']
})
export class ModalManagerComponent implements OnInit {

  method!: string;
  collection!: string;
  id?:any;

  header_content?: any;
  body_content?: any;
  buttons?: any;

  // extra links
  login_route?: any;
  register_route?: any;

  form_keys?: any;
  forms: any;

  constructor(public modalRef: MDBModalRef, private auth: AngularFireAuth, private router: Router) { }

  ngOnInit(): void {
    if(this.form_keys){
      this.forms = [];
      for(let key of this.form_keys){
        this.forms.push(new UntypedFormGroup({
          title: new UntypedFormControl(key, Validators.required),
          field: new UntypedFormControl('', Validators.required),
        }));
      }
    }
  }

  confirm () {
    if(this.buttons.confirm.toLowerCase().includes("guest")){
      this.reRoute(this.login_route);
    }
    this.modalRef.hide();
  }

  gotoRegistration () {
    if(this.buttons.registration){
      this.reRoute(this.register_route);
    }
    this.modalRef.hide();
  }

  cancel () {
    //
    this.modalRef.hide();
  }


  loginAsGuest () {
    this.router.navigate([this.login_route]);
    this.modalRef.hide();
  }

  reRoute (link: any) {
    this.router.navigate([link]);
  }

}
