import { ReferenceControlComponent } from './reference-control/reference-control.component';
import { AgencyMembersControlComponent } from './agency-control/agency-members-control/agency-members-control.component';
import { PbrisAccountUserComponent } from './pbris/pbris-account/pbris-account-user/pbris-account-user.component';
import { PbrisAccountSettingComponent } from './pbris/pbris-account/pbris-account-settings/pbris-account-setting.component';
import { PbrisAccountComponent } from './pbris/pbris-account/pbris-account.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { PbrisComponent } from './pbris/pbris.component';
import { ArtemisComponent } from './artemis/artemis.component';
import { ArtemisLoginPageComponent } from './artemis/artemis-login-page/artemis-login-page.component';
import { ArtemisReportsPageComponent } from './artemis/artemis-reports-page/artemis-reports-page.component';
import { ArtemisViewComponent } from './artemis/artemis-view/artemis-view.component';
import { ArtemisServiceInformationPageComponent } from './artemis/artemis-service-information-page/artemis-service-information-page.component';
import { ArtemisServiceProcessFlowPageComponent } from './artemis/artemis-service-process-flow-page/artemis-service-process-flow-page.component';
import { ArtemisViewOrganizationComponent } from './artemis/artemis-view/artemis-view-organization/artemis-view-organization.component';
import { ArtemisAboutUsPageComponent } from './artemis/artemis-about-us-page/artemis-about-us-page.component';
import { ArtemisAnnouncementsPageComponent } from './artemis/artemis-announcements-page/artemis-announcements-page.component';
import { ArtemisContactUsPageComponent } from './artemis/artemis-contact-us-page/artemis-contact-us-page.component';
import { ArtemisAdvancedSearchPageComponent } from './artemis/artemis-advanced-search-page/artemis-advanced-search-page.component';
import { ArtemisHelpPageComponent } from './artemis/artemis-help-page/artemis-help-page.component';
import { ArtemisInboxPageComponent } from './artemis/artemis-inbox-page/artemis-inbox-page.component';
import { CharterReportsComponent } from './artemis/artemis-charter-crud/charter-reports/charter-reports.component';
import { ArtemisNewCharterComponent } from './artemis/artemis-inbox-page/artemis-new-charter/artemis-new-charter.component';
import { ArtemisCommentCreateComponent } from './artemis/artemis-comment-page/artemis-comment-create/artemis-comment-create.component';
import { ArtemisNewCitizensCharterComponent } from './artemis/artemis-new-citizens-charter/artemis-new-citizens-charter.component';
import { ArtemisCharterPreviewPageComponent } from './artemis/artemis-inbox-page/artemis-charter-preview-page/artemis-charter-preview-page.component';
import { ArtemisSearchResultsPageComponent } from './artemis/artemis-search-results-page/artemis-search-results-page.component';
import { ArtemisUserFeedbackComponent } from './artemis/artemis-user-feedback/artemis-user-feedback.component';
import { ReportSummaryComponent } from './artemis/report-summary/report-summary.component';
// import { ArtemisInboxUserFeedbackComponent } from './artemis/artemis-inbox-user-feedback/artemis-inbox-user-feedback.component';
import { LoginPbrisComponent } from './pbris/login-pbris/login-pbris.component';
import { RegistrationPbrisComponent } from './pbris/registration-pbris/registration-pbris.component';
import { PbrisUserFeedbackCreateComponent } from './pbris/pbris-user-feedback-crud/pbris-user-feedback-create/pbris-user-feedback-create.component';
import { PbrisReferencesComponent } from './pbris/pbris-references/pbris-references.component'
import { PbrisSectorComponent } from './pbris/pbris-sector/pbris-sector.component'
import { PbrisUploadExistingRegulationsComponent } from './pbris/pbris-upload-existing-regulations/pbris-upload-existing-regulations.component'
import { PbrisUploadExistingCompleteRegulationsComponent } from './pbris/pbris-upload-existing-complete-regulations/pbris-upload-existing-complete-regulations.component'
import { PbrisUploadProposedRegulationsComponent } from './pbris/pbris-upload-proposed-regulations/pbris-upload-proposed-regulations.component'
import { CreateSectorComponent } from './create-sector/create-sector.component'
import { PbrisAdvancedSearchComponent } from './pbris/pbris-advanced-search/pbris-advanced-search.component'
import { RegulationsComponent } from './pbris/regulations/regulations.component'
import { UserManagementComponent } from './user-management/user-management.component'
import { RestrictedAccessComponent } from './restricted-access/restricted-access.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { UploadedFilesComponent } from './uploaded-files/uploaded-files.component'
import { RegistrationAgreementsComponent } from './admin/registration-agreements/registration-agreements.component'
import { AdminComponent } from './admin/admin.component'
import { RegulationsResultComponent } from './pbris/regulations-result/regulations-result.component'
import { ArtemisCharterCrudComponent } from './artemis/artemis-charter-crud/artemis-charter-crud.component';
import { ArtemisServiceCrudComponent } from './artemis/artemis-service-crud/artemis-service-crud.component';
import { ViewCharterComponent } from './artemis/artemis-charter-crud/view-charter/view-charter.component';
import { ViewCharterPublicComponent } from './artemis/artemis-charter-crud/view-charter/view-charter-public/view-charter-public.component';
import { AgencyControlComponent } from './agency-control/agency-control.component';
import { UploadCharterComponent } from './artemis/artemis-charter-crud/upload-charter/upload-charter.component';
import { CitizensRightsComponent } from './artemis/artemis-charter-crud/citizens-rights/citizens-rights.component';
import { AgencyInformationComponent } from './agency-control/agency-information/agency-information.component';
import { AgencyAddComponent } from './agency-control/agency-add/agency-add.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { UpdateRegulationsComponent } from './pbris/regulations/update-regulations/update-regulations.component';
import { UploadExistingRegulationsComponent } from './pbris/upload-existing-regulations/upload-existing-regulations.component';
import { InboxComponent } from './pbris/regulatory-management/inbox/inbox.component';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from "@angular/fire/compat/auth-guard";
import { CustomAuthHandleComponent } from './custom-auth-handle/custom-auth-handle.component';
import { PbrisRegulatoryPolicyComponent } from './pbris/pbris-regulatory-policy/pbris-regulatory-policy.component';
import { PbrisContactUsComponent } from './pbris/pbris-contact-us/pbris-contact-us.component';
import { PbrisHelpComponent } from './pbris/pbris-help/pbris-help.component';
import { UpdateCharterComponent } from './artemis/artemis-charter-crud/update-charter/update-charter.component';
import { RegulatoryManagementComponent } from './pbris/regulatory-management/regulatory-management.component';
import { PbrisAccountPageComponent } from './pbris/pbris-account-page/pbris-account-page.component';
import { ReportsMainComponent } from './pbris/reports-main/reports-main.component'
import { PbrisUserFeedbackComponent } from './pbris/pbris-user-feedback/pbris-user-feedback.component';
import { ArtemisAgencyCrudComponent } from './artemis/artemis-agency-crud/artemis-agency-crud.component';
import { CrisCreateComponent } from './pbris/cris-create/cris-create.component';
import { PbrisConsultationsComponent } from './pbris/pbris-consultation/pbris-consultation.component';
import { DrisCreateComponent } from './pbris/dris-create/dris-create.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { PbrisQuickSearchComponent } from './pbris/pbris-quick-search/pbris-quick-search.component';
import { PbrisQuickAdvSearchComponent } from './pbris/pbris-quick-adv-search/pbris-quick-adv-search.component';
import { PbrisConsultationsViewComponent } from './pbris/pbris-consultation/pbris-consultation-view/pbris-consultation-view.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { PbrisRegulatoryNotifFormComponent } from './pbris/pbris-regulatory-notif-form/pbris-regulatory-notif-form.component';
import { LoginAuthGuard } from './guards/loginAuthGaurd.gaurd';
import { AuthGuard } from './guards/authGaurd.gaurd';
import { PrivatepageguardGuard } from './guards/privatepageguard.guard';
import { AuthGuardTypeFlag } from './entities/authFlags';
import { NewServiceModalComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/new-service-modal.component';
import { PbrisFaqComponent } from './pbris/pbris-faq/pbris-faq.component';
import { ArtemisRequirementBuilderComponent } from './artemis/artemis-inbox-page/artemis-new-charter/new-service-modal/artemis-requirement-builder/artemis-requirement-builder.component';
import { ArtemisOfficeGroupingComponent } from './artemis/artemis-view/artemis-office-grouping/artemis-office-grouping.component';
import { ArtemisServiceHistoryViewComponent } from './artemis/artemis-inbox-page/artemis-service-history-view/artemis-service-history-view.component';

const redirectUnauthorizedToRestricted = () => redirectUnauthorizedTo(["/restricted"]);
const routes: Routes = [
  {
    path :'test-requirement', component : ArtemisOfficeGroupingComponent
  },
  { path: 'pbris', component: PbrisComponent, data: { title: 'PBRIS Dashboard ' }, canActivate:[AuthGuard]},
  { path: 'artemis', component: ArtemisComponent, data: { title: 'ARTEMIS ' }, canActivate:[AuthGuard]},
  {
    path: 'artemis/login',
    component: ArtemisLoginPageComponent,
    data: { title: 'ARTEMIS Login ' },
    canActivate: [LoginAuthGuard]
  },
  { path :'artemis/history-view/:id',component: ArtemisServiceHistoryViewComponent, data : {title :'Service History'},canActivate:[AuthGuard]},
  { path: 'artemis/login/:subpage', component: ArtemisLoginPageComponent, data: { title: 'ARTEMIS Register ' }, canActivate:[AuthGuard]},
  { path: 'artemis/view/:id', component: ArtemisViewComponent, data: { title: 'View' }, canActivate:[AuthGuard]},
  { path: 'artemis/view/detail/:id', component: ArtemisServiceInformationPageComponent, data: { title: 'Report Background' }, canActivate:[AuthGuard]},
  { path: 'artemis/view/process/:id', component: ArtemisServiceProcessFlowPageComponent, data: { title: 'Reports Process Flow' }, canActivate:[AuthGuard]},
  { path: 'artemis/view/agency/:id', component: ArtemisViewOrganizationComponent, data: { title: 'Agency' }, canActivate:[AuthGuard]},
  { path: 'artemis/about-us', component: ArtemisAboutUsPageComponent, data: { title: 'About Us ' }, canActivate:[AuthGuard]},
  { path: 'artemis/announcements', component: ArtemisAnnouncementsPageComponent, data: { title: 'Announcements ' }, canActivate:[AuthGuard]},
  { path: 'artemis/contact-us', component: ArtemisContactUsPageComponent, data: { title: 'Contact Us ' } },
  { path: 'artemis/advanced-search', component: ArtemisAdvancedSearchPageComponent, data: { title: 'Advanced Search ' }, canActivate:[AuthGuard]},
  { path: 'artemis/help', component: ArtemisHelpPageComponent, data: { title: 'Help ' }, canActivate:[AuthGuard]},
  { path: 'artemis/reference-controls', component: ReferenceControlComponent, data: { title: " Reference List" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter', component: ArtemisCharterCrudComponent, data: { title: "Citizen's Charter List" }, canActivate:[AuthGuard]},
  // { path: 'artemis/citizen-charter/view/:id', component: ViewCharterComponent, data: { title: "Citizen's Charter View" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/view', component: ViewCharterComponent, data: { title: "Citizen's Charter View" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/view/public/:id', component: ViewCharterPublicComponent, data: { title: "Citizen's Charter Public View" }, canActivate:[AuthGuard]},  
  // { path: 'artemis/citizen-charter/view/report/:id', component: CharterReportsComponent, data: { title: "Citizen's Charter View Report" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/view/report', component: CharterReportsComponent, data: { title: "Citizen's Charter View Report" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/update/:id', component: UpdateCharterComponent, data: { title: "Citizen's Charter View" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/upload', component: UploadCharterComponent, data: { title: "Citizen's Charter Upload" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/update/:id', component: UpdateCharterComponent, data: { title: "Citizen's Charter Update" }, canActivate:[AuthGuard]},
  { path: 'artemis/citizen-charter/rights', component: CitizensRightsComponent, data: { title: "Citizen's Rights" }, canActivate:[AuthGuard]},
  { path: 'artemis/charter', component: ArtemisInboxPageComponent, 
    canActivate:[PrivatepageguardGuard],
    data: {
      title: "Citizen's Charter Inbox ",
      restrictions: [
        AuthGuardTypeFlag.ARTEMIS_ONLY
      ]
    },
  },
  {
    path: 'artemis/charter/newcharter',
    component: ArtemisNewCharterComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: { title: "New Citizen's Charter " }
  },
  {
    path:'artemis/charter/serviceeditor/:id',component: NewServiceModalComponent, data: { title: 'Service Editor'}, canActivate:[PrivatepageguardGuard]
  },
  {
    path:'artemis/charter/serviceeditor',component: NewServiceModalComponent, data: { title: 'Service Editor'}, canActivate:[PrivatepageguardGuard]
  }
  ,
  {
    path: 'artemis/charter/newcharter/:id',
    component: ArtemisNewCharterComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: { title: "New Citizen's Charter " }
  },
  {
    path: 'artemis/charter/updatecharter/:id',
    component: ArtemisNewCharterComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: { title: "New Citizen's Charter " }
  },
  { path: 'artemis/charter/preview/:id', component: ArtemisCharterPreviewPageComponent, data: { title: "Citizen's Charter Preview" }, canActivate:[AuthGuard]},
  // { path: 'artemis/services', component: ArtemisServiceCrudComponent, data: {title: " Service's List"}},
  { path: 'artemis/comment', component: ArtemisCommentCreateComponent, data: { title: 'Artemis Comment ' }, canActivate:[AuthGuard]},
  { path: 'artemis/agencies', component: ArtemisAgencyCrudComponent, data: {title: " Government List"}, canActivate:[AuthGuard]},    
  { path: 'artemis/services', component: ArtemisServiceCrudComponent, data: { title: " Service's List" }, canActivate:[AuthGuard]},
  {
    path: 'artemis/comment',
    component: ArtemisCommentCreateComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    data: {
      title: 'Artemis Comment ',
      authGuardPipe: redirectUnauthorizedToRestricted
    }
  },
  { path: 'artemis/charter/feedback', component: ArtemisUserFeedbackComponent, data: { title: "User Feedback" }, canActivate:[AuthGuard]}, // 
  { path: 'pbris/feedback', component: PbrisUserFeedbackCreateComponent, data: { title: "User Feedback" } , canActivate:[AuthGuard]},
  // { path: 'artemis/charter/inbox-feedback', component: ArtemisInboxUserFeedbackComponent, data: { title: "Inbox User Feedback" } },
  { path: 'artemis/report', component: ArtemisReportsPageComponent, data: { title: 'ARTEMIS Report ' }, canActivate:[AuthGuard]},
  { path: 'artemis/summary-report', component: ReportSummaryComponent, data: { title: 'ARTEMIS Report Summary' }, canActivate:[AuthGuard]},
  { path: 'artemis/results', component: ArtemisSearchResultsPageComponent, data: { title: 'ARTEMIS Search Results ' }, canActivate:[AuthGuard]},
  { 
    path: 'pbris/login',
    component: LoginPbrisComponent,
    data: { title: 'PBRIS Login ' },
    canActivate: [LoginAuthGuard],
  },
  { path: 'registration-pbris', component: RegistrationPbrisComponent, data: { title: 'PBRIS Registration ' }, canActivate:[AuthGuard]},
  { path: 'pbris/advanced-search', component: PbrisAdvancedSearchComponent, data: { title: 'PBRIS Advanced Search' }, canActivate:[AuthGuard]},
  { path: 'pbris/quick-search', component: PbrisQuickAdvSearchComponent, data: { title: 'PBRIS Quick Search' }, canActivate:[AuthGuard]},
  { path: 'account', component: PbrisAccountComponent, data: { title: 'Accounts' }, canActivate:[AuthGuard]},
  { path: 'account/:accountId', component: PbrisAccountUserComponent, data: { title: 'User Account' }, canActivate:[AuthGuard]},
  // { path: 'pbris/account/:accountId/setting', component: PbrisAccountSettingComponent, data: { title: 'PBRIS Account Setting' } }, route not use, changed to modal rather than page
  { path: 'pbris/consultation', component: PbrisConsultationsComponent, data: { title: 'PBRIS Consultations' }, canActivate:[AuthGuard]},
  { path: 'pbris/consultation/view/:id', component: PbrisConsultationsViewComponent, data: { title: 'PBRIS Consultations' }, canActivate:[AuthGuard]},
  { path: 'pbris/regulations', component: RegulationsResultComponent, data: { title: 'PBRIS Regulations' }, canActivate:[AuthGuard]}, 
  { path: 'pbris/regulations/:status', component: RegulationsResultComponent, data: { title: 'PBRIS Regulations' }, canActivate:[AuthGuard]},
  { path: 'pbris/regulations/view/:id', component: RegulationsComponent, data: { title: 'PBRIS Regulations' }, canActivate:[AuthGuard]},
  { path: 'pbris/regulations/view/:from/:id', component: RegulationsComponent, data: { title: 'PBRIS Regulations' }, canActivate:[AuthGuard]},
  { path: 'pbris/regulatory-policy', component: PbrisRegulatoryPolicyComponent, data: { title: 'PBRIS Regulatory Policy' } },
  { path: 'pbris/contact-us', component: PbrisContactUsComponent, data: { title: 'PBRIS Contact Us' } },
  { path: 'pbris/faq', component: PbrisFaqComponent, data: { title: 'PBRIS FAQ' } },
  { path: 'pbris/help', component: PbrisHelpComponent, data: { title: 'PBRIS Help' } },
  { path: 'admin/references/pbris', component: PbrisReferencesComponent, data: { title: 'PBRIS References' }, canActivate:[AuthGuard]},
  { path: 'admin/references/pbris/sector', component: PbrisSectorComponent, data: { title: 'PBRIS References - View Sector' }, canActivate:[AuthGuard]},
  { path: 'agency', component: AgencyControlComponent, data: { title: 'Agency Controls' }, canActivate:[AuthGuard]},
  // { path: 'agency/view/:id', component: AgencyInformationComponent, data: { title: 'Agency Information' } }, not used, agency view in artemis/agency/view/:id
  { path: 'agency/add', component: AgencyAddComponent, data: { title: 'Add Agency' }, canActivate:[AuthGuard]},
  { path: 'agency/view/:agencyId/members', component: AgencyMembersControlComponent, data: { title: 'Agency Members' }, canActivate:[AuthGuard]},
  { path: 'about-us', component: AboutUsComponent, data: { title: 'ARTA - About Us' }, canActivate:[AuthGuard]},
  {
    path: 'email-config', component: EmailConfigurationComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    data: {
      title: 'Email Configuration',
      authGuardPipe: redirectUnauthorizedToRestricted
    },
  },
  { 
    path: 'pbris/regulatory-management', component: RegulatoryManagementComponent,
    canActivate:[PrivatepageguardGuard],
    data: { 
      title: 'PBRIS Regulatory Policy',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    },
  },
  {
    path: 'pbris/upload/existing', component: PbrisUploadExistingRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Upload Existing Regulations',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    },
  },
  {

    path: 'pbris/upload-beta/existing', component: UploadExistingRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Upload Existing Regulations - BETA',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    },
  },
  {

    path: 'pbris/upload-beta/existing/:id', component: UploadExistingRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Upload Existing Regulations - BETA',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    },
  },
  {
    path: 'pbris/upload-beta/existing/comment/:id', component: UploadExistingRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      title: 'PBRIS Upload Existing Regulations - BETA',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    },
  },
  {
    path: 'pbris/upload/complete-existing', component: PbrisUploadExistingCompleteRegulationsComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    data: {
      title: 'PBRIS Upload Phase 2 Existing Regulations',
      authGuardPipe: redirectUnauthorizedToRestricted
    }
  },
  {
    path: 'pbris/upload/notification', component: PbrisRegulatoryNotifFormComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Upload Regulatory Notification Form',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/upload/proposed', component: PbrisUploadProposedRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Upload Phase 2 Proposed Regulations',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/upload/proposed/:id', component: PbrisUploadProposedRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Upload Phase 2 Proposed Regulations',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/upload/proposed/comment/:id', component: PbrisUploadProposedRegulationsComponent,
    canActivate: [PrivatepageguardGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      title: 'PBRIS Upload Phase 2 Proposed Regulations',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/proposed/cris/:regulation_id/:worklist_id', component: CrisCreateComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Phase 2 Proposed Regulations Consultation RIS',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/proposed/cris/:cris_id', component: CrisCreateComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Phase 2 Proposed Regulations Consultation RIS',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/proposed/dris/:regulation_id/:worklist_id/:cris_id', component: DrisCreateComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Phase 2 Proposed Regulations Decision RIS',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/proposed/dris/:dris_id', component: DrisCreateComponent,
    canActivate: [PrivatepageguardGuard],
    data: {
      title: 'PBRIS Phase 2 Proposed Regulations Decision RIS',
      restrictions: [
        AuthGuardTypeFlag.PBRIS_ONLY
      ]
    }
  },
  {
    path: 'pbris/productivity', component: ReportsMainComponent,
    canActivate: [AngularFireAuthGuard,AuthGuard],
    data: {
      title: 'PBRIS Productivity Reports',
      authGuardPipe: redirectUnauthorizedToRestricted
    }
  },
  { path: 'admin/users', component: UserManagementComponent, data: { title: 'User Management' }, canActivate:[AuthGuard]},
  { path: 'admin/controls', component: AdminComponent, data: { title: 'Administrator Dashboard' }, canActivate:[AuthGuard]},
  { path: 'admin/registration-agreements', component: RegistrationAgreementsComponent, data: { title: 'Registration Agreements' }, canActivate:[AuthGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' }, canActivate:[AuthGuard]},
  { path: 'admin/files/:id', component: UploadedFilesComponent, data: { title: 'Uploaded Files' }, canActivate:[AuthGuard]},
  { path: 'restricted', component: RestrictedAccessComponent, data: { title: 'Restricted Page' }},
  {
    path: 'auth/handle', component: CustomAuthHandleComponent, data: {
      title: 'User Management'
    }, canActivate:[AuthGuard]
  },
  { path: '', component: WelcomeComponent, data: { title: 'Welcome' }},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollOffset: [0, 64] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
