<!-- <app-navbar></app-navbar> -->

<div class="">
    <!-- Title -->
    <!-- <div class="jumbotron bg-primary p-3">
        // PBRIS title with search Bar
        <div class="container">
            <div class="row text-end">
                <div class="col">
                    <h1 class="text-white">Search for Regulations </h1>
                    <div class="row justify-content-end m-0">
                        <p class="border border-warning w-50 me-1"></p>
                    </div>
                </div>
                <div class="col-1 my-2">
                    <h1 class="text-warning"><i class="fas fa-archive"></i></h1>
                </div>
            </div>
        </div>
    </div> -->

    <!-- Tabs -->
    <!-- Tabs navs -->
    <ul class="nav nav-tabs border-bottom border-1" id="ex1" role="tablist">
        <li class="nav-item col-12 {{style_theme}} text-center" role="presentation">
            <a class="nav-link rounded-top text-warning disabled" (click)="resetData()" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">
                <h6 class="mb-0 fw-bold"><i class="fas fa-search me-3"></i>Search Existing Regulations</h6>
            </a>
        </li>
    </ul>
    <!-- Tabs navs -->

    <!-- Tabs content -->
    <div class="tab-content {{style_theme}}" id="ex1-content">
        <!-- existing -->
        <div class="tab-pane rounded-bottom fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
            <!-- <h5 class="text-center p-3 bg-gray">Existing Regulations</h5> -->
            <form [formGroup]="searchform" (ngSubmit)="search('existing')">

                <!-- classification -->
                <div class="col-12 px-4 pt-4 text-center">
                    <div class="row input-group">
                        <label class="form-input-header" for="location"  matTooltipPosition="above" matTooltip="Required">CLASSIFICATION</label>
                        <div class="form-group my-3">
                            <div class="form-check form-check-inline" style="margin-right: 1em;">
                                <input mdbRadio class="form-check-input" (change)="selectChange('class',$event)" type="radio" formControlName="classification" name="classification" id="existing_class_business" value="Business">
                                <label class="form-check-label" for="existing_class_business">Business</label>
                            </div>

                            <div class="form-check form-check-inline"  style="margin-left: 1em;">
                                <input mdbRadio class="form-check-input" (change)="selectChange('class',$event)" type="radio" formControlName="classification" name="classification" id="existing_class_nonbusiness" value="Non-Business">
                                <label class="form-check-label" for="existing_class_nonbusiness">Non - business</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Non-busines -->
                <!-- sol life -->
                <div class="row px-4" *ngIf="searchform.value.classification == 'Non-Business'">
                    <div class="col-12 my-2">
                        <label class="form-input-header" for="sol">Stage of Life</label>
                        <select class="form-select" aria-label="Stage of Life" #select_sol (change)="selectChange('sol',select_sol.value)">
                            <option selected readonly value="">- select -</option>
                            <option *ngFor="let entry of sol" [value]="entry.stage">{{entry.stage}}</option>
                        </select>
                    </div>
                    <div class="col-12 my-2">
                        <label class="form-input-header" for="life">Life Event</label>
                        <!-- <input type="text" class="form-control mx-2" readonly placeholder="Life Event" formControlName="lifeevent"> -->
                        <select class="form-select" aria-label="Life Event" #select_lifeevent (change)="selectChange('life',select_lifeevent.value)">
                            <option selected readonly value="">- select -</option>
                            <option *ngFor="let entry of lifeGrouped" [value]="entry">{{entry}}</option>
                        </select>
                    </div>
                </div>

                <!-- Busines -->
                <!-- sector div -->
                <div class="row px-4" *ngIf="searchform.value.classification == 'Business'">
                    <!-- <label for="location">Business:</label> -->
                    <div class="col-12 my-2">
                        <label class="form-input-header" for="sector">Sector</label>
                        <select class="form-select" aria-label="Sector" #select_sector (change)="selectChange('sect',select_sector.value)">
                            <option selected readonly value="">- select -</option>
                            <option *ngFor="let entry of sectors" [value]="entry.name">{{entry.name}}</option>
                        </select>
                    </div>

                    <div class="col-12 my-2">
                        <label class="form-input-header" for="division">Division</label>
                        <select class="form-select select-division" aria-label="Division" #select_division (change)="selectChange('div',select_division.value)">
                            <option selected readonly value="">- select -</option>
                            <option *ngFor="let entry of divisions" [value]="entry.name" class="option-division">{{entry.name}}</option>
                        </select>
                    </div>

                    <!-- sob case -->
                    <div class="col-12 my-2">
                        <label class="form-input-header" for="sob">Stage of Business</label>
                        <select class="form-select" aria-label="Stage of Business" #select_sob (change)="selectChange('sob',select_sob.value)">
                            <option selected readonly value="">- select -</option>
                            <option *ngFor="let entry of sobs " [value]="entry.name">{{entry.name}}</option>
                        </select>
                    </div>

                    <div class="col-12 my-2">
                        <label class="form-input-header" for="case">Case Use</label>
                        <select class="form-select" aria-label="Case Use" #select_case (change)="selectChange('case',select_case.value)">
                            <option selected readonly value="">- select -</option>
                            <option *ngFor="let entry of cases" [value]="entry.name">{{entry.name}}</option>
                        </select>
                    </div>
                </div>

                <p class="border-top border-1 my-4"></p>

                <!-- Form button -->
                <div class="form-group text-center px-4 pb-4">
                    <button type="reset" (click)="resetData()" class="btn btn-light rounded-9 m-1">Reset Form</button>
                    <button type="submit" class="btn btn-warning rounded-9 m-1"><i class="fas fa-search me-2"></i>Search</button>
                </div>
            </form>
        </div>

    </div>
    <!-- Tabs content -->
</div>