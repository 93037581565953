import { L } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { intlFormat } from 'date-fns';
import { post } from 'jquery';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CharterStatusCitizensCharter, GovernmentAccountSubtype, ServiceAssessmentCitizensCharter } from 'src/app/entities/worklist';
import { ModalManagerComponent } from 'src/app/modal-manager/modal-manager.component'

@Component({
  selector: 'app-artemis-home-page-header-board',
  templateUrl: './artemis-home-page-header-board.component.html',
  styleUrls: ['./artemis-home-page-header-board.component.scss']
})
export class ArtemisHomePageHeaderBoardComponent implements OnInit {
  modalRef?: MDBModalRef;

  style_theme: any = 'theme-default'; //current theme

  userType: any = sessionStorage.getItem("user_type");
  counterCount1: any;
  counterCount2: any;
  carouselImages: any = [];

  constructor(private afs: AngularFirestore, public modalService: MDBModalService, private router: Router) { }

  ngOnInit(): void {
    if (this.userType == "agency") {
      // load counter
      this.loadAgencyCounters();
      this.loadnewAgencyCounters();
      this.getLatestCitizensCharter();
      this.getLatestOnReview();
    }
    else if (this.userType == "arta") {
      // load counter
      this.loadARTACounters();

      this.loadnewARTACounters();
      this.loadCitizensCharterCount();


    }
    else {
      // load carousel
    }




  }


  async setTheme() {
    switch (sessionStorage.getItem("user_type")) {
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  ViewDocument(data: any) {

    if (data.id) {

      this.router.navigate(["/artemis/citizen-charter/view/report/"], {
        queryParams: {
          cc_id: data.id,
          edition: data.edition
        }
      })

    }
  }

  loadAgencyCounters() {
    // set initial data
    let count1 = 0;
    let count2 = 0;
    this.counterCount1 = count1.toString();
    this.counterCount2 = count2.toString();
  }

  callCitizensRightsModal() {
    this.modalRef = this.modalService.show(ModalManagerComponent,
      {
        backdrop: 'static',
        data: {
          header_content: {
            title: "Section 9 of RA 11032"
          },
          body_content: {
            citizensrightsmessage: true
          },
          buttons: {
            cancel: "Close"
          },
        }
      });
  }


  ///ARTA REPORTS

  postedServices = 0;
  pendingServices = 0;
  totalServices = 0;
  delistedServices = 0;
  interagencyServices = 0;
  totalAgencies = 0;

  suc = 0;
  hosp = 0;
  lgu = 0;
  gocc = 0;
  nga = 0;
  concom = 0;
  lwd = 0;

  sucServices = 0;
  hospServices = 0;
  lguServices = 0;
  goccServices = 0;
  ngaServices = 0;
  concomServices = 0;
  lwdServices = 0;

  postedcharters = 0;
  unpostedcharters = 0;

  loadCitizensCharterCount() {


    this.afs.collection('citizens-charter-phase-02')
      .snapshotChanges().pipe()
      .subscribe((data: any) => {
        let posted = 0;
        let unposted = 0;

        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          if (item.status == "POSTED") posted++;
          else unposted++;

        });

        this.postedcharters = posted;
        this.unpostedcharters = unposted;

      })
  }

  loadnewARTACounters() {


    this.afs.collection('Agency').snapshotChanges()
      .subscribe((data: any) => {


        let posted = 0;
        let pending = 0;
        let total = 0;

        this.postedServices = posted;
        this.pendingServices = pending;
        this.totalServices = total;
        this.totalAgencies = 0;


        this.suc = 0;
        this.hosp = 0;
        this.lgu = 0;
        this.gocc = 0;
        this.nga = 0;
        this.concom = 0;
        this.lwd = 0;

        this.sucServices = 0;
        this.hospServices = 0;
        this.lguServices = 0;
        this.goccServices = 0;
        this.ngaServices = 0;
        this.concomServices = 0;
        this.lwdServices = 0;

        //this.totalAgencies = data.count;

        data.forEach((info: any) => {
          this.totalAgencies++;
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;



          if (item.agencyDetails?.agencyCategory?.abbreviation) {


            switch (item.agencyDetails.agencyCategory.abbreviation) {

              case "HOSP":
                this.hosp++;
                break;
              case "SUC":
                this.suc++;
                break;
              case "LWD":
                this.lwd++;
                break;
              case "CON COM":
                this.concom++;
                break;
              case "NGA":
                this.nga++;
                break;
              case "GOCC":
                this.gocc++;
                break;
              case "LGU":
                this.lgu++;
                break;
              default:
                this.lgu++;
                break;

            }

          }


          let inboxFilter: QueryFn;

          inboxFilter = (filter) =>
            filter.where('fromAgencyId', '==', item.id);

          this.afs.collection('Services Phase 02', inboxFilter)
            .snapshotChanges().subscribe((data2: any) => {

              data2.forEach((info2: any) => {

                let service: any = info2.payload.doc.data();
                if (service.posted_status == "POSTED") posted++;
                else pending++;
                total++;

                /**
                 * 
                 *   if(item.agencyDetails?.agencyCategory?.abbreviation){

                  switch (item.agencyDetails.agencyCategory.abbreviation) {

                    case "HOSP":
                      this.hospServices++;
                      break;
                    case "SUC":
                      this.sucServices++;
                      break;
                    case "LWD":
                      this.lwdServices++;
                      break;
                    case "CON COM":
                      this.concomServices++;
                      break;
                    case "NGA":
                      this.ngaServices++;
                      break;
                    case "GOCC":
                      this.goccServices++;
                      break;
                    case "LGU":
                      this.lguServices++;
                      break;
  
                  }

                }

                 */



              })

              this.postedServices = posted;
              this.pendingServices = pending;
              this.totalServices = total;
            })
        })

      })



  }


  ///ARTA REPORTS


  ///Agency REPORTS
  agencyServicesTotal = 0;
  agencyServicesDelisted = 0;
  agencyCCStatus: any = {
    Status: "",
    LatestCC: "",
    edition: "",
    id: "",
    CConReview: "",
    edition2: ""
  }
  agencyClassification: any = {
    Simple: 0,
    Complex: 0,
    HighlyTechnical: 0
  }

  ordinals: string[] = ['th', 'st', 'nd', 'rd'];

  //format certificate of compliance edition and version
  transform(n: number, keepNumber: boolean = true) {
    let v = n % 100;
    return (keepNumber ? n : '') + (this.ordinals[(v - 20) % 10] || this.ordinals[v] || this.ordinals[0]);
  }

  loadnewAgencyCounters() {



    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter.where('fromAgencyId', '==', sessionStorage.getItem('agency_id'));

    this.afs.collection('Services Phase 02', inboxFilter)
      .snapshotChanges()
      .subscribe((data: any) => {

        this.agencyServicesTotal = 0;
        this.agencyServicesDelisted = 0;
        this.agencyClassification.Simple = 0;
        this.agencyClassification.Complex = 0;
        this.agencyClassification.HighlyTechnical = 0;

        data.forEach((info: any) => {

          let item: any = info.payload.doc.data();
          if (item.posted_status?.toUpperCase() == "POSTED") this.agencyServicesTotal++;

          if (item.posted_status == "DELISTED") this.agencyServicesDelisted++;

          switch (item.serviceDetails.classification) {
            case "Simple":
              this.agencyClassification.Simple++;
              break;
            case "Complex":
              this.agencyClassification.Complex++;
              break;
            case "Highly Technical":
              this.agencyClassification.HighlyTechnical++;
              break;
          }

        })
      });

  }

  getLatestOnReview() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .orderBy('date', 'desc')
        .limit(1);


    this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .snapshotChanges().pipe()
      .subscribe({

        next: (cc) => {
          this.agencyCCStatus.CConReview = ""
          this.agencyCCStatus.edition2 = ""

          cc.map((info) => {
            if (info.payload.doc.exists) {
              let ccData: any = info.payload.doc.data();
              ccData.id = info.payload.doc.id;
              this.agencyCCStatus.CConReview = ccData.status;
              this.agencyCCStatus.edition2 = ccData.dateSubmitted.toDate().getFullYear() + " " + this.transform(ccData.edition, true) + " Edition"

            }
          })
        }
      }

      )
  }


  getLatestCitizensCharter() {
    let inboxFilter: QueryFn;

    inboxFilter = (filter) =>
      filter
        .where('fromAgencyId', '==', sessionStorage.getItem('agency_id'))
        .where('status', '==', CharterStatusCitizensCharter.POSTED)
        .orderBy('date', 'desc')
        .limit(1);


    this.afs
      .collection('citizens-charter-phase-02', inboxFilter)
      .snapshotChanges().pipe()
      .subscribe({
        next: (cc) => {
          cc.map((info) => {

            this.agencyCCStatus.Status = ""
            this.agencyCCStatus.LatestCC = ""
            this.agencyCCStatus.edition = ""
            this.agencyCCStatus.id = ""

            if (info.payload.doc.exists) {
              let ccData: any = info.payload.doc.data();
              ccData.id = info.payload.doc.id;
              this.agencyCCStatus.Status = ccData.status;
              if (ccData.status == CharterStatusCitizensCharter.POSTED) {
                this.agencyCCStatus.LatestCC = ccData.date_posted.toDate().getFullYear() + " " + this.transform(ccData.edition, true) + " Edition"
              }
              this.agencyCCStatus.id = ccData.id;
              this.agencyCCStatus.edition = ccData.edition;
            }
          });

        }
      }

      );
 
  }



  ///Agency REPORTS

  loadAgencyInfo() {

  }

  loadARTACounters() {
    // set initial data
    let count1 = 0;
    let count2 = 0;
    this.counterCount1 = count1.toString();
    this.counterCount2 = count2.toString();
    // count data from firestore
    this.afs.collection("Reports").snapshotChanges().subscribe( //, filter => filter.orderBy(orderByColumn)
      (data: any) => {
        data.forEach((info: any) => {
          let item: any = info.payload.doc.data();
          item.id = info.payload.doc.id;

          if (item.compliant) {
            count1++;
            this.counterCount1 = count1.toString();
          }

          if (item.status && item.status.toLowerCase() == 'pending') {
            count2++;
            this.counterCount2 = count2.toString();
          }
        });
      });
  }

  firestoreQuery() {
  }

}
