import { Component, OnInit } from '@angular/core';
import { formatDate } from "@angular/common";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-artemis-service-process-flow-page',
  templateUrl: './artemis-service-process-flow-page.component.html',
  styleUrls: ['./artemis-service-process-flow-page.component.scss']
})
export class ArtemisServiceProcessFlowPageComponent implements OnInit {

  style_theme: any = 'theme-default'; //current theme

  userType: any = sessionStorage.getItem("user_type");

  /** */
  isSidebarVisible: boolean = true;

  /** page elements */
  pageinfo : any;



  constructor(private route: ActivatedRoute, private router: Router, private afs: AngularFirestore) {
    this.loadPageInformation(this.route.snapshot.params.id);
  }

  ngOnInit(): void {
    this.incrementVisitCount (this.route.snapshot.params.id);
    this.setTheme();
  }

  async setTheme() {
    switch(sessionStorage.getItem("user_type")){
      case 'arta':
        this.style_theme = 'theme-arta';
        break;
      case 'agency':
        this.style_theme = 'theme-agency';
        break;
      default:
        this.style_theme = 'theme-default';
    }
  }

  toggleSidebar() {
    if(this.isSidebarVisible){
      this.isSidebarVisible = false;
    }
    else{
      this.isSidebarVisible = true
    }
  }

  async loadPageInformation (id: any) {
    const pageRef = this.afs.collection("Services").doc(id);
    /**
     * Fields
     */
    pageRef.snapshotChanges().subscribe(
      (data: any) => {
        this.pageinfo = data.payload.data();
      });
  }

  async incrementVisitCount (id: any) {
    const pageRef = this.afs.collection("Services").doc(id);
    

    let incVisit = 1 ;
    alert(await this.pageinfo.visit_count)
    pageRef.update({
      visit_count: incVisit
    }).then(()=>{
      //
      alert("increment successfull, vis:"+incVisit)
    });
  }


}
