<!----

    <div class="d-flex justify-content-center align-self-center">
      <div class="spinner-grow" role="status" *ngIf="loading">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>

<div *ngIf="!loading">
  <form [formGroup]="charterSearchForm" class="service-finder-body">
    <input
      type="text"
      formControlName="keyword"
      (input)="filterResults()"
      class="form-control w-100 service-finder-input"
      placeholder="Keyword"
    />
  </form>
  
  <div class="table-responsive" style="max-height: 500px">
    <table
      class="table table-light table-hover service-results shadow table-responsive tab-pane border border-dark border-top-0 border-right-0 border-left-0 border-bottom bg-light rounded-bottom"
    >
      <thead>
        <tr class="service-results-header">
          <th>
            <h1 style="font-weight: bold !important">
              Citizen Charters Found
              <span style="color: red !important">{{ resultscount }}</span>
            </h1>
          </th>
          <th class="sort-button">
            <button class="btn btn-light">⇅ AZ</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of filtered_results">
          <td>
            <div class="row">
              <div class="col">
                <h4 class="list-header">{{ result.name }}</h4>
              </div>
            </div>
            <div>
              <h5>Date: {{ result.date}}</h5>
            </div>
            <div>
              <h5>
                organization grouping 01: {{ result.organization_grouping_01 }}
              </h5>
            </div>
            <div class="row">
              <div class="col">
                organization level 00: {{ result.organization_level_00 }}
              </div>
              <div class="col">
                organization level 01: {{ result.organization_level_01 }}
              </div>
              <div class="col">
                organization level 02: {{ result.organization_level_02 }}
              </div>
              <div class="col">
                organization level 03: {{ result.organization_level_03 }}
              </div>
            </div>
          </td>
          <td class="service-results-buttons">
            <!-- RWL 20220525, need to be reworked because of CC encoding changes -->
            <!-- <a
              routerLink="/artemis/charter/newcharter/{{ result.id }}"
              class="btn btn-light"
              >
              <i class="far fa-list-alt fa-5x"></i>
            </a> -->
            <!--/artemis/charter/preview/{{result.id}}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <div class="btn-group m-3">
          <div class="btn btn-sm btn-light">Previous</div>
          <div class="btn btn-sm btn-light">Next</div>
        </div>
      </tfoot>
    </table>
  </div>
</div>
-->


<mat-card>
  <mat-card-header class="mb-2">
      <mat-card-title class="text-primary"><strong>Citizen's Charter Submissions</strong></mat-card-title>
      <!--<mat-card-subtitle>Start</mat-card-subtitle>-->
      
  </mat-card-header>
  
  <mat-card-content>
    <form [formGroup]="charterSearchForm">
      <div class="row">
          <div class="col-4">
              <mat-form-field>
                  <mat-label>Select date submitted</mat-label>
                  <mat-date-range-input formGroupName="rangeDateSubmitted" [rangePicker]="pickerDateSubmitted">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerDateSubmitted"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerDateSubmitted></mat-date-range-picker>
                
                  <!-- <mat-error *ngIf="charterSearchForm.controls.rangeDateSubmitted.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error> -->
                  <!-- <mat-error *ngIf="charterSearchForm.controls.rangeDateSubmitted.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
                </mat-form-field>
          </div>
          <div class="col-8">
            <mat-form-field>
                <mat-label>Select date posted</mat-label>
                <mat-date-range-input formGroupName="rangeDatePosted" [rangePicker]="pickerDatePosted">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="pickerDatePosted"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerDatePosted></mat-date-range-picker>
              
                <!-- <mat-error *ngIf="charterSearchForm.controls.rangeDatePosted.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error> -->
                <!-- <mat-error *ngIf="charterSearchForm.controls.rangeDatePosted.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
              </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <mat-form-field>
            <mat-label>Select agency category</mat-label>
            <mat-select (selectionChange)="filterAgency()" formControlName="agencyCategory">
              <mat-option value="" selected>All</mat-option>
              <mat-option *ngFor="let row of valueSuggestions.agency_category" value="{{row.id}}">
                {{row.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Select mother agency</mat-label>
            <mat-select (selectionChange)="filterAgency()" formControlName="motherAgency">
              <mat-option value="" selected>All</mat-option>
              <mat-option *ngFor="let row of valueSuggestions.mother_agency" value="{{row.id}}">
                {{row.motheragency}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>Select agency</mat-label>
            <mat-select formControlName="agency">
              <mat-option value="" selected>All</mat-option>
              <mat-option *ngFor="let row of valueSuggestions.agency_filtered" value="{{row.id}}">
                {{row.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <button  (click)="sendSearchFilters()" class="btn btn-warning btn-block">
            APPLY FILTER
          </button>
        </div>
      </div>
    </form>

      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1" class="table table-striped table-responsive">
          <thead>
              <tr>
                  <th class="table-header">Date Submitted</th>
                  <th class="table-header" style="width: 40%;">Agency</th>
                  <th class="table-header">Citizen's Charter - Handbook</th>
                  <th class="table-header">Date Posted</th>


              </tr>
          </thead>
          <tbody>

              <tr *ngFor="let row of ccCollections;">

                  <td>
                      {{ row.dateSubmitted.toDate() | date: "yyyy-MMM-dd hh:mm" }}
                  </td>
                  <td>
                      {{row.fromAgencyName}}
                  </td>
                  <td>
                    <u class="service-link text-primary" (click)="ViewCCDocument(row)">{{ transform(row.edition,row.dateSubmitted.toDate().getFullYear())}}</u>
                  </td>
                  <td>
                      <u>{{ row.date_posted.toDate() | date: "yyyy-MM-dd hh:mm" }}</u>
                  </td>

              </tr>
          </tbody>
      </table>
  </mat-card-content>
</mat-card>