<div class="mx-auto">
    <div class = "btn-group float-end m-3">
    <a class = "btn btn-outline-dark" href = "https://us-central1-arta-respond.cloudfunctions.net/app/pdf"
    target=”_blank” rel=”noreferrer”>Generate PDF Report</a>
    <a class = "btn btn-outline-dark" href = "https://us-central1-arta-respond.cloudfunctions.net/app/excel"
    target=”_blank” rel=”noreferrer”>Generate Excel Report</a>
</div>
    <table class = "table table-bordered" data-sheet-name="Summary Report">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - STOCK OF EXISTING REGULATION</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                    <app-date-picker 
                    (datePickerEvent) = "receivedDatePicker($event)"
                    ></app-date-picker>{{dateInfo}}</th>    
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">TOTAL COUNT:</td>
                <td class = "h3">{{reg_count.length}} </td>
            </tr>
            <tr>
                <td class = "table-warning">PENDING:</td>
                <td></td>
            </tr>
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0" data-sheet-name="Count per Status">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">COUNT PER STATUS:</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td>DRAFT:</td>
                                <td class = "h3">{{draft_count.length}} </td>
                            </tr>
                            <tr>
                                <td>FOR VALIDATION:</td>
                                <td class = "h3">{{validation_count.length}} </td>
                            </tr>
                            <tr>
                                <td>FOR ARTA REVIEW:</td>
                                <td class = "h3">{{arta_review_count.length}} </td>
                            </tr>
                            <tr>
                                <td>FOR MODIFICATION:</td>
                                <td class = "h3">{{modification_count.length}} </td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>

          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0" data-sheet-name="Count per Effectivity">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">COUNT PER EFFECTIVITY:</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr></tr>
                            <tr>
                                <td>IN EFFECT:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>REPEALED:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>AMENDED:</td>
                                <td></td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>
        </tbody>
        
    </table>

    <table class = "table table-bordered" data-sheet-name="Count per EODB Tagging">
        <thead class = "table">
            <tr class =" table-dark">
                <th colspan = "4">COUNT PER EODB TAGGING:</th>
            </tr>
            <tr class = "table-warning text-center">
                <th colspan = "2">
                    BUSINESS:
                </th>
                <th colspan = "2">
                    NON-BUSINESS:
                </th>
            </tr>
           
        </thead>
        <tbody>
            <tr>
                <th>SECTOR</th>
                <td>(display count per sector)</td>
                <th>STAGE OF LIFE</th>
                <td>(count per stage of life)</td>
            </tr>
            <tr>
                <th>STAGE OF BUSINESS</th>
                <td>(display count per stage)</td>
                <th>CASE USE</th>
                <td>(count per transaction)</td>
            </tr>
        
        </tbody>
        
    </table>
</div>