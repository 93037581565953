import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PbrisAccountUserComponent } from './../pbris-account-user/pbris-account-user.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationsEmailer } from 'src/app/service/notifications-emailer.service';

@Component({
    selector: 'app-pbris-account-setting.component',
    templateUrl: './pbris-account-setting.component.html',
    styleUrls: ['./pbris-account-setting.component.scss']
})
export class PbrisAccountSettingComponent  implements OnInit {

    isAdmin:boolean = false;
    isAgencyMember:boolean = false;
    isArta:boolean = false;
    userAgency:any;
    userId:any;
    userEmail:any;
    
    constructor(
        public dialogRef: MatDialogRef<PbrisAccountUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data:any,
        private afs: AngularFirestore,
        private nE: NotificationsEmailer,
    ) {}

    ngOnInit(): void {
        this.isAdmin = this.data.isAdmin
        this.isAgencyMember = this.data.isAgencyMember
        this.isArta = this.data.isArta
        this.userAgency = this.data.usersAgency
        this.userId = this.data.id
        this.userEmail = this.data.email
        this.setToggles()
    }

    setToggles(){
        if(this.data.settings){
            
            if(this.data.settings.regulations && this.data.settings.regulations.create)
                this.toggles.regulations.create = this.data.settings.regulations.create
            if(this.data.settings.consultations && this.data.settings.consultations.create)
                this.toggles.consultations.create = this.data.settings.consultations.create
            if(this.data.settings.citizenscharters && this.data.settings.citizenscharters.create)
                this.toggles.citizenscharters.create = this.data.settings.citizenscharters.create
            if(this.data.settings.services && this.data.settings.services.create)
                this.toggles.services.create = this.data.settings.services.create
            
            if(this.isAdmin || this.isAgencyMember || this.isArta){
                if(this.data.settings.regulations && this.data.settings.regulations.review)
                    this.toggles.regulations.review = this.data.settings.regulations.review
                if(this.data.settings.consultations && this.data.settings.consultations.review)
                    this.toggles.consultations.review = this.data.settings.consultations.review
                if(this.data.settings.citizenscharters && this.data.settings.citizenscharters.review)
                    this.toggles.citizenscharters.review = this.data.settings.citizenscharters.review
                if(this.data.settings.services && this.data.settings.services.review)
                    this.toggles.services.review = this.data.settings.services.review
            }
            
            this.toggles.regulations.main = (this.toggles.regulations.create || this.toggles.regulations.review) ?  true : false;
            this.toggles.consultations.main = (this.toggles.consultations.create || this.toggles.consultations.review) ?  true : false;
            this.toggles.citizenscharters.main = (this.toggles.citizenscharters.create || this.toggles.citizenscharters.review) ?  true : false;
            this.toggles.services.main = (this.toggles.services.create || this.toggles.services.review) ?  true : false;
            
            if(this.isAdmin || this.isArta){
                if(this.data.settings.agency && this.data.settings.agency.complaint)
                    this.toggles.agency.complaint = this.data.settings.agency.complaint
                if(this.data.settings.agency && this.data.settings.agency.create)
                    this.toggles.agency.create = this.data.settings.agency.create
                this.toggles.agency.main = (this.toggles.agency.complaint || this.toggles.agency.create) ? true : false
            }
            if(this.isAdmin){
                if(this.data.settings.worklist && this.data.settings.worklist.create)
                    this.toggles.admin.worklist.create = this.data.settings.worklist.create
                if(this.data.settings.worklist && this.data.settings.worklist.review)
                    this.toggles.admin.worklist.review = this.data.settings.worklist.review
                if(this.data.settings.accounts && this.data.settings.accounts.create)
                    this.toggles.admin.accounts.create = this.data.settings.accounts.create
                if(this.data.settings.accounts && this.data.settings.accounts.review)
                    this.toggles.admin.accounts.review = this.data.settings.accounts.review
                this.toggles.admin.main = (this.toggles.admin.worklist.create || this.toggles.admin.accounts.create || this.toggles.admin.worklist.review || this.toggles.admin.accounts.review) ? true : false
            }
        }
    }

    toggles:any = {
        regulations:{
            main:false,
            create:false,
            review:false,
        },
        consultations:{
            main:false,
            create:false,
            review:false,
        },
        citizenscharters:{
            main:false,
            create:false,
            review:false,
        },
        services:{
            main:false,
            create:false,
            review:false,
        },

        // admin / arta
        agency: {
            main: false,
            create:false,
            complaint:false,
        },

        //admin
        admin:{
            main:false,
            worklist:{
                create: false,
                review: false,
            },
            accounts: {
                create: false,
                review: false,
            }
        },
        
    }
  

    toggleMain(mainName:any){
        if(mainName === 'regulations' || mainName === 'consultations' || mainName === 'citizenscharters' || mainName === 'services'){
            this.toggles[mainName].main = !this.toggles[mainName].main
            this.toggles[mainName].create = this.toggles[mainName].main
            if(this.isAdmin || this.isAgencyMember || this.isArta)
                this.toggles[mainName].review = this.toggles[mainName].main
            console.log(this.toggles[mainName])
        }
        else if(mainName === 'agency' && (this.isAdmin  || this.isArta)){
            this.toggles[mainName].main = !this.toggles[mainName].main
            this.toggles[mainName].create = this.toggles[mainName].main
            this.toggles[mainName].complaint = this.toggles[mainName].main
        }
        else if(mainName === 'admin' && this.isAdmin){
            this.toggles[mainName].main = !this.toggles[mainName].main
            this.toggles[mainName].worklist.create = this.toggles[mainName].main
            this.toggles[mainName].worklist.review = this.toggles[mainName].main
            this.toggles[mainName].accounts.create = this.toggles[mainName].main
            this.toggles[mainName].accounts.review = this.toggles[mainName].main
        }
    }

    onSubmit(){
        const newSettings:any = {
            regulations:{
                create: this.toggles.regulations.create,
                review: this.toggles.regulations.review,
            },
            consultations:{
                create: this.toggles.consultations.create,
                review: this.toggles.consultations.review,
            },
            citizenscharters:{
                create: this.toggles.citizenscharters.create,
                review: this.toggles.citizenscharters.review,
            },
            services:{
                create: this.toggles.services.create,
                review: this.toggles.services.review,
            },
            // admin / arta
            agency: {
                create: this.toggles.agency.create,
                complaint: this.toggles.agency.complaint,
            },
            //admin
            worklist:{
                create: this.toggles.admin.worklist.create,
                review: this.toggles.admin.worklist.review,
            },
            accounts: {
                create: this.toggles.admin.accounts.create,
                review: this.toggles.admin.accounts.review,
            }
        }

        this.afs.collection('Users').doc(this.userId).update({
            settings: newSettings,
        })
        .then(()=>{
            console.log("Seccesfully updated settings ")
            
            this.dialogRef.close({settings: newSettings, success: true})
        })
        .catch(err=>{
            console.error("Failed to updated settings ",err)
        })
    }

    // getNotifEmailData(){
    //     const data = {
    //         newSubscriptions: [{name:'',ctr:0}],
    //         link:'',
    //     }
    //     this.toSendEmail([this.userEmail],data)
    // }

    // toSendEmail(emails:any, data:any){
    //     console.log('sending email')
    //     this.nE.sendEmailNotiftoUser(emails,2,data)
    //     .subscribe({
    //         next:(apiResponse)=>{
    //             console.log('PbrisAccountComponent emailer: ',apiResponse)
    //         },
    //         error:(err)=>{
    //             console.error('PbrisAccountComponent emailer error: ',err)
    //         }
    //     })
    // }
}