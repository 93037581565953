import { AngularFireStorage } from '@angular/fire/compat/storage';
import { filter, first } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import {Query, QueryFn } from '@angular/fire/compat/firestore';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-artemis-view-agency-services-details',
  templateUrl: './agency-services.component.html',
  styleUrls: ['./agency-services.component.scss']
})
export class ArtemisViewAgencyServicesComponent implements OnInit {
  services_columns = ['service']
  agencyServicesDataSource: MatTableDataSource<any>;
  
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  loadedServices:any[] = []


  @Input('agency_doc') doc_id:any;
  @Input('isAgencyMember') isAgencyMember:any;
  @Input('isAdmin') isAdmin:any;

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('')
  })

  constructor(
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadServices(this.doc_id)
    //console.log('aid',this.doc_id);
  }

  loadServices(agencyId:any){
    if(agencyId){
      this.afs.collection('Services Phase 02',filter=>this.filterFunc(filter,agencyId)).snapshotChanges()
      .subscribe({
        next:(result)=>{
          //console.log('result L: ',result.length)
          this.loadedServices = []
          result.map((data)=>{
            let service:any = data.payload.doc.data()
            service.id = data.payload.doc.id
            this.loadedServices.push(service)
          })
          //console.log('result: ',this.loadedServices)
          this.refreshTable()
        },
        error:(err)=>{
          console.error('Error: ',err)
        }
      })
    }
  }

  filterFunc(filter:any,agencyId:any){
    let query:Query = filter
    //console.log('isAgencyMember: ',this.isAgencyMember)
   // console.log('isAdmin: ',this.isAdmin)
    if(this.isAgencyMember == false && this.isAdmin == false) query = query.where('is_posted', '==', true)
    query = query.where('fromAgencyId', '==', agencyId)
    return query
  }

  search(event:Event){
    let searchValue = (event.target as HTMLInputElement).value
    this.agencyServicesDataSource.filter = searchValue.trim().toLowerCase()
  }

  resetSandP(){
    this.agencyServicesDataSource.paginator = this.paginator
    this.agencyServicesDataSource.sort = this.sort
    this.settingFilters()
  }

  settingFilters(){
    this.agencyServicesDataSource.filterPredicate = (option:any, searchValue:string): boolean =>{
      
      // return (option.fromOfficer)?.trim().toLowerCase().includes(searchValue)
      // || (option.fromAgency)?.trim().toLowerCase().includes(searchValue)
      // || (option.documentTitle)?.trim().toLowerCase().includes(searchValue)
      // || (option.status)?.trim().toLowerCase().includes(searchValue)
      if(searchValue){
        return (option.serviceDetails.service_name)?.trim().toLowerCase().includes(searchValue)
      }else return false
    }
  }

  refreshAll(){
    this.searchForm.reset()
    this.loadServices(this.doc_id)
  }

  refreshList(){
    this.searchForm.reset()
    this.refreshTable()
  }

  refreshTable(){
    this.agencyServicesDataSource = new MatTableDataSource<any>(this.loadedServices)
    this.resetSandP()
  }

  /*goToService(serviceId:any){
    this.router.navigate(['/artemis/view/detail',serviceId])
  }*/
}



