import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productivity-consultation',
  templateUrl: './productivity-consultation.component.html',
  styleUrls: ['./productivity-consultation.component.scss']
})
export class ProductivityConsultationComponent implements OnInit {
  
  dateInfo: any;
  
  constructor() { }

  ngOnInit(): void {
  }
  
  receivedDatePicker(p:any){
    this.dateInfo = p;
  }

}
