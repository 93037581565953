import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CurrencyPipe, DecimalPipe, JsonPipe, Location } from '@angular/common';
import { C, COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  EffectivityCitizensCharter,
  GovernmentAccountSubtype,
  PostedServiceStatusCitizensCharter,
  RemarkResolvedStatusCitizensCharter,
  WorklistStatusCitizensCharter,
} from 'src/app/entities/worklist';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { filter, first, map, startWith, take, tap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewRequirementsModalComponent } from './new-requirements-modal/new-requirements-modal.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ClientStepNode } from '../artemis-new-charter-client-service-modal/client-step';
import { NewClientStepsModalComponent } from './new-client-steps-modal/new-client-steps-modal.component';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { atLeastOne } from 'src/app/validators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { MatStepper } from '@angular/material/stepper';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';
import { ArtemisCustomDialogComponent } from 'src/app/artemis/artemis-custom-dialog/artemis-custom-dialog.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import Swal from 'sweetalert2';
import { truncate } from 'fs';
import {
  RequirementType,
  RequriementsService,
} from 'src/app/service/requriements.service';
import { ReuseLoginComponent } from 'src/app/modal-manager/reuse-login/reuse-login.component';
import { error } from 'console';
import { GovServiceService } from 'src/app/service/gov-service.service';
import { exit } from 'process';
import { ArtemisOfficeGroupingComponent } from 'src/app/artemis/artemis-view/artemis-office-grouping/artemis-office-grouping.component';
import { roLocale } from 'ngx-bootstrap/chronos';
import { EmailerService } from 'src/app/service/emailer.service';
import { ServiceHistoryService } from 'src/app/service/service-history.service';
enum EntityBuilderIndex {
  CLIENT_STEP_INFO = 1,
  AGENCY_ACTION = 2,
  REQUIREMENT = 3,
  EODB_TAG = 4,
  AGENCY_ACTION_PERSON_RESPONSIBLE = 5,
  CLIENT_STEP_STANDARD_FEE = 6,
  CLIENT_STEP_LIST_OF_POSSIBLE_FEES_OR_FORMULAS = 7,
  REQUIREMENT_TYPE_DOCUMENT = 8,
  REQUIREMENT_TYPE_DOCUMENT_ORIGINAL = 9,
  REQUIREMENT_TYPE_DOCUMENT_PHOTO = 10,
  REQUIREMENT_TYPE_DOCUMENT_ELECTRONIC = 11,
  REQUIREMENT_TYPE_OPERATOR = 12,
  REQUIREMENT_TYPE_OPERATOR_AND = 13,
  REQUIREMENT_TYPE_OPERATOR_OR = 14,
  REQUIREMENT_TYPE_PARENTHESIS = 15,
  REQUIREMENT_TYPE_PARENTHESIS_OPEN = 16,
  REQUIREMENT_TYPE_PARENTHESIS_CLOSE = 17,
}

enum DialogType {
  Success = 0,
  Danger = 1,
  Warning = 2,
  Info = 3,
}

enum MessageType {
  MESSAGE = 0,
  DIALOG = 1,
}

@Component({
  selector: 'app-new-service-modal',
  templateUrl: './new-service-modal.component.html',
  styleUrls: ['./new-service-modal.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class NewServiceModalComponent implements OnInit {
  //variables

  operatingHours: any[] = [
    { value: '7:00 AM - 4:00 PM', viewValue: '7:00 AM - 4:00 PM' },
    { value: '7:30 AM - 4:30 PM', viewValue: '7:30 AM - 4:30 PM' },
    { value: '8:00 AM - 5:00 PM', viewValue: '8:00 AM - 5:00 PM' },
    { value: '8:30 AM - 5:30 PM', viewValue: '8:30 AM - 5:30 PM' },
    { value: '9:00 AM - 6:00 PM', viewValue: '9:00 AM - 6:00 PM' },
    { value: '24/7', viewValue: '24/7' },
    { value: 'Others', viewValue: 'Others' },
  ];

  hours_per_day : any = ["8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"
  ]

  @ViewChild('stepper') stepper: MatStepper;

  enum_requirement_type = RequirementType;
  treeControl = new NestedTreeControl<ClientStepNode>((node) => node.children);
  clientStepDatasource: any = [];
  standard_requirements: any = [];

  checked = false;
  isChecked: boolean = false;
  iswithStatue: boolean = false;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  officeControl = new UntypedFormControl();
  filteredOffices: Observable<any[]>;
  listofOffices: any[] = [];
  allOffices: string[] = [];

  //inger agency offices
  interagencyoffices: string[] = [];
  interagencyControl = new UntypedFormControl();

  serviceCompleteness: boolean = false;

  // step 7 form
  eodb_tags_form: UntypedFormArray;
  eodb_tags_form_datasource: MatTableDataSource<AbstractControl>;
  eodbTagsFormDisplayedColumns: string[] = [
    'classification',
    'sector',
    'division',
    'stage_of_business',
    'case_use',
    'jurisdiction',
    'delete',
  ];

  valueSuggestions: any = {};

  service_name_and_details_form: UntypedFormGroup;

  private maxDaysMap: any = {
    Simple: 3,
    Complex: 7,
    'Highly Technical': null,
    // By law, Highly Technical services should not exceed 20 days, but ARTA has requested not to impose a hard limit yet
    'By Law Highly Technical': 20,
  };

  ///client step and agency action
  displayedColumns: string[] = ['clientstep', 'agencyaction', 'action'];
  datasrouce: any;

  @ViewChild('officeInput') officeInput: ElementRef<HTMLInputElement>;
  @ViewChild('interagencyofficeinput')
  interagencyofficeinput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  serviceForm = this.fb.group({
    isWithStatute: false,
    statute: '',
  });

  updateServiceId: any;
  account_type: string = '';
  enum_account_subtype = GovernmentAccountSubtype;

  private history: string[] = [];

  /**
   * Form Variables
   */
  citizens_charter_worklist_collection_name: string =
    'Worklist-Citizens Charter'; // test collection 'CitizensCharter'
  citizens_charter_service_collection_name: string = 'Services Phase 02'; // test collection 'CitizensCharter'
  enum_citizenscharter_status = WorklistStatusCitizensCharter;
  withInteragencySubmission: boolean = false;

  constructor(
    private dialog: MatDialog,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    public auth: AngularFireAuth,
    private router: Router,
    private snackBarService: SnackbarNotifService,
    public requirementservice: RequriementsService,
    private _service: GovServiceService,
    private emailer: EmailerService,
    private service_history: ServiceHistoryService
  ) {
    this.account_type = sessionStorage.getItem('account_subtype') || '';
  }

  ngOnInit(): void {
    this.dtOptions = {
      destroy: true,
    };
    this.getSuggestions();
    this.eodb_tags_form = new UntypedFormArray([]);
    //load defaults
    this.loadgroupOffice()
    //this.loadsubOffices();
    this.loadOfficeDivision();

    this.service_name_and_details_form = new UntypedFormGroup({
      service_name: new UntypedFormControl('', Validators.required),
      service_category: new UntypedFormControl('', Validators.required),
      service_description: new UntypedFormControl('', Validators.required),
      classification: new UntypedFormControl('', Validators.required),
      type_of_transaction: new UntypedFormGroup(
        {
          type_g2c: new UntypedFormControl(false),
          type_g2g: new UntypedFormControl(false),
          type_g2b: new UntypedFormControl(false),
        },
        { validators: [atLeastOne(Validators.requiredTrue)] }
      ),
      interagency_submission: new UntypedFormControl(false),
      process_owner: new UntypedFormControl('', Validators.required),
      who_may_avail: new UntypedFormControl('', Validators.required),
      operating_hours: new UntypedFormControl('', Validators.required),
      hour_per_day: new UntypedFormControl(this._service.maxHours | 8,Validators.required),
      has_statute: new UntypedFormControl(false),
      total_processing_time: new UntypedFormControl(''),
      general_remarks: new UntypedFormControl('')
    });

    if (this.route.snapshot.params.id) {
      this.updateServiceId = this.route.snapshot.params.id;
      this.loadRemarksData();
      this.loadWorklistData();
    } else {
      this.service_name_and_details_form.controls['hour_per_day']?.setValue('8'
      )
  
      this.setMaxHours(this.service_name_and_details_form.value.hour_per_day)
  
      this.loader = false;
    }
    
  }

  service: any;
  savedFlag: any;
  isEditMode: any;
  fordraftOnly:boolean =false;
  editedFlag: any;
  worklistStatus: any;
  loader: boolean = true;

  noRequirementChange(event:any){
    const value : boolean =event.target.checked

    if(value){
      Swal.fire({
        title: "Are you sure?",
        text: "No requirements for this Service? All encoded requirements will be removed!",
        icon:"question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, no requirements"
      }).then((result) => {
        if (result.isConfirmed) {
  
          this.standard_requirements = []
            this.situational_requirements = []
          this.snackBarService.displayNotif("success","Service Requirements Change to No Requirements")
        }else{
          this.no_req.patchValue(false)
          
          
        }
      });
    }

   
  }

  async loadWorklistData() {
    const params = await firstValueFrom(this.route.queryParams.pipe(first()));
    this.updateWorklistId = params.worklistId;

    this.fromServiceManagement = params.isEditMode;
    this.fordraftOnly = params?.draftonly
    this.afs
      .collection(this.citizens_charter_worklist_collection_name)
      .doc(params.worklistId)
      .snapshotChanges()
      .pipe(first())
      .subscribe((info) => {
        if (info.payload.exists) {
          let worklist: any = info.payload.data();
          this.worklistStatus = worklist.status;
          this.serviceCompleteness = worklist?.serviceCompleteness;
          this.loadServiceInformation(worklist.documentId);
        }
      });
  }

  remarks_thread: any = [];
  clientStepRemarks: number = 0;
  agencyActionRemarks: number = 0;
  serviceInformationRemarks: number = 0;
  generalremarks: number = 0;
  eodbRemarks: number = 0;
  requirementRemarks: number = 0;

  loadRemarksData() {
    if (!this.updateServiceId) return;
    this.afs
      .collection('Service Remarks', (filter) =>
        filter
          .where('service_id', '==', this.updateServiceId)
          .where('status', '==', RemarkResolvedStatusCitizensCharter.UNRESOLVED)
      )
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          this.remarks_thread = [];
          this.clientStepRemarks = 0;
          this.agencyActionRemarks = 0;
          this.serviceInformationRemarks = 0;
          this.generalremarks = 0;
          this.eodbRemarks = 0;
          this.requirementRemarks = 0;

          data.forEach((info: any) => {
            let remark: any = info.payload.doc.data();
            remark._id = info.payload.doc.id;
            if (
              remark.status === RemarkResolvedStatusCitizensCharter.UNRESOLVED
            ) {
              this.remarks_thread.push(remark);
            }
            if (remark.type == 'ClientStep') this.clientStepRemarks++;
            else if (remark.type == 'AgencyAction') this.agencyActionRemarks++;
            else if (remark.type == 'ProcessingTime') this.agencyActionRemarks++;
            else if (remark.type == 'PersonResponsible') this.agencyActionRemarks++;
            else if (remark.type == 'general_remarks') this.generalremarks++;
            else if (remark.type == 'eodb_tags') this.eodbRemarks++;
            else if (remark.type == 'description')
              this.serviceInformationRemarks++;
            else if (remark.type == 'requirement') this.requirementRemarks++;
          });
        }, take(1))
      )
      .subscribe();
  }

  async loadServiceInformation(serviceId: any) {
    this.afs
      .collection('Services Phase 02')
      .doc(serviceId)
      .snapshotChanges()
      .pipe(first())
      .subscribe((info) => {
        if (info.payload.exists) {
          let service: any = info.payload.data();
          service.id = info.payload.id;
          this.service = service;
          this.loader = false;
          this.patchValues(service);
        }
      });
  }

  get transactionTypeGroup() {
    return this.service_name_and_details_form.controls[
      'type_of_transaction'
    ] as UntypedFormGroup;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    let confirmFlag =
      !this.savedFlag &&
      (!this.isEditMode || (this.isEditMode && this.editedFlag));
    // function must return false if service form contains service values and savedFlag is not yet enabled
    return !confirmFlag;
  }

  //pre precessed/format requirements before loading to UI
  /**
  * moved to requirement service
  *  private preProcessStandardReq(standardRequirements: any) {
    if(standardRequirements && standardRequirements.length > 0){
      standardRequirements.map((docItem:any)=>{
        switch(docItem.requirementType){
          case 'Documentary':{
            if(docItem.documentFormat && docItem.documentFormat.length > 0){
              docItem.documentString = ''
              docItem.documentFormat.map((formatItem:any,index:any)=>{
                switch(formatItem.type){
                  case 'document':{
                    if(index > 0) docItem.documentString += ' '
                    switch(formatItem.value){
                      case 'Original Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.originalCopy ? docItem.originalCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Photo Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.photoCopy ? docItem.photoCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Electronic Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.electronicCopy ? docItem.electronicCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                      case 'Certified True Copy':{
                        docItem.documentString += '('+(formatItem.copyCount ? formatItem.copyCount : docItem.certifiedTrueCopy ? docItem.certifiedTrueCopy : 'Undefined')+') '+formatItem.value
                        break
                      }
                    }
                    break;
                  }
                  case 'operator':{
                    switch(formatItem.value){
                      case 'or':{
                        docItem.documentString +=formatItem.value
                        break
                      }
                      default:{
                        if(index > 0) docItem.documentString += ' '
                        docItem.documentString += formatItem.value
                      }
                    }
                    break;
                  }
                  default:{
                    if(index > 0) docItem.documentString += ' '
                    docItem.documentString += formatItem.value
                  }
                }
              })
            }
            break;
          }
        }
     
      })
    }
    return standardRequirements
  }
  *
  */

  //to make sure that sub requirements are pre processed/formatted
  /**
  * move to requirement service
  *   preprocessSubSituationalReq(subrequirement: any){

    let tempsubreq =[]

    for (var req of subrequirement){

      tempsubreq.push({
        title: req.title,
        requirement: this.preProcessStandardReq(req.requirement)

      })

    }

    return tempsubreq;

  }
  */

  checkifwithInterAgencyOfficeError() {
    if (this.withInteragencySubmission)
      if (this.interagencyoffices.length > 0) return false;
      else return true;
    else return false;
  }

  getAgencyActionTotalProcessingTime(agencyAction: any) {
    const days = agencyAction.processing_time_days;
    const hours = agencyAction.processing_time_hours;
    const minutes = agencyAction.processing_time_minutes;
    return (
      (days ? days + ' day/s, ' : '') +
      (hours ? hours + ' hour/s, ' : '') +
      (minutes ? minutes + ' minute/s' : '')
    );
  }
  no_req = new UntypedFormControl(false)
  //for udpating services
  patchValues(service: any) {

    //console.log('Service Data',service)
    if (service?.clientStep) {
      service.clientSteps.forEach((clientStep: any) => {
        clientStep.agency_actions.forEach((agencyAction: any) => {
          agencyAction.total_processing_time =
            this.getAgencyActionTotalProcessingTime(agencyAction);
        });
      });
    }
    this.clientStepDatasource = service.clientSteps;
    this.no_req.patchValue(service?.no_requirements) 
    this.standard_requirements = service.standardRequirements;
    this.situational_requirements = service?.situationalRequirementsArray;


    //console.log('Client Steps', this.clientStepDatasource);
    /**
     * this.standard_requirements = this.requirementservice.preProcessStandardRequirements( service.standardRequirements)
    if(service?.situationalRequirementsArray){
      const temp_sit = service.situationalRequirementsArray

      temp_sit.forEach((situation:any)=>{
  
        this.situational_requirements.push({
          title: situation.title,
          requirement: this.requirementservice.preProcessStandardRequirements(situation.requirement),
          subRequirement: this.requirementservice.preprocessSubSituationalRequriements(situation.subRequirement? situation.subRequirement: [] )
  
        })
      })
    }
     */

    if (service.serviceDetails?.interagency_submission) {
      for (var office of service.serviceDetails.inter_agency_offices) {
        this.interagencyoffices.push(office);
      }
    }
    this.service_name_and_details_form.controls[
      'interagency_submission'
    ]?.patchValue(service.serviceDetails?.interagency_submission || false);
    if (service.serviceDetails?.office_division) {
      for (var office of service.serviceDetails.office_division) {
        this.listofOffices.push(office);
      }
    }

    if (service.serviceDetails.has_statute) {
      this.service_name_and_details_form.addControl(
        'statute',
        new UntypedFormControl('', Validators.required)
      );
      this.service_name_and_details_form.controls['statute']?.patchValue(
        service.serviceDetails.statute
      );
    }

    this.service_name_and_details_form.controls['service_name']?.patchValue(
      service.serviceDetails.service_name
    );
    this.service_name_and_details_form.controls['service_category']?.patchValue(
      service.serviceDetails.service_category
    );
    this.service_name_and_details_form.controls[
      'service_description'
    ]?.patchValue(service.serviceDetails.service_description);
    this.service_name_and_details_form.controls['classification']?.patchValue(
      service.serviceDetails.classification
    );

    this.transactionTypeGroup.patchValue({
      type_g2c: service.serviceDetails.type_of_transaction.type_g2c,
      type_g2g: service.serviceDetails.type_of_transaction.type_g2g,
      type_g2b: service.serviceDetails.type_of_transaction.type_g2b
    });
    this.service_name_and_details_form.get('process_owner')?.setValue(
      service.serviceDetails.process_owner 
    );
    this.service_name_and_details_form.controls['who_may_avail']?.patchValue(
      service.serviceDetails.who_may_avail
    );
    this.service_name_and_details_form.controls['operating_hours']?.patchValue(
      service.serviceDetails.operating_hours
    );
    this.service_name_and_details_form.controls['has_statute']?.patchValue(
      service.serviceDetails.has_statute
    );
    this.service_name_and_details_form.controls[
      'operating_hours_other'
    ]?.patchValue(service.serviceDetails.operating_hours_other);
    this.service_name_and_details_form.controls['general_remarks']?.patchValue(
      service.serviceDetails?.general_remarks || ''
    );
    if (service.serviceDetails.total_processing_time) {
      this.service_name_and_details_form.controls[
        'total_processing_time'
      ]?.patchValue(service.serviceDetails?.total_processing_time);
    } else {
      this.printTotalProcessingTime();
    }

    this.service_name_and_details_form.controls['hour_per_day']?.setValue(
      service.serviceDetails?.hour_per_day || 8
    )

    //console.log(service.serviceDetails.process_owner )

    this.setMaxHours(this.service_name_and_details_form.value.hour_per_day)

    this.printTotalProcessingTime()
    this.checkProcessingTimePerSituation()

    this.service_name_and_details_form.markAllAsTouched();
    this.service_name_and_details_form.markAsDirty();
  }

  hour_per_day  : number = 8

  setMaxHours(event: any){
    this._service.maxHours = event
    this.hour_per_day = event
  }

  getOthers(selected: any) {
    if (selected === 'Others') {
      this.service_name_and_details_form.addControl(
        'operating_hours_other',
        new UntypedFormControl('', { validators: [Validators.required] })
      );
    } else {
      this.service_name_and_details_form.removeControl('operating_hours_other');
    }
  }

  getChildData(officeObj: any, destinationArray: any[]) {
    if (officeObj.children && officeObj.children.length > 0) {
      officeObj.children.forEach((office: any) => {
        destinationArray.push(office.name);
        this.getChildData(office, destinationArray);
      });
    }
  }

  groupOfficeobj:any =[]
  groupOfficelist:any = []
  keyword:any =""
  createGroup(){

    this.dialog.open(ArtemisOfficeGroupingComponent,{
      width: '83vw',
    }).afterClosed().subscribe()

  }

  loadgroupOffice(){
    
    const agencyId = sessionStorage.getItem('agency_id');
    this.afs.collection("Office Group",filter => filter.where('fromAgencyId','==',agencyId))
    .snapshotChanges()
    .pipe(tap(data=>{
      this.groupOfficelist= []
      this.groupOfficeobj = []
      data.forEach((info:any)=>{
          let item:any = info.payload.doc.data();
          const d :any = {
            name: item.group_name,
            offices: item.offices,
            groupOffice :true
          }
          this.groupOfficeobj.push(d)
          this.groupOfficelist.push(d.name)
      })
    })).subscribe()
  }

  suboffice:any =[]

  loadsubOffices(){
    const agencyId = sessionStorage.getItem('agency_id');
    this.afs.collection("Agency Offices", filter => filter.where('fromAgencyId','==',agencyId))
    .snapshotChanges()
    .pipe(tap(data=>{

      this.suboffice = []

      data.forEach((info:any)=>{
          let item:any = info.payload.doc.data();
         
          this.suboffice.push(item.name)
      })

    })).subscribe()
  }


  get AgencyOffices(){
    return [...this.allOffices, ...this.suboffice,...this.groupOfficeobj]
  }

  get alloficesexcludingGroup(){
    return [...this.allOffices, ...this.suboffice]
  }

  computeTotalFees(client_step_arr:any, situationalStep?:any){
    return this._service.computeTotalFees(client_step_arr, situationalStep);
  }

  loadOfficeDivision() {
    let officeFilter: QueryFn;
    const agencyId = sessionStorage.getItem('agency_id');
    officeFilter = (filter) =>
      filter.where('fromAgencyId', '==', agencyId).orderBy('createdAt', 'asc');
    // .where('Status', '==', 'Active');

    //from Agency-Offices-Division Collection to Agency Office Hierarchy
    this.afs
      .collection('Agency Office Hierarchy', officeFilter)
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          this.allOffices = [];
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            this.allOffices.push(item.office.name);
            this.getChildData(item.office, this.allOffices);
            //this.allOffices.push(item.name);
          });

          this.allOffices.sort((a, b) => a.localeCompare(b));
        })
      )
      .subscribe();
  }

  goBack() {
    Swal.fire({
      title: 'Leave form. Are you sure?',
      text: 'Make sure to save your work before leaving this form.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, leave now',
    }).then((result) => {
      if (result.isConfirmed) this.location.back();
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our office
    if ((value || '').trim()) {
      this.listofOffices.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.officeControl.setValue(null);
  }

  addinterAgencyOffice(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add office
    if ((value || '').trim()) {
      this.interagencyoffices.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.interagencyControl.setValue(null);
  }

  remove(office: string): void {
    const index = this.listofOffices.indexOf(office);

    if (index >= 0) {
      this.listofOffices.splice(index, 1);
    }
  }

  removeinteragencyoffice(office: string): void {
    const index = this.interagencyoffices.indexOf(office);

    if (index >= 0) {
      this.interagencyoffices.splice(index, 1);
    }
  }

  process_owner_selected(event: MatAutocompleteSelectedEvent){

    if (event.option.value){
      //console.log("select value",event.option.value)
    }

  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
     const index = this.listofOffices.indexOf(event.option.value);

     if (index === -1){
      this.listofOffices.push(event.option.value);
     }
    }
   
    this.officeInput.nativeElement.value = '';
    this.officeControl.setValue(null);
  }

  /**
   * 
   * updateSubSituatioanl(array:any , index:any ,requirement: any,requirementIndex: number ){
    let result = this.dialog
    .open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight:'20vh',
      disableClose: true,
      data: {
        isupdating : true,
        isSituational: true,
        requirement: requirement,
        requirementIndex: requirementIndex,
      },
    })

    result.afterClosed().subscribe((data:any)=>{
     if(data.submitFlag){
      array[index] = {
        title : data.title,
        requirement : data.requirement
      }
      this.scrollto('subsituation_' + index)
     }
    })

  }

  updateSituationalRequirement(array:any , requirementIndex: number,requirement:any ,situation:any,situationIndex:number){

    const req = situation.requirement
  
    let result = this.dialog
    .open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight:'20vh',
      disableClose: true,
      data: {
        isupdating : true,
        isSituational: true,
        requirement: situation,
        requirementIndex: requirementIndex
      },
    })

    result.afterClosed().subscribe((data:any)=>{
     if(data.submitFlag){

      data.subRequirement = situation.subRequirement
      
      array[situationIndex] = data
      this.scrollto('situational_' + situationIndex)
    
     }
    })
  }

  updateRequirement(array:any,index:any,requirement:any, isSituational:boolean =false){

    let result = this.dialog
    .open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight:'20vh',
      disableClose: true,
      data: {
        isupdating : true,
        isSituational: isSituational,
        requirement: requirement,
        requirementIndex: 0
      },
    })
    result.afterClosed().subscribe((data:any)=>{
      
     if(data.submitFlag){
      if(isSituational){
        const subrequirement =  requirement.subRequirement? requirement.subRequirement : []

        data.subRequirement = subrequirement
        array[index] = data
        this.scrollto('situational_' + index)
      }else{
        array[index] = data.requirement[0]
        this.scrollto('standard_' + index)
  
      }
    
     }
    })

  }
   */

  requirement_errors:any = []

  validateSituation(situation:any ){

    if(situation.title == ''){
      this.requirement_errors.push("Invalid situational requirement title")
    }

    if(situation?.requirement.length <= 0 && situation?.subRequirement.length <= 0){
      this.requirement_errors.push("Invalid situational requirement please add requirement")
    }

    if(situation?.requirement.length > 0 ){
      this.validaterequirementlist(situation.requirement)
    }

    if(situation?.subRequirement.length > 0 ){
      this.validaterequirementlist(situation.subRequirement)
    }
    

  }

  validaterequirementlist(requirements:any = []){

    requirements.map((requirement:any )=>{

        if(requirement.chooseany){

          if(requirement.title.trim() == '' ) this.requirement_errors.push("INvalid choose any title");

          if(requirement.requirement.length < 2) this.requirement_errors.push("INvalid choose any count must be 2");


        }
        if(requirement?.subRequirement){

          this.validaterequirementlist(requirement.subRequirement)

        }


    })


  }


  validateRequirements(){

    this.requirement_errors = []

    if(this.standard_requirements.length > 1){
      this.validaterequirementlist(this.standard_requirements)
     
    }

    if(this.situational_requirements.length > 1) {

      for(var situation of this.situational_requirements){

        this.validateSituation(situation)

      }
      
    }

    return this.requirement_errors.length
  

  }

  removeEodbTag(index: any) {
    this.eodb_tags_form.removeAt(index);
    this.eodb_tags_form_datasource = new MatTableDataSource(
      this.eodb_tags_form.controls
    );
  }

  situational_requirements: any = [];
  enum_req_type = RequirementType;

  addRequirement(type: RequirementType) {
    if (
      type === this.enum_requirement_type.STANDARD_NO_TITLE ||
      type === this.enum_requirement_type.STNDARD_WITH_TITLE
    ) {
      this.requirementservice.newStandardRequirement(
        this.standard_requirements,
        type
      );
    } else {

      let situational_req : any = {
        title : "Situational Requirement",
        requirement: [],
        subRequirement:  []
      }
      this.situational_requirements.push(situational_req)
      //this.standard_requirements = []


      /**
       * this.requirementservice.newStandardRequirement(
        this.situational_requirements,
        type
      );
       */
    }
  }

  /***
  *  duplicateSubSituationalReq(requirement:any,destination: any){

    let newrequirement = {
      title: requirement.title,
      requirement: requirement.requirement,
      subRequirement: []
    }

    if(requirement.subRequirement){
      for (var req of requirement?.subRequirement){
        this.pushSubSituational(newrequirement.subRequirement,req)
      }
    }



    destination.push(newrequirement)

  }

  
  duplicateRequirement(destination:any,requirement:any){
    let newrequirement = {
      title: requirement.title,
      requirement: requirement.requirement
    }
    
    destination.push(newrequirement)
  }
  */

  addSituationalSub(situation: any) {
    let result = this.dialog.open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight: '20vh',
      disableClose: true,
      data: {
        isSituational: true,
        requirementIndex: 0,
        subsituational: true,
      },
    });

    result.afterClosed().subscribe(
      (data: any) => {
        if (data.submitFlag) {
          this.requirementservice.pushSituational(
            situation.subRequirement,
            data
          );
          //console.log(this.situational_requirements);
        }
      },
      (error) => {
        //console.log(error.message);
      }
    );
  }

  addsubSituational(situation: any, requirmentIndex: any = null) {
    let result = this.dialog.open(NewRequirementsModalComponent, {
      width: '98vw',
      minHeight: '20vh',
      disableClose: true,
      data: {
        isSituational: true,
        requirementIndex: 0,
        subsituational: true,
      },
    });
    result.afterClosed().subscribe((data: any) => {
      if (data.submitFlag) {
        let temp_sit = this.situational_requirements[situation];
        let temp_sub = temp_sit.requirement[requirmentIndex];

        //console.log('temp situational',temp_sit)
        if (!temp_sub?.subRequirement) {
          let temp: any = [];
          this.requirementservice.pushSubSituational(temp, data);
          temp_sub.subRequirement = temp;
        } else
          this.requirementservice.pushSubSituational(
            temp_sub.subRequirement,
            data
          );
      }
    });
  }

  copyRequirement(object: any, destinationArray: Array<any>) {
    this.requirementservice.copyRequirement(object, destinationArray);
  }

  removeRequirement(sourceArray: [], requirementIndex: number) {
    this.requirementservice.removeRequirement(sourceArray, requirementIndex);
  }

  pushNewSubsituational(destination: any, data: any) {
    destination.push({
      title: data.title,
      requirement: data.requirement,
    });
  }

  removeSubsituational(situation: any, index: number) {
    situation.subRequirement.removeAt(index);
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  /**
   * CANCEL
   * return to charter page
   */
  returnRedirect() {
    this.router.navigate(['/artemis/charter']);
  }

  scrollto(id: any) {
    const doc = document.getElementById(id);
    if (doc) {
      doc.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  addSituation(index:number= 0) {

    this.clientStepDatasource.splice(index,0,{
      situational_step_title: '',
      situational: true,
      clientStep: [],
    })
   
    this.scrollto("situational-step");
  }

  checkifCLientDatasourcehasSituational(): boolean {
    let temp_result: boolean = false;
    this.clientStepDatasource.forEach((clientStep: any) => {
      if (clientStep.situational) temp_result = true;
      return;
    });

    return temp_result;
  }

  updateServiceStep(clientStep: any, index: number, sourceArray: any,rowIndex: any= "") {
    let result = this.dialog.open(NewClientStepsModalComponent, {
      width: '100vw',
      data: {
        clientStep: clientStep,
        rowNumber: rowIndex,
        allOffices: [
          this.service_name_and_details_form.value.process_owner,
          ...this.listofOffices.filter(f => f !== this.service_name_and_details_form.value.process_owner),
          ...this.interagencyoffices,
        ], maxHours : this.service_name_and_details_form.value.hour_per_day
      },
      disableClose: true,
    });

    result.afterClosed().subscribe((data: any) => {
      if (data.submitFlag) {
        sourceArray[index] = data.data;
        //this.clientStepDatasource[index] = data.data;
        this.printTotalProcessingTime();
        this.checkProcessingTimePerSituation();
        //this.scrollto('client_step_' + index);
      }
    });
  }

  removeServiceStep(index: number, sourceArray: any,isSituational:boolean =false) {

    this.snackBarService.confirmDialog(isSituational ? "Remove All Situational Client Step" : "Remove Client Step")
    .then(reuslt => {

      if (reuslt){

        sourceArray.splice(index, 1);
        this.printTotalProcessingTime
        this.checkProcessingTimePerSituation()
      }

    })
    //this.clientStepDatasource.splice(index, 1);
  }
  addServiceStep(
    situational: boolean = false,
    situation: any = null,
    insert: boolean = false,
    index: number = 0,
    actualIndex: any = ""
  ) {
    let result = this.dialog
      .open(NewClientStepsModalComponent, {
        width : '100vw',
        data: {
          rowNumber: situational? actualIndex : insert? actualIndex : this.clientStepDatasource.length + 1,
          allOffices: [
            this.service_name_and_details_form.value.process_owner,
            ...this.listofOffices.filter(f => f !== this.service_name_and_details_form.value.process_owner),
            ...this.interagencyoffices,
          ],
          situational : situational,
          maxHours : this.service_name_and_details_form.value.hour_per_day
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data.submitFlag) {
          if (situational) {
            if (insert) {
              situation.clientStep.splice(index, 0, data.data);
            } else {
              situation.clientStep.push(data.data);
            }
          } else {
            if (insert) {
              this.clientStepDatasource.splice(index, 0, data.data);
            } else {
              this.clientStepDatasource.push(data.data);
            }
            
            //this.scrollto('newstepbutton');
          }

          this.printTotalProcessingTime();
          this.checkProcessingTimePerSituation();
        }
      });
  }

  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  printToLetter(number: number) {
    let result = '';
    let charIndex = number % this.alphabet.length;
    let quotient = number / this.alphabet.length;
    if (charIndex - 1 == -1) {
      charIndex = this.alphabet.length;
      quotient--;
    }
    result = this.alphabet.charAt(charIndex - 1) + result;
    if (quotient >= 1) {
      result = result + this.printToLetter(quotient);
    }
    return result;
  }

  async getSuggestions() {
    /* main */
    // TODO: Move the dropdown values to the Top Level Collections
    let referencesRef = this.afs.collection('References');
    /* sub */
    //let commonRef = referencesRef.doc('common');
    let artemisRef = referencesRef.doc('ARTEMIS');
    //let pbrisRef = referencesRef.doc('PBRIS');
    /* sub sub */
    let collectionArray: any = [];
    /* common */
    /* common reference data has not yet been moved to the latest collection
    collectionArray.push({ collname: 'location_code', collRef: commonRef.collection('location_code') });
    collectionArray.push({ collname: 'notif_messages', collRef: commonRef.collection('notif_messages') });
    collectionArray.push({ collname: 'organization_code', collRef: commonRef.collection('organization_code') });
    collectionArray.push({ collname: 'psic_code', collRef: commonRef.collection('psic_code') });
    collectionArray.push({ collname: 'sector', collRef: commonRef.collection('sector') });
    */
    collectionArray.push({
      collname: 'location_code',
      collRef: this.afs.collection('Location Code'),
    });
    collectionArray.push({
      collname: 'organization_code',
      collRef: this.afs.collection('Organization Code'),
    });
    collectionArray.push({
      collname: 'psic_code',
      collRef: this.afs.collection('PSIC Code'),
    });
    collectionArray.push({
      collname: 'sector',
      collRef: this.afs.collection('Sector'),
    });
    /* artemis */
    collectionArray.push({
      collname: 'agency',
      collRef: this.afs.collection('Agency', (filter) =>
        filter.orderBy('agencyDetails.agencyName')
      ),
    });
    collectionArray.push({
      collname: 'agency_category',
      collRef: artemisRef.collection('agency_category'),
    });
    collectionArray.push({
      collname: 'classification',
      collRef: artemisRef.collection('classification', (filter) =>
        filter.orderBy('order')
      ),
    });
    collectionArray.push({
      collname: 'list_of_services',
      collRef: artemisRef.collection('list_of_services'),
    });
    collectionArray.push({
      collname: 'service_category',
      collRef: artemisRef.collection('service_category'),
    });
    collectionArray.push({
      collname: 'stage_of_life',
      collRef: artemisRef.collection('stage_of_life'),
    });
    /* pbris */
    /* pbris reference data has not yet been moved to the latest collection
    collectionArray.push({ collname: 'agency_location', collRef: pbrisRef.collection('agency_location') });
    collectionArray.push({ collname: 'case_use', collRef: pbrisRef.collection('case_use') });
    collectionArray.push({ collname: 'division', collRef: pbrisRef.collection('division') });
    collectionArray.push({ collname: 'document', collRef: pbrisRef.collection('document') });
    collectionArray.push({ collname: 'instrument', collRef: pbrisRef.collection('instrument') });
    collectionArray.push({ collname: 'jurisdiction', collRef: pbrisRef.collection('jurisdiction') });
    collectionArray.push({ collname: 'stage_of_business', collRef: pbrisRef.collection('stage_of_business') });
    */
    collectionArray.push({
      collname: 'agency_location',
      collRef: this.afs.collection('Agency Location'),
    });
    collectionArray.push({
      collname: 'case_use',
      collRef: this.afs.collection('Case Use'),
    });
    collectionArray.push({
      collname: 'document',
      collRef: this.afs.collection('Document'),
    });
    collectionArray.push({
      collname: 'instrument',
      collRef: this.afs.collection('Instrument'),
    });
    collectionArray.push({
      collname: 'jurisdiction',
      collRef: this.afs.collection('Jurisdiction'),
    });
    collectionArray.push({
      collname: 'stage_of_business',
      collRef: this.afs.collection('Stage of Business'),
    });

    let divisionFilter: QueryFn;
    divisionFilter = (filter) => filter;
    let fromAgency = sessionStorage.getItem('agency_id');

    if (fromAgency) {
      divisionFilter = (filter) =>
        filter.where('fromAgencyId', '==', fromAgency);
    }
    collectionArray.push({
      collname: 'division',
      collRef: this.afs.collection('Agency-Offices-Division', divisionFilter),
    });
    /* loop */
    for (let collJSON of collectionArray) {
      let suggestArray: any = [];
      collJSON.collRef
        .snapshotChanges()
        .pipe(
          tap((data: any) => {
            data.forEach((info: any) => {
              let item: any = info.payload.doc.data();
              if (Object.keys(item).length > 0) suggestArray.push(item);
            });
          }),
          take(1)
        )
        .subscribe();
      this.valueSuggestions[collJSON.collname] = suggestArray;
    }
  }

  // Filtered dropdowns for use in EODB tags
  filteredDivision(reg_sector: string): any[] {
    let section =
      this.valueSuggestions.sector
        .find((option: any) => option.name === reg_sector)
        ?.section?.toLowerCase() || '';
    return this.valueSuggestions.division.filter(
      (option: any) =>
        section.trim() != '' && option.section.toLowerCase().includes(section)
    );
  }

  filteredCaseUse(reg_business: string): any[] {
    let section =
      this.valueSuggestions.stage_of_business
        .find((option: any) => option.name === reg_business)
        ?.section?.toLowerCase() || '';
    return this.valueSuggestions.case_use.filter(
      (option: any) =>
        section.trim() != '' && option.section.toLowerCase().includes(section)
    );
  }

  addNewEodbTag() {
    this.eodb_tags_form.push(this.buildNewEntry(EntityBuilderIndex.EODB_TAG));
    this.eodb_tags_form_datasource = new MatTableDataSource(
      this.eodb_tags_form.controls
    );
  }

  private buildNewEntry(entry_id: number, existing_obj?: any): UntypedFormGroup {
    switch (entry_id) {
      case EntityBuilderIndex.EODB_TAG:
        let newEodbTag = new UntypedFormGroup({
          classification: new UntypedFormControl('', Validators.required),
          sector: new UntypedFormControl('', Validators.required),
          division: new UntypedFormControl('', Validators.required),
          stage_of_business: new UntypedFormControl('', Validators.required),
          case_use: new UntypedFormControl('', Validators.required),
          jurisdiction: new UntypedFormControl('', Validators.required),
        });
        return newEodbTag;
      default:
        return new UntypedFormGroup({});
    }
  }

  clearOffices() {
    this.listofOffices = [];
  }
  clearinteragencyOffices() {
    this.interagencyoffices = [];
  }

  services: any = [];
  getServices(): any {
    this.services = [];
    let temp_service: any = this.getServiceData();
    temp_service = this._service.preProcessServiceData(temp_service)
    
    /**
     * if (temp_service.serviceDetails.type_of_transaction) {
      let transactionTypeString = '';
      if (temp_service.serviceDetails.type_of_transaction.type_g2b == true)
        transactionTypeString += 'G2B (Government to Business)';
      if (temp_service.serviceDetails.type_of_transaction.type_g2c == true)
        transactionTypeString +=
          (transactionTypeString ? ', ' : '') + 'G2C (Government to Citizen)';
      if (temp_service.serviceDetails.type_of_transaction.type_g2g == true)
        transactionTypeString +=
          (transactionTypeString ? ', ' : '') +
          'G2G (Government to Government)';
      temp_service.serviceDetails.transactionTypeString = transactionTypeString;
    }
    if (temp_service.serviceDetails.operating_hours) {
      let tokenizedByDash =
        temp_service.serviceDetails.operating_hours.split('-');
      if (tokenizedByDash.length > 0) {
        if (tokenizedByDash.length == 2) {
          let tokenizedByColon1 = tokenizedByDash[0].split(':');
          let tokenizedByColon2 =
            tokenizedByDash[tokenizedByDash.length - 1].split(':');
          if (tokenizedByColon1.length == 1 && tokenizedByColon2.length == 1)
            temp_service.serviceDetails.operatingTime =
              tokenizedByColon1[0].trim() +
              ':00 AM - ' +
              tokenizedByColon2[0].trim() +
              ':00 PM';
          else
            temp_service.serviceDetails.operatingTime =
              temp_service.serviceDetails.operating_hours;
        } else
          temp_service.serviceDetails.operatingTime =
            temp_service.serviceDetails.operating_hours;
      } else
        temp_service.serviceDetails.operatingTime =
          temp_service.serviceDetails.operating_hours;
    }
     */
    
    this.services.push(temp_service);
  }

  onStepChange(event: any): void {
    this.printTotalProcessingTime();
    switch (event.selectedIndex) {
      case 1:
        this.scrollto('standard_table_top');
        break;
      case 4:
        this.getServices();
    }
  }

  checkServicesValidity(markcontrols: boolean = false): boolean {

    if(this.exceedsMaxDays) return false;
    
    if(this.requirementsValidator() === true) return false; 

    if(this.InvalidSituationalStep() > 0 || this.invalidSituationalClientStepProcessingTime.length > 0){
      return false
    }

    if (this.service_name_and_details_form.valid) {
      if (this.clientStepDatasource.length > 0) {
        if (this.listofOffices.length > 0) {
          if (this.withInteragencySubmission) {
            if (this.interagencyoffices.length > 0) return true;
            else this.markControlasDirty(markcontrols);
          } else return true;
        } else return this.markControlasDirty(markcontrols);
      } else return this.markControlasDirty(markcontrols);
    }
    return this.markControlasDirty(markcontrols);
  }

  markControlasDirty(markcontrols: boolean = false): boolean {
    if (markcontrols) {
      this.service_name_and_details_form.markAllAsTouched();
      this.service_name_and_details_form.markAsDirty();
    }

    return false;
  }

  requirementsValidator() :boolean {

    if (this.validateRequirements() > 0) return true
    
    else if (this.no_req.value == false && (this.situational_requirements.length <= 0 && this.standard_requirements.length <= 0)) return true

    return false
  }

  getServiceData() {
    var eodbTags: any = [];

    for (var y of this.eodb_tags_form.controls) {
      eodbTags.push(y.value);
    }

    const service: Record<any, any> = {
      fromAgencyId: sessionStorage.getItem('agency_id'),
      is_posted: false,
      no_requirements: this.no_req?.value == true ? true : false,
      serviceDetails: this.service_name_and_details_form.value,
      posted_status: PostedServiceStatusCitizensCharter.FOR_UPDATING,
      standardRequirements: this.standard_requirements,
      situationalRequirementsArray: this.situational_requirements,
      eodbTags: eodbTags,
      clientSteps: this.clientStepDatasource,
      validityMap: {
        serviceForm: this.service_name_and_details_form.valid,
        requirements:this.requirementsValidator() ? false : true ,
        clientSteps: this.clientStepDatasource.length > 0 ? true : false,
        eodbTags: true,
        overall: this.checkServicesValidity(true),
      },
      visit_count: 0,
    };
    if (this.withInteragencySubmission) {
      service.serviceDetails.inter_agency_offices = this.interagencyoffices;
    }
    service.serviceDetails.office_division = this.listofOffices;
    service.serviceDetails.total_fees = this._service.computeTotalFees(
      this.clientStepDatasource
    );

    return service;
  }

  fromServiceManagement: boolean = false;

  openConfirmDialog(message: string, acceptext: string) {}

  //for new services
  submitService() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Save and Submit Service',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceeed',
    }).then((result) => {
      if (result.isConfirmed)
        this.firestoreSaveServiceChanges(
          WorklistStatusCitizensCharter.FOR_VERIFICATION
        );
    });
  }


  saveAsDraft() {

    if(!this.service_name_and_details_form.value.service_name.trim()){
      this.snackBarService.displayNotif("error","Service name is required!");
      return;
    }
    
    let bypassprocess: boolean = true;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Save as Draft',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceeed',
    }).then((result) => {
      if (result.isConfirmed)
        this.firestoreSaveServiceChanges(
          WorklistStatusCitizensCharter.DRAFT,
          bypassprocess
        );
    });
  }

  //for updating services
  updateService(bypassconfirm: boolean = false, submit: boolean = false) {

    if(!this.service_name_and_details_form.value.service_name.trim()){
      this.snackBarService.displayNotif("error","Service name is required!");
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: bypassconfirm ? 'Save as draft' : 'Save Service Changes',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceeed',
    })
      .then((result) => {
        if (result.isConfirmed)
          this.firestoreUpdateService(
            WorklistStatusCitizensCharter.DRAFT,
            bypassconfirm,
            submit
          );
      })
      .finally(() => {
        this.scrollto('newstepbutton');
      });
  }

  submitUpdatedService() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Save and Submit Service',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceeed',
    }).then((result) => {
      if (result.isConfirmed)
        this.firestoreSaveServiceChanges(
          WorklistStatusCitizensCharter.FOR_VERIFICATION
        );
    });
  }

  changeStatuteValue(event: any) {
    if (this.iswithStatue == false)
      this.service_name_and_details_form.removeControl('statute');
    else
      this.service_name_and_details_form.addControl(
        'statute',
        new UntypedFormControl('', Validators.required)
      );
  }

  //save service changes for non updating citizens charter
  async firestoreSaveServiceChanges(
    status: string,
    bypassProcess: boolean = false
  ) {
    this.loader = true;

    //initialize service values
    const batch = this.afs.firestore.batch();
    let service = this.getServiceData();
    const user = await this.auth.currentUser;
    let fromOfficer = user?.displayName;
    const service_validity = this.checkServicesValidity();

    /**
     * if(this.no_req.value == false){
      if(this.standard_requirements.length <= 0 && this.situational_requirements.length <= 0){
        this.snackBarService.displayNotif("warning","Requirements must not be empty!")
        return;
      }
    }
     */

    if (((!service_validity || this.exceedsMaxDays) && bypassProcess == false ) && (this.no_req.value == false && (this.standard_requirements.length <= 0 && this.situational_requirements.length <= 0))) {
      this.loader = false;
      this.service_name_and_details_form.markAllAsTouched();
      this.service_name_and_details_form.markAsDirty();

      Swal.fire({
        title: 'Are you sure?',
        text: 'Service has incomplete detials or invalid inputs. Do you want to save as draft?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save as draft',
      }).then((result) => {
        if (result.isConfirmed) this.saveAsDraft();
        else return;
      });
    } else {
      let service_ref = this.afs.firestore
        .collection(this.citizens_charter_service_collection_name)
        .doc();

      if (this.updateWorklistId && this.updateServiceId) {
        service.parentServiceId = this.updateServiceId;
      }

      batch.set(service_ref, await service);

      let worklist_ref = this.afs.firestore
        .collection(this.citizens_charter_worklist_collection_name)
        .doc();
      let worklistJSON: any = {
        dateGenerated: Timestamp.now().toDate(),
        fromAgency: service.fromAgencyId,
        fromOfficer: fromOfficer,
        status: status,
        documentFile: '',
        documentId: service_ref.id,
        category:
          this.service_name_and_details_form.controls['classification'].value,
        process_owner:
          this.service_name_and_details_form.controls['process_owner'].value,
        documentTitle: service?.serviceDetails.service_name,
        dueDate: Timestamp.now().toDate(),
        effectivity: EffectivityCitizensCharter.NEW,
        serviceCompleteness: this.checkServicesValidity(true),
        
      };

      if(this.account_type === this.enum_account_subtype.AGENCY_ENC){
        worklistJSON.docOwner = {
          id: user?.uid,
          name: user?.displayName,
          email : user?.email,
          role: this.account_type
        }
      }

      if (
        this.updateWorklistId &&
        this.updateServiceId &&
        this.fromServiceManagement
      ) {
        worklistJSON.effectivity = EffectivityCitizensCharter.UPDATE;
        worklistJSON.previousDocumentId = this.updateServiceId;

        const update_service_ref = this.afs.firestore
          .collection(this.citizens_charter_service_collection_name)
          .doc(this.updateServiceId);

        batch.update(update_service_ref, {
          posted_status: PostedServiceStatusCitizensCharter.FOR_UPDATING,
        }); //do not comment
      }

      batch.set(worklist_ref, worklistJSON);

      await batch
        .commit()
        .then(() => {
          let alert_message = '';

          switch (status) {
            case WorklistStatusCitizensCharter.DRAFT:
              alert_message = 'Draft Saved!';
              break;
            case WorklistStatusCitizensCharter.FOR_VERIFICATION:

            this.emailer.firebaseEmailer([user?.email],'Service submitted for verification',worklistJSON.documentTitle + 'is now for verification!' + '<br> <a href="https://citizenscharter.gov.ph/artemis/view/detail/'+ service_ref.id +'?worklistId'+worklist_ref.id+'">Click here</a> to view the service' )
              alert_message = 'Submitted for Verification';
              break;
          }

          this.snackBarService.displayNotif('Success', alert_message);
          this.loader = false;
          this.returnRedirect();
        })
        .catch((err) => {
          this.loader = false;
          this.snackBarService.displayNotif('Error', err);
        });
    }
  }

  async firestoreUpdateService(
    status: string,
    bypassProcess: boolean = false,
    submit: boolean = false
  ) {
    this.loader = true;

    //initialize service values
    const batch = this.afs.firestore.batch();
    let service = this.getServiceData();
    const user = await this.auth.currentUser;
    let fromOfficer = user?.displayName;
    const service_validity = this.checkServicesValidity();

    //console.log("Service Full Details: ", service)

    /**
     * if(this.no_req.value == false){
      if(this.standard_requirements.length <= 0 && this.situational_requirements.length <= 0){
        this.snackBarService.displayNotif("warning","Requirements must not be empty!")
      }
    }
     */

    if (((!service_validity || this.exceedsMaxDays) && bypassProcess == false ) && (this.no_req.value == false && (this.standard_requirements.length <= 0 && this.situational_requirements.length <= 0))) {
      this.loader = false;
      this.service_name_and_details_form.markAllAsTouched();
      this.service_name_and_details_form.markAsDirty();

      Swal.fire({
        title: 'Are you sure?',
        text: 'Service has incomplete detials or invalid inputs. Do you want to save as draft?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save as draft',
      }).then((result) => {
        let bypass: boolean = true;
        if (result.isConfirmed) this.firestoreUpdateService(status, bypass);
        else return;
      });
    } else {
      let service_ref = this.afs.firestore
        .collection(this.citizens_charter_service_collection_name)
        .doc(this.updateServiceId);

      batch.update(service_ref, await service);

      if (this.updateWorklistId && this.updateServiceId) {
        let worklist_ref = this.afs.firestore
          .collection(this.citizens_charter_worklist_collection_name)
          .doc(this.updateWorklistId);
        let worklistJSON: any = {
          documentTitle: service.serviceDetails.service_name,
          category:
            this.service_name_and_details_form.controls['classification'].value,
          process_owner:
            this.service_name_and_details_form.controls['process_owner'].value
        };

        if(this.account_type === this.enum_account_subtype.AGENCY_ENC){
          worklistJSON.docOwner = {
            id: user?.uid,
            name: user?.displayName,
            email : user?.email,
            role: this.account_type
          }
        }

        if (status === WorklistStatusCitizensCharter.FOR_VERIFICATION) {
          worklistJSON.status = status;
        }
        if (
          this.worklistStatus === WorklistStatusCitizensCharter.DRAFT &&
          submit
        ) {
          worklistJSON.status = WorklistStatusCitizensCharter.FOR_VERIFICATION;
        }

        worklistJSON.serviceCompleteness = this.checkServicesValidity();

        batch.update(worklist_ref, worklistJSON);
      }

      await batch
        .commit()
        .then(() => {

          const cur_user = {
            id: user?.uid,
            name: user?.displayName,
            email : user?.email,
            role: this.account_type
          }
          this.service_history.saveServicehistory(this.service,cur_user).then(()=>{
            let alert_message = 'Service Updated!';
            this.snackBarService.displayNotif(
              'Success',
              bypassProcess ? 'Draft Saved!' : alert_message
            );
            this.loader = false;
            this.returnRedirect();
          })
         
        })
        .catch((err) => {
          this.loader = false;
          this.snackBarService.displayNotif('Error', err);
        });
    }
  }

  invalidSituationalClientStepProcessingTime :any = []

  checkProcessingTimePerSituation(){
    
    this.invalidSituationalClientStepProcessingTime = []

    if(this.service_name_and_details_form.value.has_statute){
      this.exceedsMaxDays =false
      return
    }
    this.clientStepDatasource.forEach((clientStep:any)=>{
      if (clientStep.situational){

        clientStep.clientStep.forEach((step:any)=>{

          let classification =this.service_name_and_details_form.get('classification')?.value;
        if (this.maxDaysMap[classification]) {
          let totalProcessingTimeMinutesObj2 =
            this._service.computeTotalProcessingTime(this.clientStepDatasource,step);

          const maxdays =
            totalProcessingTimeMinutesObj2.totalProcessingTimeMinutes >
            this.maxDaysMap[classification] * 8 * 60;

            if(maxdays){
              this.invalidSituationalClientStepProcessingTime.push("Invalid! Processing Time Exceed Maximum days for " +  step.info.situation)
            }

            
        }


        })

          
      
      }

  });

  //console.log("Invalid Times", this.invalidSituationalClientStepProcessingTime)
  }

  updateWorklistId: any;

  exceedsMaxDays: boolean = false;

  private printTotalProcessingTime() {

    let totalProcessingTimeMinutesObj =
    this._service.computeTotalProcessingTime(this.clientStepDatasource);

    this.service_name_and_details_form.controls[
      'total_processing_time'
    ].patchValue(totalProcessingTimeMinutesObj.totalProcessingTimeStr);


    //if has statute processing time is max days is not save
    if(this.service_name_and_details_form.value.has_statute){
      this.exceedsMaxDays = false;
      return;
    }

    let classification =
      this.service_name_and_details_form.get('classification')?.value;
    if (this.maxDaysMap[classification]) {
      let totalProcessingTimeMinutesObj2 =
        this._service.computeTotalProcessingTime(this.clientStepDatasource);

      this.exceedsMaxDays =
        totalProcessingTimeMinutesObj2.totalProcessingTimeMinutes >
        this.maxDaysMap[classification] * this.service_name_and_details_form.value.hour_per_day * 60;
    } else {
      this.exceedsMaxDays = false;
    }
  }

  countStepwithFormula: number = 0;
  countStepwithPossible: number = 0;

  //client step formats
  processClientStep(clientSteps: any[]) {
    this.countStepwithFormula = 0;
    this.countStepwithPossible = 0;
    let processedClientSteps: any = [];
    if (clientSteps && clientSteps.length > 0) {
      clientSteps.map((clientStep, index) => {
        if (clientStep.info?.list_of_formula_fees_arr.length > 0) {
          this.countStepwithFormula++;
        }
        if (clientStep.info?.list_of_possible_fees_arr.length > 0) {
          this.countStepwithPossible++;
        }
        if (clientStep.agency_actions && clientStep.agency_actions.length > 0) {
          clientStep.agency_actions.map((agencyAction: any, index2: number) => {
            let clientStepRow = {
              clientStepName:
                index2 == 0 ? index + 1 + '. ' + clientStep.info.title : '',
              agencyActions:
                index + 1 + '.' + (index2 + 1) + '. ' + agencyAction.title,
              fees: index2 == 0 ? this.getClientStepFees(clientStep.info) : '',
              location: clientStep.info.location,
              processingTime: '',
              possibleFeesArr: clientStep.info.list_of_possible_fees_arr,
              formulaFeesArr: clientStep.info.list_of_formula_fees_arr,
              notes: clientStep.info.notes,
              personResponsible: agencyAction.persons_responsible,
              isSituationalStep: clientStep.info.is_situational_step,
              situation: clientStep.info.is_situational_step
                ? clientStep.info.situation
                : '',
              // borderBottom: (index2 < (clientStep.agency_actions.length - 1)) ? false : true,
              // borderTop: (index2 > 0 ) ? false : true,
              rowSpan:
                clientStep.agency_actions.length > 1
                  ? index2 == 0
                    ? clientStep.agency_actions.length
                    : 0
                  : 1, //(index == 0) ? (clientStep.agency_actions.length > 1) ? clientStep.agency_actions.length : 0 : 1
            };

            // check if AA L > 1
            // T: check index 0
            // T: AA L
            // F: 0
            // F: 1

            let processingTime = '';
            if (agencyAction.processing_time_days) {
              const days = Number(agencyAction.processing_time_days);
              if (days && !isNaN(days) && days > 0)
                processingTime += agencyAction.processing_time_days + ' day/s';
            }

            if (agencyAction.processing_time_hours) {
              const hours = Number(agencyAction.processing_time_hours);
              if (hours && !isNaN(hours) && hours > 0)
                processingTime +=
                  (processingTime ? ', ' : '') +
                  agencyAction.processing_time_hours +
                  ' hour/s';
            }

            if (agencyAction.processing_time_minutes) {
              const mins = Number(agencyAction.processing_time_minutes);
              if (mins && !isNaN(mins) && mins > 0)
                processingTime +=
                  (processingTime ? ', ' : '') +
                  agencyAction.processing_time_minutes +
                  ' minute/s';
            }
            clientStepRow.processingTime = processingTime;
            processedClientSteps.push(clientStepRow);
          });
        } else {
          processedClientSteps.push({
            clientStepName: clientStep.info.title,
            agencyActions: '',
            fees: this.getClientStepFees(clientStep.info),
            processingTime: '',
            personResponsible: '',
            borderBottom: true,
          });
        }
      });
      return processedClientSteps;
      // for(let clientStep of clientSteps){
      //   let clientStep = {
      //     clientStepName:clientStep,
      //     agencyActions:'',
      //     fees:'',
      //     processingTime:'',
      //     personResponsible:''
      //   }

      // }
    }

    return processedClientSteps;
  }

  //remove redundant fees
  filterFormulaFees(clientSteps: any[]) {
    let temp_array: any = [];
    clientSteps.forEach((step: any) => {
      step.info.list_of_formula_fees_arr.forEach((data: any) => {
        if (
          temp_array.filter((row: any) => row.fee_type == data.fee_type)
            .length <= 0
        ) {
          temp_array.push(data);
        }
      });
    });

    return temp_array;
  }
  filterPossibleFees(clientSteps: any[]) {
    let temp_array: any = [];
    clientSteps.forEach((step: any) => {
      step.info.list_of_possible_fees_arr.forEach((data: any) => {
        if (temp_array.filter((row: any) => row == data).length <= 0) {
          temp_array.push(data);
        }
      });
    });

    return temp_array;
  }

  getClientStepFees(clientStepData: any) {
    let fees = '';
    if (
      clientStepData.standard_fees == true ||
      clientStepData.list_of_possible_fees_or_formulas == true
    ) {
      if (clientStepData.standard_fees == true) {
        fees += clientStepData.fees + ' PHP'; //return clientStepData.fees+' php'
        if (clientStepData.fees_arr && clientStepData.fees_arr.length > 0) {
          fees += (fees ? '<br><br>' : '') + 'Breakdown:';
          clientStepData.fees_arr.map((fee_data: any) => {
            fees += (fees ? '<br>' : '') + fee_data.fee_type + ':';
            if (fee_data.amount)
              fees += (fees ? ' ' : '') + fee_data.amount + ' PHP';
          });
        }
      } //fees += clientStepData.fees+' php'//return clientStepData.fees+' php'
      else if (
        clientStepData.list_of_possible_fees_or_formulas == true &&
        clientStepData.list_of_fees_arr &&
        clientStepData.list_of_fees_arr.length > 0
      ) {
        clientStepData.list_of_fees_arr.map((list_of_fees_data: any) => {
          fees += (fees ? '<br><br>' : '') + list_of_fees_data.fee_type + ':';
          if (list_of_fees_data.list_fee_desc)
            fees += (fees ? '<br>' : '') + list_of_fees_data.list_fee_desc;
        });
      } //fees += (fees ? '<br>' : '')+clientStepData.list_of_fees_val//return clientStepData.list_of_fees_val

      return fees;
    }
    // else if(clientStepData.is_situational_step) return clientStepData.fees+' php'
    return 'None';
  }

  step = 0;

  initializedata() {
    this.printTotalProcessingTime();
    this.checkProcessingTimePerSituation()
    if (this.step == 4) {
      this.getServices();
    }
  }

  setStep(index: number) {
    this.step = index;
    this.initializedata();
  }

  nextStep() {
    this.step++;
    this.initializedata();
  }

  prevStep() {
    this.step--;
    this.initializedata();
  }

  InvalidSituationalStep() : number {
    let counter= 0;
    this.clientStepDatasource.forEach((clientStep:any)=>{
        if (clientStep.situational){
          if( clientStep.clientStep.length <= 1 ){
            counter +=1
          }
        
        }

    })

    return counter

  }

  temp:any = [...this.allOffices,...this.suboffice]

  onkey(event:any){

    const value = event.target.value.toLowerCase();
    if(value){
      this.temp =  this.alloficesexcludingGroup.filter((f:any)=> f.toLowerCase().includes(value))
    } 
    else this.temp = [...this.allOffices,...this.suboffice]  

  }

  filterredOffice:any = []
  officefilter:any = ""
}
