<div class="mx-auto">
    <table class = "table table-bordered">
        <thead class = "table table-dark text-center">
            <tr>
                <th colspan = "2">SUMMARY REPORT  - CONSULTATION STATISTICS</th>
            </tr>
            <tr>
                <!-- <th colspan = "2">(October 1 - 30, 2020)</th> -->
                <th colspan = "2">
                    <app-date-picker 
                    (datePickerEvent) = "receivedDatePicker($event)"
                    ></app-date-picker>{{dateInfo}}</th>    
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class = "table-warning">COUNT OF SCHEDULED CONSULTATION</td>
                <td></td>
            </tr>
            <tr>
                <td class = "table-warning">% OF SCHEDULED CONSULTATION</td>
                <td></td>
            </tr>
          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">ONGOING</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr>
                                <td class = "table-warning">COUNT OF ONGOING:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT WITH COMMENT:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>UNANSWERED:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ANSWERED:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>INCORPORATED</td>
                                <td>RETURNED</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        
                   </table>
               </td>
          </tr>

          <tr>
            <td colspan = "4">
                <table class = "table table-bordered mb-0">
                    
                        <thead class = "table table-dark">
                            <tr>
                                <th colspan = "2">BY TYPE</th>
                                
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr></tr>
                            <tr>
                                <td>COUNT OF COMPLETED:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT OF DUE IN 7 DAYS</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>COUNT OF RESCHEDULED</td>
                                <td></td>
                            </tr>
                            
                        </tbody>
                        
                   </table>
               </td>
          </tr>
        </tbody>
        
    </table>
</div>