import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { format } from 'date-fns';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PdfRenderService } from 'src/app/pdf-render.service';
import { firstValueFrom } from 'rxjs';

@Component({
selector: 'app-public-consultations-view',
templateUrl: './public-consultations-view.component.html',
styleUrls: ['./public-consultations-view.component.scss']
})
export class PublicConsultationsViewComponent implements OnInit, OnChanges {

  @Input() objectData:any;
  @Input() baseCode:string = '';
  // @Input() consultationsData:any;
  consultationsData: Array<any> = [];
  agencyOption:string;
  // agency_item:any;
  consultationTableDisplayCol: string[] = ['rowId'];
  consultationTabledataSource: any;
  crisLifeStatus:any;
  crisUploads:any= []

  consultations_file:any;
  
  constructor(
      private store: AngularFireStorage,
      private pdf_render: PdfRenderService,
  ) { }

  ngOnInit(): void {
    this.crisUploads = []
    this.checkData()
    this.assignDataSource()
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.objectData.currentValue){
        this.crisUploads = []
        this.checkData()
        this.assignDataSource()
      }
  }

  // load cris data
  async checkData(){
    if(this.objectData){
      if(!this.consultations_file){
        if(this.objectData.consultation_file_folder && this.objectData.consultation_file_name){
            
          this.consultations_file = {
            consultations_file_folder: this.objectData.consultation_file_folder,
            consultations_file_name: this.objectData.consultation_file_name,
          }
  
          let tokenized = this.consultations_file.consultations_file_name.split(".")
          if(tokenized && tokenized.length > 0 && tokenized[tokenized.length-1] == 'zip'){
            this.consultations_file.consultations_file_type = 'zip';
            this.store.ref(`${this.consultations_file.consultations_file_folder}/${this.consultations_file.consultations_file_name}`).getDownloadURL()
            .subscribe((url)=>{
              this.consultations_file.consultations_file_download_link = url
            })
          } else{
            const path = `${this.consultations_file.consultations_file_folder}/${this.consultations_file.consultations_file_name}`;
  
            firstValueFrom(this.pdf_render.getFileUrl(path)).then((data: any) => {
              this.consultations_file.consultations_file = data;
              this.consultations_file.consultations_file_page = 1; 
            });
          }
        }
      }
    }
  }
  // !load cris data

  assignDataSource(){

    if(this.objectData.consultationsData){
      this.consultationsData = this.objectData.consultationsData
      let consultations:any[] = this.consultationsData
      consultations.map((item)=>{
        item.formated_from_date = format(item.from_date.toDate(), 'MM/dd/yyyy');
        item.formated_to_date = format(item.to_date.toDate(), 'MM/dd/yyyy');
      })
      this.consultationTabledataSource = new MatTableDataSource<any>(consultations)
    }
  }

  // file functions
  pageShift(action:any){
    // if(ctr == 'consultations'){
      if(action == "next"){
          if(this.consultations_file.consultations_file_page < this.consultations_file.fileBaseTotalPage)
              this.consultations_file.consultations_file_page++;
      }
      if(action == "back"){
          if(this.consultations_file.consultations_file_page > 1)
              this.consultations_file.consultations_file_page--;
      }
    // }
    // else{
    //   if(action == "next"){
    //       if(this.crisUploads[ctr].fileBasePage < this.crisUploads[ctr].fileBaseTotalPage)
    //           this.crisUploads[ctr].fileBasePage++;
    //   }
    //   if(action == "back"){
    //       if(this.crisUploads[ctr].fileBasePage > 1)
    //           this.crisUploads[ctr].fileBasePage--;
    //   }
    // }
  }

  afterLoadComplete(pdf: PDFDocumentProxy){
    this.consultations_file.consultations_file_total_page = pdf.numPages;
    // if(ctr == 'consultations') this.consultations_file.consultations_file_total_page = pdf.numPages;
    // else  this.crisUploads[ctr].fileBaseTotalPage = pdf.numPages;
  }
  
  downloadFile(downloadLink:any, fileName:string, section:string){
    window.open(downloadLink, '_blank')
  }
  // !file functions
}
