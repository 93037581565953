import { first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { PdfRenderService } from 'src/app/pdf-render.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, OnChanges, Input, OnInit, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { format } from 'date-fns';

@Component({
selector: 'app-regulations-textreg-view',
templateUrl: './regulations-textreg-view.component.html',
styleUrls: ['./regulations-textreg-view.component.scss']
})
export class RegulationsTextRegulationViewComponent implements OnInit, OnChanges {

  @Input() id:any;
  @Input() regulation:any;

  @Input() textRegulationData:any;

  isLoaded:boolean = false;

  // currenExistingRegulation:any;
  // currentProposedRegulation:any;
  chosenRegulation:any;
  chosenRegulationFile:any;
  version:any;
  imgSrc:any;

  constructor(
    private afs: AngularFirestore,
    private store: AngularFireStorage,
    public fb:UntypedFormBuilder,
  ) { }

  ngOnInit():void{
    console.log('INIT textRegulationData',this.textRegulationData)
    if(this.textRegulationData){
      this.showTextRegulationData()
    }
    this.isLoaded = true
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CHANGE textRegulationData',changes)
    if(changes.textRegulationData.currentValue){
      this.textRegulationData = changes.textRegulationData.currentValue
      this.showTextRegulationData()
    }
    this.isLoaded = true
  }

  showTextRegulationData(){
    if(this.textRegulationData.existing) this.chooseVersion(this.textRegulationData.existing.controlCode)
    else if(this.textRegulationData.dris) this.chooseVersion(this.textRegulationData.dris.controlCode)
    else if(this.textRegulationData.cris) this.chooseVersion(this.textRegulationData.cris.controlCode)
    else if(this.textRegulationData.proposed) this.chooseVersion(this.textRegulationData.proposed.controlCode)
  }

  // ngOnChanges(): void {
  //   // if(this.regulation){
  //   //   this.isLoaded=true;
  //   //   // console.log('regulation: ', this.regulation)
  //   //   this.loadTabInfo(this.regulation)
  //   // }

  //   if(this.textRegulationData){
  //     this.isLoaded=true;
  //     console.log('textRegulationData',this.textRegulationData)
  //     if(this.textRegulationData.existing) this.chooseVersion(this.textRegulationData.existing.controlCode)
  //     else if(this.textRegulationData.dris) this.chooseVersion(this.textRegulationData.dris.controlCode)
  //     else if(this.textRegulationData.cris) this.chooseVersion(this.textRegulationData.cris.controlCode)
  //     else if(this.textRegulationData.proposed) this.chooseVersion(this.textRegulationData.proposed.controlCode)
  //   }
  // }

  // loadTabInfo(regulation:any){
  //   if(regulation.regulation_text){
  //     for(let sectionCtr in regulation.regulation_text){
  //       if(regulation.regulation_text[sectionCtr].subsections){
  //         for(let subCtr in regulation.regulation_text[sectionCtr].subsections){
  //           if(regulation.regulation_text[sectionCtr].subsections[subCtr].subsection_image_file && regulation.regulation_text[sectionCtr].subsections[subCtr].subsection_image_folder){
  //             const filePath = regulation.regulation_text[sectionCtr].subsections[subCtr].subsection_image_folder+"/"+regulation.regulation_text[sectionCtr].subsections[subCtr].subsection_image_file;
  //             // console.log(filePath)
  //             //blocked by cross origin
  //             this.store.ref(filePath).getDownloadURL()
  //             .subscribe(url=>{
  //               // console.log(url)
  //               if(url)
  //               regulation.regulation_text[sectionCtr].subsections[subCtr].fileUrl = url
  //             })
  //           }
  //         }
  //       }
  //     }
  //   }
  //   if(regulation.version == "existing"){
  //     this.currenExistingRegulation = regulation
  //     this.chosenRegulation = this.currenExistingRegulation
  //   }
  //   else if(regulation.version == "proposed"){
  //     this.currentProposedRegulation = regulation
  //     this.chosenRegulation = this.currentProposedRegulation
  //     // console.log('currentProposedRegulation: ', this.currentProposedRegulation)

  //   }

  //   // if(regulation.version == "existing" && regulation.proposed_regulation_02_id)
  //   //   this.loadRegulation(regulation.proposed_regulation_02_id,'proposed','regulations-proposed-phase-02')
  // }

  chooseVersion(controlCode:any){
    if(controlCode === 'existing'){
      this.version = 'Existing'
      this.chosenRegulation = this.textRegulationData.existing.data;
      this.chosenRegulationFile = this.textRegulationData.existing.reg_text_annex_file
    }
    else if(controlCode === 'dris'){
      this.version = 'DRIS'
      this.chosenRegulation = this.textRegulationData.dris.data;
      this.chosenRegulationFile = this.textRegulationData.dris.reg_text_annex_file
    }
    else if(controlCode === 'cris'){
      this.version = 'CRIS'
      this.chosenRegulation = this.textRegulationData.cris.data;
      this.chosenRegulationFile = this.textRegulationData.cris.reg_text_annex_file
    }
    else if(controlCode === 'proposed'){
      this.version = 'Proposed'
      this.chosenRegulation = this.textRegulationData.proposed.data;
      this.chosenRegulationFile = this.textRegulationData.proposed.reg_text_annex_file
    }else{
      this.version = null
      this.chosenRegulation = null;
      this.chosenRegulationFile = null
    }
  }
  
  downloadFile(downloadLink:any){
    window.open(downloadLink, '_blank')
  }
}
