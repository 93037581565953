import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { event, map } from 'jquery';
import { tap, startWith, filter } from 'rxjs/operators';
import { SnackbarNotifService } from 'src/app/service/snackbar-notif.service';

@Component({
  selector: 'app-artemis-office-grouping',
  templateUrl: './artemis-office-grouping.component.html',
  styleUrls: ['./artemis-office-grouping.component.scss']
})
export class ArtemisOfficeGroupingComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
    private notif: SnackbarNotifService,
    public dialogRef: MatDialogRef<ArtemisOfficeGroupingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this.loadOfficeDivision()
    this.loadsubOffices()
    this.loadofficeGroups()

    //console.log(this.AgencyOffices)
  }

  closeForm(){
    this.dialogRef.close()
  }


  get AgencyOffices() : any[] {
    return [...this.officedivision,...this.suboffice]
  }

  p:number = 1

  officedivision:any[] = []
  suboffice:any[]=[]
  keyword:any =""
  keywords:any = ""
  officeGroup:any[]=[]
  groupName:any = ""

  loadsubOffices(){
    this.afs.collection("Agency Offices", filter => filter.where('fromAgencyId','==',this.agencyId))
    .snapshotChanges()
    .pipe(tap(data=>{

      this.suboffice = []

      data.forEach((info:any)=>{
          let item:any = info.payload.doc.data();
          const o :any = {
            name: item.name,
            seleted: false,
            type : item.type
          }
          this.suboffice.push(item)
      })

    })).subscribe()
  }
   agencyId = sessionStorage.getItem('agency_id');
  loadOfficeDivision() {
    let officeFilter: QueryFn;
    
    officeFilter = (filter) =>
      filter.where('fromAgencyId', '==', this.agencyId).orderBy('createdAt', 'asc');
    // .where('Status', '==', 'Active');

    //from Agency-Offices-Division Collection to Agency Office Hierarchy
    this.afs
      .collection('Agency Office Hierarchy', officeFilter)
      .snapshotChanges()
      .pipe(
        tap((data: any) => {
          this.officedivision = [];
          data.forEach((info: any) => {
            let item: any = info.payload.doc.data();
            let o :any = {
              name : item.office.name,
              selected: false,
              type : item.office.type
            }
            this.officedivision.push(o);
            this.getChildData(item.office, this.officedivision);
            //this.allOffices.push(item.name);
          });

        })
      )
      .subscribe();
  }


  getChildData(officeObj: any, destinationArray: any[]) {
    if (officeObj.children && officeObj.children.length > 0) {
      officeObj.children.forEach((office: any) => {
        let o :any = {
          name : office.name,
          selected: false,
          type : office.type
        }
        destinationArray.push(o);
        this.getChildData(office, destinationArray);
      });
    }
  }

  officeSelected:any = []

  get selectedOffices(){
    return this.AgencyOffices.filter((f:any)=> f.selected == true)
  }
  createGroup(){
    if (this.groupName && this.selectedOffices.length > 1){
      const office_group:any = {
        group_name: this.groupName,
        offices: this.selectedOffices,
        fromAgencyId: sessionStorage.getItem('agency_id')
      }
        this.afs.collection("Office Group").add(office_group)
        .then(()=>{
          this.clearAll()
        })

    }else{
      this.notif.displayNotif("error","Invalid group name or no selected office.")
    }
  }

  clearAll(){
    this.clearSelection(false);
    this.officeSelected = []
    this.groupName = ""
  }

  changed(event:any){
    this.clearSelection(event.target.checked)
  }

  clearSelection(checked:any = false){
      this.AgencyOffices.forEach((office:any)=>{
        office.selected = checked
      })
  }

  updatelist(office:any){
    if (office.selected){
        this.officeSelected.push(office)
    }else{
      const index  = this.officeSelected.indexOf(office)
      this.officeSelected.splice(index,1)
    }
  }


  groupedOffices:any = []
  loadofficeGroups(){

    this.afs.collection("Office Group", filter => filter.where('fromAgencyId','==',this.agencyId))
    .snapshotChanges()
    .pipe(tap(data=>{
        this.groupedOffices = []
        data.forEach((info:any)=>{
            let item:any = info.payload.doc.data();
            item.id = info.payload.doc.id;
            this.groupedOffices.push(item)
        })
    })).subscribe()

  }

  delete(office:any){

    this.notif.confirmDialog("Delete Group").then(result=>{
      console.log(result)
      if(result){ 
        
        this.afs.collection("Office Group").doc(office.id).delete();
      }

    })
  }
}
